import { useMemo } from 'react';
import { useTask } from './useTask';
import { useStateHandler } from 'hooks/instanceHook';
import * as StateHandlers from 'states';
import * as StateHandlerHelpers from 'states/helpers';
import { DialogTypes, ForwardedPort } from 'forwarded-ports';

export type IRemoteSupportResult = {
  isUsedForWindows: boolean;
  isUsedForLinux: boolean;
  isUsedForCore: boolean;
  isUsedForRds: boolean;
  isUsedVm: boolean;
  gatewayWanIp: string;
  selectedPort?: ForwardedPort;
  isTaskActive: boolean;
  isRequesting: boolean;
  setPort: typeof StateHandlerHelpers.updateForwardedPorts;
};

export type IRsConfig = {
  type?: DialogTypes;
  vmId?: number;
  onSuccess?: AnyFunc;
};

export function useRemoteSupport(config: IRsConfig): IRemoteSupportResult {
  const { type, vmId, onSuccess } = config;
  const coreInfrastructure = useStateHandler(StateHandlers.core);
  const rds = useStateHandler(StateHandlers.rds);
  const connectivity = useStateHandler(StateHandlers.connectivity);
  const ports = useStateHandler(StateHandlers.forwardedPorts);
  const data = ports.data;

  const foundCorePort = data.find(
    (item) => item.coreInfrastructureId === coreInfrastructure.data?.id
  );

  const foundRdsPort = data.find((item) => item.rdsId === rds.data?.id);

  const selectedPort = useMemo(() => {
    let port =
      type === 'vm' && vmId
        ? data.find((item) => item.vmId === vmId)
        : undefined;

    if (type === 'core') {
      port = foundCorePort;
    }

    if (type === 'rds') {
      port = foundRdsPort;
    }

    return port;
  }, [type, foundCorePort, foundRdsPort, vmId, JSON.stringify(data)]);

  const task = useTask(selectedPort?.task, {
    onComplete: async () => {
      await ports.get({
        perPage: 1000,
        page: 1,
      });
      return onSuccess && onSuccess();
    },
  });

  return {
    isUsedForLinux: !!ports.data.find((item) => item.port === 3389),
    isUsedForWindows: !!ports.data.find((item) => item.port === 22),
    isUsedForCore: !!foundCorePort,
    isUsedForRds: !!foundRdsPort,
    isUsedVm: !!selectedPort,
    setPort: StateHandlerHelpers.updateForwardedPorts,
    gatewayWanIp: connectivity.data?.publicIp,
    selectedPort,
    isTaskActive: task.isTaskActive,
    isRequesting: ports.isRequesting,
  };
}
