import * as React from 'react';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Row, Col, Alert, Menu, ListItemButton, MuiIcons } from 'elements';
import { useTranslation } from 'react-i18next';
import * as Types from './types';

const Title = (props: Types.ITitleProps) => {
  const {
    onManageBilling,
    onSetLimits,
    showActions,
    outsourceManagementEnabled,
    goToMaintenance,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="mb-15">
      <Row
        columnSpacing={2}
        alignItems="center"
        justifyContent="space-between"
        className="mb-15"
      >
        <Col>
          <h3>{t('monitoring.title')}</h3>
        </Col>
        {showActions && (
          <Col>
            <PopupState variant="popover">
              {(popupState) => (
                <>
                  <MuiIcons.MoreVert
                    {...bindTrigger(popupState)}
                    className="pointer fs-18"
                  />
                  <Menu {...bindMenu(popupState)}>
                    <ListItemButton
                      onClick={() => {
                        onSetLimits();
                        popupState.close();
                      }}
                      key="setLimits"
                    >
                      {t('monitoring.buttons.setLimits')}
                    </ListItemButton>
                    {onManageBilling && (
                      <ListItemButton
                        onClick={() => {
                          onManageBilling();
                          popupState.close();
                        }}
                        key="manageBilling"
                      >
                        {t('monitoring.buttons.manageBilling')}
                      </ListItemButton>
                    )}
                    <ListItemButton
                      onClick={() => {
                        goToMaintenance();
                        popupState.close();
                      }}
                      key="maintenance"
                    >
                      {t('monitoring.buttons.maintenance')}
                    </ListItemButton>
                  </Menu>
                </>
              )}
            </PopupState>
          </Col>
        )}
      </Row>
      {outsourceManagementEnabled && (
        <Alert severity="info" contentClassName="fs-12">
          {t('monitoring.alerts.hasOutsourceManagement')}
        </Alert>
      )}
    </div>
  );
};

export default Title;
