import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Dialog,
  DialogProps,
  Button,
  Row,
  Col,
  LinearTable,
  Radio,
  PrimaryTextSpan,
  Alert,
  Input,
  createColumnHelper,
} from 'elements';
import { useStateHandler, useState, useForceUpdate } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { mediaFormValidation } from './constants';
import { showSystemMessage, debounce, remCalc } from 'utils';

type Props = DialogProps<any> & {
  vmId: number;
};

const INITIAL_QUERY: IDefaultTableQuery = {
  page: 1,
  perPage: 10,
  orderBy: 'name',
  orderType: 'asc',
};

const INITIAL_STATE: { selected?: IVmMedia.Media } = {
  selected: undefined,
};

const column = createColumnHelper<IVmMedia.Media>();

export const makeColumns = (props: {
  t: any;
  onSelect: (media?: IVmMedia.Media) => void;
  selected?: IVmMedia.Media;
}) => [
  column.accessor('name', {
    header: (
      <span className="fs-14 steel weight-normal pl-35">
        {props.t('table.head.name')}
      </span>
    ),
    disableSort: false,
    width: '80%',
    cell: ({ row: { original: media } }: ICell<IVmMedia.Media>) => {
      const isSelected = props.selected?.name === media.name;
      return (
        <div className="ml-5">
          <Radio
            label={media.name}
            checked={isSelected}
            onChange={() => props.onSelect(isSelected ? undefined : media)}
          />
        </div>
      );
    },
  }),

  column.accessor('sizeMb', {
    header: (
      <span className="fs-14 steel weight-normal">
        {props.t('media.dialog.table.head.storage')}
      </span>
    ),
    disableSort: true,
    alignment: 'right',
    minWidth: 0,
    width: 150,
    cell: ({ row: { original: media } }: ICell<IVmMedia.Media>) =>
      `${media.sizeMb} MB`,
  }),
];

const MediaDialog = observer((props: Props) => {
  const { vmId } = props;
  const media = useStateHandler(StateHandlers.media);
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const { onSave, open, onClose } = props;
  const searchRef = React.useRef({ value: '' });

  const [query, changeQuery] = useState<IDefaultTableQuery>(INITIAL_QUERY);

  const injectMedia = React.useCallback(
    media.executeRequest('injectMedia'),
    []
  );

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    isSubmitting,
    submitCount,
    resetForm,
  } = useFormik({
    initialValues: INITIAL_STATE,
    validationSchema: mediaFormValidation,
    validateOnMount: false,
    onSubmit: async (val) => {
      try {
        await injectMedia(vmId, { mediaName: val.selected?.name });
        showSystemMessage('media.notify.inject.success', 'success');
        onSave && onSave(val.selected);
      } catch (er: any) {
        showSystemMessage(er.message, 'error');
      }
    },
  });

  const columns = React.useMemo(() => {
    return makeColumns({
      onSelect: (el) => setFieldValue('selected', el),
      t,
      selected: values.selected,
    });
  }, [JSON.stringify(values.selected)]);

  React.useEffect(() => {
    if (!open) {
      resetForm();
      media.reset();
    }
  }, [open]);

  React.useEffect(() => {
    if (open) {
      media.get(query);
    }
  }, [open, JSON.stringify(query)]);

  React.useEffect(() => {
    searchRef.current.value = query.q || '';
    forceUpdate();
  }, [query.q]);

  const changeSearchUrl = React.useCallback(
    (q: string) => changeQuery({ ...query, q, page: 1 }),
    [query]
  );

  const onItemSearch = React.useCallback(
    debounce(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        changeSearchUrl(ev.target.value),
      1000
    ),
    [query]
  );

  return (
    <Dialog
      title={t('media.dialog.inject.title')}
      handleSubmit={handleSubmit}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      keepMounted={false}
      actions={
        <Row alignItems="center" justifyContent="flex-end" columnSpacing={2}>
          <Col>
            <Button color="default" variant="outlined" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button disabled={isSubmitting} type="submit">
              {t('common.insert')}
            </Button>
          </Col>
        </Row>
      }
    >
      <div className="steel mb-15">{t('media.dialog.inject.content')}</div>
      <div className="mb-25">
        <Input
          ref={searchRef}
          type="search"
          placeholder={`forms.placeholders.search`}
          inputClassName={cn('bg-white')}
          inputProps={{ style: { height: remCalc(48) } }}
          onKeyUp={(e: any) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              changeSearchUrl(e.target.value);
            }
          }}
          onClear={() => {
            searchRef.current.value = '';
            changeSearchUrl('');
          }}
          onChange={onItemSearch}
        />
      </div>
      <LinearTable
        data={media.data}
        params={media.meta}
        query={query}
        usePagination
        shouldUseQuery={false}
        columns={columns}
        isLoading={!media.dataReceived}
        className={cn('mb-35', { disabled: media.isRequesting })}
        cellClassName="pt-5 pb-5"
        hasSorting
        onSort={(orderBy, orderType) => changeQuery({ orderBy, orderType })}
        onPageChange={({ page }) => changeQuery({ page })}
      />
      <Row className="mb-15" columnSpacing={2}>
        <Col>{t('media.dialog.selected')}:</Col>
        <Col>
          {values.selected?.name ? (
            <PrimaryTextSpan>{values.selected?.name}</PrimaryTextSpan>
          ) : (
            '-'
          )}
        </Col>
      </Row>
      {!!submitCount && errors.selected && (
        <Alert severity="error">{t(errors.selected)}</Alert>
      )}
    </Dialog>
  );
});

export default MediaDialog;
