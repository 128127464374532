import * as React from 'react';
import { Skeleton } from 'elements';
import { useAsync, useMounted } from 'hooks';
import { monitoringService } from 'services';
import { useTranslation } from 'react-i18next';
import { ChartItem } from './ChartHolder';
import { IChartProps } from '../types';
import { ChartItemHolder } from '../Styled';

const GlobalChart = (props: IChartProps) => {
  const {
    id,
    name,
    metricKey,
    chartConfig,
    instanceId,
    monitoringId,
    discoveryUnitName,
    dateFrom,
    dateTo,
    limit,
    measureUnit,
    isWideDateRange,
  } = props;
  const { t } = useTranslation();
  const isMounted = useMounted();

  const request = React.useMemo(() => {
    return isWideDateRange
      ? monitoringService.getChartTrend
      : monitoringService.getChartHistory;
  }, [isWideDateRange]);

  const { execute, value, error, isPending } = useAsync(request);

  const dataReceived = !!value;

  React.useEffect(() => {
    if (isMounted) {
      execute(monitoringId, instanceId, id, { dateFrom, dateTo });
    }
  }, [dateFrom, dateTo, JSON.stringify(limit), isMounted]);

  const isDisk = metricKey === 'filesystem_space_util';

  return (
    <ChartItemHolder className="chart-item">
      <div className="_title">
        {[
          `${t(`monitoring.sections.charts.box.${metricKey}.name`)} ${
            isDisk ? discoveryUnitName : ''
          }`.trim(),
          t(`monitoring.measureUnit.${measureUnit}`),
        ].join(', ')}
      </div>
      {!dataReceived || isPending ? (
        <Skeleton height={262} animation="wave" variant="rectangular" />
      ) : (
        <div className="pl-5 pr-15 _chart-content">
          <ChartItem
            name={name}
            data={value}
            chartConfig={chartConfig}
            limit={limit}
            id={id.toString()}
            error={error}
            metricKey={metricKey}
            isWideDateRange={isWideDateRange}
            measureUnit={measureUnit}
          />
        </div>
      )}
    </ChartItemHolder>
  );
};

export default GlobalChart;
