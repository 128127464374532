import {
  IInvoice,
  IImportedPayment,
  IImportedPaymentEntry,
  IImportedPaymentEntryToValidate,
  IImportedPaymentEntryToValidateResponse,
  IInvoicePosition,
  IParsedImportedPayment,
  IStateIncluded,
  IInvoiceIncomeStats,
  IInvoiceIncomeStatRecord,
  IInvoicePositionUnit,
} from 'invoices';
import { ICurrentUser } from 'auth-shapes';
import { ValueType } from 'global-shapes';
import * as R from 'ramda';
import dayjs from 'dayjs';
import {
  utcDayjs,
  showSystemMessage,
  getEntries,
  getEntryContent,
  download,
} from 'utils';
import { documentsService } from 'services';
import { ICreateDocBody } from 'documents';
import { ICustomer } from 'customer-shapes';
import { GLOBAL_DATE_FORMAT, GlobalResponse } from 'enums';
import { invoicesService, partnersService, tenantsService } from 'services';
import { DEFAULT_USERS_QUERY, unitOptions } from './constants';

import { IInvoiceTypes } from './types';

export interface ITotalPositionPricesShape {
  clearTotal: number;
  total: number;
}

export const sumAllPositionTotalPrice = (
  positions: IInvoicePosition[],
  totalRoundDiff: string,
  vats: number[]
): ITotalPositionPricesShape => {
  const clearTotal = R.sum(positions.map((p) => +p.amount));

  const allVatsValue = R.sum(vats);
  const total = clearTotal + allVatsValue + (+totalRoundDiff || 0);

  return {
    clearTotal,
    total,
  };
};

const ejectInvoiceIdFromRef = (ref?: any) => {
  const id = ref ? ref.innerHTML : '';
  return id.length ? id.slice(id.length - 9, id.length - 1) : '';
};

type EntryDataWithInvoice = IImportedPaymentEntry & { invoiceId: string };

export const parseXml = (
  xml: XMLDocument
): {
  data: IParsedImportedPayment[];
  validateData: IImportedPaymentEntryToValidate[];
} => {
  const importedPayments: IParsedImportedPayment[] = [];
  const paymentsForValidate: IImportedPaymentEntryToValidate[] = [];
  const Ntfctns = xml.querySelectorAll('Ntfctn');

  Ntfctns.forEach((Ntfctn) => {
    if (Ntfctn) {
      const entries: EntryDataWithInvoice[] = [];

      Ntfctn.querySelectorAll('Ntry').forEach((entry) => {
        const transactions = entry.querySelectorAll('TxDtls');

        transactions.forEach((txn) => {
          const CdtDbtInd = txn.querySelector('CdtDbtInd');
          const AddtlNtryInf = entry.querySelector('AddtlNtryInf');

          const isValidIndicator = CdtDbtInd
            ? CdtDbtInd.innerHTML === 'CRDT'
            : false;

          if (isValidIndicator) {
            const RmtInf = txn.querySelector('RmtInf');
            const Amt = txn.querySelector('Amt') || null;
            const Ref = RmtInf ? RmtInf.querySelector('Ref') : null;
            const AcctSvcrRef = txn ? txn.querySelector('AcctSvcrRef') : '';
            const BookgDt = entry.querySelector('BookgDt');
            const EntryDate = BookgDt ? BookgDt.querySelector('Dt') : '';

            const referenceNo = Ref ? Ref.innerHTML : '';
            const comment = AddtlNtryInf ? AddtlNtryInf.innerHTML.trim() : '';

            const amount = Amt ? Amt.innerHTML : '';
            const date = EntryDate ? EntryDate.innerHTML : undefined;
            const externalPaymentId = AcctSvcrRef ? AcctSvcrRef.innerHTML : '';

            const entryData: EntryDataWithInvoice = {
              referenceNo,
              amount,
              date: date || '',
              externalPaymentId,
              invoiceId: ejectInvoiceIdFromRef(Ref),
              comment,
            };

            const entryToValidate: IImportedPaymentEntryToValidate = {
              referenceNo,
              amount,
              date: date
                ? dayjs(date, 'YYYY-MM-DD').format(GLOBAL_DATE_FORMAT)
                : undefined,
              externalPaymentId,
              comment,
            };

            entries.push(entryData);
            paymentsForValidate.push(entryToValidate);
          }
        });
      });

      const groupedTransactions = R.groupBy<EntryDataWithInvoice>(
        (ent) => ent.invoiceId
      )(entries);

      Object.keys(groupedTransactions).forEach((invoiceId: string) => {
        importedPayments.push({
          id: invoiceId,
          entries: groupedTransactions[invoiceId].map(
            ({ invoiceId, ...entry }) => entry
          ),
        });
      });
    }
  });

  return {
    data: importedPayments,
    validateData: paymentsForValidate,
  };
};

export const reformatDataWithDuplicates = (
  data: IParsedImportedPayment[],
  validated: IImportedPaymentEntryToValidateResponse[]
): {
  data: IImportedPayment[];
  included: IStateIncluded;
} => {
  const result: IImportedPayment[] = [];
  const included: IStateIncluded = {};
  data.forEach((inv) => {
    const formattedInvoice: IImportedPayment = {
      ...inv,
      date: '',
      amount: 0,
    };
    const foundInvoice = validated.find((vEntry) =>
      vEntry?.invoice
        ? vEntry.invoice.invoiceNumberString === inv.id
        : undefined
    );
    formattedInvoice.exist = !!foundInvoice;
    if (formattedInvoice.exist) {
      formattedInvoice.date = dayjs(foundInvoice?.invoice?.invoiceDate).format(
        'DD.MM.YYYY'
      );
      formattedInvoice.amount = +(foundInvoice?.invoice?.total || 0);
    }

    formattedInvoice.entries = inv.entries.map((entry) => {
      included[entry.externalPaymentId] = true;
      const duplicate = validated.find((vEntry) => {
        return (
          entry.externalPaymentId === vEntry.externalPaymentId &&
          !!vEntry.payment
        );
      });
      if (duplicate) {
        entry.indicator = 'duplicate';
        included[entry.externalPaymentId] = false;
      }
      entry.indicator = !formattedInvoice.exist ? 'noInvoice' : entry.indicator;
      return entry;
    });

    result.push(formattedInvoice);
  });
  return {
    data: result,
    included,
  };
};

export const reformatDataForImportSave = (
  data: IImportedPaymentEntryToValidate[],
  included: IStateIncluded
) => {
  return data
    .filter((entry) => included[entry.externalPaymentId])
    .map((entry) => ({
      ...entry,
      amount: +entry.amount,
      date: entry.date
        ? dayjs(entry.date, GLOBAL_DATE_FORMAT).format('YYYY-MM-DD')
        : undefined,
    }));
};

interface IDocInfo {
  isCompleted: boolean;
  isFailed: boolean;
  isActive: boolean;
}

function isDocCompleted(id: number): Promise<IDocInfo> {
  return documentsService.getById(id).then((doc) => {
    return {
      isCompleted: doc.data.state === 'COMPLETED',
      isFailed: doc.data.state === 'FAILED',
      isActive: ['ACTIVE', 'PENDING'].includes(doc.data.state),
    };
  });
}

export async function downloadCsv(payload: ICreateDocBody): Promise<string> {
  let timer: any = undefined;
  const newDoc = await documentsService.create(payload);
  const startDate = utcDayjs(payload.dateFrom).format(GLOBAL_DATE_FORMAT);
  const endDate = utcDayjs(payload.dateTo).format(GLOBAL_DATE_FORMAT);

  return new Promise((resolve, reject) => {
    timer = setInterval(() => {
      return isDocCompleted(newDoc.data.id).then((res) => {
        if (res.isCompleted) {
          download(newDoc.data.doc.url, `payments-${startDate}-${endDate}.csv`);
          resolve(newDoc.data.doc.url);
          clearTimeout(timer);
        }

        if (res.isFailed) {
          clearTimeout(timer);
          reject('error on downloading payments csv');
        }
      });
    }, 2000);
  });
}

export async function onDropPromise(
  files: Blob[]
): Promise<Partial<IInvoiceTypes.ImportPaymentsDialogState>> {
  let submissionsCount = 0;

  const newState: Partial<IInvoiceTypes.ImportPaymentsDialogState> = {
    dataToSave: [],
    data: [],
    included: {},
  };

  const xmlFiles = files.filter((f) => f.type === 'text/xml');
  const zipFiles = files.filter((f) => f.type === 'application/zip');
  const unzippedXmlFiles = await Promise.all(
    zipFiles.map((zipFile) =>
      getEntries(zipFile, {}).then((entries) =>
        Promise.all(entries.map(getEntryContent))
      )
    )
  );
  const allFiles = [...xmlFiles, ...R.flatten(unzippedXmlFiles)];

  const filesCount = allFiles.length;

  return new Promise((resolve, reject) => {
    allFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        const xmlStr = reader.result;
        const parser = new DOMParser();
        const xml = parser.parseFromString(xmlStr as string, 'application/xml');

        const errorNode = xml.querySelector('parsererror');

        if (errorNode) {
          showSystemMessage('error while parsing', 'error');
          return reject('error while parsing');
        }

        const parsedXml = parseXml(xml);
        const { data: validated } = await invoicesService.validatePayments(
          parsedXml.validateData.map((entry) => {
            return {
              ...entry,
              amount: +entry.amount,
              date: entry.date
                ? dayjs(entry.date, GLOBAL_DATE_FORMAT).format('YYYY-MM-DD')
                : undefined,
            };
          })
        );

        const reformattedData = reformatDataWithDuplicates(
          parsedXml.data,
          validated
        );

        newState.dataToSave = [
          ...(newState.dataToSave || []),
          ...parsedXml.validateData,
        ];
        newState.data = [...(newState.data || []), ...reformattedData.data];
        newState.included = {
          ...newState.included,
          ...reformattedData.included,
        };

        submissionsCount++;

        if (submissionsCount === filesCount) {
          resolve(newState);
        }
      };
    });
  });
}

const parseLoadResponse = (payload: GlobalResponse<IPartnerTypes.Partner[] | ICustomer[]>) =>
  payload.data.map((user) => ({
    value: user.id,
    label: `${user.name}`,
    dueDate: user.invoiceDueDateDays,
  }));

export const onPartnersLoad = async (q: string) => {
  const payload = await partnersService.getPartners({
    ...DEFAULT_USERS_QUERY,
    orderBy: 'name',
    enabledBilling: true,
    q,
  });
  return parseLoadResponse(payload);
};

export const onTenantsLoad = async (q: string) => {
  const payload = await tenantsService.getTenants({
    ...DEFAULT_USERS_QUERY,
    orderBy: 'name',
    enabledBilling: true,
    q,
  });
  return parseLoadResponse(payload);
};

export function parsePositionToForm(
  position: IInvoicePosition
): IInvoiceTypes.PositionState {
  return {
    id: position.id,
    description: position.description,
    price: +position.price,
    qty: +position.qty,
    unit: R.find(R.propEq('value', position.unit))(
      unitOptions
    ) as ValueType<IInvoicePositionUnit>,
  };
}

function findPeriodValue(period: 'this' | 'last', unit: 'month' | 'year') {
  return (data?: IInvoiceIncomeStatRecord[]): number => {
    if (!data) return 0;
    const foundRecord = data.find((record) => {
      if (period === 'this') {
        if (unit === 'month') {
          return record.period === dayjs().format('YYYY-MM');
        }
        if (unit === 'year') {
          return record.period === dayjs().format('YYYY');
        }
      }
      if (period === 'last') {
        if (unit === 'month') {
          return record.period === dayjs().add(-1, 'month').format('YYYY-MM');
        }
        if (unit === 'year') {
          return record.period === dayjs().add(-1, 'year').format('YYYY');
        }
      }
      return undefined;
    });

    return foundRecord?.income || 0;
  };
}

export function parseIncomeStats(
  data?: IInvoiceIncomeStats
): IInvoiceTypes.IParsedIncomeStat {
  const thisMonth = findPeriodValue('this', 'month')(data?.statisticsPerMonth);
  const lastMonth = findPeriodValue('last', 'month')(data?.statisticsPerMonth);
  const thisYear = findPeriodValue('this', 'year')(data?.statisticsPerYear);
  const lastYear = findPeriodValue('last', 'year')(data?.statisticsPerYear);
  return {
    forecast: data?.forecast || 0,
    perMonth: {
      thisMonth,
      lastMonth,
    },
    perYear: {
      thisYear,
      lastYear,
    },
  };
}

export const parseInvoiceForEditValues = (
  invoice?: IInvoice | null,
  isTenant?: boolean,
  account?: ICurrentUser
): IInvoiceTypes.NewInvoice => {
  const user = isTenant ? account?.tenant : invoice?.tenant || invoice?.partner;
  return {
    userId: user ? { value: user.id, label: user.name } : undefined,
    date: invoice ? dayjs(invoice.invoiceDate) : dayjs(),
    dueDate: invoice
      ? utcDayjs(invoice.dueDate).startOf('day')
      : dayjs().add(14, 'day'),
    autoSend: !!invoice?.autoSend,
  };
};

// export function displayQty(
//   isHourlyUnit: boolean,
//   hasMultiliedQtyValue: boolean,
//   qty: number,
//   resValue: number
// ): number | string {
//   if (isHourlyUnit) {
//     return `${(+qty / 24 / (hasMultiliedQtyValue ? resValue : 1)).toFixed(
//       2
//     )} d`;
//   }

//   return +qty;
// }

// export function displayPrice(isHourlyUnit: boolean, price: number): number {
//   if (isHourlyUnit) {
//     return price * 24;
//   }

//   return price;
// }

// export function shouldHideQtyUnitColumns(
//   isMain: boolean,
//   hasLogs: boolean,
//   resId?: number
// ) {
//   return isMain || hasLogs || [24].includes(resId as number);
// }
