import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ICoreInfrastructure } from 'core-infrastructure';
import { useCoreActiveServices } from 'hooks';
import { CopyTooltip, ActiveServiceLayout } from 'elements';

type Props = ICoreInfrastructure;

const CoreInfrastructureCard = (props: Props) => {
  const { t } = useTranslation();
  const activeServices = useCoreActiveServices(props);
  const hasActiveServices = activeServices.length > 0;
  return (
    <div className="fs-14">
      <div className="primary-dark">
        {t('services.card.content.domainName')}
      </div>

      <div className="steel mb-15">
        <CopyTooltip>{props.domainName}</CopyTooltip>
      </div>

      <div className="primary-dark">
        {t('services.card.content.fileShares')}
      </div>
      <div className="steel mb-15">
        {props.fileShareQty || t('services.card.content.noFileShares')}
      </div>

      <div className="primary-dark">
        {t('services.card.content.totalFileStorage')}
      </div>
      <div className="steel mb-15">
        {props.fileShareDiskSizeGb || 0}
        <span className="pl-5">GB</span>
      </div>

      <div className="primary-dark bolder mb-5">
        {t('services.card.content.activeServices')}
      </div>
      {hasActiveServices ? (
        <ActiveServiceLayout services={activeServices} instance="core" />
      ) : (
        <span className="steel">
          {t('services.card.content.activeServices.noServices')}
        </span>
      )}
    </div>
  );
};

export default CoreInfrastructureCard;
