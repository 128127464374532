import * as Yup from 'yup';

export const defaultDeleteInputSchema = Yup.object({
  // @ts-ignore
  input: Yup.string()
    .trim()
    .required('forms.required')
    // @ts-ignore
    .test({
      message: 'forms.invalid',
      test: (val) => {
        if (!val) return;
        if (val !== 'DELETE') return false;
        return true;
      },
    }),
});

export const customConfirmInputSchema = (codeName: string) =>
  Yup.object({
    // @ts-ignore
    input: Yup.string()
      .trim()
      .required('forms.required')
      // @ts-ignore
      .test({
        message: 'forms.invalid',
        test: (val) => {
          console.log(val, codeName);
          if (!val) return;
          if (val !== codeName) return false;
          return true;
        },
      }),
  });

export const defaultDeleteWithReasonsSchema = (codeName: string) =>
  Yup.object({
    reason: Yup.object().required('forms.required'),
    other: Yup.string()
      .max(300, 'forms.invalid.max')
      .test({
        message: 'forms.required',
        test: function (val) {
          if (this.parent.reason?.value === 'other') {
            if (!(val || '').trim()) return false;
          }
          return true;
        },
      }),
    // @ts-ignore
    input: Yup.string()
      .trim()
      .required('forms.required')
      // @ts-ignore
      .test({
        message: 'forms.invalid',
        test: (val) => {
          if (!val) return;
          if (val !== codeName) return false;
          return true;
        },
      }),
  });
