import * as React from 'react';
import { Button, LinearTable, Row, Col } from 'elements';
import { useQuery, useState } from 'hooks';
import { useTranslation, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as UTILS from 'utils';
import * as StateHandlers from 'states';
import NetworkDetailsDialog from './NetworkDetailsDialog';
import ConnectedVmsDialog from './ConnectedVmsDialog';
import * as ENUMS from './constants';
import { ConnectivityMainWrapper } from './Styled';

const OBSERVERS = {
  networks: StateHandlers.connectivityNetworks,
};

type IViewProps = typeof OBSERVERS;

type IState = {
  networkDialogOpen: boolean;
  usedVm: number | null;
  selected?: IConnectivityTypes.INetwork;
};

const View = observer((props: IViewProps) => {
  const { networks } = props;
  const { t } = useTranslation();
  const [state, handleState] = useState<IState>({
    networkDialogOpen: false,
    selected: undefined,
    usedVm: null,
  });
  const { query, queryStr } = useQuery();

  const onRemove = React.useCallback(
    (network: IConnectivityTypes.INetwork) => {
      UTILS.confirm({
        title: t('services.connectivity.network.confirm.remove.title'),
        content: (
          <Trans
            i18nKey="services.connectivity.network.confirm.remove.content"
            values={{ name: network.name }}
            components={{ 1: <strong /> }}
          />
        ),
        onSuccess: () =>
          networks.remove(network.id).then(() => networks.get(query)),
        onCancel: () => undefined,
      });
    },
    [queryStr]
  );

  const onEdit = React.useCallback((network: IConnectivityTypes.INetwork) => {
    handleState({ selected: network, networkDialogOpen: true });
  }, []);

  const onVmView = React.useCallback((network: IConnectivityTypes.INetwork) => {
    handleState({ usedVm: network.id });
  }, []);

  React.useEffect(() => {
    networks.get(query);
  }, [queryStr]);

  return (
    <ConnectivityMainWrapper className="p-30 full-width">
      <Row
        columnSpacing={2}
        alignItems="center"
        justifyContent="space-between"
        className="mb-30"
      >
        <Col>
          <div className="uppercase fs-14 bolder">
            {t('services.connectivity.networks.title')}
          </div>
        </Col>
        <Col>
          <Button
            size="small"
            onClick={() => handleState({ networkDialogOpen: true })}
          >
            {t('services.connectivity.networks.buttons.add')}
          </Button>
        </Col>
      </Row>

      <LinearTable
        data={networks.data || []}
        isLoading={!networks.dataReceived}
        columns={ENUMS.getNetworksColumns({ t, onRemove, onEdit, onVmView })}
        onUpdateTable={() => networks.get(query)}
      />
      <NetworkDetailsDialog
        open={state.networkDialogOpen}
        initialValues={state.selected}
        onClose={() =>
          handleState({ networkDialogOpen: false, selected: undefined })
        }
        onSave={() => {
          handleState({ networkDialogOpen: false, selected: undefined });
          return networks.get(query);
        }}
      />
      <ConnectedVmsDialog
        open={!!state.usedVm}
        netId={state.usedVm}
        onClose={() => handleState({ usedVm: null, selected: undefined })}
        onSave={() => undefined}
      />
    </ConnectivityMainWrapper>
  );
});

const Networks = () => <View {...OBSERVERS} />;

export default Networks;
