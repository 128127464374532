import * as React from 'react';
import { styled } from 'elements';
import { PALETTE } from 'enums';
import { important, remCalc } from 'utils';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import { EVAL_BAR_HEIGHT, HEADER_HEIGHT } from './constants';

const Bar = styled((props: AppBarProps & { showEvaluationBar: boolean }) => {
  const { showEvaluationBar, ...rest } = props;
  return <AppBar {...rest} />;
})(({ theme, showEvaluationBar }) => {
  return {
    backgroundColor: important(theme.palette.appBar.main),
    minWidth: remCalc(320),
    left: 0,
    top: showEvaluationBar ? important(remCalc(EVAL_BAR_HEIGHT)) : 0,
    '& ._appRow': {
      maxWidth: remCalc(1950),
      margin: 'auto',
    },
    '& ._toolbar': {
      width: '100%',
      justifyContent: 'space-between',
    },
    '& ._leftNav': {
      marginRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      lineHeight: remCalc(HEADER_HEIGHT),
      height: remCalc(HEADER_HEIGHT),
    },
    '& ._logo': {
      marginRight: remCalc(15),
      height: remCalc(HEADER_HEIGHT),
      display: 'flex',
      alignItems: 'center',
    },
    '& ._notifyIcon': {
      height: remCalc(HEADER_HEIGHT),
      width: remCalc(62),
      color: theme.palette.appBar.contrastText,
      borderRadius: important(0),
    },
    '& ._notifyBtnActive': {
      backgroundColor: 'white !important',
      color: theme.palette.appBar.contrastText,
    },
    '& ._menuIcon': {
      fontSize: remCalc(24),
      color: theme.palette.appBar.contrastText,
    },
    '& ._tasksOuter': {
      maxHeight: `calc(100vh - ${remCalc(175)})`,
      overflow: 'auto',
    },
    '& ._icon': {
      marginRight: remCalc(5),
      color: '#ADB3C2',
    },
    '& ._mobileMenu': {
      width: remCalc(320),
    },
    '& ._mobileHeader': {
      backgroundColor: theme.palette.appBar.main,
    },
    '& ._tasksWrapperContent': {
      height: `calc(100vh - ${remCalc(135)})`,
    },

    '& ._mobileProfileHolder': {
      borderBottom: `2px solid ${PALETTE.paleGrey}`,
    },
  };
});

export default Bar;
