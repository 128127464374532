import * as React from 'react';
import { Tooltip, MuiIcons, createColumnHelper } from 'elements';
import { GLOBAL_PER_PAGE } from 'enums';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  IDNSRecord,
  IDNSZoneRecordPayload,
  IDNSZoneRecordType,
  IDnsRecordTag,
} from 'dns';
import { AnyShape, ValueType } from 'global-shapes';
import { find, propEq } from 'ramda';
import { remCalc } from 'utils';

export const DEFAULT_RECORDS_QUERY = {
  page: 1,
  perPage: GLOBAL_PER_PAGE,
  orderBy: 'name,type,value',
  orderType: 'asc',
};

interface IColumnsProps {
  t: TFunction;
  isUsedByInternalService: boolean;
  isFetching: boolean;
  isEditable: boolean;
  onDelete: (record: IDNSRecord) => void;
  onEdit: (record: IDNSRecord) => (ev: AnyShape) => void;
}

const recordTypes: IDNSZoneRecordType[] = [
  'A',
  'AAAA',
  'CNAME',
  'TXT',
  'MX',
  'SRV',
  'CAA',
];

const recordTags: IDnsRecordTag[] = ['issue', 'issuewild', 'iodef'];

export const ttlTypeOptions: ValueType<number>[] = [
  {
    label: 'services.dns.domains.records.options.ttl.15min',
    value: 900,
  },
  {
    label: 'services.dns.domains.records.options.ttl.1h',
    value: 3600,
  },
  {
    label: 'services.dns.domains.records.options.ttl.4h',
    value: 14400,
  },
  {
    label: 'services.dns.domains.records.options.ttl.24h',
    value: 86400,
  },
];

export const recordTypesOptions = recordTypes.map((value) => ({
  label: value,
  value,
}));

export const recordTagsOptions: ValueType<IDnsRecordTag>[] = recordTags.map(
  (value) => ({
    label: value,
    value,
  })
);

export const INITIAL_RECORDS_FORM_VALUES: IDNSZoneRecordPayload = {
  name: '',
  ttl: ttlTypeOptions[2],
  priority: '',
  weight: '',
  port: '',
  type: recordTypesOptions[0],
  value: '',
  flag: '0',
  tag: recordTagsOptions[0],
};

const column = createColumnHelper<IDNSRecord>();

export const defineRecordsColumns = (props: IColumnsProps) => {
  const { t, isFetching, onDelete, onEdit, isEditable } = props;
  return [
    column.accessor('name', {
      header: (
        <div className="fs-12 steel">{t('table.head.zone') as string}</div>
      ),
      disableSort: true,
      maxWidth: 250,
      cell: ({ row: { original } }: ICell<IDNSRecord>) => {
        const nameLength = original.name.length;
        if (nameLength <= 18) {
          return <span className="ellipsis">{original.name}</span>;
        }
        return (
          <Tooltip title={original.name} placement="top">
            <span className="ellipsis">{original.name}</span>
          </Tooltip>
        );
      },
    }),
    column.accessor('type', {
      header: (
        <div className="fs-12 steel">{t('table.head.type') as string}</div>
      ),
      disableSort: true,
      cell: ({ row: { original } }: ICell<IDNSRecord>) => {
        return (
          <span>
            {original.type === 'MX'
              ? `${original.type} (${original.priority})`
              : original.type}
          </span>
        );
      },
    }),

    column.accessor('ttl', {
      header: (
        <div className="fs-12 steel">{t('table.head.ttl') as string}</div>
      ),
      disableSort: true,
      cell: function Cell({ row: { original } }: ICell<IDNSRecord>) {
        const { t } = useTranslation();
        const ttl = find(propEq('value', original.ttl))(
          ttlTypeOptions
        ) as ValueType<number>;
        return <span>{t(ttl?.label)}</span>;
      },
    }),
    column.accessor('value', {
      header: (
        <div className="fs-12 steel">{t('table.head.value') as string}</div>
      ),
      disableSort: true,
      width: 400,
      cell: ({ row: { original } }: ICell<IDNSRecord>) => {
        return (
          <span style={{ maxWidth: remCalc(500), wordBreak: 'break-word' }}>
            {original.value}
          </span>
        );
      },
    }),
    column.accessor('id', {
      disableSort: true,
      width: 70,
      cell: ({ row: { original } }: ICell<IDNSRecord>) => {
        return isEditable ? (
          <div
            className={cn('flex align-center justify-end full-width', {
              disabled: isFetching,
            })}
          >
            <MuiIcons.Edit
              className="fs-20 steel mr-15 pointer"
              onClick={onEdit(original)}
            />
            <MuiIcons.Delete
              className="fs-20 steel pointer"
              onClick={() => onDelete(original)}
            />
          </div>
        ) : (
          <span />
        );
      },
    }),
  ];
};

export const chipStatusMap = {
  PENDING: 'info',
  ACTIVE: 'success',
  CANCELED: 'error',
  ARCHIVED: 'default',
};
