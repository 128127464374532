import * as React from 'react';
import { noop } from 'utils';
import BasePricingView from './BasePricingViewWrapper';
import { groupPrices } from './helper';
import { withPermissions } from 'hocs';
import { useUserHash } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

const OBSERVERS = {
  tenantPricing: StateHandlers.tenantPricing,
  currentUser: StateHandlers.currentUser,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { tenantPricing, currentUser } = props;
  const [hash] = useUserHash();
  const partnerName = hash.split('/')[0];

  const onGoEditPrice = React.useCallback(() => {
    const newUrl = new URL(
      `${window.location.origin}/${partnerName}/tenant-pricing?tenant=${currentUser.data?.tenant?.id}`
    );
    window.location.href = newUrl.href;
  }, [partnerName, currentUser.data?.tenant?.id]);

  React.useEffect(() => {
    tenantPricing.get();
  }, []);

  return (
    <BasePricingView
      data={tenantPricing.data}
      defaultPricing={groupPrices(tenantPricing.data)}
      dataReceived={tenantPricing.dataReceived}
      isEditable={false}
      isOnUserPricing={false}
      disabled={tenantPricing.isRequesting}
      onUpdatePricing={console.log}
      onSetPrice={noop}
      onGoEditPrice={onGoEditPrice}
    />
  );
});

const TenantOwnPricing = () => <View {...OBSERVERS} />;

export const MyTenantPricingWithPermissions = withPermissions(
  TenantOwnPricing,
  'PRICING'
);
