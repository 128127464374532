import * as R from 'ramda';
import { showSystemMessage, isArray } from 'utils';
import { v4 as uuid } from 'uuid';
import { ICustomer } from 'customer-shapes';
import { tenantsService, patchingService, partnersService } from 'services';
import { parsePatchingToPayload } from 'pages/tenants/BulkScheduleUpdateDialog/helpers';
import { BaseMobxInstanceDecorator } from './utilities';

function bulkTenantPatchingParseToGroup(
  instances: IBulkPatchingTypes.IAllGroupedInstances[],
  instaceKey: 'rdsId' | 'ciId',
  operation: IBulkScheduleUpdateDialog.IOperation
): IPartialTenantWithInstanceMW[] {
  return instances.map((inst) => ({
    ...inst.tenant,
    uniqId: uuid(),
    id: inst.id,
    instanceId: inst.id,
    existingSchedule: inst.maintenanceWindows
      ? operation === 'rdsReboot'
        ? inst.maintenanceWindows
        : inst.maintenanceWindows[0]
      : undefined,
    instaceKey,
  }));
}

const parseBulkUpdateDryRunErrors =
  (instaceKey: 'rdsId' | 'ciId') => (err: { message: AnyShape[] }) => {
    const grouped = R.groupBy((e) => e[instaceKey], err.message);

    const instanceError = R.keys(grouped).map((instanceId) => ({
      instanceId,
      instaceKey,
      errors: grouped[instanceId].map((er) => er.errorMessage),
    }));

    return {
      isValid: false,
      hasInstanceErrors: !!R.keys(instanceError).length,
      instanceErrors: instanceError,
    };
  };

export const tenants = new BaseMobxInstanceDecorator<ICustomer, true>({
  instanceName: 'customers',
  requests: {
    get: tenantsService.getTenants,
    create: async (payload) => {
      const res = await tenantsService.create(payload);
      showSystemMessage('tenants.create.success', 'success');
      return res;
    },
    remove: async (id) => {
      const res = await tenantsService.deleteTenant(id as number);
      showSystemMessage('tenants.delete.success', 'success');
      return res;
    },
  },
  initialState: [],
});

export const tenantsPatchingsBulkInfo = new BaseMobxInstanceDecorator<
  IPartialTenantWithInstanceMW,
  true
>({
  instanceName: 'customers-bul-info',
  requests: {
    // @ts-ignore
    get: async (params: AnyShape) => {
      const { operation, partnerId, mw } = params;

      let patching: IBulkPatchingTypes.IAllGroupedInstances[] = [];
      let validateData: any = undefined;

      const isCore = operation === 'core';

      if (operation === 'core') {
        patching = await patchingService
          .getCorePatchings(partnerId)
          .then((res) => res.data)
          .catch(() => []);
      }

      if (operation === 'rdsPatching') {
        patching = await patchingService
          .getRdsPatchings(partnerId)
          .then((res) => res.data)
          .catch(() => []);
      }

      if (operation === 'rdsReboot') {
        patching = await patchingService
          .getRdsRebootList(partnerId)
          .then((res) => res.data)
          .catch(() => []);
      }

      const instanceKey = isCore ? 'ciId' : 'rdsId';

      let allTenantsPatching = bulkTenantPatchingParseToGroup(
        patching,
        instanceKey,
        operation
      );

      const payload = parsePatchingToPayload(operation, patching, mw);

      if (operation === 'core') {
        validateData = await patchingService
          .saveBulkCorePatchings(partnerId, { dryRun: true }, payload)
          .then((res) => res.data)
          .catch(parseBulkUpdateDryRunErrors(instanceKey));
      }

      if (operation === 'rdsPatching') {
        validateData = await patchingService
          .saveBulkRdsPatchings(partnerId, { dryRun: true }, payload)
          .then((res) => res.data)
          .catch(parseBulkUpdateDryRunErrors(instanceKey));
      }

      if (operation === 'rdsReboot') {
        validateData = await patchingService
          .saveBulkRdsRebootList(partnerId, { dryRun: true }, payload)
          .then((res) => res.data)
          .catch(parseBulkUpdateDryRunErrors(instanceKey));
      }

      allTenantsPatching = allTenantsPatching.map((p) => {
        return {
          ...p,
          conflicts: isArray(validateData)
            ? null
            : {
                hasInstanceErrors: validateData.hasInstanceErrors,
                // @ts-ignore
                instanceErrors: validateData?.instanceErrors.find(
                  (er: any) => +er.instanceId === p.instanceId
                ),
              },
        };
      });

      return { data: allTenantsPatching };
    },
    // @ts-ignore
    create: async (params: AnyShape) => {
      const { operation, partnerId, mw, data } = params;

      let validateData: any = undefined;

      const isCore = operation === 'core';

      const instanceKey = isCore ? 'ciId' : 'rdsId';

      const payload = parsePatchingToPayload(operation, data, mw);

      if (operation === 'core') {
        validateData = await patchingService
          .saveBulkCorePatchings(partnerId, { dryRun: false }, payload)
          .then((res) => res.data)
          .catch(parseBulkUpdateDryRunErrors(instanceKey));
      }

      if (operation === 'rdsPatching') {
        validateData = await patchingService
          .saveBulkRdsPatchings(partnerId, { dryRun: false }, payload)
          .then((res) => res.data)
          .catch(parseBulkUpdateDryRunErrors(instanceKey));
      }

      if (operation === 'rdsReboot') {
        validateData = await patchingService
          .saveBulkRdsRebootList(partnerId, { dryRun: false }, payload)
          .then((res) => res.data)
          .catch(parseBulkUpdateDryRunErrors(instanceKey));
      }

      const isValid = isArray(validateData);

      if (isValid) {
        showSystemMessage(
          `tenants.dialog.bulkSchedule.notify.${operation}.create.success`,
          'success'
        );
      }

      const allTenantsPatching = data.map((p: IPartialTenantWithInstanceMW) => {
        return {
          ...p,
          conflicts: isValid
            ? null
            : {
                hasInstanceErrors: validateData.hasInstanceErrors,
                // @ts-ignore
                instanceErrors: validateData?.instanceErrors.find(
                  (er: any) => +er.instanceId === p.instanceId
                ),
              },
        };
      });

      return { data: isValid ? null : allTenantsPatching };
    },
  },
  initialState: [],
});

export const currentTenant = new BaseMobxInstanceDecorator<ICustomer, false>({
  instanceName: 'selected-customer',
  requests: {
    get: ({ id, config, ...params }) =>
      tenantsService.getTenant(id, params, config),
    // @ts-ignore
    update: async (id, payload) => {
      const { config, ...data } = payload;
      const res = await tenantsService.updateTenantPartially(
        id as number,
        data,
        // @ts-ignore
        config
      );

      // @ts-ignore
      tenants.updateById(res.data.id, res.data);
      return res;
    },
  },
  initialState: {} as ICustomer,
});

export const tenantBillingRate = new BaseMobxInstanceDecorator<
  IDedicatedServer.BillingRate,
  false
>({
  instanceName: 'tenant-billing-rate',
  requests: {
    get: ({ id }) => partnersService.getTenantBillingRate(id),
    // @ts-ignore
    update: partnersService.updateTenantBillingRate,
  },
  initialState: { dedicatedServersBillingRate: 0 },
});

export const currentTenantBillingRate = new BaseMobxInstanceDecorator<
  IDedicatedServer.BillingRate,
  false
>({
  instanceName: 'current-tenant-billing-rate',
  requests: {
    get: ({ id }) => tenantsService.getCurrentTenantBillingRate(id),
    // @ts-ignore
    update: tenantsService.updateCurrentTenantBillingRate,
  },
  initialState: { dedicatedServersBillingRate: 0 },
});
