import * as R from 'ramda';
import { dayjs } from 'utils';
import { MAINTENANCE_OPTIONS } from './constants';

export const buildMaintenanceInitialState = (
  mw?: IMonitoring.MonitoringMW
): IMonitoring.MwFormValues => {
  const defaultStart = dayjs().add(1, 'days').add(1, 'hours').set('minutes', 0);
  const startAt = mw?.startAt ? dayjs(mw?.startAt) : defaultStart;

  const recurringInterval =
    R.find(R.propEq('value', mw?.recurringInterval), MAINTENANCE_OPTIONS) ||
    MAINTENANCE_OPTIONS[0];

  const endAt =
    mw?.startAt && mw?.durationMinutes
      ? startAt.add(mw.durationMinutes, 'minutes')
      : defaultStart.add(1, 'day');

  return {
    recurringInterval,
    startAt,
    endAt,
  };
};
