import { APP_SERVICES_GROUP } from './costResourceIds';

export const HIDE_QTY_RESOURCE_IDS = [
  APP_SERVICES_GROUP.VirtualServer.resources.BaseFee.id,
  APP_SERVICES_GROUP.Connectivity.resources.BaseFee.id,
  APP_SERVICES_GROUP.CoreInfrastructure.resources.BaseFee.id,
  APP_SERVICES_GROUP.RDS.resources.BaseFee.id,
  APP_SERVICES_GROUP.HostedExchange.resources.BaseFee.id,
  APP_SERVICES_GROUP.RDS.resources.LightUsers.id,
  APP_SERVICES_GROUP.RDS.resources.OfficeUsers.id,
  APP_SERVICES_GROUP.RDS.resources.PowerUsers.id,
  APP_SERVICES_GROUP.Monitoring.resources.MonitoringService.id,
];

export const HIDE_QTY_I18N_CODES = ['bi_discount10', 'bi_discount5'];

export const HIDE_ITEM_RES_VALUE_RESOURCE_IDS = [
  APP_SERVICES_GROUP.VirtualServer.resources.IPv6.id,
  APP_SERVICES_GROUP.VirtualServer.resources.IPv4.id,
];

export const HIDE_COST_LOG_RES_VALUE_RESOURCE_IDS = [
  APP_SERVICES_GROUP.RDS.resources.LightUsers.id,
  APP_SERVICES_GROUP.RDS.resources.OfficeUsers.id,
  APP_SERVICES_GROUP.RDS.resources.PowerUsers.id,
  APP_SERVICES_GROUP.VirtualServer.resources.IPv6.id,
  APP_SERVICES_GROUP.VirtualServer.resources.IPv4.id,
];

export enum InvoiceItemType {
  usage = 'USAGE',
  custom = 'CUSTOM',
  billableItem = 'BILLABLE_ITEM',
}

export enum InvoiceItemUnit {
  hours = 'HOURS',
  pcs = 'PCS',
}
