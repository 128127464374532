import * as React from 'react';
import { styled } from './utilities';
import { remCalc } from 'utils';

export const ColorDot = styled(
  React.forwardRef((props: IColorDotProps, ref) => {
    const { size, color, ...rest } = props;
    // @ts-ignore
    return <div {...rest} ref={ref} />;
  })
)(({ size, color }) => ({
  width: remCalc(size),
  minWidth: remCalc(size),
  height: remCalc(size),
  backgroundColor: color.includes('#') ? color : `#${color}`,
  borderRadius: '50%',
  overflow: 'hidden',
  display: 'block',
  margin: 0,
}));
