import { showSystemMessage } from 'utils';
import { billableItemsService } from 'services';
import { IBillableItem } from 'billable-items';
import { BaseMobxInstanceDecorator } from './utilities';

export const billableItems = new BaseMobxInstanceDecorator<IBillableItem, true>(
  {
    instanceName: 'billing-items',
    requests: {
      get: billableItemsService.get,
      create: async (payload) => {
        const res = await billableItemsService.create(payload);
        showSystemMessage('billableItems.notify.create.success', 'success');
        return res;
      },
      update: async (id, payload) => {
        const res = await billableItemsService.update(id as number, payload);
        showSystemMessage('billableItems.notify.update.success', 'success');
        return res;
      },
      remove: async (id) => {
        const res = await billableItemsService.remove(id as number);
        showSystemMessage('billableItems.notify.delete.success', 'success');
        return res;
      },
    },
    initialState: [],
  }
);
