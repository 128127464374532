import * as React from 'react';
import { usePermissions } from 'hooks';
import Info from './Info';
import Employees from './Employees';
import Notes from './Notes';

const Main = () => {
  const { isTenant } = usePermissions();
  return (
    <>
      <Info />
      {!isTenant && (
        <>
          <Employees />
          <Notes />
        </>
      )}
    </>
  );
};

export default Main;
