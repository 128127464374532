export const SocketEvents = {
    connected: 'connected',
    connect: 'connect',
    connection: 'connection',
    connectError: 'connect_error',
    disconnect: 'disconnect',
    disconnected: 'disconnected',
    message: 'message',
    taskUpdated: 'task_updated',
    taskProgressUpdated: 'task_progress_updated',
    vmUpdated: 'vm_state_updated',
    coreUpdated: 'core_infra_state_updated',
    rdsUpdated: 'rds_state_updated',
    sessionDeleted: 'user_session_deleted',
    sessionRefreshed: 'user_session_refreshed',
    stripeAccountConnected: 'stripe_connect_account_connected',
  };