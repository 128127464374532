import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  AccordionDetails,
  IconButton,
  MuiIcons,
  Menu,
  MenuItem,
  CopyTooltip,
} from 'elements';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { SortIcon } from 'components';
import { IEmployee } from 'employees';
import {
  AccordionRoot,
  SAccordionSummary,
  SExpandIcon,
  STableHeadsWrapper,
} from './Styled';

type IProps = IEmployee & {
  onEdit: (employee: IEmployee) => void;
  onDelete: (employee: IEmployee) => void;
  onExpand: (id: number) => void;
  expanded: boolean;
};

type ITableRowHeadProps = {
  query: IEmployeeTypes.IQuery;
  onChange: (q: Partial<IEmployeeTypes.IQuery>) => void;
};

export const EmployeeTableHeadRow = (props: ITableRowHeadProps) => {
  const { t } = useTranslation();
  return (
    <STableHeadsWrapper className="fs-12 mb-5 pt-10 pb-10">
      <Row columnSpacing={1} alignItems="center">
        <Col xs={7}>
          <Row columnSpacing={1} alignItems="center" className="steel">
            <Col xs={4} className="flex align-center">
              {t('forms.name')}
              <SortIcon
                size={17}
                propName="name"
                className="ml-5"
                query={props.query}
                onSort={(field: any, orderType: any) =>
                  props.onChange({ orderBy: field, orderType })
                }
              />
            </Col>
            <Col xs={3} className="flex align-center">
              {t('forms.type')}
              <SortIcon
                size={17}
                propName="type"
                className="ml-5"
                query={props.query}
                onSort={(field: any, orderType: any) =>
                  props.onChange({ orderBy: field, orderType })
                }
              />
            </Col>
            <Col xs={5} className="flex align-center">
              {t('forms.email')}
              <SortIcon
                size={17}
                propName="email"
                className="ml-5"
                query={props.query}
                onSort={(field: any, orderType: any) =>
                  props.onChange({ orderBy: field, orderType })
                }
              />
            </Col>
          </Row>
        </Col>
        <Col xs={5}>
          <Row columnSpacing={2} className="steel">
            <Col xs={5} className="flex align-center">
              {t('forms.phone')}
              <SortIcon
                size={17}
                propName="phone"
                className="ml-5"
                query={props.query}
                onSort={(field: any, orderType: any) =>
                  props.onChange({ orderBy: field, orderType })
                }
              />
            </Col>
            <Col xs={6}>{t('forms.comment')}</Col>
            <Col xs={1} />
          </Row>
        </Col>
      </Row>
    </STableHeadsWrapper>
  );
};

export const EmployeeTableItem = (props: IProps) => {
  const { onDelete, onEdit, onExpand, expanded, ...employee } = props;
  const { t } = useTranslation();
  return (
    <AccordionRoot
      elevation={0}
      expanded={expanded}
      onChange={() => onExpand(employee.id)}
      className={cn('mb-5')}
      TransitionProps={{ unmountOnExit: true }}
    >
      <SAccordionSummary
        aria-controls={`${employee.id}-content`}
        id={`${employee.id}-header`}
        className="m-0 fs-14"
        classes={{ content: 'm-0' }}
      >
        <SExpandIcon expanded={expanded} />
        <Row columnSpacing={2} alignItems="center">
          <Col xs={7}>
            <Row columnSpacing={2} alignItems="center">
              <Col xs={4} className="ellipsis">
                {employee.name}
              </Col>
              <Col xs={3} className="ellipsis">
                {employee.type}
              </Col>
              <Col xs={5} className="ellipsis">
                <CopyTooltip contentToCopy={employee.email}>
                  {employee.email || '-'}
                </CopyTooltip>
              </Col>
            </Row>
          </Col>
          <Col xs={5}>
            <Row columnSpacing={2} alignItems="center">
              <Col xs={5} className="ellipsis">
                <CopyTooltip
                  contentToCopy={employee.phone ? `+${employee.phone}` : '-'}
                >
                  {employee.phone ? `+${employee.phone}` : '-'}
                </CopyTooltip>
              </Col>
              <Col
                xs={6}
                className={cn('transitioned ellipsis', { faded: expanded })}
                style={{ maxWidth: '300px' }}
              >
                {employee.comment || '-'}
              </Col>
              <Col
                xs={1}
                onClick={(ev) => {
                  ev.stopPropagation();
                }}
              >
                <PopupState
                  variant="popover"
                  popupId={`employee-actions-${employee.id}`}
                >
                  {(popupState) => (
                    <>
                      <IconButton size="small" {...bindTrigger(popupState)}>
                        <MuiIcons.MoreVert />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={() => {
                            popupState.close();
                            onEdit(employee);
                          }}
                        >
                          {t('common.edit')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            popupState.close();
                            onDelete(employee);
                          }}
                        >
                          {t('common.delete')}
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Col>
            </Row>
          </Col>
        </Row>
      </SAccordionSummary>
      <AccordionDetails>
        <div className="steel fs-12 mb-5">{t('forms.comment')}</div>
        <div className="fs-14">
          {employee.comment
            ? employee.comment
                .split(/\r?\n/)
                .map((str, ind) => <div key={str + ind}>{str}</div>)
            : '-'}
        </div>
      </AccordionDetails>
    </AccordionRoot>
  );
};
