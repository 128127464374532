import * as React from 'react';
import cn from 'classnames';
import {
  usePermissions,
  useStateHandler,
  useAsync,
  useTask,
  useState,
} from 'hooks';
import { rdsService } from 'services';
import { Row, Col, MuiIcons, IconButton } from 'elements';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { withCoreActions, InjectedProps } from 'hocs/withCoreActions';
import StorageDialog from './StorageDialog';
import { DiskSizeHolder } from '../Styled';

type IProps = InjectedProps;

type IState = {
  profileDiskDialog: boolean;
};

const ProfileDisk = observer((props: IProps) => {
  const { currentSnapshot, isRequesting: isCoreRequesting } = props;
  const { t } = useTranslation();
  const { permissions } = usePermissions('SERVICES');
  const [state, handleState] = useState<IState>({ profileDiskDialog: false });
  const {
    isRequesting,
    data: rds,
    get: fetchRds,
  } = useStateHandler(StateHandlers.rds);
  const { isPending, execute } = useAsync(rdsService.updateProfileDisk);
  const task = useTask(rds?.task, { onComplete: fetchRds });

  const createdSnapshot = !currentSnapshot?.schedule
    ? currentSnapshot
    : undefined;

  const coreHasSnapshot = !!createdSnapshot;

  const handleProfileDiskDialog = React.useCallback(
    (open: boolean) => () => {
      handleState({ profileDiskDialog: open });
    },
    []
  );

  const onSave = React.useCallback(async (diskSizeGb: number) => {
    const res = await execute({ diskSizeGb: +diskSizeGb });
    await fetchRds();
    handleState({ profileDiskDialog: false });
    return res;
  }, []);

  return (
    <div className="mb-30 fs-14">
      <Row columnSpacing={2} alignItems="center">
        <Col>{t('services.rds.users.storage.label')}:</Col>
        <Col>
          <DiskSizeHolder>
            <span className="mr-5">{rds?.profileDisk?.sizeGb || 0} GB</span>
            {permissions.canManage && !coreHasSnapshot && (
              <IconButton
                size="small"
                onClick={handleProfileDiskDialog(true)}
                className={cn({
                  disabled:
                    isRequesting ||
                    isPending ||
                    task.isTaskActive ||
                    isCoreRequesting,
                })}
              >
                <MuiIcons.Edit className="fs-18" />
              </IconButton>
            )}
          </DiskSizeHolder>
        </Col>
      </Row>
      <div className="fs-12 steel">
        {t('services.rds.users.storage.description')}
      </div>
      <StorageDialog
        open={state.profileDiskDialog}
        onSave={({ storage }) => onSave(storage)}
        onClose={handleProfileDiskDialog(false)}
      />
    </div>
  );
});

export default withCoreActions(ProfileDisk);
