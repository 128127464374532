import { default as axios } from 'axios';
import { CustomEvents } from 'enums';
import { Session } from 'sessions';
import { Emitter } from '../Emitter';
import { fakeEmptyFetch } from '../fakeFetch';
import {
  checkIfSessionSoonExpire,
  getCurrentRefreshToken,
  getCurrentSession,
  getCurrentSessionId,
  getCurrentSessionInfo,
  getCurrentToken,
  getExpirationInfo,
  setSessionsData,
} from '../session';
import { logOut, logOutIfNoSession } from '../fetcher/logOut';
import { finUserContextOnTheFly } from '../fetcher/fetcherHelpers';
import { apiPrefix } from './../constants';
import { awesomeCloudSockets } from './sockets';
import { SocketEvents } from './constants';
import { initExpireSessionDialog } from '../expireSessionHandler';
import { ISessionRefreshedEventPayload, ISocketContextParams } from '../types';

async function refreshTokenRequest() {
  const newSession = await axios.post<Session>(`${apiPrefix}/auth/refresh`, {
    accessToken: getCurrentToken(),
    refreshToken: getCurrentRefreshToken(),
  });

  return newSession.data;
}

const refreshToken = async (): Promise<Session | void> => {
  try {
    await logOutIfNoSession();
    const newSession = await refreshTokenRequest();
    setSessionsData(newSession, true);
    initExpireSessionDialog();
    await reinitSocketsConnections();
    return newSession;
  } catch (er) {
    await logOut();
  }
};

export const updateSessionIfNeeded =
  (awesomeCloudSockets: any) => async (context?: ISocketContextParams) => {
    try {
      // await awesomeCloudSockets.disconnect();

      const { shouldUpdate } = await checkIfSessionSoonExpire(
        getCurrentSession()
      );

      if (shouldUpdate) {
        await refreshToken();
        Emitter.emit(CustomEvents.closeExpireSessionDialog, {});
      } else {
        const { data: newSession } = await getCurrentSessionInfo();
        setSessionsData(newSession, false);

        await fakeEmptyFetch({}, 10); // make sure you have time for session data update in storage

        await awesomeCloudSockets
          .init(newSession.accessToken, context)
          .catch(() => undefined);
        Emitter.emit(CustomEvents.initExpireSessionDialog, getExpirationInfo());
        Emitter.emit(CustomEvents.closeExpireSessionDialog, {});
      }

      return {};
    } catch (er) {
      await logOut();
    }
  };

export async function reinitSocketsConnections() {
  const userContext = await finUserContextOnTheFly();

  // awesomeCloudSockets.disconnect();

  if (userContext.userType !== 'none') {
    await awesomeCloudSockets
      .init(getCurrentToken(), userContext.user)
      .catch(console.log);
  }

  return undefined;
}

awesomeCloudSockets.socket?.on(
  SocketEvents.sessionRefreshed,
  (ev: ISessionRefreshedEventPayload) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`Event ${SocketEvents.sessionRefreshed} fired -> `, ev);
    }
    if (ev.sessionId === getCurrentSessionId()) {
      return updateSessionIfNeeded(awesomeCloudSockets.context);
    }
  }
);
