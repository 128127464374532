import * as React from 'react';
import cn from 'classnames';
import { MobileProgressStepper, MuiIcons } from 'elements';
import { withTranslation } from 'react-i18next';
import { useQuery } from 'hooks';
import { WithT } from 'i18next';
import { AnyFunc } from 'global-shapes';

type IProps = React.PropsWithChildren<
  WithT & {
    showStepper: boolean;
    activeStep: number;
    handleNext: AnyFunc;
  }
>;

const Renderer = (props: IProps) => {
  const { activeStep, handleNext, showStepper, t } = props;
  const { changeQuery } = useQuery();

  if (!showStepper) return null;

  return (
    <div>
      <MobileProgressStepper
        steps={3}
        activeStep={activeStep}
        className="mb-0"
        backButton={null}
        nextButton={null}
      />
      <div className="flex full-width justify-between">
        <div className="steel fs-12">
          {
            t('auth.registration.step', {
              step: activeStep,
              total: 2,
            }) as string
          }
        </div>
        <div className="flex">
          <MuiIcons.ChevronLeft
            className={cn('pointer mr-10 fs-16', {
              disabled: activeStep === 1,
            })}
            onClick={() => changeQuery({ step: 1 })}
          />
          <MuiIcons.ChevronRight
            className={cn('pointer fs-16', {
              disabled: activeStep === 2,
            })}
            onClick={handleNext}
          />
        </div>
      </div>
    </div>
  );
};

const FormStepper = withTranslation()(Renderer);

export default FormStepper;
