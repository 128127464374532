import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { AnyFunc } from 'global-shapes';
import { IAutomationTestProps } from 'automation-test';
import { CardPlaceholder, CardWrapper, CardToDeployContent } from './Styled';

type Props = {
  title: string;
  placeholder: string;
  onAdd?: AnyFunc;
  isDisabled?: boolean;
};

const WaitingToDeployCard = (props: Props & IAutomationTestProps) => {
  const { title, placeholder } = props;
  const { t } = useTranslation();
  return (
    <CardWrapper className={cn('fs-14')} data-draggable="ignore">
      <h5 className="mb-15">{t(title)}</h5>
      <CardPlaceholder className={cn('steel')}>
        {t(placeholder)}
      </CardPlaceholder>
      <CardToDeployContent>{t('common.comingSoon')}</CardToDeployContent>
    </CardWrapper>
  );
};

export default WaitingToDeployCard;
