import { DefaultListQuery } from 'global-shapes';
import { buildListParams, GET, POST, DELETE, PATCH, PUT, dayjs } from 'utils';
import * as R from 'ramda';
import * as Shapes from 'invoices';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';

const NO_TENANT_HEADERS_CONFIG = {
  headers: {
    'X-JBBF-TENANT-SHORTNAME': undefined,
  },
};

const customTypeFilter = (position: Shapes.IInvoicePosition) =>
  position.type === 'CUSTOM';

const nonCustomTypeFilter = (position: Shapes.IInvoicePosition) =>
  position.type !== 'CUSTOM';

const buildMainCustomPosition = (positions: Shapes.IInvoicePosition[]) => {
  if (!positions.length) return undefined;

  const subItems = positions.map((p) => ({
    ...p,
    subItems: undefined,
    isMain: false,
  }));

  const main = positions[0];
  const totalAmount = R.sum(positions.map((pos) => +pos.amount || 0));

  return {
    ...main,
    subItems,
    isMain: true,
    description: 'Other positions',
    amount: totalAmount,
  };
};

function parseInvoicePositionSubItems(
  items: Shapes.IInvoicePositionSubItemDeep[],
  ind: number
): Shapes.IInvoicePositionSubItem[] {
  return items.map((it, i) => {
    const isMain = !!it.subItems;
    const index = ind + `.${i + 1}`;
    return {
      ...it,
      isMain,
      index,
      subItems: it.subItems
        ? parseInvoicePositionSubItems(it.subItems, i)
        : undefined,
    };
  });
}

export const getInvoices = (
  params: DefaultListQuery
): IAxiosPromise<Shapes.IInvoice[]> => {
  const query = buildListParams(params, [
    'orderBy',
    'orderType',
    'q',
    'status',
  ]);
  return GET('/invoices', query);
};

export const getPartnerOwnInvoices = (
  params: DefaultListQuery
): IAxiosPromise<Shapes.IInvoice[]> => {
  const query = buildListParams(params, [
    'orderBy',
    'orderType',
    'q',
    'status',
  ]);
  return GET('/invoices/own', query);
};

export const getInvoiceById = (
  invoiceId: number
): AxiosPromise<Shapes.IInvoice> => GET(`/invoices/${invoiceId}`);

export const createInvoice = (
  payload: Shapes.IInvoiceCreatePayload
): AxiosPromise<Shapes.IInvoice> =>
  POST(`/invoices`, {}, payload, NO_TENANT_HEADERS_CONFIG);

export const removeInvoice = (id: number): AxiosPromise<Shapes.IInvoice> =>
  DELETE(`/invoices/${id}`, {}, undefined, NO_TENANT_HEADERS_CONFIG);

export const editInvoice = (
  id: number,
  payload: Shapes.IInvoiceCreatePayload
): AxiosPromise<Shapes.IInvoice> =>
  PATCH(`/invoices/${id}`, {}, payload, NO_TENANT_HEADERS_CONFIG);

export const getInvoicePositions = async (
  invoiceId: number
  // @ts-ignore
): Promise<IAxiosPromise<Shapes.IInvoicePosition[]>> => {
  try {
    const res = await GET(`/invoices/${invoiceId}/items`);
    const data = [...res.data];
    const onlySystemPositions = data.filter(nonCustomTypeFilter);
    const onlyCustomPositions = data.filter(customTypeFilter);
    const mainCustomPosition = buildMainCustomPosition(onlyCustomPositions);

    const allPositions = [...onlySystemPositions];

    if (mainCustomPosition) {
      allPositions.push(mainCustomPosition);
    }

    const mapped = allPositions.map(
      (p: Shapes.IInvoicePosition, ind: number) => {
        const isMain = !!p.subItems;
        const index = ind + 1;
        return {
          ...p,
          isMain,
          index,
          subItems: p.subItems
            ? parseInvoicePositionSubItems(p.subItems, index)
            : undefined,
        };
      }
    );

    res.data = mapped;

    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createInvoicePositions = (
  invoiceId: number,
  values: Shapes.IInvoicePositionFormValues
): AxiosPromise<Shapes.IInvoicePosition> =>
  POST(
    `/invoices/${invoiceId}/items`,
    {},
    {
      ...values,
      price: +values.price,
      qty: +values.qty,
      unit: values.unit?.value,
    },
    NO_TENANT_HEADERS_CONFIG
  );

export const updateInvoicePositions = (
  invoiceId: number,
  invoiceItemId: number,
  values: Shapes.IInvoicePositionFormValues
): AxiosPromise<Shapes.IInvoicePosition> =>
  PUT(
    `/invoices/${invoiceId}/items/${invoiceItemId}`,
    {},
    {
      ...values,
      price: +values.price,
      qty: +values.qty,
      unit: values.unit?.value,
    },
    NO_TENANT_HEADERS_CONFIG
  );

export const deleteInvoicePosition = (
  invoiceId: number,
  invoiceItemId: number
): AxiosPromise<Shapes.IInvoicePosition> =>
  DELETE(
    `/invoices/${invoiceId}/items/${invoiceItemId}`,
    {},
    undefined,
    NO_TENANT_HEADERS_CONFIG
  );

export const getInvoicePayments = (
  invoiceId: number
): IAxiosPromise<Shapes.IInvoicePayment[]> =>
  GET(`/invoices/${invoiceId}/payments`);

export const createInvoicePayments = (
  invoiceId: number,
  values: Shapes.IInvoicePaymentFormValues
): AxiosPromise<Shapes.IInvoicePayment> =>
  POST(
    `/invoices/${invoiceId}/payments`,
    {},
    {
      ...values,
      comment: values.comment?.trim() || undefined,
      amount: +values.amount,
      type: 'MANUAL',
    },
    NO_TENANT_HEADERS_CONFIG
  );

export const deleteInvoicePayment = (
  invoiceId: number,
  invoiceItemId: number
): AxiosPromise<Shapes.IInvoicePayment> =>
  DELETE(
    `/invoices/${invoiceId}/payments/${invoiceItemId}`,
    {},
    undefined,
    NO_TENANT_HEADERS_CONFIG
  );

export const cancelInvoice = (
  invoiceId: number
): IAxiosPromise<Shapes.IInvoice[]> =>
  PATCH(
    `/invoices/${invoiceId}/cancel`,
    {},
    undefined,
    NO_TENANT_HEADERS_CONFIG
  );

export const unCancelInvoice = (
  invoiceId: number
): IAxiosPromise<Shapes.IInvoice[]> =>
  PATCH(
    `/invoices/${invoiceId}/uncancel`,
    {},
    undefined,
    NO_TENANT_HEADERS_CONFIG
  );

export const validatePayments = (
  payments: Shapes.IImportedPaymentEntryToValidate[]
): IAxiosPromise<Shapes.IImportedPaymentEntryToValidateResponse[]> =>
  POST('/invoices/payments/resolve-import-data', {}, { payments });

export const importPayments = (
  payments: Shapes.IImportedPaymentEntryToValidate[]
): IAxiosPromise<Shapes.IImportedPaymentEntryToValidateResponse[]> =>
  POST('/invoices/payments/import', {}, { payments });

export const resendInvoice = (id: number): IAxiosPromise<Shapes.IInvoice> =>
  PATCH(`/invoices/${id}/send`, {}, undefined, NO_TENANT_HEADERS_CONFIG);

export const getStats = (): IAxiosPromise<Shapes.IInvoiceStats> =>
  GET(`/invoices/stats`, {
    dateFrom: dayjs('2000-01-01', 'YYYY-MM-DD')
      .startOf('day')
      .format('YYYY-MM-DD'),
    dateTo: dayjs().endOf('day').format('YYYY-MM-DD'),
  });

export const getIncomeStats = (): IAxiosPromise<Shapes.IInvoiceIncomeStats> =>
  GET(`/invoices/stats/income`, {
    dateFrom: dayjs('2000-01-01', 'YYYY-MM-DD')
      .startOf('day')
      .format('YYYY-MM-DD'),
    dateTo: dayjs().endOf('day').format('YYYY-MM-DD'),
  });

export const sendReminders = (
  invoiceId: number,
  reminder: Shapes.IInvoiceReminder
): AxiosPromise<Shapes.IInvoicePayment> =>
  PUT(
    `/invoices/${invoiceId}/send-remainder`,
    {},
    { invoiceReminder: reminder },
    NO_TENANT_HEADERS_CONFIG
  );
