import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const getCurrentDateRangeParams = () => ({
  periodStart: dayjs().utc().startOf('month').toISOString(),
  periodEnd: dayjs().utc().toISOString(),
});

export const getLastYearDateRangeParams = () => ({
  periodStart: dayjs()
    .utc()
    .subtract(11, 'month')
    .startOf('month')
    .toISOString(),
  periodEnd: dayjs().utc().toISOString(),
});
