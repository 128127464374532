import { CpuIcon, RamIcon, DiskSizeIcon } from './Icons';
import { ISysytemInfoOption } from './types';

export const SYSTEM_INFO: ISysytemInfoOption[] = [
    {
      key: 'virtualCpus',
      Icon: CpuIcon,
    },
    {
      key: 'ramMb',
      Icon: RamIcon,
    },
    {
      key: 'diskSizeGb',
      Icon: DiskSizeIcon,
    },
  ];