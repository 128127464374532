import { IChipStatus } from 'elements';

export const NETWORK_STATUS_MAP: Record<string, IChipStatus> = {
  PENDING: 'primary',
  CONFIGURING: 'primary',
  REALIZED: 'success',
  REALIZATION_FAILED: 'error',
};

export const STATIC_PUBLIC_SERVICES = [
  'DNS, 53',
  'FTP-Data, 20',
  'FTP-Control, 21',
  'FTPS-Data, 989',
  'FTPS-Control, 990',
  'HTTP, 80',
  'HTTPS, 443',
  'IMAP, 220',
  'IMAPS, 993',
  'NTP, 123',
  'POP3, 110',
  'POP3S, 995',
  'SMTP, 25',
  'SMTPS, 587',
  'SNMP, 161',
  'SSH, 22',
  'RDP, 3389',
].map((label) => ({
  label,
  value: label,
}));

export const ICMP_TYPE_NAME_OPTION = {
  value: 'Protocol',
  label: 'Protocol, 49573',
};

export const RESERVED_PROTOCOLS = [
  ...STATIC_PUBLIC_SERVICES,
  ICMP_TYPE_NAME_OPTION,
].map((option) => {
  const parsed = option.label.split(', ');
  return {
    label: parsed[0],
    value: +parsed[1],
  };
});
