import { GET, POST, PUT, DELETE, buildListParams } from 'utils';
import { DefaultListQuery } from 'global-shapes';
import { AxiosPromise } from 'axios';
import {
  INewBadgePayload,
  IBadgeAssignmentPayload,
  IBadgeBulkAssignmentPayload,
} from 'badges';
import { IAxiosPromise } from 'axios-shapes';

const REQUEST_CONFIG = { headers: { 'X-JBBF-TENANT-SHORTNAME': undefined } };

const parsePayload = (payload: INewBadgePayload): INewBadgePayload => ({
  color: payload.color.replace('#', ''),
  description: payload.description || undefined,
});

export const getList = (params: DefaultListQuery): IAxiosPromise<IBadge[]> =>
  GET(
    '/badges',
    buildListParams(params, ['q', 'orderType', 'orderBy']),
    REQUEST_CONFIG
  );

export const getById = (id: number): AxiosPromise<IBadge> =>
  GET(`/badges/${id}`, {}, REQUEST_CONFIG);

export const create = (payload: INewBadgePayload): AxiosPromise<IBadge> => {
  return POST('/badges', {}, parsePayload(payload), REQUEST_CONFIG);
};

export const update = (
  id: number,
  payload: INewBadgePayload
): AxiosPromise<IBadge> => {
  return PUT(`/badges/${id}`, {}, parsePayload(payload), REQUEST_CONFIG);
};

export const deleteBadge = (id: number): AxiosPromise =>
  DELETE(`/badges/${id}`, {}, {}, REQUEST_CONFIG);

export const getAssignmentList = (
  params: DefaultListQuery
): IAxiosPromise<IBadgeAssignment[]> =>
  GET(
    '/badges/assignments',
    buildListParams(params, [
      'q',
      'orderType',
      'orderBy',
      'appEntityIds',
      'badgeIds',
    ]),
    REQUEST_CONFIG
  );

export const getAssignmentById = (id: number): AxiosPromise<IBadgeAssignment> =>
  GET(`/badges/assignments/${id}`, {}, REQUEST_CONFIG);

export const createAssignment = (
  payload: IBadgeAssignmentPayload
): AxiosPromise<IBadgeAssignment> => {
  return POST('/badges/assignments', {}, payload, REQUEST_CONFIG);
};

export const createBulkAssignment = (
  id: number,
  payload: IBadgeBulkAssignmentPayload
): AxiosPromise<IBadgeAssignment> => {
  return PUT(`/app-entities/${id}/badges`, {}, payload, REQUEST_CONFIG);
};

export const deleteBadgeAssignment = (id: number): AxiosPromise =>
  DELETE(`/badges/assignments/${id}`, {}, {}, REQUEST_CONFIG);
