import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useQuery, useState } from 'hooks';
import { Input, Button, Paper, PaperProps, Alert, styled } from 'elements';
import { authService } from 'services';
import { remCalc } from 'utils';
import { ALL_TEST_IDS } from 'enums';
import logoSrc from 'assets/images/login-logo.svg';

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email('forms.emailInvalid')
    .required('forms.required'),
});

const Page = styled('div')(({ theme }) => ({
  background: 'linear-gradient(to bottom, #3e9bd5, #1d62ab)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
  padding: remCalc(15),
}));

const SPaper = styled((props: PaperProps & { isCompleted: boolean }) => (
  <Paper {...props} />
))(({ theme, isCompleted }) => {
  return isCompleted
    ? {
        width: '100%',
        maxWidth: remCalc(520),
        padding: remCalc(30, 35),
      }
    : {
        width: '100%',
        maxWidth: remCalc(495),
        padding: remCalc(25, 35),
        [theme.breakpoints.up('sm')]: {
          padding: remCalc(40, 50),
        },
      };
});

const Logo = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: remCalc(55),
  width: remCalc(220),
  transform: 'translateX(-50%)',
  '& img': {
    width: '100%',
  },
  '@media only screen and (max-height: 790px)': {
    display: 'none',
  },
}));

const initialValues = {
  email: '',
};

const ResetPassword = () => {
  const { changeQuery } = useQuery();
  const { t } = useTranslation();
  const [state, handleStateChange] = useState({
    error: null,
    isPending: false,
    isCompleted: false,
  });
  const {
    values,
    errors,
    handleChange,
    submitCount,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (val) => {
      handleStateChange({ error: null, isPending: true, isCompleted: false });
      return authService
        .restorePassword(val.email)
        .then((res) => {
          handleStateChange({
            error: null,
            isPending: false,
            isCompleted: true,
          });
        })
        .catch((err) =>
          handleStateChange({ error: err.message, isPending: false })
        );
    },
  });

  return (
    <Page data-test-id={ALL_TEST_IDS.requestResetPassword.wrapper}>
      <SPaper isCompleted={state.isCompleted}>
        {state.isCompleted ? (
          <>
            <h3 className="mb-20">{t('common.success')}</h3>
            <p className="primary-dark mb-50">
              {t('auth.resetPassword.passwordSent')}
            </p>
            <div className="flex justify-end">
              <Button onClick={() => changeQuery({}, '/login', true)}>
                {t('common.ok')}
              </Button>
            </div>
          </>
        ) : (
          <>
            <h2 className="mb-15 text-center">
              {t('auth.resetPassword.title')}
            </h2>
            <div className="mb-30 text-center fs-20">
              {t('auth.resetPassword.enterEmail')}
            </div>
            {state.error && (
              <Alert severity="error" className="mb-15">
                {state.error}
              </Alert>
            )}
            <form
              onSubmit={handleSubmit}
              data-test-id={ALL_TEST_IDS.requestResetPassword.form}
            >
              <Input
                id="email"
                name="email"
                label={t('forms.email')}
                placeholder={t('forms.placeholders.email')}
                error={!!submitCount && !!errors.email}
                helperText={errors.email}
                className="mb-30"
                onChange={handleChange}
                value={values.email}
                testId={ALL_TEST_IDS.requestResetPassword.inputs}
              />
              <Button
                type="submit"
                fullWidth
                size="large"
                className="mb-20"
                disabled={isSubmitting}
                testId={ALL_TEST_IDS.requestResetPassword.submit}
              >
                {t('common.continue')}
              </Button>
            </form>
          </>
        )}
      </SPaper>
      <Logo>
        <img src={logoSrc} alt="" />
      </Logo>
    </Page>
  );
};

export default ResetPassword;
