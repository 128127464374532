import { reduce } from 'ramda';
import { IPrice } from 'pricing';
import { round } from 'utils';
import * as StateHandlers from 'states';

export interface IPriceShape {
  daily: number;
  monthly: number;
  serviceId: number;
  serviceName: string;
  resourceName: string;
  resourceId: number;
  spId?: number;
}

export type IUsePriceResponse = { [key: number]: IPriceShape };
export type IOptions = {
  reducer?: 'tenantPricing' | 'defaultPricing' | 'main';
};

const mapStateHandlers = {
  main: StateHandlers.mainPricing,
  tenantPricing: StateHandlers.tenantPricing,
  defaultPricing: StateHandlers.defaultPricing,
};

const mapPriceValues = reduce((res: IUsePriceResponse, price: IPrice) => {
  res[price.serviceResourceId] = {
    daily: round(+price.value, 2) || 0,
    monthly: round(+price.value * 30, 2) || 0,
    serviceId: price.serviceId,
    serviceName: price.service.serviceTitle,
    resourceName: price.serviceResource.resourceTitle,
    resourceId: price.serviceResourceId,
  };
  return res;
}, {});

const mapSpPriceValues = reduce((res: IUsePriceResponse, price: IPrice) => {
  if (price.spId) {
    res[price.spId] = {
      daily: round(+price.value, 2) || 0,
      monthly: round(+price.value * 30, 2) || 0,
      serviceId: price.serviceId,
      serviceName: price.service.serviceTitle,
      resourceName: price.serviceResource.resourceTitle,
      resourceId: price.serviceResourceId,
      spId: price.spId,
    };
  }

  return res;
}, {});

export function usePrice(
  priceIds?: number | number[],
  options?: IOptions
): IUsePriceResponse {
  const reducer = options?.reducer || 'tenantPricing';
  const basePricing = mapStateHandlers[reducer];

  const allPricing = mapPriceValues(basePricing.data);

  const pricing: IUsePriceResponse = {};

  if (!priceIds) return allPricing;

  if (typeof priceIds === 'number') {
    pricing[priceIds] = allPricing[priceIds];
  }

  if (typeof priceIds !== 'number') {
    priceIds.forEach((p) => {
      pricing[p] = allPricing[p];
    });
  }

  return pricing;
}

export function useSpPrice(
  priceIds?: number | number[],
  options?: IOptions
): IUsePriceResponse {
  const reducer = options?.reducer || 'tenantPricing';
  const basePricing = mapStateHandlers[reducer];

  const allPricing = mapSpPriceValues(basePricing.data);

  const pricing: IUsePriceResponse = {};

  if (!priceIds) return allPricing;

  if (typeof priceIds === 'number') {
    pricing[priceIds] = allPricing[priceIds];
  }

  if (typeof priceIds !== 'number') {
    priceIds.forEach((p) => {
      pricing[p] = allPricing[p];
    });
  }

  return pricing;
}
