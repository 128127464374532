import * as React from 'react';
import cn from 'classnames';
import { VerticalBadges } from 'components';
import {
  numberToCurrency,
  openUserInPortal,
  dayjs,
  getBadgeStateKey,
  validator,
  remCalc,
} from 'utils';
import {
  Row,
  Col,
  DotsProgressSmall,
  OpenUserIcon,
  createColumnHelper,
} from 'elements';
import * as Yup from 'yup';
import { useTask, useAuth } from 'hooks';
import { ILocalTypes } from './types';
import TableActions from './TableActions';

export const DEFAULT_PARAMS = {
  page: 1,
  perPage: 20,
  status: 'ALL',
  orderType: 'desc',
};

const column = createColumnHelper<IPartnerTypes.Partner>();

export const getColumns = (props: ILocalTypes.IPartnerTableCommonProps) => {
  const {
    switchUser,
    openUserInNewTab,
    t,
    ableToAction,
    goToPartnerPricing,
    pricingPermissions,
    onExpandExpiration,
    onSwitchToProd,
    onDelete,
    onUndoDeletion,
    onManageQuotas,
    onManageBilling,
    assignments,
    onSwitchToEvaluation,
  } = props;

  return [
    column.accessor('name', {
      header: () => (
        <div className="ml-35">{t('partners.table.head.companyName')}</div>
      ),
      size: 300,
      maxWidth: remCalc(300),
      cell: function Cell(props: any) {
        const {
          row: { original: partner },
        } = props;
        const task = useTask(partner.task);
        const { setChildSessionInfo } = useAuth();
        const inProgress = task.isTaskActive || !!partner.deletedAt;
        const canLogUnderUser = !inProgress;
        const entityAssignments = assignments[getBadgeStateKey(partner)] || [];

        return (
          <Row alignItems="center" columnSpacing={2}>
            <VerticalBadges size={8} assignments={entityAssignments} />
            <Col
              className={cn('mr-15 pointer link-hover')}
              onClick={
                canLogUnderUser
                  ? openUserInPortal(() => {
                      setChildSessionInfo({ psn: partner.shortName }, true);
                      switchUser(partner);
                    })
                  : undefined
              }
            >
              {partner.name}
            </Col>
            {inProgress && (
              <Col>
                <DotsProgressSmall className="mr-10" size={20} />
              </Col>
            )}
          </Row>
        );
      },
    }),

    column.accessor('id', {
      header: '',
      size: 50,
      maxWidth: remCalc(50),
      disableSort: true,
      cell: function Cell({ row: { original: partner } }: any) {
        const task = useTask(partner.task);
        const inProgress = task.isTaskActive || !!partner.deletedAt;
        const canLogUnderUser = !inProgress;

        return canLogUnderUser ? (
          <OpenUserIcon
            onClick={openUserInPortal(() => {
              openUserInNewTab(partner);
            })}
          />
        ) : (
          <>{''}</>
        );
      },
    }),

    column.accessor('shortName', {
      header: t('partners.table.head.shortName'),
      disableSort: true,
      size: 250,
      maxWidth: remCalc(250),
      cell: (pr: any) => pr.renderValue(),
    }),

    column.accessor('tenantsQuantity', {
      header: t('partners.table.head.tenants'),
      accessor: 'tenantsQuantity',
      alignment: 'right',
      disableSort: true,
      size: 100,
      maxWidth: remCalc(100),
      cell: (pr: any) => pr.renderValue(),
    }),

    column.accessor('servicesQuantity', {
      header: t('partners.table.head.services'),
      accessor: 'tenantsQuantity',
      alignment: 'right',
      disableSort: true,
      size: 100,
      maxWidth: remCalc(100),
      cell: (pr: any) => pr.renderValue(),
    }),

    column.accessor('monthlyTurnover', {
      header: () => (
        <div style={{ width: `calc(100% - ${remCalc(31)})` }}>
          {t('partners.table.head.turnover')}
        </div>
      ),
      alignment: 'right',
      size: 250,
      maxWidth: remCalc(250),
      cell: ({ row: { original: partner } }: any) => (
        <div>{numberToCurrency(+(partner.monthlyTurnover || 0), false)}</div>
      ),
    }),

    column.accessor('createdAt', {
      header: t('partners.table.head.createdAt'),
      size: 120,
      cell: ({ row: { original: partner } }: any) => (
        <>{dayjs(partner.createdAt).format('DD.MM.YYYY')}</>
      ),
    }),

    column.accessor('invoiceDueDateDays', {
      header: '',
      disableSort: true,
      size: 'auto',
      cell: ({ row: { original: partner } }: any) => (
        <TableActions
          partner={partner}
          {...{
            switchUser,
            t,
            ableToAction,
            goToPartnerPricing,
            pricingPermissions,
            onExpandExpiration,
            onSwitchToProd,
            onDelete,
            onUndoDeletion,
            onManageQuotas,
            onManageBilling,
            onSwitchToEvaluation,
          }}
        />
      ),
    }),
  ];
};

interface FilterOption {
  value: IPartnerTypes.PartnerStatus | 'ALL';
  label: string;
}

export const ACCOUNT_FILTER_OPTIONS: FilterOption[] = [
  {
    value: 'ALL',
    label: 'All accounts',
  },
  {
    value: 'PRODUCTION',
    label: 'Production',
  },
  {
    value: 'EVALUATION',
    label: 'Evaluation account',
  },
  {
    value: 'EVALUATION_EXPIRED',
    label: 'Account expired',
  },
];

export const companyInfoSchema = Yup.object({
  shortName: Yup.string()
    .trim()
    .min(3, 'forms.invalid.min')
    .max(16, 'forms.invalid.max')
    .required('forms.required')
    .test('shortName', 'forms.invalid.onlyLettersAndNums', (val) =>
      validator.onlyLettersAndNum(val || '')
    ),
  companyName: Yup.string()
    .trim()
    .max(30, 'forms.invalid.max')
    .required('forms.required'),
  line1: Yup.string()
    .trim()
    .required('forms.required')
    .max(60, 'forms.invalid.max'),
  line2: Yup.string().trim().max(60, 'forms.invalid.max'),
  zipCode: Yup.string()
    .trim()
    .required('forms.required')
    .max(30, 'forms.invalid.max'),
  city: Yup.string()
    .trim()
    .required('forms.required')
    .max(60, 'forms.invalid.max'),
  countryCode: Yup.object().required('forms.required'),
  isEvaluationAccount: Yup.boolean().required('forms.required'),
});

export const userSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .required('forms.required')
    .max(30, 'forms.invalid.max')
    .test('firstName', 'forms.invalid', (val) => validator.name(val || '')),
  lastName: Yup.string()
    .trim()
    .required('forms.required')
    .max(30, 'forms.invalid.max')
    .test('lastName', 'forms.invalid', (val) => validator.name(val || '')),
  email: Yup.string().email('forms.emailInvalid').required('forms.required'),
  lang: Yup.object().required('forms.required'),
});

export const INITIAL_EXTEND_EVAL_VALUES = {
  date: dayjs(),
};

export const quotasValidationSchema = Yup.object({
  cpu: Yup.number()
    .max(999999, 'forms.invalid.max')
    .min(1, 'forms.invalid.min')
    .required('forms.required'),
  ram: Yup.number()
    .max(999999, 'forms.invalid.max')
    .min(1, 'forms.invalid.min')
    .required('forms.required'),
  diskSize: Yup.number()
    .max(9999999, 'forms.invalid.max')
    .min(1, 'forms.invalid.min')
    .required('forms.required'),
  maxTenants: Yup.number()
    .max(9999, 'forms.invalid.max')
    .min(1, 'forms.invalid.min')
    .required('forms.required'),
});

export const extendEvalValidationSchema = Yup.object({
  date: Yup.date()
    .required('forms.required')
    .test(
      'date',
      'forms.invalid.date',
      (val) => +dayjs().endOf('day') < +dayjs(val)
    ),
});
