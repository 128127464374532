import React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import cn from 'classnames';
import AlertTitle from '@mui/material/AlertTitle';
import { TestSuffixes } from 'enums';
import { useTranslation } from 'react-i18next';
import { AnyShape } from 'global-shapes';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import Info from '@mui/icons-material/Info';
import ReportProblem from '@mui/icons-material/ReportProblem';
import { DotsProgressSmall } from './Loader';

interface Props extends AlertProps {
  testId?: string;
  contentClassName?: string;
}

export const iconMapping: AnyShape = {
  success: <CheckCircle className="fs-20" />,
  error: <Cancel className="fs-20" />,
  info: <Info className="fs-20" />,
  default: null,
  warning: <ReportProblem className="fs-20" />,
  processing: <DotsProgressSmall size={20} />,
};

export const titleMapping: any = {
  error: 'common.error',
  success: 'common.success',
  warning: 'common.warning',
  processing: 'common.processing',
};

const Alert = (props: Props) => {
  const { contentClassName, ...rest } = props;
  const { t } = useTranslation();
  return (
    <MuiAlert
      {...rest}
      className={cn('fs-14 steel', props.className)}
      iconMapping={iconMapping}
      classes={{ message: contentClassName }}
      data-test-id={cn({
        [`${props.testId}-${TestSuffixes.alert}`]: !!props.testId,
      })}
    >
      {props.severity && titleMapping[props.severity] && (
        <AlertTitle className={cn('fs-16')}>
          {t(titleMapping[props.severity])}
        </AlertTitle>
      )}
      {props.children}
    </MuiAlert>
  );
};

export { Alert };
