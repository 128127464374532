import * as React from 'react';
import { remCalc } from 'utils';
import Chart from './BundledChartComponent';
import { useTranslation } from 'react-i18next';
import { parseDataToSeries, parseChartMetricToFormat } from '../helpers';
import { IApexChartProps, IPartialChartConfig } from '../types';

const STYLES = {
  chart: {
    height: remCalc(252),
  },
};

export const ChartItem = (props: IApexChartProps & { error?: string }) => {
  const { t } = useTranslation();
  const {
    error,
    chartConfig,
    data,
    name,
    id,
    limit,
    measureUnit,
    isWideDateRange,
    metricKey,
  } = props;

  if (error) {
    return <div className="fs-12 full-width text-center">{error}</div>;
  }

  if (!data.length) {
    return (
      <div className="fs-12 full-width text-center">{t('common.noData')}</div>
    );
  }

  return (
    <ChartWrapper
      chartConfig={chartConfig}
      name={name}
      data={parseChartMetricToFormat(
        data,
        measureUnit,
        chartConfig?.dataFormarter
      )}
      limit={limit}
      id={id}
      metricKey={metricKey}
      isWideDateRange={isWideDateRange}
      measureUnit={measureUnit}
    />
  );
};

const ChartWrapper = (props: IApexChartProps) => {
  const {
    name,
    data,
    chartConfig,
    limit,
    measureUnit,
    metricKey,
    isWideDateRange,
  } = props;
  const { t } = useTranslation();

  const option = React.useMemo(() => {
    return parseDataToSeries(
      data,
      !!isWideDateRange,
      metricKey,
      limit,
      t,
      chartConfig as IPartialChartConfig
    );
  }, [
    JSON.stringify(data),
    JSON.stringify(limit),
    JSON.stringify(chartConfig),
    metricKey,
    isWideDateRange,
    measureUnit,
    name,
  ]);
  return <Chart className="full-width" option={option} style={STYLES.chart} />;
};
