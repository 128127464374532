import React, { useCallback } from 'react';
import { ALL_TEST_IDS, CustomEvents } from 'enums';
import { IAddServiceCallback } from 'services-shapes';
import { useTask, useUserHash, usePermissions } from 'hooks';
import { IVmActionNamespaces } from 'services-types';
import { Emitter, getBadgeStateKey } from 'utils';
import { Observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { AnyFunc } from 'global-shapes';
import ConnectivityCard from '../contents/Connectivity';
import { SConnectivityCard } from './Styled';

export const onMenuClick =
  (onOpenDetails: AnyFunc) => (action: IVmActionNamespaces) => {
    switch (action) {
      case 'openDetails':
        return onOpenDetails();
      default:
    }
  };

type Props = {
  onAdd: IAddServiceCallback;
};

const ConnectivityCardWrapper = (props: Props) => {
  const connectivity = StateHandlers.connectivity;
  const { permissions } = usePermissions('SERVICES');
  const [, changeUrl] = useUserHash();
  const assignments = StateHandlers.badgesAssignment;

  const entityAssignments =
    assignments.data[getBadgeStateKey(connectivity.data)] || [];

  const fetchConnectivity = useCallback(() => connectivity.get(), []);

  const connectivityData = connectivity.data;

  const hasConnectivity = connectivity.dataReceived && !!connectivityData;

  const task = useTask(hasConnectivity ? connectivityData.task || null : null, {
    onComplete: fetchConnectivity,
  });

  const onOpenDetails = React.useCallback(
    () => changeUrl('/services/connectivity/edges'),
    []
  );

  const menuItems = React.useMemo(() => {
    return [
      { label: 'openDetails', value: 'openDetails', onClick: onOpenDetails },
      {
        label: 'assignBadges',
        value: 'assignBadges',
        onClick: () =>
          Emitter.emit(CustomEvents.assignDialog, connectivity.data),
      },
    ];
  }, []);

  return (
    <Observer>
      {() => (
        <SConnectivityCard
          onAdd={() => permissions.canManage && props.onAdd('connectivity')}
          placeholder="services.content.placeholder.connectivity"
          isEmpty={!hasConnectivity}
          isDisabled={task.isTaskActive}
          title="services.card.connectivity"
          hasError={task.isFailed}
          onTitleClick={onOpenDetails}
          menuItems={menuItems}
          testId={ALL_TEST_IDS.services.connectivity.main}
          status={connectivityData && connectivityData.setupState}
          dataReceived={connectivity.dataReceived}
          createId="create-connectivity-button"
          assignments={entityAssignments}
        >
          <ConnectivityCard {...connectivityData} />
        </SConnectivityCard>
      )}
    </Observer>
  );
};

export default ConnectivityCardWrapper;
