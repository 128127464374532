import * as React from 'react';
import { ValuesType } from 'utility-types';

const CHANGE_STATE = 'CHANGE_STATE';

interface Action {
  type: string;
  key?: string;
  payload: any;
}

const changeAction = (payload: any, key?: string): Action => ({
  type: CHANGE_STATE,
  key,
  payload,
});

export function stateReducer(state: any, { type, payload, key }: Action) {
  switch (type) {
    case CHANGE_STATE:
      return payload === null
        ? null
        : {
            ...state,
            ...(key ? { [key]: payload } : payload),
          };
    default:
      return state;
  }
}

export type IReducerChangeHandler<P = Record<string, any>> = (
  // @ts-ignore
  payload: Partial<P> | ValuesType<P>,
  key?: string
) => void;

export function handleReducerChange<P>(
  dispatch: React.Dispatch<Action>
): IReducerChangeHandler<P> {
  return (payload, key) => dispatch(changeAction(payload, key));
}
