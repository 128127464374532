import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForceUpdate } from 'hooks';
import { debounce } from 'utils';
import { Row, Col, Input } from 'elements';
import { ALL_TEST_IDS, DEFAULT_PARTNERS_QUERY } from 'enums';
import { BILLING_OPTIONS } from './constants';
import { BillingSelect, ClearButton } from './Styled';

const Filters = (props: ITenantsTypes.IFilterProps) => {
  const { query, onChange } = props;
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const searchRef = React.useRef({ value: query.q || '' });

  React.useEffect(() => {
    searchRef.current.value = query.q || '';
    forceUpdate();
  }, [query.q]);

  const changeSearchUrl = React.useCallback(
    (q: string) => onChange({ ...query, q, page: 1 }),
    [query]
  );

  const onItemSearch = React.useCallback(
    debounce(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        changeSearchUrl(ev.target.value),
      1000
    ),
    [query]
  );

  return (
    <Row columnSpacing={2} rowSpacing={2} className="mb-30">
      <Col xs={8}>
        <Input
          ref={searchRef}
          type="search"
          placeholder={t('tenants.filters.input.placeholder')}
          className="bg-white"
          onKeyUp={(e: any) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              changeSearchUrl(e.target.value);
            }
          }}
          onClear={() => {
            searchRef.current.value = '';
            changeSearchUrl('');
          }}
          onChange={onItemSearch}
        />
      </Col>
      <Col xs={2}>
        <BillingSelect
          options={BILLING_OPTIONS}
          placeholder={t('tenants.options.all')}
          value={query.enabledBilling}
          onChange={(val: any) => {
            onChange({ enabledBilling: val.value });
          }}
          testId={ALL_TEST_IDS.partners.selectAccountType}
        />
      </Col>
      <Col xs>
        <ClearButton
          className="full-width"
          variant="text"
          color="primary"
          onClick={() => {
            // @ts-ignore
            onChange({
              ...DEFAULT_PARTNERS_QUERY,
              enabledBilling: 'true',
              q: '',
            });
          }}
        >
          {t('tenants.filters.buttons.clearFilters')}
        </ClearButton>
      </Col>
    </Row>
  );
};

export default Filters;
