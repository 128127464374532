import { checkIfTokenExpired } from '../session';
import { refreshToken } from './refreshToken';

export const openUserInPortal = (signIn: () => void) => async () => {
  const isExpired = await checkIfTokenExpired();
  if (isExpired) {
    await refreshToken();
  }
  return signIn();
};
