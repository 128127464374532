import { styled } from './utilities';
import { NavLink as RNavLink } from 'react-router-dom';

export const NavLink = styled(RNavLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.dark,
  },
  '&:active': {
    color: theme.palette.primary.dark,
  }
}));
