import { GET, buildListParams } from 'utils';
import { IAxiosPromise } from 'axios-shapes';

export const getVcds = (query: AnyShape): IAxiosPromise<IVmVcdTypes.Vcd[]> =>
  GET(
    '/vm-providers/vdc',
    buildListParams(query, ['orderBy', 'orderType', 'q', 'partnerId'])
  );

export const getVcdVms = (query: AnyShape): IAxiosPromise<IVmVcdTypes.Vm[]> =>
  GET(
    '/vm-providers/vm',
    buildListParams(query, ['orderBy', 'orderType', 'q', 'vdcId', 'orgId'])
  );

export const getVmSpec = (query: AnyShape): IAxiosPromise<IVmVcdTypes.VmSpec> =>
  GET(`/vm-providers/vm/${query.vmId}/spec`);
