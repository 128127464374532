import * as React from 'react';
import { RebootList } from 'components';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

const OBSERVERS = {
  rds: StateHandlers.rds,
  rdsReboots: StateHandlers.rdsReboots,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { rds, rdsReboots } = props;

  return (
    <RebootList
      appEntityId={rds.data?.appEntityId}
      instance={rdsReboots}
      baseService={rds}
    />
  );
});

const Reboots = () => <View {...OBSERVERS} />;

export default Reboots;
