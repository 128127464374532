import { providerHelpersService } from 'services';

type IReportType =
  | 'failed-core'
  | 'failed-rds'
  | 'users-app-stats'
  | 'customers-address-info'
  | 'customers-with-ms-license';

const generateCsv = (reportType: IReportType) =>
  function (data: any) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `${reportType}-report.csv`);
    a.click();
  };

export const fetchCsv = (reportType: IReportType) => {
  if (reportType === 'failed-core') {
    return () =>
      providerHelpersService.getFailedCoreList().then(generateCsv(reportType));
  }
  if (reportType === 'failed-rds') {
    return () =>
      providerHelpersService.getFailedRdsList().then(generateCsv(reportType));
  }
  if (reportType === 'customers-with-ms-license') {
    return () =>
      providerHelpersService
        .getCustomersWithMSLicense()
        .then(generateCsv(reportType));
  }
  if (reportType === 'users-app-stats') {
    return () =>
      providerHelpersService
        .getPackagesUserStats()
        .then(generateCsv(reportType));
  }
  if (reportType === 'customers-address-info') {
    return () =>
      providerHelpersService.getCustomersInfo().then(generateCsv(reportType));
  }
};
