import React from 'react';
import { useQuery } from 'hooks';
import { authService } from 'services';
import { Paper, styled } from 'elements';
import { insertXCustomLang, remCalc } from 'utils';
import logoSrc from 'assets/images/login-logo.svg';
import { useTranslation } from 'react-i18next';

const Page = styled('div')(({ theme }) => ({
  background: 'linear-gradient(to bottom, #3e9bd5, #1d62ab)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
  padding: remCalc(15),
}));

const SPaper = styled(Paper)({
  textAlign: 'center',
  width: '100%',
  maxWidth: remCalc(495),
  padding: remCalc(85, 50),
  '& h3': {
    marginBottom: remCalc(15),
  },
  '& div': {
    fontSize: remCalc(20),
  },
});

const Logo = styled('div')({
  position: 'absolute',
  left: '50%',
  bottom: remCalc(55),
  width: remCalc(220),
  transform: 'translateX(-50%)',
  '& img': {
    width: '100%',
  },
  '@media only screen and (max-height: 790px)': {
    display: 'none',
  },
});

const AccountVerify = () => {
  const [error, handleError] = React.useState(false);
  const { query, changeQuery } = useQuery();
  const { t } = useTranslation();

  React.useEffect(() => {
    authService
      .verifyAccount(query.token, insertXCustomLang(query.lang))
      .then((res) =>
        changeQuery(
          { ...query, token: res.data.token },
          '/create-password',
          true
        )
      )
      .catch(() => handleError(true));
  }, []); // eslint-disable-line

  return (
    <Page>
      {error && (
        <SPaper>
          <h3>{t('app.alerts.error')}</h3>
          <div>{t('auth.verifying.expiredLink')}</div>
        </SPaper>
      )}
      <Logo>
        <img src={logoSrc} alt="" />
      </Logo>
    </Page>
  );
};

export default AccountVerify;
