import * as Yup from 'yup';
import { validator } from 'utils';

export const NAV_PARAMS = {
  'app-store': {
    page: 1,
    perPage: 10,
    orderBy: 'name',
    orderType: 'asc',
    q: '',
  },
  blacklist: {
    page: 1,
    perPage: 50,
    orderBy: 'domainName',
    orderType: 'desc',
  },
  badges: {
    page: 1,
    perPage: 20,
    orderBy: 'createdAt',
    orderType: 'desc',
  },
};

export const INITIAL_CUSTOMER_INVOICE_VALUES: IPartnerTypes.ICustomerInvoiceValues =
  {
    email: '',
    phone: '',
    stripePaymentsEnabled: false,
    otherIban: '',
    otherVatId: '',
    otherBankDetails: '',
    otherBankIdentificationCode: '',
    otherBankAccount: '',
    swizzIban: '',
    swizzVatId: '',
  };

export const customerInvoiceValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email('forms.emailInvalid')
    .max(60, 'forms.invalid.max')
    .required('forms.required'),
  otherBankAccount: Yup.string().max(30, 'forms.invalid.max'),
  otherBankDetails: Yup.string().max(200, 'forms.invalid.max'),
  otherBankIdentificationCode: Yup.string()
    .max(11, 'forms.invalid.max')
    .min(8, 'forms.invalid.min')
    .test({
      message: 'forms.invalid.onlyLettersAndNums',
      test: (val: any) => {
        if (!val) return true;
        return validator.onlyLettersAndNum(val);
      },
    }),
  otherIban: Yup.string()
    .trim()
    // .test('iban', 'forms.invalid.iban.exceptSwitz', (val: any) => {
    //   if (!val) return true;
    //   if (val.includes('CH') || val.includes('LI')) {
    //     return false;
    //   }
    //   return true;
    // })
    .test('iban', 'forms.invalid', (val: any) => {
      if (!val) return true;
      return validator.isIBAN(val);
    }),
  otherVatId: Yup.string()
    .trim()
    .test('vatId', 'forms.invalid', (val: any) => {
      if (!val) return true;
      return validator.isVATSimplified(val);
    }),

  swizzIban: Yup.string()
    .trim()
    .test('iban', 'forms.invalid.iban.switzOnly', (val: any) => {
      if (!val) return true;
      if (!(val.includes('CH') || val.includes('LI'))) {
        return false;
      }
      return true;
    })
    .test('iban', 'forms.invalid', (val: any) => {
      if (!val) return true;
      return validator.isIBAN(val);
    }),
  swizzVatId: Yup.string()
    .trim()
    .test('vatId', 'forms.invalid', (val: any) => {
      if (!val) return true;
      return validator.isVATSimplified(val);
    }),

  phone: Yup.string()
    .trim()
    .required('forms.required')
    .test('phone', 'forms.invalid', validator.isPhone),
});
