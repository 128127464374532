import React, { useCallback, useMemo } from 'react';
import { isEmpty } from 'ramda';
import { Loader, Alert, TablePagination } from 'elements';
import { withPermissions } from 'hocs';
import { IUsageRow } from 'usage';
import { useTranslation } from 'react-i18next';
import { usePermissions, useQuery } from 'hooks';
import { openInNewTab } from 'utils';
import { AnyShape, ICommonListParams } from 'global-shapes';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import PricesRow from './components/PricesRow';
import {
  getCurrentDateRangeParams,
  getLastYearDateRangeParams,
} from './helpers';

const OBSERVERS = {
  userUsage: StateHandlers.usage,
  statistic: StateHandlers.usageStatistics,
  current: StateHandlers.usageSelected,
};
type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { userUsage, current, statistic } = props;
  const { t } = useTranslation();
  const { currentAccessLevel, isCurrentProvider, permissions, isEvaluation } =
    usePermissions('PARTNERS');
  const { query, queryStr, changeQuery } = useQuery();

  const fetchUsersUsage = useCallback(
    (params: ICommonListParams) =>
      userUsage.get({
        ...params,
        accessLevel: currentAccessLevel,
      }),
    [currentAccessLevel]
  );

  const fetchUsageStatistic = useCallback(
    (params: ICommonListParams) =>
      statistic.get({
        ...params,
        accessLevel: currentAccessLevel,
      }),
    [currentAccessLevel]
  );

  const fetchCurrentUsage = useCallback(
    (id: number, q: AnyShape) =>
      current.get({
        ...q,
        id,
        accessLevel: currentAccessLevel,
      }),
    [currentAccessLevel]
  );

  // @ts-ignore
  const resetCurrentUsage = useCallback(() => current.setData(null), []);

  const showPagination = useMemo(() => {
    const params = userUsage.meta;
    if (params && query) {
      if (query.perPage >= params.totalCount) return false;
    }
    return true;
  }, [JSON.stringify(userUsage.meta), queryStr]);

  React.useEffect(() => {
    const params = { ...query, ...getCurrentDateRangeParams() };
    fetchUsageStatistic({ ...query, ...getLastYearDateRangeParams() });
    fetchUsersUsage(params);
  }, [queryStr]);

  const isDataReceived = userUsage.dataReceived && statistic.dataReceived;

  const onPriceRowClick = useCallback(
    (item: IUsageRow) => (ev: any) => {
      if (ev === 'close-menu') {
        return resetCurrentUsage();
      }
      if (isCurrentProvider) {
        return (
          permissions.canManage &&
          openInNewTab(`/${item.shortName}/usage${queryStr}`)
        );
      }
      fetchCurrentUsage(item.id, { ...query, ...getCurrentDateRangeParams() });
    },
    [isCurrentProvider, queryStr]
  );

  if (!isDataReceived) return <Loader />;

  return (
    <div>
      <div className="pt-10 mb-35">
        <h3>{t('usage.title')}</h3>
        {isEvaluation && (
          <Alert className="fs-12 mt-10" severity="info">
            {t('costsInfo.evalAccount.notify')}
          </Alert>
        )}
      </div>

      {userUsage.isLoading ? (
        <Loader />
      ) : (
        <>
          {userUsage.data.map((item) => (
            <PricesRow
              key={item.id}
              {...item}
              isProvider={isCurrentProvider}
              onClick={onPriceRowClick(item)}
              isLoading={current.isLoading}
              current={isEmpty(current.data) ? null : current.data}
            />
          ))}
          {showPagination && (
            <TablePagination
              {...userUsage.meta}
              shouldUseQuery
              onChange={({ offset, ...nq }) => changeQuery(nq)}
            />
          )}
        </>
      )}
    </div>
  );
});

const Usage = () => <View {...OBSERVERS} />;

const UsageMain = withPermissions(Usage, 'USAGE');

export default UsageMain;
