import * as React from 'react';
import { Button, Col, IconButton, MuiIcons, Paper, Row } from 'elements';
import { useTranslation } from 'react-i18next';
import { showSystemMessage } from 'utils';
import { useAsync, usePermissions, useState, useStateHandler } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { partnersService } from 'services';
import CustomerInvoiceDialog from './CustomerInvoiceDialog';
import StripeConnection from './StripeConnection';
import {
  InvoiceHolder,
  InvoiceSection,
  InvoiceSettingsEmptyWrapper,
} from '../Styled';

const Main = observer(() => {
  const { t } = useTranslation();
  const [state, handleState] = useState({ dialogOpen: false });
  const currentUser = useStateHandler(StateHandlers.currentUser);
  const countries = useStateHandler(StateHandlers.countries);
  const { permissions } = usePermissions('CUSTOMER_BILLING');
  const partnerId = currentUser.data?.partner?.id;
  const invoiceSettings = currentUser.data?.partner?.invoiceSettings;
  const stripePaymentsEnabled = !!invoiceSettings?.stripePaymentsEnabled;

  const swizData = currentUser.data?.partner?.invoiceSettings?.accounts?.find(
    (acc) => acc.forSwissCustomers
  );

  const otherData = currentUser.data?.partner?.invoiceSettings?.accounts?.find(
    (acc) => !acc.forSwissCustomers
  );

  const otherCountryData = countries.data.find(
    (c) => c.value === otherData?.iban?.slice(0, 2)
  );

  const otherCountryName = otherCountryData?.label || '';

  const { execute: updateInvoiceSettings } = useAsync(
    partnersService.updateInvoiceSettings
  );

  const onUpdateInfo = React.useCallback(
    async (data: IPartnerTypes.ICustomerInvoiceValues) => {
      try {
        const res = await updateInvoiceSettings(partnerId, {
          ...data,
          stripePaymentsEnabled,
        });
        showSystemMessage(
          'settings.page.customer-invoice.notify.update.success',
          'success'
        );
        StateHandlers.fetchAllAccountData({ shouldApplyTheme: false });
        handleState({ dialogOpen: false });
        return res;
      } catch (err: any) {
        return showSystemMessage(err.message, 'error');
      }
    },
    [partnerId, stripePaymentsEnabled]
  );

  if (!invoiceSettings) {
    return (
      <Paper>
        <InvoiceHolder className="flex justify-center align-center">
          <InvoiceSettingsEmptyWrapper className="text-center">
            <div className="mb-25 steel">
              {t('settings.page.customer-invoice.empty.description')}
            </div>
            {permissions.canManage && (
              <Button onClick={() => handleState({ dialogOpen: true })}>
                {t('settings.page.customer-invoice.empty.button')}
              </Button>
            )}
          </InvoiceSettingsEmptyWrapper>
        </InvoiceHolder>
        <CustomerInvoiceDialog
          isNew
          open={state.dialogOpen}
          onClose={() => handleState({ dialogOpen: false })}
          onSave={onUpdateInfo}
        />
      </Paper>
    );
  }

  return (
    <Paper>
      <InvoiceHolder>
        <Row alignItems="center" justifyContent="space-between">
          <Col>
            <h3 className="mb-20">
              {t('settings.page.customer-invoice.title') as string}
            </h3>
          </Col>
          {permissions.canManage && (
            <Col>
              <IconButton onClick={() => handleState({ dialogOpen: true })}>
                <MuiIcons.Edit />
              </IconButton>
            </Col>
          )}
        </Row>

        <InvoiceSection>
          <div className="fs-17 bolder mb-25">
            {t('settings.page.customer-invoice.sections.contact')}
          </div>

          <Row>
            <Col xs={6}>
              <div className="steel fs-12 mb-10">{t('forms.phone')}</div>
              <div>
                {currentUser.data?.partner?.invoiceSettings?.phone || '-'}
              </div>
            </Col>
            <Col xs={6}>
              <div className="steel fs-12 mb-10">{t('forms.email')}</div>
              <div>
                {currentUser.data?.partner?.invoiceSettings?.email || '-'}
              </div>
            </Col>
          </Row>
        </InvoiceSection>
        {swizData && (
          <InvoiceSection>
            <div className="fs-17 bolder mb-25">
              {t('settings.page.customer-invoice.sections.swiz')}
            </div>

            <Row>
              <Col xs={6}>
                <div className="steel fs-12 mb-10">{t('forms.ibanQR')}</div>
                <div>{swizData?.iban || '-'}</div>
              </Col>
              <Col xs={6}>
                <div className="steel fs-12 mb-10">{t('forms.vatId')}</div>
                <div>{swizData?.vatId || '-'}</div>
              </Col>
            </Row>
          </InvoiceSection>
        )}

        {otherData && (
          <InvoiceSection>
            <div className="fs-17 bolder mb-25">
              {t('settings.page.customer-invoice.sections.other')}
            </div>

            <Row columnSpacing={2} rowSpacing={3} className="mb-50">
              <Col xs={6}>
                <div className="steel fs-12 mb-10">{t('forms.country')}</div>
                <div>{otherCountryName}</div>
              </Col>

              <Col xs={6}>
                <div className="steel fs-12 mb-10">{t('forms.iban')}</div>
                <div>{otherData?.iban || '-'}</div>
              </Col>

              <Col xs={6}>
                <div className="steel fs-12 mb-10">{t('forms.swift.bic')}</div>
                <div>{otherData?.bankIdentificationCode || '-'}</div>
              </Col>
              <Col xs={6}>
                <div className="steel fs-12 mb-10">
                  {t('forms.bankAccountNumber')}
                </div>
                <div>{otherData?.bankAccount || '-'}</div>
              </Col>

              <Col xs={12}>
                <div className="steel fs-12 mb-10">
                  {t('forms.bankAddress')}
                </div>
                <div>{otherData?.bankDetails || '-'}</div>
              </Col>
            </Row>
          </InvoiceSection>
        )}

        <StripeConnection permissions={permissions} />
      </InvoiceHolder>

      <CustomerInvoiceDialog
        isNew={false}
        open={state.dialogOpen}
        onClose={() => handleState({ dialogOpen: false })}
        onSave={onUpdateInfo}
      />
    </Paper>
  );
});

export default Main;
