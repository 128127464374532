import * as React from 'react';
import {
  Input,
  IconButton,
  MuiIcons,
  AddIcon,
  Row,
  Col,
  Alert,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { isValidIpCidrRange } from 'utils';
import * as TYPES from './types';

type IProps = ReturnType<typeof useFormik<TYPES.IPublicServiceFormValues>>;

const FirewallIpAddresses = (props: IProps) => {
  const { t } = useTranslation();

  const sources = props.values.sources;

  const onAddSource = React.useCallback(async () => {
    const isValid = await isValidIpCidrRange(props.values.source).catch(
      () => false
    );
    if (!isValid) {
      return props.setFieldError(
        'source',
        'services.vm.services.dialog.errors.invalid.source.ip'
      );
    }

    const hasDuplicate = sources.includes(props.values.source);

    if (hasDuplicate) {
      return props.setFieldError(
        'source',
        'services.vm.services.dialog.errors.invalid.sources.uniq'
      );
    }

    props.setValues({
      ...props.values,
      sources: [...sources, props.values.source],
      source: '',
    });
  }, [JSON.stringify(props.values)]);

  const onDelete = React.useCallback(
    (ip: string) => {
      props.setFieldValue(
        'sources',
        sources.filter((el) => el !== ip)
      );
    },
    [sources]
  );

  return (
    <>
      <Row className="mb-25" alignItems="flex-start">
        <Col xs>
          <Input
            name="source"
            label="forms.source"
            placeholder="forms.placeholders.ip.ranges.subnet"
            onChange={props.handleChange}
            value={props.values.source}
            error={!!props.errors.source}
            helperText={props.errors.source}
          />
        </Col>
        <AddIcon className="ml-15 mt-30" onClick={onAddSource} />
      </Row>
      <div>
        <div className="steel fs-12 mb-10">{t('forms.source')}</div>
        <div className="mb-20">
          {!sources.length ? (
            <div className="fs-14 steel">{t('common.noData')}</div>
          ) : (
            sources.map((s, ind) => {
              return (
                <Row
                  key={s + ind}
                  alignItems="center"
                  justifyContent="space-between"
                  className="mb-5"
                >
                  <Col>{s}</Col>
                  <Col>
                    <IconButton size="small" onClick={() => onDelete(s)}>
                      <MuiIcons.Delete className="fs-18" />
                    </IconButton>
                  </Col>
                </Row>
              );
            })
          )}
        </div>
        {!!props.submitCount && !!props.errors.sources && (
          <Alert severity="error">{t(props.errors.sources)}</Alert>
        )}
      </div>
    </>
  );
};

export default FirewallIpAddresses;
