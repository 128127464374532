import { LANGUAGES } from 'enums';
import { ICustomer } from 'customer-shapes';
import { ILanguage, ValueType } from 'global-shapes';
import { getCountries } from 'utils';
import { INITIAL_CUSTOMER_INVOICE_VALUES } from './constants';

const countries = getCountries();

export const parseCustomerInvoiceSettingsForForm = (
  partner: IPartnerTypes.Partner
): IPartnerTypes.ICustomerInvoiceValues => {
  const settings = partner.invoiceSettings || INITIAL_CUSTOMER_INVOICE_VALUES;
  const swizzAccount = settings?.accounts?.find((acc) => acc.forSwissCustomers);
  const otherAccount = settings?.accounts?.find(
    (acc) => !acc.forSwissCustomers
  );

  return {
    email: settings.email,
    phone: settings.phone.replace('+', ''),
    otherBankAccount: otherAccount?.bankAccount || '',
    otherBankDetails: otherAccount?.bankDetails || '',
    otherBankIdentificationCode: otherAccount?.bankIdentificationCode || '',
    otherIban: otherAccount?.iban || '',
    otherVatId: otherAccount?.vatId || '',
    swizzIban: swizzAccount?.iban || '',
    swizzVatId: swizzAccount?.vatId || '',
    stripePaymentsEnabled: settings.stripePaymentsEnabled,
  };
};

export function parsePartnerInfoForForm(
  partner: IPartnerTypes.Partner
): IPartnerTypes.IPartnerInfoFormValues['address'] & {
  lang: ValueType<ILanguage>;
  name: string;
  phone: string;
} {
  return {
    name: partner.name || '',
    line1: partner.address?.line1 || '',
    line2: partner.address?.line2 || '',
    zipCode: partner.address?.zipCode || '',
    city: partner.address?.city || '',
    phone: partner.phone || '',
    countryCode: countries.find(
      (el) => el.value === partner.address?.countryCode
    ),
    lang: LANGUAGES.find(
      (el) => el.value === partner.lang
    ) as ValueType<ILanguage>,
  };
}

export function parseCustomerInfoForForm(
  customer: ICustomer
): IPartnerTypes.IPartnerInfoFormValues['address'] & {
  lang: ValueType<ILanguage>;
  name: string;
  phone: string;
} {
  return {
    line1: customer.address?.line1 || '',
    line2: customer.address?.line2 || '',
    zipCode: customer.address?.zipCode || '',
    city: customer.address?.city || '',
    name: customer?.name || '',
    phone: customer?.phone || '',
    countryCode: countries.find(
      (el) => el.value === customer.address?.countryCode
    ),
    lang: LANGUAGES.find(
      (el) => el.value === customer.lang
    ) as ValueType<ILanguage>,
  };
}
