import * as React from 'react';
import { PALETTE } from 'enums';
import { remCalc, important } from 'utils';
import {
  styled,
  Accordion,
  AccordionDetails,
  MuiIcons,
  LinearTable,
} from 'elements';

export const AccordionRoot = styled(Accordion)(({ theme }) => ({
  boxShadow: important('0 1px 1px 0 rgba(23, 29, 41, 0.16)'),
  borderRadius: important(remCalc(5)),
  '&:before': {
    display: 'none',
  },
}));

export const AccordionContent = styled(AccordionDetails)(({ theme }) => ({
  paddingLeft: important(remCalc(80)),
}));

export const BillingContent = styled('div')(({ theme }) => ({
  paddingLeft: important(remCalc(80)),
}));

export const RecordDetailsEndInputValue = styled('span')(({ theme }) => ({
  flexShrink: 0,
  lineHeight: remCalc(48),
}));

export const ExpandIcon = styled((props: any & { expanded: boolean }) => {
  const { expanded, ...rest } = props;
  const Icon = props.expanded ? MuiIcons.ExpandLess : MuiIcons.ExpandMore;
  return <Icon {...rest} />;
})(({ theme }) => ({
  marginLeft: remCalc(10),
  backgroundColor: PALETTE.paleGrey,
  color: PALETTE.primary,
  width: remCalc(24),
  height: remCalc(24),
  lineHeight: remCalc(24),
  textAlign: 'center',
  borderRadius: remCalc(3),
  transition: theme.transitions.create('background-color', {
    easing: 'ease-in-out',
    duration: 150,
    delay: 0,
  }),
  '&:hover': {
    backgroundColor: PALETTE.info,
  },
}));

export const ZonesLinearTable = styled(LinearTable)(({ theme }) => ({
  '& ._noData': {
    height: remCalc(100),
  },
  '& ._cell': {
    paddingTop: important(remCalc(7)),
    paddingBottom: important(remCalc(7)),
  },
  '& ._table': {
    maxHeight: remCalc(270),
    overflowY: 'auto',
  },
}));

export const RdsDialogLeftContent = styled('div')(({ theme }) => ({
  maxWidth: remCalc(500),
  '& ul': {
    listStyle: 'disc',
    paddingLeft: remCalc(25),
  },
  '& li': {
    listStyleType: 'disk',
  },
}));
