class Translations {
  public en = {};
  public de = {};

  public setResource = (key: 'en' | 'de', trans: any) => {
    this[key] = trans;
  };

  public getResource = (key: 'en' | 'de'): Record<string, string> => {
    return this[key];
  };
}

export const GLOBAL_TRANSLATIONS = new Translations();
