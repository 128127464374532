import { styled, Row } from 'elements';
import { remCalc } from 'utils';

export const EmptyHolder = styled(Row)(({ theme }) => ({
  minHeight: remCalc(500),
  '& ._content': {
    maxWidth: remCalc(350),
  },
}));

export const FormInputsWrapper = styled('div')(({ theme }) => ({
  maxWidth: remCalc(230),
}));

export const MainContentWrapper = styled('div')(({ theme }) => ({
  maxWidth: remCalc(500),
  marginBottom: remCalc(25),
}));
