import React from 'react';
import cn from 'classnames';
import { IconButton, MuiIcons } from 'elements';
import { useTranslation } from 'react-i18next';
import { IBackupEmail } from 'backup';
import { PALETTE } from 'enums';
import { remCalc } from 'utils';

type Props = {
  onDelete: (data: IBackupEmail) => void;
  disabled: boolean;
  isEditable: boolean;
  testId: string;
  users: IBackupEmail[];
};

const BackupUsers = (props: Props) => {
  const { users, onDelete, testId, isEditable } = props;
  const { t } = useTranslation();

  if (!users.length) return <span />;

  return (
    <div>
      <div className="steel fs-12 mb-20">{t('table.head.email')}</div>
      {users.map((u) => (
        <div
          key={u.id}
          data-test-id={`${testId}-${u.id}`}
          className={cn('flex justify-between align-center pb-5 pt-5')}
          style={{
            borderBottom: `1px solid ${PALETTE.pageBackground}`,
            maxWidth: remCalc(545),
          }}
        >
          <span>{u.email}</span>
          {isEditable && (
            <IconButton
              onClick={() => onDelete(u)}
              data-test-id={`${testId}-delete-button-${u.id}`}
            >
              <MuiIcons.Delete className="fs-20 steel" />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
};

export default BackupUsers;
