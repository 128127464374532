import { CostResourceIds } from './costResourceIds';
import { ValueType } from 'global-shapes';

export const BILLING_CIRCLE_OPTIONS: ValueType<IVmTypes.IBillingCircleInterval>[] =
  [
    {
      value: 'NONE',
      label: 'costsInfo.options.billingCircle.NONE',
    },
    {
      value: 'QUARTER',
      label: 'costsInfo.options.billingCircle.QUARTER',
    },
    {
      value: 'HALF_YEAR',
      label: 'costsInfo.options.billingCircle.HALF_YEAR',
    },
    {
      value: 'YEAR',
      label: 'costsInfo.options.billingCircle.YEAR',
    },
  ];

export const getBillingCycleOptions = (
  isEvaluation?: boolean
): ValueType<IVmTypes.IBillingCircleInterval>[] =>
  BILLING_CIRCLE_OPTIONS.map((el) => ({
    ...el,
    isDisabled: el.value !== 'NONE' ? isEvaluation : false,
  }));

export const VM_PRICE_SERVICE_IDS = [
  { id: CostResourceIds.virtualServerBase, name: 'baseFee' },
  { id: CostResourceIds.virtualServerVirtualCpus, name: 'virtualCpus' },
  { id: CostResourceIds.virtualServerRamMb, name: 'ramMb' },
  { id: CostResourceIds.virtualServerGBStorage, name: 'osDiskSizeGb' },
  { id: CostResourceIds.winLicense, name: 'winLicense' },
];

export const VmColumnKeys = [
  'name',
  'os',
  'description',
  'cpu',
  'ram',
  'storage',
  'network',
  'ipv4',
  'ipv6',
  'activeServices',
  'billingCycle',
];
