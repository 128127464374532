import * as React from 'react';
import {
  BackgroundRequester,
  Button,
  Col,
  Dialog,
  DialogProps,
  PrimaryTextH5,
  PrimaryTextSpan,
  Row,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { remCalc } from 'utils';
import { useBackupPlan } from 'hooks';
import { generateInitialPlanState } from 'enums';
import { displayPriceFormat } from './helpers';
import { IBackup } from 'backup';
import { CostInfoList } from 'pages/services/components/Styled';
import PlanForm from './PlanForm';
import { observer } from 'mobx-react-lite';

type Props = DialogProps<BackupTypes.State> &
  BackupTypes.ICommonPlanDialogProps & {
    disabledActions: boolean;
    hasBackup: boolean;
    vmDiskSize: number;
    initialValues?: IBackup;
  };

const PlanDialog = observer((props: Props) => {
  const {
    onSave,
    disabledActions,
    vmDiskSize,
    initialValues,
    instance,
    hasBackup,
    ...dialogProps
  } = props;
  const { t } = useTranslation();
  const initials = dialogProps.open ? initialValues : undefined;

  const { values, handleChange, costs, isRequesting } = useBackupPlan({
    vmDiskSize,
    instance,
    initialValues: initials,
  });

  React.useEffect(() => {
    if (!dialogProps.open) {
      handleChange(generateInitialPlanState());
    } else {
      handleChange(initialValues);
    }
  }, [dialogProps.open]);

  return (
    <Dialog
      {...dialogProps}
      title={t('services.backup.dialog.settings')}
      maxWidth="md"
      fullWidth
      actions={
        <Row columnSpacing={2} justifyContent="flex-end">
          <Col>
            <Button
              color="default"
              variant="outlined"
              disabled={disabledActions}
              onClick={dialogProps.onClose}
            >
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button
              disabled={disabledActions}
              onClick={() => onSave && onSave(values)}
            >
              {t('common.save')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Row justifyContent="space-between" columnSpacing={2}>
        <Col xs={7}>
          <div style={{ maxWidth: remCalc(500) }}>
            <PlanForm
              values={values}
              handleChange={handleChange}
              noBackupAlertColor="error"
            />
          </div>
        </Col>
        <Col xs={5}>
          <BackgroundRequester isRequesting={isRequesting}>
            <Row
              direction="column"
              justifyContent="space-between"
              columnSpacing={2}
              style={{ minHeight: remCalc(400) }}
            >
              <Col>
                <PrimaryTextH5 className="mb-25 primary">
                  {t('costsInfo.title')}
                </PrimaryTextH5>
                <Row
                  justifyContent="space-between"
                  columnSpacing={2}
                  className="fs-10 steel uppercase mb-15"
                >
                  <Col xs={8}>
                    <span>{t('costsInfo.head.serviceName')}</span>
                  </Col>
                  <Col xs={4} className="text-right">
                    <span>{t('costsInfo.head.price')}</span>
                  </Col>
                </Row>
                <h5 className="mb-15">{t('costsInfo.backup')}</h5>
                <CostInfoList className="custom-list">
                  <ul>
                    <li className="mb-5">
                      <div className="flex justify-between">
                        <span>{t('costsInfo.backupService')}</span>
                        <span className="pl-10 steel">
                          {displayPriceFormat(costs.backupBaseMonthlyFee)}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        <span>{t('costsInfo.backupStorage')}</span>
                        <span className="pl-10 steel">
                          {displayPriceFormat(costs.backupStorageFinalMin)}
                          {' - '}
                          {displayPriceFormat(costs.backupStorageFinalMax)}
                        </span>
                      </div>
                    </li>
                  </ul>
                </CostInfoList>
              </Col>
              <Col>
                <div className="flex justify-between align-center">
                  <h5 className="fs-17">{t('costsInfo.totalMonthly')}</h5>
                  <PrimaryTextSpan className="fs-20 bold">
                    {displayPriceFormat(
                      costs.backupStorageFinalMin + costs.backupBaseMonthlyFee
                    )}
                    {' - '}
                    {displayPriceFormat(
                      costs.backupStorageFinalMax + costs.backupBaseMonthlyFee
                    )}
                  </PrimaryTextSpan>
                </div>
                <div className="fs-12 steel">{t('costsInfo.chfExclVat')}</div>
              </Col>
            </Row>
          </BackgroundRequester>
        </Col>
      </Row>
    </Dialog>
  );
});

export default PlanDialog;
