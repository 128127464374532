import * as R from 'ramda';
import { AnyFunc, AnyShape } from 'global-shapes';

export function compact(arr: any[]): any[] {
  return arr.filter((el) => {
    if (isArray(el)) {
      return compact(el).length;
    }
    return el;
  });
}

export function compactObject<O extends AnyShape>(obj: O): AnyShape {
  return R.reduce(
    (res: AnyShape, key: string) => {
      if (obj[key]) {
        res[key] = obj[key];
      }
      return res;
    },
    {},
    Object.keys(obj)
  );
}

export const round = (
  value: number,
  roundTo: number,
  offset?: number
): number => {
  const number = Number(
    `${Math.round(Number(`${value}e${roundTo}`))}e-${roundTo}`
  );

  return +(offset ? number.toFixed(offset) : number);
};

export const isFunction = R.is(Function);
export const isArray = R.is(Array);

export function random(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const isString = (value: any) => typeof value === 'string';

/**
 * A function that emits a side effect.
 */
type Procedure = (...args: any[]) => any;

type Options<TT> = {
  isImmediate?: boolean;
  maxWait?: number;
  callback?: (data: TT) => void;
};

interface DebouncedFunction<F extends Procedure> {
  (this: ThisParameterType<F>, ...args: Parameters<F>): Promise<ReturnType<F>>;

  cancel: (reason?: any) => void;
}

export function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = 50,
  options: Options<ReturnType<F>> = {}
): DebouncedFunction<F> {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  const isImmediate = options.isImmediate ?? false;
  const callback = options.callback ?? false;
  const maxWait = options.maxWait;
  let lastInvokeTime = Date.now();

  let promises: {
    resolve: (x: ReturnType<F>) => void;
    reject: (reason?: any) => void;
  }[] = [];

  function nextInvokeTimeout() {
    if (maxWait !== undefined) {
      const timeSinceLastInvocation = Date.now() - lastInvokeTime;

      if (timeSinceLastInvocation + waitMilliseconds >= maxWait) {
        return maxWait - timeSinceLastInvocation;
      }
    }

    return waitMilliseconds;
  }

  const debouncedFunction = function (
    this: ThisParameterType<F>,
    ...args: Parameters<F>
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    return new Promise<ReturnType<F>>((resolve, reject) => {
      const invokeFunction = function () {
        timeoutId = undefined;
        lastInvokeTime = Date.now();
        if (!isImmediate) {
          const result = func.apply(context, args);
          callback && callback(result);
          promises.forEach(({ resolve }) => resolve(result));
          promises = [];
        }
      };

      const shouldCallNow = isImmediate && timeoutId === undefined;

      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(invokeFunction, nextInvokeTimeout());

      if (shouldCallNow) {
        const result = func.apply(context, args);
        callback && callback(result);
        return resolve(result);
      }
      promises.push({ resolve, reject });
    });
  };

  debouncedFunction.cancel = function (reason?: any) {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }
    promises.forEach(({ reject }) => reject(reason));
    promises = [];
  };

  return debouncedFunction;
}

export function throttle(fn: AnyFunc, interval: number) {
  let lastTime: any;
  return function throttled() {
    const timeSinceLastExecution = Date.now() - lastTime;
    if (!lastTime || timeSinceLastExecution >= interval) {
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      fn.apply(this, arguments);
      lastTime = Date.now();
    }
  };
}

export function toggleArrayState<T = number>(filters: T[], key: T): T[] {
  const hasValue = filters.includes(key);
  if (hasValue) return R.filter((parameter) => parameter !== key)(filters);
  return [...filters, key];
}

export function calculatePercentage(value: number, percentage: number) {
  return (value / 100) * (100 + percentage);
}

export const isBetween = (min: number, max: number) => (num: number) =>
  num >= min && num <= max;
