import * as React from 'react';
import qs from 'query-string';
import { Dialog, DialogProps, Input, MuiIcons, Switch } from 'elements';
import { useTranslation } from 'react-i18next';
import { showSystemMessage } from 'utils';
import copy from 'copy-to-clipboard';
import { useAsync } from 'hooks';
import { partnersService } from 'services';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { DEFAULT_CUSTOMER_INVITE_SETTINGS } from './constants';

type IProps = DialogProps & {
  currentUser: StateHandlers.ICurrentUserHandler;
};

const View = observer((props: IProps) => {
  const { onClose, open, currentUser } = props;
  const { t } = useTranslation();

  const customerInviteSettings =
    currentUser.data.partner?.customerInviteSettings ||
    DEFAULT_CUSTOMER_INVITE_SETTINGS;

  const { execute: updateCurrentUser, isPending } =
    useAsync<IPartnerTypes.Partner>(
      partnersService.updateCurrentProfileInfo as any
    );

  const link = React.useMemo(() => {
    const query = qs.stringify(
      {
        partner: currentUser.data.partner?.name,
        key: customerInviteSettings?.inviteKey,
      },
      {
        arrayFormat: 'bracket',
      }
    );

    return `${process.env.REACT_APP_CLIENT_SERVER}/invite?` + query;
  }, [currentUser]);

  const isSaving = currentUser.isRequesting || isPending;

  const updateProfile = React.useCallback(
    (values: Partial<IPartnerTypes.ICustomerInvoiceSettings>) =>
      updateCurrentUser({
        customerInviteSettings: {
          ...values,
          billingEnabled: !values.enabled ? false : values.billingEnabled,
        },
      }).then(() =>
        StateHandlers.fetchAllAccountData({ shouldApplyTheme: false })
      ),
    []
  );

  const onSwitchChange = React.useCallback(
    (name: 'enabled' | 'billingEnabled') => (isChecked: boolean) =>
      updateProfile({
        ...customerInviteSettings,
        [name]: isChecked,
      } as IPartnerTypes.ICustomerInvoiceSettings),
    [customerInviteSettings]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      title={t('tenants.dialog.inviteCustomer.title')}
    >
      <div className="mb-15">
        <Switch
          checked={customerInviteSettings.enabled}
          disabled={isSaving}
          label={t('tenants.dialog.inviteCustomer.switchers.enableLink')}
          onCheck={onSwitchChange('enabled')}
        />
      </div>
      <div className="mb-30">
        <Switch
          checked={customerInviteSettings.billingEnabled}
          disabled={isSaving || !customerInviteSettings.enabled}
          label={t('tenants.dialog.inviteCustomer.switchers.enableBilling')}
          onCheck={onSwitchChange('billingEnabled')}
        />
      </div>
      <Input
        label={t('tenants.dialog.inviteCustomer.forms.activationLink')}
        value={link}
        endAdornment={
          <MuiIcons.FileCopy
            className="mr-10 fs-20 steel pointer"
            onClick={() =>
              copy(link, {
                format: 'text/plain',
                onCopy: () =>
                  showSystemMessage('common.copiedToClipboard', 'info'),
              })
            }
          />
        }
      />
    </Dialog>
  );
});

const InviteCustomer = (pr: DialogProps) => (
  <View {...pr} currentUser={StateHandlers.currentUser} />
);

export default InviteCustomer;
