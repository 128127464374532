import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'elements';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useLocale } from 'hooks';
import { showSystemMessage, consvertAndDownloadStringAsFile } from 'utils';
import { IExternalCmdCommands } from 'monitoring';

dayjs.extend(relativeTime);

type IProps = React.PropsWithChildren<
  IExternalCmdCommands & {
    osType: IOsType;
    isCreating?: boolean;
    isDeleting?: boolean;
  }
>;

const VmFlow = (props: IProps) => {
  const {
    isCreating,
    isDeleting,
    externalInstallCmd,
    externalUninstallCmd,
    osType,
  } = props;
  const { t } = useTranslation();
  const { locale } = useLocale();

  const onCopy = React.useCallback((text?: string) => {
    copy(text || '', {
      format: 'text/plain',
      onCopy: () => showSystemMessage('common.copiedToClipboard', 'info'),
    });
  }, []);

  const parseCode = React.useCallback((code: string) => {
    return (
      code &&
      code.split('\n').map((t) => (
        <div key={t} className="mb-5">
          {t}
        </div>
      ))
    );
  }, []);

  React.useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);

  if (isCreating) {
    if (osType === 'win') {
      return (
        <div>
          <div className="mb-25">{t('monitoring.vm.flow.creating.title')}</div>
          <div className="mb-25">
            {t('monitoring.vm.flow.creating.description')}
          </div>
          <code className="mb-25 code">
            {parseCode(externalInstallCmd as string)}
          </code>
          <Row columnSpacing={2} alignItems="center">
            <Col>
              <Button onClick={() => onCopy(externalInstallCmd)}>
                {t('monitoring.buttons.copyCommand')}
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() =>
                  consvertAndDownloadStringAsFile(
                    'awesomecloud_monitoring_installer.ps1',
                    externalInstallCmd as string
                  )
                }
                color="default"
                variant="outlined"
              >
                {t('monitoring.buttons.downloadScript')}
              </Button>
            </Col>
          </Row>
        </div>
      );
    }

    if (osType === 'lin') {
      return (
        <div>
          <div className="mb-25">{t('monitoring.vm.flow.creating.title')}</div>
          <div className="mb-25">
            {t('monitoring.vm.flow.lin.creating.description.2')}
          </div>
          <code className="mb-25 code">
            {parseCode(externalInstallCmd as string)}
          </code>
          <Button onClick={() => onCopy(externalInstallCmd)}>
            {t('monitoring.buttons.copyCommand')}
          </Button>
        </div>
      );
    }
  }

  if (isDeleting) {
    return (
      <div>
        <div className="mb-25">{t('monitoring.vm.flow.deleting.title')}</div>
        <div className="mb-25">
          {t('monitoring.vm.flow.deleting.description')}
        </div>
        <code className="mb-25 code">
          {parseCode(externalUninstallCmd as string)}
        </code>
        <Button onClick={() => onCopy(externalUninstallCmd)}>
          {t('monitoring.buttons.copyCommand')}
        </Button>
      </div>
    );
  }

  return null;
};

export default VmFlow;
