import * as R from 'ramda';
import { dayjs } from 'utils';
import * as Yup from 'yup';

export const MINUTE_STEP = 15;

export const PATCHING_INTERVAL_OPTIONS: ValueType<IMaintenanceRecurringInterval>[] =
  [
    {
      value: 'SINGLE',
      label: 'patching.options.recurring.single',
    },
    {
      value: 'DAY',
      label: 'patching.options.recurring.daily',
    },
    {
      value: 'WEEK',
      label: 'patching.options.recurring.weekly',
    },
    {
      value: 'MONTH',
      label: 'patching.options.recurring.monthly',
    },
  ];

export const PATCHING_INTERVAL_TEXT_MAP: Record<string, string> = {
  SINGLE: 'patching.options.recurring.single',
  DAY: 'patching.options.recurring.daily',
  WEEK: 'patching.options.recurring.weekly',
  MONTH: 'patching.options.recurring.monthly',
};

export const buildInitialState = (
  patching?: IServicePatching,
  userEmail?: string
): IPatchingTypes.Values => {
  const mw = patching?.maintenanceWindows && patching?.maintenanceWindows[0];

  const startDate = mw?.startAt
    ? dayjs(mw?.startAt)
    : dayjs().add(1, 'days').set('hours', 4).set('minutes', 0);

  const recurringInterval =
    R.find(
      R.propEq('value', mw?.recurringInterval),
      PATCHING_INTERVAL_OPTIONS
    ) || PATCHING_INTERVAL_OPTIONS[0];

  return {
    recurringInterval,
    startDate,
    isForced: false,
    notificationEnabled: !!mw?.notificationEnabled,
    outsourceManagementEnabled: !!patching?.outsourceManagementEnabled,
    alertEmailRecipient:
      userEmail ||
      patching?.alertEmailRecipient ||
      mw?.payload.alertEmailRecipient ||
      '',
  };
};

export const patchingValidationSchema = Yup.object({
  alertEmailRecipient: Yup.string()
    .email('forms.emailInvalid')
    .required('forms.required'),
  startDate: Yup.date()
    .test({
      message: 'forms.invalid.date',
      test(_startDate) {
        const maximumDate = dayjs().add(30, 'days').endOf('day');
        const minimumDate = dayjs();

        const startDate = dayjs(_startDate);

        const validDate =
          minimumDate.diff(startDate, 'days') > 0 ||
          maximumDate.diff(startDate, 'day') >= 0;

        return validDate;
      },
    })
    .test({
      message: 'forms.invalid.date.isEarlierThanNow',
      test(startDate) {
        const finalDate = dayjs(startDate);
        const validDate = !(+dayjs().add(15, 'minutes') > +finalDate);

        return validDate;
      },
    }),
});
