import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';
import {
  Button,
  Row,
  Col,
  IconButton,
  MuiIcons,
  List,
  Popover,
  ListItemButton,
} from 'elements';
import { confirm, noop } from 'utils';
import { usePermissions, useState } from 'hooks';
import { ALL_TEST_IDS } from 'enums';
import * as StateHandlers from 'states';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import BulkScheduleUpdateDialog from './BulkScheduleUpdateDialog/MainDialog';
import { headerActionsState } from './constants';

const OBSERVERS = {
  tenants: StateHandlers.tenants,
  selectedTenant: StateHandlers.currentTenant,
  guideTooltips: StateHandlers.guideTooltips,
  currentUser: StateHandlers.currentUser,
  assignments: StateHandlers.badgesAssignment,
  patchingsList: StateHandlers.tenantsPatchingsBulkInfo,
};

type IProps = {
  openInviteDialog: () => void;
  onCreateTenant: () => void;
};

type IViewProps = typeof OBSERVERS & IProps;

const HeaderActionsView = observer((props: IProps & IViewProps) => {
  const {
    tenants,
    currentUser,
    openInviteDialog,
    onCreateTenant,
    patchingsList,
  } = props;
  const [state, handleState] =
    useState<ITenantsTypes.IHeaderActionsState>(headerActionsState);
  const { permissions } = usePermissions('TENANTS');

  const reachedTenantsLimit =
    (currentUser.data.partner?.maxTenants || 0) <= tenants.data.length;

  const onCreateTenantClick = useCallback(() => {
    if (reachedTenantsLimit) {
      return confirm({
        title: t('tenants.confirm.limitReached.title'),
        content: t('tenants.confirm.limitReached.content', {
          value: currentUser.data.partner?.maxTenants,
        }),
        onCancel: noop,
        cancelLabel: 'common.ok',
      });
    }

    onCreateTenant();
  }, [
    reachedTenantsLimit,
    currentUser.data.partner?.maxTenants,
    onCreateTenant,
  ]);

  const onBulkPatchingCreate = React.useCallback(
    async (values: IBulkScheduleUpdateDialog.IFormValues) => {
      await patchingsList.create({
        partnerId: currentUser.data?.partner?.id,
        operation: values.operation?.value,
        data: patchingsList.data.filter((p) =>
          values.applied.includes(p.uniqId)
        ),
        mw: {
          recurring: values.recurring,
          startDate: values.startDate?.toISOString(),
          alertEmailRecipient: values.alertEmailRecipient,
          informUsers: values.informUsers,
          replaceRebootRules: values.replaceRebootRules,
        },
      });

      return handleState({ bulkSchedileDialogOpen: false });
    },
    [currentUser.data, patchingsList.data]
  );

  return (
    <>
      <Row columnSpacing={2} alignItems="center">
        <Col>
          <Button
            variant="outlined"
            color="default"
            onClick={openInviteDialog}
            testId={ALL_TEST_IDS.tenants.inviteTenant}
            id="invite-tenant-button"
          >
            {t('tenants.buttons.inviteCustomer')}
          </Button>
        </Col>
        {permissions.canManage && (
          <Col>
            <Button
              onClick={onCreateTenantClick}
              testId={ALL_TEST_IDS.tenants.createTenant}
              id="create-tenant-button"
              className="tooltip-guide-target"
            >
              {t('tenants.buttons.createCustomer')}
            </Button>
          </Col>
        )}
        <Col>
          <PopupState variant="popover">
            {(popupState) => (
              <>
                <IconButton size="small" {...bindTrigger(popupState)}>
                  <MuiIcons.MoreVert className="fs-20 steel pointer" />
                </IconButton>
                <Popover
                  {...bindMenu(popupState)}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <List>
                    <ListItemButton
                      data-test-id={`tenants-bulk-schedule-update`}
                      onClick={() => {
                        popupState.close();
                        handleState({ bulkSchedileDialogOpen: true });
                      }}
                    >
                      {t(`tenants.buttons.bulkScheduleUpdate`) as string}
                    </ListItemButton>
                  </List>
                </Popover>
              </>
            )}
          </PopupState>
        </Col>
      </Row>
      <BulkScheduleUpdateDialog
        open={state.bulkSchedileDialogOpen}
        onClose={() => handleState({ bulkSchedileDialogOpen: false })}
        onSave={onBulkPatchingCreate}
      />
    </>
  );
});

const HeaderActions = (props: IProps) => (
  <HeaderActionsView {...{ ...OBSERVERS, ...props }} />
);

export default HeaderActions;
