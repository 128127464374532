import * as React from 'react';
import * as R from 'ramda';
import { ValueType } from 'global-shapes';
import { IEmployeePayload, IEmployee, IEmployeeType } from 'employees';
import { EMPLOYEE_TYPES_OPTIONS } from './constants';
// @ts-ignore
import { convertToHTML } from 'draft-convert';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  CompositeDecorator,
  Entity,
  DraftDecorator,
  ContentBlock,
} from 'draft-js';

const Link = (props: any) => {
  const data = Entity.get(props.entityKey).getData();
  return <a {...data}>{props.children}</a>;
};

export const decorators: DraftDecorator[] = [
  { strategy: findLinkEntities, component: Link },
];

function findLinkEntities(
  block: ContentBlock,
  callback: (start: number, end: number) => void
) {
  block.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return entityKey !== null && Entity.get(entityKey).getType() === 'LINK';
  }, callback);
}

export function parseEmployeeForForm(data: IEmployee): IEmployeePayload {
  return {
    id: data.id,
    name: data.name || '',
    phone: data.phone || '',
    email: data.email || '',
    comment: data.comment || '',
    type: EMPLOYEE_TYPES_OPTIONS.find(
      (opt) => opt.value === data.type
    ) as ValueType<IEmployeeType>,
  };
}

export function toogleArrayValues(id: number, ids: number[]) {
  const currentSelected = ids;
  const hasId = R.includes(id, currentSelected);
  const newValue = hasId
    ? currentSelected.filter((_id) => +_id !== id)
    : [...currentSelected, id];

  return newValue;
}

export function parseNotesForForm(notes: string) {
  const blocksFromHTML = convertFromHTML(notes);
  const description = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  return EditorState.createWithContent(
    description,
    new CompositeDecorator(decorators)
  );
}

export function parseNotesFromForm(notes: EditorState) {
  return convertToHTML({
    entityToHTML: (entity: any, originalText: string) => {
      if (entity.type === 'LINK') {
        return (
          <a href={entity.data.url} target={entity.data.targetOption}>
            {originalText}
          </a>
        );
      }
      return originalText;
    },
  })(notes.getCurrentContent());
}
