import * as React from 'react';
import { IRds } from 'rds';
import { compact } from 'utils';
import { ICoreInfrastructure } from 'core-infrastructure';
import { ActionOption, IActionHandlers } from 'components/ActionButtons/types';
import { usePermissions } from 'hooks/usePermissions';
import { IRemoteSupportResult } from 'hooks/useRemoteSupport';
import { useStateHandler } from 'hooks/instanceHook';
import { useTask } from 'hooks/useTask';
import * as StateHandlers from 'states';
import {
  buildActionsItemsData,
  generateCoreActionItems,
  generateDedicatedServerActionItems,
  generateRdsActionItems,
  generateVmActionItems,
} from 'components/ActionButtons/helpers';
import { ISnapshot } from 'snapshots';

interface IConfig<I> {
  instance: I;
  isRequesting: boolean;
  remoteSupport?: IRemoteSupportResult;
  handlers: IActionHandlers;
  agent?: IAgentActionsConfig;
  folders?: IServiceGroups.Group[];
  isOnFolderContent?: boolean;
  isInFolder?: boolean;
  hasAttachedNetwork?: boolean;
}

interface IResponse {
  menuItems?: ActionOption[];
  actionItems?: ActionOption[];
  handlers: IActionHandlers;
  snapshot?: ISnapshot | IScheduledTasks.Task;
}

export function useVmMenuItems(config: IConfig<IVmTypes.Vm>): IResponse {
  const {
    instance,
    remoteSupport,
    handlers,
    isRequesting,
    folders,
    isOnFolderContent,
    isInFolder,
  } = config;
  const isPublic = instance.networkType === 'PUBLIC';
  const { permissions, isTenant } = usePermissions('SERVICES');
  const scheduledTasks = useStateHandler(StateHandlers.scheduledTasks);
  const snapshotTask = useTask(instance?.serviceSnapshot?.task);
  const hasInjectedMedia = !!instance.vcdMediaId;
  const snapshot =
    instance?.serviceSnapshot ||
    scheduledTasks.data.find(
      (t) =>
        t.appEntityId === instance?.appEntityId &&
        t.taskOperationName === 'ServiceSnapshotCreate'
    );

  const menuItems = React.useMemo(
    () =>
      compact(
        generateVmActionItems(
          instance.status,
          {
            isPublic,
            isRSEnabled: !!remoteSupport?.isUsedVm,
            isEditable: permissions.canManage,
            isRSTaskActive:
              !!remoteSupport?.isRequesting || !!remoteSupport?.isTaskActive,
            // @ts-ignore
            snapshot,
            isRequesting: isRequesting || snapshotTask.isTaskActive,
            isTenant,
            isInFolder,
            hasInjectedMedia,
            folders: isOnFolderContent
              ? []
              : folders?.map((folder) => ({
                  value: folder.id.toString(),
                  label: folder.name,
                  onClick: () => handlers.moveToFolder(folder),
                })) || [],
          },
          handlers
        )
      ),
    [
      JSON.stringify(snapshot),
      JSON.stringify(instance),
      JSON.stringify(remoteSupport),
      JSON.stringify(instance?.serviceSnapshot),
      isPublic,
      permissions.canManage,
      isRequesting,
      snapshotTask.isTaskActive,
    ]
  );

  return {
    menuItems,
    actionItems: buildActionsItemsData(menuItems),
    handlers,
    snapshot,
  };
}

export function useDedicatedServerMenuItems(
  config: IConfig<IVmTypes.Vm>
): IResponse {
  const {
    instance,
    handlers,
    isRequesting,
    folders,
    isOnFolderContent,
    isInFolder,
  } = config;
  const { permissions, isTenant } = usePermissions('SERVICES');

  const menuItems = React.useMemo(
    () =>
      compact(
        generateDedicatedServerActionItems(
          instance?.displayStatus as any,
          {
            isEditable: permissions.canManage,
            isTenant,
            isRequesting,
            isInFolder,
            folders: isOnFolderContent
              ? []
              : folders?.map((folder) => ({
                  value: folder.id.toString(),
                  label: folder.name,
                  onClick: () => handlers.moveToFolder(folder),
                })) || [],
          },
          handlers
        )
      ),
    [
      instance?.displayStatus,
      permissions.canManage,
      handlers,
      isRequesting,
      isInFolder,
    ]
  );

  return {
    menuItems,
    actionItems: buildActionsItemsData(menuItems),
    handlers,
  };
}

export function useCoreMenuItems(
  config: IConfig<ICoreInfrastructure>
): IResponse {
  const { remoteSupport, handlers, isRequesting, instance, agent } = config;
  const { permissions, isTenant, isProvider } = usePermissions('SERVICES');
  const snapshotTask = useTask(instance?.serviceSnapshot?.task);
  const scheduledTasks = useStateHandler(StateHandlers.scheduledTasks);
  const snapshot =
    instance?.serviceSnapshot ||
    scheduledTasks.data.find(
      (t) =>
        t.appEntityId === instance?.appEntityId &&
        t?.taskOperationName === 'ServiceSnapshotCreate'
    );

  const menuItems = React.useMemo(
    () =>
      compact(
        generateCoreActionItems(
          instance?.displayStatus,
          {
            isRSEnabled: !!remoteSupport?.isUsedVm,
            isEditable: permissions.canManage,
            isRSTaskActive:
              !!remoteSupport?.isRequesting || !!remoteSupport?.isTaskActive,
            // @ts-ignore
            snapshot,
            isRequesting: isRequesting || snapshotTask.isTaskActive,
            isTenant,
            isProvider,
            agent,
          },
          handlers
        )
      ),
    [
      JSON.stringify(snapshot),
      JSON.stringify(instance),
      JSON.stringify(remoteSupport),
      permissions.canManage,
      snapshotTask.isTaskActive,
      isRequesting,
    ]
  );

  return {
    menuItems,
    actionItems: buildActionsItemsData(menuItems),
    handlers,
    snapshot,
  };
}

export function useRdsMenuItems(config: IConfig<IRds>): IResponse {
  const { remoteSupport, handlers, instance, isRequesting, agent } = config;
  const { permissions, isTenant, isProvider } = usePermissions('SERVICES');
  const snapshotTask = useTask(instance?.serviceSnapshot?.task);
  const scheduledTasks = useStateHandler(StateHandlers.scheduledTasks);
  const snapshot =
    instance?.serviceSnapshot ||
    scheduledTasks.data.find(
      (t) =>
        t.appEntityId === instance?.appEntityId &&
        t.taskOperationName === 'ServiceSnapshotCreate'
    );

  const menuItems = React.useMemo(
    () =>
      compact(
        generateRdsActionItems(
          instance?.displayStatus,
          {
            isRSEnabled: !!remoteSupport?.isUsedVm,
            isEditable: permissions.canManage,
            isRSTaskActive:
              !!remoteSupport?.isRequesting || !!remoteSupport?.isTaskActive,
            // @ts-ignore
            snapshot,
            isRequesting: isRequesting || snapshotTask.isTaskActive,
            isTenant,
            isProvider,
            instances: instance?.host?.hostInstances?.map((inst) => ({
              value: inst.vm?.id.toString(),
              label: inst.vm?.name,
              onClick: () => handlers.console(inst.vm?.id),
            })),
            agent,
          },
          handlers
        )
      ),
    [
      JSON.stringify(snapshot),
      JSON.stringify(instance),
      JSON.stringify(remoteSupport),
      permissions.canManage,
      snapshotTask.isTaskActive,
      isRequesting,
    ]
  );

  const actionItems = buildActionsItemsData(menuItems);

  return {
    menuItems,
    actionItems,
    handlers,
    snapshot,
  };
}

export function useFolderMenuItems(
  config: IConfig<IServiceGroups.Group>
): IResponse {
  const { handlers, isRequesting } = config;
  const { permissions } = usePermissions('SERVICES');

  const menuItems = React.useMemo(
    () =>
      compact([
        {
          value: 'openInnerContent',
          label: 'openInnerContent',
          onClick: handlers.openInnerContent,
        },
        {
          value: 'edit',
          label: 'edit',
          onClick: handlers.edit,
          disabled: isRequesting || !permissions.canManage,
        },
        {
          value: 'delete',
          label: 'delete',
          onClick: handlers.delete,
          disabled: isRequesting || !permissions.canManage,
        },
      ]),
    [permissions.canManage]
  );

  const actionItems = buildActionsItemsData([]);

  return {
    menuItems,
    actionItems,
    handlers,
  };
}
