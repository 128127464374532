import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useQuery, usePermissions, useStateHandler } from 'hooks';
import ExternalNetworkDetails from './ExternalNetworkDetails/ExternalNetworkDetails';

const Network = observer(() => {
  const {
    permissions: { canManage: isEditable },
  } = usePermissions('SERVICES');
  const { query, queryStr } = useQuery();
  const vmDetails = useStateHandler(StateHandlers.vmDetails);

  const isPublic = vmDetails.data?.networkType === 'PUBLIC';

  const fetchVm = useCallback(async () => {
    if (query.id) {
      return await vmDetails.get({
        id: query.id,
        include: ['serviceMonitoring', 'serviceSnapshot'],
      });
    }
  }, [queryStr]);

  return (
    <ExternalNetworkDetails
      vmId={vmDetails.data?.id}
      onAddApiSuccess={fetchVm}
      isPublic={isPublic}
      isEditable={isEditable}
    />
  );
});

export default Network;
