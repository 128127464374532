import * as React from 'react';
import Info from './Info';

const Main = () => {
  return (
    <>
      <Info />
    </>
  );
};

export default Main;
