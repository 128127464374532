import React from 'react';
import { Button, Dialog, DialogProps, Input, Row } from 'elements';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

type Props = DialogProps<BackupTypes.CreateNotificationEmail> & {
  testId: string;
  disabled: boolean;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('forms.emailInvalid').required('forms.required'),
});

const INITIALS = {
  email: '',
};

const CreateUserDialog = (props: Props) => {
  const { onSave, testId, disabled, ...rest } = props;
  const { t } = useTranslation();
  const { handleSubmit, values, errors, resetForm, handleChange, submitCount } =
    useFormik({
      initialValues: INITIALS,
      validateOnMount: false,
      validationSchema,
      onSubmit: (val) => Promise.resolve(onSave && onSave(val)),
    });

  React.useEffect(() => {
    if (!rest.open) {
      resetForm();
    }
  }, [rest.open]);

  return (
    <Dialog
      {...rest}
      testId={testId}
      title={t('services.backup.dialog.createUser.title')}
      handleSubmit={handleSubmit}
      actions={
        <Row justifyContent="flex-end">
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={rest.onClose}
            testId={`${testId}-cancel`}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" testId={`${testId}`} disabled={disabled}>
            {t('common.save')}
          </Button>
        </Row>
      }
    >
      <div className="steel mb-20">
        {t('services.backup.dialog.createUser.subtitle')}
      </div>
      <Input
        id="email"
        value={values.email}
        name="email"
        label="forms.email"
        placeholder="forms.placeholders.email"
        onChange={handleChange}
        error={!!submitCount && !!errors.email}
        helperText={errors.email}
      />
    </Dialog>
  );
};

export default CreateUserDialog;
