import * as React from 'react';
import { DialogProps, Dialog, Button, Input, Row, Col, Loader } from 'elements';
import { useTranslation } from 'react-i18next';
import { partnersService } from 'services';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useStateHandler, usePermissions } from 'hooks';
import { DEFAULT_QUOTAS } from 'enums';
import { useFormik } from 'formik';
import { showSystemMessage, round } from 'utils';
import { quotasValidationSchema } from './constants';

type IProps = React.PropsWithChildren<
  DialogProps & {
    partnerId: number;
  }
>;

const PartnerQuotasDialog = observer((props: IProps) => {
  const { open, onClose, partnerId } = props;
  const { t } = useTranslation();
  const { isEvaluation } = usePermissions();
  const currentPartner = useStateHandler(StateHandlers.currentPartner);
  const partnerQuota = useStateHandler(StateHandlers.partnerQuota);

  const isRequesting = currentPartner.isRequesting || partnerQuota.isRequesting;

  const quotaPermNamespace = isEvaluation ? 'eval' : 'prod';

  const initialValues: IQuotaTypes.IQuotaFormValues = {
    cpu: DEFAULT_QUOTAS[quotaPermNamespace].virtualCpus,
    ram: DEFAULT_QUOTAS[quotaPermNamespace].ramMb,
    diskSize: DEFAULT_QUOTAS[quotaPermNamespace].diskSizeGb,
  };

  const onSave = React.useCallback(
    async (data: IQuotaTypes.IQuotaFormValues) => {
      await partnersService.setQuotes(partnerId, data);
      await partnerQuota.update(partnerId, {
        virtualCpus: data.cpu,
        ramMb: round(+data.ram * 1024, 0),
        diskSizeGb: data.diskSize,
      });
    },
    [partnerId]
  );

  const {
    values,
    handleChange,
    setValues,
    resetForm,
    handleSubmit,
    isSubmitting,
    submitCount,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: quotasValidationSchema,
    onSubmit: (data) =>
      onSave(data)
        .then(() => {
          showSystemMessage('partners.notify.quotes.update.success', 'success');
          onClose();
        })
        .catch((err) => showSystemMessage(err.message, 'error')),
  });

  const fetchData = React.useCallback(async () => {
    const partner = await currentPartner.get({ id: partnerId });
    const quotas = await partnerQuota.get({ id: partnerId });
    const cpu =
      quotas?.quota?.virtualCpus ||
      quotas?.defaultTenantsQuota?.virtualCpus ||
      0;
    const ram =
      (quotas?.quota?.ramMb || quotas?.defaultTenantsQuota?.ramMb || 0) / 1024;
    const diskSize =
      quotas?.quota?.diskSizeGb || quotas?.defaultTenantsQuota?.diskSizeGb || 0;
    return {
      cpu,
      ram,
      diskSize,
      maxTenants: partner?.maxTenants || 0,
    };
  }, [partnerId, currentPartner.data]);

  React.useEffect(() => {
    if (open) {
      fetchData().then((res) => {
        setValues(res as any);
      });
    }

    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      handleSubmit={handleSubmit}
      title={t('partners.dialogs.quotes.title')}
      actions={
        <>
          <Button variant="outlined" color="default" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isSubmitting || isRequesting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      {isRequesting ? (
        <Loader />
      ) : (
        <>
          <div className="fs-14 uppercase bolder mb-10">
            {t('partners.dialogs.quotes.content.sections.tenants') as string}
          </div>
          <Row columnSpacing={2} className="mb-25">
            <Col xs={4}>
              <Input
                label={t('partners.dialogs.quotes.content.forms.head.max')}
                onChange={handleChange}
                value={values.maxTenants}
                name="maxTenants"
                type="number"
                error={!!submitCount && !!errors.maxTenants}
                helperText={errors.maxTenants}
                helperTextOptions={{
                  max: 9999,
                  min: 1,
                }}
              />
            </Col>
          </Row>

          <div className="fs-14 uppercase bolder mb-10">
            {t('partners.dialogs.quotes.content.sections.resources') as string}
          </div>
          <div className="mb-10">
            {t('partners.dialogs.quotes.content.sections.currentQuotas')}
          </div>
          <div className="mb-20">
            <div className="mb-5">
              {t('partners.dialogs.quotes.content.forms.head.cpu')}:{' '}
              <span className="steel">
                {partnerQuota.data?.usedResources?.virtualCpus}
              </span>
            </div>
            <div className="mb-5">
              {t('partners.dialogs.quotes.content.forms.head.ram')}:{' '}
              <span className="steel">
                {partnerQuota.data?.usedResources?.ramMb / 1024}
              </span>
            </div>
            <div>
              {t('partners.dialogs.quotes.content.forms.head.diskSize')}:{' '}
              <span className="steel">
                {partnerQuota.data?.usedResources?.diskSizeGb}
              </span>
            </div>
          </div>
          <div className="steel fs-14 mb-20">
            {t('partners.dialogs.quotes.content.description') as string}
          </div>
          <Row columnSpacing={2}>
            <Col xs={4}>
              <Input
                label={t('partners.dialogs.quotes.content.forms.head.cpu')}
                onChange={handleChange}
                value={values.cpu}
                name="cpu"
                type="number"
                error={!!submitCount && !!errors.cpu}
                helperText={errors.cpu}
                helperTextOptions={{
                  max: 999999,
                  min: 1,
                }}
              />
            </Col>
            <Col xs={4}>
              <Input
                label={t('partners.dialogs.quotes.content.forms.head.ram')}
                onChange={handleChange}
                value={values.ram}
                name="ram"
                type="number"
                error={!!submitCount && !!errors.ram}
                helperText={errors.ram}
                helperTextOptions={{
                  max: 999999,
                  min: 1,
                }}
              />
            </Col>
            <Col xs={4}>
              <Input
                label={t('partners.dialogs.quotes.content.forms.head.diskSize')}
                onChange={handleChange}
                value={values.diskSize}
                name="diskSize"
                type="number"
                error={!!submitCount && !!errors.diskSize}
                helperText={errors.diskSize}
                helperTextOptions={{
                  max: 9999999,
                  min: 1,
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </Dialog>
  );
});

export default PartnerQuotasDialog;
