import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, DateTimePicker, Switch, Input } from 'elements';
import {
  FormikState,
  FormikHelpers,
  FormikHandlers,
  FormikComputedProps,
} from 'formik';
import { dayjs, findDisabledMinTime } from 'utils';
import { PATCHING_INTERVAL_OPTIONS, MINUTE_STEP } from './constants';
import { FormInputsWrapper } from './Styled';

type IProps = {
  isEditable: boolean;
  showOutsourceManagement?: boolean;
  formInstance: FormikState<IPatchingTypes.Values> &
    FormikHelpers<IPatchingTypes.Values> &
    FormikHandlers &
    FormikComputedProps<IPatchingTypes.Values>;
};

const Form = (props: IProps) => {
  const { formInstance: form, isEditable, showOutsourceManagement } = props;
  const { t } = useTranslation();

  const startDate = form.values.startDate
    ? dayjs(form.values.startDate)
    : dayjs();

  const disabledTime = findDisabledMinTime(startDate);

  return (
    <>
      <FormInputsWrapper>
        <div className="mb-15">
          <Select
            isDisabled={!isEditable}
            label={t('forms.recurring')}
            value={form.values.recurringInterval}
            onChange={(recurringInterval) =>
              form.setFieldValue('recurringInterval', recurringInterval)
            }
            options={PATCHING_INTERVAL_OPTIONS}
          />
        </div>
        <div className="mb-35">
          <DateTimePicker
            disabled={!isEditable}
            label={t('forms.startDate')}
            maximumDate={dayjs().add(31, 'days').toDate()}
            minimumDate={dayjs().toDate()}
            value={startDate}
            onChange={(val) => {
              form.setFieldValue('startDate', val);
            }}
            closeOnSelect
            minuteStep={MINUTE_STEP}
            disabledTime={() => {
              return {
                disabledHours: disabledTime.hours,
                disabledMinutes: disabledTime.minutes,
              };
            }}
          />
          {form.errors.startDate && (
            <div className="error fs-12 pt-5 ">
              {t(form.errors.startDate as string)}
            </div>
          )}
        </div>
      </FormInputsWrapper>

      <div className="mb-30">
        <Switch
          disabled={!isEditable}
          name="notificationEnabled"
          checked={form.values.notificationEnabled}
          onCheck={(notificationEnabled) =>
            form.setFieldValue('notificationEnabled', notificationEnabled)
          }
          label={t('patching.checkbox.notifyUsers')}
        />
      </div>
      <div className="mb-25">
        <Input
          disabled={!isEditable}
          name="alertEmailRecipient"
          label={t('patching.inputs.alertEmail')}
          placeholder={t('forms.placeholders.email')}
          value={form.values.alertEmailRecipient || ''}
          onChange={form.handleChange}
          error={!!form.errors.alertEmailRecipient}
          helperText={form.errors.alertEmailRecipient}
        />
      </div>
      {showOutsourceManagement && (
        <div className="mb-15">
          <Switch
            label={t('patching.switchers.systems')}
            checked={form.values.outsourceManagementEnabled}
            disabled={!isEditable}
            onCheck={(outsourceManagementEnabled) =>
              form.setFieldValue(
                'outsourceManagementEnabled',
                outsourceManagementEnabled
              )
            }
          />
        </div>
      )}
    </>
  );
};

export default Form;
