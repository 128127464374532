import mockResponse from '../mocks.json';
import { GlobalResponse } from 'enums';
import { pathString } from './constants';

export const fakeFetch = (
  url: string,
  status: number
): Promise<GlobalResponse<any>> => {
  return new Promise((resolve) => {
    setTimeout(function () {
      resolve({
        info: {
          status,
        },
        data: pathString(url)(mockResponse),
      });
    }, 2000);
  });
};

export const fakeEmptyFetch = (
  payload: any,
  delay?: number
): Promise<typeof payload> => {
  return new Promise((resolve) => {
    setTimeout(function () {
      resolve({
        info: {
          status: 200,
        },
        data: payload,
      });
    }, delay || 2000);
  });
};
