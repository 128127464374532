import * as React from 'react';
import cn from 'classnames';
import { Paper, Row, Col, IconButton, MuiIcons, Button } from 'elements';
import { useTranslation } from 'react-i18next';
import { useState } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { EditorState, ContentState } from 'draft-js';
// @ts-ignore
import { Editor } from 'react-draft-wysiwyg';
import { HTMLContainer } from './Styled';
import {
  TOOLBAR_EDITOR_OPTIONS,
  TENANT_REQ_CONFIG,
  NotesYupValidationSchema,
} from './constants';
import { decorators, parseNotesFromForm, parseNotesForForm } from './helpers';

type IState = {
  editMode: boolean;
  error?: string;
  notes: EditorState;
};

const emptyEditorState = EditorState.createWithContent(
  ContentState.createFromText('')
);

const INITIAL_STATE: IState = {
  editMode: false,
  notes: emptyEditorState,
};

const OBSERVERS = {
  currentUser: StateHandlers.currentUser,
  currentTenant: StateHandlers.currentTenant,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { currentUser, currentTenant } = props;
  const { t } = useTranslation();
  const [state, handleChange] = useState<IState>(INITIAL_STATE);
  const tenantId = currentUser.data?.tenant?.id as number;
  const currentNotes = currentTenant.data?.settings?.notes || '';

  const handleMode = React.useCallback(
    (editMode: boolean) => () =>
      handleChange({
        editMode,
        notes: editMode ? parseNotesForForm(currentNotes) : undefined,
      }),
    [currentNotes]
  );

  const getTenant = React.useCallback(
    () =>
      currentTenant.get({
        id: tenantId,
        include: 'settings',
        config: TENANT_REQ_CONFIG,
      }),
    [tenantId]
  );

  const onSave = React.useCallback(
    () =>
      currentTenant
        .update(tenantId, {
          settings: { notes: parseNotesFromForm(state.notes) },
          config: TENANT_REQ_CONFIG,
        })
        .then(getTenant)
        .then(handleMode(false)),
    [currentNotes, tenantId, state.notes]
  );

  React.useEffect(() => {
    getTenant();
  }, [tenantId]);

  return (
    <Paper className="pt-20 pb-20 pl-30 pr-30 mb-10">
      <Row alignItems="center" justifyContent="space-between" className="mb-20">
        <Col>
          <h5>{t('settings.page.partnerInfo.section.notes.title')}</h5>
        </Col>
        <Col>
          <IconButton onClick={handleMode(true)}>
            <MuiIcons.Edit />
          </IconButton>
        </Col>
      </Row>
      {state.editMode ? (
        <div>
          <div
            className={cn('mb-15', {
              hasError: !!state.error,
            })}
          >
            <Editor
              customDecorators={decorators}
              editorState={state.notes}
              wrapperClassName={cn('text-editor mb-5')}
              toolbar={TOOLBAR_EDITOR_OPTIONS}
              placeholder={t('forms.placeholders.description')}
              onEditorStateChange={async (notes: EditorState) => {
                const error = await NotesYupValidationSchema.validate(notes)
                  .then(() => null)
                  .catch((err) => {
                    return err.errors[0];
                  });

                handleChange({ notes, error });
              }}
            />
            {state.error && <div className="error fs-12">{t(state.error)}</div>}
          </div>
          <Row columnSpacing={2}>
            <Col>
              <Button
                onClick={handleMode(false)}
                disabled={currentTenant.isRequesting}
              >
                {t('common.cancel')}
              </Button>
            </Col>
            <Col>
              <Button
                color="default"
                variant="outlined"
                disabled={currentTenant.isRequesting}
                onClick={onSave}
              >
                {t('common.save')}
              </Button>
            </Col>
          </Row>
        </div>
      ) : currentNotes ? (
        <HTMLContainer
          dangerouslySetInnerHTML={{
            __html: currentNotes.split('<p></p>').join('<br/>'),
          }}
          className="_html break-word-all"
        />
      ) : (
        <div className="steel">-</div>
      )}
    </Paper>
  );
});

const Notes = () => <View {...OBSERVERS} />;

export default Notes;
