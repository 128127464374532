export const RECOURCE_UNIT_MAP: Record<number, string> = {
  5: '',
  6: '',
  16: '',
  20: '',
  9: '',
  3: 'GB',
  10: 'GB',
  11: 'GB',
  15: 'TB',
  21: 'GB',
};
