import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { configure } from 'mobx';
import App from './App';

configure({
  enforceActions: 'never',
});

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(<App />);
