import * as React from 'react';
import { Row, Col, IconButton, MuiIcons, ColorDot } from 'elements';

type IProps = IBadge & {
  onEdit: (badge: IBadge) => void;
  onDelete: (badge: IBadge) => void;
};

const BadgeItem = (props: IProps) => {
  const { onEdit, onDelete, ...badge } = props;
  return (
    <Row columnSpacing={2} className="mb-25" alignItems="center">
      <Col xs={3}>
        <Row columnSpacing={4} alignItems="center">
          <Col>
            <ColorDot size={24} color={badge.color} />
          </Col>
          <Col xs>{`#${badge.color}`}</Col>
        </Row>
      </Col>
      <Col xs={6} className="break-word-all">
        {badge.description}
      </Col>
      <Col xs={3}>
        <Row
          columnSpacing={2}
          className="full-width"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Col>
            <IconButton onClick={() => onEdit(badge)}>
              <MuiIcons.Edit />
            </IconButton>
          </Col>
          <Col>
            <IconButton onClick={() => onDelete(badge)}>
              <MuiIcons.Delete />
            </IconButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BadgeItem;
