import * as R from 'ramda';
import {
  ICostPerServiceExt,
  ICostMap,
  ICostPerResource,
  IEntityCost,
} from 'usage';
import { GLOBAL_DATE_FORMAT } from 'enums';
import { dayjs } from 'utils';

const groupItems = R.groupBy((p: any) => p.serviceId.toString());

function defineMonthPrice(currentCost: number, periodInMinutes: number) {
  const costForMinute = (currentCost || 0) / (periodInMinutes || 0);
  return costForMinute * 60 * 24 * 30 || 0;
}

function buildMonthPriceResult(
  resources: ICostPerResource[]
): IUsageInfoBoxTypes.IResourceCostMap {
  return R.reduce<ICostPerResource, IUsageInfoBoxTypes.IResourceCostMap>(
    (res, val) => {
      const monthPrice = defineMonthPrice(val.cost, val.periodInMinutes);
      res.resources.push({
        id: val.resourceId,
        monthPrice,
      });
      res.total = res.total + monthPrice;
      return res;
    },
    { resources: [], total: 0 },
    resources
  );
}

export function groupServiceEntities(
  data?: ICostMap
): IUsageInfoBoxTypes.IGroupServiceEntitiesResult {
  if (!data) return { data: [], costForMonth: {}, total: 0 };

  const costForMonth: IUsageInfoBoxTypes.IGroupServiceEntitiesResult['costForMonth'] =
    {};
  let totalMonthCost = 0;

  const allServices: ICostPerServiceExt[] = data.costPerService.map((s) => ({
    ...s,
    total: s.cost,
  }));

  const groupedByResource = groupItems(data.costPerResource);
  const groupedByEntity = groupItems(data.costPerEntity);

  const result = allServices.map((s) => {
    const entities = groupedByEntity[s.serviceId] || [];
    const hasSubEntities = entities.length > 1;
    const showFilters = s.serviceId === 4 && hasSubEntities; // allow only for virtual machines
    const resources = hasSubEntities
      ? []
      : entities[0]?.costPerResource || groupedByResource[s.serviceId];
    const constantEntities: IEntityCost[] = groupedByEntity[s.serviceId] || [
      { costPerResource: groupedByResource[s.serviceId], appEntity: s },
    ];

    costForMonth[s.serviceId] = hasSubEntities
      ? {
          entities: R.reduce<
            IEntityCost,
            Record<string, IUsageInfoBoxTypes.IResourceCostMap>
          >(
            (res, val) => {
              res[val.appEntity.id] = buildMonthPriceResult(
                val.costPerResource
              );
              return res;
            },
            {},
            constantEntities
          ),
        }
      : buildMonthPriceResult(resources);

    if (hasSubEntities) {
      const sumOfEntitiesResources = R.sum(
        // @ts-ignore
        R.keys(costForMonth[s.serviceId].entities).map(
          // @ts-ignore
          (entId) => costForMonth[s.serviceId].entities[entId].total
        )
      );

      totalMonthCost = totalMonthCost + sumOfEntitiesResources;
    } else {
      // @ts-ignore
      totalMonthCost = totalMonthCost + costForMonth[s.serviceId].total;
    }

    return {
      service: s,
      resources,
      entities: constantEntities,
      showFilters,
      hasSubEntities,
    };
  });

  return { data: result, costForMonth, total: totalMonthCost };
}

export function createPeriodStr(startDate?: string, endDate?: string): string {
  return startDate
    ? `(${dayjs(startDate).format(GLOBAL_DATE_FORMAT)} - ${dayjs(
        endDate
      ).format(GLOBAL_DATE_FORMAT)})`
    : '';
}
