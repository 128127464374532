import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
export *  from '@mui/material/Grid';

const Row = React.forwardRef((props: GridProps, ref: any) => (
  <Grid {...props} container ref={ref} />
));

const Col = React.forwardRef((props: GridProps, ref: any) => (
  <Grid {...props} item ref={ref} />
));

export { Row, Col, Stack };
