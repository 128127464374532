import * as R from 'ramda';

type IBadgeItemPayload = { id: number };

export function parseStateForForm(
  data: IBadgeDialogTypes.IValues
): IBadgeItemPayload[] {
  const result: IBadgeItemPayload[] = [];

  R.keys(data).forEach((key) => {
    if (data[key]) {
      result.push({ id: +key });
    }
  });

  return result;
}
