import * as React from 'react';
import { Row, Col, MuiIcons } from 'elements';
import { IUserAvatar, IRdsUserPayload } from 'rds';
import { useTranslation } from 'react-i18next';
import UserTextAvatar from './UserTextAvatar';

type IProps = IRdsUserPayload & {
  prevType: IRdsUserPayload['type'] | 'DISABLED';
  prev2FA: boolean;
  prevBypass: boolean;
};

function onOff(value: boolean) {
  return value ? 'ON' : 'OFF';
}

const ChangeLogItem = (props: IProps) => {
  const { prevType, prev2FA, prevBypass } = props;
  const { t } = useTranslation();

  const nextType = props.action === 'DELETE' ? 'DISABLED' : props.type;
  const isDifferentType = prevType !== nextType;
  const isDifferent2FA = prev2FA !== !!props.is2faEnabled;
  const isDifferentBypass = prevBypass !== !!props.twoFaBypass;
  return (
    <>
      {isDifferentType && (
        <Row className="mb-10" alignItems="center">
          <Col xs={6}>
            <UserTextAvatar {...(props.details as IUserAvatar)} />
          </Col>
          <Col xs={6}>
            <Row alignItems="center" columnSpacing={3}>
              <Col xs={5}>
                {t(`services.rds.users.changeType.${prevType}`) as string}
              </Col>
              <MuiIcons.ArrowRightAlt className="primary fs-20 lh-1" />
              <Col xs={5}>{t(`services.rds.users.changeType.${nextType}`)}</Col>
            </Row>
          </Col>
        </Row>
      )}
      {isDifferent2FA && (
        <Row className="mb-10" alignItems="center">
          <Col xs={6}>
            <UserTextAvatar {...(props.details as IUserAvatar)} />
          </Col>
          <Col xs={6}>
            <Row alignItems="center" columnSpacing={3}>
              <Col xs={5}>
                {t(`services.rds.users.changeType.2fa${onOff(prev2FA)}`) as string}
              </Col>
              <MuiIcons.ArrowRightAlt className="primary fs-20 lh-1" />
              <Col xs={5}>
                {t(
                  `services.rds.users.changeType.2fa${onOff(
                    props.is2faEnabled
                  )}`
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {isDifferentBypass && (
        <Row className="mb-10" alignItems="center">
          <Col xs={6}>
            <UserTextAvatar {...(props.details as IUserAvatar)} />
          </Col>
          <Col xs={6}>
            <Row alignItems="center" columnSpacing={3}>
              <Col xs={5}>
                {
                  t(
                    `services.rds.users.changeType.bypass${onOff(prevBypass)}`
                  ) as string
                }
              </Col>
              <MuiIcons.ArrowRightAlt className="primary fs-20 lh-1" />
              <Col xs={5}>
                {t(
                  `services.rds.users.changeType.bypass${onOff(
                    props.twoFaBypass
                  )}`
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ChangeLogItem;
