import * as React from 'react';
import { Row, Dialog, Button, DialogProps } from 'elements';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import RebootRuleBaseForm from './RebootRuleBaseForm';
import { buildInitialState, rebootValidationSchema } from './constants';

type IProps = DialogProps<IRebootDialogTypes.Values> & {
  subtitle?: string;
  isRdsReconfiguration?: boolean;
  isEnabledMode?: boolean;
};

const PlanRebootDialog = (props: IProps) => {
  const {
    isEnabledMode,
    onSave,
    open,
    onClose,
    subtitle,
    isRdsReconfiguration,
    ...rest
  } = props;
  const { t } = useTranslation();

  const { handleSubmit, setFieldValue, values, resetForm, errors } = useFormik({
    initialValues: buildInitialState(undefined, 'now'),
    validationSchema: rebootValidationSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: onSave as any,
  });

  React.useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      {...rest}
      open={open}
      handleSubmit={handleSubmit}
      onClose={onClose}
      fullWidth
      actions={
        <Row justifyContent="flex-end">
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={onClose}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit">{t('common.save')}</Button>
        </Row>
      }
    >
      {subtitle && <div className="steel mb-40">{subtitle}</div>}
      <RebootRuleBaseForm
        values={values}
        onChange={setFieldValue}
        errors={errors}
        isEnabledMode={isEnabledMode}
        isRdsReconfiguration={isRdsReconfiguration}
      />
    </Dialog>
  );
};

export default PlanRebootDialog;
