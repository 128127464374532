import * as React from 'react';
import { MuiIcons, Row, Col, Button, Loader } from 'elements';
import { useState, useQuery } from 'hooks';
import { confirm } from 'utils';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import ListItem from './ListItem';
import MaintenanceDialog from './MaintenanceDialog';

const OBSERVERS = {
  monitoring: StateHandlers.monitoring,
  maintenance: StateHandlers.monitoringMaintenance,
};

type IProps = {
  monitoringId: number;
};

type State = {
  open: boolean;
  currentMw?: IMonitoring.MonitoringMW;
};

type IViewProps = typeof OBSERVERS;

const STATE: State = {
  open: false,
  currentMw: undefined,
};

const View = observer((props: IProps & IViewProps) => {
  const { monitoringId, maintenance } = props;
  const { t } = useTranslation();
  const [state, handleState] = useState<State>(STATE);
  const { changeQuery } = useQuery();

  const onEdit = React.useCallback(
    (mw: IMonitoring.MonitoringMW) => {
      handleState({ open: true, currentMw: mw });
    },
    [monitoringId]
  );

  const onDelete = React.useCallback(
    (mw: IMonitoring.MonitoringMW) => {
      confirm({
        title: 'monitoring.maintenance.confirm.remove.title',
        content: 'monitoring.maintenance.confirm.remove.content',
        onSuccess: () =>
          maintenance.remove({ monitoringId, id: mw.id }).then(() => {
            return maintenance.get({ id: monitoringId });
          }),
        onCancel: () => undefined,
      });
    },
    [monitoringId]
  );

  React.useEffect(() => {
    maintenance.get({ id: monitoringId });
    return () => {
      maintenance.reset();
    };
  }, [monitoringId]);

  const hasMaintenance = !!maintenance.data.length;

  if (!maintenance.dataReceived) {
    return <Loader />;
  }

  return (
    <>
      <Row
        columnSpacing={1}
        alignItems="center"
        className="steel fs-14 mb-25 pointer"
        onClick={() => changeQuery({ page: undefined })}
      >
        <MuiIcons.ArrowBack className="fs-18" />
        <Col>{t('monitoring.maintenance.backText')}</Col>
      </Row>
      <Row
        columnSpacing={2}
        alignItems="center"
        justifyContent="space-between"
        className="mb-25"
      >
        <Col className="fs-20 bolder">{t('monitoring.maintenance.title')}</Col>
        <Col>
          <Button
            size="small"
            onClick={() => handleState({ open: true, currentMw: undefined })}
          >
            {t('monitoring.maintenance.actions.add')}
          </Button>
        </Col>
      </Row>
      <div className="mb-25">{t('monitoring.maintenance.description')}</div>

      {!hasMaintenance ? (
        <div className="text-center steel fs-14 p-30">{t('common.noData')}</div>
      ) : (
        maintenance.data.map((m) => {
          return (
            <ListItem {...m} key={m.id} onEdit={onEdit} onDelete={onDelete} />
          );
        })
      )}

      <MaintenanceDialog
        open={state.open}
        currentMw={state.currentMw}
        onClose={() => handleState({ open: false, currentMw: undefined })}
        onSave={() => {
          maintenance.get({ id: monitoringId });
        }}
        monitoringId={monitoringId}
      />
    </>
  );
});

const Main = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default Main;
