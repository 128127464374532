import { PALETTE, GLOBAL_BORDER_RADIUS } from 'enums';
import { remCalc, important } from 'utils';
import { Row, Button, styled } from 'elements';

export const ListItemText = styled('div')(({ theme }) => ({
  paddingLeft: remCalc(28),
  position: 'relative',
  lineHeight: remCalc(20),
  '&:before': {
    content: "''",
    width: remCalc(8),
    height: remCalc(8),
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    left: 0,
    top: 'calc(50% - 4px)',
  },
}));

export const TextBadge = styled('div')(({ theme }) => ({
  color: '#fff',
  position: 'absolute',
  left: 'calc(100% + 5px)',
  top: 0,
  backgroundColor: theme.palette.success.main,
  fontSize: remCalc(8),
  borderRadius: GLOBAL_BORDER_RADIUS,
  lineHeight: remCalc(12),
  height: remCalc(12),
  padding: remCalc(0, 7),
  opacity: 0.5,
}));

export const ResourceBlockRow = styled(Row)(({ theme }) => ({
  marginTop: remCalc(5),
  minHeight: remCalc(40),
  lineHeight: remCalc(40),
}));

export const ResourceBlockTitle = styled(Row)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const UserNameText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SButton = styled(Button)(({ theme }) => ({
  height: remCalc(34),
  fontSize: important(remCalc(14)),
  minWidth: remCalc(75),
}));

export const SCheckboxWrapperAbsolute = styled('div')({
  position: 'absolute',
  left: 0,
  bottom: remCalc(-10),
});

export const SSliderWrapper = styled('div')(({ theme }) => ({
  maxWidth: remCalc(520),
  '& ._slider_input_error': {
    marginTop: important(remCalc(-10)),
    position: important('absolute'),
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 0,
  },
}));

export const SInfoLabel = styled('sup')(({ theme }) => ({
  verticalAlign: 'middle',
  fontSize: remCalc(12),
  color: PALETTE.info,
  '& svg': {
    paddingLeft: remCalc(5),
    fontSize: remCalc(17),
  },
}));
