import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { remCalc, isString } from 'utils';
import { HorizontalBadges } from 'components';
import { Row } from 'elements';
import CardMenuItems from './CardMenuItems';
import { FolderCardHolderRoot, HolderFooter, Skeleton } from './Styled';

const FolderCardHolder = (props: ICardProps) => {
  const {
    title,
    otherTitleContent,
    className,
    isDisabled,
    onTitleClick,
    menuItems,
    testId,
    dataReceived,
    titleIcon,
    assignments,
    shouldDisablePartially,
    // onDoubleClick,
  } = props;

  const { t } = useTranslation();

  const disableTitleIcons = shouldDisablePartially ? false : isDisabled;

  if (!dataReceived) {
    return <Skeleton animation="wave" variant="rectangular" />;
  }

  return (
    <FolderCardHolderRoot className={className}>
      <div>
        <div className={cn('full-width')}>
          <Row
            justifyContent="space-between"
            alignItems="center"
            className="mb-10 lh-1"
          >
            <div
              className={cn('flex align-center relative', {
                disabled: isDisabled,
              })}
            >
              {titleIcon}
              <h5
                onClick={onTitleClick}
                className={cn(onTitleClick && 'pointer')}
              >
                {isString(title) ? t(title) : title}
              </h5>
            </div>
            <div>
              <Row
                alignItems="center"
                justifyContent="flex-end"
                columnSpacing={1}
                style={{ transform: 'translateX(10px)' }}
                className={cn({ disabled: disableTitleIcons })}
              >
                {otherTitleContent}
                {!!menuItems?.length && (
                  <CardMenuItems items={menuItems} testId={testId} />
                )}
              </Row>
            </div>
          </Row>
          <div
            style={{ minHeight: remCalc(250) }}
            className={cn({ disabled: isDisabled })}
          >
            {props.children}
          </div>
        </div>

        <HolderFooter>
          <HorizontalBadges size={16} assignments={assignments} />
        </HolderFooter>
      </div>
    </FolderCardHolderRoot>
  );
};

export default FolderCardHolder;
