import * as React from 'react';
import { styled, Accordion, MuiIcons, Button, Paper } from 'elements';
import { PALETTE } from 'enums';
import { remCalc } from 'utils';
import { ALL_STYLES } from './constants';

export const AccordionRoot = styled(Accordion)(() => ALL_STYLES.accordionRoot);

export const MainPage = styled('div')(({ theme }) => ({
  minWidth: remCalc(1465),
}));

export const TableHead = styled(Paper)(({ theme }) => ({
  padding: remCalc(12, 15, 12, 30),
}));

export const ExpandIcon = styled((props: any & { expanded: boolean }) => {
  const { expanded, ...rest } = props;
  const Icon = props.expanded ? MuiIcons.ExpandLess : MuiIcons.ExpandMore;
  return <Icon {...rest} />;
})(({ theme }) => ({
  backgroundColor: PALETTE.paleGrey,
  color: PALETTE.primary,
  width: remCalc(24),
  height: remCalc(24),
  lineHeight: remCalc(24),
  textAlign: 'center',
  borderRadius: remCalc(3),
  transition: theme.transitions.create('background-color', {
    easing: 'ease-in-out',
    duration: 150,
    delay: 0,
  }),
  '&:hover': {
    backgroundColor: PALETTE.info,
  },
}));

export const ClearButton = styled(Button)(({ theme }) => ({
  height: remCalc(48),
  width: '100%',
}));
