import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissions, useStateHandler } from 'hooks';
import { Row, Col } from 'elements';
import { numberToCurrency } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { parseIncomeStats } from './helpers';

const IncomeStatistics = observer(() => {
  const { t } = useTranslation();
  const incomeStatistic = useStateHandler(StateHandlers.invoiceIncomeStats);
  const { permissions: statisticPermissions } = usePermissions(
    'INVOICES_STATISTICS'
  );

  const pasrseData = parseIncomeStats(incomeStatistic.data);

  if (statisticPermissions.canView) {
    return (
      <Row
        alignItems="center"
        columnSpacing={2}
        className="mb-25 lh-1 bold"
        justifyContent="space-between"
      >
        <Col>
          <span className="steel">
            {t('invoices.statistic.income.thisMonth')}:
          </span>
          <span className="pl-10">
            {numberToCurrency(pasrseData.perMonth.thisMonth, false)} CHF
          </span>
        </Col>
        <Col>
          <span className="steel">
            {t('invoices.statistic.income.lastMonth')}:
          </span>
          <span className="pl-10">
            {numberToCurrency(pasrseData.perMonth.lastMonth, false)} CHF
          </span>
        </Col>
        <Col>
          <span className="steel">
            {t('invoices.statistic.income.thisYear')}:
          </span>
          <span className="pl-10">
            {numberToCurrency(pasrseData.perYear.thisYear, false)} CHF
          </span>
        </Col>
        <Col>
          <span className="steel">
            {t('invoices.statistic.income.lastYear')}:
          </span>
          <span className="pl-10">
            {numberToCurrency(pasrseData.perYear.lastYear, false)} CHF
          </span>
        </Col>
        <Col>
          <span className="steel">
            {t('invoices.statistic.income.forecast')}:
          </span>
          <span className="pl-10">
            {numberToCurrency(pasrseData.forecast, false)} CHF
          </span>
        </Col>
      </Row>
    );
  }

  return null;
});

export default IncomeStatistics;
