import { POST } from 'utils';
import { AxiosPromise } from 'axios';

export const createAccount = (
  partnerId: number,
  payload: IStripe.CreateAccountPayload
): AxiosPromise<IStripe.BaseResponse> => {
  return POST(
    `/partners/${partnerId}/create-connected-stripe-account`,
    {},
    payload
  );
};

export const refreshAccountCreationSession = (
  partnerId: number,
  payload: IStripe.CreateAccountPayload
): AxiosPromise<IStripe.BaseResponse> => {
  return POST(
    `/partners/${partnerId}/create-connected-stripe-account/refresh`,
    {},
    payload
  );
};

export const payInvoice = (
  invoiceId: number,
  payload: IStripe.CreateAccountPayload
): AxiosPromise<IStripe.BaseResponse> => {
  return POST(
    `/invoices/${invoiceId}/payments/stripe`,
    {},
    payload
  );
};
