import * as React from 'react';
import { TFunction } from 'i18next';
import { ValueType } from 'global-shapes';
import {
  IconButton,
  MuiIcons,
  IChipStatus,
  Row,
  createColumnHelper,
} from 'elements';
import { numberToCurrency, remCalc, dayjs } from 'utils';
import { IInvoicePayment, IPayStatuses, IInvoicePositionUnit } from 'invoices';
import * as Yup from 'yup';
import { IInvoiceTypes } from './types';

import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

interface IPaymentColumnsArgs {
  t: TFunction;
  isEditable: boolean;
  onDelete: (id: number) => void;
}

const PAYMENTS_STATUS_COLOR_MAP: Record<
  IInvoicePayment['paymentStatus'],
  string
> = {
  PAID: 'success',
  UNPAID: 'info',
  STRIPE_NO_PAYMENT_REQUIRED: 'grey',
};

const column = createColumnHelper<IInvoicePayment>();

export const definePaymentsColumns = ({
  t,
  onDelete,
  isEditable,
}: IPaymentColumnsArgs) => {
  return [
    column.accessor('paymentDate', {
      header: t('table.head.date'),
      cell: ({ row: { original: payment } }: ICell<IInvoicePayment>) =>
        dayjs(payment.paymentDate).format('DD.MM.YYYY HH:mm'),
    }),

    column.accessor('type', {
      header: t('table.head.paymentType'),
      cell: ({ row: { original: payment } }: ICell<IInvoicePayment>) =>
        t(`invoices.current.paymentType.${payment.type}`),
    }),
    column.accessor('comment', {
      header: t('table.head.comment'),
      cell: ({ row: { original: payment } }: ICell<IInvoicePayment>) => (
        <span>{payment.comment || '-'}</span>
      ),
    }),

    column.accessor('paymentStatus', {
      header: t('table.head.status'),
      cell: ({ row: { original: payment } }: ICell<IInvoicePayment>) => (
        <span className={PAYMENTS_STATUS_COLOR_MAP[payment.paymentStatus]}>
          {
            t(
              `invoices.current.payments.status.${payment.paymentStatus}`
            ) as string
          }
        </span>
      ),
    }),

    column.accessor('amount', {
      header: () => (
        <div className="text-right full-width">
          {t('table.head.totalCHF') as string}
        </div>
      ),
      cell: ({ row: { original: payment } }: ICell<IInvoicePayment>) => (
        <div className="text-right full-width">
          {numberToCurrency(+payment.amount, false, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    }),
    column.accessor('id', {
      header: '',
      width: 120,
      customStyles: { maxWidth: remCalc(120) },
      cell: ({ row: { original: payment } }: ICell<IInvoicePayment>) =>
        isEditable ? (
          <Row justifyContent="flex-end">
            <IconButton
              size="small"
              onClick={() => onDelete(payment.id)}
              style={{ marginRight: -10 }}
            >
              <MuiIcons.Delete className="fs-20" />
            </IconButton>
          </Row>
        ) : (
          <></>
        ),
    }),
  ];
};

export const MAX_AMOUNT_VALUE = 999999.99;
export const MIN_AMOUNT_VALUE = 0.01;
export const MAX_COMMENT_LENGTH = 60;

export const MAX_DESCRIPTION_LENGTH = 300;

export const positionSchema = Yup.object({
  description: Yup.string()
    .max(MAX_DESCRIPTION_LENGTH, 'forms.invalid.max')
    .trim()
    .required('forms.required'),
  price: Yup.number().required('forms.required'),
  qty: Yup.number().min(0.01).required('forms.required'),
  unit: Yup.object().required('forms.required'),
});

export const paymentSchema = Yup.object({
  comment: Yup.string().trim().max(MAX_COMMENT_LENGTH, 'forms.invalid.max'),
  paymentDate: Yup.date().required('forms.required'),
  amount: Yup.number()
    .min(MIN_AMOUNT_VALUE, 'forms.invalid.min')
    .required('forms.required')
    .max(MAX_AMOUNT_VALUE, 'forms.invalid.max'),
});

export const invoiceSchema = Yup.object({
  userId: Yup.object().required('forms.required'),
  date: Yup.date().required('forms.required'),
  dueDate: Yup.date().required('forms.required'),
});

export const statusLabels: IPayStatuses[] = [
  'PAID',
  'UNPAID',
  'PARTIALLY_PAID',
  'CANCELED',
  'OVERDUE',
  'OVERPAID',
];

export const statusOptions: ValueType<IPayStatuses>[] = statusLabels.map(
  (value) => ({
    value,
    label: `statuses.${value}`,
  })
);

const units: IInvoicePositionUnit[] = ['HOURS', 'PCS'];

export const unitOptions: ValueType<IInvoicePositionUnit>[] = units.map(
  (value) => ({
    value,
    label: `select.options.${value}`,
  })
);

export const INITIAL_STATE = {
  dialog: null,
  expanded: null,
  selectedTab: 'base',
  selectedInvoice: null,
  selectedPosition: null,
};

export const INITIAL_POSITION_VALUES: IInvoiceTypes.PositionState = {
  description: '',
  price: 0,
  qty: 1,
  unit: unitOptions[1],
};

export const INITIAL_PAYMENT_VALUES: IInvoiceTypes.PaymentState = {
  comment: '',
  amount: 0,
  paymentDate: dayjs.utc(),
};

export const INITIAL_PAYMENTS_DIALOG_STATE: IInvoiceTypes.ImportPaymentsDialogState =
  {
    isProcessing: false,
    data: [],
    dataToSave: [],
    dialog: false,
    error: null,
    included: {},
  };

export const TABLE_LAYOUT_STYLES = {
  tableTotalBlock: {
    paddingTop: remCalc(40),
    width: '100%',
    maxWidth: remCalc(270),
    marginLeft: 'auto',
  },
};

export interface IExportPaymentState {
  openExportDialog: boolean;
}

export const EXPORT_INVOICE_STATE: IExportPaymentState = {
  openExportDialog: false,
};

export const chipStatusMap: Record<IPayStatuses, IChipStatus> = {
  PAID: 'success',
  UNPAID: 'info',
  OVERDUE: 'error',
  OVERPAID: 'success',
  PARTIALLY_PAID: 'warning',
  CANCELED: 'default',
};

export const DEFAULT_USERS_QUERY = {
  page: 1,
  perPage: 1000,
  orderBy: 'firstName',
  orderType: 'asc',
};

export const TABS = [
  {
    label: 'invoices.tabs.link.customerInvoices',
    value: 'base',
  },
  {
    label: 'invoices.tabs.link.ownInvoices',
    value: 'own',
  },
];

export const INVOICE_REMINDERS = [
  'invoiceReminder1',
  'invoiceReminder2',
  'invoiceReminder3',
];

export const VISIBLE_RESOURCE_UNITS = [3, 9, 10, 11, 15, 20, 21, 24];

export const RESOURCE_UNIT_MAP: Record<number, string> = {
  3: 'GB',
  9: '',
  10: 'GB',
  11: 'GB',
  15: 'TB',
  20: '',
  21: 'GB',
  24: 'TB',
};
