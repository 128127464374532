import { GET, POST, PUT, DELETE, buildListParams } from 'utils';
import { DefaultListQuery } from 'global-shapes';
import { AxiosPromise } from 'axios';
import { BlacklistDomain, BlacklistDomainCreatePayload } from 'blacklist';
import { IAxiosPromise } from 'axios-shapes';

export const getBlacklist = (
  params: DefaultListQuery
): IAxiosPromise<BlacklistDomain[]> =>
  GET(
    '/black-list/domains',
    buildListParams(params, ['q', 'orderType', 'orderBy'])
  );

export const getBlacklistItem = (id: number): AxiosPromise<BlacklistDomain> =>
  GET(`/black-list/domains/${id}`);

export const create = (
  payload: BlacklistDomainCreatePayload
): AxiosPromise<BlacklistDomain> => {
  return POST('/black-list/domains', {}, payload);
};

export const update = (
  id: number,
  payload: BlacklistDomainCreatePayload
): AxiosPromise<BlacklistDomain> => {
  return PUT(`/black-list/domains/${id}`, {}, payload);
};

export const deleteDomain = (id: number): AxiosPromise =>
  DELETE(`/black-list/domains/${id}`);
