import * as React from 'react';
import { ICoreInfrastructure } from 'core-infrastructure';
import { IRds } from 'rds';
import { IActiveService, IActiveScriptStatus } from 'active-service';
import { IMonitoringServiceState } from 'monitoring';
import {
  IconBackup,
  IconSnapshot,
  IconRemoteSupport,
  IconSecondaryIp,
  IconPublicServices,
  IconConnectDomain,
  IconPatching,
} from 'elements/Icons';
import { MuiIcons } from 'elements/MuiIcons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  defaultBackupNavQuery,
  RDS_ACCOUNTS_DEFAULT_PARAMS,
  GLOBAL_DATE_FORMAT,
  CORE_DETAILS_NAV_ITEMS_PARAMS,
} from 'enums';
import { useRemoteSupport } from './useRemoteSupport';
import { useUserHash } from './useUserHash';

const generateBackupText = (backup?: IVmTypes.ILastBackupVersion) => {
  if (backup && backup.status !== 'NONE') {
    return `services.card.content.activeServices.backup.startedAt`;
  }
  return 'services.card.content.activeServices.backup.notStarted';
};

const classNames: Record<IActiveService['status'], string> = {
  info: 'info',
  default: 'steel',
  error: 'error',
  failed: 'error',
  success: 'success',
  warning: 'warning',
  none: 'steel',
};

const monitoringStatus: Record<IMonitoringServiceState, IActiveScriptStatus> = {
  SETUP_COMPLETED: 'success',
  SETUP_INSTANCES: 'warning',
  DELETING: 'warning',
  READY_TO_SETUP: 'warning',
};

const patchingStatus: Record<IMaintenanceStatus, IActiveScriptStatus> = {
  ENABLED: 'success',
  PENDING: 'success',
  DISABLED: 'default',
  FAILED: 'error',
};

const monitoringTooltips: Record<IMonitoringServiceState, string> = {
  SETUP_COMPLETED: 'monitoring.icons.analytics.tooltip.enabled',
  SETUP_INSTANCES: 'monitoring.icons.analytics.tooltip.completeInstalation',
  DELETING: 'monitoring.icons.analytics.tooltip.deleting',
  READY_TO_SETUP: 'monitoring.icons.analytics.tooltip.completeInstalation',
};

export function useCoreActiveServices(
  instance: ICoreInfrastructure
): IActiveService[] {
  const { t } = useTranslation();
  const rs = useRemoteSupport({ type: 'core' });
  const [, changeUrl] = useUserHash();
  const enabledMonitoring = !!instance?.serviceMonitoring;
  const hasSnapshot = !!instance?.serviceSnapshot;

  const patching = instance?.maintenanceWindows?.find(
    (m) => 'CoreInfraOsPatching' === m.taskOperationName
  );
  const hasPatching = !!patching;

  const services: IActiveService[] = [];

  const onOpenDetails = React.useCallback(
    () =>
      changeUrl(
        `/services/infrastructure/accounts`,
        CORE_DETAILS_NAV_ITEMS_PARAMS.accounts
      ),
    []
  );

  const onOpenMonitoring = React.useCallback(
    () => changeUrl(`/services/infrastructure/monitor`),
    []
  );

  const onOpenPatching = React.useCallback(
    () => changeUrl(`/services/infrastructure/patching`),
    []
  );

  const onBackupClick = React.useCallback(
    () => changeUrl(`/services/infrastructure/backup`, defaultBackupNavQuery),
    []
  );

  if (enabledMonitoring) {
    const setupState = instance?.serviceMonitoring
      ?.setupState as IMonitoringServiceState;

    const monitringStatus = monitoringStatus[setupState];

    services.push({
      icon: MuiIcons.AssessmentOutlined,
      tooltipText: monitoringTooltips[setupState],
      onClick: onOpenMonitoring,
      status: monitringStatus,
      className: classNames[monitringStatus],
    });
  }

  if (hasSnapshot) {
    const isFailed = instance?.serviceSnapshot?.task?.status === 'failed';

    services.push({
      icon: IconSnapshot,
      status: 'success',
      onClick: onOpenDetails,
      className: isFailed ? classNames.failed : classNames.success,
      tooltipText: t(
        'services.card.content.activeServices.snapshot.createdAt',
        {
          date: dayjs(instance?.serviceSnapshot?.createdAt).format(
            GLOBAL_DATE_FORMAT
          ),
          time: dayjs(instance?.serviceSnapshot?.createdAt).format('HH:mm:ss'),
        }
      ),
    });
  }

  if (hasPatching) {
    const status = patchingStatus[patching?.status];

    services.push({
      icon: IconPatching,
      status: status,
      onClick: onOpenPatching,
      className: status,
      tooltipText: t('services.card.content.activeServices.pachingEnabled'),
    });
  }

  if (rs.isUsedForCore) {
    services.push({
      icon: IconRemoteSupport,
      tooltipText: rs.gatewayWanIp,
      onClick: onOpenDetails,
      status: 'success',
      className: classNames.success,
      style: { padding: 2 },
    });
  }

  if (instance?.lastBackupVersion) {
    const backupStatus =
      instance.lastBackupVersion.status.toLowerCase() as IActiveService['status'];
    services.push({
      icon: IconBackup as any,
      onClick: onBackupClick,
      tooltipText: t(generateBackupText(instance.lastBackupVersion), {
        datetime: dayjs(instance.lastBackupVersion.creationDate).format(
          'DD.MM.YYYY HH:mm:ss'
        ),
        status: instance.lastBackupVersion.status,
      }),
      status: backupStatus,
      className: classNames[backupStatus],
    });
  }

  return services;
}

export function useRdsActiveServices(instance: IRds): IActiveService[] {
  const { t } = useTranslation();
  const rs = useRemoteSupport({ type: 'rds' });
  const [, changeUrl] = useUserHash();
  const enabledMonitoring = !!instance?.serviceMonitoring;
  const hasSnapshot = !!instance?.serviceSnapshot;

  const patching = instance?.maintenanceWindows?.find(
    (m) => 'RdsOsPatching' === m.taskOperationName
  );
  const hasPatching = !!patching;

  const services: IActiveService[] = [];

  const onOpenDetails = React.useCallback(
    () => changeUrl(`/services/rds/accounts`, RDS_ACCOUNTS_DEFAULT_PARAMS),
    []
  );

  const onOpenPatching = React.useCallback(
    () => changeUrl(`/services/rds/patching`),
    []
  );

  const onOpenMonitoring = React.useCallback(
    () => changeUrl(`/services/rds/monitor`),
    []
  );

  const onBackupClick = React.useCallback(
    () => changeUrl(`/services/rds/backup`, defaultBackupNavQuery),
    []
  );

  if (enabledMonitoring) {
    const setupState = instance?.serviceMonitoring
      ?.setupState as IMonitoringServiceState;

    const monitringStatus = monitoringStatus[setupState];

    services.push({
      icon: MuiIcons.AssessmentOutlined,
      tooltipText: monitoringTooltips[setupState],
      onClick: onOpenMonitoring,
      status: monitringStatus,
      className: classNames[monitringStatus],
    });
  }

  if (hasSnapshot) {
    const isFailed = instance?.serviceSnapshot?.task?.status === 'failed';

    services.push({
      icon: IconSnapshot,
      status: 'success',
      onClick: onOpenDetails,
      className: isFailed ? classNames.failed : classNames.success,
      tooltipText: t(
        'services.card.content.activeServices.snapshot.createdAt',
        {
          date: dayjs(instance?.serviceSnapshot?.createdAt).format(
            GLOBAL_DATE_FORMAT
          ),
          time: dayjs(instance?.serviceSnapshot?.createdAt).format('HH:mm:ss'),
        }
      ),
    });
  }

  if (hasPatching) {
    const status = patchingStatus[patching?.status];

    services.push({
      icon: IconPatching,
      status: status,
      onClick: onOpenPatching,
      className: status,
      tooltipText: t('services.card.content.activeServices.pachingEnabled'),
    });
  }

  if (!rs.isUsedForCore && rs.isUsedForRds) {
    services.push({
      icon: IconRemoteSupport,
      tooltipText: rs.gatewayWanIp,
      onClick: onOpenDetails,
      status: 'success',
      className: classNames.success,
      style: { padding: 2 },
    });
  }

  if (instance?.lastBackupVersion) {
    const backupStatus =
      instance.lastBackupVersion.status.toLowerCase() as IActiveService['status'];
    services.push({
      icon: IconBackup as any,
      onClick: onBackupClick,
      tooltipText: t(generateBackupText(instance.lastBackupVersion), {
        datetime: dayjs(instance.lastBackupVersion.creationDate).format(
          'DD.MM.YYYY HH:mm:ss'
        ),
        status: instance.lastBackupVersion.status,
      }),
      status: backupStatus,
      className: classNames[backupStatus],
    });
  }

  return services;
}

export function useVmActiveServices(instance: IVmTypes.Vm): IActiveService[] {
  const { t } = useTranslation();
  const rs = useRemoteSupport({ type: 'vm', vmId: instance.id });
  const [, changeUrl] = useUserHash();
  const enabledMonitoring = !!instance?.serviceMonitoring;
  const hasSnapshot = !!instance?.serviceSnapshot;

  const services: IActiveService[] = [];

  const onOpenDetails = React.useCallback(
    () => changeUrl(`/services/vm/info`, { id: instance?.id }),
    [instance?.id]
  );

  const onOpenPublicServices = React.useCallback(
    () => changeUrl(`/services/vm/public`, { id: instance?.id }),
    [instance?.id]
  );

  const onOpenMonitoring = React.useCallback(
    () => changeUrl(`/services/vm/monitor`, { id: instance?.id }),
    [instance?.id]
  );

  const onBackupClick = React.useCallback(
    () =>
      changeUrl(`/services/vm/backup`, {
        id: instance?.id,
        ...defaultBackupNavQuery,
      }),
    [instance?.id]
  );

  if (enabledMonitoring) {
    const setupState = instance?.serviceMonitoring
      ?.setupState as IMonitoringServiceState;

    const monitringStatus = monitoringStatus[setupState];

    services.push({
      icon: MuiIcons.AssessmentOutlined,
      tooltipText: monitoringTooltips[setupState],
      onClick: onOpenMonitoring,
      status: monitringStatus,
      className: classNames[monitringStatus],
    });
  }

  if (hasSnapshot) {
    const isFailed = instance?.serviceSnapshot?.task?.status === 'failed';

    services.push({
      icon: IconSnapshot,
      status: 'success',
      onClick: onOpenDetails,
      className: isFailed ? classNames.failed : classNames.success,
      tooltipText: t(
        'services.card.content.activeServices.snapshot.createdAt',
        {
          date: dayjs(instance?.serviceSnapshot?.createdAt).format(
            GLOBAL_DATE_FORMAT
          ),
          time: dayjs(instance?.serviceSnapshot?.createdAt).format('HH:mm:ss'),
        }
      ),
    });
  }

  if (rs.isUsedVm) {
    services.push({
      icon: IconRemoteSupport,
      tooltipText: rs.gatewayWanIp,
      onClick: onOpenDetails,
      status: 'success',
      className: classNames.success,
      style: { padding: 2 },
    });
  }

  if (instance?.networkType === 'PUBLIC' && instance?.nics?.length > 1) {
    services.push({
      icon: IconSecondaryIp,
      tooltipText: [instance?.nics[1]?.ipv4, instance?.nics[1]?.ipv6],
      onClick: onOpenDetails,
      status: 'success',
      className: classNames.success,
    });
  }

  if (instance?.services.length) {
    services.push({
      icon: IconPublicServices,
      tooltipText: instance?.services
        .slice(0, 10)
        .map((s) => `${s.name} (${s.port})`),
      onClick: onOpenPublicServices,
      status: 'success',
      className: classNames.success,
    });
  }

  if (instance?.lastBackupVersion) {
    const backupStatus =
      instance.lastBackupVersion.status.toLowerCase() as IActiveService['status'];
    services.push({
      icon: IconBackup as any,
      onClick: onBackupClick,
      status: backupStatus,
      className: classNames[backupStatus],
      tooltipText: t(generateBackupText(instance.lastBackupVersion), {
        datetime: dayjs(instance.lastBackupVersion.creationDate).format(
          'DD.MM.YYYY HH:mm:ss'
        ),
        status: instance.lastBackupVersion.status,
      }),
    });
  }

  if (instance?.coreInfrastructureId) {
    services.push({
      icon: IconConnectDomain,
      tooltipText: t('services.card.content.activeServices.hasCoreDomain'),
      onClick: onOpenDetails,
      status: 'success',
      className: classNames.success,
    });
  }

  return services;
}
