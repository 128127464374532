import * as React from 'react';
import cn from 'classnames';
import { ValueType } from 'global-shapes';
import { FormikErrors } from 'formik';
import { Row, Col, Checkbox, Popover, Alert, styled } from 'elements';
import { useTranslation, Trans } from 'react-i18next';
import { remCalc, IReducerChangeHandler } from 'utils';
import { useState } from 'hooks';
import { backupNamespaces, ALL_PLAN_OPTIONS } from 'enums';
import { IPlanTextStateKey } from 'backup';

type IState = Record<'anchor' | 'selectKey', any>;

type Props = {
  values: BackupTypes.State;
  errors?: FormikErrors<{ isBackupEnabled: boolean }>;
  handleChange: IReducerChangeHandler<BackupTypes.State>;
  isDisabled?: boolean;
  noBackupAlertColor: 'error' | 'info';
};

const Dropdown = styled('span')(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  paddingRight: remCalc(20),
  color: theme.palette.primary.main,

  '&:after': {
    content: "''",
    display: 'block',
    position: 'absolute',
    right: remCalc(5),
    top: '50%',
    marginTop: remCalc(-2),
    marginLeft: remCalc(-5),
    borderWidth: remCalc(5),
    borderStyle: 'solid',
    borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
  },
}));

const DropdownPaper = styled(Popover)({
  '& ._paper': {
    maxHeight: remCalc(200),
    overflow: 'auto',
    minWidth: remCalc(80),
  },
});

const PlanForm = (props: Props) => {
  const { values, handleChange, isDisabled, errors, noBackupAlertColor } =
    props;
  const { t } = useTranslation();

  const [state, handleState] = useState<IState>({
    anchor: null,
    selectKey: null,
  });

  const textValues = React.useMemo(() => {
    return {
      every: values.dailyHours?.value,
      from: values.dailyFrom?.value,
      keep: values.dailyRetention?.value,
      weekDay: values.weeklyDay?.value,
      keepWeeks: values.weeklyWeeks?.value,
      keepMonths: values.monthlyMonths?.value,
    };
  }, [
    JSON.stringify([
      values.dailyHours,
      values.dailyFrom,
      values.dailyRetention,
      values.weeklyDay,
      values.weeklyWeeks,
      values.monthlyMonths,
    ]),
  ]);

  const handlePlanAnchorOpen = React.useCallback(
    (type?: IPlanTextStateKey) =>
      (anchor: React.MouseEvent<HTMLSpanElement>) => {
        if (!type) {
          return handleState({ selectKey: undefined, anchor: undefined });
        }

        handleState({ selectKey: type, anchor: anchor.currentTarget });
      },
    []
  );

  const handlePlanOptionsChange = React.useCallback(
    (key: IPlanTextStateKey, value: ValueType) => {
      handleChange(value, key);
      handleState({ selectKey: undefined, anchor: undefined });
    },
    []
  );

  const selectedOptions: ValueType[] = React.useMemo(() => {
    // @ts-ignore
    if (state.selectKey) return ALL_PLAN_OPTIONS[state.selectKey];
    return [];
  }, [state.selectKey]);

  return (
    <>
      <div className="mb-25 steel fs-14">
        {t('services.backup.dialog.content')}
      </div>
      {backupNamespaces.map((key) => {
        return (
          <Row
            key={key}
            className={cn('lh-12 mb-20', { disabled: isDisabled })}
            alignItems="center"
          >
            <Col>
              <Checkbox
                checked={!!values[key]}
                onCheck={(isChecked) => handleChange(isChecked, key)}
              />
            </Col>
            <Col className={cn('fs-15')} xs>
              <span className="bolder pr-5">
                {t(`services.backup.plans.type.${key}`)}:
              </span>
              <Trans
                i18nKey={`services.backup.plans.options.${key}`}
                values={textValues}
                components={{
                  1: (
                    <Dropdown
                      className={cn('primary pointer')}
                      onClick={handlePlanAnchorOpen('dailyHours')}
                    />
                  ),
                  2: (
                    <Dropdown
                      className={cn('primary pointer')}
                      onClick={handlePlanAnchorOpen('dailyFrom')}
                    />
                  ),
                  3: (
                    <Dropdown
                      className={cn('primary pointer')}
                      onClick={handlePlanAnchorOpen('dailyRetention')}
                    />
                  ),
                  4: (
                    <Dropdown
                      className={cn('primary pointer')}
                      onClick={handlePlanAnchorOpen('weeklyDay')}
                    />
                  ),
                  5: (
                    <Dropdown
                      className={cn('primary pointer')}
                      onClick={handlePlanAnchorOpen('weeklyWeeks')}
                    />
                  ),
                  6: (
                    <Dropdown
                      className={cn('primary pointer')}
                      onClick={handlePlanAnchorOpen('monthlyMonths')}
                    />
                  ),
                }}
              />
            </Col>
          </Row>
        );
      })}
      {errors?.isBackupEnabled && (
        <Alert severity={noBackupAlertColor} className="mb-15">
          {t(errors.isBackupEnabled)}
        </Alert>
      )}
      <DropdownPaper
        open={!!state.selectKey}
        anchorEl={state.anchor}
        onClose={handlePlanAnchorOpen(undefined)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          className: cn('_paper pt-10 pr-25 pb-10 pl-15 fs-13'),
        }}
      >
        {selectedOptions.map((el) => {
          // @ts-ignore
          const isActive = el.value === values[state.selectKey]?.value;
          return (
            <div
              key={el.label}
              className={cn('mb-5 pointer', {
                primary: isActive,
                bold: isActive,
              })}
              onClick={() => handlePlanOptionsChange(state.selectKey, el)}
            >
              {el.label}
            </div>
          );
        })}
      </DropdownPaper>
    </>
  );
};

export default PlanForm;
