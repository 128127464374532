import * as React from 'react';
import {
  AddIcon,
  Alert,
  Button,
  Col,
  Dialog,
  Dropdown,
  IconButton,
  Input,
  MuiIcons,
  PrimaryElement,
  Row,
  Tooltip,
} from 'elements';
import cn from 'classnames';
import * as R from 'ramda';
import { IChartInstance } from 'monitoring';
import { useTranslation } from 'react-i18next';
import { remCalc, round } from 'utils';
import { useFormik } from 'formik';
import SliderWithInputController from '../../SliderWithInputController';
import {
  getLimitSliderPropsByType,
  LIMIT_MEASURE_OPTIONS,
  limitsSectionValidationSchema,
} from '../constants';
import { ILimitsDialogProps } from '../types';
import { DialogRecipientsHolder, SInfoLabel } from '../Styled';

const notEq = (el: IChartInstance) => el.metricKey !== 'network_if_out_bits';

const SetLimitsDialog = (
  props: React.PropsWithChildren<ILimitsDialogProps>
) => {
  const {
    onSave,
    isRequesting,
    entity,
    initialValues,
    items: _items,
    isEditable,
    ...dialogProps
  } = props;
  const { t } = useTranslation();
  const formatBack = React.useRef<Record<string, AnyFunc>>({});

  const items = R.filter(notEq)(_items);

  const {
    values,
    setFieldValue,
    handleSubmit,
    handleChange,
    errors,
    resetForm,
    setValues,
    submitCount,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: limitsSectionValidationSchema,
    validateOnMount: false,
    onSubmit: async ({ email, ...val }) => {
      if (isEditable) {
        const reformated = R.reduce(
          (res, id) => {
            if (formatBack.current[id]) {
              const backwarkConverter = formatBack.current[id];
              const { unit, value } = val[id];
              const isPercentage = unit === 'percentage';

              if (!isPercentage) {
                res[id] = {
                  ...val[id],
                  value: round(backwarkConverter(value), 0),
                };
              }
            }
            return res;
          },
          {} as any,
          Object.keys(val)
        );

        return (
          onSave &&
          onSave({
            ...val,
            ...reformated,
            // @ts-ignore
            emails: val.emails.map((em) => ({ email: em })),
          })
        );
      }
    },
  });

  const sliderConfigs = React.useMemo(
    () => getLimitSliderPropsByType({ t }),
    []
  );

  React.useEffect(() => {
    if (dialogProps.open) {
      setValues(initialValues);
    } else {
      resetForm();
    }
  }, [dialogProps.open, JSON.stringify(initialValues)]);

  return (
    <Dialog
      {...dialogProps}
      fullWidth
      maxWidth="md"
      title={t('monitoring.dialog.setLimits.title')}
      handleSubmit={handleSubmit}
      contentProps={{ style: { overflowX: 'visible', overflowY: 'inherit' } }}
      PaperProps={{ style: { maxWidth: 1200 } }}
      actions={
        <>
          <Button
            variant="outlined"
            color="default"
            onClick={dialogProps.onClose}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isRequesting || !isEditable}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className="steel mb-30">
        {t('monitoring.dialog.setLimits.content')}
      </div>
      <Row alignItems="flex-end" columnSpacing={8}>
        {items.map((el) => {
          const config = Object.assign({}, sliderConfigs[el.metricKey]);
          const isCpu = el.metricKey === 'cpu_util';
          const isDisk = el.metricKey === 'filesystem_space_util';
          const label =
            t(`monitoring.sections.charts.box.${el.metricKey}.name`) +
            (isDisk ? ` ${el.discoveryUnitName}` : '');
          const tooltipText = t(
            `monitoring.sections.charts.box.${el.metricKey}.tooltip`
          );

          const isPercentage = values[el.id]?.unit === 'percentage';
          const value = values[el.id];
          const inputValue = value?.value || 0;

          const maxAbsoluteValue = round(
            config.valueFormatter
              ? config.valueFormatter(el.totalResourceValue)
              : el.totalResourceValue,
            0
          );

          const max = isPercentage ? 100 : maxAbsoluteValue;
          const sliderProps = Object.assign({ max }, config.sliderProps);
          if (!isPercentage) {
            sliderProps.marks = [
              {
                value: 0,
                label: t('common.disabled'),
              },
              { value: max, label: max.toString() },
            ];
            sliderProps.max = max;

            formatBack.current[el.id] =
              config.valueFormatterBackward || undefined;
          }

          return (
            <Col key={el.id} xs={6} style={{ minHeight: remCalc(130) }}>
              <SliderWithInputController
                value={inputValue}
                label={t(label)}
                labelClassName="bold"
                isDisabled={isRequesting || !isEditable}
                name={el.id.toString()}
                inputWidth={config.inputWidth}
                sliderProps={{
                  ...sliderProps,
                  style: {
                    opacity: !inputValue ? 0.6 : 1,
                  },
                }}
                onInputChange={(ev) =>
                  setFieldValue(el.id.toString(), {
                    ...value,
                    value: +ev.target.value || '',
                  })
                }
                onSliderChange={(ev) =>
                  setFieldValue(el.id.toString(), {
                    ...value,
                    // @ts-ignore
                    value: ev.target?.value || 0,
                  })
                }
                error={!!errors[el.id]}
                // @ts-ignore
                helperText={t(errors[el.id] || '', {
                  max: sliderProps.max,
                  min: sliderProps.min,
                })}
                tooltip={
                  <SInfoLabel>
                    <Tooltip title={tooltipText} placement="top" arrow>
                      <MuiIcons.HelpOutline />
                    </Tooltip>
                  </SInfoLabel>
                }
                otherInputSettings={
                  !isCpu && (
                    <PrimaryElement>
                      <Dropdown
                        className="fs-12 pr-15"
                        options={LIMIT_MEASURE_OPTIONS(
                          el.metricKey,
                          !el.totalResourceValue
                        )}
                        value={value?.unit}
                        onChange={(unit) => {
                          if (!el.totalResourceValue) {
                            return;
                          }
                          setFieldValue(el.id.toString(), {
                            ...value,
                            value: round(
                              unit === 'values'
                                ? (maxAbsoluteValue / 100) * inputValue
                                : (inputValue / maxAbsoluteValue) * 100,
                              2
                            ),
                            unit,
                          });
                        }}
                        preValueContent={
                          <Col>
                            <Row alignItems="center">
                              <Col>
                                <MuiIcons.AssessmentOutlined
                                  className="fs-18"
                                  style={{ marginRight: 2 }}
                                />
                              </Col>
                              <Col>:</Col>
                            </Row>
                          </Col>
                        }
                        closeOnSelect
                      />
                    </PrimaryElement>
                  )
                }
              />
            </Col>
          );
        })}
      </Row>
      <DialogRecipientsHolder className={cn({ disabled: isRequesting })}>
        <div className="bold mb-25">
          {t('monitoring.dialog.setLimits.recepients.title')}
        </div>
        {isEditable && (
          <Row
            alignItems="flex-start"
            columnSpacing={2}
            className="full-width mb-30"
          >
            <Col xs>
              <Input
                value={values.email || ''}
                name="email"
                label={t('forms.email')}
                placeholder={t('forms.placeholders.email')}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Col>
            <Col>
              <AddIcon
                size="medium"
                className="mt-30"
                onClick={() => {
                  if (values.email && !errors.email) {
                    // @ts-ignore
                    setValues({
                      ...values,
                      emails: [...values.emails, values.email],
                      email: '',
                    });
                  }
                }}
              />
            </Col>
          </Row>
        )}
        <div className="fs-12">
          <div className="uppercase mb-10">
            {t('monitoring.dialog.setLimits.recepients.table.title')}
          </div>
          {values.emails.map((email, ind) => {
            return (
              <Row
                key={email + ind}
                columnSpacing={2}
                className="mb-15 steel"
                alignItems="center"
                justifyContent="space-between"
              >
                <Col>{email}</Col>
                {isEditable && (
                  <Col>
                    <IconButton
                      size="small"
                      onClick={() =>
                        setFieldValue(
                          'emails',
                          R.filter((em) => email !== em)(values.emails)
                        )
                      }
                    >
                      <MuiIcons.Delete className="fs-20" />
                    </IconButton>
                  </Col>
                )}
              </Row>
            );
          })}
          {!!submitCount && errors.emails && (
            <Alert severity="error">{t(errors.emails)}</Alert>
          )}
        </div>
      </DialogRecipientsHolder>
    </Dialog>
  );
};

export default SetLimitsDialog;
