import { BaseMobxInstanceDecorator } from './utilities';

const DnDState = {
  isDialogOpen: false,
};

export const dnd = new BaseMobxInstanceDecorator<typeof DnDState, false>({
  instanceName: 'dnd',
  requests: {
    get: async () => ({ data: DnDState }),
  },
  initialState: DnDState,
});
