import { BaseMobxInstanceDecorator } from './utilities';
import { parseBadgeAssignemntToState } from 'utils';
import { badgesService } from 'services';
import { IAssignmentReducerState } from 'badges';
import { BaseDecorator } from './types';

export const badges = new BaseMobxInstanceDecorator<IBadge, true>({
  instanceName: 'badges',
  requests: {
    get: badgesService.getList,
    create: badgesService.create,
    update: (id, payload) => badgesService.update(id as number, payload),
    remove: (id) => badgesService.deleteBadge(id as number),
  },
  initialState: [],
});

export type IBadgesHandler = BaseDecorator<IBadge, true>;

export const badgesAssignment = new BaseMobxInstanceDecorator<
  IAssignmentReducerState,
  false
>({
  instanceName: 'badgeAssignment',
  requests: {
    // @ts-ignore
    get: async () => {
      const res = await badgesService.getAssignmentList({
        page: 1,
        perPage: 1000,
      });
      // @ts-ignore
      res.data = parseBadgeAssignemntToState(res.data);
      return res;
    },
    // @ts-ignore
    create: badgesService.createAssignment,
    remove: (id) => badgesService.deleteBadgeAssignment(id as number),
  },
  initialState: {},
});

export type IBadgesAssignmentHandler = BaseDecorator<
  IAssignmentReducerState,
  false
>;
