import * as Yup from 'yup';
import { validator } from 'utils';
import { ValueType } from 'global-shapes';
import { Expire } from 'forwarded-ports';
import { IRemoteSupport } from './types';

export const EXPIRE_IN_SECONDS: Expire[] = [
  900,
  3600,
  7200,
  14400,
  28800,
  43200,
  57600,
  86400,
  604800,
];

export const PORTS = [3389, 22];

export const EXPIRE_IN_OPTIONS: ValueType<Expire>[] = [
  {
    value: 900,
    label: '15min',
  },
  {
    value: 3600,
    label: '1h',
  },
  {
    value: 7200,
    label: '2h',
  },
  {
    value: 14400,
    label: '4h',
  },
  {
    value: 28800,
    label: '8h',
  },
  {
    value: 43200,
    label: '12h',
  },
  {
    value: 57600,
    label: '16h',
  },
  {
    value: 86400,
    label: '24h',
  },
];

export const INITIAL_VALUES: IRemoteSupport.FormValues = {
  sourceWANIp: '',
  expireIn: EXPIRE_IN_OPTIONS[1],
};

export const validationSchema = (type: string) =>
  Yup.object().shape({
    sourceWANIp: Yup.string()
      .trim()
      .required('forms.required')
      .test('sourceWANIp', 'forms.ipInvalid', (val) =>
        validator.isIP(val || '')
      ),
    vmId:
      type === 'rds' ? Yup.object().required('forms.required') : Yup.object(),
  });
