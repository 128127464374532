import React, { useMemo } from 'react';
import cn from 'classnames';
import { Outlet, useLocation, Location } from 'react-router-dom';
import { Row, Col, Paper, Loader, Alert } from 'elements';
import LeftBarNavigation, {
  CItemRemove,
  CListItem,
} from 'components/LeftBarNavigation';
import { useTranslation } from 'react-i18next';
import { useServiceStatus, useUserHash, useTask, usePermissions } from 'hooks';
import {
  confirmDeleteWithReasons,
  parseConfirmDeleteWithReasonResponse,
  IConfirmDeleteReasonsRes,
} from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { CORE_DETAILS_NAV_ITEMS_PARAMS } from 'enums';
import { StatusDot } from 'pages/services/components/StatusIdentifier';
import { defineCoreDisplayStatus } from 'pages/services/helpers';
import { CORE_DETAILS_NAV_ITEMS } from 'pages/services/constants';

type IViewProps = {
  core: StateHandlers.ICoreHandler;
  costBreakdown: StateHandlers.ICostBreakdownHandler;
  tenantPricing: StateHandlers.ITenantPricingHandler;
};

const findIndexRoute = (hash: string, location: Location): boolean => {
  const ALL_CORE_ROUTES = [
    '/services/infrastructure/admins',
    '/services/infrastructure/accounts',
    '/services/infrastructure/shares',
    '/services/infrastructure/backup',
    '/services/infrastructure/monitor',
    '/services/infrastructure/patching',
  ];
  const currentRoute = location.pathname.replace(`/${hash}`, '');
  return ALL_CORE_ROUTES.some((el) => el === currentRoute);
};

const View = observer((props: IViewProps) => {
  const { core, costBreakdown, tenantPricing } = props;
  const { t } = useTranslation();
  const [hash, changeUrl] = useUserHash();
  const location = useLocation();
  const { permissionsAll } = usePermissions();

  const displayStatus = useMemo(defineCoreDisplayStatus(core.data), [
    core.data,
  ]);

  const onTaskComplete = React.useCallback(async (action: string) => {
    await core.get();
    await tenantPricing.get();
    await costBreakdown.get();
    action === 'delete' && changeUrl('/services/all');
  }, []);

  const task = useTask(core.data?.task, {
    onComplete: onTaskComplete,
  });

  const isDeployTask = ['create', 'delete'].includes(task.action);
  const failedSetup = isDeployTask && task.isFailed;

  const { status } = useServiceStatus(displayStatus);

  const deleteService = React.useCallback(() => {
    confirmDeleteWithReasons({
      title: t('services.core.confirm.delete.title'),
      content: t('services.core.confirm.delete.subtitle'),
      onSuccess: (res: IConfirmDeleteReasonsRes) => {
        core
          .remove(parseConfirmDeleteWithReasonResponse(res))
          .then(() => changeUrl('/services/all'));
      },
      onCancel: () => undefined,
    });
  }, []);

  const isOnValidRoute = findIndexRoute(hash, location);

  React.useEffect(() => {
    if (!isOnValidRoute) {
      changeUrl(
        '/services/infrastructure/accounts',
        CORE_DETAILS_NAV_ITEMS_PARAMS.accounts
      );
    }
  }, [isOnValidRoute]);

  if (!core.dataReceived) {
    return <Loader />;
  }

  if (!core.data) {
    return <Alert severity="warning">{t('services.core.noCoreMessage')}</Alert>;
  }

  const isCompleted = [
    'SETUP_COMPLETED',
    'INCREASE_VCD_DISK_SIZE',
    'INCREASE_VCD_PROFILE_DISK_SIZE',
  ].includes(core.data?.setupState);

  const agent = core.data?.agent || {};
  const vm = core.data?.vm || {};

  return (
    <>
      {!agent.isConnected && (
        <Alert severity="warning" className="mb-10">
          {t('services.core.agentNotConnected')}
        </Alert>
      )}
      <Row columnSpacing={2}>
        <Col xs={12} sm={3}>
          <LeftBarNavigation
            tHash="services.core"
            items={CORE_DETAILS_NAV_ITEMS({ permissions: permissionsAll })}
            params={CORE_DETAILS_NAV_ITEMS_PARAMS}
            baseUrl="services/infrastructure"
            header={
              <div className="p-20 pl-25 pr-25">
                <div className="mb-10 fs-17 bolder flex align-start full-width lh-20">
                  <StatusDot status={status} className="mt-5" />
                  {t('services.core.title')}
                </div>
                {vm.internalIpAddress && (
                  <div className="steel">
                    {vm.internalIpAddress} (
                    {t('services.card.content.internal')})
                  </div>
                )}
                {vm.externalIpAddress && (
                  <div className="steel">
                    {vm.externalIpAddress} (
                    {t('services.card.content.external')})
                  </div>
                )}
              </div>
            }
            footer={
              failedSetup ? (
                <CListItem className="full-width" onClick={deleteService}>
                  <CItemRemove className="full-width pointer">
                    {t('common.removeService')}
                  </CItemRemove>
                </CListItem>
              ) : null
            }
          />
        </Col>
        <Col xs={12} sm={9}>
          <Paper
            className={cn('p-30', { disabled: !isCompleted })}
            style={{ minHeight: '85vh' }}
          >
            <Outlet />
          </Paper>
        </Col>
      </Row>
    </>
  );
});

const CoreInfrastructureDetails = () => (
  <View
    core={StateHandlers.core}
    costBreakdown={StateHandlers.costBreakdown}
    tenantPricing={StateHandlers.tenantPricing}
  />
);

export default CoreInfrastructureDetails;
