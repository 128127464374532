import * as React from 'react';
import { styled } from './utilities';
import { remCalc } from 'utils';
import Radio, { RadioProps } from '@mui/material/Radio';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: remCalc(20),
  height: remCalc(20),
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: 'transparent',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: remCalc(20),
    height: remCalc(20),
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

function StyledRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default function CustomizedRadio(props: Omit<FormControlLabelProps, 'control'>) {
  return (
    <FormControlLabel
      {...props}
      label={props.label || ''}
      control={<StyledRadio />}
    />
  );
}
