import * as React from 'react';
import cn from 'classnames';
import Tabs from '@mui/material/Tabs';
import MuiTab, { TabProps } from '@mui/material/Tab';
import { styled } from './utilities';
import { important, remCalc } from 'utils';
import { PALETTE, TestSuffixes } from 'enums';
import { IAutomationTestProps } from 'automation-test';

interface TabPanelProps {
  value: number | string;
  index: number | string;
  children?: any;
  className?: string;
  style?: Record<string, any>;
}

function a11yProps(index?: number | string) {
  return {
    id: `jbbf-tabs-panel-${index}`,
    'aria-controls': `jbbf-tabs-panel-${index}`,
  };
}

export function TabPanel(props: TabPanelProps & IAutomationTestProps) {
  const { children, value, index, className, testId, style } = props;
  return value === index ? (
    <div
      role="tabpanel"
      className={cn('jbbf-tabs-panel', className)}
      aria-labelledby={`jbbf-tabs-panel-${index}`}
      data-test-id={`${testId}-${TestSuffixes.tabContent}-${index}`}
      style={style}
    >
      {children}
    </div>
  ) : null;
}

interface TabPropsExtended extends TabProps {
  index?: number;
}

export const Tab = (props: TabPropsExtended & IAutomationTestProps) => {
  const { testId, ...rest } = props;
  return (
    <MuiTab
      {...rest}
      {...a11yProps(rest.index)}
      data-test-id={`${testId}-${TestSuffixes.tab}-${rest.index}`}
    />
  );
};

export { Tabs };

export const DialogTabs = styled(Tabs)({
  border: `1px solid ${PALETTE.lightGrey}`,
  minHeight: important(remCalc(40)),
  borderRadius: remCalc(4),
  marginBottom: remCalc(25),
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

export const DialogTab = styled(Tab)(({ theme }) => {
  return {
    textTransform: 'inherit',
    minWidth: important('50%'),
    padding: important(remCalc(10, 15)),
    marginRight: 0,
    minHeight: important(remCalc(40)),
    lineHeight: 1,
    fontSize: important(remCalc(17)),
    fontWeight: important(600),
    '&:hover': {
      opacity: 1,
    },
    '&.Mui-selected': {
      color: important(PALETTE.black),
      backgroundColor: '#ededee',
    },
  };
});

export const VmTabs = styled(Tabs)(({ theme }) => {
  return {
    '&.MuiTabs-root': {
      minHeight: remCalc(25),
    },
  };
});

export const VmTab = styled(Tab)({
  '&:hover': {
    opacity: 1,
  },
  '&.MuiTab-root': {
    textTransform: 'inherit',
    marginRight: 0,
    lineHeight: remCalc(30),
    fontSize: important(remCalc(14)),
    color: 'rgba(23,29,41,0.5)',
    backgroundColor: 'transparent',
    padding: remCalc(0, 15),
    minHeight: 0,
  },
  '&.Mui-selected': {
    color: important(PALETTE.black),
  },
});
