import { remCalc } from 'utils';
import { styled, Paper } from 'elements';

export const PriceDetailsHolder = styled('div')(({ theme }) => ({
  width: remCalc(870),
  maxWidth: '100%',
  padding: remCalc(15),
  [theme.breakpoints.up('md')]: {
    padding: remCalc(30),
  },
  [theme.breakpoints.up('lg')]: {
    padding: remCalc(45, 40),
  },
}));

export const PriceDetailsListItem = styled('div')(({ theme }) => ({
  paddingLeft: remCalc(25),
  position: 'relative',
  '&::before': {
    display: 'block',
    content: "''",
    width: remCalc(8),
    height: remCalc(8),
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    position: 'absolute',
    top: `calc(50% - ${remCalc(4)})`,
    left: 0,
  },
}));

export const PriceCard = styled(Paper)(({ theme }) => ({
  transition: 'box-shadow 0.25s ease-in-out 0s',
  boxShadow: theme.shadows[1],
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

export const PriceUserName = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
}));
