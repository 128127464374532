import * as React from 'react';
import * as M from 'mobx';
import { Row, Col } from 'elements';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { IInvoicePosition } from 'invoices';
import * as StateHandlers from 'states';
import { InvoicePositionItemDto } from './PositionDto';
import PositionItem from './PositionItem';

type IProps = {
  positions: StateHandlers.IInvoicePositionsHandler;
  isPaid: boolean;
  isEditable: boolean;
  onDelete: (pos: IInvoicePosition) => void;
  onEdit: (pos: IInvoicePosition) => void;
};

const Positions = observer((props: IProps) => {
  const { positions, isPaid, isEditable, onDelete, onEdit } = props;
  const { t } = useTranslation();

  const items = React.useMemo(
    () => positions.data.map((pos) => new InvoicePositionItemDto(pos)),
    [M.toJS(positions.data)]
  );

  return (
    <div>
      <Row className="steel fs-12 pt-15 pb-15">
        <Col xs={6}>{t('table.head.description')}</Col>
        <Col xs={6}>
          <Row>
            <Col xs={10}>
              <Row>
                <Col xs={4} className="text-right">
                  {t('table.head.qty')}
                </Col>
                <Col xs={4} className="text-right">
                  {t('quotaInfo.total')}
                </Col>
                <Col xs={4} className="text-right">
                  {t('table.head.totalCHF')}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {items.map((it, index) => (
        <PositionItem
          key={index}
          isPaid={isPaid}
          isEditable={isEditable}
          index={index + 1}
          {...it}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ))}
    </div>
  );
});

export default Positions;
