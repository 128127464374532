import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIconComponent } from '@mui/icons-material';
import { isString } from 'utils';
import { MuiIcons, Col, Row } from 'elements';
import { ActionButton } from './ActionButton';
import { ActionOption } from './types';

const actionIconsMap: Partial<Record<string, SvgIconComponent>> = {
  powerOn: MuiIcons.Power,
  shutDown: MuiIcons.PowerSettingsNew,
  powerOff: MuiIcons.PowerOff,
  reset: MuiIcons.RestartAlt,
  edit: MuiIcons.Edit,
  reboot: MuiIcons.Refresh,
  console: MuiIcons.PersonalVideo,
};

const ButtonList = (props: { actions: ActionOption[] }) => {
  const { actions } = props;
  const { t } = useTranslation();

  return (
    <Row justifyContent="flex-end">
      {actions.map((item) => {
        const StartIcon = actionIconsMap[item.value as any] as any;

        if (isString(item.value)) {
          return (
            <Col key={item.value as string} className="pl-10 pb-5 pt-5">
              <ActionButton onClick={item.onClick}>
                <StartIcon className="mr-5" />
                {t(`services.content.action.${item.label}`) as string}
              </ActionButton>
            </Col>
          );
        }

        return null;
      })}
    </Row>
  );
};

export default ButtonList;
