import * as React from 'react';
import { styled } from './utilities';

export const PrimaryTextDiv = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const PrimaryTextSpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const PrimaryTextH5 = styled('h5')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const PrimaryTextLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const CustomListUl = styled('ul')(({ theme }) => ({
  '& li:before': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const PrimaryElement = styled((props: any) => {
  const { component, ...rest } = props;
  const Component = component || (() => <div {...rest} />);
  return <Component {...rest} />;
})(({ theme }) => ({
  color: theme.palette.primary.main,
}));
