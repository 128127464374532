import React, { useCallback } from 'react';
import { ConnectivityFormValues } from 'services-shapes';
import { ALL_TEST_IDS, CustomEvents } from 'enums';
import { ICoreCreatePayload } from 'core-infrastructure';
import { useState } from 'hooks';
import { Row, Col } from 'elements';
import { noop, Emitter, confirm } from 'utils';
import { IDnsCreateResponse, IDNSServer } from 'dns';
import { QuotasInfo } from 'components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import CreateCoreInfrastructureDialog from 'pages/core/dialogs/MainDialog';
import { ZonesTypes } from 'pages/dns/dialogs/types';
import GuideTooltip from '../GuideTooltip';
import ConnectivityCard from '../cards/wrappers/Connectivity';
import CoreInfrastructureCard from '../cards/wrappers/CoreInfrastructure';
import DnsCard from '../cards/wrappers/Dns';
import RemoteDesktopCard from '../cards/wrappers/Rds';
import DeployConnectivityDialog from '../../../connectivity/DeployConnectivityDialog';
import CreateVirtualMachineDialog from '../../../vm-details/dialogs/CreateVirtualMachineDialog';
import DnsSetupDialog from 'pages/dns/dialogs/DnsSetup';
import VmList from 'pages/vmList/Main';
import DeployRdsDialog from 'pages/rds/DeployRds';
import {
  AllServicePage,
  AllServiceGlobalIconWrapper,
  AllServiceStatusIndicator,
  AllServiceGlobalIcon,
  AllServiceVmsWrapper,
} from './Styled';

type ServiceType =
  | 'connectivity'
  | 'vms'
  | 'coreInfrastructure'
  | 'rds'
  | 'dns';

type IViewProps = {
  currentUser: StateHandlers.ICurrentUserHandler;
  tenantPricing: StateHandlers.ITenantPricingHandler;
  connectivity: StateHandlers.IConnectivityHandler;
  core: StateHandlers.ICoreHandler;
  rds: StateHandlers.IRdsHandler;
  dns: StateHandlers.IDnsHandler;
  vms: StateHandlers.IVmsHandler;
  taskMnager: StateHandlers.ITaskManagerHandler;
  tenantUsage: StateHandlers.IUsageSelectedHandler;
};

const INITIAL_STATE = {
  openedDialog: '',
  deleteVmConfirmOpen: false,
  currentVm: undefined,
  instance: 'none',
  remoteSupportDialog: null,
  remoteSupportAttrs: {},
  vmQuery: {},
};

const View = observer((props: IViewProps) => {
  const {
    core,
    connectivity,
    dns,
    vms,
    rds,
    taskMnager,
    tenantUsage,
    currentUser,
  } = props;
  const [state, handleStateChange] = useState(INITIAL_STATE);
  const { t } = useTranslation();

  const isAllServicesFetched =
    core.dataReceived &&
    connectivity.dataReceived &&
    dns.dataReceived &&
    vms.dataReceived &&
    rds.dataReceived &&
    currentUser.dataReceived;

  const zonesCount = dns.data ? dns.data?.zonesQty : 0;

  const isCoreRunning = core.data?.displayStatus === 'POWERED_ON';
  const connectivitySetupCompleted =
    connectivity.data?.setupState === 'SETUP_COMPLETED';
  const coreSetupCompleted = core.data?.setupState === 'SETUP_COMPLETED';

  const fetchTasks = useCallback(() => taskMnager.get(), []);

  const onDialogClose = useCallback(
    () =>
      handleStateChange({
        openedDialog: '',
        instance: 'none',
        remoteSupportDialog: null,
      }),
    []
  );

  const onCreateConnectivity = useCallback(
    (payload: ConnectivityFormValues) =>
      connectivity.create(payload).then(() => {
        connectivity.get();
        onDialogClose();
        fetchTasks();
      }),
    []
  );

  const onCreateCoreInfrastructure = useCallback(
    (payload: ICoreCreatePayload) =>
      core.create(payload).then(() => {
        core.get();
        onDialogClose();
        Emitter.emit('open-task-dashboard', null);
      }),
    []
  );

  const onCreateRds = useCallback(
    () =>
      rds.create({}).then(() => {
        rds.get();
        onDialogClose();
        Emitter.emit('open-task-dashboard', null);
      }),
    []
  );

  const onCreateDns = useCallback(
    (values: ZonesTypes.ZoneValue[]) =>
      dns
        .create(values)
        // @ts-ignore
        .then((_dns: IDnsCreateResponse[]) => {
          dns.get();
          onDialogClose();
          confirm({
            title: t('services.dialog.dns.confirm.create.success.title'),
            fullWidth: true,
            maxWidth: 'md',
            content: (
              <>
                <div className="mb-20">
                  {
                    t(
                      'services.dialog.dns.confirm.create.success.content'
                    ) as string
                  }
                </div>
                <div className="mb-25">
                  {_dns[0].servers.map((server: IDNSServer) => (
                    <div className="bold" key={server}>
                      {server}
                    </div>
                  ))}
                </div>

                <div>
                  {
                    t(
                      'services.dialog.dns.confirm.create.success.content.note'
                    ) as string
                  }
                </div>
              </>
            ),
            cancelLabel: 'common.ok',
            onCancel: noop,
          });
        }),
    []
  );

  const onCreateVM = useCallback((payload: IVmTypes.CreateVMShape) => {
    vms.create(payload).then(() => {
      Emitter.emit(CustomEvents.fetchVmList, {});
      fetchTasks();
      onDialogClose();
    });
  }, []);

  const onAdd = useCallback(
    (type: ServiceType) => handleStateChange(type, 'openedDialog'),
    []
  );

  const onRdsAdd = useCallback(
    (type: ServiceType) => {
      if (!isCoreRunning) {
        return confirm({
          title: t('services.rds.confirm.noCore.title'),
          content: t('services.rds.confirm.noCore.content'),
          onCancel: noop,
          cancelLabel: 'common.close',
        });
      }
      handleStateChange(type, 'openedDialog');
    },
    [isCoreRunning]
  );

  React.useEffect(() => {
    tenantUsage.get({
      id: currentUser.data?.tenant?.id,
      accessLevel: 'tenant',
    });
  }, []);

  return (
    <AllServicePage>
      <Row columnSpacing={3}>
        <Col xs={3}>
          <div className="flex align-center justify-center">
            <AllServiceGlobalIconWrapper>
              <AllServiceStatusIndicator
                connectivitySetupCompleted={connectivitySetupCompleted}
                data-test-id={ALL_TEST_IDS.services.main.globalIcon}
              />
              <AllServiceGlobalIcon />
            </AllServiceGlobalIconWrapper>
          </div>
        </Col>
      </Row>

      <Row columnSpacing={3} className="mb-15">
        <Col xs={3}>
          <ConnectivityCard onAdd={onAdd} />
        </Col>

        <Col xs={6}>
          <Row>
            <Col xs={6}>
              <CoreInfrastructureCard
                onAdd={onAdd}
                connectivitySetupCompleted={connectivitySetupCompleted}
                onDelete={handleStateChange}
              />
            </Col>

            <Col xs={6}>
              <RemoteDesktopCard
                onAdd={onRdsAdd}
                coreSetupCompleted={coreSetupCompleted}
                onDelete={handleStateChange}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={3}>
          <DnsCard
            onAdd={onAdd}
            onDelete={handleStateChange}
            coreSetupCompleted={coreSetupCompleted}
          />
        </Col>
      </Row>

      <AllServiceVmsWrapper>
        <VmList onAdd={onAdd} shouldUseQuery={false} />
      </AllServiceVmsWrapper>

      <DeployConnectivityDialog
        open={state.openedDialog === 'connectivity'}
        onSave={onCreateConnectivity}
        onClose={onDialogClose}
      />
      <CreateCoreInfrastructureDialog
        open={state.openedDialog === 'coreInfrastructure'}
        onSave={onCreateCoreInfrastructure}
        coreData={core.data}
        onClose={onDialogClose}
        isRequesting={core.isRequesting}
      />
      <CreateVirtualMachineDialog
        open={state.openedDialog === 'vms'}
        connectivitySetupCompleted={connectivitySetupCompleted}
        isSubmitting={vms.isRequesting}
        onSave={onCreateVM}
        onClose={onDialogClose}
      />
      {core.data && (
        <>
          <DeployRdsDialog
            open={state.openedDialog === 'rds'}
            coreData={core.data}
            onClose={onDialogClose}
            onSave={onCreateRds}
            isRequesting={rds.isRequesting}
          />
        </>
      )}
      <DnsSetupDialog
        existingZonesCount={zonesCount}
        open={state.openedDialog === 'dns'}
        onClose={onDialogClose}
        onSave={onCreateDns}
        title={t('services.dialog.dns.create.title')}
        content="services.dialog.dns.create.content"
      />
      {isAllServicesFetched && <GuideTooltip />}
      <QuotasInfo
        portal={document.querySelector('#quotas-container') as Element}
      />
    </AllServicePage>
  );
});

const AllServices = () => (
  <View
    currentUser={StateHandlers.currentUser}
    tenantPricing={StateHandlers.tenantPricing}
    connectivity={StateHandlers.connectivity}
    core={StateHandlers.core}
    rds={StateHandlers.rds}
    dns={StateHandlers.dns}
    vms={StateHandlers.vms}
    taskMnager={StateHandlers.taskManager}
    tenantUsage={StateHandlers.usageSelected}
  />
);

export default AllServices;
