import { ValueType } from 'global-shapes';
import { ICostBasisValue, IInstallPackageDependencyValues } from 'app-store';
import * as Yup from 'yup';
// @ts-ignore
import { convertToHTML } from 'draft-convert';
import BoldText from 'assets/images/icons/ic-text-bold.svg';
import UnderlineText from 'assets/images/icons/ic-text-underline.svg';
import ItalicText from 'assets/images/icons/ic-text-italic.svg';
import NumberListText from 'assets/images/icons/ic-text-number.svg';
import BulletListText from 'assets/images/icons/ic-text-bulet.svg';
import LinkText from 'assets/images/icons/ic-text-link.svg';

export const TOOLBAR_EDITOR_OPTIONS = {
  options: ['inline', 'list', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: { icon: BoldText },
    underline: { icon: UnderlineText },
    italic: { icon: ItalicText },
  },
  list: {
    options: ['ordered', 'unordered'],
    ordered: { icon: NumberListText },
    unordered: { icon: BulletListText },
  },
  link: {
    options: ['link'],
    link: { icon: LinkText },
    defaultTargetOption: '_blank',
  },
};

export const COST_BASIS_OPTIONS: ICostBasisValue[] = [
  {
    label: 'Per user',
    value: 'PER_USER',
  },
  {
    label: 'Fixed',
    value: 'FIXED',
  },
];

export const DESCRIPTION_LETTERS_LIMIT = 130;

const selectedTypeProviderCondition =
  (licenseValues: any) => (selectedType: string) => {
    if (
      selectedType === 'provider' &&
      licenseValues.costBasisType !== 'FIXED'
    ) {
      return true;
    }
    return false;
  };

export const installPackageSchema = (
  licenseValues: IInstallPackageDependencyValues
) =>
  Yup.object().shape({
    licenseKey: Yup.string().when(['selectedType'], {
      is: (selectedType: string) => {
        if (selectedType === 'own') {
          return true;
        }
        return false;
      },
      then: Yup.string().required('forms.required'),
    }),
    providersLicenseUnits: Yup.number().when(['selectedType'], {
      is: selectedTypeProviderCondition(licenseValues),
      then: Yup.number().test(
        'providersLicenseUnits',
        'forms.invalid',
        // @ts-ignore
        function (val: number) {
          if (!this.parent.activeRdsUsersBilling) return true;
          if (!+val) return false;
          if (val === licenseValues.minLicenseUnit) return true;
          return (
            +val >= licenseValues.minLicenseUnit &&
            Number.isInteger(val / licenseValues.interval)
          );
        }
      ),
    }),
    selectedType: Yup.string(),
    cost: Yup.number(),
  });

export const appStoreFormSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('forms.required')
    .max(100, 'forms.invalid.max'),
  manufacturer: Yup.string()
    .trim()
    .required('forms.required')
    .max(60, 'forms.invalid.max'),
  description: Yup.object().test(
    'description',
    'forms.invalid',
    // @ts-ignore
    (val: any) => {
      const value = convertToHTML(val.getCurrentContent());
      if (value === '<p></p>') return false;
      return !(value.length > 1000);
    }
  ),
  identifier: Yup.string().trim().required('forms.required'),
  requiresLicense: Yup.boolean().required(),
  providersLicense: Yup.boolean().required(),
  ownLicense: Yup.boolean().required(),
  ownLicenseDescription: Yup.string().when('ownLicense', {
    is: true,
    then: Yup.string()
      .required('forms.required')
      .max(1000, 'forms.invalid.max'),
  }),
  ownActivationCommand: Yup.string().when('ownLicense', {
    is: true,
    then: Yup.string().required('forms.required'),
  }),
  ownKeyFormat: Yup.string().when('ownLicense', {
    is: true,
    then: Yup.string().required('forms.required'),
  }),
  ownActivationParams: Yup.string(),
  providerActivationCommand: Yup.string().when('providersLicense', {
    is: true,
    then: Yup.string().required('forms.required'),
  }),
  providerCostBasis: Yup.object().when('providersLicense', {
    is: true,
    then: Yup.object().required('forms.required'),
  }),
  providerLicensingInterval: Yup.number().when(
    ['providersLicense', 'providerCostBasis'],
    {
      is: (providersLicense: string, providerCostBasis: ValueType<string>) => {
        if (providersLicense && providerCostBasis.value !== 'FIXED') {
          return true;
        }
        return false;
      },
      then: Yup.number().min(1).required('forms.required'),
    }
  ),
  providerMinLicenseUnit: Yup.number().when(
    ['providersLicense', 'providerCostBasis'],
    {
      is: (providersLicense: string, providerCostBasis: ValueType<string>) => {
        if (providersLicense && providerCostBasis.value !== 'FIXED') {
          return true;
        }
        return false;
      },
      then: Yup.number().min(1).required('forms.required'),
    }
  ),
  providerLicenseCost: Yup.number().when('providersLicense', {
    is: true,
    then: Yup.number()
      .min(0.01, 'forms.invalid.min')
      .max(999999.99, 'forms.invalid.max')
      .required('forms.required'),
  }),
});
