import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Row,
  Col,
  Loader,
  Alert,
  Button,
  IconButton,
  Menu,
  MenuItem,
  MuiIcons,
} from 'elements';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { toJS } from 'mobx';
import { IPricingService, IPrice } from 'pricing';
import { usePermissions, useState, useQuery } from 'hooks';
import { observer } from 'mobx-react-lite';
import { AnyFunc } from 'global-shapes';
import { ICustomer } from 'customer-shapes';
import BulkUpdateDialog from './BulkUpdate';
import DedicatedServerRate from './DedicatedServerRate';
import { ServiceBlock } from './ServiceView';
import { UserNameText } from './Styled';
import { generateMenuActionItems } from './helper';
import { IBulkUpdatePayload } from './types';

interface IProps {
  defaultPricing: IPrice[] | IPricingService[];
  data: IPricingService[] | IPrice[];
  parentPricing?: IPrice[];
  dataReceived: boolean;
  subtitle?: string;
  user?: ICustomer | IPartnerTypes.Partner;
  disabled: boolean;
  isEditable: boolean;
  showDedicatedServerRate?: boolean;
  isOnUserPricing: boolean;
  onUpdatePricing: AnyFunc;
  onResetPrice?: AnyFunc;
  onSetPrice: AnyFunc;
  onGoEditPrice?: AnyFunc;
  goToUser?: AnyFunc;
  onBulkUpdate?: (values: IBulkUpdatePayload) => void;
}

const Pricing = observer((props: IProps) => {
  const {
    defaultPricing,
    data,
    disabled,
    dataReceived,
    isOnUserPricing,
    isEditable,
    onUpdatePricing,
    onResetPrice,
    onSetPrice,
    user,
    parentPricing,
    subtitle,
    onGoEditPrice,
    goToUser,
    onBulkUpdate,
    showDedicatedServerRate,
  } = props;
  const { t } = useTranslation();
  const { query } = useQuery();
  const [state, handleState] = useState({ open: false });
  const { isCurrentProvider, isEvaluation, isTenant, accessLevel } =
    usePermissions('PRICING');
  const jsData = toJS(data);

  const menuItems = generateMenuActionItems({
    isOnUserPricing,
    accessLevel,
    isEditable,
  });

  const openBulkUpdate = React.useCallback(() => {
    handleState({ open: true });
  }, []);

  const onBulkSave = React.useCallback(
    async (values: IBulkUpdatePayload) => {
      if (onBulkUpdate) {
        await onBulkUpdate(values);
      }

      return handleState({ open: false });
    },
    [onBulkUpdate]
  );

  if (!dataReceived) {
    return <Loader />;
  }

  if (!defaultPricing.length) {
    return (
      <Paper className="p-20">{t('settings.page.pricing.noPricing')}</Paper>
    );
  }

  const hasParentPrice = !!parentPricing && !!parentPricing.length;

  return (
    <div className="pt-15">
      <Row columnSpacing={2} alignItems="center" justifyContent="space-between">
        <Col sm={6}>
          <h3 className="mb-10">{t('settings.page.pricing.title')}</h3>
          <div className="steel lh-14">{subtitle}</div>
        </Col>
        <Col>
          <Row alignItems="center" justifyContent="flex-end" columnSpacing={2}>
            {onGoEditPrice && !isTenant && (
              <Col>
                <Button onClick={onGoEditPrice} size="small">
                  {t('settings.page.pricing.buttons.managePricing')}
                </Button>
              </Col>
            )}
            {user?.name && (
              <Col>
                <div className="bold">
                  {t(
                    `settings.page.pricing.${
                      isCurrentProvider ? 'partnerName' : 'tenantName'
                    }`
                  )}
                  :{' '}
                  <UserNameText className="pl-10 pointer" onClick={goToUser}>
                    {user.name}
                  </UserNameText>
                </div>
              </Col>
            )}
            {!!menuItems.length && (
              <Col>
                <PopupState variant="popover" popupId={`pricing-actions`}>
                  {(popupState) => (
                    <>
                      <IconButton {...bindTrigger(popupState)} size="small">
                        <MuiIcons.MoreVert className="fs-20" />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}>
                        {menuItems.map((it) => {
                          if (it === 'bulk-update')
                            return (
                              <MenuItem
                                key={it}
                                onClick={() => {
                                  openBulkUpdate();
                                  popupState.close();
                                }}
                              >
                                {t('settings.page.pricing.buttons.bulkUpdate')}
                              </MenuItem>
                            );

                          if (it === 'reset')
                            return (
                              <MenuItem
                                key={it}
                                onClick={() => {
                                  onResetPrice && onResetPrice();
                                  popupState.close();
                                }}
                              >
                                {t('settings.page.pricing.buttons.resetPrice')}
                              </MenuItem>
                            );

                          return null;
                        })}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {isEvaluation && (
        <Alert className="fs-12 mt-10" severity="info">
          {t('costsInfo.evalAccount.notify')}
        </Alert>
      )}
      <Paper className={cn('p-20 mb-15 mt-25', { disabled })}>
        <Row alignItems="center">
          {hasParentPrice ? (
            <>
              <Col xs={5} className="bold">
                {t('settings.page.pricing.head.name')}
              </Col>
              <Col xs={7}>
                <Row>
                  <Col xs={5}>
                    <div className="bold mb-10">
                      {t('settings.page.pricing.head.yourRetail')}
                    </div>
                    <Row className="steel">
                      <Col xs={6}>
                        {t('settings.page.pricing.head.monthly')}
                      </Col>
                      <Col xs={6}>{t('settings.page.pricing.head.daily')}</Col>
                    </Row>
                  </Col>
                  <Col xs={7}>
                    <div className="bold mb-10">
                      {t('settings.page.pricing.head.customerRetail')}
                    </div>
                    <Row className="steel">
                      <Col xs={5}>
                        {t('settings.page.pricing.head.monthly')}
                      </Col>
                      <Col xs={5}>{t('settings.page.pricing.head.daily')}</Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <>
              <Col xs={7} className="bold">
                {t('settings.page.pricing.head.name')}
              </Col>
              <Col xs={5}>
                <div className="bold mb-10">
                  {t('settings.page.pricing.head.yourRetail')}
                </div>
                <Row className="steel">
                  <Col xs={5}>{t('settings.page.pricing.head.monthly')}</Col>
                  <Col xs={4}>{t('settings.page.pricing.head.daily')}</Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Paper>
      <ServiceBlock
        defaultPrice={defaultPricing}
        // @ts-ignore
        data={jsData}
        canEdit={isEditable}
        isOnUserPricing={isOnUserPricing}
        onUpdatePricing={onUpdatePricing}
        onSetPrice={onSetPrice}
        parentPricing={parentPricing}
        hasParentPrice={hasParentPrice}
      />

      {showDedicatedServerRate && (
        <DedicatedServerRate
          hasParentPrice={hasParentPrice}
          tenantId={query.tenant}
        />
      )}
      <BulkUpdateDialog
        open={state.open}
        hasParentPrice={hasParentPrice}
        onClose={() => handleState({ open: false })}
        onSave={onBulkSave}
        defaultPrice={defaultPricing}
        // @ts-ignore
        data={jsData}
        parentPricing={parentPricing}
      />
    </div>
  );
});

export default Pricing;
