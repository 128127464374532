import { showSystemMessage } from 'utils';
import { fileShareService } from 'services';
import { IFileShare } from 'file-shares';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export const fileShares = new BaseMobxInstanceDecorator<
  IFileShare,
  true
>({
  requests: {
    get: fileShareService.get,
    create: async (payload) => {
      const res = await fileShareService.create(payload);
      showSystemMessage(
        'services.core.file-shares.notify.create.success',
        'success'
      );
      return res;
    },
    update: async (id, payload) => {
      const res = await fileShareService.update(id, payload);
      showSystemMessage(
        'services.core.file-shares.notify.update.success',
        'success'
      );
      return res;
    },
    remove: async (id) => {
      const res = await fileShareService.remove(id);
      showSystemMessage(
        'services.core.file-shares.notify.delete.success',
        'success'
      );
      return res;
    },
  },
  initialState: [],
});

export type IFileSharesHandler = BaseDecorator<IFileShare, true>;

export const selectedFileShare = new BaseMobxInstanceDecorator<
  IFileShare,
  false
>({
  requests: {
    get: (query) => fileShareService.getById(query.id),
  },
  initialState: {} as IFileShare,
});

export type ISelectedFileShareHandler = BaseDecorator<IFileShare, false>;
