import * as R from 'ramda';
import { compact, numberToCurrency, calculatePercentage } from 'utils';
import { IPrice, IPricingResource } from 'pricing';
import { CostResourceIds } from 'enums';
import {
  IBulkUpdatePayloadItem,
  IBulkUpdatePayload,
  IMenuActionItemHelpers,
} from './types';

export function groupPrices<P extends IPrice[]>(data: P): IPrice[] {
  const newData: IPrice[] = [];

  const grouped = R.groupBy((p: IPrice) => `${p.service.id}`)(data);

  Object.keys(grouped).forEach((serviceId) => {
    // @ts-ignore
    const resources: IPrice[] = grouped[serviceId];
    newData.push({
      id: +serviceId,
      // @ts-ignore
      resources: resources.map((r) => ({
        ...r.serviceResource,
        spId: r.spId,
        spName: r.spName,
        value: r.value,
      })),
      serviceTitle: resources[0].service.serviceTitle,
    });
  });

  return newData;
}

export function sortPriceList(list: IPricingResource[]) {
  const grouped = R.groupBy<IPricingResource>((r) => r.id.toString())(list);

  return (desiredOrder: number[], targetKey: 'id') =>
    R.flatten(
      compact(
        R.reduce<number, IPricingResource[][]>((res, resourceId) => {
          let price = R.filter(
            (elem: IPricingResource) => elem[targetKey] === resourceId
          )(grouped[resourceId] || []);

          if (+resourceId === CostResourceIds.softwarePackage) {
            price = price.sort((a, b) =>
              // @ts-ignore
              a.spName?.toUpperCase() < b.spName?.toUpperCase() ? -1 : 1
            );
          }

          res.push(price || []);

          return res;
        }, [])(desiredOrder)
      )
    );
}

export const displayPriceValue = (value: number) => {
  return numberToCurrency(value, false, {
    maximumFractionDigits: 4,
    minimumIntegerDigits: 1,
  });
};

export function selectAllPrice(
  state: IBulkUpdatePayload,
  on: boolean
): IBulkUpdatePayload {
  const { percentage, ...rest } = state;
  const res = { percentage } as IBulkUpdatePayload;

  Object.keys(rest).forEach((key) => {
    res[key as any] = on;
  });

  return res as IBulkUpdatePayload;
}

export function parseBulkUpdatePayload(
  values: IBulkUpdatePayload,
  prices: IPrice[]
): IBulkUpdatePayloadItem[] {
  const { percentage, ...rest } = values;
  const res: IBulkUpdatePayloadItem[] = [];
  Object.keys(rest).forEach((key) => {
    const foundPrice = prices.find((price) => +price.id === +key) as IPrice;
    // @ts-ignore
    if (values[key] && foundPrice) {
      res.push({
        value: +displayPriceValue(
          calculatePercentage(+foundPrice.value, +percentage)
        ),
        priceId: +key,
      });
    }
  });
  return res;
}

export function generateMenuActionItems(
  config: IMenuActionItemHelpers.IConfig
): IMenuActionItemHelpers.MenuItem[] {
  const res: IMenuActionItemHelpers.MenuItem[] = [];

  if (!config.isEditable) return [];

  if (config.isOnUserPricing) {
    res.push('bulk-update');
    res.push('reset');
  }

  return res;
}
