import React, { ReactNode, useRef } from 'react';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import { AnyFunc, AnyShape } from 'global-shapes';
import { useFormik } from 'formik';
import { validateCallback, ValidatorRules } from '../utils';
import {
  Input,
  CancelIconRounded,
  SuccessIcon,
  InputProps,
  Row,
  Col,
  MuiIcons,
} from 'elements';

export interface EditableTextProps extends InputProps {
  className?: string;
  inputClassName?: string;
  textClassName?: string;
  actionRowProps?: AnyShape;
  updateWrapperProps?: AnyShape;
  onSuccess: AnyFunc;
  onCancel?: AnyFunc;
  validate?: (...args: any) => string | undefined;
  isUpdating?: boolean;
  isInactive?: boolean;
  displayText?: any;
  text: string;
  alignItems?: string;
  onSubmit?: AnyFunc;
  typingRules?: ValidatorRules;
  editComponent?: ReactNode;
  onDelete?: () => void;
  actionsPortal?: Element;
  editElementPortal?: Element;
  isEditable?: boolean;
}

const EditIcon = (props: any) => (
  <MuiIcons.Edit {...props} className="steel fs-20 ml-10 pointer" />
);

type ActionsProps = {
  onCancel: AnyFunc;
  onSuccess: () => void;
  portal?: Element;
  testId?: string;
  actionRowProps?: AnyShape;
  isUpdating?: boolean;
};

const Actions = (props: ActionsProps) => {
  const { onCancel, onSuccess, portal, isUpdating, actionRowProps } = props;
  const content = (
    <div
      {...actionRowProps}
      className={cn('flex align-center', actionRowProps?.className)}
    >
      <CancelIconRounded
        onClick={onCancel}
        className={cn('mr-5', { disabled: isUpdating })}
        testId={`${props.testId}-cancel-button`}
      />
      <SuccessIcon
        onClick={onSuccess}
        className={cn({ disabled: isUpdating })}
        testId={`${props.testId}-success-button`}
      />
    </div>
  );

  if (portal) return createPortal(content, portal);
  return content;
};

const EditElementWithPortal = (props: {
  portal?: Element;
  EditComponent?: ReactNode | any;
  onStartEditing: AnyFunc;
  testId?: string;
}) => {
  const { portal, onStartEditing, EditComponent, testId } = props;
  const EditEl = (
    // @ts-ignore
    <EditComponent onClick={onStartEditing} data-test-id={testId} />
  );
  if (portal) {
    return createPortal(EditEl, portal);
  }
  return EditEl;
};

const EditableText = (props: EditableTextProps) => {
  const {
    text,
    displayText,
    textClassName,
    className,
    inputClassName,
    validate,
    onSuccess,
    editComponent,
    typingRules,
    actionsPortal,
    editElementPortal,
    updateWrapperProps,
    isUpdating,
    actionRowProps,
    testId,
    isEditable,
    onDelete,
    alignItems,
    isInactive,
    ...rest
  } = props;
  const input = useRef();
  const [isEditing, handleEdit] = React.useState(false);
  const {
    handleSubmit,
    values,
    setFieldValue,
    handleChange,
    errors,
    submitForm,
    setErrors,
  } = useFormik({
    initialValues: { text },
    validateOnMount: false,
    validateOnChange: false,
    validate: (val) => {
      const errors: any = {};
      const textError = validate && validate(val.text);
      if (textError) {
        errors.text = textError;
      }
      return errors;
    },
    onSubmit: (val, config) => {
      if (isEditing) {
        onSuccess(val.text);
        handleEdit(false);
        config.setValues({ text: '' });
      }
    },
  });

  const onStartEditing = React.useCallback(() => {
    setFieldValue('text', text);
    handleEdit(true);
  }, [text]);

  const EditComponent = editComponent || EditIcon;

  return (
    <form
      onSubmit={handleSubmit}
      data-test-id={testId ? `${testId}-form` : ''}
      className={cn('flex align-center full-width lh-1', className, {
        disabled: isUpdating,
      })}
    >
      {isEditing ? (
        <Row {...updateWrapperProps} alignItems={alignItems || 'center'}>
          <Col xs>
            <Input
              {...rest}
              name="text"
              disabled={isUpdating}
              ref={input.current}
              className={inputClassName}
              elementSize="sm"
              value={values.text}
              onChange={validateCallback(typingRules)((ev: any) => {
                handleChange(ev);
                rest.onChange && rest.onChange(ev);
              })}
              error={!isUpdating && !!errors.text}
              helperText={errors.text}
              testId={testId}
            />
          </Col>
          <Col className={cn(!actionsPortal && 'pl-10')}>
            <Actions
              actionRowProps={actionRowProps}
              isUpdating={isUpdating}
              onCancel={() => {
                handleEdit(false);
                setFieldValue('text', '');
                setErrors({});
                rest.onCancel && rest.onCancel();
              }}
              onSuccess={() => {
                submitForm();
              }}
              portal={actionsPortal}
              testId={testId}
            />
          </Col>
        </Row>
      ) : (
        <>
          <span
            className={textClassName}
            data-test-id={`${testId}-display-text`}
          >
            {displayText || text}
          </span>
          {!isInactive && (
            <>
              {isEditable && (
                <EditElementWithPortal
                  portal={editElementPortal}
                  onStartEditing={onStartEditing}
                  EditComponent={EditComponent}
                  testId={`${testId}-edit-button`}
                />
              )}
              {onDelete && (
                <MuiIcons.Delete
                  onClick={onDelete}
                  className="steel fs-22 ml-10 pointer"
                />
              )}
            </>
          )}
        </>
      )}
    </form>
  );
};

export default EditableText;
