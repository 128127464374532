import * as React from 'react';
import { useAsync } from './useAsync';
import { billingCyclesService } from 'services';
import { confirm, showSystemMessage } from 'utils';
import { useTranslation } from 'react-i18next';

interface ReturnShape {
  cycle?: IVmTypes.IBillingCircle;
  isRequesting: boolean;
  isTerminated: boolean;
  terminate: (terminated: boolean) => Promise<any>;
}

export function useBillingCycle(
  id?: number,
  trnsltns = 'services.vm.info.billingInfo.cycle'
): ReturnShape {
  const { t } = useTranslation();
  const { execute, isPending, value } = useAsync(
    billingCyclesService.getBillingCycle
  );

  const isTerminated = !!value?.canceledAt;

  const terminateCycle = React.useCallback(
    () =>
      billingCyclesService
        .terminateCycle(id as number)
        .then(() => {
          showSystemMessage(`${trnsltns}.notify.terminate.success`, 'success');
          return execute(id);
        })
        .catch((err) => showSystemMessage(err.message, 'error')),
    [id]
  );

  const enableCycle = React.useCallback(
    () =>
      billingCyclesService
        .enableCycle(id as number)
        .then(() => {
          showSystemMessage(
            `${trnsltns}.notify.undoTerminate.success`,
            'success'
          );
          return execute(id);
        })
        .catch((err) => showSystemMessage(err.message, 'error')),
    [id]
  );

  const onConfirmTermination = React.useCallback(
    (terminated: boolean) => {
      return new Promise((res, rej) => {
        const request = terminated ? enableCycle : terminateCycle;
        return confirm({
          title: t(
            `${trnsltns}.confirm.${
              terminated ? 'undoTerminate' : 'terminate'
            }.title`
          ),
          content: t(
            `${trnsltns}.confirm.${
              terminated ? 'undoTerminate' : 'terminate'
            }.content`
          ),
          onSuccess: () => request().then(res).catch(rej),
          successLabel: terminated
            ? `${trnsltns}.confirm.buttons.undoTerminate`
            : `${trnsltns}.confirm.buttons.terminate`,
          onCancel: () => rej(),
          cancelLabel: 'common.cancel',
        });
      });
    },
    [id]
  );

  React.useEffect(() => {
    if (id) {
      execute(id);
    }
  }, [id]);

  return {
    cycle: value || undefined,
    terminate: onConfirmTermination,
    isTerminated,
    isRequesting: isPending,
  };
}
