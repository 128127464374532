import { showSystemMessage } from 'utils/global';
import { foldersService, virtualMachinesService } from 'services';
import { BaseMobxInstanceDecorator } from './utilities';

export const folders = new BaseMobxInstanceDecorator<
  IServiceGroups.Group,
  true
>({
  instanceName: 'folders',
  requests: {
    get: foldersService.getFolders,
    create: async (payload) => {
      const { tenantId, ...rest } = payload;
      const res = await foldersService.createFolder(tenantId, rest);
      showSystemMessage('folders.notify.create.success', 'success');
      return res as any;
    },
    update: async (id, payload) => {
      const { tenantId, groupId } = id;
      const res = await foldersService.updateFolder(tenantId, groupId, payload);
      showSystemMessage('folders.notify.update.success', 'success');
      return res as any;
    },
    remove: async (id) => {
      const { tenantId, groupId } = id;
      const res = await foldersService.removeFolder(tenantId, groupId);
      showSystemMessage('folders.notify.remove.success', 'success');
      return res;
    },
  },
  additionalRequests: {
    moveToFolder: foldersService.moveToFolder,
    removeFromFolder: foldersService.removeFromFolder,
  },
  initialState: [],
});

export const folderContent = new BaseMobxInstanceDecorator<IVmTypes.Vm, true>({
  instanceName: 'folders-inner-content',
  requests: {
    get: async (query) => {
      const res = await virtualMachinesService.get({
        orderBy: 'name',
        orderType: 'asc',
        ...query,
      });

      return res;
    },
  },
  additionalRequests: {},
  initialState: [],
});
