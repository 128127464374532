import React from 'react';
import { generateEntityLink } from '../helpers';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useState, useAsync } from 'hooks';
import { TablePagination, styled, IOnChangeTableProps } from 'elements';
import { searchEntityService } from 'services';
import { showSystemMessage } from 'utils';
import { DEFAULT_SEARCH_ENTITY_QUERY } from '../constants';

type IState = {
  query: ISearchEntity.TableParams;
};

type IProps = {
  entities: ISearchEntity.EntityDto[];
  entName: ISearchEntity.Entity;
  searchStr: string;
  totalCount: number;
  onClick: (link: string) => void;
};

const INITIAL_STATE = {
  query: DEFAULT_SEARCH_ENTITY_QUERY,
};

const Link = styled('div')(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const GroupItems = (props: IProps) => {
  const { entName, onClick, entities, totalCount, searchStr } = props;
  const { t } = useTranslation();
  const [state, handleState] = useState<IState>(INITIAL_STATE);

  const {
    execute,
    isPending,
    value: restEntities,
  } = useAsync(searchEntityService.getEntities, []);

  const onPageChange = React.useCallback(
    async (q: IOnChangeTableProps) => {
      const newQuery = { ...state.query, page: q.page };
      if (q.page > 1) {
        await execute({
          entity: [entName],
          perPage: 20,
          q: searchStr,
          page: q.page,
        }).catch((er) => showSystemMessage(er.messae, 'error'));
      }
      handleState({ query: newQuery });
    },
    [entName, searchStr]
  );

  const data: ISearchEntity.EntityDto[] =
    state.query.page > 1 ? restEntities || [] : entities;

  return (
    <div>
      <div key={entName} className="mb-20">
        <div className="bolder">{t(`app.instances.${entName}`) as string}</div>
        {data.map((ent) => {
          const { link, name } = generateEntityLink(ent);
          return (
            <Link
              key={ent.entityId}
              className={cn('steel pointer', { disabled: isPending })}
              onClick={() => onClick(link)}
            >
              {name}
            </Link>
          );
        })}
      </div>
      {state.query.perPage < totalCount && (
        <TablePagination
          shouldUseQuery={false}
          page={state.query.page}
          perPage={state.query.perPage}
          totalCount={totalCount}
          onChange={onPageChange}
        />
      )}
    </div>
  );
};

export default GroupItems;
