import * as React from 'react';
import {
  APP_SERVICES_GROUP,
  BackupCosts,
  backupNamespaces,
  CostResourceIds,
  generateInitialPlanState,
} from 'enums';
import { IReducerChangeHandler } from 'utils';
import { IBackup, IJobScheduleKey, IPlanTextStateKey } from 'backup';
import { usePrice } from 'hooks/usePrices';
import { useState } from 'hooks/useState';
import {
  parseBackupToForm,
  parseVmDetailsFoCalculation,
} from 'components/Backup/helpers';
import { useCostEstimation } from './useCostEstimation';

export type StateKeys = IJobScheduleKey | IPlanTextStateKey;

export type State = Record<StateKeys, any>;

type ICosts = {
  backupStorageFinalMin: number;
  backupStorageFinalMax: number;
  backupBaseMonthlyFee: number;
};

type Result = {
  values: State;
  handleChange: IReducerChangeHandler<State>;
  costs: ICosts;
  isRequesting: boolean;
};

type IConfig = {
  vmDiskSize: number;
  initialValues?: IBackup;
} & BackupTypes.ICommonPlanDialogProps;

export function useBackupCostCalculation(config: {
  values: State;
  vmDiskSize: number;
}): ICosts {
  const { values: state, vmDiskSize } = config;

  const pricing = usePrice([
    CostResourceIds.virtualServerGBStorage,
    CostResourceIds.backupBase,
    CostResourceIds.backupStorage,
  ]);

  const backupStorage = pricing[CostResourceIds.backupStorage].monthly / 1024;

  const noPlanSelected = backupNamespaces.every((key) => !state[key]);

  const maxBackupCost = React.useMemo(() => {
    let dailyFb = 0;
    let weeklyFb = 0;
    let monthlyFb = 0;
    let dailyIb = 0;
    const weeklyIb = 0;
    const monthlyIb = 0;

    if (state.dailyJobSchedule) {
      dailyFb = Math.ceil(state.dailyRetention.value / 7);
      dailyIb =
        state.dailyRetention.value * (24 / state.dailyHours.value) - dailyFb;
    }

    if (state.weeklyJobSchedule) {
      weeklyFb = state.weeklyWeeks.value - dailyFb;
    }

    if (state.monthlyJobSchedule) {
      monthlyFb = Math.ceil(
        state.monthlyMonths.value - (dailyFb + weeklyFb) / 4
      );
    }

    const Fb = dailyFb + weeklyFb + monthlyFb;
    const Ib = dailyIb + weeklyIb + monthlyIb;

    return {
      dailyFb,
      Fb,
      Ib,
    };
  }, [
    state.dailyJobSchedule,
    state.weeklyJobSchedule,
    state.monthlyJobSchedule,
    state.dailyHours,
    state.dailyFrom,
    state.dailyRetention,
    state.weeklyDay,
    state.weeklyWeeks,
    state.monthlyMonths,
  ]);

  const minBackupCost = React.useMemo(() => {
    let dailyFw = 0;
    let weeklyFw = 0;
    let monthlyFw = 0;
    let dailyIw = 0;
    const weeklyIw = 0;
    const monthlyIw = 0;

    if (state.dailyJobSchedule) {
      dailyFw = maxBackupCost.dailyFb + 1;
      dailyIw =
        (state.dailyRetention.value + 6) * (24 / state.dailyHours.value) -
        dailyFw;
    }

    if (state.weeklyJobSchedule) {
      weeklyFw = state.weeklyWeeks.value;
    }

    if (state.monthlyJobSchedule) {
      monthlyFw = state.monthlyMonths.value;
    }

    const Fw = dailyFw + weeklyFw + monthlyFw;
    const Iw = dailyIw + weeklyIw + monthlyIw;

    return {
      Fw,
      Iw,
    };
  }, [
    JSON.stringify([
      state.dailyJobSchedule,
      state.weeklyJobSchedule,
      state.monthlyJobSchedule,
      state.dailyHours,
      state.dailyFrom,
      state.dailyRetention,
      state.weeklyDay,
      state.weeklyWeeks,
      state.monthlyMonths,
      maxBackupCost,
    ]),
  ]);

  const backupStorageDailyMin =
    (vmDiskSize / BackupCosts.Cw) *
    (minBackupCost.Fw + BackupCosts.Gw * minBackupCost.Iw);

  const backupStorageDailyMax =
    (vmDiskSize / BackupCosts.Cb) *
    (maxBackupCost.Fb + BackupCosts.Gb * maxBackupCost.Ib);

  const backupStorageFinalMin = backupStorageDailyMax * backupStorage;
  const backupStorageFinalMax = backupStorageDailyMin * backupStorage;
  const backupBaseMonthlyFee = noPlanSelected
    ? 0
    : pricing[CostResourceIds.backupBase]?.monthly;

  return {
    backupStorageFinalMin,
    backupStorageFinalMax,
    backupBaseMonthlyFee,
  };
}

export function useBackupPlan({
  vmDiskSize,
  initialValues,
  instance,
}: IConfig): Result {
  const [state, handleState] = useState<State>(generateInitialPlanState());
  const RdsCoreCost = useBackupCostCalculation({ values: state, vmDiskSize });

  const isVm = instance.entityName === 'Vm';

  const values = {
    ...parseVmDetailsFoCalculation(instance as IVmTypes.Vm),
    ...state,
    isBackupEnabled: true,
  };

  const { displayList, params, isRequesting } = useCostEstimation({
    values,
    instance,
    serviceIds: [APP_SERVICES_GROUP.Backup.id],
    paralyzeHook: !isVm,
  });

  const backupBase = params.backupSchedule ? displayList[0]?.meta?.cost : 0;
  const backupStorage = params.backupSchedule
    ? displayList[1]?.meta
    : { min: 0, max: 0 };

  const VmCost = {
    backupStorageFinalMin: backupStorage?.min,
    backupStorageFinalMax: backupStorage?.max,
    backupBaseMonthlyFee: backupBase,
  };

  const cost = isVm ? VmCost : RdsCoreCost;

  React.useEffect(() => {
    if (initialValues) {
      handleState(parseBackupToForm(initialValues));
    } else {
      handleState(generateInitialPlanState());
    }
  }, [JSON.stringify(initialValues)]);

  return {
    values: state,
    handleChange: handleState,
    costs: cost,
    isRequesting,
  };
}
