import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { AnyShape } from 'global-shapes';
import { SYSTEM_INFO } from '../../../../constants';
import { CopyTooltip, Row, Col, ActiveServiceLayout } from 'elements';
import { useVmActiveServices } from 'hooks';
import { VmSystemInfoValue } from '../Styled';

type Props = IVmTypes.Vm;

export const OperatingSystemInfo = (
  props: IVmTypes.Vm & { size: number } & AnyShape
) => {
  const { t } = useTranslation();

  return (
    <Row alignItems="center" className="full-width">
      {SYSTEM_INFO.map((item) => {
        const Icon = item.img;
        return (
          <Col key={item.key} xs={4}>
            <Row columnSpacing={1}>
              <Col>
                <Icon size={props.size} scale={props.size === 40 ? 1.3 : 1} />
              </Col>
              <Col>
                <Row
                  justifyContent="space-between"
                  direction="column"
                  className="full-height"
                >
                  <div className="steel fs-12">
                    {t(`services.card.content.${item.key}`)}
                  </div>
                  <VmSystemInfoValue
                    className={cn({
                      'fs-12': props.size === 28,
                      'fs-17': props.size === 40,
                    })}
                  >
                    {item.modify
                      ? item.modify(props[item.key])
                      : props[item.key]}
                  </VmSystemInfoValue>
                </Row>
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export const DedicatedServerCard = (props: Props) => {
  const { t } = useTranslation();
  const activeServices = useVmActiveServices(props);
  const hasActiveServices = activeServices.length > 0;

  const nic = props.nics[0];

  return (
    <div className="fs-14">
      <div className="steel fs-14 mb-10 break-line-all lh-12">
        {props.dedicatedServer?.description}
      </div>

      {props.os && (
        <div className="mb-15">
          <div className="primary-dark">
            {t('services.card.content.operatingSystem')}
          </div>
          <div className="steel">
            <CopyTooltip>{props.os.name}</CopyTooltip>
          </div>
        </div>
      )}

      <div className="mb-10">
        <OperatingSystemInfo {...props} size={28} />
      </div>

      {nic && (
        <div className="mb-10">
          <div className="flex align-center">
            <span className="bolder mr-5">IPv4:</span>
            <span className="steel">
              <CopyTooltip key={nic.ipv4} contentToCopy={nic.ipv4}>
                {nic.ipv4}
              </CopyTooltip>
            </span>
          </div>
          {nic.ipv6 && (
            <div className="flex align-center">
              <span className="bolder mr-5">IPv6:</span>
              <span>
                <div key={nic.id} className="steel">
                  <CopyTooltip key={nic.ipv6}>{nic.ipv6}</CopyTooltip>
                </div>
              </span>
            </div>
          )}
        </div>
      )}
      <div className="primary-dark bolder mb-5">
        {t('services.card.content.activeServices')}
      </div>
      {hasActiveServices ? (
        <ActiveServiceLayout
          services={activeServices}
          instance={`vm-${props?.id}`}
        />
      ) : (
        <span className="steel">
          {t('services.card.content.activeServices.noServices')}
        </span>
      )}
    </div>
  );
};
