import * as React from 'react';
import { Row, Col, Button } from 'elements';
import { useTranslation, Trans } from 'react-i18next';
import { useCallback } from 'react';
import { confirm, noop } from 'utils';
import {
  IBillableItem,
  IBillableItemFormValues,
  IBIParams,
} from 'billable-items';
import { useQuery, useState } from 'hooks';
import { InjectedPermissionsProps } from 'hocs';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { parseBillableItemDetailsFromForm, queryToWatch } from './helpers';
import Filters from './Filters';
import BillableItems from './BillableItems';
import BillableItemDetailsDialog from './BillableItemDetailsDialog';
import { IBIAccessLevel } from './constants';
import { MainPage } from './Styled';

interface IState {
  currentItem?: IBillableItem;
  open: boolean;
}

const INITIAL_STATE: IState = {
  currentItem: undefined,
  open: false,
};

const OBSERVERS = {
  biItems: StateHandlers.billableItems,
  defaultPricing: StateHandlers.defaultPricing,
  mainPricing: StateHandlers.mainPricing,
  tenantPricing: StateHandlers.tenantPricing,
};

export type IProps = React.PropsWithChildren<
  InjectedPermissionsProps & {
    level: IBIAccessLevel;
  }
>;

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    level,
    permissions,
    biItems,
    defaultPricing,
    mainPricing,
    tenantPricing,
  } = props;
  const router = useQuery<IBIParams>();
  const queryStr = queryToWatch(router.queryStr);
  const [state, handleChange] = useState<IState>(INITIAL_STATE);
  const { t } = useTranslation();

  const pricing = {
    provider: defaultPricing,
    partner: mainPricing,
    tenant: tenantPricing,
  };

  const pricesReceived = (pricing as any)[level].dataReceived;

  const fetchBillableItems = React.useCallback(
    () =>
      biItems.get({
        ...router.query,
        include:
          level === 'provider'
            ? 'partner,user,recurringSettings'
            : 'user,tenant,recurringSettings',
      }),
    [queryStr, JSON.stringify(router.query)]
  );

  const fetchProviderDefaultPricing = useCallback(() => {
    return defaultPricing.get();
  }, []);

  const fetchPartnerPricing = useCallback(() => {
    return mainPricing.get();
  }, []);

  const fetchTenantPricing = useCallback(() => {
    return tenantPricing.get();
  }, []);

  const onAddClick = React.useCallback(
    () => handleChange({ currentItem: undefined, open: true }),
    []
  );

  const onEditClick = React.useCallback(
    (currentItem?: IBillableItem) =>
      handleChange({ currentItem, open: !!currentItem }),
    []
  );

  const onModalClose = React.useCallback(
    () => handleChange({ currentItem: undefined, open: false }),
    []
  );

  const onActionSuccess = React.useCallback(() => {
    fetchBillableItems();
    onModalClose();
  }, [queryStr, fetchBillableItems]);

  const onDeleteItem = React.useCallback(
    (bi: IBillableItem) => biItems.remove(bi.id).then(onActionSuccess),
    [queryStr, onActionSuccess]
  );

  const onConfirmDeleteItem = React.useCallback(
    (bi: IBillableItem) => {
      confirm({
        title: t('billableItems.confirm.delete.title'),
        content: (
          <div className="steel fs-14">
            <Trans
              i18nKey="billableItems.confirm.delete.content"
              values={{ description: bi.description }}
              components={{ 1: <strong /> }}
            />
          </div>
        ),
        onSuccess: () => onDeleteItem(bi),
        onCancel: noop,
      });
    },
    [queryStr, onDeleteItem]
  );

  const onCreate = React.useCallback(
    (payload: IBillableItemFormValues) => {
      biItems
        .create(parseBillableItemDetailsFromForm(payload))
        .then(onActionSuccess);
    },
    [queryStr]
  );

  const onUpdate = React.useCallback(
    (id: number, payload: IBillableItemFormValues) => {
      biItems
        .update(
          id,
          parseBillableItemDetailsFromForm(payload, state.currentItem)
        )
        .then(onActionSuccess);
    },
    [queryStr, state.currentItem]
  );

  const onSubmit = React.useCallback(
    (payload: IBillableItemFormValues) => {
      return state.currentItem
        ? onUpdate(state.currentItem.id, payload)
        : onCreate(payload);
    },
    [state.currentItem, queryStr]
  );

  React.useEffect(() => {
    if (level === 'provider') {
      fetchProviderDefaultPricing();
    }
    if (level === 'partner') {
      fetchPartnerPricing();
    }
    if (level === 'tenant') {
      fetchTenantPricing();
    }
  }, [level]);

  React.useEffect(() => {
    fetchBillableItems();
  }, [queryStr]);

  return (
    <MainPage>
      <Row
        rowSpacing={1}
        columnSpacing={2}
        justifyContent="space-between"
        alignItems="center"
        className="mb-5"
      >
        <Col>
          <h3>{t('billableItems.title') as string}</h3>
        </Col>
        {permissions.canManage && (
          <Col>
            <Button onClick={onAddClick}>
              {t('billableItems.buttons.addItem')}
            </Button>
          </Col>
        )}
      </Row>
      <div className="mb-5 steel half-width lh-14">
        {t(`billableItems.${level}.subtitle`) as string}
      </div>
      <Filters router={router} accessLevel={level} />
      <BillableItems
        accessLevel={level}
        router={router}
        onEdit={onEditClick}
        onDelete={onConfirmDeleteItem}
        permissions={permissions}
        isRequesting={biItems.isRequesting}
      />
      {pricesReceived && (
        <BillableItemDetailsDialog
          onSave={onSubmit}
          accessLevel={level}
          isNew={!state.currentItem}
          onClose={onModalClose}
          currentItem={state.currentItem}
          open={state.open}
          isRequesting={biItems.isRequesting}
        />
      )}
    </MainPage>
  );
});

const BillableItemsContainer = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default BillableItemsContainer;
