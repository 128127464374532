import * as Yup from 'yup';
import { REGEX } from 'enums';

export const FolderTypeOptions: ValueType<IServiceGroups.GroupType>[] = [
  { label: 'folders.options.VIRTUAL_SERVER', value: 'VIRTUAL_SERVER' },
  { label: 'folders.options.DEDICATED_SERVER', value: 'DEDICATED_SERVER' },
];

export const InitialFolderState: IServiceGroups.GroupDetailsFormValues = {
  name: '',
  description: '',
  type: FolderTypeOptions[0],
};

export const folderValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .max(30, 'forms.invalid.max')
    .required('forms.required')
    .matches(REGEX.folderName, 'forms.invalid'),
  type: Yup.object().required('forms.required'),
});
