import React from 'react';
import MuiPagination from '@mui/material/TablePagination';
import { useQuery } from 'hooks';
import { remCalc } from 'utils';
import { GLOBAL_PER_PAGE, PALETTE } from 'enums';
import { styled } from './utilities';

export interface IOnChangeTableProps {
  page: number;
  perPage: number;
  offset: number;
}

interface Props {
  onChange?: (args: IOnChangeTableProps) => void;
  shouldUseQuery?: boolean;
  perPage?: number;
  page?: number;
  totalCount?: number;
}

const Pagination = styled(MuiPagination)(({ theme }) => {
  return {
    fontSize: remCalc(14),

    '& .MuiTablePagination-selectLabel': {
      display: 'none',
    },

    '& .MuiSelect-root': {
      display: 'none',
    },

    '& .MuiTablePagination-displayedRows': {
      fontSize: remCalc(14),
      color: PALETTE.steel,
    },

    '& .MuiIconButton-root': {
      fontSize: remCalc(14),
    },

    '& .MuiSvgIcon-root': {
      width: remCalc(20),
      height: remCalc(20),
    },
  };
});

const defaultProps = {
  shouldUseQuery: true,
  limit: 20,
  nextOffset: null,
  nextPage: null,
  totalCount: 0,
};

export function TablePagination(_props: Props) {
  const props = { ...defaultProps, ..._props };
  const { shouldUseQuery, totalCount, onChange } = props;
  const { query, queryStr } = useQuery<any>();
  const page = (shouldUseQuery ? +query.page : props.page) || 1;
  const totalItems = totalCount || 0;
  const perPage =
    (shouldUseQuery ? +query.perPage : props.perPage) || GLOBAL_PER_PAGE;

  const handleChangePage = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      onChange &&
        onChange({ page: newPage + 1, perPage, offset: newPage * perPage });
    },
    [perPage, queryStr, props.page, totalCount, shouldUseQuery, onChange]
  );

  return (
    <div className="flex justify-end full-width">
      <Pagination
        // @ts-ignore
        component="div"
        count={totalItems}
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={perPage}
        rowsPerPageOptions={[]}
      />
    </div>
  );
}
