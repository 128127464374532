import * as React from 'react';
import { Row, Col, Paper, Button } from 'elements';
import { useTask } from 'hooks';
import { confirm } from 'utils';
import { withVmActions, InjectedProps } from 'hocs/withVmActions';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import NicItem from './NicItem';

type IProps = React.PropsWithChildren<{
  vmId: number;
  isEditable: boolean;
  isPublic: boolean;
  onAddApiSuccess: () => void;
}>;

const OBSERVERS = {
  reverseDns: StateHandlers.reverseDns,
  connectivity: StateHandlers.connectivity,
  nics: StateHandlers.vmNics,
  vmDetails: StateHandlers.vmDetails,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps & InjectedProps) => {
  const {
    vmId,
    isEditable,
    reverseDns,
    nics,
    vmDetails,
    handlers,
    isRequesting,
    isPublic,
  } = props;
  const { t } = useTranslation();

  const appEntityIds = nics.data.map((n) => n.appEntityId);

  const fetchNics = React.useCallback(() => {
    return nics.get({ vmId, orderType: 'asc' });
  }, [vmId]);

  useTask(vmDetails.data?.task, { onComplete: fetchNics });

  const fetchReverseDnsRecords = React.useCallback(() => {
    return reverseDns.get({ appEntityIds });
  }, [appEntityIds]);

  const handleRecordEdit = React.useCallback(
    (appEntityId: number, ipAddress: string, record?: IReverseDnsRecord) =>
      async (text: string) => {
        const isNew = !record;
        if (isNew) {
          await reverseDns.create({
            appEntityId,
            recordDomainName: text,
            ipAddress,
          });
        } else {
          await reverseDns.update(record.id, {
            recordDomainName: text,
            ipAddress,
          });
        }

        await fetchNics();
        return fetchReverseDnsRecords();
      },
    [vmId]
  );

  const refreshData = React.useCallback(() => {
    fetchReverseDnsRecords();
    fetchNics();
    return vmDetails.get({
      id: vmId,
      include: ['serviceMonitoring', 'serviceSnapshot'],
    });
  }, [appEntityIds, vmId]);

  const onSetAsPrimary = React.useCallback(
    (nic: IVmTypes.Nic) => {
      confirm({
        title: 'services.vm.confirm.setAsPrimary.title',
        content: 'services.vm.confirm.setAsPrimary.content',
        onSuccess: async () => {
          await nics.update(
            { vmId, nicId: nic.id },
            {
              ipv4Enabled: !!nic.ipv4,
              ipv6Enabled: !!nic.ipv6,
              isPrimary: true,
            }
          );
          return refreshData();
        },
        onCancel: () => undefined,
      });
    },
    [refreshData]
  );

  React.useEffect(() => {
    fetchReverseDnsRecords();
    fetchNics();
  }, [vmId]);

  return (
    <Paper className="p-30 mb-20">
      <Row alignItems="center" justifyContent="space-between" className="mb-20">
        <Col>
          <div className="fs-14 uppercase">
            {t('services.vm.info.externalNetwork.title') as string}
          </div>
        </Col>
        {isEditable && (
          <Col>
            <Row columnSpacing={2}>
              {isPublic && (
                <Col>
                  <Button size="small" onClick={handlers.manageExternalIp}>
                    {t('services.vm.info.externalNetwork.buttons.manageIp')}
                  </Button>
                </Col>
              )}

              <Col>
                <Button size="small" onClick={handlers.attachNetwork}>
                  {t('services.content.action.attachNetwork')}
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {nics.data.map((nic) => (
        <NicItem
          key={nic.id}
          {...nic}
          handleRecordEdit={handleRecordEdit}
          refreshData={refreshData}
          vmId={vmId}
          isRequesting={isRequesting}
          onDetach={handlers.detachNetwork}
          onSetAsPrimary={onSetAsPrimary}
        />
      ))}
    </Paper>
  );
});

const ExternalNetworkDetails = (props: IProps & InjectedProps) => (
  <View {...props} {...OBSERVERS} />
);

export default withVmActions(ExternalNetworkDetails, 'single');
