import { remCalc } from 'utils';
import { styled } from 'elements';

export const InvoiceHolder = styled('div')(({ theme }) => ({
  padding: remCalc(40, 115, 70, 115),
  minHeight: remCalc(400),
}));

export const InvoiceSettingsEmptyWrapper = styled('div')(({ theme }) => ({
  maxWidth: remCalc(500),
}));

export const VersionHolder = styled('div')(({ theme }) => ({
  padding: remCalc(40, 115, 70, 115),
  maxWidth: remCalc(780),
}));

export const InvoiceSection = styled('section')(({ theme }) => ({
  paddingBottom: remCalc(25),
  marginBottom: remCalc(25),
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.grey['200']}`,
  },
}));
