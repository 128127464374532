import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LinearTable, Button, Row } from 'elements';
import { useState } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import NatRulesAllDialog from './NatRulesAllDialog';
import * as ENUMS from '../constants';

type IProps = {
  connectivityId?: number;
};

const OBSERVERS = {
  natRules: StateHandlers.natRules,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { connectivityId, natRules } = props;
  const { t } = useTranslation();
  const [state, handleState] = useState({ allRulesOpen: false });

  React.useEffect(() => {
    natRules.get({ connectivityId });
  }, [connectivityId]);

  const handleDialogOpen = React.useCallback(
    (allRulesOpen: boolean) => () => {
      handleState({ allRulesOpen });
    },
    []
  );

  const showMore = natRules.data.length > 10;

  return (
    <>
      <div className="uppercase fs-14 bolder mb-20">
        {t('services.connectivity.natRules.title')}
      </div>
      <div className="mb-35">
        <LinearTable
          data={natRules.data.slice(0, 10)}
          columns={ENUMS.getNatRulesColumns({ t })}
          isLoading={!natRules.dataReceived}
        />
        {showMore && (
          <Row justifyContent="center" className="mt-20">
            <Button
              color="default"
              variant="outlined"
              size="small"
              onClick={handleDialogOpen(true)}
            >
              {t('services.connectivity.natRules.buttons.viewMore')}
            </Button>
          </Row>
        )}
      </div>
      <NatRulesAllDialog
        open={state.allRulesOpen}
        onClose={handleDialogOpen(false)}
        onSave={() => undefined}
      />
    </>
  );
});

const NatRules = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default NatRules;
