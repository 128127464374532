import { Location } from 'react-router-dom';
import { virtualMachinesService } from 'services';

export type ISnapshotAction = 'create' | 'revert' | 'remove';
export type ICreateSnapshotPayload = { description: string };

export const billingCycleColorMap = {
  PENDING: 'systemInfo',
  ACTIVE: 'success',
  CANCELED: 'error',
  ARCHIVED: 'default',
};

export interface IParsedNetwork {
  ip4Subnet: string;
  ip6Subnet: string;
  ip4Gateway: string;
  ip6Gateway: string;
}

const INITIAL: IParsedNetwork = {
  ip4Subnet: '-',
  ip6Subnet: '-',
  ip4Gateway: '-',
  ip6Gateway: '-',
};

export const parseNetworkData = (
  network?: IVmTypes.IVmNetwork
): IParsedNetwork => {
  if (!network) return INITIAL;

  const ip4 = network.subnets[network.defaultIpv4SubnetIndex];
  const ip6 = network.subnets[network.defaultIpv6SubnetIndex];

  const ip4Subnet = ip4?.subnetMask || '-';
  const ip6Subnet = ip6?.subnetMask || '-';
  const ip4Gateway = ip4?.gateway || '-';
  const ip6Gateway = ip6?.gateway || '-';

  return {
    ip4Subnet,
    ip6Subnet,
    ip4Gateway,
    ip6Gateway,
  };
};

export function parseUpdateData(
  data: VmDialogTypes.IVmFormValues,
  isRootProvider: boolean
): VmDialogTypes.IVmUpdatePayload {
  const payload: VmDialogTypes.IVmUpdatePayload = {
    name: data.name.trim(),
    description: data.description,
    osDiskSizeGb: data.osDiskSizeGb,
    virtualCpus: data.virtualCpus,
    isUsageEnabled: data.isUsageEnabled,
    ramMb: data.ramMb * 1024,
    billingCycleInterval: data.billingCycleInterval.value,
  };

  if (!isRootProvider) {
    payload.isUsageEnabled = undefined;
  }

  return payload;
}

export const findIndexRoute = (hash: string, location: Location): boolean => {
  const ALL_VM_AND_DEDICATED_ROUTES = [
    '/services/vm/info',
    '/services/vm/disks',
    '/services/vm/public',
    '/services/vm/network',
    '/services/vm/backup',
    '/services/vm/monitor',
    '/services/dedicated/info',
    '/services/dedicated/monitor',
  ];
  const currentRoute = location.pathname.replace(`/${hash}`, '');
  return ALL_VM_AND_DEDICATED_ROUTES.some((el) => el === currentRoute);
};

export const getNicsOptions = (vmId: number) => async (q: string) => {
  const res = await virtualMachinesService.getVmNics(vmId, {
    q,
    networkType: ['NAT_ROUTED'],
    page: 1,
    perPage: 1000,
  });

  return res.data.map((n) => ({
    value: n.id,
    label: n.network?.name,
    netType: n.network?.type,
    isPrimary: n.network?.isPrimary,
  }));
};
