import React, { useCallback } from 'react';
import BasePricingView from './BasePricingViewWrapper';
import { toJS } from 'mobx';
import { showSystemMessage, confirm } from 'utils';
import { DEFAULT_CUSTOMERS_QUERY } from 'enums';
import { useQuery, useUserHash } from 'hooks';
import { groupPrices } from './helper';
import { AnyShape } from 'global-shapes';
import { withPermissions, InjectedPermissionsProps } from 'hocs';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { parseBulkUpdatePayload } from './helper';
import { IBulkUpdatePayload } from './types';

const OBSERVERS = {
  defaultPricing: StateHandlers.defaultPricing,
  partnerPricing: StateHandlers.partnerPricing,
  services: StateHandlers.pricingServices,
  currentPartner: StateHandlers.currentPartner,
  mainPricing: StateHandlers.mainPricing,
};

type IProps = InjectedPermissionsProps;

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    defaultPricing,
    partnerPricing,
    services,
    currentPartner,
    mainPricing,
    permissions,
  } = props;
  const { t } = useTranslation();
  const { query } = useQuery();
  const [, navigate] = useUserHash();

  const partnerPricingId = query.partner;

  const dataReceived =
    services.dataReceived &&
    defaultPricing.dataReceived &&
    currentPartner.dataReceived &&
    partnerPricing.dataReceived;

  const disabled =
    defaultPricing.isRequesting ||
    partnerPricing.isRequesting ||
    services.isRequesting ||
    defaultPricing.isRequesting;

  const createdPrices = partnerPricing.data;
  const createdPricesJS = toJS(partnerPricing.data);
  const defaultPrices = groupPrices(partnerPricing.data);

  const fetchProviderDefaultPricing = useCallback(() => {
    return defaultPricing.get();
  }, []);

  const fetchCurrentPartnerData = useCallback(() => {
    return currentPartner.get({ id: partnerPricingId });
  }, [partnerPricingId]);

  const fetchPricingServices = useCallback(() => {
    services.get();
  }, []);

  const setDefaultPrice = useCallback((data: AnyShape) => {
    return defaultPricing
      .create(data)
      .then(() => defaultPricing.get({ id: partnerPricingId }));
  }, []);

  const onUpdatePricing = useCallback(
    (data: AnyShape) => {
      mainPricing.update(data.id, data).then(() =>
        partnerPricing.get({
          partnerId: partnerPricingId,
        })
      );
    },
    [partnerPricingId]
  );

  const onResetPrice = useCallback(async () => {
    await mainPricing
      .executeRequest('resetPartnerPrices')(partnerPricingId)
      .catch((er) => {
        showSystemMessage(er.message, 'error');
      });
    showSystemMessage('settings.page.pricing.notify.reset.success', 'success');
    await partnerPricing.get({
      partnerId: partnerPricingId,
    });
    return fetchProviderDefaultPricing();
  }, [partnerPricingId]);

  const confirmResetPrice = React.useCallback(() => {
    confirm({
      title: 'settings.page.pricing.confirm.reset.title',
      content: 'settings.page.pricing.confirm.reset.content',
      onSuccess: onResetPrice,
      successLabel: 'common.reset',
      onCancel: () => undefined,
    });
  }, [partnerPricingId]);

  const onBulkUpdate = useCallback(
    async (data: IBulkUpdatePayload) => {
      await partnerPricing.executeRequest('bulkUpdate')(
        parseBulkUpdatePayload(data, createdPricesJS)
      );
      showSystemMessage(
        'settings.page.pricing.bulk.notify.update.success',
        'success'
      );
      return await partnerPricing.get({
        partnerId: partnerPricingId,
      });
    },
    [partnerPricingId, createdPricesJS, partnerPricing.data]
  );

  const fetchProviderPartnerPricing = useCallback(() => {
    partnerPricing.get({
      partnerId: partnerPricingId,
    });
  }, [partnerPricingId]);

  React.useEffect(() => {
    fetchPricingServices();
    fetchCurrentPartnerData();
    fetchProviderDefaultPricing();
    fetchProviderPartnerPricing();
  }, []);

  const goToPartner = React.useCallback(() => {
    const partner = currentPartner.data;
    navigate(`/${partner?.shortName}/tenants`, DEFAULT_CUSTOMERS_QUERY);
    return StateHandlers.fetchAllAccountData({
      psn: partner?.shortName,
      shouldApplyTheme: true,
    });
  }, [currentPartner.data]);

  return (
    <BasePricingView
      user={currentPartner.data}
      subtitle={t('settings.page.pricing.managePartner.subtitle')}
      data={createdPrices}
      defaultPricing={defaultPrices}
      dataReceived={dataReceived}
      isEditable={permissions.canManage}
      isOnUserPricing
      onUpdatePricing={onUpdatePricing}
      onResetPrice={confirmResetPrice}
      onSetPrice={setDefaultPrice}
      disabled={disabled}
      parentPricing={defaultPricing.data}
      goToUser={goToPartner}
      onBulkUpdate={onBulkUpdate}
    />
  );
});

const EditCurrentPartnerPricing = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default withPermissions(EditCurrentPartnerPricing, 'PRICING');
