import * as React from 'react';
import cn from 'classnames';
import Divider from '@mui/material/Divider';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import {
  Alert,
  Button,
  Col,
  CopyTooltip,
  IconButton,
  Menu,
  MenuItem,
  MuiIcons,
  Row,
} from 'elements';
import { RemoteSupportDialog } from 'components';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  usePermissions,
  useRemoteSupport,
  useState,
  useTask,
  useUserHash,
} from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { noop, shouldDisableWebConsoleLaunching } from 'utils';
import { InjectedProps, withCoreActions } from 'hocs/withCoreActions';
import ButtonList from 'components/ActionButtons/ButtonList';
import SnapshotInfo from 'components/SnapshotInfo';
import { dayjs } from '../../utils/dayjsExtended';
import { GLOBAL_DATE_TIME_FORMAT } from '../../enums';

type IProps = InjectedProps &
  React.PropsWithChildren<{
    t: TFunction;
    requireAgentUpdate?: boolean;
  }>;

interface IState {
  isRemoteSupportOpen: boolean;
  isDeleteDialogOpen: boolean;
}

const INITIAL_STATE = {
  isRemoteSupportOpen: false,
  isDeleteDialogOpen: false,
};

const OBSERVERS = {
  core: StateHandlers.core,
  tenantPricing: StateHandlers.tenantPricing,
  costBreakdown: StateHandlers.costBreakdown,
  snapshots: StateHandlers.core,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    handlers,
    actionItems,
    core,
    tenantPricing,
    costBreakdown,
    snapshots,
    requireAgentUpdate,
    menuItems,
    currentSnapshot,
  } = props;
  const { t } = useTranslation();
  const [state, handleState] = useState<IState>(INITIAL_STATE);

  const coreData = core.data;

  const { permissions } = usePermissions('SERVICES');
  const [, changeUrl] = useUserHash();

  const onMainTaskComplete = React.useCallback((action: string) => {
    core.get();
    tenantPricing.get();
    costBreakdown.get();
    snapshots.get();
    action === 'delete' && changeUrl('/services/all');
  }, []);

  const onSnapshotTaskComplete = React.useCallback(() => {
    core.get();
    snapshots.get();
  }, [coreData.id]);

  const coreTask = useTask(core.data?.task || null, {
    onComplete: onMainTaskComplete,
  });

  const snapshotTask = useTask(currentSnapshot?.task, {
    onComplete: onSnapshotTaskComplete,
  });

  const rs = useRemoteSupport({ type: 'core', onSuccess: core.get });

  const ableToDelete = coreTask.isCompleted || coreTask.isFailed;

  const createdSnapshot = !currentSnapshot?.schedule
    ? currentSnapshot
    : undefined;
  const scheduledSnapshot = currentSnapshot?.schedule
    ? currentSnapshot
    : undefined;

  const snapshotOptions = menuItems?.filter((item) =>
    [
      'createSnapshot',
      'revertSnapshot',
      'removeSnapshot',
      'removeScheduledSnapshotTask',
    ].includes(item.value as string)
  );

  return (
    <div>
      <Row className="mb-25" alignItems="center" justifyContent="space-between">
        <Col>
          <div className="uppercase fs-14">
            {t('services.core.title') as string}
          </div>
        </Col>
        <Col>
          {permissions.canManage && (
            <div className="flex align-center">
              <div>
                <ButtonList actions={actionItems || []} />
              </div>
              <div className="pl-10">
                <PopupState variant="popover">
                  {(popupState) => (
                    <>
                      <IconButton size="small" {...bindTrigger(popupState)}>
                        <MuiIcons.MoreVert className="fs-20 steel pointer" />
                      </IconButton>

                      <Menu {...bindMenu(popupState)}>
                        {snapshotOptions?.map((item) => (
                          <MenuItem
                            key={item.label}
                            disabled={item.disabled}
                            onClick={() => {
                              item.onClick && item.onClick();
                              popupState.close();
                            }}
                          >
                            {t(`services.content.action.${item.label}`)}
                          </MenuItem>
                        ))}

                        <Divider />
                        {requireAgentUpdate && (
                          <MenuItem
                            data-test-id={`core-inf-menu-item-update-agent`}
                            onClick={() => {
                              popupState.close();
                              return handlers.updateAgent();
                            }}
                          >
                            {t(`services.content.action.updateAgent`) as string}
                          </MenuItem>
                        )}
                        <MenuItem
                          data-test-id={`core-inf-menu-item-remote-support`}
                          disabled={
                            !(coreTask.isCompleted || coreTask.isFailed) ||
                            rs.isRequesting ||
                            rs.isTaskActive
                          }
                          onClick={() => {
                            rs.isUsedForCore
                              ? handlers.disableRemoteSupport()
                              : handlers.enableRemoteSupport();
                            popupState.close();
                          }}
                        >
                          {
                            t(
                              `services.content.action.remoteSupport.${
                                rs.isUsedForCore ? 'disable' : 'enable'
                              }`
                            ) as string
                          }
                        </MenuItem>
                        {!shouldDisableWebConsoleLaunching(
                          core.data?.displayStatus
                        ) && (
                          <MenuItem
                            data-test-id={`core-inf-menu-item`}
                            onClick={() => {
                              handlers.console();
                              popupState.close();
                            }}
                          >
                            {t(`services.content.action.console`) as string}
                          </MenuItem>
                        )}
                        <MenuItem
                          disabled={!ableToDelete}
                          data-test-id={`core-inf-menu-item`}
                          onClick={async () => {
                            popupState.close();
                            await handlers.delete();
                            return onMainTaskComplete('');
                          }}
                        >
                          {t(`common.removeService`) as string}
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </PopupState>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row
        className="fs-14 mb-30"
        alignItems="center"
        justifyContent="space-between"
      >
        <Col>
          {t('services.card.content.domainName') as string}:
          <span className="steel pl-5">
            <CopyTooltip>{coreData.domainName}</CopyTooltip>
          </span>
        </Col>
        {createdSnapshot && (
          <Col className={cn({ disabled: snapshotTask.isTaskActive })}>
            <SnapshotInfo snapshot={createdSnapshot} />
          </Col>
        )}
      </Row>
      {scheduledSnapshot && (
        <Alert severity="info" className="flex align-center mb-20">
          <Row columnSpacing={2} alignItems="center">
            <Col>
              {t('services.core.alert.snapshot.planned.title', {
                startAt: dayjs(scheduledSnapshot.schedule?.startAt).format(
                  GLOBAL_DATE_TIME_FORMAT
                ),
              })}
            </Col>
            <Col>
              <Button
                size="small"
                color="default"
                variant="outlined"
                onClick={() =>
                  handlers.removeScheduledSnapshotTask(scheduledSnapshot)
                }
              >
                {t('services.content.action.removeScheduledSnapshotTask')}
              </Button>
            </Col>
          </Row>
        </Alert>
      )}
      <RemoteSupportDialog
        subtitle="remoteSupport.dialog.core.subtitle"
        open={state.isRemoteSupportOpen}
        attributes={{
          coreInfrastructureId: coreData?.id,
          vmId: coreData?.vm?.id,
          osType: 'win',
        }}
        type="core"
        onSave={noop}
        onClose={() => handleState({ isRemoteSupportOpen: false })}
      />
    </div>
  );
});

const Title = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default withCoreActions(Title);
