import React from 'react';
import { BlacklistDomain } from 'blacklist';
import { Row, Col, MuiIcons } from 'elements';

type Props = BlacklistDomain & {
  onDelete: (id: number) => void;
};

const BlacklistItem = (props: Props) => {
  const { id, domainName, onDelete } = props;

  const onItemDelete = React.useCallback(() => {
    onDelete(id);
  }, [id]);

  return (
    <Row
      className="steel mb-10"
      columnSpacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Col>{domainName}</Col>
      <Col>
        <MuiIcons.Delete className="pointer" onClick={onItemDelete} />
      </Col>
    </Row>
  );
};

export default BlacklistItem;
