import * as React from 'react';
import * as Translations from 'i18next';
import { Loader } from 'elements';
import { withTranslation } from 'react-i18next';
import { AccessLevel } from 'global-shapes';
import { remCalc, findShortNamesInUrl } from 'utils';
import GroupItems from './GroupItems';
import { FILTER_OPTIONS_SORTED } from '../constants';

type IProps = Translations.WithT & {
  isLoading: boolean;
  searchStr: string;
  accessLevel: AccessLevel;
  entities: ISearchEntity.IAggregation;
};

const Renderer = (props: IProps) => {
  const { t, isLoading, entities, accessLevel, searchStr } = props;

  const handleEntityClick = React.useCallback((link: string) => {
    const { psn, tsn } = findShortNamesInUrl({
      pathname: link.split('?')[0],
    });

    if (!psn) sessionStorage.removeItem('psn');
    if (!tsn) sessionStorage.removeItem('tsn');

    window.location.href = window.location.origin + link;
  }, []);

  if (isLoading) return <Loader />;

  if (!Object.keys(entities).length) {
    return (
      <div className="steel">
        {t('entitySearch.content.noResult') as string}
      </div>
    );
  }

  return (
    <div>
      <div
        style={{ height: `calc(100vh - ${remCalc(220)})`, overflowY: 'auto' }}
      >
        {FILTER_OPTIONS_SORTED[accessLevel].map((entName) => {
          const aggregation = entities[entName];

          if (!aggregation) return null;

          return (
            <GroupItems
              entName={entName}
              key={entName}
              searchStr={searchStr}
              entities={aggregation.data}
              totalCount={aggregation.pagination?.totalCount || 0}
              onClick={handleEntityClick}
            />
          );
        })}
      </div>
    </div>
  );
};

const SearchResults = withTranslation()(Renderer);

export default SearchResults;
