import { useEffect, useRef } from 'react';
import { AnyFunc } from 'global-shapes';

export const useDidUpdate = (
  effect: AnyFunc = () => undefined,
  observe: unknown[] = []
) => {
  const hasMounted = useRef(false);
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    effect();
  }, observe);
};
