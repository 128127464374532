import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CustomEvents } from 'enums';
import { Chip, Menu, MenuItem, Tooltip } from 'elements';
import { useUserHash, useAuth, useTask, useState } from 'hooks';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { ICustomer } from 'customer-shapes';
import { openUserInPortal, confirm, Emitter, dayjs } from 'utils';
import { BurgerIcon } from './Styled';

type IProps = ITenantsTypes.ITableActionsProps & {
  tenant: ICustomer;
};

const deleteTimers: AnyShape = {};

function defineTimesLeft(deletedAt: string): number {
  const now = dayjs();
  const startTime = dayjs(deletedAt);
  const endTimeTime = startTime.add(20, 'minutes');
  return endTimeTime.diff(now, 'minutes');
}

const initState = (
  tenant: ICustomer
): ITenantsTypes.IInitTableActionsState => ({
  extendDialogOpen: false,
  timeCounter: defineTimesLeft(tenant.deletedAt),
});

const TableAction = (props: IProps) => {
  const {
    switchUser,
    goToTenantPricing,
    pricingPermissions,
    onDelete,
    onManageBilling,
    hasInvoiceSettings,
    accountStatus,
    handleAccountEnable,
    onUndoDeletion,
    tenant,
    permissions,
    onManageQuotas,
  } = props;
  const [state, handleState] = useState<ITenantsTypes.IInitTableActionsState>(
    initState(tenant)
  );
  const { setChildSessionInfo } = useAuth();
  const { t } = useTranslation();
  const [, changeUrl] = useUserHash();

  const task = useTask(tenant.task);
  const isDeleting = task.isDeleting || !!tenant.deletedAt;

  const intervalCallback = React.useCallback(() => {
    if (state.timeCounter < 0) {
      clearInterval(deleteTimers[tenant.id]);
      deleteTimers[tenant.id] = undefined;
      return;
    }

    handleState(defineTimesLeft(tenant.deletedAt), 'timeCounter');
  }, [tenant.deletedAt]);

  React.useEffect(() => {
    if (tenant.deletedAt) {
      deleteTimers[tenant.id] = setInterval(intervalCallback, 5000);
    } else {
      clearInterval(deleteTimers[tenant.id]);
    }

    return () => {
      clearInterval(deleteTimers[tenant.id]);
    };
  }, [tenant.deletedAt]);

  function buildMnuItems(popupState: any) {
    const menuItems: any[] = [];

    if (pricingPermissions.canView) {
      if (switchUser) {
        menuItems.push(
          <MenuItem
            key="openDetails"
            onClick={openUserInPortal(() => {
              setChildSessionInfo({ tsn: tenant.shortName }, true);
              switchUser(tenant);
            })}
          >
            {t('tenants.table.openDetails')}
          </MenuItem>
        );
      }
      menuItems.push(
        <MenuItem
          key="goToPricing"
          onClick={() => goToTenantPricing(tenant.id)}
        >
          {t('tenants.table.goToPricing')}
        </MenuItem>
      );
    }

    if (accountStatus.prod && onManageBilling) {
      menuItems.push(
        <MenuItem
          key="manageBilling"
          onClick={() => {
            if (!hasInvoiceSettings) {
              return confirm({
                title: t(
                  'tenants.dialog.manageBilling.notify.enterInvoiceSettings.title'
                ),
                content: t(
                  'tenants.dialog.manageBilling.notify.enterInvoiceSettings.content'
                ),
                onSuccess: () => changeUrl('/settings/customer-invoice', {}),
                successLabel:
                  'tenants.dialog.manageBilling.notify.enterInvoiceSettings.buttons.openSettings',
                successButtonProps: {
                  color: 'primary',
                  variant: 'contained',
                },
              });
            }

            return onManageBilling(tenant.id);
          }}
        >
          {t('tenants.table.manageBilling')}
        </MenuItem>
      );
    }

    menuItems.push(
      <MenuItem
        onClick={() => {
          onManageQuotas(tenant.id);
          popupState.close();
        }}
        key="manageQuotes"
      >
        {t('tenants.table.manageQuotes')}
      </MenuItem>
    );

    menuItems.push(
      <MenuItem
        onClick={() => Emitter.emit(CustomEvents.assignDialog, tenant)}
        key="assignBadges"
      >
        {t('badges.menu.assignBadges')}
      </MenuItem>
    );

    menuItems.push(
      <MenuItem key="enableTenant" onClick={() => handleAccountEnable(tenant)}>
        {t(`tenants.table.${tenant.isDisabled ? 'enable' : 'disable'}`)}
      </MenuItem>
    );

    if (permissions.canManage) {
      if (isDeleting) {
        menuItems.push(
          <MenuItem
            key="undoDeleteTenant"
            onClick={() => onUndoDeletion(tenant)}
          >
            {t('tenants.table.undoDeletion')}
          </MenuItem>
        );
      } else {
        menuItems.push(
          <MenuItem key="deleteTenant" onClick={() => onDelete(tenant)}>
            {t('tenants.table.deleteTenant')}
          </MenuItem>
        );
      }
    }

    return menuItems;
  }

  const showOnlyDeleteStatus = isDeleting;

  return (
    <div className="flex full-width justify-end align-center">
      {task.isFailed && (
        <Tooltip title={task.error && task.error.message} placement="top" arrow>
          <div>
            <Chip status="error" className="mr-5 pointer">
              {t('common.error')}
            </Chip>
          </div>
        </Tooltip>
      )}
      {!showOnlyDeleteStatus && tenant.isDisabled && (
        <Chip status="default" className="mr-5">
          {t('tenants.table.disabledAccount')}
        </Chip>
      )}
      {!showOnlyDeleteStatus &&
        accountStatus.prod &&
        !tenant.billingEmailsEnabled && (
          <Chip status="systemInfo" className="mr-5">
            {t('tenants.table.account.billingDisabled')}
          </Chip>
        )}

      {showOnlyDeleteStatus && (
        <Chip status="error" className="ml-5">
          {t('partners.table.account.delete.timeLeft', {
            time: state.timeCounter > 0 ? state.timeCounter : 0,
          })}
        </Chip>
      )}

      <PopupState variant="popover" popupId={`tenant-actions-${tenant.id}`}>
        {(popupState) => (
          <div className="ml-10 flex align-center">
            <BurgerIcon
              className={cn('pointer fs-20 steel mr-15')}
              {...bindTrigger(popupState)}
            />
            <Menu {...bindMenu(popupState)}>{buildMnuItems(popupState)}</Menu>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default TableAction;
