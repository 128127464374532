import { showSystemMessage } from 'utils';
import * as R from 'ramda';
import { monitoringService } from 'services';
import * as Types from 'monitoring';
import { BaseMobxInstanceDecorator } from './utilities';

const PREFERED_CHARTS_ORDER = [
  'cpu_util',
  'ram_util',
  'network_if_in_bits',
  'network_if_out_bits',
  'filesystem_space_util',
];

function sortCharts(charts: Types.IChartInstance[]) {
  return R.sort<Types.IChartInstance>((a, b) => {
    if (
      PREFERED_CHARTS_ORDER.indexOf(a.metricKey) >
      PREFERED_CHARTS_ORDER.indexOf(b.metricKey)
    ) {
      return 1;
    } else {
      return -1;
    }
  })(charts);
}

export function filterCharts(charts: Types.IChartInstance[]) {
  const filtered = R.filter((el) => {
    return PREFERED_CHARTS_ORDER.includes(el.metricKey);
  }, charts);

  return sortCharts(filtered);
}

export const monitoring = new BaseMobxInstanceDecorator<
  Types.IMonitoringService,
  false
>({
  instanceName: 'monitoring',
  requests: {
    get: async (query) => monitoringService.getById(query.monitoringId),
    create: async (payload) => {
      const res = await monitoringService.create(payload);
      showSystemMessage('monitoring.notify.create.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const res = await monitoringService.update(id as number, payload);
      showSystemMessage('monitoring.notify.update.success', 'success');
      return res;
    },
    remove: async (id) => {
      const res = await monitoringService.remove(id as number);
      showSystemMessage('monitoring.notify.remove.success', 'success');
      return res;
    },
  },
  additionalRequests: {
    syncWithZabbix: (monitoringId: number) =>
      monitoringService.syncWithZabbix(monitoringId),
  },
  // @ts-ignore
  initialState: null,
});

export const monitoringList = new BaseMobxInstanceDecorator<
  Types.IMonitoringService,
  true
>({
  instanceName: 'monitoringList',
  requests: {
    get: monitoringService.get,
    create: async (payload) => {
      const res = await monitoringService.create(payload);
      showSystemMessage('monitoring.notify.create.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const res = await monitoringService.update(id as number, payload);
      showSystemMessage('monitoring.notify.update.success', 'success');
      return res;
    },
    remove: async (id) => {
      const res = await monitoringService.remove(id as number);
      showSystemMessage('monitoring.notify.remove.success', 'success');
      return res;
    },
  },
  initialState: [],
});

export const monitoringCharts = new BaseMobxInstanceDecorator<
  Types.IChartInstance,
  true
>({
  instanceName: 'monitoringCharts',
  requests: {
    get: async (query) => {
      const res = await monitoringService.getServiceItems(
        query.monitoringId,
        query.instanceId
      );

      res.data = filterCharts(res.data);
      return res;
    },
  },
  initialState: [],
});

export const monitoringOsServices = new BaseMobxInstanceDecorator<
  Types.IOsServiceItemGrouped,
  true
>({
  instanceName: 'monitoringServices',
  requests: {
    get: async (query) => {
      const res = await monitoringService.getServiceNamesGrouped(
        query.monitoringId,
        query.instanceId
      );

      return { data: res };
    },
  },
  additionalRequests: {
    addServiceName: monitoringService.addServiceName,
    removeServiceName: monitoringService.removeServiceName,
  },
  initialState: [],
});

export const monitoringMaintenance = new BaseMobxInstanceDecorator<
  IMonitoring.MonitoringMW,
  true
>({
  instanceName: 'monitoring-maintenance',
  requests: {
    get: (params: any) => monitoringService.getMaintenance(params.id),
    create: async (data) => {
      const { monitoringId, ...payload } = data;
      const res = await monitoringService.createMaintenance(
        monitoringId,
        payload
      );
      showSystemMessage(
        'monitoring.maintenance.notify.create.success',
        'success'
      );
      return res;
    },
    update: async (instace, payload) => {
      const { monitoringId, id } = instace;
      const res = await monitoringService.updateMaintenance(
        monitoringId,
        id,
        payload
      );
      showSystemMessage(
        'monitoring.maintenance.notify.update.success',
        'success'
      );
      return res;
    },
    remove: async (instace: any) => {
      const { monitoringId, id } = instace;
      const res = await monitoringService.removeMaintenance(monitoringId, id);
      showSystemMessage(
        'monitoring.maintenance.notify.remove.success',
        'success'
      );
      return res;
    },
  },
  initialState: [],
});
