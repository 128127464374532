import React, { useMemo } from 'react';
import { Dialog, Button, DialogProps } from 'elements';
import { useTranslation } from 'react-i18next';
import { copyMECommand } from './helpers';
import { IFileShare } from 'file-shares';

type Props = DialogProps & { currentFileShare?: IFileShare };

const EndpointCreatedInfoDialog = ({ currentFileShare, ...props }: Props) => {
  const { t } = useTranslation();
  const commandLine = useMemo(() => {
    if (currentFileShare) {
      return `# Execute in elevated PowerShell prompt\n\n# Authenticate to remote file share\nnet use \\\\${currentFileShare.migrationEndpoint?.fileSharePublicIp}\\Shares$\\${currentFileShare.name}\n\n# Copy local source path contents to remote share\n robocopy ${currentFileShare.migrationEndpoint?.localSourcePath} \\\\${currentFileShare.migrationEndpoint?.fileSharePublicIp}\\Shares$\\${currentFileShare.name} /E /PURGE /ZB /R:3 /W:3 /MT:16 /XJ /DCOPY:T /V /NP /XD '$RECYCLE.BIN' 'System Volume Information' /LOG:$env:TEMP\\${currentFileShare.name}-$(get-date -f yyyy-MM-dd-hh-mm).log`;
    }
    return '';
  }, [currentFileShare]);

  return (
    <Dialog
      {...props}
      title={t(
        'services.core.file-shares.dialog.migrationEndpointCreated.title'
      )}
      maxWidth="md"
      actions={
        <div className="flex justify-between full-width">
          <Button
            variant="text"
            color="primary"
            onClick={() => copyMECommand(currentFileShare)}
          >
            {t('common.copyCommand')}
          </Button>
          <Button
            onClick={(ev: React.SyntheticEvent) =>
              props.onClose && props.onClose(ev, 'backdropClick')
            }
          >
            {t('common.ok')}
          </Button>
        </div>
      }
    >
      <div className="mb-15">
        {t(
          'services.core.file-shares.dialog.migrationEndpointCreated.subtitle',
          { fileShareName: currentFileShare && currentFileShare.name }
        )}
      </div>
      <code className="code">
        {commandLine.split('\n\n').map((s) => (
          <div className="mb-10" key={s}>
            {s.split('\n').map((ss) => (
              <div key={ss}>{ss}</div>
            ))}
          </div>
        ))}
      </code>
    </Dialog>
  );
};

export default EndpointCreatedInfoDialog;
