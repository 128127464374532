import * as React from 'react';
import { DialogProps, Dialog, Loader, Button } from 'elements';
import { useTranslation } from 'react-i18next';
import { remCalc } from 'utils';
import { IExcludeCallback, IImportedPayment, IStateIncluded } from 'invoices';
import ImportedPaymentRow, { HeadRow } from './ImportedPaymentRow';

type Props = DialogProps<any> & {
  isLoading: boolean;
  isImporting: boolean;
  included: IStateIncluded;
  data: IImportedPayment[];
  onInclude: IExcludeCallback;
};

const PAPER_PROPS = {
  style: { minWidth: remCalc(1300) },
};

const ImportPaymentsDialog = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const {
    isLoading,
    data,
    onSave,
    onInclude,
    included,
    open,
    onClose,
    isImporting,
  } = props;

  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={onClose}
      showCloseIcon
      PaperProps={PAPER_PROPS}
      title={t('invoices.importPayments.dialog.title')}
      fullScreen
      actions={
        <>
          <Button variant="outlined" color="default" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button onClick={onSave} disabled={isImporting}>
            {t('invoices.importPayments.dialog.buttons.import')}
          </Button>
        </>
      }
    >
      {isLoading ? (
        <div className="flex fs-14 align-center">
          <div className="pr-20">{t('common.processing')}:</div>
          <div>
            <Loader size={25} />
          </div>
        </div>
      ) : (
        <>
          <HeadRow />
          {data.map((p) => (
            <ImportedPaymentRow
              key={p.id}
              {...p}
              onSelect={onInclude}
              included={included}
            />
          ))}
        </>
      )}
      {}
    </Dialog>
  );
};

export default ImportPaymentsDialog;
