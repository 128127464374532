import * as React from 'react';
import { noop } from 'utils';
import { withPermissions, InjectedPermissionsProps } from 'hocs';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import BasePricingView from './BasePricingViewWrapper';
import { groupPrices } from './helper';
import { AnyShape } from 'global-shapes';
import { useTranslation } from 'react-i18next';

const OBSERVERS = {
  mainPrices: StateHandlers.mainPricing,
  defaultTenantPrices: StateHandlers.defaultTenantPricing,
  ownPrices: StateHandlers.tenantPricing,
  currentTenantPricing: StateHandlers.currentTenantPricing,
};

type IProps = InjectedPermissionsProps;

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    permissions,
    mainPrices,
    defaultTenantPrices,
    ownPrices,
    currentTenantPricing,
  } = props;
  const { t } = useTranslation();

  const dataReceived =
    mainPrices.dataReceived && defaultTenantPrices.dataReceived;

  const disabled =
    mainPrices.isRequesting ||
    defaultTenantPrices.isRequesting ||
    ownPrices.isRequesting ||
    currentTenantPricing.isRequesting;

  const onUpdatePricing = React.useCallback((data: AnyShape) => {
    ownPrices.update(data.id, data).then(() => {
      defaultTenantPrices.get();
      return true;
    });
  }, []);

  React.useEffect(() => {
    mainPrices.get();
    defaultTenantPrices.get();
  }, []);

  const createdPrices = defaultTenantPrices.data;
  const defaultPrices = groupPrices(defaultTenantPrices.data);

  return (
    <BasePricingView
      data={createdPrices}
      subtitle={t('settings.page.pricing.defaultPartnerPricing.subtitle')}
      defaultPricing={defaultPrices}
      parentPricing={mainPrices.data}
      dataReceived={dataReceived}
      isEditable={permissions.canManage}
      isOnUserPricing={false}
      disabled={disabled}
      onUpdatePricing={onUpdatePricing}
      onSetPrice={noop}
      showDedicatedServerRate
    />
  );
});

const PartnerOwnPricing = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default withPermissions(PartnerOwnPricing, 'PRICING');
