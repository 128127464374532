import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'elements';

type IProps = {
  spec: IVmVcdTypes.VmSpec;
};

const AdditionalDisks = (props: IProps) => {
  const {
    spec: { osDisk, extraDisks },
  } = props;
  const { t } = useTranslation();
  return (
    <div>
      <div className="mb-20">
        <strong>{t('services.vmList.dialog.importVm.disks.title')}</strong>
      </div>

      <Row className="steel fs-12 mb-10">
        <Col xs={9}>{t('services.vmList.dialog.importVm.disks.head.name')}</Col>
        <Col xs={3}>{t('services.vmList.dialog.importVm.disks.head.size')}</Col>
      </Row>
      <Row className="mb-5">
        <Col xs={9}>Disk 1</Col>
        <Col xs={3}>{osDisk.sizeGb} GB</Col>
      </Row>
      {extraDisks?.map((d, ind) => {
        return (
          <Row key={ind} className="mb-5">
            <Col xs={9}>Disk {ind + 2}</Col>
            <Col xs={3}>{d.sizeGb} GB</Col>
          </Row>
        );
      })}
    </div>
  );
};

export default AdditionalDisks;
