import React from 'react';
import { Row as RowMui, styled, GridProps } from 'elements';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks';
import { NavLink } from 'react-router-dom';
import { stringify } from 'query-string';
import { ALL_TEST_IDS } from 'enums';
import { AnyFunc, AnyShape, NavItem } from 'global-shapes';
import { remCalc, important } from 'utils';

interface NavItemProps extends NavItem {
  classes: AnyShape;
  hidden: boolean;
  handleMenu: AnyFunc;
}

const StyledIcon = styled('span')(({ theme }) => {
  const mobileUp = theme.breakpoints.up(1300);

  return {
    display: 'inline-flex',
    '& svg': {
      marginRight: remCalc(10),
      lineHeight: 1,
      fontSize: remCalc(19),
      [mobileUp]: {
        fontSize: remCalc(24),
      },
    },
  };
});

const Row = styled((props: GridProps & { isActive?: boolean }) => {
  const { isActive, ...rest } = props;
  return <RowMui {...rest} />;
})(({ theme, isActive }) => {
  const mobileUp = theme.breakpoints.up(1300);

  return {
    padding: remCalc(15, 15, 20),
    borderRadius: 3,
    position: 'relative',
    width: important('auto'),
    fontWeight: 600,
    color: theme.palette.secondary.contrastText,
    fontSize: remCalc(14),
    textDecoration: 'none',
    '& a': {
      color: theme.palette.appBar.main,
      opacity: isActive ? 1 : 0.7,
      [theme.breakpoints.up('md')]: {
        color: theme.palette.appBar.contrastText,
      },
      '&:hover': {
        opacity: 1,
        [theme.breakpoints.up('md')]: {
          color: theme.palette.appBar.contrastText,
        },
      },
    },

    [mobileUp]: {
      fontSize: remCalc(17),
      borderRadius: 3,
      position: 'relative',
      width: 'auto',
      fontWeight: 600,
      color: theme.palette.secondary.contrastText,
    },
    [theme.breakpoints.up('md')]: {
      padding: remCalc(0, 6),
    },
    [theme.breakpoints.up('lg')]: {
      padding: remCalc(0, 15),
    },
    '&:after': {
      content: "''",
      height: remCalc(5),
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      zIndex: 1,
      backgroundColor: isActive ? theme.palette.primary.main : 'transparent',
    },
  };
});

const NavigationItem = (props: NavItemProps) => {
  const {
    label,
    to,
    icon: MuiIcon,
    namespace,
    hidden,
    defaultQuery,
    handleMenu,
    guideStepId,
  } = props;
  const { t } = useTranslation();
  const { location } = useQuery();

  if (hidden) return null;

  const baseUrl = to;
  const Icon = MuiIcon ? (
    <StyledIcon>
      <MuiIcon />
    </StyledIcon>
  ) : null;
  return (
    <Row
      key={baseUrl}
      alignItems="center"
      isActive={location.pathname.includes(namespace)}
      columnSpacing={0}
      id={guideStepId}
    >
      <NavLink
        to={
          baseUrl +
          (defaultQuery
            ? `?${stringify(defaultQuery, { arrayFormat: 'bracket' })}`
            : '')
        }
        onClick={() => handleMenu(false, 'isMenuOpen')}
        data-test-id={`${ALL_TEST_IDS.header.navItem}${to
          .split('/')
          .join('-')}`}
      >
        <RowMui alignItems="center">
          {Icon}
          {t(label)}
        </RowMui>
      </NavLink>
    </Row>
  );
};

export default NavigationItem;
