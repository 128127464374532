import * as React from 'react';
import { confirm, Dayjs, showSystemMessage } from 'utils';
import { useTranslation } from 'react-i18next';
import { ISnapshotEntityType } from 'snapshots';
import * as StateHandlers from 'states';
import { useStateHandler } from 'hooks/instanceHook';

export type ISnapshotAction =
  | 'create'
  | 'revert'
  | 'remove'
  | 'cancelAutoremoval';
export type ICreateSnapshotPayload = {
  description?: string;
  scheduleSnapshot: boolean;
  scheduleAt: Dayjs;
  removalOn: Dayjs;
};
export type IConfig = {
  serviceEntityId: number;
  appEntityId?: number;
  serviceEntityName: ISnapshotEntityType;
};

export function useSnapshotRequest(
  config: IConfig,
  currentSnapshotId?: number
) {
  const { serviceEntityName, serviceEntityId, appEntityId } = config;
  const { t } = useTranslation();
  const snapshots = useStateHandler(StateHandlers.snapshots);
  const scheduledTasks = useStateHandler(StateHandlers.scheduledTasks);

  const confirmSnapshotRequest = React.useCallback(
    (action: ISnapshotAction, payload?: ICreateSnapshotPayload) => {
      return new Promise((res, rej) => {
        let result: any;
        // for create action we have another flow with more complicated dialog
        if (action === 'create') {
          if (payload?.scheduleSnapshot) {
            const parsed: IScheduledTasks.CreatePayload = {
              // @ts-ignore
              schedule: {
                startAt: payload?.scheduleAt as any,
                recurringInterval: 'WEEK',
              },
              payload: {
                ServiceSnapshotCreate: {
                  removalOn: payload?.removalOn,
                  description: payload?.description || '',
                },
              },
              appEntityId: appEntityId as number,
              taskOperationName: 'ServiceSnapshotCreate',
            };

            return scheduledTasks
              .create(parsed)
              .then((response) => {
                result = response;
                showSystemMessage(
                  `snapshots.scheduled.create.success`,
                  'success'
                );
                snapshots.get();
                scheduledTasks.get();
                res(result);
              })
              .catch(rej);
          }
          return snapshots
            .create({
              serviceEntityId,
              serviceEntityName,
              description: payload?.description,
              removalOn: payload?.removalOn,
            })
            .then((response) => {
              result = response;
              showSystemMessage(
                `snapshots.notify.${action}.success`,
                'success'
              );
              snapshots.get();
              res(result);
            })
            .catch(rej);
        }

        return confirm({
          title: t(`snapshots.confirm.${serviceEntityName}.${action}.title`),
          content: t(
            `snapshots.confirm.${serviceEntityName}.${action}.content`
          ),
          onSuccess: async () => {
            try {
              if (action === 'revert') {
                // @ts-ignore
                result = await snapshots.update(currentSnapshotId);
              }
              if (action === 'remove') {
                result = await snapshots.remove(currentSnapshotId);
              }
              if (action === 'cancelAutoremoval') {
                result = await snapshots.executeRequest('cancelAutoremoval')(
                  currentSnapshotId
                );
              }
              showSystemMessage(
                `snapshots.notify.${action}.success`,
                'success'
              );
              snapshots.get();
              return res(result);
            } catch (er) {
              rej(er);
            }
          },
          onCancel: () => res(undefined),
        });
      });
    },
    [serviceEntityId, serviceEntityName, currentSnapshotId, appEntityId]
  );

  return {
    isRequesting: snapshots.isRequesting,
    execute: confirmSnapshotRequest,
  };
}
