import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import qs from 'query-string';
import ManageBillingDialog from 'components/ManageBillingDialog';
import ConfirmDeleteTextDialog from 'components/ConfirmDeleteTextDialog';
import PartnerDetailsDialog from './PartnerDetailsDialog';
import PartnerQuotasDialog from './QuotasDialog';
import ExtendEvaluationAccountDialog from './ExtendEvaluationAccountDialog';
import { DEFAULT_PARAMS, getColumns } from './constants';
import { defineExpirationDays, parseQuery } from './helpers';
import { Button, Loader, Row, Col, Table } from 'elements';
import { ALL_TEST_IDS, DEFAULT_CUSTOMERS_QUERY } from 'enums';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import {
  useAsync,
  usePermissions,
  useQuery,
  useUserHash,
  useState,
} from 'hooks';
import { partnersService } from 'services';
import { confirm, noop, showSystemMessage, openInNewTab } from 'utils';
import { IBillingFormPayload } from 'customer-shapes';
import * as StateHandlers from 'states';
import Filters from './Filters';
import { ILocalTypes } from './types';

interface IState {
  partner: IPartnerTypes.Partner | null;
  evalPartner: IPartnerTypes.Partner | undefined;
  isOpen: boolean;
  manageBillingDialog: boolean;
  deletePartnerDialog: boolean;
  partnerQuotasId: number | null;
}

const init = (): IState => {
  return {
    partner: null,
    evalPartner: undefined,
    isOpen: false,
    manageBillingDialog: false,
    deletePartnerDialog: false,
    partnerQuotasId: null,
  };
};

const OBSERVERS = {
  partners: StateHandlers.partners,
  currentPartner: StateHandlers.currentPartner,
  currentUser: StateHandlers.currentUser,
  badgesAssignment: StateHandlers.badgesAssignment,
};

type IViewProps = typeof OBSERVERS;

const PartnersView = observer((props: IViewProps) => {
  const { partners, currentPartner, badgesAssignment } = props;
  const { query, queryStr, changeQuery } = useQuery<ILocalTypes.IQuery>();
  const [, changeUrl] = useUserHash();
  const { t } = useTranslation();
  const [state, handleStateChange] = useState<IState>(init());
  const { permissions } = usePermissions('PARTNERS');
  const pricingPermissions = usePermissions('PRICING');
  const isNew = useMemo(() => state.partner === null, [state.partner]);
  const q = useMemo(() => ({ ...DEFAULT_PARAMS, ...query }), [queryStr]);

  const { execute: expandEvaluationRequest, isPending: isExpanding } = useAsync(
    partnersService.expandEvaluation
  );

  const fetchPartners = useCallback(
    () => partners.get(parseQuery(query)),
    [queryStr, query, permissions.canView]
  );

  const onPartnersTaskUpdate = useCallback(fetchPartners, [queryStr]);

  React.useEffect(() => {
    fetchPartners();
  }, [queryStr, permissions.canView]);

  const expandEvaluation = React.useCallback(
    (payload: { date: Dayjs }) => {
      expandEvaluationRequest(state.evalPartner?.id, {
        days: defineExpirationDays(payload.date),
      }).then((res) => {
        handleStateChange({ evalPartner: undefined });
        fetchPartners();
        return res;
      });
    },
    [state.evalPartner]
  );

  const switchUserSession = useCallback(
    async (partner: IPartnerTypes.Partner) => {
      changeUrl(`/${partner.shortName}/tenants`, DEFAULT_CUSTOMERS_QUERY);
      return StateHandlers.fetchAllAccountData({
        psn: partner.shortName,
        shouldApplyTheme: true,
      });
    },
    [queryStr]
  );

  const openUserInNewTab = useCallback((partner: IPartnerTypes.Partner) => {
    openInNewTab(
      `/${partner.shortName}/tenants?${qs.stringify({
        psn: partner.shortName,
        ...DEFAULT_CUSTOMERS_QUERY,
      })}`
    );
  }, []);

  const onExpandExpiration = useCallback(
    (partner: IPartnerTypes.Partner) => {
      handleStateChange({ evalPartner: partner });
    },
    [queryStr]
  );

  const onSwitchToEvaluation = useCallback(
    (partner: IPartnerTypes.Partner) =>
      confirm({
        title: t('partners.table.confirm.switchToEvaluation.title', {
          partnerName: partner.shortName,
        }),
        content: t('partners.table.confirm.switchToEvaluation.content'),
        onSuccess: () =>
          partnersService
            .switchToEvaluation(partner.id)
            .then(() => {
              showSystemMessage(
                'partners.notify.switchToEvaluation.success',
                'success'
              );
              return fetchPartners();
            })
            .catch((err) => showSystemMessage(err.message, 'error')),
        onCancel: noop,
      }),
    [queryStr]
  );

  const onSwitchToProd = useCallback(
    (partner: IPartnerTypes.Partner) =>
      confirm({
        title: t('partners.table.confirm.switchToProd.title', {
          partnerName: partner.shortName,
        }),
        content: t('partners.table.confirm.switchToProd.content'),
        onSuccess: () =>
          partnersService
            .completeEvaluation(partner.id)
            .then(fetchPartners)
            .catch((err) => showSystemMessage(err.message, 'error')),
        onCancel: noop,
      }),
    [queryStr]
  );

  const handleModalState = useCallback(
    (isOpen: boolean, partner: any) => handleStateChange({ partner, isOpen }),
    []
  );

  const handleModalClose = useCallback(() => handleModalState(false, null), []);
  const handleQuotasDialog = React.useCallback(
    (id: number | null) => handleStateChange({ partnerQuotasId: id }),
    []
  );

  const onSubmit = useCallback(
    (values: AnyShape) =>
      partners.create(values).then(() => {
        handleModalState(false, null);
        fetchPartners();
      }),
    [queryStr]
  );

  const updateTenantBillingEmails = React.useCallback(
    (payload: IBillingFormPayload) =>
      currentPartner.update(currentPartner.data.id, payload).then(() => {
        handleManageDialog(false);
        showSystemMessage(
          'partners.dialog.manageBilling.notify.update.success',
          'success'
        );
      }),
    [currentPartner.data]
  );

  const handleManageDialog = useCallback((manageBillingDialog: boolean) => {
    handleStateChange({ manageBillingDialog });
  }, []);

  const onSort = useCallback(
    (orderBy: ILocalTypes.IOrderBy, orderType: OrderTypes) => {
      changeQuery({
        orderType,
        orderBy,
      });
    },
    [queryStr]
  );

  const gotToPricing = useCallback(
    (id: number) => changeQuery({ partner: id }, '/partner-pricing', true),
    []
  );

  const onConfirmDelete = useCallback(
    (partner: IPartnerTypes.Partner) =>
      handleStateChange({ deletePartnerDialog: true, partner }),
    [queryStr]
  );

  const onConfirmUndoDelete = useCallback(
    (partner: IPartnerTypes.Partner) =>
      confirm({
        title: t('partners.table.confirm.undoDelete.title'),
        content: t('partners.table.confirm.undoDelete.content', {
          name: partner.name,
        }),
        successLabel: 'partners.table.confirm.undoDelete.buttons.undo',
        onSuccess: () =>
          partnersService
            .undoDeletion(partner.id)
            .then(fetchPartners)
            .catch((err) => showSystemMessage(err.message, 'error')),
        onCancel: noop,
      }),
    [queryStr]
  );

  const onDeletePartner = useCallback(
    (id: number) =>
      partners.remove(id).then(() => {
        handleStateChange({ deletePartnerDialog: false, partner: null });
        fetchPartners();
      }),
    [queryStr]
  );

  const fetchCurrentPartner = React.useCallback((id: number) => {
    currentPartner
      .get({ id, include: ['billingEmails', 'address'] })
      .then(() => handleManageDialog(true));
  }, []);

  const onModalClose = useCallback(
    () =>
      handleStateChange({
        manageBillingDialog: false,
      }),
    []
  );

  const columns = useMemo(
    () =>
      getColumns({
        t,
        switchUser: switchUserSession,
        openUserInNewTab: openUserInNewTab,
        onExpandExpiration,
        onSwitchToEvaluation,
        onSwitchToProd,
        ableToAction: permissions.canManage,
        pricingPermissions: pricingPermissions.permissions,
        assignments: badgesAssignment.data,
        goToPartnerPricing: gotToPricing,
        onDelete: onConfirmDelete,
        onUndoDeletion: onConfirmUndoDelete,
        onManageQuotas: handleQuotasDialog,
        onManageBilling: permissions.canManage
          ? fetchCurrentPartner
          : undefined,
      }),
    [
      t,
      queryStr,
      permissions.canManage,
      JSON.stringify(pricingPermissions.permissions),
      JSON.stringify(badgesAssignment.data),
    ]
  );

  return (
    <>
      <Row
        justifyContent="space-between"
        alignItems="center"
        className="full-width mb-20"
      >
        <Col>
          <h3>{t('partners.title')}</h3>
        </Col>
        <Col>
          {permissions.canManage && (
            <Button
              className="ml-15"
              onClick={() => handleModalState(true, null)}
              testId={ALL_TEST_IDS.partners.createPartner}
            >
              {t('partners.title.create')}
            </Button>
          )}
        </Col>
      </Row>
      <Filters query={query} onChange={changeQuery} />
      {!partners.dataReceived ? (
        <Loader />
      ) : (
        <Table
          className={cn({ disabled: partners.isRequesting })}
          testId={ALL_TEST_IDS.partners.table}
          query={q}
          columns={columns}
          hasSorting
          data={partners.data}
          onSort={onSort}
          noDataMessage="partners.empty.table"
          params={partners.meta}
          onPageChange={changeQuery}
          onUpdateTable={onPartnersTaskUpdate}
        />
      )}
      <PartnerDetailsDialog
        open={state.isOpen}
        isNew={isNew}
        onSubmit={onSubmit}
        initialValues={state.partner as any}
        onClose={handleModalClose}
        isRequesting={partners.isRequesting}
      />
      <PartnerQuotasDialog
        open={!!state.partnerQuotasId}
        partnerId={state.partnerQuotasId as number}
        onClose={() => handleQuotasDialog(null)}
        onSave={() => undefined}
      />
      <ExtendEvaluationAccountDialog
        open={!!state.evalPartner}
        isSaving={isExpanding}
        partner={state.evalPartner}
        onClose={() => handleStateChange({ evalPartner: undefined })}
        onSave={expandEvaluation}
      />
      {/* @ts-ignore */}
      <ManageBillingDialog
        open={state.manageBillingDialog}
        source="partner"
        translationNamespace="partners"
        isSaving={currentPartner.isRequesting}
        onSave={updateTenantBillingEmails}
        onClose={onModalClose}
      />
      <ConfirmDeleteTextDialog
        open={state.deletePartnerDialog}
        title={t('partners.table.confirm.delete.title')}
        subtitle={t('partners.table.confirm.delete.content', {
          name: state.partner?.name,
          count: state.partner?.tenantsQuantity,
        })}
        isPending={partners.isRequesting}
        onSave={() => onDeletePartner(state.partner?.id as number)}
        onClose={() =>
          handleStateChange({ deletePartnerDialog: false, partner: null })
        }
      />
    </>
  );
});

const PartnersMain = () => <PartnersView {...OBSERVERS} />;

export default PartnersMain;
