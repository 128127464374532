import { PATCH, GET, PUT, buildListParams } from 'utils';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';

export const getBillingCycles = (
  params: IBillingCircleTypes.IBCGetParams
): IAxiosPromise<IVmTypes.IBillingCircle[]> =>
  GET(
    `/billing-cycles`,
    buildListParams(params, [
      'rderBy',
      'orderType',
      'status',
      'interval',
      'entityName',
    ])
  );

export const getBillingCycle = (
  id: number
): AxiosPromise<IVmTypes.IBillingCircle> => GET(`/billing-cycles/${id}`);

export const editBillingInfo = (
  id: number,
  payload: { interval: IVmTypes.IBillingCircleInterval }
): AxiosPromise<IVmTypes.IBillingCircle> => {
  return PUT(`/billing-cycles/${id}`, {}, payload);
};

export const terminateCycle = (
  id: number
): AxiosPromise<IVmTypes.IBillingCircle> =>
  PATCH(`/billing-cycles/${id}/action/cancel`);

export const enableCycle = (
  id: number
): AxiosPromise<IVmTypes.IBillingCircle> =>
  PATCH(`/billing-cycles/${id}/action/enable`);
