import * as React from 'react';
import { ALL_TEST_IDS } from 'enums';
import { DragAndDropTypes } from 'enums/DnD';
import { MuiIcons } from 'elements';
import { getBadgeStateKey } from 'utils';
import { withFolderActions, InjectedProps } from 'hocs/withFolderActions';
import { observer } from 'mobx-react-lite';
import DropFolderComponent from 'components/DragAndDrop/DropFolderComponent';
import * as StateHandlers from 'states';
import FolderContent from '../contents/Folder';
import { SFolderCard } from './Styled';
import { FolderIconWrapper } from '../Styled';

const OBSERVERS = {
  folders: StateHandlers.folders,
  assignments: StateHandlers.badgesAssignment,
};

type IProps = IServiceGroups.Group & InjectedProps;

type IViewProps = typeof OBSERVERS;

const View = observer((props: React.PropsWithChildren<IProps & IViewProps>) => {
  const { folders, assignments, handlers, menuItems, ...folder } = props;

  const entityAssignments = assignments.data[getBadgeStateKey(folder)] || [];

  const isVm = folder.type === 'VIRTUAL_SERVER';
  const acceptFolderType = isVm ? DragAndDropTypes.VM : DragAndDropTypes.DS;

  const TitleIcon = React.useMemo(() => {
    return (
      <FolderIconWrapper>
        <MuiIcons.Folder color="primary" className="fs-20" />
        <span className="_name-text">{isVm ? 'VM' : 'DS'}</span>
      </FolderIconWrapper>
    );
  }, [isVm]);

  return (
    <DropFolderComponent folder={folder} accepts={acceptFolderType}>
      <SFolderCard
        placeholder=""
        title={folder.name}
        testId={ALL_TEST_IDS.services.dns.main}
        dataReceived={folders.dataReceived}
        assignments={entityAssignments}
        isDisabled={folders.isRequesting}
        titleIcon={TitleIcon}
        menuItems={menuItems}
        onTitleClick={handlers.openInnerContent}
      >
        <FolderContent {...(folder || {})} />
      </SFolderCard>
    </DropFolderComponent>
  );
});

const FolderCard = (props: React.PropsWithChildren<IProps>) => (
  <View {...props} {...OBSERVERS} />
);

export default withFolderActions(FolderCard);
