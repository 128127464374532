import { useMemo } from 'react';
import { defineStatusNamespace, IStatusNamespace } from 'enums';

interface IStatus {
  status: IStatusNamespace;
  isProvisioning: boolean;
  inProgress: boolean;
}

export function useServiceStatus<S extends string | null>(status: S): IStatus {
  const namespace = useMemo(() => defineStatusNamespace(status), [status]);

  const isInProgress = [
    'provisioning',
    'patching',
    'deleting',
    'powerOnInProgress',
    'powerOffInProgress',
    'initializing',
  ].includes(namespace);

  const isProvisioning = ['provisioning'].includes(namespace);

  return {
    status: namespace,
    isProvisioning: isProvisioning,
    inProgress: isInProgress,
  };
}
