import * as React from 'react';
import { reduce } from 'ramda';
import { round } from '../utils';
import { IPrice } from 'pricing';
import { AnyShape } from 'global-shapes';
import { VM_PRICE_SERVICE_IDS } from 'enums';

type IVmBillingDisplayConfig = {
  isRootProvider: boolean;
  billingInterval?: IVmTypes.IBillingCircleInterval;
  isUsageEnabled?: boolean;
  billingCycleId?: number;
  isNew: boolean;
  isDedicated?: boolean;
};

type IVmBillingDisplayConfigReturnValue = {
  showEnableSwitcher?: boolean;
  showCostInfo?: boolean;
  isUsageEnabled?: boolean;
  isEditableResources?: boolean;
  isBillingMonthly: boolean;
  discount: number;
};

export function useVmBillingDisplay(
  config: IVmBillingDisplayConfig
): IVmBillingDisplayConfigReturnValue {
  const {
    isRootProvider,
    billingInterval,
    isUsageEnabled,
    billingCycleId,
    isNew,
    isDedicated,
  } = config;

  const isBillingMonthly = isDedicated
    ? billingInterval === 'MONTH'
    : billingInterval === 'NONE';

  const billingSwitcherIsAvailable = isRootProvider;

  const showEnableSwitcher = isRootProvider && isBillingMonthly;

  const discount = React.useMemo(() => {
    switch (billingInterval) {
      case 'YEAR':
        return 0.1;
      case 'HALF_YEAR':
        return 0.05;
      default:
        return 0;
    }
  }, [billingInterval]);

  const isEditableResources = React.useMemo(() => {
    if (isRootProvider) {
      return true;
    }

    if (!isUsageEnabled && !billingCycleId) {
      return false;
    }

    return true;
  }, [isRootProvider, isBillingMonthly, billingCycleId, isUsageEnabled, isNew]);

  const showCostInfo = React.useMemo(() => {
    if (billingSwitcherIsAvailable) {
      return !isBillingMonthly || isUsageEnabled;
    }

    if (!isEditableResources) {
      return false;
    }

    return true;
  }, [
    billingSwitcherIsAvailable,
    isBillingMonthly,
    isUsageEnabled,
    isEditableResources,
    isNew,
  ]);

  if (isNew) {
    return {
      showEnableSwitcher,
      showCostInfo: !!isUsageEnabled,
      isUsageEnabled: !!isUsageEnabled,
      isEditableResources,
      discount,
      isBillingMonthly,
    };
  }

  return {
    showEnableSwitcher,
    showCostInfo,
    isUsageEnabled: false,
    isEditableResources,
    discount,
    isBillingMonthly,
  };
}

interface ICalculateValues {
  virtualCpus: number;
  ramMb: number;
  osDiskSizeGb: number;
  baseFee: number;
  winLicense: number;

  [field: string]: number;
}

interface ICalculatePrices {
  virtualCpus: AnyShape;
  ramMb: AnyShape;
  osDiskSizeGb: AnyShape;
  winLicense: AnyShape;
  baseFee: AnyShape;

  [field: string]: AnyShape;
}

export const findVmPricing = (pricing: IPrice[]): any =>
  reduce((res: AnyShape, val: { id: number; name: string }) => {
    const foundPrice = (pricing || []).find(
      (p) => p.serviceResourceId === val.id
    );
    res[val.name] = {
      name: val.name,
      value: foundPrice ? +foundPrice.value : 0,
      id: foundPrice ? +foundPrice.serviceResourceId : null,
    };
    return res;
  }, {})(VM_PRICE_SERVICE_IDS);

export const calculatePrices = (
  _values: ICalculateValues,
  prices: ICalculatePrices
) => {
  const result: ICalculateValues = { ..._values };
  const values = { ..._values, baseFee: 1 };

  Object.keys(values).forEach((key) => {
    // @ts-ignore
    result[key] = round(+prices[key].value * +(values[key] || 0) * 30, 2);
  });

  return result;
};
