import { ILanguage } from 'global-shapes';
import { changeLang, updatePageTitle } from './userInfo';
import { GLOBAL_TRANSLATIONS } from 'enums/translations';
import { GET_FILE_SERVER } from './fetcher/axiosWrappers';
import i18n from '../i18n';

const availableLanguages = ['de', 'en'];

export const downloadAndSetLocalization = async (currentLang: ILanguage) => {
  const de = await GET_FILE_SERVER('/public/locale/de.json').catch(() => ({}));

  const en = await GET_FILE_SERVER('/public/locale/en.json').catch(() => ({}));

  GLOBAL_TRANSLATIONS.setResource('de', de);
  GLOBAL_TRANSLATIONS.setResource('en', en);

  const ns = 'translation';

  const resources = {
    en: { translation: en },
    de: { translation: de },
  };

  availableLanguages.forEach((lang) => {
    if (i18n.hasResourceBundle(lang, ns)) {
      i18n.removeResourceBundle(lang, ns);
    }
    // @ts-ignore
    i18n.addResourceBundle(lang, ns, resources[lang].translation, true, true);
  });

  changeLang(currentLang);
  updatePageTitle(currentLang);
};
