import { vcdService } from 'services';
import { BaseMobxInstanceDecorator } from './utilities';

export const vcd = new BaseMobxInstanceDecorator<IVmVcdTypes.Vcd, true>({
  instanceName: 'vcd',
  requests: {
    get: vcdService.getVcds,
  },
  initialState: [],
});

export const vcdVms = new BaseMobxInstanceDecorator<IVmVcdTypes.Vm, true>({
  instanceName: 'vcd',
  requests: {
    get: vcdService.getVcdVms,
  },
  initialState: [],
});

export const vmSpec = new BaseMobxInstanceDecorator<IVmVcdTypes.VmSpec, false>({
  instanceName: 'vcd',
  requests: {
    get: vcdService.getVmSpec,
  },
  initialState: {} as IVmVcdTypes.VmSpec,
});
