import { makeAutoObservable } from 'mobx';
import * as R from 'ramda';
import { AxiosPromise } from 'axios';
import { showSystemMessage } from 'utils/global';
import { IAxiosPromise } from 'axios-shapes';
import { TableParams } from 'global-shapes';

type IExtId = any;

type IGetRequest<D, M> = (
  query?: any
) =>
  | IAxiosPromise<M extends true ? D[] : D>
  | AxiosPromise<M extends true ? D[] : D>
  | Promise<{ data: M extends true ? D[] : D }>;

type ICreateRequest<D> = (payload: any) => AxiosPromise<D>;

type IUpdateRequest<D> = (id: IExtId, payload: any) => AxiosPromise<D>;

type IReloadRequest<D> = (
  id: IExtId,
  params?: Record<string, any>
) => AxiosPromise<D>;

type IRemoveRequest<D> = (id: IExtId, data?: any) => AxiosPromise<D | Task>;

type IRequestTypes = 'get' | 'create' | ' update' | 'remove' | string;

type IRequestsConfig<D, M> = {
  get?: IGetRequest<D, M>;
  create?: ICreateRequest<D>;
  update?: IUpdateRequest<D>;
  remove?: IRemoveRequest<D>;
  reload?: IReloadRequest<D>;
};

type IAdditionalRequest<R = any> = (
  ...args: any[]
) => IAxiosPromise<R> | AxiosPromise<R>;

type IOptions<D, M extends boolean> = {
  requests: IRequestsConfig<D, M>;
  additionalRequests?: {
    [index: string]: IAdditionalRequest;
  };
  initialState: M extends true ? D[] : D;
  instanceName?: string;
};

export const parseParams = (params: TableParams): TableParams => {
  return {
    ...params,
    totalCount: params.totalCount || 0,
  };
};

export class BaseMobxInstanceDecorator<P, M extends boolean> {
  selectedInstance: any = null;
  isLoading = false;
  isDeleting = false;
  isUpdating = false;
  isCreating = false;
  dataReceived = false;
  initialState: M extends true ? P[] : P;
  updateIds: number[] = [];
  requests: IOptions<P, M>['requests'] & IOptions<P, M>['additionalRequests'];
  // @ts-ignore
  data: M extends true ? P[] : P = null;
  meta: Record<string, any> = {};
  errors: Record<IRequestTypes, string | null> = {};

  constructor(props: IOptions<P, M>) {
    makeAutoObservable(this);
    this.instanceName = props.instanceName;
    // @ts-ignore
    this.requests = { ...props.requests, ...props.additionalRequests };
    // @ts-ignore
    this.data = props.initialState;
    this.initialState = props.initialState;
  }

  get isStateObject() {
    return !R.is(Array)(this.initialState);
  }

  get isRequesting() {
    return (
      this.isLoading || this.isDeleting || this.isUpdating || this.isCreating
    );
  }

  public instanceName?: string = undefined;

  private resetUpdateIds = (id: number) => {
    if (id && typeof id === 'number') {
      this.updateIds = this.isStateObject
        ? []
        : this.updateIds.filter((_id: number) => _id !== id);
    }
  };

  get = async (query?: any) => {
    if (this.requests.get)
      try {
        this.isLoading = true;
        const res = await this.requests.get(query);
        this.data = res.data;

        // @ts-ignore
        if (res.pagination) {
          // @ts-ignore
          this.meta = parseParams(res.pagination);
        }

        this.errors.get = null;
        this.isLoading = false;
        this.dataReceived = true;
        return res.data;
      } catch (error: any) {
        this.errors.get = error.message;
        this.isLoading = false;
        this.dataReceived = true;
      }
  };

  create = async (
    payload: Record<string, any>,
    shouldUpdateEmediately = false
  ) => {
    if (this.requests.create)
      try {
        this.isCreating = true;
        this.errors.create = null;

        const res = await this.requests.create(payload);

        if (shouldUpdateEmediately) {
          // @ts-ignore
          this.data = this.isStateObject
            ? { ...(this.data || {}), ...res.data }
            : [
                ...((this.data as Array<P>) || []),
                ...(payload ? [payload] : []),
              ];
        }

        this.isCreating = false;

        return res.data;
      } catch (error: any) {
        this.errors.create = error.message;
        showSystemMessage(error.message, 'error');
        this.isCreating = false;
        throw error;
      }
  };

  update = async (id: IExtId, payload: any, shouldUpdateEmediately = false) => {
    if (this.requests.update) {
      try {
        this.isUpdating = true;
        this.errors.update = null;
        this.updateIds = this.isStateObject
          ? []
          : [...this.updateIds, id as number];

        const res = await this.requests.update(id, payload);

        if (shouldUpdateEmediately) {
          // @ts-ignore
          this.data = this.isStateObject
            ? { ...this.data, ...payload }
            : (this.data as [])?.map((it: any) =>
                it.id === id ? { ...it, ...payload } : it
              );
        }

        this.resetUpdateIds(id as number);

        this.isUpdating = false;

        return res.data;
      } catch (error: any) {
        this.errors.update = error.message;
        showSystemMessage(error.message, 'error');

        this.resetUpdateIds(id as number);
        this.isUpdating = false;
        throw error;
      }
    }
  };

  remove = async (id: IExtId, shouldUpdateEmediately = false) => {
    if (this.requests.remove) {
      try {
        this.isDeleting = true;
        this.errors.remove = null;

        const res = await this.requests.remove(id);

        if (shouldUpdateEmediately) {
          this.data = (
            this.isStateObject
              ? this.data
              : ((this.data as []) || []).filter((u: any) => u.id !== id)
          ) as M extends true ? P[] : P;
        }

        this.isDeleting = false;

        return res.data;
      } catch (error: any) {
        this.errors.remove = error.message;
        showSystemMessage(error.message, 'error');
        this.isDeleting = false;
        throw error;
      }
    }
  };

  reload = async (id: number, query?: any) => {
    this.errors.reload = null;
    if (this.requests.reload) {
      try {
        this.isUpdating = true;
        this.errors.remove = null;

        const res = await this.requests.reload(id as any, query);

        this.data = (
          this.isStateObject
            ? { ...this.data, ...res.data }
            : (this.data as []).map((it: any) =>
                it.id === id ? { ...it, ...res.data } : it
              )
        ) as M extends true ? P[] : P;

        this.isUpdating = false;

        return res.data;
      } catch (error: any) {
        this.errors.remove = error.message;
        showSystemMessage(error.message, 'error');
        this.isUpdating = false;
      }
    }

    this.resetUpdateIds(id as number);
  };

  reset = () => {
    this.data = this.initialState;
    this.dataReceived = false;
    this.meta = {};
    this.errors = {};
  };

  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setData = (payload: M extends true ? P[] : P, id?: number) => {
    this.data = (
      this.isStateObject
        ? payload
        : id
        ? ((this.data as []) || []).map((d: any) => (d.id === id ? payload : d))
        : payload
    ) as M extends true ? P[] : P;
  };

  updateById = (id: number | string, payload: any) => {
    this.data = (
      this.isStateObject
        ? { ...this.data, [id]: payload }
        : (this.data as []).map((it: any) =>
            it.id === id ? { ...it, ...payload } : it
          )
    ) as M extends true ? P[] : P;
  };

  mergeArray = (payload: P) => {
    this.data = R.mergeDeepRight(
      this.data as object,
      payload as object
    ) as M extends true ? P[] : P;
  };

  mergeById = (id: number | string, payload: P) => {
    this.data = (
      this.isStateObject
        ? {
            ...this.data,
            [id]: {
              // @ts-ignore
              ...this.data[id],
              ...payload,
            },
          }
        : (this.data as []).map((it: any) =>
            it.id === id ? { ...it, ...payload } : it
          )
    ) as M extends true ? P[] : P;
  };

  merge = (payload: Partial<P>, id?: number) => {
    this.data = (
      this.isStateObject
        ? {
            ...this.data,
            ...payload,
          }
        : (this.data as []).map((it: any) =>
            it.id === id ? { ...it, ...payload } : it
          )
    ) as M extends true ? P[] : P;
  };

  executeRequest =
    (namespace: string) =>
    async (...args: any) => {
      const request = this.requests[namespace];
      let result = null;
      if (request) {
        this.isLoading = true;
        try {
          result = await request(...args);
        } catch (error) {
          this.isLoading = false;
          throw error;
        }
      }

      this.isLoading = false;
      return result;
    };

  addToArrayFirst = (payload: P) => {
    this.data = [payload, ...(this.data as [])] as M extends true ? P[] : P;
  };

  public setSelectedInstance = (selectedInstance: any) => {
    this.selectedInstance = selectedInstance;
  };
}

export class BaseStateDecorator<P = Record<string, any>> {
  public state: P = {} as P;

  constructor(props: { initialState: P }) {
    makeAutoObservable(this);
    this.state = props.initialState || ({} as P);
  }

  public handleChange = async (state: Partial<P>) => {
    this.state = {
      ...this.state,
      ...state,
    };

    return this.state;
  };
}
