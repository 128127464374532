import { IAxiosPromise } from 'axios-shapes';
import { GET, POST, DELETE, buildListParams } from 'utils';
import { ForwardedPort } from 'forwarded-ports';
import { ICommonListParams } from 'global-shapes';
import { IRemoteSupport } from '../components/RemoteSupport/types';
import { AxiosPromise } from 'axios';

export const getPorts = (
  params: ICommonListParams
): IAxiosPromise<ForwardedPort[]> =>
  GET(`/forwarded-ports`, buildListParams(params, ['orderBy', 'orderType']));

export const createPort = (
  data: IRemoteSupport.FormValues
): AxiosPromise<ForwardedPort> =>
  POST(`/forwarded-ports`, {}, { ...data, expireIn: data.expireIn.value });

export const deletePort = (id: number): AxiosPromise<ForwardedPort> =>
  DELETE(`/forwarded-ports/${id}`);
