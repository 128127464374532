import * as React from 'react';
import * as Translations from 'i18next';
import cn from 'classnames';
import { MuiIcons, styled } from 'elements';
import { withTranslation } from 'react-i18next';
import { PALETTE } from 'enums';
import { remCalc } from 'utils';

const SChip = styled('div')(({ theme }) => ({
  border: `1px solid ${PALETTE.lightGrey}`,
  borderRadius: remCalc(6),
  margin: remCalc(2, 5, 2, 0),
  padding: remCalc(4, 6, 4, 10),
  lineHeight: 1,
  fontSize: remCalc(10),
  color: PALETTE.primaryDark,
}));

const SDot = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  width: remCalc(7),
  height: remCalc(7),
  backgroundColor: theme.palette.primary.main,
  marginRight: remCalc(8),
}));

const SCancelIcon = styled(MuiIcons.Clear)(({ theme }) => ({
  '&:hover': {
    color: PALETTE.error,
  },
}));

const SCheckbox = styled(
  ({ isActive, ...rest }: any & { isActive: boolean }) => <div {...rest} />
)(({ isActive, theme }) => {
  return {
    border: `1px solid ${
      isActive ? theme.palette.primary.main : PALETTE.lightGrey
    }`,
    width: remCalc(9),
    height: remCalc(9),
    backgroundColor: isActive ? theme.palette.primary.main : undefined,
    marginRight: remCalc(5),
  };
});

type IProps = Translations.WithT &
  React.PropsWithChildren<{
    id: number;
    text: string;
    onClear: (id: number) => void;
  }>;

const Renderer = (props: IProps) => {
  const { id, text, onClear } = props;
  return (
    <SChip className={cn('flex align-center steel')}>
      <SDot />
      <div>{text}</div>
      <SCancelIcon
        className={cn('ml-5 fs-12 pointer')}
        onClick={() => onClear(id)}
      />
    </SChip>
  );
};

const FilterChip = withTranslation()(Renderer);

export const FilterItemText = (props: {
  isActive: boolean;
  text: string;
  onClick: (...args: any) => void;
}) => {
  return (
    <div
      className="flex align-center pl-15 pr-10 mb-10 pointer lh-1"
      onClick={props.onClick}
    >
      <SCheckbox isActive={props.isActive} />
      <div>{props.text}</div>
    </div>
  );
};

export default FilterChip;
