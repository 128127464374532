import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Loader, LinearTable, Switch } from 'elements';
import { ALL_TEST_IDS } from 'enums';
import { useTranslation } from 'react-i18next';
import { useQuery, useAsync, useState } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import { IBackupVersion, ResoreVersionPayload, IBackupEntity } from 'backup';
import { backupService } from 'services';
import * as StateHandlers from 'states';
import * as UTILS from 'utils';
import { backupVersionsColumn } from './constants';
import VmToRestoreDialog from './VmToRestoreDialog';

const OBSERVERS = {
  backup: StateHandlers.backup,
  backupVersions: StateHandlers.backupVersions,
  backupNotifications: StateHandlers.backupNotifications,
};

const INITIAL: IState = {
  selectedVersion: null,
};

type IProps = {
  isEditable: boolean;
  entityId: number;
  backupId: number | null;
  entityName: IBackupEntity;
};

type IState = {
  selectedVersion?: IBackupVersion | null;
  vms?: IVmTypes.Vm[];
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { backupId, entityName, backupVersions, entityId, backup, isEditable } =
    props;
  const { t } = useTranslation();
  const [state, handleState] = useState<IState>(INITIAL);
  const { query, queryStr, changeQuery } = useQuery();
  const { execute: getBackupVersionVms, isPending: isRequestingVms } = useAsync(
    backupService.getBackupVersionVms
  );
  const { execute: restoreVmBackupReq } = useAsync(
    backupService.restoreVmBackup
  );

  const onBackupTaskUpdate = React.useCallback(() => {
    fetchBackupVersions(backupId);
    backup.get({ id: entityId, entityName });
  }, [entityId, entityName, backupId]);

  const restoreVersion = React.useCallback(
    (hasMultiVms: boolean) =>
      (version: IBackupVersion, payload: ResoreVersionPayload) => {
        const onSuccess = (values: any) =>
          restoreVmBackupReq(
            backupId,
            version.id,
            hasMultiVms
              ? payload
              : { ...payload, powerOnAfterRestore: values.powerOnAfterRestore }
          ).then(() => {
            UTILS.showSystemMessage(
              'services.backup.notify.restore.success',
              'success'
            );
            handleState({
              selectedVersion: null,
              vms: undefined,
            });
            onBackupTaskUpdate();
          });
        const confirmProps: any = {
          title: 'services.backup.confirm.restore.title',
          content: 'services.backup.confirm.restore.content',
          onSuccess,
          onCancel: () => undefined,
        };

        if (!hasMultiVms) {
          confirmProps.initialValues = {
            powerOnAfterRestore: false,
          };
          confirmProps.content = (form: ReturnType<typeof useFormik>) => {
            return (
              <>
                <div className="steel fs-14 mb-25">
                  {t('services.backup.confirm.restore.content')}
                </div>
                <Switch
                  className="mb-20"
                  label={t('services.backup.dialog.target.switchers.powerOn')}
                  checked={form.values.powerOnAfterRestore}
                  onCheck={(powerOnAfterRestore) =>
                    form.setFieldValue(
                      'powerOnAfterRestore',
                      powerOnAfterRestore
                    )
                  }
                />
              </>
            );
          };
        }

        return UTILS.confirm(confirmProps);
      },
    [backupId, entityName]
  );

  const onRestore = React.useCallback(
    async (version: IBackupVersion) => {
      const vms = await getBackupVersionVms(backupId as number, version.id);

      if (!vms.data.length) {
        return UTILS.showSystemMessage(
          'services.backup.notify.restore.noVms',
          'error'
        );
      }

      if (vms.data.length > 1) {
        return handleState({
          selectedVersion: version,
          vms: vms.data,
        });
      }

      return restoreVersion(false)(version, {
        vmIds: vms.data?.map((v) => v.id),
        powerOnAfterRestore: true,
      });
    },
    [backupId]
  );

  const columns = useMemo(
    () =>
      backupVersionsColumn({
        t,
        onRestore,
        isRequestingVms,
        isEditable,
      }),
    [isRequestingVms, isEditable]
  );

  const fetchBackupVersions = useCallback(
    (id: number | null) =>
      backupVersions.get({
        backupId: id,
        ...query,
        include: 'backupJob',
      }),
    [queryStr]
  );

  React.useEffect(() => {
    fetchBackupVersions(backupId);
  }, [backupId, queryStr]);

  if (backupVersions.isLoading || !backupVersions.dataReceived) {
    return <Loader />;
  }

  return (
    <div>
      <LinearTable
        testId={ALL_TEST_IDS.services.backup.versions.table}
        className="mb-30"
        columns={columns}
        data={backupVersions.data}
        noDataClassName={cn('steel fs-14 pt-50 pb-50')}
        usePagination
        query={query}
        params={backupVersions.meta as any}
        onPageChange={(nq) => changeQuery({ id: query.id, ...nq })}
        onUpdateTable={onBackupTaskUpdate}
      />
      <VmToRestoreDialog
        open={!!state.selectedVersion}
        selected={state.selectedVersion}
        vms={state.vms}
        onClose={() => handleState({ selectedVersion: null, vms: undefined })}
        onSave={(payload) =>
          restoreVersion(true)(state.selectedVersion as IBackupVersion, payload)
        }
      />
    </div>
  );
});

const BackupVersions = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default BackupVersions;
