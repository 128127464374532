import { GET, POST, PUT, DELETE, isString, buildListParams } from 'utils';
import { uploadImage } from 'services/imageUploader';
import { AnyShape, ICommonListParams } from 'global-shapes';
import {
  ISoftwarePackage,
  ICreateSoftwarePackageValues,
  IInstallSoftwarePackageValues,
  ISoftwarePackageInTenant,
} from 'app-store';
import { AxiosPromise, AxiosResponse } from 'axios';
import { IAxiosPromise } from 'axios-shapes';
import { reformatPackageFormValues } from '../components/SoftwarePackages/helpers';

export const get = (
  params: ICommonListParams
): IAxiosPromise<ISoftwarePackage[]> =>
  GET(
    '/software-packages',
    buildListParams(params, [
      'orderBy',
      'orderType',
      'q',
      'vmId',
      'isInstalled',
      'licensePricingType',
    ])
  );

export const create = async (
  payload: ICreateSoftwarePackageValues
): Promise<AxiosResponse<ISoftwarePackage>> => {
  const logoId = await uploadImage(payload.logo, 'logos').then(
    (res) => res.data.id
  );
  return POST(
    '/software-packages',
    {},
    { ...reformatPackageFormValues(payload), iconImageId: logoId }
  );
};

export const update = async (
  id: number,
  payload: ICreateSoftwarePackageValues
): Promise<AxiosResponse<ISoftwarePackage>> => {
  let logoId = undefined;
  if (!isString(payload.logo)) {
    logoId = await uploadImage(payload.logo, 'logos').then(
      (res) => res.data.id
    );
  }

  const data = reformatPackageFormValues(payload);
  if (logoId) {
    data.iconImageId = logoId;
  }
  return PUT(`/software-packages/${id}`, {}, data);
};

export const removePackage = async (
  id: number
): Promise<AxiosResponse<ISoftwarePackage>> => {
  return DELETE(`/software-packages/${id}`);
};

export const getVmPackages = ({
  vmId,
  ...params
}: ICommonListParams & { vmId: number }): IAxiosPromise<
  ISoftwarePackageInTenant[]
> => GET(`/vms/${vmId}/software-packages`, params);

export const enableSoftwarePackage = (
  packageId: number
): AxiosPromise<ISoftwarePackageInTenant> =>
  POST(`/software-packages/${packageId}/enable`);

export const disableSoftwarePackage = (
  packageId: number
): AxiosPromise<ISoftwarePackageInTenant> =>
  POST(`/software-packages/${packageId}/disable`);

export const installSoftwarePackage = (
  vmId: number,
  packageId: number,
  values: Partial<IInstallSoftwarePackageValues>
): AxiosPromise<ISoftwarePackageInTenant> => {
  const payload: AnyShape = {};
  if (values.selectedType === 'own') payload.licenseKey = values.licenseKey;
  if (values.selectedType === 'provider') {
    const useCustomLicenseQty = values.activeRdsUsersBilling;
    const activeRdsUsersBilling = useCustomLicenseQty ? false : true;
    payload.activeRdsUsersBilling = activeRdsUsersBilling;

    if (useCustomLicenseQty && values.providersLicenseUnits) {
      payload.providersLicenseUnits = +values.providersLicenseUnits;
    }
  }
  return POST(`/vms/${vmId}/software-packages/${packageId}`, {}, payload);
};

export const uninstallSoftwarePackage = (
  vmId: number,
  packageId: number
): AxiosPromise<Task> => DELETE(`/vms/${vmId}/software-packages/${packageId}`);
