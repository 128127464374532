import * as React from 'react';
import cn from 'classnames';
import { useFormik } from 'formik';
import { Button, Dialog, Row, DialogProps } from 'elements';
import { useTranslation } from 'react-i18next';
import { AnyFunc } from 'global-shapes';
import { useState } from 'hooks';
import { isString, remCalc, isFunction } from 'utils';
import { styled } from 'elements';
import { isEmpty } from 'ramda';

interface IState {
  isConfirmOpen: boolean;
  props: any;
  isRequestingConfirmDialog: boolean;
  inputValue: string;
}

const ConfirmButton = styled(Button)(({ theme }) => ({
  minWidth: remCalc(100),
}));

const INITIAL_STATE: IState = {
  isConfirmOpen: false,
  isRequestingConfirmDialog: false,
  props: null,
  inputValue: '',
};

type IProps = DialogProps<any> & {
  formInitials: any;
  validationSchema: any;
  onSuccess?: AnyFunc;
  successButtonProps?: any;
  successLabel?: string;
  cancelLabel?: string;
};

const GeneralConfirmDialog = (props: IProps) => {
  const {
    open,
    formInitials,
    onClose,
    onSave,
    validationSchema,
    maxWidth,
    cancelLabel,
    title,
    onSuccess,
    successButtonProps,
    successLabel,
    content,
  } = props;
  const [state, handleState] = useState<IState>(INITIAL_STATE);

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: formInitials || {},
    validationSchema,
    onSubmit: (val, config) =>
      Promise.resolve(onSave && onSave(val))
        .then(() => {
          onClose();
          config.resetForm();
        })
        .catch(() => null),
  });

  const outProps = {
    maxWidth,
    cancelLabel,
    title,
    onSuccess,
    successButtonProps,
    successLabel,
    content,
  };

  const showDialog = !isEmpty(outProps);

  const Content = React.useMemo(() => {
    if (isString(content))
      return <div className="steel fs-14">{t(content as string)}</div>;
    if (isFunction(content) && !React.isValidElement(content))
      // @ts-ignore
      return content(formik);
    return content || null;
  }, [content, formik]);

  return (
    <>
      {showDialog && (
        <Dialog
          maxWidth={maxWidth || 'sm'}
          handleSubmit={formInitials ? formik.handleSubmit : undefined}
          title={t(title)}
          fullWidth
          open={open}
          onClose={onClose}
          onSave={() => undefined}
          actions={
            <Row justifyContent="flex-end">
              {onClose && (
                <ConfirmButton
                  className={cn('mr-15')}
                  onClick={() =>
                    Promise.resolve(onClose()).then(() => {
                      formik.resetForm();
                    })
                  }
                >
                  {t(cancelLabel || 'common.no')}
                </ConfirmButton>
              )}

              {onSuccess && (
                <ConfirmButton
                  color="default"
                  variant="outlined"
                  disabled={
                    formik.isSubmitting || state.isRequestingConfirmDialog
                  }
                  onClick={async () => {
                    if (formInitials) {
                      return formik.submitForm();
                    }
                    handleState({ isRequestingConfirmDialog: true });
                    await onSuccess(formik.values);
                    onClose();
                    formik.resetForm();
                    handleState({ isRequestingConfirmDialog: false });
                  }}
                  {...(successButtonProps || {})}
                >
                  {t(successLabel || 'common.yes')}
                </ConfirmButton>
              )}
            </Row>
          }
        >
          {Content}
        </Dialog>
      )}
    </>
  );
};

export default GeneralConfirmDialog;
