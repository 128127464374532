import { ValueType } from 'global-shapes';
import * as Yup from 'yup';
import { ResourceQuota } from 'quotas';
import { parseYupShemaErrors } from 'utils';
import { parseBackupUpdateStateToForm } from 'components/Backup/helpers';
import { NETWORKS_DEFAULT_PARAMS, REGEX } from 'enums';
import { connectivityService, virtualMachinesService } from 'services';
import { MAX_VM_DETAILS_VALUES } from '../constants';

export function parseVmDetailsToForm(
  vm: IVmTypes.Vm | null,
  templates: IVmTypes.IVmTemplate[],
  pendingInterval?: ValueType<IVmTypes.IBillingCircleInterval>
): VmDialogTypes.IVmFormValues {
  const { interval } = vm?.billingCycle || {};

  const nextBillingCycleInterval = vm?.billingCycle
    ? {
        value: interval,
        label: `costsInfo.options.billingCircle.${interval}`,
      }
    : (pendingInterval as ValueType<IVmTypes.IBillingCircleInterval>);

  const template = templates.find((t) => t.os?.id === vm?.os?.id);

  return {
    id: vm?.id,
    name: vm?.name || '',
    description: vm?.description || '',
    virtualCpus: vm?.virtualCpus || 0,
    isUsageEnabled: !!vm?.isUsageEnabled,
    ramMb: vm?.ramMb || 0,
    osDiskSizeGb: vm?.osDiskSizeGb || 0,
    ports: vm?.services || [],
    // @ts-ignore
    disks: vm?.disks || [],
    isPublic: vm?.networkType === 'PUBLIC',
    billingCycleInterval: nextBillingCycleInterval as any,
    templateId: {
      value: template?.id || 0,
      label: template?.name || '',
      familyId: template?.os?.osFamilyId || 0,
    },
  };
}

export function mapOsSystems(
  systems: IVmTypes.IVmTemplate[]
): VmDialogTypes.IDetailsState {
  const newState: VmDialogTypes.IDetailsState = {
    systems: [],
    osDiskLimits: {},
  };

  systems.forEach((system) => {
    newState.systems.push({
      value: system.id,
      label: system.name,
      familyId: system.os.osFamilyId,
      ramMb: system.vmRamMb / 1024,
      osDiskSizeGb: system.vmStorageGb || 40,
      virtualCpus: system.vmVirtualCpus,
    });

    newState.osDiskLimits[system.id] = {
      min: system.vmStorageGb || 40,
    };
  });

  return newState;
}

export const validate =
  (validationSchema: Yup.AnySchema) =>
  async (values: VmDialogTypes.IVmFormValues) => {
    let errors: any = {};

    try {
      await validationSchema.validate(values, {
        abortEarly: false,
      });
    } catch (err) {
      errors = parseYupShemaErrors(err as any);
    }

    return errors;
  };

export const getVmDetailsValidationSchema = (
  costBreakdown: ResourceQuota,
  isNew: boolean,
  isRunning: boolean,
  initials: any
) =>
  Yup.object().shape({
    // @ts-ignore
    name: Yup.string()
      .required('forms.required')
      .max(15, 'forms.invalid.max')
      .matches(
        /^(?!-)(?![0-9]{1,15}$)(?![a-zA-Z0-9-]*-$)[a-zA-Z0-9-]{1,15}$/i,
        'forms.invalid'
      )
      .matches(/^\S+(?: \S+)*$/, 'forms.invalid')
      .matches(/[a-zA-Z]/g, 'forms.invalid.shouldContainLetter'),
    description: Yup.string().max(100, 'forms.invalid'),
    virtualCpus: Yup.string()
      .test('virtualCpus', 'forms.invalid.max', (val) => {
        const value = +(val || 0);
        return value <= MAX_VM_DETAILS_VALUES.virtualCpus;
      })
      .test('virtualCpus', 'forms.invalid.powerOffToDecrease', (val) => {
        if (!isRunning) return true;
        const value = +(val || 0);
        return value >= initials.virtualCpus;
      })
      .test('virtualCpus', 'forms.invalid.overQuota', (_val) => {
        const val = +(_val || 0);
        const initial = initials.virtualCpus || 0;
        const remaining = costBreakdown.remaining.virtualCpus;
        const used = costBreakdown.used.virtualCpus;
        const total = remaining + used;
        return used - initial + val <= total;
      }),
    ramMb: Yup.string()
      .test('ramMb', 'forms.invalid.max', (val) => {
        const value = +(val || 0);
        return value <= MAX_VM_DETAILS_VALUES.ramMb;
      })
      .test('ramMb', 'forms.invalid.powerOffToDecrease', (val) => {
        if (!isRunning) return true;
        const value = +(val || 0);
        return value >= initials.ramMb;
      })
      .test('ramMb', 'forms.invalid.ramLimitationFor3', (val) => {
        if (!isRunning) return true;
        const value = +(val || 0);

        if (initials.ramMb < 3) {
          return value <= 3;
        }
        return true;
      })
      .test('ramMb', 'forms.invalid.ramCannotChange', (val) => {
        if (!isRunning) return true;
        const value = +(val || 0);

        if (initials.ramMb === 3) {
          return value === 3;
        }

        return true;
      })
      .test('ramMb', 'forms.invalid.x16RamMore', (val) => {
        if (!isRunning) return true;
        const value = +(val || 0);

        if (initials.ramMb >= 3) {
          return value <= initials.ramMb * 16;
        }
        return true;
      })
      .test('ramMb', 'forms.invalid.overQuota', (_val) => {
        const val = +(_val || 0);
        const initial = initials.ramMb || 0;
        const remaining = costBreakdown.remaining.ramMb / 1024;
        const used = costBreakdown.used.ramMb / 1024;
        const total = remaining + used;
        return used - initial + val <= total;
      }),
    osDiskSizeGb: Yup.string()
      .test('osDiskSizeGb', 'forms.invalid.max', (val) => {
        const value = +(val || 0);
        return value <= MAX_VM_DETAILS_VALUES.osDiskSizeGb;
      })
      .test('osDiskSizeGb', 'forms.invalid.powerOffToDecrease', (val) => {
        if (!isRunning) return true;
        const value = +(val || 0);
        return value >= initials.osDiskSizeGb;
      })
      .test('osDiskSizeGb', 'forms.invalid.overQuota', (_val) => {
        const val = +(_val || 0);
        const initial = initials.osDiskSizeGb || 0;
        const remaining = costBreakdown.remaining.diskSizeGb;
        const used = costBreakdown.used.diskSizeGb;
        const total = remaining + used;
        return used - initial + val <= total;
      }),
    ...(isNew
      ? {
          templateId: Yup.object().required('forms.required'),
          guestOSPassword: Yup.string()
            .max(32, 'forms.invalid.max')
            .required('forms.required')
            .matches(REGEX.password, 'forms.invalid'),
        }
      : {}),
  });

export const onFetchTemplates = (name: string) =>
  virtualMachinesService.getTemplates({
    q: name,
    limit: 1000,
    offset: 0,
    orderBy: 'name',
    orderType: 'asc',
  });

export const parsePayload = (
  values: VmDialogTypes.IVmFormValues,
  isRootProvider: boolean,
  coreInfrastructureId?: number
): VmDialogTypes.IVmCreatePayload => {
  const isUsageEnabled = !isRootProvider ? undefined : values.isUsageEnabled;

  const networkType = values.isPublic ? 'PUBLIC' : 'PRIVATE';
  const networkId = values.isPublic ? undefined : values.networkId?.value;
  const hasIsolatedNetwork = values.networkId?.type === 'ISOLATED';

  const billingCycleInterval = values.isUsageEnabled
    ? values.billingCycleInterval?.value || 'NONE'
    : 'NONE';

  const templateId = values.templateId.value;
  const isWindows = values.templateId?.familyId === 1;

  const guestOSPassword = values.guestOSPassword || undefined;

  const disks = values.disks.map(({ id, ...rest }) => rest);

  const services =
    values.isPublic || hasIsolatedNetwork
      ? []
      : values.ports.map(({ name, port, type }) => ({
          name,
          port: +port,
          type,
        }));

  const backupConfig = values.isBackupEnabled
    ? {
        backup: {
          ...parseBackupUpdateStateToForm(values as any),
          notifications: {
            isFailedNotification: !!values.isFailedNotification,
            isSuccessNotification: !!values.isSuccessNotification,
            isWarningNotification: !!values.isWarningNotification,
            notificationEmails: values.backupEmails || [],
          },
        },
      }
    : {};

  if (hasIsolatedNetwork) {
    values.joinDomain = false;
  }

  const _coreInfrastructureId =
    !values.joinDomain || values.isPublic || !isWindows
      ? undefined
      : coreInfrastructureId;

  const payload: VmDialogTypes.IVmCreatePayload = {
    name: values.name.trim(),
    description: values.description,
    templateId,
    networkType,
    osDiskSizeGb: +values.osDiskSizeGb,
    guestOSPassword,
    ramMb: +values.ramMb * 1024,
    virtualCpus: +values.virtualCpus,
    networkId,
    isUsageEnabled,
    billingCycleInterval,
    disks,
    services,
    coreInfrastructureId: _coreInfrastructureId,
    ...backupConfig,
  };

  if (values.billingCycleInterval?.value !== 'NONE') {
    payload.isUsageEnabled = undefined;
  }

  return payload;
};

export const checkIfVmNameExist = async (config: {
  vmName?: string;
  osDiskSizeGb?: number;
  vmId?: number;
}): Promise<{ exist: boolean; errorCode: string }> => {
  const name = config.vmName || '';
  let data: any = { exist: false, isValid: true, errorCode: '' };
  if (name.trim()) {
    const res = await virtualMachinesService.validateVm({
      name,
      osDiskSizeGb: config.osDiskSizeGb,
      vmId: config.vmId,
    });
    data = res.data;
    if (!data.isValid && data.errorCode === 'VM_NAME_USED') {
      data.exist = true;
      data.errorCode = 'forms.invalid.vmNameExist';
    }
  }

  return data;
};

export const fetchNetworkOptions = async (q: string) =>
  connectivityService
    .getNetworks({
      ...NETWORKS_DEFAULT_PARAMS,
      type: ['NAT_ROUTED', 'ISOLATED'],
      q,
    })
    .then((res) =>
      res.data.map((n) => ({
        value: n.id,
        label: n.name,
        type: n.type,
        isPrimary: n.isPrimary,
      }))
    );

export function parseCloneData(
  data: VmDialogTypes.IVmFormValues,
  isRootProvider: boolean
): IVmTypes.CloneValues {
  const payload: IVmTypes.CloneValues = {
    name: data.name.trim(),
    description: data.description,
    isUsageEnabled: !!data.isUsageEnabled,
    billingCycleInterval: data.billingCycleInterval,
    networkId: data.networkId as any,
    guestOSPassword: data.guestOSPassword || '',
  };

  if (!isRootProvider) {
    payload.isUsageEnabled = undefined;
  }

  return payload;
}
