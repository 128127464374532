import * as React from 'react';
import { isEmpty, sum } from 'ramda';
import {
  Button,
  Col,
  Paper,
  Row,
  Chip,
  ConditionalTooltip,
  Loader,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import {
  numberToCurrency,
  dayjs,
  confirm,
  showSystemMessage,
  noop,
} from 'utils';
import { billingCyclesService } from 'services';
import { GLOBAL_DATE_FORMAT } from 'enums';
import isBetween from 'dayjs/plugin/isBetween';
import { billingCycleColorMap } from './helpers';
import { BILLING_DATE_FORMAT } from 'pages/billable-items/constants';

dayjs.extend(isBetween);

const OBSERVERS = {
  billingInfo: StateHandlers.billingInfo,
};

type IProps = React.PropsWithChildren<{
  id: number;
}>;

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { id, billingInfo } = props;

  const { t } = useTranslation();

  const isTerminated = !!billingInfo.data.canceledAt;

  const latestTerminationDate = React.useMemo(() => {
    return dayjs(billingInfo.data?.periodEnd, BILLING_DATE_FORMAT).add(
      -30,
      'days'
    );
  }, []);

  const isTerminationDisabled = React.useMemo(() => {
    return dayjs().isBetween(
      dayjs(billingInfo.data?.periodEnd, 'YYYY-MM-DD'),
      latestTerminationDate
    );
  }, []);

  const amount = billingInfo.data?.billableItems
    ? sum(billingInfo.data?.billableItems.map((bi) => +bi.amount || 0))
    : 0;

  const terminateCycle = React.useCallback(
    () =>
      billingCyclesService
        .terminateCycle(id)
        .then((res) => {
          showSystemMessage(
            'services.vm.info.billingInfo.cycle.notify.terminate.success',
            'success'
          );
          return billingInfo.setData(res.data);
        })
        .catch((err) => showSystemMessage(err.message, 'error')),
    [id]
  );

  const enableCycle = React.useCallback(
    () =>
      billingCyclesService
        .enableCycle(id)
        .then((res) => {
          showSystemMessage(
            'services.vm.info.billingInfo.cycle.notify.undoTerminate.success',
            'success'
          );
          return billingInfo.setData(res.data);
        })
        .catch((err) => showSystemMessage(err.message, 'error')),
    [id]
  );

  const onConfirmTermination = React.useCallback(
    (terminated: boolean) => () => {
      confirm({
        title: t(
          `services.vm.info.billingInfo.cycle.confirm.${
            terminated ? 'undoTerminate' : 'terminate'
          }.title`
        ),
        content: t(
          `services.vm.info.billingInfo.cycle.confirm.${
            terminated ? 'undoTerminate' : 'terminate'
          }.content`
        ),
        onSuccess: terminated ? enableCycle : terminateCycle,
        successLabel: terminated
          ? 'services.vm.info.billingInfo.cycle.confirm.buttons.undoTerminate'
          : 'services.vm.info.billingInfo.cycle.confirm.buttons.terminate',
        onCancel: noop,
        cancelLabel: 'common.cancel',
      });
    },
    [id]
  );

  React.useEffect(() => {
    id && billingInfo.get({ id });
  }, [id]);

  React.useEffect(
    () => () => {
      billingInfo.reset();
    },
    []
  );

  if (!billingInfo.dataReceived) {
    return <Loader />;
  }

  if (isEmpty(billingInfo.data)) {
    return null;
  }

  return (
    <>
      <Paper className="p-30">
        <Row
          justifyContent="space-between"
          alignItems="center"
          className="mb-25"
        >
          <Col>
            <div className="uppercase bolder fs-14">
              {t('services.vm.info.billingInfo.cycle.title') as string}
            </div>
          </Col>
          <Col>
            <Row columnSpacing={2}>
              {billingInfo.data?.status !== 'PENDING' && (
                <Col>
                  <ConditionalTooltip
                    condition={isTerminationDisabled}
                    placement="top"
                    title={
                      t(
                        'services.vm.info.billingInfo.cycle.tooltips.unableToTerminate',
                        { date: '2342.54.24' }
                      ) as string
                    }
                  >
                    <Button
                      size="small"
                      color={isTerminated ? 'primary' : 'default'}
                      variant={isTerminated ? 'contained' : 'outlined'}
                      onClick={onConfirmTermination(isTerminated)}
                      disabled={isTerminationDisabled}
                    >
                      {t(
                        isTerminated
                          ? 'services.vm.info.billingInfo.cycle.buttons.undoTerminate'
                          : 'services.vm.info.billingInfo.cycle.buttons.terminate'
                      )}
                    </Button>
                  </ConditionalTooltip>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={4} className="fs-12">
            {t('table.head.billingCycle') as string}
          </Col>
          <Col xs={3} className="fs-12">
            {
              t(
                `services.vm.info.billingInfo.cycle.amount.${billingInfo.data?.interval}`
              ) as string
            }
          </Col>
          <Col xs={3} className="fs-12">
            {
              t(
                isTerminated
                  ? 'table.head.terminateOf'
                  : 'table.head.nextRenewal'
              ) as string
            }
          </Col>
          <Col xs={2} className="fs-12">
            {t('table.head.creationDate') as string}
          </Col>
        </Row>
        <Row alignItems="center">
          <Col xs={4}>
            <Row columnSpacing={1}>
              <Col className="steel">
                {
                  t(
                    `costsInfo.options.billingCircle.${billingInfo.data?.interval}`
                  ) as string
                }
              </Col>
              <Col>
                <Chip
                  status={billingCycleColorMap[billingInfo.data?.status] as any}
                >
                  {t(
                    `services.vm.info.billingInfo.cycle.status.${billingInfo.data?.status}`
                  )}
                </Chip>
              </Col>
            </Row>
          </Col>
          <Col xs={3} className="steel">
            {numberToCurrency(amount, false)}
          </Col>
          <Col xs={3} className="steel">
            {dayjs(billingInfo.data?.periodEnd, BILLING_DATE_FORMAT).format(
              GLOBAL_DATE_FORMAT
            )}
            {billingInfo.data?.pendingInterval && (
              <span className="pl-5">
                (
                {
                  t(
                    `services.vm.info.billingInfo.cycle.nextRenewal.${billingInfo.data?.pendingInterval}`
                  ) as string
                }
                )
              </span>
            )}
          </Col>
          <Col xs={2} className="steel">
            {dayjs(billingInfo.data?.periodStart, 'YYYY-MM-DD').format(
              GLOBAL_DATE_FORMAT
            )}
          </Col>
        </Row>
      </Paper>
    </>
  );
});

const BillingInfoComponent = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default BillingInfoComponent;
