import React, { useMemo } from 'react';
import { AppStoreComponent } from 'components/SoftwarePackages/AppStore';
import { usePermissions } from 'hooks';
import { defineRdsDisplayStatus } from 'pages/services/helpers';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

const OBSERVERS = {
  rds: StateHandlers.rds,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { rds } = props;
  const perms = usePermissions('SERVICES');
  const hostInstances = rds.data?.host?.hostInstances;
  const hostVmId = hostInstances ? hostInstances[0].vm?.id : undefined;
  const displayStatus = defineRdsDisplayStatus(rds.data);

  const ableToInstall = useMemo(
    () => displayStatus === 'POWERED_ON',
    [displayStatus]
  );

  return (
    <AppStoreComponent
      {...perms}
      vmId={hostVmId as number}
      isTenantView
      ableToInstall={ableToInstall}
      isEditable={perms.permissions.canManage}
    />
  );
});

const AppStoreWrapper = () => <View {...OBSERVERS} />;

export default AppStoreWrapper;
