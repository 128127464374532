import {
  IActualTaskState,
  IExtendedActualTask,
  IExtendedTask,
  ITaskAction,
  ITaskCallbacks,
} from 'task-manager-service';

export type ITaskShapeValue = IExtendedActualTask & { isAvailable?: boolean };

interface ITaskShape {
  [field: string | number]: ITaskShapeValue;
}

const defineAction = (operationName: string): ITaskAction => {
  let result: ITaskAction = 'none';
  if (operationName.includes('Create')) result = 'create';
  if (operationName.includes('Delete')) result = 'delete';
  if (operationName.includes('Update')) result = 'update';
  if (operationName.includes('Enable')) result = 'enable';
  if (operationName.includes('Disable')) result = 'disable';
  if (operationName.includes('Setup')) result = 'setup';
  if (operationName.includes('Install')) result = 'install';
  if (operationName.includes('Uninstall')) result = 'uninstall';

  return result;
};

export const reformatTask = (task: Task): IActualTaskState => {
  return {
    ...task,
    isCreating:
      task.status === 'active' && task.operationName.includes('Create'),
    isDeleting:
      task.status === 'active' && task.operationName.includes('Delete'),
    isUpdating:
      task.status === 'active' && task.operationName.includes('Update'),
    isDisabling:
      task.status === 'active' && task.operationName.includes('Disable'),
    isEnabling:
      task.status === 'active' && task.operationName.includes('Enable'),
    isTaskActive: task.status === 'active' || task.status === 'delayed',
    isCompleted: task.status === 'completed',
    isFailed: task.status === 'failed' || !!task.error,
    isCanceled: task.status === 'canceled',
    notStarted: task.status === 'none' && !task.error,
    action: task ? defineAction(task.operationName || '') : 'none',
    exist: !!task,
  };
};

export const reformatTaskKey = (task: Task) => {
  return `${task.entityName.replace(/\s/g, '')}-${task.entityId || task.id}`;
};

export const reformatTaskOptionKey = (task: ITaskOption | Task) => {
  return `${task.entityName.replace(/\s/g, '')}-${task.operationName}`;
};

class TaskSubscriber {
  private tasks: ITaskShape = {};

  public register = (task: IExtendedTask, callbacks?: ITaskCallbacks) => {
    const taskId = reformatTaskKey(task);
    const _task = reformatTask(task);
    const nextCompleteCallback = callbacks?.onComplete;

    const isTaskStarting = ['active', 'delayed'].includes(_task.status);

    const isAbleToRegister = !this.isTaskRegistered(taskId) || isTaskStarting;
    const currentTask = this.tasks[taskId];

    if (isAbleToRegister) {
      this.tasks[taskId] = {
        ..._task,
        onComplete: nextCompleteCallback || currentTask?.onComplete,
      };
    } else {
      currentTask.onComplete = nextCompleteCallback || currentTask.onComplete;
    }

    return this.tasks;
  };

  public updateTask = (task: Task) => {
    const taskId = reformatTaskKey(task);
    const prev: IExtendedActualTask = this.tasks[taskId];
    const next = reformatTask(task);

    const updatedTask: ITaskShapeValue = {
      ...next,
      onComplete: prev.onComplete,
    };

    if (
      prev.isTaskActive &&
      (next.isCanceled || next.isCompleted || next.isFailed)
    ) {
      if (next.isCanceled) {
        setTimeout(
          () =>
            updatedTask.onComplete && updatedTask.onComplete(next.action, next),
          1000
        );
      }

      if (next.isCompleted) {
        updatedTask.onComplete && updatedTask.onComplete(next.action, next);
        this.unsubscribe(taskId);
      }

      if (next.isFailed) {
        updatedTask.onComplete && updatedTask.onComplete(next.action, next);
      }
    } else {
      this.tasks[taskId] = updatedTask;
    }
  };

  public isTaskRegistered = (id: string) => !!this.tasks[id];

  public unsubscribe = (id: string) => {
    delete this.tasks[id];
  };
}

export const taskSubscriber = new TaskSubscriber();
