import { default as axios } from 'axios';
import { apiPrefix } from '../constants';
import { clearClientSession, getCurrentSessionInfo } from '../session';

export async function logOut() {
  await axios.post(`${apiPrefix}/auth/logout`).catch(() => undefined);
  clearClientSession();
  window.location.pathname = '/login';
  return;
}

export async function logOutIfNoSession() {
  const { data: isSessionExist } = await getCurrentSessionInfo();

  if (!isSessionExist) return logOut();
  return false;
}
