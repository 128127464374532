import React from 'react';
import cn from 'classnames';
import { Col, Row } from 'elements';
import { IUser } from 'core-infrastructure';
import { useTask } from 'hooks';
import { AnyFunc } from 'global-shapes';
import { useTranslation } from 'react-i18next';

type Props = IUser & {
  currentPermission: string;
  task?: Task;
  onFetchFSUsers: AnyFunc;
};

const FileShareUser = (props: Props) => {
  const { currentPermission, task, onFetchFSUsers, ...user } = props;
  const { t } = useTranslation();

  useTask(task, { onComplete: onFetchFSUsers });

  return (
    <div key={user.id} className="pt-10 pb-10">
      <Row justifyContent="space-between" alignItems="center">
        <Col>{user.username}</Col>
        <Col className={cn({ 'light-grey': currentPermission === 'none' })}>
          {t(`permissions.options.${currentPermission}`)}
        </Col>
      </Row>
    </div>
  );
};

export default FileShareUser;
