import * as R from 'ramda';
import { TFunction } from 'react-i18next';
import { dayjs, Dayjs, round } from 'utils';
import { HOURS_ARRAY, MINUTES_ARRAY } from 'enums';
import { getLimitSliderPropsByType } from './constants';
import {
  IMonitoringServiceType,
  IMonitoringInstance,
  IMonitoringAlert,
  IChartMetric,
  IChartLimits,
  IMonitoringMetricKeys,
  IMetricKey,
  IChartInstance,
  IChartLimitsFormValues,
  IChartLimitsPayload,
  IMonitoringService,
  IChartLimitsItemPayload,
  IChartLimitEdgeValues,
  IMonitoringLimitValue,
} from 'monitoring';
import {
  IEntityType,
  IChartSeries,
  IPartialChartConfig,
  IMetricFormarter,
  IState,
} from './types';

export function convertTypeToServiceName(
  type: IEntityType
): IMonitoringServiceType {
  if (type === 'core') return 'CoreInfrastructure';
  if (type === 'rds') return 'Rds';
  if (type === 'vm') return 'Vm';
  if (type === 'dedicatedServer') return 'DedicatedServer';
  return 'Vm';
}

export function convertTypeToServiceNameForDialog(type: IEntityType): string {
  if (type === 'core') return 'coreInfrastructure';
  if (type === 'rds') return 'remoteDesktop';
  if (type === 'vm') return 'virtualServer';
  if (type === 'dedicatedServer') return 'dedicatedServer';
  return 'virtualServer';
}

export function getDefaultInstanceValue(instances: IMonitoringInstance[]) {
  return {
    value: instances[0]?.id,
    label: instances[0]?.vm?.name,
  };
}

export function gatherLimitsValues(
  items?: IChartInstance[],
  emails?: string[]
): {
  initialValues: IChartLimitsFormValues;
  edges: IChartLimitEdgeValues;
} {
  const edges: IChartLimitEdgeValues = {};
  const sliderValues = R.reduce<
    IChartInstance,
    Partial<IChartLimitsFormValues>
  >((res, item: IChartInstance) => {
    const formarter = getLimitSliderPropsByType({ t: () => undefined })[
      item.metricKey
    ]?.valueFormatter;
    // @ts-ignore
    res[item.id] = {
      value: item.isPercentValue
        ? item.alertLimitPercentValue
        : round(
            formarter ? formarter(item.alertLimitValue) : item.alertLimitValue,
            2
          ),
      unit:
        item.metricKey === 'cpu_util' || item.isPercentValue
          ? 'percentage'
          : 'values',
    };
    edges[item.id] = item.totalResourceValue;
    return res;
  }, {})(items || []);

  return {
    initialValues: {
      email: '',
      emails: emails || [],
      ...sliderValues,
    } as IChartLimitsFormValues,
    edges,
  };
}

export function getPositiveLimits(limit: Partial<IChartLimits>): IMetricKey[] {
  const positiveLimitKeys = R.reduce<IMetricKey, IMetricKey[]>((res, key) => {
    if (limit[key]) res.push(key);
    return res;
    // @ts-ignore
  }, [])(R.keys<Partial<IChartLimits>>(limit));

  return positiveLimitKeys;
}

export function parseDataToSeries(
  metrics: IChartMetric[],
  isWideDateRange: boolean,
  metricKey: IMetricKey,
  limit: Partial<IChartLimits>,
  t: TFunction,
  chartConfig: IPartialChartConfig
): IChartSeries {
  const limitKeys = getPositiveLimits(limit);
  const xAxis = metrics.map((m) => dayjs.unix(m.t).format('YY-MM-DD HH:mm'));
  const _metrics = metrics.map(({ v, t, ...rest }) => {
    const newItem = { ...rest };
    if (!isWideDateRange) {
      // @ts-ignore
      newItem[metricKey] = v;
    }
    return newItem;
  });

  const dataKeys = R.reduce<IChartMetric, IMonitoringMetricKeys[]>(
    (res, val) => [...res, ...R.keys(val)],
    []
    // @ts-ignore
  )(_metrics);

  const allKeys = R.uniq<IMonitoringMetricKeys | IMetricKey>([
    ...dataKeys,
    ...limitKeys,
  ]);

  const legends = allKeys.map((key) => t(`monitoring.legends.${key}`));

  const series = allKeys.map((key, ind) => {
    const isLimitKey = limitKeys.includes(key as any);
    const name = t(`monitoring.legends.${key as string}`);
    return {
      type: 'line',
      name: name,
      data: _metrics.map((m) => {
        // @ts-ignore
        return isLimitKey ? limit[key] : m[key] || 0;
      }),
      symbol: isLimitKey ? 'none' : 'circle',
      lineStyle: {
        color: chartConfig.colors[ind],
      },
      itemStyle: {
        color: chartConfig.colors[ind],
      },
    };
  });

  return {
    colors: chartConfig.colors,
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      color: chartConfig.colors,
      data: legends,
      orient: 'horizontal',
      bottom: '0%',
    },
    grid: {
      left: '20px',
      right: '10px',
      top: '30px',
      bottom: '30px',
      containLabel: true,
    },
    toolbox: {
      top: '-3px',
      right: '10px',
      feature: {
        dataZoom: {
          show: true,
          icon: {
            zoom: 'path://M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm.5-7H9v2H7v1h2v2h1v-2h2V9h-2z',
            back: 'path://M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z',
          },
          yAxisIndex: 'none',
        },
        restore: {
          show: true,
          icon: 'path://M6 13c0-1.65.67-3.15 1.76-4.24L6.34 7.34C4.9 8.79 4 10.79 4 13c0 4.08 3.05 7.44 7 7.93v-2.02c-2.83-.48-5-2.94-5-5.91zm14 0c0-4.42-3.58-8-8-8-.06 0-.12.01-.18.01l1.09-1.09L11.5 2.5 8 6l3.5 3.5 1.41-1.41-1.08-1.08c.06 0 .12-.01.17-.01 3.31 0 6 2.69 6 6 0 2.97-2.17 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93z',
        },
        saveAsImage: {
          icon: 'path://M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z',
        },
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxis,
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      min: chartConfig.yAxis.min,
      max: chartConfig.yAxis.max,
    },
    series,
  };
}

function convertToUnit(val: number, measureUnit?: string): number {
  switch (measureUnit) {
    case 'bps':
      return val / 1024 / 1024;
    default:
      return val;
  }
}

export function parseChartMetricToFormat(
  data: IChartMetric[],
  measureUnit?: string,
  formarter?: IMetricFormarter
): IChartMetric[] {
  return data.map(({ t, ...rest }) => {
    return {
      t,
      ...R.reduce((res: any, key: any) => {
        // @ts-ignore
        const unit = convertToUnit(rest[key], measureUnit);
        res[key] = formarter ? formarter(unit) : unit;
        return res;
      }, {})(R.keys(rest)),
    };
  });
}

export function parseLimitsPayloadFromForm({
  emails,
  ...metricItemsObj
}: IMonitoringAlert &
  Record<string | number, IMonitoringLimitValue>): IChartLimitsPayload {
  const metricItems: IChartLimitsItemPayload[] = R.keys(metricItemsObj).map(
    (key) => {
      return {
        itemId: key,
        value: metricItemsObj[key]?.value || 0,
        isPercentValue: metricItemsObj[key]?.unit === 'percentage',
      };
    }
  );
  return {
    metricItems,
    emails,
  };
}

export function reformatLimits(
  limits: Record<string, number | null>,
  max: number,
  reformatter?: any
): Record<string, number | null> {
  return R.reduce<string, Record<string, number | null>>((res, key: string) => {
    const limit = limits[key] || 0;
    const value = limit ? (limit <= max ? limit : null) : null;
    if (reformatter) {
      res[key] = reformatter(limits[key]);
    }
    res[key] = value;
    return res;
  }, {})(R.keys(limits));
}

export function resetTimeValue(startDate: string | Dayjs): Dayjs {
  return dayjs(startDate).set('seconds', 0);
}

export function findDisabledHours(
  inceptionDate: Dayjs,
  endDate: Dayjs,
  direction: 'after' | 'before'
): () => number[] {
  return () => {
    const inception = resetTimeValue(inceptionDate);
    const end = resetTimeValue(endDate);

    return R.filter<number>((hour) =>
      direction === 'after'
        ? +dayjs(inception).set('hours', hour) < +end
        : +dayjs(inception).set('hours', hour) > +end
    )(HOURS_ARRAY);
  };
}

export function findDisabledMinutes(
  inceptionDate: Dayjs,
  endDate: Dayjs,
  direction: 'after' | 'before'
): (hour: number) => number[] {
  return (_selectedHour) => {
    const inception = resetTimeValue(inceptionDate);

    const startForMinutes = _selectedHour
      ? dayjs(inception).set('hours', _selectedHour)
      : dayjs(inception);

    const disabledMinutes = R.filter<number>((minute) => {
      const end = resetTimeValue(endDate);
      return direction === 'after'
        ? +startForMinutes.set('minutes', minute) < +end
        : +startForMinutes.set('minutes', minute) > +end;
    })(MINUTES_ARRAY);

    return disabledMinutes;
  };
}

export function defineInitialTab(
  monitoring?: IMonitoringService,
  currentTab?: IState['tab'],
  osType?: IOsType
): IState['tab'] {
  if (osType === 'lin') return 'charts';
  if (
    !monitoring?.chartsEnabled &&
    monitoring?.servicesEnabled &&
    currentTab === 'charts'
  ) {
    return 'services';
  }

  if (monitoring?.chartsEnabled && !monitoring?.servicesEnabled) {
    return 'charts';
  }

  return currentTab;
}
