import { ILanguage } from 'global-shapes';
import * as Yup from 'yup';
import { takeLast } from 'ramda';
import { validator } from 'utils';
import { DEFAULT_COUNTRY, LANGUAGES } from 'enums';

type ILinksShape = Record<
  string,
  {
    terms: string;
    policy: string;
  }
>;

export const COMPANY_INITIAL_FORM_VALUES = {
  companyName: '',
  shortName: '',
  zipCode: '',
  line1: '',
  line2: '',
  city: '',
  countryCode: DEFAULT_COUNTRY,
  cameFrom: undefined,
  lang: LANGUAGES[1],
};

export const USER_INITIAL_FORM_VALUES = {
  email: '',
  firstName: '',
  lastName: '',
  captcha: undefined,
};

export type IUserFormValues = typeof USER_INITIAL_FORM_VALUES;
export type ICompanyFormValues = {
  name?: string;
  companyName: string;
  shortName: string;
  addressZipCode?: string;
  line1?: string;
  line2?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  countryCode?: string;
  cameFrom?: undefined;
  lang?: ILanguage;
};

export const restrictedDomain = 'awesome.cloud';

export const privacyLinks: ILinksShape = {
  de: {
    terms: 'https://awesome.cloud/terms-of-service-de',
    policy: 'https://awesome.cloud/privacy-policy-de',
  },
  en: {
    terms: 'https://awesome.cloud/terms-of-service-en',
    policy: 'https://awesome.cloud/privacy-policy-en',
  },
};

export const emailValidationSchema = Yup.string()
  .required('forms.required')
  .matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'forms.emailInvalid'
  )
  .test('email', 'forms.emailInvalid', (val) => {
    const domain = takeLast(1, (val || '').split('@'))[0] || '';
    return !domain.includes(restrictedDomain);
  });

export const captchaValidationSchema = Yup.string()
  .trim()
  .required('forms.required');

export const firstNameValidationSchema = Yup.string()
  .trim()
  .required('forms.required')
  .max(30, 'forms.invalid.max')
  .test({ message: 'forms.invalid', test: (val) => validator.name(val || '') });

export const lastNameValidationSchema = Yup.string()
  .trim()
  .required('forms.required')
  .max(30, 'forms.invalid.max')
  .test({ message: 'forms.invalid', test: (val) => validator.name(val || '') });

export const userValidationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('forms.required'),
  lastName: Yup.string().trim().required('forms.required'),
  email: emailValidationSchema,
});

export const companyValidationSchema = Yup.object().shape({
  companyName: Yup.string()
    .trim()
    .required('forms.required')
    .max(30, 'forms.invalid.max'),
  shortName: Yup.string()
    .trim()
    .min(3, 'forms.invalid.min')
    .max(16, 'forms.invalid.max')
    .required('forms.required')
    .test('shortName', 'forms.invalid.onlyLettersAndNums', (val) =>
      validator.onlyLettersAndNum(val || '')
    ),
  cameFrom: Yup.object().required('forms.required'),
});

const mixedObjectStringValidationType = Yup.lazy((value) => {
  switch (typeof value) {
    case 'object':
      return Yup.object().required('forms.required');
    case 'string':
      return Yup.string().required('forms.required');
    default:
      return Yup.mixed();
  }
});

export const customerCompanyValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required('forms.required')
    .max(30, 'forms.invalid.max'),
  shortName: Yup.string()
    .trim()
    .min(3, 'forms.invalid.min')
    .max(16, 'forms.invalid.max')
    .required('forms.required')
    .test('shortName', 'forms.invalid.onlyLettersAndNums', (val) =>
      validator.onlyLettersAndNum(val || '')
    ),
  line1: Yup.string()
    .trim()
    .required('forms.required')
    .max(60, 'forms.invalid.max'),
  line2: Yup.string().trim().max(60, 'forms.invalid.max'),
  zipCode: Yup.string().trim().required('forms.required'),
  city: Yup.string().trim().required('forms.required'),
  countryCode: mixedObjectStringValidationType,
});

export const knowUsOptions = [
  'personalRecommendation',
  'linkedin',
  'google',
  'facebook',
  'youtube',
  'other',
].map((value) => ({
  value,
  label: `auth.registration.cameFrom.options.${value}`,
}));
