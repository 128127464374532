import * as React from 'react';
import { DialogProps, Dialog, Button, Input, Row, Col, Loader } from 'elements';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { DEFAULT_QUOTAS } from 'enums';
import { useStateHandler, usePermissions } from 'hooks';
import { useFormik } from 'formik';
import { showSystemMessage, round } from 'utils';
import { quotasValidationSchema } from './constants';

type IProps = React.PropsWithChildren<
  DialogProps & {
    tenantId: number;
  }
>;

const QuotasDialog = observer((props: IProps) => {
  const { open, onClose, tenantId } = props;
  const { t } = useTranslation();
  const { isEvaluation } = usePermissions();
  const tenantQuota = useStateHandler(StateHandlers.tenantQuota);

  const isRequesting = tenantQuota.isRequesting;

  const onSave = React.useCallback(
    async (data: IQuotaTypes.IQuotaFormValues) => {
      await tenantQuota.update(tenantId, {
        virtualCpus: data.cpu,
        ramMb: round(+data.ram * 1024, 0),
        diskSizeGb: data.diskSize,
      });
    },
    [tenantId]
  );

  const quotaPermNamespace = isEvaluation ? 'eval' : 'prod';

  const initialValues: IQuotaTypes.IQuotaFormValues = {
    cpu: DEFAULT_QUOTAS[quotaPermNamespace].virtualCpus,
    ram: DEFAULT_QUOTAS[quotaPermNamespace].ramMb,
    diskSize: DEFAULT_QUOTAS[quotaPermNamespace].diskSizeGb,
  };

  const {
    values,
    handleChange,
    setValues,
    resetForm,
    handleSubmit,
    isSubmitting,
    submitCount,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: quotasValidationSchema,
    onSubmit: (data: IQuotaTypes.IQuotaFormValues) =>
      onSave(data)
        .then(() => {
          showSystemMessage('tenants.notify.quotes.update.success', 'success');
          onClose();
        })
        .catch((err) => showSystemMessage(err.message, 'error')),
  });

  const fetchData = React.useCallback(async () => {
    const quotas = await tenantQuota.get({ id: tenantId });
    const cpu =
      quotas?.quota?.virtualCpus ||
      DEFAULT_QUOTAS[quotaPermNamespace].virtualCpus;
    const ram =
      (quotas?.quota?.ramMb || 0) / 1024 ||
      DEFAULT_QUOTAS[quotaPermNamespace].ramMb;
    const diskSize =
      quotas?.quota?.diskSizeGb ||
      DEFAULT_QUOTAS[quotaPermNamespace].diskSizeGb;
    return {
      cpu,
      ram,
      diskSize,
    };
  }, [tenantId, quotaPermNamespace]);

  React.useEffect(() => {
    if (open) {
      fetchData().then((res) => {
        setValues(res as any);
      });
    }

    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      handleSubmit={handleSubmit}
      title={t('tenants.dialog.quotas.title')}
      actions={
        <>
          <Button variant="outlined" color="default" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isSubmitting || isRequesting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      {isRequesting ? (
        <Loader />
      ) : (
        <>
          <div className="fs-14 uppercase bolder mb-10">
            {t('tenants.dialog.quotas.content.sections.resources') as string}
          </div>
          <div className="mb-10">
            {t('tenants.dialog.quotas.content.sections.currentQuotas')}
          </div>
          <div className="mb-20">
            <div className="mb-5">
              {t('tenants.dialog.quotas.content.forms.head.cpu')}:{' '}
              <span className="steel">
                {tenantQuota.data?.usedResources?.virtualCpus}
              </span>
            </div>
            <div className="mb-5">
              {t('tenants.dialog.quotas.content.forms.head.ram')}:{' '}
              <span className="steel">
                {tenantQuota.data?.usedResources?.ramMb / 1024}
              </span>
            </div>
            <div>
              {t('tenants.dialog.quotas.content.forms.head.diskSize')}:{' '}
              <span className="steel">
                {tenantQuota.data?.usedResources?.diskSizeGb}
              </span>
            </div>
          </div>
          <div className="steel fs-14 mb-20">
            {t('tenants.dialog.quotas.content.description') as string}
          </div>
          <Row columnSpacing={2}>
            <Col xs={4}>
              <Input
                label={t('tenants.dialog.quotas.content.forms.head.cpu')}
                onChange={handleChange}
                value={values.cpu}
                name="cpu"
                type="number"
                error={!!submitCount && !!errors.cpu}
                helperText={errors.cpu}
                helperTextOptions={{
                  max: 999999,
                  min: 1,
                }}
              />
            </Col>
            <Col xs={4}>
              <Input
                label={t('tenants.dialog.quotas.content.forms.head.ram')}
                onChange={handleChange}
                value={values.ram}
                name="ram"
                type="number"
                error={!!submitCount && !!errors.ram}
                helperText={errors.ram}
                helperTextOptions={{
                  max: 999999,
                  min: 1,
                }}
              />
            </Col>
            <Col xs={4}>
              <Input
                label={t('tenants.dialog.quotas.content.forms.head.diskSize')}
                onChange={handleChange}
                value={values.diskSize}
                name="diskSize"
                type="number"
                error={!!submitCount && !!errors.diskSize}
                helperText={errors.diskSize}
                helperTextOptions={{
                  max: 9999999,
                  min: 1,
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </Dialog>
  );
});

export default QuotasDialog;
