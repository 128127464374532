import { stringify, StringifyOptions } from 'query-string';
import { AccessLevel } from 'global-shapes';
import { ellipseStr, utcDayjs } from 'utils';
import {
  DEFAULT_CUSTOMERS_QUERY,
  DEFAULT_USERS_PARAMS,
  DEFAULT_INVOICES_PARAMS,
  defaultDnsZonesQuery,
  defaultNavQuery,
  APP_STORE_DEFAULT_PARAMS,
  DEFAULT_FILE_SHARE_QUERY,
  RDS_ACCOUNTS_DEFAULT_PARAMS,
  VM_DISKS_DEFAULT_PARAMS,
  DEFAULT_BILLABLE_ITEMS_PARAMS,
} from 'enums';
import { FILTER_OPTIONS, FILTER_OPTIONS_SORTED } from './constants';

const INVOICE_URL_PARAMS = {
  ...DEFAULT_INVOICES_PARAMS,
  status: [
    'PAID',
    'UNPAID',
    'PARTIALLY_PAID',
    'OVERDUE',
    'OVERPAID',
    'CANCELED',
  ],
};

const biNameTrim = ellipseStr(30);

const stringifyOptions: StringifyOptions = { arrayFormat: 'bracket' };

export const parseNameForAvatar = (name: string) => {
  const parts = name.split(' ');

  return `${parts[0][0]}${parts[1][0]}`.toUpperCase();
};

export function groupEntities(
  entities: ISearchEntity.RequestResponse['aggregation'],
  accessLevel: AccessLevel
): ISearchEntity.EntityState {
  const res: Partial<ISearchEntity.EntityState> = {};
  FILTER_OPTIONS_SORTED[accessLevel].forEach((key) => {
    res[key] = entities[key];
  });

  return res as ISearchEntity.EntityState;
}

export function generateEntityLink(entity: ISearchEntity.EntityDto): {
  name: string;
  link: string;
} {
  let base: string;
  let link: string;
  let name = '';

  switch (entity.accessLevel) {
    case 'PROVIDER':
      base = '';
      name = '';
      break;
    case 'PARTNER':
      base = `/${entity.partnerShortName}`;
      name = entity.partnerName ? entity.partnerName : '';
      break;
    case 'TENANT':
      base = `/${entity.partnerShortName}/${entity.tenantShortName}`;
      name = entity.tenantName ? entity.tenantName : '';
      break;
    default:
      base = '';
  }

  switch (entity.entity) {
    case 'AppUser':
      link = `${base}/users?${stringify(
        DEFAULT_USERS_PARAMS,
        stringifyOptions
      )}`;
      break;
    case 'CoreInfrastructure':
      link = `${base}/services/infrastructure/accounts?${stringify(
        defaultNavQuery
      )}`;
      break;
    case 'CoreInfrastructureUser':
      link = `${base}/services/infrastructure/${
        entity.otherAttributes.type === 'ADMIN' ? 'admins' : 'accounts'
      }?${stringify(defaultNavQuery, stringifyOptions)}`;
      name = name + ` ${entity.otherAttributes.domainName}`;
      break;
    case 'FileShare':
      link = `${base}/services/infrastructure/shares?${stringify(
        DEFAULT_FILE_SHARE_QUERY,
        stringifyOptions
      )}`;
      name = name + ` ${entity.otherAttributes.domainName}`;
      break;
    case 'FileShareUser':
      link = `${base}/services/infrastructure/shares?${stringify(
        DEFAULT_FILE_SHARE_QUERY,
        stringifyOptions
      )}`;
      name = name + ` ${entity.otherAttributes.domainName}`;
      break;
    case 'FileShareMigrationEndpoint':
      link = `${base}/services/infrastructure/shares?${stringify(
        DEFAULT_FILE_SHARE_QUERY,
        stringifyOptions
      )}`;
      name = name + ` ${entity.otherAttributes.domainName}`;
      break;
    case 'Partner':
      link = `/${entity.partnerShortName}/tenants?${stringify(
        DEFAULT_CUSTOMERS_QUERY,
        stringifyOptions
      )}`;
      break;
    case 'Tenant':
      link = `/${entity.partnerShortName}/${entity.tenantShortName}/services/all`;
      break;
    case 'Connectivity':
      link = `${base}/services/connectivity/edges`;
      break;
    case 'IpsecVpnTunnel':
      link = `${base}/services/connectivity/edges`;
      break;
    case 'Vm':
      link = `${base}/services/vm/info?id=${entity.entityId}`;
      break;
    case 'DedicatedServer':
      link = `${base}/services/dedicated/info?id=${entity.otherAttributes?.vmId}`;
      break;
    case 'VmDisk':
      link = `${base}/services/vm/disks?${stringify(
        VM_DISKS_DEFAULT_PARAMS,
        stringifyOptions
      )}`;
      break;
    case 'Rds':
      link = `${base}/services/rds/accounts?${stringify(
        RDS_ACCOUNTS_DEFAULT_PARAMS,
        stringifyOptions
      )}`;
      break;
    case 'RdsUser':
      link = `${base}/services/rds/accounts?${stringify(
        RDS_ACCOUNTS_DEFAULT_PARAMS,
        stringifyOptions
      )}`;
      break;
    case 'RdsHost':
      link = `${base}/services/rds/accounts?${stringify(
        RDS_ACCOUNTS_DEFAULT_PARAMS,
        stringifyOptions
      )}`;
      break;
    case 'RdsGateway':
      link = `${base}/services/rds/accounts?${stringify(
        RDS_ACCOUNTS_DEFAULT_PARAMS,
        stringifyOptions
      )}`;
      break;
    case 'VmSoftwarePackage':
      link = `${base}/services/rds/apps?${stringify(
        APP_STORE_DEFAULT_PARAMS,
        stringifyOptions
      )}`;
      break;
    case 'Dns':
      link = `${base}/services/dns/domains?${stringify(
        defaultDnsZonesQuery,
        stringifyOptions
      )}`;
      break;
    case 'DnsZone':
      link = `${base}/services/dns/domains?${stringify(
        defaultDnsZonesQuery,
        stringifyOptions
      )}`;
      break;
    case 'Invoice':
      link = `${base}/invoices?${stringify(
        {
          ...INVOICE_URL_PARAMS,
          q: entity.name,
          tab: 'base',
          selected: undefined,
        },
        stringifyOptions
      )}`;
      name = entity.tenantName || entity.partnerName || '';
      break;
    case 'BillableItem':
      link = `${base}/billable-items?${stringify(
        {
          ...DEFAULT_BILLABLE_ITEMS_PARAMS,
          dateFrom: utcDayjs('01.01.2020', 'DD.MM.YYYY')
            .startOf('day')
            .toISOString(),
          dateTo: utcDayjs().add(2, 'year').endOf('day').toISOString(),
          id: entity.entityId,
        },
        stringifyOptions
      )}`;
      name = entity.tenantName || entity.partnerName || '';
      break;
    default:
      link = '';
  }

  if (link) {
    name = `${biNameTrim(entity.name)} ${name ? `(${name})` : ''}`;
  }

  return { link, name };
}

export function flattfyEntries(
  entries: ISearchEntity.FilterType[],
  rootAccessLevel: AccessLevel
): ISearchEntity.Entity[] {
  let flattenEntries: ISearchEntity.Entity[] = [];

  entries.forEach((entry) => {
    flattenEntries = [
      ...flattenEntries,
      // @ts-ignore
      ...FILTER_OPTIONS[rootAccessLevel][entry],
    ];
  });

  return flattenEntries;
}

export function shouldBeFetched(
  query: ISearchEntity.Query & Partial<ISearchEntity.TableParams>
) {
  return !(query.q && query.q.trim()) && query.entity;
}
