import * as React from 'react';
import { styled, Row, Accordion, Col, Button, MuiIcons } from 'elements';
import { remCalc, important } from 'utils';
import { PALETTE } from 'enums';
import { TABLE_LAYOUT_STYLES } from './constants';

const LINE_COLOR = '#f9f9f9';

export const SAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: '0 1px 1px 0 rgba(23, 29, 41, 0.16)',
  borderRadius: remCalc(5),
  '&:before': {
    display: 'none',
  },
}));

export const SPaymentRow = styled(Row)(({ theme }) => ({
  borderLeft: `1px solid ${LINE_COLOR}`,
  borderRight: `1px solid ${LINE_COLOR}`,
  borderTop: `1px solid ${LINE_COLOR}`,
  '&:last-of-type': {
    borderBottom: `1px solid ${LINE_COLOR}`,
  },
  '&._noBorder': {
    borderColor: important('transparent'),
  },
}));

export const SPaymentEntryItem = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  overflow: 'hidden',
  lineHeight: remCalc(43),
  '&:hover': {
    backgroundColor: LINE_COLOR,
  },
}));

export const MainPage = styled(Row)(({ theme }) => ({
  minWidth: remCalc(1450),
}));

export const SStatusSelect = styled(Col)(({ theme }) => ({
  maxWidth: remCalc(320),
  width: '100%',
}));

export const ClearButton = styled(Button)(({ theme }) => ({
  height: remCalc(48),
}));

export const TableTotalBlock = styled('div')(
  () => TABLE_LAYOUT_STYLES.tableTotalBlock
);

export const ExpandIcon = styled((props: any & { expanded: boolean }) => {
  const { expanded, ...rest } = props;
  const Icon = props.expanded ? MuiIcons.ExpandLess : MuiIcons.ExpandMore;
  return <Icon {...rest} />;
})(({ theme }) => ({
  backgroundColor: PALETTE.paleGrey,
  color: PALETTE.primary,
  width: remCalc(24),
  height: remCalc(24),
  lineHeight: remCalc(24),
  textAlign: 'center',
  borderRadius: remCalc(3),
  transition: theme.transitions.create('background-color', {
    easing: 'ease-in-out',
    duration: 150,
    delay: 0,
  }),
  '&:hover': {
    backgroundColor: PALETTE.info,
  },
}));
