import * as React from 'react';
import { Patching as PatchingBase } from 'components';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { PatchingHolder } from './Styled';

const OBSERVERS = {
  rds: StateHandlers.rds,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { rds } = props;
  return (
    <PatchingHolder className="p-30">
      <PatchingBase serviceType="Rds" fetchBaseService={() => rds.get()} />
    </PatchingHolder>
  );
});

const Patching = () => <View {...OBSERVERS} />;

export default Patching;
