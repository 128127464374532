import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { AnyFunc } from 'global-shapes';
import { isEmpty, sum } from 'ramda';
import { backupNotificationNamespaces, backupNamespaces } from 'enums';
import { remCalc } from 'utils';
import { Button, Row, Col, Alert, Switch, Tooltip } from 'elements';
import { usePermissions, useState, useVmBillingDisplay } from 'hooks';
import { StepperArgs } from '../../services/components/Stepper';
import ServerDetailsForm from './ServerDetailsForm';
import AdditionalDisksForm from './AdditionalDisks';
import PublicServicesForm from './PublicServices';
import CostInfo from './CostInfo';
import BackupPlanForm from 'components/Backup/PlanForm';
import BackupNotificationsForm from 'components/Backup/NotificationsForm';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import {
  INITIAL_VM_FORM_VALUES,
  VM_INFO_VALIDATION_SCHEMAS,
  FormSteps,
} from './constants';
import { parsePayload, validate } from './helpers';
import {
  CreateVmDialog,
  LinearStepper,
  StepperContent,
  FormSwitcher,
  InfoLabelSup,
} from './Styled';

type IProps = {
  open: boolean;
  isSubmitting?: boolean;
  connectivitySetupCompleted?: boolean;
  onSave: AnyFunc;
  onClose: AnyFunc;
};

type IState = {
  activeStep: number;
  submitCount: number;
  isPublic: boolean;
};

const INITIAL_STATE: IState = {
  activeStep: 0,
  submitCount: 0,
  isPublic: false,
};

const GENERAL_VM_STEPS = [
  'services.dialog.vms.step.1',
  'services.dialog.vms.step.2',
  'services.dialog.vms.step.3',
  'services.dialog.vms.step.4',
];

const PUBLIC_VM_STEPS = [
  'services.dialog.vms.public.step.1',
  'services.dialog.vms.public.step.2',
  'services.dialog.vms.public.step.3',
];

const OBSERVERS = {
  tenantPricing: StateHandlers.tenantPricing,
  costBreakdown: StateHandlers.costBreakdown,
  core: StateHandlers.core,
};

type IViewProps = typeof OBSERVERS;

const Content = (props: any) => {
  const { t } = useTranslation();
  const {
    open,
    activeStep,
    values,
    handleChange,
    errors,
    setFieldValue,
    setFieldError,
    isSubmitting,
    isValidating,
    touched,
    onSubmit,
    setValues,
    submitCount,
    connectivitySetupCompleted,
  } = props;
  if (open) {
    if (activeStep === 0) {
      return (
        <>
          <div className={cn('mb-20')}>
            <FormSwitcher
              label={
                <div className="flex align-center ml-10">
                  {t('services.dialog.vms.switchers.publicVm')}
                  <Tooltip
                    placement="top"
                    title={t('services.dialog.vms.switchers.publicVm.tooltip')}
                  >
                    <sup className="ml-5">
                      <InfoLabelSup />
                    </sup>
                  </Tooltip>
                </div>
              }
              disabled={!connectivitySetupCompleted}
              checked={values.isPublic}
              onCheck={(isPublic: boolean) =>
                setFieldValue('isPublic', isPublic)
              }
            />
          </div>
          {/* @ts-ignore */}
          <ServerDetailsForm
            isNew
            values={values}
            errors={errors}
            handleChange={handleChange}
            submitCount={submitCount}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            setValues={setValues}
            onSubmit={onSubmit}
          />
        </>
      );
    }

    if (activeStep === 1) {
      return (
        <AdditionalDisksForm
          disks={values.disks}
          hasSnapshot={false}
          hasBilling={true}
          onAdd={(disks: IVmTypes.VMDisk[]) => setFieldValue('disks', disks)}
          onDelete={(disks: IVmTypes.VMDisk[]) => setFieldValue('disks', disks)}
          query={{}}
          onPageChange={() => undefined}
          costBreakdown={undefined}
        />
      );
    }

    const isSelectedSomePlan = backupNamespaces.some((plan) => !!values[plan]);

    const isSelectedSomeNotification = backupNotificationNamespaces.some(
      (n) => !!values[n]
    );

    if (activeStep === 2) {
      const hasIsolatedNetwork = values.networkId?.type === 'ISOLATED';
      return values.isPublic || hasIsolatedNetwork ? (
        <>
          <div className={cn('mb-20')}>
            <FormSwitcher
              label={
                <div className="flex align-center ml-10">
                  {t('services.dialog.vms.switchers.enableBuckup')}
                </div>
              }
              checked={values.isBackupEnabled}
              onCheck={(isBackupEnabled: boolean) =>
                setFieldValue('isBackupEnabled', isBackupEnabled)
              }
            />
          </div>
          <BackupPlanForm
            isDisabled={!values.isBackupEnabled}
            values={values}
            errors={errors}
            handleChange={(value, key) => setFieldValue(key, value)}
            noBackupAlertColor="info"
          />
          {values.isBackupEnabled && !errors.isBackupEnabled && (
            <>
              {/* @ts-ignore */}
              <BackupNotificationsForm
                values={values}
                errors={errors}
                handleChange={handleChange}
                disabled={!values.isBackupEnabled}
                setFieldValue={setFieldValue}
                submitCount={submitCount}
                setFieldError={setFieldError}
                setValues={setValues}
                isSubmitting={isSubmitting}
                touched={touched}
                isValidating={isValidating}
              />
              {!!errors.backupEmails && (
                <Alert severity="error">{t(errors.backupEmails)}</Alert>
              )}
              {isSelectedSomePlan &&
                !isSelectedSomeNotification &&
                !!values.backupEmails?.length && (
                  <Alert severity="info">
                    {t(
                      'services.backup.notifications.empty.withEmails.alert.title'
                    )}
                  </Alert>
                )}
            </>
          )}
        </>
      ) : (
        <PublicServicesForm
          ports={values.ports}
          onAdd={(ports) => setFieldValue('ports', ports)}
          onDelete={(ports) => setFieldValue('ports', ports)}
        />
      );
    }

    if (activeStep === 3) {
      return (
        <>
          <div className={cn('mb-20')}>
            <FormSwitcher
              label={
                <div className="flex align-center ml-10">
                  {t('services.dialog.vms.switchers.enableBuckup')}
                </div>
              }
              checked={values.isBackupEnabled}
              onCheck={(isBackupEnabled: boolean) =>
                setFieldValue('isBackupEnabled', isBackupEnabled)
              }
            />
          </div>
          <BackupPlanForm
            isDisabled={!values.isBackupEnabled}
            values={values}
            errors={errors}
            handleChange={(value, key) => setFieldValue(key, value)}
            noBackupAlertColor="info"
          />
          {values.isBackupEnabled && !errors.isBackupEnabled && (
            <>
              {/* @ts-ignore */}
              <BackupNotificationsForm
                values={values}
                errors={errors}
                handleChange={handleChange}
                disabled={!values.isBackupEnabled}
                setFieldValue={setFieldValue}
                submitCount={submitCount}
                setFieldError={setFieldError}
                setValues={setValues}
                isSubmitting={isSubmitting}
                touched={touched}
                isValidating={isValidating}
              />
              {!!errors.backupEmails && (
                <Alert severity="error">{t(errors.backupEmails)}</Alert>
              )}
              {isSelectedSomePlan &&
                !isSelectedSomeNotification &&
                !!values.backupEmails?.length && (
                  <Alert severity="info">
                    {t(
                      'services.backup.notifications.empty.withEmails.alert.title'
                    )}
                  </Alert>
                )}
            </>
          )}
        </>
      );
    }

    return null;
  }
  return null;
};

const View = observer((props: IProps & IViewProps) => {
  const {
    onClose,
    onSave,
    connectivitySetupCompleted,
    isSubmitting,
    tenantPricing,
    costBreakdown,
    core,
    ...rest
  } = props;

  const { isEvaluation, isProvider } = usePermissions();
  const isRootProvider = isProvider;
  const { t } = useTranslation();
  const [state, handleStateChange] = useState<IState>(INITIAL_STATE);

  const validationSchema = React.useMemo(() => {
    if (state.activeStep === 0) {
      return VM_INFO_VALIDATION_SCHEMAS[0](
        costBreakdown.data,
        true,
        false,
        INITIAL_VM_FORM_VALUES
      );
    }

    if (state.isPublic && state.activeStep === 2) {
      return VM_INFO_VALIDATION_SCHEMAS[FormSteps.backup];
    }

    return VM_INFO_VALIDATION_SCHEMAS[state.activeStep];
  }, [state.activeStep, JSON.stringify(costBreakdown.data), state.isPublic]);

  const isCoreRunning = core.data?.displayStatus === 'POWERED_ON';

  const validation = React.useMemo(() => {
    return validate(validationSchema);
  }, [state.activeStep]);

  const formConfig = useFormik({
    initialValues: {
      ...INITIAL_VM_FORM_VALUES,
      isPublic: !connectivitySetupCompleted,
    } as VmDialogTypes.IVmFormValues,
    validateOnMount: false,
    validate: validation,
    onSubmit: async (values) =>
      onSave(
        parsePayload(
          values as any,
          isRootProvider,
          isCoreRunning ? core.data.id : undefined
        )
      ),
  });

  const hasIsolatedNetwork = formConfig.values.networkId?.type === 'ISOLATED';

  const { showCostInfo, showEnableSwitcher } = useVmBillingDisplay({
    billingInterval: formConfig.values.billingCycleInterval?.value,
    isRootProvider,
    isUsageEnabled: formConfig.values.isUsageEnabled,
    isNew: true,
  });

  const usedStorage = useMemo(() => {
    const base = formConfig.values.osDiskSizeGb || 0;
    const disks = sum(
      (formConfig.values.disks || []).map((d: any) => d.sizeGb)
    );
    const total = sum([disks, base]);

    return {
      gb: {
        base,
        disks,
        total,
      },
    };
  }, [formConfig.values.disks, state]);

  const isOverusedStorage =
    usedStorage.gb.total > costBreakdown.data?.remaining.diskSizeGb;

  React.useEffect(() => {
    if (!props.open) {
      formConfig.setErrors({});
      formConfig.resetForm();
      handleStateChange({ activeStep: 0 });
    }
  }, [props.open]);

  React.useEffect(() => {
    if (formConfig.values.isPublic !== state.isPublic) {
      handleStateChange({ isPublic: formConfig.values.isPublic });
    }
  }, [formConfig.values.isPublic, state.isPublic]);

  const handleNextAndSubmit = useCallback(
    ({ isLastStep, handleNext }: StepperArgs) =>
      async () => {
        if (!isLastStep) {
          return formConfig.validateForm().then((err) => {
            const hasError = isEmpty(err);
            formConfig.setErrors(err);
            formConfig.setSubmitting(false);

            if (hasError) {
              handleNext();
              handleStateChange({ submitCount: 0 });
            } else {
              handleStateChange({ submitCount: state.submitCount + 1 });
            }
          });
        }

        formConfig.setSubmitting(false);

        if (isOverusedStorage) return false;

        if (isLastStep) {
          return formConfig.submitForm();
        }
      },
    [
      JSON.stringify(formConfig.values),
      isOverusedStorage,
      state.submitCount,
      JSON.stringify(formConfig.errors),
    ]
  );

  return (
    <CreateVmDialog
      {...rest}
      classes={{ paper: '_dialog-paper' }}
      title={t('services.dialog.vms.title')}
      fullWidth
      maxWidth="md"
      contentProps={{ classes: { root: 'pb-0 overflow-visible' } }}
      onClose={onClose}
      keepMounted={false}
    >
      <LinearStepper
        steps={
          formConfig.values.isPublic || hasIsolatedNetwork
            ? PUBLIC_VM_STEPS
            : GENERAL_VM_STEPS
        }
        setActiveStep={(step: number) => handleStateChange(step, 'activeStep')}
        activeStep={state.activeStep}
      >
        {(stepperProps: StepperArgs) => {
          const { handleBack, isLastStep, isFirstStep } = stepperProps;
          return (
            <Row direction="column" justifyContent="space-between">
              <StepperContent>
                <Row columnSpacing={4} style={{ minHeight: remCalc(800) }}>
                  <Col xs={7}>
                    <Content
                      {...formConfig}
                      open={props.open}
                      activeStep={state.activeStep}
                      submitCount={state.submitCount}
                      connectivitySetupCompleted={connectivitySetupCompleted}
                    />
                    {isOverusedStorage && state.activeStep === 1 && (
                      <Alert severity="error">
                        {t('costBreakdown.storage.limitReached')}
                      </Alert>
                    )}
                  </Col>
                  <Col xs={5} justifyItems="stretch">
                    <Row direction="column" style={{ height: '100%' }}>
                      {showEnableSwitcher && (
                        <Col>
                          <div className="mb-5">
                            <Switch
                              label={t('costsInfo.billing.enable.label')}
                              checked={formConfig.values.isUsageEnabled}
                              onCheck={(isUsageEnabled) =>
                                formConfig.setFieldValue(
                                  'isUsageEnabled',
                                  isUsageEnabled
                                )
                              }
                            />
                          </div>
                          <div className="fs-12 steel mb-15">
                            {t('costsInfo.billing.enable.subtitle')}
                          </div>
                        </Col>
                      )}
                      {showCostInfo && (
                        <Col xs>
                          {/* @ts-ignore */}
                          <CostInfo
                            {...formConfig}
                            isEvaluation={isEvaluation}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </StepperContent>
              <Col>
                <Row alignItems="center" justifyContent="space-between">
                  <Button color="default" variant="outlined" onClick={onClose}>
                    {t('common.cancel')}
                  </Button>
                  <div>
                    <Row columnSpacing={2}>
                      {!isFirstStep && (
                        <Col>
                          <Button onClick={handleBack}>
                            {t('common.previous')}
                          </Button>
                        </Col>
                      )}
                      <Col>
                        <Button
                          onClick={handleNextAndSubmit(stepperProps)}
                          disabled={isSubmitting || formConfig.isSubmitting}
                        >
                          {t(isLastStep ? 'common.finish' : 'common.next')}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </Col>
            </Row>
          );
        }}
      </LinearStepper>
    </CreateVmDialog>
  );
});

const CreateVirtualMachineDialog = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default CreateVirtualMachineDialog;
