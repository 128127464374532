import {
  MuiIcons,
  IconSnapshot,
  IconRemoteSupport,
  IconBackup,
  IconSecondaryIp,
  IconPublicServices,
  IconConnectDomain,
} from 'elements';
import { REGEX } from 'enums';
import * as Yup from 'yup';
import { BILLING_CIRCLE_DS_OPTIONS } from '../services/constants';
import * as Types from './types';

export const FILTERS_STATUS_OPTIONS = [
  'POWERED_ON',
  // 'PROVISIONING',
  'ERROR',
  'UNKNOWN',
  'POWERED_OFF',
  // 'SUSPENDED',
  // 'WAITING_FOR_INPUT',
];

export const ACTIVE_SERVICES: ValueType<IActiveServiceNamespace>[] = [
  {
    value: 'MONITORING',
    label: 'MONITORING',
    icon: MuiIcons.AssessmentOutlined,
  },
  {
    value: 'REMOTE_SUPPORT',
    label: 'REMOTE_SUPPORT',
    icon: IconRemoteSupport,
  },
  {
    value: 'SECONDARY_IP',
    label: 'SECONDARY_IP',
    icon: IconSecondaryIp,
  },
  {
    value: 'ACTIVE_DIRECTORY_MEMBER',
    label: 'ACTIVE_DIRECTORY_MEMBER',
    icon: IconConnectDomain,
  },
  {
    value: 'BACKUP',
    label: 'BACKUP',
    icon: IconBackup,
  },
  {
    value: 'SNAPSHOT',
    label: 'SNAPSHOT',
    icon: IconSnapshot,
  },
  {
    value: 'PUBLIC_SERVICE',
    label: 'PUBLIC_SERVICE',
    icon: IconPublicServices,
  },
];

export const FILTERS_BILLINC_CYCLE_OPTIONS = [
  'DISABLED',
  'MONTHLY',
  'QUARTERLY',
  'SEMI_ANNUALLY',
  'ANNUALLY',
];

export const DEFAULT_FILTERS_FORM_QUERY: Types.VmFormFilterParams = {
  status: [],
  billingType: [],
  badgeId: [],
  osId: [],
  activeService: [],
  cpu: [1, 64],
  ram: [1, 512],
  storage: [0, 10000],
};

export const DedicatedServersInitialValues: IDedicatedServer.IRentDedicatedServerFormValues =
  {
    tenantosServerId: 0,
    name: '',
    description: '',
    billingCycleInterval: BILLING_CIRCLE_DS_OPTIONS[0],
    isUsageEnabled: true,
  };

export const DedicatedServersOsInitialValues: IDedicatedServer.IRentDSReinstallOsFormValues &
  IDedicatedServer.IRentDedicatedServerFormValues = {
  osPassword: '',
  templateId: undefined,
  tenantosServerId: 0,
  name: '',
  description: '',
  billingCycleInterval: BILLING_CIRCLE_DS_OPTIONS[0],
  isUsageEnabled: true,
};

export const DedicatedServersQuery: IDedicatedServer.TenantosServersQuery = {
  page: 1,
  orderBy: 'tenantHardwareMonthlyPrice',
  orderType: 'asc',
};

export const ToolbarInitialState = {
  openFilters: false,
  openSelectTenantosServers: false,
  openDedicatedServers: false,
  tenantosServerSelected: undefined,
  columnsIcon: undefined,
};

export const NewDedicatedServerValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .max(15, 'forms.invalid.max')
    .required('forms.required')
    .matches(
      /^(?!-)(?![0-9]{1,15}$)(?![a-zA-Z0-9-]*-$)[a-zA-Z0-9-]{1,15}$/i,
      'forms.invalid'
    ),
  description: Yup.string().trim().max(100, 'forms.invalid.max'),
  templateId: Yup.object().required('forms.required'),
  osPassword: Yup.string()
    .trim()
    .matches(REGEX.password, 'forms.invalid.password')
    .required('forms.required')
    .max(32, 'forms.invalid.max')
    .min(8, 'forms.invalid.min'),
});

export const EditDedicatedServerValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .max(15, 'forms.invalid.max')
    .required('forms.required')
    .matches(
      /^(?!-)(?![0-9]{1,15}$)(?![a-zA-Z0-9-]*-$)[a-zA-Z0-9-]{1,15}$/i,
      'forms.invalid'
    ),
  description: Yup.string().trim().max(100, 'forms.invalid.max'),
});

export const ReinstallOsDSValidationSchema = Yup.object({
  templateId: Yup.object().required('forms.required'),
  osPassword: Yup.string()
    .trim()
    .matches(REGEX.password, 'forms.invalid.password')
    .required('forms.required')
    .max(32, 'forms.invalid.max')
    .min(8, 'forms.invalid.min'),
});

export const noUsageIconStyles = {
  fontSize: 75,
  color: 'rgba(103, 125, 146, 0.2)',
};
