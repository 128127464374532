import * as React from 'react';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Table, MuiIcons, Row, Col } from 'elements';
import { toggleArrayState } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { getColumns, SCHEDULE_TIME_FORMAT } from './constants';

const OBSERVERS = {
  patchings: StateHandlers.tenantsPatchingsBulkInfo,
  currentUser: StateHandlers.currentUser,
};

type IObservers = typeof OBSERVERS;

type IProps = IBulkScheduleUpdateDialog.IStepCommonProps;

const View = observer((props: IProps & IObservers) => {
  const { values, setFieldValue, patchings, currentUser } = props;
  const { t } = useTranslation();

  const allUniqId = patchings.data?.map(
    (p: IPartialTenantWithInstanceMW) => p.uniqId
  );

  const isAllSelected = allUniqId?.every((id: any) =>
    values.applied.includes(id)
  );

  const onSelectAll = React.useCallback(() => {
    setFieldValue('applied', isAllSelected ? [] : allUniqId);
  }, [isAllSelected, allUniqId]);

  const columns = React.useMemo(() => {
    return getColumns({
      t,
      isAllSelected,
      onSelectAll: onSelectAll,
      onCheck: (id) =>
        setFieldValue('applied', toggleArrayState(values.applied, id)),
      values,
    });
  }, [values, isAllSelected, onSelectAll]);

  React.useEffect(() => {
    patchings.get({
      partnerId: currentUser.data?.partner?.id,
      operation: values.operation?.value,
      mw: {
        recurring: values.recurring,
        startDate: values.startDate?.toISOString(),
        alertEmailRecipient: values.alertEmailRecipient,
        informUsers: values.informUsers,
        replaceRebootRules: values.replaceRebootRules,
      },
    });
  }, []);

  return (
    <>
      <div className="mb-20 steel">
        <Trans
          i18nKey="tenants.dialog.bulkSchedule.content.configText"
          values={{
            operation: t(values.operation?.label),
            time: `${t(values.recurring?.label)}, ${values.startDate?.format(
              SCHEDULE_TIME_FORMAT
            )}`,
          }}
          components={{
            1: <strong className="pl-5 pr-5 dark" />,
            2: <strong className="pl-5 pr-5 dark" />,
          }}
        />
      </div>
      <div className="mb-25 steel">
        <Row alignItems="center" columnSpacing={3}>
          <Col>
            <Row alignItems="center" columnSpacing={1}>
              <Col>{t('tenants.dialog.bulkSchedule.content.informUsers')}:</Col>
              {values.informUsers ? (
                <MuiIcons.Done className="success fs-18 ml-10" />
              ) : (
                <MuiIcons.Close className="error fs-18 ml-10" />
              )}
            </Row>
          </Col>
          {values.operation.value === 'rdsReboot' && (
            <Col>
              <Row alignItems="center" columnSpacing={1}>
                <Col>
                  {t('tenants.dialog.bulkSchedule.switchers.replaceRules')}:
                </Col>
                {values.replaceRebootRules ? (
                  <MuiIcons.Done className="success fs-18 ml-10" />
                ) : (
                  <MuiIcons.Close className="error fs-18 ml-10" />
                )}
              </Row>
            </Col>
          )}
        </Row>
      </div>
      <div className={cn({ disabled: patchings.isRequesting })}>
        <Table columns={columns} data={patchings.data} usePagination={false} />
      </div>
    </>
  );
});

const StepSecond = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default StepSecond;
