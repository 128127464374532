import * as React from 'react';
import { useTask } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Monitoring as BaseMonitoringComponent } from 'components';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { MonitoringHolder } from './Styled';

const OBSERVERS = {
  rds: StateHandlers.rds,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { rds } = props;
  const task = useTask(rds.data.task);
  const { t } = useTranslation();
  const isPoweredOn = rds.data?.displayStatus === 'POWERED_ON';

  if (task.isTaskActive || !isPoweredOn) {
    return (
      <div
        className="text-center flex align-center full-width justify-center fs-20"
        style={{ minHeight: 'calc(100vh - 150px)' }}
      >
        {t('monitoring.noService.wait')}
      </div>
    );
  }

  return (
    <MonitoringHolder className="p-30">
      <BaseMonitoringComponent
        entity="rds"
        serviceId={rds.data?.id}
        vmCount={rds.data?.host.hostInstances.length || 1}
        monitoringId={rds.data?.serviceMonitoring?.id}
        fetchService={rds.get}
        instance={rds.data}
        osType="win"
        isDisabled={task.isTaskActive || rds.isRequesting}
      />
    </MonitoringHolder>
  );
});

const Monitoring = () => <View {...OBSERVERS} />;

export default Monitoring;
