import { IGlobalUser } from 'users-shapes';
import { parse, stringify } from 'query-string';
import { GlobalResponse } from 'enums';
import { partnersService, tenantsService, usersService } from 'services';
import {
  DEFAULT_USERS_QUERY,
  recurringIntervalOptions,
  BILLING_DATE_FORMAT,
  DEFAULT_PARTNERS_QUERY,
} from './constants';
import {
  IBillableItem,
  IBillableItemFormValues,
  IBillableItemPayload,
  IRecurringInterval,
} from 'billable-items';
import { dayjs } from 'utils/dayjsExtended';
import { remCalc } from 'utils/uxHelpers';
import { omit, find, propEq } from 'ramda';
import { ValueType } from 'global-shapes';
import { Dayjs } from 'dayjs';

const parseUsersLoadResponse = (
  payload: GlobalResponse<IGlobalUser[]>
): ValueType<number>[] =>
  payload.data.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
  }));

export const onUsersLoad = async (q: string) => {
  const payload = await usersService.getUsersList(
    {
      ...DEFAULT_USERS_QUERY,
      q,
    },
    { headers: { 'X-JBBF-TENANT-SHORTNAME': undefined } }
  );
  return parseUsersLoadResponse(payload);
};

export const onPartnersLoad = async (q: string) => {
  const payload = await partnersService.getPartners({
    ...DEFAULT_PARTNERS_QUERY,
    enabledBilling: true,
    orderBy: 'name',
    q,
  });
  return payload.data.map((partner) => ({
    value: partner.id,
    label: `${partner.name}`,
  }));
};

export const onTenantsLoad = async (q: string) => {
  const payload = await tenantsService.getTenants({
    ...DEFAULT_USERS_QUERY,
    orderBy: 'name',
    enabledBilling: true,
    q,
  });
  return payload.data.map((tanant) => ({
    value: tanant.id,
    label: `${tanant.name} (${tanant.accountNumber})`,
  }));
};

export const parseBillableItemDetailsForForm = (
  item: IBillableItem
): IBillableItemFormValues => {
  const { recurringSettings: recurring } = item;
  const enableRecurring = !!recurring;
  const startDate = enableRecurring
    ? dayjs(recurring?.periodStart, BILLING_DATE_FORMAT)
    : dayjs(item.date, BILLING_DATE_FORMAT);

  return {
    ...item,
    notes: item.notes || '',
    price: +item.price || 0,
    qty: +item.qty || 0,
    date: startDate,
    ticket: item.ticket || '',
    // @ts-ignore
    type: { value: item.type, label: `billableItems.types.${item.type}` },
    // @ts-ignore
    unit: { value: item.unit, label: `billableItems.unit.${item.unit}` },
    // @ts-ignore
    partnerId: { value: item.partner?.id, label: item.partner?.name },
    // @ts-ignore
    tenantId: { value: item.tenant?.id, label: item.tenant?.name },
    userId: {
      // @ts-ignore
      value: item.user.id,
      label: `${item.user?.firstName} ${item.user?.lastName}`,
    },
    enableRecurring,
    repeatPermanently: !recurring?.periodEnd,
    recurringInterval:
      find<ValueType<IRecurringInterval>>(propEq('value', recurring?.interval))(
        recurringIntervalOptions
      ) || recurringIntervalOptions[0],
    recurringStart: recurring?.periodStart
      ? dayjs(recurring?.periodStart, BILLING_DATE_FORMAT)
      : dayjs(),
    recurringEnd: recurring?.periodEnd
      ? dayjs(recurring?.periodEnd, BILLING_DATE_FORMAT)
      : undefined,
  };
};

export const parseBillableItemDetailsFromForm = (
  values: IBillableItemFormValues,
  currentItem?: IBillableItem
): IBillableItemPayload => {
  const {
    enableRecurring,
    repeatPermanently,
    recurringInterval,
    recurringStart,
    recurringEnd,
  } = values;

  const isNew = !currentItem;

  const isDateModifiable =
    enableRecurring && currentItem?.recurringSettings
      ? currentItem?.recurringSettings?.isDateModifiable
      : true;

  const restrictedValues = isNew
    ? {}
    : {
        // we do that coz our date value is duplicating with recurring start date
        date: dayjs(isDateModifiable ? values.date : currentItem?.date).format(
          BILLING_DATE_FORMAT
        ),
      };

  return {
    description: values.description,
    notes: values.notes?.trim() || null,
    ticket: values.ticket.trim(),
    sendUpfront: values.sendUpfront,
    date: dayjs(values.date).format(BILLING_DATE_FORMAT),
    price: +values.price || 0,
    qty: +values.qty || 0,
    type: values.type?.value,
    unit: values.unit?.value,
    partnerId: values.partnerId?.value,
    tenantId: values.tenantId?.value,
    userId: values.userId?.value,
    // @ts-ignore
    recurringSettings: enableRecurring
      ? {
          interval: recurringInterval?.value,
          periodStart: dayjs(recurringStart).format(BILLING_DATE_FORMAT),
          periodEnd: repeatPermanently
            ? null
            : dayjs(recurringEnd).format(BILLING_DATE_FORMAT),
        }
      : undefined,
    ...restrictedValues,
  };
};

export const queryToWatch = (queryStr: string) => {
  const query = omit(['selected'], parse(queryStr, { arrayFormat: 'bracket' }));
  return stringify(query);
};

export function defineEndDateByInterval(
  startDate: Dayjs,
  interval: ValueType<IRecurringInterval>
): Dayjs {
  switch (interval.value) {
    case 'MONTH':
      return startDate.endOf('day').add(30, 'days').endOf('day');
    case 'QUARTER':
      return startDate.endOf('day').add(90, 'days').endOf('day');
    case 'HALF_YEAR':
      return startDate.endOf('day').add(6, 'months').endOf('day');
    case 'YEAR':
      return startDate.endOf('day').add(1, 'years').endOf('day');
    case 'TWO_YEARS':
      return startDate.endOf('day').add(2, 'years').endOf('day');
    default:
      return startDate.endOf('day').add(30, 'days').endOf('day');
  }
}

export const setColumnWidth = (width: number) => ({
  style: { width: remCalc(width) },
});
