import React from 'react';
import cn from 'classnames';
import { remCalc } from 'utils/uxHelpers';
import { styled } from './utilities';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { IAutomationTestProps } from 'automation-test';
import StripeIcon from 'assets/images/icons/stripe-letter.svg';
import { TestSuffixes } from 'enums';

const defaultProps = {
  variant: 'contained',
  color: 'primary',
};

const Button = (
  _props: IAutomationTestProps & ButtonProps & { color?: 'default' }
) => {
  const props = { ...defaultProps, ..._props };
  const { testId, type, ...rest } = props;
  return (
    <MuiButton
      {...rest}
      type={type}
      data-test-id={cn({
        [`${testId}-${
          type === 'submit' ? TestSuffixes.buttonSubmit : TestSuffixes.button
        }`]: !!testId,
      })}
    />
  );
};

type IStripeButtonProps = ButtonProps;

const SStripeIcon = styled('span')(({ theme }) => {
  return {
    width: remCalc(16),
    height: remCalc(16),
    lineHeight: remCalc(16),
    borderRadius: '50%',
    boxShadow: `0 0 0 1px #6772e5`,
    backgroundColor: '#fff',
    fontSize: 0,
    position: 'relative',
    '& img': {
      display: 'block',
      position: 'absolute',
      maxWidth: '10px',
      left: '50%',
      top: '50%',
      transform: 'translate3d(-50%, -50%, 0)',
    },
  };
});

const StripeButton = (props: IStripeButtonProps) => (
  <Button
    size="small"
    endIcon={
      <SStripeIcon>
        <img src={StripeIcon} alt="stripe-icon" />
      </SStripeIcon>
    }
  >
    Pay
  </Button>
);

export { Button, IconButton, StripeButton };
