import React from 'react';
import { PALETTE } from 'enums';
import { styled } from './utilities';
import MobileStepper, {
  MobileStepperProps,
  mobileStepperClasses,
} from '@mui/material/MobileStepper';

const StepperComponent = styled((props: MobileStepperProps) => (
  <MobileStepper
    variant="progress"
    position="static"
    color="success"
    {...props}
  />
))(
  () => `
    flex-grow: 1;
    background-color: transparent;

    &.MuiPaper-root {
        padding-left: 0;
        padding-right: 0;
    }

    & .MuiLinearProgress-colorPrimary {
        background-color: #d8d8d8;
    }

    & .MuiLinearProgress-barColorPrimary {
        background-color: ${PALETTE.success};
    }

    & .${mobileStepperClasses.progress} {
      width: 100%;
    }

`
);

export const MobileProgressStepper = StepperComponent;
