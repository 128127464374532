import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, AddIcon } from 'elements';
import { IReducerChangeHandler } from 'utils';
import * as Types from '../types';

type IProps = {
  isEditable?: boolean;
  query: Types.IProcessQuery;
  onChange: IReducerChangeHandler<Types.IProcessQuery>;
  onRefresh: () => void;
  onAddProcess: () => void;
};

const Filters = (props: IProps) => {
  const { t } = useTranslation();
  // const searchRef = React.useRef({ value: props.query.search || '' });

  // const changeSearch = React.useCallback(
  //   debounce(
  //     (ev: React.ChangeEvent<HTMLInputElement>) =>
  //       props.onChange({ search: ev.target.value || '' }),
  //     1000
  //   ),
  //   []
  // );

  return (
    <Row alignItems="center" justifyContent="flex-end" columnSpacing={2}>
      {/* <Col xs>
        <Input
          ref={searchRef}
          type="search"
          placeholder={t('forms.placeholders.search')}
          name="processSearch"
          onChange={changeSearch}
          onClear={() => {
            searchRef.current.value = '';
            props.onChange({ search: '' });
          }}
        />
      </Col> */}
      <Col>
        <Button variant="outlined" color="default" onClick={props.onRefresh}>
          {t('monitoring.buttons.refresh')}
        </Button>
      </Col>
      <Col>
        <AddIcon
          onClick={props.onAddProcess}
          size="small"
          disabled={!props.isEditable}
        />
      </Col>
    </Row>
  );
};

export default Filters;
