import * as Yup from 'yup';
import * as TYPES from './types';

export const Steps = (isPaidNetwork: boolean) => {
  if (!isPaidNetwork) {
    return ['services.dialog.vms.attachNetwork.step.1'];
  }
  return [
    'services.dialog.vms.attachNetwork.step.1',
    'services.dialog.vms.attachNetwork.step.2',
  ];
};

type IValidationConfig = {
  tab: number;
};

export function DefineValidationSchema({ tab }: IValidationConfig) {
  switch (tab) {
    case 0:
      return Yup.object().shape({
        network: Yup.object().required('forms.required'),
      });

    case 1:
      return Yup.object();

    default:
      return undefined;
  }
}

export const InitialFormValues: TYPES.IFormValues = {
  network: undefined,
  ipv4Enabled: true,
  ipv6Enabled: false,
};
