import { HOURS_ARRAY, MINUTES_ARRAY, GLOBAL_DATE_FORMAT } from 'enums';
import { ILanguage } from 'global-shapes';
import * as R from 'ramda';
import { dayjs, Dayjs } from 'utils';
import { MaintenanceWindowPayload } from 'rds';

export function resetTimeValue(startDate: string | Dayjs): Dayjs {
  return dayjs(startDate).set('seconds', 0);
}

export function parseRebootValuesforPayload(
  reboot: IRebootDialogTypes.Values
): MaintenanceWindowPayload {
  const payload: MaintenanceWindowPayload = {
    startAt: reboot.startDate,
    isForced: false,
    notificationEnabled: reboot.notificationEnabled,
    recurringInterval:
      reboot.recurringInterval?.value === 'SINGLE' // as backend doesnt have SINGLE interval value, we need pass undefined instead
        ? null
        : reboot.recurringInterval?.value,
  };

  return payload;
}

export function findDisabledMinutes(
  startDate: string | Dayjs
): (_selectedHour: number) => number[] {
  return (_selectedHour) => {
    const start = resetTimeValue(startDate);

    const startForMinutes = _selectedHour
      ? dayjs(start).set('hours', _selectedHour)
      : dayjs(start);

    const disabledMinutes = R.filter<number>((minute) => {
      const now = resetTimeValue(dayjs());
      return +startForMinutes.set('minutes', minute) < +now.add(15, 'minutes');
    })(MINUTES_ARRAY);

    return disabledMinutes;
  };
}

export function findDisabledHours(
  startDate: Dayjs,
  _selectedHour?: number
): () => number[] {
  return () => {
    const start = resetTimeValue(startDate);
    const now = resetTimeValue(dayjs());

    return R.filter<number>((hour) => +dayjs(start).set('hours', hour) < +now)(
      HOURS_ARRAY
    );
  };
}

export function parseRepeatOnField(
  startDate: string | null,
  interval: IMaintenanceRecurringInterval,
  locale: ILanguage
): string {
  if (!startDate) return '-';

  if (interval === 'DAY') {
    return dayjs(startDate).format('HH:mm');
  }
  if (interval === 'WEEK') {
    return dayjs(startDate).locale(locale).format('dddd HH:mm');
  }
  if (interval === 'MONTH') {
    return dayjs(startDate).locale(locale).format('D MMM HH:mm');
  }

  return '-';
}

export function parseNextRebootField(
  startDate: string | null,
  interval: IMaintenanceRecurringInterval
): string {
  if (!startDate) return '-';

  if (interval === 'DAY') {
    return dayjs(startDate).format(`${GLOBAL_DATE_FORMAT} HH:mm`);
  }
  if (interval === 'WEEK') {
    return dayjs(startDate).format(`${GLOBAL_DATE_FORMAT} HH:mm`);
  }
  if (interval === 'MONTH') {
    return dayjs(startDate).format(`${GLOBAL_DATE_FORMAT} HH:mm`);
  }

  return dayjs(startDate).format(`${GLOBAL_DATE_FORMAT} HH:mm`);
}

export function filterRebootsItemByType(
  type: IMaintenanceWindow['recurringInterval'],
  reboots: IMaintenanceWindow[]
) {
  if (type === 'SINGLE') {
    return reboots.filter(
      (r) =>
        ![
          'RdsHostReconfigure',
          'RdsHostAppsUpgrade',
          'RdsOsPatching',
          'CoreInfraOsPatching',
        ].includes(r.taskOperationName)
    );
  }
  return R.filter(R.propEq('recurringInterval', type))(reboots);
}
