import { ICurrentUser } from 'auth-shapes';
import * as StateHandlers from 'states';
import { useStateHandler } from './instanceHook';

type Result = [ICurrentUser | undefined, boolean, boolean, boolean];

export function useAccount(): Result {
  const { dataReceived, data, isRequesting } = useStateHandler(
    StateHandlers.currentUser
  );
  const accountHasData = dataReceived && !!data;

  return [data || undefined, accountHasData, dataReceived, isRequesting];
}
