import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { AnyShape } from 'global-shapes';
import { ConnectivityFormValues } from 'services-shapes';
import { isEmpty } from 'ramda';
import { Address4 } from 'ip-address';
import {
  numberToCurrency,
  random,
  remCalc,
  validateCallback,
  validator,
} from 'utils';
import { CostResourceIds } from 'enums';
import {
  Alert,
  Button,
  Col,
  Dialog,
  DialogProps,
  PrimaryTextH5,
  PrimaryTextSpan,
  Row,
} from 'elements';
import { usePermissions, usePrice } from 'hooks';
import { Observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { NETWORK_MASK } from '../services/constants';
import { validateIpPoolRange } from '../services/helpers';
import * as CONSTANTS from './constants';
import {
  CreateConnectivityFixedValue,
  CreateConnectivityInput,
  CreateConnectivityIpPoolInput,
} from './Styled';
import { CostInfoList } from '../services/components/Styled';
import InternalNetworkSubnetDropdown from './InternalNetworkSubnetDropdown';

type Props = DialogProps<ConnectivityFormValues>;

const INITIAL_STATE = {
  internalNetworkAddress: `172.16.${random(1, 254)}`,
  intNetSubnet: CONSTANTS.InternalNetworkSubnets[2],
  ipPoolRange: '10-99',
};

const validate = (values: ConnectivityFormValues) => {
  const errors: AnyShape = {};

  if (!values.internalNetworkAddress) {
    errors.internalNetworkAddress = 'forms.required';
  }

  if (!values.ipPoolRange) {
    errors.ipPoolRange = 'forms.required';
  }

  try {
    new Address4(`${values.internalNetworkAddress}.0/${NETWORK_MASK}`);

    if (values.ipPoolRange) {
      const ipPoolError = validateIpPoolRange(
        values.ipPoolRange,
        values.internalNetworkAddress
      );
      if (ipPoolError) errors.ipPoolRange = ipPoolError;
    }
  } catch (err) {
    errors.internalNetworkAddress = 'forms.invalid';
  }

  return errors;
};

const DeployConnectivityDialog = (props: Props) => {
  const { t } = useTranslation();
  const { onSave, open, onClose } = props;
  const { isEvaluation } = usePermissions();
  const pricing = usePrice(CostResourceIds.connectivityBase);
  const connectivity = StateHandlers.connectivity;

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    setFieldValue,
    isSubmitting,
    resetForm,
    submitForm,
  } = useFormik({
    initialValues: INITIAL_STATE,
    validate,
    onSubmit: (val) => onSave && onSave(val),
  });

  const baseFee = pricing[CostResourceIds.connectivityBase]?.monthly;

  const keyUpHandler = React.useCallback((e: any) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  }, []);

  React.useEffect(() => {
    if (!open) {
      resetForm();
      document.removeEventListener('keyup', keyUpHandler);
    } else {
      document.addEventListener('keyup', keyUpHandler);
    }
  }, [open]);

  return (
    <Observer>
      {() => (
        <Dialog
          title={t('services.dialog.connectivity.title')}
          handleSubmit={handleSubmit}
          maxWidth="md"
          fullWidth
          open={open}
          onClose={onClose}
          actions={
            <Row alignItems="center" justifyContent="space-between">
              <Col>
                <Button color="default" variant="outlined" onClick={onClose}>
                  {t('common.cancel')}
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={
                    isSubmitting ||
                    connectivity.isRequesting ||
                    !isEmpty(errors)
                  }
                  type="submit"
                >
                  {t('common.deploy')}
                </Button>
              </Col>
            </Row>
          }
        >
          <Row columnSpacing={2}>
            <Col xs={7}>
              <div className="steel mb-25">
                {t('services.dialog.connectivity.content')}
              </div>
              <Row columnSpacing={3} className="mb-25">
                <Col>
                  <CreateConnectivityInput
                    className={cn('steel')}
                    name="internalNetworkAddress"
                    label="services.dialog.connectivity.network"
                    value={values.internalNetworkAddress}
                    onChange={validateCallback({
                      max: 11,
                      restrict: validator.ipSymbols,
                    })(handleChange)}
                    placeholder="000.000.000"
                    disabled={connectivity.isRequesting}
                    error={!!errors.internalNetworkAddress}
                    helperText={errors.internalNetworkAddress}
                    endAdornment={
                      <InternalNetworkSubnetDropdown
                        value={values.intNetSubnet}
                        onChange={(val) => setFieldValue('intNetSubnet', val)}
                      />
                    }
                  />
                </Col>
                <Col>
                  <CreateConnectivityIpPoolInput
                    className={cn('steel')}
                    label="services.dialog.connectivity.ipPool"
                    name="ipPoolRange"
                    value={values.ipPoolRange}
                    onChange={validateCallback({
                      max: 7,
                      restrict: validator.numRange,
                    })(handleChange)}
                    disabled={connectivity.isRequesting}
                    error={!!errors.ipPoolRange}
                    helperText={errors.ipPoolRange}
                    placeholder="00-00"
                    startAdornment={
                      <CreateConnectivityFixedValue
                        className={cn('pl-10')}
                        position="start"
                      >
                        {values.internalNetworkAddress || '000.000.00'}.
                      </CreateConnectivityFixedValue>
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={5}>
              <Row
                direction="column"
                justifyContent="space-between"
                columnSpacing={2}
                style={{ minHeight: remCalc(350) }}
              >
                <Col className="full-width">
                  <PrimaryTextH5 className="mb-25">
                    {t('costsInfo.title')}
                  </PrimaryTextH5>
                  <Row
                    justifyContent="space-between"
                    columnSpacing={2}
                    className="fs-10 steel uppercase mb-15"
                  >
                    <Col xs={8}>
                      <span>{t('costsInfo.head.serviceName')}</span>
                    </Col>
                    <Col xs={4} className="text-right">
                      <span>{t('costsInfo.head.price')}</span>
                    </Col>
                  </Row>
                  <h5 className="mb-15">{t('costsInfo.connectivity')}</h5>
                  <CostInfoList className="custom-list">
                    <ul>
                      <li>
                        <div className="flex justify-between">
                          <span>{t('costsInfo.baseFee')}</span>
                          <span className="pl-10 steel">
                            {numberToCurrency(baseFee, false)}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </CostInfoList>
                </Col>
                <Col className="full-width">
                  <div className="flex justify-between align-center mb-5">
                    <h5 className="fs-17">{t('costsInfo.totalMonthly')}</h5>
                    <PrimaryTextSpan className="fs-20 bold">
                      {numberToCurrency(baseFee, false)}
                    </PrimaryTextSpan>
                  </div>
                  <div className="fs-12 steel mb-10">
                    {t('costsInfo.chfExclVat')}
                  </div>
                  {isEvaluation && (
                    <Alert className="fs-12" severity="info">
                      {t('costsInfo.evalAccount.notify')}
                    </Alert>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Dialog>
      )}
    </Observer>
  );
};

export default DeployConnectivityDialog;
