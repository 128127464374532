export function getRelativeZonesPrice<N extends number, P extends number>(
  counter: N,
  dnsPrice: P
): number {
  if (counter <= 20) {
    return dnsPrice * 20;
  }

  return counter * dnsPrice;
}
