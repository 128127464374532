import dayjs, { Dayjs } from 'dayjs';
import { AnyShape } from 'global-shapes';
import { ILocalTypes } from './types';

interface RolesParams {
  limit?: number;
  offset?: number;
  name?: string;
  status: IPartnerTypes.PartnerStatus;
}

const VALID_STATUSES: IPartnerTypes.PartnerStatus[] = [
  'EVALUATION',
  'EVALUATION_EXPIRED',
  'PRODUCTION',
];

export const organizationFields: string[] = [
  'shortName',
  'companyName',
  'addressLine1',
  'addressLine2',
  'addressZipCode',
  'addressCity',
  'addressCountry',
  'addressCountryCode',
];

export const parseQuery = (q: AnyShape): RolesParams => {
  return {
    ...q,
    status: !VALID_STATUSES.includes(q.status) ? undefined : q.status,
  };
};

export const shouldDisableNextStep = (errors: AnyShape | null) => {
  let isFulfilled = false;
  organizationFields.forEach((field) => {
    if (!isFulfilled && errors && errors[field]) isFulfilled = true;
  });
  return isFulfilled;
};

export function defineTimesLeft(deletedAt: string): number {
  const now = dayjs();
  const startTime = dayjs(deletedAt);
  const endTimeTime = startTime.add(20, 'minutes');
  return endTimeTime.diff(now, 'minutes');
}

export function defineExpirationDays(date: Dayjs): number {
  return date.startOf('day').diff(dayjs(), 'days') + 1;
}

export const mapAccountStatus = (
  status?: IPartnerTypes.PartnerStatus
): ILocalTypes.IAccountStatusMap => ({
  pendingEvaluation: status === 'PENDING_EVALUATION',
  evaluation: status === 'EVALUATION',
  expired: status === 'EVALUATION_EXPIRED',
  prod: status === 'PRODUCTION',
});
