import React from 'react';
import { Row, Col, Button, Dialog, DialogProps } from 'elements';
import { remCalc } from 'utils';
import ImagePlaceholder from '../ImagePlaceholder';
import { ISoftwarePackageMerged } from 'app-store';
import LicenseListDisplay from './LicenseListDisplay';
import { useTranslation } from 'react-i18next';
import { Permission } from 'auth-shapes';

type Props = DialogProps<unknown> & {
  permissions: Permission;
  onInstall: (pac: ISoftwarePackageMerged) => void;
  isTenant: boolean;
  currentPackage: ISoftwarePackageMerged | null;
};

const SoftwarePackageInfoDialog = (props: Props) => {
  const { t } = useTranslation();
  const {
    currentPackage: pac,
    onInstall,
    isTenant,
    permissions,
    open,
    onClose,
  } = props;
  const hasLicense = pac?.licenseSettings;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      showCloseIcon
      fullWidth
      maxWidth="md"
      actions={
        permissions.canManage && isTenant && !pac?.isInstalled ? (
          <div className="flex justify-end">
            <Button onClick={() => onInstall(pac as ISoftwarePackageMerged)}>
              {t(
                hasLicense
                  ? 'softwarePackages.dialog.buttons.selectLicense'
                  : 'common.install'
              )}
            </Button>
          </div>
        ) : undefined
      }
    >
      {pac && (
        <>
          <Row columnSpacing={2} className="mb-15">
            <Col>
              <div style={{ width: remCalc(86) }}>
                <ImagePlaceholder src={pac.icon && pac.icon.link} size={86} />
              </div>
            </Col>
            <Col xs>
              <h4>{pac.name}</h4>
              <LicenseListDisplay {...pac} />
            </Col>
          </Row>
          <div
            dangerouslySetInnerHTML={{ __html: pac.description }}
            style={{ wordBreak: 'break-word' }}
          />
        </>
      )}
    </Dialog>
  );
};

export default SoftwarePackageInfoDialog;
