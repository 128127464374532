import { styled, Select } from 'elements';
import { remCalc } from 'utils';
import { PALETTE } from 'enums';

export const EmptyBlockHolder = styled('div')(({ theme }) => ({
  width: remCalc(400),
  minHeight: 'calc(100vh - 360px)',
  margin: 'auto',
  textAlign: 'center',
  color: PALETTE.steel,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ChartItemHolder = styled('div')(({ theme }) => ({
  height: remCalc(312),
  borderRadius: 4,
  overflow: 'hidden',
  boxShadow: `0 0 0 1px ${PALETTE.lightGrey}`,
  '& ._title': {
    height: remCalc(312 - 262),
    lineHeight: remCalc(312 - 262),
    padding: remCalc(0, 20),
    fontWeight: 600,
  },
  '& ._error': {
    textAlign: 'center',
    width: '100%',
  },
  '& .apexcharts-zoom-icon': {
    display: 'none',
  },
  '& .apexcharts-tooltip-series-group': {
    lineHeight: 1,
  },
  '& ._chart-content': {
    backgroundColor: PALETTE.paleGrey,
    height: remCalc(262),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& .apexcharts-tooltip': {
      borderRadius: 4,
      boxShadow: theme.shadows[3],
      border: 'none',
      '& .apexcharts-tooltip-title': {
        backgroundColor: PALETTE.primaryLight,
        color: PALETTE.white,
        padding: remCalc(7, 10),
      },
    },
    '& .apexcharts-tooltip-text-y-label': {
      textTransform: 'uppercase',
    },
    '& .apexcharts-xaxistooltip, & .apexcharts-yaxistooltip': {
      backgroundColor: PALETTE.primaryLight,
      color: PALETTE.white,
      border: 'none',
      borderRadius: 4,
      boxShadow: theme.shadows[3],
      '&:before': {
        borderBottomColor: 'transparent',
      },
      '&:after': {
        borderBottomColor: PALETTE.primaryLight,
      },
    },
  },
}));

export const DialogRecipientsHolder = styled('div')(({ theme }) => ({
  maxWidth: remCalc(550),
  width: '100%',
}));

export const QuickFilter = styled(Select)(({ theme }) => ({
  width: remCalc(200),
}));

export const SInfoLabel = styled('sup')(({ theme }) => ({
  verticalAlign: 'middle',
  fontSize: remCalc(12),
  color: PALETTE.info,
  '& svg': {
    paddingLeft: remCalc(5),
    fontSize: remCalc(17),
  },
}));

export const SMaintenanceinput = styled('div')(({ theme }) => ({
  width: remCalc(230),
}));
