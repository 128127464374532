import * as React from 'react';
import { useTask } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Monitoring as BaseMonitoringComponent } from 'components';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

const OBSERVERS = {
  core: StateHandlers.core,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { core } = props;
  const task = useTask(core.data?.task);
  const { t } = useTranslation();
  const isPoweredOn = core.data?.displayStatus === 'POWERED_ON';

  if (task.isTaskActive || !isPoweredOn) {
    return (
      <div
        className="text-center flex align-center full-width justify-center fs-20"
        style={{ minHeight: 'calc(100vh - 150px)' }}
      >
        {t('monitoring.noService.wait')}
      </div>
    );
  }

  return (
    <BaseMonitoringComponent
      entity="core"
      vmCount={1}
      serviceId={core.data?.id}
      monitoringId={core.data?.serviceMonitoring?.id}
      fetchService={() => core.get()}
      instance={core.data}
      osType="win"
      isDisabled={task.isTaskActive || core.isRequesting}
    />
  );
});

const Monitoring = () => <View {...OBSERVERS} />;

export default Monitoring;
