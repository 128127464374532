import * as React from 'react';
import * as R from 'ramda';
import {
  Row,
  Dialog,
  Button,
  DialogProps,
  Col,
  PrimaryTextH5,
  PrimaryTextSpan,
  Switch,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useFormik } from 'formik';
import { CostResourceIds } from 'enums';
import { usePrice } from 'hooks';
import { numberToCurrency } from 'utils';
import { CostInfoList } from 'pages/services/components/Styled';

type IProps = DialogProps<IServicePatchingTypes.IUpdatePayload> & {
  initialValues: IServicePatchingTypes.IUpdatePayload;
};

const OBSERVERS = {
  currentUser: StateHandlers.currentUser,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { onSave, open, onClose, currentUser, initialValues, ...rest } = props;
  const { t } = useTranslation();
  const price = usePrice([CostResourceIds.patchingManagement]);

  const form = useFormik({
    initialValues: initialValues,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: onSave as any,
  });

  const outsourceManagementPrice = price[CostResourceIds.patchingManagement];

  const finalOutsourceManagementPrice = form.values.outsourceManagementEnabled
    ? outsourceManagementPrice?.monthly
    : 0;

  const totalCost = R.sum([finalOutsourceManagementPrice]);

  React.useEffect(() => {
    if (!open) {
      form.resetForm();
    } else {
      form.setValues(initialValues);
    }
  }, [open]);

  return (
    <Dialog
      {...rest}
      title={t('patching.dialogs.manageBilling.title')}
      open={open}
      handleSubmit={form.handleSubmit}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      actions={
        <Row justifyContent="flex-end">
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={onClose}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={form.isSubmitting}>
            {t('common.save')}
          </Button>
        </Row>
      }
    >
      <Row
        justifyContent="space-between"
        columnSpacing={2}
        style={{ minHeight: 300 }}
      >
        <Col xs={7}>
          <div className="steel mb-40">
            {t('patching.dialogs.manageBilling.content')}
          </div>
          <Switch
            label={t('patching.switchers.systems')}
            checked={form.values.outsourceManagementEnabled}
            onCheck={(outsourceManagementEnabled) =>
              form.setFieldValue(
                'outsourceManagementEnabled',
                outsourceManagementEnabled
              )
            }
          />
        </Col>
        <Col xs={5}>
          <Row
            direction="column"
            justifyContent="space-between"
            columnSpacing={2}
            style={{ minHeight: '100%' }}
          >
            <Col className="full-width">
              <PrimaryTextH5 className="mb-25">
                {t('costsInfo.title')}
              </PrimaryTextH5>
              <Row
                justifyContent="space-between"
                columnSpacing={0}
                className="fs-10 steel uppercase mb-15"
              >
                <Col xs={9}>
                  <span>{t('costsInfo.head.serviceName')}</span>
                </Col>
                <Col xs={3} className="text-right">
                  <span>{t('costsInfo.head.price')}</span>
                </Col>
              </Row>
              <h5 className="mb-15">{t(`costsInfo.virtualServer`)}</h5>
              <CostInfoList className="custom-list">
                <ul>
                  <li className="mb-10">
                    <Row columnSpacing={1}>
                      <Col xs={9}>
                        <span>{t('costsInfo.outsourceManagement')}</span>
                      </Col>

                      <Col xs={3} className="text-right steel">
                        <span>
                          {numberToCurrency(
                            finalOutsourceManagementPrice,
                            false
                          )}
                        </span>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </CostInfoList>
            </Col>
            <Col className="full-width">
              <div className="flex justify-between align-center">
                <h5 className="fs-17">{t('costsInfo.totalCost.NONE')}</h5>
                <PrimaryTextSpan className="fs-20 bold">
                  {numberToCurrency(totalCost, false)}
                </PrimaryTextSpan>
              </div>
              <div className="fs-12 steel">{t('costsInfo.chfExclVat')}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
});

const ManageBillingDialog = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default ManageBillingDialog;
