import { remCalc, important } from 'utils';
import { PALETTE } from 'enums';
import { styled, Button, MenuItem, Row, MuiIcons } from 'elements';

export const DropdownItemButton = styled(Button)(({ theme }) => ({
  padding: important(remCalc(14, 5, 14, 10)),
  width: important(remCalc(125)),
  justifyContent: important('space-between'),
}));

export const DropdownMenuItem = styled(MenuItem)(({ theme }) => ({
  width: remCalc(125),
}));

export const EmptyMessageHolder = styled(Row)({
  minHeight: 'calc(100vh - 280px)',
  textAlign: 'center',
});

export const EmptyMessage = styled('div')(({ theme }) => ({
  fontSize: remCalc(20),
  marginBottom: remCalc(35),
}));

export const ActionBurgerIcon = styled(MuiIcons.MoreVert)(({ theme }) => ({
  marginLeft: remCalc(15),
  color: PALETTE.steel,
  fontSize: remCalc(24),
  cursor: 'pointer',
}));
