import { GET, POST, PUT, PATCH } from 'utils';
import {
  INewPricing,
  IPrice,
  IPricingService,
  IUpdatePricingValues,
} from 'pricing';
import { AxiosPromise } from 'axios';
import { ResourceQuota } from 'quotas';
import { IAxiosPromise } from 'axios-shapes';

export const getPricingServices = (): AxiosPromise<IPricingService[]> =>
  GET('/services');

export const getPartnerPrices = (): IAxiosPromise<IPrice[]> => GET('/prices');

export const createDefaultPrice = (data: INewPricing): AxiosPromise<IPrice> =>
  POST(`/prices`, {}, { prices: [data] });

export const update = (
  id: number,
  data: IUpdatePricingValues
): AxiosPromise<IPrice> =>
  PUT(`/prices/${id}`, {}, { value: data.value, isHidden: false });

export const updateTenantPrice = (
  id: number,
  data: IUpdatePricingValues
): AxiosPromise<IPrice> =>
  PUT(`/prices/tenant/${id}`, {}, { value: data.value, isHidden: false });

export const getProviderDefault = (): IAxiosPromise<IPrice[]> =>
  GET(`/prices/default`);

export const getProviderPartnerPrices = (
  partnerId: number
): IAxiosPromise<IPrice[]> => GET(`/prices/${partnerId}`);

export const getMyTenantPrices = (): IAxiosPromise<IPrice[]> =>
  GET(`/prices/tenant`);

export const getCurrentTenantPrices = (id: number): IAxiosPromise<IPrice[]> =>
  GET(`/prices/tenant/${id}`);

export const getTenantDefaultPrices = (): IAxiosPromise<IPrice[]> =>
  GET(`/prices/default/tenant`);

export const getCostBreakdown = (): IAxiosPromise<ResourceQuota> =>
  GET(`/vms/quota/tenants`);

export const bulkUpdatePartnerPrices = (prices: any): IAxiosPromise<IPrice[]> =>
  PATCH('/prices', {}, { prices });

export const bulkUpdateTenantPrices = (prices: any): IAxiosPromise<IPrice[]> =>
  PATCH('/prices/tenant', {}, { prices });

export const resetPartnerPrices = (id: number): AxiosPromise<IPrice> =>
  PUT(`/partners/${id}/prices/reset`, {});

export const resetTenantPrices = (id: number): AxiosPromise<IPrice> =>
  PUT(`/tenants/${id}/prices/reset`, {});

export const generateCostBreakdown = (
  payload: ICostTypes.CostEstimationPayload
): AxiosPromise<ICostTypes.CostEstimationResponse> =>
  PUT(`/service-costs/estimate`, {}, payload);
