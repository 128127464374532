import { RESOURCE_ID_TO_UNIT_MAP } from 'enums/costResourceIds';
import { compact } from './customFunctions';

export function to2Fixed(value: number): number {
  return Number(to2FixedString(value));
}

export function to2FixedString(value: number): string {
  return Number(value).toFixed(2);
}

export function to4Fixed(value: number): number {
  return Number(Number(value).toFixed(4));
}

export function toFixed(value: number, fractionDigits = 2): number {
  return Number(Number(value).toFixed(fractionDigits));
}

export function hoursToDaysString(hours: number, suffix = 'd'): string {
  const days = to2FixedString(hours / 24);
  return suffix ? `${days} ${suffix}` : days;
}

export function displayPcs(pcs: number): string {
  return pcs ? `${pcs} pcs` : '';
}

export function displayHours(h: number): string {
  return h ? `${h} h` : '';
}

export function formatServiceResourceValue(
  serviceResourceId: number,
  value: number
): string {
  const unit = RESOURCE_ID_TO_UNIT_MAP[serviceResourceId];
  return unit === undefined
    ? ''
    : `${to2Fixed(value)} ${unit}`.replace(/\s+$/, '');
}

export const ellipseStr = (trim: number) => (str: string) => {
  const _str = str || '';
  const strLength = _str.length;
  if (strLength > trim) {
    return _str.slice(0, trim) + '...';
  }
  return _str;
};

export function parseQueryForFormatter(str: string): {
  clearedString: string;
  parsed: IElementsTypes.IInputChip[];
} {
  const _str = str || '';

  const matched = compact(_str.match(/(?<=#{)(.*?)(?=\})/g) || []);
  const clearedString = (str || '')
    .replace(/(?<=#{)(.*?)(?=\})/g, '')
    .split('#{}')
    .join('')
    .trim();

  const parsed = matched.map((m) => {
    const sep = m.split(':');
    if (sep.length !== 2) return null;
    const key = (sep[0] || '').trim();
    const value = (sep[1] || '').trim();
    if (!value) return null;
    return {
      key,
      value,
    };
  });

  return {
    clearedString,
    parsed: compact(parsed),
  };
}

export function bitToMBits(val: number) {
  return val / 1000 / 1000 || 0;
}

export function MBitsToBit(val: number) {
  return val * 1000 * 1000 || 0;
}

export function bToGb(b: number) {
  return b / 1024 / 1024 / 1024 || 0;
}

export function GbToB(gb: number) {
  return gb * 1024 * 1024 * 1024 || 0;
}
