import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Loader } from 'elements/Loader';
import { useUserHash, usePermissions } from 'hooks';
import { AuthProvider } from './AuthProvider';
import RoutesHolder from './RoutesHolder';
import RequireAuth from './RequiredAuth';
import Layout from './Layout';
import Roles from 'pages/roles/Roles';
import Users from 'pages/users/Users';
import Partners from 'pages/partners/Partners';
import { Invoices } from 'pages/invoices';
import { BillableItems } from 'pages/billable-items';
import Settings from 'pages/settings/Settings';
import PartnerPricing from 'pages/settings/components/tabs/Pricing/EditCurrentPartnerPricing';
import Blacklist from 'pages/settings/components/tabs/Blacklist/Main';
import AppVersion from 'pages/settings/components/tabs/Version/Version';
import Badges from 'pages/settings/components/tabs/Badges/Main';
import Reports from 'pages/settings/components/tabs/Reports/Main';
import Usage from 'pages/usage';
import Tenants from 'pages/tenants/Tenants';
import TenantPricing from 'pages/settings/components/tabs/Pricing/EditCurrentTenantPricing';
import Services from 'pages/services/Services';
import VmDetails from 'pages/vm-details/Main';
import DedicatedSeverDetails from 'pages/vm-details/DedicatedServer/Main';
import NotFound from 'pages/404';
import Login from 'pages/auth/Login';
import InviteProxy from 'pages/auth/InviteProxy';
import SignUp from 'pages/auth/SignUp/SignUp';
import TenantSignUp from 'pages/auth/TenantSignUp';
import Setup2FA from 'pages/auth/Setup2FA';
import CreatePassword from 'pages/auth/CreatePassword';
import ResetPassword from 'pages/auth/ResetPassword';
import RequestResetPassword from 'pages/auth/RequestResetPassword';
import AccountVerify from 'pages/auth/AccountVerify';
import VerificationCode from 'pages/auth/VerificationCode';
import VerifyNewEmail from 'pages/auth/VerifyNewEmail';

// PRICING routes
import DefaultPartnerPricing from 'pages/settings/components/tabs/Pricing/DefaultPartnerPricing';
import DefaultProviderPricing from 'pages/settings/components/tabs/Pricing/DefaultProviderPricing';
import SoftwarePackages from 'components/SoftwarePackages/AppStore';
import PartnerInfo from 'pages/settings/components/tabs/PartnerInfo/Main';
import BillingEmails from 'pages/settings/components/tabs/BillingEmails/BillingEmails';
import Appearance from 'pages/settings/components/tabs/Appearance/Appearance';
import TenantCompanyInfo from 'pages/settings/components/tabs/TenantInfo/Main';
import { MyTenantPricingWithPermissions } from 'pages/settings/components/tabs/Pricing/DefaultTenantPricing';
import CustomerInvoice from 'pages/settings/components/tabs/SendInvoices/Main';

// TENANT VIEW routes
import AllServices from 'pages/services/components/tabs/AllServices';
import Connectivity from 'pages/connectivity/Main';
import Edges from 'pages/connectivity/Edges';
import Networks from 'pages/connectivity/Networks';
import ConnectivityRules from 'pages/connectivity/Rules/Main';
import CoreInfrastructure from 'pages/core/Main';
import Rds from 'pages/rds/Main';
import { Domains } from 'pages/dns';
import VMs from 'pages/services/components/tabs/VMs';

// CORE routes
import CoreAdmins from 'pages/core/Admins';
import CoreUsers from 'pages/core/Users';
import { FileShares } from 'pages/core/FileShares';
import CoreBackup from 'pages/core/Backup';
import CoreMonitoring from 'pages/core/Monitoring';
import CorePatching from 'pages/core/Patching';

// RDS routes
import RdsBackup from 'pages/rds/Backup';
import RdsUsers from 'pages/rds/Users/Users';
import AppStore from 'pages/rds/AppStore';
import RdsMonitoring from 'pages/rds/Monitoring';
import Reboots from 'pages/rds/Reboots';
import RdsPatching from 'pages/rds/Patching';

// VM DETAILS routes
import VMInfo from 'pages/vm-details/VMInfo';
import VmBackup from 'pages/vm-details/Backup';
import AdditionalDisks from 'pages/vm-details/AdditionalDisks';
import PublicServices from 'pages/vm-details/PublicServices';
import VmMonitoring from 'pages/vm-details/Monitoring';
import VmNetwork from 'pages/vm-details/Network';

// DEDICATED SERVER DETAILS routes
import DedicatedInfo from 'pages/vm-details/DedicatedServer/Info';
import DedicatedServerMonitoring from 'pages/vm-details/DedicatedServer/Monitoring';

const defineUserUrlRoute = (
  partnerShortName?: string,
  tenantShortName?: string
): string => {
  let route = '';

  if (partnerShortName) {
    route = route + partnerShortName;
  }

  if (tenantShortName) {
    route = route + '/' + tenantShortName;
  }

  if (route.trim()) {
    route = route + '/';
  }

  return route;
};

const RoutesComponent = observer(() => {
  const [, , , ps, ts] = useUserHash();
  const source = usePermissions();
  const baseRoute = defineUserUrlRoute(ps, ts);

  return (
    <AuthProvider>
      <RoutesHolder>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route element={<NotFound />} path="404" />
            <Route caseSensitive element={<Login />} path="login" />
            <Route caseSensitive element={<InviteProxy />} path="invite" />
            <Route caseSensitive element={<SignUp />} path="sign-up" />
            <Route
              caseSensitive
              element={<TenantSignUp />}
              path="customer-sign-up"
            />
            <Route caseSensitive element={<Setup2FA />} path="2fa-setup" />
            <Route
              caseSensitive
              element={<CreatePassword />}
              path="create-password"
            />
            <Route
              caseSensitive
              element={<ResetPassword />}
              path="reset-password"
            />
            <Route
              caseSensitive
              element={<RequestResetPassword />}
              path="request-reset-password"
            />
            <Route caseSensitive element={<AccountVerify />} path="verify" />
            <Route
              caseSensitive
              element={<VerificationCode />}
              path="verify-code"
            />
            <Route
              caseSensitive
              element={<VerifyNewEmail />}
              path="verify-email"
            />
            <Route
              element={
                <RequireAuth>
                  <Partners />
                </RequireAuth>
              }
              caseSensitive
              path="partners"
            />
            <Route
              element={
                <RequireAuth>
                  <PartnerPricing />
                </RequireAuth>
              }
              caseSensitive
              path={`${baseRoute}partner-pricing`}
            />

            <Route
              element={
                <RequireAuth>
                  <Services />
                </RequireAuth>
              }
              caseSensitive
              path={`${baseRoute}services`}
            >
              <Route path="all" element={<AllServices />} />
              <Route path="connectivity" element={<Connectivity />}>
                <Route element={<Edges />} path="edges" />
                <Route element={<Networks />} path="networks" />
                <Route element={<ConnectivityRules />} path="rules" />
              </Route>
              <Route path="infrastructure" element={<CoreInfrastructure />}>
                <Route element={<CoreAdmins />} path="admins" />
                <Route element={<CoreUsers />} path="accounts" />
                <Route element={<FileShares />} path="shares" />
                <Route element={<CoreBackup />} path="backup" />
                <Route element={<CoreMonitoring />} path="monitor" />
                <Route element={<CorePatching />} path="patching" />
              </Route>
              <Route path="rds" element={<Rds />}>
                <Route element={<RdsUsers />} path="accounts" />
                <Route element={<AppStore />} path="apps" />
                <Route element={<RdsBackup />} path="backup" />
                <Route element={<RdsMonitoring />} path="monitor" />
                <Route element={<Reboots />} path="reboot" />
                <Route element={<RdsPatching />} path="patching" />
              </Route>
              <Route path="dns/domains" element={<Domains />} />
              <Route path="server" element={<VMs />} />
              <Route path="vm" element={<VmDetails />}>
                <Route element={<VMInfo />} path="info" />
                <Route element={<PublicServices />} path="public" />
                <Route element={<AdditionalDisks />} path="disks" />
                <Route element={<VmBackup />} path="backup" />
                <Route element={<VmMonitoring />} path="monitor" />
                <Route element={<VmNetwork />} path="network" />
              </Route>
              <Route path="dedicated" element={<DedicatedSeverDetails />}>
                <Route element={<DedicatedInfo />} path="info" />
                <Route element={<DedicatedServerMonitoring />} path="monitor" />
              </Route>
            </Route>

            <Route
              element={
                <RequireAuth>
                  <Users />
                </RequireAuth>
              }
              caseSensitive
              path={`${baseRoute}users`}
            />

            <Route
              element={
                <RequireAuth>
                  <Roles />
                </RequireAuth>
              }
              caseSensitive
              path={`${baseRoute}roles`}
            />

            <Route
              element={
                <RequireAuth>
                  <Tenants />
                </RequireAuth>
              }
              caseSensitive
              path={`${baseRoute}tenants`}
            />
            <Route
              element={
                <RequireAuth>
                  <Invoices />
                </RequireAuth>
              }
              caseSensitive
              path={`${baseRoute}invoices`}
            />
            <Route
              element={
                <RequireAuth>
                  <BillableItems level={source.currentAccessLevel as any} />
                </RequireAuth>
              }
              caseSensitive
              path={`${baseRoute}billable-items`}
            />
            <Route
              element={
                <RequireAuth>
                  <Usage />
                </RequireAuth>
              }
              caseSensitive
              path={`${baseRoute}usage`}
            />
            <Route
              element={
                <RequireAuth>
                  <Settings level={source.currentAccessLevel} />
                </RequireAuth>
              }
              caseSensitive
              path={`${baseRoute}settings`}
            >
              <Route element={<DefaultProviderPricing />} path="base-pricing" />
              <Route element={<SoftwarePackages />} path="app-store" />
              <Route element={<DefaultPartnerPricing />} path="pricing" />
              <Route element={<PartnerInfo />} path="partner-info" />
              <Route element={<BillingEmails />} path="billing-emails" />
              <Route element={<Appearance />} path="appearance" />
              <Route element={<CustomerInvoice />} path="customer-invoice" />
              <Route element={<TenantCompanyInfo />} path="company-info" />
              <Route element={<BillingEmails />} path="billing-emails" />
              <Route element={<Blacklist />} path="blacklist" />
              <Route element={<AppVersion />} path="version" />
              <Route element={<Badges />} path="badges" />
              <Route element={<Reports />} path="reports" />
              <Route
                element={<MyTenantPricingWithPermissions />}
                path="my-pricing"
              />
            </Route>
            <Route
              element={
                <RequireAuth>
                  <TenantPricing />
                </RequireAuth>
              }
              caseSensitive
              path={`${baseRoute}tenant-pricing`}
            />
            <Route element={<Loader />} path="*" />
          </Route>
        </Routes>
      </RoutesHolder>
    </AuthProvider>
  );
});

export default RoutesComponent;
