import React, { useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { getServicesNavigationOptions } from '../../constants';
import { ALL_TEST_IDS } from 'enums';
import { useQuery, useUserHash, useTask } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { INavItem } from 'services-types';
import { LinearTabs, LinearTabLink } from 'components';

type IViewProps = {
  currentUser: StateHandlers.ICurrentUserHandler;
  core: StateHandlers.ICoreHandler;
  connectivity: StateHandlers.IConnectivityHandler;
  rds: StateHandlers.IRdsHandler;
  dns: StateHandlers.IDnsHandler;
};

const NavItem = React.memo(
  (props: INavItem & { data: any }) => {
    const { data, isAlwaysEnabled } = props;
    const { t } = useTranslation();
    const { location } = useQuery();

    const isActive = useMemo(
      () => location.pathname.includes(props.baseNamespace),
      [location.pathname]
    );

    const task = useTask(data && data.task);

    const isDisabled = useMemo(() => {
      if (isAlwaysEnabled) return false;
      return data ? task.isTaskActive || isEmpty(data) : true;
    }, [task.isTaskActive, JSON.stringify(data)]);

    return (
      <LinearTabLink
        key={props.to}
        to={props.to}
        isActive={isActive}
        className={cn({
          disabled: props.testName !== 'all' ? isDisabled : false,
        })}
        data-test-id={`${ALL_TEST_IDS.services.navBar}-${props.testName}-item`}
      >
        {t(props.label)}
      </LinearTabLink>
    );
  },
  (prev, next) => {
    if (prev.data === next.data) return true;
    return false;
  }
);

const View = observer((props: IViewProps) => {
  const { currentUser } = props;
  const [hash] = useUserHash();

  const additionalQuery = {
    view: currentUser.data?.uiServicesView || 'card',
  };

  return (
    <LinearTabs>
      {getServicesNavigationOptions(hash, additionalQuery).map((item) => {
        const reducer: any = item.dependency
          ? // @ts-ignore
            props[item.dependency]
          : undefined;

        const data = reducer ? reducer.data : null;

        return (
          <NavItem
            {...item}
            data={data}
            key={item.to}
            isAlwaysEnabled={!item.dependency}
          />
        );
      })}
    </LinearTabs>
  );
});

const TabNav = () => (
  <View
    currentUser={StateHandlers.currentUser}
    core={StateHandlers.core}
    rds={StateHandlers.rds}
    connectivity={StateHandlers.connectivity}
    dns={StateHandlers.dns}
  />
);

export default TabNav;
