import * as R from 'ramda';
import { IBackup } from 'backup';
import { confirm, numberToCurrency } from 'utils';
import {
  backupNamespaces,
  DAILY_INTERVAL_OPTIONS,
  DAILY_RETENTION_OPTIONS,
  DAILY_START_TIME_OPTIONS,
  MONTHLY_RETENTION_OPTIONS,
  WEEKLY_RETENTION_OPTIONS,
  WEEKLY_START_DAY_OPTIONS,
} from 'enums';
import { ValueType } from 'global-shapes';

function findBackupOptions(
  value: any,
  options: ValueType[]
): ValueType | undefined {
  return R.find<ValueType>(R.propEq('value', value))(options);
}

export function parseBackupToForm(backup: IBackup | null): BackupTypes.State {
  const dailyJobSchedule = !!backup?.dailyJob;
  const weeklyJobSchedule = !!backup?.weeklyJob;
  const monthlyJobSchedule = !!backup?.monthlyJob;

  return {
    dailyJobSchedule,
    weeklyJobSchedule,
    monthlyJobSchedule,
    dailyHours: dailyJobSchedule
      ? findBackupOptions(
          backup?.dailyJob?.dailyScheduleOptions.interval,
          DAILY_INTERVAL_OPTIONS
        )
      : DAILY_INTERVAL_OPTIONS[4],
    dailyFrom: dailyJobSchedule
      ? findBackupOptions(
          backup?.dailyJob?.dailyScheduleOptions.startTime,
          DAILY_START_TIME_OPTIONS
        )
      : DAILY_START_TIME_OPTIONS[11],
    dailyRetention: dailyJobSchedule
      ? findBackupOptions(
          backup?.dailyJob?.dailyScheduleOptions.retentionDays,
          DAILY_RETENTION_OPTIONS
        )
      : DAILY_RETENTION_OPTIONS[29],
    weeklyDay: weeklyJobSchedule
      ? findBackupOptions(
          backup?.weeklyJob?.weeklyScheduleOptions.dayOfWeek,
          WEEKLY_START_DAY_OPTIONS
        )
      : WEEKLY_START_DAY_OPTIONS[1],
    weeklyWeeks: weeklyJobSchedule
      ? findBackupOptions(
          backup?.weeklyJob?.weeklyScheduleOptions.retentionWeeks,
          WEEKLY_RETENTION_OPTIONS
        )
      : WEEKLY_RETENTION_OPTIONS[1],
    monthlyMonths: monthlyJobSchedule
      ? findBackupOptions(
          backup?.monthlyJob?.monthlyScheduleOptions.retentionMonths,
          MONTHLY_RETENTION_OPTIONS
        )
      : MONTHLY_RETENTION_OPTIONS[1],
  };
}

export function parseBackupStateToForm(
  state: BackupTypes.State & BackupTypes.BackupParams
): BackupTypes.CreatePayload {
  const {
    entityId,
    entityName,
    dailyJobSchedule,
    weeklyJobSchedule,
    monthlyJobSchedule,
    monthlyMonths,
    dailyFrom,
    dailyHours,
    dailyRetention,
    weeklyWeeks,
    weeklyDay,
  } = state;

  const payload: BackupTypes.CreatePayload = {
    entityId,
    entityName,
  };

  if (dailyJobSchedule) {
    payload.dailyJobSchedule = {
      startTime: dailyFrom.value,
      retentionDays: dailyRetention.value,
      interval: dailyHours.value,
    };
  }

  if (weeklyJobSchedule) {
    payload.weeklyJobSchedule = {
      retentionWeeks: weeklyWeeks.value,
      dayOfWeek: weeklyDay.value,
    };
  }

  if (monthlyJobSchedule) {
    payload.monthlyJobSchedule = {
      retentionMonths: monthlyMonths.value,
    };
  }

  return payload;
}

export function parseBackupUpdateStateToForm(
  state: BackupTypes.State
): BackupTypes.UpdatePayload {
  const {
    dailyJobSchedule,
    weeklyJobSchedule,
    monthlyJobSchedule,
    monthlyMonths,
    dailyFrom,
    dailyHours,
    dailyRetention,
    weeklyWeeks,
    weeklyDay,
  } = state;

  const payload: BackupTypes.UpdatePayload = {};

  if (dailyJobSchedule) {
    payload.dailyJobSchedule = {
      startTime: dailyFrom.value,
      retentionDays: dailyRetention.value,
      interval: dailyHours.value,
    };
  }

  if (weeklyJobSchedule) {
    payload.weeklyJobSchedule = {
      retentionWeeks: weeklyWeeks.value,
      dayOfWeek: weeklyDay.value,
    };
  }

  if (monthlyJobSchedule) {
    payload.monthlyJobSchedule = {
      retentionMonths: monthlyMonths.value,
    };
  }

  return payload;
}

export function hasDeletedOption(
  initials: BackupTypes.State,
  current: BackupTypes.State
): boolean {
  let hasDeletedItem = false;

  backupNamespaces.forEach((key) => {
    if (!!initials[key] && !current[key]) {
      hasDeletedItem = true;
    }
  });

  return hasDeletedItem;
}

export function showNoBillingAlertPopup() {
  return confirm({
    title: 'services.backup.notify.noUsageEnabled.title',
    content: 'services.backup.notify.noUsageEnabled.content',
    onSuccess: () => undefined,
    successLabel: 'common.ok',
  });
}

export const displayPriceFormat = (value: number) =>
  numberToCurrency(value, false, {
    maximumFractionDigits: 2,
  });

export function parseVmDetailsFoCalculation(
  vm: IVmTypes.Vm | null
): VmDialogTypes.IVmFormValues {
  const { interval } = vm?.billingCycle || {};

  const nextBillingCycleInterval = vm?.billingCycle
    ? {
        value: interval,
        label: `costsInfo.options.billingCircle.${interval}`,
      }
    : {
        value: 'NONE',
        label: `costsInfo.options.billingCircle.MONTH`,
      };

  return {
    id: vm?.id,
    name: vm?.name || '',
    description: vm?.description || '',
    virtualCpus: vm?.virtualCpus || 0,
    isUsageEnabled: !!vm?.isUsageEnabled,
    ramMb: vm?.ramMb || 0,
    osDiskSizeGb: vm?.osDiskSizeGb || 0,
    ports: vm?.services || [],
    // @ts-ignore
    disks: vm?.disks || [],
    isPublic: vm?.networkType === 'PUBLIC',
    billingCycleInterval: nextBillingCycleInterval as any,
    templateId: {
      value: vm?.os?.id || 0,
      label: vm?.os?.name || '',
      familyId: vm?.os?.osFamilyId,
    },
  };
}
