import { isEmpty } from 'ramda';
import { DefaultListQuery } from 'global-shapes';
import {
  ICustomer,
  ICustomerCreatePayload,
  ICustomerFormValues,
  ICustomerRequestParams,
  IRegisterCustomerPayload,
  ICustomerUpdatePayload,
} from 'customer-shapes';
import { GET, POST, buildListParams, DELETE, PATCH, PUT } from 'utils';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';
import { ICurrentUser } from 'auth-shapes';

export const getTenants = (
  params: DefaultListQuery
): IAxiosPromise<ICustomer[]> =>
  GET(
    '/tenants',
    buildListParams(params, [
      'orderType',
      'orderBy',
      'include',
      'enabledBilling',
      'q',
    ])
  );

export const getTenant = (
  id: number,
  params?: ICustomerRequestParams,
  config?: any
): AxiosPromise<ICustomer> => GET(`/tenants/${id}`, params, config);

export const deleteTenant = (id: number): AxiosPromise<Task> =>
  DELETE(`/tenants/${id}`);

export const create = (
  payload: ICustomerFormValues
): AxiosPromise<ICustomer> => {
  let emails = undefined;
  let address = undefined;

  if (payload.billingEmailsEnabled) {
    emails = isEmpty(payload.emails) ? undefined : payload.emails;
    address = {
      line1: payload.line1,
      line2: payload.line2,
      city: payload.city,
      countryCode: payload.countryCode.value,
      zipCode: payload.zipCode,
    };
  }

  const tenant: ICustomerCreatePayload = {
    shortName: payload.shortName,
    orgDomain: payload.orgDomain?.trim() || undefined,
    name: payload.name,
    ...(payload.shouldUserValidate
      ? {
          billingEmailsEnabled: payload.billingEmailsEnabled,
          emails,
          address,
        }
      : {}),
  };

  return POST('/tenants', {}, tenant);
};

export const updateTenantPartially = (
  id: number,
  data: ICustomerUpdatePayload,
  config?: any
): AxiosPromise<ICustomer> => PATCH(`/tenants/${id}`, {}, data, config);

export const logInUnderTenant = (id: number, tkn: string): AxiosPromise =>
  POST(
    `/auth/tenant/${id}/session`,
    {},
    {},
    { headers: { Authorization: `Bearer ${tkn}` }, shouldUpdate: true }
  );

export function registerSignUp(
  inviteKey: string,
  data: IRegisterCustomerPayload
): AxiosPromise<ICurrentUser> {
  return POST('/tenants/signup', { inviteKey }, data);
}

export function undoDeletion(id: number): AxiosPromise<ICustomer> {
  return DELETE(`/tenants/${id}/undo`);
}

export function getCurrentTenantBillingRate(
  id: number
): AxiosPromise<IDedicatedServer.BillingRate> {
  return GET(`/tenants/${id}/billing-rate`);
}

export function updateCurrentTenantBillingRate(
  id: number,
  payload: { rate: number }
): AxiosPromise<IDedicatedServer.BillingRate> {
  return PUT(`/tenants/${id}/billing-rate`, {}, payload);
}
