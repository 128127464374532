import * as Yup from 'yup';
import { validator } from 'utils';
import isURL from 'validator/lib/isURL';
import { IVersionTypes } from './types';

export const INITIAL_STATE: IVersionTypes.IValues = {
  version: '',
  versionLinkName: '',
  versionLinkUrl: '',
};

export const validationSchema = Yup.object({
  version: Yup.string()
    .trim()
    .required('forms.required')
    .max(100, 'forms.invalid.max')
    .test({
      message: 'forms.invalid',
      test: (val) => validator.onlyNumbersWithDot(val || ''),
    }),
  versionLinkName: Yup.string()
    .trim()
    .required('forms.required')
    .max(100, 'forms.invalid.max'),
  versionLinkUrl: Yup.string()
    .trim()
    .required('forms.required')
    .test({ message: 'forms.invalid.url', test: (val) => isURL(val || '') }),
});
