import * as Yup from 'yup';
import { dayjs } from 'utils';

export function defineValidationSchema(step: number) {
  switch (step) {
    case 0:
      return Yup.object().shape({
        alertEmailRecipient: Yup.string().when(['operation'], {
          is: (operation: ValueType<IBulkScheduleUpdateDialog.IOperation>) =>
            operation.value !== 'rdsReboot',
          then: Yup.string()
            .trim()
            .email('forms.emailInvalid')
            .required('forms.required'),
        }),
        startDate: Yup.date()
          .test({
            message: 'forms.invalid.date',
            test(_startDate) {
              const maximumDate = dayjs().add(30, 'days').endOf('day');
              const minimumDate = dayjs();

              const startDate = dayjs(_startDate);

              const validDate =
                minimumDate.diff(startDate, 'days') > 0 ||
                maximumDate.diff(startDate, 'day') >= 0;

              return validDate;
            },
          })
          .test({
            message: 'forms.invalid.date.isEarlierThanNow',
            test(startDate) {
              const finalDate = dayjs(startDate);
              const validDate = !(+dayjs().add(15, 'minutes') > +finalDate);

              return validDate;
            },
          }),
      });
    case 1:
      return Yup.object().shape({
        selected: Yup.array(Yup.object()).required('forms.required'),
      });

    default:
      return Yup.object().shape({});
  }
}

export function parsePatchingToPayload(
  operation: IBulkScheduleUpdateDialog.IOperation,
  patchings: IBulkPatchingTypes.IAllGroupedInstances[],
  mw: IBulkScheduleUpdateDialog.IPayload
) {
  if (operation === 'core') {
    return {
      ciPatchings: patchings.map((inst) => {
        return {
          alertEmailRecipient: mw?.alertEmailRecipient,
          maintenanceWindows: [
            {
              startAt: mw?.startDate,
              isForced: false,
              notificationEnabled: !!mw.informUsers,
              recurringInterval:
                mw?.recurring?.value === 'SINGLE' ? null : mw?.recurring?.value,
              message: '',
            },
          ],
          ciId: Number(inst.id),
        };
      }),
    };
  }

  if (operation === 'rdsPatching') {
    return {
      rdsPatchings: patchings.map((inst) => {
        return {
          alertEmailRecipient: mw?.alertEmailRecipient,
          maintenanceWindows: [
            {
              startAt: mw?.startDate,
              isForced: false,
              recurringInterval:
                mw?.recurring?.value === 'SINGLE' ? null : mw?.recurring?.value,
              message: '',
            },
          ],
          outsourceManagementEnabled: false,
          rdsId: Number(inst.id),
        };
      }),
    };
  }

  if (operation === 'rdsReboot') {
    return {
      rdsReboots: patchings.map((inst) => {
        return {
          maintenanceWindows: [
            {
              startAt: mw?.startDate,
              isForced: false,
              notificationEnabled: !!mw.informUsers,
              recurringInterval:
                mw?.recurring?.value === 'SINGLE' ? null : mw?.recurring?.value,
              message: '',
            },
          ],
          rdsId: Number(inst.id),
          replaceAllMaintenanceWindows: mw?.replaceRebootRules,
        };
      }),
    };
  }
}
