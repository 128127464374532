import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'elements';
import { useState } from 'hooks';
import { confirm } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import FirewallRulesAllDialog from './FirewallRulesAllDialog';
import FirewallRuleDetails from './FirewallRuleDetails';
import FirewalRulesContent from './FirewallRulesContent';
import * as HELPERS from '../helpers';

type IProps = {
  connectivityId?: number;
};

const OBSERVERS = {
  firewallRules: StateHandlers.firewallRules,
};

type IViewProps = typeof OBSERVERS;

type IState = {
  allRulesOpen: boolean;
  selectedRule?: Partial<IConnectivityTypes.IFirewallRuleFormValues>;
};

const View = observer((props: IProps & IViewProps) => {
  const { connectivityId, firewallRules } = props;
  const { t } = useTranslation();
  const [state, handleState] = useState<IState>({
    allRulesOpen: false,
    selectedRule: undefined,
  });

  const handleDialogOpen = React.useCallback(
    (allRulesOpen: boolean) => () => {
      handleState({ allRulesOpen });
    },
    []
  );

  const onEdit = React.useCallback((rule: IConnectivityTypes.IFirewallRule) => {
    handleState({ selectedRule: HELPERS.parseFWRuleToFormValues(rule) });
  }, []);

  const onDelete = React.useCallback(
    (rule: IConnectivityTypes.IFirewallRule) => {
      return confirm({
        title: t('services.connectivity.firewallRules.confirm.remove.title'),
        content: t(
          'services.connectivity.firewallRules.confirm.remove.content'
        ),
        onCancel: () => undefined,
        onSuccess: async () => {
          await firewallRules.remove({ connectivityId, id: rule.id });
          return firewallRules.get({ connectivityId });
        },
      });
    },
    [connectivityId]
  );

  const showMore = firewallRules.data.length > 10;

  return (
    <>
      <Row alignItems="center" justifyContent="space-between" className="mb-15">
        <Col>
          <div className="uppercase fs-14 bolder mb-20">
            {t('services.connectivity.firewallRules.title')}
          </div>
        </Col>
        <Col>
          <Button
            size="small"
            onClick={() => handleState({ selectedRule: {} })}
          >
            {t('services.connectivity.firewallRules.buttons.addRule')}
          </Button>
        </Col>
      </Row>
      <div className="mb-35">
        <FirewalRulesContent
          connectivityId={connectivityId}
          onDelete={onDelete}
          onEdit={onEdit}
          showAll={false}
        />
        {showMore && (
          <Row justifyContent="center" className="mt-20">
            <Button
              color="default"
              variant="outlined"
              size="small"
              onClick={handleDialogOpen(true)}
            >
              {t('services.connectivity.firewallRules.buttons.viewMore')}
            </Button>
          </Row>
        )}
      </div>
      <FirewallRulesAllDialog
        open={state.allRulesOpen}
        onClose={handleDialogOpen(false)}
        onDelete={onDelete}
        onEdit={onEdit}
        connectivityId={connectivityId}
        onSave={() => undefined}
      />

      <FirewallRuleDetails
        open={!!state.selectedRule}
        onClose={() => handleState({ selectedRule: undefined })}
        initialValues={
          state.selectedRule as IConnectivityTypes.IFirewallRuleFormValues
        }
        onSave={async (
          val: IConnectivityTypes.IFirewallRuleFormValues,
          conf
        ) => {
          if (conf.isNew) {
            await firewallRules.create(
              {
                connectivityId,
                ...HELPERS.parseFRulePayload(val),
              },
              true
            );
          } else {
            await firewallRules.update(
              { connectivityId, id: state.selectedRule?.id },
              HELPERS.parseFRulePayload(val)
            );
          }

          return firewallRules.get({ connectivityId });
        }}
      />
    </>
  );
});

const FirewallRulesContainer = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default FirewallRulesContainer;
