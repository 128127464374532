import * as React from 'react';
import { useFormik } from 'formik';
import { Emitter } from './Emitter';
import { IConfirmDeleteReasonsRes } from './types';
import { Input } from '../elements/Input';
import Select from '../elements/Select';
import { CustomEvents } from 'enums/customEvents';
import {
  defaultDeleteInputSchema,
  defaultDeleteWithReasonsSchema,
} from 'enums/validation';
import { IConfirmProps } from './types';

const REASONS_OF_DELETION = [
  {
    value: 'Project ended',
    label: 'app.deletionOptions.reason1',
  },
  {
    value: 'Product is no longer used',
    label: 'app.deletionOptions.reason2',
  },
  {
    value: 'Not satisfied with the product',
    label: 'app.deletionOptions.reason3',
  },
  {
    value: 'Product too expensive',
    label: 'app.deletionOptions.reason4',
  },
  {
    value: 'Moving to another provider',
    label: 'app.deletionOptions.reason5',
  },
  {
    value: 'other',
    label: 'app.deletionOptions.other',
  },
];

export const confirm = (props: IConfirmProps) =>
  Emitter.emit(CustomEvents.confirmDialog, props);

export const confirmDelete = (props: IConfirmProps) =>
  confirm({
    validationSchema: defaultDeleteInputSchema,
    ...props,
    initialValues: {
      input: '',
    },
    content: (form: ReturnType<typeof useFormik>) => (
      <>
        <div className="steel fs-14">{props.content}</div>
        <div className="pt-25">
          <Input
            label={props.label}
            placeholder={
              props.placeholder || 'app.confirm.typeDelete.placeholder'
            }
            value={form.values.input}
            name="input"
            onChange={form.handleChange}
            error={!!(form.submitCount && form.errors.input)}
            helperText={form.errors.input}
            helperTextOptions={props.helperTextOptions}
          />
        </div>
      </>
    ),
  });

export function parseConfirmDeleteWithReasonResponse({
  reason,
  other,
}: IConfirmDeleteReasonsRes): string {
  return (reason?.value === 'other' ? other?.trim() : reason?.value) || '';
}

export const confirmDeleteWithReasons = (props: IConfirmProps) =>
  confirm({
    ...props,
    initialValues: {
      input: '',
      reason: undefined,
      other: '',
    },
    validationSchema: defaultDeleteWithReasonsSchema('DELETE'),
    content: (form: ReturnType<typeof useFormik>) => (
      <>
        <div className="steel fs-14">{props.content}</div>
        <div className="pt-25">
          <div className="mb-20">
            <Input
              label={props.label}
              placeholder={
                props.placeholder || 'app.confirm.typeDelete.placeholder'
              }
              value={form.values.input}
              name="input"
              onChange={form.handleChange}
              error={!!(form.submitCount && form.errors.input)}
              helperText={form.errors.input}
              helperTextOptions={props.helperTextOptions}
            />
          </div>
          <div className="mb-20">
            <Select
              name="reason"
              value={form.values.reason}
              label="forms.deletionReason"
              placeholder="forms.placeholders.deletionReason"
              options={REASONS_OF_DELETION}
              onChange={(reason) => form.setFieldValue('reason', reason)}
              error={!!(form.submitCount && form.errors.reason)}
              helperText={form.errors.reason}
            />
          </div>
          {form.values.reason?.value === 'other' && (
            <div>
              <Input
                label="forms.reasonText"
                placeholder="forms.placeholders.reasonText"
                value={form.values.other}
                name="other"
                multiline
                minRows={4}
                onChange={form.handleChange}
                error={!!(form.submitCount && form.errors.other)}
                helperText={form.errors.other}
                helperTextOptions={{
                  max: 300,
                }}
              />
            </div>
          )}
        </div>
      </>
    ),
  });
