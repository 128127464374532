import React from 'react';
import cn from 'classnames';
import { Outlet, useLocation, Location } from 'react-router-dom';
import {
  buildRdsNavItems,
  RDS_DETAILS_NAV_ITEMS_PARAMS,
  RDS_ACCOUNTS_DEFAULT_PARAMS,
} from 'enums';
import { Row, Col, Loader, Alert } from 'elements';
import LeftBarNavigation, {
  CItemRemove,
  CListItem,
} from 'components/LeftBarNavigation';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import {
  confirmDeleteWithReasons,
  parseConfirmDeleteWithReasonResponse,
  IConfirmDeleteReasonsRes,
} from 'utils';
import { useTask, useUserHash, usePermissions } from 'hooks';

const findIndexRoute = (hash: string, location: Location): boolean => {
  const ALL_RDS_ROUTES = [
    '/services/rds/accounts',
    '/services/rds/apps',
    '/services/rds/backup',
    '/services/rds/monitor',
    '/services/rds/maintenance',
    '/services/rds/reboot',
    '/services/rds/patching',
  ];
  const currentRoute = location.pathname.replace(`/${hash}`, '');
  return ALL_RDS_ROUTES.some((el) => el === currentRoute);
};

const OBSERVERS = {
  rds: StateHandlers.rds,
  costBreakdown: StateHandlers.costBreakdown,
  tenantPricing: StateHandlers.tenantPricing,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { tenantPricing, costBreakdown, rds } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const { permissionsAll } = usePermissions('MONITORING');
  const [hash, changeUrl] = useUserHash();

  const fetchMyOtherData = React.useCallback(async () => {
    await tenantPricing.get();
    await costBreakdown.get();
  }, []);

  const task = useTask(rds.data?.task || null, {
    onComplete: (action) => {
      rds.get();
      fetchMyOtherData();
      action === 'delete' && changeUrl('/services/all');
    },
  });

  const isDeployTask = ['create', 'delete'].includes(task.action);
  const failedSetup = isDeployTask && task.isFailed;

  const deleteService = React.useCallback((res: IConfirmDeleteReasonsRes) => {
    rds.remove(parseConfirmDeleteWithReasonResponse(res)).then(() => {
      rds.get();
      changeUrl('/services/all');
    });
  }, []);

  const isOnValidRoute = findIndexRoute(hash, location);

  React.useEffect(() => {
    if (!isOnValidRoute) {
      changeUrl('/services/rds/accounts', RDS_ACCOUNTS_DEFAULT_PARAMS);
    }
  }, [isOnValidRoute]);

  if (!rds.dataReceived) {
    return <Loader />;
  }

  if (!rds.data) {
    return <Alert severity="warning">{t('services.core.noCoreMessage')}</Alert>;
  }

  return (
    <Row
      columnSpacing={2}
      className={cn({ disabled: task.isTaskActive || rds.isRequesting })}
    >
      <Col xs={12} sm={3}>
        <LeftBarNavigation
          tHash="services.rds"
          items={buildRdsNavItems({
            permissions: permissionsAll,
          })}
          params={RDS_DETAILS_NAV_ITEMS_PARAMS}
          baseUrl="services/rds"
          footer={
            failedSetup ? (
              <CListItem
                className="full-width"
                onClick={() =>
                  confirmDeleteWithReasons({
                    title: t('services.rds.confirm.delete.title'),
                    content: t('services.rds.confirm.delete.subtitle'),
                    placeholder: t('app.confirm.typeDelete.placeholder'),
                    successLabel: 'common.remove',
                    cancelLabel: 'common.cancel',
                    onSuccess: deleteService,
                    onCancel: () => undefined,
                  })
                }
              >
                <CItemRemove className="full-width pointer">
                  {t('common.removeService')}
                </CItemRemove>
              </CListItem>
            ) : null
          }
        />
      </Col>
      <Col xs={12} sm={9}>
        <Outlet />
      </Col>
    </Row>
  );
});

const RdsDetails = () => <View {...OBSERVERS} />;

export default RdsDetails;
