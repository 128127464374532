import { showSystemMessage, mapPermissions } from 'utils';
import { rolesService } from 'services';
import { Role } from 'roles-shapes';
import { currentUser } from './currentUser';
import { BaseMobxInstanceDecorator } from './utilities';

export const roles = new BaseMobxInstanceDecorator<Role, true>({
  instanceName: 'roles',
  requests: {
    get: rolesService.getRoles,
    create: async (payload) => {
      const res = await rolesService.create(payload);
      showSystemMessage('roles.create.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const res = await rolesService.update(id as number, payload);

      const isCurrentUserRole = currentUser.data?.role.id === id;

      showSystemMessage('roles.update.success', 'success');

      if (isCurrentUserRole) {
        currentUser.merge({
          permissions: mapPermissions(payload.permissions),
        });
      }

      return res;
    },
    remove: async (id) => {
      const res = await rolesService.deleteRole(id as number);
      showSystemMessage('roles.delete.success', 'success');
      return res;
    },
  },
  initialState: [],
});
