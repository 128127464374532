import * as React from 'react';
import BillableItemsComponent from './BillableItemsContainer';
import { withPermissions } from 'hocs';
import { IBIAccessLevel } from './constants';

const WithPermissionsBillableItemsComponent = withPermissions<{
  level: IBIAccessLevel;
}>(BillableItemsComponent, 'BILLABLE_ITEMS');

export const BillableItems = (props: { level?: IBIAccessLevel }) => {
  return <WithPermissionsBillableItemsComponent {...props} />;
};
