import io, { Socket } from 'socket.io-client';
import qs from 'query-string';
import { SimpleVmTask, EventTask } from 'ws-service';
import { getCurrentToken } from '../session';
import { SocketEvents } from './constants';
import { CustomEvents } from 'enums';
import { ISocketContextParams, ISessionDeletedEventPayload } from '../types';
import { getCurrentSessionId } from '../session';
import { logOut } from '../fetcher/logOut';
import { Emitter } from '../Emitter';

class CloudSocket {
  public socket?: Socket = undefined;
  public context?: ISocketContextParams = undefined;
  public init = (_token?: string, context?: ISocketContextParams) =>
    new Promise((resolve, reject) => {
      const token = _token || getCurrentToken();

      this.context = context;
      const query = qs.stringify({ accessToken: token, ...context });

      const url = `${process.env.REACT_APP_SOCKET_SERVER_URL}?${query}`;

      this.socket = io(url, {
        transports: ['websocket'],
        reconnection: true,
        autoConnect: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 3000,
        reconnectionDelayMax: 5000,
        timeout: 10000,
      });

      this.socket.on(SocketEvents.connect, (...args: any[]) => {
        console.log('connected', this.socket, ...args);
        // @ts-ignore
        resolve(...args);
      });

      this.socket.on(SocketEvents.disconnected, (reason: string) => {
        console.log('SocketEvents.disconnected', reason);
      });

      this.socket.on(SocketEvents.connectError, (er: any) => {
        console.log(
          `Could not connect to server ${process.env.REACT_APP_SOCKET_SERVER_URL}`,
          this.socket,
          er
        );
        reject(er);
      });

      awesomeCloudSockets.socket?.on(
        SocketEvents.taskUpdated,
        (task: EventTask) => {
          const { message, ...restTask } = task;
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Event ${SocketEvents.taskUpdated} fired -> `, task);
          }

          Emitter.emit(CustomEvents.updateTask, restTask);
        }
      );

      awesomeCloudSockets.socket?.on(
        SocketEvents.taskProgressUpdated,
        (task: EventTask) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(
              `Event ${SocketEvents.taskProgressUpdated} fired -> `,
              task
            );
          }
          Emitter.emit(CustomEvents.updateTaskList, task);
        }
      );

      awesomeCloudSockets.socket?.on(
        SocketEvents.vmUpdated,
        (task: SimpleVmTask) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Event ${SocketEvents.vmUpdated} fired -> `, task);
          }

          Emitter.emit(CustomEvents.updateVm, task);
        }
      );

      awesomeCloudSockets.socket?.on(
        SocketEvents.coreUpdated,
        (task: SimpleVmTask) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Event ${SocketEvents.coreUpdated} fired -> `, task);
          }

          Emitter.emit(CustomEvents.refetchCore, task);
        }
      );

      awesomeCloudSockets.socket?.on(
        SocketEvents.rdsUpdated,
        (task: SimpleVmTask) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Event ${SocketEvents.rdsUpdated} fired -> `, task);
          }

          Emitter.emit(CustomEvents.refetchRds, task);
        }
      );

      awesomeCloudSockets.socket?.on(
        SocketEvents.sessionDeleted,
        (ev: ISessionDeletedEventPayload) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Event ${SocketEvents.sessionDeleted} fired -> `, ev);
          }
          if (ev.sessionId === getCurrentSessionId()) {
            return logOut();
          }
        }
      );

      awesomeCloudSockets.socket?.on(
        SocketEvents.stripeAccountConnected,
        () => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Event ${SocketEvents.stripeAccountConnected} fired `);
          }

          Emitter.emit(CustomEvents.refreshAccount, {});
        }
      );

      return this.socket;
    });

  public isConnected = () => {
    return !!this.socket?.connected;
  };

  public disconnect = () => {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = undefined;
      this.context = undefined;
    }
    return 'disconnected';
  };
}

export const awesomeCloudSockets = new CloudSocket();
