import * as Yup from 'yup';
import { validator } from 'utils';

export const INITIAL_STATE: IBadgeTypes.IState = {
  detailsDialogOpen: false,
  selected: undefined,
};

export const validationSchema = Yup.object().shape({
  description: Yup.string().max(60, 'forms.invalid.max'),
  color: Yup.string().test(
    'color',
    'forms.invalid.hex',
    validator.isHex as any
  ),
});
