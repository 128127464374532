import { GET, buildListParams } from 'utils';

export const getEntities = (
  params: ISearchEntity.RequestParams
): ISearchEntity.Request =>
  // @ts-ignore
  GET(
    '/app-entities',
    buildListParams(params, ['q', 'entity', 'groupByEntity'])
  );
