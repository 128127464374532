import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ValueType } from 'global-shapes';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import PopupState, {
  bindMenu,
  bindTrigger,
  bindHover,
  bindPopover,
} from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { IAutomationTestProps } from 'automation-test';
import { isString } from 'utils';
import { MuiIcons, Popover, List, IconButton } from 'elements';

export type IItemMenuClick = (action: string, el: ValueType<any>) => void;

type Props = {
  testId?: string;
  disabled?: boolean;
  items: ValueType<string | ValueType<string>[]>[];
};

const CardMenuItems = (props: Props & IAutomationTestProps) => {
  const { testId, items, disabled } = props;
  const { t } = useTranslation();
  const innerPopupState = usePopupState({
    variant: 'popover',
    popupId: 'innerPopover',
  });

  return (
    <PopupState variant="popover">
      {(popupState) => {
        const config = bindTrigger(popupState);
        return (
          <React.Fragment key={`${testId}-menu-base-popover`}>
            <IconButton
              size="small"
              {...config}
              data-draggable="ignore"
              className="_with_inner_layer"
            >
              <MuiIcons.MoreVert
                className={cn('fs-18 steel pointer', { disabled })}
                data-test-id={`${testId}-menu-dots`}
                key={`${testId}-menu-dots`}
                data-draggable="ignore"
              />
            </IconButton>
            <Popover {...bindMenu(popupState)} key={`${testId}-menu-popover`}>
              <List data-test-id={`${testId}-menu`} className="fs-15">
                {items.map((item, index) => {
                  if (isString(item.value)) {
                    if (item.value === 'devider') {
                      return (
                        <Divider key={`${testId}-${index}-single-devider`} />
                      );
                    }
                    return (
                      <ListItemButton
                        key={`${item.value as string}-${index}-single`}
                        data-test-id={`${item.value}-menu-item`}
                        data-draggable="ignore"
                        disabled={item.disabled}
                        component="div"
                        onClick={() => {
                          if (item.disabled) return;
                          item.onClick && item.onClick();
                          popupState.close();
                        }}
                      >
                        {t(`services.content.action.${item.label}`)}
                      </ListItemButton>
                    );
                  }

                  const { disableAutoFocus, ...hoverPopoverProps } =
                    bindPopover(innerPopupState);

                  return (
                    <React.Fragment key={`${item.label}-${index}-menu-frag`}>
                      <ListItemButton
                        {...bindHover(innerPopupState)}
                        key={`${item.label}-${index}-menu`}
                        data-test-id={`${item.label}-menu-item`}
                        disabled={item.disabled}
                        data-draggable="ignore"
                        component="div"
                        className="flex justify-between align-center"
                      >
                        {t(`services.content.action.${item.label}`)}
                        <MuiIcons.ChevronRight
                          className="fs-22 steel pointer"
                          data-test-id={`${testId}-menu-inner-dots`}
                          data-draggable="ignore"
                        />
                      </ListItemButton>

                      <HoverPopover
                        {...hoverPopoverProps}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        data-test-id={`${testId}-menu-inner`}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        key={`${item.label}-hover-popover-inner`}
                        data-draggable="ignore"
                      >
                        <List
                          data-test-id={`${testId}-inner-menu`}
                          data-draggable="ignore"
                        >
                          {/* @ts-ignore */}
                          {item.value.map((el: any, ind: number) => {
                            const { value, label, disabled } = el;
                            if (value === 'devider') {
                              return (
                                <Divider
                                  key={`${testId}-${item.label}-${ind}-devider`}
                                />
                              );
                            }
                            return (
                              <ListItemButton
                                key={`${testId}-${item.label}-${ind}-${label}`}
                                data-test-id={`${label}-menu-item`}
                                disabled={disabled}
                                component="div"
                                data-draggable="ignore"
                                onClick={() => {
                                  if (disabled) return;
                                  el.onClick && el.onClick(value, el);
                                  popupState.close();
                                  innerPopupState.close();
                                }}
                              >
                                {label}
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </HoverPopover>
                    </React.Fragment>
                  );
                })}
              </List>
            </Popover>
          </React.Fragment>
        );
      }}
    </PopupState>
  );
};

export default CardMenuItems;
