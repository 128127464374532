import { IRds } from 'rds';

const createRdpContent = (info: IRds) => {
  const textArr = [
    'redirectclipboard:i:1',
    'redirectprinters:i:1',
    'redirectcomports:i:0',
    'redirectsmartcards:i:1',
    'devicestoredirect:s:*',
    'drivestoredirect:s:*',
    'redirectdrives:i:1',
    'session bpp:i:32',
    'prompt for credentials on client:i:1',
    'server port:i:3389',
    'allow font smoothing:i:1',
    'promptcredentialonce:i:1',
    'videoplaybackmode:i:1',
    'audiocapturemode:i:1',
    'gatewayusagemethod:i:2',
    'gatewayprofileusagemethod:i:1',
    'gatewaycredentialssource:i:0',
    `full address:s:${info.webAccessFQDN}`,
    `gatewayhostname:s:${info.externalFQDN}`,
    `workspace id:s:${info.remoteGatewayFQDN}`,
    'use redirection server name:i:1',
    'loadbalanceinfo:s:tsv://MS Terminal Services Plugin.1.Desktop',
    'use multimon:i:1',
    `alternate full address:s:${info.webAccessFQDN}`,
    'camerastoredirect:s:*',
    'redirectposdevices:i:0',
  ];

  return textArr.join('\n');
};

export const downloadRdpFile = (rdsInfo: IRds, filename: string) => {
  const text = createRdpContent(rdsInfo);
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', `${filename}.rdp`);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
