import React from 'react';
import { compact } from '../../utils';
import { ISoftwarePackage } from 'app-store';
import { useTranslation } from 'react-i18next';

const LicenseListDisplay = (props: ISoftwarePackage) => {
  const { t } = useTranslation();
  const licenses = props.licenseSettings
    ? compact(
        Object.keys(props.licenseSettings).map((key: string) =>
          // @ts-ignore
          props.licenseSettings[key] ? key : undefined
        )
      )
    : [];
  return (
    <small className="steel">
      {licenses
        .map((license) => t(`softwarePackages.license.${license}`))
        .join(', ')}
    </small>
  );
};

export default LicenseListDisplay;
