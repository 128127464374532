import React from 'react';
import cn from 'classnames';
import { Col, Row, LinearTable } from 'elements';
import { defaultVmsQuery, VmColumnKeys } from 'enums';
import VirtualMachineCard from '../../services/components/cards/wrappers/VirtualMachine';
import DedicatedServerCard from '../../services/components/cards/wrappers/DedicatedServer';
import { usePermissions, useUserHash, useState, useAccount } from 'hooks';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { getVmTableColumn } from '../vmColumns';
import { VmLoaderSkeleton } from '../Styled';

const OBSERVERS = {
  currentVm: StateHandlers.vmDetails,
  assignments: StateHandlers.badgesAssignment,
  vms: StateHandlers.vms,
  meta: StateHandlers.meta,
  taskManager: StateHandlers.taskManager,
  folderContent: StateHandlers.folderContent,
};

type IViewProps = typeof OBSERVERS;

const VmsView = observer(
  (props: IVmListMainTypes.IInnerContentVmListProps & IViewProps) => {
    const {
      currentVm,
      assignments,
      vms,
      meta,
      taskManager,
      folderContent,
      folder,
    } = props;
    const { t } = useTranslation();
    const [, changeUrl] = useUserHash();
    const [account, , , isRequesting] = useAccount();
    const { permissions } = usePermissions('SERVICES');
    const [q, changeQuery] = useState<IVmTypes.VMParams>(defaultVmsQuery);

    const hasData = !!folderContent.data.length;

    const onOpenDetails = React.useCallback((vm: IVmTypes.Vm) => {
      const isDedicated = !!vm.dedicatedServer;
      const routeNamespace = isDedicated ? 'dedicated' : 'vm';
      changeUrl(`/services/${routeNamespace}/info`, { id: vm.id });
    }, []);

    const reloadCurrentVm = React.useCallback(
      (id: number) =>
        vms.reload(id, { include: ['serviceMonitoring', 'serviceSnapshot'] }),
      []
    );

    const fetchTasks = React.useCallback(() => taskManager.get(), []);

    const fetchVms = React.useCallback(() => {
      const isDedicated = folder?.type === 'DEDICATED_SERVER';
      const type = isDedicated ? 'DEDICATED' : undefined;
      const networkType = isDedicated ? undefined : q.networkType;
      return folderContent.get({
        ...q,
        type,
        networkType,
        include: ['billingCycle', 'serviceMonitoring', 'serviceSnapshot'],
        serviceGroupId: folder?.id,
      });
    }, [JSON.stringify(q), folder?.id, folder?.type]);

    const vmColumns = React.useMemo(
      () =>
        getVmTableColumn({
          t,
          avaliableColumns:
            account?.uiServiceConfig?.vmListColumns || VmColumnKeys,
          permissions,
          isRequesting: currentVm.isRequesting || vms.isRequesting,
          onOpenDetails,
          fetchVms,
          meta: meta.data,
          assignments: assignments.data,
          onResolve: (id: number) => {
            reloadCurrentVm(id);
            fetchTasks();
          },
        }),
      [
        JSON.stringify(meta.data),
        JSON.stringify(assignments.data),
        JSON.stringify(q),
        JSON.stringify(account?.uiServiceConfig?.vmListColumns),
      ]
    );

    React.useEffect(() => {
      fetchVms();
    }, [JSON.stringify(q), JSON.stringify(folder)]);

    const vmDisplayType = account?.uiServiceConfig?.viewType;

    if (!folderContent.dataReceived)
      return <VmLoaderSkeleton animation="wave" variant="rectangular" />;

    if (vmDisplayType === 'list') {
      return (
        <div style={{ overflowX: 'auto' }}>
          <LinearTable
            data={folderContent.data}
            columns={vmColumns}
            params={folderContent.meta}
            onSort={(orderBy, orderType) =>
              changeQuery({ ...q, orderBy, orderType })
            }
            onPageChange={({ page }) => changeQuery({ page })}
            className={cn({
              disabled: folderContent.isRequesting || isRequesting,
            })}
            query={q}
            shouldUseQuery={false}
            usePagination
            hasSorting
          />
        </div>
      );
    }

    if (!hasData) {
      return (
        <div className="p-30 text-center steel fs-14">
          {t('services.card.content.folder.noItems')}
        </div>
      );
    }

    return (
      <Row
        columnSpacing={2}
        className={cn({
          disabled: vms.isRequesting || isRequesting,
        })}
      >
        {folderContent.data.map((vm) => {
          if (vm.type === 'DEDICATED')
            return (
              <Col key={vm.id}>
                <DedicatedServerCard {...vm} dataReceived={vms.dataReceived} />
              </Col>
            );

          return (
            <Col key={vm.id}>
              <VirtualMachineCard {...vm} dataReceived={vms.dataReceived} />
            </Col>
          );
        })}
      </Row>
    );
  }
);

const VmsList = (props: IVmListMainTypes.IInnerContentVmListProps) => (
  <VmsView {...props} {...OBSERVERS} />
);

export default VmsList;
