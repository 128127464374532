import { Permission } from 'auth-shapes';
import { ICoreInfrastructure } from 'core-infrastructure';
import { IRds } from 'rds';
import { buildInitialState } from './constants';

export function patchingMainSelectors(services: {
  currentPatching?: IServicePatching | null;
  instance?: ICoreInfrastructure | IRds;
  isProvider: boolean;
  isPartner: boolean;
  permissions: Permission;
}) {
  const { currentPatching, instance, isProvider, isPartner, permissions } =
    services;

  const maintenanceWindow =
    currentPatching?.maintenanceWindows &&
    currentPatching?.maintenanceWindows[0];

  const outsourceManagementEnabled =
    !!currentPatching?.outsourceManagementEnabled;

  const hasPatching = !!instance?.servicePatching && currentPatching;

  const patchingInitialValues = hasPatching
    ? buildInitialState(currentPatching)
    : ({} as any);

  const patchingOutManagementInitialValues = {
    outsourceManagementEnabled,
    alertEmailRecipient:
      currentPatching?.alertEmailRecipient ||
      maintenanceWindow?.payload?.alertEmailRecipient ||
      '',
  };

  const isEditable = () => {
    if (outsourceManagementEnabled && !(isProvider || isPartner)) {
      return false;
    }
    return permissions.canManage;
  };

  return {
    hasPatching,
    maintenanceWindow,
    patchingInitialValues,
    outsourceManagementEnabled,
    patchingOutManagementInitialValues,
    isEditable: isEditable(),
  };
}
