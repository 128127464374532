import React, { useCallback, useReducer } from 'react';
import { filter } from 'ramda';
import cn from 'classnames';
import { Loader, TablePagination } from 'elements';
import { IDnsCreateResponse, IDNSServer, IDNSZone } from 'dns';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { withPermissions, InjectedPermissionsProps } from 'hocs';
import { useQuery, useUserHash } from 'hooks';
import {
  confirm,
  confirmDelete,
  noop,
  stateReducer,
  handleReducerChange,
  defineDisplayPagination,
} from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { AnyFunc } from 'global-shapes';
import ZoneItem from './ZoneItem';
import DnsSetupDialog from './dialogs/DnsSetup';
import Title from './Title';
import BillingCycleInfo from './BillingCycleInfo';

type IProps = InjectedPermissionsProps &
  React.PropsWithChildren<{
    t: TFunction;
  }>;

const INITIAL_STATE = {
  dialog: null,
  selectedZone: null,
};

const OBSERVERS = {
  dns: StateHandlers.dns,
  zones: StateHandlers.dnsZones,
};

type IDialogTypes = 'zone' | null;

type IState = [
  {
    expanded: number | null;
    dialog: IDialogTypes;
    selectedZone: number | null;
  },
  AnyFunc
];

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { zones, dns, permissions } = props;
  const { t } = useTranslation();
  const { query, queryStr, changeQuery } = useQuery();
  const [, changeUrl] = useUserHash();
  const [expanded, handleExpand] = React.useState<number[]>([]);
  const [state, dispatcher]: IState = useReducer(stateReducer, INITIAL_STATE);
  const handleStateChange = React.useCallback(
    handleReducerChange(dispatcher),
    []
  );

  const zoneQty = zones.data.length;

  const showPagination = defineDisplayPagination(zones.meta?.totalCount, query);

  const fetchZones = React.useCallback(() => {
    zones.get(query);
  }, [queryStr]);

  const confirmDeleteDnsService = React.useCallback(() => {
    confirm({
      title: t('services.dns.confirm.service.title'),
      content: t('services.dns.confirm.service.content'),
      onSuccess: async () =>
        dns.remove(null).then(() => {
          dns.get();
          changeUrl('/services/all');
        }),
      onCancel: () => undefined,
    });
  }, []);

  const removeZone = React.useCallback(
    (id: number) => {
      zones.remove(id).then(() => {
        zones.get(query);
        handleStateChange(INITIAL_STATE);
        dns.get();
      });
    },
    [queryStr, zoneQty]
  );

  const confirmDeleteZone = React.useCallback(
    (zone: IDNSZone) => {
      confirmDelete({
        title: t('services.dns.confirm.domain.delete.title'),
        content: t('services.dns.confirm.domain.delete.content', {
          domainName: zone.domainName,
        }),
        placeholder: 'forms.placeholders.confirm.delete',
        successLabel: 'common.delete',
        cancelLabel: 'common.cancel',
        onSuccess: () => removeZone(zone.id),
        onCancel: () => undefined,
      });
    },
    [removeZone]
  );

  const onOpenDetails = React.useCallback((id: number) => {
    handleExpand((prevState) => {
      return prevState.includes(id)
        ? filter((_id) => id !== _id, prevState)
        : [...prevState, id];
    });
  }, []);

  const onDialogClose = useCallback(
    () => handleStateChange({ dialog: null }),
    []
  );

  const onCreateZone = useCallback(
    (values: any) =>
      dns
        .create(values)
        // @ts-ignore
        .then((zone: IDnsCreateResponse[]) => {
          onDialogClose();
          fetchZones();
          dns.get();
          confirm({
            title: t('services.dialog.dns.confirm.create.success.title'),
            fullWidth: true,
            maxWidth: 'md',
            content: (
              <>
                <div className="mb-20">
                  {
                    t(
                      'services.dialog.dns.confirm.create.success.content'
                    ) as string
                  }
                </div>
                <div className="mb-25">
                  {zone[0]?.servers?.map((server: IDNSServer) => (
                    <div className="bold" key={server}>
                      {server}
                    </div>
                  ))}
                </div>

                <div>
                  {
                    t(
                      'services.dialog.dns.confirm.create.success.content.note'
                    ) as string
                  }
                </div>
              </>
            ),
            cancelLabel: 'common.ok',
            onCancel: noop,
          });
        }),
    []
  );

  React.useEffect(() => {
    fetchZones();
  }, [queryStr]);

  if (!zones.dataReceived) {
    return <Loader />;
  }

  return (
    <div
      className={cn('full-width', {
        disabled: zones.isRequesting,
      })}
    >
      <Title
        t={t}
        permissions={permissions}
        onAddService={() => handleStateChange({ dialog: 'zone' })}
        onDelete={confirmDeleteDnsService}
      />
      <BillingCycleInfo />
      {!zones.data.length && (
        <div className="pt-25 pb-25 text-center fs-14 steel">
          {t('services.dns.domains.noZones') as string}
        </div>
      )}
      {zones.data.map((z: IDNSZone) => (
        <ZoneItem
          key={z.id}
          {...z}
          query={query}
          className="full-width"
          onOpenDetails={onOpenDetails}
          onDelete={confirmDeleteZone}
          expanded={expanded.includes(z.id)}
          isEditable={permissions.canManage}
        />
      ))}
      {showPagination && (
        <TablePagination
          {...query}
          onChange={changeQuery}
          shouldUseQuery
          {...zones.meta}
        />
      )}
      <DnsSetupDialog
        existingZonesCount={dns.data?.zonesQty}
        open={state.dialog === 'zone'}
        onClose={onDialogClose}
        onSave={onCreateZone}
        title={t('services.dns.domains.createDomain.dialog.title')}
        content="services.dns.domains.createDomain.dialog.content"
      />
    </div>
  );
});

const ObservedComponent = (props: IProps) => <View {...props} {...OBSERVERS} />;

const Zones = withPermissions(ObservedComponent, 'SERVICES');

export default Zones;
