import * as React from 'react';
import Drawer from './Drawer';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { usePermissions, useState, useLocalPagination } from 'hooks';
import { remCalc, reformatTaskOptionKey, defineDisplayPagination } from 'utils';
import { IconButton, MuiIcons, TablePagination } from 'elements';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import TaskInfoItem from '../TaskInfoItem';
import { drawerContainer } from './constants';

interface IState {
  page: number;
  perPage: number;
}

type IProps = {
  isEvaluation: boolean;
  isOpen: boolean;
  handleOpen: (open: boolean) => void;
};

const OBSERVERS = {
  currentUser: StateHandlers.currentUser,
  taskManager: StateHandlers.taskManager,
  taskOptions: StateHandlers.taskManagerOptions,
  tasks: StateHandlers.taskManagerList,
};

type IViewProps = typeof OBSERVERS;

const INITIAL_STATE = {
  page: 1,
  perPage: 50,
};

const View = observer((props: IProps & IViewProps) => {
  const {
    isEvaluation,
    taskOptions,
    tasks,
    taskManager,
    currentUser,
    isOpen,
    handleOpen,
  } = props;
  const { isProvider } = usePermissions();
  const { t } = useTranslation();

  const [query, handleQuery] = useState<IState>(INITIAL_STATE);

  const currentLang = currentUser.data?.lang;

  const tasksData = tasks.data || [];

  const dataPaginated = useLocalPagination({ data: tasksData, query });

  const showPagination = defineDisplayPagination(
    taskManager.meta?.totalCount,
    query
  );

  return (
    <Drawer
      isEvaluation={isEvaluation}
      container={drawerContainer}
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={() => handleOpen(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <div className="flex align-center justify-between mb-30 pl-40 pr-30 pt-30 mb-25">
        <h3>{t('notifications.title')}</h3>
        <IconButton
          color="default"
          style={{ marginRight: remCalc(-15) }}
          onClick={() => handleOpen(!isOpen)}
        >
          <MuiIcons.Close className="fs-24" />
        </IconButton>
      </div>
      <div className={cn('pl-40 pr-30 pb-50 _tasksOuter')}>
        {dataPaginated.map((task) => (
          <TaskInfoItem
            {...task}
            key={`${task.id}-${task.entityId}-${task.updatedAt}`}
            lang={currentLang}
            options={taskOptions.data[reformatTaskOptionKey(task)]}
            updateList={() => taskManager.get()}
            isProvider={isProvider}
          />
        ))}

        {showPagination && (
          <TablePagination
            page={query.page}
            perPage={query.perPage}
            totalCount={taskManager.meta.totalCount}
            onChange={({ page }) => handleQuery({ page })}
            shouldUseQuery={false}
          />
        )}
      </div>
    </Drawer>
  );
});

const Dashboard = (props: IProps) => (
  <View
    {...props}
    currentUser={StateHandlers.currentUser}
    taskManager={StateHandlers.taskManager}
    taskOptions={StateHandlers.taskManagerOptions}
    tasks={StateHandlers.taskManagerList}
  />
);

export default Dashboard;
