import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: { translation: {} },
  de: { translation: {} },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'de',
  fallbackLng: 'de',
  debug: false,
  contextSeparator: '.',
  keySeparator: ':',
});

export default i18n;
