import * as React from 'react';
import {
  Row,
  Col,
  Button,
  MuiIcons,
  IconButton,
  Menu,
  MenuItem,
} from 'elements';
import { Permission } from 'auth-shapes';
import { TFunction } from 'i18next';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

type IProps = {
  permissions: Permission;
  onAddService: () => void;
  onDelete: () => void;
  t: TFunction;
};

const Title = (props: IProps) => {
  const { permissions, onDelete, onAddService, t } = props;
  return (
    <Row
      justifyContent="space-between"
      alignItems="center"
      className="full-width pt-10 pb-25"
    >
      <Col>
        <div className="uppercase fs-14 bolder">
          {t('services.dns.domains.title') as string}
        </div>
      </Col>
      {permissions.canManage && (
        <Col>
          <Row alignItems="center" columnSpacing={2}>
            <Col>
              <Button size="small" onClick={onAddService}>
                {t('services.dns.domains.addDomain')}
              </Button>
            </Col>
            <Col>
              <PopupState variant="popover">
                {(popupState) => (
                  <>
                    <IconButton {...bindTrigger(popupState)}>
                      <MuiIcons.MoreVert className="fs-22 steel pointer" />
                    </IconButton>

                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        data-test-id={`dns-menu-item`}
                        onClick={() => {
                          onDelete();
                          popupState.close();
                        }}
                      >
                        {t('services.dns.domains.deleteDns') as string}
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default Title;
