import {
  IDNSRecord,
  IDNSZoneRecordPayload,
  IDNSZoneRecordType,
  IDnsRecordTag,
} from 'dns';
import { TFunction } from 'i18next';
import { YUP_OPTION } from 'enums';
import { validator } from 'utils';
import { find, propEq, pick, takeLast, startsWith } from 'ramda';
import isFQDN from 'validator/lib/isFQDN';
import * as Yup from 'yup';
import isIP from 'validator/lib/isIP';
import { ValueType } from 'global-shapes';
import {
  recordTypesOptions,
  ttlTypeOptions,
  recordTagsOptions,
} from './constants';

const isDomain = (_val: string) => {
  _val = _val || '';
  return isFQDN(_val, { allow_underscores: true });
};

const isValidHostName =
  (domainName: string, isRequired: boolean) => (_val: string) => {
    _val = (_val || '').trim();
    return isFQDN(
      _val ? `${_val}.${domainName}` : isRequired ? '' : domainName,
      {
        allow_underscores: true,
        allow_wildcard: true,
      }
    );
  };

const isValidSRVHostName = (domainName: string) => (_val: string) => {
  _val = (_val || '').trim();
  const parts = _val.split('.');
  const startsCorrectly =
    parts.length >= 2
      ? parts.slice(0, 2).every((str) => startsWith('_', str) && str.length > 1)
      : false;

  return startsCorrectly
    ? isFQDN([_val, domainName].join('.'), {
        allow_underscores: true,
        allow_wildcard: true,
      })
    : false;
};

export const recordsValidationSchema = (domainName: string) =>
  Yup.object({
    name: Yup.string()
      .trim()
      .max(255 - (domainName.length + 3), 'forms.invalid.max')
      .when('type', {
        is: (type: ValueType<string>) => type.value === 'SRV',
        then: Yup.string().required('forms.required').test(
          'name',
          'forms.invalid.domainName',
          // @ts-ignore
          isValidSRVHostName(domainName)
        ),
        otherwise: Yup.string()
          .trim()
          .when('type', {
            is: (type: ValueType<string>) => type.value === 'CNAME',
            then: Yup.string().required('forms.required').test(
              'name',
              'forms.invalid.domainName',
              // @ts-ignore
              isValidHostName(domainName, true)
            ),
            otherwise: Yup.string().test(
              'name',
              'forms.invalid.domainName',
              // @ts-ignore
              isValidHostName(domainName, false)
            ),
          }),
      }),
    type: Yup.object(YUP_OPTION).required('forms.required'),
    ttl: Yup.object(YUP_OPTION).required('forms.required'),
    priority: Yup.number().when('type', {
      is: (type: ValueType<string>) =>
        type && ['MX', 'SRV'].includes(type.value) > false,
      then: Yup.number()
        .min(0, 'forms.invalid.min')
        .max(9999, 'forms.invalid.max')
        .required('forms.required'),
    }),
    weight: Yup.number().when('type', {
      is: (type: ValueType<string>) => (type ? type.value === 'SRV' : false),
      then: Yup.number()
        .min(0, 'forms.invalid.min')
        .max(9999, 'forms.invalid.max')
        .required('forms.required')
        .test({
          message: 'forms.invalid.onlyNumbers',
          // @ts-ignore
          test: (val) => validator.onlyNumbers(val || ''),
        }),
    }),
    port: Yup.number().when('type', {
      is: (type: ValueType<string>) => (type ? type.value === 'SRV' : false),
      then: Yup.number()
        .min(0, 'forms.invalid.min')
        .max(65535, 'forms.invalid.max')
        .required('forms.required')
        .test({
          message: 'forms.invalid.onlyNumbers',
          // @ts-ignore
          test: (val) => validator.onlyNumbers(val || ''),
        }),
    }),
    value: Yup.string()
      .trim()
      .required('forms.required')
      .when('type', {
        is: (type: ValueType<string>) => type && type.value === 'A',
        then: Yup.string()
          .trim()
          .required('forms.required')
          .test('value', 'forms.invalid', (_val) => isIP(_val || '', 4)),
      })
      .when('type', {
        is: (type: ValueType<string>) => type && type.value === 'AAAA',
        then: Yup.string()
          .trim()
          .required('forms.required')
          .test('value', 'forms.invalid', (_val) => isIP(_val || '', 6)),
      })
      .when('type', {
        is: (type: ValueType<string>) =>
          type && ['MX', 'CNAME', 'SRV'].includes(type.value),
        then: Yup.string()
          .trim()
          .required('forms.required')
          // @ts-ignore
          .test('value', 'forms.invalid', isDomain),
      })
      .when('type', {
        is: (type: ValueType<string>) => type && type.value === 'TXT',
        then: Yup.string()
          .trim()
          .required('forms.required')
          .test('value', 'forms.invalid', (val) => {
            return isWrappedCorrectly(val) && getByteSize(val) <= 65535;
          }),
      }),
    tag: Yup.object(YUP_OPTION).when('type', {
      is: (type: ValueType<string>) => type?.value === 'CAA',
      then: Yup.object(YUP_OPTION).required('forms.required'),
    }),
    flag: Yup.string().when('type', {
      is: (type: ValueType<string>) => type?.value === 'CAA',
      then: Yup.string()
        .required('forms.required')
        .test({
          message: 'forms.invalid',
          test: (flag) => !isNaN(+(flag || 0)),
        }),
    }),
  });

const defineFieldsToSave = (type: IDNSZoneRecordType) => {
  const defaultFields = ['name', 'type', 'ttl', 'value'];
  switch (type) {
    case 'SRV':
      return [
        ...defaultFields,
        'priority',
        'port',
        'weight',
        'protocol',
        'service',
      ];
    case 'MX':
      return [...defaultFields, 'priority'];
    case 'CAA':
      return [...defaultFields, 'flag', 'tag'];
    default:
      return defaultFields;
  }
};

const QUOTES = ['"', "'"];

export const isWrappedCorrectly = (_str?: string | null) => {
  _str = _str || '';
  const wrapped = isWrapped(_str);
  if (wrapped) return true;
  return !(QUOTES.includes(_str[0]) || QUOTES.includes(_str[_str.length - 1]));
};

const isSingleQuoteWrapped = (chars: string[]) => {
  return chars[0] === "'" && chars[chars.length - 1] === "'";
};

const isDoubleQuoteWrapped = (chars: string[]) => {
  return chars[0] === '"' && chars[chars.length - 1] === '"';
};

export const isWrapped = (val: string) => {
  const chars = val.split('');

  if (chars.length > 2) {
    return isDoubleQuoteWrapped(chars) || isSingleQuoteWrapped(chars);
  }

  return false;
};

const wrapStrWithQuotes = (_str: string): string => {
  const chars = _str.split('');

  if (isDoubleQuoteWrapped(chars)) {
    return _str;
  }

  if (isSingleQuoteWrapped(chars)) {
    return `"${_str.substring(1, _str.length - 1)}"`;
  }

  return `"${_str}"`;
};

export const parseRecordPayloadToSave = (
  values: IDNSZoneRecordPayload,
  domain: string
) => {
  const prePayload = {
    ...values,
    weight: values.weight ? +values.weight : undefined,
    port: values.port ? +values.port : undefined,
    priority: values.priority ? +values.priority : undefined,
    name: values.name ? `${values.name.trim()}.${domain}` : domain,
    flag: +(values.flag || 0),
    tag: values.tag?.value,
    value:
      values.type?.value === 'TXT'
        ? wrapStrWithQuotes(values.value)
        : values.value,
  };

  return pick(defineFieldsToSave(values.type.value), prePayload);
};

const parseResponseValue = (val: string, type: string) => {
  if (type === 'TXT' && isWrapped(val)) {
    return val.substring(1, val.length - 1);
  }

  if (type === 'SRV' && takeLast(1, val) === '.') {
    return val.slice(0, -1);
  }
  return val;
};

export const parseInitialsForForm = (
  record: IDNSRecord,
  domain: string,
  t: TFunction
): IDNSZoneRecordPayload => {
  const type =
    find(propEq('value', record.type))(recordTypesOptions) ||
    recordTypesOptions[0];

  const ttl =
    find(propEq('value', record.ttl))(ttlTypeOptions) || ttlTypeOptions[2];

  const tag = (find(propEq('value', record.tag))(recordTagsOptions) ||
    recordTagsOptions[0]) as ValueType<IDnsRecordTag>;

  const name = record.name.replace(`.${domain}`, '');

  return {
    name: name === domain ? '' : name,
    // @ts-ignore
    type,
    // @ts-ignore
    ttl,
    value: parseResponseValue(record.value, type.value),
    priority: record.priority ? record.priority.toString() : '',
    weight: record.weight ? record.weight.toString() : '',
    port: record.port ? record.port.toString() : '',
    flag: record.flag || '',
    tag,
  };
};

export const getByteSize = (str?: string | null) => {
  str = str || '';
  return new Blob([str]).size;
};
