import { hostedExchangeService } from 'services';
import { BaseMobxInstanceDecorator } from './utilities';

export const hostedExchange = new BaseMobxInstanceDecorator<any, false>({
  instanceName: 'hex',
  requests: {
    get: hostedExchangeService.get,
    create: hostedExchangeService.create,
    update: hostedExchangeService.update,
    remove: hostedExchangeService.remove,
  },
  initialState: {},
});
