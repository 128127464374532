import * as React from 'react';
import cn from 'classnames';
import { Loader } from 'elements';
import { useState, useTask, usePermissions, useQuery } from 'hooks';
import {
  IMonitoringCreateFormValues,
  IChartLimitsFormValues,
} from 'monitoring';
import { LinearTabs, LinearTabButton } from '../LinearTabs';
import { ValueType } from 'global-shapes';
import { useTranslation } from 'react-i18next';
import { confirm, showSystemMessage } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { withPermissions, InjectedPermissionsProps } from 'hocs';
import { ITaskCallback } from 'task-manager-service';
import Title from './Title';
import EmptyState from './EmptyState';
import FailedState from './FailedState';
import Maintenance from './Maintenance/Maintenance';
import VmFlow from './VmFlow';
import Charts from './Charts/Charts';
import Processes from './Processes/Processes';
import InstanceSelector from './InstanceSelector';
import ServiceTriggerDialog from './ServiceTriggerDialog';
import SetLimitsDialog from './LimitsDialog/SetLimitsDialog';
import { QUERY } from './constants';
import {
  convertTypeToServiceName,
  getDefaultInstanceValue,
  parseLimitsPayloadFromForm,
  gatherLimitsValues,
  defineInitialTab,
} from './helpers';
import * as Types from './types';

const OBSERVERS = {
  monitoring: StateHandlers.monitoring,
  charts: StateHandlers.monitoringCharts,
};

type IProps = Types.IBaseProps & InjectedPermissionsProps;

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    monitoringId,
    entity,
    serviceId,
    fetchService,
    isDisabled,
    vmCount,
    permissions,
    monitoring,
    charts,
    osType,
    instance,
  } = props;
  const { t } = useTranslation();
  const { query, changeQuery } = useQuery();
  const { isProvider, isPartner } = usePermissions();

  const outsourceManagementEnabled =
    monitoring.data?.outsourceManagementEnabled;

  const isLimitsEditable = React.useMemo(() => {
    if (outsourceManagementEnabled && !(isProvider || isPartner)) {
      return false;
    }
    return permissions.canManage;
  }, [
    outsourceManagementEnabled,
    permissions.canManage,
    isProvider,
    isPartner,
  ]);

  const isAllFormEnabled = isLimitsEditable;

  const hasMonitoring = !!monitoringId;

  const instances = monitoring.data?.instances || [];

  const isMultiInstanceService = instances.length > 1;

  const defaultInstance = React.useMemo(
    () => getDefaultInstanceValue(instances),
    [JSON.stringify(instances)]
  );

  const { initialValues: initialDefaultChartValues } = React.useMemo(
    () => gatherLimitsValues(charts.data, monitoring.data?.alert?.emails),
    [
      JSON.stringify(charts.data),
      JSON.stringify(monitoring.data?.alert?.emails),
    ]
  );

  const [state, handleState] = useState<Types.IState>({
    manageBilling: false,
    manageLimits: false,
    tab: 'charts',
    instance: defaultInstance,
  });

  const isChartsEnabled = state.tab === 'charts';
  const isOsServicesEnabled = state.tab === 'services';

  const onComplete = React.useCallback<ITaskCallback>(
    (action) => {
      fetchService().then(() => {
        if (action !== 'delete') {
          if (monitoringId) {
            monitoring.get({ monitoringId, ...QUERY });
          }
        } else {
          monitoring.reset();
          charts.reset();
        }
      });
    },
    [monitoringId, hasMonitoring, fetchService, state.instance.value]
  );

  const task = useTask(monitoring.data?.task, {
    onComplete,
  });

  const isReqesting =
    task.isTaskActive || monitoring.isRequesting || isDisabled;

  const serviceConfig = {
    chartsEnabled: !!monitoring.data?.chartsEnabled,
    servicesEnabled: !!monitoring.data?.servicesEnabled,
    outsourceManagementEnabled,
  };

  const handleManageBilling = React.useCallback(
    (manageBilling: boolean) => () => {
      handleState({ manageBilling });
    },
    []
  );

  const handleManageLimits = React.useCallback(
    (manageLimits: boolean) => () => {
      handleState({ manageLimits });
    },
    []
  );

  const onSaveBillingService = React.useCallback(
    (values: IMonitoringCreateFormValues) => {
      monitoring
        .create(
          {
            ...values,
            serviceEntityId: serviceId,
            serviceEntityName: convertTypeToServiceName(entity),
          },
          true
        )
        .then((res) => {
          handleState({ tab: defineInitialTab(res, state.tab, osType) });
          fetchService();
          monitoringId && monitoring.get({ monitoringId, ...QUERY });
          handleState({ manageBilling: false });
        })
        .catch((er) => {
          showSystemMessage(er.message, 'error');
        });
    },
    [serviceId, monitoringId, entity, state.tab, osType]
  );

  const onDeleteService = React.useCallback(() => {
    confirm({
      title: t('monitoring.confirm.delete.title'),
      content: t('monitoring.confirm.delete.content'),
      onSuccess: () =>
        monitoring.remove(monitoringId).then((_task) => {
          monitoring.merge({ task: _task } as any);
          fetchService().then(() => {
            monitoringId && monitoring.get({ monitoringId, ...QUERY });
          });
          handleState({ manageBilling: false });
        }),
      onCancel: () => undefined,
    });
  }, [monitoring.data?.id, monitoringId, fetchService]);

  const onUpdateBillingService = React.useCallback(
    (values: IMonitoringCreateFormValues) => {
      if (!values.chartsEnabled && !values.servicesEnabled) {
        return onDeleteService();
      }
      monitoring
        .update(monitoringId, values, true)
        .then((res) => {
          handleState({ tab: defineInitialTab(res, state.tab, osType) });
          fetchService().then(() => {
            monitoringId && monitoring.get({ monitoringId, ...QUERY });
          });
          handleState({ manageBilling: false });
        })
        .catch((er) => {
          showSystemMessage(er.message, 'error');
        });
    },
    [monitoringId, entity, onDeleteService, state.tab, osType]
  );

  const onUpdateLimitsService = React.useCallback(
    (alert: IChartLimitsFormValues) => {
      monitoring
        .update(monitoringId, {
          ...serviceConfig,
          alert: parseLimitsPayloadFromForm(alert),
        })
        .then(() => {
          fetchService().then(() => {
            if (monitoringId) {
              monitoring.get({ monitoringId, ...QUERY });
              charts.get({ monitoringId, instanceId: state.instance.value });
            }
          });

          handleState({ manageLimits: false });
        });
    },
    [monitoring.data?.id, entity, monitoringId, state.instance.value]
  );

  const fetchMonitoring = React.useCallback(async () => {
    return monitoringId
      ? await monitoring.get({ monitoringId, ...QUERY })
      : null;
  }, [monitoringId]);

  const onRefresh = React.useCallback(() => {
    return monitoring
      .executeRequest('syncWithZabbix')(monitoringId)
      .then(fetchMonitoring);
  }, [monitoringId]);

  React.useEffect(() => {
    if (hasMonitoring && monitoring.dataReceived) {
      handleState({ instance: getDefaultInstanceValue(instances) });
    }
  }, [hasMonitoring, monitoring.dataReceived]);

  React.useEffect(() => {
    fetchMonitoring();
  }, [monitoringId]);

  React.useEffect(() => {
    return () => {
      monitoring.reset();
      charts.reset();
    };
  }, []);

  React.useEffect(() => {
    if (monitoring.dataReceived) {
      let tab: Types.IState['tab'] = 'charts';
      if (!monitoring.data?.chartsEnabled && monitoring.data?.servicesEnabled) {
        tab = 'services';
      }
      handleState({ tab });
    }
  }, [monitoring.dataReceived]);

  if (
    (entity === 'vm' || entity === 'dedicatedServer') &&
    (task.isCreating || task.isDeleting)
  ) {
    return (
      <VmFlow
        isCreating={task.isCreating}
        isDeleting={task.isDeleting}
        externalInstallCmd={instances[0]?.externalInstallCmd}
        externalUninstallCmd={instances[0]?.externalUninstallCmd}
        osType={osType}
      />
    );
  }

  if (!hasMonitoring) {
    return (
      <div className={cn({ disabled: isReqesting })}>
        <EmptyState
          entity={entity}
          onAdd={handleManageBilling(true)}
          isEditable={permissions.canManage}
        />
        <ServiceTriggerDialog
          initialValues={serviceConfig}
          entity={entity}
          vmCount={vmCount}
          osType={osType}
          open={state.manageBilling}
          onSave={onSaveBillingService}
          isRequesting={monitoring.isRequesting || task.isTaskActive}
          isEditable={permissions.canManage}
          onClose={handleManageBilling(false)}
          instance={instance}
        />
      </div>
    );
  }

  if (!monitoring.dataReceived || (task.isCreating && !task.isFailed)) {
    return <Loader />;
  }

  if (query.page === 'maintenance') {
    return <Maintenance monitoringId={monitoringId} />;
  }

  return (
    <div
      className={cn({
        disabled: isReqesting,
      })}
    >
      <Title
        onManageBilling={handleManageBilling(true)}
        onSetLimits={handleManageLimits(true)}
        showActions={!task.isFailed}
        outsourceManagementEnabled={outsourceManagementEnabled}
        goToMaintenance={() => changeQuery({ page: 'maintenance' })}
      />
      {isMultiInstanceService && (
        <InstanceSelector
          selected={state.instance}
          instances={instances}
          onChange={(instance: ValueType<number>) => handleState({ instance })}
        />
      )}
      {task.isFailed ? (
        <FailedState
          error={task.error?.message}
          errorName={task.error?.name}
          onDelete={onDeleteService}
        />
      ) : (
        <>
          <LinearTabs>
            <LinearTabButton
              isActive={isChartsEnabled}
              onClick={() => handleState({ tab: 'charts' })}
              className={cn({ disabled: !monitoring.data?.chartsEnabled })}
            >
              {t('monitoring.tabs.charts')}
            </LinearTabButton>
            <LinearTabButton
              isActive={isOsServicesEnabled}
              onClick={() => handleState({ tab: 'services' })}
              className={cn({ disabled: !monitoring.data?.servicesEnabled })}
            >
              {t('monitoring.tabs.services')}
            </LinearTabButton>
          </LinearTabs>

          {monitoring.data?.chartsEnabled && isChartsEnabled && (
            <Charts
              monitoringId={monitoringId}
              instanceId={state.instance.value}
              isRequesting={charts.isRequesting || monitoring.isRequesting}
              onRefresh={onRefresh}
            />
          )}
          {monitoring.data?.servicesEnabled && isOsServicesEnabled && (
            <Processes
              monitoringId={monitoringId}
              instanceId={state.instance.value}
              onRefresh={onRefresh}
              isEditable={isAllFormEnabled}
            />
          )}
        </>
      )}

      <ServiceTriggerDialog
        initialValues={serviceConfig}
        entity={entity}
        osType={osType}
        vmCount={vmCount}
        open={state.manageBilling}
        onSave={onUpdateBillingService}
        isEditable={permissions.canManage}
        isRequesting={monitoring.isRequesting || task.isTaskActive}
        onClose={handleManageBilling(false)}
        instance={instance}
      />

      <SetLimitsDialog
        entity={entity}
        initialValues={initialDefaultChartValues}
        items={charts.data}
        open={state.manageLimits}
        onSave={onUpdateLimitsService}
        isRequesting={monitoring.isRequesting || task.isTaskActive}
        isEditable={isAllFormEnabled}
        onClose={handleManageLimits(false)}
      />
    </div>
  );
});

const Main = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default withPermissions<Types.IBaseProps>(Main, 'MONITORING');
