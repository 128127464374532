import { AnyShape } from 'global-shapes';
import { baseGuideNamespaces } from './constants';

const customerInfoFields: string[] = ['shortName', 'name'];

export const shouldDisableNextStep = (errors: AnyShape | null) => {
  let isFulfilled = false;

  customerInfoFields.forEach((field) => {
    if (!isFulfilled && errors && errors[field]) isFulfilled = true;
  });

  return isFulfilled;
};

export const isAllGuideStepsCompleted = (state: AnyShape) => {
  return baseGuideNamespaces.every((name) => state[name]?.status === 'SEEN');
};

export function parseQueryToFetch(query: ITenantsTypes.Query) {
  return {
    ...query,
    enabledBilling:
      !query.enabledBilling || query.enabledBilling === 'true'
        ? undefined
        : false,
  };
}
