import { Address4 } from 'ip-address';
import { MIN_INTERNAL_NETWORK_POOL_SIZE, NETWORK_MASK } from './constants';
import { IRds } from 'rds';
import { ICoreInfrastructure } from 'core-infrastructure';
import { IDnsService } from 'dns';

export const validateIpPoolRange = (
  ipPoolValue: string,
  networkValue: string
) => {
  const start = ipPoolValue.split('-')[0];
  const end = ipPoolValue.split('-')[1];
  if (!start) return 'forms.invalid';
  if (!end) return 'forms.invalid';
  if (+start < 2 || +end > 254)
    return 'services.connectivity.validation.minMax';
  try {
    const addressStart = new Address4(
      `${networkValue}.${start}/${NETWORK_MASK}`
    );
    const addressEnd = new Address4(`${networkValue}.${end}/${NETWORK_MASK}`);
    const diff = addressEnd
      .bigInteger()
      .subtract(addressStart.bigInteger())
      .intValue();
    if (diff < MIN_INTERNAL_NETWORK_POOL_SIZE)
      return 'services.connectivity.validation.range';
  } catch (err) {
    return 'forms.invalid';
  }
};

export const ejectPubServiceName = (service?: string): string =>
  service ? service.split(', ')[0] : '';

export const defineCoreDisplayStatus =
  (coreData: ICoreInfrastructure) => () => {
    if (coreData) {
      if (coreData.displayStatus === 'ERROR') return coreData.displayStatus;
      return coreData.setupState !== 'SETUP_COMPLETED'
        ? coreData.setupState
        : coreData.displayStatus;
    }
    return 'none';
  };
export const defineDnsDisplayStatus =
  (dns: IDnsService) => (): 'ERROR' | 'RUNNING' | 'none' => {
    if (dns) {
      if (dns.displayStatus === 'ERROR') return 'ERROR';
      return 'RUNNING';
    }
    return 'none';
  };

export const defineRdsDisplayStatus = (rds: IRds) => {
  if (rds) {
    if (rds.displayStatus === 'ERROR') return rds.displayStatus;
    return rds.setupState !== 'SETUP_COMPLETED'
      ? rds.setupState
      : rds.displayStatus;
  }
  return 'none';
};

export const defineVmDisplayStatus = (vm: IVmTypes.Vm) => () => {
  if (vm) {
    if (vm.displayStatus === 'ERROR') return vm.displayStatus;
    return vm.setupState !== 'SETUP_COMPLETED'
      ? vm.setupState
      : vm.displayStatus;
  }
  return 'none';
};

export const defineDedicatedServerDisplayStatus =
  (vm: IDedicatedServer.DedicatedServer) => () => {
    if (vm) {
      if (vm.displayStatus === 'ERROR') return vm.displayStatus;
      return vm.setupState !== 'SETUP_COMPLETED'
        ? vm.setupState
        : vm.displayStatus;
    }
    return 'none';
  };
