import * as React from 'react';
import cn from 'classnames';
import qs from 'query-string';
import { Breadcrumbs } from 'elements';
import { indexOf } from 'ramda';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePermissions, useAuth } from 'hooks';
import { Emitter } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import {
  ILevelSwitcherNavItem,
  CustomEvents,
  defineSwitcherNavItemsByBaseLevel,
} from 'enums';
import { ICurrentUser } from 'auth-shapes';
import { AnyShape, AccessLevelLow } from 'global-shapes';
import { SNavLink } from './Styled';
import { ILevelItemProps } from './types';

const LEVELS = {
  PROVIDER: ['base', 'partner', 'tenant'],
  PARTNER: ['base', 'tenant'],
  TENANT: [],
};

const defineLevels = (
  currentUser: ICurrentUser,
  currentAccessLevel: AccessLevelLow
): ILevelSwitcherNavItem[] => {
  if (!currentAccessLevel || !currentUser.accessLevel) {
    return [];
  }

  const endIndex =
    indexOf(currentAccessLevel, LEVELS[currentUser.accessLevel]) + 1;

  const levels = defineSwitcherNavItemsByBaseLevel(
    currentUser,
    currentUser.accessLevel
  );
  const items = levels.slice(0, endIndex);
  return items.length > 1 ? items : [];
};

const OBSERVERS = {
  currentUser: StateHandlers.currentUser,
  guideTooltips: StateHandlers.guideTooltips,
  taskManager: StateHandlers.taskManager,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { currentUser, guideTooltips, taskManager } = props;
  const { currentAccessLevel } = usePermissions();
  const location = useLocation();
  const navigate = useNavigate();
  const { setChildSessionInfo } = useAuth();

  const handleLoading = React.useCallback(
    (isLoading: boolean) => Emitter.emit(CustomEvents.changeLevel, isLoading),
    []
  );

  const onItemClick = React.useCallback(
    (options: ILevelItemProps) =>
      async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        const { level, defaultQuery, to } = options;
        const isBase = level === 'base';

        const isUrlEqual =
          `${to}?${qs.stringify(defaultQuery as AnyShape, {
            arrayFormat: 'bracket',
          })}` ===
          location.pathname + location.search;

        const isSamePage = currentAccessLevel === level;

        if (isSamePage) {
          if (!isUrlEqual) {
            navigate(
              `${to}?${qs.stringify(defaultQuery as AnyShape, {
                arrayFormat: 'bracket',
              })}`
            );
          }

          return;
        }

        handleLoading(true);

        if (isBase) {
          sessionStorage.clear();
          window.location.pathname = '/';
          return;
        }

        setChildSessionInfo({ tsn: undefined }, true);
        navigate(`${to}?${qs.stringify(defaultQuery as AnyShape)}`);
        await guideTooltips.get();
        await taskManager.get();
        await StateHandlers.fetchAllAccountData({ shouldApplyTheme: true });
        handleLoading(false);
      },
    [
      JSON.stringify(currentUser.data),
      location.search,
      location.pathname,
      currentAccessLevel,
    ]
  );

  const levelItems = defineLevels(currentUser.data, currentAccessLevel);

  if (!levelItems.length) return null;

  return (
    <div role="presentation" className="mb-10">
      <Breadcrumbs aria-label="breadcrumb">
        {levelItems.map((el) => {
          const isActive = currentAccessLevel === el.level;
          return (
            <SNavLink
              key={el.to}
              color="inherit"
              to={el.to}
              isActive={isActive}
              className={cn('fs-13')}
              onClick={onItemClick({
                level: el.level,
                to: el.to,
                defaultQuery: el.defaultQuery,
              })}
            >
              {el.label}
            </SNavLink>
          );
        })}
      </Breadcrumbs>
    </div>
  );
});

const LevelSwitcher = () => <View {...OBSERVERS} />;

export default LevelSwitcher;
