import * as React from 'react';
import * as R from 'ramda';
import {
  Switch,
  Col,
  Row,
  PrimaryTextH5,
  CustomListUl,
  PrimaryTextSpan,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { usePrice } from 'hooks';
import { observer } from 'mobx-react-lite';
import { CostResourceIds } from 'enums';
import { numberToCurrency, remCalc } from 'utils';
import { FormikProps } from 'formik';
import * as TYPES from './types';

type IProps = FormikProps<TYPES.IFormValues> & {
  descriptionString: string;
};

const SecondStep = observer((props: IProps) => {
  const { values, setFieldValue, descriptionString } = props;
  const { t } = useTranslation();

  const prices = usePrice([CostResourceIds.ipv4, CostResourceIds.ipv6]);

  const ipv4 = prices[CostResourceIds.ipv4]?.monthly;
  const ipv6 = prices[CostResourceIds.ipv6]?.monthly;

  const totalCost = R.sum([
    values.ipv4Enabled ? ipv4 : 0,
    values.ipv6Enabled ? ipv6 : 0,
  ]);

  React.useEffect(() => {
    return () => {
      setFieldValue('ipv4Enabled', true);
      setFieldValue('ipv6Enabled', false);
    };
  }, []);

  return (
    <Row justifyContent="space-between" columnSpacing={2}>
      <Col xs={7} className="steel">
        <div className="mb-15">{t(descriptionString)}</div>

        <div className="mb-15">
          <Switch
            checked={values.ipv4Enabled}
            label={t('services.vm.info.externalNetwork.dialog.content.IPv4')}
            onCheck={(ipv4Enabled) => setFieldValue('ipv4Enabled', ipv4Enabled)}
          />
        </div>
        <Switch
          label={t('services.vm.info.externalNetwork.dialog.content.IPv6')}
          checked={values.ipv6Enabled}
          onCheck={(ipv6Enabled) => setFieldValue('ipv6Enabled', ipv6Enabled)}
        />
      </Col>
      <Col xs={5}>
        <Row
          direction="column"
          justifyContent="space-between"
          columnSpacing={2}
          style={{ minHeight: remCalc(500) }}
        >
          <Col className="full-width">
            <PrimaryTextH5 className="mb-25">
              {t('costsInfo.title') as string}
            </PrimaryTextH5>
            <Row
              justifyContent="space-between"
              columnSpacing={2}
              className="fs-10 steel uppercase mb-15"
            >
              <Col xs={8}>
                <span>{t('costsInfo.head.serviceName') as string}</span>
              </Col>
              <Col xs={4} className="text-right">
                <span>{t('costsInfo.head.price') as string}</span>
              </Col>
            </Row>
            <h5 className="mb-15">{t('costsInfo.virtualServer') as string}</h5>
            <div className="custom-list">
              <CustomListUl>
                <li>
                  <div className="flex justify-between">
                    <span>{t('costsInfo.IPv4') as string}</span>
                    <span className="pl-10 steel">
                      {numberToCurrency(ipv4, false)}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex justify-between">
                    <span>{t('costsInfo.IPv6') as string}</span>
                    <span className="pl-10 steel">
                      {numberToCurrency(ipv6, false)}
                    </span>
                  </div>
                </li>
              </CustomListUl>
            </div>
          </Col>
          <Col className="full-width">
            <div className="flex justify-between align-center">
              <h5 className="fs-17">{t('costsInfo.totalMonthly') as string}</h5>
              <PrimaryTextSpan className="fs-20 bold">
                {numberToCurrency(totalCost, false)}
              </PrimaryTextSpan>
            </div>
            <div className="fs-12 steel mb-10">
              {t('costsInfo.chfExclVat') as string}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

export default SecondStep;
