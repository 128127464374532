import * as React from 'react';
import cn from 'classnames';
import * as R from 'ramda';
import { dayjs } from 'utils';
import duration from 'dayjs/plugin/duration';
import {
  DateTimePicker,
  Row,
  Col,
  Button,
  IconButton,
  MuiIcons,
} from 'elements';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useTranslation } from 'react-i18next';
import { PERIOD_OPTIONS } from '../constants';
import { findDisabledHours, findDisabledMinutes } from '../helpers';
import { QuickFilter } from '../Styled';
import * as Types from '../types';

dayjs.extend(duration);

const OBSERVERS = {
  meta: StateHandlers.meta,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: Types.IChartFilterProps & IViewProps) => {
  const { meta } = props;
  const { t } = useTranslation();

  const periodValue = React.useMemo(() => {
    const diff = props.dtTo.diff(props.dtFrom, 'day');
    return R.find(R.propEq('value', diff), PERIOD_OPTIONS);
  }, [props.dtFrom, props.dtTo]);

  return (
    <div className="fs-14">
      {/* <div className="mb-15 bold">{t('monitoring.sections.charts.title')}</div> */}
      <div className="steel mb-25">
        {t('monitoring.sections.charts.content')}
      </div>
      <Row
        justifyContent="space-between"
        alignItems="flex-end"
        className="mb-25"
      >
        <Col xs>
          <Row alignItems="flex-end" columnSpacing={2}>
            <Col>
              <QuickFilter
                label={t('monitoring.filters.quickFilter')}
                value={periodValue}
                options={PERIOD_OPTIONS}
                onChange={(period: any) => {
                  props.onChange(
                    'dtFrom',
                    dayjs().add(-period.value, 'day').startOf('day')
                  );
                  props.onChange('dtTo', dayjs().endOf('day'));
                }}
              />
            </Col>
            <Col>
              <div className="mb-15 pl-10 pr-10">
                {t('monitoring.filters.or')}
              </div>
            </Col>
            <Col xs>
              <Row columnSpacing={1}>
                <Col>
                  <DateTimePicker
                    label={t('monitoring.filters.dtFrom')}
                    value={props.dtFrom}
                    onChange={(date) => props.onChange('dtFrom', date)}
                    maximumDate={props.dtTo.toDate()}
                    disabledTime={() => ({
                      disabledHours: findDisabledHours(
                        props.dtFrom,
                        props.dtTo,
                        'before'
                      ),
                      disabledMinutes: findDisabledMinutes(
                        props.dtFrom,
                        props.dtTo,
                        'before'
                      ),
                    })}
                  />
                </Col>
                <Col>
                  <DateTimePicker
                    label={t('monitoring.filters.dtTo')}
                    value={props.dtTo}
                    onChange={(date) => props.onChange('dtTo', date)}
                    minimumDate={props.dtFrom.toDate()}
                    maximumDate={props.dtFrom
                      .add(1, 'year')
                      .endOf('day')
                      .toDate()}
                    disabledTime={() => ({
                      disabledHours: findDisabledHours(
                        props.dtTo,
                        props.dtFrom,
                        'after'
                      ),
                      disabledMinutes: findDisabledMinutes(
                        props.dtTo,
                        props.dtFrom,
                        'after'
                      ),
                    })}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row
            justifyContent="flex-end"
            alignItems="center"
            columnSpacing={1}
            className="light-grey"
          >
            {props.onRefresh && (
              <Col>
                <Button
                  variant="outlined"
                  color="default"
                  onClick={props.onRefresh}
                >
                  {t('monitoring.buttons.refresh')}
                </Button>
              </Col>
            )}

            <Col>
              <IconButton
                onClick={() => meta.merge({ monitorDisplay: 'card' })}
              >
                <MuiIcons.CalendarViewMonth
                  className={cn({
                    dark: meta.data.monitorDisplay === 'card',
                    'light-grey': meta.data.monitorDisplay !== 'card',
                  })}
                />
              </IconButton>
            </Col>
            <Col>
              <IconButton
                onClick={() => meta.merge({ monitorDisplay: 'list' })}
              >
                <MuiIcons.Reorder
                  className={cn({
                    dark: meta.data.monitorDisplay === 'list',
                    'light-grey': meta.data.monitorDisplay !== 'list',
                  })}
                />
              </IconButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
});

const Charts = (props: Types.IChartFilterProps) => (
  <View {...props} {...OBSERVERS} />
);

export default Charts;
