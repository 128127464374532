import { dayjs, round, bitToMBits, bToGb, GbToB, MBitsToBit } from 'utils';
import { ValueType } from 'global-shapes';
import * as Yup from 'yup';
import { IMetricKey, IMonitoringCreateFormValues } from 'monitoring';
import { TFunction } from 'react-i18next';
import * as Types from './types';

export const QUERY = {
  page: 1,
  perPage: 1000,
  orderType: 'asc',
  orderBy: 'createdAt',
  include: 'instances',
};

export const PERIODS: Types.IFilterPeriod[] = [2, 7, 30, 180];

export const PERIOD_OPTIONS: ValueType<Types.IFilterPeriod>[] = [
  {
    value: 2,
    label: `monitoring.filters.options.last2days`,
  },
  {
    value: 7,
    label: `monitoring.filters.options.last7days`,
  },
  {
    value: 30,
    label: `monitoring.filters.options.last30days`,
  },
  {
    value: 180,
    label: `monitoring.filters.options.last6months`,
  },
];

export function getInitialChartFilters(): Types.IChartFilterState {
  return {
    dtFrom: dayjs().add(-2, 'days').startOf('day'),
    dtTo: dayjs(),
    period: PERIOD_OPTIONS[1],
  };
}

export const SINGLE_CHART_COLORS = ['rgb(42, 157, 244)', 'rgb(245, 0, 0)'];

export const EXTENDED_CHART_COLORS = [
  'rgb(42, 157, 244)',
  'rgb(178, 41, 181)',
  'rgb(255, 214, 6)',
  'rgb(245, 0, 0)',
];

const dataFormarter: Types.IMetricFormarter = (val) => {
  return round(val, 3, 3);
};

export const getChartConfigs = (cfg: {
  isWideDateRange: boolean;
}): Partial<Record<IMetricKey, Types.IChartConfig>> => {
  const colors = cfg.isWideDateRange
    ? EXTENDED_CHART_COLORS
    : SINGLE_CHART_COLORS;
  return {
    cpu_util: {
      config: {
        yAxis: { max: 100, min: 0 },
        colors,
        dataFormarter,
      },
    },
    ram_util: {
      config: {
        yAxis: { max: 100, min: 0 },
        colors,
        dataFormarter,
      },
    },
    network_if_out_bits: {
      limitFormatter: (limit: number) => {
        return 10 * limit;
      },
      config: {
        yAxis: { min: 0 },
        colors,
        dataFormarter,
      },
    },
    network_if_in_bits: {
      limitFormatter: (limit: number) => {
        return 10 * limit;
      },
      config: {
        yAxis: { min: 0 },
        colors,
        dataFormarter,
      },
    },
    filesystem_space_util: {
      config: {
        yAxis: { max: 100, min: 0 },
        colors,
        dataFormarter,
      },
    },
  };
};

export const limitsSectionValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('forms.emailInvalid')
    .test('email', 'forms.invalid.uniq', function (email) {
      if (!email) return true;
      const emails = this.parent.emails || [];
      const isUniq = !emails.includes(email);
      if (!isUniq) return false;
      return true;
    }),
  emails: Yup.array(Yup.string())
    .min(1, 'monitoring.dialog.setLimits.errors.recepients.atLeastOne')
    .required('forms.required'),
});

export const getLimitSliderPropsByType = (pr: {
  t: TFunction;
}): Partial<Record<IMetricKey, any>> => {
  return {
    cpu_util: {
      label: pr.t('monitoring.limits.cpu_util.name'),
      inputWidth: 120,
      sliderProps: {
        defaultValue: 0,
        step: 1,
        min: 0,
        max: 100,
        marks: [
          {
            value: 0,
            label: pr.t('common.disabled'),
          },
          { value: 100, label: 100 },
        ],
      },
    },
    ram_util: {
      label: pr.t('monitoring.limits.ram_util.name'),
      inputWidth: 120,
      valueFormatter: bToGb,
      valueFormatterBackward: GbToB,
      sliderProps: {
        defaultValue: 0,
        step: 1,
        min: 0,
        max: 100,
        marks: [
          {
            value: 0,
            label: pr.t('common.disabled'),
          },
          { value: 100, label: 100 },
        ],
      },
    },
    network_if_in_bits: {
      label: pr.t('monitoring.limits.inbound.name'),
      inputWidth: 120,
      valueFormatter: bitToMBits,
      valueFormatterBackward: MBitsToBit,
      sliderProps: {
        defaultValue: 0,
        step: 1,
        min: 0,
        max: 100,
        marks: [
          {
            value: 0,
            label: pr.t('common.disabled'),
          },
          { value: 100, label: 100 },
        ],
      },
    },
    network_if_out_bits: {
      label: pr.t('monitoring.limits.outbound.name'),
      inputWidth: 120,
      valueFormatter: bitToMBits,
      valueFormatterBackward: MBitsToBit,
      sliderProps: {
        defaultValue: 0,
        step: 1,
        min: 0,
        max: 100,
        marks: [
          {
            value: 0,
            label: pr.t('common.disabled'),
          },
          { value: 100, label: 100 },
        ],
      },
    },
    filesystem_space_util: {
      label: pr.t('monitoring.limits.disk.name'),
      inputWidth: 120,
      valueFormatter: bToGb,
      valueFormatterBackward: GbToB,
      sliderProps: {
        defaultValue: 0,
        step: 1,
        min: 0,
        max: 100,
        marks: [
          {
            value: 0,
            label: pr.t('common.disabled'),
          },
          { value: 100, label: 100 },
        ],
      },
    },
  };
};

export const INITIAL_SERVICES_VALUES: IMonitoringCreateFormValues = {
  chartsEnabled: false,
  servicesEnabled: false,
  outsourceManagementEnabled: false,
};

export const LIMIT_MEASURE_OPTIONS = (
  metricKey: IMetricKey,
  onlyPercentage: boolean
) => {
  if (onlyPercentage) {
    return [
      {
        value: 'percentage',
        label: 'monitoring.limitMeasure.percentage',
      },
    ];
  }

  let valuesLabel = 'monitoring.limitMeasure.values';

  switch (metricKey) {
    case 'cpu_util':
      valuesLabel = 'GB';
      break;
    case 'network_if_in_bits':
      valuesLabel = 'Mbit/s';
      break;
    case 'network_if_out_bits':
      valuesLabel = 'Mbit/s';
      break;
    case 'ram_util':
      valuesLabel = 'GB';
      break;
    case 'filesystem_space_util':
      valuesLabel = 'GB';
      break;
    default:
      valuesLabel = 'monitoring.limitMeasure.values';
  }

  return [
    {
      value: 'percentage',
      label: 'monitoring.limitMeasure.percentage',
    },
    {
      value: 'values',
      label: valuesLabel,
    },
  ];
};

export const MAINTENANCE_OPTIONS: ValueType<IMaintenanceRecurringInterval>[] = [
  {
    value: 'NONE',
    label: 'patching.options.recurring.single',
  },
  {
    value: 'DAY',
    label: 'patching.options.recurring.daily',
  },
  {
    value: 'WEEK',
    label: 'patching.options.recurring.weekly',
  },
  {
    value: 'MONTH',
    label: 'patching.options.recurring.monthly',
  },
];

export const INITIAL_MW_STATE = {};
