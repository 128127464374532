import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'hooks';
import { Row, Col, Alert, Skeleton } from 'elements';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import Filters from './Filters';
import GlobalChart from './GlobalChart';
import { getChartConfigs, getInitialChartFilters } from '../constants';
import * as Types from '../types';

const OBSERVERS = {
  meta: StateHandlers.meta,
  charts: StateHandlers.monitoringCharts,
  monitoring: StateHandlers.monitoring,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: Types.IChartsProps & IViewProps) => {
  const { instanceId, monitoringId, isRequesting, meta, charts, monitoring } =
    props;
  const { t } = useTranslation();
  const [filters, handleFilter] = useState<Types.IChartFilterState>(
    getInitialChartFilters()
  );
  const columnsBreakpoint = meta.data.monitorDisplay === 'card' ? 6 : 12;

  const date = {
    dateFrom: filters.dtFrom.toISOString(),
    dateTo: filters.dtTo.toISOString(),
  };

  const isWideDateRange = filters.dtTo.diff(filters.dtFrom, 'days') > 7;

  const getCharts = React.useCallback(() => {
    if (instanceId && monitoringId) {
      charts.get({ monitoringId, instanceId });
    }
  }, [instanceId, monitoringId]);

  React.useEffect(() => {
    getCharts();
  }, [instanceId, monitoringId]);

  if (!instanceId || !monitoringId) {
    return null;
  }

  return (
    <section className="mb-40">
      <Filters
        {...filters}
        onChange={(field, newValue) => handleFilter(newValue, field)}
        onRefresh={
          charts.data.length
            ? () => props.onRefresh().then(getCharts)
            : undefined
        }
      />
      {!charts.data.length ? (
        <Alert severity="info">
          {t('monitoring.sections.charts.noDataYet')}
        </Alert>
      ) : (
        <Row columnSpacing={2} rowSpacing={2}>
          {charts.data.map((el, ind) => {
            const chartConfig = getChartConfigs({ isWideDateRange })[
              el.metricKey
            ];
            const limitValue = chartConfig?.limitFormatter
              ? chartConfig?.limitFormatter(el.alertLimitPercentValue)
              : el.alertLimitPercentValue;

            if (charts.isRequesting || monitoring.isRequesting) {
              return (
                <Col
                  key={el.metricKey + ind + el.createdAt}
                  xs={columnsBreakpoint}
                >
                  <Skeleton
                    height={312}
                    className="full-width"
                    animation="wave"
                    variant="rectangular"
                  />
                </Col>
              );
            }

            return (
              <Col
                key={el.metricKey + ind + el.createdAt}
                xs={columnsBreakpoint}
              >
                <GlobalChart
                  monitoringId={monitoringId}
                  instanceId={instanceId}
                  isWideDateRange={isWideDateRange}
                  {...el}
                  {...date}
                  chartConfig={chartConfig?.config}
                  limit={{ limit: limitValue }}
                  isRequesting={isRequesting}
                />
              </Col>
            );
          })}
        </Row>
      )}
    </section>
  );
});

const Charts = (props: Types.IChartsProps) => (
  <View {...props} {...OBSERVERS} />
);

export default Charts;
