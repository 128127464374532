import React from 'react';
import cn from 'classnames';
import MuiPaper, { PaperProps } from '@mui/material/Paper';
import { styled } from './utilities';

export * from '@mui/material/Paper';

const Wrapper = styled(MuiPaper)(({ theme }) => ({
  boxShadow: '0 1px 1px 0 rgba(23, 29, 41, 0.16)',
  borderRadius: '6px',
}));

export const Paper = React.forwardRef((props: PaperProps, ref: any) => (
  <Wrapper {...props} className={cn(props.className)} ref={ref} />
));
