import * as React from 'react';
import cn from 'classnames';
import { important, remCalc } from 'utils';
import { useState } from 'hooks';
import { styled } from './utilities';
import { Col, Row } from './Grid';
import { MuiIcons } from './MuiIcons';
import { IconButton } from './Button';
import { default as Popover } from './Popover';
import { useTranslation } from 'react-i18next';
import { HOURS_ARRAY_STRING, MINUTES_ARRAY_STRING, PALETTE } from 'enums';

export const PickerLabel = styled('div')(({ theme }) => ({
  fontSize: remCalc(12),
  lineHeight: remCalc(17),
  marginBottom: remCalc(8),
}));

type IState = {
  hourAnchor: any;
  minuteAnchor: any;
};

type IValue = {
  hours: string;
  minutes: string;
};

type Props = {
  value: IValue;
  label?: any;
  error?: boolean;
  disabled?: boolean;
  helperText?: string;
  onChange: (duration: IValue) => void;
};

const DisplayWrapper = styled(
  (
    props: React.ComponentProps<'div'> & {
      disabled: boolean;
      error: boolean;
    }
  ) => {
    const { disabled, error, ...rest } = props;
    return <div {...rest} />;
  }
  // @ts-ignore
)(({ theme, disabled, error }) => {
  const errorStyles = error
    ? {
        backgroundColor: '#FFF0EE',
        borderColor: theme.palette.error.main,
      }
    : {};

  const disabledStyles = disabled
    ? {
        backgroundColor: PALETTE.pageBackground,
        borderColor: important(PALETTE.pageBackground),
        pointerEvents: 'none',
        '& ._dateInput, & ._timeInput': {
          color: PALETTE.steel,
        },
      }
    : {};

  return {
    height: remCalc(48),
    padding: remCalc(0, 5, 0, 15),
    borderRadius: remCalc(6),
    backgroundColor: theme.palette.common.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    minWidth: remCalc(150),
    fontSize: remCalc(14),
    lineHeight: 1,
    borderColor: PALETTE.lightGrey,
    ...errorStyles,
    ...disabledStyles,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '& ._dateInput': {
      lineHeight: remCalc(46),
      height: remCalc(46),
      width: remCalc(80),
    },
  };
});

const ErrorLabel = styled('div')(({ theme }) => ({
  lineHeight: remCalc(19),
  fontSize: remCalc(12),
  color: theme.palette.error.main,
  marginTop: remCalc(3),
  textAlign: 'right',
}));

const INITIAL_STATE: IState = {
  hourAnchor: null,
  minuteAnchor: null,
};

const defaultProps = {
  value: {
    hours: HOURS_ARRAY_STRING[0],
    minutes: MINUTES_ARRAY_STRING[0],
  },
};

const DurationPicker: React.FunctionComponent<Props> = (_props) => {
  const props = { ...defaultProps, ..._props };
  const { t } = useTranslation();
  const { value, label, onChange } = props;
  const [state, handleState] = useState<IState>(INITIAL_STATE);

  const handleAnchorClick = React.useCallback(
    (key: string) => (event: any) => {
      handleState({ [key]: event.currentTarget });
    },
    []
  );

  const handleAnchorClose = React.useCallback(() => {
    handleState(INITIAL_STATE);
  }, []);

  return (
    <div>
      {label && <PickerLabel>{label}</PickerLabel>}
      <DisplayWrapper
        error={!!props.error}
        disabled={!!props.disabled}
        className={cn('pointer')}
      >
        <Row
          justifyContent="space-between"
          alignItems="center"
          className="full-width"
          columnSpacing={2}
        >
          <Col onClick={handleAnchorClick('hourAnchor')} className="_dateInput">
            {value?.hours} Hrs
          </Col>
          <Col
            onClick={handleAnchorClick('minuteAnchor')}
            className="_dateInput"
          >
            {value?.minutes} Mins
          </Col>

          <Col>
            <IconButton>
              <MuiIcons.ArrowDropDown
                className="fs-18 steel"
                style={{ lineHeight: 1, height: remCalc(18) }}
              />
            </IconButton>
          </Col>
        </Row>
      </DisplayWrapper>
      {props.error && (
        <ErrorLabel>{t(props.helperText as string) as string}</ErrorLabel>
      )}
      <Popover
        id={'date-anchor'}
        open={!!state.hourAnchor}
        anchorEl={state.hourAnchor}
        onClose={handleAnchorClose}
        style={{ maxHeight: 200 }}
        classes={{
          paper: 'pt-5 pl-5 pr-5 pb-20',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {HOURS_ARRAY_STRING.map((h) => {
          return (
            <div
              key={h}
              onClick={() => {
                onChange({ ...value, hours: h });
                handleAnchorClose();
              }}
              className={cn('pl-10 pr-10 pointer', {
                primary: value.hours === h,
              })}
            >
              {h}
            </div>
          );
        })}
      </Popover>

      <Popover
        id={'time-anchor'}
        open={!!state.minuteAnchor}
        anchorEl={state.minuteAnchor}
        onClose={handleAnchorClose}
        style={{ maxHeight: 200 }}
        classes={{
          paper: 'pt-5 pb-5',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {MINUTES_ARRAY_STRING.map((m) => {
          return (
            <div
              key={m}
              onClick={() => {
                onChange({ ...value, minutes: m });
                handleAnchorClose();
              }}
              className={cn('pl-10 pr-10 pointer', {
                primary: value.minutes === m,
              })}
            >
              {m}
            </div>
          );
        })}
      </Popover>
    </div>
  );
};

export default DurationPicker;
