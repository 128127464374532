import * as Yup from 'yup';
import { RESERVED_PROTOCOLS } from 'enums';
import { isBetween, isCorrectPortValue } from 'utils';

export const MAX_VM_DETAILS_VALUES = {
  virtualCpus: 64,
  ramMb: 512,
  osDiskSizeGb: 10000,
};

const BasePubServiceSchema = {
  name: Yup.string().trim().required('forms.required'),
  port: Yup.string()
    .trim()
    .required('forms.required')
    .test({
      message: 'forms.invalid',
      test: (port) => {
        const splitted = (port?.split('-') ?? []).map((el) => el.trim());
        const min = +splitted[0];
        const max = +splitted[1];
        if (max < min) return false;
        return isCorrectPortValue(splitted);
      },
    })
    .test({
      message: 'forms.invalid.port.range.customOnly',
      test: function (port) {
        const splitted = (port?.split('-') ?? []).map((el) => el.trim());
        const isRange = splitted.length > 1;

        const hasCustomName =
          !!this.parent.name?.trim() &&
          RESERVED_PROTOCOLS.every((el) => {
            return (
              this.parent.name.toLowerCase().split(', ')[0] !==
              el.label.toLowerCase()
            );
          });

        return !(!hasCustomName && isRange);
      },
    })
    .test({
      message: 'forms.invalid.port.range',
      test: function (port) {
        const splitted = (port?.split('-') ?? []).map((el) => el.trim());
        const isRange = splitted.length > 1;

        const min = +splitted[0];
        const max = +splitted[1];

        const hasCustomName =
          !!this.parent.name?.trim() &&
          RESERVED_PROTOCOLS.every((el) => {
            return (
              this.parent.name.toLowerCase().split(', ')[0] !==
              el.label.toLowerCase()
            );
          });

        if (isRange || hasCustomName) {
          return !RESERVED_PROTOCOLS.some(
            (prot) => +prot.value === min || isBetween(min, max)(prot.value)
          );
        }
        return true;
      },
    })

    .test({
      message: 'services.vm.services.errors.ICMPtype',
      test: function (port: any) {
        const isIcmp = this.parent.type?.value === 'icmp';
        if (isIcmp) {
          return port === '49573';
        }
        return true;
      },
    }),
  type: Yup.object()
    .required('forms.required')
    .when('port', {
      is: (port: string) => +port === 161,
      then: Yup.object()
        .required('forms.required')
        .test({
          message: 'services.vm.services.errors.161Port',
          test: (el) => {
            return el?.value === 'udp';
          },
        }),
    })
    .when('port', {
      is: (port: string) => +port === 49573,
      then: Yup.object()
        .required('forms.required')
        .test({
          message: 'services.vm.services.errors.ICMPPort',
          test: (el) => {
            return el?.value === 'icmp';
          },
        }),
    }),
};

export const publicServiceValidationSchema =
  Yup.object().shape(BasePubServiceSchema);

export const createPublicServiceValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('forms.required'),
  port: Yup.string()
    .trim()
    .required('forms.required')
    .test({
      message: 'forms.invalid',
      test: (port) => {
        const splitted = (port?.split('-') ?? []).map((el) => el.trim());
        const min = +splitted[0];
        const max = +splitted[1];
        if (max < min) return false;
        return isCorrectPortValue(splitted);
      },
    })
    .test({
      message: 'forms.invalid.port.range.customOnly',
      test: function (port) {
        const splitted = (port?.split('-') ?? []).map((el) => el.trim());
        const isRange = splitted.length > 1;

        const hasCustomName =
          !!this.parent.name?.trim() &&
          RESERVED_PROTOCOLS.every((el) => {
            return (
              this.parent.name.toLowerCase().split(', ')[0] !==
              el.label.toLowerCase()
            );
          });

        return !(!hasCustomName && isRange);
      },
    })
    .test({
      message: 'forms.invalid.port.range',
      test: function (port) {
        const splitted = (port?.split('-') ?? []).map((el) => el.trim());
        const isRange = splitted.length > 1;

        const min = +splitted[0];
        const max = +splitted[1];

        const hasCustomName =
          !!this.parent.name?.trim() &&
          RESERVED_PROTOCOLS.every((el) => {
            return (
              this.parent.name.toLowerCase().split(', ')[0] !==
              el.label.toLowerCase()
            );
          });

        if (isRange || hasCustomName) {
          return !RESERVED_PROTOCOLS.some(
            (prot) => +prot.value === min || isBetween(min, max)(prot.value)
          );
        }
        return true;
      },
    })

    .test({
      message: 'services.vm.services.errors.ICMPtype',
      test: function (port: any) {
        const isIcmp = this.parent.type?.value === 'icmp';
        if (isIcmp) {
          return port === '49573';
        }
        return true;
      },
    }),
  type: Yup.object()
    .required('forms.required')
    .when('port', {
      is: (port: string) => +port === 161,
      then: Yup.object()
        .required('forms.required')
        .test({
          message: 'services.vm.services.errors.161Port',
          test: (el) => {
            return el?.value === 'udp';
          },
        }),
    })
    .when('port', {
      is: (port: string) => +port === 49573,
      then: Yup.object()
        .required('forms.required')
        .test({
          message: 'services.vm.services.errors.ICMPPort',
          test: (el) => {
            return el?.value === 'icmp';
          },
        }),
    }),
  sources: Yup.array(Yup.string()).when(['anySource', 'tab'], {
    is: (anySource: boolean, tab: number) => {
      if (!anySource && tab === 0) return true;
      return false;
    },
    then: Yup.array(Yup.string()).test(
      'sources',
      'services.vm.services.dialog.errors.invalid.sources.required',
      (val) => !!val?.length
    ),
  }),
  countryCodes: Yup.array(Yup.object()).when(['anySource', 'tab'], {
    is: (anySource: boolean, tab: number) => {
      if (!anySource && tab === 1) return true;
      return false;
    },
    then: Yup.array(Yup.object()).test(
      'sources',
      'services.vm.services.dialog.errors.invalid.country.required',
      (val) => !!val?.length
    ),
  }),
});

export const editPublicServiceValidationSchema = Yup.object().shape({
  sources: Yup.array(Yup.string()).when(['anySource', 'tab'], {
    is: (anySource: boolean, tab: number) => {
      if (!anySource && tab === 0) return true;
      return false;
    },
    then: Yup.array(Yup.string()).test(
      'sources',
      'services.vm.services.dialog.errors.invalid.sources.required',
      (val) => !!val?.length
    ),
  }),
  countryCodes: Yup.array(Yup.object()).when(['anySource', 'tab'], {
    is: (anySource: boolean, tab: number) => {
      if (!anySource && tab === 1) return true;
      return false;
    },
    then: Yup.array(Yup.object()).test(
      'sources',
      'services.vm.services.dialog.errors.invalid.country.required',
      (val) => !!val?.length
    ),
  }),
});
