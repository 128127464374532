import { MessageInterface } from './messageInterface';
import { virtualMachinesService } from 'services';
import { incomingRequestType } from 'enums';

const webConsoleWindowRef: { [key: number]: Window | null } = {};

const initMessageHandlers = (messageInterface: MessageInterface) => {
  messageInterface.addHandler(incomingRequestType.requestI18n, () => {
    /* Replace this values with specific for selected language */
    const i18nData: any = {
      'wmks.error.acquireTicket': 'Error on creating web console session',
      'wmks.loading': 'Loading',
      'wmks.connecting': 'Connecting',
      'wmks.connected': 'Connected',
      'wmks.disconnected': 'Disconnected',
      'wmks.no.parent.no.vm.id.error': 'There is no vm id',
      'wmks.full.screen': 'Full screen',
      'wmks.ctrl.alt.del': 'CTRL+ALT+DEL',
      'wmks.options': 'Options',
      'wmks.keyboard.layout': 'Keyboard layout',
      'wmks.retryConnect': 'Retry',
    };
    messageInterface.updateI18n(i18nData);
  });

  messageInterface.addHandler(
    incomingRequestType.requestTicket,
    // @ts-ignore
    async (vmId: number) => {
      try {
        const ticket = await virtualMachinesService
          .getTicketApi(vmId)
          .then((res) => res.data);
        messageInterface.updateTicket(ticket);
      } catch (e) {
        // @ts-ignore
        console.log('Error on create mks ticket: ', e.message);
        messageInterface.requestTicketError('wmks.error.acquireTicket');
      }
    }
  );

  messageInterface.addHandler(
    incomingRequestType.requestVm,
    // @ts-ignore
    async (vmId: number) => {
      const vm = await virtualMachinesService
        .getById(vmId, {})
        .then((res) => res.data);
      messageInterface.updateVm(vm);
    }
  );
};

export const openWebConsoleWindow = (id: number) => {
  if (
    !webConsoleWindowRef[id] ||
    (webConsoleWindowRef[id] && webConsoleWindowRef[id]?.closed)
  ) {
    webConsoleWindowRef[id] = window.open(
      `${window.location.origin}/web-console?vmId=${id}`,
      '',
      'width=600,height=500'
    );
  } else {
    webConsoleWindowRef[id]?.focus();
    return;
  }

  // @ts-ignore
  const messageInterface = new MessageInterface(webConsoleWindowRef[id]);
  initMessageHandlers(messageInterface);
};
