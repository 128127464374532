import * as React from 'react';
import {
  Input,
  IconButton,
  MuiIcons,
  AddIcon,
  Row,
  Col,
  Alert,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { isValidIpCidrRange } from 'utils';

type IProps = ReturnType<
  typeof useFormik<IConnectivityTypes.IFirewallRuleFormValues>
>;

const DestinationForm = (props: IProps) => {
  const { t } = useTranslation();

  const sources = props.values.destinations;

  const onAddSource = React.useCallback(async () => {
    const isValid = await isValidIpCidrRange(
      props.values.destinationText
    ).catch(() => false);
    if (!isValid) {
      return props.setFieldError(
        'destinationText',
        'services.vm.services.dialog.errors.invalid.source.ip'
      );
    }

    const hasDuplicate = sources.includes(props.values.destinationText);

    if (hasDuplicate) {
      return props.setFieldError(
        'destinationText',
        'services.vm.services.dialog.errors.invalid.sources.uniq'
      );
    }

    props.setValues({
      ...props.values,
      destinations: [props.values.destinationText, ...sources],
      destinationText: '',
    });
  }, [JSON.stringify(props.values)]);

  const onDelete = React.useCallback(
    (ip: string) => {
      props.setFieldValue(
        'destinations',
        sources.filter((el) => el !== ip)
      );
    },
    [sources]
  );

  return (
    <>
      <Row className="mb-25" alignItems="flex-start">
        <Col xs>
          <Input
            name="destinationText"
            label="forms.source"
            placeholder="forms.placeholders.ip.ranges.subnet"
            onChange={props.handleChange}
            value={props.values.destinationText}
            error={!!props.errors.destinationText}
            helperText={props.errors.destinationText}
          />
        </Col>
        <AddIcon className="ml-15 mt-30" onClick={onAddSource} />
      </Row>
      <div>
        <div className="steel fs-12 mb-10">{t('forms.source')}</div>
        <div className="mb-20">
          {!sources.length ? (
            <div className="fs-14 steel">{t('common.noData')}</div>
          ) : (
            sources.map((s, ind) => {
              return (
                <Row
                  key={s + ind}
                  alignItems="center"
                  justifyContent="space-between"
                  className="mb-5"
                >
                  <Col>{s}</Col>
                  <Col>
                    <IconButton size="small" onClick={() => onDelete(s)}>
                      <MuiIcons.Delete className="fs-18" />
                    </IconButton>
                  </Col>
                </Row>
              );
            })
          )}
        </div>
        {!!props.submitCount && !!props.errors.destinations && (
          <Alert severity="error">{t(props.errors.destinations)}</Alert>
        )}
      </div>
    </>
  );
};

export default DestinationForm;
