import qs from 'query-string';
import { path, pick, pipe, reduce, split } from 'ramda';
import TagManager from 'react-gtm-module';
import countryList from 'react-select-country-list';
import {
  AnyShape,
  DefaultListQuery,
  OptionShape,
  SystemMessageStatus,
  TableParams,
  ValueType,
} from 'global-shapes';
import { Emitter } from './Emitter';
import { STATUS_NAMESPACES_MAP } from 'enums/statusColors';
import { CustomEvents } from 'enums/customEvents';
import { ResolvedPluralRulesOptions } from 'pricing';
import { isString } from './customFunctions';
import { Permission, PermissionsMap } from 'auth-shapes';

export const showSystemMessage = (
  title: string,
  status: SystemMessageStatus = 'info'
) => Emitter.emit(CustomEvents.showSystemMessage, { title, status });

export const parseToOptions =
  (label = 'name', value = 'id') =>
  (res: AnyShape) =>
    res.data.map(
      (el: AnyShape): OptionShape => ({
        label: el[label],
        value: el[value],
        isDefault: el.isDefault,
      })
    );

export const getCurrentQuery = () => qs.parse(window.location.search);

export function openInNewTab(url: string) {
  const win = window.open(url, '_blank');
  win && win.focus();
}

export const getPath = pipe(split(/[[\].]/), path);

export const buildListParams = (
  query?: DefaultListQuery,
  fieldsToPick?: string[]
): TableParams => {
  let params = {};

  if (fieldsToPick && query) {
    params = pick(fieldsToPick)(query);
  }

  return {
    limit: +(query?.perPage || 20),
    // @ts-ignore
    offset: query?.page ? (+query.page - 1) * +query?.perPage : 0,
    ...params,
  };
};

export const numberToCurrency = (
  number: number,
  useStyles = true,
  options?: ResolvedPluralRulesOptions
) => {
  return new Intl.NumberFormat(
    'de-CH',
    useStyles
      ? {
          style: 'currency',
          currency: 'CHF',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          ...options,
        }
      : {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: 'decimal',
          ...options,
        }
  ).format(typeof number === 'number' ? number : 0);
};

export const noop = () => {
  return undefined;
};

export const parseError = (error?: any) => {
  if (error) {
    return isString(error) ? error : error.message;
  }
  return undefined;
};

export const mapPermissions = (permissions: Permission[]): PermissionsMap => {
  return reduce(
    (res, p) => {
      res[p.resource] = p;
      return res;
    },
    {} as any,
    permissions || []
  );
};

export const insertXCustomLang = (lang?: string): undefined | Partial<any> => {
  return lang
    ? {
        headers: {
          'x-custom-lang': lang,
        },
      }
    : undefined;
};

export const initTagManager = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-MFJTK6X',
    events: { 'gtm.start': new Date().getTime(), event: 'gtm.js' },
  };

  TagManager.initialize(tagManagerArgs);
};

export const getCountries = (): ValueType<string>[] => {
  return [...countryList().getData(), { value: 'XK', label: 'Kosovo' }].sort(
    (a, b) => a.label.localeCompare(b.label)
  );
};

export const countries = getCountries();

export function defineOs(os: IVmTypes.IVmOs): 'win' | 'linux' | 'ios' {
  switch (os.osFamilyId) {
    case 1:
      return 'win';
    case 2:
      return 'linux';
    case 3:
      return 'ios';
    default:
      return 'win';
  }
}

export const stripCoreDomain = (domainName: string): string => {
  let domain = '';
  domain = domainName.split('.').slice(0, -2).join('');
  return domain;
};

export function generateNumberSeq(end: number) {
  return [
    0,
    ...Array(end)
      // @ts-ignore
      .fill()
      .map((element, index) => index + 1),
  ];
}

export function shouldDisableWebConsoleLaunching(status: string): boolean {
  return [
    ...STATUS_NAMESPACES_MAP.poweredOff,
    ...STATUS_NAMESPACES_MAP.initializing,
    ...STATUS_NAMESPACES_MAP.provisioning,
  ].includes(status);
}

export const defineDisplayPagination = (
  totalCount?: number,
  query?: AnyShape
) => {
  if (totalCount && query) {
    if (query.perPage >= (totalCount || 0)) return false;
  }
  return true;
};
