import React from 'react';
import { Paper } from 'elements';
import { Backup } from 'components/Backup';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

const OBSERVERS = {
  rds: StateHandlers.rds,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { rds } = props;
  const hostVm = rds.data?.host?.hostInstances[0];
  const vmDiskSize = hostVm?.vm.osDiskSizeGb;

  return (
    <Paper className="p-30">
      <Backup
        entityId={rds.data?.id}
        entityName="Rds"
        vmDiskSize={vmDiskSize}
        isUsageEnabled
        instance={rds.data}
      />
    </Paper>
  );
});

const BackupComponent = () => <View {...OBSERVERS} />;

export default BackupComponent;
