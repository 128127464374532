import {
  AxiosError,
  AxiosPromise,
  AxiosResponse,
  default as axios,
} from 'axios';
import { refreshToken } from './refreshToken';
import { apiFileServerPrefix, apiPrefix, defaultHeaders } from '../constants';
import { compactObject } from '../customFunctions';
import { getCurrentToken } from '../session';

const parseResponse = (res: AxiosResponse) => {
  if (typeof res.data === 'string') {
    return res.data;
  }

  return {
    ...res.data,
    info: {
      status: res.status,
    },
  };
};

const errorHandling =
  (shouldUpdate?: boolean) =>
  (err: AxiosError): Promise<AxiosError | AxiosPromise> => {
    if (shouldUpdate && err.response && err.response.status === 401) {
      return refreshToken()
        .then(() =>
          axios.create()({
            ...err.config,
            headers: compactObject({
              ...err.config?.headers,
              Authorization: `Bearer ${getCurrentToken()}`,
            }),
          })
        )
        .then(parseResponse);
    }

    if (err.response) {
      throw err.response.data;
    }

    throw err;
  };

export function GET<T = any>(
  url = '',
  params: object = {},
  config: any = { shouldUpdate: true }
): AxiosPromise<T> {
  const _config = config
    ? { shouldUpdate: true, ...config }
    : { shouldUpdate: true };
  return axios
    .get(`${apiPrefix}${url}`, {
      params,
      ..._config,
      headers: compactObject({ ...defaultHeaders(), ..._config.headers }),
    })
    .then(parseResponse)
    .catch(errorHandling(_config.shouldUpdate));
}

export function POST<T = any>(
  url = '',
  params: object = {},
  data: any = undefined,
  config: any = { shouldUpdate: true }
): AxiosPromise<T> {
  const _config = config
    ? { shouldUpdate: true, ...config }
    : { shouldUpdate: true };
  return axios
    .post(`${apiPrefix}${url}`, data, {
      params,
      ..._config,
      headers: compactObject({ ...defaultHeaders(), ..._config.headers }),
    })
    .then(parseResponse)
    .catch(errorHandling(_config.shouldUpdate));
}

export function PATCH<T = any>(
  url = '',
  params: object = {},
  data: any = undefined,
  config: any = { shouldUpdate: true }
): AxiosPromise<T> {
  const _config = config
    ? { shouldUpdate: true, ...config }
    : { shouldUpdate: true };
  return axios
    .patch(`${apiPrefix}${url}`, data, {
      params,
      ..._config,
      headers: compactObject({ ...defaultHeaders(), ..._config.headers }),
    })
    .then(parseResponse)
    .catch(errorHandling(_config.shouldUpdate));
}

export function DELETE<T = any>(
  url = '',
  params: object = {},
  data: any = undefined,
  config: any = { shouldUpdate: true }
): AxiosPromise<T> {
  const _config = config
    ? { shouldUpdate: true, ...config }
    : { shouldUpdate: true };
  return axios
    .delete(`${apiPrefix}${url}`, {
      params,
      ..._config,
      headers: compactObject({ ...defaultHeaders(), ..._config.headers }),
      data,
    })
    .then(parseResponse)
    .catch(errorHandling(_config.shouldUpdate));
}

export function PUT<T = any>(
  url = '',
  params: object = {},
  data: any = undefined,
  config: any = { shouldUpdate: true }
): AxiosPromise<T> {
  const _config = config
    ? { shouldUpdate: true, ...config }
    : { shouldUpdate: true };
  return axios
    .put(`${apiPrefix}${url}`, data, {
      params,
      ..._config,
      headers: compactObject({ ...defaultHeaders(), ..._config.headers }),
    })
    .then(parseResponse)
    .catch(errorHandling(_config.shouldUpdate));
}

export const GET_FILE_SERVER = (
  url = '',
  params: object = {},
  config: any = { shouldUpdate: true }
): AxiosPromise => {
  const _config = config
    ? { shouldUpdate: true, ...config }
    : { shouldUpdate: true };
  return axios
    .get(`${apiFileServerPrefix}${url}`, {
      params,
      ..._config,
      headers: compactObject({
        ...defaultHeaders(),
        ...{ 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
        ..._config.headers,
      }),
    })
    .then(parseResponse)
    .catch(errorHandling(_config.shouldUpdate));
};
