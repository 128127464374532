import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from 'elements';
import { desiredOrderByService } from './constants';
import { ResourceBlock, ResourceBulkUpdateBlock } from './ResourceBlockView';
import { ResourceBlockTitle } from './Styled';
import { sortPriceList } from './helper';
import { IServiceBlockProps, IServiceBlockBulkProps } from './types';

export const ServiceBlock = (props: IServiceBlockProps) => {
  const {
    data,
    defaultPrice,
    canEdit,
    isOnUserPricing,
    onUpdatePricing,
    onSetPrice,
    parentPricing,
    hasParentPrice,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      {sortPriceList(defaultPrice as any)(desiredOrderByService, 'id').map(
        (service) => {
          return (
            <Paper className="p-20 mb-15" key={service.id}>
              <ResourceBlockTitle className="bold">
                {t(`settings.page.pricing.titles.${service.id}`)}
              </ResourceBlockTitle>
              <ResourceBlock
                service={service}
                price={data}
                isOnUserPricing={isOnUserPricing}
                canEdit={canEdit}
                onUpdatePricing={onUpdatePricing}
                onSetPrice={onSetPrice}
                parentPricing={parentPricing}
                hasParentPrice={hasParentPrice}
              />
            </Paper>
          );
        }
      )}
    </>
  );
};

export const ServiceBulkUpdateBlock = (props: IServiceBlockBulkProps) => {
  const {
    data,
    defaultPrice,
    checked,
    onCheck,
    parentPricing,
    hasParentPrice,
    percentage,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      {sortPriceList(defaultPrice as any)(desiredOrderByService, 'id').map(
        (service) => {
          return (
            <Paper className="p-20 mb-15" key={service.id}>
              <ResourceBlockTitle className="bold">
                {t(`settings.page.pricing.titles.${service.id}`)}
              </ResourceBlockTitle>
              <ResourceBulkUpdateBlock
                service={service}
                price={data}
                parentPricing={parentPricing}
                hasParentPrice={hasParentPrice}
                checked={checked}
                onCheck={onCheck}
                percentage={percentage}
              />
            </Paper>
          );
        }
      )}
    </>
  );
};
