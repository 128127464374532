import * as React from 'react';
import * as R from 'ramda';
import { useLocation } from 'react-router-dom';
import {
  confirm,
  confirmDeleteWithReasons,
  Emitter,
  IConfirmDeleteReasonsRes,
  noop,
  parseConfirmDeleteWithReasonResponse,
  showSystemMessage,
} from 'utils';
import { useDedicatedServerMenuItems } from 'hooks/useInstanceMenuItems';
import { ActionOption, IActionHandlers } from 'components/ActionButtons/types';
import {
  useAccount,
  useRemoteSupport,
  useStateHandler,
  useTask,
  useUserHash,
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import * as StateHandlerHelpers from 'states/helpers';
import { CustomEvents } from 'enums';
import { IExtendedActualTask } from 'task-manager-service';
import { BILLING_CIRCLE_DS_OPTIONS } from 'pages/services/constants';

export type InjectedProps = {
  handleDetailsOpen: (open: boolean) => void;
  isRequesting: boolean;
  isInFolder: boolean;
  fetchVm: any;
  fetchBillingInfo: any;
  onEdit: any;
  onDelete: any;
  task: IExtendedActualTask;
  menuItems?: ActionOption[];
  actionItems?: ActionOption[];
  handlers: IActionHandlers;
};

const showError = (err: any) => showSystemMessage(err.message, 'error');

const parseUpdateData = (
  vm?: IDedicatedServer.DedicatedServer,
  billingInfo?: IVmTypes.IBillingCircle
): ValueType<IVmTypes.IBillingCircleInterval> => {
  return (
    (!!vm?.billingCycleId &&
      R.find(
        R.propEq('value', billingInfo?.pendingInterval || billingInfo?.interval)
      )(BILLING_CIRCLE_DS_OPTIONS)) ||
    (BILLING_CIRCLE_DS_OPTIONS[0] as any)
  );
};

export function withDedicatedServerActions(
  Component: React.ComponentType<any>,
  place: 'list' | 'single'
) {
  return observer((props: any) => {
    const { ...passedVmProps } = props;
    const isDetailsPage = place === 'single';
    const { t } = useTranslation();
    const [, changeQuery] = useUserHash();
    const location = useLocation();
    const [account] = useAccount();
    const vm = useStateHandler(StateHandlers.vmDetails);
    const dedicatedServers = useStateHandler(StateHandlers.dedicatedServers);
    const vms = useStateHandler(StateHandlers.vms);
    const tasks = useStateHandler(StateHandlers.taskManager);
    const billingInfo = useStateHandler(StateHandlers.billingInfo);
    const folders = useStateHandler(StateHandlers.folders);
    const dnd = useStateHandler(StateHandlers.dnd);

    const currentVm = isDetailsPage ? vm.data : passedVmProps;
    const vmId = currentVm.id;
    const dedicatedVm = isDetailsPage
      ? vm.data.dedicatedServer
      : passedVmProps.dedicatedServer;

    const isInFolder = !!currentVm.serviceGroupId;

    const reloadCurrentVm = React.useCallback(() => {
      if (isDetailsPage) {
        return vm.get({
          id: vmId,
          include: ['serviceMonitoring', 'serviceSnapshot', 'billingCycle'],
          isDedicatedServer: true,
        });
      }
      return vms.reload(vmId, {
        include: ['serviceMonitoring', 'serviceSnapshot', 'billingCycle'],
      });
    }, [isDetailsPage, vmId]);

    const refetchServer = React.useCallback((action: string) => {
      if (action === 'delete') return StateHandlerHelpers.fetchVms();
      return reloadCurrentVm();
    }, []);

    const fetchFolders = React.useCallback(async () => {
      if (isDetailsPage)
        return folders.get({
          tenantId: account?.tenant?.id,
          type: 'DEDICATED_SERVER',
        });

      return Emitter.emit(CustomEvents.fetchFolderList, {});
    }, [account?.tenant?.id, isDetailsPage]);

    const openWebConsole = React.useCallback(
      dedicatedServers.executeRequest('openWebConsole'),
      [dedicatedServers.isRequesting]
    );

    const vmCompleteCallback = React.useCallback(
      (action: string) => {
        StateHandlerHelpers.fetchQuotas();
        return refetchServer(action);
      },
      [vmId, reloadCurrentVm, isDetailsPage]
    );

    const task = useTask(dedicatedVm?.task, {
      onComplete: vmCompleteCallback,
    });

    const remoteSupport = useRemoteSupport({
      type: 'vm',
      vmId,
      onSuccess: () => refetchServer('none'),
    });

    const withBillingCycle = !!vm.data?.billingCycleId;
    const isRequesting =
      billingInfo.isRequesting || vm.isRequesting || task.isTaskActive;
    const isPerformingRequest =
      vm.isRequesting ||
      billingInfo.isRequesting ||
      dedicatedServers.isRequesting;

    const onEdit = async () => {
      const id = props.id || vmId;
      let _vm: IDedicatedServer.DedicatedServer = dedicatedVm || undefined;
      let _billingInfo: IVmTypes.IBillingCircle = billingInfo.data || undefined;

      if (!isDetailsPage) {
        // @ts-ignore
        _vm = await vm.get({ id, isDedicatedServer: true });
      }

      if (_vm?.billingCycleId) {
        // @ts-ignore
        _billingInfo = await billingInfo.get({
          id: _vm?.billingCycleId,
        });
      }

      const billingCycleInterval = parseUpdateData(dedicatedVm, _billingInfo);

      Emitter.emit(CustomEvents.editDS, {
        dedicatedServer: dedicatedVm,
        vmId: id,
        isDetailsPage,
        initialValues: {
          name: dedicatedVm?.name,
          description: dedicatedVm?.description,
          billingCycleInterval,
          billingCycleId: dedicatedVm.billingCycleId,
        },
      });
    };

    const oReinstallOs = React.useCallback(async () => {
      try {
        const id = props.id || vmId;
        let dVm: IDedicatedServer.DedicatedServer | undefined =
          dedicatedVm || undefined;
        let billing: IVmTypes.IBillingCircle | undefined =
          billingInfo.data || undefined;

        if (!isDetailsPage) {
          const res = await vm.get({ id, isDedicatedServer: true });
          dVm = res?.dedicatedServer;
        }

        if (dVm?.billingCycleId) {
          billing = await billingInfo.get({
            id: dVm?.billingCycleId,
          });
        }

        const billingCycleInterval = parseUpdateData(dVm, billing);

        Emitter.emit(CustomEvents.reinstallOs, {
          dedicatedServer: dVm,
          vmId: id,
          isDetailsPage,
          initialValues: {
            name: dVm?.name,
            description: dVm?.description,
            billingCycleInterval,
            billingCycleId: dVm?.billingCycleId,
          },
        });
      } catch (er) {
        console.log('er -> ', er);
      }
    }, [vmId, props.id, dedicatedVm, billingInfo.data, isDetailsPage]);

    const onDeleteVm = React.useCallback(
      () =>
        confirmDeleteWithReasons({
          title: t('services.dialog.dedicatedServer.confirm.delete.title'),
          content: withBillingCycle
            ? t(
                'services.dialog.dedicatedServer.confirm.delete.withBillingCycle.subtitle'
              )
            : t('services.dialog.dedicatedServer.confirm.delete.subtitle', {
                name: dedicatedVm?.name,
              }),
          onSuccess: (val: IConfirmDeleteReasonsRes) =>
            dedicatedServers
              .remove({
                id: dedicatedVm.id,
                deletionReason: parseConfirmDeleteWithReasonResponse(val),
              })
              .then((res) => {
                if (!location.pathname.includes('/services/all')) {
                  changeQuery('/services/all');
                }

                return res;
              }),
          onCancel: () => undefined,
          successLabel: 'common.remove',
          cancelLabel: 'common.cancel',
        }),
      [dedicatedVm?.id, withBillingCycle, dedicatedVm?.name, location.pathname]
    );

    const moveToFolder = React.useCallback(
      (f: IServiceGroups.Group) =>
        confirm({
          title: 'folders.confirm.moveToFolder.title',
          content: 'folders.confirm.moveToFolder.content',
          onSuccess: () =>
            folders
              .executeRequest('moveToFolder')(account?.tenant?.id, f.id, {
                appEntityId: dedicatedVm?.appEntityId,
              })
              .then(() => {
                reloadCurrentVm();
                Emitter.emit(CustomEvents.fetchVmList, {});
                return fetchFolders();
              })
              .catch((er) => {
                showSystemMessage(er.message, 'error');
              }),
          onCancel: () => undefined,
        }),
      [
        account?.tenant?.id,
        dedicatedVm?.appEntityId,
        fetchFolders,
        reloadCurrentVm,
      ]
    );

    const removeFromFolder = React.useCallback(
      () =>
        confirm({
          title: 'folders.confirm.removeFromFolder.title',
          content: 'folders.confirm.removeFromFolder.content',
          onSuccess: () =>
            folders
              .executeRequest('removeFromFolder')(
                account?.tenant?.id,
                currentVm.serviceGroupId,
                {
                  appEntityId: dedicatedVm?.appEntityId,
                }
              )
              .then(() => {
                reloadCurrentVm();
                Emitter.emit(CustomEvents.fetchVmList, {});
                return fetchFolders();
              })
              .catch((er) => {
                showSystemMessage(er.message, 'error');
              }),
          onCancel: () => undefined,
        }),
      [
        account?.tenant?.id,
        dedicatedVm?.appEntityId,
        currentVm.serviceGroupId,
        fetchFolders,
        reloadCurrentVm,
      ]
    );

    const onActionSuccess = React.useCallback(async () => {
      await refetchServer('none');
      return tasks.get();
    }, [vmId]);

    const onOpenDetails = React.useCallback(
      () => changeQuery(`/services/dedicated/info`, { id: vmId }),
      [vmId]
    );

    const handlers = React.useMemo(() => {
      return {
        powerOn: () => {
          return dedicatedServers
            .executeRequest('powerOn')(dedicatedVm.id)
            .then(onActionSuccess)
            .catch(showError);
        },
        powerOff: () => {
          return confirm({
            title: t(`services.vm.confirm.powerOff.title`, {
              title: dedicatedVm.name,
            }),
            content: t(`services.vm.confirm.powerOff.subtitle`, {
              title: dedicatedVm.name,
            }),
            onSuccess: () =>
              dedicatedServers
                .executeRequest('powerOff')(dedicatedVm.id)
                .then(onActionSuccess)
                .catch(showError),
            onCancel: noop,
          });
        },
        reset: () => {
          return confirm({
            title: t(`services.vm.confirm.reset.title`, {
              title: dedicatedVm.name,
            }),
            content: t(`services.vm.confirm.reset.subtitle`, {
              title: dedicatedVm.name,
            }),
            onSuccess: () =>
              dedicatedServers
                .executeRequest('reset')(dedicatedVm.id)
                .then(onActionSuccess)
                .catch(showError),
            onCancel: noop,
          });
        },
        delete: onDeleteVm,
        openDetails: onOpenDetails,
        moveToFolder,
        removeFromFolder,
        console: (id: number) => openWebConsole(id || dedicatedVm.id),
        assignBadges: () => {
          dnd.merge({ isDialogOpen: true });
          Emitter.emit(CustomEvents.assignDialog, dedicatedVm);
        },
        edit: onEdit,
        reinstallOs: oReinstallOs,
      };
    }, [
      JSON.stringify(dedicatedVm),
      vmId,
      onActionSuccess,
      onOpenDetails,
      onDeleteVm,
      onEdit,
      isPerformingRequest,
      dedicatedVm,
      oReinstallOs,
    ]) as IActionHandlers;

    const { menuItems, actionItems } = useDedicatedServerMenuItems({
      instance: dedicatedVm,
      isRequesting: isPerformingRequest || isRequesting,
      remoteSupport,
      handlers,
      folders: folders.data.filter((f) => f.type === 'DEDICATED_SERVER'),
      isInFolder: isInFolder,
    });

    const fetchVmInstance = React.useCallback((q: AnyShape) => {
      return vm.get({ ...q, isDedicatedServer: true });
    }, []);

    return (
      <>
        <Component
          {...props}
          fetchVm={fetchVmInstance}
          fetchBillingInfo={billingInfo.get}
          onEdit={onEdit}
          handlers={handlers}
          onDelete={onDeleteVm}
          menuItems={menuItems}
          actionItems={actionItems}
          task={task}
          isRequesting={isPerformingRequest}
          isInFolder={isInFolder}
        />
      </>
    );
  });
}
