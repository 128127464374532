import React, { ReactElement } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { AnyFunc, AnyShape } from 'global-shapes';
import { Step, StepLabel } from 'elements';
import { isFunction } from 'utils';
import { Stepper } from './Styled';

const Connector = () => null;

export interface StepperArgs {
  handleReset: AnyFunc;
  handleSkip: AnyFunc;
  handleNext: AnyFunc;
  handleBack: AnyFunc;
  isLastStep: boolean;
  isFirstStep: boolean;
}

interface IProps {
  className?: string;
  stepClassName?: string;
  steps: string[];
  activeStep: number;
  setActiveStep: AnyFunc;
  children?: ((args: StepperArgs) => any) | ReactElement;
}

const defaultProps = {
  actions: () => null,
};

export default (_props: IProps) => {
  const props = { ...defaultProps, ..._props };
  const {
    steps,
    activeStep,
    setActiveStep,
    children,
    className,
    stepClassName,
  } = props;
  const [skipped, setSkipped] = React.useState(new Set());
  const { t } = useTranslation();

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    const newStep = activeStep + 1;

    setActiveStep(newStep > steps.length ? activeStep : newStep);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    const newStep = activeStep - 1;
    setActiveStep(newStep < 0 ? activeStep : newStep);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(activeStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Stepper
        className={cn('justify-between _paper', className)}
        activeStep={activeStep}
        classes={{ root: '' }}
        connector={<Connector />}
      >
        {steps.map((label, index) => {
          const stepProps: AnyShape = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps} className={cn(stepClassName)}>
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {isFunction(children)
        ? // @ts-ignore
          children({
            handleReset,
            handleSkip,
            handleNext,
            handleBack,
            isLastStep: activeStep >= steps.length - 1,
            isFirstStep: activeStep === 0,
          })
        : children}
    </>
  );
};
