import * as React from 'react';
import { Dialog, Button, DialogProps, Input } from 'elements';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { IServiceName } from 'monitoring';
import { serviceNameValidationSchema } from './constants';

const ServiceNameDialog = (props: DialogProps<IServiceName>) => {
  const { onSave, ...dialogProps } = props;
  const { t } = useTranslation();

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    submitCount,
    errors,
    resetForm,
  } = useFormik({
    initialValues: { serviceName: '' },
    validationSchema: serviceNameValidationSchema,
    onSubmit: onSave as any,
  });

  React.useEffect(() => {
    if (!dialogProps.open) {
      resetForm();
    }
  }, [dialogProps.open]);

  return (
    <Dialog
      {...dialogProps}
      fullWidth
      maxWidth="md"
      title={t('monitoring.sections.processes.dialog.serviceName.title')}
      handleSubmit={handleSubmit}
      actions={
        <>
          <Button
            variant="outlined"
            color="default"
            onClick={dialogProps.onClose}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className="steel mb-25">
        <Trans
          i18nKey="monitoring.sections.processes.dialog.serviceName.content"
          components={{
            1: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                rel="noreferrer"
                target="_blank"
                href="https://docs.awesome.cloud/docs/monitoring#dienste-services-monitoring"
                className="link-hover bolder"
              />
            ),
          }}
        />
      </div>
      <Input
        value={values.serviceName}
        name="serviceName"
        placeholder="monitoring.sections.processes.dialog.input.placeholder"
        onChange={handleChange}
        error={!!submitCount && !!errors.serviceName}
        helperText={errors.serviceName}
      />
    </Dialog>
  );
};

export default ServiceNameDialog;
