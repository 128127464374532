import React from 'react';
import { CopyTooltip, Chip, MuiIcons, createColumnHelper } from 'elements';
import { ALL_TEST_IDS } from 'enums';
import { IUser } from 'core-infrastructure';
import { AnyFunc } from 'global-shapes';
import { useTask } from 'hooks';

export const MINUTE_STEP = 15;

type IColumnProps = { t: AnyFunc; coreDomain: string };

const column = createColumnHelper<IUser>();

export const USERS_COLUMNS = ({ t, coreDomain }: IColumnProps) => [
  column.accessor('firstName', {
    header: (
      <div className="fs-12 steel weight-normal">{t('table.head.user')}</div>
    ),
    disableSort: false,
    cell: ({ row: { original: user } }: ICell<IUser>) => {
      return (
        <div>
          <div className="fs-14">
            {user.firstName} {user.lastName}
          </div>
          <div className="fs-12 steel">
            <CopyTooltip>{`${user.username}@${coreDomain}`}</CopyTooltip>
          </div>
        </div>
      );
    },
  }),
  column.accessor('id', {
    header: (
      <div className="fs-12 steel weight-normal">{t('table.head.2fa')}</div>
    ),
    sortId: 'rdsUser.twoFAState',
    disableSort: false,
    size: 70,
    cell: ({ row: { original: user } }: ICell<IUser>) => {
      const has2fa = !!user.rdsUser?.is2faEnabled;
      return (
        <div>{has2fa ? <MuiIcons.Check className="success fs-18" /> : '-'}</div>
      );
    },
  }),

  column.accessor('password', {
    header: (
      <div className="fs-12 steel weight-normal">{t('table.head.bypass')}</div>
    ),
    disableSort: false,
    sortId: 'rdsUser.twoFABypass',
    size: 70,
    cell: ({ row: { original: user } }: ICell<IUser>) => {
      const bypassEnabled = !!user.rdsUser?.twoFaBypass;
      return (
        <div>
          {bypassEnabled ? <MuiIcons.Check className="success fs-18" /> : '-'}
        </div>
      );
    },
  }),

  column.accessor('lastName', {
    header: (
      <div className="fs-12 steel weight-normal">
        {t('table.head.performanceProfile')}
      </div>
    ),
    disableSort: false,
    sortId: 'rdsUser.type',
    size: 100,
    cell: function Cell({ row: { original: user } }: ICell<IUser>) {
      const task = useTask(user.task);

      return (
        <div className="flex full-width align-center">
          {task.isFailed && (
            <Chip
              status="error"
              className="mr-20"
              tooltip={task.error && task.error.message}
            >
              {t('services.card.content.failed')}
            </Chip>
          )}
          {user.rdsUser ? (
            <>
              <span
                data-test-id={`${ALL_TEST_IDS.services.remote.users}-${user.id}-performance`}
              >
                {t(`services.rds.users.performanceType.${user.rdsUser.type}`)}
              </span>
            </>
          ) : (
            '-'
          )}
        </div>
      );
    },
  }),

  column.accessor('username', {
    header: (
      <div className="fs-12 steel weight-normal">{t('table.head.status')}</div>
    ),
    sortId: 'rdsUser.rdsId',
    disableSort: false,
    size: 70,
    cell: ({ row: { original: user } }: ICell<IUser>) => {
      const isActive = !!user.rdsUser;
      return (
        <div>
          <Chip status={isActive ? 'success' : 'default'}>
            {t(isActive ? 'common.active' : 'common.disabled')}
          </Chip>
        </div>
      );
    },
  }),
];
