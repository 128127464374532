import React from 'react';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useStateHandler, useState } from 'hooks';
import { observer } from 'mobx-react-lite';
import { Table, Dialog, DialogProps } from 'elements';
import * as StateHandlers from 'states';
import * as HELPERS from './helpers';
import * as CONSTANTS from '../constants';

type Props = DialogProps<IDedicatedServer.TenantosServer> & {
  initialValues?: IDedicatedServer.TenantosServer;
};

const DedicatedServersDetailsDialog = observer((props: Props) => {
  const { open, onSave, onClose } = props;

  const { t } = useTranslation();
  const [query, handleQuery] = useState<IDedicatedServer.TenantosServersQuery>(
    CONSTANTS.DedicatedServersQuery
  );
  const tenantosServers = useStateHandler(StateHandlers.tenantosServers);

  const { handleSubmit, resetForm, setValues, submitForm } = useFormik({
    initialValues: {} as IDedicatedServer.TenantosServer,
    validateOnMount: false,
    onSubmit: async (val) => (onSave ? onSave(val) : {}),
  });

  const columns = React.useMemo(() => {
    return HELPERS.getTenantosServersTableColumn({
      t,
      onSelect: (server) => {
        setValues(server);
        submitForm();
      },
    });
  }, []);

  React.useEffect(() => {
    if (open) {
      tenantosServers.get(query);
    }

    if (!open) {
      resetForm({});
    }
  }, [open, JSON.stringify(query)]);

  return (
    <Dialog
      open={open}
      title={t(`tenantosServers.dialog.select.title`)}
      fullWidth
      maxWidth="xl"
      showCloseIcon
      handleSubmit={handleSubmit}
      onClose={onClose}
      actions={null}
    >
      <div className="steel mb-15">
        {t('tenantosServers.dialog.select.content')}
      </div>
      <Table
        className={cn({ disabled: tenantosServers.isRequesting })}
        data={tenantosServers.data}
        columns={columns}
        params={tenantosServers.meta}
        noDataClassName="steel fs-14"
        query={query}
        hasSorting
        onSort={(orderBy, orderType) => {
          handleQuery({ orderBy, orderType });
        }}
        usePagination={false}
      />
    </Dialog>
  );
});

export default DedicatedServersDetailsDialog;
