import React, { useCallback, useMemo } from 'react';
import * as R from 'ramda';
import { MuiIcons, Tooltip } from 'elements';
import { ALL_TEST_IDS } from 'enums';
import * as StateHandlerHelpers from 'states/helpers';
import { useTask, useUserHash, usePermissions, useStateHandler } from 'hooks';
import { AnyFunc } from 'global-shapes';
import { useTranslation } from 'react-i18next';
import { IAddServiceCallback } from 'services-shapes';
import { getBadgeStateKey } from 'utils/parsers';
import { shouldDisableWebConsoleLaunching } from 'utils/global';
import { openWebConsoleWindow } from 'utils/openWebConsole';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import DropVmComponent from 'components/DragAndDrop/DropVmComponent';
import { VirtualServer as VirtualServerCard } from '../contents/VirtualServer';
import { withVmActions, InjectedProps } from 'hocs/withVmActions';
import { defineVmDisplayStatus } from '../../../helpers';
import { SVmCard } from './Styled';

type Props = IVmTypes.Vm & {
  onAdd: IAddServiceCallback;
  handleStateChange: AnyFunc;
  dataReceived: boolean;
  ref?: any;
};

type IViewProps = {
  vms: StateHandlers.IVmsHandler;
  assignments: StateHandlers.IBadgesAssignmentHandler;
};

const NoopComponent = ({ children }: any) => <>{children}</>;

const View = observer((props: Props & InjectedProps & IViewProps) => {
  const {
    onAdd,
    handleStateChange,
    dataReceived,
    handleDetailsOpen,
    handleRsOpen,
    isRequesting,
    fetchVm,
    fetchBillingInfo,
    onEdit,
    onDelete,
    notifyOfCreatedSnapshot,
    confirmSnapshotRequest,
    menuItems,
    actionItems,
    assignments,
    vms,
    isInFolder,
    ...vm
  } = props;
  const { t } = useTranslation();
  const dnd = useStateHandler(StateHandlers.dnd);
  const { isProvider, permissions } = usePermissions('SERVICES');
  const [, changeUrl] = useUserHash();

  const shouldUseDrop = !isInFolder;

  const entityAssignments = assignments.data[getBadgeStateKey(vm)] || [];

  const displayStatus = useMemo(defineVmDisplayStatus(vm), [vm]);

  const onOpenDetails = React.useCallback(
    () =>
      changeUrl(`/services/vm/info`, {
        id: vm.id,
      }),
    [vm.id]
  );

  const reloadCurrentVm = useCallback(
    () =>
      StateHandlerHelpers.reloadVmInList(vm.id, {
        include: ['serviceMonitoring', 'serviceSnapshot'],
      }),
    [vm.id]
  );

  const task = useTask(props.task, {
    onComplete: (action) => {
      StateHandlerHelpers.fetchQuotas();
      if (action === 'delete') return StateHandlerHelpers.fetchVms();
      reloadCurrentVm();
    },
  });

  const isUpdating = vms.updateIds.includes(vm.id);

  const TitleIcon = React.useMemo(() => {
    if (vm.networkType === 'PUBLIC') {
      return MuiIcons.Public;
    }

    if (vm.networkType === 'PRIVATE') {
      return MuiIcons.Shield;
    }

    return MuiIcons.Shield;
  }, [vm.networkType]);

  const DropComponent = React.useMemo(() => {
    if (dnd.data.isDialogOpen) {
      return NoopComponent;
    }
    if (shouldUseDrop) {
      return DropVmComponent;
    }
    return NoopComponent;
  }, [shouldUseDrop, isRequesting, dnd.data.isDialogOpen]);

  const cornerBadge: string = React.useMemo(() => {
    if (!isProvider) return '';

    if (!vm.billingCycle && !vm.isUsageEnabled)
      return t('services.content.cardBadge.free');

    return '';
  }, [JSON.stringify(vm), isProvider]);

  const totalDisksSize =
    vm.osDiskSizeGb + R.sum(vm.disks.map((d: any) => d.sizeGb));
  const isDisabled = task.isTaskActive || isUpdating;

  const otherTitleContent = React.useMemo(() => {
    if (!permissions.canManage) return null;

    if (shouldDisableWebConsoleLaunching(displayStatus)) {
      return (
        <Tooltip
          title={t('services.content.tooltips.consoleDisabled')}
          placement="top"
          arrow
        >
          <MuiIcons.DesktopWindowsOutlined className="fs-18 steel mr-5 half-faded" />
        </Tooltip>
      );
    }

    return (
      <MuiIcons.DesktopWindowsOutlined
        className="pointer fs-18 steel mr-5"
        onClick={() => openWebConsoleWindow(vm.id)}
      />
    );
  }, [permissions.canManage, displayStatus]);

  return (
    <DropComponent vm={vm}>
      <SVmCard
        status={displayStatus}
        title={vm.name}
        titleIcon={<TitleIcon color="primary" className="mr-5 fs-20" />}
        otherTitleContent={otherTitleContent}
        onAdd={onAdd}
        placeholder="services.content.placeholder.services"
        isEmpty={false}
        onTitleClick={onOpenDetails}
        isDisabled={isDisabled}
        testId={`${ALL_TEST_IDS.services.vm.main}-${vm.id}`}
        dataReceived={dataReceived}
        menuItems={menuItems}
        cornerBadge={cornerBadge}
        assignments={entityAssignments}
      >
        <VirtualServerCard {...vm} osDiskSizeGb={totalDisksSize} />
      </SVmCard>
    </DropComponent>
  );
});

const VirtualMachineCard = (props: Props & InjectedProps) => (
  <View
    {...props}
    vms={StateHandlers.vms}
    assignments={StateHandlers.badgesAssignment}
  />
);

export default withVmActions(VirtualMachineCard, 'list');
