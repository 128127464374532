import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import {
  Row,
  Col,
  Button,
  IconButton,
  TablePagination,
  MuiIcons,
} from 'elements';
import { IFileShare } from 'file-shares';
import { AnyFunc } from 'global-shapes';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useTranslation } from 'react-i18next';
import { IUser, IFileShareUser } from 'core-infrastructure';
import { useTask, useState } from 'hooks';
import FileShareUser from './FileShareUser';
import { DEFAULT_USERS_GET_PARAMS, PERMISSIONS_MAP } from './constants';
import { copyMECommand, getExpirationText } from './helpers';

interface IAdditionalProps {
  users: IUser[];
  onEdit: AnyFunc;
  onDelete: AnyFunc;
  onStartMigration: (file: {
    id: number;
    name: string;
    migrationEndpoint: any;
  }) => any;
  isEditable: boolean;
  onRemoveMigration: AnyFunc;
  onEditMigration: AnyFunc;
  onUpdateFS: AnyFunc;
  onFetchFSUsers: AnyFunc;
  fetchFS: AnyFunc;
}

const OBSERVERS = {
  core: StateHandlers.core,
  selectedFileShare: StateHandlers.selectedFileShare,
  coreUsersWithFileSharePerms: StateHandlers.coreUsersWithFileSharePerms,
};

type IViewProps = typeof OBSERVERS;

type Props = IFileShare & IAdditionalProps;

const PER_PAGE = 5;

const View = observer((props: Props & IViewProps) => {
  const {
    onFetchFSUsers,
    fetchFS,
    onUpdateFS,
    isEditable,
    coreUsersWithFileSharePerms,
    selectedFileShare,
    core,
  } = props;
  const { t } = useTranslation();
  const [state, handleStateChange] = useState({
    ...DEFAULT_USERS_GET_PARAMS,
    perPage: PER_PAGE,
  });

  const fetchCore = React.useCallback(() => core.get(), []);

  const onTaskComplete = React.useCallback(() => {
    fetchFS();
    fetchCore();
  }, []);

  const task = useTask(props.task, { onComplete: onTaskComplete });

  const meTask = useTask(
    props.migrationEndpoint ? props.migrationEndpoint.task : null,
    {
      onComplete: onUpdateFS,
    }
  );

  const onPageChange = useCallback(
    (params: object) => {
      const newParams = { ...state, ...params };
      return handleStateChange(newParams);
    },
    [state.toString()]
  );

  const disableAction = selectedFileShare.isRequesting;

  const dataToModify = useMemo(
    () => ({
      id: props.id,
      name: props.name,
      migrationEndpoint: props.migrationEndpoint,
    }),
    [JSON.stringify(props)]
  );

  const currentOffset = useMemo(
    () => (state.page - 1) * PER_PAGE,
    [state.page]
  );

  return (
    <div
      className={cn('bordered radiused mb-15', {
        disabled: task.isTaskActive,
      })}
    >
      {selectedFileShare.isLoading && <span>loading...</span>}
      <div className="p-30">
        <Row
          columnSpacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Col>
            <div className="bolder primary fs-17">
              {props.name} ({props.driveLetter})
            </div>
            <div className="steel fs-14">{`\\\\${core.data?.vm?.internalIpAddress}\\Shares$\\${props.name}`}</div>
          </Col>
          <Col>
            {isEditable && (
              <Row columnSpacing={1}>
                <Col>
                  <IconButton
                    onClick={() => props.onEdit(props)}
                    disabled={disableAction}
                  >
                    <MuiIcons.Edit />
                  </IconButton>
                </Col>
                <Col>
                  <IconButton
                    onClick={() => props.onDelete(dataToModify)}
                    disabled={disableAction}
                  >
                    <MuiIcons.Delete />
                  </IconButton>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {props.users.slice(currentOffset, currentOffset + PER_PAGE).map((u) => {
          const permissions: IFileShareUser[] = u.fileShareUsers || [];
          const onCurrentFileShare = permissions.find(
            (p) => p.fileShareId === props.id
          );
          const currentPermission = onCurrentFileShare
            ? PERMISSIONS_MAP[onCurrentFileShare.permission]
            : 'none';

          return (
            <FileShareUser
              key={u.id}
              {...u}
              onFetchFSUsers={onFetchFSUsers}
              task={onCurrentFileShare && onCurrentFileShare.task}
              currentPermission={currentPermission}
            />
          );
        })}
        {state.perPage < coreUsersWithFileSharePerms.meta.totalCount && (
          <TablePagination
            page={state.page}
            perPage={state.perPage}
            totalCount={coreUsersWithFileSharePerms.meta.totalCount}
            onChange={onPageChange}
            shouldUseQuery={false}
          />
        )}
      </div>

      <div className="p-30 bordered-top">
        <Row
          columnSpacing={2}
          justifyContent="space-between"
          alignItems="center"
          className={cn({ 'mb-10': !!props.migrationEndpoint })}
        >
          <Col className="uppercase bolder fs-14">
            {t('services.core.file-shares.migrationEndpoint')}
          </Col>
          <Col>
            <Row
              columnSpacing={2}
              alignItems="center"
              className={cn({ disabled: meTask.isTaskActive })}
            >
              {props.migrationEndpoint ? (
                <>
                  <Col>
                    <Button
                      size="small"
                      variant="outlined"
                      color="default"
                      onClick={() => copyMECommand(props)}
                    >
                      {t('common.copyCommand')}
                    </Button>
                  </Col>
                  {isEditable && (
                    <>
                      <Col>
                        <Button
                          size="small"
                          onClick={() => props.onRemoveMigration(props.id)}
                        >
                          {t('common.remove')}
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          size="small"
                          onClick={() => props.onEditMigration(dataToModify)}
                        >
                          {t('common.edit')}
                        </Button>
                      </Col>
                    </>
                  )}
                </>
              ) : (
                isEditable && (
                  <Button
                    size="small"
                    onClick={() => props.onStartMigration(dataToModify)}
                  >
                    {t('services.core.file-shares.startMigration')}
                  </Button>
                )
              )}
            </Row>
          </Col>
        </Row>
        {props.migrationEndpoint && (
          <Row columnSpacing={2}>
            <Col xs={12} lg={3}>
              <div className="fs-14">{t('forms.sourceWanIp')}</div>
              <div className="fs-14 steel">
                {props.migrationEndpoint.sourceWANIp}
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div className="fs-14">{t('forms.localSourcePath')}</div>
              <div className="fs-14 steel">
                {props.migrationEndpoint.localSourcePath}
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div className="fs-14">{t('forms.expiration')}</div>
              <div className="fs-14 steel">
                {getExpirationText(
                  props.migrationEndpoint.createdAt,
                  props.migrationEndpoint.expireIn
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
});

const FileShare = (props: Props) => <View {...props} {...OBSERVERS} />;

export default FileShare;
