import { GET, POST, DELETE, buildListParams } from 'utils';
import { AxiosPromise } from 'axios';
import { ICreateDocBody, IDoc, IRequestQuery } from 'documents';
import { IAxiosPromise } from 'axios-shapes';

export const get = (params: IRequestQuery): IAxiosPromise<IDoc[]> => {
  const query = buildListParams(params, ['orderBy', 'orderType', 'state']);
  return GET('/documents/requests', query);
};

export const create = (payload: ICreateDocBody): AxiosPromise<IDoc> => {
  return POST('/documents/requests', {}, payload);
};

export const getById = (id: number): AxiosPromise<IDoc> => {
  return GET(`/documents/requests/${id}`);
};

export const remove = (id: number): AxiosPromise<IDoc> => {
  return DELETE(`/documents/requests/${id}`);
};
