import * as React from 'react';
import { withPermissions } from 'hocs';
import UserContainer from './UserContainer';

const CoreAdmins = () => (
  <UserContainer
    reducer="admins"
    selectedReducer="selectedAdmin"
    subtext="services.core.admins.subtext"
    title="services.core.admins"
  />
);

export default withPermissions(CoreAdmins, 'DOMAIN_ADMINS');
