import * as React from 'react';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { usePermissions } from 'hooks';
import { sum } from 'ramda';
import { GLOBAL_DATE_FORMAT, GLOBAL_DATE_TIME_FORMAT } from 'enums';
import { dayjs } from 'utils';
import {
  Alert,
  Button,
  Chip,
  Col,
  CopyTooltip,
  LightTooltip,
  Paper,
  Row,
} from 'elements';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { OperatingSystemInfo } from 'pages/services/components/cards/contents/VirtualServer/VirtualServer';
import { InjectedProps, withVmActions } from 'hocs/withVmActions';
import ButtonList from 'components/ActionButtons/ButtonList';
import BillingInformation from './BillingInformation';
import ActionMenu from './ActionMenu';
import { InfoIcon } from './Styled';

const OBSERVERS = {
  snapshots: StateHandlers.snapshots,
  vm: StateHandlers.vmDetails,
  billingInfo: StateHandlers.billingInfo,
  osTemplates: StateHandlers.osTemplates,
  folders: StateHandlers.folders,
};

type IProps = InjectedProps;
type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    currentSnapshot,
    handlers,
    actionItems,
    snapshotTask,
    vm,
    billingInfo,
    osTemplates,
    injectedMedia,
  } = props;
  const { t } = useTranslation();
  const {
    permissions: { canManage: isEditable },
  } = usePermissions('SERVICES');
  const hasInjectedMedia = !!injectedMedia;
  const withBillingCycle = !!vm.data?.billingCycleId;
  const isBillingMonthly = withBillingCycle
    ? billingInfo.data.interval === 'NONE'
    : true;

  const totalDisksSize =
    vm.data?.osDiskSizeGb + sum(vm.data?.disks?.map((d) => d.sizeGb));

  React.useEffect(() => {
    osTemplates.get('');
  }, []);

  const createdSnapshot = !currentSnapshot?.schedule
    ? currentSnapshot
    : undefined;
  const scheduledSnapshot = currentSnapshot?.schedule
    ? currentSnapshot
    : undefined;

  return (
    <>
      <Paper className="p-30 mb-15">
        <Row
          className="mb-25"
          alignItems="center"
          justifyContent="space-between"
          columnSpacing={2}
        >
          <Col>
            <div className="uppercase fs-14 bolder">
              {t('services.vm.title')}
            </div>
          </Col>
          {isEditable && (
            <Col xs>
              <Row alignItems="center" justifyContent="flex-end">
                <Col>
                  <ButtonList actions={actionItems || []} />
                </Col>

                <Col className="pl-10 pb-5 pt-5">
                  <div className="flex justify-end full-width">
                    <ActionMenu />
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        {hasInjectedMedia && (
          <div className="mb-25">
            <Alert severity="info" className="flex align-center">
              <Row columnSpacing={2} alignItems="center">
                <Col>
                  <Trans
                    i18nKey="media.alerts.injected"
                    values={{ name: injectedMedia }}
                    components={{
                      1: <span className="primary" />,
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    size="small"
                    color="default"
                    variant="outlined"
                    onClick={handlers.ejectMedia}
                  >
                    {t('media.buttons.eject')}
                  </Button>
                </Col>
              </Row>
            </Alert>
          </div>
        )}
        {scheduledSnapshot && (
          <Alert severity="info" className="flex align-center mb-20">
            <Row columnSpacing={2} alignItems="center">
              <Col>
                {t('services.vm.alert.snapshot.planned.title', {
                  startAt: dayjs(scheduledSnapshot.schedule?.startAt).format(
                    GLOBAL_DATE_TIME_FORMAT
                  ),
                })}
              </Col>
              <Col>
                <Button
                  size="small"
                  color="default"
                  variant="outlined"
                  onClick={() =>
                    handlers.removeScheduledSnapshotTask(scheduledSnapshot)
                  }
                >
                  {t('services.content.action.removeScheduledSnapshotTask')}
                </Button>
              </Col>
            </Row>
          </Alert>
        )}
        <div>
          <Row columnSpacing={2} className="mb-20 fs-14">
            <Col xs={2}>
              <div className="mb-5 steel">
                {t('services.vm.label.serverName')}
              </div>
              <div className="flex align-center">
                <div className="break-word">
                  <CopyTooltip>{vm.data?.name}</CopyTooltip>
                </div>
                {isBillingMonthly && !vm.data?.isUsageEnabled && (
                  <div className="ml-10">
                    <Chip status="info">
                      {t('services.vm.chips.billing.disabled')}
                    </Chip>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={2}>
              <div className="mb-5 steel">
                {t('services.vm.label.creationDate')}
              </div>
              <div className="flex align-center">
                <div>
                  <CopyTooltip>
                    {dayjs(vm.data?.createdAt).format(GLOBAL_DATE_FORMAT)}
                  </CopyTooltip>
                </div>
              </div>
            </Col>
            <Col xs={3}>
              <div className="mb-5 steel">{t('services.vm.label.os')}</div>
              <div>
                <CopyTooltip>
                  {`${vm.data?.os.name} ${
                    vm.data?.os.lang
                      ? `(${t(`app.lang.${vm.data.os.lang}`)})`
                      : ''
                  }`}
                </CopyTooltip>
              </div>
            </Col>

            {createdSnapshot && (
              <Col xs className={cn({ disabled: snapshotTask?.isTaskActive })}>
                <div className="mb-5 steel">
                  {t('services.vm.label.snapshot.title')}
                </div>
                <div>
                  <Row alignItems="center">
                    <Col>
                      {
                        t('services.vm.label.snapshot.titleWithCreated', {
                          day: dayjs(createdSnapshot.createdAt).format(
                            GLOBAL_DATE_FORMAT
                          ),
                          time: dayjs(createdSnapshot.createdAt).format(
                            'HH:mm:ss'
                          ),
                        }) as string
                      }
                    </Col>
                    {createdSnapshot.description && (
                      <LightTooltip
                        title={createdSnapshot.description}
                        placement="right"
                        arrow
                      >
                        <InfoIcon className="fs-20 ml-5" />
                      </LightTooltip>
                    )}
                  </Row>
                  {createdSnapshot.removalOn && (
                    <Row alignItems="center">
                      <Col>
                        {
                          t('services.vm.label.snapshot.autoremovalLabel', {
                            day: dayjs(createdSnapshot.removalOn).format(
                              GLOBAL_DATE_FORMAT
                            ),
                            time: dayjs(createdSnapshot.removalOn).format(
                              'HH:mm'
                            ),
                          }) as string
                        }
                      </Col>
                      {createdSnapshot.description && (
                        <LightTooltip
                          title={createdSnapshot.description}
                          placement="right"
                          arrow
                        >
                          <InfoIcon className="fs-20 ml-5" />
                        </LightTooltip>
                      )}
                    </Row>
                  )}
                </div>
              </Col>
            )}
          </Row>
          <div className="mb-30 fs-14">
            <div className="mb-5 steel">
              {t('services.vm.label.description')}
            </div>
            <div className="break-line-all">{vm.data?.description || '-'}</div>
          </div>
          <OperatingSystemInfo
            {...vm.data}
            osDiskSizeGb={totalDisksSize}
            size={40}
          />
        </div>
      </Paper>

      {!!vm.data?.billingCycleId && (
        <BillingInformation id={vm.data.billingCycleId} />
      )}
    </>
  );
});

const VmInfo = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default withVmActions(VmInfo, 'single');
