import * as React from 'react';
import { PermissionsShape } from 'global-shapes';
import { PermissionList } from 'auth-shapes';
import { useTranslation } from 'react-i18next';
import { usePermissions, useUserHash } from 'hooks';
import { Button, Row } from 'elements';
import { DEFAULT_PARTNERS_QUERY, DEFAULT_CUSTOMERS_QUERY } from 'enums';

export type InjectedPermissionsProps = PermissionsShape;

export function withPermissions<P>(
  Component: React.ComponentType<P & InjectedPermissionsProps>,
  permissionType: PermissionList
): React.FC<Partial<P & InjectedPermissionsProps>> {
  return function (props): JSX.Element {
    const [, changeUrl] = useUserHash();
    const { t } = useTranslation();
    const {
      permissionsReceived,
      permissions,
      currentAccessLevel,
      ...rest
    } = usePermissions(permissionType);

    const onGoHome = React.useCallback(() => {
      if (currentAccessLevel === 'provider') {
        changeUrl('/partners', DEFAULT_PARTNERS_QUERY);
      }
      if (currentAccessLevel === 'partner') {
        changeUrl('/customers', DEFAULT_CUSTOMERS_QUERY);
      }
      if (currentAccessLevel === 'tenant') {
        changeUrl('/services/all');
      }
    }, [currentAccessLevel]);

    if (permissionsReceived && !permissions.canView) {
      return (
        <Row
          justifyContent="center"
          alignItems="center"
          className="text-center"
          style={{ minHeight: `calc(100vh - 280px)` }}
        >
          <div>
            <div className="fs-20 mb-35">
              {t('common.permissions.restricted')}
            </div>
            <Button onClick={onGoHome}>{t('app.goHome')}</Button>
          </div>
        </Row>
      );
    }

    return (
      // @ts-ignore
      <Component
        {...props}
        {...rest}
        permissionsReceived={permissionsReceived}
        permissions={permissions}
        currentAccessLevel={currentAccessLevel}
      />
    );
  };
}
