import { showSystemMessage } from 'utils';
import { snapshotsService } from 'services';
import { ISnapshot } from 'snapshots';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export const snapshots = new BaseMobxInstanceDecorator<ISnapshot, true>({
  instanceName: 'snapshots',
  requests: {
    get: () =>
      snapshotsService.get({
        perPage: 1000,
        page: 1,
        orderBy: 'createdAt',
        orderType: 'desc',
      }),
    create: async (payload) => {
      const res = await snapshotsService.create(payload);
      showSystemMessage('snapshots.notify.create.success', 'success');
      return res;
    },
    update: async (id) => {
      const res = await snapshotsService.revert(id as number);
      showSystemMessage('snapshots.notify.revert.success', 'success');
      return res;
    },
    remove: async (id) => {
      const res = await snapshotsService.remove(id as number);
      showSystemMessage('snapshots.notify.remove.success', 'success');
      return res;
    },
  },
  additionalRequests: {
    cancelAutoremoval: async (id: number) =>
      snapshotsService.cancelAutoremoval(id),
  },
  initialState: [],
});

export type ISnapshotsHandler = BaseDecorator<ISnapshot, true>;
