import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ALL_TEST_IDS } from 'enums';
import { usePermissions, useTask, useUserHash, useState } from 'hooks';
import {
  Row,
  Col,
  Paper,
  Loader,
  Alert,
  CopyTooltip,
  MuiIcons,
  Menu,
  MenuItem,
  IconButton,
} from 'elements';
import { noop, confirm } from 'utils';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { ITaskAction } from 'task-manager-service';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import IpSecVpnTunnelTable from './IpSecVpnTunnelTable';
import LimitRateDialog from './LimitRateDialog';
import { isValidDomain } from './helpers';
import { ConnectivityMainWrapper, SDnsEditableText } from './Styled';

type IState = {
  rateLimitDialogOpen: boolean;
};

const INITIAL_STATE: IState = {
  rateLimitDialogOpen: false,
};

const OBSERVERS = {
  connectivity: StateHandlers.connectivity,
  reverseDnsRecords: StateHandlers.reverseDns,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { connectivity, reverseDnsRecords } = props;
  const { t } = useTranslation();
  const { permissions } = usePermissions('SERVICES');
  const [, changeQuery] = useUserHash();

  const [state, handleState] = useState<IState>(INITIAL_STATE);

  const currentReverseDnsRecord = useMemo(
    () => reverseDnsRecords.data[0],
    [reverseDnsRecords.data]
  );

  const fetchReverseDnsRecord = useCallback(() => {
    if (connectivity.data?.appEntityId) {
      return reverseDnsRecords.get({
        appEntityIds: [connectivity.data?.appEntityId],
      });
    }
  }, [connectivity.data?.appEntityId]);

  const reverseDnsTask = useTask(currentReverseDnsRecord?.task, {
    onComplete: fetchReverseDnsRecord,
  });

  const getConnectivity = React.useCallback(async (action: ITaskAction) => {
    await connectivity.get(undefined);
    if (action === 'delete') {
      changeQuery('/services/all');
    }
  }, []);

  const onRemoveConnectivity = React.useCallback(
    () =>
      confirm({
        title: t('services.connectivity.confirm.delete.title'),
        content: t('services.connectivity.confirm.delete.content'),
        onSuccess: async () => {
          await connectivity.remove(null);
          return connectivity.get();
        },
        onCancel: noop,
      }),
    []
  );

  const createReverseDnsRecord = useCallback(
    (recordDomainName: string) => {
      return reverseDnsRecords
        .create({
          appEntityId: connectivity.data?.appEntityId,
          ipAddress: connectivity.data?.publicIp,
          recordDomainName,
        })
        .then(fetchReverseDnsRecord);
    },
    [connectivity.data]
  );

  const updateReverseDnsRecord = useCallback(
    (recordDomainName: string) => {
      return reverseDnsRecords
        .update(currentReverseDnsRecord?.id, {
          ipAddress: connectivity.data?.publicIp,
          recordDomainName,
        })
        .then(fetchReverseDnsRecord);
    },
    [currentReverseDnsRecord, JSON.stringify(connectivity.data)]
  );

  const removeReverseDnsRecord = useCallback(() => {
    confirm({
      title: t('reverseDnsRecords.confirm.delete.title'),
      content: t('reverseDnsRecords.confirm.delete.content'),
      onSuccess: async () => {
        await reverseDnsRecords.remove(currentReverseDnsRecord?.id);
        return fetchReverseDnsRecord();
      },
      onCancel: () => undefined,
    });
  }, [currentReverseDnsRecord, JSON.stringify(connectivity.data)]);

  const task = useTask(connectivity.data?.task, {
    onComplete: getConnectivity,
  });

  React.useEffect(() => {
    fetchReverseDnsRecord();
  }, [connectivity.data?.appEntityId]);

  if (!connectivity.dataReceived) return <Loader />;

  if (!connectivity.data) {
    return (
      <Paper className="p-30 full-width">
        <h4 className="text-center">{t('common.noData')}</h4>
      </Paper>
    );
  }

  if (task.isCreating) {
    return (
      <Paper className="p-30 flex full-width align-center">
        <div className="bolder mr-25">
          {t('services.connectivity.creatingStatusMessage')}{' '}
        </div>{' '}
        <div>
          <Loader />
        </div>
      </Paper>
    );
  }

  return (
    <ConnectivityMainWrapper
      className={cn('p-30 full-width', {
        disabled: task.isTaskActive || connectivity.isRequesting,
      })}
    >
      <Row alignItems="center" justifyContent="space-between" className="mb-15">
        <Col>
          <div className="uppercase fs-14 bolder">
            {t('services.connectivity.internalNetwork')}
          </div>
        </Col>
        <Col>
          {permissions.canManage && (
            <PopupState variant="popover">
              {(popupState) => (
                <>
                  <MuiIcons.MoreVert
                    {...bindTrigger(popupState)}
                    className="fs-22 steel pointer"
                  />

                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      data-test-id={`connectivity-menu-item`}
                      onClick={() => {
                        onRemoveConnectivity();
                        popupState.close();
                      }}
                    >
                      {t(`services.content.action.removeConnectivity`)}
                    </MenuItem>
                  </Menu>
                </>
              )}
            </PopupState>
          )}
        </Col>
      </Row>
      {task.isFailed && task.error && (
        <Alert severity="error" className="mb-10">
          {task.error.message}
        </Alert>
      )}
      <Row className="mb-45" columnSpacing={2}>
        <Col xs>
          <div className="mb-5 fs-14">
            {t('services.connectivity.internalNetworkAddress')}:
          </div>
          <div
            className="steel"
            data-test-id={ALL_TEST_IDS.services.connectivity.network}
          >
            <CopyTooltip>
              {connectivity.data.internalNetworkAddress}
            </CopyTooltip>
          </div>
        </Col>
        {/* <Col xs>
          <div className="mb-5 fs-14">
            {t('services.connectivity.internalIpPool')}:
          </div>
          <div className="steel">
            <SEditableText
              isEditable={permissions.canManage}
              elementSize="sm"
              text={`${start}-${end}`}
              testId={ALL_TEST_IDS.services.connectivity.internalPool}
              displayText={
                <CopyTooltip>{`${connectivity.data.internalIpPoolStart} - ${connectivity.data.internalIpPoolEnd}`}</CopyTooltip>
              }
              textClassName="steel"
              onSuccess={updateConnectivityService}
              disabled={task.isFailed}
              isUpdating={connectivity.isRequesting}
              validate={(text: string) =>
                validateIpPoolRange(text, clearNetwork)
              }
              typingRules={{
                max: 7,
                restrict: validator.numRange,
              }}
              startAdornment={
                <ConnectivityAdornment className={cn('pl-10')}>
                  {clearNetwork}.
                </ConnectivityAdornment>
              }
            />
          </div>
        </Col> */}
        <Col xs>
          <div className="mb-5 fs-14">{t('services.connectivity.wanIp')}:</div>
          <div
            className="steel"
            data-test-id={ALL_TEST_IDS.services.connectivity.wanIp}
          >
            <CopyTooltip>{connectivity.data.publicIp}</CopyTooltip>
          </div>
        </Col>
        <Col xs>
          <div className="fs-14">{t('services.connectivity.rateLimit')}:</div>
          <div className="steel flex align-center">
            {connectivity.data?.qosProfileName}/
            {connectivity.data?.qosProfileName}
            <IconButton
              size="small"
              className="steel ml-10"
              onClick={() => handleState({ rateLimitDialogOpen: true })}
            >
              <MuiIcons.Edit className="fs-20" />
            </IconButton>
          </div>
        </Col>
        <Col xs>
          <div className="mb-5 fs-14">
            {t('services.connectivity.reverseDns')}:
          </div>
          <div className="steel">
            <SDnsEditableText
              isEditable={permissions.canManage}
              elementSize="sm"
              text={currentReverseDnsRecord?.domainName}
              testId={ALL_TEST_IDS.services.connectivity.internalPool}
              placeholder={t('common.url')}
              displayText={currentReverseDnsRecord?.domainName || '-'}
              textClassName="steel"
              onSuccess={
                !currentReverseDnsRecord
                  ? createReverseDnsRecord
                  : updateReverseDnsRecord
              }
              disabled={reverseDnsTask.isFailed}
              isUpdating={
                reverseDnsTask.isTaskActive || reverseDnsRecords.isRequesting
              }
              onDelete={currentReverseDnsRecord && removeReverseDnsRecord}
              validate={isValidDomain}
            />
          </div>
        </Col>
      </Row>
      <Alert severity="info" className="mb-25 full-width">
        {t('services.connectivity.alerts.blockSMTP')}
      </Alert>
      <IpSecVpnTunnelTable disabled={task.isFailed} />
      <LimitRateDialog
        open={state.rateLimitDialogOpen}
        onClose={() => handleState({ rateLimitDialogOpen: false })}
        onSave={() => handleState({ rateLimitDialogOpen: false })}
      />
    </ConnectivityMainWrapper>
  );
});

const Connectivity = () => <View {...OBSERVERS} />;

export default Connectivity;
