import { remCalc, important } from 'utils';
import { PALETTE } from 'enums';
import { styled } from 'elements';
import CardHolder from '../CardHolder';
import FolderHolder from '../FolderHolder';
import { LINE_COLOR } from '../../../constants';

export const SConnectivityCard = styled(CardHolder)(({ theme }) => ({
  '&::before': {
    content: "''",
    height: remCalc(22),
    width: 0,
    top: '100%',
    left: '50%',
    position: 'absolute',
    borderLeft: `2px dashed ${LINE_COLOR}`,
  },
}));

export const SCoreCard = styled(CardHolder)({
  position: 'relative',
  '&::before': {
    content: "''",
    height: 0,
    width: remCalc(24),
    top: '50%',
    left: remCalc(-24),
    position: 'absolute',
    borderTop: `2px dashed ${LINE_COLOR}`,
  },
});

export const SVmCard = styled(CardHolder)(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${PALETTE.lightGrey}`,
  height: important('auto'),
  minHeight: remCalc(388),
  width: remCalc(325),
  borderRadius: 6,
  '& ._rs-icon': {
    position: 'absolute',
    left: remCalc(9),
    top: remCalc(7),
    zIndex: 1,
    width: remCalc(12),
  },
}));

export const SFolderCard = styled(FolderHolder)(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${PALETTE.lightGrey}`,
  height: important('auto'),
  minHeight: remCalc(388),
  width: remCalc(325),
  borderRadius: 6,
}));
