import {
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
  NavigateOptions,
} from 'react-router-dom';
import { isEmpty } from 'ramda';
import qs from 'query-string';
import { AnyShape } from 'global-shapes';
import { useForceUpdate } from './useForceUpdate';

interface AnyQuery {
  [field: string]: any;
}

export type RouterState<P = AnyQuery> = {
  query: P;
  params: AnyQuery;
  location: AnyShape;
  queryStr: string;
  changeQuery: (
    query: Partial<P>,
    url?: string,
    shouldClarifyQuery?: boolean,
    openInNewTab?: boolean,
    options?: NavigateOptions
  ) => void;
};

const compact = (obj: AnyShape) => {
  Object.keys(obj).forEach((key: string) => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

export function useQuery<P = AnyQuery>(
  onChangeCallback?: (query: P) => void
): RouterState<P> {
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [, changeParams] = useSearchParams();
  const query = qs.parse(location.search, {
    arrayFormat: 'bracket',
  }) as unknown as P;

  const changeQuery = (
    nQuery: AnyQuery = {},
    url = '',
    shouldClarifyQuery = false,
    openInNewTab = false,
    options?: NavigateOptions
  ) => {
    const preQuery: AnyShape = shouldClarifyQuery
      ? {}
      : qs.parse(location.search, { arrayFormat: 'bracket' });
    const newQuery: AnyShape = { ...preQuery, ...(nQuery || {}) };
    const newQueryCleared = isEmpty(newQuery) ? {} : compact(newQuery);
    const newSearch: string = isEmpty(newQuery)
      ? ''
      : `?${qs.stringify(compact(newQuery), { arrayFormat: 'bracket' })}`;

    if (openInNewTab) {
      const win = window.open(url + newSearch, '_blank');
      win && win.focus();
      return;
    }

    // @ts-ignore
    onChangeCallback && onChangeCallback(newQueryCleared);

    if (url) {
      return navigate(url + newSearch, options);
    }

    changeParams(newSearch);
    forceUpdate();
  };

  return {
    query,
    queryStr: location.search,
    changeQuery,
    params,
    location,
  };
}
