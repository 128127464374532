import * as React from 'react';
import cn from 'classnames';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'elements';
import { numberToCurrency } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { ServiceBlockWrapper, PriceDetailsListItem } from './Styled';

const OBSERVERS = {
  prepaidUsage: StateHandlers.billingCycle,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { prepaidUsage } = props;

  React.useEffect(() => {
    prepaidUsage.get({ page: 1, perPage: 1000 });
  }, []);

  return <PrepaidServiceContent prepaidUsage={prepaidUsage} />;
});

const PrepaidServiceContent = (props: IViewProps) => {
  const { prepaidUsage } = props;
  const { t } = useTranslation();

  if (!prepaidUsage.data.length) {
    return (
      <div
        className="flex justify-center pt-20 pb-10 fs-14 steel m-auto text-center"
        style={{ maxWidth: 440 }}
      >
        {t('usage.details.tabs.prepaid.noData')}
      </div>
    );
  }

  const totalCost = prepaidUsage.data.reduce((res, val) => {
    res = res + R.sum(val.billableItems?.map((bi) => +bi.amount || 0) || []);
    return res;
  }, 0);

  return (
    <>
      <div className="pl-40 pr-20">
        <Row className="uppercase steel fs-10 mb-5" columnSpacing={1}>
          <Col xs={10}>{t('usage.details.table.head.services')}</Col>
          <Col xs={2}>
            {t('usage.details.table.head.total')}, <br /> CHF
          </Col>
        </Row>
      </div>
      {prepaidUsage.data.map((bc) => {
        if (!bc.billableItems?.length) return null;
        return (
          <ServiceBlockWrapper
            key={bc.id}
            className={cn({ disabled: prepaidUsage.isRequesting })}
          >
            {bc.billableItems.map((bi) => {
              return (
                <React.Fragment key={`bi-item-${bc.id + bi.id}`}>
                  <Row className="mb-15" columnSpacing={1}>
                    <Col xs={10} className="bold">
                      {bi.description}
                    </Col>
                    <Col xs={2} className="uppercase steel">
                      {numberToCurrency(+bi.amount, false)}
                    </Col>
                  </Row>
                  {bi.subItems?.map((si) => {
                    return (
                      <Row
                        alignItems="center"
                        className="mb-10"
                        key={si.id}
                        columnSpacing={1}
                      >
                        <Col xs={10}>
                          <PriceDetailsListItem>
                            {si.description}
                          </PriceDetailsListItem>
                        </Col>
                        <Col xs={2} className="uppercase steel">
                          {numberToCurrency(+si.amount, false)}
                        </Col>
                      </Row>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </ServiceBlockWrapper>
        );
      })}
      <div className="pt-20 pl-40 pr-20">
        <Row className="fs-17 mb-5" columnSpacing={1}>
          <Col xs={10} className="bold">
            {t('usage.details.totalPrice')}, {t('usage.priceRow.chf')}
          </Col>
          <Col xs={2} className="steel">
            {numberToCurrency(totalCost, false)}
          </Col>
        </Row>
      </div>
    </>
  );
};

const PrepaidServicesTab = () => <View {...OBSERVERS} />;

export default PrepaidServicesTab;
