import * as React from 'react';
import {
  Row,
  Col,
  Button,
  Dialog,
  DialogProps,
  DateRangePicker,
  Loader,
  DateRangePickerElements,
} from 'elements';
import { withTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import { utcDayjs, dayjs } from 'utils';

interface IState {
  startDate: string;
  endDate: string;
}

type IProps = React.PropsWithChildren<
  WithT &
    DialogProps<IState> & {
      isDisabled: boolean;
    }
>;

const INITIAL_STATE = {
  startDate: utcDayjs().add(-30, 'day').startOf('day').toISOString(),
  endDate: utcDayjs().endOf('day').toISOString(),
};

const Renderer = (props: IProps) => {
  const { t, open, onClose, onSave: onSubmit, isDisabled } = props;

  const [date, handleState] = React.useState<IState>(INITIAL_STATE);

  const handleDate =
    React.useCallback<DateRangePickerElements.IDateChangeCallback>(
      ({ startDate, endDate }) =>
        handleState({
          startDate: dayjs(startDate).startOf('day').toISOString(),
          endDate: dayjs(endDate).endOf('day').toISOString(),
        }),
      []
    );

  const handleCloseDialog = React.useCallback(() => {
    if (isDisabled) {
      return;
    }
    onClose();
  }, [isDisabled, onClose]);

  const onSave = React.useCallback(() => {
    onSubmit && onSubmit(date);
  }, [date, onSubmit]);

  React.useEffect(() => {
    if (!open) {
      handleState(INITIAL_STATE);
    }
  }, [open]);

  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={handleCloseDialog}
      title={t('invoices.exportPayments.dialog.title')}
      fullWidth
      actions={
        <Row columnSpacing={2} alignItems="center" justifyContent="flex-end">
          <Col className="mr-15">{isDisabled && <Loader size={20} />}</Col>
          <Col>
            <Button
              variant="outlined"
              color="default"
              onClick={handleCloseDialog}
            >
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button onClick={onSave} disabled={isDisabled}>
              {t('common.export')}
            </Button>
          </Col>
        </Row>
      }
    >
      <DateRangePicker
        label={t('forms.paymentDate')}
        value={date}
        onChange={handleDate}
        closeOnSelect
      />
    </Dialog>
  );
};

const ExportPaymentsDialog = withTranslation()(Renderer);

export default ExportPaymentsDialog;
