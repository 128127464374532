import { AnyShape, DefaultListQuery } from 'global-shapes';
import { Session } from 'sessions';
import { buildListParams, DELETE, GET, PATCH, POST, PUT } from 'utils';
import { IAxiosPromise } from 'axios-shapes';
import { AxiosPromise } from 'axios';
import { IBillingFormPayload } from 'customer-shapes';
import { uploadImage } from './imageUploader';

export const getPartners = (
  params: DefaultListQuery
): IAxiosPromise<IPartnerTypes.Partner[]> =>
  GET(
    '/partners',
    buildListParams(params, [
      'status',
      'orderBy',
      'orderType',
      'q',
      'enabledBilling',
    ])
  );

export const getPartner = (
  params: AnyShape
): AxiosPromise<IPartnerTypes.Partner> => {
  const { id, ...otherParams } = params;
  return GET(`/partners/${id}`, otherParams);
};

export const getPartnerByShortName = (
  name: string,
  config?: any
): AxiosPromise<IPartnerTypes.Partner> => {
  return GET(`/partners/${name}`, {}, config);
};

export const updateCurrentPartner = (
  id: number,
  payload: IBillingFormPayload
): AxiosPromise<IPartnerTypes.Partner> => PATCH(`/partners/${id}`, {}, payload);

export const create = (
  payload: IPartnerTypes.PartnerFormValues
): AxiosPromise<IPartnerTypes.Partner> => {
  const partner = {
    shortName: payload.shortName,
    name: payload.companyName,
    type: payload.type,
    address: {
      line1: payload.line1,
      line2: payload.line2,
      zipCode: payload.zipCode,
      city: payload.city,
      countryCode: payload.countryCode.value,
    },
    ...(payload.shouldUserValidate
      ? {
          admin: {
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            lang: payload.lang && payload.lang?.value,
          },
        }
      : {}),
  };
  return POST('/partners', {}, partner);
};
export const update = (
  id: number,
  partner: IPartnerTypes.PartnerFormValues
): AxiosPromise<IPartnerTypes.Partner> => PUT(`/partners/${id}`, {}, partner);

export const deletePartner = (id: number): AxiosPromise<Task> =>
  DELETE(`/partners/${id}`);

export const logInUnderPartner = (
  id: number,
  token: string
): AxiosPromise<Session> =>
  POST(
    `/auth/partner/${id}/session`,
    {},
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
      shouldUpdate: true,
    }
  );

export const expandEvaluation = (
  partnerId: number,
  payload: IPartnerTypes.IExtendPartnerPayload
): AxiosPromise<IPartnerTypes.Partner> =>
  PATCH(`/partners/${partnerId}/expand-evaluation`, {}, payload);

export const completeEvaluation = (
  partnerId: number
): AxiosPromise<IPartnerTypes.Partner> =>
  PATCH(`/partners/${partnerId}/complete-evaluation`);

export const switchToEvaluation = (
  partnerId: number
): AxiosPromise<IPartnerTypes.Partner> =>
  PATCH(`/partners/${partnerId}/approve-evaluation`);

export const requestSwitchToProd = (
  partnerId: number
): AxiosPromise<IPartnerTypes.Partner> =>
  PATCH(`/partners/${partnerId}/request-complete-evaluation`);

export const updateCustomerInvoice = (
  data: IPartnerTypes.ICustomerInvoice
): AxiosPromise<IPartnerTypes.ICustomerInvoice> => {
  const payload = {
    ...data,
    phone: `+${data.phone}`,
  };
  return PUT(`/partners`, {}, { invoiceSettings: payload });
};

export const updateInvoiceSettings = (
  partnerId: number,
  data: IPartnerTypes.ICustomerInvoiceValues
): AxiosPromise<IPartnerTypes.ICustomerInvoice> => {
  const {
    otherBankAccount,
    otherBankDetails,
    otherBankIdentificationCode,
    swizzIban,
    swizzVatId,
    otherVatId,
    otherIban,
    email,
    phone,
    stripePaymentsEnabled,
  } = data;

  const hasSwizz = !!(swizzIban?.trim() || swizzVatId?.trim());

  const hasOther = !!(
    otherBankAccount?.trim() ||
    otherVatId?.trim() ||
    otherIban?.trim() ||
    otherBankIdentificationCode?.trim() ||
    otherBankDetails?.trim()
  );

  const accounts: IPartnerTypes.IAccountBody[] = [];

  if (hasSwizz) {
    accounts.push({
      isDefault: true,
      forSwissCustomers: true,
      isForeignCustomersDefault: !hasOther,
      vatId: swizzVatId.trim(),
      iban: swizzIban.trim(),
    });
  }

  if (hasOther) {
    accounts.push({
      isDefault: !hasSwizz,
      forSwissCustomers: false,
      isForeignCustomersDefault: true,
      vatId: otherVatId.trim(),
      iban: otherIban.trim(),
      bankDetails: otherBankDetails?.trim(),
      bankAccount: otherBankAccount?.trim(),
      bankIdentificationCode: otherBankIdentificationCode?.trim(),
    });
  }

  const payload: IPartnerTypes.ICustomerInvoicePayload = {
    email,
    stripePaymentsEnabled,
    phone: `+${phone.replace('+', '')}`,
    accounts,
  };

  return PUT(`/partners/${partnerId}/invoice-settings`, {}, payload);
};

export const updateCurrentProfileInfo = async (
  payload: IPartnerTypes.IPartnerInfoFormValues,
  logo?: File | string
): Promise<AxiosPromise<IPartnerTypes.Partner>> => {
  const data = { ...payload };
  if (logo && typeof logo !== 'string') {
    const image = await uploadImage(logo, 'logos');
    data.logoId = image.data.id;
  }
  if (!logo) {
    data.logoId = null;
  }
  return PUT('/partners', {}, data);
};

export function setQuotes(
  id: number,
  data: IQuotaTypes.IQuotaFormValues
): AxiosPromise<IPartnerTypes.Partner> {
  const payload = {
    maxTenants: data.maxTenants,
  };
  return PATCH(`/partners/${id}`, {}, payload);
}

export function resolveInvitationSettings(inviteKey: string): AxiosPromise<{
  enabled: boolean;
}> {
  return POST('/partners/resolve-invitation-settings', { inviteKey });
}

export function undoDeletion(id: number): AxiosPromise<IPartnerTypes.Partner> {
  return DELETE(`/partners/${id}/undo`);
}

export const getTenantBillingRate = (
  partnerId: number
): AxiosPromise<IDedicatedServer.BillingRate> => {
  return GET(`/partners/${partnerId}/tenants-billing-rate`, {});
};

export function updateTenantBillingRate(
  id: number,
  payload: { rate: number }
): AxiosPromise<IDedicatedServer.BillingRate> {
  return PUT(`/partners/${id}/tenants-billing-rate`, {}, payload);
}

export const getPartnerInvoiceSettings = (
  partnerId: number
): AxiosPromise<IPartnerTypes.ICustomerInvoice> => {
  return GET(`/partners/${partnerId}/invoice-settings`);
};
