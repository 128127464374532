import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogProps, Button, Row, Col, LinearTable } from 'elements';
import { useStateHandler } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import * as ENUMS from '../constants';

type Props = DialogProps;

const FirewallRulesDialog = observer((props: Props) => {
  const { t } = useTranslation();
  const { open, onClose } = props;
  const natRules = useStateHandler(StateHandlers.natRules);

  return (
    <Dialog
      title={t('services.connectivity.natRules.title')}
      maxWidth="md"
      fullWidth
      fullScreen
      showCloseIcon
      open={open}
      onClose={onClose}
      contentProps={{
        style: {
          minHeight: 'calc(100vh - 220px)',
        },
      }}
      actions={
        <Row alignItems="center" justifyContent="flex-end">
          <Col>
            <Button color="default" variant="outlined" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
        </Row>
      }
    >
      <LinearTable
        data={natRules.data}
        columns={ENUMS.getNatRulesColumns({
          t,
        })}
        isLoading={!natRules.dataReceived}
      />
    </Dialog>
  );
});

export default FirewallRulesDialog;
