import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Checkbox,
  Loader,
  Button,
  Dialog,
  DialogProps,
  ColorDot,
  Slider,
  SliderValueLabel,
} from 'elements';
import * as StateHandlers from 'states';
import { useStateHandler, useAsync } from 'hooks';
import * as SERVICES from 'services';
import { remCalc, toggleArrayState } from 'utils';
import {
  parseInitilaFiltersValues,
  parseFiltersValuesToQuery,
} from './helpers';
import {
  FILTERS_STATUS_OPTIONS,
  FILTERS_BILLINC_CYCLE_OPTIONS,
  DEFAULT_FILTERS_FORM_QUERY,
  ACTIVE_SERVICES,
} from './constants';

type Props = DialogProps<any> & {
  initialValues: IVmTypes.VmFiltersParams & IVmTypes.VMParams;
};

const PAPER_PROPS = { style: { maxWidth: remCalc(1140) } };
const CONTENT_PROPS = { className: 'fs-14' };

const VmFiltersDialog = (props: Props) => {
  const { open, initialValues, onSave, onClose } = props;
  const { t } = useTranslation();
  const badges = useStateHandler(StateHandlers.badges);
  const currentUser = useStateHandler(StateHandlers.currentUser);
  const {
    execute: fetchOS,
    isPending: isFetchingOS,
    value: systems,
  } = useAsync((q: string) =>
    SERVICES.virtualMachinesService.getOsList({
      q,
      limit: 1000,
      offset: 0,
      orderBy: 'name',
      orderType: 'asc',
      tenantId: currentUser.data.tenant?.id,
    })
  );

  const { handleSubmit, values, setFieldValue, resetForm, setValues } =
    useFormik({
      initialValues: DEFAULT_FILTERS_FORM_QUERY,
      validateOnMount: false,
      onSubmit: (val) =>
        onSave
          ? Promise.resolve(onSave(parseFiltersValuesToQuery(val)))
          : undefined,
    });

  const handleCheckboxChange = React.useCallback(
    (key: string, value: any) => () =>
      // @ts-ignore
      setFieldValue(key, toggleArrayState(values[key], value)),
    [JSON.stringify(values)]
  );

  React.useEffect(() => {
    fetchOS('');
  }, []);

  React.useEffect(() => {
    if (open) setValues(parseInitilaFiltersValues(initialValues));
    if (!open) {
      resetForm();
    }
  }, [JSON.stringify(initialValues), open]);

  return (
    <Dialog
      open={open}
      title={t(`services.vmList.toolbar.dialog.filters.title`)}
      PaperProps={PAPER_PROPS}
      fullWidth
      maxWidth="md"
      handleSubmit={handleSubmit}
      onClose={onClose}
      contentProps={CONTENT_PROPS}
      actions={
        <>
          <Button variant="outlined" color="default" onClick={props.onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit">{t('common.save')}</Button>
        </>
      }
    >
      <div className="steel mb-15">
        {t('services.vmList.toolbar.dialog.filters.content')}
      </div>
      {!!badges.data?.length && (
        <section className="mb-15 pb-15 bordered-bottom">
          <div className="fs-17 mb-15">
            <strong>
              {t('services.vmList.toolbar.dialog.filters.section.badges')}
            </strong>
          </div>
          <Row rowSpacing={1}>
            {badges.data.map((b) => (
              <Col key={b.id} xs={6}>
                <Row alignItems="center">
                  <Col>
                    <Checkbox
                      checked={values.badgeId?.includes(b.id)}
                      onCheck={handleCheckboxChange('badgeId', b.id)}
                    />
                  </Col>
                  <Col onClick={handleCheckboxChange('badgeId', b.id)}>
                    <ColorDot size={20} color={b.color} />
                  </Col>
                  <Col
                    xs
                    className="break-word-all pl-10 pointer"
                    onClick={handleCheckboxChange('badgeId', b.id)}
                  >
                    {b.description}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </section>
      )}
      <section className="mb-15 pb-15 bordered-bottom">
        <div className="fs-17 mb-15">
          <strong>
            {t('services.vmList.toolbar.dialog.filters.section.status')}
          </strong>
        </div>
        <Row rowSpacing={1}>
          {FILTERS_STATUS_OPTIONS.map((status) => (
            <Col key={status} xs={4}>
              <Row alignItems="center">
                <Col>
                  <Checkbox
                    checked={values.status?.includes(status)}
                    onCheck={handleCheckboxChange('status', status)}
                  />
                </Col>
                <Col
                  onClick={handleCheckboxChange('status', status)}
                  className="pointer"
                >
                  {t(`statuses.${status}`)}
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </section>
      <section className="mb-15 pb-15 bordered-bottom">
        <div className="fs-17 mb-15">
          <strong>
            {t('services.vmList.toolbar.dialog.filters.section.billingCycle')}
          </strong>
        </div>
        <Row rowSpacing={1}>
          {FILTERS_BILLINC_CYCLE_OPTIONS.map((cycle) => (
            <Col key={cycle} xs={4}>
              <Row alignItems="center">
                <Col>
                  <Checkbox
                    checked={values.billingType?.includes(cycle)}
                    onCheck={handleCheckboxChange('billingType', cycle)}
                  />
                </Col>
                <Col
                  onClick={handleCheckboxChange('billingType', cycle)}
                  className="pointer"
                >
                  {t(`app.billingCircle.${cycle}`)}
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </section>
      <section className="mb-15 pb-15 bordered-bottom">
        <div className="fs-17 mb-15">
          <strong>
            {t('services.vmList.toolbar.dialog.filters.section.os')}
          </strong>
        </div>
        {isFetchingOS ? (
          <Loader />
        ) : (
          <Row rowSpacing={1}>
            {systems?.map((os) => (
              <Col key={os.id} xs={4}>
                <Row alignItems="center">
                  <Col>
                    <Checkbox
                      checked={values.osId?.includes(os.id)}
                      onCheck={handleCheckboxChange('osId', os.id)}
                    />
                  </Col>
                  <Col
                    onClick={handleCheckboxChange('osId', os.id)}
                    className="pointer"
                  >
                    {os.name}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        )}
      </section>
      <section className="mb-15 pb-15 bordered-bottom">
        <div className="fs-17 mb-15">
          <strong>
            {t('services.vmList.toolbar.dialog.filters.section.activeServices')}
          </strong>
        </div>
        <Row rowSpacing={1}>
          {ACTIVE_SERVICES.map((s) => {
            const Icon = s.icon;
            return (
              <Col key={s.value} xs={3}>
                <Row alignItems="center" columnSpacing={1}>
                  <Col>
                    <Checkbox
                      checked={values.activeService?.includes(s.value)}
                      onCheck={handleCheckboxChange('activeService', s.value)}
                    />
                  </Col>
                  <Col
                    onClick={handleCheckboxChange('activeService', s.value)}
                    className="pointer"
                  >
                    <Row alignItems="center" columnSpacing={1}>
                      <Icon status="success" className="success fs-20" />
                      <Col>{t(`activeServices.${s.label}`)}</Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </section>
      <div className="pb-30">
        <div>
          <div className="fs-17 mb-10">
            <strong>
              {t('services.vmList.toolbar.dialog.filters.section.cpu')}
            </strong>
          </div>
          <div className="pl-15 pr-15">
            <Slider
              value={values.cpu}
              onChange={(ev, val) => setFieldValue('cpu', val)}
              valueLabelDisplay="auto"
              disableSwap
              components={{
                ValueLabel: SliderValueLabel,
              }}
              min={1}
              max={64}
              marks={[
                {
                  value: 1,
                  label: 1,
                },
                { value: 64, label: '64' },
              ]}
            />
          </div>
        </div>
        <div>
          <div className="fs-17 mb-10">
            <strong>
              {t('services.vmList.toolbar.dialog.filters.section.ram')}
            </strong>
          </div>
          <div className="pl-15 pr-15">
            <Slider
              value={values.ram}
              onChange={(ev, val) => setFieldValue('ram', val)}
              valueLabelDisplay="auto"
              disableSwap
              components={{
                ValueLabel: SliderValueLabel,
              }}
              min={1}
              max={512}
              marks={[
                {
                  value: 1,
                  label: 1,
                },
                { value: 512, label: '512' },
              ]}
            />
          </div>
        </div>
        <div>
          <div className="fs-17 mb-10">
            <strong>
              {t('services.vmList.toolbar.dialog.filters.section.storage')}
            </strong>
          </div>
          <div className="pl-15 pr-15">
            <Slider
              value={values.storage}
              onChange={(ev, val) => setFieldValue('storage', val)}
              valueLabelDisplay="auto"
              disableSwap
              components={{
                ValueLabel: SliderValueLabel,
              }}
              min={0}
              step={10}
              max={10000}
              marks={[
                {
                  value: 0,
                  label: 0,
                },
                { value: 10000, label: `10'000` },
              ]}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default VmFiltersDialog;
