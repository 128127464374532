import * as React from 'react';
import cn from 'classnames';
import { styled } from './utilities';
import {
  Switch as MuiSwitch,
  switchClasses,
  SwitchProps,
} from '@mui/base/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { remCalc } from 'utils';

const Root = styled(MuiSwitch)(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  min-width: ${remCalc(40)};
  height: ${remCalc(24)};
  
  margin: 0;
  cursor: pointer;

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    background: #c8c9cc;
    border-radius: ${remCalc(20)};
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchClasses.thumb} {
    display: block;
    width: ${remCalc(16)};
    height: ${remCalc(16)};
    top: ${remCalc(4)};
    left: ${remCalc(4)};
    border-radius: ${remCalc(25)};
    background-color: #FFF;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchClasses.focusVisible} .${switchClasses.thumb} {
    background-color: rgba(255,255,255,1);
    box-shadow: 0 0 1px 8px rgba(0,0,0,0.25);
  }

  &.${switchClasses.checked} { 
    .${switchClasses.thumb} {
      left: ${remCalc(20)};
      top: ${remCalc(4)};
      background-color: #FFF;
    }

    .${switchClasses.track} {
      background: ${theme.palette.primary.main};
    }
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }`
);

interface IProps extends SwitchProps {
  label?: any;
  testId?: string;
  labelClassName?: string;
  className?: string;
  name?: string;
  onCheck: (isChecked: boolean) => void;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

export const Switch = (props: IProps) => {
  const {
    testId,
    label,
    disabled,
    labelClassName,
    className,
    onCheck,
    checked,
    labelPlacement,
    ...rest
  } = props;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Root
            {...rest}
            checked={!!checked}
            onChange={() => {
              !disabled && onCheck(!props.checked);
            }}
          />
        }
        disabled={disabled}
        className={className}
        label={
          label && (
            <div className={cn(labelClassName, 'pl-10 pr-10')}>{label}</div>
          )
        }
        style={{ margin: 0 }}
        data-test-id={testId || ''}
        labelPlacement={labelPlacement || 'end'}
      />
    </FormGroup>
  );
};
