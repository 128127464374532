import * as React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Alert } from '../elements';

const ErrorComponent = () => {
  const { t } = useTranslation();
  return <Alert security="error">{t('common.errorComponent')}</Alert>;
};

export default function <P>(component: React.ComponentType<P>) {
  return withErrorBoundary(component, {
    FallbackComponent: ErrorComponent,
    onError(error) {
      console.error(error);
    },
  });
}
