import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { remCalc, dayjs } from 'utils';
import duration from 'dayjs/plugin/duration';
import { useLocale } from 'hooks';
import { GLOBAL_BORDER_RADIUS } from 'enums';
import {
  styled,
  Row,
  Col,
  MuiIcons,
  IconButton,
  Menu,
  MenuItem,
} from 'elements';
import { MAINTENANCE_OPTIONS } from './constants';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import {
  parseRepeatOnField,
  parseNextRebootField,
} from '../../RebootSchedules/helpers';

dayjs.extend(duration);

type IProps = IMonitoring.MonitoringMW & {
  onEdit: (el: IMonitoring.MonitoringMW) => void;
  onDelete: (el: IMonitoring.MonitoringMW) => void;
  className?: string;
};

const ListItem = (props: IProps) => {
  const { className, onDelete, onEdit, ...mw } = props;
  const { t } = useTranslation();
  const { locale } = useLocale();

  const interval = MAINTENANCE_OPTIONS.find(
    (opt) => opt.value === mw.recurringInterval
  );

  const hours = Math.floor(mw.durationMinutes / 60);
  const minutes = mw.durationMinutes - hours * 60;

  console.log();

  return (
    <div className={cn(className)}>
      <Row columnSpacing={2}>
        <Col xs>
          <div className="mb-20 fs-12 steel">{t('table.head.type')}</div>
          <div>{t(interval?.label)}</div>
        </Col>
        <Col xs={3}>
          <div className="mb-20 fs-12 steel">{t('table.head.repeatOn')}</div>
          <div>
            {parseRepeatOnField(mw.startAt, mw.recurringInterval, locale)}
          </div>
        </Col>
        <Col xs={3}>
          <div className="mb-20 fs-12 steel">{t('table.head.nextReboot')}</div>
          <div> {parseNextRebootField(mw.startAt, mw.recurringInterval)}</div>
        </Col>
        <Col xs>
          <div className="mb-20 fs-12 steel">{t('table.head.duration')}</div>
          <div>{dayjs.duration({ minutes, hours }).format('H[h] m[m]')}</div>
        </Col>
        <Col xs={1}>
          <Row
            justifyContent="flex-end"
            alignItems="center"
            className="full-width"
          >
            <Col>
              <PopupState variant="popover">
                {(popupState) => (
                  <>
                    <IconButton
                      size="small"
                      className="mt-20"
                      {...bindTrigger(popupState)}
                    >
                      <MuiIcons.MoreVert className="fs-20 steel pointer" />
                    </IconButton>

                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          onEdit(mw);
                          popupState.close();
                        }}
                      >
                        {t(`common.edit`)}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          onDelete(mw);
                          popupState.close();
                        }}
                      >
                        {t(`common.delete`)}
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default styled(ListItem)(({ theme }) => {
  return {
    boxShadow: theme.shadows[1],
    marginBottom: remCalc(2),
    borderRadius: GLOBAL_BORDER_RADIUS,
    padding: remCalc(15, 40),
  };
});
