import { pricingService } from 'services';
import { ResourceQuota } from 'quotas';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

const INITIAL_QUOTA: ResourceQuota = {
  defaultVmsResources: {
    coreInfraVm: { osDiskSizeGb: 0, ramMb: 0, virtualCpus: 0 },
    rdsGatewayVm: { osDiskSizeGb: 0, ramMb: 0, virtualCpus: 0 },
    rdsHostVm: { osDiskSizeGb: 0, ramMb: 0, virtualCpus: 0 },
  },
  remaining: { diskSizeGb: 0, ramMb: 0, virtualCpus: 0 },
  used: { diskSizeGb: 0, ramMb: 0, virtualCpus: 0 },
};

export const costBreakdown = new BaseMobxInstanceDecorator<
  ResourceQuota,
  false
>({
  instanceName: 'costBreakdown',
  requests: {
    get: pricingService.getCostBreakdown,
  },
  initialState: INITIAL_QUOTA,
});

export type ICostBreakdownHandler = BaseDecorator<ResourceQuota, false>;

export const costEstimation = new BaseMobxInstanceDecorator<
  ICostTypes.CostEstimationResponse,
  false
>({
  instanceName: 'costBreakdown',
  requests: {
    get: pricingService.generateCostBreakdown,
  },
  initialState: {} as ICostTypes.CostEstimationResponse,
});
