import * as React from 'react';

export interface IAuthChildSessionInfo {
  psn?: string; // partner shortName
  tsn?: string; // tenant shortName
}

export type ISetSessionInfoCallback = (
  names: Partial<IAuthChildSessionInfo>,
  useSessionStorage?: boolean
) => void;

interface AuthContextType {
  token: string;
  isOnAuthorizedRoute: boolean;
  isAuthorized: boolean;
  isOnRoute: boolean;
  setToken: (token: string) => void;
  setChildSessionInfo: ISetSessionInfoCallback;
  signOut: () => void;
  childSession: IAuthChildSessionInfo;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth() {
  return React.useContext(AuthContext);
}
