import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { connectivityService } from 'services';
import * as R from 'ramda';
import { numberToCurrency, remCalc, validateCallback, validator } from 'utils';
import {
  Alert,
  Button,
  Col,
  Dialog,
  DialogProps,
  Input,
  PrimaryTextSpan,
  Row,
  Select,
} from 'elements';
import { CostInfoList } from '../services/components/Styled';
import {
  useForceUpdate,
  usePermissions,
  usePrice,
  useStateHandler,
} from 'hooks';
import { CostResourceIds } from 'enums';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import * as HELPERS from './helpers';
import * as ENUMS from './constants';
import InternalNetworkSubnetDropdown from './InternalNetworkSubnetDropdown';
import {
  CreateConnectivityFixedValue,
  CreateConnectivityInput,
  CreateConnectivityIpPoolInput,
} from './Styled';

type Props = DialogProps<IConnectivityTypes.INetworkPayload> & {
  initialValues?: IConnectivityTypes.INetwork;
};

const NetworkDetailsDialog = observer((props: Props) => {
  const { t } = useTranslation();
  const { onSave, open, onClose, initialValues } = props;
  const forceUpdate = useForceUpdate();
  const paidExistingNetworks = React.useRef(0);
  const { isEvaluation } = usePermissions();
  const prices = usePrice([
    CostResourceIds.connectivityBase,
    CostResourceIds.connectivityAdditionalNetwork,
  ]);
  const networks = useStateHandler(StateHandlers.connectivityNetworks);
  const isNew = !initialValues;
  const totalNetCount = paidExistingNetworks.current + (isNew ? 1 : 0); // + 1 in case we are creating new network

  const baseFee = prices[CostResourceIds.connectivityBase].monthly;
  const additionalNewtorksFee =
    prices[CostResourceIds.connectivityAdditionalNetwork].monthly;
  const total = R.sum([baseFee, additionalNewtorksFee * totalNetCount]);

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    setFieldValue,
    isSubmitting,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: HELPERS.parseNetworkForForm(),
    validate: HELPERS.validateNetworkDetailValues,
    validateOnMount: false,
    onSubmit: async (val) => {
      const payload = HELPERS.parseNetworkFormValuesToPayload(val, isNew);
      isNew
        ? await networks.create(payload)
        : await networks.update(initialValues?.id, payload);
      return onSave && onSave(payload);
    },
  });

  const isIsolated = values.type?.value === 'ISOLATED';

  React.useEffect(() => {
    if (open) {
      connectivityService
        .getNetworks({
          perPage: 1000,
          page: 1,
          type: ['NAT_ROUTED', 'ISOLATED'],
          isPrimary: false,
        })
        .then((res) => {
          const hasPrimaryNet = !!res.data.find((el) => el.isPrimary);
          paidExistingNetworks.current =
            (res.pagination?.totalCount || 0) - (hasPrimaryNet ? 1 : 0);
          forceUpdate();
          return res;
        });
    }
    if (open && initialValues) {
      setValues(HELPERS.parseNetworkForForm(initialValues));
    } else {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      title={t(
        `services.connectivity.networks.dialog.details.${
          isNew ? 'add' : 'edit'
        }.title`
      )}
      handleSubmit={handleSubmit}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      keepMounted={false}
      actions={
        <Row alignItems="center" justifyContent="space-between">
          <Col>
            <Button color="default" variant="outlined" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button disabled={isSubmitting} type="submit">
              {t('common.deploy')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Row justifyContent="space-between" columnSpacing={2}>
        <Col xs={7}>
          <div className="steel fs-14 mb-30">
            {t(
              `services.connectivity.networks.dialog.details.${
                isNew ? 'add' : 'edit'
              }.content`
            )}
          </div>
          <Row columnSpacing={3} className="mb-25">
            <Col>
              <Input
                name="name"
                onChange={handleChange}
                value={values.name}
                label="forms.name"
                placeholder="forms.placeholders.name"
                error={!!errors.name}
                helperText={errors.name}
                helperTextOptions={{ max: 30 }}
                style={{ width: 200 }}
              />
            </Col>
            <Col>
              <div style={{ width: 200 }}>
                <Select
                  name="type"
                  value={values.type}
                  onChange={(el) => setFieldValue('type', el)}
                  label="forms.type"
                  error={!!errors.type}
                  helperText={errors.type}
                  options={ENUMS.NETWORK_TYPES_OPTIONS}
                  isDisabled={!isNew}
                />
              </div>
            </Col>
          </Row>
          <Row columnSpacing={3} className="mb-25">
            <Col>
              <CreateConnectivityInput
                className={cn('steel')}
                name="networkAddress"
                label="services.dialog.connectivity.network"
                value={values.networkAddress}
                onChange={validateCallback({
                  max: 11,
                  restrict: validator.ipSymbols,
                })(handleChange)}
                placeholder="000.000.000"
                disabled={!isNew || networks.isRequesting}
                error={!!errors.networkAddress}
                helperText={errors.networkAddress}
                endAdornment={
                  <InternalNetworkSubnetDropdown
                    value={values.intNetSubnet}
                    onChange={(val) =>
                      isNew && setFieldValue('intNetSubnet', val)
                    }
                  />
                }
              />
            </Col>
            {!isIsolated && (
              <Col>
                <CreateConnectivityIpPoolInput
                  className={cn('steel')}
                  label="services.dialog.connectivity.ipPool"
                  name="ipPoolRange"
                  value={values.ipPoolRange}
                  onChange={validateCallback({
                    max: 7,
                    restrict: validator.numRange,
                  })(handleChange)}
                  disabled={networks.isRequesting}
                  error={!!errors.ipPoolRange}
                  helperText={errors.ipPoolRange}
                  placeholder="00-00"
                  startAdornment={
                    <CreateConnectivityFixedValue
                      className={cn('pl-10')}
                      position="start"
                    >
                      {values.networkAddress || '000.000.00'}.
                    </CreateConnectivityFixedValue>
                  }
                />
              </Col>
            )}
          </Row>
          {isIsolated && (
            <Alert severity="info" style={{ maxWidth: remCalc(425) }}>
              <span className="fs-12">
                {t(
                  'services.connectivity.networks.dialog.details.alert.254Reserved'
                )}
              </span>
            </Alert>
          )}
        </Col>
        <Col xs={5}>
          <Row
            direction="column"
            justifyContent="space-between"
            columnSpacing={2}
            style={{ minHeight: remCalc(350) }}
          >
            <Col className="full-width">
              <Row
                justifyContent="space-between"
                columnSpacing={2}
                className="fs-10 steel uppercase mb-15"
              >
                <Col xs={7}>
                  <span>{t('costsInfo.head.serviceName')}</span>
                </Col>
                <Col xs={2} className="text-right">
                  <span>{t('costsInfo.head.quantity')}</span>
                </Col>
                <Col xs={3} className="text-right">
                  <span>{t('costsInfo.head.price')}</span>
                </Col>
              </Row>
              <h5 className="mb-15">{t('costsInfo.connectivity')}</h5>
              <CostInfoList className="custom-list">
                <ul>
                  <li className="mb-10">
                    <Row>
                      <Col xs={7}>
                        <span>{t('costsInfo.baseFee')}</span>
                      </Col>
                      <Col xs={2} className="text-right steel">
                        <span>{1}</span>
                      </Col>
                      <Col xs={3} className="text-right steel">
                        <span>{numberToCurrency(baseFee, false)}</span>
                      </Col>
                    </Row>
                  </li>
                  <li className="mb-10">
                    <Row>
                      <Col xs={7}>
                        <span>{t('costsInfo.additionalNetwork')}</span>
                      </Col>
                      <Col xs={2} className="text-right steel">
                        <span>{totalNetCount}</span>
                      </Col>
                      <Col xs={3} className="text-right steel">
                        <span>
                          {numberToCurrency(additionalNewtorksFee, false)}
                        </span>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </CostInfoList>
            </Col>
            <Col className="full-width">
              <div className="flex justify-between align-center mb-5">
                <h5 className="fs-17">{t('costsInfo.totalMonthly')}</h5>
                <PrimaryTextSpan className="fs-20 bold">
                  {numberToCurrency(total, false)}
                </PrimaryTextSpan>
              </div>
              <div className="fs-12 steel mb-10">
                {t('costsInfo.chfExclVat')}
              </div>
              {isEvaluation && (
                <Alert className="fs-12" severity="info">
                  {t('costsInfo.evalAccount.notify')}
                </Alert>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
});

export default NetworkDetailsDialog;
