import * as Yup from 'yup';
import { PALETTE } from 'enums';
import { validator } from 'utils';

export const validationSchema = Yup.object({
  supportInfo: Yup.string()
    .required('forms.required')
    .test('supportInfo', 'forms.invalid.url', validator.isURL as any),
  primaryColor: Yup.string().test(
    'primaryColor',
    'forms.invalid.hex',
    validator.isHex as any
  ),
  secondaryColor: Yup.string().test(
    'secondaryColor',
    'forms.invalid.hex',
    validator.isHex as any
  ),
});

export const DEFAULT_THEME_COLORS = {
  primary: PALETTE.primary,
  appBar: PALETTE.dark,
};
