import * as R from 'ramda';
import { dayjs } from 'utils';
import * as Yup from 'yup';

export const INITIAL_STATE: IRebootDialogTypes.IState = {
  openDialog: false,
  current: undefined,
  operationName: undefined,
};

export const MINUTE_STEP = 15;

export const REBOOT_INTERVAL_OPTIONS: ValueType<IMaintenanceRecurringInterval>[] =
  [
    {
      value: 'SINGLE',
      label: 'services.rds.reboot.options.recurring.single',
    },
    {
      value: 'DAY',
      label: 'services.rds.reboot.options.recurring.daily',
    },
    {
      value: 'WEEK',
      label: 'services.rds.reboot.options.recurring.weekly',
    },
    {
      value: 'MONTH',
      label: 'services.rds.reboot.options.recurring.monthly',
    },
  ];

export const buildInitialState = (
  mw?: IMaintenanceWindow,
  initialType?: 'now' | 'plan'
): IRebootDialogTypes.Values => {
  const startDate = mw?.startAt
    ? dayjs(mw?.startAt)
    : dayjs().add(1, 'days').set('hours', 4).set('minutes', 0);

  const recurringInterval =
    R.find(R.propEq('value', mw?.recurringInterval), REBOOT_INTERVAL_OPTIONS) ||
    REBOOT_INTERVAL_OPTIONS[0];

  return {
    configureType: mw?.isForced ? 'now' : initialType || 'plan',
    configureText: '',
    recurringInterval,
    startDate,
    notificationEnabled: !!mw?.notificationEnabled,
  };
};

export const startDateValidationShecma = Yup.date().when('configureType', {
  is: (val: string) => val === 'plan',
  then: Yup.date()
    .test({
      message: 'forms.invalid.date',
      test(_startDate) {
        const maximumDate = dayjs().add(6, 'days').endOf('day');
        const minimumDate = dayjs();

        const startDate = dayjs(_startDate);

        const validDate =
          minimumDate.diff(startDate, 'days') > 0 ||
          maximumDate.diff(startDate, 'day') >= 0;

        return validDate;
      },
    })
    .test({
      message: 'forms.invalid.date.isEarlierThanNow',
      test(startDate) {
        const finalDate = dayjs(startDate);
        const validDate = !(+dayjs().add(15, 'minutes') > +finalDate);

        return validDate;
      },
    }),
});

export const rebootValidationSchema = Yup.object({
  configureType: Yup.string(),
  configureText: Yup.string().when('configureType', {
    is: (val: string) => val === 'now',
    then: Yup.string().test(
      'configureText',
      'forms.invalid',
      (val) => val === 'Reboot'
    ),
  }),
  startDate: startDateValidationShecma,
});
