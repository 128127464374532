import { buildListParams, GET } from 'utils';
import { AccessLevelLow, ICommonListParams } from 'global-shapes';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';
import { IUsageStatisticsByUser, IUsageRow } from 'usage';

const fieldsToPeak = ['periodStart', 'periodEnd', 'orderBy', 'orderType'];

export const getUsage = (
  accessLevel: AccessLevelLow,
  params: ICommonListParams
): IAxiosPromise<IUsageRow[]> => {
  switch (accessLevel) {
    case 'provider':
      return GET('/usage/partners', buildListParams(params, fieldsToPeak));
    case 'partner':
      return GET('/usage/tenants', buildListParams(params, fieldsToPeak));
    default:
      return GET('/usage/tenants', buildListParams(params, fieldsToPeak));
  }
};

export const getUsageById = (
  params: ICommonListParams & { accessLevel: AccessLevelLow; id: number }
): AxiosPromise<IUsageRow> => {
  switch (params.accessLevel) {
    case 'provider':
      return GET(
        `/usage/partners/${params.id}`,
        buildListParams(params, fieldsToPeak)
      );
    case 'partner':
      return GET(
        `/usage/tenants/${params.id}`,
        buildListParams(params, fieldsToPeak)
      );
    default:
      return GET(
        `/usage/tenants/${params.id}`,
        buildListParams(params, fieldsToPeak)
      );
  }
};

interface IStatsParams {
  accessLevel: AccessLevelLow;
  periodStart: string;
  periodEnd: string;
}

export const getStatistics = async (
  params: IStatsParams
): Promise<IUsageStatisticsByUser> => {
  const { accessLevel, periodStart, periodEnd } = params;
  const periodParams = { periodStart, periodEnd };
  const res = { tenants: [], partners: [] };

  if (accessLevel === 'provider') {
    const partners = await GET(`/partners/statistic`, periodParams);

    res.partners = partners.data;
  } else {
    const tenants = await GET(`/tenants/statistic`, periodParams);

    res.tenants = tenants.data;
  }

  return res;
};
