import React from 'react';
import cn from 'classnames';
import { TestSuffixes } from 'enums';
import { remCalc } from 'utils';
import { styled } from './utilities';

const LoaderRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: remCalc(15, 0),
  width: '100%',
}));

const LoaderSpinner = styled('div')(({ theme }) => ({
  borderTop: `3px solid ${theme.palette.primary.main}`,
  borderRight: '3px solid transparent',
  borderRadius: '50%',
  animation: 'spin .8s linear infinite',
}));

interface ILoaderProps {
  testId?: string;
  className?: string;
  size?: number;
}

const defaultProps = {
  size: 40,
};

export function Loader<P extends ILoaderProps>(_props: P) {
  const props = { ...defaultProps, ..._props };
  const { testId, className } = props;

  return (
    <LoaderRoot
      className={cn(className)}
      data-test-id={cn({ [`${testId}-${TestSuffixes.loader}`]: testId })}
    >
      <LoaderSpinner
        style={{ width: remCalc(props.size), height: remCalc(props.size) }}
      />
    </LoaderRoot>
  );
}

export function DotsProgress<P extends ILoaderProps>(props: P) {
  const { className, testId } = props;
  return (
    <div
      data-title=".jbbf-dots-progress"
      className={cn('jbbf-dots-wrapper', className)}
      data-test-id={cn({ [`${testId}-${TestSuffixes.dotsLoader}`]: testId })}
    >
      <div className={cn('jbbf-dots-progress')} />
    </div>
  );
}

export const DotsProgressSmall = (props: ILoaderProps) => {
  return (
    <div className={cn('jbbf-dots-progress-small', props.className)}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );
};

export const BackgroundRequester = styled(
  (
    props: React.PropsWithChildren<{
      className?: string;
      isRequesting: boolean;
    }>
  ) => {
    if (props.isRequesting) {
      return (
        <div className={cn(props.className)}>
          {props.isRequesting && <Loader className="_in-loader fade-in" />}
          <div
            className={cn('_requester_content', {
              disabled: props.isRequesting,
            })}
          >
            {props.children}
          </div>
        </div>
      );
    }
    return props.children;
  }
)(({ theme }) => ({
  position: 'relative',
  minHeight: '100%',
  '& ._requester_content': {
    position: 'relative',
    zIndex: 0,
  },
  '& ._in-loader': {
    height: '100%',
    zIndex: 1,
    position: 'absolute',
    left: 0,
    top: 0,
  },
}));
