import * as React from 'react';
import { IGlobalUser, UserFormValues } from 'users-shapes';
import { Permission } from 'auth-shapes';
import dayjs from 'dayjs';
import { AnyShape } from 'global-shapes';
import cn from 'classnames';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { colorMap, textMap } from './constants';
import { createColumnHelper, Menu, MenuItem, MuiIcons, Row } from 'elements';
import { confirm, parseToOptions, showSystemMessage } from 'utils';
import { authService, rolesService } from 'services';
import { LANG_MAP } from 'enums';
import { ActionBurgerIcon } from './Styled';

export const parseUserValuesForForm = (
  values: IGlobalUser | null
): UserFormValues | null => {
  if (values) {
    return {
      ...values,
      role: { value: values.role.id, label: values.role.name },
      lang: LANG_MAP[values.lang],
    };
  }
  return values;
};

export const onRolesLoad = (name: string) =>
  rolesService.getRoles({ name }).then(parseToOptions());

type DeployStatus = 'active' | 'pending' | 'blocked';

const column = createColumnHelper<IGlobalUser>();

export const getColumns = (props: AnyShape) => {
  const {
    t,
    permissions,
    handleStateChange,
    handleOpenDetails,
    currentUserId,
    requestDeleteUser,
    requestUnblockUser,
    requestBlockUser,
    isCurrentTenant,
    onUpdate2fa,
  } = props;

  const usersPermissions: Permission = permissions;

  return [
    column.accessor('firstName', {
      header: t('table.head.firstName'),
      cell: ({ row }: ICell<IGlobalUser>) => <>{row.original.firstName}</>,
    }),
    column.accessor('lastName', {
      header: t('table.head.lastName'),
      cell: ({ row }: ICell<IGlobalUser>) => <>{row.original.lastName}</>,
    }),
    column.accessor('email', {
      header: t('table.head.email'),
      cell: ({ row }: ICell<IGlobalUser>) => <>{row.original.email}</>,
    }),
    column.accessor('role', {
      header: t('table.head.role'),
      cell: ({ row }: ICell<IGlobalUser>) => (
        <>{row.original.role?.name || '-'}</>
      ),
    }),

    column.accessor('isBlocked', {
      header: t('table.head.status'),
      cell: ({ row }: ICell<IGlobalUser>) => {
        let status: DeployStatus = 'active';
        if (!row.original.isVerified) status = 'pending';
        if (row.original.isBlocked) status = 'blocked';
        return (
          // @ts-ignore
          <span style={{ color: colorMap[status] }}>
            {t(`common.${textMap[status]}`) as string}
          </span>
        );
      },
    }),

    column.accessor('createdAt', {
      header: t('table.head.creationDate'),
      cell: ({ row }: ICell<IGlobalUser>) => (
        <>
          {row.original.createdAt
            ? dayjs(row.original.createdAt).format('DD.MM.YYYY')
            : '-'}
        </>
      ),
    }),

    column.accessor('lastActivityAt', {
      header: t('table.head.lastActivity'),
      cell: ({ row }: ICell<IGlobalUser>) => (
        <>
          {row.original.lastActivityAt
            ? dayjs(row.original.lastActivityAt).format('DD.MM.YYYY')
            : '-'}
        </>
      ),
    }),

    column.accessor('updatedAt', {
      header: '',
      cell: ({ row }: ICell<IGlobalUser>) => {
        const { isBlocked, isVerified, is2faEnabled, firstName, lastName } =
          row.original;

        const name = [firstName, lastName].join(' ');

        return (
          <Row justifyContent="flex-end">
            {usersPermissions.canManage && (
              <MuiIcons.Create
                className="fs-22 pointer steel ml-15"
                onClick={() => {
                  handleStateChange(parseUserValuesForForm(row.original));
                  handleOpenDetails(true);
                }}
              />
            )}

            {usersPermissions.canManage && (
              <MuiIcons.Delete
                className={cn('fs-22 pointer steel ml-15', {
                  disabled: currentUserId === row.original.id,
                })}
                onClick={() =>
                  confirm({
                    title: 'users.confirm.delete.title',
                    content: (
                      <div>
                        {row.original.firstName} {row.original.lastName} (
                        {row.original.email})
                        <br />
                        <br />
                        {t('users.confirm.delete.message')}
                      </div>
                    ),
                    onSuccess: () => requestDeleteUser(row.original.id),
                    onCancel: () => undefined,
                  })
                }
              />
            )}

            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <>
                  <ActionBurgerIcon {...bindTrigger(popupState)} />
                  <Menu {...bindMenu(popupState)}>
                    {isVerified && (
                      <div>
                        {usersPermissions.canManage && (
                          <MenuItem
                            onClick={() => {
                              const action = isBlocked
                                ? requestUnblockUser
                                : requestBlockUser;

                              return confirm({
                                title: t(
                                  `users.confirm.${
                                    isBlocked ? 'unblock' : 'block'
                                  }.title`
                                ),
                                content: t(
                                  `users.confirm.${
                                    isBlocked ? 'unblock' : 'block'
                                  }.content`,
                                  {
                                    value: row.original.email,
                                  }
                                ),
                                onSuccess: () => action(row.original.id),
                                onCancel: () => undefined,
                              });
                            }}
                            disabled={currentUserId === row.original.id}
                          >
                            {t(`common.${isBlocked ? 'unblock' : 'block'}`)}
                          </MenuItem>
                        )}

                        <MenuItem
                          onClick={() =>
                            authService
                              .restorePassword(row.original.email)
                              .then(() => {
                                showSystemMessage(
                                  'users.confirm.restorePassword.success',
                                  'success'
                                );
                                return popupState.close();
                              })
                              .catch((err) =>
                                showSystemMessage(err.message, 'error')
                              )
                          }
                        >
                          {t('users.table.actions.restorePassword')}
                        </MenuItem>
                        {usersPermissions.canManage && (
                          <MenuItem
                            onClick={() =>
                              confirm({
                                title: t(
                                  'users.table.actions.confirm.restore2fa.title'
                                ),
                                content: t(
                                  'users.table.actions.confirm.restore2fa.content'
                                ),
                                onSuccess: () =>
                                  authService
                                    .restore2fa(row.original.email)
                                    .then(() => popupState.close()),
                                onCancel: popupState.close,
                              })
                            }
                          >
                            {t('users.table.actions.restore2fa')}
                          </MenuItem>
                        )}
                      </div>
                    )}

                    {isCurrentTenant && (
                      <MenuItem
                        onClick={() =>
                          confirm({
                            title: t(
                              `users.confirm.2fa.${
                                is2faEnabled ? 'disable' : 'enable'
                              }.title`,
                              { name }
                            ),
                            content: `users.confirm.2fa.${
                              is2faEnabled ? 'disable' : 'enable'
                            }.content`,
                            onSuccess: () => {
                              onUpdate2fa(row.original.id, {
                                ...parseUserValuesForForm(row.original),
                                is2faEnabled: !is2faEnabled,
                              });
                              popupState.close();
                            },
                            onCancel: popupState.close,
                          })
                        }
                      >
                        {t(
                          `users.table.actions.2fa.${
                            is2faEnabled ? 'disable' : 'enable'
                          }`
                        )}
                      </MenuItem>
                    )}

                    {!isVerified && (
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          return authService
                            .resendVerificationLink(row.original.email)
                            .then(() =>
                              confirm({
                                title: 'users.confirm.verifyEmail.title',
                                content: t('users.confirm.verifyEmail.message'),
                                onCancel: () => undefined,
                                cancelLabel: 'common.ok',
                              })
                            )
                            .catch((err) =>
                              showSystemMessage(err.message, 'error')
                            );
                        }}
                      >
                        {t('users.table.actions.resendVerifyLink')}
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </PopupState>
          </Row>
        );
      },
    }),
  ];
};
