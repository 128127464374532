import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { without } from 'ramda';
import LeftBarNavigation from 'components/LeftBarNavigation';
import { Row, Col } from 'elements';
import { usePermissions, useAccount } from 'hooks';
import { observer } from 'mobx-react-lite';
import { AccessLevelLow } from 'global-shapes';

import { NAV_PARAMS } from './constants';

const NAV_KEYS = {
  provider: [
    'base-pricing',
    'app-store',
    'blacklist',
    'version',
    'badges',
    'reports',
  ],
  partner: [
    'partner-info',
    'appearance',
    'billing-emails',
    'customer-invoice',
    'pricing',
    'badges',
  ],
  tenant: ['company-info', 'billing-emails', 'my-pricing'],
};

const PRICING_NAMES = {
  provider: 'base-pricing',
  partner: 'pricing',
  tenant: 'my-pricing',
};

const Settings = observer((props: { level: AccessLevelLow }) => {
  const { permissionsAll, isEvaluation } = usePermissions();
  const [account] = useAccount();
  const hasPricingPermission = !!permissionsAll['PRICING'];
  const hasAppStorePermission = !!permissionsAll['APP_STORE'];
  const hasServicesPermission = !!permissionsAll['SERVICES'];
  const showCustomerBilling = !!permissionsAll['CUSTOMER_BILLING']?.canView;

  const billingEmailsEnabled =
    !isEvaluation &&
    (!!account?.partner?.billingEmailsEnabled ||
      !!account?.tenant?.billingEmailsEnabled);

  const showPricing =
    hasPricingPermission && !!permissionsAll['PRICING']?.canView;
  const showAppStore =
    hasAppStorePermission && !!permissionsAll['APP_STORE']?.canView;
  const showReports =
    hasServicesPermission && !!permissionsAll['SERVICES']?.canView;

  const prohibitedPages = useMemo(() => {
    const items = [];

    if (!showPricing) items.push(PRICING_NAMES[props.level]);

    if (!showAppStore) items.push('app-store');
    if (!showCustomerBilling || isEvaluation) items.push('customer-invoice');
    if (!billingEmailsEnabled) items.push('billing-emails');
    if (!showReports) items.push('reports');

    return items;
  }, [
    showPricing,
    showAppStore,
    showReports,
    isEvaluation,
    billingEmailsEnabled,
    showCustomerBilling,
  ]);

  return (
    <Row columnSpacing={2}>
      <Col xs={12} sm={3}>
        <LeftBarNavigation
          items={without(prohibitedPages, NAV_KEYS[props.level])}
          params={NAV_PARAMS}
          baseUrl="settings"
          tHash="settings"
        />
      </Col>
      <Col xs={12} sm={9}>
        <Outlet />
      </Col>
    </Row>
  );
});

export default Settings;
