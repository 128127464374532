import * as React from 'react';
import * as Translations from 'i18next';
import cn from 'classnames';
import { styled } from 'elements';
import { withTranslation } from 'react-i18next';
import { PALETTE } from 'enums';
import { remCalc } from 'utils';

type IProps = Translations.WithT &
  React.PropsWithChildren<{
    translationHash: string;
    checked: boolean;
    onClick: () => void;
    parameter: string;
  }>;

const Item = styled('div')(({ theme }) => {
  return {
    lineHeight: 1,
    marginBottom: remCalc(10),
  };
});

const SCheckbox = styled(
  ({ isActive, ...rest }: any & { isActive: boolean }) => <div {...rest} />
)(({ isActive, theme }) => {
  return {
    border: `1px solid ${
      isActive ? theme.palette.primary.main : PALETTE.lightGrey
    }`,
    width: remCalc(9),
    height: remCalc(9),
    backgroundColor: isActive ? theme.palette.primary.main : undefined,
    marginRight: remCalc(5),
  };
});

const Renderer = (props: IProps) => {
  const { t, parameter, checked, onClick, translationHash } = props;

  return (
    <Item className={cn('flex align-center pointer')} onClick={onClick}>
      <SCheckbox isActive={checked} />
      <div>{t(`${translationHash}.${parameter}`) as string}</div>
    </Item>
  );
};

const EntityFilterItem = withTranslation()(Renderer);

export default EntityFilterItem;
