import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ResetPasswordValues } from 'auth-shapes';
import { useQuery } from 'hooks';
import { Input, Button, Tooltip, Alert, MuiIcons } from 'elements';
import { authService } from 'services';
import { setSessionsData, insertXCustomLang } from 'utils';
import * as StateHandlers from 'states';
import { REGEX, ALL_TEST_IDS } from 'enums';
import logoSrc from 'assets/images/login-logo.svg';
import { AnyShape } from 'global-shapes';
import {
  CreatePassPage,
  CreatePassPaper,
  CreatePassInfoLabel,
  CreatePassLogo,
} from './Styled';

const validationSchema = Yup.object({
  password: Yup.string()
    .trim()
    .required('Password is required')
    .matches(REGEX.password, 'Invalid format'),
  confirmPassword: Yup.string()
    .trim()
    .when('password', {
      is: (val: any) => val && val.length > 0,
      then: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm password is required'),
    }),
});

const initialValues: ResetPasswordValues = {
  password: '',
  confirmPassword: '',
};

const passRulesContent = (
  <div>
    <div>Password format:</div>
    <br />
    <div>- at least 8 characters</div>
    <div>- at least 1 capital letter</div>
    <div>- at least 1 small letter</div>
    <div>- at least 1 special character</div>
    <div>- at least 1 number</div>
  </div>
);

const ResetPassword = () => {
  const { query, queryStr, changeQuery } = useQuery();
  const { t } = useTranslation();

  const getMe = React.useCallback(() => {
    StateHandlers.currentUser.get(query);
  }, [queryStr]);

  const [error, handleError] = React.useState('');
  const { values, errors, handleChange, touched, handleSubmit, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (val: ResetPasswordValues) => {
        handleError('');
        return authService
          .verifyAccountPassword(
            val.password,
            query.token,
            insertXCustomLang(query.lang)
          )
          .then((res) => {
            localStorage.clear();
            sessionStorage.clear();

            const options: AnyShape = {
              url: '/verify-code',
              params: { twoFactorAuthToken: res.data.twoFactorAuthToken },
            };

            if (query.is2fa === 'false' || !res.data.twoFactorAuthToken) {
              options.url = '/';
              options.params = {};
            }

            if (res.data.user.registrationStep === 'EXPECT_2FA') {
              options.url = '/2fa-setup';
              options.params = {
                twoFactorAuthToken: res.data.twoFactorAuthToken,
              };
              changeQuery(options.params, options.url, true);
              return;
            }

            if (!res.data.twoFactorAuthToken && res.data.session) {
              setSessionsData(res.data.session);
              getMe();
            }

            changeQuery(options.params, options.url, true);
            return;
          })
          .catch((err) => handleError(err.message));
      },
    });

  return (
    <CreatePassPage data-test-id={ALL_TEST_IDS.resetPassword.wrapper}>
      <CreatePassPaper>
        <h2 className="mb-50 text-center">{t('auth.password.title')}</h2>
        {error && (
          <Alert severity="error" className="mb-15">
            {error}
          </Alert>
        )}
        <form
          onSubmit={handleSubmit}
          data-test-id={ALL_TEST_IDS.resetPassword.form}
        >
          <Input
            id="password"
            name="password"
            type="password"
            touched={touched}
            label={
              <CreatePassInfoLabel>
                {t('forms.newPassword')}{' '}
                <Tooltip title={passRulesContent} placement="top" arrow>
                  <MuiIcons.HelpOutline />
                </Tooltip>
              </CreatePassInfoLabel>
            }
            placeholder="Your password"
            error={!!errors.password}
            helperText={errors.password}
            className="mb-25"
            onChange={handleChange}
            value={values.password}
            testId={ALL_TEST_IDS.resetPassword.inputs}
          />
          <Input
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm new password"
            placeholder="Your password"
            type="password"
            touched={touched}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            className="mb-25"
            onChange={handleChange}
            value={values.confirmPassword}
            testId={ALL_TEST_IDS.resetPassword.inputs}
          />
          <Button
            type="submit"
            fullWidth
            size="large"
            className="mt-50"
            disabled={isSubmitting}
            testId={ALL_TEST_IDS.resetPassword.submit}
          >
            {t('common.continue')}
          </Button>
        </form>
      </CreatePassPaper>
      <CreatePassLogo>
        <img src={logoSrc} alt="" />
      </CreatePassLogo>
    </CreatePassPage>
  );
};

export default ResetPassword;
