export function useLocalPagination<D = any>({
  data,
  query,
}: {
  data: D[];
  query: { perPage: number; page: number };
}): D[] {
  return data.slice(
    (query.page - 1) * query.perPage,
    (query.page - 1) * query.perPage + query.perPage
  );
}
