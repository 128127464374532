import { GET, PUT, fakeEmptyFetch } from 'utils';
import { AxiosPromise } from 'axios';
import { IAppVersion, IAppVersionPayload } from 'app-settings';
import { IAxiosPromise } from 'axios-shapes';

export const getSettings = (): IAxiosPromise<IAppVersion> =>
  GET('/app-settings');

export const update = (
  payload: IAppVersionPayload
): AxiosPromise<IAppVersion> => {
  return PUT(`/app-settings`, {}, payload);
};

export const create = () => fakeEmptyFetch({}, 1500)
export const deleteSettings = () => fakeEmptyFetch({}, 1500)
