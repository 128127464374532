import { defaultVmsQueryFilters } from 'enums';
import { upgradeByteMeasure, downgradeByteMeasure } from 'utils';
import * as StateHandlers from 'states';
import * as R from 'ramda';
import * as Types from './types';

export function mapTabIndex(ind: number): IVmTypes.IVMNetworkType | undefined {
  if (ind === 1) {
    return 'PRIVATE';
  }
  if (ind === 2) {
    return 'PUBLIC';
  }

  if (ind === 3) {
    return 'DEDICATED';
  }
  return undefined;
}

export function mapTabTypeToIndex(
  type: IVmTypes.IVMNetworkType | undefined
): number {
  if (type === 'PRIVATE') {
    return 1;
  }
  if (type === 'PUBLIC') {
    return 2;
  }
  if (type === 'DEDICATED') {
    return 3;
  }
  return 0;
}

export function pickFilters(
  query: IVmTypes.VMParams | IVmTypes.VmFiltersParams
): IVmTypes.VmFiltersParams {
  return R.pick(
    [
      'status',
      'billingType',
      'badgeId',
      'osId',
      'cpuMin',
      'cpuMax',
      'ramMin',
      'ramMax',
      'totalDiskSizeMin',
      'totalDiskSizeMax',
      'type',
    ],
    query
  );
}

export function parseInitilaFiltersValues(
  q: IVmTypes.VmFiltersParams
): Types.VmFormFilterParams {
  const {
    cpuMin,
    cpuMax,
    ramMin,
    ramMax,
    totalDiskSizeMin,
    totalDiskSizeMax,
    status,
    billingType,
    badgeId,
    osId,
    activeService,
    type,
  } = pickFilters(q);

  const _cpuMax = cpuMax ? +cpuMax : 64;
  const _ramMax = downgradeByteMeasure(ramMax ? +ramMax : 512);
  const _totalDiskSizeMax = totalDiskSizeMax ? +totalDiskSizeMax : 10000;

  const newState: Types.VmFormFilterParams = {
    status: status || [],
    billingType: billingType || [],
    badgeId: badgeId || [],
    activeService: activeService || [],
    osId: osId || [],
    cpu: [cpuMin || 1, _cpuMax],
    ram: [downgradeByteMeasure(ramMin || 1), _ramMax],
    storage: [totalDiskSizeMin || 1, _totalDiskSizeMax],
    type: type || undefined,
  };

  return newState;
}

export function parseFiltersValuesToQuery(
  q: Types.VmFormFilterParams
): IVmTypes.VmFiltersParams {
  const { cpu, ram, storage, ...filters } = q;

  const query: IVmTypes.VmFiltersParams = {
    ...filters,
    cpuMin: cpu[0],
    cpuMax: cpu[1],
    ramMin: upgradeByteMeasure(ram[0]),
    ramMax: upgradeByteMeasure(ram[1]),
    totalDiskSizeMin: storage[0],
    totalDiskSizeMax: storage[1],
  };

  return query;
}

export function defineAppliedFilters(
  aq: IVmTypes.VmFiltersParams
): Partial<Types.AppliedFilters> {
  const applied: Partial<Types.AppliedFilters> = {};
  const df = defaultVmsQueryFilters;
  const q = {
    ...aq,
    cpuMin: +(aq.cpuMin || 0),
    cpuMax: +(aq.cpuMax || 0),
    ramMin: +(aq.ramMin || 0),
    ramMax: +(aq.ramMax || 0),
    totalDiskSizeMin: +(aq.totalDiskSizeMin || 0),
    totalDiskSizeMax: +(aq.totalDiskSizeMax || 0),
  };

  if (
    !R.equals(q.billingType, df.billingType) &&
    !R.isEmpty(q.billingType || [])
  ) {
    applied.billingType = q.billingType;
  }

  if (!R.equals(q.status, df.status) && !R.isEmpty(q.status || [])) {
    applied.status = q.status;
  }

  if (
    !R.equals(q.activeService, df.activeService) &&
    !R.isEmpty(q.activeService || [])
  ) {
    applied.activeService = q.activeService;
  }

  if (!R.equals(q.osId, df.osId) && !R.isEmpty(q.osId || [])) {
    applied.osId = q.osId;
  }

  if (!R.equals(q.badgeId, df.badgeId)) {
    const badgeId = StateHandlers.badges.data.filter((b) =>
      q.badgeId?.includes(b.id)
    );
    if (!R.isEmpty(badgeId)) {
      applied.badgeId = badgeId;
    }
  }

  if (!R.equals(+q.cpuMin, df.cpuMin) || !R.equals(q.cpuMax, df.cpuMax)) {
    applied.cpu = [q.cpuMin as number, q.cpuMax as number];
  }

  if (!R.equals(q.ramMin, df.ramMin) || !R.equals(q.ramMax, df.ramMax)) {
    applied.ram = [q.ramMin as number, q.ramMax as number];
  }

  if (
    !R.equals(q.totalDiskSizeMin, df.totalDiskSizeMin) ||
    !R.equals(q.totalDiskSizeMax, df.totalDiskSizeMax)
  ) {
    applied.storage = [
      q.totalDiskSizeMin as number,
      q.totalDiskSizeMax as number,
    ];
  }

  return applied;
}
