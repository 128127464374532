import * as React from 'react';
import { compact, Emitter } from 'utils';
import { ActionOption, IActionHandlers } from 'components/ActionButtons/types';
import { useSnapshotRequest, useState, useStateHandler } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { BILLING_CIRCLE_OPTIONS, CustomEvents } from 'enums';
import { IExtendedActualTask } from 'task-manager-service';
import VmDetailsDialog from 'pages/vm-details/dialogs/VmDetailsDialog';
import CloneVmDialog from 'pages/vm-details/dialogs/CloneVmDialog';
import MediaDialog from 'pages/vm-details/dialogs/MediaDialog';
import AttachNetworkDialog from '../../AttachNetwork/MainDialog';
import ManageExternalIpDialog from '../../AttachNetwork/ManageExternalIpDialog';
import SnapshotDialog from '../../SnapshotDialog';
import RemoteSupportDialog from '../../RemoteSupport/RemoteSupportDialog';
import { ValueType } from 'global-shapes';
import * as R from 'ramda';

export type InjectedProps = {
  handleDetailsOpen: (open: boolean) => void;
  isRequesting: boolean;
  isInFolder: boolean;
  fetchVm: any;
  fetchBillingInfo: any;
  onEdit: any;
  onDelete: any;
  task: IExtendedActualTask;
  menuItems?: ActionOption[];
  actionItems?: ActionOption[];
  handlers: IActionHandlers;
};

type IState = {
  initialValues: null | IVmTypes.Vm;
  prices: Record<string, any>;
  detailsDialogOpen: boolean;
  cloneDialogOpen: boolean;
  mediaDialogOpen: boolean;
  attachDialogOpen: boolean;
  manageExternalIpDialogOpen: boolean;
  manageIpDialogOpen: boolean | IVmTypes.Nic;
  snapshotDialogOpen: boolean;
  rsConfig?: null | Record<string, any>;
  selectedNetwork?: IConnectivityTypes.INetwork;
  billingCycleInterval: ValueType<IVmTypes.IBillingCircleInterval>;
  currentVm?: IVmTypes.Vm;
  vmId: number;
  isDetailsPage: boolean;
};

const initial: IState = {
  initialValues: null,
  prices: {},
  detailsDialogOpen: false,
  cloneDialogOpen: false,
  snapshotDialogOpen: false,
  mediaDialogOpen: false,
  manageIpDialogOpen: false,
  attachDialogOpen: false,
  manageExternalIpDialogOpen: false,
  rsConfig: null,
  selectedNetwork: undefined,
  billingCycleInterval: BILLING_CIRCLE_OPTIONS[0],
  currentVm: undefined,
  vmId: 0,
  isDetailsPage: false,
};

export default observer((props: any) => {
  const vm = useStateHandler(StateHandlers.vmDetails);
  const vms = useStateHandler(StateHandlers.vms);
  const dnd = useStateHandler(StateHandlers.dnd);
  const nics = useStateHandler(StateHandlers.vmNics);
  const primaryNic = nics.data.find(
    (n) => n.isPrimary && n.isPublic && n.network?.type === 'DIRECT'
  );
  const additionalPrimaryNic = nics.data.find(
    (n) => !n.isPrimary && n.isPublic && n.network?.type === 'DIRECT'
  );
  const primaryNetworkId = primaryNic?.network?.id;
  const assignedNetworks = compact(
    R.uniq(nics.data.map((el) => el.network?.id))
  );

  const [state, handleState] = useState<IState>(initial);

  const { vmId, currentVm, isDetailsPage, initialValues } = state;

  const isPrivate = currentVm?.networkType === 'PRIVATE';

  const currentSnapshot = currentVm?.serviceSnapshot;

  const { execute: confirmSnapshotRequest } = useSnapshotRequest(
    {
      serviceEntityId: vmId,
      serviceEntityName: 'Vm',
      appEntityId: currentVm?.appEntityId,
    },
    currentSnapshot?.id
  );

  const reloadCurrentVm = React.useCallback(
    (id: number, fetchAll?: boolean) => {
      if (isDetailsPage) {
        return vm.get({
          id,
          include: ['serviceMonitoring', 'serviceSnapshot', 'billingCycle'],
          isDedicatedServer: true,
        });
      }

      if (fetchAll) {
        Emitter.emit(CustomEvents.fetchVmList, {});
        Emitter.emit(CustomEvents.fetchFolderList, {});
      }

      return vms.reload(id, {
        include: ['serviceMonitoring', 'serviceSnapshot', 'billingCycle'],
      });
    },
    [isDetailsPage, vmId]
  );

  const handleStateChange = React.useCallback((payload: Partial<IState>) => {
    let newState: Partial<IState> = payload;
    const open =
      payload.detailsDialogOpen ||
      payload.cloneDialogOpen ||
      payload.mediaDialogOpen ||
      payload.attachDialogOpen ||
      payload.manageExternalIpDialogOpen ||
      !!payload.manageIpDialogOpen ||
      payload.snapshotDialogOpen ||
      !!payload.rsConfig;

    if (!open) {
      newState = initial;
    }

    dnd.merge({ isDialogOpen: open });

    handleState(newState);
  }, []);

  React.useEffect(() => {
    Emitter.on(CustomEvents.vmState, handleStateChange);

    return () => {
      Emitter.off(CustomEvents.vmState, () =>
        handleStateChange({ detailsDialogOpen: false, cloneDialogOpen: false })
      );
    };
  }, []);

  return (
    <>
      {state.detailsDialogOpen && (
        <VmDetailsDialog
          open={state.detailsDialogOpen}
          onClose={() => handleStateChange({ detailsDialogOpen: false })}
          currentVM={initialValues}
          prices={state.prices}
          billingCycleInterval={state.billingCycleInterval}
          vmId={vm.data?.id}
          hasSnapshot={!!currentSnapshot}
          onSave={() => {
            reloadCurrentVm(vmId);
          }}
        />
      )}

      {state.cloneDialogOpen && (
        <CloneVmDialog
          open={state.cloneDialogOpen}
          onClose={() => handleStateChange({ cloneDialogOpen: false })}
          currentVM={initialValues}
          prices={state.prices}
          billingCycleInterval={state.billingCycleInterval}
          vmId={vm.data?.id}
          hasSnapshot={!!currentSnapshot}
          onSave={() => {
            reloadCurrentVm(vmId, true);
          }}
        />
      )}

      <MediaDialog
        open={state.mediaDialogOpen}
        onSave={() => {
          reloadCurrentVm(vmId);
          handleStateChange({ mediaDialogOpen: false });
        }}
        onClose={() => handleStateChange({ mediaDialogOpen: false })}
        vmId={vmId}
      />

      <AttachNetworkDialog
        open={state.attachDialogOpen}
        onSave={(network) => {
          reloadCurrentVm(vmId);
          dnd.merge({ isDialogOpen: false });
          handleState({
            attachDialogOpen: false,
            selectedNetwork: undefined,
          });
        }}
        onClose={() => handleStateChange({ attachDialogOpen: false })}
        vmId={vmId}
        assignedNetworks={assignedNetworks}
        isPrivateVm={isPrivate}
      />

      <ManageExternalIpDialog
        open={state.manageExternalIpDialogOpen}
        onSave={() => {
          reloadCurrentVm(vmId);
          dnd.merge({ isDialogOpen: false });
          handleState({
            manageExternalIpDialogOpen: false,
            selectedNetwork: undefined,
          });
        }}
        onClose={() => handleStateChange({ manageExternalIpDialogOpen: false })}
        vmId={vmId}
        nic={additionalPrimaryNic}
        primaryNetworkId={primaryNetworkId}
      />

      <SnapshotDialog
        action="create"
        serviceEntityName="Rds"
        open={state.snapshotDialogOpen}
        keepMounted={false}
        onClose={() =>
          handleStateChange({
            snapshotDialogOpen: false,
          })
        }
        onSave={(payload) =>
          confirmSnapshotRequest('create', payload)
            .then(() => {
              return reloadCurrentVm(vmId);
            })
            .then(() =>
              handleStateChange({
                snapshotDialogOpen: false,
              })
            )
        }
      />

      <RemoteSupportDialog
        subtitle="remoteSupport.dialog.vm.subtitle"
        open={!!state.rsConfig}
        attributes={{
          vmId: state.rsConfig?.vmId,
          osType: state.rsConfig?.osType,
        }}
        type="vm"
        onSave={() => undefined}
        onClose={() => {
          handleStateChange({ rsConfig: null });
        }}
      />
    </>
  );
});
