import * as React from 'react';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { openUserInPortal, Emitter, dayjs } from 'utils';
import { Row, Menu, MenuItem, Chip, Tooltip } from 'elements';
import { useTask, useAuth, useState } from 'hooks';
import { CustomEvents } from 'enums';
import { mapAccountStatus } from './helpers';
import { BurgerIcon } from './Styled';
import { ILocalTypes } from './types';

const deleteTimers: AnyShape = {};

function defineTimesLeft(deletedAt: string): number {
  const now = dayjs();
  const startTime = dayjs(deletedAt);
  const endTimeTime = startTime.add(20, 'minutes');
  return endTimeTime.diff(now, 'minutes');
}

const initState = (
  partner: IPartnerTypes.Partner
): ILocalTypes.IInitTableActionsState => ({
  extendDialogOpen: false,
  timeCounter: defineTimesLeft(partner.deletedAt),
});

const TableActions = ({
  partner,
  onExpandExpiration,
  switchUser,
  t,
  onSwitchToProd,
  onManageQuotas,
  onManageBilling,
  pricingPermissions,
  goToPartnerPricing,
  onUndoDeletion,
  onDelete,
  ableToAction,
  onSwitchToEvaluation,
}: ILocalTypes.TableActionsProps) => {
  const [state, handleState] = useState<ILocalTypes.IInitTableActionsState>(
    initState(partner)
  );
  const { setChildSessionInfo } = useAuth();

  const status = React.useMemo(
    () => mapAccountStatus(partner.status),
    [partner.status]
  );
  const task = useTask(partner.task);
  const isDeleting = task.isDeleting || !!partner.deletedAt;

  const onExtentTenant = React.useCallback(
    () => onExpandExpiration(partner),
    [JSON.stringify(partner)]
  );

  const intervalCallback = React.useCallback(() => {
    if (state.timeCounter < 0) {
      clearInterval(deleteTimers[partner.id]);
      deleteTimers[partner.id] = undefined;
      return;
    }

    handleState(defineTimesLeft(partner.deletedAt), 'timeCounter');
  }, [partner.deletedAt]);

  React.useEffect(() => {
    if (partner.deletedAt) {
      deleteTimers[partner.id] = setInterval(intervalCallback, 5000);
    } else {
      clearInterval(deleteTimers[partner.id]);
    }

    return () => {
      clearInterval(deleteTimers[partner.id]);
    };
  }, [partner.deletedAt]);

  return (
    <Row justifyContent="flex-end" alignItems="center">
      {task.isFailed && (
        <Tooltip title={task.error && task.error.message} placement="top" arrow>
          <div>
            <Chip status="error" className="ml-5 pointer">
              {t('common.error')}
            </Chip>
          </div>
        </Tooltip>
      )}
      {status.prod && !partner.billingEmailsEnabled && !partner.deletedAt && (
        <Chip status="systemInfo" className="ml-5">
          {t('partners.table.account.billingDisabled')}
        </Chip>
      )}
      {status.pendingEvaluation && (
        <Chip status="warning" className="ml-5">
          {t('partners.table.account.testPending')}
        </Chip>
      )}
      {status.evaluation && (
        <Chip status="info" className="ml-5">
          {t('partners.table.account.test')}
        </Chip>
      )}
      {status.expired && (
        <Chip status="error" className="ml-5">
          {t('partners.table.account.expired')}
        </Chip>
      )}
      {isDeleting && (
        <Chip status="error" className="ml-5">
          {t('partners.table.account.delete.timeLeft', {
            time: state.timeCounter > 0 ? state.timeCounter : 0,
          })}
        </Chip>
      )}

      {ableToAction && (
        <PopupState variant="popover" popupId={`partner-actions-${partner.id}`}>
          {(popupState) => (
            <>
              <BurgerIcon {...bindTrigger(popupState)} />
              <Menu {...bindMenu(popupState)}>
                {switchUser && (
                  <MenuItem
                    onClick={openUserInPortal(() => {
                      setChildSessionInfo({ psn: partner.shortName }, true);
                      switchUser(partner);
                      popupState.close();
                    })}
                    key="openDetails"
                  >
                    {t('partners.table.openDetails')}
                  </MenuItem>
                )}

                {(status.evaluation || status.expired) && (
                  <MenuItem
                    onClick={() => {
                      onExtentTenant();
                      popupState.close();
                    }}
                    key="expandExpiration"
                  >
                    {t('partners.table.expandExpiration')}
                  </MenuItem>
                )}

                {status.pendingEvaluation && (
                  <MenuItem
                    onClick={() => {
                      onSwitchToEvaluation(partner);
                      popupState.close();
                    }}
                    key="switchToEvaluation"
                  >
                    {t('partners.table.switchToEvaluation')}
                  </MenuItem>
                )}

                {!status.prod && (
                  <MenuItem
                    onClick={() => {
                      onSwitchToProd(partner);
                      popupState.close();
                    }}
                    key="switchToProd"
                  >
                    {t('partners.table.switchToProd')}
                  </MenuItem>
                )}

                {status.prod && onManageBilling && (
                  <MenuItem
                    key="manageBilling"
                    onClick={() => {
                      onManageBilling(partner.id);
                      return popupState.close();
                    }}
                  >
                    {t('tenants.table.manageBilling')}
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    onManageQuotas(partner.id);
                    popupState.close();
                  }}
                  key="manageQuotes"
                >
                  {t('partners.table.manageQuotes')}
                </MenuItem>

                {pricingPermissions.canView && (
                  <MenuItem
                    onClick={() => {
                      goToPartnerPricing(partner.id);
                      popupState.close();
                    }}
                    key="goToPricing"
                  >
                    {t('partners.table.goToPricing')}
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    Emitter.emit(CustomEvents.assignDialog, partner);
                    popupState.close();
                  }}
                  key="assignBadges"
                >
                  {t('badges.menu.assignBadges')}
                </MenuItem>

                {!isDeleting && (
                  <MenuItem
                    onClick={() => {
                      onDelete(partner);
                      popupState.close();
                    }}
                    key="deleteTenant"
                    data-test-id={`delete-tenant-${partner.id}}`}
                  >
                    {t('common.delete')}
                  </MenuItem>
                )}

                {isDeleting && (
                  <MenuItem
                    onClick={() => {
                      onUndoDeletion(partner);
                      popupState.close();
                    }}
                    key="undoDeleteTenant"
                    data-test-id={`undo-delete-tenant-${partner.id}}`}
                  >
                    {t('partners.table.undoDeletion')}
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </PopupState>
      )}
    </Row>
  );
};

export default TableActions;
