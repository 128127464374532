import React, { useEffect } from 'react';
import QRCode from 'react-qr-code';
import { Button, Row, Col, Alert } from 'elements';
import { POST } from 'utils';
import { useQuery, useState } from 'hooks';
import { ALL_TEST_IDS } from 'enums';
import logoSrc from 'assets/images/login-logo.svg';
import { Trans, useTranslation } from 'react-i18next';
import { AnyShape } from 'global-shapes';
import {
  CreatePassPage,
  SetupFaPaper,
  Setup2FaLogo,
  SetupFaCode,
  SetupFaInstallText,
} from './Styled';

const links: AnyShape = {
  ios: 'https://apps.apple.com/app/google-authenticator/id388497605',
  android:
    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
};

const Setup2Fa = () => {
  const [state, handleStateChange] = useState<any>({});
  const { t } = useTranslation();
  const { query, changeQuery } = useQuery<any>();

  useEffect(() => {
    if (query.twoFactorAuthToken) {
      POST('/auth/2fa', {}, query)
        .then((res) => handleStateChange(res.data))
        .catch((err: any) => {
          handleStateChange({ error: err.message });
        });
    }
  }, []); // eslint-disable-line

  const content = !query.twoFactorAuthToken ? (
    <>
      <h2 className="mb-20 text-center">{t('auth.2fa.noConfig')}</h2>
      <Row justifyContent="center">
        <Button
          fullWidth
          size="large"
          onClick={() => changeQuery({}, '/login', true)}
          testId={ALL_TEST_IDS.qrCode.back}
        >
          {t('auth.code.back')}
        </Button>
      </Row>
    </>
  ) : (
    <>
      <Row className="mb-20 text-center">
        <Col xs={12}>
          <h2 className="mb-20">{t('auth.2fa.title')}</h2>
        </Col>
        <Col xs={12}>
          <h6 className="fs-20">{t('auth.2fa.scan')}</h6>
        </Col>
      </Row>
      {state.error && <Alert severity="error">{state.error}</Alert>}

      <SetupFaCode
        justifyContent="center"
        data-test-id={ALL_TEST_IDS.qrCode.image}
      >
        {state.otpauthUrl && <QRCode value={state.otpauthUrl} />}
      </SetupFaCode>
      <SetupFaInstallText justifyContent="center">
        <Trans
          i18nKey="auth.2fa.installAuthenticator"
          components={[
            <a
              href={links.ios}
              className="primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              iOS
            </a>,
            <a
              href={links.android}
              className="primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Android
            </a>,
          ]}
        >
          Please install Google Authenticator for
          <a
            href={links.ios}
            className="primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            iOS
          </a>
          or
          <a
            href={links.android}
            className="primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Android
          </a>
        </Trans>
      </SetupFaInstallText>
      <Row>
        <Button
          testId={ALL_TEST_IDS.qrCode.submit}
          className="mb-20 full-width"
          size="large"
          onClick={() => changeQuery(query, '/verify-code', true)}
        >
          {t('common.continue')}
        </Button>
      </Row>
    </>
  );

  return (
    <CreatePassPage>
      <SetupFaPaper>{content}</SetupFaPaper>
      <Setup2FaLogo>
        <img src={logoSrc} alt="" />
      </Setup2FaLogo>
    </CreatePassPage>
  );
};

export default Setup2Fa;
