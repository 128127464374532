import React from 'react';
import { ICostPerService } from 'usage';
import { Col } from 'elements';
import { numberToCurrency } from 'utils';
import { withTranslation, TFunction } from 'react-i18next';

const ServicePriceCol = (props: ICostPerService & { t: TFunction }) => {
  const { serviceId, cost, t } = props;
  return (
    <Col xs={12} sm={6} md={4} lg={2} className="mb-10">
      <div className="fs-14 uppercase bolder mb-10">
        {t(`usage.serviceName.${serviceId}`)}
      </div>
      <div className="steel uppercase">{numberToCurrency(cost)}</div>
    </Col>
  );
};

export default withTranslation()(ServicePriceCol);
