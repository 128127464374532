import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PopupState, {
  bindHover,
  bindMenu,
  bindPopover,
  bindTrigger,
} from 'material-ui-popup-state';
import Divider from '@mui/material/Divider';
import { useQuery, useRemoteSupport } from 'hooks';
import { IconButton, List, ListItemButton, MuiIcons, Popover } from 'elements';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { InjectedProps, withVmActions } from 'hocs/withVmActions';
import { usePopupState } from 'material-ui-popup-state/hooks';

const OBSERVERS = {
  snapshots: StateHandlers.snapshots,
  vm: StateHandlers.vmDetails,
  folders: StateHandlers.folders,
};

type IProps = InjectedProps;
type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    fetchVm: _fetchVM,
    isRequesting,
    handlers,
    vm,
    folders,
    isInFolder,
    injectedMedia,
    menuItems,
  } = props;
  const { t } = useTranslation();
  const { query, queryStr } = useQuery();
  const innerPopupState = usePopupState({
    variant: 'popover',
    popupId: 'innerPopover',
  });

  const fetchVM = useCallback(
    () =>
      _fetchVM({
        id: query.id,
        include: ['serviceMonitoring', 'serviceSnapshot'],
      }),
    [queryStr, _fetchVM]
  );

  const rs = useRemoteSupport({
    type: 'vm',
    vmId: vm.data?.id,
    onSuccess: fetchVM,
  });

  const isPublic = vm.data?.networkType === 'PUBLIC';
  const isPoweredOn = vm.data?.status === 'POWERED_ON';
  const hasInjectedMedia = !!injectedMedia;

  const availableFolders =
    folders.data.filter((f) => f.type === 'VIRTUAL_SERVER') || [];

  const snapshotOptions = menuItems?.filter((item) =>
    [
      'createSnapshot',
      'revertSnapshot',
      'removeSnapshot',
      'removeScheduledSnapshotTask',
    ].includes(item.value as string)
  );

  return (
    <PopupState variant="popover" popupId={`tenant-actions-${vm.data?.id}`}>
      {(popupState) => (
        <>
          <IconButton {...bindTrigger(popupState)} size="small">
            <MuiIcons.MoreVert className="fs-20" />
          </IconButton>
          <Popover {...bindMenu(popupState)}>
            <List>
              {snapshotOptions?.map((item) => (
                <ListItemButton
                  key={item.label}
                  disabled={item.disabled}
                  onClick={() => {
                    item.onClick && item.onClick();
                    popupState.close();
                  }}
                >
                  {t(`services.content.action.${item.label}`)}
                </ListItemButton>
              ))}

              <Divider />
              <ListItemButton
                disabled={isRequesting}
                onClick={() => {
                  hasInjectedMedia
                    ? handlers.ejectMedia()
                    : handlers.insertMedia();
                  popupState.close();
                }}
              >
                {t(
                  `services.content.action.${
                    hasInjectedMedia ? 'ejectMedia' : 'insertMedia'
                  }`
                )}
              </ListItemButton>
              <Divider />
              <ListItemButton
                disabled={isRequesting}
                onClick={() => {
                  handlers.attachNetwork();
                  popupState.close();
                }}
              >
                {t(`services.content.action.attachNetwork`)}
              </ListItemButton>
              {!isPublic && (
                <ListItemButton
                  disabled={rs.isRequesting || rs.isTaskActive}
                  onClick={() => {
                    !rs.isUsedVm
                      ? handlers.enableRemoteSupport()
                      : handlers.disableRemoteSupport();
                    popupState.close();
                  }}
                >
                  {t(
                    `services.content.action.remoteSupport.${
                      rs.isUsedVm ? 'disable' : 'enable'
                    }`
                  )}
                </ListItemButton>
              )}

              <ListItemButton
                onClick={() => {
                  handlers.switchVmNetworkType();
                  popupState.close();
                }}
              >
                {t(`services.content.action.switchVmNetworkType`)}
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  handlers.clone();
                  popupState.close();
                }}
              >
                {t(`services.content.action.clone`)}
              </ListItemButton>

              {!isInFolder && !!availableFolders.length && (
                <ListItemButton {...bindHover(innerPopupState)}>
                  <div className="flex justify-between align-center">
                    <MuiIcons.ChevronLeft className="fs-22 steel pointer mr-5" />
                    {t(`services.content.action.moveToFolder`)}
                  </div>
                </ListItemButton>
              )}

              {isInFolder && (
                <ListItemButton
                  onClick={() => {
                    handlers.removeFromFolder();
                    popupState.close();
                  }}
                >
                  {t(`services.content.action.removeFromFolder`)}
                </ListItemButton>
              )}

              {isPoweredOn && (
                <ListItemButton
                  disabled={rs.isRequesting || rs.isTaskActive}
                  onClick={() => {
                    handlers.console(vm.data?.id);
                    popupState.close();
                  }}
                >
                  {t(`services.content.action.console`)}
                </ListItemButton>
              )}

              <ListItemButton
                onClick={async () => {
                  popupState.close();
                  await handlers.delete();
                  return await fetchVM();
                }}
              >
                {t('services.vm.navigation.removeService')}
              </ListItemButton>
            </List>

            <HoverPopover
              {...bindPopover(innerPopupState)}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <List>
                {/* @ts-ignore */}
                {availableFolders.map((folder) => {
                  return (
                    <ListItemButton
                      key={`${folder.id}-folder`}
                      data-test-id={`${folder.id}-folder-menu-item`}
                      disabled={isRequesting}
                      onClick={() => {
                        handlers.moveToFolder(folder);
                        popupState.close();
                        innerPopupState.close();
                      }}
                    >
                      {folder.name}
                    </ListItemButton>
                  );
                })}
              </List>
            </HoverPopover>
          </Popover>
        </>
      )}
    </PopupState>
  );
});

const ActionMenu = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default withVmActions(ActionMenu, 'single');
