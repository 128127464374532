import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { UserFormValues } from 'users-shapes';
import { Role } from 'roles-shapes';
import { onRolesLoad } from './helpers';
import { rolesService } from 'services';
import { LANG_MAP, LANGUAGES, TestPrefixes } from 'enums';
import { parseToOptions } from 'utils';
import { Button, Col, Dialog, DialogProps, Input, Row, Select } from 'elements';
import { Permission } from 'auth-shapes';

const validationSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .required('forms.required')
    .max(30, 'forms.invalid.max'),
  lastName: Yup.string()
    .trim()
    .required('forms.required')
    .max(30, 'forms.invalid.max'),
  email: Yup.string().email('forms.emailInvalid').required('forms.required'),
  role: Yup.object().nullable().required('forms.required'),
  lang: Yup.object().nullable().required('forms.required'),
});

const initials: UserFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  role: null,
  lang: LANG_MAP.de,
};

type Props = DialogProps<UserFormValues> & {
  isNew: boolean;
  isYourself: boolean;
  isRequesting: boolean;
  initialValues: UserFormValues | null;
  accessLevel: string;
  rolesPermission: Permission;
};

const UserDetailsDialog = (props: Props) => {
  const {
    isNew,
    initialValues,
    onSave,
    accessLevel,
    rolesPermission,
    isRequesting,
    isYourself,
    ...rest
  } = props;
  const { t } = useTranslation();
  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    setFieldValue,
    touched,
    submitCount,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: initials,
    validationSchema,
    onSubmit: async (val) =>
      onSave &&
      onSave({
        accessLevel,
        initialValues,
        ...val,
      }),
  });

  React.useEffect(() => {
    rolesService
      .getRoles({ limit: 1000, offset: 0 })
      .then(parseToOptions())
      .then((options) => {
        initials.role = options.find((o: Role) => o.isDefault);
      });
  }, []);

  React.useEffect(() => {
    if (rest.open && initialValues) {
      setValues(initialValues);
    }

    if (!rest.open) {
      resetForm();
    }
  }, [JSON.stringify(initialValues), rest.open]); // eslint-disable-line

  const testId = isNew ? TestPrefixes.createUser : TestPrefixes.editUser;

  return (
    <Dialog
      {...rest}
      title={t(`users.dialog.${isNew ? 'create' : 'edit'}.title`)}
      actions={
        <Row justifyContent="flex-end" columnSpacing={3} className="full-width">
          <Col>
            <Button
              variant="outlined"
              color="default"
              testId={`${testId}-close`}
              onClick={rest.onClose}
            >
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button type="submit" testId={testId} disabled={isRequesting}>
              {t('common.save')}
            </Button>
          </Col>
        </Row>
      }
      handleSubmit={handleSubmit}
      fullWidth
      testId={testId}
    >
      <Row columnSpacing={2}>
        <Col xs={6}>
          <Input
            name="firstName"
            className="mb-25"
            value={values.firstName}
            label={t('forms.firstName')}
            placeholder={t('forms.placeholders.firstName')}
            touched={touched}
            onChange={handleChange}
            error={!!submitCount && !!errors.firstName}
            helperText={errors.firstName}
            helperTextOptions={{ max: 30 }}
            testId={testId}
          />
        </Col>
        <Col xs={6}>
          <Input
            name="lastName"
            className="mb-25"
            value={values.lastName}
            label={t('forms.lastName')}
            placeholder={t('forms.placeholders.lastName')}
            touched={touched}
            onChange={handleChange}
            error={!!submitCount && !!errors.lastName}
            helperText={errors.lastName}
            helperTextOptions={{ max: 30 }}
            testId={testId}
          />
        </Col>
      </Row>

      <Input
        name="email"
        className="mb-25"
        value={values.email}
        label={t('forms.email')}
        placeholder={t('forms.placeholders.email')}
        touched={touched}
        onChange={handleChange}
        error={!!submitCount && !!errors.email}
        helperText={errors.email}
        testId={testId}
      />

      <Select
        value={values.role}
        label={t('forms.roles')}
        useAsync
        onLoad={onRolesLoad}
        isDisabled={isYourself || !rolesPermission.canView}
        defaultOptions
        name="role"
        className="mb-25"
        onChange={(val) => setFieldValue('role', val)}
        error={!!submitCount && !!errors.role}
        testId={testId}
      />
      <Select
        value={values.lang}
        label={t('forms.language')}
        name="lang"
        options={LANGUAGES}
        onChange={(val) => setFieldValue('lang', val)}
        error={!!submitCount && !!errors.lang}
        testId={testId}
      />
    </Dialog>
  );
};

export default UserDetailsDialog;
