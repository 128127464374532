import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Row, Col } from 'elements';
import { EditableText } from 'components';
import * as StateHandlers from 'states';
import { numberToCurrency, showSystemMessage } from 'utils';
import { usePermissions, useState, useAccount } from 'hooks';
import { observer } from 'mobx-react-lite';
import Decimal from 'decimal.js';
import { ResourceBlockRow, ResourceBlockTitle, ListItemText } from './Styled';

const OBSERVERS = {
  tenantBillingRate: StateHandlers.tenantBillingRate,
  currentTenantBillingRate: StateHandlers.currentTenantBillingRate,
};

interface IProps {
  hasParentPrice: boolean;
  tenantId?: number;
}

type IViewProps = typeof OBSERVERS;

const parseToPercent = (coefficient: string | number): number => {
  const coef = new Decimal(+coefficient);
  coef.minus(1);
  return +coef.times(100).minus(100);
};

const parseToCoefficient = (percent: string | number) => {
  return 1 + Number(percent) / 100;
};

const BARE_METAL_PRICE = 200;
const WIN_LEC_PRICE = 50;

const View = observer((props: IProps & IViewProps) => {
  const {
    hasParentPrice,
    tenantBillingRate,
    currentTenantBillingRate,
    tenantId,
  } = props;
  const { t } = useTranslation();
  const [account] = useAccount();
  const isOnTenantPricingDetails = hasParentPrice && !!tenantId;
  const billingRate = isOnTenantPricingDetails
    ? currentTenantBillingRate
    : tenantBillingRate;
  const [state, handleState] = useState({
    rate: parseToPercent(billingRate.data?.dedicatedServersBillingRate) || 0,
  });
  const { isCurrentPartner } = usePermissions('PRICING');
  const accountId = isOnTenantPricingDetails ? tenantId : account?.partner?.id;

  const onUpdateRate = React.useCallback(
    async (rate: string) => {
      await billingRate
        .update(accountId, {
          dedicatedServersBillingRate: parseToCoefficient(rate) || 1,
        })
        .catch((er) => {
          showSystemMessage(er.message, 'error');
          handleState({
            rate: Number(billingRate.data?.dedicatedServersBillingRate) || 0,
          });
        });
      return billingRate.get({ id: accountId });
    },
    [accountId]
  );

  React.useEffect(() => {
    if (accountId) {
      billingRate.get({ id: accountId }).then((res) => {
        console.log('res -> ', res);
        handleState({
          rate: parseToPercent(res?.dedicatedServersBillingRate || 0) || 0,
        });
      });
    }
  }, [accountId]);

  const bareMetalPrice = numberToCurrency(
    BARE_METAL_PRICE + (BARE_METAL_PRICE / 100) * (state.rate || 0),
    false
  );

  const winPrice = numberToCurrency(
    WIN_LEC_PRICE + (WIN_LEC_PRICE / 100) * (state.rate || 0),
    false
  );

  const rateText = React.useMemo(() => {
    return parseToPercent(billingRate.data?.dedicatedServersBillingRate || 0);
  }, [billingRate.data?.dedicatedServersBillingRate]);

  if (!isCurrentPartner) return null;

  return (
    <Paper className="p-20 mb-15">
      <ResourceBlockTitle className="bold mb-15">
        {t(`settings.page.pricing.titles.dedicatedServer.title`)}
      </ResourceBlockTitle>
      <Row alignItems="center" columnSpacing={2} className="mb-10">
        <Col className="fs-14  pt-5 pb-5">Pricing increase rate:</Col>
        <Col>
          <EditableText
            isEditable
            disabled={billingRate.isRequesting}
            text={rateText.toString()}
            displayText={`${rateText.toString()} %`}
            value={billingRate.data.dedicatedServersBillingRate}
            onSuccess={onUpdateRate}
            onCancel={() => {
              handleState({ rate: Number(rateText) || 0 });
            }}
            onChange={(ev) => {
              handleState({ rate: Number(ev.target.value) || 0 });
            }}
          />
        </Col>
      </Row>
      <div className="fs-12 steel">
        {t('settings.page.pricing.titles.dedicatedServer.subtitle')}
      </div>
      <ResourceBlockRow columnSpacing={0} alignItems="center">
        {hasParentPrice ? (
          <>
            <Col xs={5}>
              <ListItemText>
                {t(`settings.page.pricing.dedicatedServer.item1`)}
              </ListItemText>
            </Col>
            <Col xs={7}>
              <Row className="steel">
                <Col xs={5}>
                  {numberToCurrency(BARE_METAL_PRICE, false)} (
                  {t('settings.page.pricing.dedicatedServer.exampleCost')})
                </Col>
                <Col xs={7}>{bareMetalPrice}</Col>
              </Row>
            </Col>
          </>
        ) : (
          <>
            <Col xs={7}>
              <ListItemText>
                {t(`settings.page.pricing.dedicatedServer.item1`)}
              </ListItemText>
            </Col>
            <Col xs={5}>
              <Row className="steel">
                <Col xs={5}>{numberToCurrency(BARE_METAL_PRICE, false)}</Col>
                <Col xs={4}>{bareMetalPrice}</Col>
              </Row>
            </Col>
          </>
        )}
      </ResourceBlockRow>
      <ResourceBlockRow columnSpacing={0} alignItems="center">
        {hasParentPrice ? (
          <>
            <Col xs={5}>
              <ListItemText>
                {t(`settings.page.pricing.dedicatedServer.item2`)}
              </ListItemText>
            </Col>
            <Col xs={7}>
              <Row className="steel">
                <Col xs={5}>
                  {numberToCurrency(WIN_LEC_PRICE, false)} (
                  {t('settings.page.pricing.dedicatedServer.exampleCost')})
                </Col>
                <Col xs={7}>{winPrice}</Col>
              </Row>
            </Col>
          </>
        ) : (
          <>
            <Col xs={7}>
              <ListItemText>
                {t(`settings.page.pricing.dedicatedServer.item2`)}
              </ListItemText>
            </Col>
            <Col xs={5}>
              <Row className="steel">
                <Col xs={5}>{numberToCurrency(WIN_LEC_PRICE, false)}</Col>
                <Col xs={4}>{winPrice}</Col>
              </Row>
            </Col>
          </>
        )}
      </ResourceBlockRow>
    </Paper>
  );
});

const DedicatedServerRate = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default DedicatedServerRate;
