import { showSystemMessage } from 'utils';
import { softwarePackagesService } from 'services';
import { ISoftwarePackage, ISoftwarePackageInTenant } from 'app-store';
import { BaseMobxInstanceDecorator } from './utilities';

export const softwarePackages = new BaseMobxInstanceDecorator<
  ISoftwarePackage,
  true
>({
  instanceName: 'app-store',
  requests: {
    get: softwarePackagesService.get,
    create: async (payload) => {
      const res = await softwarePackagesService.create(payload);
      showSystemMessage('softwarePackages.notify.create.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const res = await softwarePackagesService.update(id as number, payload);
      showSystemMessage('softwarePackages.notify.update.success', 'success');
      return res;
    },
    remove: async (id) => {
      const res = await softwarePackagesService.removePackage(id as number);
      showSystemMessage('softwarePackages.notify.delete.success', 'success');
      return res;
    },
  },
  initialState: [],
});

export const vmPackages = new BaseMobxInstanceDecorator<
  ISoftwarePackageInTenant,
  true
>({
  instanceName: 'tenant-app-store',
  requests: {
    get: softwarePackagesService.getVmPackages,
    create: async (payload) => {
      const { install, vmId, packageId, values } = payload;
      const res = (await install)
        ? await softwarePackagesService.installSoftwarePackage(
            vmId,
            packageId,
            values
          )
        : await softwarePackagesService.uninstallSoftwarePackage(
            vmId,
            packageId
          );
      showSystemMessage(
        `softwarePackages.notify.${install ? 'install' : 'uninstall'}.success`,
        'success'
      );
      return res as any;
    },
  },
  initialState: [],
});
