import * as React from 'react';
import BasePricingView from './BasePricingViewWrapper';
import { AnyShape } from 'global-shapes';
import { withPermissions, InjectedPermissionsProps } from 'hocs';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

const OBSERVERS = {
  pricingServices: StateHandlers.pricingServices,
  defaultProviderPricing: StateHandlers.defaultPricing,
  mainPricings: StateHandlers.mainPricing,
};

type IProps = InjectedPermissionsProps;

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { permissions, pricingServices, defaultProviderPricing, mainPricings } =
    props;
  const { t } = useTranslation();

  const dataReceived =
    pricingServices.dataReceived && defaultProviderPricing.dataReceived;

  const disabled =
    defaultProviderPricing.isRequesting || pricingServices.isRequesting;

  const setDefaultPrice = React.useCallback((data: AnyShape) => {
    defaultProviderPricing.create(data).then(() => {
      defaultProviderPricing.get();
    });
  }, []);

  const updateDefaultPrice = React.useCallback((data: AnyShape) => {
    mainPricings.update(data.id, data).then(() => {
      return defaultProviderPricing.get();
    });
  }, []);

  React.useEffect(() => {
    pricingServices.get();
    defaultProviderPricing.get();
  }, []);

  const createdPrices = defaultProviderPricing.data;
  const defaultPrices = pricingServices.data;

  return (
    <BasePricingView
      data={createdPrices}
      subtitle={t('settings.page.pricing.defaultProviderPricing.subtitle')}
      defaultPricing={defaultPrices}
      dataReceived={dataReceived}
      isEditable={permissions.canManage}
      isOnUserPricing={false}
      disabled={disabled}
      onUpdatePricing={updateDefaultPrice}
      onSetPrice={setDefaultPrice}
    />
  );
});

const DefaultProviderPricing = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default withPermissions(DefaultProviderPricing, 'PRICING');
