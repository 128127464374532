import { showSystemMessage } from 'utils';
import { blacklistService } from 'services';
import { BlacklistDomain } from 'blacklist';
import { BaseMobxInstanceDecorator } from './utilities';

export const blacklist = new BaseMobxInstanceDecorator<
  BlacklistDomain,
  true
>({
  instanceName: 'blacklist',
  requests: {
    get: blacklistService.getBlacklist,
    create: async (payload) => {
      const res = await blacklistService.create(payload);
      showSystemMessage(
        'settings.page.blacklist.notify.add.success',
        'success'
      );
      return res;
    },
    update: async (id, payload) => {
      const res = await blacklistService.update(id as number, payload);
      showSystemMessage(
        'settings.page.blacklist.notify.update.success',
        'success'
      );
      return res;
    },
    remove: async (id) => {
      const res = await blacklistService.deleteDomain(id as number);
      showSystemMessage(
        'settings.page.blacklist.notify.delete.success',
        'success'
      );
      return res;
    },
  },
  initialState: [],
});
