import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'elements';
import { CpuIcon, RamIcon, DiskSizeIcon } from 'components/QuotasInfo/Icons';

const SystemInfo = (props: IVmVcdTypes.VmSpec & { className?: string }) => {
  const { t } = useTranslation();

  return (
    <Row className={props.className}>
      <Col xs={5}>
        <Row columnSpacing={2} alignItems="center">
          <Col>
            <CpuIcon size={36} scale={1} />
          </Col>
          <Col className="fs-14">
            <div className="steel fs-12">
              {t(`services.card.content.virtualCpus`)}
            </div>
            <div className="bold">{props.virtualCpus}</div>
          </Col>
        </Row>
      </Col>
      <Col xs={4}>
        <Row columnSpacing={2} alignItems="center">
          <Col>
            <RamIcon size={36} scale={1} />
          </Col>
          <Col className="fs-14">
            <div className="steel fs-12">
              {t(`services.card.content.ramMb`)}
            </div>
            <div className="bold">{props.ramMb / 1024} GB</div>
          </Col>
        </Row>
      </Col>
      <Col xs={3}>
        <Row columnSpacing={2} alignItems="center">
          <Col>
            <DiskSizeIcon size={36} scale={1} />
          </Col>
          <Col className="fs-14">
            <div className="steel fs-12">
              {t(`services.card.content.totalDiskSizeGb`)}
            </div>
            <div className="bold">{props.diskSizeGb} GB</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SystemInfo;
