import { BaseMobxInstanceDecorator } from './utilities';
import { dedicatedServersService } from 'services';
import { showSystemMessage, openInNewTab } from 'utils';

export const tenantosServers = new BaseMobxInstanceDecorator<
  IDedicatedServer.TenantosServer,
  true
>({
  instanceName: 'tenantos-server',
  requests: {
    get: dedicatedServersService.getTenantosServers,
  },
  initialState: [],
  additionalRequests: {},
});

export const dedicatedServers = new BaseMobxInstanceDecorator<
  IDedicatedServer.DedicatedServer,
  true
>({
  instanceName: 'dedicated-servers',
  requests: {
    get: dedicatedServersService.getDedicatedServers,
    create: async (payload) => {
      const res = await dedicatedServersService.createDedicatedServer(payload);
      showSystemMessage('tenantosServers.notify.create.success', 'success');
      return res;
    },
    update: async (
      id: any,
      payload: IDedicatedServer.IRentDedicatedServerPayload
    ) => {
      const res = await dedicatedServersService.updateDedicatedServer(
        id,
        payload
      );
      showSystemMessage('dedicatedServer.notify.update.success', 'success');
      return res;
    },
    remove: async (id: any) => {
      const res = await dedicatedServersService.removeDedicatedServer(
        id as any
      );
      showSystemMessage('dedicatedServer.notify.delete.success', 'success');
      return res;
    },
  },
  initialState: [],
  additionalRequests: {
    powerOn: async (id: number) => {
      const res = await dedicatedServersService.powerOnDedicatedServer(id);
      showSystemMessage('dedicatedServer.notify.powerOn.success', 'success');
      return res;
    },
    powerOff: async (id: number) => {
      const res = await dedicatedServersService.powerOffDedicatedServer(id);
      showSystemMessage('dedicatedServer.notify.powerOff.success', 'success');
      return res;
    },
    reset: async (id: number) => {
      const res = await dedicatedServersService.resetDedicatedServer(id);
      showSystemMessage('dedicatedServer.notify.reset.success', 'success');
      return res;
    },
    reinstallOs: async (
      id: number,
      payload: IDedicatedServer.IReinstallPayload &
        IDedicatedServer.IRentDedicatedServerPayload
    ) => {
      const { osPassword, templateId, billingCycleInterval } = payload;
      const res = await dedicatedServersService.reinstallOs(id, {
        osPassword,
        templateId,
      });
      await dedicatedServersService.updateDedicatedServer(id, {
        billingCycleInterval,
      });
      showSystemMessage(
        'dedicatedServer.notify.reinstallOs.success',
        'success'
      );
      return res;
    },
    openWebConsole: async (id: number) => {
      const res = await dedicatedServersService.getWebConsoleUrl(id);
      openInNewTab(res.data.url);

      return res;
    },
  },
});

export const dedicatedServerDetails = new BaseMobxInstanceDecorator<
  IDedicatedServer.DedicatedServer,
  false
>({
  instanceName: 'dedicated-server',
  requests: {
    get: dedicatedServersService.getDedicatedServerById,
  },
  // @ts-ignore
  initialState: null,
});
