import React from 'react';
import { Role } from 'roles-shapes';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'elements';
import { compact } from 'utils';
import { ALL_TEST_IDS } from 'enums';
import { usePermissions } from 'hooks';
import {
  RoleItemHolder,
  RoleItemHolderContent,
  PermissionsHeader,
  GroupIcon,
} from './Styled';

interface Props extends Role {
  onEdit: () => any;
  onDelete: () => any;
}

const RoleItem = (props: Props) => {
  const { t } = useTranslation();
  const { permissions } = usePermissions('ROLES');
  const testId = `${ALL_TEST_IDS.roles.roleItem}-${props.id}`;

  return (
    <RoleItemHolder data-test-id={testId}>
      <Row justifyContent="space-between" className="mb-15">
        <Col>
          <h4 data-test-id={`${ALL_TEST_IDS.roles.roleItemName}-${props.id}`}>
            {props.name}
          </h4>
        </Col>
        <Col>
          <Row
            alignItems="center"
            className="bolder"
            data-test-id={`${ALL_TEST_IDS.roles.roleItemCount}-${props.id}`}
          >
            {props.usersCount || 0}
            <GroupIcon />
          </Row>
        </Col>
      </Row>
      <PermissionsHeader>PERMISSIONS</PermissionsHeader>
      <RoleItemHolderContent>
        {compact(
          props.permissions.map((permission) => {
            if (permission.canView || permission.canManage)
              return t(`permissions.${permission.resource}`);
            return undefined;
          })
        ).join(', ')}
      </RoleItemHolderContent>
      {permissions.canManage && (
        <Row justifyContent="flex-end" alignItems="flex-end">
          {!props.isDefault && (
            <>
              <Button
                variant="text"
                onClick={props.onDelete}
                testId={`${ALL_TEST_IDS.roles.roleItemDelete}-${props.id}`}
              >
                {t('common.delete')}
              </Button>
              <Button
                variant="text"
                onClick={props.onEdit}
                testId={`${ALL_TEST_IDS.roles.roleItemEdit}-${props.id}`}
              >
                {t('common.edit')}
              </Button>
            </>
          )}
        </Row>
      )}
    </RoleItemHolder>
  );
};

export default RoleItem;
