import { useCallback } from 'react';
import { showSystemMessage } from 'utils';
import { useState } from 'hooks/useState';
import { IAxiosPromise } from 'axios-shapes';
import { AxiosPromise } from 'axios';

interface IValues<T> {
  isPending: boolean;
  value: T | null;
  params: any;
  error: any;
}

interface IValuesWithExecute<T> extends IValues<T> {
  execute: (...args: any) => IAxiosPromise<T> & AxiosPromise<T>;
}

const INITIAL_STATE = {
  value: null,
  error: null,
  params: {},
  isPending: false,
};

export function useAsync<T>(
  asyncFunction: (...args: any) => IAxiosPromise<T> & AxiosPromise<T>,
  defaultValue?: any
): IValuesWithExecute<T> {
  const initialValue = defaultValue || INITIAL_STATE.value;
  const [state, handleState] = useState({
    ...INITIAL_STATE,
    value: initialValue,
  });

  const execute = useCallback(
    async (...rest: any) => {
      handleState({ isPending: true, error: null });
      try {
        const res = await asyncFunction(...rest);
        handleState({
          isPending: false,
          error: null,
          value: res.data,
          params: res.pagination || {},
        });
        return res;
      } catch (err: any) {
        handleState({ isPending: false, error: err });
        showSystemMessage(err.message, 'error');
        throw err;
      }
    },
    [asyncFunction]
  );

  // @ts-ignore
  return { ...state, execute };
}
