import * as unzipService from '@zip.js/zip.js';

const {
  BlobReader,
  BlobWriter,
  ZipReader,
} = unzipService;

export function getEntries(file: Blob, options: any) {
  return new ZipReader(new BlobReader(file)).getEntries(options || {});
}

export async function getEntryContent(entry: any): Promise<File> {
  const res = await entry.getData(new BlobWriter());
  return blobToFile(res, entry.filename);
}

export function blobToFile(theBlob: Blob, fileName: string): File {
  return new File(
    [theBlob as any], // cast as any
    fileName,
    {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    }
  );
}
