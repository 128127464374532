import * as React from 'react';
import { remCalc, important } from 'utils';
import { PALETTE } from 'enums';
import { styled, Paper } from 'elements';

export const Holder = styled(Paper)(({ theme }) => ({
  padding: remCalc(40, 115),
}));

export const Content = styled('div')(({ theme }) => ({
  maxWidth: remCalc(570),
}));

export const InputWrapper = styled('div')(({ theme }) => ({
  maxWidth: remCalc(170),
  width: '100%',
}));

export const Preview = styled(
  (
    props: React.ComponentProps<'div'> & {
      isActive: boolean;
      hasValue: boolean;
    }
  ) => {
    const { isActive, hasValue, ...rest } = props;
    return <div {...rest} />;
  }
)(({ isActive, hasValue }) => ({
  width: remCalc(200),
  height: remCalc(64),
  borderRadius: 2,
  overflow: 'hidden',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  borderStyle: 'dashed',
  borderWidth: 1,
  borderColor: isActive
    ? important(PALETTE.primary)
    : hasValue
    ? important('transparent')
    : important(PALETTE.steel),
}));
