import { Address4 } from 'ip-address';
import { compact } from 'utils';
import * as Yup from 'yup'; // 22 will automatically increase to 32 symbols, that we need

// 22 will automatically increase to 32 symbols, that we need

export function generatePreSharedKey(len = 32, an?: string): string {
  an = an && an.toLowerCase();
  let str = '',
    i = 0;
  const min = an === 'a' ? 10 : 0,
    max = an === 'n' ? 10 : 62;
  for (; i++ < len; ) {
    let r = (Math.random() * (max - min) + min) << 0;
    str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
  }
  return str;
}

const preSharedKeyValidation = Yup.string()
  .min(16, 'forms.invalid.min')
  .max(64, 'forms.invalid.max')
  .trim()
  .when('editKey', {
    is: true,
    then: Yup.string()
      .min(16, 'forms.invalid.min')
      .max(64, 'forms.invalid.max')
      .trim()
      .required('forms.required'),
  });

const preSharedKeyReq = preSharedKeyValidation.required('forms.required');

const checkIsSubnetIsInvalid = (val: string) => {
  const str = val ? val.trim() : '';
  try {
    const address = new Address4(str);
    const networkMask = str && str.split('/')[1];
    if (!networkMask) {
      return false;
    }
    const networkAddress = address.startAddress();
    if (address.addressMinusSuffix !== networkAddress.addressMinusSuffix) {
      return false;
    }
  } catch (err) {
    return false;
  }

  return true;
};

export const validationSchema = (isNew: boolean) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(30, 'forms.invalid.max')
      .required('forms.required'),
    remoteEndpoint: Yup.string()
      .trim()
      .required('forms.required')
      // @ts-ignore
      .test('remoteEndpoint', 'forms.ipInvalid', (val: string) => {
        if (!val) return false;
        try {
          const address = new Address4(val);
          if (!address) return false;
          if (!address.isCorrect()) {
            return false;
          }
        } catch (err) {
          return false;
        }
        return true;
      }),
    remoteSubnets: Yup.string()
      .trim()
      .required('forms.required')
      // @ts-ignore
      .test('remoteSubnets', 'forms.ipInvalidSubnet', (val: string) => {
        if (!val) return false;
        const validValues = compact(val.split(',')).map(checkIsSubnetIsInvalid);

        return !validValues.some((str) => !str);
      })
      // @ts-ignore
      .test('remoteSubnets', 'forms.invalid.max', (val: string) => {
        if (!val) return false;
        const count = compact(val.split(',').map((str) => str.trim())).length;

        return count <= 10;
      }),
    preSharedKey: isNew ? preSharedKeyReq : preSharedKeyValidation,
    ikeVersion: Yup.object().required('forms.required'),
    perfectForwardSecrecy: Yup.boolean(),
    encryptionAlgorithm: Yup.object().required('forms.required'),
    diffieHellmanGroup: Yup.object().required('forms.required'),
    digestAlgorithm: Yup.object().required('forms.required'),
    ikeSATimeLife: Yup.number()
      .min(21600, 'forms.invalid.min')
      .max(31536000, 'forms.invalid.max'),
    tunnelSALifeTime: Yup.number()
      .min(900, 'forms.invalid.min')
      .max(31536000, 'forms.invalid.max'),
    dpdProbeInterval: Yup.number()
      .required('forms.required')
      .max(60, 'forms.invalid.max')
      .min(3, 'forms.invalid.min'),
  });
