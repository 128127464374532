import { DELETE, GET, POST } from 'utils';
import { AnyShape } from 'global-shapes';
import { IAxiosPromise } from 'axios-shapes';
import { AxiosPromise } from 'axios';

export const get = (params: AnyShape): IAxiosPromise<Task[]> =>
  GET('/tasks', params);

export const getOptions = (): IAxiosPromise<ITaskOption[]> =>
  GET('/tasks/options');
export const getById = (id: number): AxiosPromise<Task> => GET(`/tasks/${id}`);

export const retry = (id: number): IAxiosPromise<Task[]> =>
  POST(`/tasks/${id}/retry`);

export const cancel = (id: number): IAxiosPromise<Task[]> =>
  POST(`/tasks/${id}/cancel`);

export const getScheduledTasks = (
  params: AnyShape
): IAxiosPromise<IScheduledTasks.Task[]> => GET('/scheduled-tasks', params);

export const createScheduledTasks = (
  payload: IScheduledTasks.CreatePayload
): IAxiosPromise<IScheduledTasks.Task> => POST('/scheduled-tasks', {}, payload);

export const removeScheduledTasks = (
  id: number
): IAxiosPromise<IScheduledTasks.Task> => DELETE(`/scheduled-tasks/${id}`);
