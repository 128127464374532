import React from 'react';
import {
  Button,
  DateTimePicker,
  Dialog,
  DialogProps,
  Input,
  styled,
  Switch,
} from 'elements';
import { ISnapshotEntityType } from 'snapshots';
import { useFormik } from 'formik';
import { dayjs, Dayjs, findDisabledMaxTime, resetTimeValue } from 'utils';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export type ISnapshotAction = 'create' | 'revert' | 'remove';

type IValues = {
  description?: string;
  scheduleSnapshot: boolean;
  scheduleAt: Dayjs;
  removalOn: Dayjs;
};

type Props = DialogProps<IValues> & {
  serviceEntityName: ISnapshotEntityType;
  action: ISnapshotAction;
};

export const snapshotInputValidationSchema = Yup.object({
  description: Yup.string().trim().max(100, 'forms.invalid.max'),
});

export const SPickerWrapper = styled('div')(({ theme }) => ({
  maxWidth: 210,
}));

const emptyDisabledTime = {
  hours: () => [],
  minutes: () => [],
};

const SnapshotDialog = (props: Props) => {
  const { onSave, serviceEntityName, open, action, ...rest } = props;
  const { t } = useTranslation();

  const initialValues: IValues = {
    description: '',
    scheduleSnapshot: false,
    scheduleAt: resetTimeValue(dayjs().add(1, 'days').set('minutes', 0)).add(
      1,
      'hours'
    ),
    removalOn: resetTimeValue(dayjs().add(7, 'days').set('minutes', 0)).add(
      1,
      'days'
    ),
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: snapshotInputValidationSchema,
    validateOnMount: false,
    onSubmit: async (val) => {
      try {
        return onSave && onSave(val);
      } catch (er) {
        console.log('er -> ', er);
      }
    },
  });

  const maxScheduledDate = resetTimeValue(
    dayjs().add(7, 'days').set('minutes', 0)
  );

  const maxRemovalDate = resetTimeValue(
    dayjs().add(14, 'days').set('minutes', 0)
  ).add(-1, 'hours');

  const disabledRemovalTimeMax = values.removalOn.isAfter(
    dayjs().add(13, 'days')
  )
    ? findDisabledMaxTime(maxRemovalDate)
    : emptyDisabledTime;

  const disabledTimeMax = values.scheduleAt.isAfter(
    values.removalOn.add(-1, 'days')
  )
    ? findDisabledMaxTime(values.removalOn.add(-1, 'hours'))
    : emptyDisabledTime;

  React.useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      {...rest}
      open={open}
      title={t(`snapshots.confirm.${serviceEntityName}.${action}.title`)}
      handleSubmit={handleSubmit}
      actions={
        <>
          <Button color="default" variant="outlined" onClick={props.onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className="mb-20 steel">
        {t(`snapshots.confirm.${serviceEntityName}.${action}.content`)}
      </div>

      <Switch
        className="mb-30"
        label={t('snapshots.confirm.scheduleSnapshot')}
        checked={values.scheduleSnapshot}
        onCheck={(scheduleSnapshot) =>
          setFieldValue('scheduleSnapshot', scheduleSnapshot)
        }
      />

      {values.scheduleSnapshot && (
        <SPickerWrapper className="mb-30">
          <DateTimePicker
            label={t('snapshots.form.startDate')}
            minimumDate={dayjs().toDate()}
            maximumDate={
              values.scheduleAt.isAfter(values.removalOn.add(-7, 'days'))
                ? values.removalOn.toDate()
                : maxScheduledDate.toDate()
            }
            value={values.scheduleAt}
            onChange={(scheduleAt) => {
              const val = scheduleAt.isAfter(values.removalOn)
                ? scheduleAt
                    .set('hours', values.removalOn.get('hours') - 1)
                    .set('minutes', 0)
                : scheduleAt;

              setFieldValue('scheduleAt', val);
            }}
            closeOnSelect
            minuteStep={15}
            showTime={true}
            showMinute={true}
            disabledTime={() => {
              return {
                disabledHours: disabledTimeMax.hours,
                disabledMinutes: disabledTimeMax.minutes,
              };
            }}
          />
        </SPickerWrapper>
      )}

      <SPickerWrapper className="mb-30">
        <DateTimePicker
          label={t('forms.removalDate')}
          minimumDate={dayjs().toDate()}
          maximumDate={
            values.scheduleSnapshot
              ? values.scheduleAt.add(14, 'days').toDate()
              : dayjs().add(14, 'days').set('minutes', 0).toDate()
          }
          value={values.removalOn}
          onChange={(removalOn) => {
            if (!removalOn.isAfter(values.scheduleAt)) {
              setFieldValue(
                'scheduleAt',
                resetTimeValue(dayjs())
                  .set('hours', dayjs().get('hours') + 1)
                  .set('minutes', 0)
              );
            }
            setFieldValue('removalOn', removalOn);
          }}
          closeOnSelect
          minuteStep={15}
          showTime={true}
          showMinute={true}
          disabledTime={() => {
            return {
              disabledHours: disabledRemovalTimeMax.hours,
              disabledMinutes: disabledRemovalTimeMax.minutes,
            };
          }}
        />
      </SPickerWrapper>

      <div className="mb-25">
        <Input
          label={t('forms.snapshotNotes')}
          name="description"
          placeholder="forms.placeholders.snapshotNotes"
          aria-autocomplete="none"
          autoComplete="off"
          inputProps={{
            autoComplete: 'off',
          }}
          value={values.description}
          onChange={handleChange}
          error={!!errors.description}
          helperText={errors.description}
          helperTextOptions={{ max: 100 }}
        />
      </div>
    </Dialog>
  );
};

export default SnapshotDialog;
