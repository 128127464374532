import React, { useCallback, useMemo } from 'react';
import { ALL_TEST_IDS, defaultRdsNavQuery } from 'enums';
import { getBadgeStateKey } from 'utils';
import { useTask, useUserHash, usePermissions } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { IAddServiceCallback } from 'services-shapes';
import { Attributes } from 'forwarded-ports';
import { withRdsActions, InjectedProps } from 'hocs/withRdsActions';
import { defineRdsDisplayStatus } from '../../../helpers';
import RemoteDesktopCard from '../contents/RemoteDesktop';
import CardHolder from '../CardHolder';

type IProps = InjectedProps & {
  onAdd: IAddServiceCallback;
  onOpenRS: (attrs: Attributes) => void;
  coreSetupCompleted: boolean;
};

type IViewProps = {
  core: StateHandlers.ICoreHandler;
  connectivity: StateHandlers.IConnectivityHandler;
  costBreakdown: StateHandlers.ICostBreakdownHandler;
  tenantPricing: StateHandlers.ITenantPricingHandler;
  assignments: StateHandlers.IBadgesAssignmentHandler;
};

const rdsNavParams = { ...defaultRdsNavQuery, orderType: 'asc' };

const View = observer((props: IProps & IViewProps) => {
  const {
    coreSetupCompleted,
    onAdd,
    menuItems,
    handlers,
    instance,
    core,
    costBreakdown,
    tenantPricing,
    assignments,
  } = props;
  const [, changeUrl] = useUserHash();
  const { permissions } = usePermissions('SERVICES');

  const entityAssignments =
    assignments.data[getBadgeStateKey(instance.data)] || [];

  const rdsData = instance.data;
  const coreData = core.data;

  const rdsHostVm = rdsData?.host?.hostInstances[0] || undefined;

  const _coreTask = useTask((coreData && coreData.task) || null);

  const fetchQuotas = useCallback(async () => {
    await tenantPricing.get();
    return costBreakdown.get();
  }, []);

  const task = useTask(rdsData && rdsData.task, {
    onComplete: () => {
      fetchQuotas();
      instance.get();
    },
  });
  const displayStatus = useMemo(
    () => defineRdsDisplayStatus(rdsData),
    [JSON.stringify(rdsData)]
  );
  const isAgentInitializing = displayStatus === 'AGENT_INIT';

  const onOpenDetails = React.useCallback(
    () => changeUrl(`/services/rds/accounts`, rdsNavParams),
    [rdsNavParams]
  );

  const isDisabled = instance.data
    ? instance.isRequesting ||
      !coreSetupCompleted ||
      task.isTaskActive ||
      isAgentInitializing
    : !coreSetupCompleted;

  return (
    <CardHolder
      createId="create-rds-button"
      onAdd={() => permissions.canManage && onAdd('rds')}
      placeholder="services.content.placeholder.remote"
      isEmpty={!rdsData}
      hasError={task.isFailed}
      status={displayStatus}
      title={'services.card.remote'}
      testId={ALL_TEST_IDS.services.remote.main}
      dataReceived={instance.dataReceived}
      isDisabled={isDisabled}
      assignments={entityAssignments}
      onTitleClick={onOpenDetails}
      menuItems={
        permissions.canManage
          ? rdsHostVm && !_coreTask.isTaskActive
            ? menuItems
            : []
          : [
              {
                value: 'openDetails',
                label: 'openDetails',
                onClick: handlers.openDetails,
              },
            ]
      }
    >
      <RemoteDesktopCard {...instance.data} />
    </CardHolder>
  );
});

const RdsCard = (props: IProps) => (
  <View
    {...props}
    connectivity={StateHandlers.connectivity}
    core={StateHandlers.core}
    costBreakdown={StateHandlers.costBreakdown}
    tenantPricing={StateHandlers.tenantPricing}
    assignments={StateHandlers.badgesAssignment}
  />
);

export default withRdsActions(RdsCard);
