import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { sortBy, prop } from 'ramda';
import {
  getPublicServicesColumns,
  VM_CREATE_PORTS_LIMIT,
} from 'pages/services/constants';
import { LinearTable } from 'elements';
import { showSystemMessage } from 'utils';
import AddPublicServiceForm from '../AddPublicServiceForm';

interface IProps {
  initialValues?: IVmTypes.VMPort[];
  ports: IVmTypes.VMPort[];
  onAdd: (ports: IVmTypes.NewVMPorts[]) => void;
  onDelete: (ports: IVmTypes.NewVMPorts[]) => void;
}

const PublicServices = (props: IProps) => {
  const { t } = useTranslation();

  const ports = sortBy(prop('port'))(props.ports || []);

  const onAdd =
    (createdPorts: IVmTypes.VMPort[]) => (port: IVmTypes.NewVMPorts) => {
      if (ports.length > VM_CREATE_PORTS_LIMIT) {
        return showSystemMessage(
          'services.dialog.vms.publicServices.limit',
          'error'
        );
      }

      const payload = [
        ...createdPorts,
        {
          id: v4(),
          ...port,
        },
      ];

      props.onAdd(payload);
    };

  const onDelete = React.useCallback(
    (port: IVmTypes.VMPort) =>
      props.onDelete(ports.filter((d) => d.id !== port.id)),
    [JSON.stringify(ports)]
  );

  return (
    <div>
      <AddPublicServiceForm onSave={onAdd(ports)} useAddButton />
      <div className="steel mb-20 fs-14">
        {t('services.dialog.vms.publicServices.text')}
      </div>
      <div className="mb-25">
        <LinearTable
          data={ports}
          cellClassName="fs-12"
          columns={getPublicServicesColumns({
            t,
            onDelete,
            isEditable: true,
            hideGeo: true,
            showNetwork: false,
          })}
          noDataMessage="services.dialog.vms.table.noPorts"
        />
      </div>
    </div>
  );
};

export default PublicServices;
