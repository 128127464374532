import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Dialog,
  DialogProps,
  Stepper,
  Step,
  StepLabel,
} from 'elements';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useStateHandler, useState, useAccount } from 'hooks';
import { remCalc, showSystemMessage } from 'utils';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import * as CONSTANTS from './constants';
import * as TYPES from './types';

type Props = DialogProps<any>;

type IState = {
  activeStep: number;
};
const Connector = () => null;

const PAPER_PROPS = { style: { maxWidth: remCalc(1040) } };

const ImportVmDialog = observer((props: Props) => {
  const { open, onSave, onClose } = props;
  const { t } = useTranslation();
  const [account] = useAccount();
  const vcd = useStateHandler(StateHandlers.vcd);
  const vcdVms = useStateHandler(StateHandlers.vcdVms);
  const vmSpec = useStateHandler(StateHandlers.vmSpec);
  const vms = useStateHandler(StateHandlers.vms);

  const importVm = useCallback(vms.executeRequest('importVm'), []);

  const [state, handleState] = useState<IState>({
    activeStep: 0,
  });

  const isLastStep = state.activeStep === 2;

  const onSubmit = React.useCallback(
    async (data: TYPES.IFormValues) => {
      if (state.activeStep === 0) {
        handleState({ activeStep: 1 });
      }

      if (state.activeStep === 1) {
        handleState({ activeStep: 2 });
      }

      if (state.activeStep === 2) {
        const payload: IVmTypes.IVmImportPayload = {
          billingCycleInterval: data.billingCycle?.value || 'NONE',
          isUsageEnabled: !!data.isUsageEnabled,
          isGuestCustomizationEnabled: !!data.isGuestCustomizationEnabled,
          name: data.vm?.name || '',
          templateId: data.templateId?.value as number,
          tenantId: account?.tenant?.id as number,
          vcdVmId: data.vm?.id as string,
          networkType: data.isPublic ? 'PUBLIC' : 'PRIVATE',
        };

        try {
          await importVm(payload);
          return onSave && onSave(payload);
        } catch (er: any) {
          showSystemMessage(er.message, 'error');
        }
      }
    },
    [state.activeStep, onSave, account?.tenant?.id]
  );

  const form = useFormik({
    initialValues: CONSTANTS.InitialFormValues,
    validateOnMount: false,
    validationSchema: CONSTANTS.DefineValidationSchema({
      tab: state.activeStep,
    }),
    onSubmit,
  });

  React.useEffect(() => {
    if (!open) {
      vcd.reset();
      vcdVms.reset();
      vmSpec.reset();
      handleState({ activeStep: 0 });
      form.resetForm();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      title={t(`services.vmList.dialog.importVm.title`)}
      PaperProps={PAPER_PROPS}
      fullWidth
      maxWidth="md"
      handleSubmit={form.handleSubmit}
      onClose={onClose}
      actions={
        <Row justifyContent="space-between" columnSpacing={3} rowSpacing={2}>
          <Col>
            <Button variant="outlined" color="default" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Row justifyContent="flex-end" columnSpacing={2}>
              {state.activeStep !== 0 && (
                <Col>
                  <Button
                    onClick={() => {
                      handleState({ activeStep: state.activeStep - 1 });
                    }}
                    variant="outlined"
                    color="default"
                  >
                    {t('common.previous')}
                  </Button>
                </Col>
              )}
              <Col>
                <Button type="submit" disabled={form.isSubmitting}>
                  {t(isLastStep ? 'common.save' : 'common.next')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <div className="mb-30">
        <Stepper
          activeStep={state.activeStep}
          classes={{ root: '' }}
          connector={<Connector />}
        >
          {CONSTANTS.ImportVmSteps.map((label) => {
            return (
              <Step key={label} className="mr-50">
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {state.activeStep === 0 && (
        <FirstStep
          {...form}
          onSelect={(vcd) => form.setFieldValue('vcd', vcd)}
        />
      )}
      {state.activeStep === 1 && (
        <SecondStep {...form} onSelect={(vm) => form.setFieldValue('vm', vm)} />
      )}
      {state.activeStep === 2 && <ThirdStep {...form} />}
    </Dialog>
  );
});

export default ImportVmDialog;
