import * as React from 'react';
import {
  Button,
  Dialog,
  DialogProps,
  Input,
  InputPhone,
  MuiIcons,
  Tooltip,
} from 'elements';
import { LinearTabButton, LinearTabs } from 'components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import { useState, useStateHandler } from 'hooks';
import * as StateHandlers from 'states';
import { customerInvoiceValidationSchema } from 'pages/settings/constants';
import { parseCustomerInvoiceSettingsForForm } from 'pages/settings/helpers';

type IProps = DialogProps<IPartnerTypes.ICustomerInvoiceValues> & {
  isNew: boolean;
};

type IState = {
  schema: any;
  tab: 'swizz' | 'other';
};

const CustomerInvoice = observer((props: IProps) => {
  const { open, onClose, onSave, isNew } = props;
  const { t } = useTranslation();
  const currentUser = useStateHandler(StateHandlers.currentUser);
  const [state, handleState] = useState<IState>({
    schema: customerInvoiceValidationSchema,
    tab: 'swizz',
  });

  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    submitCount,
    handleSubmit,
    isSubmitting,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: parseCustomerInvoiceSettingsForForm(
      currentUser.data?.partner as IPartnerTypes.Partner
    ),
    validationSchema: customerInvoiceValidationSchema,
    onSubmit: onSave as any,
  });

  React.useEffect(() => {
    if (open) {
      setValues(
        parseCustomerInvoiceSettingsForForm(
          currentUser.data?.partner as IPartnerTypes.Partner
        )
      );
    } else {
      handleState({ tab: 'swizz' });
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      title={t(
        `settings.page.customer-invoice.dialog.${isNew ? 'add' : 'edit'}.title`
      )}
      open={open}
      handleSubmit={handleSubmit}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} variant="outlined" color="default">
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className="steel mb-30">
        {t('settings.page.customer-invoice.dialog.edit.content') as string}
      </div>
      <div className="fs-17 bolder mb-25">
        {t('settings.page.customer-invoice.dialog.sections.contact')}
      </div>

      <Input
        label={t('forms.email')}
        value={values.email}
        name="email"
        placeholder={t('forms.placeholders.email')}
        className="mb-25"
        onChange={handleChange}
        helperText={errors.email}
        helperTextOptions={{ max: 60 }}
        error={!!submitCount && !!errors.email}
      />
      <InputPhone
        label={t('forms.phone')}
        placeholder="+0000000000"
        value={values.phone}
        name="phone"
        className="mb-35"
        onChange={(val) => {
          setFieldValue('phone', val);
        }}
        helperText={errors.phone}
        error={!!submitCount && !!errors.phone}
      />

      <div className="fs-17 bolder">
        {t('settings.page.customer-invoice.dialog.sections.bank')}
      </div>

      <LinearTabs>
        <LinearTabButton
          isActive={state.tab === 'swizz'}
          onClick={() => handleState({ tab: 'swizz' })}
        >
          {t('settings.page.customer-invoice.dialog.tabs.swiz')}
        </LinearTabButton>
        <LinearTabButton
          isActive={state.tab === 'other'}
          onClick={() => handleState({ tab: 'other' })}
        >
          {t('settings.page.customer-invoice.dialog.tabs.other')}
        </LinearTabButton>
      </LinearTabs>

      {state.tab === 'swizz' && (
        <>
          <Input
            label={t('forms.vatId')}
            placeholder="CHE-123.456.789 MWST"
            value={values.swizzVatId}
            name="swizzVatId"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.swizzVatId}
            error={!!submitCount && !!errors.swizzVatId}
          />
          <Input
            label={t('forms.ibanQR')}
            placeholder="CH00 0000 0000 0000 0000 0"
            labelIcon={
              <Tooltip
                title={
                  t('settings.page.customer-invoice.tooltip.iban') as string
                }
                placement="top"
              >
                <sup>
                  <MuiIcons.HelpOutline className="fs-12 info ml-5" />
                </sup>
              </Tooltip>
            }
            value={values.swizzIban}
            name="swizzIban"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.swizzIban}
            error={!!submitCount && !!errors.swizzIban}
          />
        </>
      )}

      {state.tab === 'other' && (
        <>
          <Input
            label={t('forms.iban')}
            placeholder="CH00 0000 0000 0000 0000 0"
            labelIcon={
              <Tooltip
                title={
                  t('settings.page.customer-invoice.tooltip.iban') as string
                }
                placement="top"
              >
                <sup>
                  <MuiIcons.HelpOutline className="fs-12 info ml-5" />
                </sup>
              </Tooltip>
            }
            value={values.otherIban}
            name="otherIban"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.otherIban}
            error={!!submitCount && !!errors.otherIban}
          />

          <Input
            label={t('forms.bankAddress')}
            placeholder="forms.placeholders.bankAddress"
            multiline
            value={values.otherBankDetails}
            name="otherBankDetails"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.otherBankDetails}
            error={!!submitCount && !!errors.otherBankDetails}
            helperTextOptions={{
              max: 200,
            }}
          />

          <Input
            label={t('forms.bankAccountNumber')}
            placeholder="forms.placeholders.bankAccountNumber"
            multiline
            value={values.otherBankAccount}
            name="otherBankAccount"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.otherBankAccount}
            error={!!submitCount && !!errors.otherBankAccount}
            helperTextOptions={{
              max: 30,
            }}
          />

          <Input
            label={t('forms.swift.bic')}
            placeholder="forms.placeholders.swift.bic"
            value={values.otherBankIdentificationCode}
            name="otherBankIdentificationCode"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.otherBankIdentificationCode}
            error={!!submitCount && !!errors.otherBankIdentificationCode}
            helperTextOptions={{
              max: 11,
              min: 8,
            }}
          />
        </>
      )}
    </Dialog>
  );
});

export default CustomerInvoice;
