import * as React from 'react';
import { taskSubscriber, reformatTaskKey, reformatTask } from 'utils';
import { IActualTaskState, ITaskCallbacks, ITask } from 'task-manager-service';
import * as StateHandlers from 'states';
import { useStateHandler } from './instanceHook';
import { useState } from './useState';
import { usePrevious } from './usePrevious';

export function useTasks<T extends Task[]>(tasks: T): IActualTaskState[] {
  const taskMap = useStateHandler(StateHandlers.taskManager);

  return tasks.map((t) => {
    const finalTask = t ? taskMap.data[reformatTaskKey(t)] || t : {};
    // @ts-ignore
    finalTask.operationName = finalTask.operationName || '';
    // @ts-ignore
    return reformatTask(finalTask);
  });
}

const DEFAULT_EMPTY_TASK: Task = {
  createdAt: '',
  entityId: 0,
  entityName: '' as IAllServicesType,
  id: 0,
  isBackground: true,
  message: '',
  operationName: '' as any,
  parentTaskId: null,
  status: 'none',
  updatedAt: '',
};

export function useTask<T extends ITask, C extends ITaskCallbacks>(
  _task?: T | null,
  callbacks?: C
): IActualTaskState {
  const [task, changeTask] = useState<Task>({
    ...DEFAULT_EMPTY_TASK,
    ...(_task || {}),
  });

  const prevTaskId = usePrevious(task?.id);

  React.useEffect(() => {
    if (_task) {
      _task.operationName = _task.operationName || '';

      changeTask(_task);

      taskSubscriber.register(_task, callbacks);
    } else {
      changeTask(DEFAULT_EMPTY_TASK);
      taskSubscriber.unsubscribe(prevTaskId || '0');
    }
  }, [JSON.stringify(_task)]);

  return reformatTask(task);
}
