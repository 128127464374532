import { POST, PUT } from 'utils/fetcher/axiosWrappers';
import { Session } from 'sessions';
import { AxiosPromise } from 'axios';
import {
  ISignupPartnerBodyDto,
  IUserSessionDto,
  IMySofInfo,
} from 'auth-shapes';
import { publicIpv4 } from 'public-ip';

interface IFraudResponse {
  score: number;
  id: string;
  label: 'DECLINED' | 'APPROVED' | 'REVIEWING';
}

export const signUp = (
  data: ISignupPartnerBodyDto
): AxiosPromise<IPartnerTypes.Partner> => POST('/partners/signup', {}, data);

export const login = (data: object): AxiosPromise<IUserSessionDto> =>
  POST('/auth/login', {}, data);

export const verifyAccount = (
  token: string,
  headers: any
): AxiosPromise<IUserSessionDto> =>
  PUT('/auth/verify', {}, { token }, { headers, shouldUpdate: true });

export const restorePassword = (email: string): AxiosPromise =>
  POST('/auth/password', {}, { email });

export const verifyAccountPassword = (
  password: string,
  token: string,
  config?: object
): AxiosPromise<IUserSessionDto> =>
  PUT('/auth/password', {}, { token, password }, config);

export const resendVerificationLink = (email: string): AxiosPromise =>
  PUT('/auth/verify/resend', {}, { email });

export const verifyCode = (
  twoFactorAuthToken: string,
  code: string,
  config?: object
): AxiosPromise<Session> =>
  POST('/auth/2fa/verify', {}, { twoFactorAuthToken, code }, config);

export const restore2fa = (email: string): AxiosPromise =>
  PUT('/auth/2fa', {}, { email });

export const verifyEmail = (token: string, config?: object): AxiosPromise =>
  POST('/auth/verify-email', {}, { token }, config);

export const logInUnderPartner = (
  id: number | string,
  tkn: string
): AxiosPromise =>
  POST(`/auth/partner/${id}/session`, {}, {}, { shouldUpdate: true });

export const logInUnderTenant = (
  id: number | string,
  tkn: string
): AxiosPromise =>
  POST(`/auth/tenant/${id}/session`, {}, {}, { shouldUpdate: true });

export const checkValidEmail = (email: string): AxiosPromise<IFraudResponse> =>
  POST('/fraud-meta', {}, { email });

export const getCurrentIp = (): Promise<IMySofInfo> =>
  publicIpv4().then((ip) => ({ ip }));
