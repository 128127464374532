import * as React from 'react';
import * as R from 'ramda';
import {
  Input,
  IconButton,
  MuiIcons,
  AddIcon,
  Row,
  Col,
  Alert,
  Select,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { compact, validator } from 'utils';
import * as ENUMS from '../constants';
import { PORT_TYPES_OPTIONS_SHORT } from '../../services/constants';

type IProps = ReturnType<
  typeof useFormik<IConnectivityTypes.IFirewallRuleFormValues>
>;

const trimAll = (val?: string) => {
  return val
    ?.trim()
    .split(',')
    .map((v) => v.trim())
    .join(',');
};

const PortsForm = (props: IProps) => {
  const { t } = useTranslation();

  const ports = props.values.ports;

  const onAddPort = React.useCallback(async () => {
    const errors: AnyShape = {};

    const sourcePorts = trimAll(props.values.sourcePorts);
    const destinationPorts = trimAll(props.values.destinationPorts);

    const isPortValid = validator.numberRangesWithComma(sourcePorts || '');

    const isDestPortValid = validator.numberRangesWithComma(
      destinationPorts || ''
    );

    if (!isPortValid) {
      errors.sourcePorts = 'forms.invalid';
    }

    if (!isDestPortValid) {
      errors.destinationPorts = 'forms.invalid';
    }

    if (!R.isEmpty(errors)) {
      return props.setErrors(errors);
    }

    const newPort = {
      sourcePorts,
      destinationPorts,
      protocol: props.values.protocol,
    };

    props.setValues({
      ...props.values,
      ports: [newPort, ...ports],
      protocol: PORT_TYPES_OPTIONS_SHORT[0],
      sourcePorts: '',
      destinationPorts: '',
    });
  }, [JSON.stringify(props.values), ports]);

  const onDelete = React.useCallback(
    (ind: number) => {
      props.setFieldValue(
        'ports',
        ports.filter((el, _ind) => ind !== _ind)
      );
    },
    [ports]
  );

  return (
    <>
      <Row className="mb-25" columnSpacing={2} alignItems="flex-end">
        <Col xs={3}>
          <Select
            className="full-width"
            name="protocol"
            label="forms.type"
            value={props.values.protocol}
            onChange={(protocol) => props.setFieldValue('protocol', protocol)}
            options={PORT_TYPES_OPTIONS_SHORT}
          />
        </Col>
        <Col xs={3}>
          <Input
            name="sourcePorts"
            label="forms.port"
            value={props.values.sourcePorts}
            onChange={props.handleChange}
            error={!!props.errors.sourcePorts}
            helperText={props.errors.sourcePorts}
          />
        </Col>

        <Col xs={3}>
          <Input
            name="destinationPorts"
            label="forms.destinationPort"
            value={props.values.destinationPorts}
            onChange={props.handleChange}
            error={!!props.errors.destinationPorts}
            helperText={props.errors.destinationPorts}
          />
        </Col>

        <Col xs={3}>
          <AddIcon className="mt-25" onClick={onAddPort} />
        </Col>
      </Row>

      <div style={ENUMS.FWR_STYLES.tab}>
        <div className="steel fs-12 mb-10">{t('forms.ports')}</div>
        <div className="mb-20">
          {!ports.length ? (
            <div className="fs-14 steel">{t('common.noData')}</div>
          ) : (
            ports.map((p, ind) => {
              const sourceText = compact([
                p.sourcePorts,
                compact(
                  p.destinationPorts
                    ?.split(',')
                    .map((p) => (p ? `(${p.trim()})` : '')) || []
                ),
                t(p.protocol?.label),
              ]).join(', ');

              return (
                <Row
                  key={p.protocol?.value + ind}
                  alignItems="center"
                  justifyContent="space-between"
                  className="mb-5"
                >
                  <Col className="steel fs-14">{sourceText}</Col>
                  <Col>
                    <IconButton size="small" onClick={() => onDelete(ind)}>
                      <MuiIcons.Delete className="fs-18" />
                    </IconButton>
                  </Col>
                </Row>
              );
            })
          )}
        </div>
        {!!props.submitCount && !!props.errors.ports && (
          <Alert severity="error" className="mb-20">
            {t(props.errors.ports as string)}
          </Alert>
        )}
      </div>
    </>
  );
};

export default PortsForm;
