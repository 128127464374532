import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { INewBadgePayload } from 'badges';
import { Paper, Row, Col, Button, TablePagination } from 'elements';
import { useState, useQuery } from 'hooks';
import { confirm } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import BadgeItem from './BadgeItem';
import { INITIAL_STATE } from './constants';
import BadgeDetailsDialog from './BadgeDetailsDialog';

const OBSERVERS = {
  badges: StateHandlers.badges,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { badges } = props;
  const { t } = useTranslation();
  const { query, changeQuery, queryStr } = useQuery();
  const [state, handleState] = useState<IBadgeTypes.IState>(INITIAL_STATE);

  const isNew = !state.selected;
  const showPagination = badges.meta.totalCount > query.perPage;

  const handleOpenDialog = React.useCallback(
    (detailsDialogOpen: boolean, badge?: IBadge) => () =>
      handleState({ detailsDialogOpen, selected: badge || undefined }),
    []
  );

  const onSave = React.useCallback(
    (payload: INewBadgePayload) =>
      badges
        .create(payload)
        .then(() => badges.get(query))
        .then(handleOpenDialog(false)),
    [queryStr]
  );

  const onEdit = React.useCallback(
    (payload: INewBadgePayload) =>
      badges
        .update(state.selected?.id, payload)
        .then(() => badges.get(query))
        .then(handleOpenDialog(false)),
    [state.selected, queryStr]
  );

  const confirmDelete = React.useCallback(
    (badge: IBadge) =>
      confirm({
        title: t('badges.confirm.delete.title'),
        content: t('badges.confirm.delete.content'),
        onSuccess: () => badges.remove(badge.id).then(() => badges.get(query)),
        onCancel: () => undefined,
      }),
    [queryStr]
  );

  React.useEffect(() => {
    badges.get(query);
  }, [queryStr]);

  return (
    <Paper className="pt-20 pb-40 pl-40 pr-40">
      <Row
        columnSpacing={2}
        alignItems="center"
        justifyContent="space-between"
        className="mb-20"
      >
        <Col>
          <h3>{t('badges.title')}</h3>
        </Col>
        <Col>
          <Button onClick={handleOpenDialog(true, undefined)}>
            {t('badges.buttons.add')}
          </Button>
        </Col>
      </Row>
      <p className="mb-40">{t('badges.content')}</p>

      <div
        className={cn({
          disabled: badges.isRequesting || !badges.dataReceived,
        })}
      >
        {badges.data.length ? (
          badges.data.map((badge) => {
            return (
              <BadgeItem
                key={badge.id}
                {...badge}
                onEdit={handleOpenDialog(true, badge)}
                onDelete={confirmDelete}
              />
            );
          })
        ) : (
          <div className="steel fs-14">{t('common.noData')}</div>
        )}
      </div>

      {showPagination && (
        <TablePagination
          totalCount={badges.meta.totalCount}
          onChange={({ page }) => changeQuery({ page })}
        />
      )}

      <BadgeDetailsDialog
        open={state.detailsDialogOpen}
        onClose={handleOpenDialog(false)}
        initialValues={state.selected}
        onSave={isNew ? onSave : onEdit}
        isRequesting={badges.isRequesting}
      />
    </Paper>
  );
});

const Main = () => <View {...OBSERVERS} />;

export default Main;
