import { IFileShare, IFileShareFormValues, INewFileShare } from 'file-shares';
import { PERMISSIONS_MAP_REVERSE } from './constants';
import copy from 'copy-to-clipboard';
import { showSystemMessage } from 'utils';
import dayjs from 'dayjs';

export const reformatFileSharePayload = (
  data: IFileShareFormValues
): INewFileShare => {
  return {
    name: data.name.trim(),
    // @ts-ignore
    driveLetter: data.driveLetter?.value,
    // @ts-ignore
    users: Object.keys(data.userPermissions).map((key) => ({
      coreInfraUserId: +key,
      // @ts-ignore
      permission: PERMISSIONS_MAP_REVERSE[data.userPermissions[key]],
    })),
  };
};

export const copyMECommand = (currentFileShare?: IFileShare) => {
  const commandLine = (() => {
    if (currentFileShare) {
      const localSourcePath = currentFileShare.migrationEndpoint?.localSourcePath.replace(
        '\\\\',
        ''
      );
      return `# Execute in elevated PowerShell prompt\n \n# Authenticate to remote file share\nnet use \\\\${currentFileShare.migrationEndpoint?.fileSharePublicIp}\\Shares$\\${currentFileShare.name}\n\n# Copy local source path contents to remote share\nrobocopy \\\\${localSourcePath} \\\\${currentFileShare.migrationEndpoint?.fileSharePublicIp}\\Shares$\\${currentFileShare.name} /E /PURGE /ZB /R:3 /W:3 /MT:16 /XJ /DCOPY:T /V /NP /XD '$RECYCLE.BIN' 'System Volume Information' /LOG:$env:TEMP\\${currentFileShare.name}-$(get-date -f yyyy-MM-dd-hh-mm).log`;
    }
    return '';
  })();

  const isCopied = copy(commandLine, {
    format: 'text/plain',
  });
  isCopied && showSystemMessage('common.copiedToClipboard', 'success');
};

export const getExpirationText = (createdAt: string, expireIn: number) => {
  const toTime = dayjs(createdAt).add(expireIn, 'seconds');

  return toTime.format('DD.MM.YYYY HH:mm');
};
