import React, { useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { useAccount, useForceUpdate, useState, useStateHandler } from 'hooks';
import {
  LinearTable,
  Radio,
  Input,
  Chip,
  IconDirectNetworkMonitor,
  IconIsolatedNetworkMonitor,
  IconNatRoutedNetworkMonitor,
  createColumnHelper,
} from 'elements';
import { NETWORK_STATUS_MAP, NETWORKS_DEFAULT_PARAMS } from 'enums';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { debounce, noop } from 'utils';
import * as TYPES from './types';

type IConfig = {
  t: any;
  selected?: IConnectivityTypes.INetwork;
  onSelect: (net: IConnectivityTypes.INetwork) => void;
};

type IQuery = {
  orderType: string;
  orderBy: string;
  q?: string;
  page: number;
  perPage: number;
  type: IConnectivityTypes.INetworkType[] | string[];
};

const column = createColumnHelper<IConnectivityTypes.INetwork>();

export const makeColumns = (props: IConfig) => [
  column.accessor('name', {
    header: (
      <span className="fs-12 steel weight-normal pl-50">
        {props.t('table.head.networkName')}
      </span>
    ),
    disableSort: true,
    cell: ({
      row: { original: network },
    }: ICell<IConnectivityTypes.INetwork>) => {
      let icon = <IconDirectNetworkMonitor status="primary" onClick={noop} />;

      if (network.type === 'DIRECT') {
        icon = <IconDirectNetworkMonitor status="primary" onClick={noop} />;
      }
      if (network.type === 'ISOLATED') {
        icon = <IconIsolatedNetworkMonitor status="primary" onClick={noop} />;
      }
      if (network.type === 'NAT_ROUTED') {
        icon = <IconNatRoutedNetworkMonitor status="primary" onClick={noop} />;
      }

      const isSelected = props.selected?.id === network.id;
      return (
        <div className="ml-5">
          <Radio
            label={
              <div className="flex align-center ml-15">
                {icon}
                <span className="pl-10">{network.name}</span>
              </div>
            }
            checked={isSelected}
            onChange={() => props.onSelect(network)}
          />
        </div>
      );
    },
  }),
  column.accessor('subnets', {
    header: (
      <span className="fs-12 steel weight-normal">
        {props.t('table.head.ipAddress')}
      </span>
    ),
    alignment: 'right',
    disableSort: true,
    minWidth: 0,
    size: 150,
    cell: ({
      row: { original: network },
    }: ICell<IConnectivityTypes.INetwork>) =>
      network.subnets[network.defaultIpv4SubnetIndex]?.cidr,
  }),

  column.accessor('vcdStatus', {
    header: (
      <span className="fs-12 steel weight-normal">
        {props.t('table.head.status')}
      </span>
    ),
    alignment: 'right',
    disableSort: true,
    minWidth: 0,
    size: 150,
    cell: ({ row }: ICell<IConnectivityTypes.INetwork>) => {
      const status = NETWORK_STATUS_MAP[row.original.vcdStatus];
      return (
        <Chip status={status}>
          {props.t(
            `services.connectivity.networks.status.${row.original.vcdStatus}`
          )}
        </Chip>
      );
    },
  }),
];

type IProps = FormikProps<TYPES.IFormValues> & {
  onSelect: (net: IConnectivityTypes.INetwork) => void;
  assignedNetworks: number[];
};

const FirstStep = observer((props: IProps) => {
  const { t } = useTranslation();
  const [account] = useAccount();
  const forceUpdate = useForceUpdate();
  const networks = useStateHandler(StateHandlers.connectivityNetworks);
  const searchRef = React.useRef({ value: '' });
  const [query, handleQuery] = useState<IQuery>(NETWORKS_DEFAULT_PARAMS);

  React.useEffect(() => {
    searchRef.current.value = query.q || '';
    forceUpdate();
  }, [query.q]);

  const changeSearchUrl = React.useCallback(
    (q: string) => handleQuery({ ...query, q, page: 1 }),
    [query]
  );

  const onItemSearch = React.useCallback(
    debounce(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        changeSearchUrl(ev.target.value),
      1000
    ),
    [query]
  );

  const columns = React.useMemo(
    () =>
      makeColumns({
        t,
        onSelect: props.onSelect,
        selected: props.values.network,
      }),
    [props.onSelect]
  );

  useEffect(() => {
    networks.get(query);
  }, [JSON.stringify(query), account?.partner?.id]);

  return (
    <div>
      <div className="mb-25">
        <Input
          ref={searchRef}
          type="search"
          placeholder={t('forms.placeholders.search')}
          className="bg-white"
          onKeyUp={(e: any) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              changeSearchUrl(e.target.value);
            }
          }}
          onClear={() => {
            searchRef.current.value = '';
            changeSearchUrl('');
          }}
          onChange={onItemSearch}
        />
      </div>
      <LinearTable
        className={cn({ disabled: networks.isRequesting })}
        data={networks.data}
        columns={columns}
        query={query}
        params={networks.meta}
        isLoading={!networks.dataReceived}
        hasSorting
        onSort={(orderBy, orderType) => handleQuery({ orderBy, orderType })}
        onPageChange={(newQuery) => handleQuery({ page: newQuery.page })}
        onUpdateTable={() => networks.get(query)}
        customizeRow={(net) =>
          cn({ disabled: props.assignedNetworks.includes(net?.id) })
        }
        usePagination
        shouldUseQuery={false}
      />
    </div>
  );
});

export default FirstStep;
