import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { filter } from 'ramda';
import qs from 'query-string';
import { SortIcon } from 'components';
import {
  Row,
  Col,
  Input,
  Select,
  Loader,
  TablePagination,
  Button,
  Paper,
} from 'elements';
import { LinearTabs, LinearTabButton } from 'components';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  confirm,
  noop,
  showSystemMessage,
  debounce,
  numberToCurrency,
  openInNewTab,
} from 'utils';
import { DEFAULT_INVOICES_PARAMS, DEFAULT_CUSTOMERS_QUERY } from 'enums';
import { invoicesService } from 'services';
import {
  useAsync,
  useForceUpdate,
  useQuery,
  useUserHash,
  useState,
  useAccount,
} from 'hooks';
import { IInvoice, IPayStatuses, IInvoicePosition } from 'invoices';
import { ValueType } from 'global-shapes';
import { InjectedPermissionsProps } from 'hocs';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import InvoiceListItem from './InvoiceListItem';
import PositionDialog from './Positions/PositionDialog';
import AddPaymentDialog from './AddPaymentDialog';
import InvoiceDialog from './InvoiceDialog';
import ImportPaymentsButton from './ImportPaymentsButton';
import ExportInvoicesButton from './ExportInvoicesButton';
import IncomeStatistics from './IncomeStatistics';
import { statusOptions, INITIAL_STATE, TABS } from './constants';
import { parsePositionToForm } from './helpers';
import { IInvoiceTypes } from './types';
import { MainPage, SStatusSelect, ClearButton } from './Styled';

interface IState {
  dialog: null | 'position' | 'payment' | 'invoice';
  expanded: null | number;
  selectedPosition: IInvoiceTypes.PositionState | null;
  selectedTab: string;
  selectedInvoice: IInvoice | null;
}

const { cancelInvoice, unCancelInvoice } = invoicesService;

const findStatusValues = (status: string) => (el: ValueType) =>
  status.includes(el.value);

type Props = InjectedPermissionsProps & {
  invoices: StateHandlers.IInvoicesHandler;
  payments: StateHandlers.IInvoicePaymentsHandler;
  positions: StateHandlers.IInvoicePositionsHandler;
  statistic: StateHandlers.IInvoiceStatiticHandler;
  incomeStatistic: StateHandlers.IInvoiceIncomeStatiticHandler;
  stripe: StateHandlers.IStripeHandler;
};

const View = observer((props: Props) => {
  const {
    permissions: _permissions,
    isServerBase,
    currentAccessLevel,
    accessLevel,
    isCurrentPartner,
    invoices,
    payments,
    positions,
    statistic,
    incomeStatistic,
    stripe,
  } = props;

  const { t } = useTranslation();
  const location = useLocation();
  const [hash] = useUserHash();
  const [account] = useAccount();
  const forceUpdate = useForceUpdate();
  const { query, queryStr, changeQuery } = useQuery();
  const searchRef = useRef({ value: query.q || '' });

  const { execute: handleCancelInvoice, isPending: isCancelPending } = useAsync(
    // @ts-ignore
    (cancel: boolean, invoiceId: number) => {
      const _request = cancel ? cancelInvoice : unCancelInvoice;
      return _request(invoiceId)
        .then((res) => {
          invoices.reload(invoiceId);
          return res;
        })
        .catch((err) => showSystemMessage(err.message, 'error'));
    }
  );

  const isRequesting =
    payments.isRequesting ||
    positions.isRequesting ||
    invoices.isRequesting ||
    isCancelPending;

  const [state, handleChange] = useState<IState>({
    ...INITIAL_STATE,
    selectedTab: query.inittab || 'base',
    expanded: +query.initexp || null,
  });

  const isOwnInvoices = state.selectedTab === 'own';
  const isTenantAccount = accessLevel === 'tenant';
  const showTabs = currentAccessLevel === 'partner';
  const pretendedLevel = isOwnInvoices ? 'tenant' : currentAccessLevel;
  const isTenant = pretendedLevel === 'tenant';
  const stripeEnabled =
    account?.partner?.invoiceSettings?.stripePaymentsEnabled;
  const shouldShowUserName = !isOwnInvoices ? !isTenant : false;

  const canManage = React.useMemo(() => {
    if (isTenantAccount) return false;
    if (showTabs && isOwnInvoices) {
      return false;
    }

    return _permissions.canManage;
  }, [_permissions.canManage, isTenantAccount]);

  const isAbleToCreate = React.useMemo(() => {
    if (showTabs && isOwnInvoices) {
      return false;
    }

    return _permissions.canManage;
  }, [_permissions.canManage, showTabs, isOwnInvoices]);

  const permissions = {
    ..._permissions,
    canManage,
  };

  const unpaidInvoices = +(statistic.data?.subtotalSumPerStatus?.UNPAID || 0);
  const overdueInvoices = +(statistic.data?.subtotalSumPerStatus?.OVERDUE || 0);

  const changeSearchUrl = React.useCallback(
    (q?: string) => changeQuery({ ...query, q, page: 1 }),
    [queryStr]
  );

  const userSource = React.useMemo(() => {
    if (currentAccessLevel === 'provider') return 'partner';
    return 'tenant';
  }, [currentAccessLevel]);

  const getInvoices = React.useCallback(
    (q: any) => {
      return invoices.get({
        ...q,
        requestType: !isCurrentPartner ? 'base' : state.selectedTab,
      });
    },
    [userSource, queryStr, isCurrentPartner, state.selectedTab]
  );

  const onInvoiceSearch = React.useCallback(
    debounce(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        changeSearchUrl(ev.target.value),
      1000
    ),
    [queryStr]
  );

  React.useEffect(() => {
    resetState();
    getInvoices({
      ...query,
      q: searchRef.current?.value,
    });
    statistic.get();
    if (currentAccessLevel !== 'tenant') {
      incomeStatistic.get();
    }
  }, [
    queryStr,
    searchRef.current?.value,
    state.selectedTab,
    currentAccessLevel,
    isCurrentPartner,
  ]);

  const resetState = React.useCallback(() => {
    payments.reset();
    positions.reset();
    handleChange({ expanded: null });
  }, []);

  const getInvoiceDetails = React.useCallback(
    (id: number) => {
      if (state.expanded === id) {
        return resetState();
      }
      positions.get({ id });
      payments.get({ id });
      handleChange({ expanded: id });
    },
    [state.expanded]
  );

  const handleDialog = React.useCallback(
    (dialog: IState['dialog']) => () => {
      const restValues: Partial<IState> = {};
      if (!dialog) {
        restValues.selectedPosition = null;
        restValues.selectedInvoice = null;
      }
      handleChange({ dialog, ...restValues });
    },
    []
  );

  const onStatusChange = React.useCallback(
    (val: ValueType<IPayStatuses>[]) =>
      changeQuery({
        ...query,
        status: val.map((el) => el.value),
        page: 1,
        q: searchRef.current?.value,
      }),
    [queryStr, query, searchRef.current?.value]
  );

  const onCreateInvoice = React.useCallback(
    async (values: IInvoiceTypes.NewInvoice) => {
      const userKey =
        currentAccessLevel === 'provider' ? 'partnerId' : 'tenantId';
      await invoices.create({
        [userKey]: values.userId?.value,
        date: values.date.startOf('day').format('YYYY-MM-DD'),
        dueDate: values.dueDate.endOf('day').format('YYYY-MM-DD'),
        autoSend: values.autoSend,
      });
      await getInvoices(query);
      handleChange({ dialog: null, selectedInvoice: undefined });
    },
    [queryStr, currentAccessLevel]
  );

  const onEditInvoice = React.useCallback(
    async (values: IInvoiceTypes.NewInvoice) => {
      await invoices.update(state.selectedInvoice?.id, {
        dueDate: values.dueDate.endOf('day').format('YYYY-MM-DD'),
      });
      await getInvoices(query);
      handleChange({ dialog: null, selectedInvoice: undefined });
    },
    [queryStr, currentAccessLevel, state.selectedInvoice]
  );

  const onCreatePosition = React.useCallback(
    (values: IInvoiceTypes.PositionState) =>
      positions
        .create({
          values,
          invoiceId: state.expanded,
        })
        .then(() => {
          handleChange({ dialog: null, selectedPosition: null });
          invoices.reload(state.expanded as number);
          positions.get({ id: state.expanded });
        }),
    [state.expanded]
  );

  const onUpdatePosition = React.useCallback(
    async (values: IInvoiceTypes.PositionState) => {
      const { invoiceId, id, ...rest } = values;
      await positions.update({ invoiceId, invoiceItemId: id }, rest);
      handleChange({ dialog: null, selectedPosition: null });
      invoices.reload(state.expanded as number);
      positions.get({ id: invoiceId });
    },
    [state.expanded]
  );

  const onDeletePosition = React.useCallback(
    (pos: IInvoicePosition, invoiceId: number) => {
      confirm({
        title: t('invoices.current.confirm.position.delete.title'),
        content: t('invoices.current.confirm.position.delete.content'),
        onSuccess: async () => {
          await positions.remove({ invoiceItemId: pos.id, invoiceId });
          await invoices.reload(invoiceId);
          return positions.get({ id: invoiceId });
        },
        onCancel: noop,
      });
    },
    [state.expanded]
  );

  const onEditPosition = React.useCallback(
    (invoiceId: number, position: IInvoicePosition) => {
      handleChange({
        dialog: 'position',
        selectedPosition: {
          invoiceId,
          ...parsePositionToForm(position),
        },
      });
    },
    [state.expanded]
  );

  const onCreatePayment = React.useCallback(
    (values: IInvoiceTypes.PaymentState) =>
      payments
        .create({
          values,
          invoiceId: state.expanded,
        })
        .then(() => {
          invoices.reload(state.expanded as number);
          payments.get({ id: state.expanded });
          handleChange({ dialog: null });
        }),
    [state.expanded]
  );

  const onDeletePayment = React.useCallback(
    (invoiceItemId: number, invoiceId: number) => {
      confirm({
        title: t('invoices.current.confirm.payment.delete.title'),
        content: t('invoices.current.confirm.payment.delete.content'),
        onSuccess: async () => {
          await payments.remove({ invoiceItemId, invoiceId });
          await invoices.reload(invoiceId);
          return payments.get({ id: state.expanded });
        },
        onCancel: noop,
      });
    },
    [state.expanded]
  );

  const onChangeStatus = React.useCallback(
    (manualStatus: IInvoice['manualStatus'] | null) => (invoice: IInvoice) => {
      const namespace = !manualStatus ? 'resetStatus' : 'markAsPaid';
      confirm({
        title: t(`invoices.confirm.${namespace}.title`),
        content: t(`invoices.confirm.${namespace}.content`),
        onSuccess: async () => {
          await invoices
            .executeRequest(namespace)(invoice.id, { manualStatus })
            .catch((er) => {
              showSystemMessage(er.message, 'error');
            });
          return invoices.reload(invoice.id);
        },
        onCancel: noop,
      });
    },
    []
  );

  const onCancelInvoice = React.useCallback(
    (cancel: boolean, invoiceId: number) => {
      const type = cancel ? 'cancel' : 'unCancel';
      confirm({
        title: t(`invoices.current.confirm.${type}.title`),
        content: t(`invoices.current.confirm.${type}.content`),
        onSuccess: () => {
          handleCancelInvoice(cancel, invoiceId);
        },
        onCancel: noop,
      });
    },
    [state.expanded, handleCancelInvoice, confirm]
  );

  const onPayWithStripe = React.useCallback(
    async (invoiceId: number) => {
      try {
        const q = qs.parse(queryStr);
        const newQ = {
          ...q,
          inittab: state.selectedTab,
          initexp: invoiceId,
        };

        const baseUrl =
          process.env.REACT_APP_CLIENT_SERVER + location.pathname + '?';
        const fullUrlPath =
          baseUrl + qs.stringify(newQ, { arrayFormat: 'bracket' });

        const res = await stripe.executeRequest('payInvoice')(invoiceId, {
          successUrlPath: fullUrlPath,
          cancelUrlPath: fullUrlPath,
        });

        window.location.href = res?.data?.url;

        return res;
      } catch (error: any) {
        showSystemMessage(error.message, 'error');
      }
    },
    [queryStr, process.env.REACT_APP_CLIENT_SERVER, location, state.selectedTab]
  );

  const onDeleteInvoice = React.useCallback(
    (invoiceId: number) => {
      confirm({
        title: t(`invoices.current.confirm.delete.title`),
        content: t(`invoices.current.confirm.delete.content`),
        onSuccess: () => {
          return invoices.remove(invoiceId).finally(() => getInvoices(query));
        },
        onCancel: noop,
      });
    },
    [queryStr]
  );

  const openUserInNewTab = React.useCallback<IInvoiceTypes.IOpenUserInNewTab>(
    (user) => {
      if (isCurrentPartner) {
        return openInNewTab(
          `/${hash}/${user.shortName}/services/all?${qs.stringify({
            tsn: user.shortName,
          })}`
        );
      }

      return openInNewTab(
        `/${user.shortName}/tenants?${qs.stringify({
          ...DEFAULT_CUSTOMERS_QUERY,
          psn: user.shortName,
        })}`
      );
    },
    [hash, isCurrentPartner]
  );

  const isNewInvoice = !state.selectedInvoice;

  React.useEffect(() => {
    if (query.initexp) {
      getInvoiceDetails(query.initexp);
    }
    handleChange({
      selectedTab: query.inittab || 'base',
      expanded: +query.initexp || null,
    });
  }, []);

  const statusValue = React.useMemo(() => {
    if (!query.status) return [];
    return filter(findStatusValues(query.status))(statusOptions);
  }, [queryStr]);

  React.useEffect(() => {
    if (invoices.dataReceived) {
      if (searchRef.current) {
        searchRef.current.value = query.q || '';
      }
      forceUpdate();
    }
  }, [invoices.dataReceived]);

  React.useEffect(() => {
    return () => {
      statistic.reset();
    };
  }, []);

  if (!invoices.dataReceived) {
    return <Loader />;
  }

  return (
    <>
      <MainPage
        className={cn('mb-15 full-width')}
        justifyContent="space-between"
        alignItems="center"
      >
        <Col>
          <h3 className="pt-10 pb-10">{t('invoices.title')}</h3>
        </Col>
      </MainPage>
      {showTabs && (
        <LinearTabs className="mb-25">
          {TABS.map((tab, ind) => {
            return (
              <LinearTabButton
                key={tab.label}
                isActive={state.selectedTab === tab.value}
                onClick={() => {
                  invoices.setData([]);
                  positions.setData([]);
                  payments.setData([]);
                  handleChange({ selectedTab: tab.value });
                }}
              >
                {t(tab.label)}
              </LinearTabButton>
            );
          })}
        </LinearTabs>
      )}
      {!isOwnInvoices && (
        <Row
          alignItems="center"
          justifyContent="space-between"
          className="mb-25"
        >
          <Col>
            <Row columnSpacing={3} className="bold steel">
              <Col>
                {t('statuses.UNPAID')}:{' '}
                <span className="info">
                  {numberToCurrency(unpaidInvoices, false)} CHF
                </span>
              </Col>
              <Col>
                {t('statuses.OVERDUE')}:{' '}
                <span className="error">
                  {numberToCurrency(overdueInvoices, false)} CHF
                </span>
              </Col>
            </Row>
          </Col>

          {isAbleToCreate && (
            <Col>
              <Row columnSpacing={2}>
                {isServerBase && (
                  <Col>
                    <ExportInvoicesButton />
                  </Col>
                )}
                <Col>
                  <ImportPaymentsButton
                    onImportSuccess={() => getInvoices(query)}
                  />
                </Col>
                <Col>
                  <Button onClick={handleDialog('invoice')}>
                    {t('invoices.buttons.createInvoice')}
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      )}
      {!isTenant && <IncomeStatistics />}

      <Row columnSpacing={2} className="full-width mb-25" alignItems="center">
        <Col xs>
          <Input
            ref={searchRef}
            type="search"
            className="bg-white"
            onChange={onInvoiceSearch}
            onKeyUp={(e: any) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                changeSearchUrl(e.target.value);
              }
            }}
            onClear={() => {
              // @ts-ignore
              searchRef.current.value = '';
              changeSearchUrl(undefined);
            }}
            placeholder={t(
              `invoices.inputs.placeholders.${
                userSource === 'partner' ? 'searchByPartner' : 'searchByTenant'
              }`
            )}
          />
        </Col>
        <SStatusSelect>
          <Select
            isMulti
            options={statusOptions}
            value={statusValue}
            closeMenuOnScroll
            closeMenuOnSelect={false}
            useCheckboxOption
            maxDisplayLabels={2}
            hideSelectedOptions={false}
            // @ts-ignore
            onChange={onStatusChange}
          />
        </SStatusSelect>
        <Col>
          <ClearButton
            variant="text"
            onClick={() => {
              // @ts-ignore
              searchRef.current.value = '';
              changeQuery(DEFAULT_INVOICES_PARAMS);
            }}
          >
            {t('invoices.buttons.clearFilters')}
          </ClearButton>
        </Col>
      </Row>
      <div className={cn({ disabled: invoices.isLoading })}>
        {!invoices.data.length ? (
          <div className="fs-14 text-center pt-30 pb-30">
            {t('invoices.noInvoices')}
          </div>
        ) : (
          <>
            <Paper
              className={cn(
                'flex justify-between align-center full-width pt-15 pb-15 bolder mb-5 lh-1 pl-50'
              )}
            >
              <Row justifyContent="space-between" alignItems="center">
                {shouldShowUserName && (
                  <Col xs={2}>
                    <Row alignItems="center">
                      <Col>
                        <div className={cn('uppercase fs-14')}>
                          {t(`invoices.list.heads.${userSource}.name`)}
                        </div>
                      </Col>
                      <Col>
                        <SortIcon
                          size={16}
                          className="ml-5"
                          propName={`${userSource}.name`}
                          query={query}
                          onSort={(field: any, orderType: any) =>
                            changeQuery({ orderBy: field, orderType })
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col xs={4}>
                  <Row
                    alignItems="center"
                    data-level={isOwnInvoices ? 'tenant' : currentAccessLevel}
                  >
                    <Col xs={4}>
                      <Row alignItems="center">
                        <Col className="uppercase fs-14">
                          {t('invoices.list.heads.invoiceNumber')}
                        </Col>
                        <Col>
                          <SortIcon
                            size={16}
                            propName="invoiceNumber"
                            className="ml-5"
                            query={query}
                            onSort={(field: any, orderType: any) =>
                              changeQuery({ orderBy: field, orderType })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={4}>
                      <Row alignItems="center">
                        <Col className="uppercase fs-14">
                          {t('invoices.list.heads.invoiceDate')}
                        </Col>
                        <Col>
                          <SortIcon
                            size={16}
                            propName="invoiceDate"
                            className="ml-5"
                            query={query}
                            onSort={(field: any, orderType: any) =>
                              changeQuery({ orderBy: field, orderType })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={4}>
                      <Row alignItems="center">
                        <Col className="uppercase fs-14">
                          {t('invoices.list.heads.dueDate')}
                        </Col>
                        <Col>
                          <SortIcon
                            size={16}
                            propName="dueDate"
                            className="ml-5"
                            query={query}
                            onSort={(field: any, orderType: any) =>
                              changeQuery({ orderBy: field, orderType })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Row alignItems="center" columnSpacing={2}>
                    <Col xs={4}>
                      <Row alignItems="center">
                        <Col className="uppercase fs-14">
                          {t('invoices.list.heads.datePaid')}
                        </Col>
                        <Col>
                          <SortIcon
                            size={16}
                            className="ml-5"
                            propName="paidDate"
                            query={query}
                            onSort={(field: any, orderType: any) =>
                              changeQuery({ orderBy: field, orderType })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={5}>
                      <Row alignItems="center" justifyContent="flex-end">
                        <Col className="uppercase fs-14">
                          {t('invoices.list.heads.total')}
                        </Col>
                        <Col>
                          <SortIcon
                            size={16}
                            propName="total"
                            className="ml-5"
                            query={query}
                            onSort={(field: any, orderType: any) =>
                              changeQuery({ orderBy: field, orderType })
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={3}>
                      <Row alignItems="center">
                        <Col className="uppercase fs-14">
                          {t('invoices.list.heads.status')}
                        </Col>
                        <Col />
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={2} />
              </Row>
            </Paper>
            {invoices.data.map((invoice: IInvoice) => (
              <InvoiceListItem
                key={invoice.id}
                {...invoice}
                pretendedLevel={pretendedLevel}
                accessLevel={accessLevel}
                currentAccessLevel={currentAccessLevel}
                isEditable={permissions.canManage}
                userSource={userSource}
                expanded={state.expanded === invoice.id}
                onOpenDetails={getInvoiceDetails}
                onAddPosition={handleDialog('position')}
                onAddPayment={handleDialog('payment')}
                openUserInNewTab={openUserInNewTab}
                onDelete={onDeleteInvoice}
                onDeletePosition={onDeletePosition}
                onEditPosition={onEditPosition}
                onDeletePayment={onDeletePayment}
                onCancelInvoice={onCancelInvoice}
                reloadInvoice={invoices.reload}
                isRequesting={isRequesting}
                onMarkAsPaid={onChangeStatus('PAID')}
                onResetStatus={onChangeStatus(null)}
                onEdit={() =>
                  handleChange({ dialog: 'invoice', selectedInvoice: invoice })
                }
                stripeEnabled={!!stripeEnabled}
                onPayWithStripe={onPayWithStripe}
              />
            ))}
            <TablePagination
              page={query.page}
              perPage={query.perPage}
              totalCount={invoices.meta.totalCount}
              onChange={({ page }) => changeQuery({ page })}
            />
          </>
        )}
      </div>
      <PositionDialog
        open={state.dialog === 'position'}
        onSave={state.selectedPosition ? onUpdatePosition : onCreatePosition}
        onClose={handleDialog(null)}
        isRequesting={isRequesting}
        initialValues={state.selectedPosition}
      />
      <AddPaymentDialog
        open={state.dialog === 'payment'}
        onSave={onCreatePayment}
        onClose={handleDialog(null)}
        isRequesting={isRequesting}
      />
      <InvoiceDialog
        initialValues={state.selectedInvoice}
        isNew={isNewInvoice}
        open={state.dialog === 'invoice'}
        currentAccessLevel={currentAccessLevel}
        onSave={isNewInvoice ? onCreateInvoice : onEditInvoice}
        onClose={handleDialog(null)}
        isRequesting={isRequesting}
        account={account}
      />
    </>
  );
});

const InvoiceList = (props: InjectedPermissionsProps) => (
  <View
    {...props}
    invoices={StateHandlers.invoices}
    payments={StateHandlers.invoicePayments}
    positions={StateHandlers.invoicePositions}
    statistic={StateHandlers.invoiceStatistics}
    incomeStatistic={StateHandlers.invoiceIncomeStats}
    stripe={StateHandlers.stripe}
  />
);

export default InvoiceList;
