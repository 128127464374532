import * as asn1js from 'asn1js';
import {
  Certificate,
  EnvelopedData,
  OriginatorInfo,
  CertificateSet,
  ContentInfo,
  // @ts-ignore
} from 'pkijs';
import { Buffer } from 'buffer';
import {
  arrayBufferToString,
  fromBase64,
  stringToArrayBuffer,
  toBase64,
} from 'pvutils';

interface InnerASN1 {
  offset: number;
  result: asn1js.LocalBaseBlockType;
}

const encryptionAlgorithm = {
  name: 'AES-CBC',
  length: 256,
};

const oaepHashAlgorithm = 'sha-512';
const envelopedDataOID = '1.2.840.113549.1.7.3';

export const encodeToBase64 = (str: string) =>
  new Buffer(str).toString('base64');

export const decodePemToAscii = (str: string) =>
  new Buffer(str, 'base64').toString('ascii');

export const decodePemToUtf8 = (str: string) =>
  new Buffer(str, 'base64').toString('utf8');

export async function encryptData(
  value: string,
  certificatePem: string,
  convertToBase64 = true
) {
  const asn1 = certPemToAsn1(certificatePem);
  const innerCert = new Certificate({ schema: asn1.result });

  const cmsEnveloped = new EnvelopedData({
    originatorInfo: new OriginatorInfo({
      certs: new CertificateSet({
        certificates: [innerCert],
      }),
    }),
  });

  cmsEnveloped.addRecipientByCertificate(innerCert, { oaepHashAlgorithm });

  await cmsEnveloped.encrypt(encryptionAlgorithm, stringToArrayBuffer(value));

  const cmsContent = new ContentInfo();
  cmsContent.contentType = envelopedDataOID;
  cmsContent.content = cmsEnveloped.toSchema();

  const cmsEnvelopedBuffer = cmsContent.toSchema().toBER(false);
  const cmsPem = formatPEM('CMS', cmsEnvelopedBuffer);

  return convertToBase64 ? Buffer.from(cmsPem).toString('base64') : cmsPem;
}

export function certPemToAsn1(certPem: string): InnerASN1 {
  const clearEncodedCertificate = certPem.replace(
    /(-----(BEGIN|END)( NEW)? CERTIFICATE-----|\r\n|\n)/g,
    ''
  );
  const certificateBuffer = stringToArrayBuffer(
    fromBase64(clearEncodedCertificate)
  );

  // Decode input certificate
  return asn1js.fromBER(certificateBuffer);
}

/**
 * Format string in order to have each line with length equal to 64
 */
export function formatPEMBody(pemString: string): string {
  const PEM_STRING_LENGTH = pemString.length,
    LINE_LENGTH = 64;
  const wrapNeeded = PEM_STRING_LENGTH > LINE_LENGTH;

  if (wrapNeeded) {
    let formattedString = '',
      wrapIndex = 0;

    for (let i = LINE_LENGTH; i < PEM_STRING_LENGTH; i += LINE_LENGTH) {
      formattedString += pemString.substring(wrapIndex, i) + '\r\n';
      wrapIndex = i;
    }

    formattedString += pemString.substring(wrapIndex, PEM_STRING_LENGTH);
    return formattedString;
  } else {
    return pemString;
  }
}

export function formatPEM(type: string, buffer: Buffer): string {
  const pemBody = formatPEMBody(toBase64(arrayBufferToString(buffer)));

  return [`-----BEGIN ${type}-----`, pemBody, `-----END ${type}-----`].join(
    '\r\n'
  );
}
