import * as CONSTANTS from './constants';

export function parseFolderToForm(
  group: IServiceGroups.Group | null
): IServiceGroups.GroupDetailsFormValues {
  return {
    name: group?.name || '',
    description: group?.description || '',
    type:
      CONSTANTS.FolderTypeOptions.find((el) => el.value === group?.type) ||
      CONSTANTS.FolderTypeOptions[0],
  };
}
