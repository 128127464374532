import * as React from 'react';
import { Alert, Button } from 'elements';
import { useTranslation } from 'react-i18next';

type IProps = {
  error?: string;
  errorName?: string;
  onDelete: () => void;
};

const FailedState = (props: IProps) => {
  const { error, onDelete, errorName } = props;
  const { t } = useTranslation();

  if (errorName === 'GeneralRuntimeError') {
    return (
      <div>
        <Alert severity="error" className="mb-25">
          {t(`monitoring.vm.flow.creating.alert.${errorName}`)}
        </Alert>
        <div className="mb-25">
          {t('monitoring.vm.flow.creating.alert.GeneralRuntimeError.subtext')}
        </div>
        <Button onClick={onDelete} variant="outlined" color="default">
          {t('common.delete')}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Alert severity="error" className="mb-25">
        {t(error as string)}
      </Alert>
      <div className="mb-25">
        {t('monitoring.vm.flow.creating.alert.subtext')}
      </div>
      <Button onClick={onDelete} variant="outlined" color="default">
        {t('common.delete')}
      </Button>
    </div>
  );
};

export default FailedState;
