import * as React from 'react';
import { Loader } from 'elements';
import { useQuery, useState } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import VmListContainer from 'pages/vmList/Main';
import CreateVirtualMachineDialog from '../../../vm-details/dialogs/CreateVirtualMachineDialog';

type IState = {
  dialog: 'create-vm' | null;
};

const INITIAL_STATE: IState = {
  dialog: null,
};

const OBSERVERS = {
  tenantPricing: StateHandlers.tenantPricing,
  connectivity: StateHandlers.connectivity,
  vms: StateHandlers.vms,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { vms, connectivity, tenantPricing } = props;
  const [state, handleChange] = useState<IState>(INITIAL_STATE);
  const { query, queryStr } = useQuery<IVmTypes.VMParams>();
  const isPricingReceived = tenantPricing.dataReceived;
  const connectivitySetupCompleted = connectivity
    ? connectivity.data?.setupState === 'SETUP_COMPLETED'
    : false;

  const fetchVms = React.useCallback(() => {
    return vms.get({
      ...query,
      include: ['billingCycle', 'serviceMonitoring', 'serviceSnapshot'],
    });
  }, [queryStr]);

  const onCreateVms = React.useCallback((payload: any) => {
    vms.create(payload).then(() => {
      return fetchVms();
    });
  }, []);

  React.useEffect(() => {
    if (!vms.isLoading) fetchVms();
  }, [queryStr]);

  React.useEffect(() => {
    if (!vms.isRequesting) {
      handleChange(INITIAL_STATE);
    }
  }, [vms.isRequesting]);

  if (!vms.dataReceived && !isPricingReceived) {
    return <Loader />;
  }

  return (
    <div className="full-width">
      <VmListContainer
        onAdd={() => handleChange({ dialog: 'create-vm' })}
        shouldUseQuery
      />
      <CreateVirtualMachineDialog
        open={state.dialog === 'create-vm'}
        onSave={onCreateVms}
        connectivitySetupCompleted={connectivitySetupCompleted}
        onClose={() => handleChange(INITIAL_STATE)}
        isSubmitting={vms.isRequesting}
      />
    </div>
  );
});

const VMs = () => <View {...OBSERVERS} />;

export default VMs;
