import { GET, PUT, DELETE } from 'utils';
import { IAxiosPromise } from 'axios-shapes';

export const getList = (
  params: Record<string, any>
): IAxiosPromise<IMaintenanceWindow[]> => GET('/maintenance-window', params);

export const updateById = (
  id: number,
  payload: Record<string, any>
): IAxiosPromise<IMaintenanceWindow> =>
  PUT(`/maintenance-window/${id}`, payload);

export const deleteById = (id: number): IAxiosPromise<IMaintenanceWindow> =>
  DELETE(`/maintenance-window/${id}`);
