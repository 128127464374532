import * as React from 'react';
import cn from 'classnames';
import { MaintenanceWindowPayload } from 'rds';
import { useTranslation } from 'react-i18next';
import { customConfirmInputSchema } from 'enums';
import { useState, usePermissions } from 'hooks';
import { Paper, Row, Col, Button, Loader, styled } from 'elements';
import { remCalc, confirm, confirmDelete, noop } from 'utils';
import { observer } from 'mobx-react-lite';
import { BaseDecorator } from 'states/types';
import { INITIAL_STATE, buildInitialState } from './constants';
import RebootItem from './RebootItem';
import RebootRuleDialog from './RebootRuleDetailsDialog';
import {
  parseRebootValuesforPayload,
  filterRebootsItemByType,
} from './helpers';

type IProps = {
  appEntityId: number;
  instance: BaseDecorator<IMaintenanceWindow, true>;
  baseService: BaseDecorator<any, false>;
  className?: string;
};

const List = observer((props: IProps) => {
  const { instance, appEntityId, className } = props;
  const { t } = useTranslation();
  const { permissions } = usePermissions('SERVICES');
  const [state, handleState] =
    useState<IRebootDialogTypes.IState>(INITIAL_STATE);

  const handleDialogOpen = React.useCallback(
    (openDialog: boolean) => () => {
      handleState({ openDialog });
    },
    []
  );

  const onCreateNewReboot = React.useCallback(
    async (val: IRebootDialogTypes.Values) => {
      await instance.create([
        parseRebootValuesforPayload(val),
        ...filterRebootsItemByType('SINGLE', instance.data),
      ]);
      handleState(INITIAL_STATE);
      return await instance.get({
        appEntityIds: [appEntityId],
        orderBy: 'startAt',
        orderType: 'asc',
      });
    },
    [appEntityId, JSON.stringify(instance.data)]
  );

  const onUpdateReboot = React.useCallback(
    async (val: IRebootDialogTypes.Values) => {
      await instance.update(
        state.current?.id,
        parseRebootValuesforPayload(val)
      );
      handleState(INITIAL_STATE);
      return await instance.get({
        appEntityIds: [appEntityId],
        orderBy: 'startAt',
        orderType: 'asc',
      });
    },
    [appEntityId, state.current?.id]
  );

  const onForceReboot = React.useCallback(
    (rebootItem: IMaintenanceWindow) => {
      return confirmDelete({
        title: t('services.rds.reboot.confirm.forceReboot.title'),
        content: t('services.rds.reboot.confirm.forceReboot.content'),
        placeholder: t('services.rds.reboot.confirm.forceReboot.input'),
        validationSchema: customConfirmInputSchema('Reboot'),
        onSuccess: async () => {
          const payload: MaintenanceWindowPayload = {
            startAt: rebootItem.startAt,
            isForced: true,
            notificationEnabled: rebootItem.notificationEnabled,
            recurringInterval: undefined,
          };

          await instance.update(rebootItem.id, payload);
          return await instance.get({
            appEntityIds: [appEntityId],
            orderBy: 'startAt',
            orderType: 'asc',
          });
        },
        onCancel: () => undefined,
        successLabel: 'common.save',
        cancelLabel: 'common.cancel',
      });
    },
    [appEntityId, JSON.stringify(instance.data)]
  );

  const onOpenEdit = React.useCallback(
    (rebootItem: IMaintenanceWindow) => {
      return handleState({
        openDialog: true,
        current: { id: rebootItem.id, ...buildInitialState(rebootItem) },
        operationName: rebootItem.taskOperationName,
      });
    },
    [appEntityId, JSON.stringify(instance.data)]
  );

  const onRemoveReboot = React.useCallback(
    (rebootItem: IMaintenanceWindow) => {
      return confirm({
        title: t('services.rds.reboot.confirm.delete.title'),
        content: t('services.rds.reboot.confirm.delete.content', {
          name: t(`reboots.operationName.${rebootItem.taskOperationName}`),
        }),
        onSuccess: async () => {
          await instance.remove(rebootItem.id).catch(noop);
          return await instance.get({
            appEntityIds: [appEntityId],
            orderBy: 'startAt',
            orderType: 'asc',
          });
        },
        onCancel: () => undefined,
      });
    },
    [appEntityId, JSON.stringify(instance.data)]
  );

  React.useEffect(() => {
    instance.get({
      appEntityIds: [appEntityId],
      orderBy: 'startAt',
      orderType: 'asc',
    });
  }, [appEntityId]);

  if (!instance.dataReceived) {
    return <Loader />;
  }

  if (!instance.data.length) {
    return (
      <Paper className={cn(className, 'flex align-center justify-center')}>
        <div className="text-center _empty-holder">
          <div className="steel mb-15">
            {t('services.rds.reboot.empty.content')}
          </div>
          {permissions.canManage && (
            <Button onClick={handleDialogOpen(true)}>
              {t('services.rds.reboot.buttons.addReboot')}
            </Button>
          )}
        </div>
        <RebootRuleDialog
          fullWidth
          maxWidth="md"
          PaperProps={{
            style: {
              maxWidth: remCalc(830),
            },
          }}
          instance={instance}
          open={state.openDialog}
          subtitle={t('services.rds.reboot.dialogs.addReboot.subtitle')}
          onClose={handleDialogOpen(false)}
          onSave={onCreateNewReboot}
        />
      </Paper>
    );
  }

  return (
    <Paper className={className}>
      <Row
        alignItems="center"
        justifyContent="space-between"
        columnSpacing={2}
        className="mb-25"
      >
        <Col>
          <h3>{t('services.rds.reboot.title')}</h3>
        </Col>
        {permissions.canManage && (
          <Col>
            <Button onClick={handleDialogOpen(true)}>
              {t('services.rds.reboot.buttons.addReboot')}
            </Button>
          </Col>
        )}
      </Row>
      {instance.data.map((reboot) => {
        return (
          <RebootItem
            {...reboot}
            key={reboot.taskOperationName + reboot.id}
            onEdit={onOpenEdit}
            onForceReboot={onForceReboot}
            onDelete={onRemoveReboot}
            isEditable={permissions.canManage}
          />
        );
      })}
      <RebootRuleDialog
        fullWidth
        maxWidth="md"
        open={state.openDialog}
        instance={instance}
        initialValues={state.current}
        operationName={state.operationName}
        subtitle={t('services.rds.reboot.dialogs.addReboot.subtitle')}
        onClose={handleDialogOpen(false)}
        onSave={state.current ? onUpdateReboot : onCreateNewReboot}
      />
    </Paper>
  );
});

export default styled(List)(({ theme }) => ({
  padding: remCalc(25, 30),
  minHeight: remCalc(500),
  '& ._empty-holder': {
    maxWidth: remCalc(510),
  },
}));
