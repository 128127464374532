import { GLOBAL_DATE_FORMAT } from 'enums';
import * as R from 'ramda';
import { dayjs, Dayjs } from 'utils';

export function resetTimeValue(startDate: string | Dayjs): Dayjs {
  return dayjs(startDate).set('seconds', 0);
}

export function parseNextRebootField(
  startDate: string | null | undefined,
  interval: IMaintenanceRecurringInterval | undefined
): string {
  if (!startDate) return '-';

  if (interval === 'DAY') {
    return dayjs(startDate).format(`${GLOBAL_DATE_FORMAT} HH:mm`);
  }
  if (interval === 'WEEK') {
    return dayjs(startDate).format(`${GLOBAL_DATE_FORMAT} HH:mm`);
  }
  if (interval === 'MONTH') {
    return dayjs(startDate).format(`${GLOBAL_DATE_FORMAT} HH:mm`);
  }

  return dayjs(startDate).format(`${GLOBAL_DATE_FORMAT} HH:mm`);
}

export function isDirtyForm(
  initial: IPatchingTypes.Values,
  current: IPatchingTypes.Values
): boolean {
  return R.keys(initial).every((key) => R.equals(initial[key], current[key]));
}
