import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Loader, Table, Row, Col } from 'elements';
import { useMounted, useQuery, useTask, usePermissions, useState } from 'hooks';
import {
  decodePemToAscii,
  encryptData,
  confirm,
  noop,
  stripCoreDomain,
} from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { ALL_TEST_IDS, defaultNavQuery } from 'enums';
import { IUser } from 'core-infrastructure';
import { isEmpty } from 'ramda';
import cn from 'classnames';
import CoreTitle from './Title';
import UsersDialog from './dialogs/CommonUser';
import { USERS_ADMINS_COLUMNS } from './constants';

const INITIAL_STATE = {
  isOpen: false,
};

type IProps = React.PropsWithChildren<{
  title: string;
  subtext?: string;
  reducer: 'users' | 'admins';
  selectedReducer: 'selectedUser' | 'selectedAdmin';
}>;

type IViewProps = {
  core: StateHandlers.ICoreHandler;
  admins: StateHandlers.ICoreAdminsHandler;
  coreUsers: StateHandlers.ICoreUsersHandler;
  selectedAdmin: StateHandlers.ISelectedCoreAdminHandler;
  selectedUser: StateHandlers.ISelectedCoreUserHandler;
};

const View = observer((props: IProps & IViewProps) => {
  const {
    reducer,
    selectedReducer,
    title,
    subtext,
    core,
    admins,
    coreUsers,
    selectedAdmin,
    selectedUser,
  } = props;
  const { t } = useTranslation();
  const isMounted = useMounted();
  const { permissions: servicePermissions } = usePermissions('SERVICES');
  const { permissions: adminsPermission } = usePermissions('DOMAIN_ADMINS');
  const { query, queryStr, changeQuery } = useQuery();
  const [state, handleChange] = useState(INITIAL_STATE);
  const users = reducer === 'admins' ? admins : coreUsers;
  const current =
    selectedReducer === 'selectedAdmin' ? selectedAdmin : selectedUser;
  const getPubKey = useCallback(() => {
    return core.data?.agent && core.data?.agent.isVerified
      ? decodePemToAscii(core.data?.agent.certificatePemBase64)
      : '';
  }, [core.data?.agent]);

  const coreDomain = core.data ? stripCoreDomain(core.data?.domainName) : '';

  const permissions =
    reducer === 'admins' ? adminsPermission : servicePermissions;

  const isAgentConnected = core.data?.agent?.isConnected;

  const task = useTask(core.data ? core.data.task : null);

  const fetchUsers = useCallback(
    () => users.get(isEmpty(query) ? defaultNavQuery : query),
    [queryStr]
  );

  const onSort = useCallback(
    (orderBy: string, orderType: string) => {
      changeQuery({
        orderType,
        orderBy,
      });
    },
    [queryStr]
  );

  const deleteUser = useCallback(
    (user: IUser) =>
      confirm({
        title: t(`services.core.${reducer}.confirm.delete.title`),
        content: (
          <div className="steel fs-14">
            {
              t(`services.core.${reducer}.confirm.delete.subtitle`, {
                name: user.username,
              }) as string
            }
          </div>
        ),
        onCancel: noop,
        onSuccess: () =>
          users.remove(user.id).then(() => {
            fetchUsers();
          }),
      }),
    [queryStr]
  );

  const createUser = useCallback(
    async (values: IUser) => {
      const passwordSecure = await encryptData(
        values.password || '',
        getPubKey(),
        true
      );
      return users
        .create({
          ...values,
          passwordSecure,
        })
        .then(() => {
          fetchUsers();
          handleChange(false, 'isOpen');
        });
    },
    [queryStr]
  );

  const updateUser = useCallback(
    async (values: IUser) => {
      const passwordSecure =
        values.resetPassword && values.password
          ? await encryptData(values.password, getPubKey(), true)
          : '';

      return users
        .update(current.data.id, {
          ...values,
          passwordSecure,
        })
        .then(() => {
          current.reset();
          handleChange(false, 'isOpen');
        });
    },
    [current.data, queryStr]
  );

  const editUser = useCallback((user: IUser | null) => {
    if (user) {
      current.get({ id: user.id });
    } else {
      handleChange(true, 'isOpen');
    }
  }, []);

  const onDialogClose = useCallback(() => {
    current.reset();
    handleChange({ isOpen: false });
  }, []);

  const columns = useMemo(
    () =>
      USERS_ADMINS_COLUMNS({
        t,
        onDelete: isAgentConnected ? deleteUser : undefined,
        onEdit: isAgentConnected ? editUser : undefined,
        isFetching: current.isRequesting,
        isEditable: permissions.canManage,
        coreDomain,
      }),
    [
      current.isRequesting,
      queryStr,
      isAgentConnected,
      permissions.canManage,
      permissions.canManage,
      reducer,
    ]
  );

  const isNew = !current.data.id;

  React.useEffect(() => {
    fetchUsers();
  }, [queryStr]);

  React.useEffect(() => {
    handleChange(!!current.data.id, 'isOpen');
  }, [current.data.id]);

  if (!isMounted || !users.dataReceived) {
    return <Loader />;
  }

  if (core.data?.agent && !core.data?.agent.isVerified) {
    return (
      <Alert severity="error">
        {t(`services.core.${reducer}.agentNotVerified`) as string}
      </Alert>
    );
  }

  return (
    <div className={cn('not-scrollable', { disabled: task.isTaskActive })}>
      <CoreTitle />
      <div className={cn({ disabled: !isAgentConnected })}>
        <Row className="mb-20" justifyContent="space-between" columnSpacing={2}>
          <Col xs>
            <div className="fs-14 bolder pt-5 pb-5 uppercase">
              {t(title) as string}
            </div>
            {subtext && (
              <div className="fs-14 steel">{t(subtext) as string}</div>
            )}
          </Col>
          {permissions.canManage && (
            <Col>
              <Button
                size="small"
                onClick={() => isAgentConnected && editUser(null)}
                testId={
                  // @ts-ignore
                  ALL_TEST_IDS.services.coreInfrastructure[reducer].addButton
                }
              >
                {t(`services.core.button.${reducer}.add`)}
              </Button>
            </Col>
          )}
        </Row>
        <Table
          hasSorting
          query={query}
          onSort={onSort}
          columns={columns}
          data={users.data}
          params={users.meta}
          noDataClassName="pt-50 fs-14 steel"
          className={cn({ disabled: users.isRequesting })}
          onPageChange={(nq) => changeQuery({ ...defaultNavQuery, ...nq })}
          onUpdateTable={fetchUsers}
        />
        <UsersDialog
          isNew={isNew}
          title={t(
            `services.dialog.core.${
              reducer === 'admins' ? 'adminDetails' : 'userDetails'
            }.title.${isNew ? 'create' : 'edit'}`
          )}
          open={state.isOpen}
          onClose={onDialogClose}
          onSave={isNew ? createUser : updateUser}
          initialValues={current.data}
          coreDomain={coreDomain}
        />
      </div>
    </div>
  );
});

const UserContainer = (props: IProps) => (
  <View
    {...props}
    core={StateHandlers.core}
    coreUsers={StateHandlers.coreUsers}
    admins={StateHandlers.coreAdmins}
    selectedAdmin={StateHandlers.selectedCoreAdmin}
    selectedUser={StateHandlers.selectedCoreUser}
  />
);

export default UserContainer;
