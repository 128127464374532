import React from 'react';
import { AnyFunc } from 'global-shapes';
import { MuiIcons, styled } from 'elements';
import { remCalc } from 'utils';
import { PALETTE } from 'enums';

type Props = {
  size?: number;
  src?: string | null;
  onClick?: AnyFunc;
};

const AppLogo = styled('div')(({ emptyLogo }: any) => {
  const emptyStyles = emptyLogo
    ? {
        backgroundColor: ' rgba(23, 29, 41, 0.08)',
        color: PALETTE.steel,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: remCalc(33),
      }
    : {};

  return {
    width: remCalc(86),
    height: remCalc(86),
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    overflow: 'hidden',
    borderRadius: remCalc(4),

    ...emptyStyles,
  };
});

const defaultProps = {
  size: 86,
};

const ImagePlaceholder = (_props: Props) => {
  const props = { ...defaultProps, ..._props };
  const { size, src, onClick } = props;
  return (
    <AppLogo
      // @ts-ignore
      emptyLogo={!src}
      style={{
        backgroundImage: `url(${src})`,
        width: remCalc(size),
        height: remCalc(size),
      }}
      onClick={onClick}
    >
      {!src && <MuiIcons.InsertPhoto />}
    </AppLogo>
  );
};

export default ImagePlaceholder;
