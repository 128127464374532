import * as Yup from 'yup';
import * as R from 'ramda';

export function parseYupShemaErrors(
  err: Yup.ValidationError
): Record<string, string | string[]> {
  const { inner } = JSON.parse(JSON.stringify(err));
  const errors = R.reduce(
    (res: Record<string, string | string[]>, val: any) => {
      if (!res[val.path]) res[val.path] = val.message;
      return res;
    },
    {}
  )(inner);
  return errors;
}
