import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
  DateTimePicker,
  Row,
  Col,
  Switch,
  Input,
  styled,
} from 'elements';
import { dayjs, findDisabledMinTime, remCalc } from 'utils';
import { PATCHING_INTERVAL_OPTIONS } from 'components/Patching/constants';
import { OPERATION_OPTIONS } from './constants';

type IProps = IBulkScheduleUpdateDialog.IStepCommonProps & {
  className?: string;
};

const StepFirst = styled((props: IProps) => {
  const {
    values,
    errors,
    setFieldValue,
    handleChange,
    className,
    submitCount,
  } = props;
  const { t } = useTranslation();
  const hasFirstSubmit = !!submitCount;

  const disabledTime = findDisabledMinTime(values.startDate);

  return (
    <div className={className}>
      <div className="steel mb-20">
        {t('tenants.dialog.bulkSchedule.content.mainText')}
      </div>
      <div className="mb-30 _operationInput">
        <Select
          name="operations"
          label={t('tenants.dialog.bulkSchedule.inputs.operations')}
          value={values.operation}
          onChange={(operation) => setFieldValue('operation', operation)}
          options={OPERATION_OPTIONS}
        />
        {values.operation.value === 'rdsReboot' && (
          <div className="pt-20">
            <Switch
              checked={values.replaceRebootRules}
              label={t('tenants.dialog.bulkSchedule.switchers.replaceRules')}
              onCheck={(replaceRebootRules) =>
                setFieldValue('replaceRebootRules', replaceRebootRules)
              }
            />
          </div>
        )}
      </div>
      <div className="mb-25">
        <Row columnSpacing={2}>
          <Col>
            <div className="_intervalInput">
              <Select
                name="recurring"
                label={t('forms.recurring')}
                value={values.recurring}
                onChange={(recurring) => setFieldValue('recurring', recurring)}
                options={PATCHING_INTERVAL_OPTIONS}
              />
            </div>
          </Col>
          <Col>
            <div className="_intervalInput">
              <DateTimePicker
                label={t('forms.startDate')}
                maximumDate={dayjs().add(31, 'days').toDate()}
                minimumDate={dayjs().toDate()}
                value={values.startDate}
                onChange={(val) => {
                  setFieldValue('startDate', val);
                }}
                closeOnSelect
                minuteStep={15}
                disabledTime={() => {
                  if (values.startDate) {
                    return {
                      disabledHours: disabledTime.hours,
                      disabledMinutes: disabledTime.minutes,
                    };
                  }
                  return {
                    disabledHours: () => [],
                    disabledMinutes: () => [],
                  };
                }}
              />
              {hasFirstSubmit && errors.startDate && (
                <div className="error fs-12 pt-5 ">
                  {t(errors.startDate as string)}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="mb-20">
        <Switch
          checked={values.informUsers}
          label={t('tenants.dialog.bulkSchedule.switchers.informUsers')}
          onCheck={(informUsers) => setFieldValue('informUsers', informUsers)}
        />
      </div>
      {values.operation.value !== 'rdsReboot' && (
        <div className="mb-25 _operationInput">
          <div className="mb-10">
            <Input
              name="alertEmailRecipient"
              label={t('patching.inputs.alertEmail')}
              placeholder={t('forms.placeholders.email')}
              value={values.alertEmailRecipient || ''}
              onChange={handleChange}
              error={hasFirstSubmit && !!errors.alertEmailRecipient}
              helperText={errors.alertEmailRecipient}
            />
          </div>
          <div className="fs-12 steel">
            {t('tenants.dialog.bulkSchedule.content.replaceAlertEmail')}
          </div>
        </div>
      )}
    </div>
  );
})({
  '& ._operationInput': {
    maxWidth: remCalc(460),
    width: '100%',
  },
  '& ._intervalInput': {
    width: remCalc(222),
  },
});

export default StepFirst;
