import React, { useCallback } from 'react';
import cn from 'classnames';
import { Row } from 'elements';
import { IUsageRow } from 'usage';
import { useTranslation } from 'react-i18next';
import { numberToCurrency } from 'utils';
import ServicePriceCol from './ServicePriceCol';
import { UsageInfoBox } from 'components';
import { AnyFunc } from 'global-shapes';
import { PriceCard, PriceUserName } from './Styled';

const PricesRow = (
  props: IUsageRow & {
    isProvider: boolean;
    isLoading: boolean;
    onClick: AnyFunc;
    current: IUsageRow | null;
  }
) => {
  const {
    name,
    cost: { costPerService, total },
    onClick,
    isLoading,
    current,
    isProvider,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      onClick(event);
      !isProvider && setAnchorEl(event.currentTarget);
    },
    [isProvider]
  );

  const handleClose = useCallback(() => {
    onClick('close-menu');
    !isProvider && setAnchorEl(null);
  }, [isProvider]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="mb-15">
      <PriceCard
        className={cn('p-30 pointer', {
          disabled: isLoading,
        })}
        onClick={handleClick}
        aria-describedby={id}
      >
        <div className="flex justify-between align-center mb-25">
          <PriceUserName className="bolder">{name}</PriceUserName>
          <div className="bolder">
            {t('usage.priceRow.total')}: {numberToCurrency(total)}
          </div>
        </div>
        <Row columnSpacing={1}>
          {costPerService.map((c) => (
            <ServicePriceCol {...c} key={c.serviceTitle} />
          ))}
        </Row>
      </PriceCard>
      <UsageInfoBox
        id={id}
        open={open}
        anchorEl={anchorEl}
        accountId={current?.id}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </div>
  );
};

export default PricesRow;
