import * as React from 'react';
import cn from 'classnames';
import { Row, Col, MuiIcons, Checkbox } from 'elements';
import { useTranslation } from 'react-i18next';
import { IExcludeCallback, IImportedPayment, IStateIncluded } from 'invoices';
import { numberToCurrency } from 'utils';
import dayjs from 'dayjs';
import { BILLING_DATE_FORMAT } from '../billable-items/constants';
import { SPaymentRow, SPaymentEntryItem } from './Styled';

type IRowProps = IImportedPayment & {
  onSelect: IExcludeCallback;
  included: IStateIncluded;
};

const LINE_COLOR = '#f9f9f9';

const ImportedPaymentRow = (props: React.PropsWithChildren<IRowProps>) => {
  const { onSelect, included, ...invoice } = props;
  const { t } = useTranslation();

  return (
    <SPaymentRow alignItems="center" columnSpacing={2}>
      <Col xs={4}>
        <Row alignItems="center" className="full-width" columnSpacing={2}>
          <Col xs={4}>
            <span className="pl-10">{invoice.exist ? invoice.id : '-'}</span>
          </Col>
          <Col xs={4}>{invoice.date || '-'}</Col>
          <Col xs={4} className="text-right">
            {invoice.amount ? numberToCurrency(+invoice.amount, false) : '-'}
          </Col>
        </Row>
      </Col>
      <Col xs={8}>
        <div style={{ borderLeft: `1px solid ${LINE_COLOR}` }}>
          {invoice.entries.map((entry) => {
            const isIncluded = included[entry.externalPaymentId];
            const isDuplicate = entry.indicator === 'duplicate';

            const isPaymentEqualTotalAmount = +invoice.amount === +entry.amount;

            return (
              <SPaymentEntryItem
                className={cn('full-width')}
                key={entry.externalPaymentId}
              >
                <Row
                  columnSpacing={2}
                  className="full-width"
                  alignItems="center"
                  onClick={() => {
                    onSelect(entry.externalPaymentId, !isIncluded);
                  }}
                >
                  <Col xs={5}>
                    <span className="pl-10">{entry.referenceNo}</span>
                  </Col>
                  <Col xs={2}>
                    {entry.date
                      ? dayjs(entry.date, BILLING_DATE_FORMAT).format(
                          'DD.MM.YYYY'
                        )
                      : '-'}
                  </Col>
                  <Col xs={2} className="text-right">
                    {entry.amount
                      ? numberToCurrency(+entry.amount, false)
                      : '-'}
                  </Col>
                  <Col
                    xs={2}
                    className={cn('flex align-center', {
                      error: isDuplicate || !invoice.exist,
                    })}
                  >
                    {entry.indicator ? (
                      t(
                        `invoices.importPayments.dialog.indicator.${entry.indicator}`
                      )
                    ) : isPaymentEqualTotalAmount ? (
                      <MuiIcons.Check className="fs-16 success" />
                    ) : (
                      '-'
                    )}
                  </Col>
                  {invoice.exist && !isDuplicate && (
                    <Col xs className="flex justify-end">
                      <Checkbox
                        label={''}
                        checked={isIncluded}
                        onChange={() => undefined}
                      />
                    </Col>
                  )}
                </Row>
              </SPaymentEntryItem>
            );
          })}
        </div>
      </Col>
    </SPaymentRow>
  );
};

export const HeadRow = () => {
  const { t } = useTranslation();

  return (
    <SPaymentRow
      className={cn('steel fs-12 mb-5 _noBorder')}
      alignItems="center"
      columnSpacing={2}
    >
      <Col xs={4}>
        <Row alignItems="center" className="full-width" columnSpacing={2}>
          <Col xs={4}>
            <span className="pl-10">{t(`table.head.invoiceNo`)}</span>
          </Col>
          <Col xs={4}>{t(`table.head.date`)}</Col>
          <Col xs={4} className="text-right">
            {t(`table.head.amountCHF`)}
          </Col>
        </Row>
      </Col>
      <Col xs={8}>
        <SPaymentEntryItem className={cn('full-width')}>
          <Row
            columnSpacing={2}
            alignItems="center"
            className={cn('full-width')}
          >
            <Col xs={5}>
              <span className="pl-10">{t(`table.head.referenceNo`)}</span>
            </Col>
            <Col xs={2}>{t(`table.head.date`)}</Col>
            <Col xs={2} className="text-right">
              {t(`table.head.amountCHF`)}
            </Col>
            <Col xs={3}>{t(`table.head.indicator`)}</Col>
          </Row>
        </SPaymentEntryItem>
      </Col>
    </SPaymentRow>
  );
};

export default ImportedPaymentRow;
