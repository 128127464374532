import * as React from 'react';
import { useFormik } from 'formik';
import {
  Button,
  Col,
  Dialog,
  DialogProps,
  Row,
  Switch,
  VmTabs,
  VmTab,
  TabPanel,
  Select,
} from 'elements';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { showSystemMessage, remCalc } from 'utils';
import { useQuery, useStateHandler, usePermissions } from 'hooks';
import { ejectPubServiceName } from 'pages/services/helpers';
import { PORT_TYPES_OPTIONS } from 'pages/services/constants';
import { createPublicServiceValidationSchema } from './constants';
import * as TYPES from './types';
import AddPublicServiceForm from './AddPublicServiceForm';
import FirewallIpAddresses from './FirewallIpAddresses';
import GeoIp from './GeoIp';
import { getNicsOptions } from './helpers';

const INITIAL: TYPES.IPublicServiceFormValues = {
  name: '',
  port: 1,
  type: PORT_TYPES_OPTIONS[0],
  anySource: true,
  tab: 0,
  source: '',
  sources: [],
  geoip: undefined,
  countryCodes: [],
};

type IProps = DialogProps<any>;

const STYLES = {
  tab: { maxWidth: remCalc(350) },
};

const Renderer = observer((props: IProps) => {
  const { open, onClose, onSave } = props;
  const { t } = useTranslation();
  const { queryStr, query } = useQuery();
  const { isProvider } = usePermissions();

  const { isRequesting, create: createPort } = useStateHandler(
    StateHandlers.vmPorts
  );

  const createService = React.useCallback(
    (port: TYPES.IPublicServiceFormValues) => {
      const restPayload = !port.anySource
        ? {
            sourceIpAddresses: port.sources,
            sourceCountryCodes: port.countryCodes.map((el) => el.value),
          }
        : {};
      return createPort({
        vmId: query.id,
        name: ejectPubServiceName(port.name),
        port: port?.port,
        // @ts-ignore
        type: port.type.value,
        nicId: port.nicId?.value,
        ...restPayload,
      }).then((res) => {
        onClose();
        onSave && onSave({});
        showSystemMessage('services.vm.services.create.success', 'success');
        return res;
      });
    },
    [queryStr]
  );

  const form = useFormik({
    initialValues: INITIAL,
    validationSchema: createPublicServiceValidationSchema,
    onSubmit: createService,
    validateOnMount: true,
  });

  const { values, setFieldValue } = form;

  const handleTabChange = React.useCallback((ev: any, ind: number) => {
    setFieldValue('tab', ind);
  }, []);

  React.useEffect(() => {
    if (open) {
      form.setValues(INITIAL);
      getNicsOptions(query.id)('').then((opts) => {
        const routedNetNic = opts.find(
          (n) => n.netType === 'NAT_ROUTED' && n.isPrimary
        );
        setFieldValue('nicId', routedNetNic || opts[0]);
      });
    } else {
      form.resetForm();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      title={t('services.vm.services.dialog.title')}
      onClose={onClose}
      fullWidth
      handleSubmit={form.handleSubmit}
      PaperProps={{ style: { maxWidth: remCalc(750) } }}
      actions={
        <Row justifyContent="flex-end" spacing={2}>
          <Col>
            <Button variant="outlined" color="default" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button disabled={isRequesting} type="submit">
              {t('common.save')}
            </Button>
          </Col>
        </Row>
      }
    >
      <div className={cn('mb-25 steel lh-12')}>
        {t('services.vm.services.dialog.content') as string}
      </div>
      <Row
        alignItems="flex-start"
        justifyContent="space-between"
        columnSpacing={2}
        className="mb-25"
      >
        <Col xs>
          <Row className="full-width" columnGap={2} alignItems="flex-start">
            <Col xs={5}>
              <Select
                name="nicId"
                label="forms.network"
                value={form.values.nicId}
                onChange={(nicId) => form.setFieldValue('nicId', nicId)}
                useAsync
                defaultOptions
                onLoad={getNicsOptions(query.id)}
              />
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>

      <AddPublicServiceForm useAddButton={false} formConfig={form} />
      <div className="mb-25">
        <Switch
          label={t('services.vm.services.dialog.switchers.anySource')}
          checked={values.anySource}
          onCheck={(anySource) => setFieldValue('anySource', anySource)}
        />
      </div>
      {!values.anySource && (
        <>
          <VmTabs onChange={handleTabChange} value={values.tab}>
            <VmTab
              index={0}
              label={t('services.vm.services.dialog.tab.firewall')}
            />
            {isProvider && (
              <VmTab
                index={1}
                label={t('services.vm.services.dialog.tab.geoip')}
              />
            )}
          </VmTabs>
          <TabPanel
            value={0}
            index={values.tab}
            className="pt-25 pb-25"
            style={STYLES.tab}
          >
            <FirewallIpAddresses {...form} />
          </TabPanel>
          {isProvider && (
            <TabPanel
              value={1}
              index={values.tab}
              className="pt-25 pb-25"
              style={STYLES.tab}
            >
              <GeoIp {...form} />
            </TabPanel>
          )}
        </>
      )}
    </Dialog>
  );
});

export default Renderer;
