import * as React from 'react';
import * as R from 'ramda';
import {
  Select,
  IconButton,
  MuiIcons,
  AddIcon,
  Row,
  Col,
  Alert,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { getCountries } from 'utils';
import * as TYPES from './types';

type IProps = ReturnType<typeof useFormik<TYPES.IPublicServiceFormValues>>;

const GeoIp = (props: IProps) => {
  const { t } = useTranslation();

  const countryCodes = props.values.countryCodes;

  const countries = React.useMemo(
    () => R.filter((el) => !R.includes(el, countryCodes), getCountries()),
    [countryCodes]
  );

  const isMaxReached = props.values.countryCodes.length >= 5;

  const onAddSource = React.useCallback(() => {
    props.setValues({
      ...props.values,
      countryCodes: [...countryCodes, props.values.geoip as any],
      geoip: null as any,
    });
  }, [JSON.stringify(props.values)]);

  const onDelete = React.useCallback(
    (ip: string) => {
      props.setFieldValue(
        'countryCodes',
        countryCodes.filter((el) => el.value !== ip)
      );
    },
    [countryCodes]
  );

  return (
    <>
      <Row className="mb-25" alignItems="flex-start">
        <Col xs>
          <Select
            name="geoip"
            label="forms.geoip"
            placeholder="forms.placeholders.country"
            onChange={(geoip) => props.setFieldValue('geoip', geoip)}
            value={props.values.geoip}
            error={!!props.errors.geoip}
            helperText={props.errors.geoip}
            options={countries}
          />
        </Col>
        <AddIcon
          className="ml-15 mt-30"
          onClick={onAddSource}
          disabled={isMaxReached}
        />
      </Row>
      <div>
        <div className="steel fs-12 mb-10">{t('forms.source')}</div>
        <div className="mb-15">
          {!countryCodes.length ? (
            <div className="fs-14 steel">{t('common.noData')}</div>
          ) : (
            countryCodes.map((c, ind) => {
              return (
                <Row
                  key={c.value + ind}
                  alignItems="center"
                  justifyContent="space-between"
                  className="mb-5"
                >
                  <Col>
                    {c.value}, {c.label}
                  </Col>
                  <Col>
                    <IconButton size="small" onClick={() => onDelete(c.value)}>
                      <MuiIcons.Delete className="fs-18" />
                    </IconButton>
                  </Col>
                </Row>
              );
            })
          )}
        </div>
        {!!props.submitCount && !!props.errors.countryCodes && (
          <Alert severity="error">
            {t(props.errors.countryCodes as string)}
          </Alert>
        )}
      </div>
    </>
  );
};

export default GeoIp;
