import * as React from 'react';
// @ts-ignore
import { convertToHTML } from 'draft-convert';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  CompositeDecorator,
  Entity,
  DraftDecorator,
  ContentBlock,
} from 'draft-js';
import {
  ICreateSoftwarePackageValues,
  ISoftwarePackage,
  ICreateSoftwarePackage,
} from 'app-store';
import { COST_BASIS_OPTIONS } from './constants';
import { AnyShape } from 'global-shapes';

const Link = (props: any) => {
  const data = Entity.get(props.entityKey).getData();
  return <a {...data}>{props.children}</a>;
};

export const decorators: DraftDecorator[] = [
  { strategy: findLinkEntities, component: Link },
];

function findLinkEntities(
  block: ContentBlock,
  callback: (start: number, end: number) => void
) {
  block.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return entityKey !== null && Entity.get(entityKey).getType() === 'LINK';
  }, callback);
}

export const reformatPackageValuesForForm = (
  values: ISoftwarePackage
): ICreateSoftwarePackageValues => {
  const blocksFromHTML = convertFromHTML(values.description);
  const description = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  return {
    logo: values.icon && values.icon.link,
    name: values.name || '',
    description: EditorState.createWithContent(
      description,
      new CompositeDecorator(decorators)
    ),
    manufacturer: values.manufacturer || '',
    identifier: values.identifier || '',
    installParams: values.installParams || '',
    isEnabled: !!values.isEnabled,
    isUninstallable: !!values.isUninstallable,

    requiresLicense: !!values.licenseSettings,
    providersLicense:
      !!values.licenseSettings && !!values.licenseSettings.providersLicense,
    ownLicense: !!values.licenseSettings && !!values.licenseSettings.ownLicense,
    ownLicenseDescription:
      (values.licenseSettings &&
        values.licenseSettings.ownLicense &&
        values.licenseSettings.ownLicense.description) ||
      '',
    ownActivationCommand:
      (values.licenseSettings &&
        values.licenseSettings.ownLicense &&
        values.licenseSettings.ownLicense.activationCommand) ||
      '',
    ownKeyFormat:
      (values.licenseSettings &&
        values.licenseSettings.ownLicense &&
        values.licenseSettings.ownLicense.keyFormat) ||
      '',
    ownActivationParams:
      (values.licenseSettings &&
        values.licenseSettings.ownLicense &&
        values.licenseSettings.ownLicense.activationParams) ||
      '',

    providerActivationCommand:
      (values.licenseSettings &&
        values.licenseSettings.providersLicense &&
        values.licenseSettings.providersLicense.activationCommand) ||
      '',
    providerActivationParams:
      (values.licenseSettings &&
        values.licenseSettings.providersLicense &&
        values.licenseSettings.providersLicense.activationParams) ||
      '',
    providerCostBasis:
      values.licenseSettings && values.licenseSettings.providersLicense
        ? COST_BASIS_OPTIONS.find(
            (opt) =>
              opt.value === values.licenseSettings?.providersLicense?.costBasis
          )
        : undefined,
    providerLicensingInterval:
      (values.licenseSettings &&
        values.licenseSettings.providersLicense &&
        values.licenseSettings.providersLicense.licensingInterval) ||
      1,
    providerMinLicenseUnit:
      (values.licenseSettings &&
        values.licenseSettings.providersLicense &&
        values.licenseSettings.providersLicense.minLicenseUnit) ||
      1,
    providerLicenseCost:
      (values.licenseSettings &&
        values.licenseSettings.providersLicense &&
        values.licenseSettings.providersLicense.cost) ||
      1,
  };
};

export const reformatPackageFormValues = (
  values: ICreateSoftwarePackageValues
): ICreateSoftwarePackage => {
  const payload: ICreateSoftwarePackage = {
    name: values.name,
    manufacturer: values.manufacturer,
    description: convertToHTML({
      entityToHTML: (entity: AnyShape, originalText: string) => {
        if (entity.type === 'LINK') {
          return (
            <a href={entity.data.url} target={entity.data.targetOption}>
              {originalText}
            </a>
          );
        }
        return originalText;
      },
    })(values.description?.getCurrentContent()),
    identifier: values.identifier,
    installParams: values.installParams,
    isEnabled: values.isEnabled,
    isUninstallable: values.isUninstallable,
  };

  if (values.requiresLicense) {
    payload.licenseSettings = {};
    if (values.ownLicense) {
      payload.licenseSettings.ownLicense = {
        description: values.ownLicenseDescription,
        activationCommand: values.ownActivationCommand,
        activationParams: values.ownActivationParams,
        keyFormat: values.ownKeyFormat,
      };
    }
    if (values.providersLicense) {
      payload.licenseSettings.providersLicense = {
        minLicenseUnit: +(values?.providerMinLicenseUnit || 0),
        licensingInterval: +(values?.providerLicensingInterval || 0),
        cost: values.providerLicenseCost ? +values.providerLicenseCost : 0,
        activationCommand: values.providerActivationCommand,
        activationParams: values.providerActivationParams,
        costBasis: values.providerCostBasis?.value,
      };
    }
  }

  return payload;
};

export const stripHtml = (html: string) => {
  // Create a new div element
  const temporalDivElement = document.createElement('div');
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
};
