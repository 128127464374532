import * as React from 'react';
import { IconButton, styled } from 'elements';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { Emitter, remCalc } from 'utils';
import { CustomEvents, PALETTE } from 'enums';
import { useTranslation } from 'react-i18next';
import { SystemMessageStatus } from 'global-shapes';
import CustomSystemMessage from './CustomSystemMessage';

interface SystemMessageProps {
  title: string;
  status: SystemMessageStatus;
}

interface ISnackPackStateItem {
  message: string;
  key: number;
  severity: SystemMessageStatus;
}

const AppSnackBar = styled(Snackbar)(({ theme }) => ({
  top: remCalc(75),

  '& .MuiSnackbarContent-action svg': {
    width: remCalc(20),
    height: remCalc(20),
  },

  '& .MuiPaper-root': {
    backgroundColor: PALETTE.white,
    color: '#222222',
  },
}));

export default function ConsecutiveSnackbars() {
  const { t } = useTranslation();

  const [snackPack, setSnackPack] = React.useState<ISnackPackStateItem[]>([]);

  const [open, setOpen] = React.useState(false);

  const [messageInfo, setMessageInfo] = React.useState<
    ISnackPackStateItem | undefined
  >(undefined);

  const handleSystemMessage = React.useCallback((opts: SystemMessageProps) => {
    setSnackPack((prev) => [
      ...prev,
      { message: opts.title, key: new Date().getTime(), severity: opts.status },
    ]);
  }, []);

  React.useEffect(() => {
    Emitter.on(CustomEvents.showSystemMessage, handleSystemMessage);
    return () => {
      Emitter.off(CustomEvents.showSystemMessage, handleSystemMessage);
    };
  }, []);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = React.useCallback(
    (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    },
    []
  );

  const handleExited = React.useCallback(() => {
    setMessageInfo(undefined);
  }, []);

  return (
    <AppSnackBar
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      message={
        messageInfo ? (
          <CustomSystemMessage
            title={t(messageInfo.message)}
            status={messageInfo.severity}
          />
        ) : undefined
      }
      action={
        <IconButton
          aria-label="close"
          color="default"
          sx={{ p: 0.5 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
}
