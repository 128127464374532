import React, { useCallback, useMemo } from 'react';
import { ALL_TEST_IDS, defaultNavQuery } from 'enums';
import { Attributes } from 'forwarded-ports';
import { getBadgeStateKey } from 'utils';
import { useTask, useUserHash, usePermissions } from 'hooks';
import { AnyFunc } from 'global-shapes';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import CoreInfrastructureContent from '../contents/CoreInfrastructure';
import { defineCoreDisplayStatus } from '../../../helpers';
import { withCoreActions, InjectedProps } from 'hocs/withCoreActions';
import { isEmpty } from 'ramda';
import { IAddServiceCallback } from 'services-shapes';
import { ICoreInfrastructure } from 'core-infrastructure';
import { SCoreCard } from './Styled';

type Props = InjectedProps<ICoreInfrastructure> & {
  onAdd: IAddServiceCallback;
  onDelete: AnyFunc;
  onOpenRS: (attr: Attributes) => void;
  connectivitySetupCompleted: boolean;
};

const OBSERVERS = {
  rds: StateHandlers.rds,
  tenantPricing: StateHandlers.tenantPricing,
  costBreakdown: StateHandlers.costBreakdown,
  assignments: StateHandlers.badgesAssignment,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: Props & IViewProps) => {
  const {
    connectivitySetupCompleted,
    onAdd,
    menuItems,
    instance,
    assignments,
    tenantPricing,
    costBreakdown,
    rds,
  } = props;
  const [, changeUrl] = useUserHash();
  const { permissions } = usePermissions('SERVICES');

  const entityAssignments =
    assignments.data[getBadgeStateKey(instance.data)] || [];

  const coreData = instance.data;

  const displayStatus = useMemo(defineCoreDisplayStatus(coreData), [
    JSON.stringify(coreData),
  ]);

  const fetchQuotas = useCallback(async () => {
    await tenantPricing.get();
    return costBreakdown.get();
  }, []);

  const fetchCore = useCallback(() => instance.get(), []);

  const onOpenDetails = useCallback(
    () => changeUrl(`/services/infrastructure/accounts`, defaultNavQuery),
    [defaultNavQuery]
  );

  useTask(coreData?.vm?.task, {
    onComplete: () => {
      fetchQuotas();
      fetchCore();
    },
  });

  const rdsData = rds.data;
  const rdsHost = rdsData?.host || null;

  const _rdsTask = useTask(rdsData?.task || null);
  const rdsHostTask = useTask(rdsHost?.task || null);
  const task = useTask(coreData?.task, { onComplete: fetchCore });
  const isEmptyCore = !coreData || isEmpty(coreData);

  const isDisabled = instance.data
    ? instance.isRequesting ||
      !connectivitySetupCompleted ||
      task.isTaskActive ||
      displayStatus === 'AGENT_INIT'
    : !connectivitySetupCompleted;

  return (
    <SCoreCard
      createId="create-core-button"
      onAdd={() => permissions.canManage && onAdd('coreInfrastructure')}
      isDisabled={isDisabled}
      placeholder="services.content.placeholder.infrastructure"
      isEmpty={isEmptyCore}
      status={displayStatus}
      title={'services.card.infrastructure'}
      testId={ALL_TEST_IDS.services.coreInfrastructure.main}
      dataReceived={instance.dataReceived}
      assignments={entityAssignments}
      menuItems={
        coreData?.vm && !_rdsTask.isTaskActive && !rdsHostTask.isTaskActive
          ? menuItems
          : undefined
      }
      onTitleClick={onOpenDetails}
    >
      <CoreInfrastructureContent {...(coreData || {})} />
    </SCoreCard>
  );
});

const CoreInfrastructureCard = (props: Props) => {
  return <View {...props} {...OBSERVERS} />;
};

export default withCoreActions(CoreInfrastructureCard);
