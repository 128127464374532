import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks';
import { partnersService } from 'services';
import { Alert } from 'elements';

interface IQuery {
  key?: string;
  partner?: string;
  inviteKey?: string;
}

const InviteProxy = () => {
  const { t } = useTranslation();
  const { query, changeQuery } = useQuery<IQuery>();

  const [isValid, handleState] = React.useState(true);

  React.useEffect(() => {
    partnersService.resolveInvitationSettings(query.key || '').then((res) => {
      handleState(res.data.enabled);
      if (res.data.enabled) {
        changeQuery(
          { inviteKey: query.key, partner: query.partner },
          '/customer-sign-up',
          true
        );
      }
    });
  }, []);

  return isValid ? null : (
    <Alert severity="error">{t('auth.invite.invalidLink')}</Alert>
  );
};

export default InviteProxy;
