import * as React from 'react';
import { Button } from 'elements';
import { useTranslation, Trans } from 'react-i18next';
import { EmptyBlockHolder } from './Styled';
import { IEntityType } from './types';

type IProps = {
  entity: IEntityType;
  isEditable: boolean;
  onAdd: () => void;
};

const EmptyState = (props: IProps) => {
  const { entity, onAdd, isEditable } = props;
  const { t } = useTranslation();
  return (
    <EmptyBlockHolder>
      <div>
        <div className="mb-25">
          <Trans
            i18nKey={`monitoring.${entity}.empty.text`}
            components={{
              1: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  rel="noreferrer"
                  href="https://docs.awesome.cloud/docs/monitoring"
                  target="_blank"
                />
              ),
            }}
          />
        </div>
        <Button onClick={onAdd} disabled={!isEditable}>
          {t('monitoring.buttons.initialManageBilling')}
        </Button>
      </div>
    </EmptyBlockHolder>
  );
};

export default EmptyState;
