import { DELETE, GET, POST, PUT } from 'utils';
import {
  IBackup,
  IBackupEntity,
  IBackupNotificationValues,
  IBackupNotificationsWithEmails,
  ResoreVersionPayload,
  IBackupVersion,
  INewBackup,
} from 'backup';
import { ICommonListParams } from 'global-shapes';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';

export const getBackup =
  (entityName: IBackupEntity) =>
  (entityId: number): AxiosPromise<IBackup> => {
    if (entityName === 'CoreInfrastructure') {
      return GET(`/backup/core-infrastructure/${entityId}`);
    }
    if (entityName === 'Rds') {
      return GET(`/backup/rds/${entityId}`);
    }
    return GET(`/backup/vm/${entityId}`);
  };

export const createBackup = (data: INewBackup): AxiosPromise<IBackup> =>
  POST(`/backup`, {}, data);

export const updateBackup = (
  backupId: number,
  data: BackupTypes.UpdatePayload
): AxiosPromise<IBackup> => PUT(`/backup/${backupId}`, {}, data);

export const removeBackup = (backupId: number): AxiosPromise<Task> =>
  DELETE(`/backup/${backupId}`);

export const getBackupVersions = (
  backupId: number,
  params: ICommonListParams
): IAxiosPromise<IBackupVersion[]> =>
  GET(`/backup/${backupId}/versions`, params);

export const getBackupNotifications = (
  backupId: number,
  params: ICommonListParams
): IAxiosPromise<IBackupNotificationsWithEmails> =>
  GET(`/backup/${backupId}/notifications`, params);

export const updateBackupNotifications = (
  backupId: number,
  data: IBackupNotificationValues
): AxiosPromise<IBackupNotificationsWithEmails> =>
  PUT(`/backup/${backupId}/notifications`, {}, data);

export const createBackupNotificationEmails = (
  backupId: number,
  data: { email: string }
): AxiosPromise<IBackupNotificationsWithEmails> =>
  POST(`/backup/${backupId}/notifications/email`, {}, data);

export const deleteBackupNotificationEmails = (
  backupId: number,
  entityId: number
): AxiosPromise<Task> =>
  DELETE(`/backup/${backupId}/notifications/email/${entityId}`);

export const getBackupVersionVms = (
  backupId: number,
  versionId: number
): IAxiosPromise<IVmTypes.Vm[]> =>
  GET(`/backup/${backupId}/versions/${versionId}/vms`);

export const restoreVmBackup = (
  backupId: number,
  versionId: number,
  payload: ResoreVersionPayload
): IAxiosPromise<IVmTypes.Vm[]> =>
  POST(`/backup/${backupId}/versions/${versionId}/restore`, {}, payload);
