import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Paper } from 'elements';
import { useStateHandler } from 'hooks';
import * as StateHandlers from 'states';
import FirewallRulesContainer from './FirewallRulesContainer';
import NatRules from './NatRules';

const Main = observer(() => {
  const connectivity = useStateHandler(StateHandlers.connectivity);

  return (
    <Paper className="p-30">
      <FirewallRulesContainer connectivityId={connectivity.data?.id} />
      <NatRules connectivityId={connectivity.data?.id} />
    </Paper>
  );
});

export default Main;
