import * as React from 'react';
import { styled } from 'elements/utilities';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { PALETTE } from 'enums';

export const SNavLink = styled(
  (props: NavLinkProps & { isActive: boolean }) => {
    const { isActive, ...rest } = props;
    return <NavLink {...rest} />;
  }
)(({ theme, isActive }) => {
  return {
    color: isActive ? theme.palette.primary.main : PALETTE.steel,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  };
});
