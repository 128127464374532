import { styled } from './utilities';
import Typography from '@mui/material/Typography';

export const SPrimarySpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SPrimaryDiv = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SCustomList = styled('div')(({ theme }) => ({
  '& li:before': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export { Typography };
