import { BaseMobxInstanceDecorator } from './utilities';
import { agentService } from 'services';
import { IAgent } from 'agent';

export const agent = new BaseMobxInstanceDecorator<IAgent, false>({
  instanceName: 'agent',
  requests: {},
  initialState: {} as IAgent,
  additionalRequests: {
    getAgentInfo: agentService.getAgentInfo,
    runAgentUpdate: (id) => agentService.runAgentUpdate(id),
  },
});
