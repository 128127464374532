import React, { useMemo } from 'react';
import cn from 'classnames';
import {
  Alert,
  Button,
  Col,
  Dialog,
  generateSortingIndicator,
  IconButton,
  Input,
  MuiIcons,
  Row,
  Tooltip,
} from 'elements';
import * as R from 'ramda';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { coreInfService, rdsService } from 'services';
import { Trans, useTranslation } from 'react-i18next';
import { usePagination } from 'react-use-pagination';
import { resetTimeValue } from 'components/RebootSchedules/helpers';
import { PlanRebootDialog } from 'components';
import { IRdsUserPayload } from 'rds';
import {
  confirm,
  dayjs,
  debounce,
  numberToCurrency,
  remCalc,
  showSystemMessage,
  utcDayjs,
} from 'utils';
import { CostResourceIds } from 'enums';
import { usePrice, useState, useStateHandler } from 'hooks';
import ChangeLogItem from './ChangeLogItem';
import UserRow from './UserRow';
import {
  INITIAL_RDS_USERS_DIALOG_QUERY,
  INITIAL_STATE,
  typeStringsMap,
} from './constants';
import {
  groupUserByType,
  parseUsersToForm,
  parseValuesForApi,
} from './helpers';
import { IDialogQuery, IProps, IState } from './types';

const EditPerformanceProfile = observer((props: IProps) => {
  const { onSave, open, onClose, testId, coreDomain, ...rest } = props;
  const { t } = useTranslation();
  const rdsUsers = useStateHandler(StateHandlers.rdsUsers);
  const allUsers = useStateHandler(StateHandlers.rdsAllUsers);
  const { totalCount } = allUsers.meta;
  const [query, changeQuery] = useState<IDialogQuery>(
    INITIAL_RDS_USERS_DIALOG_QUERY
  );
  const searchRef = React.useRef({ value: query.q || '' });
  const {
    setNextPage,
    setPreviousPage,
    nextEnabled,
    previousEnabled,
    startIndex,
    endIndex,
    currentPage,
    setPage,
  } = usePagination({
    totalItems: totalCount,
    initialPageSize: INITIAL_RDS_USERS_DIALOG_QUERY.perPage,
    initialPage: INITIAL_RDS_USERS_DIALOG_QUERY.page - 1,
  });
  const [state, handleStateChange] = useState<IState>(INITIAL_STATE);

  const prices = usePrice([
    CostResourceIds.rdsBase,
    CostResourceIds.rdsStandardUser,
    CostResourceIds.rdsLightUser,
    CostResourceIds.rdsPowerUser,
    CostResourceIds.rds2faLicense,
  ]);

  const confirmNoReboot = React.useCallback(
    (users: IRdsUserPayload[]) => {
      confirm({
        title: t('services.rds.confirm.performance.noReboot.title'),
        content: t('services.rds.confirm.performance.noReboot.subtitle'),
        onSuccess: () => onSave && onSave({ users }),
        onCancel: () => undefined,
      });
    },
    [onSave]
  );

  const {
    handleSubmit,
    setFieldValue,
    values,
    dirty,
    resetForm,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues: {} as any,
    validateOnMount: false,
    onSubmit: async (val) => {
      const changedUsers = parseValuesForApi(val, state.initials);
      const users = changedUsers.map((el) => R.omit(['details'], el));

      if (!changedUsers.length) return null;

      return confirm({
        title: t('services.rds.confirm.performance.changeLog.title'),
        content: (
          <>
            <div className="steel mb-25">
              {t('services.rds.confirm.performance.changeLog.content')}
            </div>
            {changedUsers.map((u) => {
              const prevType =
                typeStringsMap[
                  state.initials[u.coreInfraUserId]?.type as number
                ];
              const prev2FA = !!state.initials[u.coreInfraUserId]?.is2faEnabled;
              const prevBypass =
                !!state.initials[u.coreInfraUserId]?.twoFaBypass;
              return (
                <ChangeLogItem
                  key={`log-${u.coreInfraUserId}`}
                  {...u}
                  {...{ prev2FA, prevBypass, prevType }}
                />
              );
            })}
          </>
        ),
        successLabel: 'common.confirm',
        onSuccess: async () => {
          const { data: validated } = await rdsService.validate({ users });
          if (!validated.isValid) {
            return showSystemMessage(validated.errorMessage, 'error');
          }

          if (validated.otherAttributes?.requireMaintenanceWindow) {
            return handleStateChange({
              maintenanceDialog: true,
              validatedValues: {
                require2FAServiceModification:
                  validated.otherAttributes?.require2FAServiceModification,
                resourceDiff:
                  validated.otherAttributes?.maxPercentResourceDifference,
              },
            });
          }

          if (validated.otherAttributes?.require2FAServiceModification) {
            return confirmNoReboot(users);
          }

          return onSave && onSave({ users });
        },
        onCancel: () => undefined,
      });
    },
  });

  const changeSearchUrl = React.useCallback(
    (q: string) => {
      changeQuery({ ...query, q });
      setPage(0);
    },
    [JSON.stringify(query)]
  );

  const onSearchClear = React.useCallback(() => {
    searchRef.current.value = '';
    changeSearchUrl('');
  }, [changeSearchUrl, searchRef.current]);

  const onItemSearch = React.useCallback(
    debounce(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        changeSearchUrl(ev.target.value),
      1000
    ),
    [JSON.stringify(query)]
  );

  const onMaintenanceSave = React.useCallback(
    (rebootValues: IRebootDialogTypes.Values) => {
      const isForced = rebootValues.configureType === 'now';
      const users = parseValuesForApi(values, state.initials);
      const startDate = resetTimeValue(dayjs(rebootValues.startDate));

      const saveRequest = async () => {
        if (onSave) {
          await onSave({
            users,
            maintenanceWindow: {
              message: '',
              notificationEnabled: rebootValues.notificationEnabled || false,
              isForced,
              startAt: utcDayjs(startDate).toISOString(),
            },
          });
        }

        handleStateChange({
          maintenanceDialog: false,
          validatedValues: undefined,
        });
      };

      if (!isForced && state.validatedValues?.require2FAServiceModification) {
        return confirm({
          title: t('services.rds.confirm.performance.withReboot.title'),
          content: t('services.rds.confirm.performance.withReboot.subtitle'),
          onSuccess: saveRequest,
          onCancel: () => undefined,
        });
      }

      return saveRequest();
    },
    [values, state.initials, state.validatedValues]
  );

  const currentQuantity = useMemo(
    () => groupUserByType(state.initials),
    [JSON.stringify(state.initials)]
  );

  const quantity = useMemo(
    () => groupUserByType(values),
    [JSON.stringify(values)]
  );

  const baseFee = prices[CostResourceIds.rdsBase]?.monthly;

  const currentLightUserFee =
    prices[CostResourceIds.rdsLightUser]?.monthly * currentQuantity.type[1];

  const currentStandardUserFee =
    prices[CostResourceIds.rdsStandardUser]?.monthly * currentQuantity.type[2];

  const currentPowerUserFee =
    prices[CostResourceIds.rdsPowerUser]?.monthly * currentQuantity.type[3];

  const current2FaFee =
    prices[CostResourceIds.rds2faLicense]?.monthly * currentQuantity.faLicense;

  const lightUserFee =
    prices[CostResourceIds.rdsLightUser]?.monthly * quantity.type[1];

  const standardUserFee =
    prices[CostResourceIds.rdsStandardUser]?.monthly * quantity.type[2];

  const powerUserFee =
    prices[CostResourceIds.rdsPowerUser]?.monthly * quantity.type[3];

  const faFee =
    prices[CostResourceIds.rds2faLicense]?.monthly * quantity.faLicense;

  React.useEffect(() => {
    if (!open) {
      resetForm();
      handleStateChange({ initials: {} });
      setPage(0);
      onSearchClear();
      changeQuery(INITIAL_RDS_USERS_DIALOG_QUERY);
    }
    if (open) {
      allUsers.get({ ...query, page: currentPage + 1 });
      coreInfService
        .getUsers({
          ...INITIAL_RDS_USERS_DIALOG_QUERY,
          include: 'rdsUser',
          perPage: 1000,
        })
        .then((res) => {
          const newValues = parseUsersToForm(res.data || []);
          handleStateChange({ initials: newValues });
          setValues(newValues);
          return res;
        });
    }
  }, [open]);

  React.useEffect(() => {
    if (open) {
      allUsers.get({ ...query, page: currentPage + 1 });
    }
  }, [JSON.stringify(query), currentPage]);

  const start = startIndex + 1;
  const end = endIndex + 1;

  return (
    <>
      <Dialog
        {...rest}
        title={t('services.rds.users.dialog.performance.title')}
        open={open}
        handleSubmit={handleSubmit}
        onClose={onClose}
        testId={testId}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: { maxWidth: remCalc(1330), minWidth: remCalc(1260) },
        }}
        contentProps={{ style: { overflow: 'hidden' } }}
        actions={
          <Row justifyContent="flex-end">
            <Button
              className="mr-15"
              color="default"
              variant="outlined"
              onClick={onClose}
              testId={`${testId}-cancel`}
            >
              {t('common.cancel')}
            </Button>
            <Button
              type="submit"
              testId={`${testId}`}
              disabled={isSubmitting || !dirty || rdsUsers.isRequesting}
            >
              {t('common.save')}
            </Button>
          </Row>
        }
      >
        <Row columnSpacing={3}>
          <Col xs={7}>
            <div className="steel">
              <Trans
                i18nKey="services.rds.users.dialog.performance.subtitle"
                components={{ 1: <div className="bold mt-15 mb-20" /> }}
              />
            </div>
            <Input
              placeholder={t('forms.placeholders.search')}
              ref={searchRef}
              type="search"
              inputClassName={cn('bg-white')}
              className="mb-25"
              onKeyUp={(e: any) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  changeSearchUrl(e.target.value);
                }
              }}
              onClear={onSearchClear}
              onChange={onItemSearch}
            />
            <Row
              columnSpacing={1}
              className="fs-12 steel light-bordered-bottom pb-5"
            >
              <Col xs={4}>
                {t('table.head.user')}
                <IconButton
                  size="small"
                  className="ml-5 p-0"
                  onClick={() => {
                    const nextOrderType =
                      query?.orderType === 'desc' ? 'asc' : 'desc';
                    changeQuery({ orderType: nextOrderType });
                  }}
                >
                  {generateSortingIndicator('firstName', query || {})}
                </IconButton>
              </Col>
              <Col xs={1}>{t('table.head.2fa')}</Col>
              <Col xs={1}>{t('table.head.bypass')}</Col>
              <Col xs={6}>
                <Row alignItems="center">
                  <Col xs={3}>{t('table.head.disabled')}</Col>
                  <Col xs={3}>
                    <div className="flex align-center justify-center">
                      <span>{t('table.head.1.2.app')}</span>
                      <span className="flex align-end">
                        <Tooltip
                          title={t(
                            'services.rds.users.dialog.performance.tooltips.lightAppUsers'
                          )}
                          placement="top"
                          arrow
                        >
                          <sup>
                            <MuiIcons.HelpOutline className="fs-12 info ml-5" />
                          </sup>
                        </Tooltip>
                      </span>
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div className="flex align-center justify-end">
                      <span>{t('table.head.multiApps')}</span>
                      <span className="flex align-end">
                        <Tooltip
                          title={t(
                            'services.rds.users.dialog.performance.tooltips.multiAppUsers'
                          )}
                          placement="top"
                          arrow
                        >
                          <sup>
                            <MuiIcons.HelpOutline className="fs-12 info ml-5" />
                          </sup>
                        </Tooltip>
                      </span>
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div className="flex align-center justify-end">
                      <span>{t('table.head.heavyApps')}</span>
                      <span className="flex align-end">
                        <Tooltip
                          title={t(
                            'services.rds.users.dialog.performance.tooltips.heavyAppUsers'
                          )}
                          placement="top"
                          arrow
                        >
                          <sup>
                            <MuiIcons.HelpOutline className="fs-12 info ml-5" />
                          </sup>
                        </Tooltip>
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className={cn('mb-15', { disabled: allUsers.isRequesting })}>
              {!totalCount ? (
                <div className="pt-35 pb-35 steel text-center fs-12">
                  {t('common.noData')}
                </div>
              ) : (
                allUsers.data.map((u) => (
                  <UserRow
                    key={u.id}
                    {...u}
                    coreDomain={coreDomain}
                    value={values[u.id] || undefined}
                    onChange={(val) => {
                      const newValue = { ...values[u.id], ...val };
                      setFieldValue(u.id.toString(), newValue);
                    }}
                  />
                ))
              )}
            </div>
            {totalCount > INITIAL_RDS_USERS_DIALOG_QUERY.perPage && (
              <div className="flex align-center justify-end full-width fs-14 steel">
                <div className="mr-15">
                  {start}-{end} of {totalCount}
                </div>

                <MuiIcons.ArrowBackIosNew
                  onClick={setPreviousPage}
                  className={cn('pointer fs-14', {
                    disabled: !previousEnabled,
                  })}
                />

                <MuiIcons.ArrowForwardIos
                  onClick={setNextPage}
                  className={cn('pointer fs-14 ml-15', {
                    disabled: !nextEnabled,
                  })}
                />
              </div>
            )}
          </Col>
          <Col xs={5}>
            <Row
              direction="column"
              justifyContent="space-between"
              columnSpacing={2}
              style={{
                height: '100%',
                minHeight: remCalc(470),
                paddingLeft: remCalc(10),
              }}
            >
              <Col className="full-width">
                <h5 className="mb-25 primary">{t('costsInfo.title')}</h5>
                <Row
                  justifyContent="space-between"
                  columnSpacing={2}
                  className="fs-10 steel uppercase mb-15"
                >
                  <Col xs={7}>
                    <span>{t('costsInfo.head.serviceName')}</span>
                  </Col>
                  <Col xs={2} className="text-right">
                    <span>{t('costsInfo.head.quantity')}</span>
                  </Col>
                  <Col xs={3} className="text-right">
                    <span>{t('costsInfo.head.price')}</span>
                  </Col>
                </Row>
                <h5 className="mb-15">{t('costsInfo.remoteDesktop')}</h5>
                <div className="custom-list fs-16">
                  <ul>
                    <li className="mb-10">
                      <Row>
                        <Col xs={7}>
                          <span>{t('costsInfo.baseFee')}</span>
                        </Col>
                        <Col xs={2} />
                        <Col xs={3} className="text-right steel">
                          <span>{numberToCurrency(baseFee, false)}</span>
                        </Col>
                      </Row>
                    </li>
                    <li className="mb-10">
                      <Row>
                        <Col xs={7}>
                          <span>
                            {t('costsInfo.remoteDesktop.lightAppUsers')}
                          </span>
                        </Col>
                        <Col xs={2} className="text-right steel">
                          <span>{quantity.type[1]}</span>
                        </Col>
                        <Col xs={3} className="text-right steel">
                          <span>{numberToCurrency(lightUserFee, false)}</span>
                        </Col>
                      </Row>
                    </li>
                    <li className="mb-10">
                      <Row>
                        <Col xs={7}>
                          <span>
                            {t('costsInfo.remoteDesktop.multiAppUsers')}
                          </span>
                        </Col>
                        <Col xs={2} className="text-right steel">
                          <span>{quantity.type[2]}</span>
                        </Col>
                        <Col xs={3} className="text-right steel">
                          <span>
                            {numberToCurrency(standardUserFee, false)}
                          </span>
                        </Col>
                      </Row>
                    </li>
                    <li className="mb-10">
                      <Row>
                        <Col xs={7}>
                          <span>
                            {t('costsInfo.remoteDesktop.heavyAppUsers')}
                          </span>
                        </Col>
                        <Col xs={2} className="text-right steel">
                          <span>{quantity.type[3]}</span>
                        </Col>
                        <Col xs={3} className="text-right steel">
                          <span>{numberToCurrency(powerUserFee, false)}</span>
                        </Col>
                      </Row>
                    </li>
                    <li className="mb-10">
                      <Row>
                        <Col xs={7}>
                          <span>{t('costsInfo.remoteDesktop.2faLicense')}</span>
                        </Col>
                        <Col xs={2} className="text-right steel">
                          <span>{quantity.faLicense}</span>
                        </Col>
                        <Col xs={3} className="text-right steel">
                          <span>{numberToCurrency(faFee, false)}</span>
                        </Col>
                      </Row>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col className="full-width">
                <Alert severity="info" className="mb-10">
                  {t(
                    'services.rds.users.dialog.performance.alerts.2faLicenseCost'
                  )}
                </Alert>
                <div className="flex justify-between align-center mb-5">
                  <div className="fs-14">
                    {t('costsInfo.currentTotalMonthly')}
                  </div>
                  <span className="fs-16 bold">
                    {numberToCurrency(
                      R.sum([
                        baseFee,
                        currentLightUserFee,
                        currentStandardUserFee,
                        currentPowerUserFee,
                        current2FaFee,
                      ]),
                      false
                    )}
                  </span>
                </div>
                <div className="flex justify-between align-center">
                  <h5 className="fs-17">{t('costsInfo.totalMonthly')}</h5>
                  <span className="fs-20 bold primary">
                    {numberToCurrency(
                      R.sum([
                        baseFee,
                        lightUserFee,
                        standardUserFee,
                        powerUserFee,
                        faFee,
                      ]),
                      false
                    )}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Dialog>
      <PlanRebootDialog
        title={t('services.rds.maintenance.dialog.title')}
        subtitle={t('services.rds.maintenance.dialog.subtitle', {
          resourceDiff: state.validatedValues?.resourceDiff,
        })}
        open={state.maintenanceDialog}
        onClose={() => handleStateChange({ maintenanceDialog: false })}
        onSave={onMaintenanceSave}
        isRdsReconfiguration
      />
    </>
  );
});

export default EditPerformanceProfile;
