import { DELETE, GET, POST, PUT, buildListParams } from 'utils';
import { pick, mergeDeepRight } from 'ramda';
import {
  IAdmin,
  ICoreCreatePayload,
  ICoreRequestParams,
  ICoreDomainNameValidateParams,
  ICoreDomainNameValidateResponse,
  ICoreInfrastructure,
  IUser,
} from 'core-infrastructure';
import { AxiosPromise } from 'axios';
import { ICommonListParams } from 'global-shapes';
import { IAxiosPromise } from 'axios-shapes';

export const get = (
  params?: ICoreRequestParams
): AxiosPromise<ICoreInfrastructure> =>
  GET(
    '/core-infrastructure',
    mergeDeepRight(params || {}, {
      include: ['serviceMonitoring', 'serviceSnapshot', 'servicePatching'],
    })
  );

export const check = (): AxiosPromise<ICoreInfrastructure> =>
  PUT('/core-infrastructure/check/core');

export const create = (
  data: ICoreCreatePayload
): AxiosPromise<ICoreInfrastructure> => POST('/core-infrastructure', {}, data);

export const removeCore = (deletionReason: string): AxiosPromise<Task> =>
  DELETE('/core-infrastructure', {}, { deletionReason });

export const getAllUsers = (
  params: ICommonListParams
): IAxiosPromise<IUser[]> =>
  GET(
    '/core-infrastructure/users',
    buildListParams(
      {
        ...params,
      },
      ['orderType', 'orderBy', 'include']
    )
  );

export const getAdmins = (params: ICommonListParams): IAxiosPromise<IUser[]> =>
  GET(
    '/core-infrastructure/users',
    buildListParams(
      {
        ...params,
        type: 'ADMIN',
      },
      ['type', 'orderType', 'orderBy', 'include']
    )
  );

export const createAdmin = (admin: IAdmin): AxiosPromise<IUser> => {
  const payload = pick([
    'firstName',
    'lastName',
    'username',
    'email',
    'passwordSecure',
  ])(admin);
  // @ts-ignore
  payload.email = admin.email || undefined;
  return POST(
    '/core-infrastructure/users',
    {},
    {
      users: [
        {
          ...payload,
          username: payload.username.trim(),
          type: 'ADMIN',
        },
      ],
    }
  );
};

export const getUsers = (params: ICommonListParams): IAxiosPromise<IUser[]> =>
  GET(
    '/core-infrastructure/users',
    buildListParams({ ...params, type: 'USER' }, [
      'type',
      'orderType',
      'orderBy',
      'include',
      'q',
    ])
  );

export const getUser = (id: number): AxiosPromise<IUser> =>
  GET(`/core-infrastructure/users/${id}`);

export const createUser = (user: IUser): AxiosPromise<IUser> => {
  const payload = pick([
    'firstName',
    'lastName',
    'email',
    'username',
    'passwordSecure',
  ])(user);
  // @ts-ignore
  payload.email = user.email || undefined;

  return POST(
    '/core-infrastructure/users',
    {},
    {
      users: [
        {
          ...payload,
          username: payload.username.trim(),
          type: 'USER',
        },
      ],
    }
  );
};

export const updateUser = (id: number, data: IUser): AxiosPromise<IUser> => {
  const payload = pick(['firstName', 'lastName', 'email', 'passwordSecure'])(
    data
  );
  // @ts-ignore
  payload.email = data.email || null;
  payload.passwordSecure = data.resetPassword ? data.passwordSecure : undefined;
  return PUT(`/core-infrastructure/users/${id}`, {}, payload);
};

export const deleteUser = (id: number): AxiosPromise<Task> =>
  DELETE(`/core-infrastructure/users/${id}`);

export const updateDiskSize = (
  size: number
): AxiosPromise<ICoreInfrastructure> =>
  PUT(
    `/core-infrastructure/disk`,
    {},
    {
      fileShareDiskSizeGb: +size,
    }
  );

export const validateDomainName = (
  params: ICoreDomainNameValidateParams
): AxiosPromise<ICoreDomainNameValidateResponse> =>
  POST(`/core-infrastructure/validate-domain-name`, {}, params);

export const powerOn = (): AxiosPromise<ICoreInfrastructure> =>
  POST(`/core-infrastructure/action/power-on`);

export const powerOff = (): AxiosPromise<ICoreInfrastructure> =>
  POST(`/core-infrastructure/action/power-off`);

export const reset = (): AxiosPromise<ICoreInfrastructure> =>
  POST(`/core-infrastructure/action/reset`);

export const shutDown = (): AxiosPromise<ICoreInfrastructure> =>
  POST(`/core-infrastructure/action/shutdown`);

export const setPatching = (
  payload: IPatchingTypes.Values
): AxiosPromise<ICoreInfrastructure> => {
  const data = {
    alertEmailRecipient: payload.alertEmailRecipient,
    outsourceManagementEnabled: payload.outsourceManagementEnabled,
    maintenanceWindows: [
      {
        startAt: payload.startDate,
        isForced: false,
        notificationEnabled: payload.notificationEnabled,
        recurringInterval:
          payload.recurringInterval?.value === 'SINGLE' // as backend doesnt have SINGLE interval value, we need pass undefined instead
            ? null
            : payload.recurringInterval?.value,
      },
    ],
  };
  return POST(`/core-infrastructure/patching/config`, {}, data);
};
