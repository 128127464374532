const calc = (base: number, ...values: any) =>
  values.reduce((res: string, value: number | string) => {
    const val = ` ${Number(value) / base}rem`;
    res = (res + val).trim();
    return res;
  }, '');

export const important = (val: number | string): string => `${val} !important`;

export const remCalc = (...values: string[] | number[]) => calc(16, ...values);
