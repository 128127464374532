import * as React from 'react';
import { remCalc } from 'utils';
import { styled, Row, Col, ColorDot, LightTooltip } from 'elements';

const BadgesVerticalWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: remCalc(35),
  height: remCalc(24),
  '& > div': {
    position: 'absolute',
    left: remCalc(23),
    top: '50%',
    width: remCalc(8),
    transform: `translateY(-50%)`,
    '& > div div:not(:last-of-type)': {
      marginBottom: remCalc(3),
    },
  },
}));

const BadgesHorizontalWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: remCalc(20),
  bottom: remCalc(22),
}));

const Dot = (props: IColorDotProps & { description?: string }) => {
  if (props.description) {
    return (
      <LightTooltip title={props.description} placement="top" arrow>
        <ColorDot size={props.size} color={props.color} className="pointer" />
      </LightTooltip>
    );
  }
  return <ColorDot size={props.size} color={props.color} className="pointer" />;
};

export const VerticalBadges = (props: {
  className?: string;
  assignments: IBadgeAssignment[];
  size: number;
}) => {
  return (
    <BadgesVerticalWrapper className={props.className}>
      <div>
        <Row direction="column">
          {props.assignments.map((ent) => (
            <Col key={ent.badge?.id}>
              <Dot
                size={props.size}
                color={ent.badge?.color as string}
                description={ent.badge?.description}
              />
            </Col>
          ))}
        </Row>
      </div>
    </BadgesVerticalWrapper>
  );
};

export const HorizontalBadges = (props: {
  assignments: IBadgeAssignment[];
  size: number;
}) => {
  return (
    <BadgesHorizontalWrapper>
      <Row alignItems="center" columnSpacing={1}>
        {props.assignments.map((ent) => (
          <Col key={ent.badge?.id}>
            <Dot
              size={props.size}
              color={ent.badge?.color as string}
              description={ent.badge?.description}
            />
          </Col>
        ))}
      </Row>
    </BadgesHorizontalWrapper>
  );
};
