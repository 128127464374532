import React, { useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { shouldDisableNextStep } from './helpers';
import { ALL_TEST_IDS } from 'enums';
import ManageBillingEmails from 'components/ManageBillingEmailsContainer';
import { getCountries } from 'utils';
import { useUserHash } from 'hooks';
import { TabPanel, SDialog } from './Styled';
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  DialogTab,
  DialogTabs,
  Switch,
  Alert,
} from 'elements';
import {
  INITIAL_DIALOG_VALUES,
  customerInfoSchema,
  billingInfoSchema,
} from './constants';

type Props = {
  isNew: boolean;
  open: boolean;
  isRequesting: boolean;
  hasInvoiceSettings: boolean;
  isTicketingEnabled: boolean;
  initialValues: any | null;
  onClose: () => void;
  onSubmit: (payload: any) => void;
};

const TenantDetailsDialog = (props: Props) => {
  const {
    isNew,
    initialValues,
    onSubmit,
    hasInvoiceSettings,
    isRequesting,
    isTicketingEnabled,
    ...rest
  } = props;
  const [tabValue, setTab] = React.useState(0);
  const { t } = useTranslation();
  const [userHash] = useUserHash();

  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    setFieldValue,
    submitCount,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: INITIAL_DIALOG_VALUES,
    validateOnMount: false,
    validationSchema: tabValue === 0 ? customerInfoSchema : billingInfoSchema,
    onSubmit: (val) => {
      if (tabValue === 1) {
        return Promise.resolve(
          onSubmit({
            ...val,
            shouldUserValidate: true,
          })
        );
      }
      setTab(1);
    },
  });

  const countries = React.useMemo(getCountries, []);

  const isDisabledNextStep = useMemo(
    () => shouldDisableNextStep(errors),
    [JSON.stringify(errors)]
  );

  React.useEffect(() => {
    if (rest.open) setValues(initialValues || INITIAL_DIALOG_VALUES);
    if (!rest.open) {
      resetForm();
      setTab(0);
    }
  }, [JSON.stringify(initialValues), rest.open]);

  const handleTabChange = useCallback((event: any, newValue: any) => {
    setTab(newValue);
  }, []);

  const testId = useMemo(
    () =>
      isNew
        ? ALL_TEST_IDS.tenants.createTenant
        : ALL_TEST_IDS.tenants.editTenant,
    [isNew]
  );

  return (
    <SDialog
      {...rest}
      title={t(`tenants.dialog.title.${isNew ? 'create' : 'edit'}`)}
      PaperProps={{ className: '_paper' }}
      fullWidth
      handleSubmit={handleSubmit}
      testId={testId}
      actions={
        <Row justifyContent="flex-end" columnSpacing={3} rowSpacing={2}>
          <Col>
            <Button
              variant="outlined"
              color="default"
              onClick={props.onClose}
              testId={ALL_TEST_IDS.tenants.dialog.back}
            >
              {t('common.cancel')}
            </Button>
          </Col>
          {tabValue === 1 && values.billingEmailsEnabled && (
            <Col>
              <Button
                onClick={() =>
                  onSubmit({
                    ...values,
                    shouldUserValidate: false,
                  })
                }
                disabled={isRequesting}
                testId={ALL_TEST_IDS.tenants.dialog.skip}
              >
                {t('tenants.dialog.skipButton')}
              </Button>
            </Col>
          )}
          {tabValue === 0 && (
            <Col>
              <Button
                type="submit"
                testId={ALL_TEST_IDS.tenants.dialog.next}
                disabled={isRequesting}
              >
                {t('common.next')}
              </Button>
            </Col>
          )}
          {tabValue === 1 && (
            <Col>
              <Button
                type="submit"
                testId={ALL_TEST_IDS.tenants.dialog.submit}
                disabled={isRequesting}
              >
                {t('common.save')}
              </Button>
            </Col>
          )}
        </Row>
      }
    >
      <DialogTabs value={tabValue} onChange={handleTabChange}>
        <DialogTab
          index={0}
          label={t('tenants.dialog.tab.tenant')}
          testId={testId}
        />
        <DialogTab
          index={1}
          disabled={isDisabledNextStep}
          label={t('tenants.dialog.tab.billingInfo')}
          testId={testId}
        />
      </DialogTabs>

      <TabPanel index={0} value={tabValue} testId={testId}>
        <Input
          autoFocus
          label={t('tenants.dialog.field.tenantName')}
          value={values.name}
          name="name"
          placeholder={t('tenants.dialog.field.placeholder.tenantName')}
          className="mb-25"
          onChange={handleChange}
          helperText={errors.name}
          error={!!submitCount && !!errors.name}
          helperTextOptions={{ max: 40 }}
          testId={testId}
        />
        <Input
          label={t('forms.shortname', { shortName: userHash }).replace(
            '&#x2F;',
            '/'
          )}
          value={values.shortName}
          helperText={errors.shortName}
          name="shortName"
          placeholder={t('tenants.dialog.field.placeholder.shortname')}
          className="mb-25"
          onChange={handleChange}
          helperTextOptions={{ min: 3, max: 16 }}
          error={!!submitCount && !!errors.shortName}
          testId={testId}
        />
        {isTicketingEnabled && (
          <Input
            label={t('forms.zamadDomain')}
            value={values.orgDomain}
            helperText={errors.orgDomain}
            name="orgDomain"
            placeholder={t('forms.placeholders.zamadDomain')}
            className="mb-25"
            onChange={handleChange}
            error={!!submitCount && !!errors.orgDomain}
            testId={testId}
          />
        )}
      </TabPanel>
      <TabPanel index={1} value={tabValue} testId={testId}>
        <div className="steel mb-20">
          {t('tenants.dialog.tab.billingInfo.description')}
        </div>
        <div className="mb-25">
          <Switch
            label={t('tenants.dialog.tab.billingInfo.switchers.enableBilling')}
            checked={values.billingEmailsEnabled}
            disabled={!hasInvoiceSettings}
            onCheck={(billingEmailsEnabled) => {
              if (billingEmailsEnabled) {
                const nextValues = {
                  ...INITIAL_DIALOG_VALUES,
                  billingEmailsEnabled,
                  name: values.name,
                  shortName: values.shortName,
                  orgDomain: values.orgDomain,
                };
                resetForm();
                setValues(nextValues);
              } else {
                setFieldValue('billingEmailsEnabled', billingEmailsEnabled);
              }
            }}
          />
          {!hasInvoiceSettings && (
            <Alert severity="info" className="mt-10">
              <Trans
                i18nKey="tenants.dialog.manageBilling.alerts.enterInvoiceSettings"
                components={{
                  1: (
                    <NavLink
                      className="primary"
                      to={`/${userHash}/settings/customer-invoice`}
                    />
                  ),
                }}
              />
            </Alert>
          )}
        </div>
        <div hidden={!values.billingEmailsEnabled}>
          <Input
            label={t('forms.address1')}
            value={values.line1}
            name="line1"
            placeholder={t('partners.dialog.field.placeholder.address1')}
            className="mb-25"
            onChange={handleChange}
            helperText={errors.line1}
            helperTextOptions={{ max: 60 }}
            error={!!submitCount && !!errors.line1}
            testId={testId}
          />
          <Input
            label={t('forms.address2')}
            value={values.line2}
            name="line2"
            placeholder={t('partners.dialog.field.placeholder.address2')}
            onChange={handleChange}
            error={!!submitCount && !!errors.line2}
            helperText={errors.line2}
            helperTextOptions={{ max: 60 }}
            testId={testId}
            className="mb-25"
          />
          <Row columnSpacing={2} className="mb-25">
            <Col xs={5}>
              <Input
                label={t('forms.zipcode')}
                value={values.zipCode}
                name="zipCode"
                placeholder={t('partners.dialog.field.placeholder.zipcode')}
                onChange={handleChange}
                error={!!submitCount && !!errors.zipCode}
                helperText={errors.zipCode}
                helperTextOptions={{ max: 30 }}
                testId={testId}
              />
            </Col>
            <Col xs={7}>
              <Input
                label={t('forms.city')}
                value={values.city}
                name="city"
                placeholder={t('partners.dialog.field.placeholder.city')}
                onChange={handleChange}
                error={!!submitCount && !!errors.city}
                helperText={errors.city}
                helperTextOptions={{ max: 60 }}
                testId={testId}
              />
            </Col>
          </Row>
          <Select
            label={t('forms.country')}
            value={values.countryCode || undefined}
            name="countryCode"
            placeholder={t('partners.dialog.field.placeholder.country')}
            onChange={(val: any) => setFieldValue('countryCode', val)}
            options={countries}
            error={!!submitCount && !!errors.countryCode}
            helperText={errors.countryCode}
            testId={testId}
            className="mb-25"
          />
          <ManageBillingEmails
            useFormElement={false}
            emails={values.emails}
            billingEmailsEnabled={values.billingEmailsEnabled}
            onEmailsChange={(emails) => setFieldValue('emails', emails)}
          />
          {errors.emails && (
            <Alert severity="error" className="mb-10">
              {t('partners.dialog.manageBilling.alert.noEmail')}
            </Alert>
          )}
        </div>
      </TabPanel>
    </SDialog>
  );
};

export default TenantDetailsDialog;
