import {
  IDriveLetter,
  IDriveLetterOption,
  IFileShareFormValues,
} from 'file-shares';
import * as Yup from 'yup';
import { validator } from 'utils';
import isIP from 'validator/lib/isIP';

export const INITIAL_STATE = {
  openedDialog: null,
  commandToCopy: '',
  currentFS: null,
};

export const DRIVE_LETTERS: IDriveLetter[] = [
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const DRIVE_LETTERS_OPTIONS: IDriveLetterOption[] = DRIVE_LETTERS.map(
  (l) => ({ label: l, value: l })
);

export const getInitialValues = (): IFileShareFormValues => ({
  name: '',
  driveLetter: undefined,
  userPermissions: {},
});

export const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .max(20, 'forms.invalid.max')
    .required('forms.required')
    .test({
      message: 'forms.invalid',
      test: (val) => validator.fileShareName(val || ''),
    }),
  driveLetter: Yup.object().required('forms.required'),
  userPermissions: Yup.object(),
});

export const migrationEndpointFormSchema = Yup.object({
  sourceWANIp: Yup.string()
    .trim()
    .required('forms.required')
    .test('sourceWANIp', 'forms.ipInvalid', (val) => isIP(val || '')),
  localSourcePath: Yup.string()
    .trim()
    .required('forms.required')
    // @ts-ignore
    .test('localSourcePath', 'forms.ipInvalid', (val) => {
      if (!val) return false;
      const splited = val.split('\\');

      if (!splited.length) {
        return false;
      }
      if (splited.length <= 1) return false;
      const ip = isIP(splited[0]);
      return ip && splited.slice(1).every((str) => /^[a-z0-9]/i.test(str));
    }),
  expireIn: Yup.object().required('forms.required'),
});

export const PERMISSIONS_MAP = {
  NONE: 'none',
  READ: 'viewOnly',
  WRITE: 'modify',
};

export const PERMISSIONS_MAP_REVERSE = {
  none: 'NONE',
  viewOnly: 'READ',
  modify: 'WRITE',
};

export const DEFAULT_USERS_GET_PARAMS = {
  orderBy: 'username',
  orderType: 'asc',
  page: 1,
  perPage: 10,
};

export const EXPIRATION_OPTIONS = [
  {
    label: '4h',
    value: '' + 14400,
  },
  {
    label: '12h',
    value: '' + 43200,
  },
  {
    label: '24h',
    value: '' + 86400,
  },
  {
    label: '1 week',
    value: '' + 604800,
  },
];

export const DEFAULT_FS_GET_PARAMS = {
  orderBy: 'name',
  orderType: 'desc',
  page: 1,
  perPage: 8,
};
