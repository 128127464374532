import * as React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Dialog,
  DialogProps,
  Stepper,
  Step,
  StepLabel,
} from 'elements';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useStateHandler, useState } from 'hooks';
import { remCalc, showSystemMessage } from 'utils';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import * as CONSTANTS from './constants';
import * as TYPES from './types';

type Props = DialogProps<TYPES.IFormValues> & {
  vmId: number;
  isPrivateVm: boolean;
  assignedNetworks: number[];
};

type IState = {
  activeStep: number;
};

const Connector = () => null;

const PAPER_PROPS = { style: { maxWidth: remCalc(1040) } };

const ImportVmDialog = observer((props: Props) => {
  const { open, onSave, onClose, vmId, assignedNetworks } = props;
  const { t } = useTranslation();
  const nics = useStateHandler(StateHandlers.vmNics);

  const [state, handleState] = useState<IState>({
    activeStep: 0,
  });

  const form = useFormik({
    initialValues: CONSTANTS.InitialFormValues,
    validateOnMount: false,
    validationSchema: CONSTANTS.DefineValidationSchema({
      tab: state.activeStep,
    }),
    onSubmit: async (data: TYPES.IFormValues) => {
      const isPaidNetwork = data.network?.type === 'DIRECT';

      if (isPaidNetwork && state.activeStep === 0) {
        return handleState({ activeStep: 1 });
      }

      try {
        await nics.create({ vmId, ...data, networkId: data.network?.id });
        return onSave && onSave(data);
      } catch (er: any) {
        showSystemMessage(er.message, 'error');
      }
    },
  });

  const isPaidNetwork = form.values.network?.type === 'DIRECT';

  const isLastStep = form.values.network
    ? isPaidNetwork
      ? state.activeStep === 1
      : state.activeStep === 0
    : false;

  React.useEffect(() => {
    if (!open) {
      handleState({ activeStep: 0 });
      form.resetForm(CONSTANTS.InitialFormValues as any);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      title={t(`services.dialog.vms.attachNetwork.title`)}
      PaperProps={PAPER_PROPS}
      fullWidth
      maxWidth="md"
      handleSubmit={form.handleSubmit}
      onClose={onClose}
      actions={
        <Row justifyContent="space-between" columnSpacing={3} rowSpacing={2}>
          <Col>
            <Button variant="outlined" color="default" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Row justifyContent="flex-end" columnSpacing={2}>
              {state.activeStep !== 0 && (
                <Col>
                  <Button
                    onClick={() => {
                      handleState({ activeStep: state.activeStep - 1 });
                    }}
                    variant="outlined"
                    color="default"
                  >
                    {t('common.previous')}
                  </Button>
                </Col>
              )}
              <Col>
                <Button type="submit" disabled={form.isSubmitting}>
                  {t(isLastStep ? 'common.save' : 'common.continue')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <div className="mb-30">
        <Stepper
          activeStep={state.activeStep}
          classes={{ root: '' }}
          connector={<Connector />}
        >
          {CONSTANTS.Steps(isPaidNetwork).map((label) => {
            return (
              <Step key={label} className="mr-50">
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {state.activeStep === 0 && (
        <FirstStep
          {...form}
          onSelect={(net) => form.setFieldValue('network', net)}
          assignedNetworks={assignedNetworks}
        />
      )}
      {state.activeStep === 1 && (
        <SecondStep
          {...form}
          descriptionString="services.dialog.vms.setupNetwork.content"
        />
      )}
    </Dialog>
  );
});

export default ImportVmDialog;
