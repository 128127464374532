import React from 'react';
import * as R from 'ramda';
import cn from 'classnames';
import { Dialog, DialogProps, TablePagination, Button } from 'elements';
import { useState } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useTranslation } from 'react-i18next';
import { IEmployee } from 'employees';
import { EmployeeTableHeadRow, EmployeeTableItem } from './EmployeeTableItem';
import { toogleArrayValues } from './helpers';

type IState = {
  expanded: number[];
};

const INITIAL_STATE: IState = {
  expanded: [],
};

const OBSERVERS = {
  employees: StateHandlers.employees,
};

type IProps = DialogProps & {
  query: Record<string, any>;
  onChangeQuery: (query: IEmployeeTypes.IQuery) => void;
  onEdit: (employee: IEmployee) => void;
  onDelete: (employee: IEmployee) => void;
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { onEdit, onDelete, query, onChangeQuery, employees } = props;
  const { t } = useTranslation();
  const [state, handleChange] = useState<IState>(INITIAL_STATE);

  const onExpand = React.useCallback(
    (id: number) => {
      handleChange({ expanded: toogleArrayValues(id, state.expanded) });
    },
    [JSON.stringify(state.expanded)]
  );

  React.useEffect(() => {
    if (!props.open) {
      handleChange(INITIAL_STATE);
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={t('settings.page.partnerInfo.section.employees.title')}
      data-test-id="employee-all"
      fullScreen
      showCloseIcon
      actions={
        <>
          <Button onClick={props.onClose}>{t('common.ok')}</Button>
        </>
      }
    >
      <div className={cn({ disabled: employees.isRequesting })}>
        <EmployeeTableHeadRow
          query={query}
          onChange={(newQuery) => onChangeQuery({ ...query, ...newQuery })}
        />
        {employees.data.map((emp, ind) => {
          const isExpanded = R.includes(emp.id, state.expanded);
          return (
            <EmployeeTableItem
              key={`${emp.name}-all-view-${ind}`}
              {...emp}
              expanded={isExpanded}
              onDelete={onDelete}
              onEdit={onEdit}
              onExpand={onExpand}
            />
          );
        })}
        <TablePagination
          shouldUseQuery={false}
          page={query.page}
          perPage={query.perPage}
          totalCount={employees.meta.totalCount}
          onChange={({ page }) => onChangeQuery({ ...query, page })}
        />
      </div>
    </Dialog>
  );
});

const EmployeesAllDialog = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default EmployeesAllDialog;
