import { IGlobalUser, IUserContext, UserFormValues } from 'users-shapes';
import { buildListParams, DELETE, GET, PATCH, POST, PUT } from 'utils';
import { AnyShape, DefaultListQuery } from 'global-shapes';
import { AxiosPromise } from 'axios';
import { ICurrentUser } from 'auth-shapes';
import { IAxiosPromise } from 'axios-shapes';

export const getUsersList = (
  params: DefaultListQuery,
  config?: any
): IAxiosPromise<IGlobalUser[]> =>
  GET('/users', buildListParams(params, ['q', 'orderType', 'orderBy']), config);

export const getUser = (id: string | number): AxiosPromise<ICurrentUser> =>
  GET(`/users/${id}`);

export const blockUser = (id: number): AxiosPromise =>
  POST(`/users/${id}/block`);

export const unBlockUser = (id: number): AxiosPromise =>
  DELETE(`/users/${id}/block`);

export const create = (user: UserFormValues): AxiosPromise<ICurrentUser> => {
  const { firstName, lastName, lang, email, role } = user;
  const payload: AnyShape = {
    firstName,
    lastName,
    email,
    lang: lang ? lang.value : undefined,
  };

  if (role) {
    payload.roleId = role.value;
  }

  return POST('/users', {}, payload);
};

export const update = (
  userId: number,
  user: UserFormValues
): AxiosPromise<ICurrentUser> => {
  const { firstName, lastName, lang, email, role, accessLevel, is2faEnabled } =
    user;
  const payload: AnyShape = {
    firstName,
    lastName,
    is2faEnabled,
    email,
    accessLevel,
    lang: lang ? lang.value : undefined,
  };

  if (role) {
    payload.roleId = role.value;
  }

  return PUT(`/users/${userId}`, {}, payload);
};

export const updatePartially = (
  userId: number | 'me',
  data: Record<string, unknown>,
  config?: any
): AxiosPromise<ICurrentUser> => PATCH(`/users/${userId}`, {}, data, config);

export const deleteUser = (userId: number): AxiosPromise =>
  DELETE(`/users/${userId}`);

export function checkIfEmailIsInUse(
  email: string
): AxiosPromise<{ isFree: boolean }> {
  return POST(`/users/check`, {}, { email });
}

export function resolveContext(config?: any): AxiosPromise<IUserContext> {
  return GET(`/users/resolve-context`, {}, config);
}
