import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useQuery, useState } from 'hooks';
import { Button, Row, Col, Alert } from 'elements';
import { remCalc, setSessionsData, insertXCustomLang } from 'utils';
import { authService } from 'services';
import { ALL_TEST_IDS } from 'enums';
import logoSrc from 'assets/images/login-logo.svg';
import {
  CreatePassPage,
  VerCodePaper,
  VerCode,
  CreatePassLogo,
} from './Styled';

interface InitialState {
  isSubmitted: boolean;
  code: string;
  error: undefined | string;
}

const initialState: InitialState = {
  isSubmitted: false,
  code: '',
  error: undefined,
};

const VerificationCode = () => {
  const { query } = useQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, handleStateChange] = useState(initialState);

  const handleCodeSubmit = (code: string) =>
    authService
      .verifyCode(query.twoFactorAuthToken, code, insertXCustomLang(query.lang))
      .then((res) => {
        setSessionsData(res.data);
        return (window.location.pathname = '/');
      })
      .catch((err: Error) => {
        handleStateChange(err.message, 'error');
      });

  if (!query.twoFactorAuthToken) {
    return (
      <CreatePassPage>
        <VerCodePaper>
          <Row justifyContent="center">
            <h2
              className="mb-20 text-center"
              style={{ marginBottom: remCalc(85) }}
            >
              {t('auth.code.noConfig')}
            </h2>
          </Row>
          <Row justifyContent="center">
            <Button fullWidth size="large" onClick={() => navigate('/login')}>
              {t('auth.code.back')}
            </Button>
          </Row>
        </VerCodePaper>
      </CreatePassPage>
    );
  }

  return (
    <CreatePassPage>
      <VerCodePaper>
        <Row
          justifyContent="center"
          className="mb-20 text-center"
          style={{ marginBottom: remCalc(85) }}
        >
          <Row justifyContent="center">
            <h2 className="mb-15">{t('auth.code.title')}</h2>
          </Row>
          <Row justifyContent="center" className="fs-20">
            {t('auth.code.subTitle')}
          </Row>
        </Row>

        <Row
          style={{ marginBottom: remCalc(70) }}
          data-test-id={ALL_TEST_IDS.verifyCode.wrapper}
        >
          <VerCode
            className={cn('flex justify-center')}
            fieldWidth={53}
            fieldHeight={56}
            onChange={(code: string) => handleStateChange(code, 'code')}
            onComplete={handleCodeSubmit}
          />

          {state.error && (
            <Col xs={12}>
              <Alert severity="error" testId={ALL_TEST_IDS.verifyCode.error}>
                {state.error}
              </Alert>
            </Col>
          )}
        </Row>
      </VerCodePaper>
      <CreatePassLogo>
        <img src={logoSrc} alt="" />
      </CreatePassLogo>
    </CreatePassPage>
  );
};

export default VerificationCode;
