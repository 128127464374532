import * as React from 'react';
import {
  Row,
  Col,
  Dialog,
  DialogProps,
  Switch,
  DatePicker,
  Input,
  Select,
  Button,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useFormik } from 'formik';
import {
  remCalc,
  dayjs,
  numberToCurrency,
  validateCallback,
  validator,
  Dayjs,
} from 'utils';
import {
  BILLABLE_ITEM_INITIALS,
  IBIAccessLevel,
  typeOptions,
  unitOptions,
  PartnerValidationSchema,
  ProviderValidationSchema,
  recurringIntervalOptions,
} from './constants';
import {
  onUsersLoad,
  onPartnersLoad,
  parseBillableItemDetailsForForm,
  onTenantsLoad,
  defineEndDateByInterval,
} from './helpers';
import {
  IBillableItem,
  IBillableItemFormValues,
  IBillableItemType,
} from 'billable-items';
import { ValueType } from 'global-shapes';
import { usePrice } from 'hooks';
import { CostResourceIds, GLOBAL_DATE_FORMAT } from 'enums';

const OBSERVERS = {
  currentUser: StateHandlers.currentUser,
};

type IProps = React.PropsWithChildren<
  DialogProps<IBillableItemFormValues> & {
    isNew: boolean;
    isRequesting: boolean;
    currentItem?: IBillableItem;
    accessLevel: IBIAccessLevel;
  }
>;

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    isNew,
    classes,
    currentItem,
    onSave,
    accessLevel,
    isRequesting,
    currentUser,
    ...rest
  } = props;

  const isTenant = accessLevel === 'tenant';

  const { t } = useTranslation();

  const [currentUserOption, handleInitialUser] = React.useState<
    ValueType<number> | undefined
  >(undefined);

  const reducer = React.useMemo(() => {
    if (accessLevel === 'provider') {
      return 'defaultPricing';
    }
    if (accessLevel === 'partner') {
      return 'main';
    }
    if (accessLevel === 'tenant') {
      return 'tenantPricing';
    }
  }, [accessLevel]);

  const prices = usePrice([CostResourceIds.serviceAndSupportHourlyRate], {
    reducer,
  });

  const defaultPrice =
    prices[CostResourceIds.serviceAndSupportHourlyRate].daily || 0;

  const {
    values,
    submitCount,
    errors,
    setFieldValue,
    setValues,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      ...BILLABLE_ITEM_INITIALS,
      userId: currentUserOption,
      price: defaultPrice,
    },
    validationSchema:
      accessLevel === 'provider'
        ? ProviderValidationSchema
        : PartnerValidationSchema,
    onSubmit: onSave as any,
  });

  const onTypeChange = React.useCallback(
    (type: ValueType<IBillableItemType>) => {
      setFieldValue('type', type);

      if (type.value === 'SUPPORT') {
        setFieldValue('unit', unitOptions[0]);
      }

      if (type.value === 'GENERAL') {
        setFieldValue('unit', unitOptions[1]);
      }
    },
    []
  );

  const onUnitChange = React.useCallback(
    (unit: any) => {
      setFieldValue('unit', unit);

      if (unit.value === 'HOURS') {
        setFieldValue('price', defaultPrice);
      }
    },
    [defaultPrice]
  );

  const isUnitDisabled = React.useMemo(() => {
    return ['SUPPORT', 'APP_SERVICE'].includes(values.type?.value);
  }, [values.type]);

  const isDateModifiable = currentItem?.recurringSettings
    ? currentItem?.recurringSettings?.isDateModifiable
    : true;

  const setFirstInitUser = React.useCallback(
    () => onUsersLoad('').then((res) => handleInitialUser(res[0])),
    []
  );

  React.useEffect(() => {
    setFirstInitUser();
  }, [isNew]);

  React.useEffect(() => {
    if (rest.open) {
      setValues(
        // @ts-ignore
        currentItem
          ? parseBillableItemDetailsForForm(currentItem)
          : {
              ...BILLABLE_ITEM_INITIALS,
              userId: currentUserOption,
              price: defaultPrice,
              tenantId: isTenant
                ? {
                    label: currentUser.data.tenant?.name,
                    value: currentUser.data.tenant?.id as number,
                  }
                : BILLABLE_ITEM_INITIALS.tenantId,
            }
      );
      if (isTenant) {
        setFirstInitUser();
      }
    } else {
      resetForm();
    }
  }, [rest.open, isTenant]);

  return (
    <Dialog
      {...rest}
      fullWidth
      handleSubmit={handleSubmit}
      PaperProps={{ style: { minWidth: remCalc(640) } }}
      title={t(
        `billableItems.dialogs.details.title.${isNew ? 'create' : 'update'}`
      )}
      actions={
        <Row
          alignItems="center"
          justifyContent="space-between"
          columnSpacing={2}
        >
          <Col>
            <span className="bolder">
              {t('billableItems.dialogs.details.total') as string}{' '}
              {numberToCurrency(+values.qty * +values.price || 0, false)}
            </span>
          </Col>
          <Col>
            <Row columnSpacing={2}>
              <Col>
                <Button
                  variant="outlined"
                  color="default"
                  onClick={rest.onClose}
                >
                  {t('common.cancel')}
                </Button>
              </Col>
              <Col>
                <Button type="submit" disabled={isRequesting}>
                  {t('common.save')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <Row columnSpacing={2}>
        <Col xs={5}>
          <Select
            name="type"
            label={t('forms.type') as string}
            options={typeOptions.slice(0, 2)}
            value={values.type}
            className="mb-25"
            error={!!submitCount && !!errors.type}
            helperText={errors.type}
            onChange={onTypeChange}
          />
        </Col>
        <Col xs={7}>
          <Select
            name="userId"
            label={t('forms.user') as string}
            value={values.userId}
            className="mb-25"
            error={!!submitCount && !!errors.userId}
            useAsync
            onLoad={onUsersLoad}
            defaultOptions
            helperText={errors.userId}
            onChange={(type) => setFieldValue('userId', type)}
          />
        </Col>
      </Row>
      {accessLevel === 'provider' ? (
        <Select
          useAsync
          defaultOptions
          onLoad={onPartnersLoad}
          label={t('forms.partner') as string}
          name="partnerId"
          options={[]}
          value={values.partnerId}
          className="mb-25"
          error={!!submitCount && !!errors.partnerId}
          helperText={errors.partnerId}
          onChange={(type) => setFieldValue('partnerId', type)}
        />
      ) : (
        <Select
          defaultOptions
          useAsync={!isTenant}
          onLoad={isTenant ? undefined : onTenantsLoad}
          isDisabled={isTenant}
          label={t('forms.tenant') as string}
          name="tenantId"
          options={[]}
          value={values.tenantId}
          className="mb-25"
          error={!!submitCount && !!errors.tenantId}
          helperText={errors.tenantId}
          onChange={(type) => setFieldValue('tenantId', type)}
        />
      )}

      <Row columnSpacing={2} rowSpacing={2} className="mb-25">
        <Col xs>
          <DatePicker
            helperText={errors.date}
            disabled={values.enableRecurring}
            label={t('forms.date') as string}
            error={!!submitCount && !!errors.date}
            value={values.date ? dayjs.utc(values.date) : undefined}
            onChange={(val) => {
              setFieldValue('date', val);
              setFieldValue('recurringStart', val);
            }}
            closeOnSelect
          />
        </Col>
        <Col xs={3}>
          <Input
            label={t('forms.unitPrice')}
            name="price"
            value={values.price}
            error={!!submitCount && !!errors.price}
            helperText={errors.price}
            onChange={validateCallback({
              restrict: validator.pricesWithNegative(6, 2),
            })(handleChange)}
          />
        </Col>
        <Col xs>
          <Input
            label={t('forms.qty')}
            name="qty"
            value={values.qty}
            error={!!submitCount && !!errors.qty}
            helperText={errors.qty}
            onChange={validateCallback({
              restrict: validator.onlyNumbersWithDot,
            })(handleChange)}
          />
        </Col>
        <Col xs>
          <Select
            label={t('forms.unit') as string}
            name="unit"
            isDisabled={isUnitDisabled}
            options={unitOptions}
            value={values.unit}
            error={!!submitCount && !!errors.unit}
            helperText={errors.unit}
            onChange={onUnitChange}
          />
        </Col>
      </Row>

      <div className="mb-25">
        <Switch
          checked={values.enableRecurring}
          name="enableRecurring"
          label={t('billableItems.dialogs.details.switchers.enableRecurring')}
          onCheck={(isChecked) => setFieldValue('enableRecurring', isChecked)}
        />
      </div>

      {values.enableRecurring && (
        <>
          <Row className="mb-25">
            <Col xs={6}>
              <Select
                options={recurringIntervalOptions}
                value={values.recurringInterval}
                error={!!submitCount && !!errors.recurringInterval}
                helperText={errors.recurringInterval}
                onChange={(el: any) => {
                  setFieldValue('recurringInterval', el);
                }}
              />
            </Col>
          </Row>
          <Row columnSpacing={2} className="mb-25" alignItems="flex-end">
            <Col xs={4}>
              <DatePicker
                label={t('forms.startDate') as string}
                value={values.recurringStart}
                error={!!submitCount && !!errors.recurringStart}
                helperText={errors.recurringStart}
                onChange={(val) => {
                  setFieldValue('recurringStart', val);
                  setFieldValue('date', val);
                }}
                disabled={!isNew && !isDateModifiable}
                closeOnSelect
              />
            </Col>
            {!values.repeatPermanently && (
              <Col xs={4}>
                <DatePicker
                  label={t('forms.endDate') as string}
                  value={values.recurringEnd}
                  minimumDate={defineEndDateByInterval(
                    values.recurringStart as Dayjs,
                    // @ts-ignore
                    values.recurringInterval
                  ).toDate()}
                  error={!!submitCount && !!errors.recurringEnd}
                  helperText={errors.recurringEnd}
                  onChange={(val) => setFieldValue('recurringEnd', val)}
                  closeOnSelect
                />
              </Col>
            )}

            <Col xs>
              <Switch
                className="default-input-height full-width"
                checked={values.repeatPermanently}
                name="repeatPermanently"
                labelClassName="fs-12 flex"
                label={t(
                  'billableItems.dialogs.details.switchers.repeatPermanently'
                )}
                onCheck={(isChecked) =>
                  setFieldValue('repeatPermanently', isChecked)
                }
              />
            </Col>
          </Row>
        </>
      )}
      <Input
        label={t('forms.description')}
        name="description"
        value={values.description}
        className="mb-25"
        error={!!submitCount && !!errors.description}
        helperText={errors.description}
        helperTextOptions={{ max: 300 }}
        onChange={handleChange}
        placeholder={t(
          'billableItems.dialogs.details.inputs.description.placeholder'
        )}
      />
      <Input
        label={t('forms.ticket')}
        name="ticket"
        value={values.ticket}
        className="mb-25"
        error={!!submitCount && !!errors.ticket}
        helperText={errors.ticket}
        helperTextOptions={{ max: 30 }}
        onChange={handleChange}
        placeholder={t(
          'billableItems.dialogs.details.inputs.ticket.placeholder'
        )}
      />

      <Input
        label={t('forms.adminNotes')}
        name="notes"
        value={values.notes}
        className="mb-25"
        error={!!submitCount && !!errors.notes}
        helperText={errors.notes}
        helperTextOptions={{ max: 300 }}
        onChange={handleChange}
        placeholder={t('forms.placeholders.adminNotes')}
      />

      <Switch
        className="mb-5"
        label={t('billableItems.dialogs.details.invoiceUpfront')}
        checked={values.sendUpfront}
        onCheck={(sendUpfront) => setFieldValue('sendUpfront', sendUpfront)}
      />
      {values.sendUpfront && (
        <div className="fs-12 steel">
          {t('billableItems.dialogs.details.invoiceUpfront.sup', {
            tomorrow: dayjs(values.date)
              .add(1, 'days')
              .format(GLOBAL_DATE_FORMAT),
            today: dayjs(values.date).format(GLOBAL_DATE_FORMAT),
          })}
        </div>
      )}
    </Dialog>
  );
});

const BillableItemDetailsDialog = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default BillableItemDetailsDialog;
