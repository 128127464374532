import { GET } from 'utils';
import { AxiosPromise } from 'axios';

export const getFailedCoreList = (): AxiosPromise =>
  GET(`/core-infrastructure/reports/failed`);

export const getFailedRdsList = (): AxiosPromise => GET(`/rds/reports/failed`);
export const getCustomersWithMSLicense = (): AxiosPromise =>
  GET(`/tenants/reports/windows-licences`);

export const getPackagesUserStats = (): AxiosPromise =>
  GET(`/software-packages/reports/users-app-stats`);

export const getCustomersInfo = (): AxiosPromise =>
  GET(`/tenants/reports/info`);
