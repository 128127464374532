import { ICoreWithTenant } from 'core-infrastructure';
import { IRdsWithTenant } from 'rds';
import { GET, PUT, DELETE, POST } from 'utils';
import { IAxiosPromise } from 'axios-shapes';
import { AxiosPromise } from 'axios';

export const getList = (
  params: Record<string, any>
): IAxiosPromise<IServicePatching[]> => GET('/service-patching', params);

export const getById = (id: number): IAxiosPromise<IServicePatching> =>
  GET(`/service-patching/${id}`);

export const updateById = (
  id: number,
  payload: IServicePatchingTypes.IUpdatePayload
): IAxiosPromise<IServicePatching> =>
  PUT(`/service-patching/${id}`, {}, payload);

export const deleteById = (id: number): IAxiosPromise<IServicePatching> =>
  DELETE(`/service-patching/${id}`);

export const getCorePatchings = (
  partnerId: number
): AxiosPromise<ICoreWithTenant[]> =>
  GET(`/partners/${partnerId}/core-infrastructure/patchings`);

export const saveBulkCorePatchings = (
  partnerId: number,
  query: AnyShape,
  payload: any
): AxiosPromise<ICoreWithTenant[]> =>
  POST(`/partners/${partnerId}/core-infrastructure/patchings`, query, payload);

export const getRdsPatchings = (
  partnerId: number
): AxiosPromise<IRdsWithTenant[]> =>
  GET(`/partners/${partnerId}/rds/patchings`);

export const saveBulkRdsPatchings = (
  partnerId: number,
  query: AnyShape,
  payload: any
): AxiosPromise<ICoreWithTenant[]> =>
  POST(`/partners/${partnerId}/rds/patchings`, query, payload);

export const getRdsRebootList = (
  partnerId: number
): AxiosPromise<IRdsWithTenant[]> => GET(`/partners/${partnerId}/rds/reboots`);

export const saveBulkRdsRebootList = (
  partnerId: number,
  query: AnyShape,
  payload: any
): AxiosPromise<ICoreWithTenant[]> =>
  POST(`/partners/${partnerId}/rds/reboots`, query, payload);
