import * as React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoginValues } from 'auth-shapes';
import { ALL_TEST_IDS } from 'enums';
import { Input, Button, Row, Col, Alert, useMediaQuery } from 'elements';
import {
  initTagManager,
  getCurrentLang,
  changeLang,
  setSessionsData,
  history,
} from 'utils';
import Logo from 'assets/images/login-logo.svg';
import MobileLogo from 'assets/images/mobile-dark-logo.svg';
import { useAsync, useQuery } from 'hooks';
import { authService } from 'services';
import qs from 'query-string';
import {
  AuthRoot,
  LoginCol,
  LoginRightCol,
  AuthFooterLink,
  LoginLeftContent,
  AuthMobileLogo,
} from './Styled';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('forms.emailInvalid').required('forms.required'),
  password: Yup.string().required('forms.required'),
});

const initialValues: LoginValues = {
  email: '',
  password: '',
};

const Login = () => {
  const { t } = useTranslation();
  const { changeQuery, query } = useQuery();
  const navigate = useNavigate();
  const isSmUp = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const {
    execute: logInRequest,
    isPending,
    error,
  } = useAsync(authService.login, null);

  const requestLogin = React.useCallback(async (val: any) => {
    const { data: payload } = await logInRequest(val);

    if (!payload.session) {
      const newQ = qs.stringify({
        twoFactorAuthToken: payload.twoFactorAuthToken,
      });

      history.push(`/verify-code?${newQ}`);
      return window.location.reload();
    }
    setSessionsData(payload.session);
    window.location.search = '';
    window.location.pathname = '/';
  }, []);

  const { values, errors, handleChange, handleSubmit, touched, submitCount } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (val) => Promise.resolve(val).then(requestLogin),
    });

  React.useEffect(initTagManager, []);

  React.useEffect(() => {
    if (!query.lang) {
      changeQuery({ lang: getCurrentLang() });
    } else {
      changeLang(query.lang);
    }
  }, [query.lang]);

  if (localStorage.getItem('_t') || sessionStorage.getItem('_t')) {
    window.location.pathname = '/';
    return null;
  }

  return (
    <AuthRoot data-test-id={ALL_TEST_IDS.login.wrapper}>
      <LoginCol xs={12} md={5}>
        <Row
          justifyContent="space-between"
          direction="column"
          columnSpacing={4}
          className="full-width"
        >
          <Col>
            <AuthMobileLogo className={cn('mb-35')}>
              <img src={MobileLogo} alt="" />
            </AuthMobileLogo>
          </Col>
          <Col className="full-width">
            <LoginLeftContent className={cn('full-width')}>
              <h2 className="mb-40">{t('auth.login.title')}</h2>
              <form
                onSubmit={handleSubmit}
                data-test-id={ALL_TEST_IDS.login.form}
              >
                <Input
                  testId={ALL_TEST_IDS.login.inputs}
                  id="email"
                  name="email"
                  label={t('forms.email')}
                  placeholder={t('forms.placeholders.email')}
                  touched={touched}
                  error={!!errors.email}
                  helperText={errors.email}
                  className="mb-25"
                  onChange={handleChange}
                  value={values.email}
                  elementSize="lg"
                />
                <Input
                  id="password"
                  name="password"
                  testId={ALL_TEST_IDS.login.inputs}
                  label={t('forms.password')}
                  placeholder={t('forms.placeholders.password')}
                  type="password"
                  touched={touched}
                  error={!!submitCount && !!errors.password}
                  helperText={!submitCount ? undefined : errors.password}
                  className="mb-15"
                  onChange={handleChange}
                  value={values.password}
                  elementSize="lg"
                />
                <div className="flex justify-end mb-25">
                  <div
                    className="pointer"
                    onClick={() => navigate('/request-reset-password')}
                  >
                    {t('auth.login.resetPassword')}
                  </div>
                </div>
                <Button
                  testId={ALL_TEST_IDS.login.submit}
                  type="submit"
                  size="large"
                  fullWidth
                  disabled={isPending}
                  className="mb-25"
                >
                  {t('auth.login.button')}
                </Button>
              </form>
              {error && (
                <Alert severity="error" className="mb-20">
                  {error.message}
                </Alert>
              )}
            </LoginLeftContent>
          </Col>
          <AuthFooterLink>
            {t('auth.login.noAccount')}{' '}
            <NavLink
              to={`/sign-up?lang=${query.lang}&step=1`}
              className="bolder primary"
            >
              {t('auth.login.signUp')}
            </NavLink>
          </AuthFooterLink>
        </Row>
      </LoginCol>
      {isSmUp && (
        <LoginRightCol md={7}>
          <Row alignItems="center" justifyContent="center">
            <img src={Logo} alt="" />
          </Row>
        </LoginRightCol>
      )}
    </AuthRoot>
  );
};

export default Login;
