import React, { useMemo } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Dialog,
  DialogProps,
  Input,
  MuiIcons,
  Row,
  Tooltip,
} from 'elements';
import { withErrorBoundary } from 'hocs';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useFormik } from 'formik';
import { AnyFunc } from 'global-shapes';
import { useTranslation } from 'react-i18next';
import { IUser } from 'core-infrastructure';
import {
  commonUserValidationSchema,
  getInitialValues,
  IDefaultValues,
} from './helpers';

interface IProps extends DialogProps<IUser> {
  onClose: AnyFunc;
  isNew: boolean;
  testId?: string;
  initialValues: IDefaultValues;
  coreDomain: string;
}

const OBSERVERS = {
  admins: StateHandlers.coreAdmins,
  users: StateHandlers.coreUsers,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    onSave,
    open,
    initialValues,
    isNew,
    onClose,
    testId,
    coreDomain,
    admins,
    users,
    ...rest
  } = props;
  const { t } = useTranslation();
  const initials = useMemo(
    () => (isNew ? getInitialValues() : initialValues),
    [isNew, open, initialValues]
  );

  const isRequesting = admins.isRequesting || users.isRequesting;

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    handleChange,
    resetForm,
    submitCount,
    setValues,
  } = useFormik({
    initialValues: initials,
    validateOnMount: true,
    validationSchema: commonUserValidationSchema(isNew),
    onSubmit: (val) => onSave && onSave(val as IUser),
  });

  React.useEffect(() => {
    if (!open) {
      resetForm();
      setValues(getInitialValues());
    }
    if (open) {
      setValues(initials);
    }
  }, [open]);

  return (
    <Dialog
      {...rest}
      open={open}
      handleSubmit={handleSubmit}
      onClose={onClose}
      testId={testId}
      actions={
        <Row justifyContent="flex-end">
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={onClose}
            testId={`${testId}-cancel`}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isRequesting} testId={`${testId}`}>
            {t('common.save')}
          </Button>
        </Row>
      }
    >
      <Row columnSpacing={2} className="mb-25">
        <Col xs={6}>
          <Input
            name="firstName"
            label="forms.firstName"
            value={values.firstName}
            placeholder="forms.placeholders.firstName"
            error={!!submitCount && !!errors.firstName}
            helperText={errors.firstName}
            helperTextOptions={{ max: 30 }}
            onChange={handleChange}
          />
        </Col>
        <Col xs={6}>
          <Input
            name="lastName"
            label="forms.lastName"
            value={values.lastName}
            placeholder="forms.placeholders.lastName"
            error={!!submitCount && !!errors.lastName}
            helperText={errors.lastName}
            helperTextOptions={{ max: 30 }}
            onChange={handleChange}
            testId="lastName"
          />
        </Col>
      </Row>
      <Input
        name="username"
        label="forms.username"
        value={values.username}
        className="mb-15"
        disabled={!isNew}
        placeholder="forms.placeholders.username"
        error={!!submitCount && !!errors.username}
        endAdornment={<span className="pr-10">@{coreDomain}</span>}
        helperText={errors.username}
        helperTextOptions={{ text: 'administrator', max: 20 }}
        onChange={handleChange}
        labelIcon={
          <Tooltip
            placement="top"
            title={
              <div>
                {t('forms.sups.username')
                  .split('; ')
                  .map((s) => (
                    <div key={s}>- {s}</div>
                  ))}
              </div>
            }
          >
            <MuiIcons.HelpOutline className="fs-12 ml-5" color="primary" />
          </Tooltip>
        }
      />
      {!isNew && (
        <div className="mb-15">
          <Checkbox
            testId="resetPassword"
            checked={values.resetPassword}
            onCheck={(isChecked) => setFieldValue('resetPassword', isChecked)}
            label={t('forms.resetPassword')}
          />
        </div>
      )}
      <Input
        name="password"
        label="forms.password"
        type="password"
        value={values.password}
        className="mb-25"
        disabled={isNew ? false : !values.resetPassword}
        placeholder="forms.placeholders.password"
        error={!!submitCount && !!errors.password}
        helperText={errors.password}
        onChange={handleChange}
      />
      <Input
        name="email"
        label="forms.email"
        value={values.email}
        placeholder="forms.placeholders.email"
        error={!!submitCount && !!errors.email}
        helperText={errors.email}
        onChange={handleChange}
      />
    </Dialog>
  );
});

const CommonUser = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default withErrorBoundary(CommonUser);
