import { showSystemMessage } from 'utils';
import { dnsService } from 'services';
import { IDNSRecord, IDNSZone, IDnsService } from 'dns';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export const dns = new BaseMobxInstanceDecorator<IDnsService, false>({
  instanceName: 'dns',
  requests: {
    get: async () => {
      const res = await dnsService.get();
      // @ts-ignore
      res.data = res.data
        ? {
            ...res.data,
            displayStatus: 'POWERED_ON',
          }
        : null;
      return res;
    },
    create: async (payload) => {
      const res = (await dnsService.createBulk(payload)) as any;
      return res;
    },
    remove: async () => {
      const res = await dnsService.remove();
      return res as any;
    },
  },
  initialState: {} as IDnsService,
});

export type IDnsHandler = BaseDecorator<IDnsService, false>;

export const dnsZones = new BaseMobxInstanceDecorator<IDNSZone, true>({
  instanceName: 'dnsZones',
  requests: {
    get: dnsService.getZones,
    reload: async (id) => {
      const res = await dnsService.getZoneById(id);
      return res;
    },
    remove: async (id) => {
      const res = await dnsService.removeZone(id);
      showSystemMessage('services.dns.notify.domain.delete.success', 'success');
      return res as any;
    },
  },
  initialState: [],
});

export type IDnsZonesHandler = BaseDecorator<IDNSZone, true>;

export const dnsZoneRecords = new BaseMobxInstanceDecorator<IDNSRecord, true>({
  instanceName: 'dnsZoneRecords',
  requests: {
    get: async (query) => {
      const { id, ...q } = query;
      const res = await dnsService.getRecords(id, q);
      return res;
    },
    create: async (payload) => {
      const { zoneId, values } = payload;
      const res = await dnsService.createRecord(zoneId, values);
      showSystemMessage('services.dns.notify.record.create.success', 'success');
      return res as any;
    },
    update: async (id, payload) => {
      const { zoneId, values } = payload;
      const res = await dnsService.updateRecord(zoneId, id, values);
      showSystemMessage(
        'services.dns.notify.record.updated.success',
        'success'
      );
      return res as any;
    },
    remove: async (id) => {
      const { id: _id, zoneId } = id;
      const res = await dnsService.deleteRecord(zoneId, _id);
      showSystemMessage('services.dns.notify.record.delete.success', 'success');
      return res as any;
    },
  },
  initialState: [],
});

export type IDnsZonRecordsHandler = BaseDecorator<IDNSRecord, true>;

export const reverseDns = new BaseMobxInstanceDecorator<
  IReverseDnsRecord,
  true
>({
  instanceName: 'reverseDnsRecords',
  requests: {
    get: dnsService.getReverseRecords,
    create: async (payload) => {
      const res = await dnsService.createReverseRecord(payload);
      showSystemMessage('reverseDnsRecords.notify.create.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const res = await dnsService.updateReverseRecord(id, payload);
      showSystemMessage('reverseDnsRecords.notify.update.success', 'success');
      return res;
    },
    remove: async (id) => {
      const res = await dnsService.removeReverseRecord(id);
      showSystemMessage('reverseDnsRecords.notify.remove.success', 'success');
      return res;
    },
  },
  initialState: [],
});

export type IReverseDnsHandler = BaseDecorator<IReverseDnsRecord, true>;
