import * as React from 'react';
import { IconButton, MuiIcons, List, ListItemButton, Popover } from 'elements';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { useAsync } from 'hooks';
import { invoicesService } from 'services';
import { showSystemMessage } from 'utils';
import { useTranslation } from 'react-i18next';
import PopupState, {
  bindTrigger,
  bindHover,
  bindPopover,
  bindPopper,
} from 'material-ui-popup-state';
import { INVOICE_REMINDERS } from './constants';
import { IInvoiceMenuItems } from './types';

const buildMenuItems = (
  config: IInvoiceMenuItems.IMenuItemsBuilderConfig
): IInvoiceMenuItems.MenuItemNamespace[] => {
  const {
    isResendEnabled,
    isCancelEnabled,
    status,
    showActions,
    showEdit,
    accessLevel,
    total,
    manualStatus,
    // currentAccessLevel,
  } = config;

  const menuItems: IInvoiceMenuItems.MenuItemNamespace[] = [];

  const isMarkedPaidManually = manualStatus === 'PAID';
  const isCanceled = status === 'CANCELED';

  if (showEdit) {
    menuItems.push('edit');
  }

  if (isResendEnabled) {
    menuItems.push('resend');
  }

  if (showActions) {
    menuItems.push('resend-reminders');

    if (
      accessLevel !== 'tenant' &&
      !isMarkedPaidManually &&
      total === 0 &&
      !isCanceled
    ) {
      menuItems.push('mark-paid');
    }

    if (accessLevel !== 'tenant' && isMarkedPaidManually) {
      menuItems.push('reset-manual-status');
    }

    if (isCancelEnabled) {
      menuItems.push('cancel');
    }
    
    if (status === 'CANCELED') {
      menuItems.push('remove');
    }
  }

  return menuItems;
};

export const ListItemMenu = (
  props: React.PropsWithChildren<IInvoiceMenuItems.IProps>
) => {
  const {
    id,
    isRequesting,
    onCancelInvoice,
    showActions,
    isCancelEnabled,
    isResendEnabled,
    onDelete,
    status,
    onEdit,
    accessLevel,
    currentAccessLevel,
    total,
    onMarkAsPaid,
    onResetStatus,
    manualStatus,
  } = props;
  const { t } = useTranslation();
  const resentInvoiceService = useAsync(invoicesService.resendInvoice);
  const sendReminders = useAsync(invoicesService.sendReminders);

  const showEdit = !!onEdit;

  const menuItems = buildMenuItems({
    accessLevel,
    currentAccessLevel,
    showActions,
    isResendEnabled,
    isCancelEnabled,
    showEdit,
    manualStatus,
    status,
    total,
  });

  const innerPopupState = usePopupState({
    variant: 'popover',
    popupId: 'innerPopover',
  });

  if (!menuItems.length)
    return (
      <IconButton size="small" disabled className="faded">
        <MuiIcons.MoreVert className="fs-22 hidden" />
      </IconButton>
    );

  return (
    <PopupState variant="popover">
      {(popupState) => {
        return (
          <>
            <IconButton size="small" {...bindTrigger(popupState)}>
              <MuiIcons.MoreVert className="fs-22" />
            </IconButton>

            <Popover
              {...bindPopper(popupState)}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              onClose={() => popupState.close()}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <List>
                {menuItems.map((item: string) => {
                  const key = `invoice-${item}-menu-item`;
                  if (item === 'cancel') {
                    return (
                      <ListItemButton
                        key={key}
                        data-test-id={`invoice-resend-menu-item`}
                        disabled={isRequesting}
                        onClick={() => {
                          onCancelInvoice();
                          popupState.close();
                        }}
                      >
                        {t(
                          status !== 'CANCELED'
                            ? 'invoices.buttons.cancel'
                            : 'invoices.buttons.unCancel'
                        )}
                      </ListItemButton>
                    );
                  }
                  if (item === 'resend') {
                    return (
                      <ListItemButton
                        key={key}
                        data-test-id={`invoice-resend-menu-item`}
                        disabled={resentInvoiceService.isPending}
                        onClick={() =>
                          !resentInvoiceService.isPending &&
                          resentInvoiceService
                            .execute(id)
                            .then(() =>
                              showSystemMessage(
                                'invoices.notify.resendInvoice.success',
                                'success'
                              )
                            )
                            .catch((err) =>
                              showSystemMessage(err.message, 'error')
                            )
                            .finally(popupState.close)
                        }
                      >
                        {t(`invoices.item.menu.resendInvoice`)}
                      </ListItemButton>
                    );
                  }

                  if (item === 'remove') {
                    return (
                      <ListItemButton
                        key={key}
                        data-test-id={`invoice-remove-menu-item`}
                        disabled={isRequesting}
                        onClick={() => {
                          onDelete(id);
                          popupState.close();
                        }}
                      >
                        {t('common.delete')}
                      </ListItemButton>
                    );
                  }

                  if (item === 'edit' && onEdit) {
                    return (
                      <ListItemButton
                        key={key}
                        data-test-id={`invoice-edit-menu-item`}
                        onClick={() => {
                          onEdit();
                          popupState.close();
                        }}
                      >
                        {t(`common.edit`)}
                      </ListItemButton>
                    );
                  }

                  if (item === 'mark-paid' && onMarkAsPaid) {
                    return (
                      <ListItemButton
                        key={key}
                        data-test-id={`invoice-mark-as-paid-menu-item`}
                        onClick={() => {
                          onMarkAsPaid();
                          popupState.close();
                        }}
                      >
                        {t(`invoices.item.menu.markAsPaid`)}
                      </ListItemButton>
                    );
                  }

                  if (item === 'reset-manual-status' && onResetStatus) {
                    return (
                      <ListItemButton
                        key={key}
                        data-test-id={`invoice-reset-manual-status-menu-item`}
                        onClick={() => {
                          onResetStatus();
                          popupState.close();
                        }}
                      >
                        {t(`invoices.item.menu.resetStatus`)}
                      </ListItemButton>
                    );
                  }

                  if (item === 'resend-reminders') {
                    return (
                      <React.Fragment key={item}>
                        <ListItemButton {...bindHover(innerPopupState)}>
                          <div className="flex justify-between align-center">
                            {t(`invoices.item.menu.reminders`)}
                            <MuiIcons.ChevronRight className="fs-22 steel pointer ml-5" />
                          </div>
                        </ListItemButton>
                        <HoverPopover
                          {...bindPopover(innerPopupState)}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <List>
                            {INVOICE_REMINDERS.map((reminder) => {
                              return (
                                <ListItemButton
                                  key={reminder}
                                  data-test-id={`${reminder}-menu-item`}
                                  onClick={() => {
                                    sendReminders
                                      .execute(id, reminder)
                                      .then(() => {
                                        showSystemMessage(
                                          'invoices.notify.reminders.send.success',
                                          'success'
                                        );
                                      })
                                      .catch((err) =>
                                        showSystemMessage(err.message, 'error')
                                      );
                                    popupState.close();
                                    innerPopupState.close();
                                  }}
                                >
                                  {t(
                                    `invoices.item.menu.reminders.options.${reminder}`
                                  )}
                                </ListItemButton>
                              );
                            })}
                          </List>
                        </HoverPopover>
                      </React.Fragment>
                    );
                  }

                  return null;
                })}
              </List>
            </Popover>
          </>
        );
      }}
    </PopupState>
  );
};
