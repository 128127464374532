import * as React from 'react';
import { virtualMachinesService } from 'services';
import {
  Button,
  MuiIcons,
  PrimaryTextSpan,
  createColumnHelper,
} from 'elements';
import { TFunction } from 'react-i18next';
import { numberToCurrency } from 'utils';

type ColumnProps = {
  t: TFunction;
  onSelect: (server: IDedicatedServer.TenantosServer) => void;
};

const column = createColumnHelper<IDedicatedServer.TenantosServer>();

export const getTenantosServersTableColumn = (props: ColumnProps) => {
  const { t, onSelect } = props;
  return [
    column.accessor('serverName', {
      header: (
        <div className="fs-15 weight-normal pl-30">{t('table.head.model')}</div>
      ),
      disableSort: false,
      cell: ({
        row: { original: server },
      }: ICell<IDedicatedServer.TenantosServer>) => {
        return (
          <div className="lh-12 flex align-center">
            <PrimaryTextSpan className="mr-10">
              <MuiIcons.LanOutlined className="fs-20" />
            </PrimaryTextSpan>
            <span>{server.model}</span>
          </div>
        );
      },
    }),
    column.accessor('cpuMHz', {
      header: (
        <div className="fs-15 weight-normal">
          {t('tenantosServers.dialog.table.head.cpu')}
        </div>
      ),
      disableSort: false,
      cell: ({
        row: { original: server },
      }: ICell<IDedicatedServer.TenantosServer>) => {
        return (
          <div className="lh-12">
            <div>{server.cpuModel}</div>
            <div>
              {server.cpuCount}x{server.cpuCores} ({server.cpuMHz} MHz)
            </div>
          </div>
        );
      },
    }),

    column.accessor('ramMb', {
      header: (
        <div className="fs-15 weight-normal">
          {t('tenantosServers.dialog.table.head.memory')}
        </div>
      ),
      disableSort: false,
      alignment: 'right',
      size: 100,
      cell: ({
        row: { original: server },
      }: ICell<IDedicatedServer.TenantosServer>) => {
        return <div className="lh-12">{server.ramMb / 1024}</div>;
      },
    }),

    column.accessor('storageMb', {
      header: (
        <div className="fs-15 weight-normal">
          {t('tenantosServers.dialog.table.head.storage')}
        </div>
      ),
      alignment: 'right',
      disableSort: false,
      size: 100,
      cell: ({
        row: { original: server },
      }: ICell<IDedicatedServer.TenantosServer>) => {
        return <div className="lh-12">{server.disks}</div>;
      },
    }),
    column.accessor('psuCount', {
      header: <div className="fs-15 weight-normal">{t('table.head.psu')}</div>,
      alignment: 'right',
      disableSort: false,
      size: 90,
      cell: ({
        row: { original: server },
      }: ICell<IDedicatedServer.TenantosServer>) => {
        return <div className="lh-12">{server.psuCount}</div>;
      },
    }),

    column.accessor('raidEnabled', {
      header: <div className="fs-15 weight-normal">{t('table.head.raid')}</div>,
      disableSort: false,
      size: 70,
      cell: ({
        row: { original: server },
      }: ICell<IDedicatedServer.TenantosServer>) => {
        return (
          <div className="lh-12">
            {server.raidEnabled ? (
              <MuiIcons.Check className="success fs-16" />
            ) : (
              <MuiIcons.Close className="error fs-16" />
            )}
          </div>
        );
      },
    }),
    column.accessor('location', {
      header: (
        <div className="fs-15 weight-normal">{t('table.head.location')}</div>
      ),
      disableSort: false,
      size: 100,
      cell: ({
        row: { original: server },
      }: ICell<IDedicatedServer.TenantosServer>) => {
        return <div className="lh-12">{server.location}</div>;
      },
    }),
    column.accessor('tenantHardwareMonthlyPrice', {
      header: (
        <div className="fs-15 weight-normal">{t('table.head.monthlyCost')}</div>
      ),
      disableSort: false,
      size: 150,
      alignment: 'right',
      cell: ({
        row: { original: server },
      }: ICell<IDedicatedServer.TenantosServer>) => {
        return (
          <div className="lh-12">
            {numberToCurrency(+server.tenantHardwareMonthlyPrice, false)}
          </div>
        );
      },
    }),

    column.accessor('status', {
      header: '',
      disableSort: true,
      size: 75,
      cell: ({
        row: { original: server },
      }: ICell<IDedicatedServer.TenantosServer>) => {
        return (
          <div className="flex justify-end full-width align-center">
            <Button size="small" onClick={() => onSelect(server)}>
              {t('tenantosServers.dialog.select.buttons.select')}
            </Button>
          </div>
        );
      },
    }),
  ];
};

export const loadOsListToOptions = async (q: string) => {
  const res = await virtualMachinesService.getTenantosTemplates({
    q,
    page: 1,
    perPage: 1000,
  });

  return res.data.map((os) => ({
    label: os.name,
    value: os.id,
    // @ts-ignore
    osFamilyId: os.os.osFamilyId,
    tenantosId: os.tenantosId,
    lang: os.lang,
  }));
};
