import React, { useCallback, useMemo } from 'react';
import { Button, styled } from 'elements';
import { confirm, remCalc, round, showSystemMessage } from 'utils';
import { useTranslation } from 'react-i18next';
import { AnyFunc } from 'global-shapes';
import { usePermissions, useUserHash } from 'hooks';
import dayjs from 'dayjs';
import { partnersService } from 'services';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import ConfirmationProdStartDialog from './ConfirmationProdStartDialog';

type Props = {
  onStarted: AnyFunc;
};

const Root = styled('div')(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: remCalc(48),
    padding: remCalc(5, 15),
    position: 'fixed',
    top: 0,
    width: '100%',
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  };
});

const OBSERVERS = {
  currentUser: StateHandlers.currentUser,
};

type IViewProps = typeof OBSERVERS;

const View = observer(function (props: Props & IViewProps) {
  const { onStarted, currentUser } = props;
  const { t } = useTranslation();

  const { isPartner, isSwitchToProdRequested, isExpiredAccount } =
    usePermissions();
  const [, changeUrl] = useUserHash();
  const [isConfirmOpen, handleConfirmOpenDialog] = React.useState(false);

  const leftDays = useMemo(() => {
    const expiresAt = dayjs(currentUser.data?.partner?.evaluationExpiresAt);
    const now = dayjs();
    const days = expiresAt.diff(now, 'hours') / 24;
    return round(days, 0);
  }, [currentUser.data?.partner?.evaluationExpiresAt]);

  const isExpiresToday = useMemo(() => {
    const expiresAt = dayjs(currentUser.data?.partner?.evaluationExpiresAt);
    const now = dayjs();
    const endOf = expiresAt.endOf('day');
    return endOf.diff(now, 'hours') <= 24;
  }, [currentUser.data?.partner?.evaluationExpiresAt]);

  const checkIfPersonalDataFulfilled = useCallback(() => {
    return currentUser.data?.partner?.address
      ? !['line1', 'zipCode', 'city', 'countryCode'].some(
          // @ts-ignore
          (key) => !currentUser.data?.partner?.address[key]
        )
      : false;
  }, [JSON.stringify(currentUser)]);

  const onStartClick = useCallback(() => {
    const useHasAllDataCompleted = checkIfPersonalDataFulfilled();
    if (!useHasAllDataCompleted)
      return confirm({
        title: t('app.evaluationBar.noFullfiledData.confirm.title'),
        content: t('app.evaluationBar.noFullfiledData.confirm.content'),
        cancelLabel: 'app.evaluationBar.noFullfiledData.confirm.button',
        onCancel: () => changeUrl('/settings/partner-info'),
      });
    handleConfirmOpenDialog(true);
  }, [JSON.stringify(currentUser)]);

  const onStart = useCallback(() => {
    const request = partnersService.requestSwitchToProd(
      // @ts-ignore
      currentUser.data?.partner?.id
    );

    return request
      .then(() => {
        onStarted();
        handleConfirmOpenDialog(false);
      })
      .catch((err) => showSystemMessage(err.message, 'error'));
  }, [isPartner, JSON.stringify(currentUser)]);

  const text = useMemo(() => {
    const requestedNamespace = isSwitchToProdRequested ? 'requested.' : '';
    if (isExpiredAccount) {
      return t('app.evaluationBar.expired.text');
    }
    return isExpiresToday
      ? t(`app.evaluationBar.${requestedNamespace}text.expiresToday`)
      : t(`app.evaluationBar.${requestedNamespace}text`, { value: leftDays });
  }, [leftDays, isExpiresToday, isExpiredAccount, isSwitchToProdRequested]);

  return (
    <Root>
      <div className="mr-15 fs-14">{text}</div>
      {isPartner && (!isSwitchToProdRequested || isExpiredAccount) && (
        <Button size="small" color="default" onClick={onStartClick}>
          {t('app.evaluationBar.button')}
        </Button>
      )}
      <ConfirmationProdStartDialog
        title={t('app.evaluationBar.confirm.title')}
        open={isConfirmOpen}
        onClose={() => handleConfirmOpenDialog(false)}
        onSave={onStart}
      />
    </Root>
  );
});

const EvaluationBar = (props: Props) => <View {...props} {...OBSERVERS} />;

export default EvaluationBar;
