import * as R from 'ramda';
import { ValueType } from 'global-shapes';
import { compact } from 'utils';
import { ActionOption, IActionHandlers } from './types';
import { ISnapshot } from 'snapshots';

export const generateVmActionItems = (
  status: IVmTypes.VMStatus,
  attrs: {
    isPublic: boolean;
    isRSEnabled: boolean;
    isEditable: boolean;
    isRSTaskActive: boolean;
    snapshot?: ISnapshot & IScheduledTasks.Task;
    hasInjectedMedia: boolean;
    isRequesting: boolean;
    isTenant: boolean;
    isInFolder?: boolean;
    folders: ValueType<string>[];
  },
  handlers: IActionHandlers
): ActionOption[] => {
  const {
    isRSEnabled,
    isEditable,
    isPublic,
    isRSTaskActive,
    snapshot,
    isRequesting,
    isTenant,
    folders,
    isInFolder,
    hasInjectedMedia,
  } = attrs;
  const {
    openDetails,
    powerOn,
    powerOff,
    shutDown,
    createSnapshot,
    removeSnapshot,
    revertSnapshot,
    console: openConsole,
    reset,
    edit,
    enableRemoteSupport: enableRs,
    disableRemoteSupport: disableRs,
    assignBadges,
    moveToFolder,
    removeFromFolder,
    insertMedia,
    ejectMedia,
    switchVmNetworkType,
    clone,
    attachNetwork,
    removeScheduledSnapshotTask,
  } = handlers;
  if (!isEditable) {
    return [
      { value: 'openDetails', label: 'openDetails', onClick: openDetails },
    ];
  }

  const AssignBadgesOptions = isTenant
    ? undefined
    : { value: 'assignBadges', label: 'assignBadges', onClick: assignBadges };

  const MoveToFolderOption =
    !isInFolder && folders.length
      ? {
          value: folders,
          label: 'moveToFolder',
          disabled: isRequesting,
          onClick: moveToFolder,
        }
      : undefined;

  const mediaOption = hasInjectedMedia
    ? {
        value: 'ejectMedia',
        label: 'ejectMedia',
        disabled: isRequesting,
        onClick: ejectMedia,
      }
    : {
        value: 'insertMedia',
        label: 'insertMedia',
        disabled: isRequesting,
        onClick: insertMedia,
      };

  const attachNetworkOption = {
    value: 'attachNetwork',
    label: 'attachNetwork',
    disabled: isRequesting,
    onClick: attachNetwork,
  };

  const switchVmTypeOption = {
    value: 'switchVmNetworkType',
    label: 'switchVmNetworkType',
    disabled: isRequesting,
    onClick: switchVmNetworkType,
  };

  const cloneVmOption = {
    value: 'clone',
    label: 'clone',
    disabled: isRequesting,
    onClick: clone,
  };

  const RemoveFromFolderOption = isInFolder
    ? {
        value: 'removeFromFolder',
        label: 'removeFromFolder',
        disabled: isRequesting,
        onClick: removeFromFolder,
      }
    : undefined;

  const remoteSupportOption = isPublic
    ? undefined
    : isRSEnabled
    ? {
        value: 'disableRemoteSupport',
        label: 'disableRemoteSupport',
        onClick: disableRs,
        disabled: isRSTaskActive,
      }
    : {
        value: 'enableRemoteSupport',
        label: 'enableRemoteSupport',
        onClick: enableRs,
        disabled: isRSTaskActive,
      };

  const hasSnapshot = !!snapshot && !snapshot?.schedule;
  const scheduledSnapshotTask =
    snapshot?.taskOperationName === 'ServiceSnapshotCreate'
      ? snapshot
      : undefined;

  const createSnapshotOption = scheduledSnapshotTask
    ? undefined
    : !hasSnapshot && {
        value: 'createSnapshot',
        label: 'createSnapshot',
        disabled: isRequesting,
        onClick: createSnapshot,
      };

  const revertSnapshotOption = !scheduledSnapshotTask &&
    hasSnapshot && {
      value: 'revertSnapshot',
      label: 'revertSnapshot',
      disabled: isRequesting,
      onClick: revertSnapshot,
    };

  const removeSnapshotOption = !scheduledSnapshotTask &&
    hasSnapshot && {
      value: 'removeSnapshot',
      label: 'removeSnapshot',
      disabled: isRequesting,
      onClick: removeSnapshot,
    };

  const removeScheduledSnapshotTaskOption = scheduledSnapshotTask && {
    value: 'removeScheduledSnapshotTask',
    label: 'removeScheduledSnapshotTask',
    disabled: isRequesting,
    onClick: () => removeScheduledSnapshotTask(scheduledSnapshotTask),
  };

  switch (status) {
    case 'FAILED_CREATION':
      return [];
    case 'UNRESOLVED':
      return [];
    case 'RESOLVED':
      return [];
    case 'SUSPENDED':
      return [];
    case 'UNKNOWN':
      return [];
    case 'UNRECOGNIZED':
      return [];
    case 'POWERED_OFF':
      return compact([
        { value: 'openDetails', label: 'openDetails', onClick: openDetails },
        { value: 'devider', label: 'devider' },
        { value: 'powerOn', label: 'powerOn', onClick: powerOn },
        { value: 'edit', label: 'edit', onClick: edit },
        { value: 'devider', label: 'devider' },
        createSnapshotOption,
        revertSnapshotOption,
        removeSnapshotOption,
        removeScheduledSnapshotTaskOption,
        { value: 'devider', label: 'devider' },
        mediaOption,
        { value: 'devider', label: 'devider' },
        attachNetworkOption,
        remoteSupportOption,
        switchVmTypeOption,
        cloneVmOption,
        MoveToFolderOption,
        RemoveFromFolderOption,
        AssignBadgesOptions,
      ]);
    case 'WAITING_FOR_INPUT':
      return compact([
        { value: 'openDetails', label: 'openDetails', onClick: openDetails },
        { value: 'devider', label: 'devider' },
        { value: 'powerOn', label: 'powerOn', onClick: powerOn },
        { value: 'devider', label: 'devider' },
        MoveToFolderOption,
        RemoveFromFolderOption,
        AssignBadgesOptions as ActionOption,
      ]);
    case 'POWERED_ON':
      return compact([
        { value: 'openDetails', label: 'openDetails', onClick: openDetails },
        { value: 'devider', label: 'devider' },
        { value: 'shutDown', label: 'shutDown', onClick: shutDown },
        { value: 'powerOff', label: 'powerOff', onClick: powerOff },
        { value: 'reset', label: 'reset', onClick: reset },
        { value: 'edit', label: 'edit', onClick: edit },
        { value: 'console', label: 'console', onClick: openConsole },
        { value: 'devider', label: 'devider' },
        createSnapshotOption,
        revertSnapshotOption,
        removeSnapshotOption,
        removeScheduledSnapshotTaskOption,
        { value: 'devider', label: 'devider' },
        mediaOption,
        { value: 'devider', label: 'devider' },
        attachNetworkOption,
        remoteSupportOption,
        switchVmTypeOption,
        cloneVmOption,
        MoveToFolderOption,
        RemoveFromFolderOption,
        AssignBadgesOptions,
      ]);

    default:
      return [];
  }
};

export const generateDedicatedServerActionItems = (
  status: IVmTypes.VMStatus,
  attrs: {
    isEditable: boolean;
    isTenant: boolean;
    isRequesting: boolean;
    isInFolder?: boolean;
    folders: ValueType<string>[];
  },
  handlers: IActionHandlers
): ActionOption[] => {
  const { isEditable, isTenant, isRequesting, folders, isInFolder } = attrs;
  const {
    openDetails,
    powerOn,
    powerOff,
    console: openConsole,
    reset,
    edit,
    assignBadges,
    moveToFolder,
    removeFromFolder,
    reinstallOs,
  } = handlers;
  if (!isEditable) {
    return [
      { value: 'openDetails', label: 'openDetails', onClick: openDetails },
    ];
  }

  const MoveToFolderOption =
    !isInFolder && folders.length
      ? {
          value: folders,
          label: 'moveToFolder',
          disabled: isRequesting,
          onClick: moveToFolder,
        }
      : undefined;

  const RemoveFromFolderOption = isInFolder
    ? {
        value: 'removeFromFolder',
        label: 'removeFromFolder',
        disabled: isRequesting,
        onClick: removeFromFolder,
      }
    : undefined;

  const reinstallOsOption = {
    value: 'reinstallOs',
    label: 'reinstallOs',
    disabled: isRequesting,
    onClick: reinstallOs,
  };

  const AssignBadgesOptions = isTenant
    ? undefined
    : { value: 'assignBadges', label: 'assignBadges', onClick: assignBadges };

  switch (status) {
    case 'FAILED_CREATION':
      return [];
    case 'UNRESOLVED':
      return [];
    case 'RESOLVED':
      return [];
    case 'SUSPENDED':
      return [];
    case 'UNKNOWN':
      return [];
    case 'UNRECOGNIZED':
      return [];
    case 'POWERED_OFF':
      return compact([
        { value: 'openDetails', label: 'openDetails', onClick: openDetails },
        { value: 'devider', label: 'devider' },
        {
          value: 'powerOn',
          label: 'powerOn',
          onClick: powerOn,
          disabled: isRequesting,
        },
        { value: 'edit', label: 'edit', onClick: edit, disabled: isRequesting },
        { value: 'devider', label: 'devider' },
        reinstallOsOption,
        { value: 'devider', label: 'devider' },
        AssignBadgesOptions,
        MoveToFolderOption,
        RemoveFromFolderOption,
      ]);
    case 'WAITING_FOR_INPUT':
      return compact([
        { value: 'openDetails', label: 'openDetails', onClick: openDetails },
        { value: 'devider', label: 'devider' },
        {
          value: 'powerOn',
          label: 'powerOn',
          onClick: powerOn,
          disabled: isRequesting,
        },
        { value: 'devider', label: 'devider' },
        AssignBadgesOptions as ActionOption,
        MoveToFolderOption,
        RemoveFromFolderOption,
      ]);
    case 'POWERED_ON':
      return compact([
        { value: 'openDetails', label: 'openDetails', onClick: openDetails },
        { value: 'devider', label: 'devider' },
        {
          value: 'powerOff',
          label: 'powerOff',
          onClick: powerOff,
          disabled: isRequesting,
        },
        {
          value: 'reset',
          label: 'reset',
          onClick: reset,
          disabled: isRequesting,
        },
        { value: 'edit', label: 'edit', onClick: edit, disabled: isRequesting },
        {
          value: 'console',
          label: 'console',
          onClick: openConsole,
          disabled: isRequesting,
        },
        { value: 'devider', label: 'devider' },
        reinstallOsOption,
        { value: 'devider', label: 'devider' },
        AssignBadgesOptions,
        MoveToFolderOption,
        RemoveFromFolderOption,
      ]);

    default:
      return [];
  }
};

export const generateRdsActionItems = (
  status: string,
  attrs: {
    isRSEnabled: boolean;
    isEditable: boolean;
    isRSTaskActive: boolean;
    instances?: ValueType<string>[];
    snapshot?: ISnapshot & IScheduledTasks.Task;
    isRequesting: boolean;
    isTenant: boolean;
    isProvider: boolean;
    agent?: IAgentActionsConfig;
  },
  handlers: IActionHandlers
): ActionOption[] => {
  const {
    isRSEnabled,
    isEditable,
    isRSTaskActive,
    instances,
    snapshot,
    isRequesting,
    isTenant,
    isProvider,
    agent,
  } = attrs;
  const {
    openDetails,
    powerOn,
    powerOff,
    reboot,
    shutDown,
    createSnapshot,
    removeSnapshot,
    revertSnapshot,
    console: openConsole,
    reset,
    enableRemoteSupport: enableRs,
    disableRemoteSupport: disableRs,
    assignBadges,
    updateAgent,
    removeScheduledSnapshotTask,
  } = handlers;

  const AssignBadgesOptions = isTenant
    ? undefined
    : { value: 'assignBadges', label: 'assignBadges', onClick: assignBadges };

  if (!isEditable) {
    return [
      { value: 'openDetails', label: 'openDetails', onClick: openDetails },
    ];
  }

  const agentUpdateOption =
    isProvider && agent?.requiresUpdate
      ? {
          value: 'updateAgent',
          label: 'updateAgent',
          disabled: isRequesting,
          onClick: updateAgent,
        }
      : undefined;

  const hasSnapshot = !!snapshot && !snapshot?.schedule;
  const scheduledSnapshotTask =
    snapshot?.taskOperationName === 'ServiceSnapshotCreate'
      ? snapshot
      : undefined;

  const createSnapshotOption = !scheduledSnapshotTask &&
    !hasSnapshot && {
      value: 'createSnapshot',
      label: 'createSnapshot',
      disabled: isRequesting,
      onClick: createSnapshot,
    };

  const revertSnapshotOption = !scheduledSnapshotTask &&
    hasSnapshot && {
      value: 'revertSnapshot',
      label: 'revertSnapshot',
      disabled: isRequesting,
      onClick: revertSnapshot,
    };

  const removeSnapshotOption = !scheduledSnapshotTask &&
    hasSnapshot && {
      value: 'removeSnapshot',
      label: 'removeSnapshot',
      disabled: isRequesting,
      onClick: removeSnapshot,
    };

  const removeScheduledSnapshotTaskOption = scheduledSnapshotTask && {
    value: 'removeScheduledSnapshotTask',
    label: 'removeScheduledSnapshotTask',
    disabled: isRequesting,
    onClick: () => removeScheduledSnapshotTask(scheduledSnapshotTask),
  };

  switch (status) {
    case 'FAILED_CREATION':
      return [];
    case 'UNRESOLVED':
      return [];
    case 'RESOLVED':
      return [];
    case 'SUSPENDED':
      return [];
    case 'UNKNOWN':
      return [];
    case 'UNRECOGNIZED':
      return [];
    case 'POWERED_OFF':
      return compact([
        { value: 'openDetails', label: 'openDetails', onClick: openDetails },
        { value: 'devider', label: 'devider' },
        { value: 'powerOn', label: 'powerOn', onClick: powerOn },
        { value: 'devider', label: 'devider' },
        createSnapshotOption,
        revertSnapshotOption,
        removeSnapshotOption,
        removeScheduledSnapshotTaskOption,
        { value: 'devider', label: 'devider' },
        agentUpdateOption,
        isRSEnabled
          ? {
              value: 'disableRemoteSupport',
              label: 'disableRemoteSupport',
              disabled: isRSTaskActive,
              onClick: disableRs,
            }
          : {
              value: 'enableRemoteSupport',
              label: 'enableRemoteSupport',
              disabled: isRSTaskActive,
              onClick: enableRs,
            },
        AssignBadgesOptions as ActionOption,
      ]);
    case 'WAITING_FOR_INPUT':
      return [
        { value: 'openDetails', label: 'openDetails', onClick: openDetails },
        { value: 'devider', label: 'devider' },
        { value: 'powerOn', label: 'powerOn', onClick: powerOn },
        { value: 'devider', label: 'devider' },
        AssignBadgesOptions as ActionOption,
      ];
    case 'POWERED_ON':
      return compact([
        { value: 'openDetails', label: 'openDetails', onClick: openDetails },
        { value: 'devider', label: 'devider' },
        { value: 'shutDown', label: 'shutDown', onClick: shutDown },
        { value: 'powerOff', label: 'powerOff', onClick: powerOff },
        { value: 'reboot', label: 'reboot', onClick: reboot },
        { value: 'reset', label: 'reset', onClick: reset },
        {
          value: instances || [],
          label: 'console',
          onClick: openConsole,
        },
        { value: 'devider', label: 'devider' },
        createSnapshotOption,
        revertSnapshotOption,
        removeSnapshotOption,
        removeScheduledSnapshotTaskOption,
        { value: 'devider', label: 'devider' },
        agentUpdateOption,
        isRSEnabled
          ? {
              value: 'disableRemoteSupport',
              label: 'disableRemoteSupport',
              disabled: isRSTaskActive,
              onClick: disableRs,
            }
          : {
              value: 'enableRemoteSupport',
              label: 'enableRemoteSupport',
              disabled: isRSTaskActive,
              onClick: enableRs,
            },
        AssignBadgesOptions as ActionOption,
      ]);

    default:
      return [];
  }
};

export const generateCoreActionItems = (
  status: string,
  attrs: {
    isRSEnabled: boolean;
    isEditable: boolean;
    isRSTaskActive: boolean;
    snapshot?: ISnapshot & IScheduledTasks.Task;
    isRequesting: boolean;
    isTenant: boolean;
    isProvider: boolean;
    agent?: IAgentActionsConfig;
  },
  handlers: IActionHandlers
): ActionOption[] => {
  const {
    isRSEnabled,
    isEditable,
    isRSTaskActive,
    snapshot,
    isRequesting,
    isTenant,
    isProvider,
    agent,
  } = attrs;

  const {
    openDetails,
    powerOn,
    powerOff,
    shutDown,
    createSnapshot,
    removeSnapshot,
    revertSnapshot,
    console: openConsole,
    reset,
    enableRemoteSupport: enableRs,
    disableRemoteSupport: disableRs,
    assignBadges,
    updateAgent,
    removeScheduledSnapshotTask,
  } = handlers;

  const agentUpdateOption =
    isProvider && agent?.requiresUpdate
      ? {
          value: 'updateAgent',
          label: 'updateAgent',
          disabled: isRequesting,
          onClick: updateAgent,
        }
      : undefined;

  const AssignBadgesOptions = isTenant
    ? undefined
    : { value: 'assignBadges', label: 'assignBadges', onClick: assignBadges };

  if (!isEditable) {
    return [
      { value: 'openDetails', label: 'openDetails', onClick: openDetails },
    ];
  }

  const hasSnapshot = !!snapshot && !snapshot?.schedule;
  const scheduledSnapshotTask =
    snapshot?.taskOperationName === 'ServiceSnapshotCreate'
      ? snapshot
      : undefined;

  const createSnapshotOption = !scheduledSnapshotTask &&
    !hasSnapshot && {
      value: 'createSnapshot',
      label: 'createSnapshot',
      disabled: isRequesting,
      onClick: createSnapshot,
    };

  const revertSnapshotOption = !scheduledSnapshotTask &&
    hasSnapshot && {
      value: 'revertSnapshot',
      label: 'revertSnapshot',
      disabled: isRequesting,
      onClick: revertSnapshot,
    };

  const removeSnapshotOption = !scheduledSnapshotTask &&
    hasSnapshot && {
      value: 'removeSnapshot',
      label: 'removeSnapshot',
      disabled: isRequesting,
      onClick: removeSnapshot,
    };

  const removeScheduledSnapshotTaskOption = scheduledSnapshotTask && {
    value: 'removeScheduledSnapshotTask',
    label: 'removeScheduledSnapshotTask',
    disabled: isRequesting,
    onClick: () => removeScheduledSnapshotTask(scheduledSnapshotTask),
  };

  switch (status) {
    case 'FAILED_CREATION':
      return [];
    case 'UNRESOLVED':
      return [];
    case 'RESOLVED':
      return [];
    case 'SUSPENDED':
      return [];
    case 'UNKNOWN':
      return [];
    case 'UNRECOGNIZED':
      return [];
    case 'POWERED_OFF':
      return compact([
        { value: 'openDetails', label: 'openDetails', onClick: openDetails },
        { value: 'devider', label: 'devider' },
        { value: 'powerOn', label: 'powerOn', onClick: powerOn },
        { value: 'devider', label: 'devider' },
        createSnapshotOption,
        revertSnapshotOption,
        removeSnapshotOption,
        removeScheduledSnapshotTaskOption,
        { value: 'devider', label: 'devider' },
        agentUpdateOption,
        isRSEnabled
          ? {
              value: 'disableRemoteSupport',
              label: 'disableRemoteSupport',
              onClick: disableRs,
              disabled: isRSTaskActive,
            }
          : {
              value: 'enableRemoteSupport',
              label: 'enableRemoteSupport',
              onClick: enableRs,
              disabled: isRSTaskActive,
            },
        AssignBadgesOptions as ActionOption,
      ]);
    case 'WAITING_FOR_INPUT':
      return [
        { value: 'openDetails', label: 'openDetails', onClick: openDetails },
        { value: 'devider', label: 'devider' },
        { value: 'powerOn', label: 'powerOn', onClick: powerOn },
        { value: 'devider', label: 'devider' },
        AssignBadgesOptions as ActionOption,
      ];
    case 'POWERED_ON':
      return compact([
        { value: 'openDetails', label: 'openDetails', onClick: openDetails },
        { value: 'devider', label: 'devider' },
        { value: 'shutDown', label: 'shutDown', onClick: shutDown },
        { value: 'powerOff', label: 'powerOff', onClick: powerOff },
        { value: 'reset', label: 'reset', onClick: reset },
        { value: 'console', label: 'console', onClick: openConsole },
        { value: 'devider', label: 'devider' },
        createSnapshotOption,
        revertSnapshotOption,
        removeSnapshotOption,
        removeScheduledSnapshotTaskOption,
        { value: 'devider', label: 'devider' },
        agentUpdateOption,
        isRSEnabled
          ? {
              value: 'disableRemoteSupport',
              label: 'disableRemoteSupport',
              onClick: disableRs,
              disabled: isRSTaskActive,
            }
          : {
              value: 'enableRemoteSupport',
              label: 'enableRemoteSupport',
              onClick: enableRs,
              disabled: isRSTaskActive,
            },
        AssignBadgesOptions as ActionOption,
      ]);

    default:
      return [];
  }
};

export function buildActionsItemsData(items: ActionOption[]) {
  return R.filter(
    (el) =>
      ![
        'delete',
        'openDetails',
        'removeConnectivity',
        'enableRemoteSupport',
        'disableRemoteSupport',
        'createSnapshot',
        'revertSnapshot',
        'removeSnapshot',
        'removeScheduledSnapshotTask',
        'assignBadges',
        'updateAgent',
        'console',
        'devider',
        'insertMedia',
        'ejectMedia',
        'editNic',
        'clone',
        'attachNetwork',
        'detachNetwork',
        'reinstallOs',
        'moveToFolder',
        'removeFromFolder',
        'switchVmNetworkType',
      ].includes(el.value as string),
    items || []
  );
}
