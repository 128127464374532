import * as React from 'react';
import cn from 'classnames';
import { ALL_TEST_IDS } from 'enums';
import { Row, Col, Alert, useMediaQuery } from 'elements';
import {
  showSystemMessage,
  initTagManager,
  getCurrentLang,
  changeLang,
} from 'utils';
import MobileLogo from 'assets/images/mobile-dark-logo.svg';
import { useAsync, useQuery, useState } from 'hooks';
import { ILanguage } from 'global-shapes';
import { authService, tenantsService } from 'services';
import { useTranslation } from 'react-i18next';
import UserInfoForm from './SignUp/UserInfoForm';
import CustomerCompanyInfoForm from './SignUp/CustomerCompanyInfoForm';
import {
  COMPANY_INITIAL_FORM_VALUES,
  USER_INITIAL_FORM_VALUES,
} from './SignUp/constants';
import { reformatCustomerPayloadForApi } from './SignUp/helpers';
import {
  AuthRoot,
  AuthMainColWrapper,
  AuthMainColRight,
  AuthLeftContent,
  AuthMobileLogo,
  AuthFooterLink,
} from './Styled';

const INIT_STATE = {
  isLinkResent: false,
  valid: true,
  user: USER_INITIAL_FORM_VALUES,
  company: COMPANY_INITIAL_FORM_VALUES,
};

interface IQuery {
  inviteKey: string;
  partner: string;
  step: string | number;
  lang: ILanguage;
}

const TenantSignUp = () => {
  const { query, changeQuery } = useQuery<IQuery>();
  const { t } = useTranslation();
  const [state, handleStateChange] = useState<any>(INIT_STATE);

  React.useEffect(() => {
    const q = {
      ...query,
      step: 1,
    };

    if (!query.lang) {
      q.lang = getCurrentLang();
    }

    changeQuery(q);
    initTagManager();
  }, []);

  const { execute: signUpPartner, isPending: isSubmitting } = useAsync(
    (values) => {
      return tenantsService.registerSignUp(
        query.inviteKey,
        reformatCustomerPayloadForApi(values, query)
      );
    }
  );

  const { execute: resendLink, isPending: isResending } = useAsync(
    (email: string) => {
      handleStateChange(false, 'isLinkResent');
      return authService.resendVerificationLink(email).then((res) => {
        handleStateChange(true, 'isLinkResent');
        return res;
      });
    }
  );

  const isSmUp = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  React.useEffect(() => {
    changeLang(query.lang);
  }, [query.lang]);

  const showStepper = !query.step || query.step !== 'completed';

  const activeStep = +query.step || 0;

  const isFormSubmitted = query.step === 'completed';

  return (
    <AuthRoot data-test-id={ALL_TEST_IDS.tenantSignUp.wrapper}>
      <AuthMainColWrapper xs={12} md={5}>
        <Row
          alignItems="center"
          justifyContent="space-between"
          direction="column"
          className="full-width full-height"
        >
          {isFormSubmitted ? (
            <Col className="flex justify-center full-height flex-column">
              <AuthLeftContent>
                <h1 className="mb-15">
                  {t('auth.registration.tenant.success.title')}
                </h1>
                <p className="mb-15 lh-14">
                  {t('auth.registration.tenant.success.content')}
                </p>
                <Alert severity="info" className="mb-25">
                  {t('auth.registration.tenant.success.content.spamNote')}
                </Alert>
                <div
                  className={cn('primary pointer bolder fs-17 mb-20', {
                    disabled: isResending,
                  })}
                  onClick={() => resendLink(state.user.email)}
                >
                  {t('auth.registration.resendLink')}
                </div>
                {state.isLinkResent && (
                  <Alert severity="success">
                    {t('auth.registration.resendLink.success')}
                  </Alert>
                )}
              </AuthLeftContent>
            </Col>
          ) : (
            <>
              <Col className="full-width">
                <AuthMobileLogo className={cn('mb-35')}>
                  <img src={MobileLogo} alt="" />
                </AuthMobileLogo>
              </Col>
              <Col className="full-width">
                <img
                  height="1"
                  width="1"
                  style={{ display: 'none' }}
                  alt=""
                  src="https://px.ads.linkedin.com/collect/?pid=2736097&conversionId=5075777&fmt=gif"
                />
                <AuthLeftContent className={cn('full-width')}>
                  {+query.step === 1 && (
                    <>
                      <h2 className="mb-20">
                        {t('auth.registration.tenant.title')}
                      </h2>
                      <UserInfoForm
                        showStepper={showStepper}
                        activeStep={activeStep}
                        initialValues={state.user}
                        onSubmit={(user) => {
                          handleStateChange({ user });
                          changeQuery({ step: 2 });
                        }}
                      />
                    </>
                  )}
                  {+query.step === 2 && (
                    <>
                      <h2 className="mb-20">
                        {t('auth.registration.title.continue')}
                      </h2>

                      <CustomerCompanyInfoForm
                        showStepper={showStepper}
                        activeStep={activeStep}
                        isSubmitting={isSubmitting}
                        initialValues={state.company}
                        innerContent={
                          <div className="uppercase fs-14 bolder mb-20">
                            {t('auth.registration.tenant.companyInfo')}
                          </div>
                        }
                        onSubmit={(val) => {
                          return signUpPartner({
                            ...state.user,
                            ...val,
                          })
                            .then(() => changeQuery({ step: 'completed' }))
                            .catch((err) =>
                              showSystemMessage(err.message, 'error')
                            );
                        }}
                      />
                    </>
                  )}
                </AuthLeftContent>
              </Col>
              <AuthFooterLink />
            </>
          )}
        </Row>
      </AuthMainColWrapper>
      {isSmUp && <AuthMainColRight md={7} />}
    </AuthRoot>
  );
};

export default TenantSignUp;
