import { virtualMachinesService } from 'services';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export const osTemplates = new BaseMobxInstanceDecorator<
  IVmTypes.IVmTemplate,
  true
>({
  instanceName: 'os-temapltes',
  requests: {
    get: (name: string) =>
      virtualMachinesService.getTemplates({
        q: name,
        limit: 1000,
        offset: 0,
        orderBy: 'name',
        orderType: 'asc',
      }),
  },
  initialState: [],
});

export const tenantosTemplates = new BaseMobxInstanceDecorator<
  IVmTypes.IVmTemplateOs,
  true
>({
  instanceName: 'os-tenantos-templates',
  requests: {
    get: (query) =>
      virtualMachinesService.getTenantosTemplates({
        q: query.q,
        limit: 1000,
        offset: 0,
        orderBy: 'name',
        orderType: 'asc',
        tenantId: query.tenantId,
      }),
  },
  initialState: [],
});

export const osList = new BaseMobxInstanceDecorator<IVmTypes.IVmOs, true>({
  instanceName: 'os-list',
  requests: {
    get: (query) =>
      virtualMachinesService.getOsList({
        q: query.q,
        perPage: 1000,
        page: 1,
        orderBy: 'name',
        orderType: 'asc',
        tenantId: query.tenantId,
      }),
  },
  initialState: [],
});

export type IOsTemplatesHandler = BaseDecorator<IVmTypes.IVmTemplate, true>;
export type IOsTenantosTemplatesHandler = BaseDecorator<
  IVmTypes.IVmTemplateOs,
  true
>;
export type IOsListHandler = BaseDecorator<IVmTypes.IVmOs, true>;
