import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Alert,
  Button,
  Dialog,
  DialogProps,
  Input,
  Row,
  Select,
  Switch,
  TabPanel,
  VmTab,
  VmTabs,
} from 'elements';
import { observer } from 'mobx-react-lite';
import * as ENUMS from '../constants';
import * as HELPERS from '../helpers';
import DestinationForm from './DestinationForm';
import PortsForm from './PortsForm';
import FirewallIpAddresses from 'pages/vm-details/FirewallIpAddresses';
import GeoIp from 'pages/vm-details/GeoIp';
import { PORT_TYPES_OPTIONS_SHORT } from '../../services/constants';

type Props = DialogProps<any> & {
  initialValues?: IConnectivityTypes.IFirewallRuleFormValues;
};

const INITIAL: IConnectivityTypes.IFirewallRuleFormValues = {
  id: undefined,
  name: '',
  ports: [],
  sourcePorts: '',
  destinationPorts: '',
  protocol: PORT_TYPES_OPTIONS_SHORT[0],
  anySource: true,
  tab: 0,
  source: '',
  sources: [],
  state: true,
  geoip: undefined,
  countryCodes: [],
  destination: true,
  destinationText: '',
  destinations: [],
  isICMPEnabled: false,
  action: ENUMS.FIREWALL_ACTION_OPTIONS[0],
};

const FirewallRuleDetailsDialog = observer((props: Props) => {
  const { t } = useTranslation();
  const { open, onClose, initialValues, onSave } = props;
  const isNew = initialValues ? !Object.keys(initialValues).length : true;
  const form = useFormik({
    initialValues: initialValues || INITIAL,
    validate: HELPERS.validateFWRuleValues,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (val, conf) => {
      if (onSave) {
        await onSave(val, { isNew });
      }
      onClose();
      conf.resetForm();
      return val;
    },
  });

  const {
    values,
    setFieldValue,
    isSubmitting,
    handleChange,
    setValues,
    resetForm,
  } = form;

  const handleTabChange = React.useCallback((ev: any, ind: number) => {
    setFieldValue('tab', ind);
  }, []);

  React.useEffect(() => {
    if (open && !isNew) {
      setValues(initialValues || INITIAL);
    } else {
      resetForm(INITIAL as any);
    }
  }, [open, isNew]);

  return (
    <Dialog
      title={t(
        `services.connectivity.firewallRules.dialog.details.${
          isNew ? 'add' : 'edit'
        }.title`
      )}
      maxWidth="sm"
      fullWidth
      open={open}
      handleSubmit={form.handleSubmit}
      onClose={onClose}
      actions={
        <>
          <Button color="default" variant="outlined" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className="mb-15">
        {t('services.connectivity.firewallRules.dialog.details.description')}
      </div>

      <Input
        name="name"
        label="forms.name"
        placeholder="forms.placeholders.name"
        value={values.name}
        onChange={handleChange}
        error={!!form.errors.name}
        helperText={!!form.submitCount && form.errors.name}
        className="mb-25"
      />

      <Row className="mb-25" justifyContent="flex-start">
        <div style={ENUMS.FWR_STYLES.stateSwitcher}>
          <Switch
            label={t(
              'services.connectivity.firewallRules.dialog.details.switchers.state'
            )}
            checked={values.state}
            onCheck={(state) => setFieldValue('state', state)}
            labelPlacement="start"
          />
        </div>
      </Row>

      <div className="mb-25">
        <div className="mb-10">
          {t(
            'services.connectivity.firewallRules.dialog.details.sections.source'
          )}
        </div>

        <div className="mb-15">
          <Switch
            label={t(
              'services.connectivity.firewallRules.dialog.details.switchers.anySource'
            )}
            checked={values.anySource}
            onCheck={(anySource) => setFieldValue('anySource', anySource)}
          />
        </div>
        {!values.anySource && (
          <>
            <VmTabs onChange={handleTabChange} value={values.tab}>
              <VmTab
                index={0}
                label={t('services.vm.services.dialog.tab.firewall')}
              />
              <VmTab
                index={1}
                label={t('services.vm.services.dialog.tab.geoip')}
              />
            </VmTabs>
            <TabPanel
              value={0}
              index={values.tab}
              className="pt-25 pb-25"
              style={ENUMS.FWR_STYLES.tab}
            >
              {/*@ts-ignore*/}
              <FirewallIpAddresses {...form} />
            </TabPanel>
            <TabPanel
              value={1}
              index={values.tab}
              className="pt-25 pb-25"
              style={ENUMS.FWR_STYLES.tab}
            >
              {/*@ts-ignore*/}
              <GeoIp {...form} />
            </TabPanel>
          </>
        )}

        {!!form.submitCount && !!form.errors.anySource && (
          <Alert severity="error">{t(form.errors.anySource)}</Alert>
        )}
      </div>

      <div className="mb-25">
        <div className="mb-10">
          {t(
            'services.connectivity.firewallRules.dialog.details.sections.destination'
          )}
        </div>

        <div className="mb-15">
          <Switch
            label={t(
              'services.connectivity.firewallRules.dialog.details.switchers.anySource'
            )}
            checked={values.destination}
            onCheck={(anySource) => setFieldValue('destination', anySource)}
          />
        </div>

        <div style={ENUMS.FWR_STYLES.tab}>
          {!values.destination && <DestinationForm {...form} />}
        </div>
      </div>

      <div className="mb-10">
        {t(
          'services.connectivity.firewallRules.dialog.details.sections.application'
        )}
      </div>

      <div className="mb-15">
        <Switch
          label={t(
            'services.connectivity.firewallRules.dialog.details.switchers.icmpEnabled'
          )}
          checked={values.isICMPEnabled}
          onCheck={(isICMPEnabled) =>
            setFieldValue('isICMPEnabled', isICMPEnabled)
          }
        />
      </div>

      {!values.isICMPEnabled && <PortsForm {...form} />}

      <div className="mb-15">
        {t(
          'services.connectivity.firewallRules.dialog.details.sections.action'
        )}
      </div>

      <div style={ENUMS.FWR_STYLES.tab}>
        <Select
          name="action"
          value={values.action}
          onChange={(val) => setFieldValue('action', val)}
          options={ENUMS.FIREWALL_ACTION_OPTIONS}
        />
      </div>
    </Dialog>
  );
});

export default FirewallRuleDetailsDialog;
