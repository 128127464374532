import * as React from 'react';
import { Col, Row, Input, Switch, Alert } from 'elements';
import { numberToCurrency, validateCallback } from 'utils';
import { ISoftwarePackage, IInstallPackageDependencyValues } from 'app-store';
import { useTranslation } from 'react-i18next';

type IProps = ISoftwarePackage & {
  values: any;
  errors: any;
  setFieldValue: AnyFunc;
  handleChange: AnyFunc;
  dependencyValues: IInstallPackageDependencyValues;
  cost?: number;
};

const ProviderLicenseInput = (props: IProps) => {
  const { values, handleChange, errors, setFieldValue, cost } = props;
  const { t } = useTranslation();
  const providersLicense = props.licenseSettings?.providersLicense;

  if (providersLicense?.costBasis === 'FIXED') {
    return null;
  }

  return (
    <div>
      <div className="mb-25">
        {t('softwarePackages.dialog.install.providerLicenseKey.explanation')}:
        <span className="primary pl-5">
          {numberToCurrency(cost || 0, true)}
        </span>
      </div>
      <div className="mb-25">
        <Switch
          label={t(
            `softwarePackages.dialog.install.providerLicenseKey.switchers.customQty`
          )}
          checked={values.activeRdsUsersBilling}
          onCheck={(activeRdsUsersBilling) =>
            setFieldValue('activeRdsUsersBilling', activeRdsUsersBilling)
          }
        />
      </div>
      {values.activeRdsUsersBilling && (
        <div>
          <Row columnSpacing={3} alignItems="flex-end" className="mb-25">
            <Col>
              <div className="fs-12 mb-5">
                {t('forms.providersLicenseUnits')}
              </div>
              <Input
                type="number"
                name="providersLicenseUnits"
                value={values.providersLicenseUnits}
                placeholder="forms.placeholders.providersLicenseUnits"
                onChange={validateCallback({
                  min: 0,
                })(handleChange)}
                error={!!errors.providersLicenseUnits}
                helperText={errors.providersLicenseUnits}
              />
            </Col>
          </Row>
          <Alert contentClassName="fs-12" severity="info">
            {t(
              'softwarePackages.dialog.install.providerLicenseKey.alerts.info'
            )}
          </Alert>
        </div>
      )}
    </div>
  );
};

export default ProviderLicenseInput;
