import { BaseMobxInstanceDecorator } from './utilities';
import { partnersService } from 'services';

export const partners = new BaseMobxInstanceDecorator<
  IPartnerTypes.Partner,
  true
>({
  instanceName: 'partners',
  requests: {
    get: partnersService.getPartners,
    create: partnersService.create,
    update: (id, payload) => partnersService.update(id as number, payload),
    remove: (id) => partnersService.deletePartner(id as number),
  },
  initialState: [],
});

export const currentPartner = new BaseMobxInstanceDecorator<
  IPartnerTypes.Partner,
  false
>({
  requests: {
    get: partnersService.getPartner,
    update: (id, payload) =>
      partnersService.updateCurrentPartner(id as number, payload),
  },
  additionalRequests: {
    updateInvoiceSettings: partnersService.updateInvoiceSettings,
  },
  initialState: {} as IPartnerTypes.Partner,
});
