import * as React from 'react';
import cn from 'classnames';
import { MuiIcons, ColorDot, styled } from 'elements';
import { PALETTE } from 'enums';
import { remCalc } from 'utils';

const SChip = styled('div')(({ theme }) => ({
  border: `1px solid ${PALETTE.lightGrey}`,
  fontSize: remCalc(9),
  borderRadius: remCalc(5),
  margin: remCalc(2, 5, 2, 0),
  padding: remCalc(4, 6, 4, 10),
  lineHeight: 1,
}));

const SSquare = styled('div')(({ theme }) => ({
  width: remCalc(7),
  height: remCalc(7),
  backgroundColor: theme.palette.primary.main,
  marginRight: remCalc(5),
}));

const SCancelIcon = styled(MuiIcons.Clear)(({ theme }) => ({
  '&:hover': {
    color: PALETTE.error,
  },
}));

type IProps = React.PropsWithChildren<{
  dotColor?: string;
  onClear: () => void;
}>;

const FilterChip = (props: IProps) => {
  const { dotColor, onClear, children } = props;
  return (
    <SChip className={cn('flex align-center')}>
      <SSquare />
      {dotColor && <ColorDot size={9} color={dotColor} className="mr-5" />}
      <div className="fs-11">{children}</div>
      <SCancelIcon className={cn('ml-5 fs-10 pointer')} onClick={onClear} />
    </SChip>
  );
};

export default FilterChip;
