import React from 'react';
import { IRds } from 'rds';
import { useTranslation } from 'react-i18next';
import { useRdsActiveServices } from 'hooks';
import { CopyTooltip, ActiveServiceLayout } from 'elements';

type Props = IRds;

const RemoteDesktopCard = (props: Props) => {
  const { t } = useTranslation();
  const activeServices = useRdsActiveServices(props);
  const hasActiveServices = activeServices.length > 0;
  return (
    <>
      <div className="primary-dark">
        {t('services.card.content.accessGateway')}
      </div>
      <div className="steel mb-15">
        <CopyTooltip>{props.externalFQDN}</CopyTooltip>
      </div>

      <div className="primary-dark">
        {t('services.card.content.lightUsers')}
      </div>
      <div className="steel mb-5">{props.lightUsersCount || 0}</div>

      <div className="primary-dark">
        {t('services.card.content.officeUsers')}
      </div>
      <div className="steel mb-5">{props.officeUsersCount || 0}</div>
      <div className="primary-dark">
        {t('services.card.content.powerUsers')}
      </div>
      <div className="steel mb-5">{props.powerUsersCount || 0}</div>
      <div className="primary-dark bolder mb-5">
        {t('services.card.content.activeServices')}
      </div>
      {hasActiveServices ? (
        <ActiveServiceLayout services={activeServices} instance="rds" />
      ) : (
        <span className="steel">
          {t('services.card.content.activeServices.noServices')}
        </span>
      )}
    </>
  );
};

export default RemoteDesktopCard;
