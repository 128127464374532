import {
  IMonitoringService,
  IChartInstance,
  IOsServiceItem,
  IMonitoringCreateFullPayload,
  IMonitoringUpdatePayload,
  IRequestParamsGet,
  IChartMetric,
  IServiceName,
  IOsServiceItemGrouped,
} from 'monitoring';
import { POST, GET, DELETE, PUT, PATCH, buildListParams, compact } from 'utils';
import { IAxiosPromise } from 'axios-shapes';
import { AxiosPromise, AxiosResponse } from 'axios';

type IDateParams = {
  dateFrom: string;
  dateTo: string;
};

export const get = (
  params: IRequestParamsGet
): IAxiosPromise<IMonitoringService[]> =>
  GET(
    '/service-monitoring',
    buildListParams(params, ['orderBy', 'orderType', 'include'])
  );

export const getById = (id: number): AxiosPromise<IMonitoringService> =>
  GET(`/service-monitoring/${id}`);

export const create = (
  payload: IMonitoringCreateFullPayload
): AxiosPromise<IMonitoringService> => POST('/service-monitoring', {}, payload);

export const update = (
  id: number,
  payload: IMonitoringUpdatePayload
): AxiosPromise<IMonitoringService> =>
  PUT(`/service-monitoring/${id}`, {}, payload);

export const syncWithZabbix = (id: number): AxiosPromise<IMonitoringService> =>
  PUT(`/service-monitoring/${id}/refresh`);

export const remove = (id: number): AxiosPromise<Task> =>
  DELETE(`/service-monitoring/${id}`);

export const getServiceItems = (
  monitoringId: number,
  instanceId: number
): IAxiosPromise<IChartInstance[]> =>
  GET(`/service-monitoring/${monitoringId}/instances/${instanceId}/items`);

export const getChartHistory = (
  monitoringId: number,
  instanceId: number,
  itemId: number,
  params: IDateParams
): IAxiosPromise<IChartMetric[]> =>
  GET(
    `/service-monitoring/${monitoringId}/instances/${instanceId}/items/${itemId}/history`,
    params
  ).then((res: AxiosResponse<IChartMetric[]>) => {
    res.data = res.data.sort((a, b) => (a.t > b.t ? 1 : -1));
    return res;
  });

export const getChartTrend = (
  monitoringId: number,
  instanceId: number,
  itemId: number,
  params: IDateParams
): IAxiosPromise<IChartMetric[]> =>
  GET(
    `/service-monitoring/${monitoringId}/instances/${instanceId}/items/${itemId}/trend`,
    params
  ).then((res: AxiosResponse<IChartMetric[]>) => {
    res.data = res.data.sort((a, b) => (a.t > b.t ? 1 : -1));
    return res;
  });

export const getServiceLastData = (
  monitoringId: number,
  instanceId: number,
  itemId: number
): IAxiosPromise<IChartMetric[]> =>
  GET(
    `/service-monitoring/${monitoringId}/instances/${instanceId}/items/${itemId}/last-data`
  );

export const getServiceNames = (
  monitoringId: number,
  instanceId: number
): IAxiosPromise<IServiceName[]> =>
  GET(
    `/service-monitoring/${monitoringId}/instances/${instanceId}/services-names`
  );

export const addServiceName = (
  monitoringId: number,
  instanceId: number,
  payload: IServiceName
): IAxiosPromise<IServiceName> =>
  POST(
    `/service-monitoring/${monitoringId}/instances/${instanceId}/services-names`,
    {},
    payload
  );

export const removeServiceName = (
  monitoringId: number,
  instanceId: number,
  payload: IServiceName
): IAxiosPromise<IServiceName> =>
  DELETE(
    `/service-monitoring/${monitoringId}/instances/${instanceId}/services-names`,
    {},
    payload
  );

export const getOsServices = (
  monitoringId: number,
  instanceId: number
): IAxiosPromise<IOsServiceItem[]> =>
  GET(`/service-monitoring/${monitoringId}/instances/${instanceId}/items`);

export const getServiceNamesGrouped = async (
  monitoringId: number,
  instanceId: number
): Promise<IOsServiceItemGrouped[]> => {
  const serviceNames = await getServiceNames(monitoringId, instanceId);
  const items = await getServiceItems(monitoringId, instanceId);

  const mappedItem = compact(
    serviceNames.data.map(
      (sn) =>
        items.data.find(
          (item) =>
            item.name === sn.serviceName ||
            item.discoveryUnitName === sn.serviceName
        ) || {
          name: sn.serviceName,
        }
    )
  );

  return Promise.all(
    mappedItem.map(async (service) => {
      let lastData = {};

      if (service.id) {
        const { data } = await getServiceLastData(
          monitoringId,
          instanceId,
          service.id
        ).catch(() => ({ data: { v: 7, t: 0 } }));
        lastData = data;
      }

      return {
        ...service,
        lastData,
      };
    })
  );
};

export const getMaintenance = (
  monitoringId: number
): IAxiosPromise<IMonitoring.MonitoringMW[]> =>
  GET(`/service-monitoring/${monitoringId}/maintenance-windows`);

export const createMaintenance = (
  monitoringId: number,
  payload: any
): IAxiosPromise<IMonitoring.MonitoringMW> =>
  POST(`/service-monitoring/${monitoringId}/maintenance-windows`, {}, payload);

export const updateMaintenance = (
  monitoringId: number,
  mId: number,
  payload: any
): IAxiosPromise<IMonitoring.MonitoringMW> =>
  PATCH(
    `/service-monitoring/${monitoringId}/maintenance-windows/${mId}`,
    {},
    payload
  );

export const removeMaintenance = (
  monitoringId: number,
  mId: number
): IAxiosPromise<IMonitoring.MonitoringMW> =>
  DELETE(`/service-monitoring/${monitoringId}/maintenance-windows/${mId}`);
