import * as React from 'react';
import cn from 'classnames';
import {
  Row,
  Col,
  Input,
  DateRangePicker,
  InputChip,
  TableHeadFilterItems,
} from 'elements';
import { withTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import { IBIParams } from 'billable-items';
import { RouterState, useForceUpdate } from 'hooks';
import { debounce, remCalc } from 'utils';
import { DEFAULT_BILLABLE_ITEMS_PARAMS } from 'enums';
import { usersService } from 'services';
import { SortIcon } from 'components';
import { ClearButton, TableHead } from './Styled';
import { setColumnWidth } from './helpers';
import {
  IBIAccessLevel,
  statusFilterOptions,
  recurringIntervalFullOptions,
} from './constants';

type IProps = React.PropsWithChildren<
  WithT & {
    router: RouterState<IBIParams>;
    accessLevel: IBIAccessLevel;
  }
>;

const Renderer = (props: IProps) => {
  const { t, router, accessLevel } = props;
  const { query, changeQuery, queryStr } = router;
  const forceUpdate = useForceUpdate();
  const searchRef = React.useRef({ value: query.q || '' });

  React.useEffect(() => {
    searchRef.current.value = query.q || '';
    forceUpdate();
  }, [query.q]);

  const changeSearchUrl = React.useCallback(
    (q: string) => changeQuery({ ...query, q, page: 1 }),
    [queryStr, query]
  );

  const onItemSearch = React.useCallback(
    debounce(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        changeSearchUrl(ev.target.value),
      1000
    ),
    [queryStr, query]
  );

  const isTenant = accessLevel === 'tenant';

  return (
    <>
      <Row
        columnSpacing={2}
        alignItems="center"
        rowSpacing={2}
        className="mb-25"
      >
        <Col xs={12} sm>
          <Input
            ref={searchRef}
            type="search"
            placeholder={`billableItems.filters.${accessLevel}.search`}
            inputClassName={cn('bg-white')}
            inputProps={{ style: { height: remCalc(48) } }}
            className="mt-25"
            onKeyUp={(e: any) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                changeSearchUrl(e.target.value);
              }
            }}
            onClear={() => {
              searchRef.current.value = '';
              changeSearchUrl('');
            }}
            onChange={onItemSearch}
            startAdornment={
              query.id && (
                <InputChip
                  chip={{} as any}
                  onClear={(c) => {
                    changeQuery({ id: undefined });
                  }}
                >
                  <span>EntityId:</span>
                  <span className="pl-5">{query.id}</span>
                </InputChip>
              )
            }
          />
        </Col>
        <Col>
          <Row alignItems="center" columnSpacing={2}>
            <Col xs>
              <DateRangePicker
                label={t('forms.dateRange')}
                closeOnSelect
                displayClassName="fs-14"
                value={{ startDate: query.dateFrom, endDate: query.dateTo }}
                onChange={({ startDate, endDate }) =>
                  changeQuery({
                    dateFrom: startDate.toISOString(),
                    dateTo: endDate.toISOString(),
                    q: searchRef.current.value,
                  })
                }
              />
            </Col>
            <Col>
              <ClearButton
                variant="text"
                color="primary"
                className={cn('mt-25')}
                onClick={() => changeQuery(DEFAULT_BILLABLE_ITEMS_PARAMS)}
              >
                {t('billableItems.buttons.clearFilters')}
              </ClearButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <TableHead>
        <Row
          columnSpacing={1}
          className={cn('bolder uppercase fs-14')}
          justifyContent="space-between"
          alignItems="center"
        >
          <Col xs={4}>
            <Row columnSpacing={1}>
              {!isTenant && (
                <Col xs={6}>
                  {
                    t(
                      `table.head.${
                        accessLevel === 'provider' ? 'partner' : 'tenant'
                      }Name`
                    ) as string
                  }
                </Col>
              )}

              <Col xs={6}>{t('table.head.description') as string}</Col>
            </Row>
          </Col>
          <Col>
            <Row columnSpacing={2}>
              <Col {...setColumnWidth(180)}>
                <div className="flex align-center">
                  {t('table.head.cycle') as string}
                  <TableHeadFilterItems
                    query={query}
                    queryName="recurringInterval"
                    isMulti
                    options={recurringIntervalFullOptions}
                    onChange={(recurringInterval) => {
                      changeQuery({ recurringInterval });
                    }}
                  />
                </div>
              </Col>
              <Col {...setColumnWidth(200)}>
                <div className="flex align-center">
                  <div className="pr-5">
                    {t('table.head.sentUpfront') as string}
                  </div>
                  <SortIcon
                    size={17}
                    propName="sendUpfront"
                    query={query}
                    onSort={(field: any, orderType: any) =>
                      changeQuery({ orderBy: field, orderType })
                    }
                  />
                </div>
              </Col>
              <Col {...setColumnWidth(150)}>
                {isTenant ? (
                  <div className="flex align-center">
                    {t('table.head.ticket') as string}
                  </div>
                ) : (
                  <div className="flex align-center">
                    {t('table.head.user') as string}
                    <TableHeadFilterItems
                      query={query}
                      queryName="userId"
                      isMulti
                      loadOptions={() =>
                        usersService
                          .getUsersList({ page: 1, perPage: 1000 })
                          .then((res) =>
                            res.data.map((user) => ({
                              label: `${user.firstName} ${user.lastName}`,
                              value: user.id,
                            }))
                          )
                      }
                      onChange={(users) =>
                        changeQuery({ userId: users, page: 1 })
                      }
                    />
                  </div>
                )}
              </Col>
              <Col {...setColumnWidth(100)}>
                <div className="flex align-center">
                  <div className="pr-5">{t('table.head.date') as string}</div>
                  <SortIcon
                    size={17}
                    propName="date"
                    query={query}
                    onSort={(field: any, orderType: any) =>
                      changeQuery({ orderBy: field, orderType })
                    }
                  />
                </div>
              </Col>
              <Col {...setColumnWidth(130)}>
                <div className="flex align-center justify-end">
                  <div className="pr-5">{t('table.head.total') as string}</div>
                  <SortIcon
                    size={17}
                    propName="amount"
                    query={query}
                    onSort={(field: any, orderType: any) =>
                      changeQuery({ orderBy: field, orderType })
                    }
                  />
                </div>
              </Col>
              <Col {...setColumnWidth(190)}>
                <div className="flex align-center">
                  {t('table.head.status') as string}
                  <TableHeadFilterItems
                    query={query}
                    queryName="status"
                    isMulti
                    options={statusFilterOptions}
                    onChange={(status) => changeQuery({ status, page: 1 })}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </TableHead>
    </>
  );
};

const Filters = withTranslation()(Renderer);

export default Filters;
