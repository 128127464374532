import { GET, POST, PUT, DELETE, buildListParams } from 'utils';
import { DefaultListQuery } from 'global-shapes';
import { AxiosPromise } from 'axios';
import { IEmployee, IEmployeePayload } from 'employees';
import { IAxiosPromise } from 'axios-shapes';

const CONFIG = { headers: { 'X-JBBF-TENANT-SHORTNAME': undefined } };

export const getEmployees = (
  tenantId: number,
  params: DefaultListQuery
): IAxiosPromise<IEmployee[]> =>
  GET(
    `/tenants/${tenantId}/employees`,
    buildListParams(params, ['q', 'orderType', 'orderBy']),
    CONFIG
  );

export const getEmployee = (
  tenantId: number,
  id: number
): AxiosPromise<IEmployee> =>
  GET(`/tenants/${tenantId}/employees/${id}`, {}, CONFIG);

export const createEmployee = (
  tenantId: number,
  data: IEmployeePayload
): AxiosPromise<IEmployee> => {
  const { type, ...values } = data;
  const payload = {
    name: values.name.trim(),
    email: values.email.trim() || undefined,
    phone: values.phone.trim() || undefined,
    comment: values.comment.trim() || undefined,
    type: type.value,
  };
  return POST(`/tenants/${tenantId}/employees`, {}, payload, CONFIG);
};

export const updateEmployee = (
  tenantId: number,
  id: number,
  data: IEmployeePayload
): AxiosPromise<IEmployee> => {
  const { type, ...values } = data;
  const payload = {
    name: values.name.trim() || undefined,
    email: values.email.trim() || null,
    phone: values.phone.trim() || null,
    comment: values.comment.trim() || null,
    type: type.value,
  };
  return PUT(`/tenants/${tenantId}/employees/${id}`, {}, payload, CONFIG);
};

export const deleteEmployee = (tenantId: number, id: number): AxiosPromise =>
  DELETE(`/tenants/${tenantId}/employees/${id}`, undefined, undefined, CONFIG);
