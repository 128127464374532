import { GET, POST, DELETE, buildListParams, PUT } from 'utils';
import { AxiosPromise } from 'axios';
import {
  IDNSZoneRecordResponse,
  IDNSZoneRecordPayload,
  IZonePayload,
  IDNSRecord,
  IDNSZone,
  IDnsService,
} from 'dns';
import { ICommonListParams } from 'global-shapes';
import { IAxiosPromise } from 'axios-shapes';

const parseRecordPayload = (payload: IDNSZoneRecordPayload) => ({
  ...payload,
  ttl: payload.ttl.value,
  type: payload.type.value,
});

export const get = (): AxiosPromise<IDnsService> => GET('/dns');
export const remove = (): AxiosPromise<Task> => DELETE('/dns');

export const getZones = (
  params: ICommonListParams
): IAxiosPromise<IDNSZone[]> =>
  GET('/dns/zones', buildListParams(params, ['orderBy', 'orderType']));

export const getZoneById = (id: number): AxiosPromise<IDNSZone> =>
  GET(`/dns/zones/${id}`);

export const create = (data: IZonePayload): AxiosPromise<IDNSZone> =>
  POST('/dns/zones', {}, data);

export const createBulk = (data: IZonePayload[]): IAxiosPromise<IDNSZone[]> =>
  POST(
    '/dns/zones/bulk',
    {},
    { zones: data.map(({ domain }) => ({ domain })) }
  );

export const removeZone = (id: number): AxiosPromise<Task> =>
  DELETE(`/dns/zones/${id}`);

export const getRecords = (
  zoneId: number,
  query: ICommonListParams
): IAxiosPromise<IDNSRecord[]> =>
  GET(
    `/dns/zones/${zoneId}/records`,
    buildListParams(query, ['orderBy', 'orderType'])
  );

export const createRecord = (
  zoneId: number,
  payload: IDNSZoneRecordPayload
): AxiosPromise<IDNSZoneRecordResponse> =>
  POST(`/dns/zones/${zoneId}/records`, {}, parseRecordPayload(payload));

export const updateRecord = (
  zoneId: number,
  recordId: number,
  payload: IDNSZoneRecordPayload
): AxiosPromise<IDNSZoneRecordResponse> =>
  PUT(
    `/dns/zones/${zoneId}/records/${recordId}`,
    {},
    parseRecordPayload(payload)
  );

export const deleteRecord = (
  zoneId: number,
  recordId: number
): AxiosPromise<Task> => DELETE(`/dns/zones/${zoneId}/records/${recordId}`);

export const getReverseRecords = (
  params: ICommonListParams
): IAxiosPromise<IReverseDnsRecord[]> =>
  GET(
    '/reverse-dns-records',
    buildListParams(params, ['orderBy', 'orderType', 'q', 'appEntityIds'])
  );

export const createReverseRecord = (
  data: IReverseDnsRecordsTypes.ICreatePayload
): AxiosPromise<IReverseDnsRecord> => POST('/reverse-dns-records', {}, data);

export const updateReverseRecord = (
  id: number,
  data: IReverseDnsRecordsTypes.IUpdatePayload
): AxiosPromise<IReverseDnsRecord> =>
  PUT(`/reverse-dns-records/${id}`, {}, data);

export const removeReverseRecord = (id: number): AxiosPromise<Task> =>
  DELETE(`/reverse-dns-records/${id}`);
