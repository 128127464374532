import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Dialog,
  DialogProps,
  Button,
  Row,
  Col,
  Alert,
  Select,
  PrimaryTextSpan,
} from 'elements';
import { usePrice, useStateHandler, usePermissions } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { numberToCurrency, remCalc, showSystemMessage } from 'utils';
import * as R from 'ramda';
import { CostResourceIds } from 'enums';
import * as ENUMS from './constants';

type Props = DialogProps<any>;
type IValues = {
  qosProfileName: ValueType<number>;
};

const INITIAL_STATE: IValues = {
  qosProfileName: ENUMS.NetworkSpeedOptions[0],
};

const LimitRateDialog = observer((props: Props) => {
  const connectivity = useStateHandler(StateHandlers.connectivity);
  const { t } = useTranslation();
  const { isEvaluation } = usePermissions();
  const { onSave, open, onClose } = props;
  const prices = usePrice([
    CostResourceIds.connectivityBase,
    CostResourceIds.connectivityRateLimit200,
    CostResourceIds.connectivityRateLimit500,
    CostResourceIds.connectivityRateLimit1000,
  ]);
  const baseFee = prices[CostResourceIds.connectivityBase].monthly;

  const setConRateLimit = React.useCallback(
    connectivity.executeRequest('setConRateLimit'),
    []
  );

  const {
    handleSubmit,
    values,
    setFieldValue,
    isSubmitting,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: INITIAL_STATE,
    onSubmit: async (val) => {
      try {
        await setConRateLimit(connectivity.data?.id, {
          qosProfileName: val.qosProfileName.label,
        }).then((res) => {
          showSystemMessage(
            'services.connectivity.notify.rateLimit.update.success',
            'success'
          );
          onSave && onSave(val);
          return res;
        });
        return connectivity.get(undefined);
      } catch (er: any) {
        showSystemMessage(er.message, 'error');
      }
    },
  });

  React.useEffect(() => {
    if (!open) {
      resetForm();
    } else {
      setValues({
        qosProfileName:
          R.find<ValueType<number>>(
            R.propEq('value', connectivity.data.qosProfileName)
          )(ENUMS.NetworkSpeedOptions) || ENUMS.NetworkSpeedOptions[0],
      });
    }
  }, [open]);

  return (
    <Dialog
      title={t('services.connectivity.dialog.rateLimit.title')}
      handleSubmit={handleSubmit}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      actions={
        <Row alignItems="center" justifyContent="space-between">
          <Col>
            <Button color="default" variant="outlined" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button disabled={isSubmitting} type="submit">
              {t('common.save')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Row columnSpacing={2}>
        <Col xs={7}>
          <div className="steel mb-25">
            {t('services.connectivity.dialog.rateLimit.content')}
          </div>
          <div style={{ maxWidth: remCalc(230) }}>
            <Select
              name="qosProfileName"
              label={t(
                'services.connectivity.dialog.rateLimit.input.networkSpeed'
              )}
              value={values.qosProfileName}
              onChange={(val) => setFieldValue('qosProfileName', val)}
              options={ENUMS.NetworkSpeedOptions}
            />
          </div>
        </Col>
        <Col xs={5}>
          <Row
            direction="column"
            justifyContent="space-between"
            columnSpacing={2}
            style={{
              height: '100%',
              minHeight: remCalc(470),
              paddingLeft: remCalc(10),
            }}
          >
            <Col className="full-width">
              <h5 className="mb-25 primary">{t('costsInfo.title')}</h5>
              <Row
                justifyContent="space-between"
                columnSpacing={2}
                className="fs-10 steel uppercase mb-15"
              >
                <Col xs={7}>
                  <span>{t('costsInfo.head.serviceName')}</span>
                </Col>
                <Col xs={2} className="text-right">
                  <span>{t('costsInfo.head.quantity')}</span>
                </Col>
                <Col xs={3} className="text-right">
                  <span>{t('costsInfo.head.price')}</span>
                </Col>
              </Row>
              <h5 className="mb-15">{t('costsInfo.connectivity')}</h5>
              <div className="custom-list fs-16">
                <ul>
                  <li className="mb-10">
                    <Row>
                      <Col xs={7}>
                        <span>{t('costsInfo.baseFee')}</span>
                      </Col>
                      <Col xs={2} />
                      <Col xs={3} className="text-right steel">
                        <span>{numberToCurrency(baseFee, false)}</span>
                      </Col>
                    </Row>
                  </li>

                  {ENUMS.NetworkSpeedWithCost.map((el) => {
                    const isSelected =
                      values.qosProfileName?.value === el.value;

                    return (
                      <li className="mb-10" key={el.value}>
                        <Row>
                          <Col xs={7}>
                            <span>
                              {t('costsInfo.rateLimit')} {el.value}/{el.value}
                            </span>
                          </Col>
                          <Col xs={2} className="text-right steel">
                            <span>{isSelected ? 1 : 0}</span>
                          </Col>
                          <Col xs={3} className="text-right steel">
                            <span>
                              {numberToCurrency(
                                prices[el.resourceId]?.monthly,
                                false
                              )}
                            </span>
                          </Col>
                        </Row>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
            <Col className="full-width">
              <div className="flex justify-between align-center mb-5">
                <h5 className="fs-17">{t('costsInfo.totalMonthly')}</h5>
                <PrimaryTextSpan className="fs-20 bold">
                  {numberToCurrency(
                    R.sum([
                      baseFee,
                      prices[values.qosProfileName.resourceId]?.monthly || 0,
                    ]),
                    false
                  )}
                </PrimaryTextSpan>
              </div>
              <div className="fs-12 steel mb-10">
                {t('costsInfo.chfExclVat')}
              </div>
              {isEvaluation && (
                <Alert className="fs-12" severity="info">
                  {t('costsInfo.evalAccount.notify')}
                </Alert>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
});

export default LimitRateDialog;
