import React, { useMemo, useCallback, useRef } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTranslation } from 'react-i18next';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DotsProgress, Row, Col, Button } from 'elements';
import { AnyShape, ILanguage } from 'global-shapes';
import { findIndex } from 'ramda';
import { useAsync } from 'hooks';
import { taskManagerService } from 'services';
import { confirm } from 'utils';

dayjs.extend(duration);

type IProps = Task & {
  isProvider: boolean;
  lang: ILanguage;
  options?: ITaskOption;
  updateList: () => any;
};

type IFailedButtonProps = {
  id: number;
  isProvider?: boolean;
  isRetryable?: boolean;
  isCancelable?: boolean;
  inProgress?: boolean;
  isFailed?: boolean;
  updateList: () => any;
};

const statusMap: Record<Task['status'], string> = {
  failed: 'failed',
  canceled: 'canceled',
  completed: 'completed',
  waiting: 'notStarted',
  delayed: 'notStarted',
  active: 'inProgress',
  none: 'none',
};

const classNameMap = {
  failed: 'error',
  completed: 'success',
  canceled: 'light-grey',
  waiting: 'error',
  delayed: 'primary',
  active: 'primary',
  none: 'none',
};

const langKeysMap: AnyShape = {
  en: 'Minutes',
  de: 'Minuten',
};

dayjs.extend(relativeTime);

const approximateTaskDuration: AnyShape = {
  RdsCreate: 35,
  CoreInfraCreate: 11,
};

const taskDurationInterval: AnyShape = {
  RdsCreate: 21010,
  CoreInfraCreate: 6550,
};

const percentageSteps: AnyShape = {
  RdsCreate: [0, 2, 23, 54, 59, 62, 65, 66, 67, 100],
  CoreInfraCreate: [0, 17, 37, 38, 45, 80, 82, 85, 89, 93, 100],
};

const FailedStateButton = (props: IFailedButtonProps) => {
  const { t } = useTranslation();
  const { execute: retryTask, isPending: retryPending } = useAsync(
    taskManagerService.retry
  );
  const { execute: cancelTask, isPending: cancelPending } = useAsync(
    taskManagerService.cancel
  );

  const confirmRetry = React.useCallback((id: number) => {
    confirm({
      title: t('notifications.confirm.retry.title'),
      content: t('notifications.confirm.retry.content'),
      onSuccess: () =>
        retryTask(id).then((res) => {
          props.updateList();
          return res;
        }),
      onCancel: () => undefined,
    });
  }, []);

  const confirmCancel = React.useCallback((id: number) => {
    confirm({
      title: t('notifications.confirm.cancel.title'),
      content: t('notifications.confirm.cancel.content'),
      onSuccess: () =>
        cancelTask(id).then((res) => {
          props.updateList();
          return res;
        }),
      onCancel: () => undefined,
    });
  }, []);

  const isPending = retryPending || cancelPending;
  if (!props.isProvider) return null;

  if (props.isFailed && props.isRetryable) {
    return (
      <div
        onClick={() => confirmRetry(props.id)}
        className={cn('flex justify-end', { disabled: isPending })}
      >
        <Button
          size="small"
          color="default"
          variant="outlined"
          className="pt-0 pb-0 lh-14"
        >
          {t('notifications.actions.retry')}
        </Button>
      </div>
    );
  }

  if (props.inProgress && props.isCancelable) {
    return (
      <div
        onClick={() => confirmCancel(props.id)}
        className={cn('flex justify-end', { disabled: isPending })}
      >
        <Button
          size="small"
          color="default"
          variant="outlined"
          className="pt-0 pb-0 lh-14"
        >
          {t('notifications.actions.cancel')}
        </Button>
      </div>
    );
  }

  return null;
};

const TaskInfoItem = (props: IProps) => {
  const {
    status,
    message,
    createdAt,
    updatedAt,
    progress,
    lang,
    operationName,
    options,
    updateList,
    isProvider,
  } = props;
  const { t } = useTranslation();

  const initialCounter = +(
    sessionStorage.getItem(operationName) ||
    progress ||
    0
  );

  const [progressCounter, handleCounter] = React.useState(initialCounter);
  const counterRef = useRef(initialCounter);

  React.useEffect(() => {
    counterRef.current = progressCounter;
  });

  const isCompleted = status === 'completed';
  const isFailed = status === 'failed';
  const inProgress = ['active', 'delayed'].includes(status);
  const showProgress =
    inProgress && Object.keys(percentageSteps).includes(operationName);

  let progressTimer: any = null;

  const handleProgress = useCallback(() => {
    const startStepIndex = findIndex((val) => val === progress)(
      percentageSteps[operationName]
    );

    const nextStepValue = percentageSteps[operationName][startStepIndex + 1];
    const nextPercentage = counterRef.current + 1;

    if (nextPercentage === 1 || nextPercentage <= nextStepValue) {
      sessionStorage.setItem(operationName, String(nextPercentage));
      handleCounter(nextPercentage);
    }
  }, [operationName, progress, counterRef.current]);

  const initInterval = useCallback(() => {
    progressTimer = setInterval(
      handleProgress,
      taskDurationInterval[operationName]
    );
  }, [operationName]);

  React.useEffect(() => {
    const _progress = progress || 0;
    if (_progress >= progressCounter) {
      handleCounter(+_progress);
    }
  }, [progress, progressCounter]);

  React.useEffect(() => {
    if (showProgress) {
      clearInterval(progressTimer);
      initInterval();
    } else {
      sessionStorage.removeItem(operationName);
      clearInterval(progressTimer);
    }
  }, [showProgress]);

  React.useEffect(() => () => clearInterval(progressTimer), []);

  const minutesLeft = useMemo(() => {
    if (showProgress) {
      const timeToComplete = +dayjs(createdAt).add(
        approximateTaskDuration[operationName],
        'minutes'
      );
      const _cat = +dayjs(createdAt);
      const percentTime = (timeToComplete - _cat) / 100;
      const letTimeStr = dayjs
        .duration(
          dayjs(timeToComplete).diff(
            _cat + progressCounter * percentTime,
            'milliseconds',
            true
          )
        )
        .format(`m`);
      return letTimeStr;
    }
    return null;
  }, [progressCounter, showProgress, operationName, createdAt]);

  const duration = dayjs.duration(
    dayjs(updatedAt).diff(createdAt, 'milliseconds', false)
  );

  const minutes = +duration.asMinutes().toFixed(0);
  const strictMinutes = minutes >= 10 ? minutes : `0${minutes}`;
  const seconds = duration.format(`ss`);
  const fullTimeOfTask = `${strictMinutes}:${seconds} ${langKeysMap[lang]}`;

  return (
    <div className="mb-25">
      <Row>
        <Col xs>
          <div className="lh-20"> {message}</div>
          <div className={cn('fs-14', classNameMap[status])}>
            {inProgress && <DotsProgress className="mr-10" size={20} />}
            {showProgress
              ? t('notifications.status.progress.value', {
                  percentage: progressCounter,
                  minutes: minutesLeft,
                })
              : t(`notifications.status.${statusMap[status]}`)}
            {isCompleted && ` (${fullTimeOfTask})`}
          </div>
        </Col>
        <Col className="fs-14">
          <div className="lh-20 pl-10 text-right mb-5 steel">
            {dayjs(createdAt).format('DD.MM.YYYY HH:mm:ss')}
          </div>
          <div className="text-right">
            <FailedStateButton
              id={props.id}
              isFailed={isFailed}
              inProgress={inProgress}
              isCancelable={options?.isCancelable}
              isRetryable={options?.isRetryable}
              isProvider={isProvider}
              updateList={updateList}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TaskInfoItem;
