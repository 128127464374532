import React from 'react';
import {
  Dialog,
  DialogProps,
  Button,
  Row,
  Col,
  Alert,
  PrimaryTextSpan,
  PrimaryTextH5,
  CustomListUl,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { numberToCurrency, remCalc } from 'utils';
import { ICoreInfrastructure } from 'core-infrastructure';
import { CostResourceIds } from 'enums';
import { usePermissions, usePrice } from 'hooks';
import { RdsDialogLeftContent } from 'pages/dns/Styled';

type Props = DialogProps<any> & {
  coreData: ICoreInfrastructure;
  isRequesting: boolean;
};

const DeployRds = (props: Props) => {
  const { coreData, open, onSave, isRequesting } = props;
  const { t } = useTranslation();
  const { isEvaluation } = usePermissions();
  const prices = usePrice([
    CostResourceIds.rdsBase,
    CostResourceIds.rdsStandardUser,
    CostResourceIds.rdsPowerUser,
    CostResourceIds.rdsLightUser,
  ]);

  const baseFee = prices[CostResourceIds.rdsBase]?.monthly;
  const standardUserFee = prices[CostResourceIds.rdsStandardUser]?.monthly;
  const lightUserFee = prices[CostResourceIds.rdsLightUser]?.monthly;
  const powerUserFee = prices[CostResourceIds.rdsPowerUser]?.monthly;

  const keyUpHandler = React.useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        onSave && onSave({});
      }
    },
    [onSave]
  );

  React.useEffect(() => {
    if (!open) {
      document.removeEventListener('keyup', keyUpHandler);
    } else {
      document.addEventListener('keyup', keyUpHandler);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      title={t('services.dialog.rds.title')}
      contentProps={{
        style: {
          minHeight: remCalc(400),
        },
      }}
      onClose={props.onClose}
      onSave={() => undefined}
      actions={
        <div className="full-width flex justify-between">
          <Button
            color="default"
            variant="outlined"
            onClick={props.onClose}
            disabled={isRequesting}
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={onSave} disabled={isRequesting}>
            {t('common.deploy')}
          </Button>
        </div>
      }
    >
      <Row justifyContent="space-between" columnSpacing={2}>
        <Col xs={7} className="steel">
          <RdsDialogLeftContent>
            <p className="mb-20">{t('services.dialog.rds.content.mainText')}</p>
            <div className="mb-15">
              {t('services.dialog.rds.content.availableOn')}
            </div>
            <div className="bold mb-20">rds.{coreData.domainName}</div>
            <div>{t('services.dialog.rds.content.preListText')}</div>
            <ul>
              <li>
                {t('services.dialog.rds.content.list.lightUsers')}:{' '}
                {numberToCurrency(lightUserFee)}
              </li>
              <li>
                {t('services.dialog.rds.content.list.officeUsers')}:{' '}
                {numberToCurrency(standardUserFee)}
              </li>
              <li>
                {t('services.dialog.rds.content.list.powerUsers')}:{' '}
                {numberToCurrency(powerUserFee)}
              </li>
            </ul>
          </RdsDialogLeftContent>
        </Col>
        <Col xs={5}>
          <Row
            direction="column"
            justifyContent="space-between"
            columnSpacing={2}
            style={{ minHeight: remCalc(400) }}
          >
            <Col className="full-width">
              <PrimaryTextH5 className="mb-25">
                {t('costsInfo.title')}
              </PrimaryTextH5>
              <Row
                justifyContent="space-between"
                columnSpacing={2}
                className="fs-10 steel uppercase mb-15"
              >
                <Col xs={8}>
                  <span>{t('costsInfo.head.serviceName')}</span>
                </Col>
                <Col xs={4} className="text-right">
                  <span>{t('costsInfo.head.price')}</span>
                </Col>
              </Row>
              <h5 className="mb-15">{t('costsInfo.remoteDesktop')}</h5>
              <div className="custom-list">
                <CustomListUl>
                  <li>
                    <div className="flex justify-between">
                      <span>{t('costsInfo.baseFee')}</span>
                      <span className="pl-10 steel">
                        {numberToCurrency(baseFee, false)}
                      </span>
                    </div>
                  </li>
                </CustomListUl>
              </div>
            </Col>
            <Col className="full-width">
              <div className="flex justify-between align-center">
                <h5 className="fs-17">{t('costsInfo.totalMonthly')}</h5>
                <PrimaryTextSpan className="fs-20 bold">
                  {numberToCurrency(baseFee, false)}
                </PrimaryTextSpan>
              </div>
              <div className="fs-12 steel mb-10">
                {t('costsInfo.chfExclVat')}
              </div>
              {isEvaluation && (
                <Alert className="fs-12" severity="info">
                  {t('costsInfo.evalAccount.notify')}
                </Alert>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
};

export default DeployRds;
