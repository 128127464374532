import React, { useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { Row, Button, Paper } from 'elements';
import { observer } from 'mobx-react-lite';
import { withVmActions, InjectedProps } from 'hocs/withVmActions';
import * as StateHandlers from 'states';
import { confirm, confirmDelete, noop } from 'utils';
import {
  useQuery,
  usePermissions,
  useState,
  useSnapshotRequest,
  useTask,
  useStateHandler,
} from 'hooks';
import { ISnapshotAction } from './helpers';
import AdditionalDiskDetailsDialog from './dialogs/AdditionalDiskDetails';
import { SAdditionalDisksTable, SnapshotAlert } from './Styled';

interface IState {
  open: boolean;
  selectedDisk: null | IVmTypes.VMDisk;
}

const INIT_STATE: IState = {
  open: false,
  selectedDisk: null,
};

const AdditionalDisks = observer((props: InjectedProps) => {
  const { onEdit, fetchVm, isRequesting } = props;
  const { t } = useTranslation();
  const {
    permissions: { canManage: isEditable },
    isProvider,
  } = usePermissions('SERVICES');
  const {
    data: _disks,
    get: fetchDisks,
    remove: deleteDisk,
    update: updateDisk,
    create: createDisk,
  } = useStateHandler(StateHandlers.vmDisks);
  const { query, queryStr, changeQuery } = useQuery();
  const { data: vm } = useStateHandler(StateHandlers.vmDetails);
  const snapshots = useStateHandler(StateHandlers.snapshots);
  const currentSnapshot = vm.serviceSnapshot;
  const { isRequesting: isSnapshotRequesting, execute: snapshotPromise } =
    useSnapshotRequest(
      { serviceEntityId: vm.id, serviceEntityName: 'Vm' },
      currentSnapshot?.id
    );
  const costBreakdown = useStateHandler(StateHandlers.costBreakdown);
  const [state, handleStateChange] = useState<IState>(INIT_STATE);
  const mainDisk = {
    id: uuid(),
    isMain: true,
    name: t('services.vm.disks.table.mainDisk'),
    sizeGb: vm.osDiskSizeGb,
  };
  const disks = [mainDisk, ..._disks] as
    | IVmTypes.VMDisk[]
    | IVmTypes.NewVMDisk[];
  const hasBillingCycle = !!vm?.billingCycleId;
  const isUsageEnabled = !!vm?.isUsageEnabled;
  const hasBilling = isProvider || hasBillingCycle || isUsageEnabled;

  const hasSnapshot = !!vm.vcdSnapshotCreatedAt;

  const isNew = !state.selectedDisk;

  const handleOpenState = React.useCallback(
    (open: boolean, selectedDisk: IVmTypes.VMDisk | null) => () => {
      const newState = open
        ? { open, selectedDisk }
        : { open, selectedDisk: null };
      handleStateChange(newState);
    },
    []
  );

  const onUpdateSnTaskComplete = React.useCallback(() => {
    snapshots.get();
    fetchVm({ id: query.id });
  }, [query.id]);

  const snapshotTask = useTask(currentSnapshot?.task, {
    onComplete: onUpdateSnTaskComplete,
  });

  const getDisks = useCallback(
    (query: any) => {
      const { id: vmId, ...restQuery } = query;
      fetchDisks({ vmId, ...restQuery });
    },
    [queryStr]
  );

  const onCreateDisk = useCallback(
    (payload: any) => {
      createDisk({ ...payload, vmId: query.id }).then(() => {
        getDisks(query);
        handleOpenState(false, null)();
      });
    },
    [queryStr]
  );

  const onUpdateDisk = useCallback(
    (payload: any) => {
      updateDisk(
        { vmId: query.id, diskId: state.selectedDisk?.id },
        payload
      ).then(() => {
        getDisks(query);
        handleOpenState(false, null)();
      });
    },
    [query.id, state.selectedDisk]
  );

  const onDelete = useCallback(
    (diskId: number) => {
      return confirmDelete({
        title: 'services.vm.disks.confirm.delete.title',
        content: t('services.vm.disks.confirm.delete.content'),
        placeholder: t('app.confirm.typeDelete.placeholder'),
        onSuccess: () =>
          deleteDisk({ vmId: query.id, diskId }).then(() => {
            getDisks(query);
            handleOpenState(false, null)();
          }),
        onCancel: noop,
      });
    },
    [queryStr]
  );

  const onAddDisks = useCallback(() => {
    if (!hasBilling) {
      return confirm({
        title: 'services.vm.disks.notify.add.noUsage.title',
        content: 'services.vm.disks.notify.add.noUsage.content',
        onSuccess: () => undefined,
        successLabel: 'common.ok',
      });
    }
    return handleOpenState(true, null)();
  }, [hasBilling, isProvider]);

  const onEditDisk = React.useCallback(
    (disk: IVmTypes.VMDisk) => {
      if (disk.isMain) {
        return onEdit();
      }
      handleOpenState(true, disk)();
    },
    [onEdit]
  );

  const confirmSnapshotRequest = React.useCallback(
    (action: ISnapshotAction) => {
      snapshotPromise(action).then(() => {
        snapshots.get();
        fetchVm({ id: query.id });
      });
    },
    [fetchVm]
  );

  React.useEffect(() => {
    getDisks(query);
  }, [queryStr]);

  return (
    <Paper className="p-30">
      <Row className="mb-25" alignItems="center" justifyContent="space-between">
        <div className="uppercase fs-14 bolder">
          {t('services.vm.navigation.disks')}
        </div>
        {isEditable && (
          <Button disabled={hasSnapshot} size="small" onClick={onAddDisks}>
            {t('services.vm.disks.addButton')}
          </Button>
        )}
      </Row>
      {hasSnapshot && (
        <SnapshotAlert severity="info" className="mb-15 relative">
          <div className="flex align-center _text">
            <div>{t('services.vm.alert.snapshot.created.title')}</div>
            <Button
              size="small"
              className="ml-10 _button"
              color="default"
              variant="outlined"
              disabled={
                isRequesting ||
                isSnapshotRequesting ||
                snapshotTask.isTaskActive
              }
              onClick={() => {
                confirmSnapshotRequest('remove');
              }}
            >
              {t('services.content.action.removeSnapshot')}
            </Button>
          </div>
        </SnapshotAlert>
      )}
      <div className="mb-25">
        <SAdditionalDisksTable
          hasSnapshot={!!vm.vcdSnapshotCreatedAt}
          tableClassName={cn({ disabled: isRequesting })}
          isEditable={isEditable}
          hasBilling={hasBilling}
          shouldRenderForm={false}
          cellClassName={cn('fs-14')}
          onDelete={(r: any, id: number) => onDelete(id)}
          onEdit={onEditDisk}
          disks={disks}
          fetchDisks={() => getDisks(query)}
          query={query}
          onPageChange={changeQuery}
          costBreakdown={costBreakdown.data}
        />
      </div>
      <AdditionalDiskDetailsDialog
        open={state.open}
        currentDisk={state.selectedDisk}
        onClose={handleOpenState(false, null)}
        isNew={isNew}
        onSave={isNew ? onCreateDisk : onUpdateDisk}
      />
    </Paper>
  );
});

export default withVmActions(AdditionalDisks, 'single');
