import React from 'react';
import { Dialog, Button, Alert } from 'elements';
import { useFormik } from 'formik';
import { useAsync } from 'hooks';
import { buildListParams } from 'utils';
import { useTranslation } from 'react-i18next';
import { badgesService } from 'services';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { parseStateForForm } from './helpers';
import { ALLOWED_BADGES_LIMIT } from './constants';
import BadgeItem from './BadgeItem';

const OBSERVERS = {
  badges: StateHandlers.badges,
  assignments: StateHandlers.badgesAssignment,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IBadgeDialogTypes.IDialogProps & IViewProps) => {
  const { onSave, appEntityId, badges, assignments, ...rest } = props;
  const { t } = useTranslation();
  const { execute: getAssignmentList, isPending } = useAsync(
    badgesService.getAssignmentList
  );

  const fetchAssignments = React.useCallback(async () => {
    const res = await getAssignmentList(
      buildListParams({ page: 1, perPage: 1000, appEntityIds: [appEntityId] }, [
        'appEntityIds',
      ])
    );

    const newData: IBadgeDialogTypes.IValues = {};

    res.data.forEach((badge) => {
      newData[`${badge.badgeId}`] = true;
    });

    form.setValues(newData);

    return await assignments.get();
  }, [appEntityId]);

  const form = useFormik<IBadgeDialogTypes.IValues>({
    initialValues: {},
    onSubmit: (data) =>
      badgesService
        .createBulkAssignment(appEntityId, {
          badges: parseStateForForm(data),
        })
        .then(fetchAssignments)
        .then(props.onClose),
  });

  React.useEffect(() => {
    if (rest.open) {
      fetchAssignments();
    }

    if (!rest.open) {
      form.resetForm();
    }
  }, [rest.open, appEntityId]);

  const hasValues = !!Object.keys(form.values).length;

  const error = React.useMemo(() => {
    let checkedBadges = 0;
    Object.keys(form.values).forEach((key) => {
      if (form.values[key]) {
        checkedBadges++;
      }
    });
    return checkedBadges > ALLOWED_BADGES_LIMIT;
  }, [form.values]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      handleSubmit={form.handleSubmit}
      {...rest}
      title={t('badges.dialog.assign.title')}
      actions={
        <>
          <Button color="default" variant="outlined" onClick={props.onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={error || isPending}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className="lh-12 mb-50">{t('badges.dialog.assign.content')}</div>
      {badges.data.length ? (
        badges.data.map((badge: any) => {
          return (
            <BadgeItem
              key={badge.createdAt}
              {...badge}
              disabled={isPending}
              checked={hasValues ? form.values[badge.id] : false}
              onCheck={(checked) => form.setFieldValue(badge.id, checked)}
            />
          );
        })
      ) : (
        <div className="steel fs-14">{t('badges.noBadges')}</div>
      )}
      {error && (
        <Alert severity="error">{t('badges.errors.selectionLimits')}</Alert>
      )}
    </Dialog>
  );
});

const AssignBadgesDialog = (props: IBadgeDialogTypes.IDialogProps) => (
  <View {...props} {...OBSERVERS} />
);

export default AssignBadgesDialog;
