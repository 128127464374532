import React, { useCallback, useMemo } from 'react';
import { equals } from 'ramda';
import cn from 'classnames';
import { Button, Row, Col, Checkbox } from 'elements';
import { useTranslation } from 'react-i18next';
import { AnyFunc } from 'global-shapes';
import { useFormik } from 'formik';
import { ALL_TEST_IDS } from 'enums';
import { useQuery } from 'hooks';
import { IBackupNotificationValues } from 'backup';
import { showSystemMessage } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

const INITIAL_VALUES: IBackupNotificationValues = {
  isSuccessNotification: false,
  isWarningNotification: false,
  isFailedNotification: false,
};

type IProps = {
  entityId: number;
  backupId: number | null;
  userCount: number;
  initialValues?: typeof INITIAL_VALUES;
  onSave: AnyFunc;
  onAddUser: AnyFunc;
  disabled: boolean;
  isEditable: boolean;
  testId: string;
};

const OBSERVERS = {
  notifications: StateHandlers.backupNotifications,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    backupId,
    onAddUser,
    onSave,
    initialValues,
    disabled,
    testId,
    userCount,
    isEditable,
    notifications,
  } = props;
  const { t } = useTranslation();
  const { query, queryStr } = useQuery();

  const { values, setFieldValue, resetForm, setValues, handleSubmit } =
    useFormik({
      initialValues: initialValues || INITIAL_VALUES,
      onSubmit: (val) => {
        return Promise.resolve(onSave(backupId, { ...INITIAL_VALUES, ...val }));
      },
    });

  const isDirty = useMemo(
    () => !equals(initialValues, values),
    [initialValues, values]
  );

  const fetchNotifications = useCallback(() => {
    notifications.get({ ...query, backupId });
  }, [backupId, queryStr]);

  React.useEffect(() => {
    fetchNotifications();
  }, []);

  React.useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    } else {
      resetForm();
    }
  }, [JSON.stringify(initialValues)]);

  const onCheck = useCallback(
    (name: keyof IBackupNotificationValues) => (isChecked: boolean) => {
      if (isEditable) {
        if (!userCount) {
          return showSystemMessage(
            'services.backup.notifications.empty.alert.title',
            'error'
          );
        }
        setFieldValue(name, isChecked);
      }
    },
    [userCount, isEditable]
  );

  return (
    <div className={cn('mb-20', { disabled })}>
      <div className="flex justify-between  align-center full-width mb-10">
        <div className="uppercase fs-14 bolder">
          {t('services.backup.notifications')}
        </div>
        {isEditable && (
          <Button
            size="small"
            onClick={onAddUser}
            testId={ALL_TEST_IDS.services.backup.notifications.addUser}
          >
            {t('services.backup.notifications.addUser')}
          </Button>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <Row columnSpacing={2} alignItems="center">
          <Col>
            <Checkbox
              testId={testId}
              name="isSuccessNotification"
              checked={values.isSuccessNotification}
              label={t('services.backup.notifications.successful')}
              onCheck={onCheck('isSuccessNotification')}
            />
          </Col>
          <Col>
            <Checkbox
              testId={testId}
              name="isWarningNotification"
              checked={values.isWarningNotification}
              label={t('services.backup.notifications.withWarnings')}
              onCheck={onCheck('isWarningNotification')}
            />
          </Col>
          <Col>
            <Checkbox
              testId={testId}
              name="isFailedNotification"
              checked={values.isFailedNotification}
              label={t('services.backup.notifications.failed')}
              onCheck={onCheck('isFailedNotification')}
            />
          </Col>
          {isEditable && isDirty && (
            <Col>
              <Button
                type="submit"
                size="small"
                testId={testId}
                disabled={disabled}
              >
                {t('common.save')}
              </Button>
            </Col>
          )}
        </Row>
      </form>
    </div>
  );
});

const BackupNotifications = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default BackupNotifications;
