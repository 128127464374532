import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { isString, useFormik } from 'formik';
import { ContentState, EditorState } from 'draft-js';
import FormHelperText from '@mui/material/FormHelperText';
import { TestSuffixes } from 'enums';
// @ts-ignore
import { Editor } from 'react-draft-wysiwyg';
import { ICreateSoftwarePackageValues } from 'app-store';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useState } from 'hooks';
import {
  appStoreFormSchema,
  COST_BASIS_OPTIONS,
  TOOLBAR_EDITOR_OPTIONS,
} from './constants';
import { decorators } from './helpers';
import { validateCallback, validator } from 'utils';
import { ImagePlaceholder } from 'components';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Dialog,
  DialogProps,
  DialogTab,
  DialogTabs,
  Input,
  InputLabel,
  MuiIcons,
  Row,
  Select,
  Switch,
  TabPanel,
  Tooltip,
} from 'elements';

type IProps = DialogProps<ICreateSoftwarePackageValues> & {
  isNew?: boolean;
  title: any;
  initialValues: ICreateSoftwarePackageValues;
};

const INITIAL_STATE = {
  tab: 0,
};

const initials: ICreateSoftwarePackageValues = {
  logo: undefined,
  manufacturer: '',
  name: '',
  description: EditorState.createWithContent(ContentState.createFromText('')),
  identifier: '',
  installParams: '',
  isEnabled: false,
  isUninstallable: true,
  ownLicense: false,
  requiresLicense: false,
  providersLicense: false,
  ownLicenseDescription: '',
  ownActivationCommand: '',
  ownActivationParams: '',
  providerMinLicenseUnit: 1,
  providerLicensingInterval: 1,
  providerLicenseCost: 1,
  ownKeyFormat: '',
  providerCostBasis: COST_BASIS_OPTIONS[0],
};

const OBSERVERS = {
  softwarePackages: StateHandlers.softwarePackages,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { t } = useTranslation();
  const { onSave, open, initialValues, isNew, softwarePackages, ...rest } =
    props;
  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    setFieldValue,
    setValues,
    resetForm,
    submitCount,
  } = useFormik({
    initialValues: initials,
    validateOnMount: false,
    validationSchema: appStoreFormSchema,
    onSubmit: (val: ICreateSoftwarePackageValues) => onSave && onSave(val),
  });

  const [state, handleStateChange] = useState<any>(INITIAL_STATE);

  const handleTabChange = useCallback(
    (ev: any, newTab: number) => handleStateChange(newTab, 'tab'),
    []
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => setFieldValue('logo', acceptedFiles[0]),
    [] // eslint-disable-line
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/png': [], 'image/jpeg': [], 'iimage/jpg': [] },
  });

  const imagePreview = useMemo(
    () =>
      values.logo &&
      (isString(values.logo) ? values.logo : URL.createObjectURL(values.logo)),
    [values.logo]
  );

  React.useEffect(() => {
    if (open && initialValues) {
      setValues(initialValues);
    }
    if (!open) {
      handleStateChange(0, 'tab');
      resetForm();
    }
  }, [open, initialValues]);

  return (
    <Dialog
      {...rest}
      fullWidth
      scroll="body"
      open={open}
      handleSubmit={handleSubmit}
      contentProps={{ style: { overflow: 'visible' } }}
      actions={
        <>
          <Button color="default" variant="outlined" onClick={props.onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={softwarePackages.isRequesting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <DialogTabs value={state.tab} onChange={handleTabChange}>
        <DialogTab index={0} label={t('softwarePackages.dialog.tab.info')} />
        <DialogTab index={1} label={t('softwarePackages.dialog.tab.license')} />
      </DialogTabs>
      <TabPanel value={state.tab} index={0}>
        <Row columnSpacing={3} alignItems="center" className="pb-25">
          <Col>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <ImagePlaceholder src={imagePreview} />
            </div>
          </Col>
          <Col>
            <p className="fs-14 steel mb-10">
              {t('softwarePackages.dialog.logo.tip')}
            </p>
            <span {...getRootProps()}>
              <input {...getInputProps()} />
              <Button size="small">{t('common.upload')}</Button>
            </span>
          </Col>
        </Row>
        <Input
          label="softwarePackages.dialog.appManufacturer"
          placeholder="softwarePackages.dialog.placeholder.appManufacturer"
          name="manufacturer"
          className="mb-25"
          onChange={handleChange}
          value={values.manufacturer}
          error={!!submitCount && !!errors.manufacturer}
          helperText={errors.manufacturer}
          helperTextOptions={{ max: 60 }}
        />
        <Input
          label="softwarePackages.dialog.appName"
          placeholder="softwarePackages.dialog.placeholder.appName"
          name="name"
          className="mb-25"
          onChange={handleChange}
          value={values.name}
          error={!!submitCount && !!errors.name}
          helperText={errors.name}
          helperTextOptions={{ max: 100 }}
        />
        <div
          className={cn('mb-25', {
            hasError: !!submitCount && !!errors.description,
          })}
        >
          <InputLabel>{t('softwarePackages.dialog.appDescription')}</InputLabel>
          <Editor
            customDecorators={decorators}
            editorState={values.description}
            wrapperClassName="text-editor"
            toolbar={TOOLBAR_EDITOR_OPTIONS}
            placeholder={t(
              'softwarePackages.dialog.placeholder.appDescription'
            )}
            onEditorStateChange={(text: EditorState) =>
              setFieldValue('description', text)
            }
          />
          {!!submitCount && !!errors.description && (
            <Row justifyContent="flex-end">
              <FormHelperText
                error={true}
                data-test-id={`software-package-${TestSuffixes.inputError}-description`}
              >
                {t(errors.description)}
              </FormHelperText>
            </Row>
          )}
        </div>
        <Input
          label="softwarePackages.dialog.appIdentifier"
          placeholder="softwarePackages.dialog.placeholder.appIdentifier"
          name="identifier"
          className="mb-25"
          onChange={handleChange}
          value={values.identifier}
          error={!!submitCount && !!errors.identifier}
          helperText={errors.identifier}
        />
        <Input
          label="softwarePackages.dialog.appParameters"
          placeholder="softwarePackages.dialog.placeholder.appParameters"
          name="installParams"
          className="mb-25"
          onChange={handleChange}
          value={values.installParams}
        />
        <Switch
          label={t('softwarePackages.dialog.allowTenantToUninstall')}
          checked={values.isUninstallable}
          onCheck={(isUninstallable) =>
            setFieldValue('isUninstallable', isUninstallable)
          }
        />
      </TabPanel>
      <TabPanel value={state.tab} index={1}>
        <div className="mb-15">
          <Checkbox
            label={t('softwarePackages.dialog.requireLicense')}
            checked={values.requiresLicense}
            name="requiresLicense"
            disabled={!isNew}
            onCheck={(requiresLicense) => {
              const restValues: any = values;
              if (!requiresLicense) {
                restValues.ownLicense = false;
                restValues.providersLicense = false;
              }
              setValues({ ...restValues, requiresLicense });
            }}
          />
        </div>
        <div className="pl-30">
          <div className="mb-10">
            <Checkbox
              name="ownLicense"
              disabled={!values.requiresLicense || !isNew}
              checked={values.ownLicense}
              label={t('softwarePackages.dialog.allowOwn')}
              onCheck={(val) => setFieldValue('ownLicense', val)}
            />
          </div>
          {values.ownLicense && (
            <div>
              <Input
                label="softwarePackages.dialog.description"
                placeholder="softwarePackages.dialog.placeholder.description"
                name="ownLicenseDescription"
                className="mb-25"
                onChange={handleChange}
                value={values.ownLicenseDescription}
                multiline
                rows={5}
                style={{ height: 'auto' }}
                error={!!submitCount && !!errors.ownLicenseDescription}
                helperText={errors.ownLicenseDescription}
                helperTextOptions={{ max: 1000 }}
              />
              <Input
                label="softwarePackages.dialog.activationScript"
                placeholder="softwarePackages.dialog.placeholder.activationScript"
                name="ownActivationCommand"
                className="mb-25"
                onChange={handleChange}
                value={values.ownActivationCommand}
                error={!!submitCount && !!errors.ownActivationCommand}
                helperText={errors.ownActivationCommand}
              />
              <Input
                label="softwarePackages.dialog.activationParams"
                placeholder="softwarePackages.dialog.placeholder.activationParams"
                name="ownActivationParams"
                className="mb-25"
                onChange={handleChange}
                value={values.ownActivationParams}
              />
              <Input
                label="softwarePackages.dialog.licenseFormat"
                placeholder="softwarePackages.dialog.placeholder.licenseFormat"
                name="ownKeyFormat"
                className="mb-25"
                onChange={handleChange}
                value={values.ownKeyFormat}
                error={!!submitCount && !!errors.ownKeyFormat}
                helperText={errors.ownKeyFormat}
              />
            </div>
          )}
          <div className="mb-10">
            <Checkbox
              name="providersLicense"
              disabled={!values.requiresLicense || !isNew}
              checked={values.providersLicense}
              label={t('softwarePackages.dialog.providerLicense')}
              onCheck={(val) => setFieldValue('providersLicense', val)}
            />
          </div>
          {values.providersLicense && (
            <div>
              <Input
                label="softwarePackages.dialog.activationScript"
                placeholder="softwarePackages.dialog.placeholder.activationScript"
                name="providerActivationCommand"
                className="mb-25"
                onChange={handleChange}
                value={values.providerActivationCommand}
                error={!!submitCount && !!errors.providerActivationCommand}
                helperText={errors.providerActivationCommand}
              />
              <Input
                label="softwarePackages.dialog.activationParams"
                placeholder="softwarePackages.dialog.placeholder.activationParams"
                name="providerActivationParams"
                className="mb-25"
                onChange={handleChange}
                value={values.providerActivationParams}
              />
              <Select
                label={t('softwarePackages.dialog.licenseBasedOn')}
                placeholder="softwarePackages.dialog.placeholder.activationParams"
                name="providerCostBasis"
                className="mb-25"
                options={COST_BASIS_OPTIONS}
                onChange={(val) => setFieldValue('providerCostBasis', val)}
                value={values.providerCostBasis}
                error={!!submitCount && !!errors.providerCostBasis}
                helperText={errors.providerCostBasis}
              />
              <Input
                label="softwarePackages.dialog.licenseCostPerUnit"
                placeholder="1"
                name="providerLicenseCost"
                className="mb-25"
                type="number"
                inputProps={{ step: 0.01 }}
                onChange={validateCallback({
                  restrict: validator.onlyNumbersWithDot,
                })(handleChange)}
                value={values.providerLicenseCost}
                error={!!submitCount && !!errors.providerLicenseCost}
                helperText={errors.providerLicenseCost}
                helperTextOptions={{ max: 999999.99, min: 0.01 }}
                labelIcon={
                  <Tooltip
                    placement="top"
                    title={
                      <div>
                        {t('forms.sups.numbersAndDots')
                          .split('; ')
                          .map((s) => (
                            <div key={s}>- {s}</div>
                          ))}
                      </div>
                    }
                  >
                    <MuiIcons.HelpOutline
                      className="fs-12 ml-5"
                      color="primary"
                    />
                  </Tooltip>
                }
              />
              {values.providerCostBasis?.value !== 'FIXED' && (
                <>
                  <Input
                    label="softwarePackages.dialog.minUnits"
                    placeholder="1"
                    name="providerMinLicenseUnit"
                    className="mb-25"
                    type="number"
                    onChange={validateCallback({ min: 1 })(handleChange)}
                    value={values.providerMinLicenseUnit}
                    error={!!submitCount && !!errors.providerMinLicenseUnit}
                    helperText={errors.providerMinLicenseUnit}
                    helperTextOptions={{ min: 1 }}
                  />
                  <Input
                    label="softwarePackages.dialog.licenseInterval"
                    placeholder="1"
                    name="providerLicensingInterval"
                    className="mb-25"
                    type="number"
                    onChange={validateCallback({ min: 0 })(handleChange)}
                    value={values.providerLicensingInterval}
                    error={!!submitCount && !!errors.providerLicensingInterval}
                    helperText={errors.providerLicensingInterval}
                    helperTextOptions={{ min: 0 }}
                  />
                </>
              )}
              <Alert severity="warning" contentClassName="fs-12">
                {t(
                  `softwarePackages.dialog.alerts.providerLicense.${
                    isNew ? 'add' : 'edit'
                  }`
                )}
              </Alert>
            </div>
          )}
        </div>
      </TabPanel>
    </Dialog>
  );
});

const AppStoreDetailsDialog = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default AppStoreDetailsDialog;
