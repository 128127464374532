export enum CustomEvents {
  showSystemMessage = 'show-system-message',
  changeTheme = 'change-theme',
  changeLevel = 'change-level',
  confirmDialog = 'confirm-dialog',
  assignDialog = 'assign-dialog',
  initExpireSessionDialog = 'init-expire-session-dialog',
  closeExpireSessionDialog = 'close-expire-session-dialog',
  fetchVmList = 'fetch-vm-list',
  fetchFolderList = 'fetch-folder-list',
  updateTask = 'updateTask',
  updateTaskList = 'updateTaskList',
  updateVm = 'update-vm',
  refetchCore = 'refetch-core',
  refetchRds = 'refetch-rds',
  refetchVms = 'refetch-vms',
  refreshAccount = 'refreshAccount',
  vmState = 'vm-state-change',
  editDS = 'edit-ds',
  reinstallOs = 'reinstall-os',
}
