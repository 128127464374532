import * as React from 'react';
import {
  Input,
  Slider,
  SliderValueLabel,
  SliderProps,
  InputProps,
} from 'elements';
import { withTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import { remCalc } from 'utils';
import cn from 'classnames';
import { ALL_TEST_IDS } from 'enums';

type IProps = React.PropsWithChildren<
  WithT & {
    name: string;
    className?: string;
    labelClassName?: string;
    error?: boolean;
    tooltip?: any;
    inputWidth?: number;
    isDisabled?: boolean;
    onInputChange: InputProps['onChange'];
    onSliderChange: SliderProps['onChange'];
    sliderProps?: SliderProps;
    inputProps?: any;
    label?: string;
    value: any;
    helperText?: any;
    isNew?: boolean;
    otherInputSettings?: any;
  }
>;

const Renderer = (props: IProps) => {
  const {
    name,
    label,
    error,
    helperText,
    onSliderChange,
    sliderProps,
    value,
    onInputChange,
    className,
    t,
    isDisabled,
    inputWidth,
    labelClassName,
    tooltip,
    inputProps,
    otherInputSettings,
  } = props;

  return (
    <div className={cn(className, 'relative')}>
      <div className="flex justify-between align-center mb-10">
        {label && (
          <div className={cn(labelClassName)}>
            {t(label) as string} {tooltip}
          </div>
        )}
        <div>
          <div className="flex align-center justify-end">
            {otherInputSettings}
            <Input
              {...inputProps}
              type="number"
              elementSize="sm"
              name={name}
              testId={ALL_TEST_IDS.services.vm.details}
              style={{ width: remCalc(inputWidth || 100) }}
              value={value}
              onChange={onInputChange}
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
      <Slider
        value={+value}
        {...sliderProps}
        valueLabelDisplay="auto"
        components={{
          ValueLabel: SliderValueLabel,
        }}
        disabled={isDisabled}
        onChange={onSliderChange}
      />
      {helperText && (
        <div
          className={cn('fs-12 _slider_input_error', { error, steel: !error })}
          style={{
            position: 'relative',
            display: 'block',
            maxWidth: '80%',
            margin: '-28px auto 0',
            textAlign: 'center',
          }}
        >
          {t(helperText) as string}
        </div>
      )}
    </div>
  );
};

const SliderWithInputController = withTranslation()(Renderer);

export default SliderWithInputController;
