import * as React from 'react';
import { styled } from 'elements';
import { remCalc, important } from 'utils';
import { backdropClasses } from '@mui/material/Backdrop';
import DrawerMUI, { DrawerProps } from '@mui/material/Drawer';
import { PALETTE } from 'enums';
import { HEADER_HEIGHT, EVAL_BAR_HEIGHT } from './constants';

const DEFAULT_STYLES = {
  height: `calc(100vh - ${remCalc(HEADER_HEIGHT)})`,
  top: important(remCalc(HEADER_HEIGHT)),
  [`& > .${backdropClasses.root}`]: {
    top: remCalc(HEADER_HEIGHT),
  },
  [`& > .MuiPaper-root`]: {
    top: remCalc(HEADER_HEIGHT),
    width: remCalc(520),
  },
};

const WITH_EVAL_BAR_STYLES = {
  width: remCalc(520),
  height: `calc(100vh - ${remCalc(HEADER_HEIGHT + EVAL_BAR_HEIGHT)})`,
  top: important(remCalc(HEADER_HEIGHT + EVAL_BAR_HEIGHT)),
  [`& > .${backdropClasses.root}`]: {
    top: remCalc(HEADER_HEIGHT + EVAL_BAR_HEIGHT),
  },
  [`& > .MuiPaper-root`]: {
    top: remCalc(HEADER_HEIGHT + EVAL_BAR_HEIGHT),
    width: remCalc(520),
  },
  '& .search-input': {
    '&::before': {
      borderWidth: important('2px'),
      borderColor: important(PALETTE.lightGrey),
    },
  },
};

export default styled((props: DrawerProps & { isEvaluation: boolean }) => {
  const { isEvaluation, ...rest } = props;
  return <DrawerMUI {...rest} />;
})(({ isEvaluation }) =>
  isEvaluation ? WITH_EVAL_BAR_STYLES : DEFAULT_STYLES
);
