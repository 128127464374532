import { showSystemMessage } from 'utils';
import { usersService } from 'services';
import { IGlobalUser } from 'users-shapes';
import { BaseMobxInstanceDecorator } from './utilities';

export const users = new BaseMobxInstanceDecorator<IGlobalUser, true>({
  instanceName: 'users',
  requests: {
    get: usersService.getUsersList,
    // @ts-ignore
    create: usersService.create,
    update: async (id, payload) => {
      const res = await usersService.update(id as number, payload);
      showSystemMessage('users.notify.update.success', 'success');
      return res as any;
    },
    remove: async (id) => {
      const res = await usersService.deleteUser(id as number);
      showSystemMessage('users.notify.delete.success', 'success');
      return res;
    },
  },
  initialState: [],
});
