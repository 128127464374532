import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, IconButton, MuiIcons } from 'elements';
import { GLOBAL_DATE_FORMAT } from 'enums';
import * as Utils from 'utils';
import PositionCostLogItem from './PositionCostLogItem';
import { InvoicePositionItemDto } from './PositionDto';
import { IInvoiceTypes } from '../types';

type IProps = InvoicePositionItemDto & IInvoiceTypes.AdditionalPositionProps;

const PositionSubItem = (props: IProps) => {
  const { index, onDelete, onEdit, isEditable, ...it } = props;

  const {
    i18nCode,
    description,
    formattedResourceValue,
    hasDaterange,
    meta,
    hideQty,
    amount,
    displayPrice,
    displayQty,
    isCustomItem,
    hasCostLogs,
  } = it;
  const { t } = useTranslation();

  const _isEditable = isEditable && isCustomItem;

  return (
    <>
      <Row alignItems="center" className="fs-15 steel pt-5 pb-5">
        <Col xs={6}>
          {`${index} `}
          {hasCostLogs ? '' : `${formattedResourceValue} `}
          {i18nCode
            ? t(`invoices.current.positions.items.${i18nCode}`)
            : description}
          {hasDaterange &&
            ` (${Utils.dayjs(meta?.startDate)
              .utc()
              .format(GLOBAL_DATE_FORMAT)}-${Utils.dayjs(meta?.endDate)
              .utc()
              .format(GLOBAL_DATE_FORMAT)})`}
        </Col>
        <Col xs={6}>
          <Row alignItems="center">
            <Col xs={10}>
              <Row alignItems="center">
                <Col className="text-right" xs={4}>
                  {hasCostLogs || hideQty ? undefined : displayQty}
                </Col>
                <Col className="text-right" xs={4}>
                  {hasCostLogs || hideQty
                    ? undefined
                    : Utils.numberToCurrency(displayPrice as number, false, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Col>
                <Col xs={4} className="text-right">
                  {Utils.numberToCurrency(amount, false, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Col>
              </Row>
            </Col>
            <Col xs={2}>
              {_isEditable && (
                <Row alignItems="center" justifyContent="flex-end">
                  <Col>
                    <IconButton
                      size="small"
                      onClick={() => onEdit && onEdit(it)}
                    >
                      <MuiIcons.Edit className="fs-20" />
                    </IconButton>
                  </Col>
                  <Col>
                    <IconButton
                      size="small"
                      onClick={() => onDelete && onDelete(it)}
                    >
                      <MuiIcons.Delete className="fs-20" />
                    </IconButton>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {hasCostLogs &&
        meta?.costLogs?.map((log, ind) => {
          return (
            <PositionCostLogItem
              key={ind}
              {...{
                index: `${index}.${ind + 1}`,
                isEditable,
                onDelete,
                onEdit,
              }}
              {...log}
              hideQty={hideQty}
              isEditable={_isEditable}
            />
          );
        })}
    </>
  );
};

export default PositionSubItem;
