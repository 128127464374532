import * as React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { DragAndDropTypes } from 'enums/DnD';
import { observer } from 'mobx-react-lite';
import { useStateHandler } from 'hooks';
import * as StateHandlers from 'states';

export interface IProps {
  folder: IServiceGroups.Group;
  accepts: typeof DragAndDropTypes.DS | typeof DragAndDropTypes.VM;
  style?: React.CSSProperties;
}

function selectBackgroundColor(isOver: boolean, isSameTypeFolder: boolean) {
  if (isOver && isSameTypeFolder) {
    return 'rgba(29, 98, 171, 0.2)';
  } else {
    return 'inherit';
  }
}

export default observer(
  ({ folder, children, accepts, style }: React.PropsWithChildren<IProps>) => {
    const folders = useStateHandler(StateHandlers.folders);

    const elementType =
      folder.type === 'DEDICATED_SERVER'
        ? DragAndDropTypes.DS
        : DragAndDropTypes.VM;

    const { isOver, setNodeRef, active } = useDroppable({
      id: folder.id,
      data: {
        accepts,
        elementType,
        ...folder,
      },
    });

    let ref: any = setNodeRef;

    const isSameTypeFolder = active?.data?.current?.elementType === elementType;
    const backgroundColor = selectBackgroundColor(isOver, isSameTypeFolder);

    if (folders.dataReceived && !folders.data.length) {
      ref = undefined;
    }

    return (
      <div
        ref={ref}
        style={{
          overflow: 'hidden',
          backgroundColor,
          transition: 'background-color 0.25s ease-in-out 0s',
          borderRadius: 6,
          zIndex: 3,
          ...style,
        }}
      >
        {children}
      </div>
    );
  }
);
