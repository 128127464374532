import * as React from 'react';
import { getCurrentToken } from 'utils';
import { useQuery } from 'hooks';
import { Loader } from 'elements';
import * as StateHandlers from 'states';
import { observer } from 'mobx-react-lite';
import { useAuth } from './AuthProvider';

type IProps = React.PropsWithChildren;
type IHandlersProps = {
  currentUser: StateHandlers.ICurrentUserHandler;
  taskManager: StateHandlers.ITaskManagerHandler;
};
type IQueryParams = { psn?: string; tsn: string };

const View = observer((props: IProps & IHandlersProps) => {
  const { currentUser, taskManager } = props;
  const auth = useAuth();
  const { query, changeQuery } = useQuery<IQueryParams>();

  const fetchCurrentUser = React.useCallback(async (query: any) => {
    await StateHandlers.fetchAllAccountData(query);
    await taskManager.get();
    changeQuery({ tsn: undefined, psn: undefined });
  }, []);

  React.useEffect(() => {
    const token = getCurrentToken();
    if (token) {
      auth.setToken(token);
      if (auth.isOnAuthorizedRoute) {
        if (!currentUser.dataReceived) {
          fetchCurrentUser({ ...query, shouldApplyTheme: true });
        }
      }
    } else {
      if (auth.isOnAuthorizedRoute) {
        auth.signOut();
      }
    }
  }, []);

  const isLoadintAccountData = React.useMemo(() => {
    if (auth.isOnAuthorizedRoute) {
      if (getCurrentToken() && !currentUser.dataReceived) return true;
    }

    return false;
  }, [auth.isOnAuthorizedRoute, currentUser.dataReceived]);

  if (isLoadintAccountData) {
    return <Loader />;
  }

  return <>{props.children}</>;
});

const RoutesHolder = (props: IProps) => (
  <View
    {...props}
    currentUser={StateHandlers.currentUser}
    taskManager={StateHandlers.taskManager}
  />
);

export default RoutesHolder;
