import React, { useCallback } from 'react';
import { Steps } from 'intro.js-react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { DelayedRender } from 'hocs';

const namespaces = [
  'tenantShowInvoicesButton',
  'tenantShowBillableItemsButton',
  'tenantShowSettingsButton',
  'tenantServicesConnectivity',
  'tenantServicesCoreInfra',
  'tenantServicesRDS',
  'tenantServicesVM',
  'tenantServicesDNS',
  'tenantShowJobsDashboardButton',
  'tenantShowCostBreakdownButton',
];

const seenPayload = namespaces.map((name) => ({ name, status: 'SEEN' }));

const isAllGuideStepsCompleted = (state: any) => {
  return namespaces.every(
    (name) => state[name] && state[name].status === 'SEEN'
  );
};

const getConfig = (t: TFunction) => ({
  initialStep: 0,
  steps: [
    {
      title: t('appGuide.tenant.step.1.title'),
      element: '#invoice-view-nav-item',
      intro: t('appGuide.tenant.header.step.1.content'),
      position: 'top-middle-aligned',
    },
    {
      title: t('appGuide.tenant.step.2.title'),
      element: '#billable-items-nav-item',
      intro: t('appGuide.tenant.header.step.2.content'),
      position: 'top-middle-aligned',
    },
    {
      title: t('appGuide.tenant.step.3.title'),
      element: '#settings-items-nav-item',
      intro: t('appGuide.tenant.header.step.3.content'),
      position: 'top-middle-aligned',
    },
    {
      title: t('appGuide.tenant.step.4.title'),
      element: '#job-dashboard-icon',
      intro: t('appGuide.tenant.header.step.4.content'),
      position: 'top-middle-aligned',
    },
    {
      title: t('appGuide.tenant.step.5.title'),
      element: '#cost-breakdown-button',
      intro: t('appGuide.tenant.header.step.5.content'),
      position: 'top-middle-aligned',
    },
    {
      title: t('appGuide.tenant.step.6.title'),
      element: '#create-connectivity-button',
      intro: t('appGuide.services.step.1.content'),
      position: 'top-left-aligned',
    },
    {
      title: t('appGuide.tenant.step.7.title'),
      element: '#create-core-button',
      intro: t('appGuide.services.step.2.content'),
      position: 'top-middle-aligned',
      tooltipClass: 'lg',
    },
    {
      title: t('appGuide.tenant.step.8.title'),
      element: '#create-rds-button',
      intro: t('appGuide.services.step.3.content'),
      position: 'top-right-aligned',
      tooltipClass: 'lg',
    },
    {
      title: t('appGuide.tenant.step.9.title'),
      element: '#create-vm-button',
      intro: t('appGuide.services.step.4.content'),
      position: 'top-middle-aligned',
      tooltipClass: 'lg',
    },
    {
      title: t('appGuide.tenant.step.10.title'),
      element: '#create-dns-button',
      intro: t('appGuide.services.step.5.content'),
      position: 'top-right-aligned',
      tooltipClass: 'lg',
    },
  ],
  options: {
    showBullets: false,
    exitOnOverlayClick: false,
    disableInteraction: true,
    keyboardNavigation: true,
    showStepNumbers: true,
    skipLabel: 'close',
    prevLabel: t('common.previous'),
    nextLabel: t('common.next'),
    doneLabel: t('common.close'),
    overlayOpacity: 0.3,
  },
});

const OBSERVERS = {
  appGuide: StateHandlers.guideTooltips,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { appGuide } = props;
  const { t } = useTranslation();

  const isAllStepsCompleted = isAllGuideStepsCompleted(appGuide.data);

  const onSeenGuide = useCallback(() => {
    if (appGuide.dataReceived && !isAllStepsCompleted) {
      appGuide.update(null, seenPayload);
    }
  }, [appGuide.dataReceived, isAllStepsCompleted]);

  const config = React.useMemo(() => getConfig(t), []);

  return (
    <DelayedRender delay={1500}>
      <Steps
        enabled={appGuide.dataReceived && !isAllStepsCompleted}
        steps={config.steps}
        initialStep={config.initialStep}
        onExit={onSeenGuide}
        options={config.options}
      />
    </DelayedRender>
  );
});

const GuideTooltip = () => <View {...OBSERVERS} />;

export default GuideTooltip;
