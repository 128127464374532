import React from 'react';
import UserContainer from './UserContainer';

const CoreUsers = () => (
  <UserContainer
    reducer="users"
    selectedReducer="selectedUser"
    title="services.core.users"
  />
);

export default CoreUsers;
