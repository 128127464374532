import React from 'react';
import { iconMapping, titleMapping, useTheme } from 'elements';
import { useTranslation } from 'react-i18next';
import { PALETTE } from 'enums';

type Props = {
  status: 'error' | 'success' | 'warning' | 'processing' | 'info';
};

const StatusIcon = React.forwardRef((props: Props, ref: any) => {
  const { status, ...rest } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const classes = React.useMemo(
    () => ({
      error: {
        color: PALETTE.error,
      },
      success: {
        color: PALETTE.success,
      },
      warning: {
        color: PALETTE.warning,
      },
      processing: {
        color: theme.palette.primary.main,
      },
      info: {
        color: PALETTE.info,
      },
    }),
    [status]
  );

  return (
    <div
      {...rest}
      className="flex align-center fs-16"
      style={classes[status]}
      ref={ref}
    >
      {iconMapping[status]}
      <span className="pl-10 fs-14">{t(titleMapping[status])}</span>
    </div>
  );
});

export default StatusIcon;
