import * as React from 'react';
import cn from 'classnames';
import { VmColumnKeys } from 'enums';
import { LinearTable } from 'elements';
import { useStateHandler, usePermissions, useAccount } from 'hooks';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import FolderList from './Folders/FolderList';
import { getVmTableColumn } from './vmColumns';

type IProps = {
  query: AnyShape;
  changeQuery: AnyFunc;
  onResolve: AnyFunc;
  onOpenDetails: AnyFunc;
  fetchVms: AnyFunc;
};

const TablesWrapper = observer((props: IProps) => {
  const { changeQuery, onResolve, onOpenDetails, fetchVms, query } = props;
  const { t } = useTranslation();
  const currentUser = useStateHandler(StateHandlers.currentUser);
  const vms = useStateHandler(StateHandlers.vms);
  const currentVm = useStateHandler(StateHandlers.vmDetails);
  const meta = useStateHandler(StateHandlers.meta);
  const assignments = useStateHandler(StateHandlers.badgesAssignment);
  const { permissions } = usePermissions('SERVICES');
  const [account] = useAccount();

  const vmColumns = React.useMemo(
    () =>
      getVmTableColumn({
        t,
        permissions,
        avaliableColumns:
          account?.uiServiceConfig?.vmListColumns || VmColumnKeys,
        isRequesting: currentVm.isRequesting || vms.isRequesting,
        onOpenDetails,
        fetchVms,
        meta: meta.data,
        assignments: assignments.data,
        onResolve: onResolve,
      }),
    [
      meta.data,
      assignments.data,
      JSON.stringify(permissions),
      JSON.stringify(query),
      JSON.stringify(account?.uiServiceConfig?.vmListColumns),
      vms.isRequesting,
      currentVm.isRequesting,
      onResolve,
      fetchVms,
    ]
  );

  return (
    <LinearTable
      data={vms.data}
      columns={vmColumns}
      params={vms.meta}
      onSort={(orderBy, orderType) =>
        changeQuery({ ...query, orderBy, orderType })
      }
      onPageChange={({ page }) => changeQuery({ page })}
      className={cn('overflow-auto', {
        disabled: vms.isRequesting || currentUser.isRequesting,
      })}
      query={query}
      shouldUseQuery={false}
      usePagination
      hasSorting
      useDnD
      ContentUnderHeader={<FolderList />}
      initialTableState={{
        columnPinning: {
          right: ['status'],
        },
      }}
    />
  );
});

export default TablesWrapper;
