import * as React from 'react';
import cn from 'classnames';
import { Dialog, DialogProps, Button, Row, Col, Input, Select } from 'elements';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useFormik } from 'formik';
import {
  INITIAL_RECORDS_FORM_VALUES,
  recordTypesOptions,
  ttlTypeOptions,
  recordTagsOptions,
} from '../constants';
import {
  parseRecordPayloadToSave,
  parseInitialsForForm,
  recordsValidationSchema,
} from '../helpers';
import { IDNSRecord } from 'dns';
import { validateCallback, validator } from 'utils';
import { RecordDetailsEndInputValue } from '../Styled';

type IProps = React.PropsWithChildren<
  DialogProps<any> & {
    t: TFunction;
    isNew: boolean;
    isRequesting: boolean;
    domain: string;
    initialValues: IDNSRecord | null;
  }
>;

const Renderer = (props: IProps) => {
  const {
    t,
    open,
    onClose,
    onSave,
    isNew,
    domain,
    initialValues,
    isRequesting,
  } = props;
  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    handleChange,
    resetForm,
    submitCount,
    setValues,
  } = useFormik({
    initialValues: INITIAL_RECORDS_FORM_VALUES,
    validateOnMount: false,
    validationSchema: recordsValidationSchema(domain),
    onSubmit: (val) =>
      Promise.resolve(onSave && onSave(parseRecordPayloadToSave(val, domain))),
  });

  React.useEffect(() => {
    if (open) {
      const newValues = initialValues
        ? parseInitialsForForm(initialValues, domain, t)
        : INITIAL_RECORDS_FORM_VALUES;

      setValues(newValues);
    } else {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      handleSubmit={handleSubmit}
      open={open}
      fullWidth
      onClose={onClose}
      title={t(
        `services.dns.domains.records.dialog.title.${isNew ? 'create' : 'edit'}`
      )}
      actions={
        <>
          <Button variant="outlined" color="default" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isRequesting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <Input
        className="mb-25"
        inputProps={{
          className: 'pr-0',
        }}
        onChange={handleChange}
        value={values.name}
        name="name"
        label="forms.zone"
        error={!!submitCount && !!errors.name}
        helperText={errors.name}
        helperTextOptions={{ value: 253 }}
        disabled={!isNew}
        endAdornment={
          <RecordDetailsEndInputValue className={cn('pr-10 primary flex')}>
            {values.name.trim() && '.'}
            {domain}
          </RecordDetailsEndInputValue>
        }
      />
      <Row className="mb-25" columnSpacing={2}>
        <Col xs={6}>
          <Select
            isDisabled={!isNew}
            options={recordTypesOptions}
            name="type"
            label={t('forms.recordType') as string}
            value={values.type}
            onChange={(newValue) => setFieldValue('type', newValue)}
          />
        </Col>
        <Col xs={6}>
          <Select
            options={ttlTypeOptions}
            name="ttl"
            label={t('forms.ttl') as string}
            value={values.ttl}
            onChange={(newValue) => setFieldValue('ttl', newValue)}
          />
        </Col>
      </Row>
      <Input
        onChange={handleChange}
        value={values.value}
        name="value"
        label="forms.value"
        error={!!submitCount && !!errors.value}
        helperText={errors.value}
        helperTextOptions={{ value: 255 }}
        className="mb-25"
      />
      {values.type.value === 'MX' && (
        <Row>
          <Col xs={4}>
            <Input
              onChange={validateCallback({ restrict: validator.onlyNumbers })(
                handleChange
              )}
              value={values.priority}
              name="priority"
              label="forms.priority"
              error={!!submitCount && !!errors.priority}
              helperTextOptions={{
                value: errors.priority === 'forms.invalid.min' ? 0 : 9999,
                min: 0,
                max: 9999,
              }}
            />
          </Col>
        </Row>
      )}

      {values.type.value === 'SRV' && (
        <Row columnSpacing={2}>
          <Col xs={4}>
            <Input
              onChange={validateCallback({ restrict: validator.onlyNumbers })(
                handleChange
              )}
              value={values.priority}
              name="priority"
              label="forms.priority"
              error={!!submitCount && !!errors.priority}
              helperText={errors.priority}
              helperTextOptions={{
                value: errors.priority === 'forms.invalid.min' ? 0 : 9999,
                min: 0,
                max: 9999,
              }}
            />
          </Col>
          <Col xs={4}>
            <Input
              onChange={validateCallback({ restrict: validator.onlyNumbers })(
                handleChange
              )}
              value={values.weight}
              name="weight"
              label="forms.weight"
              error={!!submitCount && !!errors.weight}
              helperText={errors.weight}
              helperTextOptions={{
                value: errors.priority === 'forms.invalid.min' ? 0 : 9999,
                min: 0,
                max: 9999,
              }}
            />
          </Col>
          <Col xs={4}>
            <Input
              onChange={validateCallback({ restrict: validator.onlyNumbers })(
                handleChange
              )}
              value={values.port}
              name="port"
              label="forms.port"
              error={!!submitCount && !!errors.port}
              helperText={errors.port}
              helperTextOptions={{
                value: errors.priority === 'forms.invalid.min' ? 0 : 65535,
                min: 0,
                max: 65535,
              }}
            />
          </Col>
        </Row>
      )}

      {values.type.value === 'CAA' && (
        <Row columnSpacing={2}>
          <Col xs={6}>
            <Input
              onChange={validateCallback({ restrict: validator.onlyNumbers })(
                handleChange
              )}
              value={values.flag}
              name="flag"
              label="forms.flag"
              error={!!submitCount && !!errors.flag}
            />
          </Col>
          <Col xs={6}>
            <Select
              onChange={(tag) => setFieldValue('tag', tag)}
              options={recordTagsOptions}
              value={values.tag}
              name="tag"
              label="forms.tag"
              error={!!submitCount && !!errors.tag}
              helperText={errors.tag}
            />
          </Col>
        </Row>
      )}
    </Dialog>
  );
};

const RecordDetailsDialog = withTranslation()(Renderer);

export default RecordDetailsDialog;
