import * as R from 'ramda';
import { getCurrentLang } from './userInfo';

const { pipe, path, split } = R;

export const pathString = pipe(split(/[[\].]/), path);

export const apiPrefix = `${
  process.env.NODE_ENV !== 'development'
    ? process.env.REACT_APP_API_SERVER
    : 'http://localhost:3000'
}/api/v1`;

export const apiFileServerPrefix =
  process.env.NODE_ENV !== 'development'
    ? process.env.REACT_APP_FILE_SERVER
    : 'http://localhost:3000/storage';

export const defaultHeaders = () => {
  const sessionHeaders: any = {};

  if (sessionStorage.getItem('psn')) {
    sessionHeaders['X-JBBF-PARTNER-SHORTNAME'] = sessionStorage.getItem('psn');
  }

  if (sessionStorage.getItem('tsn')) {
    sessionHeaders['X-JBBF-TENANT-SHORTNAME'] = sessionStorage.getItem('tsn');
  }

  const token = localStorage.getItem('_t') || '';
  const lang = getCurrentLang();

  return {
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Content-Type': 'application/json',
    'x-custom-lang': lang,
    ...sessionHeaders,
  };
};
