import { remCalc } from 'utils';
import { PALETTE } from 'enums';
import { TabPanel, styled, Select, MuiIcons, Dialog, Button } from 'elements';

export const STabPaner = styled(TabPanel)(({ theme }) => ({
  minHeight: remCalc(570),
}));

export const SkipText = styled('p')(({ theme }) => ({
  color: PALETTE.steel,
  fontSize: remCalc(14),
  maxWidth: remCalc(400),
}));

export const AccountSelect = styled(Select)(({ theme }) => ({
  minWidth: remCalc(210),
}));

export const BurgerIcon = styled(MuiIcons.MoreVert)(({ theme }) => ({
  marginLeft: remCalc(15),
  color: PALETTE.steel,
  fontSize: remCalc(20),
  cursor: 'pointer',
}));

export const SDialog = styled(Dialog)(({ theme }) => ({
  '& ._paper': {
    maxWidth: remCalc(680),
  },
}));

export const ClearButton = styled(Button)(({ theme }) => ({
  height: remCalc(48),
  width: '100%',
}));
