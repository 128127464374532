import * as React from 'react';
import { MuiIcons, IconButton, createColumnHelper } from 'elements';

type IZoneInput = { domain: string; id: number };

const column = createColumnHelper<IZoneInput>();

export const getZonesInputsColumns = (props: any) => [
  column.accessor('domain', {
    header: <div className="fs-12 steel">{props.t('forms.domainName')}</div>,
    disableSort: true,
  }),

  column.accessor('id', {
    header: '',
    disableSort: true,
    width: 25,
    cell: ({ row }: any) => (
      <div className="full-width lh-1 flex justify-end">
        <IconButton
          onClick={() => props.onDelete(row.original.id)}
          size="small"
        >
          <MuiIcons.Delete className="fs-22 pointer steel" />
        </IconButton>
      </div>
    ),
  }),
];
