import * as Yup from 'yup';
import { ValueType } from 'global-shapes';
import { IEmployeeType } from 'employees';
import BoldText from 'assets/images/icons/ic-text-bold.svg';
import UnderlineText from 'assets/images/icons/ic-text-underline.svg';
import ItalicText from 'assets/images/icons/ic-text-italic.svg';
import NumberListText from 'assets/images/icons/ic-text-number.svg';
import BulletListText from 'assets/images/icons/ic-text-bulet.svg';
import LinkText from 'assets/images/icons/ic-text-link.svg';
// @ts-ignore
import { convertToHTML } from 'draft-convert';

export const TENANT_REQ_CONFIG = {
  headers: { 'X-JBBF-TENANT-SHORTNAME': undefined },
};

export const EMPLOYEE_TYPES_OPTIONS: ValueType<IEmployeeType>[] = [
  {
    value: 'EMPLOYEE',
    label: 'EMPLOYEE',
  },
  {
    value: 'SUPPLIER',
    label: 'SUPPLIER',
  },
];

export const TOOLBAR_EDITOR_OPTIONS = {
  options: ['inline', 'list', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: { icon: BoldText },
    underline: { icon: UnderlineText },
    italic: { icon: ItalicText },
  },
  list: {
    options: ['ordered', 'unordered'],
    ordered: { icon: NumberListText },
    unordered: { icon: BulletListText },
  },
  link: {
    options: ['link'],
    link: { icon: LinkText },
    defaultTargetOption: '_blank',
  },
};

export const NotesYupValidationSchema = Yup.object()
  .test({
    message: 'forms.invalid',
    test: (val: any) => {
      const value = convertToHTML(val.getCurrentContent());
      return !(value.length > 15000);
    },
  });
