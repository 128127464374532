import { useMemo } from 'react';
import { AccessLevelLow, PermissionsShape } from 'global-shapes';
import { PermissionsMap, Permission, PermissionList } from 'auth-shapes';
import { ACCESS_LEVELS } from 'enums';
import { useAccount } from './useAccount';

const DEFAULT_PERMISSION: Permission = {
  canView: false,
  canManage: false,
  resource: 'NONE',
};

export function usePermissions(namespace?: PermissionList): PermissionsShape {
  const [currentUser, , dataReceived] = useAccount();

  const accessLevel: AccessLevelLow = currentUser
    ? ACCESS_LEVELS[currentUser?.accessLevel]
    : 'provider';

  const currentAccessLevel = useMemo(() => {
    if (currentUser?.tenant) {
      return 'tenant';
    }

    if (currentUser?.partner) {
      return 'partner';
    }

    return 'provider';
  }, [JSON.stringify(currentUser)]);

  const isTenant = accessLevel === 'tenant';
  const isPartner = accessLevel === 'partner';
  const isProvider = accessLevel === 'provider';

  const isCurrentTenant = currentAccessLevel === 'tenant';
  const isCurrentPartner = currentAccessLevel === 'partner';
  const isCurrentProvider = currentAccessLevel === 'provider';

  const permissions = (currentUser?.permissions as PermissionsMap) || {};

  const isServerBase = useMemo(() => {
    if (isCurrentProvider) return true;
    if (isCurrentTenant) return currentUser?.partner?.isProtected || false;
    if (isCurrentPartner) return currentUser?.partner?.isProtected || false;
    return false;
  }, [
    isCurrentProvider,
    isCurrentPartner,
    isCurrentTenant,
    JSON.stringify(currentUser),
  ]);

  const isPendingEvaluation =
    currentUser?.partner?.status === 'PENDING_EVALUATION';

  const isEvaluation: boolean = useMemo(() => {
    return (
      currentUser?.partner?.status === 'EVALUATION' ||
      isPendingEvaluation ||
      currentUser?.partner?.status === 'EVALUATION_EXPIRED'
    );
  }, [JSON.stringify(currentUser), isPendingEvaluation]);

  const isExpiredAccount: boolean = useMemo(() => {
    return currentUser?.partner?.status === 'EVALUATION_EXPIRED';
  }, [JSON.stringify(currentUser)]);

  const isSwitchToProdRequested: boolean = useMemo(() => {
    if (isPartner) {
      return !!currentUser?.partner?.switchToProdRequestedAt;
    }

    return false;
  }, [isPartner, dataReceived, JSON.stringify(currentUser)]);

  const isDeleted = useMemo(() => {
    if (isCurrentPartner) {
      return currentUser?.partner?.isDeleted;
    }
    if (isCurrentTenant) {
      return currentUser?.tenant?.isDeleted;
    }
    return false;
  }, [JSON.stringify(currentUser)]) as boolean;

  const task = useMemo(() => {
    if (isCurrentPartner) {
      return (
        currentUser?.partner?.task || {
          entityName: 'Partner',
          entityId: currentUser?.partner?.id,
          status: 'none',
        }
      );
    }

    if (isCurrentTenant) {
      return (
        currentUser?.tenant?.task || {
          entityName: 'Tenant',
          entityId: currentUser?.tenant?.id,
          status: 'none',
        }
      );
    }

    return undefined;
  }, [JSON.stringify(currentUser)]) as Task;

  return {
    permissionsReceived: dataReceived,
    permissions: namespace
      ? permissions[namespace] || DEFAULT_PERMISSION
      : DEFAULT_PERMISSION,
    permissionsAll: permissions,
    accessLevel,
    isAdmin: currentUser?.role?.isDefault || false,
    accessLevelOriginal: currentUser?.accessLevel || 'PROVIDER',
    currentAccessLevel,
    isServerBase,
    isTenant,
    isPartner,
    isProvider,
    isCurrentTenant,
    isCurrentPartner,
    isCurrentProvider,
    isEvaluation,
    isPendingEvaluation,
    isExpiredAccount,
    isSwitchToProdRequested,
    isDeleted,
    task,
  };
}
