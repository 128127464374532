import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LinearTable, Input } from 'elements';
import { useState } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import * as ENUMS from '../constants';
import * as HELPERS from '../helpers';

type IProps = {
  connectivityId?: number;
  showAll: boolean;
  onEdit: (rule: IConnectivityTypes.IFirewallRule) => void;
  onDelete: (rule: IConnectivityTypes.IFirewallRule) => void;
};

const OBSERVERS = {
  firewallRules: StateHandlers.firewallRules,
};

type IViewProps = typeof OBSERVERS;

type IQuery = {
  q: string;
  orderBy: string;
  orderType: OrderTypes;
  action: string[];
  isEnabled: string[];
};

const initialQuery: IQuery = {
  q: '',
  orderBy: 'name',
  orderType: 'asc',
  action: [],
  isEnabled: [],
};

const View = observer((props: IProps & IViewProps) => {
  const { connectivityId, firewallRules, onDelete, onEdit, showAll } = props;
  const searchRef = React.useRef({ value: '' });
  const { t } = useTranslation();

  const [query, handleQuery] = useState<IQuery>(initialQuery);

  React.useEffect(() => {
    firewallRules.get({ connectivityId });
  }, [connectivityId]);

  const filteredData = HELPERS.filterFRs(
    { ...query, q: searchRef.current?.value },
    firewallRules.data
  );

  const data = showAll ? filteredData : filteredData.slice(0, 10);

  return (
    <>
      <div className="mb-15">
        <Input
          placeholder={t('forms.placeholders.search')}
          type="search"
          ref={searchRef}
          onChange={(ev) => handleQuery({ q: ev.target.value })}
          onClear={() => {
            // @ts-ignore
            searchRef.current.value = '';
            handleQuery({ q: '' });
          }}
        />
      </div>
      <LinearTable
        data={data}
        columns={ENUMS.getNetworkRulesColumns({
          t,
          onDelete,
          onEdit,
          query,
          onChangeQuery: (key, val) => handleQuery({ [key]: val }),
        })}
        onUpdateTable={() => firewallRules.get({ connectivityId })}
        isLoading={!firewallRules.dataReceived}
      />
    </>
  );
});

const FirewallRulesContent = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default FirewallRulesContent;
