import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { IActiveService } from 'active-service';
import { LightTooltip } from './Tooltip';

export function ActiveServiceLayout(props: {
  services: IActiveService[];
  instance: 'core' | 'rds' | 'vm' | number | string;
}) {
  const { t } = useTranslation();
  return (
    <div className="flex align-center lh-1 mb-5">
      {props.services.map((el, ind) => {
        const Icon = el.icon as any;

        if (el.tooltipText) {
          const title = Array.isArray(el.tooltipText) ? (
            <div>
              {el.tooltipText.map((text) => (
                <div key={text}>- {text}</div>
              ))}
            </div>
          ) : (
            t(el.tooltipText)
          );

          return (
            <LightTooltip
              title={title}
              placement="top"
              arrow
              key={`active-service-core-el-${ind}`}
            >
              <div
                data-draggable="ignore"
                className="_with_inner_layer mr-10 pointer"
                onClick={(ev: any) => {
                  ev.stopPropagation();
                  el.onClick && el.onClick();
                }}
              >
                <Icon
                  className={cn('fs-20 relative', el.className)}
                  style={{ height: 18, ...el.style }}
                  status={el.status}
                />
              </div>
            </LightTooltip>
          );
        }

        return (
          <div
            data-draggable="ignore"
            className="_with_inner_layer mr-10 pointer"
            onClick={(ev: any) => {
              ev.stopPropagation();
              el.onClick && el.onClick();
            }}
          >
            <Icon
              key={`active-service-${props.instance}-el-${ind}`}
              className={cn('fs-20 relative', el.className)}
              style={{ height: 18, ...el.style }}
              status={el.status}
            />
          </div>
        );
      })}
    </div>
  );
}
