import * as React from 'react';
import { Permission } from 'auth-shapes';
import { useTranslation } from 'react-i18next';
import { Row, Col, Switch, Button, Loader } from 'elements';
import { showSystemMessage, confirm } from 'utils';
import StripeLogo from 'assets/images/stripe-logo.svg';
import { useStateHandler } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

type IProps = {
  permissions: Permission;
};

type IButtonProps = {
  hasStripeAccount: boolean;
  isConnecting: boolean;
  stripeEnabled: boolean;
  isRequesting: boolean;
  disabled?: boolean;
  handleStripeAccountEnable: (enable: boolean) => void;
  onCreateAccount: () => void;
  onAccountCreationContinue: () => void;
};

const ConnectionButton = (props: IButtonProps) => {
  const {
    handleStripeAccountEnable,
    stripeEnabled,
    onAccountCreationContinue,
    onCreateAccount,
    isConnecting,
    hasStripeAccount,
    isRequesting,
    disabled,
  } = props;
  const { t } = useTranslation();

  if (!hasStripeAccount) {
    return (
      <div className="flex">
        {isRequesting && <Loader size={15} />}

        <Button
          onClick={isRequesting ? undefined : onCreateAccount}
          size="small"
          className="ml-10"
          disabled={disabled}
        >
          {t('settings.page.customer-invoice.stripe.buttons.createAccount')}
        </Button>
      </div>
    );
  }

  if (isConnecting) {
    return (
      <div className="flex">
        {isRequesting && <Loader size={15} />}
        <Button
          onClick={isRequesting ? undefined : onAccountCreationContinue}
          size="small"
          className="ml-10"
          disabled={disabled}
        >
          {t('settings.page.customer-invoice.stripe.buttons.pendingAccount')}
        </Button>
      </div>
    );
  }

  return (
    <Switch
      checked={stripeEnabled}
      onCheck={handleStripeAccountEnable}
      disabled={isRequesting || disabled}
    />
  );
};

const StripeConnection = observer((props: IProps) => {
  const { permissions } = props;
  const { t } = useTranslation();
  const currentUser = useStateHandler(StateHandlers.currentUser);
  const currentPartner = useStateHandler(StateHandlers.currentPartner);
  const stripe = useStateHandler(StateHandlers.stripe);

  const partnerId = currentUser.data?.partner?.id;
  const invoiceSettings = currentUser.data?.partner?.invoiceSettings;

  const stripeEnabled = invoiceSettings?.stripePaymentsEnabled;
  const hasStripeAccount = !!currentUser.data?.partner?.stripeAccountId;
  const isConnecting =
    currentUser.data?.partner?.stripeAccountConnectionStatus === 'PENDING';

  const createStripeAccount = React.useCallback(async () => {
    try {
      const payload = {
        returnUrlPath:
          process.env.REACT_APP_CLIENT_SERVER + window.location.pathname,
        refreshUrlPath:
          process.env.REACT_APP_CLIENT_SERVER + window.location.pathname,
      };

      const res = await stripe.executeRequest('createAccount')(
        currentUser.data?.partner?.id,
        payload
      );

      window.location.href = res?.data?.url;
      return res;
    } catch (error: any) {
      showSystemMessage(error.message, 'error');
    }
  }, []);

  const continueStripeAccountCreation = React.useCallback(async () => {
    try {
      const payload = {
        returnUrlPath:
          process.env.REACT_APP_CLIENT_SERVER + window.location.pathname,
        refreshUrlPath:
          process.env.REACT_APP_CLIENT_SERVER + window.location.pathname,
      };

      const res = await stripe.executeRequest('refreshActiveAccountSession')(
        currentUser.data?.partner?.id,
        payload
      );

      window.location.href = res?.data?.url;
      return res;
    } catch (error: any) {
      showSystemMessage(error.message, 'error');
    }
  }, []);

  const handleStripeAccountEnable = React.useCallback(
    (stripePaymentsEnabled: boolean) => {
      confirm({
        title: `settings.page.customer-invoice.stripe.confirm.${
          stripePaymentsEnabled ? 'enable' : 'disable'
        }.title`,
        content: `settings.page.customer-invoice.stripe.confirm.${
          stripePaymentsEnabled ? 'enable' : 'disable'
        }.content`,
        onSuccess: () =>
          currentPartner
            .executeRequest('updateInvoiceSettings')(partnerId, {
              ...invoiceSettings,
              stripePaymentsEnabled,
            })
            .then((res) => {
              showSystemMessage(
                `settings.page.customer-invoice.notify.stripe.${
                  stripePaymentsEnabled ? 'connected' : 'disconnected'
                }`,
                'success'
              );
              return currentUser.get({ shouldApplyTheme: false });
            })
            .catch((err) => {
              showSystemMessage(err.message, 'error');
            }),
        onCancel: () => undefined,
      });
    },
    [
      hasStripeAccount,
      process.env.REACT_APP_CLIENT_SERVER,
      window.location.pathname,
      invoiceSettings,
      partnerId,
    ]
  );

  const descriptionString = React.useMemo(() => {
    if (!hasStripeAccount) {
      return 'settings.page.customer-invoice.stripe.description.createAccount';
    }

    if (isConnecting) {
      return 'settings.page.customer-invoice.stripe.description.pendingAccount';
    }

    return 'settings.page.customer-invoice.stripe.description';
  }, [hasStripeAccount, isConnecting]);

  return (
    <div>
      <div className="bold mb-20">
        {t('settings.page.customer-invoice.stripe.title')}
      </div>
      <Row columnSpacing={3} alignItems="center">
        <Col>
          <img src={StripeLogo} alt="" width={100} height={45} />
        </Col>
        <Col xs className="steel">
          {t(descriptionString)}
        </Col>

        <Col>
          <ConnectionButton
            stripeEnabled={!!stripeEnabled}
            isRequesting={currentUser.isRequesting || stripe.isRequesting}
            handleStripeAccountEnable={handleStripeAccountEnable}
            hasStripeAccount={hasStripeAccount}
            isConnecting={isConnecting}
            onCreateAccount={createStripeAccount}
            onAccountCreationContinue={continueStripeAccountCreation}
            disabled={!permissions.canManage}
          />
        </Col>
      </Row>
    </div>
  );
});

export default StripeConnection;
