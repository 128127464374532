import * as React from 'react';
// import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { Row, Col, MuiIcons, Popover } from 'elements';
import { useState } from 'hooks';
import { ICostPerServiceWithResources } from 'usage';
import { numberToCurrency, compact, toggleArrayState } from 'utils';
import { RECOURCE_UNIT_MAP } from './constants';
import { createPeriodStr } from './helpers';
import FilterChip, { FilterItemText } from './FilterChip';
import { PriceDetailsListItem, ServiceBlockWrapper } from './Styled';

type IProps = ICostPerServiceWithResources & {
  cost: IUsageInfoBoxTypes.IServiceBlockCostProp;
};

const UsageServiceBlock = (props: IProps) => {
  const {
    service,
    entities,
    hasSubEntities,
    showFilters,
    resources,
    // cost,
  } = props;
  const { t } = useTranslation();
  const initialSelected = showFilters
    ? compact(entities.map((ent) => ent.appEntity?.id))
    : [];
  const [state, handleState] = useState({
    selected: initialSelected,
    anchorEl: null,
  });

  const handleClick = React.useCallback((event: React.MouseEvent<any>) => {
    handleState({ anchorEl: event.currentTarget });
  }, []);

  const selectedEntChipsData = compact(
    state.selected.map((id) => {
      const foundEnts = entities.find((ent) => ent.appEntity.id === id);
      return foundEnts;
    })
  );

  return (
    <ServiceBlockWrapper>
      <Row alignItems="center" columnSpacing={1} className="mb-10">
        <Col xs={10}>
          <Row alignItems="center" columnSpacing={1}>
            <Col className="bold">
              {t(`usage.serviceName.${service.serviceId}`)}
            </Col>
            {showFilters && (
              <Col>
                <div className="flex align-center">
                  <MuiIcons.FilterAlt
                    className="fs-18 steel pointer mr-10"
                    onClick={handleClick}
                  />

                  {selectedEntChipsData.map((ent) => (
                    <FilterChip
                      key={+new Date() + ent.appEntity.id}
                      id={ent.appEntity.id}
                      text={ent.appEntity.name}
                      onClear={(id) =>
                        handleState({
                          selected: toggleArrayState(state.selected, id),
                        })
                      }
                    />
                  ))}
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={2} className="steel">
          {numberToCurrency(service?.total || 0, false)}
        </Col>
      </Row>
      {!hasSubEntities &&
        resources.map((resource, ind) => {
          const recourceUnit =
            resource.serviceResourceValue > 1
              ? `, ${resource.serviceResourceValue} ${
                  RECOURCE_UNIT_MAP[resource.resourceId]
                }`
              : '';
          const period = createPeriodStr(resource.startDate, resource.endDate);

          return (
            <Row
              alignItems="center"
              className="mb-10"
              key={+new Date() + ind}
              columnSpacing={1}
            >
              <Col xs={10}>
                <PriceDetailsListItem>
                  {resource.resourceTitle}
                  {recourceUnit} {period}
                </PriceDetailsListItem>
              </Col>
              <Col xs={2} className="uppercase steel">
                {numberToCurrency(resource.cost, false)}
              </Col>
            </Row>
          );
        })}
      {hasSubEntities &&
        entities.map((ent, i) => {
          const showServiceData = state.selected.includes(ent.appEntity.id);
          if (!showServiceData) return null;

          return (
            <div key={ent.appEntity.name + ent.appEntity.id + +new Date() + i}>
              <Row
                alignItems="center"
                className="mb-10 pt-10"
                columnSpacing={1}
              >
                <Col xs={10}>{ent.appEntity.name}</Col>
                <Col xs={2} className="uppercase steel">
                  {numberToCurrency(ent.total, false)}
                </Col>
              </Row>

              {ent.costPerResource.map((resource, ind) => {
                const period = createPeriodStr(
                  resource.startDate,
                  resource.endDate
                );
                const recourceUnit =
                  resource.serviceResourceValue > 1
                    ? `, ${resource.serviceResourceValue} ${
                        RECOURCE_UNIT_MAP[resource.resourceId]
                      }`
                    : '';

                return (
                  <Row
                    alignItems="center"
                    className="mb-10"
                    columnSpacing={1}
                    key={+new Date() + ind}
                  >
                    <Col xs={10}>
                      <PriceDetailsListItem>
                        {resource.resourceTitle}
                        {recourceUnit} {period}
                      </PriceDetailsListItem>
                    </Col>
                    <Col xs={2} className="uppercase steel">
                      {numberToCurrency(resource.cost, false)}
                    </Col>
                  </Row>
                );
              })}
            </div>
          );
        })}
      <Popover
        id={service.serviceId.toString()}
        open={!!state.anchorEl}
        anchorEl={state.anchorEl}
        onClose={() => {
          handleState({ anchorEl: null });
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="pt-15 pb-10">
          {(hasSubEntities ? entities : []).map((ent) => {
            return (
              <FilterItemText
                key={ent.appEntity.id}
                isActive={state.selected.includes(ent.appEntity.id)}
                text={ent.appEntity.name}
                onClick={() =>
                  handleState({
                    selected: toggleArrayState(
                      state.selected,
                      ent.appEntity.id
                    ),
                  })
                }
              />
            );
          })}
        </div>
      </Popover>
    </ServiceBlockWrapper>
  );
};

export default UsageServiceBlock;
