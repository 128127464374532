import * as React from 'react';
import { AnyFunc, AnyShape } from 'global-shapes';
import dayjs from 'dayjs';
import { IBackupVersion } from 'backup';
import { round } from 'utils';
import { Tooltip, Button, createColumnHelper } from 'elements';
import StatusIcon from './StatusIcon';

const defineDiskSizeDisplay = (sizeGb: number) => {
  if (sizeGb > 1024) return `${round(sizeGb / 1024, 1)} TB`;
  if (sizeGb < 1) return `${round(sizeGb * 1024, 1)} MB`;
  return `${round(sizeGb, 1)} GB`;
};

const MAPPED_STATUS: AnyShape = {
  FAILED: 'error',
  NONE: 'processing',
  SUCCESS: 'success',
  WARNING: 'warning',
};

type VersionTableConfig = {
  t: AnyFunc;
  onRestore: (version: IBackupVersion) => void;
  isEditable: boolean;
  isRequestingVms: boolean;
};

const column = createColumnHelper<IBackupVersion>();

export const backupVersionsColumn = (props: VersionTableConfig) => {
  const { t, onRestore, isRequestingVms, isEditable } = props;
  return [
    column.accessor('creationDate', {
      header: (
        <span className="steel fs-12 weight-normal">
          {t('table.head.restorePoint')}
        </span>
      ),
      disableSort: true,
      cell: ({ row }: ICell<IBackupVersion>) =>
        dayjs(row.original.creationDate).format('DD.MM.YYYY HH:mm:ss'),
    }),

    column.accessor('type', {
      header: (
        <span className="steel fs-12 weight-normal">
          {t('table.head.type')}
        </span>
      ),
      disableSort: true,
    }),

    column.accessor('sizeGb', {
      header: (
        <span className="steel fs-12 weight-normal">
          {t('table.head.size')}
        </span>
      ),
      disableSort: true,
      cell: ({ row }: ICell<IBackupVersion>) =>
        defineDiskSizeDisplay(row.original.sizeGb),
    }),

    column.accessor('backupJob', {
      header: (
        <span className="steel fs-12 weight-normal">
          {t('table.head.jobType')}
        </span>
      ),
      disableSort: true,
      cell: ({ row }: ICell<IBackupVersion>) =>
        t(`services.backup.versions.jobType.${row.original.backupJob?.type}`),
    }),

    column.accessor('status', {
      header: (
        <span className="steel fs-12 weight-normal">
          {t('table.head.considerStatus')}
        </span>
      ),
      disableSort: true,
      cell: ({ row: { original: backup } }: ICell<IBackupVersion>) => {
        const status = MAPPED_STATUS[backup.status];
        if (backup.status === 'FAILED' && backup.errorMessage) {
          return (
            <Tooltip title={backup.errorMessage} placement="top" arrow>
              <StatusIcon status={status} />
            </Tooltip>
          );
        }

        return <StatusIcon status={status} />;
      },
    }),

    column.accessor('progress', {
      header: <span />,
      disableSort: true,
      cell: ({ row }: ICell<IBackupVersion>) => {
        const disabled = !['SUCCESS', 'WARNING'].includes(row.original.status);
        if (isEditable) {
          return (
            <div className="flex justify-end full-width">
              <Button
                size="small"
                onClick={() => !disabled && onRestore(row.original)}
                disabled={isRequestingVms || disabled}
              >
                {t('services.backup.versions.table.buttons.restore')}
              </Button>
            </div>
          );
        }
        return null;
      },
    }),
  ];
};
