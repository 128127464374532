import * as React from 'react';
import { CustomEvents } from 'enums';
import { EventTask } from 'ws-service';
import { useStateHandler } from 'hooks';

import { reformatTaskKey, taskSubscriber } from 'utils/taskSubscriber';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import * as TaskHandlers from 'states/taskManager';
import * as AccountHandlers from 'states/currentUser';
import * as CoreHandlers from 'states/core';
import * as VmsHandlers from 'states/vms';
import * as RdsHandlers from 'states/rds';
import * as DnsHandlers from 'states/dns';
import { Emitter } from 'utils/Emitter';

export const fetchTaskList = () => TaskHandlers.taskManager.get();

export const mergeTask = (task: EventTask) =>
  TaskHandlers.taskManager.mergeById(reformatTaskKey(task), task as any);

export const refreshAccount = () =>
  AccountHandlers.currentUser.get({ shouldApplyTheme: false });

export const updateTask = (task: EventTask) =>
  TaskHandlers.taskManager.updateById(reformatTaskKey(task), task);

export const updateListTask = (task: EventTask) =>
  TaskHandlers.taskManagerList.updateById(task.id, task);

export const updateCoreInfrastructure = () => CoreHandlers.core.get();

export const updateRds = () => RdsHandlers.rds.get();

export const updateDns = () => DnsHandlers.dns.get();

export const updateVms = (taskId: number) =>
  VmsHandlers.vms.reload(taskId, {
    include: ['serviceMonitoring', 'serviceSnapshot'],
  });

export function withSocketEvents(Component: any) {
  return observer((props: any) => {
    const vmDetails = useStateHandler(VmsHandlers.vmDetails);

    const updateTask = React.useCallback((task: EventTask) => {
      mergeTask(task);

      if (!task.isBackground) {
        fetchTaskList();

        taskSubscriber.register(task);
        taskSubscriber.updateTask(task);
      }
    }, []);

    const updateTaskLIst = React.useCallback((task: EventTask) => {
      updateListTask(task);
    }, []);

    const updateVm = React.useCallback(
      (task: Task) => {
        updateVms(task.id);

        if (vmDetails.data?.id === task.id) {
          vmDetails.get({
            id: task.id,
            include: ['serviceMonitoring', 'serviceSnapshot'],
          });
        }
      },
      [toJS(vmDetails.data)]
    );

    const refetchCore = React.useCallback(() => {
      updateCoreInfrastructure();
    }, []);

    const refetchRds = React.useCallback(() => {
      updateRds();
    }, []);

    React.useEffect(() => {
      Emitter.on(CustomEvents.updateTask, updateTask);
      Emitter.on(CustomEvents.updateTaskList, updateTaskLIst);
      Emitter.on(CustomEvents.updateVm, updateVm);
      Emitter.on(CustomEvents.refetchRds, refetchRds);
      Emitter.on(CustomEvents.refetchCore, refetchCore);
      Emitter.on(CustomEvents.refreshAccount, refreshAccount);
      return () => {
        Emitter.off(CustomEvents.updateTask, updateTask);
        Emitter.off(CustomEvents.updateTaskList, updateTaskLIst);
        Emitter.off(CustomEvents.updateVm, updateVm);
        Emitter.off(CustomEvents.refetchRds, refetchRds);
        Emitter.off(CustomEvents.refetchCore, refetchCore);
        Emitter.off(CustomEvents.refreshAccount, refreshAccount);
      };
    }, []);

    return <Component {...props} />;
  });
}
