import React, { useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { STATUS_NAMESPACES_MAP } from 'enums';
import { remCalc, isString } from 'utils';
import { HorizontalBadges } from 'components';
import { Row, Col } from 'elements';
import StatusIdentifier from '../StatusIdentifier';
import EmptyCard from './contents/EmptyCard';
import ToDeployCard from './contents/ToDeployCard';
import { useServiceStatus, usePermissions } from 'hooks';
import CardMenuItems from './CardMenuItems';
import {
  CardHolderRoot,
  HolderBadge,
  HolderFooter,
  SStatusLine,
  Skeleton,
} from './Styled';

const CardHolder = React.forwardRef((props: ICardProps, ref) => {
  const {
    createId,
    title,
    otherTitleContent,
    status,
    className,
    isEmpty,
    onAdd,
    placeholder,
    isDisabled,
    onTitleClick,
    menuItems,
    testId,
    waitingToDeploy,
    hasError,
    dataReceived,
    titleIcon,
    cornerBadge,
    assignments,
    shouldDisablePartially,
  } = props;

  const { t } = useTranslation();
  const isErrorCard: boolean = useMemo(
    () => hasError || STATUS_NAMESPACES_MAP.error.includes(status as string),
    [status, hasError]
  );
  const { permissions } = usePermissions('SERVICES');
  const { status: displayStatus, inProgress } = useServiceStatus(
    isErrorCard ? 'UNKNOWN' : status || 'none'
  );

  const disableTitleIcons = shouldDisablePartially ? false : isDisabled;

  if (!dataReceived) {
    return <Skeleton animation="wave" variant="rectangular" />;
  }

  return (
    <CardHolderRoot
      isError={isEmpty ? false : isErrorCard}
      waitingToDeploy={waitingToDeploy}
      className={className}
      isDisabled={isEmpty || status === 'delayed'}
      data-test-id={cn({
        [`${testId}-card-empty`]: isEmpty,
        [`${testId}-card`]: !isEmpty,
        [`${testId}-card-waiting-to-deploy`]: waitingToDeploy,
        [`${testId}-card-disabled`]: isDisabled || status === 'delayed',
      })}
      ref={ref}
    >
      {cornerBadge && (
        <HolderBadge>
          <div className="relative text-right full-width">{cornerBadge}</div>
        </HolderBadge>
      )}
      {isEmpty && (
        <EmptyCard
          id={createId}
          title={title}
          placeholder={placeholder}
          onAdd={onAdd}
          isEditable={permissions.canManage}
          isDisabled={isDisabled}
          data-test-id={`${testId}-card-empty-icon`}
        />
      )}

      {waitingToDeploy && (
        <ToDeployCard
          title={title}
          placeholder={placeholder}
          data-test-id={`${testId}-card-waiting-to-deploy`}
        />
      )}
      {!isEmpty && !waitingToDeploy && (
        <div>
          <div className={cn('full-width')}>
            <Row
              justifyContent="space-between"
              alignItems="center"
              className="mb-10 lh-1"
              
            >
              <div
                className={cn('flex align-center relative', {
                  disabled: isDisabled,
                })}
              >
                {titleIcon}
                <h5
                  onClick={onTitleClick}
                  className={cn(onTitleClick && 'pointer')}
                  data-draggable="ignore"
                >
                  {isString(title) ? t(title) : title}
                </h5>
              </div>
              <div>
                <Row
                  alignItems="center"
                  justifyContent="flex-end"
                  columnSpacing={1}
                  style={{ transform: 'translateX(10px)' }}
                  className={cn({ disabled: disableTitleIcons })}
                >
                  {otherTitleContent}
                  {!!menuItems?.length && (
                    <CardMenuItems items={menuItems} testId={testId} />
                  )}
                </Row>
              </div>
            </Row>
            <div
              style={{ minHeight: remCalc(250) }}
              className={cn({ disabled: isDisabled })}
            >
              {props.children}
            </div>
          </div>

          <HolderFooter>
            <Row justifyContent="space-between" className="full-width">
              <Col>
                <StatusIdentifier
                  status={displayStatus}
                  inProgress={inProgress}
                />
              </Col>
            </Row>
            <HorizontalBadges size={16} assignments={assignments} />
          </HolderFooter>
        </div>
      )}
      <SStatusLine status={displayStatus} />
    </CardHolderRoot>
  );
});

export default CardHolder;
