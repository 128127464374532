export const AUTHORIZED_ROUTES: string[] = [
  'users',
  'partners',
  'partner-pricing',
  'services',
  'vm',
  'dedicated',
  'roles',
  'tenants',
  'invoices',
  'billable-items',
  'usage',
  'settings',
  'tenant-pricing',
];

export const UNPROTECTED_ROUTE_NAMES: string[] = [
  '/login',
  '/invite',
  '/customer-sign-up',
  '/2fa-setup',
  '/404',
  '/verify',
  '/sign-up',
  '/create-password',
  '/reset-password',
  '/request-reset-password',
  '/restore-password',
  '/verify-code',
  '/sign-under-user',
];
