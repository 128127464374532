import { GET, POST, PATCH, DELETE, buildListParams } from 'utils';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';

export const getFolders = (
  params: any
): IAxiosPromise<IServiceGroups.Group[]> => {
  const { tenantId, ...rest } = params;
  return GET(
    `/tenants/${tenantId}/service-groups`,
    buildListParams({ ...rest, page: 1, perPage: 1000 }, [
      'q',
      'orderType',
      'orderBy',
      'type',
    ])
  );
};

export const createFolder = (
  tenantId: number,
  data: IServiceGroups.GroupDetailsFormValues
): AxiosPromise<IServiceGroups.Group> => {
  const { type, ...values } = data;

  const payload: IServiceGroups.CreatePayload = {
    name: values.name.trim(),
    description: values.description.trim(),
    type: type.value,
  };

  return POST(`/tenants/${tenantId}/service-groups`, {}, payload);
};

export const updateFolder = (
  tenantId: number,
  groupId: number,
  data: IServiceGroups.GroupDetailsFormValues
): AxiosPromise<IServiceGroups.Group> => {
  const payload: IServiceGroups.UpdatePayload = {
    name: data.name.trim(),
    description: data.description.trim(),
  };

  return PATCH(`/tenants/${tenantId}/service-groups/${groupId}`, {}, payload);
};

export const removeFolder = (
  tenantId: number,
  groupId: number
): AxiosPromise<IServiceGroups.Group> => {
  return DELETE(`/tenants/${tenantId}/service-groups/${groupId}`);
};

export const moveToFolder = (
  tenantId: number,
  groupId: number,
  payload: IServiceGroups.UpdateServiceGroupItemPayload
): AxiosPromise<IServiceGroups.Group> => {
  return POST(
    `/tenants/${tenantId}/service-groups/${groupId}/services`,
    {},
    payload
  );
};
export const removeFromFolder = (
  tenantId: number,
  groupId: number,
  payload: IServiceGroups.UpdateServiceGroupItemPayload
): AxiosPromise<IServiceGroups.Group> => {
  return DELETE(
    `/tenants/${tenantId}/service-groups/${groupId}/services`,
    {},
    payload
  );
};
