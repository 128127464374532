import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogProps, Button, Row, Col } from 'elements';
import { observer } from 'mobx-react-lite';
import FirewallRulesContent from './FirewallRulesContent';

type Props = DialogProps & {
  connectivityId?: number;
  onEdit: (rule: IConnectivityTypes.IFirewallRule) => void;
  onDelete: (rule: IConnectivityTypes.IFirewallRule) => void;
};

const FirewallRulesDialog = observer((props: Props) => {
  const { t } = useTranslation();
  const { open, onClose, onEdit, onDelete, connectivityId } = props;

  return (
    <Dialog
      title={t('services.connectivity.firewallRules.dialog.all.title')}
      maxWidth="md"
      fullWidth
      fullScreen
      showCloseIcon
      open={open}
      onClose={onClose}
      contentProps={{
        style: {
          minHeight: 'calc(100vh - 220px)',
        },
      }}
      actions={
        <Row alignItems="center" justifyContent="flex-end">
          <Col>
            <Button color="default" variant="outlined" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
        </Row>
      }
    >
      <FirewallRulesContent
        connectivityId={connectivityId}
        onDelete={onDelete}
        onEdit={onEdit}
        showAll
      />
    </Dialog>
  );
});

export default FirewallRulesDialog;
