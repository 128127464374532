import * as React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { ALL_TEST_IDS } from 'enums';
import { Row, Col, useMediaQuery } from 'elements';
import { initTagManager, getCurrentLang, changeLang } from 'utils';
import Logo from 'assets/images/logo-full-dark.svg';
import MobileLogo from 'assets/images/mobile-dark-logo.svg';
import Waves from 'assets/images/sign-up-waves.svg';
import { useAsync, useQuery, useState } from 'hooks';
import { ISignupPartnerBodyDto, ICameFromOption } from 'auth-shapes';
import { ILanguage, ValueType } from 'global-shapes';
import { authService } from 'services';
import { useTranslation } from 'react-i18next';
import UserInfoForm from './UserInfoForm';
import CompanyInfoForm from './CompanyInfoForm';
import {
  USER_INITIAL_FORM_VALUES,
  COMPANY_INITIAL_FORM_VALUES,
} from './constants';
import {
  AuthFooterLink,
  AuthLeftContent,
  AuthSignUpMainColRight,
  AuthMainColWrapper,
  AuthMobileLogo,
  AuthRoot,
  AuthSignUpRightContent,
  AuthServiceIncludeList,
  AuthSignUpLogo,
  AuthSignUpWaves,
} from '../Styled';

interface IQuery {
  inviteKey: string;
  partner: string;
  step: string | number;
  lang: ILanguage;
}

const INIT_STATE = {
  isLinkResent: false,
  user: USER_INITIAL_FORM_VALUES,
  company: COMPANY_INITIAL_FORM_VALUES,
};

interface IFormValues {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  shortName: string;
  addressZipCode: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  cameFrom?: ValueType<ICameFromOption>;
  lang?: ValueType<ILanguage>;
}

const reformatPayloadForApi = (
  values: IFormValues,
  query: IQuery
): ISignupPartnerBodyDto => {
  return {
    shortName: values.shortName,
    name: values.companyName,
    cameFrom: values.cameFrom ? values.cameFrom?.value : undefined,
    lang: query.lang,
    admin: {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      lang: query.lang,
      showTooltips: true,
    },
  };
};

const SignUp = () => {
  const { query, changeQuery } = useQuery<IQuery>();
  const { t } = useTranslation();
  const [state, handleStateChange] = useState<any>(INIT_STATE);

  React.useEffect(() => {
    const q = {
      ...query,
      step: 1,
    };

    if (!query.lang) {
      q.lang = getCurrentLang();
    }

    changeQuery(q);
    initTagManager();
  }, []);

  const { execute: signUpPartner, isPending: isSubmitting } = useAsync(
    (values) => {
      return authService.signUp(reformatPayloadForApi(values, query));
    }
  );

  const isSmUp = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  React.useEffect(() => {
    changeLang(query.lang);
  }, [query.lang]);

  const showStepper = !query.step || query.step !== 'completed';

  const activeStep = +query.step || 0;

  const isFormSubmitted = query.step === 'completed';

  return (
    <AuthRoot data-test-id={ALL_TEST_IDS.signUp.wrapper}>
      <AuthMainColWrapper xs={12} md={5}>
        <Row
          alignItems="center"
          justifyContent="space-between"
          direction="column"
          className="full-width full-height"
        >
          {isFormSubmitted ? (
            <Col className="flex justify-center full-height flex-column">
              <AuthLeftContent>
                <h1 className="mb-15">
                  {t('auth.registration.success.title')}
                </h1>
                <p className="mb-15 lh-14">
                  {t('auth.registration.success.content')}
                </p>
              </AuthLeftContent>
            </Col>
          ) : (
            <>
              <Col className="full-width">
                <AuthMobileLogo className={cn('mb-35')}>
                  <img src={MobileLogo} alt="" />
                </AuthMobileLogo>
              </Col>
              <Col className="full-width">
                <img
                  height="1"
                  width="1"
                  style={{ display: 'none' }}
                  alt=""
                  src="https://px.ads.linkedin.com/collect/?pid=2736097&conversionId=5075777&fmt=gif"
                />
                <AuthLeftContent className={cn('full-width')}>
                  {+query.step === 1 && (
                    <>
                      <h2 className="mb-20">{t('auth.registration.title')}</h2>
                      <UserInfoForm
                        showStepper={showStepper}
                        activeStep={activeStep}
                        initialValues={state.user}
                        onSubmit={(user) => {
                          handleStateChange({ user });
                          changeQuery({ step: 2 });
                        }}
                      />
                    </>
                  )}
                  {+query.step === 2 && (
                    <>
                      <h2 className="mb-20">
                        {t('auth.registration.title.continue')}
                      </h2>
                      <CompanyInfoForm
                        showStepper={showStepper}
                        activeStep={activeStep}
                        isSubmitting={isSubmitting}
                        initialValues={state.company}
                        innerContent={
                          <div className="uppercase fs-14 bolder mb-20">
                            {t('auth.registration.subtitle.partnerInfo')}
                          </div>
                        }
                        onSubmit={(val) => {
                          return signUpPartner({
                            ...state.user,
                            ...val,
                          }).then(() => changeQuery({ step: 'completed' }));
                        }}
                      />
                    </>
                  )}
                </AuthLeftContent>
              </Col>
              <AuthFooterLink>
                {t('auth.registration.haveAccount')}{' '}
                <NavLink
                  to={`/login?lang=${query.lang}`}
                  className="bolder primary"
                >
                  {t('auth.registration.button.signIn')}
                </NavLink>
              </AuthFooterLink>
            </>
          )}
        </Row>
      </AuthMainColWrapper>
      {isSmUp && (
        <AuthSignUpMainColRight className={cn('full-height')} md={7}>
          <AuthSignUpWaves src={Waves} alt="" />
          <AuthSignUpRightContent alignItems="center" justifyContent="center">
            <div className="full-width">
              <div className="text-center">
                <AuthSignUpLogo src={Logo} alt="" />
              </div>

              <AuthServiceIncludeList className={cn('check-custom-list')}>
                <li className="fs-20 mb-50">
                  {t('auth.registration.includesServicesList.1')}
                </li>
                <li className="fs-20 mb-50">
                  {t('auth.registration.includesServicesList.2')}
                </li>
                <li className="fs-20 mb-50">
                  {t('auth.registration.includesServicesList.3')}
                </li>
                <li className="fs-20 mb-50">
                  {t('auth.registration.includesServicesList.4')}
                </li>
                <li className="fs-20 mb-50">
                  {t('auth.registration.includesServicesList.5')}
                </li>
                <li className="fs-20 mb-50">
                  <strong>
                    {t('auth.registration.includesServicesList.6')}
                  </strong>
                </li>
              </AuthServiceIncludeList>
            </div>
          </AuthSignUpRightContent>
        </AuthSignUpMainColRight>
      )}
    </AuthRoot>
  );
};

export default SignUp;
