import React from 'react';
import { IconButton, MuiIcons, styled } from 'elements';
import { confirm, logOut, remCalc, important } from 'utils';
import { ALL_TEST_IDS } from 'enums';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
  id: string;
};

const LogOutButton = styled((props: Props) => {
  const { t } = useTranslation();
  const { className, id } = props;
  return (
    <IconButton
      edge="end"
      disableRipple
      onClick={() =>
        confirm({
          title: t('app.header.logOut.title'),
          maxWidth: 'xs',
          onSuccess: logOut,
          onCancel: () => undefined,
        })
      }
      
      color="inherit"
      size="small"
      className={className}
      data-test-id={`${ALL_TEST_IDS.header.logout}-${id}`}
    >
      <span className="_logout-text">{t('app.navigation.logout')}</span>
      <MuiIcons.Login fontSize="small" className="fs-20 ml-15" />
    </IconButton>
  );
})(({ theme }) => {
  const mobileUp = theme.breakpoints.up(1300);
  return {
    fontSize: remCalc(14),
    [mobileUp]: {
      fontSize: remCalc(17),
      color: important(theme.palette.appBar.contrastText),
    },
    fontWeight: 600,
    color: important(theme.palette.common.black),
    '& ._logout-text': {
      display: 'none',
      [mobileUp]: {
        display: 'inline-flex',
      },
    },
  };
});

export default LogOutButton;
