import * as React from 'react';
import cn from 'classnames';
import { Checkbox, Row, Col, ColorDot } from 'elements';

type IProps = IBadge & {
  checked: boolean;
  disabled?: boolean;
  onCheck: (checked: boolean) => void;
};

const BadgeItem = (props: IProps) => {
  return (
    <div className={cn('mb-20', { disabled: props.disabled })}>
      <Checkbox
        wrapperClassName="full-width"
        checked={props.checked}
        onCheck={() => props.onCheck(!props.checked)}
        label={
          <Row className="full-width" alignItems="center">
            <Col>
              <div className="pl-25 pr-40">
                <ColorDot size={24} color={props.color} />
              </div>
            </Col>
            <Col className="break-word-all" xs>
              {props.description}
            </Col>
          </Row>
        }
      />
    </div>
  );
};

export default BadgeItem;
