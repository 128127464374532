import * as React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Dialog, DialogProps } from 'elements';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useStateHandler } from 'hooks';
import { remCalc, showSystemMessage } from 'utils';
import SecondStep from './SecondStep';
import * as CONSTANTS from './constants';
import * as TYPES from './types';

type Props = DialogProps<TYPES.IFormValues> & {
  vmId: number;
  nic?: IVmTypes.Nic;
  primaryNetworkId?: number;
};

const PAPER_PROPS = { style: { maxWidth: remCalc(1040) } };

const defaultState = {
  network: undefined,
  ipv4Enabled: false,
  ipv6Enabled: false,
};

const ManageExternalIpDialog = observer((props: Props) => {
  const { open, onSave, onClose, vmId, nic, primaryNetworkId } = props;
  const { t } = useTranslation();
  const nics = useStateHandler(StateHandlers.vmNics);

  const isNew = !nic;

  const initial = nic
    ? {
        ipv4Enabled: !!nic.ipv4,
        ipv6Enabled: !!nic.ipv6,
      }
    : defaultState;

  const form = useFormik({
    initialValues: initial,
    validateOnMount: false,
    validationSchema: CONSTANTS.DefineValidationSchema({
      tab: 1,
    }),
    onSubmit: async (data: TYPES.IFormValues) => {
      try {
        if (isNew) {
          await nics.create({ vmId, ...data, networkId: primaryNetworkId });
        } else {
          await nics.update({ vmId, nicId: nic?.id }, data);
        }

        return onSave && onSave(data);
      } catch (er: any) {
        showSystemMessage(er.message, 'error');
      }
    },
  });

  React.useEffect(() => {
    if (!open) {
      form.resetForm(defaultState as any);
    } else {
      form.setValues(initial);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      title={t(`services.vm.info.externalNetwork.dialog.title`)}
      PaperProps={PAPER_PROPS}
      fullWidth
      maxWidth="md"
      handleSubmit={form.handleSubmit}
      onClose={onClose}
      actions={
        <Row justifyContent="space-between" columnSpacing={3} rowSpacing={2}>
          <Col>
            <Button variant="outlined" color="default" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button type="submit" disabled={form.isSubmitting}>
              {t('common.save')}
            </Button>
          </Col>
        </Row>
      }
    >
      <SecondStep
        {...form}
        descriptionString="services.vm.info.externalNetwork.dialog.content.main"
      />
    </Dialog>
  );
});

export default ManageExternalIpDialog;
