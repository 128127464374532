import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { useForceUpdate, useAuth, useState } from 'hooks';
import {
  Emitter,
  getCurrentToken,
  getExpirationInfo,
  IExpirationInfoResponse,
  noop,
  refreshToken,
  logOut,
} from 'utils';
import dayjs from 'dayjs';
import { CustomEvents } from 'enums';
import { dnd } from 'states';
import DeleteDialogs from './ConfirmDialogs';
import KeepSessionAliveDialog from '../KeepSessionAliveDialog';
import AssignBadgesDialog from '../AssignBadgesDialog/AssignBadgesDialog';
import DedicatedServerDialogs from './dialogs/DedicatedServerDialogs';
import VirtialServerDialogs from './dialogs/VirtialServerDialogs';

interface IState {
  keepAliveSessionOpen: boolean;
  assignDialog: any;
}

const INITIAL_STATE: IState = {
  keepAliveSessionOpen: false,
  assignDialog: null,
};

const Renderer = () => {
  const [state, handleState] = useState<IState>(INITIAL_STATE);
  const { isOnAuthorizedRoute } = useAuth();
  const hasToken = getCurrentToken();

  const expirePopupTimer = React.useRef<ReturnType<typeof setTimeout>>();
  const forceUpdate = useForceUpdate();

  const handleAssignDialog = React.useCallback(
    (isOpen: boolean) => (assignDialog: any) => {
      handleState({ assignDialog: isOpen ? assignDialog : null });
      dnd.merge({ isDialogOpen: false });
    },
    []
  );

  const handleAssignOpen = React.useCallback(handleAssignDialog(true), []);

  const handleSessionDialogClose = React.useCallback(
    () => handleState(false, 'keepAliveSessionOpen'),
    []
  );

  const initExpirePopupTimer = (expirationInfo: IExpirationInfoResponse) => {
    const { sessionExpiresAt } = expirationInfo;
    clearTimeout(expirePopupTimer.current);
    const expiresInAlertTime = sessionExpiresAt.subtract(120, 'seconds');
    const now = dayjs();
    const diffFull = expiresInAlertTime.diff(now);
    const delay = dayjs.duration(diffFull).asSeconds();

    expirePopupTimer.current = setTimeout(() => {
      forceUpdate();
      handleState(true, 'keepAliveSessionOpen');
    }, delay * 1000);
  };

  React.useEffect(() => {
    if (isOnAuthorizedRoute) {
      Emitter.on(CustomEvents.assignDialog, handleAssignOpen);
      Emitter.on(CustomEvents.initExpireSessionDialog, initExpirePopupTimer);
      Emitter.on(
        CustomEvents.closeExpireSessionDialog,
        handleSessionDialogClose
      );

      initExpirePopupTimer(getExpirationInfo());
    }
  }, [isOnAuthorizedRoute]);

  React.useEffect(() => {
    window.addEventListener('storage', (ev) => {
      if (ev.key === '_t' && ev.oldValue !== ev.newValue) {
        const newSessionInfo = getExpirationInfo();
        initExpirePopupTimer(newSessionInfo);
        setTimeout(() => {
          if (!ev.newValue) return logOut();
          handleSessionDialogClose();
        }, 10);
      }
    });

    return () => {
      clearTimeout(expirePopupTimer.current);
      Emitter.off(CustomEvents.assignDialog, handleAssignOpen);
      Emitter.off(
        CustomEvents.closeExpireSessionDialog,
        handleSessionDialogClose
      );
      Emitter.off(CustomEvents.initExpireSessionDialog, () => {
        handleState(false, 'keepAliveSessionOpen');
      });
    };
  }, []);

  const open = hasToken ? state.keepAliveSessionOpen : false;

  return (
    <>
      <DeleteDialogs />

      <KeepSessionAliveDialog
        open={open}
        date={getExpirationInfo().sessionExpiresAt.toDate()}
        onClose={noop}
        onSave={() => refreshToken().then(handleSessionDialogClose)}
      />

      <AssignBadgesDialog
        open={!!state.assignDialog}
        appEntityId={state.assignDialog?.appEntityId}
        onClose={handleAssignDialog(false)}
        onSave={state.assignDialog?.onSave}
      />

      {isOnAuthorizedRoute && (
        <>
          <DedicatedServerDialogs />
          <VirtialServerDialogs />
        </>
      )}
    </>
  );
};

const DialogUtilsComponents = withTranslation()(Renderer);

export default DialogUtilsComponents;
