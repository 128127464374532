import * as React from 'react';
import { styled } from 'elements/utilities';
import { remCalc, hexToRGBA } from 'utils';

export const CpuIcon = styled(
  (props: any & { size: number; scale?: number }) => {
    const { size, scale, ...rest } = props;

    return (
      <div {...rest}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
        >
          <g fill="none" fillRule="evenodd">
            <g>
              <g>
                <g>
                  <g transform="translate(-80 -867) translate(60 690) translate(20 174) translate(0 3)">
                    <rect width="28" height="28" fill="transparent" rx="4" />
                    <g className="_fill">
                      <path
                        d="M15.531 7.219c.26 0 .469-.21.469-.469s-.21-.469-.469-.469H13.47V4.72h2.062c.26 0 .469-.21.469-.469s-.21-.469-.469-.469H13.47V3.47c0-.517-.42-.938-.938-.938h-.312V.47c0-.26-.21-.469-.469-.469s-.469.21-.469.469V2.53H9.72V.47c0-.26-.21-.469-.469-.469s-.469.21-.469.469V2.53H7.22V.47c0-.26-.21-.469-.469-.469s-.469.21-.469.469V2.53H4.72V.47c0-.26-.21-.469-.469-.469s-.469.21-.469.469V2.53H3.47c-.517 0-.938.42-.938.938v.312H.47c-.26 0-.469.21-.469.469s.21.469.469.469H2.53V6.28H.47c-.26 0-.469.21-.469.469s.21.469.469.469H2.53V8.78H.47c-.26 0-.469.21-.469.469s.21.469.469.469H2.53v1.562H.47c-.26 0-.469.21-.469.469s.21.469.469.469H2.53v.312c0 .517.42.938.938.938h.312v2.062c0 .26.21.469.469.469s.469-.21.469-.469V13.47H6.28v2.062c0 .26.21.469.469.469s.469-.21.469-.469V13.47H8.78v2.062c0 .26.21.469.469.469s.469-.21.469-.469V13.47h1.562v2.062c0 .26.21.469.469.469s.469-.21.469-.469V13.47h.312c.517 0 .938-.42.938-.938v-.312h2.062c.26 0 .469-.21.469-.469s-.21-.469-.469-.469H13.47V9.72h2.062c.26 0 .469-.21.469-.469s-.21-.469-.469-.469H13.47V7.22h2.062zm-3 5.312H3.47V3.47h9.062c0 9.314.003 9.062 0 9.062z"
                        transform="translate(6.2 6.2)"
                      />
                      <path
                        d="M6.031 8.874c.553 0 1 .448 1 1 0 .553-.447 1-1 1-.552 0-1-.447-1-1 0-.552.448-1 1-1zm4 0c.553 0 1 .448 1 1 0 .553-.447 1-1 1-.552 0-1-.447-1-1 0-.552.448-1 1-1zm-4-3.6c.553 0 1 .448 1 1 0 .553-.447 1-1 1-.552 0-1-.447-1-1 0-.552.448-1 1-1zm4 0c.553 0 1 .448 1 1 0 .553-.447 1-1 1-.552 0-1-.447-1-1 0-.552.448-1 1-1z"
                        transform="translate(6.2 6.2)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
)(({ theme, size, scale }) => {
  return {
    position: 'relative',
    width: remCalc(size),
    height: remCalc(size),
    borderRadius: '3px',
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.1),
    '& svg': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: `translate3d(-50%, -50%, 0) scale(${scale || 1})`,
    },
    '& ._fill': {
      fill: theme.palette.primary.main,
    },
  };
});

export const RamIcon = styled((props: any) => {
  const { size, scale, ...rest } = props;
  return (
    <div {...rest}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
      >
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g>
                <g transform="translate(-172 -867) translate(60 690) translate(112 174) translate(0 3)">
                  <rect width="28" height="28" fill="transparent" rx="4" />
                  <g className="_fill">
                    <path
                      d="M15.072 6.663c.28 0 .507-.237.507-.53 0-.293-.227-.53-.507-.53H12.84v-1.77h2.232c.28 0 .507-.237.507-.53 0-.293-.227-.53-.507-.53H12.84V1.06C12.84.476 12.385 0 11.826 0h-1.352.04H3.753c-.56 0-1.015.476-1.015 1.061v1.712H.508c-.28 0-.508.237-.508.53 0 .293.227.53.507.53h2.231v1.77H.508c-.28 0-.508.237-.508.53 0 .293.227.53.507.53h2.231v1.768H.508c-.28 0-.508.238-.508.531 0 .293.227.53.507.53h2.231v1.769H.508c-.28 0-.508.237-.508.53 0 .293.227.53.507.53h2.231v2.165c0 .585.455 1.514 1.015 1.514h6.761-.04 1.352c.56 0 1.014-.476 1.014-1.061v-2.617h2.232c.28 0 .507-.238.507-.53 0-.294-.227-.531-.507-.531H12.84V9.492h2.232c.28 0 .507-.237.507-.53 0-.293-.227-.53-.507-.53H12.84V6.662h2.232zm-3.246 8.276H3.753V1.061h8.073c0 10.541.004 13.878 0 13.878z"
                      transform="translate(6 6)"
                    />
                    <path
                      d="M3.531 9.684V8.49h.52l.638 1.194h.786L4.76 8.375c.192-.082.339-.205.441-.368.103-.164.154-.36.154-.59 0-.335-.105-.602-.316-.802-.211-.2-.508-.3-.891-.3H2.819v3.37h.712zm.484-1.766H3.53v-1.02h.48c.407 0 .61.173.61.518 0 .335-.202.502-.606.502zm2.337 1.766l.25-.768h1.216l.25.768h.763L7.67 6.316h-.918L5.589 9.684h.763zM7.638 8.36h-.855l.415-1.274h.026l.414 1.274zM9.84 9.684v-2.2h.028l.872 2.184h.47l.872-2.176h.028v2.192h.691V6.316h-.878l-.928 2.263h-.04l-.927-2.263h-.878v3.368h.69z"
                      transform="translate(6 6) rotate(-90 7.81 8)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
})(({ theme, size, scale }) => {
  return {
    position: 'relative',
    width: remCalc(size),
    height: remCalc(size),
    borderRadius: '3px',
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.1),
    '& svg': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: `translate3d(-50%, -50%, 0) scale(${scale || 1})`,
    },
    '& ._fill': {
      fill: theme.palette.primary.main,
    },
  };
});

export const DiskSizeIcon = styled((props: any) => {
  const { size, scale, ...rest } = props;
  return (
    <div {...rest}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="16"
        viewBox="0 0 12 16"
        fill="none"
      >
        <g clipPath="url(#clip0_301_42)">
          <rect width="12" height="16" fill="transparent" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0.8V15.2C0 15.4122 0.0842855 15.6157 0.234315 15.7657C0.308601 15.84 0.396793 15.8989 0.493853 15.9391C0.590914 15.9793 0.694942 16 0.8 16H11.132C11.3442 16 11.5477 15.9157 11.6977 15.7657C11.8477 15.6157 11.932 15.4122 11.932 15.2V0.8C11.932 0.587827 11.8477 0.384344 11.6977 0.234315C11.5477 0.0842855 11.3442 0 11.132 0H0.8C0.694942 0 0.590914 0.0206926 0.493853 0.0608964C0.396793 0.1011 0.308601 0.160028 0.234315 0.234315C0.0842855 0.384344 0 0.587827 0 0.8ZM10.194 15.062H1.737C1.52483 15.062 1.32134 14.9777 1.17131 14.8277C1.02129 14.6777 0.937 14.4742 0.937 14.262V1.739C0.937 1.52683 1.02129 1.32334 1.17131 1.17331C1.32134 1.02329 1.52483 0.939 1.737 0.939H10.194C10.4062 0.939 10.6097 1.02329 10.7597 1.17331C10.9097 1.32334 10.994 1.52683 10.994 1.739V14.263C10.994 14.4752 10.9097 14.6787 10.7597 14.8287C10.6097 14.9787 10.4062 15.063 10.194 15.063V15.062ZM7.841 1.87502H1.875V4.68802H7.841V1.87502ZM6.903 3.75002H2.812V2.81202H6.903V3.75002ZM5.966 14.125C7.05059 14.1237 8.09039 13.6923 8.85731 12.9253C9.62424 12.1584 10.0557 11.1186 10.057 10.034C10.0557 8.94943 9.62424 7.90964 8.85731 7.14271C8.09039 6.37579 7.05059 5.94435 5.966 5.94302C4.88158 5.94435 3.84194 6.37565 3.07504 7.14236C2.30814 7.90907 1.87659 8.9486 1.875 10.033C1.87606 11.1176 2.30724 12.1575 3.07398 12.9246C3.84072 13.6917 4.88041 14.1234 5.965 14.125H5.966ZM5.966 6.88002C6.80208 6.88108 7.60359 7.21375 8.19469 7.80504C8.78579 8.39633 9.11821 9.19795 9.119 10.034C9.11794 10.8699 8.78541 11.6713 8.19434 12.2624C7.60327 12.8534 6.8019 13.186 5.966 13.187C5.12992 13.1862 4.3283 12.8538 3.73702 12.2627C3.14573 11.6716 2.81306 10.8701 2.812 10.034C2.81279 9.19777 3.14534 8.396 3.73666 7.80469C4.32798 7.21337 5.12975 6.88082 5.966 6.88002ZM6.43501 9.56543H5.49701V10.5034H6.43501V9.56543Z"
            className="_fill"
          />
        </g>
        <defs>
          <clipPath id="clip0_301_42">
            <rect width="12" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
})(({ theme, size, scale }) => {
  return {
    position: 'relative',
    width: remCalc(size),
    height: remCalc(size),
    borderRadius: '3px',
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.1),
    '& svg': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: `translate3d(-50%, -50%, 0) scale(${scale || 1})`,
    },
    '& ._fill': {
      fill: theme.palette.primary.main,
    },
  };
});
