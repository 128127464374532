import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'elements';
import { GLOBAL_DATE_FORMAT } from 'enums';
import * as Utils from 'utils';
import { InvoiceItemCostLogInterface } from 'invoices';
import { IInvoiceTypes } from '../types';

type IProps = InvoiceItemCostLogInterface &
  IInvoiceTypes.AdditionalPositionProps;

const PositionCostLogItem = (props: IProps) => {
  const { index, onDelete, onEdit, isEditable, ...it } = props;

  const {
    i18n,
    name,
    formattedResourceValue,
    formattedUsageDays,
    dailyPrice,
    startDate,
    endDate,
    cost,
  } = it;
  const { t } = useTranslation();

  const hasDaterange = !!startDate;

  const itemName = React.useMemo(() => {
    if (name) return name;
    return i18n ? t(`invoices.current.positions.items.${i18n}`) : '';
  }, [name, i18n]);

  return (
    <Row alignItems="center" className="fs-15 light-grey pt-5 pb-5">
      <Col xs={6}>
        {`${index} `}
        {`${formattedResourceValue} `}
        {itemName}
        {hasDaterange &&
          ` (${Utils.dayjs(startDate)
            .utc()
            .format(GLOBAL_DATE_FORMAT)}-${Utils.dayjs(endDate)
            .utc()
            .format(GLOBAL_DATE_FORMAT)})`}
      </Col>
      <Col xs={6}>
        <Row alignItems="center">
          <Col xs={10}>
            <Row alignItems="center">
              <Col className="text-right" xs={4}>
                {formattedUsageDays}
              </Col>
              <Col className="text-right" xs={4}>
                {Utils.numberToCurrency(dailyPrice as number, false, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Col>
              <Col xs={4} className="text-right">
                {Utils.numberToCurrency(cost, false, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Col>
            </Row>
          </Col>
          <Col xs={2} />
        </Row>
      </Col>
    </Row>
  );
};

export default PositionCostLogItem;
