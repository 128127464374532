import * as React from 'react';
import cn from 'classnames';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useTranslation } from 'react-i18next';
import { PermissionOption, PermissionValueTypes } from 'auth-shapes';
import { Menu, MuiIcons } from 'elements';
import { DropdownItemButton, DropdownMenuItem } from './Styled';

type Props = {
  value: PermissionValueTypes;
  options: PermissionOption[];
  disabled: boolean;
  onChange: (p: PermissionValueTypes) => any;
  testId?: string;
};

const DropdownItem = (props: Props) => {
  const { value, onChange, disabled, testId, options } = props;
  const { t } = useTranslation();

  return (
    <div>
      <PopupState variant="popover" popupId="permissions-options">
        {(popupState) => {
          const EndIcon = popupState.isOpen
            ? MuiIcons.ArrowDropUp
            : MuiIcons.ArrowDropDown;
          return (
            <>
              <DropdownItemButton
                variant="text"
                color="primary"
                disabled={disabled}
                disableRipple
                disableElevation
                disableFocusRipple
                endIcon={<EndIcon />}
                {...bindTrigger(popupState)}
                testId={testId}
              >
                {t(`permissions.options.${value}`)}
              </DropdownItemButton>
              <Menu {...bindMenu(popupState)}>
                {options.map((perm) => (
                  <DropdownMenuItem
                    key={perm.key}
                    className={cn({
                      disabled: perm.disabled,
                    })}
                    onClick={() => {
                      onChange(perm.key);
                      popupState.close();
                    }}
                    data-test-id={`${testId}-${perm.key}`}
                  >
                    {t(`permissions.options.${perm.key}`)}
                  </DropdownMenuItem>
                ))}
              </Menu>
            </>
          );
        }}
      </PopupState>
    </div>
  );
};

export default DropdownItem;
