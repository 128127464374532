import React from 'react';
import * as R from 'ramda';
import cn from 'classnames';
import { INavItem } from 'services-types';
import { stringify } from 'query-string';
import { compact, round } from 'utils';
import { CpuIcon, DiskSizeIcon, RamIcon } from 'components/QuotasInfo/Icons';
import { PermissionsMap } from 'auth-shapes';
import { ValueType } from 'global-shapes';
import {
  defaultDnsZonesQuery,
  defaultNavQuery,
  defaultRdsNavQuery,
  defaultVmsQuery,
} from 'enums';
import { useTask } from 'hooks';
import {
  Chip,
  Col,
  createColumnHelper,
  IconButton,
  LightTooltip,
  MuiIcons,
  Row,
} from 'elements';

export const LINE_COLOR = '#81858B';

export const getServicesNavigationOptions = (
  accountHash: string,
  otherQueryParams: Record<string, any>
): INavItem[] => {
  const vmQuery = {
    ...defaultVmsQuery,
    networkType: undefined,
    view: otherQueryParams.view,
  };
  return [
    {
      label: 'services.tabs.all',
      to: `/${accountHash}/services/all`,
      baseNamespace: '/services/all',
      testName: 'all',
    },
    {
      label: 'services.tabs.connectivity',
      to: `/${accountHash}/services/connectivity/edges`,
      dependency: 'connectivity',
      baseNamespace: '/services/connectivity/edges',
      testName: 'connectivity',
    },
    {
      label: 'services.tabs.infrastructure',
      to: `/${accountHash}/services/infrastructure/accounts?${stringify(
        defaultNavQuery
      )}`,
      dependency: 'core',
      baseNamespace: '/services/infrastructure',
      testName: 'infrastructure',
    },
    {
      label: 'services.tabs.remote',
      to: `/${accountHash}/services/rds/accounts?${stringify({
        ...defaultRdsNavQuery,
        orderType: 'asc',
      })}`,
      dependency: 'rds',
      baseNamespace: `/services/rds`,
      testName: 'rds',
    },
    {
      label: 'services.tabs.dns',
      to: `/${accountHash}/services/dns/domains?${stringify(
        defaultDnsZonesQuery
      )}`,
      dependency: 'dns',
      baseNamespace: `/services/dns`,
      testName: 'dns',
    },
    {
      label: 'services.tabs.server',
      to: `/${accountHash}/services/server?${stringify(vmQuery)}`,
      baseNamespace: '/services/server',
      testName: 'vms',
    },
  ];
};

export const SYSTEM_INFO = [
  {
    key: 'virtualCpus',
    img: CpuIcon,
  },
  {
    key: 'ramMb',
    img: RamIcon,
    modify: (val: any) => `${round(val / 1024, 1)} GB`,
  },
  {
    key: 'totalDiskSizeGb',
    img: DiskSizeIcon,
    modify: (val: any) => `${val} GB`,
  },
];

export const NETWORK_MASK = '24';
export const MIN_INTERNAL_NETWORK_POOL_SIZE = 49;

const column = createColumnHelper<any>();

export const getAdditionalDiskColumns = (props: any) => [
  column.accessor('name', {
    header: (
      <div className="fs-12 steel">
        {props.t('services.dialog.vms.table.head.diskName')}
      </div>
    ),
    size: 35,
    sizeUnit: '%',
    disableSort: true,
  }),

  column.accessor('sizeGb', {
    header: (
      <div className="fs-12 steel">
        {props.t('services.dialog.vms.table.head.diskSize')}
      </div>
    ),
    disableSort: true,
    cell: ({ row: { original: disk } }: ICell<IVmTypes.VMDisk>) =>
      `${disk.sizeGb} GB`,
  }),

  column.accessor('id', {
    header: '',
    disableSort: true,
    alignment: 'right',
    cell: ({ row }: ICell<IVmTypes.VMDisk>) => {
      const disabled = !props.hasBilling || props.hasSnapshot;
      const ableToEdit = !!props.onEdit;
      const ableToDelete = !row.original.isMain;

      return (
        <Row alignItems="center" justifyContent="flex-end" columnSpacing={2}>
          {ableToEdit && (
            <Col>
              <IconButton
                size="small"
                className={cn('p-0', { disabled })}
                onClick={() => props.onEdit(row.original)}
                disabled={disabled}
              >
                <MuiIcons.Edit className={cn('fs-22 pointer steel')} />
              </IconButton>
            </Col>
          )}

          {ableToDelete && (
            <Col>
              <IconButton
                size="small"
                className={cn('p-0', { disabled })}
                onClick={() => props.onDelete(row.original.id)}
                disabled={disabled}
              >
                <MuiIcons.Delete className={cn('fs-22 steel')} />
              </IconButton>
            </Col>
          )}
        </Row>
      );
    },
  }),
];

interface IPublicServiceTableProps {
  t: any;
  onDelete: (port: IVmTypes.VMPort) => void;
  onEdit?: (port: IVmTypes.VMPort) => void;
  showNetwork?: boolean;
  isEditable?: boolean;
  hideGeo?: boolean;
}

// : ICell<IVmTypes.VMDisk>

export const getPublicServicesColumns = ({
  t,
  onDelete,
  isEditable,
  hideGeo,
  showNetwork,
  onEdit,
}: IPublicServiceTableProps) => {
  const entities: any = hideGeo
    ? []
    : [
        column.accessor('sourceIpAddresses', {
          header: <div className="fs-12 steel">{t('table.head.source')}</div>,
          size: 200,
          disableSort: true,
          cell: ({ row: { original: service } }: ICell<IVmTypes.VMPort>) => {
            const totalCount = service.sourceIpAddresses.length;
            const left = totalCount - 2;
            const restIpString = service.sourceIpAddresses.slice(
              2,
              totalCount + 1
            );

            const hasMore = totalCount > 2;
            if (hasMore) {
              return (
                <LightTooltip
                  title={restIpString.map((s: string) => (
                    <div key={s}>{s}</div>
                  ))}
                  placement="top"
                  arrow
                >
                  <span>
                    {service.sourceIpAddresses.slice(0, 2).join(', ')}
                    <span className="steel fs-14 pl-15">
                      {t('app.more', { value: left })}
                    </span>
                  </span>
                </LightTooltip>
              );
            }
            return <span>{service.sourceIpAddresses.join(', ') || '-'}</span>;
          },
        }),
        column.accessor('sourceCountryCodes', {
          header: <div className="fs-12 steel">{t('table.head.geoip')}</div>,
          size: 150,
          disableSort: true,
          cell: ({ row: { original: service } }: ICell<IVmTypes.VMPort>) => {
            return <span>{service.sourceCountryCodes.join(', ') || '-'}</span>;
          },
        }),
      ];

  return compact([
    showNetwork &&
      column.accessor('nic', {
        header: (
          <div className="fs-12 steel">{t('table.head.networkName')}</div>
        ),
        disableSort: true,
        cell: ({ row: { original: service } }: ICell<IVmTypes.VMPort>) => {
          return <span>{service.nic?.network?.name}</span>;
        },
      }),
    column.accessor('name', {
      header: (
        <div className="fs-12 steel">
          {t('services.dialog.vms.table.head.portName')}
        </div>
      ),
      disableSort: true,
    }),
    column.accessor('port', {
      header: (
        <div className="fs-12 steel">
          {t('services.dialog.vms.table.head.port')}
        </div>
      ),
      size: 80,
      disableSort: true,
    }),

    column.accessor('type', {
      header: <div className="fs-12 steel">{t('table.head.type')}</div>,
      size: 80,
      disableSort: true,
      cell: ({ row: { original: service } }: ICell<IVmTypes.VMPort>) => {
        return (
          <span>{t(`services.vm.services.portTypes.${service.type}`)}</span>
        );
      },
    }),

    ...(entities as any),

    column.accessor('id', {
      header: '',
      disableSort: true,
      size: 100,
      cell: function Cell({
        row: { original: service },
      }: ICell<IVmTypes.VMPort>) {
        const task = useTask(service.task);
        return (
          <div className="flex justify-end align-center full-width">
            {task.isFailed && (
              <Chip
                status="error"
                className="mr-20"
                tooltip={task.error && task.error.message}
              >
                {t('services.card.content.failed')}
              </Chip>
            )}
            {isEditable && (
              <>
                {onEdit && (
                  <MuiIcons.Edit
                    className={cn('fs-20 pointer steel')}
                    onClick={() => onEdit(service)}
                  />
                )}

                <MuiIcons.Delete
                  className={cn('fs-20 pointer steel ml-10')}
                  onClick={() => onDelete(service)}
                />
              </>
            )}
          </div>
        );
      },
    }),
  ]);
};

export const VM_DETAILS_NAV_ITEMS = (config: {
  permissions: PermissionsMap;
  isPublic: boolean;
  hidePubServices: boolean;
}) => {
  const hiddenTabs = [];

  if (config.isPublic || config.hidePubServices) {
    hiddenTabs.push('public');
  }

  if (!config.permissions.MONITORING?.canView) {
    hiddenTabs.push('monitor');
  }

  if (!config.permissions.BACKUP?.canView) {
    hiddenTabs.push('backup');
  }

  return R.without(hiddenTabs, [
    'info',
    'network',
    'public',
    'disks',
    'backup',
    'monitor',
  ]);
};

export const DEDICATED_SERVER_DETAILS_NAV_ITEMS = (config: {
  permissions: PermissionsMap;
}): string[] => {
  const hiddenTabs: string[] = [];

  if (!config.permissions.MONITORING?.canView) {
    hiddenTabs.push('monitor');
  }

  return R.without(hiddenTabs, ['info', 'monitor']);
};

export const CORE_DETAILS_NAV_ITEMS = (config: {
  permissions: PermissionsMap;
}) => {
  const hiddenTabs = [];

  if (!config.permissions.DOMAIN_ADMINS?.canView) {
    hiddenTabs.push('admins');
  }

  if (!config.permissions.BACKUP?.canView) {
    hiddenTabs.push('backup');
  }

  if (!config.permissions.MONITORING?.canView) {
    hiddenTabs.push('monitor');
  }

  return R.without(hiddenTabs, [
    'accounts',
    'admins',
    'shares',
    'backup',
    'monitor',
    'patching',
  ]);
};

const buildPortElements = (
  types: IVmTypes.IPortType[]
): IVmTypes.IPortTypeValue[] => {
  return types.map((el) => ({
    value: el,
    label: `services.vm.services.portTypes.${el}`,
  }));
};

export const PORT_TYPES_ELEMENTS: IVmTypes.IPortType[] = [
  'tcp',
  'udp',
  'tcpUdp',
  'icmp',
  'any',
];

export const PORT_TYPES_OPTIONS = buildPortElements(PORT_TYPES_ELEMENTS);
export const PORT_TYPES_OPTIONS_SHORT = buildPortElements(['tcp', 'udp']);

export const VM_CREATE_PORTS_LIMIT = 100;

export const BILLING_CIRCLE_DS_OPTIONS: ValueType<IVmTypes.IBillingCircleInterval>[] =
  [
    {
      value: 'MONTH',
      label: 'costsInfo.options.billingCircle.MONTH',
    },
    {
      value: 'QUARTER',
      label: 'costsInfo.options.billingCircle.QUARTER',
    },
    {
      value: 'HALF_YEAR',
      label: 'costsInfo.options.billingCircle.HALF_YEAR',
    },
    {
      value: 'YEAR',
      label: 'costsInfo.options.billingCircle.YEAR',
    },
  ];

export const getBillingCycleDsOptions = (
  isEvaluation?: boolean
): ValueType<IVmTypes.IBillingCircleInterval>[] =>
  BILLING_CIRCLE_DS_OPTIONS.map((el) => ({
    ...el,
    isDisabled: el.value !== 'MONTH' ? isEvaluation : false,
  }));
