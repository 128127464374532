import * as React from 'react';
import { useServiceStatus, useTask, useVmActiveServices } from 'hooks';
import { IAssignmentReducerState } from 'badges';
import * as StateHandlerHelpers from 'states/helpers';
import { MetaData } from 'meta-data';
import { Permission } from 'auth-shapes';
import { TFunction } from 'react-i18next';
import { Tooltip } from 'elements';
import { STATUS_NAMESPACES_MAP } from 'enums';
import {
  shouldDisableWebConsoleLaunching,
  getBadgeStateKey,
  remCalc,
  round,
  compact,
} from 'utils';
import {
  MuiIcons,
  Row,
  Col,
  ActiveServiceLayout,
  createColumnHelper,
} from 'elements';
import { withVmActions, InjectedProps } from 'hocs/withVmActions';
import {
  withDedicatedServerActions,
  InjectedProps as InjectedDedServerProps,
} from 'hocs/withDedicatedServerActions';
import { SVerticalBadges } from './Styled';
import CardMenuItems from '../services/components/cards/CardMenuItems';
import {
  defineVmDisplayStatus,
  defineDedicatedServerDisplayStatus,
} from '../services/helpers';
import { VmIconWrapper, StatusChip } from '../services/components/Styled';
import { ICell } from '../../modules/table';

type ColumnProps = {
  t: TFunction;
  meta: MetaData;
  isRequesting: boolean;
  avaliableColumns: string[];
  fetchVms: () => void;
  permissions: Permission;
  onOpenDetails: (vm: IVmTypes.Vm) => void;
  onResolve: (id: number) => void;
  assignments: IAssignmentReducerState;
};

const VmActionsComponent = (
  props: IVmTypes.Vm & InjectedProps & ColumnProps
) => {
  const {
    onResolve,
    confirmSnapshotRequest,
    onEdit,
    t,
    isRequesting,
    handleRsOpen,
    onOpenDetails,
    permissions,
    onDelete,
    handlers,
    menuItems,
    ...vm
  } = props;

  const displayStatus = defineVmDisplayStatus(vm)();
  const hasError = STATUS_NAMESPACES_MAP.error.includes(displayStatus);
  const { status, inProgress } = useServiceStatus(
    hasError ? 'UNKNOWN' : displayStatus || 'none'
  );

  const WebConsoleIcon = React.useMemo(() => {
    if (!permissions.canManage) return null;

    if (shouldDisableWebConsoleLaunching(displayStatus)) {
      return (
        <Tooltip
          title={t('services.content.tooltips.consoleDisabled')}
          placement="top"
          arrow
        >
          <MuiIcons.DesktopWindowsOutlined
            data-draggable="ignore"
            className="half-faded fs-16 mr-10 steel lh-1"
            onClick={() => handlers.console(vm.id)}
            style={{ height: 22 }}
          />
        </Tooltip>
      );
    }

    return (
      <MuiIcons.DesktopWindowsOutlined
        data-draggable="ignore"
        className="pointer fs-16 mr-10 steel lh-1"
        onClick={() => handlers.console(vm.id)}
        style={{ height: 22 }}
      />
    );
  }, [permissions.canManage, displayStatus, vm.id]);

  return (
    <Row justifyContent="space-between" alignItems="center">
      <StatusChip className="fs-11 white" status={status}>
        {t(`services.card.content.status.${status}`) as string}
      </StatusChip>
      <Col className="lh-1">
        <Row columnSpacing={2} alignItems="center" data-draggable="ignore">
          {WebConsoleIcon}
          <CardMenuItems
            items={menuItems || []}
            testId={`${vm}-osy-view-dropdown`}
            disabled={inProgress}
          />
        </Row>
      </Col>
    </Row>
  );
};

const DedServerActionsComponent = (
  props: IVmTypes.Vm & InjectedDedServerProps & ColumnProps
) => {
  const {
    onResolve,
    onEdit,
    t,
    isRequesting,
    onOpenDetails,
    permissions,
    onDelete,
    handlers,
    menuItems,
    ...vm
  } = props;

  const displayStatus = defineDedicatedServerDisplayStatus(
    vm.dedicatedServer as IDedicatedServer.DedicatedServer
  )();
  const hasError = STATUS_NAMESPACES_MAP.error.includes(displayStatus);
  const { status, inProgress } = useServiceStatus(
    hasError ? 'UNKNOWN' : displayStatus || 'none'
  );

  return (
    <Row justifyContent="space-between" alignItems="center">
      <StatusChip className="fs-11 white" status={status}>
        {t(`services.card.content.status.${status}`) as string}
      </StatusChip>
      <Col className="lh-1">
        <Row columnSpacing={2} alignItems="center" data-draggable="ignore">
          <MuiIcons.DesktopWindowsOutlined
            data-draggable="ignore"
            className="pointer fs-16 mr-10 steel lh-1"
            onClick={() => handlers.console(vm.dedicatedServer?.id)}
            style={{ height: 22 }}
          />
          <CardMenuItems
            items={menuItems || []}
            testId={`${vm}-osy-view-dropdown`}
            disabled={inProgress}
          />
        </Row>
      </Col>
    </Row>
  );
};

const VmActions = withVmActions(VmActionsComponent, 'list');
const DedicatedServerActions = withDedicatedServerActions(
  DedServerActionsComponent,
  'list'
);

const column = createColumnHelper<IVmTypes.Vm>();

export const getVmTableColumn = (props: ColumnProps) => {
  const {
    t,
    onOpenDetails,
    fetchVms,
    assignments,
    avaliableColumns,
    onResolve,
    permissions,
    isRequesting,
  } = props;

  return compact([
    avaliableColumns.includes('name') &&
      column.accessor('name', {
        header: () => (
          <div className="fs-15 weight-normal ml-20">
            {t('table.head.name')}
          </div>
        ),
        cell: function Cell({ row: { original: vm } }: ICell<IVmTypes.Vm>) {
          useTask(vm.task, {
            onComplete: (action) => {
              StateHandlerHelpers.fetchQuotas();
              if (action === 'delete') return fetchVms();
              StateHandlerHelpers.reloadVmInList(vm.id, {
                include: ['serviceMonitoring', 'serviceSnapshot'],
              });
            },
          });
          const isPublic = vm.networkType === 'PUBLIC';
          const isDedictaed = vm.type === 'DEDICATED';

          const TitleIcon = React.useMemo(() => {
            if (isDedictaed) return MuiIcons.LanOutlined;
            return isPublic ? MuiIcons.Public : MuiIcons.Shield;
          }, [isDedictaed, isPublic]);

          const entityAssignments =
            assignments[getBadgeStateKey(vm.dedicatedServer || vm)] || [];

          return (
            <>
              <Row alignItems="center">
                <SVerticalBadges size={8} assignments={entityAssignments} />
                <Col>
                  <VmIconWrapper>
                    <TitleIcon color="primary" className="fs-20" />
                  </VmIconWrapper>
                </Col>
                <Col xs>
                  <span
                    className="fs-14 pointer break-word-all"
                    onClick={() => onOpenDetails(vm)}
                    data-draggable="ignore"
                  >
                    {vm.dedicatedServer ? vm.dedicatedServer?.name : vm.name}
                  </span>
                </Col>
              </Row>
            </>
          );
        },
        disableSort: false,
        size: 200,
        minWidth: remCalc(200),
      }),

    avaliableColumns.includes('description') &&
      column.accessor('description', {
        header: (
          <div className="fs-15 weight-normal">
            {t('table.head.description')}
          </div>
        ),
        sortId: 'description',
        disableSort: false,
        size: 150,
        minWidth: remCalc(150),
        cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => (
          <span className="fs-14 steel ellipsis">{vm.description}</span>
        ),
      }),

    avaliableColumns.includes('os') &&
      column.accessor('os', {
        header: <div className="fs-15 weight-normal">{t('table.head.os')}</div>,
        sortId: 'os.name',
        disableSort: false,
        size: 180,
        minWidth: remCalc(180),
        cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => (
          <span className="fs-14 steel">{vm.os.name}</span>
        ),
      }),

    avaliableColumns.includes('cpu') &&
      column.accessor('virtualCpus', {
        header: (
          <div className="fs-15 weight-normal">{t('table.head.cpu')}</div>
        ),
        sortId: 'virtualCpus',
        alignment: 'right',
        disableSort: false,
        size: 90,
        minWidth: remCalc(90),
        cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => (
          <span className="fs-14 steel">{vm.virtualCpus}</span>
        ),
      }),

    avaliableColumns.includes('ram') &&
      column.accessor('ramMb', {
        header: (
          <div className="fs-15 weight-normal">{t('table.head.ram')}</div>
        ),
        sortId: 'ramMb',
        alignment: 'right',
        disableSort: false,
        size: 90,
        minWidth: remCalc(90),
        cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => (
          <span className="fs-14 steel">{round(vm.ramMb / 1024, 1)}</span>
        ),
      }),

    avaliableColumns.includes('storage') &&
      column.accessor('totalDiskSizeGb', {
        header: (
          <div className="fs-15 weight-normal">{t('table.head.storage')}</div>
        ),
        sortId: 'totalDiskSizeGb',
        alignment: 'right',
        disableSort: false,
        size: 120,
        minWidth: remCalc(120),
        cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => (
          <span className="fs-14 steel">{vm.totalDiskSizeGb}</span>
        ),
      }),

    avaliableColumns.includes('network') &&
      column.accessor('nics', {
        header: (
          <div className="fs-15 weight-normal">{t('table.head.network')}</div>
        ),
        sortId: 'network',
        disableSort: false,
        size: 200,
        minWidth: remCalc(200),
        cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => {
          const primaryNetwork = vm.nics.find((n) => n.isPrimary);
          return (
            <span className="fs-14 steel">
              {primaryNetwork?.network?.name || '-'}
            </span>
          );
        },
      }),

    avaliableColumns.includes('ipv4') &&
      column.accessor('internalIpAddress', {
        header: (
          <div className="fs-15 weight-normal">{t('table.head.ipv4')}</div>
        ),
        sortId: 'nics.ipv4',
        disableSort: false,
        size: 110,
        minWidth: remCalc(110),
        cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => {
          return (
            <div className="lh-12 steel">
              {vm.nics[0]?.ipv4 && (
                <div className="fs-14 steel full-width">{vm.nics[0]?.ipv4}</div>
              )}
              {vm.nics[1]?.ipv4 && (
                <div className="fs-14 steel">{vm.nics[1]?.ipv4}</div>
              )}
              {!vm.nics[0]?.ipv4 && !vm.nics[1]?.ipv4 && '-'}
            </div>
          );
        },
      }),

    avaliableColumns.includes('ipv6') &&
      column.accessor('updatedAt', {
        header: (
          <div className="fs-15 weight-normal">{t('table.head.ipv6')}</div>
        ),
        sortId: 'nics.ipv6',
        disableSort: false,
        size: 190,
        minWidth: remCalc(190),
        cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => {
          return (
            <div className="lh-12 steel">
              {vm.nics[0]?.ipv6 && (
                <div className="fs-14 steel full-width">{vm.nics[0]?.ipv6}</div>
              )}
              {vm.nics[1]?.ipv6 && (
                <div className="fs-14 steel">{vm.nics[1]?.ipv6}</div>
              )}
              {!vm.nics[0]?.ipv6 && !vm.nics[1]?.ipv6 && '-'}
            </div>
          );
        },
      }),

    avaliableColumns.includes('activeServices') &&
      column.accessor('lastBackupVersion', {
        header: (
          <div className="fs-15 weight-normal">
            {t('table.head.activeServices')}
          </div>
        ),
        disableSort: true,
        size: 160,
        minWidth: remCalc(160),
        cell: function Cell({ row: { original: vm } }: ICell<IVmTypes.Vm>) {
          const activeServices = useVmActiveServices(vm);
          return (
            <ActiveServiceLayout
              services={activeServices}
              instance={`vm-${vm.id}`}
            />
          );
        },
      }),

    avaliableColumns.includes('billingCycle') &&
      column.accessor('billingCycle', {
        header: (
          <div className="fs-15 weight-normal">
            {t('table.head.billingCycle')}
          </div>
        ),
        sortId: 'billingCycle',
        disableSort: false,
        size: 200,
        minWidth: remCalc(200),
        cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => (
          <span className="fs-14 steel">
            {t(
              `costsInfo.options.billingCircle.${
                vm.billingCycle?.interval || 'NONE'
              }`
            )}
          </span>
        ),
      }),

    column.accessor('status', {
      header: (
        <div className="fs-15 weight-normal">{t('table.head.status')}</div>
      ),
      disableSort: true,
      size: 210,
      minWidth: remCalc(210),
      cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) =>
        vm.dedicatedServer ? (
          <DedicatedServerActions
            {...vm}
            permissions={permissions}
            onResolve={() => onResolve(vm.id)}
            isRequesting={isRequesting}
            onOpenDetails={onOpenDetails}
            t={t}
          />
        ) : (
          <VmActions
            {...vm}
            permissions={permissions}
            onResolve={() => onResolve(vm.id)}
            isRequesting={isRequesting}
            onOpenDetails={onOpenDetails}
            t={t}
          />
        ),
    }),
  ]);
};
