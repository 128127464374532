import React, { useCallback } from 'react';
import cn from 'classnames';
import {
  Checkbox,
  IconButton,
  MuiIcons,
  Input,
  Row,
  Col,
  AddIcon,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { PALETTE } from 'enums';
import { FormikProps } from 'formik';
import { IBackupNotificationValues, IBackupEmailNew } from 'backup';
import { remCalc, validator } from 'utils';

type IValues = IBackupNotificationValues & { backupEmails: IBackupEmailNew[] };

type IProps = FormikProps<IValues> & {
  disabled: boolean;
};

const BackupNotificationsForm = (props: IProps) => {
  const { initialValues, disabled, ...formikConfig } = props;
  const { t } = useTranslation();
  const [emailInput, setEmail] = React.useState('');

  const { values, setFieldValue } = formikConfig;

  const userCount = values.backupEmails.length;

  const hasEmailDuplicates = !!values.backupEmails.find(
    (el) => el.email === emailInput
  );

  const isInvalidEmail =
    (!!emailInput && !validator.isEmail(emailInput)) || hasEmailDuplicates;

  const onCheck = useCallback(
    (name: keyof IBackupNotificationValues) => (isChecked: boolean) => {
      setFieldValue(name, isChecked);
    },
    [userCount]
  );

  return (
    <div className={cn('mb-20', { disabled })}>
      <div className="fs-18 bolder mb-10">
        {t('services.backup.notifications')}
      </div>
      <div>
        <div className="mb-10">
          <Checkbox
            name="isSuccessNotification"
            checked={values.isSuccessNotification}
            label={
              <span className="fs-15">
                {t('services.backup.notifications.successful')}
              </span>
            }
            onCheck={onCheck('isSuccessNotification')}
          />
        </div>
        <div className="mb-10">
          <Checkbox
            name="isWarningNotification"
            checked={values.isWarningNotification}
            label={
              <span className="fs-15">
                {t('services.backup.notifications.withWarnings')}
              </span>
            }
            onCheck={onCheck('isWarningNotification')}
          />
        </div>
        <div className="mb-30">
          <Checkbox
            name="isFailedNotification"
            checked={values.isFailedNotification}
            label={
              <span className="fs-15">
                {t('services.backup.notifications.failed')}
              </span>
            }
            onCheck={onCheck('isFailedNotification')}
          />
        </div>
      </div>

      <div className="mb-15">
        <div className="fs-12 mb-5">{t('forms.email')}</div>
        <Row columnSpacing={2}>
          <Col xs>
            <Input
              value={emailInput}
              onChange={(ev) => setEmail(ev.target.value)}
              error={isInvalidEmail}
              helperText={
                hasEmailDuplicates
                  ? 'forms.invalid.email.uniq'
                  : 'forms.emailInvalid'
              }
            />
          </Col>
          <Col className="pt-5">
            <AddIcon
              disabled={!emailInput || isInvalidEmail}
              onClick={() => {
                setFieldValue('backupEmails', [
                  ...values.backupEmails,
                  { email: emailInput },
                ]);
                setEmail('');
              }}
            />
          </Col>
        </Row>
      </div>
      {values.backupEmails.map((el, ind) => (
        <div
          key={el.email}
          data-test-id={`backup-emails-${ind}`}
          className={cn('flex justify-between align-center fs-14')}
          style={{
            borderBottom: `1px solid ${PALETTE.pageBackground}`,
            maxWidth: remCalc(545),
          }}
        >
          <span>{el.email}</span>
          <IconButton
            onClick={() =>
              setFieldValue(
                'backupEmails',
                values.backupEmails.filter((em) => em.email !== el.email)
              )
            }
            data-test-id={`delete-button-${ind}`}
          >
            <MuiIcons.Delete className="fs-20 steel" />
          </IconButton>
        </div>
      ))}
    </div>
  );
};

export default BackupNotificationsForm;
