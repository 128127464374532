import { remCalc } from 'utils';
import { PALETTE } from 'enums';
import { styled, Skeleton, Row, Switch, MuiIcons } from 'elements';
import { VerticalBadges } from 'components';

export const VmLoaderSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '100%',
  height: remCalc(422),
  marginBottom: remCalc(20),
}));

export const SVerticalBadges = styled(VerticalBadges)({
  position: 'absolute',
  left: remCalc(-15),
  top: 0,
  width: remCalc(8),
  height: '100%',
  zIndex: 1,
  '& > div': {
    left: remCalc(15),
  },
});

export const VmDetailsContent = styled(Row)(({ theme }) => ({
  maxWidth: remCalc(900),
  height: '100%',
}));

export const FolderRowTr = styled('div')(({ theme }) => ({
  backgroundColor: PALETTE.folderBackground,
  padding: remCalc(10, 0, 10, 0),
  marginBottom: remCalc(2),
  borderRadius: theme.shape.borderRadius,
  fontSize: remCalc(14),
  cursor: 'pointer',
}));

export const FolderIconWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  lineHeight: remCalc(12),
  marginRight: remCalc(8),
  marginLeft: remCalc(15),
  textAlign: 'center',
  '& ._name-text': {
    position: 'absolute',
    fontSize: remCalc(8),
    left: 0,
    top: 0,
    color: theme.palette.primary.contrastText,
    zIndex: 1,
    width: '100%',
    lineHeight: remCalc(20),
    height: remCalc(20),
  },
}));

export const FormSwitcher = styled(Switch)(({ theme }) => ({
  height: remCalc(25),
}));

export const InfoLabelSup = styled(MuiIcons.HelpOutline)(({ theme }) => ({
  fontSize: remCalc(12),
  position: 'relative',
  top: remCalc(-3),
  color: theme.palette.primary.main,
}));
