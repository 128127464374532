import * as React from 'react';
import * as R from 'ramda';
import cn from 'classnames';
import { Paper, Row, Col, Button } from 'elements';
import { useTranslation } from 'react-i18next';
import { IEmployeePayload, IEmployee } from 'employees';
import { useState } from 'hooks';
import { confirm } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { EmployeeTableItem, EmployeeTableHeadRow } from './EmployeeTableItem';
import EmployeeDetailsDialog from './EmployeeDetails';
import EmployeesAllDialog from './EmployeesAll';
import { parseEmployeeForForm, toogleArrayValues } from './helpers';

type IState = {
  contactDialogOpen: boolean;
  allEmployeesDialogOpen: boolean;
  selected?: IEmployeePayload;
  query: IEmployeeTypes.IQuery;
  staticQuery: IEmployeeTypes.IQuery;
  expanded: number[];
};

const INITIAL_STATE: IState = {
  contactDialogOpen: false,
  allEmployeesDialogOpen: false,
  staticQuery: {
    page: 1,
    perPage: 20,
    orderType: 'asc',
    orderBy: 'name',
  },
  query: {
    page: 1,
    perPage: 20,
    orderType: 'asc',
    orderBy: 'name',
  },
  expanded: [],
};

const OBSERVERS = {
  employees: StateHandlers.employees,
  currentUser: StateHandlers.currentUser,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { currentUser, employees } = props;
  const { t } = useTranslation();
  const [state, handleChange] = useState<IState>(INITIAL_STATE);
  const tenantId = currentUser.data?.tenant?.id;
  const query = state.allEmployeesDialogOpen ? state.query : state.staticQuery;
  const queryKey = state.allEmployeesDialogOpen ? 'query' : 'staticQuery';

  const isNew = !state.selected;

  const hasEmployees = !!employees.data.length;
  const showViewAll = employees.meta.totalCount > 20;

  const handleDetailsDialog = React.useCallback(
    (contactDialogOpen: boolean) => () =>
      handleChange({ contactDialogOpen, selected: undefined }),
    []
  );

  const handleAllEmployeesDialog = React.useCallback(
    (allEmployeesDialogOpen: boolean) => () =>
      handleChange({ allEmployeesDialogOpen }),
    []
  );

  const onCreateEmployee = React.useCallback(
    (payload: IEmployeePayload) =>
      employees.create({ tenantId, ...payload }).then(() => {
        handleChange({ contactDialogOpen: false });
        return employees.get({ tenantId, ...query });
      }),
    [tenantId, JSON.stringify(state)]
  );

  const onUpdateEmployee = React.useCallback(
    (payload: IEmployeePayload) =>
      employees
        .update({ tenantId, id: state.selected?.id }, payload)
        .then(() => {
          handleChange({ contactDialogOpen: false, selected: undefined });
          return employees.get({ tenantId, ...query });
        }),
    [tenantId, JSON.stringify(state)]
  );

  const onExpand = React.useCallback(
    (id: number) => {
      handleChange({ expanded: toogleArrayValues(id, state.expanded) });
    },
    [JSON.stringify(state.expanded)]
  );

  const onEdit = React.useCallback((employee: IEmployee) => {
    handleChange({
      contactDialogOpen: true,
      selected: parseEmployeeForForm(employee),
    });
  }, []);

  const onDelete = React.useCallback(
    (employee: IEmployee) => {
      confirm({
        title: t(
          'settings.page.partnerInfo.section.employees.confirm.delete.title'
        ),
        content: t(
          'settings.page.partnerInfo.section.employees.confirm.delete.subtitle',
          { name: employee.name }
        ),
        onSuccess: () =>
          employees
            .remove({ tenantId, id: employee.id })
            .then(() => employees.get({ tenantId, ...query })),
        onCancel: () => undefined,
      });
    },
    [JSON.stringify(query), tenantId]
  );

  const onChangeQuery = React.useCallback(
    (q: IEmployeeTypes.IQuery) => {
      handleChange({ query: q });
    },
    [state, handleChange, tenantId]
  );

  React.useEffect(() => {
    employees.get({ tenantId, ...query });
  }, [JSON.stringify(query), tenantId]);

  return (
    <Paper className="pt-20 pb-20 pl-30 pr-30 mb-10">
      <Row alignItems="center" justifyContent="space-between" className="mb-20">
        <Col>
          <h5>{t('settings.page.partnerInfo.section.employees.title')}</h5>
        </Col>
        {employees.meta.totalCount <= 100 && (
          <Col>
            <Button onClick={handleDetailsDialog(true)} size="small">
              {t('settings.page.partnerInfo.section.employees.buttons.add')}
            </Button>
          </Col>
        )}
      </Row>
      <div className={cn({ disabled: employees.isRequesting })}>
        <EmployeeTableHeadRow
          query={query}
          onChange={(newQuery) =>
            handleChange({ [queryKey]: { ...state[queryKey], ...newQuery } })
          }
        />
        {hasEmployees ? (
          employees.data.map((emp, ind) => {
            const isExpanded = R.includes(emp.id, state.expanded);
            return (
              <EmployeeTableItem
                key={`${emp.name}-${ind}`}
                {...emp}
                expanded={isExpanded}
                onDelete={onDelete}
                onEdit={onEdit}
                onExpand={onExpand}
              />
            );
          })
        ) : (
          <div className="text-center pt-15">{t('common.noData')}</div>
        )}
        {showViewAll && (
          <div className="text-center pt-15">
            <Button
              size="small"
              color="default"
              variant="outlined"
              onClick={handleAllEmployeesDialog(true)}
            >
              {t('settings.page.partnerInfo.section.employees.buttons.viewAll')}
            </Button>
          </div>
        )}
      </div>
      <EmployeeDetailsDialog
        open={state.contactDialogOpen}
        onClose={handleDetailsDialog(false)}
        onSave={isNew ? onCreateEmployee : onUpdateEmployee}
        initialValues={state.selected}
      />
      <EmployeesAllDialog
        open={state.allEmployeesDialogOpen}
        onClose={handleAllEmployeesDialog(false)}
        onSave={() => undefined}
        onDelete={onDelete}
        onEdit={onEdit}
        query={state.query}
        onChangeQuery={onChangeQuery}
      />
    </Paper>
  );
});

const Employees = () => <View {...OBSERVERS} />;

export default Employees;
