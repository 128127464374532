import * as React from 'react';
import * as R from 'ramda';
import { getCurrentToken, logOut, findShortNamesInUrl, history } from 'utils';
import { AuthContext, useAuth, IAuthChildSessionInfo, useState } from 'hooks';
import * as StateHandlers from 'states';
import { UNPROTECTED_ROUTE_NAMES } from 'enums';
import { useLocation } from 'react-router-dom';
import { Update } from 'history';

type IProps = React.PropsWithChildren;

const INITIAL_CHILD_SESSION_STATE: IAuthChildSessionInfo = {
  psn: sessionStorage.getItem('psn') || undefined,
  tsn: sessionStorage.getItem('tsn') || undefined,
};

const AuthProvider = (props: IProps) => {
  const { children } = props;
  const location = useLocation();
  const { pathname } = location;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [token, setTokenValue] = React.useState<string>(null!);

  const [childSession, handleSessionChange] = useState<IAuthChildSessionInfo>(
    INITIAL_CHILD_SESSION_STATE
  );

  const setChildSessionInfo = React.useCallback(
    (names: Partial<IAuthChildSessionInfo>, useSessionStorage?: boolean) => {
      handleSessionChange(names);
      if (useSessionStorage) {
        R.keys(names).forEach((key) => {
          if (names[key] !== undefined) {
            // @ts-ignore
            return sessionStorage.setItem(key, names[key].toString());
          }

          sessionStorage.removeItem(key);
        });
      }
    },
    []
  );

  const isOnAuthorizedRoute = React.useMemo(
    () => !UNPROTECTED_ROUTE_NAMES.some((name) => pathname.includes(name)),
    [pathname]
  );

  const isOnRoute = React.useMemo(
    () => !!pathname.split('/').filter((el) => el).length,
    [pathname]
  );
  const isAuthorized = isOnAuthorizedRoute && !!getCurrentToken();

  const setToken = React.useCallback((newToken: string) => {
    return setTokenValue(newToken);
  }, []);

  const signOut = React.useCallback(logOut, []);

  const onHistoryChange = React.useCallback(
    ({ location: newLocation }: Update) => {
      const prev = findShortNamesInUrl(location);
      const next = findShortNamesInUrl(newLocation);
      const isPrevTenantChanged = !!(prev.tsn && !next.tsn);
      const isPrevPartnerChanged = !!(prev.psn && !next.psn);
      const isNextTenantChanged = !!(!prev.tsn && next.tsn);
      const isNextPartnerChanged = !!(!prev.psn && next.psn);
      const isPrevAction = isPrevTenantChanged || isPrevPartnerChanged;
      const isNextAction = isNextTenantChanged || isNextPartnerChanged;

      if (
        isPrevTenantChanged ||
        isPrevPartnerChanged ||
        isNextTenantChanged ||
        isNextPartnerChanged
      ) {
        if (isPrevAction) {
          !next.psn && sessionStorage.removeItem('psn');
          !next.tsn && sessionStorage.removeItem('tsn');
        }
        if (isNextAction) {
          next.psn && sessionStorage.setItem('psn', next.psn);
          next.tsn && sessionStorage.setItem('tsn', next.tsn);
        }

        setChildSessionInfo(next, true);
        StateHandlers.currentUser.reset();
        return StateHandlers.fetchAllAccountData({ shouldApplyTheme: true });
      }
    },
    [JSON.stringify(location)]
  );

  React.useEffect(() => {
    const stopHistoryListen = history.listen(onHistoryChange);

    return stopHistoryListen;
  }, []);

  const value = {
    token,
    setToken,
    signOut,
    isAuthorized,
    isOnAuthorizedRoute,
    childSession,
    setChildSessionInfo,
    isOnRoute,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { useAuth, AuthProvider };

export default AuthProvider;
