import * as React from 'react';
import Popover from './Popover';
import { MuiIcons } from './MuiIcons';
import { Checkbox } from './Checkbox';
import { withTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import { equals } from 'ramda';
import cn from 'classnames';
import { ValueType } from 'global-shapes';
import { styled } from './utilities';
import { important, remCalc } from '../utils';

type IProps = React.PropsWithChildren<
  WithT & {
    query: AnyShape;
    queryName: string;
    isMulti?: boolean;
    loadOptions?: () => Promise<ValueType<string | number>[]>;
    options?: ValueType<string | number>[];
    onChange: (selected: any) => void;
  }
>;

export const FilterAlt = styled(MuiIcons.FilterAlt)(({ theme }) => ({
  lineHeight: 1,
  height: important(remCalc(16)),
  display: 'inline-flex',
  alignSelf: 'center',
}));

const IconHolder = styled('div')(({ theme }) => ({
  lineHeight: 1,
  height: important(remCalc(16)),
  display: 'inline-flex',
  alignSelf: 'center',
  position: 'relative',
  '&:after': {
    content: '" "',
    position: 'absolute',
    top: 0,
    right: 2,
    width: remCalc(7),
    height: remCalc(7),
    borderRadius: '50%',
    display: 'block',
    zIndex: 1,
  },
  '&._active_dot:after': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Renderer = (props: IProps) => {
  const { t, queryName, options, onChange, loadOptions, isMulti, query } =
    props;

  const [loadedOptions, handleOptions] = React.useState<
    ValueType<string | number>[]
  >([]);

  const [selected, handleState] = React.useState(query[queryName] || []);

  const [anchorEl, handleAnchor] = React.useState(null);

  const handleClick = React.useCallback((event: React.MouseEvent<any>) => {
    handleAnchor(event.currentTarget);
  }, []);

  const handleCheck = React.useCallback(
    (id: string) => (isChecked: boolean) =>
      handleState((prev: string[]) => {
        id = id.toString();
        if (isMulti) {
          // @ts-ignore
          return isChecked ? [...prev, id] : prev.filter((el) => el !== id);
        }
        return isChecked ? [id] : [];
      }),
    [isMulti]
  );

  React.useEffect(() => {
    if (loadOptions) {
      loadOptions().then(handleOptions);
    }
  }, [!!loadOptions]);

  React.useEffect(() => {
    if (!equals(query[queryName], selected))
      handleState(query[queryName] || []);
  }, [JSON.stringify(query[queryName])]);

  const finalOptions = (loadOptions ? loadedOptions : options) || [];

  return (
    <>
      <IconHolder
        className={cn('pointer ml-10 steel', {
          _active_dot: !!selected.length,
        })}
        onClick={handleClick}
        aria-describedby={queryName}
      >
        <FilterAlt className={cn(' fs-20')} />
      </IconHolder>
      <Popover
        id={queryName}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          handleAnchor(null);
          onChange(selected as any);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="pt-5 pb-5 pl-15 pr-15">
          {finalOptions.map((opt) => (
            <div key={`${queryName}-${opt.label}`}>
              <Checkbox
                label={t(opt.label as string)}
                checked={selected.includes(opt.value.toString())}
                onCheck={handleCheck(opt.value as string)}
              />
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

export const TableHeadFilterItems = withTranslation()(Renderer);
