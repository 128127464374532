import { PermissionValueTypes, PermissionList } from 'auth-shapes';
import { AccessLevelLow } from 'global-shapes';

export const DEFAULT_QUERY = {
  orderBy: 'name',
  orderType: 'asc',
  page: 1,
  perPage: 20,
};

interface PermissionOption {
  key: PermissionValueTypes;
  disabled?: boolean;
}

type PermissionOptionShape = Partial<
  Record<PermissionList, PermissionOption[]>
>;

export const PERMISSION_OPTIONS: PermissionOptionShape = {
  USERS: [{ key: 'none' }, { key: 'viewOnly' }, { key: 'modify' }],
  ROLES: [{ key: 'none' }, { key: 'viewOnly' }, { key: 'modify' }],
  PARTNERS: [{ key: 'viewOnly' }, { key: 'modify' }],
  TENANTS: [{ key: 'none' }, { key: 'viewOnly' }, { key: 'modify' }],
  PRICING: [{ key: 'none' }, { key: 'viewOnly' }, { key: 'modify' }],
  INVOICES: [{ key: 'none' }, { key: 'viewOnly' }, { key: 'modify' }],
  BILLABLE_ITEMS: [{ key: 'none' }, { key: 'modify' }],
  USAGE: [{ key: 'none' }, { key: 'viewOnly' }],
  APP_STORE: [{ key: 'none' }, { key: 'viewOnly' }, { key: 'modify' }],
  SERVICES: [{ key: 'viewOnly' }, { key: 'modify' }],
  INVOICES_STATISTICS: [{ key: 'none' }, { key: 'viewOnly' }],
  DOMAIN_ADMINS: [{ key: 'none' }, { key: 'viewOnly' }, { key: 'modify' }],
  CUSTOMER_BILLING: [{ key: 'none' }, { key: 'viewOnly' }, { key: 'modify' }],
  MONITORING: [{ key: 'none' }, { key: 'viewOnly' }, { key: 'modify' }],
};

export const PERMISSION_OPTIONS_BY_LEVEL: Record<
  AccessLevelLow,
  PermissionOptionShape
> = {
  provider: {
    ...PERMISSION_OPTIONS,
    BACKUP: [{ key: 'none' }, { key: 'viewOnly' }, { key: 'modify' }],
  },
  partner: {
    ...PERMISSION_OPTIONS,
    BACKUP: [{ key: 'none' }, { key: 'viewOnly' }, { key: 'modify' }],
  },
  tenant: {
    ...PERMISSION_OPTIONS,
    BILLABLE_ITEMS: [{ key: 'none' }, { key: 'viewOnly' }],
    BACKUP: [{ key: 'none' }, { key: 'viewOnly' }, { key: 'modify' }],
  },
};

export const permissionNamespaceMap = {
  none: {
    canView: false,
    canManage: false,
  },
  modify: {
    canView: true,
    canManage: true,
  },
  viewOnly: {
    canView: true,
    canManage: false,
  },
};
