import * as React from 'react';
import { takeLast } from 'ramda';
import cn from 'classnames';
import * as Yup from 'yup';
import { AnyFunc, AnyShape, ValueType } from 'global-shapes';
import { useServiceStatus, useUserHash } from 'hooks';
import { StatusChip } from 'pages/services/components/Styled';
import { defineVmDisplayStatus } from 'pages/services/helpers';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import {
  Button,
  Chip,
  ChipOutlined,
  ConditionalTooltip,
  CopyTooltip,
  createColumnHelper,
  IconDirectNetworkMonitor,
  IconIsolatedNetworkMonitor,
  IconNatRoutedNetworkMonitor,
  LightTooltip,
  Menu,
  MenuItem,
  MuiIcons,
  OpenUserIcon,
  parseSups,
  TableHeadFilterItems,
  Tooltip,
} from 'elements';
import {
  CostResourceIds,
  NETWORK_STATUS_MAP,
  STATUS_NAMESPACES_MAP,
} from 'enums';
import { noop, random, remCalc, validator } from 'utils';
import { generatePreSharedKey } from './ipsecTunnels/helpers';

const CLASSES: AnyShape = {
  success: 'success',
  error: 'error',
};

interface IIPSECVpnTableProps {
  t: AnyFunc;
  onDelete: AnyFunc;
  onEdit: AnyFunc;
  isFetching: boolean;
  isEditable: boolean;
}

const column = createColumnHelper<any>();

// IIpSecVpnTunnel

export function getVpnTunnelsColumns<P extends IIPSECVpnTableProps>({
  t,
  onDelete,
  onEdit,
  isFetching,
  isEditable,
}: P) {
  return [
    column.accessor('name', {
      header: <div className="fs-12 steel">{t('table.head.name')}</div>,
      disableSort: true,
      cell: ({ row }: ICell<IIpsecVpnTunnels.IpSecVpnTunnel>) => (
        <CopyTooltip>{row.original.name}</CopyTooltip>
      ),
    }),
    column.accessor('localEndpoint', {
      header: (
        <div className="fs-12 steel">{t('table.head.localEndpoint')}</div>
      ),
      disableSort: true,
      cell: ({ row }: ICell<IIpsecVpnTunnels.IpSecVpnTunnel>) => (
        <CopyTooltip>{row.original.localEndpoint}</CopyTooltip>
      ),
    }),
    column.accessor('localSubnets', {
      header: <div className="fs-12 steel">{t('table.head.localSubnet')}</div>,
      disableSort: true,
      cell: ({ row }: ICell<IIpsecVpnTunnels.IpSecVpnTunnel>) => (
        <CopyTooltip>{row.original.localSubnets[0]}</CopyTooltip>
      ),
    }),
    column.accessor('remoteEndpoint', {
      header: (
        <div className="fs-12 steel">{t('table.head.remoteEndpoint')}</div>
      ),
      disableSort: true,
      cell: ({ row }: ICell<IIpsecVpnTunnels.IpSecVpnTunnel>) => (
        <CopyTooltip>{row.original.remoteEndpoint}</CopyTooltip>
      ),
    }),
    column.accessor('remoteSubnet', {
      header: <div className="fs-12 steel">{t('table.head.remoteSubnet')}</div>,
      disableSort: true,
      cell: ({ row }: ICell<IIpsecVpnTunnels.IpSecVpnTunnel>) => {
        const subnets =
          typeof row.original.remoteSubnets === 'string'
            ? row.original.remoteSubnets.split(', ')
            : row.original.remoteSubnets;
        const visibleSubnets = subnets.slice(0, 3);
        const restSubnets = subnets.slice(3, subnets.length);

        if (restSubnets.length) {
          return (
            <div>
              <Tooltip
                placement="top"
                arrow
                title={restSubnets.map((net: any) => (
                  <div key={net} className="mb-5">
                    {net}
                  </div>
                ))}
              >
                <span>{visibleSubnets.join(', ')}</span>
              </Tooltip>
            </div>
          );
        }

        return (
          <div>
            <CopyTooltip>{visibleSubnets.join(', ')}</CopyTooltip>
          </div>
        );
      },
    }),
    column.accessor('status', {
      header: <div className="fs-12 steel">{t('table.head.status')}</div>,
      disableSort: true,
      cell: ({
        row: { original: tunnel },
      }: ICell<IIpsecVpnTunnels.IpSecVpnTunnel>) => {
        const connection =
          tunnel.ikeStatus === 'UP' && tunnel.tunnelStatus === 'UP'
            ? 'success'
            : 'error';
        return (
          <div className={cn(CLASSES[connection])}>
            {t(
              connection === 'success'
                ? `statuses.CONNECTED`
                : `statuses.DISCONNECTED`
            )}
          </div>
        );
      },
    }),
    column.accessor('id', {
      header: '',
      disableSort: true,
      size: 100,
      cell: ({ row }: any) => {
        return isEditable ? (
          <div
            className={cn('flex align-center justify-end full-width', {
              disabled: isFetching,
            })}
          >
            <MuiIcons.Edit
              className="fs-24 pointer steel mr-15"
              onClick={() => onEdit(row.original)}
            />
            <MuiIcons.Delete
              className="fs-24 pointer steel"
              onClick={() => onDelete(row.original.id)}
            />
          </div>
        ) : undefined;
      },
    }),
  ];
}

const STATE_OPTIONS = [
  {
    value: 'ACTIVE',
    label: 'services.connectivity.firewallRules.options.ACTIVE',
  },
  {
    value: 'INACTIVE',
    label: 'services.connectivity.firewallRules.options.INACTIVE',
  },
];

const ACTION_OPTIONS = [
  {
    value: 'ALLOW',
    label: 'services.connectivity.firewallRules.options.ALLOW',
  },
  {
    value: 'DROP',
    label: 'services.connectivity.firewallRules.options.DROP',
  },
  {
    value: 'REJECT',
    label: 'services.connectivity.firewallRules.options.REJECT',
  },
];

export function getNetworkRulesColumns({
  t,
  onEdit,
  onDelete,
  query,
  onChangeQuery,
}: {
  t: AnyFunc;
  query: AnyShape;
  onChangeQuery: (key: string, val: string[]) => void;
  onEdit: (rule: IConnectivityTypes.IFirewallRule) => void;
  onDelete: (rule: IConnectivityTypes.IFirewallRule) => void;
}) {
  return [
    column.accessor('id', {
      header: '',
      accessor: 'id',
      disableSort: true,
      size: 30,
      maxWidth: 30,
      cell: ({ row }: ICell<IConnectivityTypes.IFirewallRule>) => (
        <div>
          {row.original.isSystem ? (
            <MuiIcons.LockOutlined className="fs-18 steel" />
          ) : (
            row.original.index
          )}
        </div>
      ),
    }),

    column.accessor('name', {
      header: <div className="fs-12 steel">{t('table.head.name')}</div>,
      accessor: 'name',
      size: 150,
      disableSort: true,
      cell: function Cell({ row }: ICell<IConnectivityTypes.IFirewallRule>) {
        const [, goTo] = useUserHash();
        return (
          <div className="flex align-center justify-between full-width">
            <LightTooltip title={row.original.name} placement="top" arrow>
              <span
                className="ellipsis"
                style={{ maxWidth: 'calc(100% - 20px)', paddingRight: 5 }}
              >
                {row.original.name}
              </span>
            </LightTooltip>
            {row.original.isUserDefined && row.original.ownerAppEntity && (
              <OpenUserIcon
                onClick={() =>
                  goTo(`/services/vm/public`, {
                    id: row.original.ownerAppEntity?.entityId,
                  })
                }
              />
            )}
          </div>
        );
      },
    }),

    column.accessor('isEnabled', {
      header: <div className="fs-12 steel">{t('table.head.state')}</div>,
      size: 60,
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.IFirewallRule>) => {
        const className = row.original.isEnabled ? 'success' : 'error';
        const text = row.original.isEnabled ? 'Active' : 'Inactive';
        return <span className={className}>{text}</span>;
      },
      filterComponent: () => (
        <TableHeadFilterItems
          query={query}
          queryName="isEnabled"
          options={STATE_OPTIONS}
          isMulti
          onChange={(val) => onChangeQuery('isEnabled', val)}
        />
      ),
    }),

    column.accessor('applications', {
      header: <div className="fs-12 steel">{t('table.head.applications')}</div>,
      size: 200,
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.IFirewallRule>) => {
        if (row.original?.applications?.length) {
          const text = row.original.applications.join(', ');
          return (
            <>
              <LightTooltip title={text} placement="top" arrow>
                <div className="ellipsis">{text}</div>
              </LightTooltip>
            </>
          );
        }

        return '-';
      },
    }),
    column.accessor('sourceIpAddresses', {
      header: <div className="fs-12 steel">{t('table.head.source')}</div>,
      size: 80,
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.IFirewallRule>) => {
        const sourceIpAddresses = row.original?.sourceIpAddresses || [];
        const sourceCountryCodes = row.original?.sourceCountryCodes || [];
        const source = [...sourceIpAddresses, ...sourceCountryCodes];
        const address = source?.join(', ') || '';
        const charsCount = address.length || 0;
        if (charsCount) {
          return (
            <ConditionalTooltip
              condition={charsCount > 15}
              title={parseSups(address, ', ')}
              arrow
              placement="top"
            >
              <div className="ellipsis">{address}</div>
            </ConditionalTooltip>
          );
        }

        return t('common.any');
      },
    }),

    column.accessor('destinationIpAddresses', {
      header: <div className="fs-12 steel">{t('table.head.destination')}</div>,
      size: 70,
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.IFirewallRule>) => {
        const destinationIpAddresses =
          row.original?.destinationIpAddresses || [];
        const destinationCountryCodes =
          row.original?.destinationCountryCodes || [];
        const source = [...destinationIpAddresses, ...destinationCountryCodes];
        const destination = source?.join(', ') || '';

        const charsCount = destination.length || 0;
        if (charsCount) {
          return (
            <ConditionalTooltip
              condition={charsCount > 15}
              title={parseSups(destination, ', ')}
              arrow
              placement="top"
            >
              <div className="ellipsis">{destination}</div>
            </ConditionalTooltip>
          );
        }

        return t('common.any');
      },
    }),
    column.accessor('action', {
      header: <div className="fs-12 steel">{t('table.head.action')}</div>,
      size: 60,
      alignment: 'right',
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.IFirewallRule>) => {
        return row.original.action || '-';
      },
      filterComponent: () => (
        <TableHeadFilterItems
          query={query}
          queryName="action"
          options={ACTION_OPTIONS}
          isMulti
          onChange={(val) => onChangeQuery('action', val)}
        />
      ),
    }),

    column.accessor('ownerAppEntity', {
      header: '',
      disableSort: true,
      size: 30,
      alignment: 'right',
      cell: ({ row }: ICell<IConnectivityTypes.IFirewallRule>) => {
        if (row.original.type === 'USER') {
          return (
            <PopupState variant="popover">
              {(popupState) => (
                <div className="ml-10 flex align-center">
                  <MuiIcons.MoreVert
                    className={cn('pointer fs-20 steel')}
                    {...bindTrigger(popupState)}
                  />
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={() => {
                        popupState.close();
                        onEdit(row.original);
                      }}
                    >
                      {t('common.edit')}
                    </MenuItem>
                    {row.original.id && !row.original.isSystem && (
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          onDelete(row.original);
                        }}
                      >
                        {t('common.delete')}
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              )}
            </PopupState>
          );
        }
        return null;
      },
    }),
  ];
}

export function getNatRulesColumns({ t }: { t: AnyFunc }) {
  return [
    column.accessor('name', {
      header: <div className="fs-12 steel">{t('table.head.name')}</div>,
      accessor: 'name',
      size: 250,
      disableSort: true,
      cell: function Cell({ row }: ICell<IConnectivityTypes.INatRule>) {
        const [, goTo] = useUserHash();
        return (
          <div className="flex align-center justify-between full-width">
            <LightTooltip title={row.original.name} placement="top" arrow>
              <span
                className="ellipsis"
                style={{ maxWidth: 'calc(100% - 20px)', paddingRight: 5 }}
              >
                {row.original.name}
              </span>
            </LightTooltip>
            {row.original.ownerAppEntity && (
              <OpenUserIcon
                onClick={() =>
                  goTo(`/services/vm/public`, {
                    id: row.original.ownerAppEntity?.entityId,
                  })
                }
              />
            )}
          </div>
        );
      },
    }),

    column.accessor('isEnabled', {
      header: <div className="fs-12 steel">{t('table.head.state')}</div>,
      size: 60,
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.INatRule>) => {
        const className = row.original.isEnabled ? 'success' : 'steel';
        const text = row.original.isEnabled
          ? 'common.active'
          : 'common.inactive';
        return <span className={className}>{t(text)}</span>;
      },
    }),

    column.accessor('natActionType', {
      header: <div className="fs-12 steel">{t('table.head.natAction')}</div>,
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.INatRule>) => (
        <span>{row.original.natActionType || '-'}</span>
      ),
    }),
    column.accessor('externalIpAddress', {
      header: <div className="fs-12 steel">{t('table.head.externalIp')}</div>,
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.INatRule>) => (
        <span>{row.original.externalIpAddress || '-'}</span>
      ),
    }),
    column.accessor('internalIpAddress', {
      header: <div className="fs-12 steel">{t('table.head.internalIp')}</div>,
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.INatRule>) => (
        <span>{row.original.internalIpAddress || '-'}</span>
      ),
    }),
    column.accessor('applicationPortName', {
      header: <div className="fs-12 steel">{t('table.head.application')}</div>,
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.INatRule>) => (
        <span>{row.original.applicationPortName || '-'}</span>
      ),
    }),
    column.accessor('dnatExternalPorts', {
      header: <div className="fs-12 steel">{t('table.head.externalPort')}</div>,
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.INatRule>) => (
        <span>{row.original.dnatExternalPorts || t('common.any')}</span>
      ),
    }),
  ];
}

type INetColumnsConfig = {
  t: AnyFunc;
  onRemove: (net: IConnectivityTypes.INetwork) => void;
  onEdit: (net: IConnectivityTypes.INetwork) => void;
  onVmView: (net: IConnectivityTypes.INetwork) => void;
};

export function getNetworksColumns(config: INetColumnsConfig) {
  const { t, onRemove, onEdit, onVmView } = config;

  return [
    column.accessor('type', {
      header: '',
      disableSort: true,
      size: 40,
      maxWidth: 40,
      cell: ({ row }: ICell<IConnectivityTypes.INetwork>) => {
        if (row.original.type === 'DIRECT') {
          return <IconDirectNetworkMonitor status="primary" onClick={noop} />;
        }
        if (row.original.type === 'ISOLATED') {
          return <IconIsolatedNetworkMonitor status="primary" onClick={noop} />;
        }
        if (row.original.type === 'NAT_ROUTED') {
          return (
            <IconNatRoutedNetworkMonitor status="primary" onClick={noop} />
          );
        }
        return '';
      },
    }),
    column.accessor('name', {
      header: <div className="fs-12 steel">{t('table.head.name')}</div>,
      accessor: 'name',
      size: 250,
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.INetwork>) => (
        <div className="flex align-center">
          <span className="pr-5">{row.original.name}</span>
          {row.original.isPrimary && (
            <ChipOutlined status="primary" className="fs-10">
              Primary
            </ChipOutlined>
          )}
        </div>
      ),
    }),
    column.accessor('defaultIpv4SubnetIndex', {
      header: <div className="fs-12 steel">{t('table.head.gatewayCIDR')}</div>,
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.INetwork>) => (
        <CopyTooltip>{row.original.subnets[0]?.cidr || '-'}</CopyTooltip>
      ),
    }),
    column.accessor('defaultIpv6SubnetIndex', {
      header: (
        <div className="fs-12 steel">{t('table.head.internalIpPool')}</div>
      ),
      accessor: '',
      disableSort: true,
      cell: ({ row }: ICell<IConnectivityTypes.INetwork>) => {
        if (row.original.type !== 'ISOLATED' && row.original.subnets) {
          return row.original.subnets[0]?.ipRanges?.map((range) => {
            const end = range.endAddress?.split('.') || [];

            return (
              <span key={range.startAddress}>{`${
                range?.startAddress
              } - ${takeLast(1, end)}`}</span>
            );
          });
        }
        return '-';
      },
    }),
    column.accessor('connectedVmsQty', {
      header: <div className="fs-12 steel">{t('table.head.connectedVms')}</div>,
      disableSort: true,
      size: 120,
      cell: ({ row }: ICell<IConnectivityTypes.INetwork>) => {
        return (
          <span className="pointer" onClick={() => onVmView(row.original)}>
            {row.original.connectedVmsQty}
          </span>
        );
      },
    }),

    column.accessor('vcdStatus', {
      header: <div className="fs-12 steel">{t('table.head.status')}</div>,
      disableSort: true,
      size: 80,
      cell: ({ row }: ICell<IConnectivityTypes.INetwork>) => {
        const status = NETWORK_STATUS_MAP[row.original.vcdStatus];
        return (
          <Chip status={status}>
            {t(
              `services.connectivity.networks.status.${row.original.vcdStatus}`
            )}
          </Chip>
        );
      },
    }),

    column.accessor('id', {
      header: '',
      disableSort: true,
      size: 30,
      alignment: 'right',
      cell: ({ row }: ICell<IConnectivityTypes.INetwork>) => {
        if (row.original.isPrimary) {
          return null;
        }
        return (
          <PopupState variant="popover">
            {(popupState) => (
              <div className="ml-10 flex align-center">
                <MuiIcons.MoreVert
                  className={cn('pointer fs-20 steel')}
                  {...bindTrigger(popupState)}
                />
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      onEdit(row.original);
                    }}
                  >
                    {t('common.edit')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      onRemove(row.original);
                    }}
                  >
                    {t('common.delete')}
                  </MenuItem>
                </Menu>
              </div>
            )}
          </PopupState>
        );
      },
    }),
  ];
}

export const DF_POLICY_OPTIONS: ValueType<IIpsecVpnTunnels.TunnelDFPolicy>[] = [
  {
    label: 'common.copy',
    value: 'copy',
  },
  {
    label: 'common.clear',
    value: 'clear',
  },
];

export const IKE_OPTIONS: ValueType<IIpsecVpnTunnels.IkeVersion>[] = [
  {
    label: 'IKEv1',
    value: 'IKEv1',
  },
  {
    label: 'IKEv2',
    value: 'IKEv2',
  },
  {
    label: 'IKE FLEX',
    value: 'IKE_FLEX',
  },
];

export const ENCRYPTION_ALGORITHM_OPTIONS: ValueType<IIpsecVpnTunnels.EncryptionAlgorithm>[] =
  [
    {
      label: 'AES128',
      value: 'AES128',
    },
    {
      label: 'AES256',
      value: 'AES256',
    },
    {
      label: 'AES_GCM128',
      value: 'AES_GCM128',
    },
    {
      label: 'AES_GCM192',
      value: 'AES_GCM192',
    },
    {
      label: 'AES_GCM256',
      value: 'AES_GCM256',
    },
  ];

export const DIGEST_ALGORITHM_OPTIONS: ValueType<IIpsecVpnTunnels.DigestAlgorithm>[] =
  [
    {
      label: 'SHA-1',
      value: 'sha1',
    },
    {
      label: 'SHA-256',
      value: 'sha-256',
    },
    {
      label: 'SHA-384',
      value: 'sha-384',
    },
    {
      label: 'SHA-512',
      value: 'sha-512',
    },
  ];

export const DIFFIE_HELLMAN_OPTIONS: ValueType<IIpsecVpnTunnels.DiffieHellmanGroup>[] =
  [
    {
      label: 'DH2',
      value: 'dh2',
    },
    {
      label: 'DH5',
      value: 'dh5',
    },
    {
      label: 'DH14',
      value: 'dh14',
    },
    {
      label: 'DH15',
      value: 'dh15',
    },
    {
      label: 'DH16',
      value: 'dh16',
    },
    {
      label: 'DH19',
      value: 'dh19',
    },
    {
      label: 'DH20',
      value: 'dh20',
    },
    {
      label: 'DH21',
      value: 'dh21',
    },
  ];

export const INITIAL_STATE = {
  tunnelDialogOpen: false,
  userDialogOpen: false,
  selectedUser: null,
  selectedTunnel: null,
};

export const INITIAL_SET_UP_IPSEC_VPN: IIpsecVpnTunnels.IpSecVpnTunnelValues = {
  localSubnets: '',
  name: '',
  remoteEndpoint: '',
  remoteSubnets: '',
  localEndpoint: '80.40.212.14',
  localSubnet: '172.10.16.0/24',
  preSharedKey: generatePreSharedKey(),
  ikeVersion: IKE_OPTIONS[1],
  perfectForwardSecrecy: true,
  encryptionAlgorithm: ENCRYPTION_ALGORITHM_OPTIONS[1],
  diffieHellmanGroup: DIFFIE_HELLMAN_OPTIONS[2],
  digestAlgorithm: DIGEST_ALGORITHM_OPTIONS[1],
  tunnelDFPolicy: DF_POLICY_OPTIONS[0],
  tunnelDFGroup: DIFFIE_HELLMAN_OPTIONS[2],
  tunnelEncryptionAlgorithm: ENCRYPTION_ALGORITHM_OPTIONS[1],
  tunnelDigestAlgorithm: DIGEST_ALGORITHM_OPTIONS[1],
  ikeSATimeLife: 28800,
  tunnelSALifeTime: 3600,
  dpdProbeInterval: 60,
  isEnabled: true,
};

export const NetworkSpeed = [100, 200, 500, 1000];
export const NetworkSpeedWithCost = [
  { value: 200, resourceId: CostResourceIds.connectivityRateLimit200 },
  { value: 500, resourceId: CostResourceIds.connectivityRateLimit500 },
  { value: 1000, resourceId: CostResourceIds.connectivityRateLimit1000 },
];
export const NetworkSpeedOptions: ValueType<number>[] = [
  {
    label: `100 Mbps`,
    value: 100,
    resourceId: undefined,
  },
  {
    label: `200 Mbps`,
    value: 200,
    resourceId: CostResourceIds.connectivityRateLimit200,
  },
  {
    label: `500 Mbps`,
    value: 500,
    resourceId: CostResourceIds.connectivityRateLimit500,
  },
  {
    label: `1000 Mbps`,
    value: 1000,
    resourceId: CostResourceIds.connectivityRateLimit1000,
  },
];

export const InternalNetworkSubnets = ['22', '23', '24'];

export const NETWORK_TYPES_OPTIONS: ValueType<IConnectivityTypes.INetworkType>[] =
  [
    {
      value: 'NAT_ROUTED',
      label: 'services.connectivity.networks.options.types.NAT_ROUTED',
    },
    {
      value: 'ISOLATED',
      label: 'services.connectivity.networks.options.types.ISOLATED',
    },
  ];

export const NETWOTK_INITIAL_VALUES: IConnectivityTypes.INetworkFormValues = {
  name: '',
  type: NETWORK_TYPES_OPTIONS[0],
  networkAddress: `172.16.${random(1, 254)}`,
  intNetSubnet: InternalNetworkSubnets[2],
  ipPoolRange: '10-99',
};

export const getVmTableColumn = (props: any) => {
  const { t, onOpenDetails } = props;
  return [
    column.accessor('name', {
      header: <div className="fs-15 weight-normal">{t('table.head.name')}</div>,
      accessor: 'name',
      disableSort: false,
      size: 150,
      cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => {
        return (
          <span
            className="fs-14 break-word-all"
            onClick={() => onOpenDetails(vm)}
          >
            {vm.name}
          </span>
        );
      },
    }),

    column.accessor('os', {
      header: <div className="fs-15 weight-normal">{t('table.head.os')}</div>,
      sortId: 'os.name',
      disableSort: false,
      size: 170,
      cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => (
        <span className="fs-14 steel">{vm.os.name}</span>
      ),
    }),

    column.accessor('virtualCpus', {
      header: <div className="fs-15 weight-normal">{t('table.head.cpu')}</div>,
      sortId: 'virtualCpus',
      alignment: 'right',
      disableSort: false,
      cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => (
        <span className="fs-14 steel">{vm.virtualCpus}</span>
      ),
    }),

    column.accessor('ramMb', {
      header: <div className="fs-15 weight-normal">{t('table.head.ram')}</div>,
      sortId: 'ramMb',
      alignment: 'right',
      disableSort: false,
      cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => (
        <span className="fs-14 steel">{vm.ramMb / 1024}</span>
      ),
    }),
    column.accessor('totalDiskSizeGb', {
      header: (
        <div className="fs-15 weight-normal">{t('table.head.storage')}</div>
      ),
      sortId: 'totalDiskSizeGb',
      alignment: 'right',
      disableSort: false,
      cell: ({ row: { original: vm } }: ICell<IVmTypes.Vm>) => (
        <span className="fs-14 steel">{vm.totalDiskSizeGb}</span>
      ),
    }),
    column.accessor('appEntityId', {
      header: (
        <div className="fs-15 weight-normal">{t('table.head.status')}</div>
      ),
      accessor: '',
      size: 110,
      disableSort: true,
      cell: function Cell({ row: { original: vm } }: ICell<IVmTypes.Vm>) {
        const isDS = !!vm.dedicatedServer;
        const displayStatus = defineVmDisplayStatus(
          (isDS ? vm.dedicatedServer : vm) as any
        )();
        const hasError = STATUS_NAMESPACES_MAP.error.includes(displayStatus);
        const { status } = useServiceStatus(
          hasError ? 'UNKNOWN' : displayStatus || 'none'
        );

        return (
          <StatusChip className="fs-11 white" status={status}>
            {t(`services.card.content.status.${status}`) as string}
          </StatusChip>
        );
      },
    }),
    column.accessor('status', {
      header: '',
      alignment: 'right',
      size: 90,
      disableSort: true,
      cell: function Cell({ row: { original: _vm } }: ICell<IVmTypes.Vm>) {
        const [, goTo] = useUserHash();

        const isDS = !!_vm.dedicatedServer;
        const vm = isDS ? _vm.dedicatedServer : _vm;
        const route = isDS ? 'dedicated' : 'vm';

        return (
          <Button
            onClick={() => goTo(`/services/${route}/info`, { id: vm?.id })}
            size="small"
          >
            {t('common.open')}
          </Button>
        );
      },
    }),
  ];
};

export const FIREWALL_ACTION_OPTIONS: ValueType<IConnectivityTypes.IFirewallRuleAction>[] =
  [
    {
      value: 'ALLOW',
      label: 'Allow',
    },
    {
      value: 'DROP',
      label: 'Drop',
    },
    {
      value: 'REJECT',
      label: 'Reject',
    },
  ];

const portShape = Yup.object();

export const furewallRuleValidationSchemas = {
  name: Yup.string().trim().required('forms.required'),
  ports: Yup.array(portShape).min(1, 'forms.invalid.portsRequired'),
  destinations: Yup.array().min(
    1,
    'services.vm.services.dialog.errors.invalid.country.required'
  ),
  // @ts-ignore
  sourcePorts: Yup.string()
    .trim()
    // @ts-ignore
    .test({
      message: 'forms.invalid',
      test: (val: string) => {
        const str = val?.trim() || '';
        if (!str) return true;
        return validator.numberRangesWithComma(val);
      },
    }),
  // @ts-ignore
  destinationPorts: Yup.string()
    .trim()
    // @ts-ignore
    .test({
      message: 'forms.invalid',
      test: (val: string) => {
        const str = val?.trim() || '';
        if (!str) return true;
        return validator.numberRangesWithComma(val);
      },
    }),
  protocol: Yup.object(),
};

export const FWR_STYLES = {
  tab: { maxWidth: remCalc(350) },
  stateSwitcher: { marginLeft: remCalc(-10) },
};
