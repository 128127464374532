import { billingCyclesService } from 'services';
import { BaseMobxInstanceDecorator } from './utilities';

export const billingCycle = new BaseMobxInstanceDecorator<
  IVmTypes.IBillingCircle,
  true
>({
  instanceName: 'billingCycle',
  requests: {
    get: billingCyclesService.getBillingCycles,
  },
  initialState: [],
});
