import { showSystemMessage } from 'utils/global';
import { coreInfService } from 'services';
import { ICoreInfrastructure, IUser } from 'core-infrastructure';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export const core = new BaseMobxInstanceDecorator<ICoreInfrastructure, false>({
  instanceName: 'core',
  requests: {
    get: () => coreInfService.get({ include: 'serviceMonitoring' }),
    create: async (payload) => {
      const res = await coreInfService.create(payload);
      showSystemMessage('services.core.main.create.success', 'success');
      return res;
    },
    remove: async (deletionReason) => {
      const res = await coreInfService.removeCore(deletionReason);
      showSystemMessage('services.core.main.remove.success', 'success');
      return res as any;
    },
  },
  // @ts-ignore
  initialState: null,
});

export type ICoreHandler = BaseDecorator<ICoreInfrastructure, false>;

export const coreAllUsers = new BaseMobxInstanceDecorator<IUser, true>({
  instanceName: 'allCoreUsers',
  requests: {
    get: coreInfService.getAllUsers,
  },
  initialState: [],
});

export type ICoreAllUsersHandler = BaseDecorator<IUser, true>;

export const coreUsersWithFileSharePerms = new BaseMobxInstanceDecorator<
  IUser,
  true
>({
  instanceName: 'coreUsersWithFileSharePerms',
  requests: {
    get: (q) =>
      coreInfService.getUsers({
        ...q,
        include: 'fileShareUsers',
      }),
  },
  initialState: [],
});

export type ICoreUsersWithFileSharePermsHandler = BaseDecorator<IUser, true>;

export const coreAdmins = new BaseMobxInstanceDecorator<IUser, true>({
  instanceName: 'coreAdmins',
  requests: {
    get: coreInfService.getAdmins,
    create: async (payload) => {
      const res = await coreInfService.createAdmin(payload);
      showSystemMessage('services.core.admins.create.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const { id: _id, ..._payload } = payload;
      const res = await coreInfService.updateUser(id, _payload);
      showSystemMessage('services.core.admins.update.success', 'success');
      return res;
    },
    remove: async (id) => {
      const res = await coreInfService.deleteUser(id);
      showSystemMessage('services.core.admins.delete.success', 'success');
      return res;
    },
  },
  initialState: [],
});

export type ICoreAdminsHandler = BaseDecorator<IUser, true>;

export const selectedCoreAdmin = new BaseMobxInstanceDecorator<IUser, false>({
  instanceName: 'selected-core-admin',
  requests: {
    get: (q) => coreInfService.getUser(q.id),
  },
  initialState: {} as IUser,
});

export type ISelectedCoreAdminHandler = BaseDecorator<IUser, false>;

export const coreUsers = new BaseMobxInstanceDecorator<IUser, true>({
  instanceName: 'coreUsers',
  requests: {
    get: coreInfService.getUsers,
    create: async (payload) => {
      const res = await coreInfService.createUser(payload);
      showSystemMessage('services.core.users.create.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const { id: _id, ..._payload } = payload;
      const res = await coreInfService.updateUser(id, _payload);
      showSystemMessage('services.core.users.update.success', 'success');
      return res;
    },
    remove: async (id) => {
      const res = await coreInfService.deleteUser(id);
      showSystemMessage('services.core.users.delete.success', 'success');
      return res;
    },
  },
  initialState: [],
});

export type ICoreUsersHandler = BaseDecorator<IUser, true>;

export const selectedCoreUser = new BaseMobxInstanceDecorator<IUser, false>({
  instanceName: 'selected-core-user',
  requests: {
    get: (q) => coreInfService.getUser(q.id),
  },
  initialState: {} as IUser,
});

export type ISelectedCoreUserHandler = BaseDecorator<IUser, false>;
