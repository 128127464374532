import * as React from 'react';
import { autorun } from 'mobx';
import { BaseDecorator, BaseState } from 'states/types';

export function useStateHandler<T, M extends boolean>(
  decorator: BaseDecorator<T, M>
) {
  const [decor, setDecor] = React.useState(decorator);

  React.useEffect(() => {
    autorun(() => {
      setDecor(decorator);
    });
  }, [
    JSON.stringify(decorator.data),
    JSON.stringify(decorator.meta),
    decorator.isRequesting,
    decorator.dataReceived,
    JSON.stringify(decorator.updateIds),
  ]);

  return decor;
}

export function useSimpleStateHandler<P>(
  decorator: BaseState<P>
): BaseState<P> {
  const [decor, setDecor] = React.useState(decorator);

  React.useEffect(() => {
    autorun(() => {
      setDecor(decorator);
    });
  }, [JSON.stringify(decorator.state)]);

  return decor;
}
