import * as React from 'react';
import { equals } from 'ramda';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import DefaultLogo from 'assets/images/logo.svg';
import { Row, Col, Button, Input, ColorPicker } from 'elements';
import { isString } from 'utils';
import { partnersService } from 'services';
import { useAsync } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { validationSchema, DEFAULT_THEME_COLORS } from './constants';
import {
  getInitialProfileSettings,
  IDefaultValues,
  isDirtyTheme as checkIfDirtyTheme,
} from './helpers';
import { Holder, Content, Preview, InputWrapper } from './Styled';

const OBSERVERS = {
  currentUser: StateHandlers.currentUser,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { currentUser } = props;
  const { t } = useTranslation();
  const initialValues = getInitialProfileSettings(currentUser.data?.partner);

  const { execute } = useAsync<IPartnerTypes.Partner>(
    // @ts-ignore
    partnersService.updateCurrentProfileInfo
  );

  const fetchCurrentUser = React.useCallback(
    () => StateHandlers.fetchAllAccountData({ shouldApplyTheme: true }),
    []
  );

  const updateProfile = React.useCallback(
    (values: IDefaultValues) =>
      execute(
        {
          primaryColor: values.primaryColor.replace('#', ''),
          secondaryColor: values.secondaryColor.replace('#', ''),
          supportInfo: values.supportInfo.replace('#', ''),
        },
        values.logo
      ).then(fetchCurrentUser),
    []
  );

  const {
    values,
    setFieldValue,
    handleSubmit,
    handleChange,
    isSubmitting,
    submitCount,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: updateProfile,
  });

  const handleChangeColor = React.useCallback(
    (field: string) => (color: string) => setFieldValue(field, color),
    []
  );

  const onDrop = React.useCallback((files: File[]) => {
    // Do something with the files
    setFieldValue('logo', files[0]);
  }, []);

  const onRemoveLogo = React.useCallback(
    () => setFieldValue('logo', undefined),
    []
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: { 'image/jpeg': [], 'image/png': [], 'image/jpg': [] },
    multiple: false,
  });

  const partnerLogo = React.useMemo(
    () =>
      (values.logo
        ? isString(values.logo)
          ? values.logo
          : URL.createObjectURL(values.logo as Blob)
        : DefaultLogo) || DefaultLogo,
    [values.logo]
  );

  const hasBgHexError = React.useMemo(
    () => !!submitCount && !!errors.secondaryColor,
    [submitCount, errors.secondaryColor]
  );

  const isDirtyTheme = checkIfDirtyTheme({
    primary: values.primaryColor,
    appBar: values.secondaryColor,
  });

  return (
    <Holder>
      <h3 className="mb-30">{t('settings.page.appearance.title') as string}</h3>
      <Content>
        <div className="mb-30">
          {t('settings.page.appearance.content') as string}
        </div>
        <form onSubmit={handleSubmit}>
          <Row columnSpacing={3} alignItems="center" className="mb-40">
            <Col>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Preview
                  isActive={isDragActive}
                  hasValue={!!values.logo}
                  style={{
                    backgroundImage: `url(${partnerLogo || ''})`,
                    backgroundColor: hasBgHexError
                      ? initialValues.secondaryColor
                      : values.secondaryColor,
                  }}
                />
              </div>
            </Col>
            <Col>
              <Row columnSpacing={2}>
                <Col>
                  <Button onClick={() => open()}>
                    {t('settings.page.appearance.buttons.updateLogo')}
                  </Button>
                </Col>
                {values.logo && (
                  <Col>
                    <Button
                      variant="outlined"
                      color="default"
                      onClick={onRemoveLogo}
                    >
                      {t('settings.page.appearance.buttons.removeLogo')}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>

          <Input
            name="supportInfo"
            value={values.supportInfo}
            onChange={handleChange}
            error={!!submitCount && !!errors.supportInfo}
            helperText={errors.supportInfo}
            label="forms.supportInfo"
            className="mb-30"
          />

          <Row
            columnSpacing={4}
            className="mb-40 full-width"
            alignItems="flex-end"
          >
            <Col xs={4}>
              <InputWrapper>
                <ColorPicker
                  name="primaryColor"
                  value={values.primaryColor}
                  label={t('forms.primaryColor')}
                  onChange={handleChangeColor('primaryColor')}
                  error={!!submitCount && !!errors.primaryColor}
                  helperText={errors.primaryColor}
                />
              </InputWrapper>
            </Col>
            <Col xs={4}>
              <InputWrapper>
                <ColorPicker
                  name="secondaryColor"
                  value={values.secondaryColor}
                  label={t('forms.secondaryColor')}
                  onChange={handleChangeColor('secondaryColor')}
                  error={hasBgHexError}
                  helperText={errors.secondaryColor}
                />
              </InputWrapper>
            </Col>
            {isDirtyTheme && (
              <Col xs={4}>
                <Button
                  disabled={isSubmitting}
                  className="full-width"
                  variant="outlined"
                  color="default"
                  onClick={() => {
                    setFieldValue('primaryColor', DEFAULT_THEME_COLORS.primary);
                    setFieldValue(
                      'secondaryColor',
                      DEFAULT_THEME_COLORS.appBar
                    );
                  }}
                >
                  {t('settings.page.appearance.buttons.resetColors')}
                </Button>
              </Col>
            )}
          </Row>
          <Row columnSpacing={2}>
            <Col>
              <Button
                type="submit"
                disabled={isSubmitting || equals(values, initialValues)}
              >
                {t('settings.page.appearance.buttons.saveChanges')}
              </Button>
            </Col>
          </Row>
        </form>
      </Content>
    </Holder>
  );
});

const Appearance = () => <View {...OBSERVERS} />;

export default Appearance;
