import { showSystemMessage } from 'utils/global';
import { coreInfService, maintenanceService, rdsService } from 'services';
import { IRds, IRdsGeoipAccessPayload, IRdsUser } from 'rds';
import { IUser } from 'core-infrastructure';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export const rds = new BaseMobxInstanceDecorator<IRds, false>({
  instanceName: 'rds',
  requests: {
    get: rdsService.get,
    create: async () => {
      const res = await rdsService.deploy();
      showSystemMessage('services.rds.notify.create.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const res = await rdsService.updateMaintenance(id as number, payload);
      showSystemMessage('services.rds.users.notify.change.success', 'success');
      return res;
    },
    remove: async (deletionReason) => {
      const res = await rdsService.remove(deletionReason);
      showSystemMessage('services.rds.notify.delete.success', 'success');
      return res;
    },
  },
  additionalRequests: {
    reboot: rdsService.reboot,
    setGeoipRestriction: async (payload: IRdsGeoipAccessPayload) => {
      const res = await rdsService.setGeoipRestriction(payload);
      showSystemMessage(
        'services.rds.notify.limitAccess.update.success',
        'success'
      );
      return res;
    },
  },
  // @ts-ignore
  initialState: null,
});

export type IRdsHandler = BaseDecorator<IRds, false>;

export const rdsUsers = new BaseMobxInstanceDecorator<IUser, true>({
  instanceName: 'rdsUsers',
  requests: {
    get: (query) =>
      coreInfService.getUsers({
        ...query,
        include: 'rdsUser',
      }),
    // @ts-ignore
    create: async (payload) => {
      const res = await rdsService.update(payload);
      showSystemMessage('services.rds.users.notify.change.success', 'success');
      return res;
    },
    remove: async (id) => {
      const res = await rdsService.removeUserRds(id);
      showSystemMessage('services.rds.users.notify.disable.success', 'success');
      return res;
    },
  },
  initialState: [],
});

export type IRdsUsersHandler = BaseDecorator<IUser, true>;

export const activeRdsUsers = new BaseMobxInstanceDecorator<IRdsUser, true>({
  instanceName: 'rdsActiveUsers',
  requests: {
    get: () => rdsService.getActiveUsers({}),
  },
  initialState: [],
});

export type IRdsActiveUsersHandler = BaseDecorator<IRdsUser, true>;

export const rdsAllUsers = new BaseMobxInstanceDecorator<IUser, true>({
  instanceName: 'allRdsUsers',
  requests: {
    get: (query) =>
      coreInfService.getUsers({
        ...query,
        include: 'rdsUser',
      }),
  },
  initialState: [],
});

export type IRdsAllUsersHandler = BaseDecorator<IUser, true>;

export const rdsReboots = new BaseMobxInstanceDecorator<
  IMaintenanceWindow,
  true
>({
  instanceName: 'rdsReboots',
  requests: {
    get: maintenanceService.getList,
    // @ts-ignore
    create: rdsService.createNewReboot,
    // @ts-ignore
    update: rdsService.updateReboot,
    // @ts-ignore
    remove: rdsService.removeReboot,
  },
  initialState: [],
});

export type IRdsRebootsHandler = BaseDecorator<IMaintenanceWindow, true>;
