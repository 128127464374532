import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Dialog,
  Button,
  Row,
  Col,
  Alert,
  DialogProps,
  PrimaryTextSpan,
  PrimaryTextH5,
  CustomListUl,
} from 'elements';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { usePrice, useTask } from 'hooks';
import { numberToCurrency, remCalc, round } from 'utils';
import Slider from 'components/SliderWithInputController';
import { CostResourceIds } from 'enums';
import { getFSStorageValidationSchema } from './helpers';

type IValues = {
  storage: number;
};

const displayPrice = (price: number) =>
  numberToCurrency(round(price, 2), false);

const OBSERVERS = {
  core: StateHandlers.core,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: DialogProps<IValues> & IViewProps) => {
  const { t } = useTranslation();
  const { onSave, core, ...restProps } = props;
  const prices = usePrice([
    CostResourceIds.coreBase,
    CostResourceIds.coreGBStorage,
  ]);
  const task = useTask(core.data?.task);

  const initialDiskSizeGb = core.data?.fileShareDiskSizeGb || 0;

  const baseFee = prices[CostResourceIds.coreBase]?.monthly;

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    setValues,
    resetForm,
    submitCount,
    setFieldValue,
  } = useFormik({
    initialValues: { storage: initialDiskSizeGb },
    validationSchema: getFSStorageValidationSchema(initialDiskSizeGb),
    validateOnMount: false,
    onSubmit: onSave as any,
  });

  React.useEffect(() => {
    if (!restProps.open) {
      resetForm();
    } else {
      setValues({ storage: initialDiskSizeGb });
    }
  }, [restProps.open, initialDiskSizeGb]);

  const storagePrice =
    prices[CostResourceIds.coreGBStorage]?.monthly * values.storage;

  const hasError = !!submitCount && !!errors.storage;
  const isDisabledSubmit = core.isRequesting || task.isTaskActive;

  return (
    <Dialog
      {...restProps}
      maxWidth="md"
      fullWidth
      title={t('services.core.file-shares.dialog.storage.edit.title')}
      handleSubmit={handleSubmit}
      contentProps={{ style: { overflow: 'visible' } }}
      actions={
        <>
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={props.onClose}
          >
            {t('common.cancel')}
          </Button>
          <Button disabled={isDisabledSubmit} type="submit">
            {t('common.save')}
          </Button>
        </>
      }
    >
      <Row columnSpacing={2}>
        <Col md={7} xs={12} className="pr-50">
          <div className="steel fs-14 mb-25">
            {t('services.core.file-shares.dialog.storage.edit.subtitle')}
          </div>
          <div className="mb-20">
            <Slider
              name="storage"
              label={t(
                'services.core.file-shares.dialog.storage.edit.slider.label'
              )}
              value={values.storage}
              onInputChange={handleChange}
              onSliderChange={(ev, value) => setFieldValue('storage', value)}
              error={hasError}
              helperText={hasError && errors.storage}
              sliderProps={{
                defaultValue: initialDiskSizeGb,
                step: 1,
                min: 10,
                max: 1000,
                marks: [
                  {
                    value: 10,
                    label: 10,
                  },
                  { value: 1000, label: 1000 },
                ],
              }}
            />
          </div>
          <Alert severity="info">
            {t('services.core.file-shares.dialog.invalid.onlyIncrease')}
          </Alert>
        </Col>

        <Col md={5} xs={12}>
          <Row
            direction="column"
            justifyContent="space-between"
            columnSpacing={2}
            style={{ minHeight: remCalc(400) }}
          >
            <Col className="full-width">
              <PrimaryTextH5 className="mb-25">
                {t('costsInfo.title')}
              </PrimaryTextH5>
              <Row
                justifyContent="space-between"
                columnSpacing={2}
                className="fs-10 steel uppercase mb-15"
              >
                <Col xs={8}>
                  <span>{t('costsInfo.head.serviceName')}</span>
                </Col>
                <Col xs={4} className="text-right">
                  <span>{t('costsInfo.head.price')}</span>
                </Col>
              </Row>
              <h5 className="mb-15">{t('costsInfo.coreInfrastructure')}</h5>
              <div className="custom-list">
                <CustomListUl>
                  <li className="mb-15">
                    <div className="flex justify-between">
                      <span>{t('costsInfo.baseFee')}</span>
                      <span className="pl-10 steel">
                        {displayPrice(baseFee)}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="flex justify-between">
                      <span>
                        {t('costsInfo.fsStorage', { gb: values.storage })}
                      </span>
                      <span className="pl-10 steel">
                        {displayPrice(storagePrice)}
                      </span>
                    </div>
                  </li>
                </CustomListUl>
              </div>
            </Col>
            <Col className="full-width">
              <div className="flex justify-between align-center mb-10">
                <h5 className="fs-17">{t('costsInfo.totalMonthly')}</h5>
                <PrimaryTextSpan className="fs-20 bold">
                  {displayPrice(baseFee + storagePrice)}
                </PrimaryTextSpan>
              </div>
              <div className="steel fs-12 mb-10">
                {t('costsInfo.inChfExclVat')}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
});

const FileShareStorageDetailsDialog = (props: DialogProps<IValues>) => (
  <View {...props} {...OBSERVERS} />
);

export default FileShareStorageDetailsDialog;
