import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Props as DateRangePickerProps } from 'react-daterange-picker';
import { Dayjs, getCurrentLang, remCalc, utcDayjs } from 'utils';
import { GLOBAL_DATE_FORMAT } from 'enums';
import { styled } from './utilities';
import { Col, Row } from './Grid';
import { MuiIcons } from './MuiIcons';
import { IconButton } from './Button';
import { PickerLabel, SDateRangePicker } from './DatePicker';
import { DateInput } from './DateInput';
import { default as Popover } from './Popover';
import Moment from 'moment';

import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment as any);

export type IDefinedDateRanges =
  | 'today'
  | 'lastMonth'
  | 'currentMonth'
  | 'thisWeek'
  | 'lastWeek'
  | 'currentYear';

export interface IValue {
  startDate: string;
  endDate: string;
}

export type IDateChangeCallback = (dates: {
  startDate: Dayjs;
  endDate: Dayjs;
}) => void;

type Props = Omit<DateRangePickerProps, 'value'> & {
  ranges?: IDefinedDateRanges[];
  value: IValue;
  label?: string;
  displayClassName?: string;
  closeOnSelect?: boolean;
  selectionType?: 'range' | 'single';
  onChange: IDateChangeCallback;
};

const DisplayHolder = styled('div')(({ theme }) => ({
  height: remCalc(48),
  lineHeight: remCalc(46),
  padding: remCalc(0, 5, 0, 20),
  borderRadius: remCalc(6),
  backgroundColor: theme.palette.common.white,
  minWidth: remCalc(235),
  '& ._dateInput': {
    textAlign: 'center',
    width: remCalc(80),
    height: remCalc(46),
  },
}));
const defaultProps = {
  ranges: [],
  selectionType: 'range',
};

const MuiDateRangePicker: React.FunctionComponent<Props> = (_props) => {
  const props = { ...defaultProps, ..._props };
  const value = useMemo(() => {
    return {
      startDate: utcDayjs(props.value.startDate).toDate(),
      endDate: utcDayjs(props.value.endDate).toDate(),
    };
  }, [JSON.stringify(props.value)]);

  const momentValue = useMemo(() => {
    if (!props.value) return undefined;
    return moment.range(
      moment(props.value.startDate).add(-1, 'day') || null,
      moment(props.value.endDate).add(-1, 'day') || null
    );
  }, [JSON.stringify(props.value)]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = useCallback(
    (dates: any) => {
      const newValue = {
        startDate: utcDayjs(dates.start.add(1, 'day')).startOf('day'),
        endDate: utcDayjs(dates.end.add(1, 'day')).endOf('day'),
      };
      props.onChange(newValue);
      props.closeOnSelect && handleClose();
    },
    [props.closeOnSelect, props.onChange]
  );

  const onSelectInput = useCallback(
    (propName: 'startDate' | 'endDate') => (date: Dayjs) => {
      const method = propName === 'startDate' ? 'startOf' : 'endOf';
      const newValue = {
        ...value,
        [propName]: utcDayjs(date.add(1, 'day'))[method]('day'),
      };
      // @ts-ignore
      props.onChange(newValue);
      props.closeOnSelect && handleClose();
    },
    [props.closeOnSelect, props.onChange]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'date-range-picker-popover' : undefined;

  return (
    <div>
      {props.label && <PickerLabel>{props.label}</PickerLabel>}
      <DisplayHolder className={cn('bordered pointer', props.displayClassName)}>
        <Row
          justifyContent="space-between"
          alignItems="center"
          className="full-width"
        >
          <Col>
            <Row alignItems="center">
              <DateInput
                className="_dateInput"
                value={utcDayjs(value.startDate).format(GLOBAL_DATE_FORMAT)}
                onSelect={onSelectInput('startDate')}
              />
              <div className="pl-5 pr-5">{' - '}</div>
              <DateInput
                className="_dateInput"
                value={utcDayjs(value.endDate)
                  .endOf('day')
                  .format(GLOBAL_DATE_FORMAT)}
                onSelect={onSelectInput('endDate')}
              />
            </Row>
          </Col>
          <Col>
            <IconButton className="ml-5" onClick={handleClick}>
              <MuiIcons.EventNote className="fs-14" />
            </IconButton>
          </Col>
        </Row>
      </DisplayHolder>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: 'pt-5 pl-5 pr-5 pb-20',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SDateRangePicker
          firstOfWeek={1}
          numberOfCalendars={2}
          selectionType={'range'}
          singleDateRange
          value={momentValue}
          onSelect={onSelect}
          locale={getCurrentLang()}
        />
      </Popover>
    </div>
  );
};

export default MuiDateRangePicker;
