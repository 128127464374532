import React, { useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import qs from 'query-string';
import { t } from 'i18next';
import { Steps } from 'intro.js-react';
import { Button, Loader, Row, Table, Alert, Col } from 'elements';
import { usePermissions, useQuery, useUserHash, useState } from 'hooks';
import { confirm, noop, showSystemMessage, openInNewTab } from 'utils';
import { ALL_TEST_IDS } from 'enums';
import { DelayedRender } from 'hocs';
import { IGuideTooltipName } from 'guide-tooltips';
import { tenantsService } from 'services';
import { ICustomerUpdatePayload, ICustomer } from 'customer-shapes';
import * as StateHandlers from 'states';
import ManageBillingDialog from 'components/ManageBillingDialog';
import ConfirmDeleteTextDialog from 'components/ConfirmDeleteTextDialog';
import {
  DEFAULT_PARAMS,
  getColumns,
  getGeneralStepGuideConfig,
  getDoneCreatingTenantOptions,
  baseGuideNamespaces,
} from './constants';
import TenantDetailsDialog from './TenantDetailsDialog';
import InviteCustomer from './InviteCustomer';
import Filters from './Filters';
import HeaderActions from './HeaderActions';
import QuotasDialog from './QuotasDialog';
import { isAllGuideStepsCompleted, parseQueryToFetch } from './helpers';
import { mapAccountStatus } from '../partners/helpers';
import { EmptyMessageHolder } from './Styled';

const OBSERVERS = {
  tenants: StateHandlers.tenants,
  selectedTenant: StateHandlers.currentTenant,
  guideTooltips: StateHandlers.guideTooltips,
  currentUser: StateHandlers.currentUser,
  assignments: StateHandlers.badgesAssignment,
};

type Props = typeof OBSERVERS;

const INITIAL_STATE: ITenantsTypes.IState = {
  tenant: null,
  quotaTenantId: null,
  isOpen: false,
  manageBillingDialog: false,
  deleteTenantDialog: false,
  inviteCustomerDialog: false,
};

const TenantsView = observer((props: Props) => {
  const { selectedTenant, tenants, currentUser, guideTooltips, assignments } =
    props;
  const { query, queryStr, changeQuery } = useQuery<ITenantsTypes.Query>();
  const [hash, changeUrl] = useUserHash();
  const createdTenantIntro = useRef();
  const { permissions, permissionsReceived } = usePermissions('TENANTS');
  const { permissions: pricingPermissions } = usePermissions('PRICING');
  const [state, handleStateChange] =
    useState<ITenantsTypes.IState>(INITIAL_STATE);
  const isNew = state.tenant === null;
  const q = useMemo(() => ({ ...DEFAULT_PARAMS, ...query }), [queryStr]);

  const tenantsCount = tenants.data.length;

  const reachedTenantsLimit =
    (currentUser.data.partner?.maxTenants || 0) <= tenants.data.length;

  const isFirstGuideStepCompleted =
    guideTooltips.data.partnerShowCreateTenantButton?.status === 'SEEN';

  const generalStepGuideConfig = React.useMemo(
    () => getGeneralStepGuideConfig(t, isFirstGuideStepCompleted),
    [isFirstGuideStepCompleted]
  );

  const doneCreatingTenantOptions = React.useMemo(
    () => getDoneCreatingTenantOptions(t),
    []
  );

  const isEnabledFirstTenantCreatedGuide =
    guideTooltips.dataReceived &&
    guideTooltips.data.partnerCreatedFirstTenant?.status !== 'SEEN';

  const isAllStepsCompleted = isAllGuideStepsCompleted(guideTooltips.data);

  const isEnabledBaseGuide = guideTooltips.dataReceived && !isAllStepsCompleted;

  const onSeenGuide = useCallback(
    (keys: IGuideTooltipName[]) => {
      if (!isAllStepsCompleted) {
        guideTooltips.update(
          null,
          keys.map((key) => ({
            name: key,
            status: 'SEEN',
          }))
        );
      }
    },
    [isAllStepsCompleted]
  );

  const handleManageDialog = React.useCallback(
    (manageBillingDialog: boolean) => {
      handleStateChange({ manageBillingDialog });
    },
    []
  );

  const fetchTenants = useCallback(
    () => tenants.get(parseQueryToFetch({ ...query, include: ['address'] })),
    [queryStr, query]
  );

  const fetchCurrentTenant = React.useCallback((id: number) => {
    return selectedTenant
      .get({ id, include: ['billingEmails', 'address'] })
      .then(() => handleManageDialog(true));
  }, []);

  React.useEffect(() => {
    fetchTenants();
  }, [queryStr]);

  const switchUserSession = useCallback((customer: ICustomer) => {
    changeUrl(`/${customer.shortName}/services/all`, {});
    return StateHandlers.fetchAllAccountData({
      tsn: customer.shortName,
      shouldApplyTheme: true,
    });
  }, []);

  const openUserInNewTab = useCallback(
    (customer: ICustomer) => {
      openInNewTab(
        `/${hash}/${customer.shortName}/services/all?${qs.stringify({
          tsn: customer.shortName,
        })}`
      );
    },
    [hash]
  );

  const updateTenantBillingEmails = React.useCallback(
    (payload: ICustomerUpdatePayload) =>
      selectedTenant.update(selectedTenant.data.id, payload).then(() => {
        handleManageDialog(false);
        showSystemMessage(
          'tenants.dialog.manageBilling.notify.update.success',
          'success'
        );
      }),
    [selectedTenant.data]
  );

  const onModalClose = useCallback(
    () =>
      handleStateChange({
        tenant: null,
        isOpen: false,
        manageBillingDialog: false,
        inviteCustomerDialog: false,
      }),
    []
  );

  const onSubmit = useCallback(
    (values: any) =>
      tenants.create(values).then((res) => {
        fetchTenants();
        onModalClose();
        return res;
      }),
    [queryStr]
  );

  const onSort = useCallback(
    (orderBy: ITenantsTypes.IOrderBy, orderType: OrderTypes) => {
      changeQuery({
        orderType,
        orderBy,
      });
    },
    [queryStr, changeQuery]
  );

  const gotToPricing = useCallback(
    (tenant: number) => changeUrl('/tenant-pricing', { tenant }),
    []
  );

  const onConfirmDelete = useCallback(
    (tenant: ICustomer) =>
      handleStateChange({ deleteTenantDialog: true, tenant }),
    []
  );

  const handleQuotasDialog = React.useCallback(
    (id: number | null) => handleStateChange({ quotaTenantId: id }),
    []
  );

  const onConfirmUndoDelete = useCallback(
    (tenant: ICustomer) =>
      confirm({
        title: t('tenants.table.confirm.undoDelete.title'),
        content: t('tenants.table.confirm.undoDelete.content', {
          name: tenant.name,
        }),
        successLabel: 'tenants.table.confirm.undoDelete.buttons.undo',
        onSuccess: () =>
          tenantsService
            .undoDeletion(tenant.id)
            .then(fetchTenants)
            .catch((err) => showSystemMessage(err.message, 'error')),
        onCancel: noop,
      }),
    [queryStr]
  );

  const handleAccountEnable = React.useCallback((customer: ICustomer) => {
    const enable = customer.isDisabled;
    confirm({
      title: t(`tenants.table.confirm.${enable ? 'enable' : 'disable'}.title`),
      content: t(
        `tenants.table.confirm.${enable ? 'enable' : 'disable'}.content`,
        { name: customer.name }
      ),
      onSuccess: () =>
        selectedTenant
          .update(customer.id, { isDisabled: !customer.isDisabled })
          .then(() => {
            showSystemMessage(
              `tenants.notify.${enable ? 'enable' : 'disable'}.success`,
              'success'
            );
          }),
      onCancel: () => undefined,
    });
  }, []);

  const onDeleteTenant = useCallback(
    (id: number) =>
      tenants.remove(id).then(() => {
        handleStateChange({ deleteTenantDialog: false, tenant: null });
        fetchTenants();
      }),
    []
  );

  const hasInvoiceSettings = !!currentUser.data.partner?.invoiceSettings;

  const columns = useMemo(
    () =>
      getColumns({
        t,
        switchUser: switchUserSession,
        openUserInNewTab: openUserInNewTab,
        goToTenantPricing: gotToPricing,
        pricingPermissions,
        tenantsCount,
        hasInvoiceSettings,
        permissions,
        onUndoDeletion: onConfirmUndoDelete,
        onDelete: onConfirmDelete,
        onManageBilling: permissions.canManage ? fetchCurrentTenant : undefined,
        accountStatus: mapAccountStatus(currentUser.data.partner?.status),
        assignments: assignments.data,
        handleAccountEnable,
        onManageQuotas: handleQuotasDialog,
      }),
    [
      tenantsCount,
      permissions.canManage,
      queryStr,
      selectedTenant.data,
      hasInvoiceSettings,
      openUserInNewTab,
      currentUser.data.partner?.status,
      assignments.data,
      onConfirmDelete,
      handleAccountEnable,
    ]
  );

  const openInviteDialog = React.useCallback(
    () => handleStateChange({ inviteCustomerDialog: true }),
    []
  );

  const onCreateTenant = useCallback(() => {
    handleStateChange({ isOpen: true, tenant: null });
  }, [reachedTenantsLimit, currentUser.data.partner?.maxTenants]);

  if (permissionsReceived && !permissions.canView) {
    return (
      <EmptyMessageHolder justifyContent="center" alignItems="center">
        <div>
          <div className="fs-20 mb-35">
            {t('common.permissions.restricted') as string}
          </div>
          <Button onClick={() => changeQuery({}, '/', true)}>
            {t('app.goHome')}
          </Button>
        </div>
      </EmptyMessageHolder>
    );
  }

  const renderContent = () => {
    if (tenants.errors.get) {
      return (
        <Alert severity="error" testId={ALL_TEST_IDS.tenants.errorGetTenant}>
          {tenants.errors.get || (t('common.requestError') as string)}
        </Alert>
      );
    }
    return (
      <>
        <DelayedRender delay={1500}>
          <Steps
            enabled={isEnabledFirstTenantCreatedGuide}
            steps={doneCreatingTenantOptions.steps}
            initialStep={doneCreatingTenantOptions.initialStep}
            onExit={() => onSeenGuide(['partnerCreatedFirstTenant'])}
            options={doneCreatingTenantOptions.options}
            // @ts-ignore
            ref={createdTenantIntro}
          />
        </DelayedRender>
        <Table
          testId={ALL_TEST_IDS.tenants.table}
          query={q}
          className={cn({ disabled: tenants.isRequesting })}
          columns={columns}
          hasSorting
          data={tenants.data}
          onSort={onSort}
          params={tenants.meta}
          isLoading={!tenants.dataReceived}
          onPageChange={changeQuery}
          onUpdateTable={fetchTenants}
        />
      </>
    );
  };

  return (
    <>
      <Row justifyContent="space-between" alignItems="center" className="mb-20">
        <Col>
          <h3 className="lh-default">{t('tenants.title') as string}</h3>
        </Col>
        <Col>
          <HeaderActions
            onCreateTenant={onCreateTenant}
            openInviteDialog={openInviteDialog}
          />
        </Col>
      </Row>
      <Filters query={query} onChange={changeQuery} />
      {!(tenants.dataReceived && guideTooltips.dataReceived) ? (
        <Loader />
      ) : (
        renderContent()
      )}

      {guideTooltips.dataReceived && (
        <DelayedRender delay={1500}>
          <Steps
            enabled={isEnabledBaseGuide}
            steps={generalStepGuideConfig.steps}
            initialStep={generalStepGuideConfig.initialStep}
            onExit={() => onSeenGuide(baseGuideNamespaces)}
            options={generalStepGuideConfig.options}
          />
        </DelayedRender>
      )}

      <TenantDetailsDialog
        open={state.isOpen}
        hasInvoiceSettings={hasInvoiceSettings}
        isNew={isNew}
        onSubmit={onSubmit}
        initialValues={state.tenant}
        onClose={onModalClose}
        isRequesting={tenants.isRequesting}
        isTicketingEnabled={!!currentUser.data?.partner?.isTicketingEnabled}
      />
      <InviteCustomer
        open={state.inviteCustomerDialog}
        onClose={onModalClose}
        onSave={() => undefined}
      />
      <QuotasDialog
        open={!!state.quotaTenantId}
        tenantId={state.quotaTenantId as number}
        onClose={() => handleQuotasDialog(null)}
        onSave={() => undefined}
      />
      <ManageBillingDialog
        source="tenant"
        translationNamespace="tenants"
        open={state.manageBillingDialog}
        isSaving={selectedTenant.isRequesting || tenants.isRequesting}
        onSave={updateTenantBillingEmails}
        onClose={onModalClose}
        subtitle=""
      />
      <ConfirmDeleteTextDialog
        open={state.deleteTenantDialog}
        title={t('tenants.table.confirm.delete.title')}
        subtitle={t('tenants.table.confirm.delete.content', {
          name: state.tenant?.name,
        })}
        isPending={tenants.isRequesting}
        onSave={() => onDeleteTenant(state.tenant?.id as number)}
        onClose={() =>
          handleStateChange({ deleteTenantDialog: false, tenant: null })
        }
      />
    </>
  );
});

const TenantsMain = () => <TenantsView {...OBSERVERS} />;

export default TenantsMain;
