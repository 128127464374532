import * as React from 'react';
import cn from 'classnames';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import ContentCopy from '@mui/icons-material/ContentCopy';
import copy from 'copy-to-clipboard';
import { fakeEmptyFetch, isString, remCalc, throttle } from 'utils';

type IClickElement = React.MouseEvent<any> & string;

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ ...props.classes, popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    color: theme.palette.common.black,
    lineHeight: 1,
    padding: remCalc(8),
    maxWidth: remCalc(350),
  },
  [`& .${tooltipClasses.arrow}`]: {
    borderColor: theme.palette.common.white,
    '&:before': {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
    },
  },
}));

const defaultProps = {
  enableDoubleClick: true,
};

export const CopyTooltip = (
  _props: React.PropsWithChildren<{
    contentToCopy?: string;
    enableDoubleClick?: boolean;
    className?: string;
    style?: Record<string, any>;
  }>
) => {
  const props = { ...defaultProps, ..._props };
  const { children, contentToCopy, enableDoubleClick, className, style } =
    props;

  const [isNotifying, handleNotify] = React.useState(false);

  const coppiedText = contentToCopy || children || '';

  const showNotify = React.useCallback(async () => {
    handleNotify(true);
    await fakeEmptyFetch({}, 1000);
    handleNotify(false);
  }, []);

  const onCopy = React.useCallback(
    (ev: IClickElement) => {
      if (ev.stopPropagation) {
        ev.stopPropagation();
      }
      copy(coppiedText as string, {
        format: 'text/plain',
        onCopy: showNotify,
      });
    },
    [children, coppiedText]
  );

  const onDoubleClick = React.useCallback(
    throttle(() => {
      if (enableDoubleClick && isString(coppiedText)) {
        onCopy(coppiedText as IClickElement);
      }

      return undefined;
    }, 1000),
    [enableDoubleClick, coppiedText, onCopy]
  );

  const testId = cn({ notifyEffect: isNotifying }) || undefined;
  const notifyBgClass = cn({ 'success-bg-fade-out': isNotifying });
  const notifyBorderClass = cn({ 'success-border-fade-out': isNotifying });

  return (
    <LightTooltip
      placement="right"
      arrow
      classes={{ tooltip: notifyBgClass, arrow: notifyBorderClass }}
      data-testid={testId}
      title={
        <ContentCopy
          className={cn('steel fs-16 pointer')}
          onClick={onCopy}
          data-testid="light-copy-tooltip-title"
        />
      }
    >
      <span
        onDoubleClick={onDoubleClick}
        data-testid="light-copy-tooltip-content"
        className={cn('pointer', className)}
        style={style}
      >
        {children}
      </span>
    </LightTooltip>
  );
};

export const ConditionalTooltip = (
  props: TooltipProps & { condition: boolean; Tooltip?: any }
) => {
  const { condition, Tooltip: CustomTooltip, ...rest } = props;
  const MTooltip = CustomTooltip || Tooltip;
  if (condition) {
    return <MTooltip {...rest}>{rest.children}</MTooltip>;
  }
  return rest.children;
};

function parseSups(str: string, splitter = ';') {
  const splited = str.split(splitter);

  return (
    <div className="pt-5 pb-5">
      {splited.map((el, ind) => (
        <div key={`${el}-${ind}`} className="fs-10" style={{ marginBottom: 3 }}>
          {el.trim()}
        </div>
      ))}
    </div>
  );
}

export { Tooltip, parseSups };
