import * as React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Dialog,
  Button,
  Row,
  Col,
  Alert,
  DialogProps,
  PrimaryTextSpan,
  PrimaryTextH5,
  CustomListUl,
} from 'elements';
import { usePrice, useTask, useStateHandler } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { numberToCurrency, remCalc, round } from 'utils';
import Slider from 'components/SliderWithInputController';
import { CostResourceIds } from 'enums';
import { getProfileDisktorageValidationSchema } from './constants';
import { groupUserByType, parseUsersToForm } from './helpers';

type IValues = {
  storage: number;
};

const displayPrice = (price: number) =>
  numberToCurrency(round(price, 2), false);

const RdsStorageDialog = observer((props: DialogProps<IValues>) => {
  const { t } = useTranslation();
  const { onSave, ...restProps } = props;
  const rds = useStateHandler(StateHandlers.rds);
  const allUsers = useStateHandler(StateHandlers.rdsAllUsers);
  const prices = usePrice([
    CostResourceIds.rdsBase,
    CostResourceIds.rdsProfileDisks,
    CostResourceIds.rdsLightUser,
    CostResourceIds.rds2faLicense,
    CostResourceIds.rdsStandardUser,
    CostResourceIds.rdsLightUser,
  ]);
  const task = useTask(rds.data?.task);

  const initialDiskSizeGb = rds.data?.profileDisk?.sizeGb || 0;

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    setValues,
    resetForm,
    submitCount,
    setFieldValue,
  } = useFormik({
    initialValues: { storage: initialDiskSizeGb },
    validationSchema: getProfileDisktorageValidationSchema(initialDiskSizeGb),
    validateOnMount: false,
    onSubmit: onSave as any,
  });

  React.useEffect(() => {
    if (!restProps.open) {
      resetForm();
    } else {
      allUsers.get({ page: 1, perPage: 1000, orderType: 'asc' });
      setValues({ storage: initialDiskSizeGb });
    }
  }, [restProps.open, initialDiskSizeGb]);

  const quantity = React.useMemo(
    () => groupUserByType(parseUsersToForm(allUsers.data || [])),
    [JSON.stringify(allUsers.data)]
  );

  const baseFee = prices[CostResourceIds.rdsBase]?.monthly;

  const lightUserFee =
    prices[CostResourceIds.rdsLightUser]?.monthly * quantity.type[1];

  const standardUserFee =
    prices[CostResourceIds.rdsStandardUser]?.monthly * quantity.type[2];

  const faFee =
    prices[CostResourceIds.rds2faLicense]?.monthly * quantity.faLicense;

  const storagePrice =
    prices[CostResourceIds.rdsProfileDisks]?.monthly * values.storage;

  const hasError = !!submitCount && !!errors.storage;
  const isDisabledSubmit = rds.isRequesting || task.isTaskActive;

  return (
    <Dialog
      {...restProps}
      maxWidth="md"
      fullWidth
      title={t('services.rds.storage.edit.title')}
      handleSubmit={handleSubmit}
      contentProps={{ style: { overflow: 'visible' } }}
      actions={
        <>
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={props.onClose}
          >
            {t('common.cancel')}
          </Button>
          <Button disabled={isDisabledSubmit} type="submit">
            {t('common.save')}
          </Button>
        </>
      }
    >
      <Row columnSpacing={2}>
        <Col md={7} xs={12} className="pr-50">
          <div className="steel fs-14 mb-25">
            {t('services.rds.storage.edit.subtitle')}
          </div>
          <div className="mb-20">
            <Slider
              name="storage"
              label={t('services.rds.storage.edit.slider.label')}
              value={values.storage}
              onInputChange={handleChange}
              onSliderChange={(ev, value) => setFieldValue('storage', value)}
              error={hasError}
              helperText={hasError && errors.storage}
              sliderProps={{
                defaultValue: initialDiskSizeGb,
                step: 1,
                min: 10,
                max: 1000,
                marks: [
                  {
                    value: 10,
                    label: 10,
                  },
                  { value: 1000, label: 1000 },
                ],
              }}
            />
          </div>
          <Alert severity="info">{t('services.rds.ssdStorage.warning')}</Alert>
        </Col>

        <Col md={5} xs={12}>
          <Row
            direction="column"
            justifyContent="space-between"
            columnSpacing={2}
            style={{ minHeight: remCalc(400) }}
          >
            <Col className="full-width">
              <PrimaryTextH5 className="mb-25">
                {t('costsInfo.title')}
              </PrimaryTextH5>
              <Row
                justifyContent="space-between"
                columnSpacing={2}
                className="fs-10 steel uppercase mb-15"
              >
                <Col xs={8}>
                  <span>{t('costsInfo.head.serviceName')}</span>
                </Col>
                <Col xs={4} className="text-right">
                  <span>{t('costsInfo.head.price')}</span>
                </Col>
              </Row>
              <h5 className="mb-15">{t('costsInfo.remoteDesktop')}</h5>
              <div className="custom-list">
                <CustomListUl>
                  <li className="mb-10">
                    <Row>
                      <Col xs={7}>
                        <span>{t('costsInfo.baseFee')}</span>
                      </Col>
                      <Col xs={2} />
                      <Col xs={3} className="text-right steel">
                        <span>{numberToCurrency(baseFee, false)}</span>
                      </Col>
                    </Row>
                  </li>
                  <li className="mb-10">
                    <Row>
                      <Col xs={7}>
                        <span>
                          {t('costsInfo.remoteDesktop.lightAppUsers')}
                        </span>
                      </Col>
                      <Col xs={2} className="text-right steel">
                        <span>{quantity.type[1]}</span>
                      </Col>
                      <Col xs={3} className="text-right steel">
                        <span>{numberToCurrency(lightUserFee, false)}</span>
                      </Col>
                    </Row>
                  </li>
                  <li className="mb-10">
                    <Row>
                      <Col xs={7}>
                        <span>
                          {t('costsInfo.remoteDesktop.multiAppUsers')}
                        </span>
                      </Col>
                      <Col xs={2} className="text-right steel">
                        <span>{quantity.type[2]}</span>
                      </Col>
                      <Col xs={3} className="text-right steel">
                        <span>{numberToCurrency(standardUserFee, false)}</span>
                      </Col>
                    </Row>
                  </li>
                  <li className="mb-10">
                    <Row>
                      <Col xs={7}>
                        <span>{t('costsInfo.remoteDesktop.2faLicense')}</span>
                      </Col>
                      <Col xs={2} className="text-right steel">
                        <span>{quantity.faLicense}</span>
                      </Col>
                      <Col xs={3} className="text-right steel">
                        <span>{numberToCurrency(faFee, false)}</span>
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <div className="flex justify-between">
                      <span>
                        {t('costsInfo.profileDiskStorage', {
                          gb: values.storage,
                        })}
                      </span>
                      <span className="pl-10 steel">
                        {displayPrice(storagePrice)}
                      </span>
                    </div>
                  </li>
                </CustomListUl>
              </div>
            </Col>
            <Col className="full-width">
              <div className="flex justify-between align-center mb-10">
                <h5 className="fs-17">{t('costsInfo.totalMonthly')}</h5>
                <PrimaryTextSpan className="fs-20 bold">
                  {numberToCurrency(
                    R.sum([
                      baseFee,
                      lightUserFee,
                      standardUserFee,
                      faFee,
                      +storagePrice,
                    ]),
                    false
                  )}
                </PrimaryTextSpan>
              </div>
              <div className="steel fs-12 mb-10">
                {t('costsInfo.inChfExclVat')}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
});

export default RdsStorageDialog;
