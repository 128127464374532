import * as React from 'react';
import { CopyTooltip } from 'elements';
import { useTranslation } from 'react-i18next';
import { IDnsService } from 'dns';
import { AnyFunc } from 'global-shapes';

type Props = IDnsService & {
  onSeeAll: AnyFunc;
};

const Dns = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { zones, zonesQty, onSeeAll } = props;

  return (
    <div className="fs-14">
      <div className="primary-dark">{t('services.card.content.domains')}</div>
      {zonesQty === 0 && (
        <div className="steel fs-14">
          {t('services.dns.domains.noZones') as string}
        </div>
      )}
      {zones &&
        zones.map((zone) => (
          <div className="steel ellipsis" key={zone.id}>
            <CopyTooltip>{zone.domainName}</CopyTooltip>
          </div>
        ))}
      {zonesQty >= 9 && (
        <div className="primary mb-15 pointer" onClick={onSeeAll}>
          {t('services.card.content.seeAllDomains')}
          {' >>'}
        </div>
      )}
    </div>
  );
};

export default Dns;
