import { BaseMobxInstanceDecorator } from './utilities';
import { usersService } from 'services';
import { ICurrentUser } from 'auth-shapes';
import { getExpirationInfo, Emitter } from 'utils';
import { CustomEvents } from 'enums';
import { guideTooltips } from './guideTooltips';
import { appSettings } from './appSettings';
import { getUserSession } from './helpers';
import { BaseDecorator } from './types';

export const fetchAdditionalAccountData = async () => {
  await appSettings.get();
  await guideTooltips.get();
  Emitter.emit(CustomEvents.initExpireSessionDialog, getExpirationInfo());

  return undefined;
};

export const currentUser = new BaseMobxInstanceDecorator<ICurrentUser, false>({
  instanceName: 'account',
  requests: {
    get: getUserSession,
    update: (id, payload) =>
      usersService.updatePartially('me', payload, {
        headers: {
          'X-JBBF-PARTNER-SHORTNAME': undefined,
          'X-JBBF-TENANT-SHORTNAME': undefined,
        },
      }),
  },
  initialState: {} as ICurrentUser,
});

export const fetchAllAccountData = async (query?: any) => {
  await currentUser.get(query);
  return await fetchAdditionalAccountData();
};

export type ICurrentUserHandler = BaseDecorator<ICurrentUser, false>;
