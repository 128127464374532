import * as React from 'react';
import * as R from 'ramda';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { remCalc } from 'utils';
import { styled } from './utilities';
import ArrowDropDownIcon from '@mui/icons-material/ExpandMore';
import ArrowDropUp from '@mui/icons-material/ExpandLess';
import Popover from '@mui/material/Popover';
import { Row, Col } from './Grid';
import { ListItemButton, List } from './List';

type IProps = {
  options: ValueType[];
  value: string;
  onChange: (value: string, option?: ValueType) => void;
  className?: string;
  preValueContent?: any;
  closeOnSelect?: boolean;
};

const Option = styled(ListItemButton)(({ theme }) => {
  return {
    '&._selected': {
      color: theme.palette.primary.main,
    },
  };
});

const STYLES_DISPLAY = {
  height: remCalc(22),
  lineHeight: remCalc(22),
};

export function Dropdown(props: IProps) {
  const {
    options,
    value,
    onChange,
    className,
    closeOnSelect,
    preValueContent,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const open = !!anchorEl;

  const selected = R.find<ValueType>((el) => el.value === value)(options);

  const onOptionClick = React.useCallback(
    (val: string) => () => {
      onChange(val);
      if (closeOnSelect) {
        setAnchorEl(null);
      }
    },
    [closeOnSelect, onChange]
  );

  return (
    <div>
      <Row
        alignItems="center"
        justifyContent="space-between"
        className={cn('lh-1 pointer', className)}
        style={STYLES_DISPLAY}
        columnSpacing={1}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        {preValueContent}
        <Col xs style={STYLES_DISPLAY}>
          {selected?.label ? t(selected.label) : t('forms.placeholders.select')}
        </Col>
        {open ? (
          <ArrowDropUp className="fs-16" />
        ) : (
          <ArrowDropDownIcon className="fs-16" />
        )}
      </Row>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List>
          {options.map((option, index) => (
            <Option
              key={option.value}
              className={cn('fs-14', { _selected: value === option.value })}
              onClick={onOptionClick(option.value)}
            >
              {t(option.label)}
            </Option>
          ))}
        </List>
      </Popover>
    </div>
  );
}
