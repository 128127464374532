import React, { useCallback } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useTranslation, Trans } from 'react-i18next';
import { Alert, Button, Row, LinearTable, Paper } from 'elements';
import { confirm } from 'utils';
import { useQuery, usePermissions, useState, useStateHandler } from 'hooks';
import AddPublicService from './AddPublicService';
import EditPubService from './dialogs/EditPubService';
import { getPublicServicesColumns } from 'pages/services/constants';

type IState = {
  isLoading: boolean;
  isCreating: boolean;
  open: boolean;
  selectedPort?: IVmTypes.VMPort;
};

const INIT_STATE: IState = {
  isLoading: false,
  isCreating: false,
  open: false,
  selectedPort: undefined,
};

const PublicServices = observer(() => {
  const { t } = useTranslation();
  const {
    permissions: { canManage: isEditable },
  } = usePermissions('SERVICES');
  const { query, queryStr } = useQuery();
  const [state, handleState] = useState<IState>(INIT_STATE);
  const {
    get: getPorts,
    isLoading,
    isRequesting,
    remove: removePort,
    data: ports,
  } = useStateHandler(StateHandlers.vmPorts);
  const vmDetails = useStateHandler(StateHandlers.vmDetails);

  const handleOpenState = useCallback(
    (open: boolean) => () => {
      handleState({ open });
    },
    []
  );

  const fetchVm = useCallback(async () => {
    if (query.id) {
      return await vmDetails.get({
        id: query.id,
        include: ['serviceMonitoring', 'serviceSnapshot'],
      });
    }
  }, [queryStr]);

  const fetchServices = useCallback(() => {
    if (query.id) {
      getPorts({ vmId: query.id });
      fetchVm();
    }
  }, [queryStr]);

  React.useEffect(() => {
    if (query.id) {
      getPorts({ vmId: query.id });
    }
  }, []); // eslint-disable-line

  const onEdit = React.useCallback((port: IVmTypes.VMPort) => {
    handleState({ selectedPort: port });
  }, []);

  const onDelete = useCallback(
    (port: IVmTypes.VMPort) => {
      const onSuccess = () =>
        removePort({ vmId: query.id, portId: port.id }).then(() => {
          fetchServices();
        });

      if (query.id) {
        return confirm({
          title: 'services.vm.services.confirm.delete',
          content:
            ports.length <= 1 ? (
              <Alert severity="warning">
                {t('services.vm.services.confirm.delete.warning')}
              </Alert>
            ) : (
              <div className="steel fs-14">
                <Trans
                  i18nKey={`services.vm.services.confirm.delete.content`}
                  values={{ name: port.name }}
                  components={{
                    1: <strong />,
                  }}
                />
              </div>
            ),
          onSuccess,
          onCancel: () => undefined,
        });
      }
    },
    [queryStr, query.id, ports.length]
  );

  return (
    <Paper className="p-30">
      <Row className="mb-25" alignItems="center" justifyContent="space-between">
        <div className="uppercase fs-14 bolder">
          {t('services.vm.services.title')}
        </div>
        {isEditable && (
          <Button size="small" onClick={handleOpenState(true)}>
            {t('services.vm.services.addButton')}
          </Button>
        )}
      </Row>

      <div className="mb-25">
        <LinearTable
          data={ports}
          cellClassName={cn('fs-14')}
          className={cn({ disabled: isLoading || isRequesting })}
          columns={getPublicServicesColumns({
            t,
            onDelete,
            isEditable,
            hideGeo: false,
            showNetwork: true,
            onEdit,
          })}
          noDataMessage="services.dialog.vms.table.noPorts"
          onUpdateTable={fetchServices}
        />
      </div>
      <AddPublicService
        onClose={handleOpenState(false)}
        open={state.open}
        onSave={fetchServices}
      />
      <EditPubService
        onClose={() => handleState({ selectedPort: undefined })}
        open={!!state.selectedPort}
        initialValues={state.selectedPort}
        onSave={fetchServices}
      />
    </Paper>
  );
});

export default PublicServices;
