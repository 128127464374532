import * as React from 'react';
import { IUserAvatar } from 'rds';

const UserTextAvatar = (props: IUserAvatar) => {
  return (
    <>
      <div className="fs-14 thin">
        {props.firstName} {props.lastName}
      </div>
      <div className="fs-12 steel thin">
        {props.username}@{props.coreDomain}
      </div>
    </>
  );
};

export default UserTextAvatar;
