import * as React from 'react';
import {
  IconButton,
  Popover,
  PopoverProps,
  MuiIcons,
  DialogTab,
  DialogTabs,
  TabPanel,
  PrimaryTextDiv,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { useState } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import UsageServiceTab from './UsageServiceTab';
import PrepaidServicesTab from './PrepaidServicesTab';
import { PriceDetailsHolder } from './Styled';

const OBSERVERS = {
  actualUsage: StateHandlers.usageSelected,
};

type IProps = PopoverProps & {
  accountId?: number;
  showTabs?: boolean;
};

type IState = {
  tab: number;
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { accountId, showTabs, actualUsage, ...rest } = props;

  const { t } = useTranslation();
  const [state, handleState] = useState<IState>({ tab: 0 });

  const handleTabChange = React.useCallback((event: any, tab: number) => {
    handleState({ tab });
  }, []);

  return (
    <Popover {...rest}>
      <PriceDetailsHolder>
        <div className="flex justify-between align-center mb-10">
          <PrimaryTextDiv className="fs-20 bolder">
            {actualUsage.data?.name}
          </PrimaryTextDiv>
          {rest.onClose && (
            <IconButton
              // @ts-ignore
              onClick={(ev) => rest.onClose(ev, 'backdropClick')}
              size="small"
            >
              <MuiIcons.Close className="fs-20" />
            </IconButton>
          )}
        </div>
        {showTabs ? (
          <>
            <DialogTabs
              value={state.tab}
              onChange={handleTabChange}
              aria-label="usage details"
            >
              <DialogTab index={0} label={t('usage.details.tabs.current')} />
              <DialogTab index={1} label={t('usage.details.tabs.prepaid')} />
            </DialogTabs>

            <TabPanel index={0} value={state.tab}>
              <UsageServiceTab accountId={accountId} />
            </TabPanel>
            <TabPanel index={1} value={state.tab}>
              <PrepaidServicesTab />
            </TabPanel>
          </>
        ) : (
          <UsageServiceTab accountId={accountId} />
        )}
      </PriceDetailsHolder>
    </Popover>
  );
});

const Main = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default Main;
