import React from 'react';
import cn from 'classnames';
import { IUser } from 'core-infrastructure';
import { AnyFunc } from 'global-shapes';
import { useTask } from 'hooks';
import {
  Chip,
  CopyTooltip,
  MuiIcons,
  Tooltip,
  createColumnHelper,
} from 'elements';
import copy from 'copy-to-clipboard';
import { showSystemMessage } from 'utils';

interface IArgs {
  t: AnyFunc;
  onDelete?: (user: IUser) => void;
  onEdit?: (user: IUser) => void;
  isFetching?: boolean;
  isEditable?: boolean;
  coreDomain: string;
}

interface ITooltipLimiterProps {
  limit: number;
  text: string;
  children: React.ReactElement;
}

function TooltipLimiter<P extends ITooltipLimiterProps>(props: P) {
  const { limit, text, children } = props;
  const onCopy = React.useCallback(
    () =>
      copy(text, {
        format: 'text/plain',
        onCopy: () => showSystemMessage('common.copiedToClipboard', 'info'),
      }),
    [text]
  );

  if (text.length >= limit) {
    return (
      <Tooltip
        title={
          <div className="flex align-center">
            {text}{' '}
            <MuiIcons.ContentCopy
              className={cn('fs-16 pointer ml-5')}
              onClick={onCopy}
            />
          </div>
        }
        placement="top"
      >
        {children}
      </Tooltip>
    );
  }
  return <CopyTooltip contentToCopy={text}>{children}</CopyTooltip>;
}

const column = createColumnHelper<IUser>();

export const USERS_ADMINS_COLUMNS = ({
  t,
  onDelete,
  onEdit,
  isFetching,
  coreDomain,
  isEditable,
}: IArgs) => [
  column.accessor('firstName', {
    header: <div className="fs-12 steel">{t('table.head.firstName')}</div>,
    disableSort: false,
  }),

  column.accessor('lastName', {
    header: <div className="fs-12 steel">{t('table.head.lastName')}</div>,
    disableSort: false,
  }),

  column.accessor('username', {
    header: <div className="fs-12 steel">{t('table.head.username')}</div>,
    disableSort: false,
    cell: ({ row: { original: admin } }: ICell<IUser>) => {
      const fullDomainName = `${admin.username}@${coreDomain}`;
      return (
        <TooltipLimiter text={fullDomainName} limit={20}>
          <div>
            {admin.username}
            <span className="steel">@{coreDomain}</span>
          </div>
        </TooltipLimiter>
      );
    },
  }),

  column.accessor('email', {
    header: <div className="fs-12 steel">{t('table.head.email')}</div>,
    disableSort: true,
    cell: ({ row: { original: admin } }: ICell<IUser>) => (
      <div className="break-word-all">{admin.email || '-'}</div>
    ),
  }),

  column.accessor('id', {
    header: '',
    disableSort: true,
    cell: function Cell({ row: { original } }: ICell<IUser>) {
      const task = useTask(original.task);

      return (
        <div
          className={cn('flex align-center justify-end full-width', {
            disabled: isFetching || task.isTaskActive,
          })}
        >
          {task.isFailed && (
            <Chip
              status="error"
              className="mr-20"
              tooltip={task.error && task.error.message}
            >
              {t('services.card.content.failed')}
            </Chip>
          )}
          {isEditable && onEdit && !task.isFailed && (
            <MuiIcons.Edit
              className="fs-20 steel mr-15 pointer"
              onClick={() => onEdit(original)}
            />
          )}
          {isEditable && (
            <MuiIcons.Delete
              className="fs-20 steel pointer"
              onClick={() => onDelete && onDelete(original)}
            />
          )}
        </div>
      );
    },
  }),
];
