import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { FormikProps } from 'formik';
import { usePermissions, useVmBillingDisplay, usePrice } from 'hooks';
import {
  Row,
  Col,
  Switch,
  PrimaryTextH5,
  Select,
  Alert,
  PrimaryTextSpan,
} from 'elements';
import * as TYPES from './types';
import { CostInfoList } from 'pages/services/components/Styled';
import * as UTILS from 'utils';
import { observer } from 'mobx-react-lite';
import { CostResourceIds, getBillingCycleOptions } from 'enums';

type IProps = FormikProps<TYPES.IFormValues> & { spec: IVmVcdTypes.VmSpec };

const roundCost = (val: number) => UTILS.round(val, 2);

const CostBreakdown = observer((props: IProps) => {
  const { values, setFieldValue, spec } = props;
  const { t } = useTranslation();
  const { isProvider, isEvaluation } = usePermissions();
  const pricing = usePrice([
    CostResourceIds.virtualServerBase,
    CostResourceIds.virtualServerVirtualCpus,
    CostResourceIds.virtualServerRamMb,
    CostResourceIds.virtualServerGBStorage,
    CostResourceIds.winLicense,
  ]);

  const basePrice = pricing[CostResourceIds.virtualServerBase]?.monthly;
  const perGBStorage = pricing[CostResourceIds.virtualServerGBStorage]?.monthly;
  const ramPrice = pricing[CostResourceIds.virtualServerGBStorage]?.monthly;
  const cpuPrice = pricing[CostResourceIds.virtualServerGBStorage]?.monthly;
  const winLicensePrice = pricing[CostResourceIds.winLicense]?.monthly;

  const { showCostInfo, showEnableSwitcher, discount } = useVmBillingDisplay({
    billingInterval: values.billingCycle?.value,
    isRootProvider: isProvider,
    isUsageEnabled: values.isUsageEnabled,
    isNew: true,
  });

  const BILLING_OPTIONS = React.useMemo(
    () => getBillingCycleOptions(isEvaluation),
    [isEvaluation]
  );

  const calculatedPrices = React.useMemo(() => {
    const isWindows = values.templateId?.familyId === 1;

    const baseFee = roundCost(basePrice);
    const cpu = roundCost(cpuPrice * (spec?.virtualCpus || 0));
    const ram = roundCost(ramPrice * ((spec?.ramMb || 0) / 1024));
    const winLicense = isWindows ? winLicensePrice : 0;

    const mainDisk = roundCost(spec?.osDisk?.sizeGb * perGBStorage);
    let allExtraDiskCost = 0;
    const extraDisks =
      spec?.extraDisks?.reduce((acc, val) => {
        const cost = roundCost(val.sizeGb * perGBStorage || 0);
        allExtraDiskCost = roundCost(allExtraDiskCost + cost);
        acc[val.diskId.toString()] = cost;
        return acc;
      }, {} as Record<string, number>) || {};

    const total = roundCost(
      R.sum([baseFee, cpu, ram, winLicense, mainDisk, allExtraDiskCost])
    );

    return {
      fees: {
        base: baseFee,
        cpu,
        ram,
        winLicense,
      },
      mainDisk,
      extraDisks,
      allExtraDiskCost,
      total,
    };
  }, [
    JSON.stringify(values),
    JSON.stringify(spec),
    perGBStorage,
    ramPrice,
    cpuPrice,
    winLicensePrice,
  ]);

  const monthCount = React.useMemo(
    () => UTILS.buildMonthCountByBillingInterval(values.billingCycle?.value),
    [values.billingCycle?.value]
  );

  const clearPrice = calculatedPrices.total * monthCount;

  const discountPrice = clearPrice * discount;

  const totalPrice = UTILS.round(clearPrice - discountPrice, 2);

  return (
    <Row direction="column" style={{ height: '100%' }}>
      {showEnableSwitcher && (
        <Col>
          <div className="mb-5">
            <Switch
              label={t('costsInfo.billing.enable.label')}
              checked={values.isUsageEnabled}
              onCheck={(isUsageEnabled) =>
                setFieldValue('isUsageEnabled', isUsageEnabled)
              }
            />
          </div>
          <div className="fs-12 steel mb-15">
            {t('costsInfo.billing.enable.subtitle')}
          </div>
        </Col>
      )}
      {showCostInfo && (
        <Col xs>
          <Row
            direction="column"
            justifyContent="space-between"
            columnSpacing={2}
            style={{ minHeight: '100%' }}
          >
            <Col className="full-width" xs>
              <PrimaryTextH5 className="mb-25 primary">
                {t('costsInfo.title') as string}
              </PrimaryTextH5>
              <Select
                onChange={(interval) => setFieldValue('billingCycle', interval)}
                options={BILLING_OPTIONS}
                value={values.billingCycle}
                className="mb-15"
              />
              <div className="steel fs-14 mb-15">
                {t('costsInfo.billingCircle.subtext') as string}
              </div>
              <Row
                justifyContent="space-between"
                columnSpacing={2}
                className="fs-10 steel uppercase mb-15"
              >
                <Col xs={8}>
                  <span>{t('costsInfo.head.serviceName') as string}</span>
                </Col>
                <Col xs={4} className="text-right">
                  <span>{t('costsInfo.head.price') as string}</span>
                </Col>
              </Row>
              <h5 className="mb-15">
                {t('costsInfo.virtualServer') as string}
              </h5>
              <CostInfoList className="custom-list mb-20">
                <ul>
                  <li>
                    <div className="flex justify-between">
                      <span>{t('costsInfo.baseFee') as string}</span>
                      <span className="pl-10 steel">
                        {UTILS.numberToCurrency(
                          calculatedPrices.fees.base * monthCount,
                          false
                        )}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="flex justify-between">
                      <span>
                        {
                          t('costsInfo.virtualCpus', {
                            value: spec?.virtualCpus,
                          }) as string
                        }
                      </span>
                      <span className="pl-10 steel">
                        {UTILS.numberToCurrency(
                          calculatedPrices.fees.cpu * monthCount,
                          false
                        )}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="flex justify-between">
                      <span>
                        {
                          t('costsInfo.ramMb', {
                            value: spec?.ramMb / 1024,
                          }) as string
                        }
                      </span>
                      <span className="pl-10 steel">
                        {UTILS.numberToCurrency(
                          calculatedPrices.fees.ram * monthCount,
                          false
                        )}
                      </span>
                    </div>
                  </li>
                  {values.templateId?.familyId === 1 && (
                    <li>
                      <div className="flex justify-between">
                        <span>{t('costsInfo.winLicense') as string}</span>
                        <span className="pl-10 steel">
                          {UTILS.numberToCurrency(
                            calculatedPrices.fees.winLicense * monthCount,
                            false
                          )}
                        </span>
                      </div>
                    </li>
                  )}
                  <li>
                    <div className="flex justify-between full-width">
                      <div className="flex">
                        <span
                          className="ellipsis pr-10"
                          style={{ maxWidth: UTILS.remCalc(200) }}
                        >
                          Disk 1
                        </span>
                        {spec?.osDisk?.sizeGb} GB
                      </div>

                      <span className="pl-10 steel">
                        {UTILS.numberToCurrency(
                          calculatedPrices.mainDisk * monthCount,
                          false
                        )}
                      </span>
                    </div>
                  </li>
                  {spec?.extraDisks?.map((d, ind) => (
                    <li key={d.diskId}>
                      <div className="flex justify-between full-width">
                        <div className="flex">
                          <span
                            className="ellipsis pr-10"
                            style={{ maxWidth: UTILS.remCalc(200) }}
                          >
                            Disk {ind + 2}
                          </span>
                          {d.sizeGb} GB
                        </div>

                        <span className="pl-10 steel">
                          {UTILS.numberToCurrency(
                            (calculatedPrices.extraDisks[d.diskId] || 0) *
                              monthCount,
                            false
                          )}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </CostInfoList>
            </Col>
            <Col className="full-width" justifyItems="flex-end">
              {!!discount && (
                <div className="flex justify-between align-center">
                  <div className="fs-12 mb-10">
                    {
                      t('costsInfo.discount', {
                        value: discount * 100,
                      }) as string
                    }
                  </div>
                  <div className="bolder">
                    {UTILS.numberToCurrency(-discountPrice, false)}
                  </div>
                </div>
              )}

              <div className="flex justify-between align-center">
                <h5 className="fs-17">
                  {
                    t(
                      `costsInfo.totalCost.${values.billingCycle?.value}`
                    ) as string
                  }
                </h5>
                <PrimaryTextSpan className="fs-20 bold">
                  {UTILS.numberToCurrency(totalPrice, false)}
                </PrimaryTextSpan>
              </div>
              <div className="fs-12 steel mb-10">
                {t('costsInfo.chfExclVat') as string}
              </div>
              {isEvaluation && (
                <Alert className="fs-12 mb-15" severity="info">
                  {t('costsInfo.evalAccount.notify') as string}
                </Alert>
              )}
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
});

export default CostBreakdown;
