import * as React from 'react';
import * as StateHandlers from 'states';
import { useUserHash, useAuth, usePermissions } from 'hooks';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { REDIRECT_LINKS } from 'enums';
import qs from 'query-string';
import AppSnackbar from '../AppSnackbar';
import DialogUtilsComponents from './DialogUtilsComponents';

const Layout = () => {
  const navigate = useNavigate();
  const { isAuthorized } = useAuth();
  const { currentAccessLevel } = usePermissions();
  const [hash, , shouldHaveHash] = useUserHash();

  const { pathname } = useLocation();

  const { currentUser } = StateHandlers;

  React.useEffect(() => {
    const isOnRoute = !!pathname.split('/').filter((el) => el).length;
    if (currentUser.dataReceived && isAuthorized) {
      if (!isOnRoute || (shouldHaveHash && !pathname.includes(hash))) {
        const level = REDIRECT_LINKS[currentAccessLevel.toUpperCase()];
        level && navigate(`${hash}${level.link}${qs.stringify(level.query)}`);
      }
    }
  }, [currentUser.dataReceived]);

  return (
    <>
      <Outlet />

      <AppSnackbar />

      <DialogUtilsComponents />
    </>
  );
};

export default Layout;
