import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { deepmerge } from '@mui/utils';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NoSsr from '@mui/material/NoSsr';
import DarkLogo from './assets/images/logo-full-dark.svg';
import {
  downloadAndSetLocalization,
  Emitter,
  INewSessionColorPalette,
} from 'utils';
import { useState } from 'hooks';

import { CustomEvents, THEME } from 'enums';
import './assets/scss/global.scss';

import { Loader, Theme } from 'elements';
import AllRoutes from './components/AuthLayout/Routes';

type IState = {
  isLocalizationReceived: boolean;
  isChangingTheme: boolean;
  theme: Theme;
};

const INITIAL_APP_STATE: IState = {
  isLocalizationReceived: false,
  isChangingTheme: false,
  theme: THEME,
};

function App() {
  const [state, handleStateChange] = useState<IState>(INITIAL_APP_STATE);
  const { t } = useTranslation();

  const onChangeTheme = React.useCallback(
    async (newTheme: { palette: INewSessionColorPalette } | null) => {
      if (!newTheme) {
        return handleStateChange({
          isChangingTheme: true,
        });
      }

      const mergedConfigs = deepmerge(state.theme, { palette: newTheme });

      handleStateChange({
        theme: createTheme(mergedConfigs),
        isChangingTheme: false,
      });
    },
    [state.theme]
  );

  React.useEffect(() => {
    Emitter.on(CustomEvents.changeTheme, onChangeTheme);

    downloadAndSetLocalization(
      ['de'].includes(navigator.language) ? 'de' : 'en'
    ).then(() => {
      handleStateChange(true, 'isLocalizationReceived');
    });

    return () => {
      Emitter.off(CustomEvents.changeTheme, onChangeTheme);
    };
  }, []);

  if (!state.isLocalizationReceived) {
    return (
      <div>
        <img src={DarkLogo} alt="" />
      </div>
    );
  }

  if (state.isChangingTheme) {
    return (
      <div className="flex align-center full-width justify-center">
        <div>
          <Loader />
        </div>
        <div className="ml-20 fs-18">{t('app.applyingTheme')}</div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={state.theme}>
      <NoSsr>
        <BrowserRouter>
          <AllRoutes />
        </BrowserRouter>
      </NoSsr>
    </ThemeProvider>
  );
}

export default App;
