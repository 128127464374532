import * as React from 'react';
import { Outlet } from 'react-router-dom';
import LeftBarNavigation from 'components/LeftBarNavigation';
import { Row, Col } from 'elements';
import { NETWORKS_DEFAULT_PARAMS } from 'enums';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

const OBSERVERS = {
  connectivity: StateHandlers.connectivity,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  return (
    <Row columnSpacing={2}>
      <Col xs={12} sm={3}>
        <LeftBarNavigation
          items={['edges', 'networks', 'rules']}
          baseUrl="services/connectivity"
          params={{
            networks: NETWORKS_DEFAULT_PARAMS,
          }}
          tHash="services.connectivity"
        />
      </Col>
      <Col xs={12} sm={9}>
        <Outlet />
      </Col>
    </Row>
  );
});

const Connectivity = () => <View {...OBSERVERS} />;

export default Connectivity;
