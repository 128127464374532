import * as React from 'react';
import { Paper } from 'elements';
import { useQuery } from 'hooks';
import { Monitoring as BaseMonitoringComponent } from 'components';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

const OBSERVERS = {
  vm: StateHandlers.vmDetails,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { vm } = props;
  const { query } = useQuery<{ id: number }>();

  return (
    <Paper className="p-30">
      <BaseMonitoringComponent
        entity="vm"
        vmCount={1}
        serviceId={vm.data?.id}
        monitoringId={vm.data?.serviceMonitoring?.id}
        instance={vm.data}
        isDisabled={vm.isRequesting}
        fetchService={() =>
          vm.get({ id: query.id, include: 'serviceMonitoring' })
        }
        osType={vm.data?.os?.osFamilyId === 1 ? 'win' : 'lin'}
      />
    </Paper>
  );
});

const Monitoring = () => <View {...OBSERVERS} />;

export default Monitoring;
