import * as React from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { ValueType } from 'global-shapes';
import { VerticalBadges } from 'components';
import {
  DotsProgressSmall,
  Row,
  Col,
  OpenUserIcon,
  createColumnHelper,
} from 'elements';
import { useTask, useAuth } from 'hooks';
import { ICustomer } from 'customer-shapes';
import {
  numberToCurrency,
  openUserInPortal,
  validator,
  getBadgeStateKey,
  remCalc,
} from 'utils';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { IGuideTooltipName } from 'guide-tooltips';
import TableActions from './TableAction';

export const headerActionsState: ITenantsTypes.IHeaderActionsState = {
  bulkSchedileDialogOpen: false,
};

export const DEFAULT_PARAMS = {
  page: 1,
  perPage: 20,
  orderType: 'desc',
};

const column = createColumnHelper<ICustomer>();

export const getColumns = (props: ITenantsTypes.ITableCommonProps) => {
  const {
    switchUser,
    openUserInNewTab,
    goToTenantPricing,
    pricingPermissions,
    t,
    tenantsCount,
    onDelete,
    onManageBilling,
    hasInvoiceSettings,
    accountStatus,
    assignments,
    handleAccountEnable,
    onUndoDeletion,
    permissions,
    onManageQuotas,
  } = props;
  const isSingleTenant = tenantsCount === 1;

  return [
    column.accessor('name', {
      header: () => (
        <div className="ml-35">{t('tenants.table.head.tenantName')}</div>
      ),
      size: 300,
      maxWidth: remCalc(300),
      cell: function Cell({ row: { original: tenant } }: any) {
        const task = useTask(tenant.task);
        const { setChildSessionInfo } = useAuth();
        const entityAssignments = assignments[getBadgeStateKey(tenant)] || [];
        const inProgress = task.isTaskActive || !!tenant.deletedAt;
        const canLogUnderUser = !inProgress;

        const changeAccount = React.useCallback(
          openUserInPortal(() => {
            setChildSessionInfo({ tsn: tenant.shortName }, true);
            switchUser(tenant);
          }),
          [tenant]
        );

        return (
          <Row alignItems="center" columnSpacing={2}>
            <VerticalBadges size={8} assignments={entityAssignments} />
            <Col
              className={cn('mr-15 pointer link-hover')}
              onClick={canLogUnderUser ? changeAccount : undefined}
            >
              {tenant.name}
            </Col>
            {inProgress && (
              <Col>
                <DotsProgressSmall className="mr-10" size={20} />
              </Col>
            )}
          </Row>
        );
      },
    }),

    column.accessor('id', {
      header: '',
      disableSort: true,
      size: 50,
      maxWidth: remCalc(50),
      cell: function Cell({ row: { original: tenant } }: any) {
        const task = useTask(tenant.task);
        const inProgress = task.isTaskActive || !!tenant.deletedAt;
        const canLogUnderUser = !inProgress;

        return canLogUnderUser ? (
          <OpenUserIcon
            className={cn('fs-20 pointer')}
            onClick={openUserInPortal(() => {
              openUserInNewTab(tenant);
            })}
            id={isSingleTenant ? 'first-tenant-portal' : ''}
          />
        ) : (
          <>{''}</>
        );
      },
    }),

    column.accessor('shortName', {
      header: t('table.head.shortName'),
      disableSort: false,
      size: 250,
      maxWidth: remCalc(250),
    }),

    column.accessor('servicesQty', {
      header: t('tenants.table.head.services'),
      alignment: 'right',
      size: 150,
      maxWidth: remCalc(150),
      cell: (pr: any) => pr.renderValue(),
    }),

    column.accessor('monthlyTurnover', {
      header: () => (
        <div style={{ width: `calc(100% - ${remCalc(31)})` }}>
          {t('partners.table.head.turnover')}
        </div>
      ),
      alignment: 'right',
      size: 250,
      maxWidth: remCalc(250),
      cell: ({ row: { original: tenant } }: any) => (
        <div>{numberToCurrency(+(tenant.monthlyTurnover || 0), false)}</div>
      ),
    }),

    column.accessor('createdAt', {
      header: t('tenants.table.head.createdAt'),
      size: 150,
      cell: ({ row: { original: tenant } }: any) => (
        <span>{dayjs(tenant.createdAt).format('DD.MM.YYYY')}</span>
      ),
    }),

    column.accessor('updatedAt', {
      header: () => '',
      disableSort: true,
      size: 'auto',
      cell: ({ row: { original: tenant } }: any) => (
        <TableActions
          tenant={tenant}
          {...{
            onManageQuotas,
            switchUser,
            goToTenantPricing,
            pricingPermissions,
            onDelete,
            onUndoDeletion,
            onManageBilling,
            hasInvoiceSettings,
            accountStatus,
            handleAccountEnable,
            permissions,
          }}
        />
      ),
    }),
  ];
};

export const INITIAL_DIALOG_VALUES = {
  shortName: '',
  name: '',
  city: '',
  line1: '',
  line2: '',
  orgDomain: '',
  zipCode: '',
  countryCode: { value: 'CH', label: 'Switzerland' },
  billingEmailsEnabled: false,
  emails: [],
};

const mixedObjectStringValidationType = Yup.lazy((value) => {
  switch (typeof value) {
    case 'object':
      return Yup.object().required('forms.required');
    case 'string':
      return Yup.string().required('forms.required');
    default:
      return Yup.mixed();
  }
});

export const customerInfoSchema = Yup.object({
  shortName: Yup.string()
    .trim()
    .min(3, 'forms.invalid.min')
    .max(16, 'forms.invalid.max')
    .required('forms.required')
    .test('shortName', 'forms.invalid.onlyLettersAndNums', (val) =>
      validator.onlyLettersAndNum(val || '')
    ),
  name: Yup.string()
    .trim()
    .required('forms.required')
    .max(40, 'forms.invalid.max'),
  orgDomain: Yup.string()
    .trim()
    .test({
      message: 'forms.invalid.domainName',
      test: (val) => {
        if (!val) return true;
        return validator.isFQDN(val);
      },
    }),
});

export const billingInfoSchema = Yup.object({
  billingEmailsEnabled: Yup.boolean(),
  line1: Yup.string().when(['billingEmailsEnabled'], {
    is: (billingEmailsEnabled: boolean) => billingEmailsEnabled,
    then: Yup.string()
      .trim()
      .required('forms.required')
      .max(60, 'forms.invalid.max'),
    otherwise: Yup.string(),
  }),

  line2: Yup.string().max(60, 'forms.invalid.max'),

  zipCode: Yup.string().when(['billingEmailsEnabled'], {
    is: (billingEmailsEnabled: boolean) => billingEmailsEnabled,
    then: Yup.string()
      .trim()
      .required('forms.required')
      .max(30, 'forms.invalid.max'),
    otherwise: Yup.string(),
  }),

  city: Yup.string().when(['billingEmailsEnabled'], {
    is: (billingEmailsEnabled: boolean) => billingEmailsEnabled,
    then: Yup.string()
      .trim()
      .required('forms.required')
      .max(60, 'forms.invalid.max'),
    otherwise: Yup.string(),
  }),

  countryCode: mixedObjectStringValidationType,
  emails: Yup.array(Yup.object()).when(['billingEmailsEnabled'], {
    is: (billingEmailsEnabled: boolean) => billingEmailsEnabled,
    then: Yup.array(Yup.object()).required('forms.required'),
    otherwise: Yup.array(Yup.object()),
  }),
});

export const DEFAULT_CUSTOMER_INVITE_SETTINGS = {
  enabled: false,
  billingEnabled: false,
  inviteKey: undefined,
};

export const baseGuideNamespaces: IGuideTooltipName[] = [
  'partnerShowCreateTenantButton',
  'partnerShowInviteTenantButton',
  'partnerShowInvoicesButton',
  'partnerShowBillableItemsButton',
];

export const getGeneralStepGuideConfig = (
  t: TFunction,
  isFirstGuideStepCompleted: boolean
) => {
  const createTenantStepConfig = {
    title: t('appGuide.tenants.step.1.title'),
    element: '#create-tenant-button',
    intro: t('appGuide.tenants.step.1.content'),
    position: 'top-middle-aligned',
  };

  const steps = [
    {
      title: t('appGuide.tenants.step.3.title'),
      element: '#invite-tenant-button',
      intro: t('appGuide.tenants.step.3.content'),
      position: 'bottom-left-aligned',
    },
    {
      title: t('appGuide.tenants.step.4.title'),
      element: '#invoice-view-nav-item',
      intro: t('appGuide.tenants.step.4.content'),
      position: 'bottom-left-aligned',
    },
    {
      title: t('appGuide.tenants.step.5.title'),
      element: '#billable-items-nav-item',
      intro: t('appGuide.tenants.step.5.content'),
      position: 'bottom-left-aligned',
    },
  ];

  if (!isFirstGuideStepCompleted) {
    steps.unshift(createTenantStepConfig);
  }

  return {
    initialStep: 0,
    options: {
      tooltipPosition: 'bottom-middle-aligned',
      showBullets: false,
      exitOnOverlayClick: false,
      disableInteraction: true,
      keyboardNavigation: true,
      showStepNumbers: true,
      skipLabel: 'close',
      prevLabel: t('common.previous'),
      nextLabel: t('common.next'),
      doneLabel: t('common.close'),
      overlayOpacity: 0.7,
    },
    steps,
  };
};

export const getDoneCreatingTenantOptions = (t: TFunction) => ({
  stepsEnabled: true,
  initialStep: 0,
  steps: [
    {
      title: t('appGuide.tenants.step.2.title'),
      intro: t('appGuide.tenants.step.2.content'),
      element: '#first-tenant-portal',
      position: 'bottom-middle-aligned',
    },
  ],
  options: {
    showBullets: false,
    tooltipPosition: 'auto',
    exitOnOverlayClick: false,
    disableInteraction: true,
    showButtons: false,
    overlayOpacity: 0.4,
  },
});

export const BILLING_OPTIONS: ValueType<boolean>[] = [
  {
    label: 'tenants.options.all',
    value: true,
  },
  {
    label: 'tenants.options.disabledBilling',
    value: false,
  },
  // {
  //   label: 'tenants.options.enabledTenant',
  //   value: false,
  // },
];

export const BULk_SCHEDULE_STEPS = [
  'tenants.dialog.bulkSchedule.steps.1',
  'tenants.dialog.bulkSchedule.steps.2',
];

export const quotasValidationSchema = Yup.object({
  cpu: Yup.number()
    .max(999999, 'forms.invalid.max')
    .min(1, 'forms.invalid.min')
    .required('forms.required'),
  ram: Yup.number()
    .max(999999, 'forms.invalid.max')
    .min(1, 'forms.invalid.min')
    .required('forms.required'),
  diskSize: Yup.number()
    .max(9999999, 'forms.invalid.max')
    .min(1, 'forms.invalid.min')
    .required('forms.required'),
});
