import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogProps } from 'elements';
import * as StateHandlers from 'states';
import { useStateHandler } from 'hooks';
import VmList from 'pages/vmList/Folders/VmList';

type Props = DialogProps<IServiceGroups.GroupDetailsFormValues> & {
  initialValues?: IServiceGroups.Group;
};

const ForderInnerContentDialog = (props: Props) => {
  const { open, initialValues, onClose } = props;
  const { t } = useTranslation();
  const folderContent = useStateHandler(StateHandlers.folderContent);

  React.useEffect(() => {
    if (!open) {
      folderContent.reset();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      title={t(`folders.dialog.inner.title`)}
      fullWidth
      maxWidth="xl"
      onClose={onClose}
      keepMounted={false}
      actions={
        <>
          <Button variant="outlined" color="default" onClick={props.onClose}>
            {t('common.close')}
          </Button>
        </>
      }
    >
      <div className="steel mb-30">{t(`folders.dialog.inner.content`)}</div>
      <VmList folder={initialValues} />
    </Dialog>
  );
};

export default ForderInnerContentDialog;
