import { PALETTE } from 'enums';

interface stateMap {
  active?: string;
  pending?: string;
  blocked?: string;
}

export const textMap: stateMap = {
  active: 'active',
  pending: 'pending',
  blocked: 'inactive',
};

export const colorMap: stateMap = {
  active: PALETTE.success,
  blocked: PALETTE.error,
  pending: PALETTE.primary,
};
