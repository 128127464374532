import { remCalc } from 'utils';
import { styled } from 'elements';
import { EditableText } from 'components';

export const FSDiskSizeHolder = styled('div')(({ theme }) => ({
  height: remCalc(35),
  display: 'flex',
  alignItems: 'center',
}));

export const SEditableText = styled(EditableText)(({ theme }) => ({
  '& ._displayText': {
    color: theme.palette.primary.main,
  },
}));
