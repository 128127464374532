import * as React from 'react';
import { Col } from 'elements';
import { ICurrentUserVmDisplayType } from 'auth-shapes';
import { observer } from 'mobx-react-lite';
import FolderListItem from './FolderListItem';
import Folder from 'pages/services/components/cards/wrappers/Folder';

type IProps = {
  vmDisplayType: ICurrentUserVmDisplayType;
} & IServiceGroups.Group;

const FolderListItemCombined = observer((props: IProps) => {
  const { vmDisplayType, ...folder } = props;

  if (vmDisplayType === 'card') {
    return (
      <Col>
        <Folder {...folder} />
      </Col>
    );
  }

  return <FolderListItem {...folder} />;
});

export default FolderListItemCombined;
