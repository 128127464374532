import * as React from 'react';
import {
  Dialog,
  Button,
  DateTimePicker,
  Select,
  DialogProps,
  Row,
  Col,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { useStateHandler } from 'hooks';
import {
  findDisabledMinTime,
  findDisabledMaxTime,
  dayjs,
  validator,
} from 'utils';
import { useFormik } from 'formik';
import * as StateHandlers from 'states';
import { MAINTENANCE_OPTIONS, maintenanceValidate } from './constants';
import { SMaintenanceinput } from '../Styled';
import { buildMaintenanceInitialState } from './helpers';

const MINUTE_STEP = 5;
type IProps = DialogProps<IMonitoring.MwFormValues> & {
  currentMw?: IMonitoring.MonitoringMW;
  monitoringId: number;
};
const MaintenanceDialog = (props: IProps) => {
  const { onSave, currentMw, monitoringId, ...dialogProps } = props;
  const { t } = useTranslation();
  const maintenance = useStateHandler(StateHandlers.monitoringMaintenance);

  const isNew = !currentMw;

  const { values, setFieldValue, handleSubmit, resetForm, setValues, errors } =
    useFormik({
      initialValues: buildMaintenanceInitialState(),
      validate: maintenanceValidate,
      onSubmit: async (val) => {
        const durationMinutes = val.endAt.diff(val.startAt, 'minutes');
        const payload: IMonitoring.MwPayload = {
          startAt: val.startAt,
          durationMinutes,
          recurringInterval: val.recurringInterval?.value,
        };
        const res = isNew
          ? await maintenance.create({ monitoringId, ...payload })
          : await maintenance.update(
              { monitoringId, id: currentMw?.id },
              payload
            );
        onSave && onSave(val);
        dialogProps.onClose();
        return res;
      },
    });

  const startAt = values.startAt ? dayjs(values.startAt) : dayjs();
  const endLimitDate = startAt.add(30, 'days');

  const disabledTimeMin = findDisabledMinTime(startAt);
  const disabledTimeMax = findDisabledMaxTime(endLimitDate);

  React.useEffect(() => {
    if (!dialogProps.open) {
      resetForm();
    } else {
      setValues(buildMaintenanceInitialState(currentMw));
    }
  }, [dialogProps.open]);

  return (
    <Dialog
      {...dialogProps}
      fullWidth
      maxWidth="sm"
      title={t(
        `monitoring.maintenance.dialog.${isNew ? 'create' : 'edit'}.title`
      )}
      handleSubmit={handleSubmit}
      actions={
        <>
          <Button
            variant="outlined"
            color="default"
            onClick={dialogProps.onClose}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={maintenance.isRequesting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className="mb-20">
        {t('monitoring.maintenance.dialog.description')}
      </div>

      <Row columnSpacing={3} className="mb-25">
        <Col>
          <SMaintenanceinput>
            <Select
              label={t('forms.recurring')}
              value={values.recurringInterval}
              onChange={(recurringInterval) =>
                setFieldValue('recurringInterval', recurringInterval)
              }
              options={MAINTENANCE_OPTIONS}
            />
          </SMaintenanceinput>
        </Col>
      </Row>

      <Row columnSpacing={2}>
        <Col>
          <SMaintenanceinput>
            <DateTimePicker
              label={t('forms.dateTimeFrom')}
              maximumDate={dayjs().add(30, 'days').toDate()}
              minimumDate={dayjs().toDate()}
              value={startAt}
              onChange={(val) => {
                setFieldValue('startAt', val);
                if (validator.isEarlierThan(values.endAt, val)) {
                  setFieldValue('endAt', val.add(1, 'day'));
                }
              }}
              closeOnSelect
              minuteStep={MINUTE_STEP}
              disabledTime={() => {
                return {
                  disabledHours: disabledTimeMin.hours,
                  disabledMinutes: disabledTimeMin.minutes,
                };
              }}
            />
            {errors.startAt && (
              <div className="error fs-12 pt-5 ">
                {t(errors.startAt as string)}
              </div>
            )}
          </SMaintenanceinput>
        </Col>
        <Col>
          <SMaintenanceinput>
            <DateTimePicker
              label={t('forms.dateTimeTo')}
              maximumDate={endLimitDate.toDate()}
              minimumDate={startAt.toDate()}
              value={values.endAt}
              onChange={(endAt) => {
                setFieldValue('endAt', endAt);
              }}
              closeOnSelect
              minuteStep={MINUTE_STEP}
              disabledTime={() => {
                return {
                  disabledHours: disabledTimeMax.hours,
                  disabledMinutes: disabledTimeMax.minutes,
                };
              }}
            />
            {errors.endAt && (
              <div className="error fs-12 pt-5 ">
                {t(errors.endAt as string)}
              </div>
            )}
          </SMaintenanceinput>
        </Col>
      </Row>
    </Dialog>
  );
};

export default MaintenanceDialog;
