import { GET, POST, DELETE, PATCH } from 'utils';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';

export const getTenantosServers = (
  query: IDedicatedServer.TenantosServersQuery
): IAxiosPromise<IDedicatedServer.TenantosServer[]> =>
  GET(`/tenantos-servers`, query);

export const getTenantosServerById = (
  id: number
): AxiosPromise<IDedicatedServer.TenantosServer> =>
  GET(`/tenantos-servers/${id}`);

export const getDedicatedServers = (): IAxiosPromise<
  IDedicatedServer.DedicatedServer[]
> => GET(`/dedicated-servers`);

export const getDedicatedServerById = (
  query: AnyShape
): IAxiosPromise<IDedicatedServer.DedicatedServer> =>
  GET(`/dedicated-servers/${query.id}`, {
    include: ['billingCycle', 'serviceMonitoring'],
  });

export const createDedicatedServer = (
  payload: IDedicatedServer.IRentDedicatedServerPayload
): AxiosPromise<IDedicatedServer.DedicatedServer> =>
  POST(
    `/dedicated-servers`,
    {},
    {
      osPassword: payload.osPassword,
      tenantosServerId: payload.tenantosServerId,
      name: payload.name,
      templateId: payload.templateId,
      description: payload.description,
      billingCycleInterval: payload.billingCycleInterval,
    }
  );

export const updateDedicatedServer = (
  id: number,
  payload: Partial<IDedicatedServer.IRentDedicatedServerPayload>
): AxiosPromise<IDedicatedServer.DedicatedServer> =>
  PATCH(`/dedicated-servers/${id}`, {}, payload);

export const removeDedicatedServer = (payload: {
  id: number;
  deletionReason: string;
}): AxiosPromise<IDedicatedServer.DedicatedServer> =>
  DELETE(
    `/dedicated-servers/${payload.id}`,
    {},
    { deletionReason: payload.deletionReason }
  );

export const powerOnDedicatedServer = (
  id: number
): AxiosPromise<IDedicatedServer.DedicatedServer> =>
  POST(`/dedicated-servers/${id}/actions/power-on`);

export const powerOffDedicatedServer = (
  id: number
): AxiosPromise<IDedicatedServer.DedicatedServer> =>
  POST(`/dedicated-servers/${id}/actions/power-off`);

export const resetDedicatedServer = (
  id: number
): AxiosPromise<IDedicatedServer.DedicatedServer> =>
  POST(`/dedicated-servers/${id}/actions/power-reset`);

export const reinstallOs = (
  id: number,
  payload: IDedicatedServer.IReinstallPayload
): AxiosPromise<IDedicatedServer.DedicatedServer> =>
  POST(`/dedicated-servers/${id}/actions/install-os`, {}, payload);

export const getWebConsoleUrl = (vmId: number): AxiosPromise<{ url: string }> =>
  POST(`/dedicated-servers/${vmId}/actions/get-web-console-url`);
