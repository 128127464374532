import React, { useCallback } from 'react';
import { IconButton, List, MuiIcons, Popover } from 'elements';
import { IActualTaskState } from 'task-manager-service';
import { observer } from 'mobx-react-lite';
import PopupState, {
  bindHover,
  bindMenu,
  bindPopover,
  bindTrigger,
} from 'material-ui-popup-state';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import { shouldDisableWebConsoleLaunching } from 'utils';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { InjectedProps, withRdsActions } from 'hocs/withRdsActions';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';

const Actions = observer(
  (
    props: InjectedProps & {
      task: IActualTaskState;
      handleChange: AnyFunc;
    }
  ) => {
    const {
      menuItems,
      instance: rds,
      handlers,
      requireAgentUpdate,
      task,
      handleChange,
      remoteSupport: rs,
    } = props;
    // const rds = useStateHandler(StateHandlers.rds);
    const { t } = useTranslation();

    const innerPopupState = usePopupState({
      variant: 'popover',
      popupId: 'innerPopover',
    });

    const snapshotOptions = menuItems?.filter((item) =>
      [
        'createSnapshot',
        'revertSnapshot',
        'removeSnapshot',
        'removeScheduledSnapshotTask',
      ].includes(item.value as string)
    );

    const rdsVms =
      rds.data?.host?.hostInstances.map((el) => ({
        value: 'console',
        label: el.vm?.name,
        id: el.vm?.id,
      })) || [];

    const fetchRds = useCallback(() => rds.get({}), []);

    return (
      <>
        <PopupState variant="popover" popupId="rds-users-menu" disableAutoFocus>
          {(popupState) => (
            <>
              <IconButton size="small" {...bindTrigger(popupState)}>
                <MuiIcons.MoreVert className="fs-20 steel pointer" />
              </IconButton>
              <Popover {...bindMenu(popupState)}>
                <List>
                  {snapshotOptions?.map((item) => (
                    <ListItemButton
                      key={item.label}
                      disabled={item.disabled}
                      onClick={() => {
                        item.onClick && item.onClick();
                        popupState.close();
                      }}
                    >
                      {t(`services.content.action.${item.label}`)}
                    </ListItemButton>
                  ))}

                  <Divider />
                  {requireAgentUpdate && (
                    <ListItemButton
                      data-test-id={`rds-menu-item-update-agent`}
                      onClick={() => {
                        popupState.close();
                        return handlers.updateAgent();
                      }}
                    >
                      {t(`services.content.action.updateAgent`) as string}
                    </ListItemButton>
                  )}
                  <ListItemButton
                    data-test-id={`rds-menu-item-remote-support`}
                    disabled={
                      !(task.isCompleted || task.isFailed) ||
                      rs.isRequesting ||
                      rs.isTaskActive
                    }
                    onClick={() => {
                      rs.isUsedForRds
                        ? handlers.disableRemoteSupport()
                        : handlers.enableRemoteSupport();
                      handleChange(true, 'remoteSupportOpen');
                      popupState.close();
                    }}
                  >
                    <div className="full-width test-right">
                      {t(
                        `services.content.action.remoteSupport.${
                          rs.isUsedForRds ? 'disable' : 'enable'
                        }`
                      )}
                    </div>
                  </ListItemButton>
                  {!shouldDisableWebConsoleLaunching(
                    rds.data?.displayStatus
                  ) && (
                    <ListItemButton {...bindHover(innerPopupState)}>
                      <div className="flex justify-between align-center">
                        <MuiIcons.ChevronLeft className="fs-22 steel pointer mr-5" />
                        {t(`services.content.action.console`)}
                      </div>
                    </ListItemButton>
                  )}

                  <ListItemButton
                    data-test-id={`rds-menu-item-delete`}
                    disabled={!(task.isCompleted || task.isFailed)}
                    onClick={async () => {
                      popupState.close();
                      await handlers.delete();
                      await fetchRds();
                    }}
                  >
                    <div className="full-width test-right">
                      {t(`services.rds.navigation.remove`)}
                    </div>
                  </ListItemButton>
                </List>
              </Popover>

              <HoverPopover
                {...bindPopover(innerPopupState)}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <List>
                  {/* @ts-ignore */}
                  {rdsVms.map((el: any) => {
                    const { label, disabled } = el;
                    return (
                      <ListItemButton
                        key={`${el.label}-${label}`}
                        data-test-id={`${label}-menu-item`}
                        disabled={disabled}
                        onClick={() => {
                          handlers.console(el.id);
                          popupState.close();
                          innerPopupState.close();
                        }}
                      >
                        {label}
                      </ListItemButton>
                    );
                  })}
                </List>
              </HoverPopover>
            </>
          )}
        </PopupState>
      </>
    );
  }
);

export default withRdsActions(Actions);
