import * as React from 'react';
import cn from 'classnames';
import { Row, Col, Button, Loader, TablePagination } from 'elements';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks';
import { confirm } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { BlacklistDomain } from 'blacklist';
import AddDomainDialog from './AddDomainDialog';
import BlacklistItem from './BlacklistItem';
import { BlacklistHolder } from './Styled';

const OBSERVERS = {
  blacklist: StateHandlers.blacklist,
};
type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { blacklist } = props;
  const { t } = useTranslation();
  const { query, queryStr, changeQuery } = useQuery();
  const [open, handleDialog] = React.useState(false);

  const onCreateDomain = React.useCallback(
    (payload: { domainName: string }) => {
      blacklist.create(payload).then(() => {
        handleDialog(false);
        blacklist.get(query);
      });
    },
    [queryStr]
  );

  const onDeleteDomain = React.useCallback(
    (domain: BlacklistDomain) => () => {
      confirm({
        title: t('settings.page.blacklist.confirm.delete.title'),
        content: t('settings.page.blacklist.confirm.delete.content', {
          domain: domain.domainName,
        }),
        onSuccess: () =>
          blacklist.remove(domain.id).then(() => {
            handleDialog(false);
            blacklist.get(query);
          }),
        onCancel: () => undefined,
      });
    },
    [queryStr]
  );

  React.useEffect(() => {
    blacklist.get(query);
  }, [queryStr]);

  return (
    <div>
      <BlacklistHolder>
        <Row
          alignItems="center"
          justifyContent="space-between"
          className="mb-15"
        >
          <Col className="uppercase fs-14">
            {t('settings.page.blacklist.title') as string}
          </Col>
          <Col>
            <Button size="small" onClick={() => handleDialog(true)}>
              {t('settings.page.blacklist.buttons.addDomain')}
            </Button>
          </Col>
        </Row>
        <div className="mb-25 fs-14">
          {t('settings.page.blacklist.subtitle') as string}
        </div>

        <div className={cn({ disabled: blacklist.isRequesting })}>
          {!blacklist.dataReceived ? (
            <Loader />
          ) : (
            blacklist.data.map((item) => {
              return (
                <BlacklistItem
                  {...item}
                  onDelete={onDeleteDomain(item)}
                  key={item.id}
                />
              );
            })
          )}
          <TablePagination
            shouldUseQuery
            totalCount={blacklist.meta.totalCount}
            perPage={query.perPage}
            onChange={changeQuery}
          />
        </div>

        <AddDomainDialog
          open={open}
          onSave={onCreateDomain}
          onClose={() => handleDialog(false)}
          testId="blacklist"
          isSaving={blacklist.isRequesting}
        />
      </BlacklistHolder>
    </div>
  );
});

const Main = () => <View {...OBSERVERS} />;

export default Main;
