import * as React from 'react';
import * as R from 'ramda';
import { AnyShape } from 'global-shapes';
import Hidden from '@mui/material/Hidden';
import Drawer from './Drawer';
import Toolbar from '@mui/material/Toolbar';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { stringify } from 'query-string';
import { usePermissions, useUserHash, useState } from 'hooks';
import { remCalc, Emitter } from 'utils';
import { REDIRECT_LINKS, ALL_TEST_IDS, HEADER_NAV_ITEMS, PALETTE } from 'enums';
import Logo from 'assets/images/logo.svg';
import {
  IconButton,
  Row,
  Col,
  Dialog,
  Button,
  MuiIcons,
  Avatar,
  styled,
} from 'elements';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import TaskIcon from './TaskIcons';
import EvaluationBar from './EvaluationBar';
import CongratsIcon from 'assets/images/im-congrats-for-prod.svg';
import NavigationItem from './NavigationItem';
import LogOutButton from './LogOutButton';
import SupportInfoIcon from './SupportInfoIcon';
import SearchIcon from './SearchIcon';
import ProfileDropDown from './ProfileDropDown';
import JobDashboard from './Dashboard';
import SearchEntityDrawer from './EntitySearch/SearchEntityDrawer';
import AppBar from './AppBar';
import { parseNameForAvatar } from './helpers';
import { drawerContainer, HEADER_HEIGHT } from './constants';

interface ILogoProps {
  defaultLink: AnyShape;
  logoUrl: string;
  userHash: string;
  className?: string;
}

interface IState {
  isMenuOpen: boolean;
  isTasksOpen: boolean;
  isStartProdOpen: boolean;
  isSearchBarOpen: boolean;
}

type IProps = {
  isEvaluation: boolean;
  classes?: any;
};

type IViewProps = {
  currentUser: StateHandlers.ICurrentUserHandler;
  tasks: StateHandlers.ITaskManagerListHandler;
};

const LogoComponent = styled((props: ILogoProps) => {
  const { defaultLink, userHash, logoUrl, className } = props;

  const fullHash = userHash ? `/${userHash}` : '';

  return (
    <NavLink
      to={`${fullHash}${defaultLink.link}${stringify(defaultLink.query)}`}
      className={cn(className, 'flex align-center')}
    >
      <div
        className="_logo-image"
        style={{
          backgroundImage: `url(${logoUrl})`,
        }}
      />
    </NavLink>
  );
})(({ theme }) => {
  const mobileUp = theme.breakpoints.up(1300);

  return {
    height: remCalc(42),
    [mobileUp]: {
      height: remCalc(HEADER_HEIGHT),
      padding: 0,
    },
    '& ._logo-image': {
      width: remCalc(140),
      height: remCalc(42),
      overflow: 'hidden',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      [mobileUp]: {
        width: remCalc(200),
        height: remCalc(HEADER_HEIGHT),
      },
    },
  };
});

const View = observer((props: IProps & IViewProps) => {
  const { isEvaluation, classes, tasks, currentUser } = props;
  const {
    permissionsAll,
    permissionsReceived,
    currentAccessLevel,
    isCurrentTenant,
    isCurrentPartner,
    isCurrentProvider,
  } = usePermissions();
  const { t } = useTranslation();
  const [userHash] = useUserHash();

  const [state, handleChange] = useState<IState>({
    isMenuOpen: false,
    isTasksOpen: false,
    isStartProdOpen: false,
    isSearchBarOpen: false,
  });

  const defaultLink = currentAccessLevel
    ? REDIRECT_LINKS[currentAccessLevel.toUpperCase()]
    : { query: {}, link: '' };

  const handleOpenSearch = React.useCallback(() => {
    const open = !state.isSearchBarOpen;
    const newValue: Partial<IState> = { isSearchBarOpen: open };

    if (open) {
      newValue.isTasksOpen = false;
    }

    return handleChange(newValue);
  }, [state.isSearchBarOpen]);

  const onOpenDashboard = React.useCallback(() => {
    const open = !state.isTasksOpen;
    const newValue: Partial<IState> = { isTasksOpen: open };

    if (open) {
      newValue.isSearchBarOpen = false;
    }

    return handleChange(newValue);
  }, [state.isTasksOpen]);

  React.useEffect(() => {
    Emitter.on('open-task-dashboard', onOpenDashboard);
    return () => {
      Emitter.off('open-task-dashboard', onOpenDashboard);
    };
  }, []);

  const navItems = React.useMemo(
    () =>
      currentAccessLevel &&
      permissionsAll &&
      currentUser.data &&
      HEADER_NAV_ITEMS[currentAccessLevel](permissionsAll, userHash).map(
        (item) => (
          <NavigationItem
            key={item.to}
            {...item}
            handleMenu={handleChange}
            classes={classes}
            hidden={
              item.permissionName && permissionsAll[item.permissionName]
                ? !permissionsAll[item.permissionName]?.canView
                : false
            }
          />
        )
      ),
    [currentAccessLevel, userHash]
  );

  const tasksData = tasks.data || [];

  const iconStatus: TaskStatus = React.useMemo(() => {
    if (
      R.find((t: Task) => ['active', 'delayed'].includes(t.status))(tasksData)
    ) {
      return 'active';
    }
    return tasksData.length ? tasksData[0].status : 'none';
  }, [JSON.stringify(tasksData)]);

  const logoUrl = React.useMemo(() => {
    let url = '';
    url = currentUser.data?.partner?.logo?.link || '';

    return url || Logo;
  }, [isCurrentTenant, isCurrentPartner]);

  if (!permissionsReceived) {
    return null;
  }

  const showEvaluationBar = isEvaluation;

  if (R.isEmpty(currentUser)) return null;

  return (
    <>
      {showEvaluationBar && (
        <EvaluationBar
          onStarted={() => {
            StateHandlers.fetchAllAccountData();
            handleChange(true, 'isStartProdOpen');
          }}
        />
      )}
      <AppBar
        showEvaluationBar={showEvaluationBar}
        position="fixed"
        color={undefined}
      >
        <Row className="_appRow">
          <Toolbar className="_toolbar">
            <div className="_leftNav">
              <div className="_logo">
                <LogoComponent
                  userHash={userHash}
                  defaultLink={defaultLink}
                  logoUrl={logoUrl}
                />
              </div>
              <Hidden mdDown>{navItems}</Hidden>
            </div>
            <div className="flex align-center">
              <SearchIcon
                onClick={handleOpenSearch}
                open={state.isSearchBarOpen}
              />
              <SupportInfoIcon />
              {isCurrentTenant && (
                <IconButton
                  className={cn(
                    '_notifyIcon',
                    { _notifyBtnActive: state.isTasksOpen },
                    'fs-20 mr-5'
                  )}
                  onClick={onOpenDashboard}
                >
                  <div
                    data-test-id={`${ALL_TEST_IDS.header.wrapper}-notify-icon`}
                    className="relative"
                  >
                    <TaskIcon
                      isActive={state.isTasksOpen}
                      status={iconStatus}
                    />
                  </div>
                </IconButton>
              )}
              <Hidden mdUp>
                <IconButton
                  onClick={() => handleChange(true, 'isMenuOpen')}
                  data-test-id={`${ALL_TEST_IDS.header.wrapper}-menu-icon`}
                >
                  <MuiIcons.Menu className="_menuIcon" />
                </IconButton>
              </Hidden>
              <Hidden mdDown>
                <ProfileDropDown />
              </Hidden>
            </div>
          </Toolbar>
        </Row>
      </AppBar>
      <Dialog
        open={state.isStartProdOpen}
        onClose={() => handleChange(false, 'isStartProdOpen')}
      >
        <Row direction="column" justifyContent="center" alignItems="center">
          <Col>
            <img src={CongratsIcon} alt="" className="mb-15" />
          </Col>
          <Col>
            <h3 className="mb-25">
              {t('app.evaluationBar.notify.success.title')}
            </h3>
          </Col>
          <Col className="mb-25 text-center">
            {t('app.evaluationBar.notify.success.content')}
          </Col>
          <Col>
            <Button onClick={() => handleChange(false, 'isStartProdOpen')}>
              {t('common.ok')}
            </Button>
          </Col>
        </Row>
      </Dialog>
      <Drawer
        isEvaluation={isEvaluation}
        container={drawerContainer}
        variant="temporary"
        anchor="left"
        open={state.isMenuOpen}
        onClose={() => handleChange(false, 'isMenuOpen')}
        className="_mobileMenu"
        classes={{
          paper: '_mobileMenu',
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div className={cn('pl-20 pr-20 flex justify-between _mobileHeader')}>
          <LogoComponent
            userHash={userHash}
            defaultLink={defaultLink}
            logoUrl={logoUrl}
          />
          <div className="flex align-center">
            <SearchIcon
              onClick={handleOpenSearch}
              open={state.isSearchBarOpen}
            />
            <SupportInfoIcon />
            <LogOutButton id="mobile" />
          </div>
        </div>
        <div className="p-20">
          <div className="fs-12 mb-10">
            {isCurrentProvider
              ? t('app.header.profile.provider')
              : currentUser.data?.tenant?.name ||
                currentUser.data?.partner?.name}
          </div>
          <div
            className={cn('flex align-center mb-10 pb-15 _mobileProfileHolder')}
          >
            <div className="mr-15">
              <Avatar
                sx={{
                  bgcolor: PALETTE.paleGrey,
                  width: 32,
                  height: 32,
                  fontSize: remCalc(14),
                  color: PALETTE.primary,
                  fontWeight: 'bolder',
                }}
              >
                {parseNameForAvatar(
                  `${currentUser.data?.firstName} ${currentUser.data?.lastName}`
                )}
              </Avatar>
            </div>
            <div className="fs-12">
              <div className="bold">
                {currentUser.data?.firstName} {currentUser.data?.lastName}
              </div>
              <div>{currentUser.data?.email}</div>
            </div>
          </div>
          <div>{navItems}</div>
        </div>
      </Drawer>
      {isCurrentTenant && (
        <JobDashboard
          isEvaluation={isEvaluation}
          isOpen={state.isTasksOpen}
          handleOpen={(isTasksOpen) => handleChange(isTasksOpen, 'isTasksOpen')}
        />
      )}
      <SearchEntityDrawer
        isEvaluation={isEvaluation}
        open={state.isSearchBarOpen}
        onClose={() => handleChange({ isSearchBarOpen: false })}
      />
    </>
  );
});

const Header = (props: IProps) => (
  <View
    {...props}
    currentUser={StateHandlers.currentUser}
    tasks={StateHandlers.taskManagerList}
  />
);

export default Header;
