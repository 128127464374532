import * as React from 'react';
import { PALETTE, IStatusNamespace } from 'enums';
import { styled } from 'elements/utilities';

const defineColor = (st: IStatusNamespace) => {
  switch (st) {
    case 'completed':
      return PALETTE.success;
    case 'provisioning':
      return PALETTE.primary;
    case 'patching':
      return PALETTE.primary;
    case 'waiting':
      return PALETTE.warning;
    case 'active':
      return PALETTE.success;
    case 'delayed':
      return PALETTE.lightGrey;
    case 'failed':
      return PALETTE.error;
    case 'initializing':
      return PALETTE.primary;
    case 'powerOnInProgress':
      return PALETTE.primary;
    case 'powerOffInProgress':
      return PALETTE.primary;
    case 'running':
      return PALETTE.success;
    case 'suspended':
      return PALETTE.steel;
    case 'waitingForInput':
      return PALETTE.warning;
    case 'deleting':
      return PALETTE.primary;
    case 'error':
      return PALETTE.error;
    case 'poweredOff':
      return PALETTE.lightGrey;
    case 'none':
      return PALETTE.lightGrey;

    default:
      return PALETTE.lightGrey;
  }
};

export const ColorAccumulator = styled(
  (props: React.ComponentProps<'div'> & { status: IStatusNamespace }) => {
    const { status, ...rest } = props;
    return <div {...rest} />;
  }
)(({ status }) => {
  const color = defineColor(status);

  return {
    backgroundColor: color,
  };
});
