import * as Yup from 'yup';
import { REGEX } from 'enums';
import { validator } from 'utils';
import password from 'secure-random-password';

export interface IDefaultValues {
  firstName: string;
  lastName: string;
  username: string;
  password?: string;
  resetPassword?: boolean;
  email: string;
}

const checkIfPasswordContainsFields = (fields: string[]) => (val: string) => {
  let result = false;
  fields.forEach((field) => {
    if (
      val &&
      field &&
      val.toLocaleLowerCase().includes(field.toLocaleLowerCase())
    )
      result = true;
  });
  return result;
};

function testPassword(val: any) {
  // @ts-ignore
  const { firstName, lastName, username } = this.parent;
  if (checkIfPasswordContainsFields([firstName, lastName, username])(val || ''))
    return false;
  return true;
}

const passwordSchema = (isNew: boolean) => {
  return isNew
    ? Yup.string()
        .trim()
        .matches(REGEX.password, 'forms.passwordInvalid')
        .required('forms.required')
        .test('username', 'forms.invalid.ci.user.password', testPassword)
    : Yup.string()
        .trim()
        .matches(REGEX.password, 'forms.passwordInvalid')
        .when('resetPassword', {
          is: true,
          then: Yup.string().trim().required('forms.required'),
        })
        .test('username', 'forms.invalid.ci.user.password', testPassword);
};

export const commonUserValidationSchema = (isNew: boolean) =>
  Yup.object({
    firstName: Yup.string()
      .trim()
      .required('forms.required')
      .max(30, 'forms.invalid.max')
      .test('firstName', 'forms.invalid', (val) =>
        validator.coreUserFirstLastName(val || '')
      ),
    lastName: Yup.string()
      .trim()
      .required('forms.required')
      .max(30, 'forms.invalid.max')
      .test('firstName', 'forms.invalid', (val) =>
        validator.coreUserFirstLastName(val || '')
      ),
    username: Yup.string()
      .trim()
      .required('forms.required')
      .max(20, 'forms.invalid.max')
      .test('username', 'forms.invalid.reservedName', (val) => {
        const _val = val || '';
        return _val.toLowerCase() !== 'administrator';
      })
      .test('username', 'forms.invalid', (val) => {
        const _val = val || '';
        return validator.username(_val);
      }),
    email: Yup.string().nullable().trim().email('forms.emailInvalid'),
    password: passwordSchema(isNew),
  });

export const getInitialValues = (): IDefaultValues => ({
  firstName: '',
  lastName: '',
  username: '',
  password: password.randomPassword({
    length: 16,
    characters: [
      password.lower,
      password.upper,
      password.digits,
      password.symbols,
    ],
  }),
  email: '',
});

export function getFSStorageValidationSchema(initialStorage: number) {
  return Yup.object({
    storage: Yup.string()
      .trim()
      .required('forms.required')
      .test(
        'storage',
        'forms.invalid.onlyDecrease',
        (val) => initialStorage <= +(val || 0)
      ),
  });
}
