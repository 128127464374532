import { GET, PATCH } from 'utils';
import {
  IGuideTooltip,
  IGuideTooltipNew,
  IGuideTooltipsState,
} from 'guide-tooltips';
import { AxiosPromise } from 'axios';

export const reformatTooltipResponseResponse = (
  tooltips: IGuideTooltip[]
): IGuideTooltipsState => {
  const result = {} as IGuideTooltipsState;
  tooltips.forEach((t) => {
    result[t.name] = t;
  });
  return result;
};

export const getTooltips = async (): Promise<IGuideTooltipsState> => {
  const tooltips: IGuideTooltip[] = await GET('/user-tooltips').then(
    (res) => res.data
  );
  return reformatTooltipResponseResponse(tooltips);
};

export const updateTooltips = (
  tooltips: IGuideTooltipNew[]
): AxiosPromise<IGuideTooltipsState> => {
  return PATCH('/user-tooltips', {}, { tooltips: tooltips }).then((res) => ({
    ...res,
    data: reformatTooltipResponseResponse(res.data),
  }));
};
