import React from 'react';
import {
  Input,
  Row,
  Col,
  Button,
  Select,
  Dialog,
  DialogProps,
  InputPhone,
} from 'elements';
import { PUT, showSystemMessage, getCountries, validator } from 'utils';
import { useFormik } from 'formik';
import { useUserHash, useStateHandler } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { LANGUAGES } from 'enums';
import { ILanguage, ValueType } from 'global-shapes';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { AxiosPromise } from 'axios';
import { parsePartnerInfoForForm } from '../../../helpers';

type IState = {
  countryCode?: string;
  line1: string;
  line2: string;
  city: string;
  zipCode: string;
  name: string;
  phone: string;
  lang: ValueType<ILanguage>;
};

const initials: IState = {
  name: '',
  line1: '',
  line2: '',
  zipCode: '',
  city: '',
  phone: '',
  countryCode: undefined,
  lang: { label: 'app.lang.de', value: 'de' },
};

const testId = 'partner-info';

const updatePartnerRequest = (
  payload: IPartnerTypes.IPartnerUpdatePayload
): AxiosPromise<IPartnerTypes.Partner> => {
  const { name, lang, line1, line2, zipCode, countryCode, city, phone } =
    payload;
  return PUT(
    `/partners`,
    {},
    {
      address: {
        line1,
        line2,
        zipCode,
        countryCode,
        city,
      },
      phone: phone || null,
      lang,
      name,
    }
  );
};

const mixedObjectStringValidationType = Yup.lazy((value) => {
  switch (typeof value) {
    case 'object':
      return Yup.object().required('forms.required');
    case 'string':
      return Yup.string().required('forms.required');
    default:
      return Yup.mixed();
  }
});

const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .max(30, 'forms.invalid.max')
    .required('forms.required'),
  line1: Yup.string()
    .trim()
    .max(60, 'forms.invalid.max')
    .required('forms.required'),
  line2: Yup.string().trim().max(60, 'forms.invalid.max'),
  zipCode: Yup.string()
    .trim()
    .required('forms.required')
    .max(60, 'forms.invalid.max'),
  city: Yup.string()
    .trim()
    .required('forms.required')
    .max(60, 'forms.invalid.max'),
  lang: Yup.object().required('forms.required'),
  phone: Yup.string()
    .trim()
    .test('phone', 'forms.invalid', (val) => {
      if (!val) return true;
      return validator.isPhone(val);
    }),
  countryCode: mixedObjectStringValidationType,
});

const PartnerInfoDialog = observer((props: DialogProps) => {
  const { t } = useTranslation();
  const [hash] = useUserHash();
  const currentUser = useStateHandler(StateHandlers.currentUser);
  const countries = React.useMemo(getCountries, []);

  const onSubmit = React.useCallback(
    (values: IState) =>
      updatePartnerRequest({
        line1: values?.line1.trim(),
        line2: values?.line2.trim(),
        zipCode: values?.zipCode.trim(),
        city: values?.city.trim(),
        phone: values?.phone.trim(),
        countryCode:
          values?.countryCode && typeof values.countryCode === 'string'
            ? values?.countryCode
            : // @ts-ignore
              values?.countryCode?.value,
        lang: values?.lang?.value as ILanguage,
        name: values.name.trim(),
      })
        .then(() => {
          showSystemMessage('partners.update.success', 'success');
          props.onClose();
          return StateHandlers.fetchAllAccountData({});
        })
        .catch((err: Error) => showSystemMessage(err.message, 'error')),
    []
  );

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    submitCount,
    isSubmitting,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: currentUser.data?.partner
      ? parsePartnerInfoForForm(
          currentUser.data?.partner as IPartnerTypes.Partner
        )
      : (initials as any),
    validateOnMount: false,
    validationSchema,
    onSubmit,
  });

  const isRequesting = isSubmitting || currentUser.isRequesting;

  React.useEffect(() => {
    if (props.open) {
      setValues(
        parsePartnerInfoForForm(
          currentUser.data?.partner as IPartnerTypes.Partner
        ) as any
      );
    } else {
      resetForm();
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={t('settings.page.partnerInfo.section.company.dialog.title')}
      handleSubmit={handleSubmit}
      data-test-id="partner-info-form"
      fullWidth
      actions={
        <>
          <Button
            color="default"
            variant="outlined"
            onClick={props.onClose}
            disabled={isSubmitting}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isRequesting}>
            {t('settings.page.partnerInfo.section.company.buttons.save')}
          </Button>
        </>
      }
    >
      <Input
        label={t('forms.companyName')}
        value={values.name}
        onChange={handleChange}
        name="name"
        className="mb-25"
        touched={touched.name}
        error={!!submitCount && !!errors.name}
        helperText={errors.name}
        helperTextOptions={{ max: 30 }}
      />
      <Input
        label={t('forms.address1')}
        value={values.line1}
        name="line1"
        placeholder={t('partners.dialog.field.placeholder.address1')}
        className="mb-25"
        onChange={handleChange}
        touched={touched.line1}
        helperText={errors.line1}
        helperTextOptions={{ max: 60 }}
        error={!!submitCount && !!errors.line1}
        testId={testId}
      />
      <Input
        label={t('forms.address2')}
        value={values.line2}
        name="line2"
        placeholder={t('partners.dialog.field.placeholder.address2')}
        onChange={handleChange}
        touched={touched.line2}
        error={!!submitCount && !!errors.line2}
        helperText={errors.line2}
        helperTextOptions={{ max: 60 }}
        testId={testId}
        className="mb-25"
      />
      <Row columnSpacing={2} className="mb-25">
        <Col xs={5}>
          <Input
            label={t('forms.zipcode')}
            value={values.zipCode}
            name="zipCode"
            placeholder={t('partners.dialog.field.placeholder.zipcode')}
            onChange={handleChange}
            touched={touched.zipCode}
            error={!!submitCount && !!errors.zipCode}
            helperText={errors.zipCode}
            helperTextOptions={{ max: 60 }}
            testId={testId}
          />
        </Col>
        <Col xs={7}>
          <Input
            label={t('forms.city')}
            value={values.city}
            name="city"
            placeholder={t('partners.dialog.field.placeholder.city')}
            onChange={handleChange}
            touched={touched.city}
            error={!!submitCount && !!errors.city}
            helperText={errors.city}
            helperTextOptions={{ max: 60 }}
            testId={testId}
          />
        </Col>
      </Row>
      <Select
        label={t('forms.country')}
        value={values.countryCode || undefined}
        name="countryCode"
        placeholder={t('partners.dialog.field.placeholder.country')}
        onChange={(val: any) => setFieldValue('countryCode', val)}
        options={countries}
        error={!!submitCount && !!errors.countryCode}
        helperText={errors.countryCode}
        testId={testId}
        className="mb-25"
      />
      <Select
        label={t('forms.emailsLanguage')}
        value={values.lang}
        name="lang"
        onChange={(val: any) => setFieldValue('lang', val)}
        options={LANGUAGES}
        error={!!submitCount && !!errors.lang}
        helperText={errors.lang}
        testId={testId}
        className="mb-25"
      />
      <InputPhone
        label={t('forms.phone')}
        value={values.phone}
        name="phone"
        className="mb-25"
        onChange={(val) => {
          setFieldValue('phone', val);
        }}
        helperText={errors.phone}
        error={!!submitCount && !!errors.phone}
        placeholder={t('forms.placeholders.phone')}
        touched={touched.phone}
        testId={testId}
      />
      <Input
        label={t('forms.shortname', { shortName: hash }).replace('&#x2F;', '/')}
        value={currentUser.data?.partner?.shortName}
        name="shortName"
        disabled
        testId={testId}
        className="mb-25"
      />
    </Dialog>
  );
});

export default PartnerInfoDialog;
