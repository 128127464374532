import { Role } from 'roles-shapes';
import { GET, POST, PUT, DELETE, buildListParams } from 'utils';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';

export const getRoles = (params: any): IAxiosPromise<Role[]> =>
  GET('/roles', buildListParams(params, ['name']));

export const getRole = (id: number): AxiosPromise<Role> => GET(`/roles/${id}`);

export const create = (role: Role): AxiosPromise<Role> => {
  const payload = {
    ...role,
    name: role.name.trim(),
  };

  return POST('/roles', {}, payload);
};

export const update = (id: number, role: Role): AxiosPromise<Role> =>
  PUT(`/roles/${id}`, {}, role);

export const deleteRole = (id: number): AxiosPromise<Role> =>
  DELETE(`/roles/${id}`);
