import { remCalc } from 'utils';
import { PALETTE } from 'enums';
import {
  styled,
  Select,
  MuiIcons,
  Row,
  Dialog,
  Button,
  TabPanel as MTabPanel,
} from 'elements';

export const SkipText = styled('p')(({ theme }) => ({
  color: PALETTE.steel,
  fontSize: remCalc(14),
  maxWidth: remCalc(400),
}));

export const EmptyMessageHolder = styled(Row)({
  minHeight: `calc(100vh - 280px)`,
  textAlign: 'center',
});

export const AccountSelect = styled(Select)(({ theme }) => ({
  minWidth: remCalc(210),
}));

export const BurgerIcon = styled(MuiIcons.MoreVert)(({ theme }) => ({
  marginLeft: remCalc(15),
  color: PALETTE.steel,
  fontSize: remCalc(20),
  cursor: 'pointer',
}));

export const TabPanel = styled(MTabPanel)(({ theme }) => ({
  minHeight: remCalc(325),
}));

export const SDialog = styled(Dialog)(({ theme }) => ({
  '& ._paper': {
    maxWidth: remCalc(700),
  },
}));

export const BillingSelect = styled(Select)(({ theme }) => ({
  minWidth: remCalc(210),
}));

export const ClearButton = styled(Button)(({ theme }) => ({
  height: remCalc(48),
  width: '100%',
}));
