import * as React from 'react';
import cn from 'classnames';
import { Avatar, Popover, MuiIcons, styled } from 'elements';
import { withTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import { PALETTE } from 'enums';
import { observer } from 'mobx-react-lite';
import { usePermissions, useAccount } from 'hooks';
import { logOut, remCalc } from 'utils';
import { parseNameForAvatar } from './helpers';

type IProps = React.PropsWithChildren<WithT & { className?: string }>;

const SAvatar = styled(Avatar)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Renderer = styled(
  observer((props: IProps) => {
    const { t, className } = props;
    const [account] = useAccount();
    const { isCurrentProvider } = usePermissions();

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = React.useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
      },
      []
    );

    const handleClose = React.useCallback(() => {
      setAnchorEl(null);
    }, []);

    const username = `${account?.firstName} ${account?.lastName}`;

    const open = Boolean(anchorEl);
    const id = open ? 'header-profile-pic' : undefined;

    return (
      <div className={className}>
        <div
          onClick={handleClick}
          className={cn('flex align-center justify-center _iconWrapper', {
            _activeIcon: open,
          })}
        >
          <SAvatar
            sx={{
              bgcolor: PALETTE.paleGrey,
              width: 32,
              height: 32,
              fontSize: remCalc(14),
              cursor: 'pointer',
              fontWeight: 'bolder',
            }}
          >
            {parseNameForAvatar(username)}
          </SAvatar>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={{ className: '_menu' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className="pt-20 pl-25 pr-25 pb-25 fs-14 full-width">
            <div className="flex align-center justify-between mb-15">
              <div>
                {isCurrentProvider
                  ? (t('app.header.profile.provider') as string)
                  : account?.tenant?.name || account?.partner?.name}
              </div>
              <div>
                <MuiIcons.Login
                  fontSize="small"
                  className="fs-20 ml-10 pointer"
                  onClick={logOut}
                />
              </div>
            </div>
            <div className="flex align-center">
              <div className="mr-15">
                <SAvatar
                  sx={{
                    bgcolor: PALETTE.paleGrey,
                    width: 54,
                    height: 54,
                    fontSize: remCalc(22),
                    fontWeight: 'bolder',
                  }}
                >
                  {parseNameForAvatar(username)}
                </SAvatar>
              </div>
              <div>
                <div className="bold">{username}</div>
                <div>{account?.email}</div>
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  })
)(({ theme }) => {
  return {
    '& ._menu': {
      '&.MuiPaper-root': {
        minWidth: remCalc(285),
      },
    },
    '& ._iconWrapper': {
      height: remCalc(64),
      lineHeight: remCalc(64),
      width: remCalc(62),
      transition: 'transform .1s ease-in-out 0s',
      transform: 'scale(1)',
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    '& ._activeIcon': {
      transform: 'scale(1.1)',
    },
  };
});

const ProfileDropDown = withTranslation()(Renderer);

export default ProfileDropDown;
