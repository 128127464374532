import React, { useCallback, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { Button, LinearTable } from 'elements';
import { useTranslation } from 'react-i18next';
import { useAsync, usePermissions, useState } from 'hooks';
import { confirm } from 'utils';
import { connectivityService } from 'services';
import { ALL_TEST_IDS } from 'enums';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { ICommonListParams } from 'global-shapes';
import { getVpnTunnelsColumns, INITIAL_STATE } from './constants';
import IpsecVpnDetails from './ipsecTunnels/IpsecVpnDetails';
import { TunnelTable } from './Styled';

type IState = {
  tunnelDialogOpen: boolean;
  selectedTunnel: IIpsecVpnTunnels.IpSecVpnTunnel | null;
};

type IProps = { disabled?: boolean };

const OBSERVERS = {
  connectivity: StateHandlers.connectivity,
  tunnels: StateHandlers.ipSecVpnTunnels,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { connectivity, tunnels } = props;
  const { t } = useTranslation();
  const { permissions } = usePermissions('SERVICES');
  const [state, handleChange] = useState<IState>(INITIAL_STATE);

  const isRequestingTunnels = tunnels.isRequesting;

  const { execute: getTunnelService, isPending: isFetchingTunnel } = useAsync(
    connectivityService.getTunnel
  );

  const connectivityId = connectivity.data?.id;

  const onCloseIpsecDialog = useCallback(() => {
    handleChange({ selectedTunnel: null, tunnelDialogOpen: false });
  }, []);

  const onDeleteTunnel = useCallback(
    (id: number) =>
      confirm({
        title: 'services.dialog.confirm.tunnel.delete.title',
        content: t('services.dialog.confirm.tunnel.delete.subtitle'),
        onSuccess: () => tunnels.remove({ id, connectivityId }),
        onCancel: () => undefined,
        successLabel: 'common.remove',
        cancelLabel: 'common.cancel',
      }),
    [connectivityId]
  );

  const onEditTunnel = useCallback(
    (selectedTunnel: IIpsecVpnTunnels.IpSecVpnTunnel) =>
      getTunnelService(connectivity.data.id, selectedTunnel.id).then(() =>
        handleChange({ selectedTunnel, tunnelDialogOpen: true })
      ),
    []
  );

  const getTunnels = useCallback(
    (query: ICommonListParams) => tunnels.get(query),
    []
  );

  const onRefresh = useCallback((id: number) => {
    const requestTunnels = () =>
      getTunnels({
        orderBy: 'name',
        orderType: 'asc',
        offset: 0,
        id,
      });
    return connectivityService
      .refreshTunnels(id)
      .then(requestTunnels)
      .catch(requestTunnels);
  }, []);

  const onSubmitIpsec = useCallback(
    (val: IIpsecVpnTunnels.IpSecVpnTunnelValues, isNew?: boolean) => {
      return isNew
        ? tunnels.create({ connectivityId, ...val }).then(() => {
            onRefresh(connectivityId);
            onCloseIpsecDialog();
          })
        : tunnels
            .update(
              { connectivityId, tunnelId: val.id },
              {
                ...val,
                preSharedKey: val.editKey ? val.preSharedKey : undefined,
              }
            )
            .then(() => {
              onRefresh(connectivityId);
              onCloseIpsecDialog();
            });
    },
    [connectivityId]
  );

  const tunnelsColumns = useMemo(
    () =>
      getVpnTunnelsColumns({
        t,
        onDelete: onDeleteTunnel,
        onEdit: onEditTunnel,
        isFetching: isFetchingTunnel,
        isEditable: permissions.canManage,
      }),
    [
      isFetchingTunnel,
      permissions.canManage,
      onDeleteTunnel,
      onEditTunnel,
      connectivityId,
    ]
  );

  useEffect(() => {
    if (connectivityId) {
      onRefresh(connectivityId);
    }
  }, [connectivityId]);

  return (
    <div>
      <div className="flex align-center justify-between mb-15">
        <div className="fs-14 uppercase bolder">
          {t('services.connectivity.ipsecVpnTunnels.title')}
        </div>
        {permissions.canManage && (
          <Button
            size="small"
            testId={ALL_TEST_IDS.services.connectivity.addIpsecVpnTunnel}
            onClick={() => handleChange(true, 'tunnelDialogOpen')}
            disabled={props.disabled}
          >
            {t('services.connectivity.ipsecVpnTunnels.addTunnel')}
          </Button>
        )}
      </div>
      <TunnelTable
        className={cn('mb-25', {
          disabled: tunnels.isLoading || !tunnels.dataReceived,
        })}
      >
        <LinearTable
          data={tunnels.data}
          columns={tunnelsColumns}
          noDataMessage={t('table.no.vpnTunnels')}
          noDataClassName="pt-30 pb-30 auto-height fs-12"
          testId={ALL_TEST_IDS.services.connectivity.ipsecVpnTunnel}
          onUpdateTable={() =>
            getTunnels({
              orderBy: 'name',
              orderType: 'asc',
              offset: 0,
              id: connectivityId,
            })
          }
        />
      </TunnelTable>

      <IpsecVpnDetails
        isNew={!state.selectedTunnel}
        initialValues={state.selectedTunnel}
        open={state.tunnelDialogOpen}
        isRequesting={isRequestingTunnels}
        onClose={() =>
          handleChange({ tunnelDialogOpen: false, selectedTunnel: null })
        }
        onSave={onSubmitIpsec}
        connectivity={connectivity.data}
      />
    </div>
  );
});

const IpSecVpnTunnelTable = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default IpSecVpnTunnelTable;
