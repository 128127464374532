import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks';
import { Row, Col, Button, Menu, MenuItem, MuiIcons } from 'elements';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

const Filters = () => {
  const { t } = useTranslation();
  const { query, changeQuery } = useQuery();

  const hasInstallFilterValue = query.isInstalled !== 'none';
  const hasLicenseFilterValue = query.licensePricingType !== 'all';

  return (
    <Row alignItems="center" columnSpacing={2} className="mb-25">
      <Col className="bolder">{t('softwarePackages.filters.label')}:</Col>
      <Col>
        <PopupState variant="popover" popupId="app-store-filter-installation">
          {(popupState) => (
            <>
              <div
                {...bindTrigger(popupState)}
                className="flex align-center pointer"
              >
                <div className="mr-5 pb-10 pt-10">
                  {t(
                    hasInstallFilterValue
                      ? `softwarePackages.filters.options.installation.${query.isInstalled}`
                      : 'softwarePackages.filters.placeholders.installation'
                  )}
                </div>
                {popupState.isOpen ? (
                  <MuiIcons.KeyboardArrowUp className="fs-16" />
                ) : (
                  <MuiIcons.KeyboardArrowDown className="fs-16" />
                )}
              </div>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    changeQuery({ isInstalled: 'none' });
                    popupState.close();
                  }}
                >
                  <span
                    className={cn({ primary: query.isInstalled === 'none' })}
                  >
                    {t('softwarePackages.filters.options.installation.none')}
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    changeQuery({ isInstalled: true });
                    popupState.close();
                  }}
                >
                  <span className={cn({ primary: query.isInstalled === true })}>
                    {t('softwarePackages.filters.options.installation.true')}
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    changeQuery({ isInstalled: false });
                    popupState.close();
                  }}
                >
                  <span
                    className={cn({ primary: query.isInstalled === false })}
                  >
                    {t('softwarePackages.filters.options.installation.false')}
                  </span>
                </MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      </Col>
      <Col>
        <PopupState variant="popover" popupId="app-store-filter-license">
          {(popupState) => (
            <>
              <div
                {...bindTrigger(popupState)}
                className="flex align-center pointer"
              >
                <div className="mr-5 pb-10 pt-10">
                  {t(
                    hasLicenseFilterValue
                      ? `softwarePackages.filters.options.license.${query.licensePricingType}`
                      : 'softwarePackages.filters.placeholders.license'
                  )}
                </div>
                {popupState.isOpen ? (
                  <MuiIcons.KeyboardArrowUp className="fs-16" />
                ) : (
                  <MuiIcons.KeyboardArrowDown className="fs-16" />
                )}
              </div>

              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    changeQuery({ licensePricingType: 'all' });
                    popupState.close();
                  }}
                >
                  <span
                    className={cn({
                      primary: query.licensePricingType === 'all',
                    })}
                  >
                    {t('softwarePackages.filters.options.license.all')}
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    changeQuery({ licensePricingType: 'free' });
                    popupState.close();
                  }}
                >
                  <span
                    className={cn({
                      primary: query.licensePricingType === 'free',
                    })}
                  >
                    {t('softwarePackages.filters.options.license.free')}
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    changeQuery({ licensePricingType: 'paid' });
                    popupState.close();
                  }}
                >
                  <span
                    className={cn({
                      primary: query.licensePricingType === 'paid',
                    })}
                  >
                    {t('softwarePackages.filters.options.license.paid')}
                  </span>
                </MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      </Col>
      <Col>
        <Button
          size="small"
          variant="text"
          onClick={() =>
            changeQuery({
              isInstalled: 'none',
              licensePricingType: 'all',
            })
          }
        >
          {t('softwarePackages.buttons.clearFilters')}
        </Button>
      </Col>
    </Row>
  );
};

export default Filters;
