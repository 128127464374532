import { BaseMobxInstanceDecorator } from './utilities';
import { appSettingsService } from 'services';
import { IAppVersion } from 'app-settings';

export const appSettings = new BaseMobxInstanceDecorator<IAppVersion, false>({
  instanceName: 'app-settings',
  requests: {
    get: appSettingsService.getSettings,
    update: (_id, payload) => appSettingsService.update(payload),
  },
  initialState: {} as IAppVersion,
});
