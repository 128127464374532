import React, { useReducer, Reducer } from 'react';
import {
  stateReducer,
  handleReducerChange,
  IReducerChangeHandler,
} from 'utils';

export function useState<S = any>(
  initialState: S
): [S, IReducerChangeHandler<S>] {
  const [state, dispatcher] = useReducer<Reducer<S, any>>(
    stateReducer,
    initialState
  );
  const handleStateChange = React.useCallback(
    handleReducerChange<S>(dispatcher),
    []
  );

  return [state, handleStateChange];
}
