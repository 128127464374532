import * as React from 'react';
import { IconButton, MuiIcons, Row, Col, Paper } from 'elements';
import { useTranslation } from 'react-i18next';
import { useState, useUserHash, useStateHandler } from 'hooks';
import * as StateHandlers from 'states';
import MainInfoDialog from './MainInfoDialog';

type IState = {
  detailsDialogOpen: boolean;
};

const INITIAL_STATE: IState = {
  detailsDialogOpen: false,
};

const Info = () => {
  const currentUser = useStateHandler(StateHandlers.currentUser);
  const [hash] = useUserHash();
  const { t } = useTranslation();
  const [state, handleChange] = useState<IState>(INITIAL_STATE);

  const handleDialog = React.useCallback(
    (detailsDialogOpen: boolean) => () => handleChange({ detailsDialogOpen }),
    []
  );

  return (
    <Paper className="pt-20 pb-20 pl-30 pr-30 mb-10">
      <Row alignItems="center" justifyContent="space-between" className="mb-20">
        <Col>
          <h5>{t('settings.page.partnerInfo.section.company.title')}</h5>
        </Col>
        <Col>
          <IconButton onClick={handleDialog(true)}>
            <MuiIcons.Edit />
          </IconButton>
        </Col>
      </Row>
      <Row columnSpacing={2} rowSpacing={4} className="break-word-all">
        <Col xs={3}>
          <div className="mb-10 fs-12 steel">{t('forms.companyName')}</div>
          <div>{currentUser.data?.partner?.name}</div>
        </Col>
        <Col xs={3}>
          <div className="mb-10 fs-12 steel">{t('forms.city')}</div>
          <div>{currentUser.data?.partner?.address?.city || '-'}</div>
        </Col>
        <Col xs={2}>
          <div className="mb-10 fs-12 steel">{t('forms.country')}</div>
          <div>{currentUser.data?.partner?.address?.country || '-'}</div>
        </Col>
        <Col xs={2}>
          <div className="mb-10 fs-12 steel">{t('forms.zipcode')}</div>
          <div>{currentUser.data?.partner?.address?.zipCode || '-'}</div>
        </Col>
        <Col xs={2}>
          <div className="mb-10 fs-12 steel">{t('forms.emailsLanguage')}</div>
          <div>
            {currentUser.data?.partner?.lang
              ? t(`app.lang.${currentUser.data?.partner?.lang}`)
              : '-'}
          </div>
        </Col>
        <Col xs={3}>
          <div className="mb-10 fs-12 steel">{t('forms.address1')}</div>
          <div>{currentUser.data?.partner?.address?.line1 || '-'}</div>
        </Col>
        <Col xs={3}>
          <div className="mb-10 fs-12 steel">{t('forms.address2')}</div>
          <div>{currentUser.data?.partner?.address?.line2 || '-'}</div>
        </Col>
        <Col xs={2}>
          <div className="mb-10 fs-12 steel">{t('forms.phone')}</div>
          <div>
            {currentUser.data?.partner?.phone
              ? `+${currentUser.data?.partner.phone}`
              : '-'}
          </div>
        </Col>
        <Col xs={4}>
          <div className="mb-10 fs-12 steel">
            {t('forms.shortname', { shortName: hash }).replace('&#x2F;', '/')}
          </div>
          <div>{currentUser.data?.partner?.shortName || '-'}</div>
        </Col>
      </Row>
      <MainInfoDialog
        open={state.detailsDialogOpen}
        onClose={handleDialog(false)}
        onSave={() => undefined}
      />
    </Paper>
  );
};

export default Info;
