import React from 'react';
import { DialogProps, Dialog, Button } from 'elements';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { logOut } from '../utils';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

type Props = DialogProps<any> & { date: any };

const renderer = (t: TFunction) => ({
  seconds,
  minutes,
  completed,
}: CountdownRenderProps) => {
  if (completed) {
    logOut();
    return <span>{t('app.keepAliveDialog.loggingOut') as string}</span>;
  } else {
    return (
      <span>
        <Trans
          i18nKey="session.expired.content"
          values={{ countdown: minutes * 60 + seconds }}
          components={{ 1: <span className="primary" /> }}
        />
      </span>
    );
  }
};

const KeepSessionAliveDialog = (props: Props) => {
  const { onSave, date, ...rest } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      {...rest}
      title={t('session.expired.title')}
      actions={
        <Button onClick={onSave}>{t('session.expired.keepAlive')}</Button>
      }
    >
      <Countdown date={date} renderer={renderer(t)} onComplete={logOut} />
    </Dialog>
  );
};

export default KeepSessionAliveDialog;
