import { validator, dayjs } from 'utils';

export const MAINTENANCE_OPTIONS: ValueType<IMaintenanceRecurringInterval>[] = [
  {
    value: 'NONE',
    label: 'patching.options.recurring.single',
  },
  {
    value: 'DAY',
    label: 'patching.options.recurring.daily',
  },
  {
    value: 'WEEK',
    label: 'patching.options.recurring.weekly',
  },
  {
    value: 'MONTH',
    label: 'patching.options.recurring.monthly',
  },
];

export const maintenanceValidate = async (
  values: IMonitoring.MwFormValues
): Promise<AnyShape> => {
  const errors: AnyShape = {};

  if (validator.isEarlierThan(values.startAt, dayjs().startOf('day'))) {
    errors.startAt = 'forms.invalid.date';
  }

  if (validator.isLaterThan(values.endAt, values.startAt.add(30, 'days'))) {
    errors.endAt = 'forms.invalid.date';
  }

  return errors;
};
