import React, { useMemo } from 'react';
import cn from 'classnames';
import { usePermissions } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useAccount } from 'hooks';
import { MuiIcons, styled } from 'elements';
import { openInNewTab, remCalc } from 'utils';

const SupportInfoIcon = styled((props: { className?: string }) => {
  const [account] = useAccount();
  const { isCurrentPartner, isCurrentTenant } = usePermissions();

  const infoString = useMemo(() => {
    const defaultSupportInfo =
      account?.lang === 'en'
        ? 'https://awesome.cloud/go/support-en'
        : 'https://awesome.cloud/go/support-de';

    let supportInfo = undefined;

    if (isCurrentTenant) {
      supportInfo = account?.partner?.supportInfo;
    }

    return supportInfo || defaultSupportInfo;
  }, [account?.lang, isCurrentTenant, isCurrentPartner]);

  return (
    <MuiIcons.QuestionMark
      className={cn('mr-25 pointer', props.className)}
      onClick={() => openInNewTab(infoString)}
    />
  );
})(({ theme }) => {
  const mobileUp = theme.breakpoints.up(1200);
  return {
    color: theme.palette.appBar.contrastText,
    fontSize: remCalc(17),
    [mobileUp]: {
      fontSize: remCalc(21),
    },
  };
});

export default observer(SupportInfoIcon);
