export enum TestSuffixes { // eslint-disable-line
  input = 'input',
  inputLabel = 'input-label',
  label = 'label',
  inputError = 'input-error',
  error = 'error',
  button = 'button',
  buttonSubmit = 'submit-button',
  checkbox = 'checkbox',
  badge = 'badge',
  alert = 'alert',
  systemMessageSuccess = 'system-message-success',
  systemMessageError = 'system-message-error',
  systemMessageWarning = 'system-message-warning',
  systemMessageInfo = 'system-message-info',
  icon = 'icon',
  wrapper = 'wrapper',
  loader = 'loader',
  dotsLoader = 'dots-loader',
  tooltip = 'tooltip',
  table = 'table',
  tableRow = 'table-row',
  switcher = 'switcher',
  tab = 'tab',
  tabContent = 'tab-content',
  stepper = 'stepper',
  slider = 'slider',
  select = 'select',
  selectItem = 'select-item',
  menu = 'menu',
  item = 'item', // general for div list item
  menuItem = 'menu-item',
  pagination = 'pagination',
  paginationPage = 'pagination-page-select',
  paginationNext = 'pagination-next-button',
  paginationPrev = 'pagination-prev-button',
  list = 'list',
  listItem = 'list-item',
  dialog = 'dialog',
  form = 'form',
  paper = 'paper',
  dropdown = 'dropdown',
  dropdownItem = 'dropdown-item',
}

export enum TestPrefixes { // eslint-disable-line
  login = 'login',
  signUp = 'sign-up',
  tenantSignUp = 'tenant-sign-up',
  createPassword = 'create-password',
  resetPassword = 'reset-password',
  requestResetPassword = 'request-reset-password',
  verifyCode = 'verification-code',
  qrCode = 'qr-code',
  leftBarNavigation = 'left-bar-navigation',

  users = 'users',
  createUser = 'create-user',
  createFirstUser = 'create-first-user',
  editUser = 'edit-user',

  partners = 'partners',
  createPartner = 'create-partner',
  editPartner = 'edit-partner',

  tenants = 'tenants',
  createTenant = 'create-tenant',
  inviteTenant = 'invite-tenant',
  editTenant = 'edit-tenant',

  roles = 'roles',
  roleItem = 'role-item',
  services = 'services',
  vm = 'virtual-machine',
  vmDetails = 'virtual-machine-details',
  connectivity = 'connectivity',
  coreInfrastructure = 'core-infrastructure',
  exchange = 'hosted-exchange',
  dns = 'dns',
  remoteDesktop = 'remoteDesktop',
  backup = 'backup',
  tasks = 'tasks',
  partnerSettings = 'partner-settings',
  appVersion = 'app-version-info',

  editableText = 'editable-text',
}

export const ALL_TEST_IDS = {
  login: {
    wrapper: `${TestPrefixes.login}-${TestSuffixes.wrapper}`,
    form: `${TestPrefixes.login}-${TestSuffixes.form}`,
    inputs: TestPrefixes.login,
    submit: TestPrefixes.login,
  },
  signUp: {
    wrapper: `${TestPrefixes.signUp}-${TestSuffixes.wrapper}`,
    form: `${TestPrefixes.signUp}-${TestSuffixes.form}`,
    inputs: TestPrefixes.signUp,
    continue: `${TestPrefixes.signUp}-continue`,
    submit: TestPrefixes.signUp,
  },
  tenantSignUp: {
    wrapper: `${TestPrefixes.signUp}-${TestSuffixes.wrapper}`,
    form: `${TestPrefixes.signUp}-${TestSuffixes.form}`,
    inputs: TestPrefixes.signUp,
    continue: `${TestPrefixes.signUp}-continue`,
    submit: TestPrefixes.signUp,
  },
  createPassword: {
    wrapper: `${TestPrefixes.createPassword}-${TestSuffixes.wrapper}`,
    form: `${TestPrefixes.createPassword}-${TestSuffixes.form}`,
    inputs: TestPrefixes.createPassword,
    submit: TestPrefixes.createPassword,
  },
  resetPassword: {
    wrapper: `${TestPrefixes.resetPassword}-${TestSuffixes.wrapper}`,
    form: `${TestPrefixes.resetPassword}-${TestSuffixes.form}`,
    inputs: TestPrefixes.resetPassword,
    submit: TestPrefixes.resetPassword,
  },
  requestResetPassword: {
    wrapper: `${TestPrefixes.requestResetPassword}-${TestSuffixes.wrapper}`,
    form: `${TestPrefixes.requestResetPassword}-${TestSuffixes.form}`,
    inputs: TestPrefixes.requestResetPassword,
    submit: TestPrefixes.requestResetPassword,
    confirmed: `${TestPrefixes.requestResetPassword}-confirmed`,
  },
  qrCode: {
    wrapper: `${TestPrefixes.qrCode}-${TestSuffixes.wrapper}`,
    image: `${TestPrefixes.qrCode}-image`,
    back: `${TestPrefixes.qrCode}-back`,
    submit: TestPrefixes.qrCode,
  },
  verifyCode: {
    wrapper: `${TestPrefixes.verifyCode}-${TestSuffixes.wrapper}`,
    error: TestPrefixes.verifyCode,
  },
  header: {
    wrapper: 'app-header',
    navItem: 'app-header-nav-item',
    logout: 'app-header-logout',
  },
  navigation: {
    leftBar: TestPrefixes.leftBarNavigation,
    leftBarItem: `${TestPrefixes.leftBarNavigation}-item`,
  },
  users: {
    table: TestPrefixes.users,
    createButton: TestPrefixes.createUser,
    createFirstUserButton: TestPrefixes.createFirstUser,
    goToRoles: `${TestPrefixes.users}-go-to-roles`,
  },
  roles: {
    list: TestPrefixes.roles,
    roleItem: TestPrefixes.roleItem,
    roleItemName: `${TestPrefixes.roleItem}-name`,
    roleItemCount: `${TestPrefixes.roleItem}-count`,
    roleItemDelete: `${TestPrefixes.roleItem}-delete`,
    roleItemEdit: `${TestPrefixes.roleItem}-edit`,
    createRole: `${TestPrefixes.roles}-create`,
    editRole: `${TestPrefixes.roles}-edit`,
    create: `${TestPrefixes.roles}-create`,
    permission: `${TestPrefixes.roles}-permission`,
  },
  tenants: {
    submit: TestPrefixes.tenants,
    table: TestPrefixes.tenants,
    errorGetTenant: `${TestPrefixes.tenants}-get-error`,
    createTenant: TestPrefixes.createTenant,
    inviteTenant: TestPrefixes.inviteTenant,
    editTenant: TestPrefixes.editTenant,
    createTenantButtonEmpty: `${TestPrefixes.createTenant}-empty`,
    dialog: {
      back: `${TestPrefixes.tenants}-${TestSuffixes.dialog}-back`,
      skip: `${TestPrefixes.tenants}-${TestSuffixes.dialog}-skip`,
      next: `${TestPrefixes.tenants}-${TestSuffixes.dialog}-next`,
      submit: `${TestPrefixes.tenants}-${TestSuffixes.dialog}-submit`,
    },
    inviteTenantDialog: {
      cancel: `${TestPrefixes.inviteTenant}-${TestSuffixes.dialog}-cancel`,
      submit: `${TestPrefixes.inviteTenant}-${TestSuffixes.dialog}-submit`,
    },
  },
  partners: {
    selectAccountType: `${TestPrefixes.partners}-account-type`,
    table: TestPrefixes.partners,
    createPartner: `${TestPrefixes.partners}-create`,
    editPartner: `${TestPrefixes.partners}-edit`,
    dialog: {
      paper: `${TestPrefixes.partners}-${TestSuffixes.dialog}`,
      cancel: `${TestPrefixes.partners}-${TestSuffixes.dialog}-cancel`,
      back: `${TestPrefixes.partners}-${TestSuffixes.dialog}-back`,
      skip: `${TestPrefixes.partners}-${TestSuffixes.dialog}-skip`,
      next: `${TestPrefixes.partners}-${TestSuffixes.dialog}-next`,
      submit: `${TestPrefixes.partners}-${TestSuffixes.dialog}-submit`,
    },
  },

  partnerSettings: {
    billingEmails: `${TestPrefixes.partnerSettings}-billing-emails`,
  },

  services: {
    navBar: `${TestPrefixes.services}-navigation`,
    main: {
      globalIcon: `${TestPrefixes.services}-connection-icon`,
      cardMenu: `${TestPrefixes.services}-card-menu`,
    },
    connectivity: {
      main: TestPrefixes.connectivity,
      network: `${TestPrefixes.connectivity}-network`,
      internalPool: `${TestPrefixes.connectivity}-internal-pool`,
      rateLimit: `${TestPrefixes.connectivity}-rate-limit`,
      wanIp: `${TestPrefixes.connectivity}-wan-ip`,
      ipsecVpnTunnel: `${TestPrefixes.connectivity}-ipsec-vpn`,
      vpnUsers: `${TestPrefixes.connectivity}-vpn-users`,
      addIpsecVpnTunnel: `${TestPrefixes.connectivity}-add-ipsec-vpn-button`,
      ipsecVpnDialog: `${TestPrefixes.connectivity}-ipse-vpn-dialog`,
      vpnUserDialog: `${TestPrefixes.connectivity}-vpn-user-dialog`,
      sslVpnSwitcher: `${TestPrefixes.connectivity}-ssl-vpn-switcher`,
    },
    coreInfrastructure: {
      admins: {
        main: `${TestPrefixes.coreInfrastructure}-admins`,
        addButton: `${TestPrefixes.coreInfrastructure}-admins-add`,
        edit: `${TestPrefixes.coreInfrastructure}-admins-edit`,
        create: `${TestPrefixes.coreInfrastructure}-admins-create`,
      },
      users: {
        main: `${TestPrefixes.coreInfrastructure}-users`,
        addButton: `${TestPrefixes.coreInfrastructure}-users-add`,
        edit: `${TestPrefixes.coreInfrastructure}-users-edit`,
        create: `${TestPrefixes.coreInfrastructure}-users-create`,
      },
      main: TestPrefixes.coreInfrastructure,
    },
    remote: {
      main: TestPrefixes.remoteDesktop,
      users: `${TestPrefixes.remoteDesktop}-users`,
      usersDialog: `${TestPrefixes.remoteDesktop}-dialog-users`,
      manageUsers: `${TestPrefixes.remoteDesktop}-manage-users`,
      enableUsers: `${TestPrefixes.remoteDesktop}-enable-users`,
    },
    exchange: {
      main: TestPrefixes.exchange,
    },
    dns: {
      main: TestPrefixes.dns,
    },
    vm: {
      main: TestPrefixes.vm,
      emptyCard: `${TestPrefixes.vm}-card-empty`,
      emptyCardIcon: `${TestPrefixes.vm}-card-empty-icon`,
      details: TestPrefixes.vmDetails,
    },
    backup: {
      main: TestPrefixes.backup,
      disabled: `${TestPrefixes.vm}-disabled`,
      enabled: `${TestPrefixes.vm}-enabled`,
      remove: `${TestPrefixes.vm}-remove`,
      edit: `${TestPrefixes.vm}-edit`,
      versions: {
        table: `${TestPrefixes.vm}-versions-table`,
      },
      notifications: {
        type: `${TestPrefixes.vm}-notifications-type`,
        addUser: `${TestPrefixes.vm}-add-user`,
        deleteUser: `${TestPrefixes.vm}-delete-user`,
        users: `${TestPrefixes.vm}-users`,
      },
    },
  },
  appVersion: {
    main: TestPrefixes.appVersion,
  },
};
