import * as React from 'react';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { confirm, Emitter } from 'utils';
import { CustomEvents } from 'enums';
import { AnyFunc } from 'global-shapes';
import ForderDetailsDialog from 'components/Folders/FolderDetails';
import ForderInnerContentDialog from 'components/Folders/FolderInnerContentDialog';
import { IActionHandlers, ActionOption } from 'components/ActionButtons/types';
import {
  useState,
  useFolderMenuItems,
  useStateHandler,
  useAccount,
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { BaseDecorator } from 'states/types';

export type InjectedProps = {
  isRequesting: boolean;
  handlers: IActionHandlers;
  onDelete: AnyFunc;
  menuItems?: ActionOption[];
  actionItems?: ActionOption[];
  instance: BaseDecorator<IServiceGroups.Group, true>;
};

type IState = {
  innerContentDialog: boolean;
  folderInfoDialog: boolean;
};

const initial: IState = {
  innerContentDialog: false,
  folderInfoDialog: false,
};

export function withFolderActions(Component: React.ComponentType<any>) {
  return observer((props: IServiceGroups.Group) => {
    const { ...folder } = props;
    const { t } = useTranslation();
    const [account] = useAccount();
    const folders = useStateHandler(StateHandlers.folders);
    const folderContent = useStateHandler(StateHandlers.folderContent);

    const [state, handleState] = useState<IState>(initial);

    const isRequesting = folders.isRequesting;

    const fetchFolders = React.useCallback(
      async () => Emitter.emit(CustomEvents.fetchFolderList, {}),
      [account?.tenant?.id]
    );

    const handlers = React.useMemo(() => {
      return {
        openInnerContent: () => {
          folderContent.setSelectedInstance(folder);
          return handleState({ innerContentDialog: true });
        },
        edit: () => {
          return handleState({ folderInfoDialog: true });
        },
        delete: () => {
          return confirm({
            title: t('folders.confirm.delete.title'),
            content: t('folders.confirm.delete.content'),
            onSuccess: () =>
              folders
                .remove({
                  tenantId: account?.tenant?.id,
                  groupId: folder?.id,
                })
                .then(fetchFolders),
            onCancel: () => undefined,
            successLabel: 'common.remove',
            cancelLabel: 'common.cancel',
          });
        },
      };
    }, [JSON.stringify(folder), fetchFolders]) as IActionHandlers;

    const { menuItems, actionItems } = useFolderMenuItems({
      instance: folder,
      isRequesting,
      handlers,
    });

    return (
      <>
        <Component
          {...props}
          isRequesting={isRequesting}
          handlers={handlers}
          menuItems={menuItems}
          actionItems={actionItems}
          instance={folders}
        />

        <ForderDetailsDialog
          open={state.folderInfoDialog}
          onClose={() => handleState(false, 'folderInfoDialog')}
          onSave={fetchFolders}
          initialValues={folder}
        />

        <ForderInnerContentDialog
          open={state.innerContentDialog}
          onClose={() => {
            handleState(false, 'innerContentDialog');
            folderContent.setSelectedInstance(null);
          }}
          onSave={(payload) => undefined}
          initialValues={folder}
        />
      </>
    );
  });
}
