import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { AddIcon } from 'elements';
import { IAutomationTestProps } from 'automation-test';
import { CardWrapper, CardPlaceholder, CardAddIconWrapper } from './Styled';

type Props = {
  id: any;
  title: string;
  placeholder: string;
  onAdd?: () => void;
  isDisabled?: boolean;
  isEditable?: boolean;
};

const EmptyCard = (props: Props & IAutomationTestProps) => {
  const { title, placeholder, isDisabled, onAdd, id, isEditable } = props;
  const { t } = useTranslation();
  const onAddCallback = React.useCallback(() => {
    if (!isDisabled && onAdd) {
      onAdd();
    }
  }, [isDisabled, onAdd]);

  return (
    <CardWrapper className={cn('fs-14')} data-draggable="ignore">
      <h5 className="mb-15">{t(title)}</h5>
      <CardPlaceholder className={cn('steel')}>
        {t(placeholder)}
      </CardPlaceholder>
      <CardAddIconWrapper>
        {isEditable && (
          <AddIcon
            size="large"
            onClick={onAddCallback}
            disabled={isDisabled}
            data-test-id={props.testId}
            id={id}
          />
        )}
      </CardAddIconWrapper>
    </CardWrapper>
  );
};

export default EmptyCard;
