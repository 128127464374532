import * as React from 'react';
import { IconButton, MuiIcons, Chip, createColumnHelper } from 'elements';
import { AnyFunc } from 'global-shapes';
import * as Yup from 'yup';
import { IOsServiceItemGrouped, IProcessMetricStatus } from 'monitoring';

const PROCESS_STATUS: Record<number, IProcessMetricStatus> = {
  0: 'running',
  1: 'paused',
  2: 'startPending',
  3: 'pausePending',
  4: 'continuePending',
  5: 'stopPending',
  6: 'stopped',
  7: 'unknown',
  255: 'noSuchService',
};

const PROCESS_COLOR_STATUS: Record<number, string> = {
  0: 'success',
  1: 'systemInfo',
  2: 'systemInfo',
  3: 'systemInfo',
  4: 'systemInfo',
  5: 'systemInfo',
  6: 'default',
  7: 'error',
  255: 'warning',
};

export const serviceNameValidationSchema = Yup.object().shape({
  serviceName: Yup.string().required('forms.required'),
});

const column = createColumnHelper<IOsServiceItemGrouped>();

export const processColumns = (config: {
  t: AnyFunc;
  isEditable?: boolean;
  onDelete: (item: IOsServiceItemGrouped) => void;
}) => [
  column.accessor('name', {
    header: (
      <span className="steel fs-12 weight-normal">
        {config.t('table.head.name')}
      </span>
    ),
    disableSort: false,
  }),

  column.accessor('metricKey', {
    header: (
      <span className="steel fs-12 weight-normal">
        {config.t('table.head.status')}
      </span>
    ),
    disableSort: true,
    cell: ({ row }: ICell<IOsServiceItemGrouped>) => {
      let lastData = row.original.lastData;
      if (row.original.resource !== 'service') return <span>-</span>;

      if (lastData === null) {
        lastData = { t: 0, v: 7 };
      }

      return (
        <div className="full-width">
          <Chip
            status={PROCESS_COLOR_STATUS[row.original.lastData?.v] as any}
            className="fs-11"
          >
            {config.t(
              `monitoring.sections.processes.metricStatus.${
                PROCESS_STATUS[row.original.lastData?.v]
              }`
            )}
          </Chip>
        </div>
      );
    },
  }),
  column.accessor('id', {
    disableSort: true,
    header: '',
    cell: ({ row }: ICell<IOsServiceItemGrouped>) => {
      return (
        <div className="full-width flex justify-end">
          <IconButton
            onClick={() => config.onDelete(row.original)}
            disabled={!config.isEditable}
          >
            <MuiIcons.Delete />
          </IconButton>
        </div>
      );
    },
  }),
];
