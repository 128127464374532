import React from 'react';
import { Select, Input, DateTimePicker, Radio, Switch, Alert } from 'elements';
import { dayjs } from 'utils';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { MINUTE_STEP, REBOOT_INTERVAL_OPTIONS } from './constants';
import {
  findDisabledHours,
  findDisabledMinutes,
  parseNextRebootField,
} from './helpers';
import { RebootDialogInputsWrapper } from './Styled';

interface IProps {
  onChange: (field: string, data: any) => void;
  values: IRebootDialogTypes.Values;
  errors: FormikErrors<IRebootDialogTypes.Values>;
  isEnabledMode?: boolean;
  isRdsReconfiguration?: boolean;
}

const RebootRuleBaseForm = (props: IProps) => {
  const { onChange, values, errors, isEnabledMode, isRdsReconfiguration } =
    props;
  const { t } = useTranslation();

  const startDate = values.startDate ? dayjs(values.startDate) : dayjs();

  return (
    <div>
      {isEnabledMode && (
        <Alert severity="info" className="mb-25">
          {t('services.rds.maintenance.tab.alerts.modeEnabled')}
        </Alert>
      )}
      <FormControl className="full-width mb-15">
        <RadioGroup
          value={values.configureType}
          onChange={(ev, val) => onChange('configureType', val)}
          name="configureType"
        >
          <Radio
            value="now"
            label={t('services.rds.maintenance.radio.reconfigureNow')}
            className="mb-5 ml-0"
            disabled={isEnabledMode}
          />
          <Radio
            value="plan"
            label={t('services.rds.maintenance.radio.plan')}
            className="ml-0"
            disabled={isEnabledMode}
          />
        </RadioGroup>
      </FormControl>
      {values.configureType === 'now' ? (
        <Input
          value={values.configureText}
          placeholder={t(
            'services.rds.maintenance.inputs.placeholders.reconfigureNow'
          )}
          onChange={(ev) => onChange('configureText', ev.target.value)}
          error={!!errors.configureText}
          helperText={errors.configureText}
        />
      ) : (
        <>
          <RebootDialogInputsWrapper>
            <div className="mb-15">
              <Select
                label={t('forms.recurring')}
                value={values.recurringInterval}
                onChange={(recurringInterval) =>
                  onChange('recurringInterval', recurringInterval)
                }
                options={REBOOT_INTERVAL_OPTIONS}
                isDisabled={isRdsReconfiguration || isEnabledMode}
              />
            </div>
            <div className="mb-35">
              <DateTimePicker
                label={t('services.rds.maintenance.select.startDate')}
                maximumDate={dayjs().add(31, 'days').toDate()}
                minimumDate={dayjs().toDate()}
                value={startDate}
                onChange={(val) => {
                  onChange('startDate', val);
                }}
                closeOnSelect
                minuteStep={MINUTE_STEP}
                disabled={isEnabledMode}
                disabledTime={() => {
                  return {
                    disabledHours: findDisabledHours(startDate),
                    disabledMinutes: findDisabledMinutes(startDate),
                  };
                }}
              />
              {errors.startDate && (
                <div className="error fs-12 pt-5 ">
                  {t(errors.startDate as string)}
                </div>
              )}
            </div>
          </RebootDialogInputsWrapper>

          <div className="mb-30">
            <Switch
              name="notificationEnabled"
              checked={values.notificationEnabled}
              onCheck={(notificationEnabled) =>
                onChange('notificationEnabled', notificationEnabled)
              }
              disabled={isEnabledMode}
              label={t(
                'services.rds.reboot.dialogs.addReboot.checkbox.notifyUsers'
              )}
            />
          </div>

          <div>
            {t('services.rds.reboot.dialogs.addReboot.nextReboot')}:{' '}
            {parseNextRebootField(
              values.startDate,
              values.recurringInterval?.value || 'SINGLE'
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RebootRuleBaseForm;
