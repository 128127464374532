import * as Yup from 'yup';
import * as TYPES from './types';
import { BILLING_CIRCLE_OPTIONS } from 'enums';

export const ImportVmSteps = [
  'services.vmList.dialog.importVm.steps.1',
  'services.vmList.dialog.importVm.steps.2',
  'services.vmList.dialog.importVm.steps.3',
];

type IValidationConfig = {
  tab: number;
};

export function DefineValidationSchema({ tab }: IValidationConfig) {
  switch (tab) {
    case 0:
      return Yup.object().shape({
        vcd: Yup.object().required('forms.required'),
      });

    case 1:
      return Yup.object().shape({
        vm: Yup.object().required('forms.required'),
      });

    case 2:
      return Yup.object().shape({
        templateId: Yup.object().required('forms.required'),
        billingCycle: Yup.object().required('forms.required'),
      });

    default:
      return undefined;
  }
}

export const InitialFormValues: TYPES.IFormValues = {
  vcd: undefined,
  vm: undefined,
  isUsageEnabled: true,
  isGuestCustomizationEnabled: true,
  billingCycle: BILLING_CIRCLE_OPTIONS[0],
};
