import * as React from 'react';
import { Button, Col, Row, Paper, MuiIcons } from 'elements';
import { useTranslation } from 'react-i18next';
import { AxiosPromise } from 'axios';
import { confirm, noop, PUT } from 'utils';
import { observer } from 'mobx-react-lite';
import AddEmailDialog from './AddEmailDialog';
import { ALL_TEST_IDS } from 'enums';
import { useAsync, usePermissions, useAccount } from 'hooks';
import * as StateHandlers from 'states';
import { ICustomer } from 'customer-shapes';
import { AccessLevelLow } from 'global-shapes';
import { BillingEmailsHolder } from './Styled';

const updatePartnerRequest = (
  emails: IPartnerTypes.IBillingEmail[]
): AxiosPromise<IPartnerTypes.Partner> => PUT(`/partners`, {}, { emails });

const updateTenantRequest = (
  emails: IPartnerTypes.IBillingEmail[]
): AxiosPromise<ICustomer> => PUT(`/tenants`, {}, { emails });

const updateRequest = (accessLevel: AccessLevelLow) => {
  if (accessLevel === 'partner') {
    return updatePartnerRequest;
  }

  return updateTenantRequest;
};

const BillingEmails = observer(() => {
  const { t } = useTranslation();
  const [account] = useAccount();
  const { currentAccessLevel } = usePermissions();
  const [open, handleState] = React.useState(false);

  const handleDialog = React.useCallback(
    (_open: boolean) => () => handleState(_open),
    []
  );

  const billingEmails: IPartnerTypes.IBillingEmail[] =
    // @ts-ignore
    account ? account[currentAccessLevel]?.billingEmails || [] : [];

  const request = React.useMemo(() => {
    return updateRequest(currentAccessLevel);
  }, [currentAccessLevel]);

  const { execute: updateEmails, isPending: isSaving } = useAsync(
    (emails: IPartnerTypes.IBillingEmail[]) =>
      request(emails).then((res: any) => {
        StateHandlers.fetchAllAccountData({ shouldApplyTheme: false });
        handleDialog(false)();
        return res;
      })
  );

  const confirmDelete = React.useCallback(
    (email: string) => () => {
      confirm({
        title: t(
          'settings.page.partnerInfo.billingEmails.confirm.delete.title'
        ),
        content: t(
          'settings.page.partnerInfo.billingEmails.confirm.delete.content',
          { email }
        ),
        onSuccess: () =>
          updateEmails(billingEmails.filter((el) => el.email !== email)),
        onCancel: noop,
      });
    },
    [JSON.stringify(billingEmails)]
  );

  return (
    <Paper>
      <BillingEmailsHolder>
        <Row
          alignItems="center"
          justifyContent="space-between"
          className="mb-20"
        >
          <Col className="uppercase fs-14">
            {t('settings.page.partnerInfo.billingEmails.title') as string}
          </Col>
          <Col>
            <Button
              size="small"
              testId={ALL_TEST_IDS.partnerSettings.billingEmails}
              onClick={handleDialog(true)}
            >
              {t('settings.page.partnerInfo.billingEmails.buttons.addEmail')}
            </Button>
          </Col>
        </Row>
        <div className="steel fs-12">{t('table.head.email') as string}</div>

        {billingEmails.map((el) => (
          <Row
            key={el.email}
            justifyContent="space-between"
            className="pt-10 pb-10 mb-10"
          >
            <Col className="fs-14 steel">{el.email}</Col>
            <Col>
              <MuiIcons.Delete
                className="fs-20 steel pointer"
                onClick={confirmDelete(el.email)}
              />
            </Col>
          </Row>
        ))}
      </BillingEmailsHolder>
      <AddEmailDialog
        open={open}
        onSave={(email: IPartnerTypes.IBillingEmail) =>
          updateEmails([...billingEmails, email])
        }
        testId={ALL_TEST_IDS.partnerSettings.billingEmails}
        onClose={handleDialog(false)}
        isSaving={isSaving}
      />
    </Paper>
  );
});

export default BillingEmails;
