import { showSystemMessage } from 'utils';
import { invoicesService } from 'services';
import {
  IInvoice,
  IInvoicePosition,
  IInvoicePayment,
  IInvoiceStats,
  IInvoiceIncomeStats,
} from 'invoices';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export const invoices = new BaseMobxInstanceDecorator<IInvoice, true>({
  instanceName: 'invoices',
  requests: {
    get: async (query) => {
      const { requestType, ...q } = query;
      const res =
        requestType === 'base'
          ? await invoicesService.getInvoices(q)
          : await invoicesService.getPartnerOwnInvoices(q);

      return res;
    },
    create: async (payload) => {
      const res = await invoicesService.createInvoice(payload);
      showSystemMessage('invoices.notify.createInvoice.success', 'success');
      return res;
    },
    update: async (id, payload) => {
      const res = await invoicesService.editInvoice(id, payload);
      showSystemMessage('invoices.notify.editInvoice.success', 'success');
      return res;
    },
    reload: (id) => invoicesService.getInvoiceById(id),
    remove: async (id) => {
      const res = await invoicesService.removeInvoice(id);
      showSystemMessage('invoices.current.notify.delete.success', 'success');

      return res;
    },
  },
  additionalRequests: {
    markAsPaid: async (id, payload) => {
      const res = await invoicesService.editInvoice(id, payload);
      showSystemMessage('invoices.notify.markAsPaid.success', 'success');
      return res;
    },
    resetStatus: async (id, payload) => {
      const res = await invoicesService.editInvoice(id, payload);
      showSystemMessage('invoices.notify.resetStatus.success', 'success');
      return res;
    },
  },
  initialState: [],
});

export type IInvoicesHandler = BaseDecorator<IInvoice, true>;

export const invoicePositions = new BaseMobxInstanceDecorator<
  IInvoicePosition,
  true
>({
  instanceName: 'invoice-positions',
  requests: {
    get: async (query) => invoicesService.getInvoicePositions(query.id),
    create: async (payload) => {
      const res = await invoicesService.createInvoicePositions(
        payload.invoiceId,
        payload.values
      );
      showSystemMessage(
        'invoices.current.notify.position.create.success',
        'success'
      );
      return res;
    },
    update: async (id, payload) => {
      const res = await invoicesService.updateInvoicePositions(
        id.invoiceId,
        id.invoiceItemId,
        payload
      );
      showSystemMessage(
        'invoices.current.notify.position.update.success',
        'success'
      );
      return res;
    },
    remove: async (id) => {
      const res = await invoicesService.deleteInvoicePosition(
        id.invoiceId,
        id.invoiceItemId
      );
      showSystemMessage(
        'invoices.current.notify.position.delete.success',
        'success'
      );
      return res;
    },
  },
  initialState: [],
});

export type IInvoicePositionsHandler = BaseDecorator<IInvoicePosition, true>;

export const invoicePayments = new BaseMobxInstanceDecorator<
  IInvoicePayment,
  true
>({
  instanceName: 'invoice-payments',
  requests: {
    get: async (query) => {
      const res = await invoicesService.getInvoicePayments(query.id);

      return res;
    },
    create: async (payload) => {
      const res = await invoicesService.createInvoicePayments(
        payload.invoiceId,
        payload.values
      );
      showSystemMessage(
        'invoices.current.notify.payment.create.success',
        'success'
      );
      return res;
    },
    remove: async (id) => {
      const res = await invoicesService.deleteInvoicePayment(
        id.invoiceId,
        id.invoiceItemId
      );
      showSystemMessage(
        'invoices.current.notify.payment.delete.success',
        'success'
      );
      return res;
    },
  },
  initialState: [],
});

export type IInvoicePaymentsHandler = BaseDecorator<IInvoicePayment, true>;

export const invoiceStatistics = new BaseMobxInstanceDecorator<
  IInvoiceStats,
  false
>({
  instanceName: 'invoice-stats',
  requests: {
    get: invoicesService.getStats,
  },
  initialState: {} as IInvoiceStats,
});

export type IInvoiceStatiticHandler = BaseDecorator<IInvoiceStats, false>;

export const invoiceIncomeStats = new BaseMobxInstanceDecorator<
  IInvoiceIncomeStats,
  false
>({
  instanceName: 'income-invoice-stats',
  requests: {
    get: invoicesService.getIncomeStats,
  },
  initialState: {} as IInvoiceIncomeStats,
});

export type IInvoiceIncomeStatiticHandler = BaseDecorator<
  IInvoiceIncomeStats,
  false
>;
