import * as React from 'react';
import * as Utils from 'utils';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'elements';
import { InvoicePositionItemDto } from './PositionDto';
import PositionSubItem from './PositionSubItem';
import { IInvoiceTypes } from '../types';

type IProps = InvoicePositionItemDto & IInvoiceTypes.AdditionalPositionProps;

const PositionItem = (props: IProps) => {
  const {
    index,
    formattedResourceValue,
    i18nCode,
    description,
    isPaid,
    isEditable,
    amount,
    hasSubItems,
    subItems,
    onDelete,
    onEdit,
  } = props;

  const { t } = useTranslation();

  const _isEditable = isEditable && !isPaid;

  return (
    <>
      <Row alignItems="center" className="bolder pt-15 pb-15 fs-16">
        <Col xs={6}>
          {`${index} `}
          {formattedResourceValue}
          {i18nCode
            ? t(`invoices.current.positions.items.${i18nCode}`)
            : description}
        </Col>
        <Col xs={6}>
          <Row alignItems="center">
            <Col xs={10}>
              <Row alignItems="center">
                <Col className="text-right" xs={4} />
                <Col className="text-right" xs={4} />
                <Col xs={4} className="text-right">
                  {Utils.numberToCurrency(amount, false, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Col>
              </Row>
            </Col>
            <Col xs={2} />
          </Row>
        </Col>
      </Row>
      {hasSubItems &&
        subItems.map((it, ind) => {
          return (
            <PositionSubItem
              key={ind}
              {...{
                index: `${index}.${ind + 1}`,
                isPaid,
                isEditable,
                onDelete,
                onEdit,
              }}
              {...it}
              isEditable={_isEditable}
            />
          );
        })}
    </>
  );
};

export default PositionItem;
