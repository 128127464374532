export const buildMonthCountByBillingInterval = (
  interval?: IVmTypes.IBillingCircleInterval
) => {
  switch (interval) {
    case 'YEAR':
      return 12;
    case 'HALF_YEAR':
      return 6;
    case 'QUARTER':
      return 3;
    default:
      return 1;
  }
};
