import * as React from 'react';
import * as R from 'ramda';
import { Col } from 'elements';
import { upgradeByteMeasure, downgradeByteMeasure } from 'utils';
import * as StateHandlers from 'states';
import { useStateHandler } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import FilterChip from './FilterChip';
import * as Types from './types';

type IProps = {
  query: IVmTypes.AllVmQuery;
  applied: Partial<Types.AppliedFilters>;
  hasFilters: boolean;
  onClear: (q: Record<string, any>) => void;
};

const FiltersChips = observer((props: IProps) => {
  const { onClear, applied, hasFilters } = props;
  const { t } = useTranslation();
  const osListLocal = useStateHandler(StateHandlers.osList);

  if (!hasFilters) {
    return <Col>--</Col>;
  }

  return R.keys(applied).map((key) => {
    if (key === 'badgeId') {
      return (
        <React.Fragment key={key}>
          {applied.badgeId?.map((b) => {
            return (
              <Col key={key + b.id}>
                <FilterChip
                  onClear={() =>
                    onClear({
                      [key]: applied.badgeId
                        ?.filter((bad) => bad.id !== b.id)
                        .map((el) => el.id),
                    })
                  }
                  dotColor={b.color}
                >
                  <div className="ellipsis" style={{ maxWidth: 130 }}>
                    {b.description}
                  </div>
                </FilterChip>
              </Col>
            );
          })}
        </React.Fragment>
      );
    }

    if (key === 'status') {
      return (
        <React.Fragment key={key}>
          {applied.status?.map((st: any) => {
            return (
              <Col key={key + st}>
                <FilterChip
                  onClear={() =>
                    onClear({
                      [key]: applied.status?.filter((s: any) => s !== st),
                    })
                  }
                >
                  {t(`statuses.${st}`)}
                </FilterChip>
              </Col>
            );
          })}
        </React.Fragment>
      );
    }

    if (key === 'activeService') {
      return (
        <React.Fragment key={key}>
          {applied.activeService?.map((s: any) => {
            return (
              <Col key={key + s}>
                <FilterChip
                  onClear={() =>
                    onClear({
                      [key]: applied.activeService?.filter(
                        (_s: any) => _s !== s
                      ),
                    })
                  }
                >
                  {t(`activeServices.${s}`)}
                </FilterChip>
              </Col>
            );
          })}
        </React.Fragment>
      );
    }

    if (key === 'billingType') {
      return (
        <React.Fragment key={key}>
          {applied.billingType?.map((cycle: any) => {
            return (
              <Col key={key + cycle}>
                <FilterChip
                  onClear={() =>
                    onClear({
                      [key]: applied.billingType?.filter(
                        (b: any) => b !== cycle
                      ),
                    })
                  }
                >
                  {t(
                    'services.vmList.toolbar.dialog.filters.section.billingCycle'
                  )}
                  : {t(`app.billingCircle.${cycle}`)}
                </FilterChip>
              </Col>
            );
          })}
        </React.Fragment>
      );
    }

    if (key === 'osId') {
      return (
        <React.Fragment key={key}>
          {applied.osId?.map((osId: any) => {
            const template = osListLocal.data.find((os) => os.id === osId);
            return (
              <Col key={key + osId}>
                <FilterChip
                  onClear={() =>
                    onClear({
                      [key]: applied.osId?.filter((b: any) => b !== osId),
                    })
                  }
                >
                  {t('services.vmList.toolbar.dialog.filters.section.os')}:{' '}
                  {template?.name}
                </FilterChip>
              </Col>
            );
          })}
        </React.Fragment>
      );
    }

    if (key === 'cpu') {
      return (
        <Col key={key}>
          <FilterChip
            onClear={() =>
              onClear({
                cpuMin: 1,
                cpuMax: 24,
              })
            }
          >
            {t('services.vmList.toolbar.dialog.filters.section.cpu')} (
            {/* @ts-ignore */}
            {applied.cpu[0]} - {applied.cpu[1]})
          </FilterChip>
        </Col>
      );
    }

    if (key === 'ram') {
      return (
        <Col key={key}>
          <FilterChip
            onClear={() =>
              onClear({
                ramMin: upgradeByteMeasure(1),
                ramMax: upgradeByteMeasure(64),
              })
            }
          >
            {t('services.vmList.toolbar.dialog.filters.section.ram')} (
            {/* @ts-ignore */}
            {downgradeByteMeasure(applied.ram[0])} - {/* @ts-ignore */}
            {downgradeByteMeasure(applied.ram[1])})
          </FilterChip>
        </Col>
      );
    }

    if (key === 'storage') {
      return (
        <Col key={key}>
          <FilterChip
            onClear={() =>
              onClear({
                totalDiskSizeMin: 1,
                totalDiskSizeMax: 1000,
              })
            }
          >
            {t('services.vmList.toolbar.dialog.filters.section.storage')} (
            {/* @ts-ignore */}
            {applied.storage[0]} - {applied.storage[1]})
          </FilterChip>
        </Col>
      );
    }

    return null;
  });
});

export default FiltersChips;
