import { default as axios } from 'axios';
import { Session } from 'sessions';

import { logOutIfNoSession, logOut } from './logOut';
import { apiPrefix } from '../constants';
import { initExpireSessionDialog } from '../expireSessionHandler';
import {
  getCurrentToken,
  getCurrentRefreshToken,
  setSessionsData,
} from '../session';
import { reinitSocketsConnections } from '../sockets/helpers';

export async function refreshTokenRequest() {
  const newSession = await axios.post<{ data: Session }>(
    `${apiPrefix}/auth/refresh`,
    {
      accessToken: getCurrentToken(),
      refreshToken: getCurrentRefreshToken(),
    }
  );

  return newSession.data.data;
}

export const refreshToken = async (): Promise<Session | void> => {
  try {
    await logOutIfNoSession();
    const newSession = await refreshTokenRequest();
    setSessionsData(newSession, true);
    initExpireSessionDialog();
    await reinitSocketsConnections();
    return newSession;
  } catch (er) {
    await logOut();
  }
};
