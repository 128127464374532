import { showSystemMessage } from 'utils';
import { employeesService } from 'services';
import { IEmployee } from 'employees';
import { BaseMobxInstanceDecorator } from './utilities';

export const employees = new BaseMobxInstanceDecorator<IEmployee, true>({
  instanceName: 'employees',
  requests: {
    get: async (query) => {
      const { tenantId, ...q } = query;
      return employeesService.getEmployees(tenantId, q);
    },
    create: async (payload) => {
      const { tenantId, ..._payload } = payload;
      const res = await employeesService.createEmployee(tenantId, _payload);
      showSystemMessage(
        'settings.page.partnerInfo.section.employees.notify.create.success',
        'success'
      );
      return res;
    },
    update: async (id, payload) => {
      const res = await employeesService.updateEmployee(
        id.tenantId,
        id.id,
        payload
      );
      showSystemMessage(
        'settings.page.partnerInfo.section.employees.notify.update.success',
        'success'
      );
      return res;
    },
    remove: async (id) => {
      const res = await employeesService.deleteEmployee(id.tenantId, id.id);
      showSystemMessage(
        'settings.page.partnerInfo.section.employees.notify.delete.success',
        'success'
      );
      return res;
    },
  },
  initialState: [],
});
