import * as React from 'react';
import { Button } from 'elements';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useReducer } from 'react';
import { stateReducer, handleReducerChange, showSystemMessage } from 'utils';
import { AnyFunc } from 'global-shapes';
import { useAsync } from 'hooks';
import { invoicesService } from 'services';
import { IExcludeCallback } from 'invoices';
import ImportPaymentsDialog from './ImportPaymentsDialog';
import { reformatDataForImportSave, onDropPromise } from './helpers';
import { INITIAL_PAYMENTS_DIALOG_STATE } from './constants';
import { IInvoiceTypes } from './types';

type Props = {
  onImportSuccess: AnyFunc;
};

type IState = [typeof INITIAL_PAYMENTS_DIALOG_STATE, AnyFunc];

const ImportPaymentsButton = (props: Props) => {
  const { onImportSuccess } = props;
  const { t } = useTranslation();
  const { execute, isPending: isImporting } = useAsync(
    invoicesService.importPayments
  );

  const [state, dispatcher]: IState = useReducer(
    stateReducer,
    INITIAL_PAYMENTS_DIALOG_STATE
  );

  const handleStateChange = React.useCallback(
    handleReducerChange<IInvoiceTypes.ImportPaymentsDialogState>(dispatcher),
    []
  );

  const onCreatePayments = React.useCallback(
    () =>
      execute(reformatDataForImportSave(state.dataToSave, state.included)).then(
        () => {
          onImportSuccess();
          showSystemMessage(
            'invoices.importPayments.dialog.action.import.success',
            'success'
          );
          handleCloseDialog();
        }
      ),
    [JSON.stringify(state)]
  );
  const handleCloseDialog = React.useCallback(
    () =>
      handleStateChange({
        isProcessing: false,
        dialog: false,
        data: [],
        dataToSave: [],
        included: {},
      }),
    []
  );

  const onInclude = React.useCallback<IExcludeCallback>(
    (refId, included) => {
      handleStateChange({
        included: { ...state.included, [refId]: included },
      });
    },
    [JSON.stringify(state.included)]
  );

  const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
    handleStateChange({ isProcessing: true, dialog: true });

    let newState: Partial<IInvoiceTypes.ImportPaymentsDialogState> = {};

    try {
      newState = await onDropPromise(acceptedFiles);
      newState.isProcessing = false;
      return handleStateChange(newState);
    } catch (er) {
      showSystemMessage('error while parsing', 'error');
      newState.isProcessing = false;
      return handleStateChange(INITIAL_PAYMENTS_DIALOG_STATE);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/xml': [],
      'application/zip': [],
    },
    multiple: true,
    onDrop,
  });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button variant="outlined" color="default">
          {t('invoices.buttons.importPayments')}
        </Button>
      </div>
      <ImportPaymentsDialog
        open={state.dialog}
        data={state.data}
        included={state.included}
        isImporting={isImporting}
        onInclude={onInclude}
        isLoading={state.isProcessing}
        onClose={handleCloseDialog}
        onSave={onCreatePayments}
      />
    </>
  );
};

export default ImportPaymentsButton;
