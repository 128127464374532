import { IPrice, IPricingService } from 'pricing';
import * as R from 'ramda';
import { CostResourceIds } from 'enums';
import { pricingService } from 'services';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export function filterProviderResources(
  services: IPricingService[]
): IPricingService[] {
  // need to hide resources that has separate prices for each usage item, but not for whole resource group
  return R.reduce<IPricingService, IPricingService[]>(
    (res, val) => {
      const service = val;
      service.resources = val.resources.filter(
        (resource) => resource.id !== CostResourceIds.softwarePackage
      );
      res.push(service);
      return res;
    },
    [],
    services
  );
}

export const pricingServices = new BaseMobxInstanceDecorator<
  IPricingService,
  true
>({
  instanceName: 'pricing-services',
  requests: {
    get: async () => {
      const services = await pricingService.getPricingServices();

      return { data: filterProviderResources(services.data) };
    },
  },
  initialState: [],
});

export type IPricingServicesHandler = BaseDecorator<IPricingService, true>;

export const mainPricing = new BaseMobxInstanceDecorator<IPrice, true>({
  instanceName: 'main-pricings',
  requests: {
    get: pricingService.getPartnerPrices,
    update: pricingService.update,
  },
  additionalRequests: {
    resetPartnerPrices: pricingService.resetPartnerPrices,
    resetTenantPrices: pricingService.resetTenantPrices,
  },
  initialState: [],
});

export type IMainPricingHandler = BaseDecorator<IPrice, true>;

export const defaultPricing = new BaseMobxInstanceDecorator<IPrice, true>({
  instanceName: 'default-provider-pricings',
  requests: {
    get: pricingService.getProviderDefault,
    create: (payload) => pricingService.createDefaultPrice(payload),
  },
  initialState: [],
});

export type IDefaultPricingHandler = BaseDecorator<IPrice, true>;

export const partnerPricing = new BaseMobxInstanceDecorator<IPrice, true>({
  requests: {
    get: (query) => pricingService.getProviderPartnerPrices(query.partnerId),
  },
  additionalRequests: {
    bulkUpdate: pricingService.bulkUpdatePartnerPrices,
  },
  initialState: [],
});

export type IPartnerPricingHandler = BaseDecorator<IPrice, true>;

export const tenantPricing = new BaseMobxInstanceDecorator<IPrice, true>({
  instanceName: 'tenant-pricings',
  requests: {
    get: pricingService.getMyTenantPrices,
    update: pricingService.updateTenantPrice,
  },
  initialState: [],
});

export type ITenantPricingHandler = BaseDecorator<IPrice, true>;

export const defaultTenantPricing = new BaseMobxInstanceDecorator<IPrice, true>(
  {
    requests: {
      get: pricingService.getTenantDefaultPrices,
      create: (payload) => pricingService.createDefaultPrice(payload),
    },
    initialState: [],
  }
);

export type IDefaultTenantPricingHandler = BaseDecorator<IPrice, true>;

export const currentTenantPricing = new BaseMobxInstanceDecorator<IPrice, true>(
  {
    instanceName: 'current-tenant-pricing',
    requests: {
      get: (query) => pricingService.getCurrentTenantPrices(query.id),
      update: pricingService.updateTenantPrice,
    },
    additionalRequests: {
      bulkUpdate: pricingService.bulkUpdateTenantPrices,
    },
    initialState: [],
  }
);

export type ICurrentTenantPricingHandler = BaseDecorator<IPrice, true>;
