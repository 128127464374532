export enum CostResourceIds {
  connectivityBase = 1,
  connectivityRateLimit200 = 29,
  connectivityRateLimit500 = 30,
  connectivityRateLimit1000 = 31,
  connectivityAdditionalNetwork = 32,
  coreBase = 2,
  coreGBStorage = 3,
  rdsBase = 4,
  rdsStandardUser = 5,
  rdsPowerUser = 6,
  rdsLightUser = 16,
  virtualServerVirtualCpus = 9,
  virtualServerRamMb = 10,
  winLicense = 8,
  virtualServerBase = 7,
  virtualServerGBStorage = 11,
  hostedExchangeBase = 12,
  hostedExchangeMailboxes = 13,
  backupBase = 14,
  backupStorage = 15,
  dnsPerDomainZone = 17,
  serviceAndSupportHourlyRate = 18,
  rds2faLicense = 19,
  monitoring = 20,
  rdsProfileDisks = 21,
  ipv4 = 22,
  ipv6 = 23,
  outsourceManagement = 24,
  patchingManagement = 25,
  softwarePackage = 26,
}

export const APP_SERVICES_GROUP = {
  Connectivity: {
    id: 1,
    title: 'Connectivity Service',
    resources: {
      BaseFee: {
        id: CostResourceIds.connectivityBase,
        title: 'Base fee',
      },
    },
  },
  CoreInfrastructure: {
    id: 2,
    title: 'Core Infrastructure',
    resources: {
      BaseFee: {
        id: CostResourceIds.coreBase,
        title: 'Base fee',
      },
      FileShareStorage: {
        id: CostResourceIds.coreGBStorage,
        title: 'File Share Storage',
      },
    },
  },
  RDS: {
    id: 3,
    title: 'Remote Desktop',
    resources: {
      BaseFee: {
        id: CostResourceIds.rdsBase,
        title: 'Base fee',
      },
      OfficeUsers: {
        id: CostResourceIds.rdsStandardUser,
        title: 'Power Users',
      },
      PowerUsers: {
        id: CostResourceIds.rdsPowerUser,
        title: 'Extreme Users',
      },
      LightUsers: {
        id: CostResourceIds.rdsLightUser,
        title: 'Standard Users',
      },
      TwoFALicense: {
        id: CostResourceIds.rds2faLicense,
        title: '2FA license',
      },
      ProfileStorage: {
        id: CostResourceIds.rdsProfileDisks,
        title: 'Additional profile Storage',
      },
    },
  },
  VirtualServer: {
    id: 4,
    title: 'Virtual Server',
    resources: {
      BaseFee: {
        id: CostResourceIds.virtualServerBase,
        title: 'Base fee',
      },
      WindowsServerLicense: {
        id: CostResourceIds.winLicense,
        title: 'Windows Server License',
      },
      vCPU: {
        id: CostResourceIds.virtualServerVirtualCpus,
        title: 'vCPU',
      },
      RAM: {
        id: CostResourceIds.virtualServerRamMb,
        title: 'RAM',
      },
      Storage: {
        id: CostResourceIds.virtualServerGBStorage,
        title: 'SSD storage',
      },
      IPv4: {
        id: CostResourceIds.ipv4,
        title: 'IPv4 address',
      },
      IPv6: {
        id: CostResourceIds.ipv6,
        title: 'IPv6 address',
      },
    },
  },
  HostedExchange: {
    id: 5,
    title: 'Hosted Exchange',
    resources: {
      BaseFee: {
        id: CostResourceIds.hostedExchangeBase,
        title: 'Base fee',
      },
      Mailbox: {
        id: CostResourceIds.hostedExchangeMailboxes,
        title: 'Mailboxes',
      },
    },
  },
  Backup: {
    id: 6,
    title: 'Backup Service',
    resources: {
      License: {
        id: CostResourceIds.backupBase,
        title: 'Server licenses',
      },
      Storage: {
        id: CostResourceIds.backupStorage,
        title: 'TB Storage',
      },
    },
  },
  DNS: {
    id: 7,
    title: 'DNS',
    resources: {
      Domain: {
        id: CostResourceIds.dnsPerDomainZone,
        title: 'Domain zone(min. 20 zones)',
      },
    },
  },
  CustomerSupport: {
    id: 8,
    title: 'Service and Support',
    resources: {
      Support: {
        id: CostResourceIds.serviceAndSupportHourlyRate,
        title: 'Hourly rate',
      },
    },
  },
  Monitoring: {
    id: 9,
    title: 'Monitoring',
    resources: {
      MonitoringService: {
        id: CostResourceIds.monitoring,
        title: 'Monitoring service',
      },
      OutsourceManagement: {
        id: CostResourceIds.outsourceManagement,
        title: 'Managed system',
      },
    },
  },
  Patching: {
    id: 10,
    title: 'Patching',
    resources: {
      OutsourceManagement: {
        id: CostResourceIds.patchingManagement,
        title: 'Managed system',
      },
    },
  },
};

export const VM_BASED_APP_SERVICE_IDS = [APP_SERVICES_GROUP.VirtualServer.id];

export const RESOURCE_ID_TO_UNIT_MAP = {
  [APP_SERVICES_GROUP.VirtualServer.resources.vCPU.id]: '',
  [APP_SERVICES_GROUP.VirtualServer.resources.RAM.id]: 'GB',
  [APP_SERVICES_GROUP.VirtualServer.resources.Storage.id]: 'GB',
  [APP_SERVICES_GROUP.VirtualServer.resources.IPv4.id]: '',
  [APP_SERVICES_GROUP.VirtualServer.resources.IPv6.id]: '',
  [APP_SERVICES_GROUP.CoreInfrastructure.resources.FileShareStorage.id]: 'GB',
  [APP_SERVICES_GROUP.Backup.resources.Storage.id]: 'TB',
  [APP_SERVICES_GROUP.RDS.resources.LightUsers.id]: '',
  [APP_SERVICES_GROUP.RDS.resources.OfficeUsers.id]: '',
  [APP_SERVICES_GROUP.RDS.resources.PowerUsers.id]: '',
  // [APP_SERVICES_GROUP.RDS.resources.TwoFALicense.id]: '',
  [APP_SERVICES_GROUP.RDS.resources.ProfileStorage.id]: 'GB',
  [APP_SERVICES_GROUP.Monitoring.resources.MonitoringService.id]: '',
  [APP_SERVICES_GROUP.DNS.resources.Domain.id]: '',
};
