import * as React from 'react';
import * as R from 'ramda';
import cn from 'classnames';
import { Col, Tooltip, Row, SliderBold, Switch } from 'elements';
import { useTranslation } from 'react-i18next';
import { IUser } from 'core-infrastructure';
import { remCalc } from 'utils';
import { useTask } from 'hooks';
import { IUserAvatar } from 'rds';
import UserTextAvatar from './UserTextAvatar';

type IValue = {
  type: number;
  is2faEnabled: boolean;
  twoFaBypass: boolean;
};

type IField = 'is2faEnabled' | 'twoFaBypass' | 'type' | 'details';

type IChangeValue = Partial<Record<IField, boolean | number | IUserAvatar>>;

const UserRow = (
  props: IUser & {
    onChange: (value: IChangeValue) => void;
    value?: IValue;
    coreDomain: string;
  }
) => {
  const { t } = useTranslation();
  const task = useTask(props.task);

  const onChange = React.useCallback(
    (val: IChangeValue) => {
      props.onChange({
        ...val,
        details: R.pick(
          ['firstName', 'lastName', 'username', 'coreDomain'],
          props
        ),
      });
    },
    [props.onChange]
  );

  return (
    <Row
      className={cn('fs-12 pb-5 pt-5 light-bordered-bottom', {
        disabled: task.isTaskActive,
      })}
      alignItems="center"
    >
      <Col xs={4} className={cn({ disabled: props.value?.type === 0 })}>
        <UserTextAvatar {...props} />
      </Col>
      <Col xs={1}>
        {props.email ? (
          <Switch
            checked={props.value?.is2faEnabled}
            disabled={props.value?.type === 0}
            onCheck={(is2faEnabled) => {
              const val: IChangeValue = { is2faEnabled };
              if (!is2faEnabled) {
                val.twoFaBypass = false;
              }
              onChange(val);
            }}
          />
        ) : (
          <div className="flex">
            <Tooltip
              title={t(
                'services.rds.users.dialog.performance.tooltips.enterEmail'
              )}
              placement="top"
              arrow
            >
              <div>
                <Switch
                  className={cn({ disabled: !props.email })}
                  checked={!!props.value?.is2faEnabled}
                  onCheck={(is2faEnabled) => {
                    const val: IChangeValue = { is2faEnabled };
                    if (!is2faEnabled) {
                      val.twoFaBypass = false;
                    }
                    onChange(val);
                  }}
                />
              </div>
            </Tooltip>
          </div>
        )}
      </Col>
      <Col xs={1}>
        <Switch
          checked={props.value?.twoFaBypass}
          disabled={
            !props.email ||
            props.value?.type === 0 ||
            !props.value?.is2faEnabled
          }
          onCheck={(twoFaBypass) => onChange({ twoFaBypass })}
        />
      </Col>
      <Col xs={6}>
        <div
          style={{
            maxWidth: remCalc(300),
            width: '100%',
            margin: 'auto',
            display: 'block',
          }}
        >
          <SliderBold
            value={props.value?.type || 0}
            onChange={(ev: any, type: any) => onChange({ type })}
            min={0}
            marks
            max={3}
            step={1}
          />
        </div>
      </Col>
    </Row>
  );
};

export default UserRow;
