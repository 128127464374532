import React from 'react';

type Props = React.PropsWithChildren<{ delay: number }>;

const WithDelayedRender = (props: Props) => {
  const [isRendered, handleState] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => handleState(true), props.delay);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return isRendered ? <>{props.children}</> : <div />;
};

export default WithDelayedRender;
