import * as React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAccount } from 'hooks';
import { Loader } from 'elements';
import { useAuth } from './AuthProvider';
import AppLayout from '../AppLayout';
import { withSocketEvents } from 'hocs/withSocketEvents';

const AuthView = observer((props: React.PropsWithChildren) => {
  const [account] = useAccount();
  const auth = useAuth();
  const location = useLocation();

  React.useEffect(() => {
    if (auth.isAuthorized) {
      const root = document.querySelector('#root') || { classList: [] };

      // @ts-ignore
      root.classList = ['root-justify'];
    }
  }, [auth.isAuthorized]);

  if (auth.isAuthorized && !account) {
    return <Loader />;
  }

  if (!auth.isAuthorized && window.location.pathname !== '/login') {
    return <Navigate to="/login?step=1" state={{ from: location }} replace />;
  }

  return <AppLayout>{props.children}</AppLayout>;
});

export default withSocketEvents(AuthView);
