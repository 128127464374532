import { GET, POST, DELETE, PATCH, buildListParams } from 'utils';
import { ISnapshot, ICreateSnapshotPayload } from 'snapshots';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';

export const get = (params: any): IAxiosPromise<ISnapshot[]> =>
  GET('/snapshots', buildListParams(params, ['orderType', 'orderBy']));

export const create = (
  payload: ICreateSnapshotPayload
): AxiosPromise<ISnapshot> => POST('/snapshots', {}, payload);

export const getById = (id: number): AxiosPromise<ISnapshot> =>
  GET(`/snapshots/${id}`);

export const cancelAutoremoval = (id: number): AxiosPromise<ISnapshot> =>
  PATCH(`/snapshots/${id}`, {}, { removalOn: null });

export const remove = (id: number): AxiosPromise<Task> =>
  DELETE(`/snapshots/${id}`);

export const revert = (id: number): AxiosPromise<ISnapshot> =>
  POST(`/snapshots/${id}/revert`);
