import { GET, PUT } from 'utils';
import { AxiosPromise } from 'axios';

export const getTenantQuotas = (
  tenantId: number
): AxiosPromise<IQuotaTypes.TenantResource> =>
  GET(`/tenants/${tenantId}/vm-resources`);

export const updateTenantQuotas = (
  tenantId: number,
  payload: IQuotaTypes.IQuotaPayload
): AxiosPromise<IQuotaTypes.TenantResource> =>
  PUT(`/tenants/${tenantId}/vm-resources/quota`, {}, { quota: payload });

export const getPartnerQuotas = (
  partnerId: number
): AxiosPromise<IQuotaTypes.PartnerResource> =>
  GET(`/partners/${partnerId}/vm-resources`);

export const updatePartnerQuotas = (
  tenantId: number,
  payload: IQuotaTypes.IQuotaPayload
): AxiosPromise<IQuotaTypes.TenantResource> =>
  PUT(`/partners/${tenantId}/vm-resources/quota`, {}, { quota: payload });
