import React, { useCallback } from 'react';
import BasePricingView from './BasePricingViewWrapper';
import { useQuery, useUserHash } from 'hooks';
import { toJS } from 'mobx';
import { showSystemMessage, confirm } from 'utils';
import { AnyShape } from 'global-shapes';
import { withPermissions, InjectedPermissionsProps } from 'hocs';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { parseBulkUpdatePayload, groupPrices } from './helper';
import { IBulkUpdatePayload } from './types';

const OBSERVERS = {
  defaultTenantPricing: StateHandlers.defaultTenantPricing,
  currentTenant: StateHandlers.currentTenant,
  currentTenantPricing: StateHandlers.currentTenantPricing,
  mainPricing: StateHandlers.mainPricing,
};

type IProps = InjectedPermissionsProps;

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    defaultTenantPricing,
    currentTenant,
    currentTenantPricing,
    mainPricing,
    permissions,
  } = props;
  const { t } = useTranslation();
  const { query } = useQuery();
  const [, navigate] = useUserHash();

  const currentTenantPricingId = query.tenant;

  const dataReceived =
    currentTenant.dataReceived &&
    currentTenantPricing.dataReceived &&
    mainPricing.dataReceived;

  const disabled =
    defaultTenantPricing.isRequesting || currentTenantPricing.isRequesting;

  const fetchCurrentTenantData = useCallback(() => {
    return currentTenant.get({
      id: currentTenantPricingId,
    });
  }, [currentTenantPricingId]);

  const fetchPartnerDefaultPricing = useCallback(() => {
    return mainPricing.get();
  }, []);

  const fetchCurrentTenantDefaultPricing = useCallback(() => {
    return defaultTenantPricing.get();
  }, []);

  const fetchCurrentTenantPricing = useCallback(() => {
    return currentTenantPricing.get({
      id: currentTenantPricingId,
    });
  }, [currentTenantPricingId]);

  const onUpdatePricing = useCallback(
    (data: AnyShape) => {
      return currentTenantPricing
        .update(data.id, data)
        .then(fetchCurrentTenantPricing);
    },
    [currentTenantPricingId, currentTenantPricing.data]
  );

  const onResetPrice = useCallback(async () => {
    await mainPricing
      .executeRequest('resetTenantPrices')(currentTenantPricingId)
      .catch((er) => {
        showSystemMessage(er.message, 'error');
      });
    showSystemMessage('settings.page.pricing.notify.reset.success', 'success');
    await fetchCurrentTenantPricing();
    await fetchCurrentTenantData();
    return fetchCurrentTenantDefaultPricing();
  }, [currentTenantPricingId]);

  const confirmResetPrice = React.useCallback(() => {
    confirm({
      title: 'settings.page.pricing.confirm.reset.title',
      content: 'settings.page.pricing.confirm.reset.content',
      onSuccess: onResetPrice,
      successLabel: 'common.reset',
      onCancel: () => undefined,
    });
  }, [currentTenantPricingId]);

  React.useEffect(() => {
    fetchCurrentTenantData();
    fetchCurrentTenantPricing();
    fetchPartnerDefaultPricing();
    fetchCurrentTenantDefaultPricing();
  }, []);

  const createdPrices = currentTenantPricing.data;
  const createdPricesJS = toJS(createdPrices);
  const defaultPrices = groupPrices(currentTenantPricing.data);

  const onBulkUpdate = useCallback(
    async (data: IBulkUpdatePayload) => {
      await currentTenantPricing.executeRequest('bulkUpdate')(
        parseBulkUpdatePayload(data, createdPricesJS)
      );
      showSystemMessage(
        'settings.page.pricing.bulk.notify.update.success',
        'success'
      );
      return await fetchCurrentTenantPricing();
    },
    [currentTenantPricingId, createdPricesJS]
  );

  const goToTenant = React.useCallback(() => {
    const tenant = currentTenant.data;

    navigate(`/${tenant.shortName}/services/all`, {});
    return StateHandlers.fetchAllAccountData({
      tsn: tenant.shortName,
      shouldApplyTheme: true,
    });
  }, [currentTenant.data]);

  return (
    <BasePricingView
      user={currentTenant.data}
      subtitle={t('settings.page.pricing.manageTenant.subtitle')}
      data={createdPrices}
      defaultPricing={defaultPrices}
      dataReceived={dataReceived}
      isEditable={!!permissions.canManage}
      onResetPrice={confirmResetPrice}
      isOnUserPricing
      onUpdatePricing={onUpdatePricing}
      parentPricing={mainPricing.data}
      onSetPrice={onUpdatePricing}
      disabled={disabled}
      goToUser={goToTenant}
      // @ts-ignore
      onBulkUpdate={onBulkUpdate}
      showDedicatedServerRate
    />
  );
});

const EditCurrentTenantPricing = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

const TenantPricing = withPermissions(EditCurrentTenantPricing, 'PRICING');

export default TenantPricing;
