import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ResetPasswordValues } from 'auth-shapes';
import { useQuery } from 'hooks';
import { Input, Button, Tooltip, MuiIcons } from 'elements';
import { authService } from 'services';
import {  insertXCustomLang } from 'utils';
import { REGEX, ALL_TEST_IDS } from 'enums';
import logoSrc from 'assets/images/login-logo.svg';
import { AnyShape } from 'global-shapes';
import { CreatePassPage, CreatePassPaper, CreatePassInfoLabel, CreatePassLogo } from './Styled';

const validationSchema = Yup.object({
  password: Yup.string()
    .trim()
    .required('Password is required')
    .matches(
      REGEX.password,
      'Please use at least one lower and one upper case letter, one number and one special character.'
    ),
  confirmPassword: Yup.string()
    .trim()
    .when('password', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm password is required'),
    }),
});

const initialValues: ResetPasswordValues = {
  password: '',
  confirmPassword: '',
};

const passRulesContent = (
  <div>
    <div>Password format:</div>
    <br />
    <div>- at least 8 characters</div>
    <div>- at least 1 capital letter</div>
    <div>- at least 1 small letter</div>
    <div>- at least 1 special character</div>
    <div>- at least 1 number</div>
  </div>
);

const CreatePassword = () => {
  const { query, changeQuery } = useQuery<any>();
  const { t } = useTranslation();
  const {
    values,
    errors,
    handleChange,
    touched,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (val: ResetPasswordValues) =>
      authService
        .verifyAccountPassword(
          val.password,
          query.token,
          insertXCustomLang(query.lang)
        )
        .then((res) => {
          localStorage.clear();
          sessionStorage.clear();
          const options: AnyShape = {
            url: '/2fa-setup',
            params: { twoFactorAuthToken: res.data.twoFactorAuthToken },
          };
          if (query.is2fa === 'false') {
            options.url = '/login';
            options.params = {};
          }
          changeQuery(options.params, options.url, true);
        }),
  });

  return (
    <CreatePassPage data-test-id={ALL_TEST_IDS.createPassword.wrapper}>
      <CreatePassPaper>
        <h2 className="text-center mb-50">{t('auth.password.title')}</h2>
        <form
          onSubmit={handleSubmit}
          data-test-id={ALL_TEST_IDS.createPassword.form}
        >
          <Input
            id="password"
            name="password"
            type="password"
            touched={touched}
            label="New password"
            labelIcon={
              <CreatePassInfoLabel>
                <Tooltip title={passRulesContent} placement="top" arrow>
                  <MuiIcons.HelpOutline />
                </Tooltip>
              </CreatePassInfoLabel>
            }
            placeholder="Your password"
            error={!!errors.password}
            helperText={errors.password}
            className="mb-25"
            onChange={handleChange}
            value={values.password}
            testId={ALL_TEST_IDS.createPassword.inputs}
          />
          <Input
            id="confirmPassword"
            name="confirmPassword"
            label={t('forms.confirmPassword')}
            placeholder="Your password"
            type="password"
            touched={touched}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            className="mb-25"
            onChange={handleChange}
            value={values.confirmPassword}
            testId={ALL_TEST_IDS.createPassword.inputs}
          />
          <Button
            type="submit"
            fullWidth
            size="large"
            className="mt-50"
            disabled={isSubmitting}
            testId={ALL_TEST_IDS.createPassword.submit}
          >
            {t('common.continue')}
          </Button>
        </form>
      </CreatePassPaper>
      <CreatePassLogo>
        <img src={logoSrc} alt="" />
      </CreatePassLogo>
    </CreatePassPage>
  );
};

export default CreatePassword;
