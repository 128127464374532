import { pick } from 'ramda';
import { PALETTE } from 'enums';
import { DEFAULT_THEME_COLORS } from './constants';

export interface IDefaultValues {
  primaryColor: string;
  secondaryColor: string;
  supportInfo: string;
  logo?: File | string;
}

const fieldsToPick = ['logo', 'primaryColor', 'secondaryColor', 'supportInfo'];
const defaultValues = (
  currentUser?: IPartnerTypes.Partner
): IDefaultValues => ({
  primaryColor: PALETTE.primary,
  secondaryColor: PALETTE.dark,
  supportInfo:
    currentUser?.lang === 'en'
      ? 'https://awesome.cloud/go/support-en'
      : 'https://awesome.cloud/go/support-de',
  logo: undefined,
});

export const getInitialProfileSettings = (
  user?: IPartnerTypes.Partner
): IDefaultValues => {
  const userValues = pick(fieldsToPick, user || {}) as IPartnerTypes.Partner;
  const dfValues = defaultValues(user);
  return {
    primaryColor: userValues?.primaryColor
      ? `#${userValues.primaryColor}`
      : dfValues.primaryColor,
    secondaryColor: userValues?.secondaryColor
      ? `#${userValues.secondaryColor}`
      : dfValues.secondaryColor,
    supportInfo: userValues?.supportInfo || dfValues.supportInfo,
    logo: userValues?.logo?.link || dfValues.logo || undefined,
  };
};

export const isDirtyTheme = (colors: { primary: string; appBar: string }) => {
  return (
    DEFAULT_THEME_COLORS.primary !== colors.primary ||
    DEFAULT_THEME_COLORS.appBar !== colors.appBar
  );
};
