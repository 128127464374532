import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogProps,
  Button,
  Input,
  Row,
  Col,
  DatePicker,
} from 'elements';
import {
  INITIAL_PAYMENT_VALUES,
  paymentSchema,
  MAX_COMMENT_LENGTH,
  MAX_AMOUNT_VALUE,
  MIN_AMOUNT_VALUE,
} from './constants';
import { validateCallback, validator, dayjs } from 'utils';
import { IInvoiceTypes } from './types';

type Props = DialogProps<IInvoiceTypes.PaymentState> & {
  isRequesting: boolean;
};

const AddPaymentDialog = (props: Props) => {
  const { isRequesting } = props;
  const { t } = useTranslation();
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    submitCount,
    errors,
    values,
    resetForm,
  } = useFormik({
    validateOnMount: false,
    validationSchema: paymentSchema,
    initialValues: INITIAL_PAYMENT_VALUES,
    onSubmit: (val) =>
      Promise.resolve(
        props.onSave &&
          props.onSave({ ...val, paymentDate: dayjs(val.paymentDate).utc() })
      ),
  });

  React.useEffect(() => {
    if (!props.open) resetForm();
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      title={t('invoices.current.dialog.addPayment.title')}
      handleSubmit={handleSubmit}
      actions={
        <>
          <Button
            color="default"
            variant="outlined"
            onClick={props.onClose}
            disabled={isRequesting}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isRequesting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <Input
        name="comment"
        className="mb-20"
        label={t('forms.comment')}
        onChange={handleChange}
        placeholder={t('forms.placeholders.comment')}
        helperTextOptions={{ max: MAX_COMMENT_LENGTH }}
        value={values.comment}
        error={!!submitCount && !!errors.comment}
        helperText={errors.comment}
      />
      <Row columnSpacing={2}>
        <Col xs={6}>
          <DatePicker
            label={t('forms.date')}
            onChange={(date) => setFieldValue('paymentDate', date)}
            value={values.paymentDate}
            error={!!submitCount && !!errors.paymentDate}
            helperText={errors.paymentDate as string}
          />
        </Col>
        <Col xs={6}>
          <Input
            name="amount"
            label={t('forms.amountCHF')}
            onChange={validateCallback({
              restrict: validator.numbersWithDefinedDecimals(2, false),
            })(handleChange)}
            value={values.amount}
            error={!!submitCount && !!errors.amount}
            helperText={errors.amount}
            helperTextOptions={{
              min: MIN_AMOUNT_VALUE,
              max: MAX_AMOUNT_VALUE,
            }}
          />
        </Col>
      </Row>
    </Dialog>
  );
};

export default AddPaymentDialog;
