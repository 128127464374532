import * as React from 'react';
import { usePermissions } from 'hooks';
import { observer } from 'mobx-react-lite';
import InvoiceList from './InvoiceList';

const Invoices = observer((props: any) => {
  const { permissions, isServerBase, ...rest } = usePermissions('INVOICES');

  return (
    <InvoiceList
      {...props}
      {...rest}
      isServerBase={isServerBase}
      permissions={permissions}
    />
  );
});

export { Invoices };
