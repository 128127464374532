import * as Yup from 'yup';
import { IPerformanceProfile } from 'rds';
import { IState, IDialogQuery } from './types';

export const DEFAULT_VALUES = {
  0: 0,
  1: 0,
  2: 0,
  3: 0,
};

export const valueMap: Record<string, number> = {
  DISABLED: 0,
  LIGHT: 1,
  OFFICE: 2,
  POWER: 3,
};

export const valueMapReversed: Record<number, string> = {
  0: 'LIGHT',
  1: 'LIGHT',
  2: 'OFFICE',
  3: 'POWER',
};

export const typeStringsMap: Record<number, IPerformanceProfile | 'DISABLED'> =
  {
    0: 'DISABLED',
    1: 'LIGHT',
    2: 'OFFICE',
    3: 'POWER',
  };

export const actionsMap: Record<number, string> = {
  0: 'DELETE',
  1: 'REPLACE',
  2: 'REPLACE',
  3: 'REPLACE',
};

export const INITIAL_STATE: IState = {
  initials: {},
  maintenanceDialog: false,
  validatedValues: undefined,
};

export const INITIAL_RDS_USERS_DIALOG_QUERY: IDialogQuery = {
  page: 1,
  perPage: 15,
  orderType: 'asc',
  orderBy: 'firstName',
  q: undefined,
};

export function getProfileDisktorageValidationSchema(initialStorage: number) {
  return Yup.object({
    storage: Yup.string()
      .trim()
      .required('forms.required')
      .test(
        'storage',
        'forms.invalid.onlyIncrease',
        (val) => initialStorage <= +(val || 0)
      ),
  });
}
