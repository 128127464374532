import * as React from 'react';
import * as R from 'ramda';
import { Backup } from 'components/Backup';
import { useQuery, usePermissions } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { Paper } from 'elements';

const OBSERVERS = {
  currentVm: StateHandlers.vmDetails,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { currentVm } = props;
  const { query } = useQuery();
  const { isProvider } = usePermissions();
  const hasBillingCycle = !!currentVm.data?.billingCycleId;
  const isUsageEnabled = !!currentVm.data?.isUsageEnabled;
  const hasBilling = isProvider || hasBillingCycle || isUsageEnabled;

  const totalDiskSize = R.sum([
    currentVm.data.osDiskSizeGb,
    ...currentVm.data.disks.map((d) => d.sizeGb),
  ]);

  return (
    <Paper className="p-30">
      <Backup
        entityId={query.id}
        entityName="Vm"
        instance={currentVm.data}
        vmDiskSize={totalDiskSize}
        isUsageEnabled={hasBilling}
      />
    </Paper>
  );
});

const BackupComponent = () => <View {...OBSERVERS} />;

export default BackupComponent;
