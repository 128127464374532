import { ILanguage } from 'global-shapes';
import { AUTHORIZED_ROUTES } from 'enums/routes';
import { GLOBAL_TRANSLATIONS } from 'enums/translations';
import i18n from '../i18n';

export const getCurrentLang = (): ILanguage => {
  const browserLang = ['de'].includes(navigator.language) ? 'de' : 'en';
  return (localStorage.getItem('lang') as ILanguage) || browserLang;
};

export const findShortNamesInUrl = (
  location: Location | typeof window.location | { pathname: string }
): { psn?: string; tsn?: string } => {
  let psn = '';
  let tsn = '';

  const names = location.pathname.split('/').slice(1);

  const partner = names[0];
  const tenant = names[1];

  if (names.length > 1 && AUTHORIZED_ROUTES.includes(names[1])) {
    psn = partner;
  }

  if (names.length > 2 && AUTHORIZED_ROUTES.includes(names[2])) {
    psn = partner;
    tsn = tenant;
  }

  return { psn, tsn };
};

export const changeLang = (lang: ILanguage) => {
  i18n.changeLanguage(lang);
};

export const updatePageTitle = (lang: ILanguage) =>
  (document.title = GLOBAL_TRANSLATIONS.getResource(lang)['app.title']);
