import { MetaData } from 'meta-data';
import countryList from 'react-select-country-list';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export const getCountries = (): ValueType<string>[] => {
  return [...countryList().getData(), { value: 'XK', label: 'Kosovo' }].sort(
    (a, b) => a.label.localeCompare(b.label)
  );
};

export const meta = new BaseMobxInstanceDecorator<MetaData, false>({
  instanceName: 'meta',
  requests: {},
  initialState: {} as MetaData,
});

export type IMetaHandler = BaseDecorator<MetaData, false>;

export const countries = new BaseMobxInstanceDecorator<ValueType<string>, true>(
  {
    instanceName: 'countries',
    requests: {
      get: async () => {
        const res = getCountries();
        return { data: res };
      },
    },
    initialState: [],
  }
);
