import * as React from 'react';
import cn from 'classnames';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import {
  List,
  ListItemButton,
  PrimaryTextSpan,
  Popover,
  MuiIcons,
} from 'elements';
import * as CONS from './constants';
import { CreateConnectivityFixedValue } from './Styled';

type IProps = {
  value: string;
  onChange(val: string): void;
};

const InternalNetworkSubnetDropdown = (props: IProps) => {
  const { onChange, value } = props;
  return (
    <CreateConnectivityFixedValue position="end" className={cn('pr-10')}>
      <PopupState variant="popover">
        {(popupState) => (
          <>
            <div
              className="flex align-center pointer"
              {...bindTrigger(popupState)}
              style={{ width: 60 }}
            >
              <span>.0/{value}</span>
              {popupState.isOpen ? (
                <MuiIcons.ArrowDropUp className="fs-16" />
              ) : (
                <MuiIcons.ArrowDropDown className="fs-16" />
              )}
            </div>

            <Popover
              {...bindMenu(popupState)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              <List>
                {CONS.InternalNetworkSubnets.map((subnet) => {
                  const isActive = subnet === value;

                  return (
                    <ListItemButton
                      key={subnet}
                      data-test-id={`con-int-net-subnet-menu-item`}
                      onClick={() => {
                        onChange(subnet);
                        popupState.close();
                      }}
                    >
                      {isActive ? (
                        <PrimaryTextSpan>{subnet}</PrimaryTextSpan>
                      ) : (
                        subnet
                      )}
                    </ListItemButton>
                  );
                })}
              </List>
            </Popover>
          </>
        )}
      </PopupState>
    </CreateConnectivityFixedValue>
  );
};

export default InternalNetworkSubnetDropdown;
