import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'elements';
import { CpuIcon, RamIcon, DiskSizeIcon } from './QuotasInfo/Icons';

const DedicatedServerSystemInfo = (
  props: IDedicatedServer.TenantosServer & { className?: string }
) => {
  const { t } = useTranslation();

  return (
    <Row className={props.className}>
      <Col xs={5}>
        <Row columnSpacing={2} alignItems="center">
          <Col>
            <CpuIcon size={36} scale={1} />
          </Col>
          <Col className="fs-14">
            <div>{props.cpuModel}</div>
            <div>
              {props.cpuCount}x{props.cpuCores}{' '}
              <strong>({props.cpuMHz} MHz)</strong>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={4}>
        <Row columnSpacing={2} alignItems="center">
          <Col>
            <RamIcon size={36} scale={1} />
          </Col>
          <Col className="fs-14">
            <div className="steel fs-12">
              {t(`services.card.content.ramMb`)}
            </div>
            <div className="bold">{props.ramMb / 1024} GB</div>
          </Col>
        </Row>
      </Col>
      <Col xs={3}>
        <Row columnSpacing={2} alignItems="center">
          <Col>
            <DiskSizeIcon size={36} scale={1} />
          </Col>
          <Col className="fs-14">
            <div className="steel fs-12">
              {t(`services.card.content.totalDiskSizeGb`)}
            </div>
            <div className="bold">{props.disks}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DedicatedServerSystemInfo;
