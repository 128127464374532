import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogProps, Select, Input } from 'elements';
import * as StateHandlers from 'states';
import { useStateHandler, useAccount } from 'hooks';
import * as HELPERS from './helpers';
import * as CONSTANTS from './constants';

type Props = DialogProps<IServiceGroups.GroupDetailsFormValues> & {
  initialValues?: IServiceGroups.Group;
};

const ForderDetailsDialog = (props: Props) => {
  const { open, initialValues, onSave, onClose } = props;
  const { t } = useTranslation();
  const [account] = useAccount();
  const folders = useStateHandler(StateHandlers.folders);

  const isNew = !initialValues;

  const {
    handleSubmit,
    values,
    setFieldValue,
    resetForm,
    setValues,
    submitCount,
    handleChange,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: CONSTANTS.InitialFolderState,
    validationSchema: CONSTANTS.folderValidationSchema,
    validateOnMount: false,
    onSubmit: async (val) => {
      try {
        if (isNew) {
          await folders.create({ tenantId: account?.tenant?.id, ...val });
        } else {
          await folders.update(
            // @ts-ignore
            { tenantId: account?.tenant?.id, groupId: initialValues?.id },
            { ...val }
          );
        }
        onClose();
        return onSave && onSave(val);
      } catch (error) {
        return;
      }
    },
  });

  React.useEffect(() => {
    if (open && initialValues)
      setValues(HELPERS.parseFolderToForm(initialValues));
    if (!open) {
      resetForm();
    }
  }, [JSON.stringify(initialValues), open]);

  return (
    <Dialog
      open={open}
      title={t(`folders.dialog.details.${isNew ? 'create' : 'update'}.title`)}
      fullWidth
      maxWidth="sm"
      handleSubmit={handleSubmit}
      keepMounted={false}
      onClose={onClose}
      actions={
        <>
          <Button variant="outlined" color="default" onClick={props.onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isSubmitting || folders.isRequesting}>
            {t(isNew ? 'common.create' : 'common.update')}
          </Button>
        </>
      }
    >
      <div className="steel mb-30">
        {t(`folders.dialog.details.${isNew ? 'create' : 'update'}.description`)}
      </div>

      {isNew && (
        <div className="mb-25">
          <Select
            name="type"
            options={CONSTANTS.FolderTypeOptions}
            isDisabled={!isNew}
            label={t(`forms.type`)}
            placeholder="forms.placeholders.type"
            value={values.type}
            onChange={(val) => {
              setFieldValue('type', val);
            }}
            error={!!submitCount && !!errors.type}
            helperText={errors.type}
          />
        </div>
      )}

      <Input
        onChange={handleChange}
        value={values.name}
        name="name"
        label="forms.folderName"
        placeholder="forms.placeholders.folderName"
        error={!!submitCount && !!errors.name}
        helperText={errors.name}
        helperTextOptions={{
          max: 30,
        }}
      />
    </Dialog>
  );
};

export default ForderDetailsDialog;
