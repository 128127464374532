import React from 'react';
import {
  Input,
  Button,
  Select,
  Dialog,
  DialogProps,
  InputPhone,
} from 'elements';
import { validator } from 'utils';
import { useFormik } from 'formik';
import { IEmployeePayload } from 'employees';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { EMPLOYEE_TYPES_OPTIONS } from './constants';

type IProps = DialogProps<IEmployeePayload> & {
  initialValues?: IEmployeePayload;
};

const INITIALS: IEmployeePayload = {
  name: '',
  email: '',
  phone: '',
  comment: '',
  type: EMPLOYEE_TYPES_OPTIONS[0],
};

const testId = 'employees-info';

const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .max(30, 'forms.invalid.max')
    .required('forms.required'),
  type: Yup.object().required('forms.required'),
  comment: Yup.string().max(1000, 'forms.invalid.max'),
  email: Yup.string().email('forms.emailInvalid'),
  phone: Yup.string()
    .trim()
    .test('phone', 'forms.invalid', (val) => {
      if (!val) return true;
      return validator.isPhone(val);
    }),
});

const EmployeeDetailsDialog = (props: IProps) => {
  const { t } = useTranslation();

  const isNew = !props.initialValues;

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    submitCount,
    isSubmitting,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: INITIALS,
    validateOnMount: false,
    validationSchema,
    onSubmit: props.onSave as any,
  });

  const isRequesting = isSubmitting;

  React.useEffect(() => {
    if (props.open && props.initialValues) {
      setValues(props.initialValues);
    }

    if (!props.open) {
      resetForm();
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={t(
        `settings.page.partnerInfo.section.employees.dialog.${
          isNew ? 'add' : 'edit'
        }.title`
      )}
      handleSubmit={handleSubmit}
      data-test-id="employee-details-form"
      fullWidth
      actions={
        <>
          <Button
            color="default"
            variant="outlined"
            onClick={props.onClose}
            disabled={isSubmitting}
          >
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={isRequesting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <Input
        label={t('forms.name')}
        value={values.name}
        onChange={handleChange}
        placeholder={t('forms.placeholders.name')}
        name="name"
        className="mb-25"
        touched={touched.name}
        error={!!submitCount && !!errors.name}
        helperText={errors.name}
        helperTextOptions={{ max: 30 }}
      />
      <Input
        label={t('forms.email')}
        value={values.email}
        onChange={handleChange}
        placeholder={t('forms.placeholders.email')}
        name="email"
        className="mb-25"
        touched={touched.email}
        error={!!submitCount && !!errors.email}
        helperText={errors.email}
      />
      <Select
        label={t('forms.type') as string}
        value={values.type}
        name="type"
        onChange={(val: any) => setFieldValue('type', val)}
        options={EMPLOYEE_TYPES_OPTIONS}
        error={!!submitCount && !!errors.type}
        helperText={errors.type}
        testId={testId}
        className="mb-25"
      />
      <InputPhone
        label={t('forms.phone')}
        value={values.phone}
        name="phone"
        className="mb-25"
        onChange={(val) => {
          setFieldValue('phone', val);
        }}
        helperText={errors.phone}
        error={!!submitCount && !!errors.phone}
        placeholder={t('forms.placeholders.phone')}
        touched={touched.phone}
        testId={testId}
      />
      <Input
        multiline
        minRows={4}
        label={t('forms.comment')}
        placeholder={t('forms.placeholders.comment')}
        value={values.comment}
        onChange={handleChange}
        name="comment"
        className="mb-25"
        touched={touched.comment}
        error={!!submitCount && !!errors.comment}
        helperText={errors.comment}
        helperTextOptions={{ max: 1000 }}
      />
    </Dialog>
  );
};

export default EmployeeDetailsDialog;
