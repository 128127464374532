import { useCallback } from 'react';
import { useNavigate, useLocation, Location } from 'react-router-dom';
import { compact, findShortNamesInUrl } from 'utils';
import { useAccount } from './useAccount';
import { ICurrentUser } from 'auth-shapes';
import { AnyShape } from 'global-shapes';
import { stringify } from 'query-string';

type ChangeCallback = (url: string, params?: AnyShape) => void;
type HashResult = [string, ChangeCallback, boolean, string?, string?];

const defineShortName = (location: Location, account?: ICurrentUser) => {
  const { psn, tsn } = findShortNamesInUrl(location);

  let partnerName = psn;
  let tenantName = tsn;

  if (account?.tenant) {
    tenantName = account?.tenant?.shortName || '';
    partnerName = account?.partner?.shortName || '';
  }

  if (account?.partner) {
    partnerName = account?.partner?.shortName || '';
  }

  return { partnerName, tenantName };
};

export function useUserHash(): HashResult {
  const [account, accountHasData] = useAccount();
  const navigate = useNavigate();
  const location = useLocation();

  const { tenantName, partnerName } = defineShortName(location, account);

  const hash = compact([partnerName, tenantName]).join('/');
  const hasHash = accountHasData ? account?.accessLevel !== 'PROVIDER' : false;

  const changeUrl = useCallback<ChangeCallback>(
    (url, params) => {
      const search = params
        ? `?${stringify(params, {
            arrayFormat: 'bracket',
          })}`
        : location.search;
      const fullHash = hash ? `/${hash}` : '';
      const newUrl = `${fullHash}${url}${search}`;

      return navigate(newUrl);
    },
    [JSON.stringify(location), hash, JSON.stringify(account)]
  );

  return [hash, changeUrl, hasHash, partnerName, tenantName];
}
