import * as R from 'ramda';
import { ICommonListParams } from 'global-shapes';
import { PermissionsMap } from 'auth-shapes';
import { utcDayjs } from 'utils/dayjsExtended';
import { upgradeByteMeasure } from 'utils/parsers';
import { IBIParams } from 'billable-items';

function buildDefaultListParams<P = ICommonListParams>(
  orderBy: string,
  other = {}
): P | any {
  return {
    page: 1,
    perPage: 20,
    orderBy,
    orderType: 'asc',
    ...other,
  };
}

export const defaultNavQuery = buildDefaultListParams('username');

export const defaultRdsNavQuery: ICommonListParams = {
  page: 1,
  perPage: 10,
};

export const defaultBackupNavQuery = buildDefaultListParams('creationDate', {
  orderType: 'desc',
});

export const defaultDnsZonesQuery = buildDefaultListParams('domainName');
export const defaultVmsQueryFilters = {
  badgeId: [],
  osId: [],
  status: [],
  billingType: [],
  activeService: [],
  cpuMin: 1,
  cpuMax: 64,
  ramMin: upgradeByteMeasure(1),
  ramMax: upgradeByteMeasure(512),
  totalDiskSizeMin: 1,
  totalDiskSizeMax: 10000,
  type: undefined,
};

export const defaultVmsQuery = {
  ...buildDefaultListParams('name'),
  ...defaultVmsQueryFilters,
};

export const buildRdsNavItems = (config: { permissions: PermissionsMap }) => {
  const hiddenTabs = [];

  if (!config.permissions.MONITORING?.canView) {
    hiddenTabs.push('monitor');
  }

  if (!config.permissions.BACKUP?.canView) {
    hiddenTabs.push('backup');
  }

  const items = ['accounts', 'apps', 'backup', 'monitor', 'patching', 'reboot'];

  return R.without(hiddenTabs, items);
};

export const DNS_DETAILS_NAV_ITEMS = ['domains', 'some', 'other'];

export const DEFAULT_FILE_SHARE_QUERY = {
  ...defaultNavQuery,
  orderBy: 'name',
};

export const CORE_DETAILS_NAV_ITEMS_PARAMS = {
  admins: defaultNavQuery,
  accounts: defaultNavQuery,
  backup: defaultBackupNavQuery,
  shares: DEFAULT_FILE_SHARE_QUERY,
};

export const APP_STORE_DEFAULT_PARAMS = {
  ...defaultRdsNavQuery,
  orderBy: 'name',
  orderType: 'asc',
  q: '',
  isInstalled: 'none',
  licensePricingType: 'all',
};

export const RDS_ACCOUNTS_DEFAULT_PARAMS = {
  ...defaultRdsNavQuery,
  orderType: 'asc',
  orderBy: 'firstName',
  q: undefined,
};

export const RDS_DETAILS_NAV_ITEMS_PARAMS = {
  accounts: RDS_ACCOUNTS_DEFAULT_PARAMS,
  backup: defaultBackupNavQuery,
  apps: APP_STORE_DEFAULT_PARAMS,
};

export const DNS_DETAILS_NAV_ITEMS_PARAMS = {
  domains: { ...defaultNavQuery, orderType: 'asc' },
  some: defaultNavQuery,
  other: { ...defaultNavQuery, orderBy: 'package.name', orderType: 'desc' },
};

export const VM_DISKS_DEFAULT_PARAMS = { ...defaultNavQuery, orderBy: 'name' };
export const NETWORKS_DEFAULT_PARAMS = {
  page: 1,
  perPage: 20,
  orderType: 'asc',
  orderBy: 'name',
  q: undefined,
  type: ['DIRECT', 'NAT_ROUTED', 'ISOLATED'],
};

export const VM_DETAILS_NAV_ITEMS_PARAMS = {
  disks: VM_DISKS_DEFAULT_PARAMS,
  backup: defaultBackupNavQuery,
};

export const DEFAULT_USERS_PARAMS = {
  page: 1,
  perPage: 20,
  orderBy: 'firstName',
  orderType: 'asc',
};

export const DEFAULT_INVOICES_PARAMS = {
  page: 1,
  perPage: 100,
  orderBy: 'invoiceDate',
  orderType: 'desc',
  q: '',
  status: [
    'PAID',
    'UNPAID',
    'PARTIALLY_PAID',
    'CANCELED',
    'OVERDUE',
    'OVERPAID',
  ],
  inittab: 'base',
  initexp: undefined,
};

export const DEFAULT_PARTNERS_QUERY = {
  page: 1,
  perPage: 20,
  orderBy: 'name',
  orderType: 'asc',
  status: 'ALL',
};

export const DEFAULT_CUSTOMERS_QUERY = {
  page: 1,
  perPage: 20,
  orderType: 'asc',
  orderBy: 'name',
  enabledBilling: true,
};

export const DEFAULT_BILLABLE_ITEMS_PARAMS: IBIParams = {
  page: 1,
  perPage: 50,
  orderBy: 'date',
  orderType: 'desc',
  partnerId: undefined,
  userId: undefined,
  status: [],
  type: [],
  q: '',
  include: undefined,
  selected: undefined,
  dateFrom: utcDayjs().add(-30, 'day').startOf('day').toISOString(),
  dateTo: utcDayjs().add(2, 'year').endOf('day').toISOString(),
  recurringInterval: undefined,
};
