import {
  IBillableItemFormValues,
  IBillableItemStatus,
  IBillableItemType,
  IBillableItemUnit,
  IRecurringInterval,
} from 'billable-items';
import * as Yup from 'yup';
import { IChipStatus } from 'elements';
import { ValueType } from 'global-shapes';
import { important, remCalc, dayjs } from 'utils';

export type IBIAccessLevel = 'provider' | 'partner' | 'tenant';

export const BILLING_DATE_FORMAT = 'YYYY-MM-DD';

export const ALL_STYLES = {
  accordionRoot: {
    boxShadow: important('0 1px 1px 0 rgba(23, 29, 41, 0.16)'),
    borderRadius: remCalc(5),
    '&:before': {
      display: 'none',
    },
    paddingTop: remCalc(3),
    paddingBottom: remCalc(3),
  },
};

export const statusColorMap: Record<IBillableItemStatus, IChipStatus> = {
  NOT_INVOICED: 'info',
  INVOICED: 'success',
};

export const DEFAULT_USERS_QUERY = {
  page: 1,
  perPage: 1000,
  orderBy: 'firstName',
  orderType: 'asc',
};

export const DEFAULT_PARTNERS_QUERY = {
  ...DEFAULT_USERS_QUERY,
  orderBy: 'shortName',
};

export const unitOptions: ValueType<IBillableItemUnit>[] = [
  {
    value: 'HOURS',
    label: 'billableItems.unit.HOURS',
  },
  {
    value: 'PCS',
    label: 'billableItems.unit.PCS',
  },
];

export const typeOptions: ValueType<IBillableItemType>[] = [
  {
    value: 'SUPPORT',
    label: 'billableItems.types.SUPPORT',
  },
  {
    value: 'GENERAL',
    label: 'billableItems.types.GENERAL',
  },
  {
    value: 'APP_SERVICE',
    label: 'billableItems.types.APP_SERVICE',
  },
];

export const statusFilterOptions: ValueType<IBillableItemStatus>[] = [
  {
    value: 'NOT_INVOICED',
    label: 'billableItems.status.NOT_INVOICED',
  },
  {
    value: 'INVOICED',
    label: 'billableItems.status.INVOICED',
  },
];

export const recurringIntervalOptions: ValueType<IRecurringInterval>[] = [
  {
    value: 'MONTH',
    label: 'billableItems.options.MONTH',
  },
  {
    value: 'QUARTER',
    label: 'billableItems.options.QUARTER',
  },
  {
    value: 'HALF_YEAR',
    label: 'billableItems.options.HALF_YEAR',
  },
  {
    value: 'YEAR',
    label: 'billableItems.options.YEAR',
  },
  {
    value: 'TWO_YEARS',
    label: 'billableItems.options.TWO_YEARS',
  },
];

export const recurringIntervalFullOptions: ValueType<IRecurringInterval>[] = [
  {
    value: 'NONE',
    label: 'billableItems.options.NONE',
  },
  ...recurringIntervalOptions,
];

export const BILLABLE_ITEM_INITIALS: IBillableItemFormValues = {
  date: dayjs(),
  description: '',
  notes: '',
  price: 0,
  qty: 1,
  sendUpfront: false,
  ticket: '',
  type: typeOptions[1],
  unit: unitOptions[1],
  partnerId: undefined,
  tenantId: undefined,
  userId: undefined,
  enableRecurring: false,
  repeatPermanently: true,
  recurringInterval: recurringIntervalOptions[0],
  recurringStart: dayjs(),
  recurringEnd: dayjs().add(1, 'years'),
};

const commonValidation = {
  description: Yup.string()
    .trim()
    .max(300, 'forms.invalid.max')
    .required('forms.required'),
  price: Yup.number()
    .required('forms.required')
    .test('price', 'forms.invalid', (val) => !isNaN(val || 0)),
  qty: Yup.number().min(0.01).required('forms.required'),
  userId: Yup.object().required('forms.required'),
  ticket: Yup.string().max(30, 'forms.invalid.max'),
  type: Yup.object().required('forms.required'),
  date: Yup.object().when('enableRecurring', {
    is: false,
    then: Yup.object().required('forms.required'),
  }),
  enableRecurring: Yup.boolean(),
  notes: Yup.string().max(300, 'forms.invalid.max'),
};

export const ProviderValidationSchema = Yup.object({
  ...commonValidation,
  partnerId: Yup.object().required('forms.required'),
});

export const PartnerValidationSchema = Yup.object({
  ...commonValidation,
  tenantId: Yup.object().required('forms.required'),
});
