import * as React from 'react';
import { Button } from 'elements';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { InjectedProps, withCoreActions } from 'hocs/withCoreActions';
import { SnapshotAlert } from '../Styled';

type IProps = InjectedProps;

const OBSERVERS = {
  rds: StateHandlers.rds,
  users: StateHandlers.rdsUsers,
  allUsers: StateHandlers.rdsAllUsers,
  core: StateHandlers.core,
  currentUser: StateHandlers.currentUser,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { currentSnapshot, isRequesting, handlers } = props;
  const { t } = useTranslation();

  const createdSnapshot = !currentSnapshot?.schedule
    ? currentSnapshot
    : undefined;

  const hasSnapshot = !!createdSnapshot;

  return hasSnapshot ? (
    <SnapshotAlert severity="info" className="mb-15 relative">
      <div className="flex align-center _text">
        <div>{t('services.rds.alert.snapshot.created.title')}</div>
        <Button
          size="small"
          className="ml-10 _button"
          color="default"
          variant="outlined"
          disabled={isRequesting}
          onClick={() => {
            handlers.removeSnapshot();
          }}
        >
          {t('services.content.action.removeSnapshot')}
        </Button>
      </div>
    </SnapshotAlert>
  ) : null;
});

const Users = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default withCoreActions(Users);
