import * as React from 'react';
import cn from 'classnames';
import { Tooltip } from './Tooltip';
import { styled } from './utilities';
import { MuiIcons } from './MuiIcons';
import { remCalc } from 'utils';
import { PALETTE } from 'enums';

export type IChipStatus =
  | 'error'
  | 'success'
  | 'info'
  | 'default'
  | 'warning'
  | 'systemInfo'
  | 'primary';

type Props = {
  className?: string;
  tooltip?: string;
  status: IChipStatus;
  children?: any;
};

const Content = styled('div')(({ theme }) => ({
  fontSize: remCalc(12),
  padding: remCalc(5, 10),
  lineHeight: remCalc(14),
  display: 'inline-block',
  color: PALETTE.white,
  borderRadius: remCalc(4),
  backgroundColor: PALETTE.success,

  '&._success': {
    backgroundColor: PALETTE.success,
  },

  '&._warning': {
    backgroundColor: PALETTE.warning,
  },

  '&._info': {
    backgroundColor: PALETTE.primaryLight,
  },

  '&._systemInfo': {
    backgroundColor: PALETTE.primaryLight,
  },

  '&._primary': {
    backgroundColor: PALETTE.primary,
  },

  '&._default': {
    backgroundColor: PALETTE.steel,
  },

  '&._error': {
    backgroundColor: PALETTE.errorSecondary,
  },
  '&._brand-primary': {
    borderColor: theme.palette.primary.main,
  },
}));

const OutlinedContent = styled('div')(({ theme }) => ({
  padding: '0.2em 0.4em',
  lineHeight: 1,
  display: 'inline-block',
  borderRadius: remCalc(4),
  backgroundColor: 'transparent',
  border: `1px solid ${PALETTE.primary}`,

  '&._success': {
    borderColor: PALETTE.success,
  },

  '&._warning': {
    borderColor: PALETTE.warning,
  },

  '&._info': {
    borderColor: PALETTE.primaryLight,
  },

  '&._systemInfo': {
    borderColor: PALETTE.primaryLight,
  },

  '&._primary': {
    borderColor: PALETTE.primary,
  },

  '&._default': {
    borderColor: PALETTE.steel,
  },

  '&._error': {
    borderColor: PALETTE.errorSecondary,
  },

  '&._brand-primary': {
    borderColor: theme.palette.primary.main,
  },
}));

const Chip = (props: Props) => {
  const { status, className, tooltip, ...rest } = props;
  if (tooltip) {
    return (
      <Tooltip title={tooltip} arrow placement="top">
        <Content
          {...rest}
          className={cn(className, { [`_${status}`]: status })}
        >
          {props.children}
        </Content>
      </Tooltip>
    );
  }
  return (
    <Content {...rest} className={cn(className, { [`_${status}`]: status })}>
      {props.children}
    </Content>
  );
};

const SChip = styled('div')(({ theme }) => ({
  border: `1px solid ${PALETTE.paleGrey}`,
  borderRadius: remCalc(20),
  margin: remCalc(0, 5, 0, 0),
  padding: remCalc(4, 20, 4, 10),
  lineHeight: 1,
  position: 'relative',
}));

const SCancelIcon = styled(MuiIcons.Clear)(({ theme }) => ({
  position: 'absolute',
  right: remCalc(5),
  top: 0,
  lineHeight: remCalc(18),
  height: remCalc(18),
  zIndex: 1,
  '&:hover': {
    color: PALETTE.error,
  },
}));

type IProps = React.PropsWithChildren<{
  chip: IElementsTypes.IInputChip;
  onClear: (chip: IElementsTypes.IInputChip) => void;
}>;

export const InputChip = (props: IProps) => {
  const { children, onClear, chip } = props;
  return (
    <SChip className={cn('flex align-center fs-11 steel')}>
      <span>{children}</span>
      <SCancelIcon
        className={cn('fs-12 pointer')}
        onClick={() => onClear(chip)}
      />
    </SChip>
  );
};

export const ChipOutlined = (props: Props) => {
  const { status, className, tooltip, ...rest } = props;
  if (tooltip) {
    return (
      <Tooltip title={tooltip} arrow placement="top">
        <OutlinedContent
          {...rest}
          className={cn(className, { [`_${status}`]: status })}
        >
          {props.children}
        </OutlinedContent>
      </Tooltip>
    );
  }
  return (
    <OutlinedContent
      {...rest}
      className={cn(className, { [`_${status}`]: status })}
    >
      {props.children}
    </OutlinedContent>
  );
};

export default Chip;
