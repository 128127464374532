import { GET, POST, PUT, DELETE, buildListParams } from 'utils';
import { IBillableItem, IBillableItemPayload, IBIParams } from 'billable-items';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';

const NONT_TENANT_HEADERS = {
  headers: { 'X-JBBF-TENANT-SHORTNAME': undefined },
};

export const get = (params: IBIParams): IAxiosPromise<IBillableItem[]> =>
  GET(
    '/billable-items',
    buildListParams(params, [
      'orderType',
      'orderBy',
      'type',
      'status',
      'include',
      'partnerId',
      'userId',
      'include',
      'q',
      'dateFrom',
      'dateTo',
      'id',
      'recurringInterval',
    ])
  );

export const create = (
  payload: IBillableItemPayload
): AxiosPromise<IBillableItem> =>
  POST('/billable-items', {}, payload, NONT_TENANT_HEADERS);

export const update = (
  id: number,
  payload: IBillableItemPayload
): AxiosPromise<IBillableItem> =>
  PUT(`/billable-items/${id}`, {}, payload, NONT_TENANT_HEADERS);

export const remove = (id: number): AxiosPromise<Task> =>
  DELETE(`/billable-items/${id}`, {}, {}, NONT_TENANT_HEADERS);
