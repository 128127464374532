import * as React from 'react';
import { useTranslation } from 'react-i18next';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { MuiIcons, IconButton, List, ListItemButton, Popover } from 'elements';
import { observer } from 'mobx-react-lite';

type IProps = {
  isRequesting: boolean;
  onDetach: () => void;
  onSetAsPrimary: () => void;
};

const View = observer((props: IProps) => {
  const { isRequesting, onDetach, onSetAsPrimary } = props;
  const { t } = useTranslation();

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <>
          <IconButton
            {...bindTrigger(popupState)}
            size="small"
            className="mt-15"
          >
            <MuiIcons.MoreVert className="fs-18" />
          </IconButton>
          <Popover {...bindMenu(popupState)}>
            <List>
              <ListItemButton
                disabled={isRequesting}
                onClick={() => {
                  onDetach();
                  popupState.close();
                }}
              >
                {t(`services.content.action.detachNetwork`)}
              </ListItemButton>
              <ListItemButton
                disabled={isRequesting}
                onClick={() => {
                  onSetAsPrimary();
                  popupState.close();
                }}
              >
                {t(`services.content.action.setAsPrimary`)}
              </ListItemButton>
            </List>
          </Popover>
        </>
      )}
    </PopupState>
  );
});

const ActionMenu = (props: IProps) => <View {...props} />;

export default ActionMenu;
