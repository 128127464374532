import * as React from 'react';
import {
  Alert,
  Button,
  Col,
  Dialog,
  DialogProps,
  Input,
  MuiIcons,
  Row,
  SCustomList,
  Select,
  SPrimarySpan,
  Switch,
  Tooltip,
} from 'elements';
import { useTranslation } from 'react-i18next';
import {
  buildMonthCountByBillingInterval,
  confirm,
  dayjs,
  debounce,
  numberToCurrency,
  round,
  showSystemMessage,
} from 'utils';
import {
  ALL_TEST_IDS,
  CostResourceIds,
  getBillingCycleOptions,
  GLOBAL_DATE_FORMAT,
} from 'enums';
import { pick, sum, values as rValues } from 'ramda';
import {
  calculatePrices,
  findVmPricing,
  usePermissions,
  usePrice,
  useVmBillingDisplay,
} from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { ValueType } from 'global-shapes';
import { useFormik } from 'formik';
import {
  checkIfVmNameExist,
  fetchNetworkOptions,
  getVmDetailsValidationSchema,
  parseCloneData,
  parseVmDetailsToForm,
  validate,
} from './helpers';
import { VmDetailsContent } from '../Styled';

type IProps = React.PropsWithChildren<
  DialogProps<unknown> & {
    vmId: number;
    billingCycleInterval: ValueType<IVmTypes.IBillingCircleInterval>;
    currentVM: IVmTypes.Vm | null;
    hasSnapshot?: boolean;
    prices: Record<string, any>;
  }
>;
const noUsageIconStyles = {
  fontSize: 75,
  color: 'rgba(103, 125, 146, 0.2)',
};
export const STATIC_SERVICE_COUNT = { baseFee: 1 };
const FULL_PRICE_LIST = ['virtualCpus', 'ramMb', 'osDiskSizeGb', 'baseFee'];

const INITIAL_PRICES = {
  virtualCpus: 1,
  ramMb: 1,
  osDiskSizeGb: 100,
  ...STATIC_SERVICE_COUNT,
};

const OBSERVERS = {
  vmDetails: StateHandlers.vmDetails,
  vms: StateHandlers.vms,
  costBreakdown: StateHandlers.costBreakdown,
  tenantPricing: StateHandlers.tenantPricing,
  billingInfo: StateHandlers.billingInfo,
  templates: StateHandlers.osTemplates,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    onClose,
    onSave,
    open,
    vmId,
    currentVM,
    billingCycleInterval,
    prices: vmPrices,
    vmDetails,
    billingInfo,
    costBreakdown,
    tenantPricing,
    templates,
    vms,
  } = props;
  const { t } = useTranslation();
  const { isProvider, isEvaluation } = usePermissions();
  const isRootProvider = isProvider;
  const isUpdating = vmDetails.isRequesting;

  const prices = usePrice([CostResourceIds.virtualServerGBStorage]);
  const isRunning = !!currentVM && currentVM.status === 'POWERED_ON';

  const initials = React.useMemo(
    () => parseVmDetailsToForm(currentVM, templates.data, billingCycleInterval),
    [JSON.stringify([currentVM, billingCycleInterval, templates.data])]
  );

  const _prices = vmPrices || INITIAL_PRICES;

  const validation = React.useCallback(
    async (values: VmDialogTypes.IVmFormValues) => {
      const isSameName = initials.name === values.name;
      const errors = await validate(
        getVmDetailsValidationSchema(
          costBreakdown.data,
          false,
          isRunning,
          initials
        )
      )(values);

      if (!isSameName) {
        const res = await checkIfVmNameExist({
          vmId,
          osDiskSizeGb: values.osDiskSizeGb,
          vmName: values.name,
        }).catch(() => ({ exist: false, errorCode: 'error placeholder' }));

        if (res.exist) {
          errors.name = res.errorCode;
        }
      }

      return errors;
    },
    [
      JSON.stringify(costBreakdown.data),
      isRunning,
      JSON.stringify(initials),
      vmId,
    ]
  );

  const formConfig = useFormik({
    initialValues: initials,
    validateOnMount: false,
    validate: validation,
    onSubmit: async (val) => {
      return confirm({
        title: t('services.vm.confirm.clone.title', {
          currentVmName: currentVM?.name,
          name: val.name,
        }),
        content: (
          <Alert severity="warning">
            {t('services.vm.confirm.clone.content')}
          </Alert>
        ),
        onSuccess: async () => {
          try {
            await vms.executeRequest('cloneVm')(
              vmId,
              parseCloneData(val, isRootProvider)
            );
            await fetchCostBreakdown();
            if (onSave) {
              await onSave(val);
            }
            onClose();
            showSystemMessage('services.vm.edit.success', 'success');
          } catch (er: any) {
            showSystemMessage(er.message, 'error');
          }
        },
        onCancel: () => undefined,
      });
    },
  });
  const {
    values,
    dirty,
    submitCount,
    isSubmitting,
    errors,
    handleChange,
    setFieldValue,
    setFieldError,
  } = formConfig;

  const { isEditableResources, showCostInfo, showEnableSwitcher, discount } =
    useVmBillingDisplay({
      isRootProvider,
      billingInterval: values.billingCycleInterval?.value,
      isUsageEnabled: values.isUsageEnabled,
      billingCycleId: currentVM?.billingCycleId,
      isNew: false,
    });

  const isBillingMonthly = values.billingCycleInterval?.value === 'NONE';
  const isCycledChanged =
    initials.billingCycleInterval?.value !== billingCycleInterval.value;

  const isWindows = React.useMemo(
    () => currentVM && currentVM.os.osFamilyId === 1,
    [JSON.stringify(currentVM)]
  );

  const pricesFieldsToPick = React.useMemo(() => {
    const list = [...FULL_PRICE_LIST];
    if (isWindows) list.push('winLicense');
    return list;
  }, [currentVM, isWindows]);

  const monthCount = React.useMemo(
    () => buildMonthCountByBillingInterval(values.billingCycleInterval?.value),
    [values.billingCycleInterval?.value]
  );

  const calculatedPrices: Record<string, any> = currentVM
    ? calculatePrices(
        // @ts-ignore
        pick(pricesFieldsToPick)({
          ...values,
          winLicense: isWindows ? 1 : 0,
        }),
        findVmPricing(tenantPricing.data)
      )
    : {};

  const currentCalculatedPrices: Record<string, any> | null = currentVM
    ? calculatePrices(
        // @ts-ignore
        pick(pricesFieldsToPick)({
          ...currentVM,
          ...STATIC_SERVICE_COUNT,
          winLicense: isWindows ? 1 : 0,
        }),
        findVmPricing(tenantPricing.data)
      )
    : null;

  const disksPrice = currentVM
    ? sum(
        currentVM.disks.map(
          (d: IVmTypes.VMDisk) =>
            d.sizeGb * prices[CostResourceIds.virtualServerGBStorage]?.monthly
        )
      )
    : 0;

  const clearPrice = React.useMemo(
    () => sum([...rValues(calculatedPrices), disksPrice]) * monthCount,
    [currentCalculatedPrices]
  );

  const discountPrice = clearPrice * discount;
  const totalPrice = React.useMemo(
    () => round(clearPrice - discountPrice, 2),
    [clearPrice, discount]
  );

  const fetchCostBreakdown = React.useCallback(() => costBreakdown.get(), []);

  const handleVmNameChange = React.useCallback(
    debounce(async (e: React.ChangeEvent<any>) => {
      const vmName = e.target.value || '';
      const res = await checkIfVmNameExist({
        vmName,
        osDiskSizeGb: values.osDiskSizeGb,
        vmId,
      }).catch(() => ({ exist: false, errorCode: 'error placeholder' }));
      if (res.exist) {
        setFieldError('name', res.errorCode);
      }
    }, 500),
    [vmId]
  );

  React.useEffect(() => {
    if (open) {
      formConfig.setValues(
        parseVmDetailsToForm(currentVM, templates.data, billingCycleInterval)
      );
      fetchNetworkOptions('').then((res) => {
        const defaultNetwork = res.find((el) => el.isPrimary);
        const currentNetwork = res.find(
          (el) => el.value === currentVM?.nics[0]?.networkId
        );

        setFieldValue('networkId', currentNetwork || defaultNetwork);
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      contentProps={{ classes: { root: 'pb-0 overflow-visible' } }}
      title={t('services.vm.dialog.clone.title')}
      onClose={onClose}
      maxWidth="md"
      handleSubmit={formConfig.handleSubmit}
      fullWidth
      keepMounted={false}
    >
      {currentVM && (
        <Row columnSpacing={4}>
          <Col xs={7}>
            <div className="mb-20">
              <Input
                name="name"
                label="services.dialog.vms.serverName"
                testId={ALL_TEST_IDS.services.vm.details}
                value={values.name}
                onChange={(ev) => {
                  handleChange(ev);
                  handleVmNameChange(ev);
                }}
                placeholder="services.dialog.vms.serverName"
                error={!!submitCount && !!errors.name}
                helperText={errors.name}
                helperTextOptions={{ max: 15 }}
                aria-autocomplete="none"
                autoComplete="off"
                inputProps={{
                  autoComplete: 'off',
                }}
                labelIcon={
                  <Tooltip
                    placement="top"
                    title={
                      <div>
                        {t('services.dialog.vms.name.tip')
                          .split('; ')
                          .map((s) => (
                            <div key={s}>- {s}</div>
                          ))}
                      </div>
                    }
                  >
                    <MuiIcons.HelpOutline
                      className="fs-12 ml-5"
                      color="primary"
                    />
                  </Tooltip>
                }
              />
            </div>

            <div className="mb-20">
              <Input
                type="password"
                name="guestOSPassword"
                label="forms.password"
                testId={ALL_TEST_IDS.services.vm.details}
                value={values.guestOSPassword}
                onChange={handleChange}
                placeholder="forms.placeholders.password"
                error={!!submitCount && !!errors.guestOSPassword}
                helperText={errors.guestOSPassword}
                helperTextOptions={{ max: 32 }}
                aria-autocomplete="none"
                autoComplete="off"
                inputProps={{
                  autoComplete: 'new-password',
                }}
                labelIcon={
                  <Tooltip
                    placement="top"
                    title={
                      <div>
                        {t('forms.sups.password')
                          .split('; ')
                          .map((s) => (
                            <div key={s}>- {s}</div>
                          ))}
                      </div>
                    }
                  >
                    <MuiIcons.HelpOutline
                      className="fs-12 ml-5"
                      color="primary"
                    />
                  </Tooltip>
                }
              />
            </div>

            <div className="mb-20">
              <Input
                name="description"
                testId={ALL_TEST_IDS.services.vm.details}
                label="services.dialog.vms.description"
                value={values.description}
                multiline
                rows={3}
                onChange={handleChange}
                placeholder="services.dialog.vms.description"
                error={!!errors.description}
                helperText={errors.description}
                helperTextOptions={{ max: 100 }}
              />
            </div>
            {!values.isPublic && (
              <div className="mb-20">
                <Select
                  name="networkId"
                  testId={ALL_TEST_IDS.services.vm.details}
                  label="forms.network"
                  value={values.networkId}
                  onChange={(el) => setFieldValue('networkId', el)}
                  defaultOptions
                  useAsync
                  onLoad={fetchNetworkOptions}
                />
                {!values.networkId?.isPrimary && (
                  <div className="fs-12 error lh-20">
                    {t('services.dialog.vms.message.notPrimaryNetwork')}
                  </div>
                )}
              </div>
            )}
          </Col>
          <Col xs={5}>
            <VmDetailsContent
              direction="column"
              justifyContent="space-between"
              columnSpacing={2}
            >
              <Col>
                {!isEditableResources && (
                  <div className="steel text-center pl-30 pr-30">
                    <div className="mb-15 fs-15">
                      {t('costsInfo.billingDisabled') as string}
                    </div>
                    <MuiIcons.CreditCardOff style={noUsageIconStyles} />
                  </div>
                )}
                {showEnableSwitcher && (
                  <div>
                    <div className="mb-5">
                      <Switch
                        label={t('costsInfo.billing.enable.label')}
                        checked={values.isUsageEnabled}
                        onCheck={(isUsageEnabled) => {
                          formConfig.setFieldValue(
                            'isUsageEnabled',
                            isUsageEnabled
                          );
                        }}
                      />
                    </div>
                    <div className="fs-12 steel mb-15">
                      {t('costsInfo.billing.enable.subtitle') as string}
                    </div>
                  </div>
                )}

                {showCostInfo && (
                  <>
                    <h5 className="mb-25 primary">
                      <SPrimarySpan>
                        {t('costsInfo.title') as string}
                      </SPrimarySpan>
                    </h5>
                    <Select
                      options={getBillingCycleOptions(isEvaluation)}
                      value={values.billingCycleInterval}
                      onChange={(val) =>
                        formConfig.setFieldValue('billingCycleInterval', val)
                      }
                      className="mb-15"
                    />
                    {currentVM.billingCycleId && isCycledChanged && (
                      <Alert severity="info" className="mb-15">
                        <ul>
                          {isCycledChanged && dirty && (
                            <li>
                              {
                                t(
                                  'costsInfo.billingCircle.warnings.onlyCycle',
                                  {
                                    date: dayjs(
                                      billingInfo.data.periodEnd,
                                      'YYYY-MM-DD'
                                    ).format(GLOBAL_DATE_FORMAT),
                                  }
                                ) as string
                              }
                            </li>
                          )}
                          {dirty && (
                            <li>
                              {
                                t(
                                  'costsInfo.billingCircle.warnings.cycleWithVmValues',
                                  {
                                    date: '2122.11.24',
                                  }
                                ) as string
                              }
                            </li>
                          )}
                        </ul>
                      </Alert>
                    )}
                    <Row
                      justifyContent="space-between"
                      columnSpacing={2}
                      className="fs-10 steel uppercase mb-15"
                    >
                      <Col xs={8}>
                        <span>{t('costsInfo.head.serviceName') as string}</span>
                      </Col>
                      <Col xs={4} className="text-right">
                        <span>{t('costsInfo.head.price') as string}</span>
                      </Col>
                    </Row>
                    <h5 className="mb-15">
                      {t('costsInfo.virtualServer') as string}
                    </h5>
                    <SCustomList className="custom-list">
                      <ul>
                        <li>
                          <div className="flex justify-between">
                            <span>{t('costsInfo.baseFee') as string}</span>
                            <span className="pl-10 steel">
                              {numberToCurrency(
                                calculatedPrices.baseFee * monthCount,
                                false
                              )}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="flex justify-between">
                            <span>
                              {
                                t('costsInfo.virtualCpus', {
                                  value: _prices.virtualCpus,
                                }) as string
                              }
                            </span>
                            <span className="pl-10 steel">
                              {numberToCurrency(
                                calculatedPrices.virtualCpus * monthCount,
                                false
                              )}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="flex justify-between">
                            <span>
                              {
                                t('costsInfo.ramMb', {
                                  value: _prices.ramMb,
                                }) as string
                              }
                            </span>
                            <span className="pl-10 steel">
                              {numberToCurrency(
                                calculatedPrices.ramMb * monthCount,
                                false
                              )}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="flex justify-between">
                            <span>
                              {
                                t('costsInfo.osDiskSizeGb', {
                                  value: _prices.osDiskSizeGb,
                                }) as string
                              }
                            </span>
                            <span className="pl-10 steel">
                              {numberToCurrency(
                                calculatedPrices.osDiskSizeGb * monthCount,
                                false
                              )}
                            </span>
                          </div>
                        </li>
                        {isWindows && (
                          <li>
                            <div className="flex justify-between">
                              <span>{t('costsInfo.winLicense') as string}</span>
                              <span className="pl-10 steel">
                                {numberToCurrency(
                                  calculatedPrices.winLicense * monthCount,
                                  false
                                )}
                              </span>
                            </div>
                          </li>
                        )}
                        {currentVM.disks.map((d: IVmTypes.VMDisk) => {
                          return (
                            <li key={d.id}>
                              <div className="flex justify-between">
                                <span>
                                  {d.name} {d.sizeGb} GB
                                </span>
                                <span className="pl-10 steel">
                                  {numberToCurrency(
                                    d.sizeGb *
                                      prices[
                                        CostResourceIds.virtualServerGBStorage
                                      ]?.monthly *
                                      monthCount,
                                    false
                                  )}
                                </span>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </SCustomList>
                  </>
                )}
              </Col>
              {showCostInfo && (
                <Col>
                  {!!discount && (
                    <div className="flex justify-between align-center">
                      <div className="fs-12 mb-10">
                        {
                          t('costsInfo.discount', {
                            value: discount * 100,
                          }) as string
                        }
                      </div>
                      <div className="bolder">
                        {numberToCurrency(-discountPrice, false)}
                      </div>
                    </div>
                  )}
                  {isBillingMonthly && (
                    <div className="flex justify-between align-center">
                      <div className="fs-14">
                        {t('costsInfo.currentTotalMonthly') as string}
                      </div>
                      <span className="fs-16 bold">
                        {currentCalculatedPrices &&
                          numberToCurrency(
                            sum([
                              ...rValues(currentCalculatedPrices),
                              disksPrice,
                            ]),
                            false
                          )}
                      </span>
                    </div>
                  )}
                  <div className="flex justify-between align-center">
                    <h5 className="fs-17">
                      {
                        t(
                          `costsInfo.totalCost.${values.billingCycleInterval?.value}`
                        ) as string
                      }
                    </h5>
                    <SPrimarySpan className="fs-20 bold">
                      {numberToCurrency(totalPrice, false)}
                    </SPrimarySpan>
                  </div>
                  <div className="fs-12 steel">
                    {t('costsInfo.chfExclVat') as string}
                  </div>
                </Col>
              )}
            </VmDetailsContent>
          </Col>
        </Row>
      )}

      <Row justifyContent="space-between" columnSpacing={2}>
        <Col>
          <Button variant="outlined" color="default" onClick={onClose}>
            {t('common.cancel')}
          </Button>
        </Col>
        <Col>
          <Button type="submit" disabled={isUpdating || isSubmitting}>
            {t('common.save')}
          </Button>
        </Col>
      </Row>
    </Dialog>
  );
});

const CloneVmDialog = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default CloneVmDialog;
