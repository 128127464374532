import { GET, POST, DELETE, PUT, buildListParams } from 'utils';
import {
  IFileShareFormValues,
  IFSUserPermission,
  IMigrationEndpointFormValues,
  IMigrationEndpoint,
  IFileShare,
} from 'file-shares';
import { ICommonListParams } from 'global-shapes';
import { AxiosPromise } from 'axios';
import { IAxiosPromise } from 'axios-shapes';

export const get = (params: ICommonListParams): IAxiosPromise<IFileShare[]> =>
  GET('/file-shares', buildListParams(params, ['orderBy', 'orderType']));

export const getById = (id: number): IAxiosPromise<IFileShare> =>
  GET(`/file-shares/${id}`);

export const create = (data: IFileShareFormValues): AxiosPromise<IFileShare> =>
  POST(`/file-shares`, {}, data);

export const update = (
  fsId: number,
  data: IFSUserPermission
): AxiosPromise<IFileShare> =>
  PUT(`/file-shares/${fsId}/users`, {}, { users: data });

export const remove = (id: number): AxiosPromise<Task> =>
  DELETE(`/file-shares/${id}`);

export const createMigrationEndpoint = (
  fileShareId: number,
  payload: IMigrationEndpointFormValues
): AxiosPromise<IMigrationEndpoint> =>
  POST(
    `/file-shares/${fileShareId}/migration-endpoint`,
    {},
    {
      ...payload,
      localSourcePath: `\\\\${payload.localSourcePath}`,
    }
  );

export const deleteMigrationEndpoint = (
  fileShareId: number
): AxiosPromise<Task> =>
  DELETE(`/file-shares/${fileShareId}/migration-endpoint`);

export const updateMigrationEndpoint = (
  fileShareId: number,
  payload: IMigrationEndpointFormValues
): AxiosPromise<IMigrationEndpoint> =>
  PUT(
    `/file-shares/${fileShareId}/migration-endpoint`,
    {},
    {
      ...payload,
      localSourcePath: `\\\\${payload.localSourcePath}`,
    }
  );
