import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { useAccount, useForceUpdate, useState, useStateHandler } from 'hooks';
import { LinearTable, Row, Radio, Input, createColumnHelper } from 'elements';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { debounce } from 'utils';
import * as TYPES from './types';

type IConfig = {
  t: any;
  selected?: IVmVcdTypes.Vcd;
  onSelect: (vcd: IVmVcdTypes.Vcd | undefined) => void;
};

type IQuery = {
  orderType: string;
  orderBy: string;
  q?: string;
  page: number;
  perPage: 50;
};

const column = createColumnHelper<IVmVcdTypes.Vcd>();

const getColumns = ({ t, onSelect, selected }: IConfig) => [
  column.accessor('name', {
    header: (
      <span className="steel fs-12 weight-normal">
        {t('table.head.tenantName')}
      </span>
    ),
    size: 300,
    disableSort: false,
  }),
  column.accessor('orgName', {
    header: (
      <span className="steel fs-12 weight-normal">
        {t('table.head.shortName')}
      </span>
    ),
    size: 250,
    disableSort: true,
  }),

  column.accessor('numberOfVMs', {
    header: (
      <span className="steel fs-12 weight-normal">{t('table.head.vmQty')}</span>
    ),
    disableSort: true,
  }),

  column.accessor('numberOfVApps', {
    header: <span className="" />,
    disableSort: true,
    size: 60,
    cell: ({ row: { original } }: ICell<IVmVcdTypes.Vcd>) => {
      const isSelected = selected?.id === original.id;

      return (
        <Row
          alignItems="center"
          justifyContent="flex-end"
          className="full-width"
        >
          <Radio
            value={original.id}
            label={''}
            className="m-0"
            checked={isSelected}
            onChange={() => onSelect(isSelected ? undefined : original)}
          />
        </Row>
      );
    },
  }),
];

type IProps = FormikProps<TYPES.IFormValues> & {
  onSelect: (vcd: IVmVcdTypes.Vcd | undefined) => void;
};

const FirstStep = observer((props: IProps) => {
  const { t } = useTranslation();
  const [account] = useAccount();
  const forceUpdate = useForceUpdate();
  const vcd = useStateHandler(StateHandlers.vcd);
  const searchRef = React.useRef({ value: '' });
  const [query, handleQuery] = useState<IQuery>({
    orderBy: 'name',
    orderType: 'asc',
    page: 1,
    perPage: 50,
  });

  React.useEffect(() => {
    searchRef.current.value = query.q || '';
    forceUpdate();
  }, [query.q]);

  const changeSearchUrl = React.useCallback(
    (q: string) => handleQuery({ ...query, q, page: 1 }),
    [query]
  );

  const onItemSearch = React.useCallback(
    debounce(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        changeSearchUrl(ev.target.value),
      1000
    ),
    [query]
  );

  const columns = React.useMemo(
    () =>
      getColumns({ t, onSelect: props.onSelect, selected: props.values.vcd }),
    [props.onSelect]
  );

  useEffect(() => {
    vcd.get({ ...query, partnerId: account?.partner?.id });
  }, [JSON.stringify(query), account?.partner?.id]);

  return (
    <div>
      <div className="mb-25">
        <Input
          ref={searchRef}
          type="search"
          placeholder={t('forms.placeholders.search')}
          className="bg-white"
          onKeyUp={(e: any) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              changeSearchUrl(e.target.value);
            }
          }}
          onClear={() => {
            searchRef.current.value = '';
            changeSearchUrl('');
          }}
          onChange={onItemSearch}
        />
      </div>
      <LinearTable
        data={vcd.data}
        columns={columns}
        query={query}
        params={vcd.meta}
        hasSorting
        onSort={(orderBy, orderType) => handleQuery({ orderBy, orderType })}
        onPageChange={(newQuery) => handleQuery({ page: newQuery.page })}
        usePagination
        shouldUseQuery={false}
      />
    </div>
  );
});

export default FirstStep;
