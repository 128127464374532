import { maintenanceService } from 'services';
import { BaseMobxInstanceDecorator } from './utilities';

export const maintenance = new BaseMobxInstanceDecorator<
  IMaintenanceWindow,
  true
>({
  instanceName: 'maintenance',
  requests: {
    get: maintenanceService.getList,

    update: maintenanceService.updateById,
    remove: maintenanceService.deleteById,
  },
  initialState: [],
});
