import React, { useMemo } from 'react';
import { Dialog, DialogProps, Button, Input, Row, Col, Select } from 'elements';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { numberToCurrency, validateCallback, validator } from 'utils';
import {
  INITIAL_POSITION_VALUES,
  positionSchema,
  unitOptions,
  MAX_DESCRIPTION_LENGTH,
} from '../constants';
import { IInvoiceTypes } from '../types';

type Props = DialogProps<IInvoiceTypes.PositionState> & {
  isRequesting: boolean;
  initialValues?: IInvoiceTypes.PositionState | null;
};

const PositionDialog = (props: Props) => {
  const { isRequesting, initialValues } = props;
  const { t } = useTranslation();
  const isNew = !initialValues;
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    submitCount,
    errors,
    values,
    resetForm,
    setValues,
  } = useFormik<IInvoiceTypes.PositionState>({
    validateOnMount: false,
    validationSchema: positionSchema,
    initialValues: initialValues || INITIAL_POSITION_VALUES,
    onSubmit: (val) => Promise.resolve(props.onSave && props.onSave(val)),
  });

  React.useEffect(() => {
    if (!props.open) {
      resetForm();
      setValues(INITIAL_POSITION_VALUES);
    }
    if (props.open && initialValues) {
      setValues(initialValues);
    }
  }, [props.open]);

  const total = useMemo(
    () => Number(values.qty || 0) * Number(values.price || 0),
    [values.qty, values.price]
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      title={t(
        `invoices.current.dialog.${isNew ? 'add' : 'edit'}Position.title`
      )}
      handleSubmit={handleSubmit}
      actions={
        <Row justifyContent="space-between" alignItems="center">
          <Col>
            <div className="flex bold">
              <span className="pr-10">
                {t('invoices.current.dialog.addPosition.totalCHF')}{' '}
              </span>
              <span>{numberToCurrency(total, false)}</span>
            </div>
          </Col>
          <Col>
            <Row columnSpacing={2}>
              <Col>
                <Button
                  color="default"
                  variant="outlined"
                  onClick={props.onClose}
                  disabled={isRequesting}
                >
                  {t('common.cancel')}
                </Button>
              </Col>
              <Col>
                <Button type="submit" disabled={isRequesting}>
                  {t('common.save')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <Input
        name="description"
        className="mb-20"
        label={t('forms.description')}
        onChange={handleChange}
        placeholder={t('forms.placeholders.description')}
        value={values.description}
        error={!!submitCount && !!errors.description}
        helperText={errors.description}
        helperTextOptions={{ max: MAX_DESCRIPTION_LENGTH }}
      />
      <Row columnSpacing={2}>
        <Col xs={4}>
          <Input
            name="price"
            label={t('forms.cost')}
            onChange={validateCallback({
              restrict: validator.numbersWithDefinedDecimals(4),
            })(handleChange)}
            value={values.price}
            error={!!submitCount && !!errors.price}
            helperText={errors.price}
          />
        </Col>
        <Col xs={4}>
          <Input
            name="qty"
            label={t('forms.qty')}
            onChange={validateCallback({
              restrict: validator.numbersWithDefinedDecimals(4),
            })(handleChange)}
            value={values.qty}
            error={!!submitCount && !!errors.qty}
            helperText={errors.qty}
          />
        </Col>
        <Col xs={4}>
          <Select
            name="unit"
            label={t('forms.unit')}
            options={unitOptions}
            onChange={(val) => setFieldValue('unit', val)}
            value={values.unit}
            error={!!submitCount && !!errors.unit}
            helperText={errors.unit}
          />
        </Col>
      </Row>
    </Dialog>
  );
};

export default PositionDialog;
