import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogProps, Button, Row, Col, LinearTable } from 'elements';
import { useStateHandler, useState } from 'hooks';
import { observer } from 'mobx-react-lite';
import { remCalc } from 'utils';
import { defaultVmsQuery } from 'enums/defaultQueryForPages';
import * as StateHandlers from 'states';
import * as ENUMS from './constants';

type Props = DialogProps & {
  netId: number | null;
};

const ConnectedVmsDialog = observer((props: Props) => {
  const { t } = useTranslation();
  const { open, onClose, netId } = props;
  const vms = useStateHandler(StateHandlers.vmsByNetwork);
  const [query, handleQuery] = useState(defaultVmsQuery);

  React.useEffect(() => {
    if (open && netId) {
      vms.get({
        ...query,
        networkId: netId,
      });
    }
    return () => {
      vms.reset();
    };
  }, [open, JSON.stringify(query)]);

  return (
    <Dialog
      title={t('services.connectivity.networks.dialog.vms.title')}
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={onClose}
      contentProps={{
        style: {
          minHeight: remCalc(400),
        },
      }}
      actions={
        <Row alignItems="center" justifyContent="flex-end">
          <Col>
            <Button color="default" variant="outlined" onClick={onClose}>
              {t('common.ok')}
            </Button>
          </Col>
        </Row>
      }
    >
      <div className="fs-14 mb-30">
        {t('services.connectivity.networks.dialog.vms.content')}
      </div>
      <LinearTable
        data={vms.data}
        params={vms.meta}
        columns={ENUMS.getVmTableColumn({ t })}
        isLoading={!vms.dataReceived}
        className={cn({ disabled: vms.isRequesting })}
        shouldUseQuery={false}
        onSort={(orderBy, orderType) => handleQuery({ orderBy, orderType })}
        onPageChange={({ page }) => handleQuery({ page })}
        query={query}
        usePagination
        hasSorting
      />
    </Dialog>
  );
});

export default ConnectedVmsDialog;
