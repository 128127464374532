import { showSystemMessage } from 'utils';
import { forwardedPortsService } from 'services';
import { ForwardedPort } from 'forwarded-ports';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export const forwardedPorts = new BaseMobxInstanceDecorator<
  ForwardedPort,
  true
>({
  instanceName: 'forwarded-ports',
  requests: {
    get: forwardedPortsService.getPorts,
    create: async (payload) => {
      const res = await forwardedPortsService.createPort(payload);
      showSystemMessage(
        'remoteSupport.dialog.notify.enable.success',
        'success'
      );
      return res;
    },
    remove: async (id) => {
      const res = await forwardedPortsService.deletePort(id as number);
      showSystemMessage(
        'remoteSupport.dialog.notify.disable.success',
        'success'
      );
      return res;
    },
  },
  initialState: [],
});

export type IForwardedPortsHandler = BaseDecorator<ForwardedPort, true>;
