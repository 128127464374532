import React from 'react';
import { Backup } from 'components/Backup';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

const OBSERVERS = {
  core: StateHandlers.core,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { core } = props;
  const coreData = core.data || { vm: {} };
  return (
    <Backup
      entityId={coreData.id}
      entityName="CoreInfrastructure"
      vmDiskSize={coreData.vm.osDiskSizeGb}
      isUsageEnabled
      instance={coreData}
    />
  );
});

const BackupComponent = () => <View {...OBSERVERS} />;

export default BackupComponent;
