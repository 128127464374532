import React from 'react';
import { Paper, Input, Button, Loader } from 'elements';
import { ALL_TEST_IDS } from 'enums';
import { useFormik } from 'formik';
import { withPermissions, InjectedPermissionsProps } from 'hocs';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useTranslation } from 'react-i18next';
import { INITIAL_STATE, validationSchema } from './constnants';
import { IVersionTypes } from './types';
import { VersionHolder } from '../Styled';

const testId = ALL_TEST_IDS.appVersion.main;

const OBSERVERS = {
  appSettings: StateHandlers.appSettings,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: InjectedPermissionsProps & IViewProps) => {
  const { appSettings } = props;
  const { t } = useTranslation();

  const onSubmit = React.useCallback(
    (values: IVersionTypes.IValues) =>
      appSettings.update(null, values).then(appSettings.get),
    []
  );

  const { values, errors, handleChange, handleSubmit, submitCount, setValues } =
    useFormik({
      initialValues: appSettings.data || INITIAL_STATE,
      validateOnMount: false,
      validationSchema,
      onSubmit,
    });

  React.useEffect(() => {
    appSettings.get();
  }, []);

  React.useEffect(() => {
    if (appSettings.dataReceived) {
      setValues(appSettings.data);
    }
  }, [appSettings.dataReceived]);

  if (!appSettings.dataReceived) {
    return <Loader />;
  }

  return (
    <Paper>
      <VersionHolder>
        <h3 className="mb-30">{t('settings.page.version.title')}</h3>
        <form
          onSubmit={handleSubmit}
          data-test-id="version-form"
          className="mb-30"
        >
          <Input
            label={t('forms.version')}
            value={values.version}
            onChange={handleChange}
            name="version"
            className="mb-25"
            helperText={errors.version}
            helperTextOptions={{ max: 100 }}
            error={!!submitCount && !!errors.version}
          />
          <Input
            label={t('forms.linkName')}
            value={values.versionLinkName}
            name="versionLinkName"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.versionLinkName}
            helperTextOptions={{ max: 100 }}
            error={!!submitCount && !!errors.versionLinkName}
            testId={testId}
          />
          <Input
            label={t('forms.link')}
            value={values.versionLinkUrl}
            name="versionLinkUrl"
            className="mb-25"
            onChange={handleChange}
            helperText={errors.versionLinkUrl}
            error={!!submitCount && !!errors.versionLinkUrl}
            testId={testId}
          />
          <Button type="submit" disabled={appSettings.isRequesting}>
            {t('common.save')}
          </Button>
        </form>
      </VersionHolder>
    </Paper>
  );
});

const Versions = (props: InjectedPermissionsProps) => (
  <View {...props} {...OBSERVERS} />
);

export default withPermissions(Versions, 'USERS');
