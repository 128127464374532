import * as React from 'react';
import { Button, Input, Select } from 'elements';
import { withTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import { useFormik } from 'formik';
import { useUserHash } from 'hooks';
import {
  companyValidationSchema,
  knowUsOptions,
  ICompanyFormValues,
} from './constants';
import { ALL_TEST_IDS } from 'enums';
import FormStepper from './FormStepper';

type IProps = React.PropsWithChildren<
  WithT & {
    innerContent: any;
    isSubmitting: boolean;
    showStepper: boolean;
    activeStep: number;
    initialValues: ICompanyFormValues;
    onSubmit: (val: ICompanyFormValues) => void;
  }
>;

const Renderer = (props: IProps) => {
  const [hash] = useUserHash();
  const {
    t,
    showStepper,
    activeStep,
    innerContent,
    initialValues,
    isSubmitting,
    onSubmit,
  } = props;

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    submitCount,
    setFieldValue,
    isValidating,
    submitForm,
  } = useFormik({
    initialValues,
    validationSchema: companyValidationSchema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit,
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mb-40">
          <FormStepper
            showStepper={showStepper}
            handleNext={() => submitForm()}
            activeStep={activeStep}
          />
        </div>
        {innerContent}
        <Input
          testId={ALL_TEST_IDS.signUp.inputs}
          id="companyName"
          name="companyName"
          label={t('forms.companyName')}
          placeholder={t('forms.placeholders.companyName')}
          error={!!submitCount && !!errors.companyName}
          helperText={errors.companyName}
          helperTextOptions={{ max: 30 }}
          className="mb-25"
          onChange={handleChange}
          value={values.companyName}
          elementSize="lg"
        />
        <Input
          testId={ALL_TEST_IDS.signUp.inputs}
          id="shortName"
          name="shortName"
          label={t('forms.shortname', { shortName: hash }).replace(
            '&#x2F;',
            '/'
          )}
          placeholder={t('forms.placeholders.shortname')}
          error={!!submitCount && !!errors.shortName}
          helperText={errors.shortName}
          helperTextOptions={{ max: 16, min: 3 }}
          className="mb-25"
          onChange={handleChange}
          value={values.shortName}
          elementSize="lg"
        />
        <Select
          testId={ALL_TEST_IDS.signUp.inputs}
          id="cameFrom"
          label={t('auth.registration.cameFrom.label') as string}
          value={values.cameFrom || undefined}
          name="cameFrom"
          error={!!submitCount && !!errors.cameFrom}
          helperText={errors.cameFrom}
          className="mb-40"
          onChange={(val) => setFieldValue('cameFrom', val)}
          options={knowUsOptions}
        />
        <Button
          testId={ALL_TEST_IDS.signUp.submit}
          size="large"
          type="submit"
          fullWidth
          disabled={isValidating || isSubmitting}
          className="mb-20"
        >
          {t('auth.registration.button.submit')}
        </Button>
      </form>
    </>
  );
};

const CompanyInfoForm = withTranslation()(Renderer);

export default CompanyInfoForm;
