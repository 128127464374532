import * as React from 'react';
import {
  CopyTooltip,
  Col,
  Row,
  Loader,
  IconDirectNetworkMonitor,
  IconIsolatedNetworkMonitor,
  IconNatRoutedNetworkMonitor,
  ChipOutlined,
  Tooltip,
} from 'elements';
import { useTask } from 'hooks';
import { validator, confirm, noop } from 'utils';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import Actions from './Actions';
import { SExternalIpRow, SEditableText } from '../Styled';

const OBSERVERS = {
  reverseDns: StateHandlers.reverseDns,
};

type IProps = IVmTypes.Nic & {
  vmId: number;
  isRequesting: boolean;
  onDetach: (nic: IVmTypes.Nic) => void;
  onSetAsPrimary: (nic: IVmTypes.Nic) => void;
  refreshData: () => void;
  handleRecordEdit: (
    appEntityId: number,
    ip: string,
    record?: IReverseDnsRecord
  ) => (text: string) => void;
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    vmId,
    isRequesting,
    onDetach,
    refreshData,
    handleRecordEdit,
    onSetAsPrimary,
    ...nic
  } = props;
  const { ipv4, ipv6, isPrimary, appEntityId, reverseDns, network } = nic;

  const isDirectNet = network.type === 'DIRECT';
  const isIsolatedNet = network.type === 'ISOLATED';
  const isRoutedNet = network.type === 'NAT_ROUTED';

  const { t } = useTranslation();

  const dnsRecordV4 = reverseDns.data.find((rd) => rd.ipAddress === ipv4);
  const dnsRecordV6 = reverseDns.data.find((rd) => rd.ipAddress === ipv6);

  const taskV4 = useTask(dnsRecordV4?.task, {
    onComplete: refreshData,
  });

  const taskV6 = useTask(dnsRecordV6?.task, {
    onComplete: refreshData,
  });

  const onConfirmReverseDnsDelete = React.useCallback(
    (recordId: number) => () => {
      confirm({
        title: t('services.vm.info.reverseDns.confirm.title'),
        content: t('services.vm.info.reverseDns.confirm.content'),
        onSuccess: () => reverseDns.remove(recordId).then(refreshData),
        onCancel: () => undefined,
      });
    },
    [vmId]
  );

  const ipv4Subnet = network.subnets[network.defaultIpv4SubnetIndex];
  const ipv6Subnet = network.subnets[network.defaultIpv6SubnetIndex];

  let icon = <span />;

  if (isDirectNet) {
    icon = <IconDirectNetworkMonitor status="primary" onClick={noop} />;
  }
  if (isIsolatedNet) {
    icon = <IconIsolatedNetworkMonitor status="primary" onClick={noop} />;
  }
  if (isRoutedNet) {
    icon = <IconNatRoutedNetworkMonitor status="primary" onClick={noop} />;
  }

  let networkName: any = undefined;

  const nameLength = network.name.length;

  if (nameLength <= 15) {
    networkName = <span className="ellipsis">{network.name}</span>;
  }

  networkName = (
    <Tooltip title={network.name} placement="top" arrow>
      <span className="ellipsis">{network.name}</span>
    </Tooltip>
  );

  return (
    <div className="mb-5">
      <SExternalIpRow columnSpacing={1} alignItems="flex-start">
        <Col xs={2}>
          <div className="steel fs-12 mb-5">
            {t('table.head.networkName') as string}
          </div>
          <Row alignItems="flex-end" className="full-width">
            {icon}
            <Col className="ellipsis pl-5" xs>
              {networkName}
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <div className="fs-12 steel mb-5">
            {t('table.head.IPv4') as string}
          </div>
          <div className="flex align-center mb-10">
            <div className="mr-10">
              {ipv4 ? <CopyTooltip>{ipv4}</CopyTooltip> : '-'}
            </div>
            {isPrimary && (
              <ChipOutlined status="primary" className="fs-10">
                {t('common.primary') as string}
              </ChipOutlined>
            )}
          </div>
        </Col>
        <Col xs={2}>
          <div className="fs-12 steel mb-5">
            {t('table.head.subnet') as string}
          </div>
          <div className="flex align-center mb-10">
            <div className="mr-10 ellipsis">
              {ipv4 ? <CopyTooltip>{ipv4Subnet?.cidr}</CopyTooltip> : '-'}
            </div>
          </div>
        </Col>
        <Col xs={2}>
          <div className="steel fs-12 mb-5">
            {t('table.head.gateway') as string}
          </div>
          <div className="flex align-center mb-10">
            <div className="mr-10 ellipsis">
              {ipv4 && !isIsolatedNet ? (
                <CopyTooltip>{ipv4Subnet?.gateway}</CopyTooltip>
              ) : (
                '-'
              )}
            </div>
          </div>
        </Col>
        <Col xs>
          <Row justifyContent="space-between" alignItems="center">
            <Col>
              {isDirectNet &&
                (!reverseDns.dataReceived ? (
                  <Loader size={15} />
                ) : (
                  <>
                    <div className="steel fs-12 mb-5">
                      {t('table.head.reverseDns') as string}
                    </div>

                    <SEditableText
                      name="domainName"
                      textClassName="_display"
                      isInactive={!ipv4}
                      text={dnsRecordV4?.domainName || ''}
                      displayText={
                        dnsRecordV4?.domainName || (
                          <span className="steel">
                            {
                              t(
                                'services.vm.info.reverseDns.noDomain'
                              ) as string
                            }
                          </span>
                        )
                      }
                      alignItems="flex-start"
                      placeholder="www.example.com"
                      onSuccess={handleRecordEdit(
                        appEntityId as number,
                        ipv4,
                        dnsRecordV4
                      )}
                      // @ts-ignore
                      validate={(text: string) => {
                        const error = !validator.isFQDN(text || '');
                        return error ? t('forms.invalid.domainName') : false;
                      }}
                      isUpdating={
                        taskV4.isTaskActive || reverseDns.isRequesting
                      }
                      onDelete={
                        dnsRecordV4 && onConfirmReverseDnsDelete(dnsRecordV4.id)
                      }
                      isEditable
                    />
                  </>
                ))}
            </Col>
            <Col>
              {!isPrimary && (
                <Actions
                  onDetach={() => onDetach(nic)}
                  onSetAsPrimary={() => onSetAsPrimary(nic)}
                  isRequesting={isRequesting}
                />
              )}
            </Col>
          </Row>
        </Col>
      </SExternalIpRow>
      {ipv6 && (
        <SExternalIpRow columnSpacing={1}>
          <Col xs={2}>
            <div className="steel fs-12 mb-5">
              {t('table.head.networkName') as string}
            </div>
            <Row alignItems="flex-end" className="full-width">
              {icon}
              <Col className="ellipsis pl-5" xs>
                {networkName}
              </Col>
            </Row>
          </Col>
          <Col xs={3}>
            <div className="steel fs-12 mb-5">
              {t('table.head.IPv6') as string}
            </div>
            <div className="flex align-center mb-10">
              <div className="mr-10">
                {ipv6 ? <CopyTooltip>{ipv6}</CopyTooltip> : '-'}
              </div>
              {isPrimary && (
                <ChipOutlined status="primary" className="fs-10">
                  {t('common.primary') as string}
                </ChipOutlined>
              )}
            </div>
          </Col>
          <Col xs={2}>
            <div className="steel fs-12 mb-5">{t('subnet') as string}</div>
            <div className="flex align-center mb-10">
              <div className="mr-10 ellipsis">
                {ipv6 ? <CopyTooltip>{ipv6Subnet?.cidr}</CopyTooltip> : '-'}
              </div>
            </div>
          </Col>
          <Col xs={2}>
            <div className="steel fs-12 mb-5">{t('gateway') as string}</div>
            <div className="flex align-center 10">
              <div className="mr-10 ellipsis">
                {ipv6 && !isIsolatedNet ? (
                  <CopyTooltip>{ipv6Subnet?.gateway}</CopyTooltip>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </Col>
          <Col xs>
            <Row justifyContent="space-between" alignItems="center">
              <Col>
                {isDirectNet &&
                  (!reverseDns.dataReceived ? (
                    <Loader size={15} />
                  ) : (
                    <>
                      <div className="steel fs-12 mb-5">
                        {t('table.head.reverseDns') as string}
                      </div>

                      <SEditableText
                        name="domainName"
                        textClassName="_display"
                        text={dnsRecordV6?.domainName || ''}
                        displayText={
                          dnsRecordV6?.domainName || (
                            <span className="steel">
                              {
                                t(
                                  'services.vm.info.reverseDns.noDomain'
                                ) as string
                              }
                            </span>
                          )
                        }
                        isInactive={!ipv6}
                        alignItems="flex-start"
                        placeholder="www.example.com"
                        onSuccess={handleRecordEdit(
                          appEntityId as number,
                          ipv6,
                          dnsRecordV6
                        )}
                        // @ts-ignore
                        validate={(text: string) => {
                          const error = !validator.isFQDN(text || '');
                          return error ? t('forms.invalid.domainName') : false;
                        }}
                        isUpdating={
                          taskV6.isTaskActive || reverseDns.isRequesting
                        }
                        onDelete={
                          dnsRecordV6 &&
                          onConfirmReverseDnsDelete(dnsRecordV6.id)
                        }
                        isEditable
                      />
                    </>
                  ))}
              </Col>
              <Col>
                {!isPrimary && (
                  <Actions
                    onDetach={() => onDetach(nic)}
                    onSetAsPrimary={() => onSetAsPrimary(nic)}
                    isRequesting={isRequesting}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </SExternalIpRow>
      )}
    </div>
  );
});

const NicItem = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default NicItem;
