export enum incomingRequestType {
  requestI18n = 'requestI18n',
  requestTicket = 'requestTicket',
  requestVm = 'requestVm',
}

export enum outcomingRequestType {
  updateI18n = 'updateI18n',
  updateVm = 'updateVm',
  updateTicket = 'updateTicket',
  requestTicketError = 'requestTicketError',
}
