import { IMessageData, IMKSTicket } from 'vm-message-interface';
import { incomingRequestType, outcomingRequestType } from 'enums';
const magic = 'jbbf.ch.wmks.v1';

/**
 * This is a simple post-message channel with a given window.
 * The packets look like {magic: 'jbbf.ch.wmks.v1', type: '...', payload: ...}.
 * @param targetWindow window to post to.
 * @constructor
 */
export class MessageInterface {
  /**
   * Map of message type to array of handlers.
   */
  private handlers: any = {};
  private targetWindow: Window;

  constructor(targetWindow: Window) {
    this.targetWindow = targetWindow;

    /**
     * Bind up the message handler.
     */
    window.addEventListener('message', (event) =>
      this.onMessageReceived(event)
    );
  }

  /**
   * Handle incoming postmessage.  Will dispatch to registered handlers based on the
   * message type.
   */
  private onMessageReceived(event: MessageEvent): void {
    const origin: string = event.origin;
    const data: IMessageData = event.data;

    // Drop packets that aren't from our origin.
    // eslint-disable-next-line
    if (origin != window.location.origin) {
      console.log('Dropping unknown origin', event);
      return;
    }

    // Drop packets not matching our expected magic value.
    // eslint-disable-next-line
    if (!data || data.magic != magic) {
      console.log('Dropping unknown message', event);
      return;
    }

    console.log('Event from child window', data);
    // Get the handlers and dispatch to each one.
    const handlerList = this.handlers[event.data.type];
    if (handlerList) {
      handlerList.forEach((handler: any) => {
        handler(data.payload);
      });
    }
  }

  addHandler(type: incomingRequestType, callback: () => void) {
    const handlerList = this.handlers[type] || [];
    handlerList.push(callback);
    this.handlers[type] = handlerList;
  }

  updateI18n(translations: string[]) {
    const message = {
      magic,
      type: outcomingRequestType.updateI18n,
      payload: translations,
    };
    this.targetWindow.postMessage(message, window.location.origin);
  }

  updateVm(vm: any) {
    const message = { magic, type: outcomingRequestType.updateVm, payload: vm };
    this.targetWindow.postMessage(message, window.location.origin);
  }

  updateTicket(ticket: IMKSTicket) {
    const message = {
      magic,
      type: outcomingRequestType.updateTicket,
      payload: ticket,
    };
    this.targetWindow.postMessage(message, window.location.origin);
  }

  requestTicketError(errorMessage: string) {
    const message = {
      magic,
      type: outcomingRequestType.requestTicketError,
      payload: errorMessage,
    };
    this.targetWindow.postMessage(message, window.location.origin);
  }
}
