import React, { useMemo } from 'react';
import cn from 'classnames';
import { MuiIcons } from 'elements';
import { ALL_TEST_IDS } from 'enums';
import { useUserHash, usePermissions } from 'hooks';
import { AnyFunc } from 'global-shapes';
import { useTranslation } from 'react-i18next';
import { IAddServiceCallback } from 'services-shapes';
import { getBadgeStateKey } from 'utils/parsers';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import DropBox from 'components/DragAndDrop/DropVmComponent';
import { DedicatedServerCard } from '../contents/VirtualServer';
import {
  withDedicatedServerActions,
  InjectedProps,
} from 'hocs/withDedicatedServerActions';
import { defineDedicatedServerDisplayStatus } from '../../../helpers';
import { SVmCard } from './Styled';

type Props = IVmTypes.Vm & {
  onAdd: IAddServiceCallback;
  handleStateChange: AnyFunc;
  dataReceived: boolean;
};

type IViewProps = {
  vms: StateHandlers.IVmsHandler;
  assignments: StateHandlers.IBadgesAssignmentHandler;
};

const View = observer((props: Props & InjectedProps & IViewProps) => {
  const {
    onAdd,
    handleStateChange,
    dataReceived,
    handleDetailsOpen,
    isRequesting,
    fetchVm,
    fetchBillingInfo,
    onEdit,
    onDelete,
    menuItems,
    actionItems,
    assignments,
    vms,
    task,
    handlers,
    isInFolder,
    ...vm
  } = props;
  const { t } = useTranslation();
  const { isProvider, permissions } = usePermissions('SERVICES');
  const [, changeUrl] = useUserHash();
  const dedicatedVm = vm.dedicatedServer as IDedicatedServer.DedicatedServer;
  const shouldUseDrop = !isInFolder;

  const entityAssignments =
    assignments.data[getBadgeStateKey(vm.dedicatedServer)] || [];

  const displayStatus = useMemo(
    defineDedicatedServerDisplayStatus(
      vm.dedicatedServer as IDedicatedServer.DedicatedServer
    ),
    [vm]
  );

  const onOpenDetails = React.useCallback(
    () =>
      changeUrl(`/services/dedicated/info`, {
        id: vm.id,
      }),
    [vm.id]
  );

  const isUpdating = vms.updateIds.includes(vm.id);

  const cornerBadge: string = React.useMemo(() => {
    if (!isProvider) return '';

    if (
      !vm?.dedicatedServer?.billingCycle ||
      vm?.dedicatedServer?.billingCycle?.interval === 'NONE'
    ) {
      return t('services.content.cardBadge.free');
    }

    return '';
  }, [JSON.stringify(vm), isProvider]);

  const totalDisksSize = vm.osDiskSizeGb;
  const isDisabled = task.isTaskActive || isUpdating;

  const DropComponent = React.useMemo(() => {
    return shouldUseDrop ? DropBox : ({ children }: any) => <>{children}</>;
  }, [shouldUseDrop, isRequesting]);

  const otherTitleContent = React.useMemo(() => {
    if (!permissions.canManage) return null;

    return (
      <MuiIcons.DesktopWindowsOutlined
        className={cn('pointer fs-18 steel mr-5', {
          disabled: isRequesting,
        })}
        onClick={() => handlers.console(dedicatedVm.id)}
      />
    );
  }, [permissions.canManage, displayStatus, dedicatedVm.id, isRequesting]);

  return (
    <DropComponent vm={vm}>
      <SVmCard
        status={displayStatus}
        title={dedicatedVm.name}
        titleIcon={
          <MuiIcons.LanOutlined color="primary" className="mr-5 fs-20" />
        }
        otherTitleContent={otherTitleContent}
        onAdd={onAdd}
        placeholder="services.content.placeholder.services"
        isEmpty={false}
        onTitleClick={onOpenDetails}
        isDisabled={isDisabled}
        testId={`${ALL_TEST_IDS.services.vm.main}-${dedicatedVm.id}`}
        dataReceived={dataReceived}
        menuItems={menuItems}
        cornerBadge={cornerBadge}
        assignments={entityAssignments}
        shouldDisablePartially
      >
        <DedicatedServerCard {...vm} osDiskSizeGb={totalDisksSize} />
      </SVmCard>
    </DropComponent>
  );
});

const DedicatedServer = (props: Props & InjectedProps) => (
  <View
    {...props}
    vms={StateHandlers.vms}
    assignments={StateHandlers.badgesAssignment}
  />
);

export default withDedicatedServerActions(DedicatedServer, 'list');
