import { patchingService } from 'services';
import { BaseMobxInstanceDecorator } from './utilities';

export const patching = new BaseMobxInstanceDecorator<IServicePatching, true>({
  instanceName: 'patchings',
  requests: {
    get: patchingService.getList,
    remove: patchingService.deleteById,
  },
  initialState: [],
});

export const currentPatching = new BaseMobxInstanceDecorator<
  IServicePatching | null,
  false
>({
  instanceName: 'current-patching',
  requests: {
    get: patchingService.getById,
    update: patchingService.updateById,
    remove: patchingService.deleteById,
  },
  initialState: null,
});
