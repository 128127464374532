import * as React from 'react';
import {
  Alert,
  Button,
  Col,
  Dialog,
  DialogProps,
  PrimaryTextH5,
  PrimaryTextSpan,
  Row,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { numberToCurrency, remCalc, showSystemMessage } from 'utils';
import { usePermissions, usePrice, useState } from 'hooks';
import { CostResourceIds } from 'enums';
import ZonesInputs from './ZoneInputs';
import { getRelativeZonesPrice } from './helper';
import { RdsDialogLeftContent } from '../Styled';
import { CostInfoList } from 'pages/services/components/Styled';
import { ZonesTypes } from './types';

const OBSERVERS = {
  dns: StateHandlers.dns,
};

type IState = {
  zones: ZonesTypes.ZoneValue[];
};

type Props = DialogProps<ZonesTypes.ZoneValue[]> & {
  title: string;
  content: string;
  existingZonesCount: number;
};

type IViewProps = typeof OBSERVERS;

const INITIAL_STATE: IState = { zones: [] };

const View = observer((props: React.PropsWithChildren<Props> & IViewProps) => {
  const { onSave, content, classes, existingZonesCount, dns, ...dialogProps } =
    props;
  const { isEvaluation } = usePermissions();
  const { t } = useTranslation();
  const prices = usePrice([CostResourceIds.dnsPerDomainZone]);
  const [state, handleChange] = useState<IState>(INITIAL_STATE);
  const dnsPrice = prices[CostResourceIds.dnsPerDomainZone];

  const isNew = !dns.data;

  const onAddNewZone = React.useCallback(
    (zone: ZonesTypes.ZoneValue) =>
      handleChange({ zones: [...state.zones, zone] }),
    [state.zones.length]
  );

  const onRemoveZone = React.useCallback(
    (zones: ZonesTypes.ZoneValue[]) => handleChange({ zones }),
    [state.zones.length]
  );

  const submit = React.useCallback(() => {
    if (state.zones.length < 1)
      return showSystemMessage('costBreakdown.alert.dns.createFirst', 'info');
    return onSave && onSave(state.zones);
  }, [JSON.stringify(state.zones)]);

  React.useEffect(() => {
    if (!dialogProps.open) {
      handleChange(INITIAL_STATE);
    }
  }, [dialogProps.open]);

  const newZonesCount = state.zones.length;
  const totalZonesCount = existingZonesCount + newZonesCount;

  const zonesPrice =
    getRelativeZonesPrice(totalZonesCount, dnsPrice?.monthly) * 12;

  return (
    <Dialog
      {...dialogProps}
      fullWidth
      maxWidth="md"
      actions={
        <>
          <Button
            variant="outlined"
            color="default"
            onClick={dialogProps.onClose}
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={submit}
            disabled={dns.isRequesting || !state.zones.length}
          >
            {t(isNew ? 'common.setUp' : 'common.save')}
          </Button>
        </>
      }
    >
      <Row justifyContent="space-between" columnSpacing={2}>
        <Col xs={7} className="steel">
          <RdsDialogLeftContent>
            <div className="mb-20 fs-14 steel">{t(content)}</div>
            <ZonesInputs
              zones={state.zones}
              onAdd={onAddNewZone}
              onDelete={onRemoveZone}
            />
          </RdsDialogLeftContent>
        </Col>
        <Col xs={5}>
          <Row
            direction="column"
            justifyContent="space-between"
            columnSpacing={2}
            style={{ minHeight: remCalc(500) }}
          >
            <Col className="full-width">
              <PrimaryTextH5 className="mb-25">
                {t('costsInfo.title')}
              </PrimaryTextH5>
              <Row
                justifyContent="space-between"
                columnSpacing={2}
                className="fs-10 steel uppercase mb-15"
              >
                <Col xs={7}>
                  <span>{t('costsInfo.head.serviceName')}</span>
                </Col>
                <Col xs={2} className="text-right">
                  <span>{t('costsInfo.head.quantity')}</span>
                </Col>
                <Col xs={3} className="text-right">
                  <span>{t('costsInfo.head.price')}</span>
                </Col>
              </Row>
              <h5 className="mb-15">{t('costsInfo.dns')}</h5>
              <CostInfoList className="custom-list">
                <ul>
                  <li className="mb-10">
                    <Row>
                      <Col xs={7}>
                        <span>{t('costsInfo.dns.domains')}</span>
                      </Col>
                      <Col xs={2} className="text-right steel">
                        <span>{totalZonesCount}</span>
                      </Col>
                      <Col xs={3} className="text-right steel">
                        <span>{numberToCurrency(zonesPrice, false)}</span>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </CostInfoList>
            </Col>
            <Col className="full-width">
              {dns.data?.billingCycle?.status === 'ACTIVE' && (
                <Alert severity="info" className="mb-15">
                  {t('costsInfo.billingCircle.warnings.noExtraCharge')}
                </Alert>
              )}
              <div className="flex justify-between align-center">
                <h5 className="fs-17">{t('costsInfo.totalCost.YEAR')}</h5>
                <PrimaryTextSpan className="fs-20 bold">
                  {numberToCurrency(zonesPrice, false)}
                </PrimaryTextSpan>
              </div>
              <div className="fs-12 steel mb-10">
                {t('costsInfo.chfExclVat')}
              </div>
              {isEvaluation && (
                <Alert className="fs-12" severity="info">
                  {t('costsInfo.evalAccount.notify')}
                </Alert>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Dialog>
  );
});

const DnsSetup = (props: React.PropsWithChildren<Props>) => (
  <View {...props} {...OBSERVERS} />
);

export default DnsSetup;
