import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Checkbox } from 'elements';
import { find } from 'ramda';
import { EditableText } from 'components';
import { remCalc, validator, calculatePercentage } from 'utils';
import { IPrice } from 'pricing';
import { useState } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { desiredOrderByResource } from './constants';
import { sortPriceList, displayPriceValue } from './helper';
import { ListItemText, ResourceBlockRow, SButton, TextBadge } from './Styled';
import { IResourceBlockProps, IResourceBlockBulkProps } from './types';

const renderEditComponent = (isChanged: boolean, isRequesting: boolean) => {
  return (pr: any): any => {
    const { t } = useTranslation();
    return isChanged ? (
      <SButton
        size="small"
        variant="outlined"
        color="default"
        {...pr}
        disabled={isRequesting}
      >
        {t('common.update')}
      </SButton>
    ) : (
      <SButton {...pr} size="small" disabled={isRequesting}>
        {t('settings.page.pricing.setPrice')}
      </SButton>
    );
  };
};

function defineRequestingStatuses(handlers: IViewProps) {
  return Object.keys(handlers).some(
    // @ts-ignore
    (key: string) => handlers[key].isRequesting
  );
}

const OBSERVERS = {
  mainPricing: StateHandlers.mainPricing,
  pricingServices: StateHandlers.pricingServices,
  tenantPricing: StateHandlers.tenantPricing,
  defaultPricing: StateHandlers.defaultPricing,
  partnerPricing: StateHandlers.partnerPricing,
  currentTenantPricing: StateHandlers.currentTenantPricing,
  defaultTenantPricing: StateHandlers.defaultTenantPricing,
};

type IViewProps = typeof OBSERVERS;

const ResourceBlockView = observer(
  ({
    service,
    price,
    canEdit,
    isOnUserPricing,
    onUpdatePricing,
    onSetPrice,
    parentPricing,
    hasParentPrice,
    ...dataHandlers
  }: IResourceBlockProps & IViewProps) => {
    const { t } = useTranslation();
    const [refs, setRef] = useState<any>({});

    const isRequesting = defineRequestingStatuses(dataHandlers);

    return (
      <>
        {sortPriceList(service.resources)(desiredOrderByResource, 'id').map(
          (resource, ind) => {
            const createdPrice = (price || []).find((p) => {
              if (p.serviceId === service.id) {
                if (p.spId) return p.spId === resource.spId;
                return p.serviceResourceId === resource.id;
              }
              return undefined;
            });
            const isChanged = !!createdPrice;
            const refKey = `serv-${service.id}-${resource.id}-${
              resource.spId || ''
            }`;
            const value = +(createdPrice?.value || 0);

            const ValueElement = canEdit ? (
              <div style={{ width: remCalc(95) }}>
                <EditableText
                  testId={refKey}
                  onSuccess={(val) => {
                    if (!isChanged) {
                      return onSetPrice({
                        value: +val,
                        serviceId: service.id,
                        serviceResourceId: resource.id,
                      });
                    }
                    onUpdatePricing({
                      // @ts-ignore
                      id: createdPrice.id,
                      value: +val,
                      isHidden: false,
                    });
                  }}
                  isUpdating={isRequesting}
                  actionRowProps={{
                    className: 'justify-between',
                    style: { width: remCalc(75) },
                  }}
                  elementSize="sm"
                  displayText={displayPriceValue(value)}
                  text={`${value}`}
                  editComponent={
                    renderEditComponent(
                      isOnUserPricing
                        ? createdPrice
                          ? !createdPrice.isHidden
                          : false
                        : isChanged,
                      isRequesting
                    ) as any
                  }
                  typingRules={{
                    restrict: validator.prices,
                  }}
                  isEditable
                  editElementPortal={refs[refKey]}
                  actionsPortal={refs[refKey]}
                />
              </div>
            ) : (
              displayPriceValue(value)
            );

            const retailsPrice = parentPricing?.find((p) => {
              if (resource.spId) return resource.spId === p.spId;
              return p.serviceResourceId === resource.id;
            });

            const yourRetailPrice: number = hasParentPrice
              ? retailsPrice
                ? +retailsPrice.value
                : 0
              : 0;

            return (
              <ResourceBlockRow
                columnSpacing={0}
                alignItems="center"
                key={`${resource.id}-${ind}`}
              >
                {hasParentPrice ? (
                  <>
                    <Col xs={5}>
                      <ListItemText>
                        <div className="flex">
                          {resource.spName ? (
                            <div className="flex relative">
                              <span>{resource.spName}</span>
                              <TextBadge>
                                {t('settings.page.pricing.badges.app')}
                              </TextBadge>
                            </div>
                          ) : (
                            t(`settings.page.pricing.resources.${resource.id}`)
                          )}
                        </div>
                      </ListItemText>
                    </Col>
                    <Col xs={7} className="steel">
                      <Row columnSpacing={0}>
                        <Col xs={5}>
                          <Row>
                            <Col xs={6}>
                              ~{displayPriceValue(yourRetailPrice * 30)}
                            </Col>
                            <Col xs={6}>
                              {displayPriceValue(yourRetailPrice)}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={7}>
                          <Row columnSpacing={0} alignItems="center">
                            <Col xs={5} className="steel">
                              {value !== undefined && '~'}
                              {displayPriceValue(value * 30)}
                            </Col>
                            <Col xs={5} className="steel">
                              {ValueElement}
                            </Col>
                            {canEdit && (
                              <Col
                                xs={2}
                                className="steel flex justify-end"
                                ref={(el: any) => {
                                  if (el && !refs[refKey]) {
                                    setRef({ [refKey]: el });
                                  }
                                }}
                              />
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={7}>
                      <ListItemText>
                        <div className="flex">
                          {resource.spName ? (
                            <div className="flex relative">
                              <span>{resource.spName}</span>
                              <TextBadge>
                                {t('settings.page.pricing.badges.app')}
                              </TextBadge>
                            </div>
                          ) : (
                            t(`settings.page.pricing.resources.${resource.id}`)
                          )}
                        </div>
                      </ListItemText>
                    </Col>
                    <Col xs={5}>
                      <Row className="steel" alignItems="center">
                        <Col xs={5}>
                          {value !== undefined && '~'}
                          {displayPriceValue(value * 30)}
                        </Col>
                        <Col xs={4} className="flex align-center">
                          {ValueElement}
                        </Col>
                        {canEdit && (
                          <Col
                            xs={3}
                            className="steel flex justify-end"
                            ref={(el: any) => {
                              if (el && !refs[refKey]) {
                                setRef({ [refKey]: el });
                              }
                            }}
                          />
                        )}
                      </Row>
                    </Col>
                  </>
                )}
              </ResourceBlockRow>
            );
          }
        )}
      </>
    );
  }
);

export const ResourceBlock = (props: IResourceBlockProps) => (
  <ResourceBlockView {...props} {...OBSERVERS} />
);

export const ResourceBulkUpdateBlock = ({
  service,
  price,
  onCheck,
  checked,
  parentPricing,
  hasParentPrice,
  percentage,
}: IResourceBlockBulkProps) => {
  const { t } = useTranslation();

  const onChange = React.useCallback(
    (resourceId: number) => (on: boolean) => {
      onCheck(resourceId, on);
    },
    []
  );

  return (
    <>
      {sortPriceList(service.resources)(desiredOrderByResource, 'id').map(
        (resource, ind) => {
          const resourceId = resource.id;

          const createdPrice = find((p: IPrice) => {
            if (p.serviceId === service.id) {
              if (p.spId || resource.spId) {
                return p.spId === resource.spId;
              }
              return p.serviceResourceId === resourceId;
            }
            return false;
          })(price || []);

          const value = Number(createdPrice?.value) || 0;

          const retailsPrice = parentPricing?.find(
            (p) => p.serviceResourceId === resourceId
          );

          const currentPrice = price.find((p) => {
            if (p.spId) {
              return p.spId === createdPrice?.spId;
            }
            return p.id === createdPrice?.id;
          }) as IPrice;

          const priceId = currentPrice?.id as number;
          const isChecked = currentPrice ? checked[priceId] : false;

          const yourRetailPrice: number = hasParentPrice
            ? Number(retailsPrice?.value) || 0
            : 0;

          return (
            <ResourceBlockRow
              columnSpacing={0}
              alignItems="center"
              key={`${resourceId}-${ind}`}
            >
              {hasParentPrice ? (
                <>
                  <Col xs={5}>
                    <ListItemText>
                      <div className="flex">
                        {resource.spName ? (
                          <div className="flex relative">
                            <span>{resource.spName}</span>
                            <TextBadge>
                              {t('settings.page.pricing.badges.app')}
                            </TextBadge>
                          </div>
                        ) : (
                          t(`settings.page.pricing.resources.${resource.id}`)
                        )}
                      </div>
                    </ListItemText>
                  </Col>
                  <Col xs={6} className="steel">
                    <Row columnSpacing={0}>
                      <Col xs={5}>
                        <Row>
                          <Col xs={6}>
                            ~{displayPriceValue(yourRetailPrice * 30)}
                          </Col>
                          <Col xs={6}>{displayPriceValue(yourRetailPrice)}</Col>
                        </Row>
                      </Col>
                      <Col xs={7}>
                        <Row columnSpacing={0} alignItems="center">
                          <Col xs={5} className="steel">
                            {value !== undefined && '~'}
                            {displayPriceValue(
                              calculatePercentage(value * 30, percentage)
                            )}
                          </Col>
                          <Col xs={5} className="steel">
                            {displayPriceValue(
                              calculatePercentage(value, percentage)
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={1} className="steel">
                    <Checkbox checked={isChecked} onCheck={onChange(priceId)} />
                  </Col>
                </>
              ) : (
                <>
                  <Col xs={7}>
                    <ListItemText>
                      <div className="flex">
                        {resource.spName ? (
                          <div className="flex relative">
                            <span>{resource.spName}</span>
                            <TextBadge>
                              {t('settings.page.pricing.badges.app')}
                            </TextBadge>
                          </div>
                        ) : (
                          t(`settings.page.pricing.resources.${resource.id}`)
                        )}
                      </div>
                    </ListItemText>
                  </Col>
                  <Col xs={5}>
                    <Row className="steel" alignItems="center">
                      <Col xs={5}>
                        {value !== undefined && '~'}
                        {displayPriceValue(value * 30)}
                      </Col>
                      <Col xs={6} className="flex align-center">
                        {displayPriceValue(value)}
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </ResourceBlockRow>
          );
        }
      )}
    </>
  );
};
