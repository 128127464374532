import { ICustomer } from 'customer-shapes';
import {
  IInvoicePosition,
  IInvoicePositionType,
  IInvoicePositionUnit,
  InvoiceItemMetaInterface,
} from 'invoices';
import {
  hoursToDaysString,
  to2Fixed,
  formatServiceResourceValue,
  displayPcs,
  displayHours,
} from 'utils';
import {
  APP_SERVICES_GROUP,
  VM_BASED_APP_SERVICE_IDS,
} from 'enums/costResourceIds';
import {
  HIDE_QTY_I18N_CODES,
  // HIDE_QTY_RESOURCE_IDS,
  InvoiceItemType,
  InvoiceItemUnit,
} from 'enums/invoices';

export class InvoicePositionItemDto {
  id: number;
  invoiceId: number;
  tenantId: number;
  tenant: ICustomer;
  name?: string;
  description: string;
  i18nCode: string;
  unit: IInvoicePositionUnit;
  price: number;
  amount: number;
  qty: number;
  type: IInvoicePositionType;
  serviceResourceId: number;
  serviceResourceValue: number;
  serviceId: number;
  parentId?: number;
  subItems: InvoicePositionItemDto[];
  discountPercent: number;

  /* extra fields */
  discountRate: number;
  hasSubItems: boolean;
  isMain: boolean;
  isVmBasedService: boolean;
  isSupportBillableItem: boolean;
  isBillableItem: boolean;
  isCustomItem: boolean;
  isUsageItem: boolean;
  isHourlyBased: boolean;
  hasCostLogs: boolean;
  hasDaterange: boolean;
  isOneTime: boolean;
  isRecurring: boolean;
  usageHours?: number;
  meta?: InvoiceItemMetaInterface;
  formattedResourceValue: string;
  formattedUsageDays?: string;
  dailyPrice?: number;
  displayQty?: number | string;
  displayPrice?: number;
  hideQty: boolean;

  constructor(
    item: IInvoicePosition,
    additionalProps = {
      isSupportBI: false,
      isOneTime: false,
      isRecurring: false,
    }
  ) {
    this.id = item.id;
    this.invoiceId = item.invoiceId;
    this.tenantId = item.tenantId;
    this.name = item.name;
    this.description = item.name || item.description;
    this.i18nCode = item.i18nCode as string;
    this.unit = item.unit;
    this.discountPercent = item.discountPercent;
    this.discountRate = to2Fixed((100 - this.discountPercent) / 100);
    this.price = Number(item.price) * this.discountRate;
    this.amount = Number(item.amount);
    this.qty = Number(item.qty);
    this.type = item.type;
    this.serviceId = item.serviceId as number;
    this.hasSubItems = !!(item.subItems && item.subItems.length);
    this.isMain = this.hasSubItems;
    this.isSupportBillableItem =
      additionalProps.isSupportBI ||
      (this.serviceId === APP_SERVICES_GROUP.CustomerSupport.id &&
        this.hasSubItems);

    this.serviceResourceId = item.serviceResourceId as number;
    this.serviceResourceValue = item.serviceResourceValue as number;
    this.isOneTime = additionalProps.isOneTime || item.i18nCode === '8_oneTime';
    this.isRecurring =
      additionalProps.isRecurring || item.i18nCode === '8_recurring';
    this.parentId = item.parentId;
    this.subItems = item.subItems
      ? item.subItems.map(
          (subItem) =>
            new InvoicePositionItemDto(subItem as InvoicePositionItemDto, {
              isSupportBI: this.isSupportBillableItem,
              isOneTime: this.isOneTime,
              isRecurring: this.isRecurring,
            })
        )
      : [];
    this.tenant = item.tenant as ICustomer;

    this.isVmBasedService = VM_BASED_APP_SERVICE_IDS.includes(this.serviceId);
    this.isBillableItem = this.type === InvoiceItemType.billableItem;
    this.isCustomItem = this.type === InvoiceItemType.custom;
    this.isUsageItem = this.type === InvoiceItemType.usage;
    this.isHourlyBased = item.unit === InvoiceItemUnit.hours;
    this.meta = item.meta;
    this.hasDaterange = !!item.meta?.startDate;
    this.hasCostLogs = !!(
      item.meta &&
      item.meta.costLogs &&
      item.meta.costLogs.length
    );
    if (this.hasCostLogs) {
      this.meta?.costLogs?.forEach((costLog) => {
        const resourceValue =
          costLog.resValue !== undefined
            ? costLog.resValue
            : costLog.qty / (costLog.usageHours || 1);
        costLog.formattedResourceValue =
          resourceValue !== 1
            ? formatServiceResourceValue(costLog.resourceId, resourceValue)
            : '';
        costLog.formattedUsageDays =
          costLog.usageHours !== undefined
            ? hoursToDaysString(costLog.usageHours)
            : '';
        costLog.dailyPrice = costLog.price * 24;
      });
    }

    if ((this.isUsageItem || this.isVmBasedService) && this.isHourlyBased) {
      this.usageHours = this.qty / (this.serviceResourceValue || 1);
      this.formattedUsageDays = hoursToDaysString(this.usageHours);
      this.dailyPrice = this.price * 24;
    } else if (
      this.serviceResourceId === APP_SERVICES_GROUP.DNS.resources.Domain.id
    ) {
      this.usageHours = (this.qty / (this.serviceResourceValue || 1)) * 24;
      this.formattedUsageDays = hoursToDaysString(this.usageHours);
      this.dailyPrice = this.price;
    }

    this.displayQty = this.formattedUsageDays;

    if ((this.isUsageItem || this.isVmBasedService) && !this.isHourlyBased) {
      this.displayQty = this.qty;
    }

    this.formattedResourceValue = formatServiceResourceValue(
      this.serviceResourceId,
      this.serviceResourceValue
    );

    this.hideQty =
      // HIDE_QTY_RESOURCE_IDS.includes(item.serviceResourceId as number) ||
      HIDE_QTY_I18N_CODES.includes(item.i18nCode as string);

    this.displayPrice =
      this.dailyPrice === undefined ? this.price : this.dailyPrice;

    if (this.isCustomItem || this.isOneTime || this.isRecurring) {
      this.displayQty = this.isHourlyBased
        ? displayHours(this.qty)
        : displayPcs(this.qty);
    }
  }
}
