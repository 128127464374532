import { ButtonProps } from '@mui/base/Button/Button.types';
import { useButton } from '@mui/base/useButton';
import { styled } from 'elements/utilities';
import { remCalc } from 'utils';
import { PALETTE } from 'enums';
import * as React from 'react';
import clsx from 'clsx';

const Root = styled('button')(
  () => `
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
  line-height: 1;
  background-color: ${PALETTE.white};
  color: ${PALETTE.primaryDark};
  cursor: pointer;
  transition: all 200ms ease;
  border: 1px solid ${PALETTE.lightGrey};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${remCalc(14)};
  padding: ${remCalc(7, 10)};
  
  & svg {
    color: ${PALETTE.steel};
    font-size: ${remCalc(16)};
  }

  &:hover {
    background-color: #ededee;
  }

  &.active {
    background-color: #ededee;
  }

  &.focusVisible {
    outline: none;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: 0 0 0 0 rgba(0, 127, 255, 0);
  }
`
);

export const ActionButton = React.forwardRef(function AddIcon(
  props: ButtonProps,
  ref: React.ForwardedRef<any>
) {
  const { id, children } = props;
  const { active, focusVisible, getRootProps } = useButton({
    ...props,
    disabled: props.disabled,
    rootRef: ref,
  });

  const classes = {
    active,
    disabled: props.disabled,
    focusVisible,
  };

  return (
    <Root {...getRootProps()} className={clsx(classes)} id={id}>
      {children}
    </Root>
  );
});
