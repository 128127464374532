import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';
import DateRangePicker, {
  Props as DateRangePickerProps,
} from 'react-daterange-picker';
import { getCurrentLang, dayjs, Dayjs, remCalc, important } from 'utils';
import { useState } from 'hooks';
import { styled } from './utilities';
import { Row, Col } from './Grid';
import { MuiIcons } from './MuiIcons';
import { IconButton } from './Button';
import { default as Popover } from './Popover';
import { useTranslation } from 'react-i18next';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import { GLOBAL_DATE_FORMAT, PALETTE } from 'enums';
import { PickerLabel } from './DatePicker';
import { TimePicker } from './TimePicker';
import { DateInput } from './DateInput';
const moment = extendMoment(originalMoment as any);

type IState = {
  dateAnchor: any;
  timeAnchor: any;
};

type DisabledTimes = {
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
  disabledSeconds?: (hour: number, minute: number) => number[];
};

type Props = Omit<DateRangePickerProps, 'value'> & {
  value?: Dayjs;
  label?: React.ReactNode;
  error?: boolean;
  disabled?: boolean;
  disabledTime?: (date: Dayjs) => DisabledTimes;
  helperText?: string;
  displayClassName?: string;
  closeOnSelect?: boolean;
  showMinute?: boolean;
  showTime?: any;
  minuteStep?: number;
  onChange: (date: Dayjs) => void;
};

const DisplayWrapper = styled(
  (
    props: React.ComponentProps<'div'> & {
      isDisabled: boolean;
      hasError: boolean;
    }
  ) => {
    const { isDisabled, hasError, ...rest } = props;
    return <div {...rest} />;
  }
  // @ts-ignore
)(({ theme, isDisabled, hasError }) => {
  const errorStyles = hasError
    ? {
        backgroundColor: '#FFF0EE',
        borderColor: theme.palette.error.main,
      }
    : {};

  const disabledStyles = isDisabled
    ? {
        backgroundColor: PALETTE.pageBackground,
        borderColor: important(PALETTE.pageBackground),
        pointerEvents: 'none',
        '& ._dateInput, & ._timeInput': {
          color: PALETTE.steel,
        },
      }
    : {};

  return {
    height: remCalc(48),
    padding: remCalc(0, 5, 0, 15),
    borderRadius: remCalc(6),
    backgroundColor: theme.palette.common.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    minWidth: remCalc(150),
    fontSize: remCalc(14),
    lineHeight: 1,
    borderColor: PALETTE.lightGrey,
    ...errorStyles,
    ...disabledStyles,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '& ._dateInput': {
      lineHeight: remCalc(46),
      height: remCalc(46),
      width: remCalc(80),
    },
  };
});

const ErrorLabel = styled('div')(({ theme }) => ({
  lineHeight: remCalc(19),
  fontSize: remCalc(12),
  color: theme.palette.error.main,
  marginTop: remCalc(3),
  textAlign: 'right',
}));

const INITIAL_STATE: IState = {
  dateAnchor: null,
  timeAnchor: null,
};

const defaultProps = {
  value: dayjs(undefined, GLOBAL_DATE_FORMAT),
  showMinute: true,
};

const MuiDateTimePicker: React.FunctionComponent<Props> = (_props) => {
  const { t } = useTranslation();
  const props = { ...defaultProps, ..._props };
  const {
    minimumDate,
    maximumDate,
    disabledTime,
    showTime,
    minuteStep,
    showMinute,
  } = props;
  const [state, handleState] = useState<IState>(INITIAL_STATE);

  const value = useMemo(
    () => (props.value ? props.value.toDate() : undefined),
    [JSON.stringify(props.value)]
  ) as Date;

  const momentValue = useMemo(() => {
    if (!props.value) return undefined;
    return moment(props.value.toDate(), GLOBAL_DATE_FORMAT) || undefined;
  }, [props.value]);

  const handleDateClick = React.useCallback((event: any) => {
    handleState({ dateAnchor: event.currentTarget });
  }, []);

  const handleDateClose = React.useCallback(() => {
    handleState({ dateAnchor: null });
  }, []);

  const handleTimeClick = React.useCallback((event: any) => {
    handleState({ timeAnchor: event.currentTarget });
  }, []);

  const handleTimeClose = React.useCallback(() => {
    handleState({ timeAnchor: null });
  }, []);

  const onSelectDate = useCallback(
    (date: any) => {
      props.closeOnSelect && handleDateClose();
      props.onChange(
        dayjs(date)
          .set('hours', props.value?.get('hour') || 0)
          .set('minutes', props.value?.get('minutes') || 0)
      );
    },
    [props.closeOnSelect, props.value, props.onChange]
  );

  return (
    <div>
      {props.label && <PickerLabel>{props.label}</PickerLabel>}
      <DisplayWrapper
        hasError={!!props.error}
        isDisabled={!!props.disabled}
        className={cn('pointer', props.displayClassName)}
      >
        <Row
          justifyContent="space-between"
          alignItems="center"
          className="full-width"
          columnSpacing={2}
        >
          <Col>
            <DateInput
              className="_dateInput"
              value={dayjs(value).format(GLOBAL_DATE_FORMAT)}
              onSelect={onSelectDate}
            />
          </Col>
          <Col>
            <div onClick={handleTimeClick} className="relative _timeInput">
              {dayjs(value).format('HH:mm')}
            </div>
          </Col>
          <Col>
            <IconButton onClick={handleDateClick}>
              <MuiIcons.EventNote
                className="fs-16 steel"
                style={{ lineHeight: 1, height: remCalc(14) }}
              />
            </IconButton>
          </Col>
        </Row>
      </DisplayWrapper>
      {props.error && (
        <ErrorLabel>{t(props.helperText as string) as string}</ErrorLabel>
      )}
      <Popover
        id={'date-anchor'}
        open={!!state.dateAnchor}
        anchorEl={state.dateAnchor}
        onClose={handleDateClose}
        classes={{
          paper: 'pt-5 pl-5 pr-5 pb-20',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DateRangePicker
          firstOfWeek={1}
          numberOfCalendars={1}
          selectionType={'single'}
          singleDateRange
          value={momentValue}
          onSelect={onSelectDate}
          locale={getCurrentLang()}
          minimumDate={minimumDate}
          maximumDate={maximumDate}
        />
      </Popover>

      <Popover
        id={'time-anchor'}
        open={!!state.timeAnchor}
        anchorEl={state.timeAnchor}
        onClose={handleTimeClose}
        classes={{
          paper: 'pt-5 pb-5',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <TimePicker
          // @ts-ignore
          open
          inputReadOnly
          value={props.value}
          showSecond={false}
          disabled={props.disabled}
          disabledTime={disabledTime}
          showTime={showTime}
          showMinute={showMinute}
          // @ts-ignore
          minuteStep={minuteStep}
          onChange={(val: any) => {
            props.onChange(dayjs(val));
          }}
        />
      </Popover>
    </div>
  );
};

export default MuiDateTimePicker;
