import * as React from 'react';
import {
  Row,
  Col,
  IconButton,
  Popover,
  MuiIcons,
  List,
  ListItemButton,
} from 'elements';
import cn from 'classnames';
import { remCalc } from 'utils';
import { DragAndDropTypes } from 'enums/DnD';
import { withFolderActions, InjectedProps } from 'hocs/withFolderActions';
import DropFolderComponent from 'components/DragAndDrop/DropFolderComponent';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { FolderRowTr, FolderIconWrapper } from '../Styled';

type IProps = InjectedProps & IServiceGroups.Group;

const FolderListItem = observer((props: IProps) => {
  const { handlers, isRequesting, ...f } = props;
  const { t } = useTranslation();

  const isVm = f.type === 'VIRTUAL_SERVER';
  const acceptFolderType = isVm ? DragAndDropTypes.VM : DragAndDropTypes.DS;

  const innerVms =
    (f.type === 'DEDICATED_SERVER' ? f.dedicatedServers : f.vms) || [];

  const hasContent = !!innerVms.length;

  return (
    <DropFolderComponent
      folder={f}
      accepts={acceptFolderType}
      style={{ position: 'sticky', left: 0, width: '100%' }}
    >
      <FolderRowTr key={f.id}>
        <Row alignItems="center" justifyContent="space-between">
          <Col xs>
            <Row>
              <Col style={{ width: remCalc(215) }}>
                <Row alignItems="center" onClick={handlers.openInnerContent}>
                  <Col>
                    <FolderIconWrapper>
                      <MuiIcons.Folder color="primary" className="fs-20" />
                      <span className="_name-text">
                        {f.type === 'VIRTUAL_SERVER' ? 'VM' : 'DS'}
                      </span>
                    </FolderIconWrapper>
                  </Col>
                  <Col>{f.name}</Col>
                </Row>
              </Col>
              <Col xs className="steel lh-20" alignItems="center">
                {hasContent ? (
                  <div>
                    {t('services.card.content.folder.includes')}:{' '}
                    {innerVms.map((v) => v.name).join(', ')}
                  </div>
                ) : (
                  t('services.card.content.folder.noItems')
                )}
              </Col>
            </Row>
          </Col>
          <Col justifyItems="flex-end">
            <PopupState variant="popover">
              {(popupState) => (
                <React.Fragment>
                  <IconButton size="small" {...bindTrigger(popupState)}>
                    <MuiIcons.MoreVert className={cn('fs-18 steel pointer')} />
                  </IconButton>
                  <Popover
                    {...bindMenu(popupState)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  >
                    <List className="fs-15">
                      <ListItemButton
                        component="div"
                        disabled={isRequesting}
                        onClick={() => {
                          handlers.openInnerContent(f);
                          popupState.close();
                        }}
                      >
                        {t(`services.content.action.openInnerContent`)}
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        disabled={isRequesting}
                        onClick={() => {
                          handlers.edit(f);
                          popupState.close();
                        }}
                      >
                        {t(`common.edit`)}
                      </ListItemButton>
                      <ListItemButton
                        component="div"
                        disabled={isRequesting}
                        onClick={() => {
                          handlers.delete(f);
                          popupState.close();
                        }}
                      >
                        {t(`common.delete`)}
                      </ListItemButton>
                    </List>
                  </Popover>
                </React.Fragment>
              )}
            </PopupState>
          </Col>
        </Row>
      </FolderRowTr>
    </DropFolderComponent>
  );
});

export default withFolderActions(FolderListItem);
