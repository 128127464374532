import React, { useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import PopupState, {
  bindHover,
  bindMenu,
  bindPopover,
  bindTrigger,
} from 'material-ui-popup-state';
import { useQuery, usePermissions } from 'hooks';
import { GLOBAL_DATE_FORMAT } from 'enums';
import { dayjs } from 'utils';
import {
  Row,
  Col,
  Paper,
  CopyTooltip,
  MuiIcons,
  List,
  ListItemButton,
  Chip,
  IconButton,
  Popover,
} from 'elements';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import {
  withDedicatedServerActions,
  InjectedProps,
} from 'hocs/withDedicatedServerActions';
import DedicatedServerSystemInfo from 'components/DedicatedServerSystemInfo';
import ButtonList from 'components/ActionButtons/ButtonList';
import BillingInformation from '../BillingInformation';

const OBSERVERS = {
  snapshots: StateHandlers.snapshots,
  vm: StateHandlers.vmDetails,
  osTemplates: StateHandlers.osTemplates,
  folders: StateHandlers.folders,
};

type IProps = InjectedProps;
type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    fetchVm,
    handlers,
    actionItems,
    vm,
    osTemplates,
    task,
    isRequesting,
    isInFolder,
    folders,
  } = props;
  const { t } = useTranslation();
  const {
    permissions: { canManage: isEditable },
  } = usePermissions('SERVICES');
  const innerPopupState = usePopupState({
    variant: 'popover',
    popupId: 'innerPopover',
  });
  const { query, queryStr } = useQuery();
  const billingEnabled = React.useMemo(() => {
    if (vm.data?.dedicatedServer?.billingCycle) {
      return vm.data.dedicatedServer.billingCycle.interval !== 'NONE';
    }
    return false;
  }, [vm.data?.dedicatedServer?.billingCycle]);

  const availableFolders =
    folders.data.filter((f) => f.type === 'DEDICATED_SERVER') || [];

  const fetchVM = useCallback(
    () =>
      fetchVm({
        id: query.id,
        include: ['serviceMonitoring', 'serviceSnapshot'],
      }),
    [queryStr, fetchVm]
  );

  const isPoweredOn = vm.data?.dedicatedServer?.displayStatus === 'POWERED_ON';

  React.useEffect(() => {
    osTemplates.get('');
  }, []);

  return (
    <>
      <Paper className="p-30 mb-15">
        <Row
          className="mb-25"
          alignItems="center"
          justifyContent="space-between"
          columnSpacing={2}
        >
          <Col>
            <div className="uppercase fs-14 bolder">
              {t('services.dedicated.title')}
            </div>
          </Col>
          {isEditable && (
            <Col xs>
              <Row alignItems="center" justifyContent="flex-end">
                <Col className={cn({ disabled: task.isTaskActive })}>
                  <ButtonList actions={actionItems || []} />
                </Col>

                <Col className="pl-10 pb-5 pt-5">
                  <div className="flex justify-end full-width">
                    <PopupState
                      variant="popover"
                      popupId={`tenant-actions-${vm.data?.id}`}
                    >
                      {(popupState) => (
                        <>
                          <IconButton {...bindTrigger(popupState)} size="small">
                            <MuiIcons.MoreVert className="fs-20" />
                          </IconButton>
                          <Popover {...bindMenu(popupState)}>
                            <List>
                              {isPoweredOn && (
                                <ListItemButton
                                  onClick={() => {
                                    handlers.console(
                                      vm.data?.dedicatedServer?.id
                                    );
                                    popupState.close();
                                  }}
                                >
                                  {t(`services.content.action.console`)}
                                </ListItemButton>
                              )}

                              {!isInFolder && !!availableFolders.length && (
                                <ListItemButton {...bindHover(innerPopupState)}>
                                  <div className="flex justify-between align-center">
                                    <MuiIcons.ChevronLeft className="fs-22 steel pointer mr-5" />
                                    {t(`services.content.action.moveToFolder`)}
                                  </div>
                                </ListItemButton>
                              )}

                              {isInFolder && (
                                <ListItemButton
                                  onClick={() => {
                                    handlers.removeFromFolder();
                                    popupState.close();
                                  }}
                                >
                                  {t(
                                    `services.content.action.removeFromFolder`
                                  )}
                                </ListItemButton>
                              )}

                              <ListItemButton
                                onClick={() => {
                                  popupState.close();
                                  return handlers.reinstallOs();
                                }}
                              >
                                {t('services.content.action.reinstallOs')}
                              </ListItemButton>
                              <ListItemButton
                                onClick={async () => {
                                  popupState.close();
                                  await handlers.delete();
                                  return await fetchVM();
                                }}
                              >
                                {t('services.vm.navigation.removeService')}
                              </ListItemButton>
                            </List>
                            <HoverPopover
                              {...bindPopover(innerPopupState)}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              <List>
                                {/* @ts-ignore */}
                                {availableFolders.map((folder) => {
                                  return (
                                    <ListItemButton
                                      key={`${folder.id}-folder`}
                                      data-test-id={`${folder.id}-folder-menu-item`}
                                      disabled={isRequesting}
                                      onClick={() => {
                                        handlers.moveToFolder(folder);
                                        popupState.close();
                                        innerPopupState.close();
                                      }}
                                    >
                                      {folder.name}
                                    </ListItemButton>
                                  );
                                })}
                              </List>
                            </HoverPopover>
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <div>
          <Row
            columnSpacing={5}
            justifyContent="space-between"
            className="mb-40 fs-14"
          >
            <Col>
              <div className="mb-5 steel">{t('table.head.model')}</div>
              <div className="flex align-center">
                <div className="break-word">
                  <CopyTooltip>{vm.data?.dedicatedServer?.name}</CopyTooltip>
                </div>
                {!billingEnabled && (
                  <div className="ml-10">
                    <Chip status="info">
                      {t('services.vm.chips.billing.disabled')}
                    </Chip>
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-5 steel">
                {t('services.vm.label.creationDate')}
              </div>
              <div className="flex align-center">
                <div>
                  <CopyTooltip>
                    {dayjs(vm.data?.createdAt).format(GLOBAL_DATE_FORMAT)}
                  </CopyTooltip>
                </div>
              </div>
            </Col>
            <Col>
              <div className="mb-5 steel">{t('services.vm.label.os')}</div>
              <div>
                <CopyTooltip>
                  {`${vm.data?.os.name} ${
                    vm.data?.os.lang
                      ? `(${t(`app.lang.${vm.data.os.lang}`)})`
                      : ''
                  }`}
                </CopyTooltip>
              </div>
            </Col>
            <Col>
              <div className="mb-5 steel">{t('table.head.psu')}</div>
              <div className="flex align-center">
                <div>
                  <CopyTooltip>
                    {vm.data?.dedicatedServer?.tenantosServer?.psuCount}
                  </CopyTooltip>
                </div>
              </div>
            </Col>
            <Col>
              <div className="mb-5 steel">{t('table.head.raid')}</div>
              <div className="flex align-center">
                <div>
                  <CopyTooltip>
                    {vm.data?.dedicatedServer?.tenantosServer?.raidEnabled ? (
                      <MuiIcons.Check className="success fs-16" />
                    ) : (
                      <MuiIcons.Close className="error fs-16" />
                    )}
                  </CopyTooltip>
                </div>
              </div>
            </Col>
            <Col>
              <div className="mb-5 steel">{t('table.head.location')}</div>
              <div className="flex align-center">
                <div>
                  <CopyTooltip>
                    {vm.data?.dedicatedServer?.tenantosServer?.location}
                  </CopyTooltip>
                </div>
              </div>
            </Col>
            <Col>
              <div className="mb-5 steel">{t('table.head.networkSpeed')}</div>
              <div className="flex align-center">
                <div>
                  <CopyTooltip>
                    {vm.data?.dedicatedServer?.tenantosServer?.networkSpeed}
                  </CopyTooltip>
                </div>
              </div>
            </Col>
          </Row>

          <div className="mb-40">
            <DedicatedServerSystemInfo
              {...(vm.data.dedicatedServer
                ?.tenantosServer as IDedicatedServer.TenantosServer)}
            />
          </div>

          <div className="mb-15 fs-14">
            <div className="mb-5 steel">
              {t('services.vm.label.description')}
            </div>
            <div className="break-line-all">
              {vm.data?.dedicatedServer?.description || '-'}
            </div>
          </div>
        </div>
      </Paper>

      {!!vm.data?.dedicatedServer?.billingCycleId && (
        <BillingInformation id={vm.data.dedicatedServer?.billingCycleId} />
      )}
    </>
  );
});

const VmInfo = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default withDedicatedServerActions(VmInfo, 'single');
