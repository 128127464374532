import { getVmDetailsValidationSchema } from './helpers';
import * as Yup from 'yup';
import { validator } from 'utils';
import {
  generateInitialPlanState,
  BILLING_CIRCLE_OPTIONS,
  backupNamespaces,
  backupNotificationNamespaces,
} from 'enums';

export const DEFAULT_VM_DETAILS_VALUES: Partial<VmDialogTypes.IVmBaseInfoFormValues> =
  {
    isPublic: false,
    guestOSPassword: '',
    templateId: undefined,
    networkId: undefined,
    virtualCpus: 1,
    ramMb: 1,
    osDiskSizeGb: 40,
    name: '',
    description: '',
  };

export enum FormSteps {
  vmDetails = 0,
  disks = 1,
  services = 2,
  backup = 3,
}

export const INITIAL_VM_FORM_VALUES: Partial<VmDialogTypes.IVmFormValues> = {
  billingCycleInterval: BILLING_CIRCLE_OPTIONS[0],
  ...DEFAULT_VM_DETAILS_VALUES,
  isPublic: false,
  joinDomain: false,
  isUsageEnabled: true,
  disks: [],
  ports: [],
  isBackupEnabled: false,
  ...generateInitialPlanState(),
  backupEmails: [],
  isFailedNotification: false,
  isSuccessNotification: false,
  isWarningNotification: false,
};

export const VM_INFO_VALIDATION_SCHEMAS: { [field: number]: any } = {
  [FormSteps.vmDetails]: getVmDetailsValidationSchema,
  [FormSteps.disks]: Yup.object().shape({
    name: Yup.string(),
    sizeGb: Yup.string(),
    disks: Yup.array(Yup.object()),
  }),
  [FormSteps.services]: Yup.object().shape({
    name: Yup.string(),
    port: Yup.string(),
    ports: Yup.array(Yup.object()),
  }),
  [FormSteps.backup]: Yup.object().shape({
    isBackupEnabled: Yup.boolean().when({
      is: true,
      then: Yup.boolean().test({
        message: 'services.backup.notify.create.noPlanSelected',
        test: function () {
          const isSelectedSomePlan = backupNamespaces.some(
            (plan) => !!this.parent[plan]
          );
          return isSelectedSomePlan;
        },
      }),
    }),
    backupEmails: Yup.array().test({
      message: 'services.backup.notifications.empty.alert.title',
      test: function (val) {
        const isSelectedSomePlan = backupNamespaces.some(
          (plan) => !!this.parent[plan]
        );
        if (this.parent.isBackupEnabled && isSelectedSomePlan) {
          const isSelectedSomeNotification = backupNotificationNamespaces.some(
            (n) => !!this.parent[n]
          );

          if (isSelectedSomeNotification && !val?.length) {
            return false;
          }
        }
        return true;
      },
    }),
  }),
};

export const getAdditionalDiskValidSchema = (
  initValues?: IVmTypes.VMDisk | null
) => {
  const isNew = !initValues;
  const currentSizeGb = initValues ? initValues.sizeGb : 0;
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('forms.required')
      .max(30, 'forms.invalid.max')
      .test({
        message: 'forms.invalid',
        test: (val) => validator.diskName(val || ''),
      }),
    sizeGb: Yup.string()
      .trim()
      .test({
        message: 'forms.invalid.onlyIncrease',
        test: (value) => (isNew ? true : +(value || 0) >= currentSizeGb),
      })
      .test({ message: 'forms.required', test: (value) => +(value || 0) > 0 }),
  });
};

export const mediaFormValidation = Yup.object().shape({
  selected: Yup.object().required('media.dialog.errors.selectRequired'),
});

export const attachNetworkFormValidation = Yup.object().shape({
  selected: Yup.object().required('media.dialog.errors.selectRequired'),
});
