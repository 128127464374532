import * as React from 'react';
import { Paper, Row, Col, Button, Chip } from 'elements';
import { useTranslation } from 'react-i18next';
import { sum } from 'ramda';
import { dayjs, numberToCurrency } from 'utils';
import cn from 'classnames';
import { useBillingCycle } from 'hooks';
import { withPermissions, InjectedPermissionsProps } from 'hocs';
import { GLOBAL_DATE_FORMAT } from 'enums';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { chipStatusMap } from './constants';
import { BillingContent } from './Styled';

const OBSERVERS = {
  dns: StateHandlers.dns,
};

type Props = InjectedPermissionsProps;
type IViewProps = typeof OBSERVERS;

const View = observer((props: React.PropsWithChildren<Props> & IViewProps) => {
  const { dns, permissions } = props;
  const { t } = useTranslation();
  const { cycle, isRequesting, isTerminated, terminate } = useBillingCycle(
    dns.data?.billingCycleId,
    'billingCycle.dns'
  );

  if (!cycle) {
    return null;
  }

  const amount = cycle.billableItems
    ? sum(cycle.billableItems.map((bi: any) => +bi.amount || 0))
    : 0;

  const interval = cycle.pendingInterval || cycle.interval;

  return (
    <Paper className={cn('pt-20 pb-20 mb-5', { disabled: isRequesting })}>
      <BillingContent>
        <div className="fs-14 uppercase bolder mb-10">
          {t('billingCycle.title') as string}
        </div>
      </BillingContent>
      <Row
        className="full-width"
        alignItems="center"
        justifyContent="space-between"
        columnSpacing={2}
      >
        <Col className="flex align-center" xs={4}>
          <BillingContent>
            <div className="fs-12 steel mb-5">
              {t('table.head.billingCycle') as string}
            </div>
            <div className="capitalize flex align-center">
              <span className="mr-10">
                {t(`billingCycle.interval.${interval}`) as string}
              </span>
              {/* @ts-ignore */}
              <Chip status={chipStatusMap[cycle.status]}>
                {t(`billingCycle.status.${cycle.status}`)}
              </Chip>
            </div>
          </BillingContent>
        </Col>
        <Col xs={4}>
          <Row justifyContent="space-between" columnSpacing={2}>
            <Col xs={6}>
              <div className="fs-12 steel mb-5">
                {t(`table.head.billingCycle.priceTotal.${interval}`) as string}
              </div>
              <div className="uppercase">{numberToCurrency(amount, false)}</div>
            </Col>
            <Col xs={6}>
              <div className="fs-12 steel mb-5">
                {t('table.head.startDate') as string}
              </div>
              <div className="uppercase">
                {dayjs(cycle.periodStart).format(GLOBAL_DATE_FORMAT)}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs>
          <Row justifyContent="space-between" columnSpacing={2}>
            <Col>
              <div className="fs-12 steel mb-5">
                {t('table.head.nextRenewal') as string}
              </div>
              <div className="uppercase">
                {dayjs(cycle.periodEnd).format(GLOBAL_DATE_FORMAT)}
              </div>
            </Col>
            <Col className="pr-20">
              {permissions.canManage && cycle.status !== 'PENDING' && (
                <Button
                  size="small"
                  color={isTerminated ? 'default' : 'primary'}
                  variant={isTerminated ? 'outlined' : 'contained'}
                  onClick={() =>
                    terminate(isTerminated)
                      .then(() => {
                        dns.get();
                      })
                      .catch(() => undefined)
                  }
                >
                  {t(
                    isTerminated
                      ? 'billingCycle.buttons.undoTerminate'
                      : 'billingCycle.buttons.terminate'
                  )}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Paper>
  );
});

const ZoneItem = (props: React.PropsWithChildren<Props>) => (
  <View {...props} {...OBSERVERS} />
);

export default withPermissions(ZoneItem, 'SERVICES');
