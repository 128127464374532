import { ResourceQuota } from 'quotas';
import { IQuotas } from './types';

export function calculateTotals(quotas: ResourceQuota): IQuotas.ICalculated {
  const used = { ...quotas.used, ramMb: quotas.used.ramMb / 1024 };
  const remaining = {
    ...quotas.remaining,
    ramMb: quotas.remaining.ramMb / 1024,
  };

  return {
    ...quotas,
    used,
    remaining,
    total: {
      virtualCpus: quotas.used.virtualCpus + quotas.remaining.virtualCpus,
      ramMb: used.ramMb + remaining.ramMb,
      diskSizeGb: quotas.used.diskSizeGb + quotas.remaining.diskSizeGb,
    },
  };
}

export function defineLimitColor(
  current: number,
  total: number
): IQuotas.LimitColor {
  let color: IQuotas.LimitColor = '';
  const percentage = (current / total) * 100;

  if (percentage >= 80) {
    color = 'warning';
  }

  if (percentage >= 90) {
    color = 'error';
  }

  return color;
}
