import * as React from 'react';
import { Dialog, DialogProps, Button, DatePicker } from 'elements';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { Dayjs } from 'dayjs';
import { dayjs } from 'utils';
import {
  extendEvalValidationSchema,
  INITIAL_EXTEND_EVAL_VALUES,
} from './constants';
import { defineExpirationDays } from './helpers';

type IProps = React.PropsWithChildren<
  DialogProps<{ date: Dayjs }> & {
    isSaving: boolean;
    partner?: IPartnerTypes.Partner;
  }
>;

const Renderer = (props: IProps) => {
  const { open, onClose, onSave, isSaving, partner } = props;

  const { values, resetForm, setFieldValue, handleSubmit, setValues, errors } =
    useFormik({
      initialValues: INITIAL_EXTEND_EVAL_VALUES,
      validationSchema: extendEvalValidationSchema,
      onSubmit: (val) => Promise.resolve(onSave && onSave(val)),
    });

  React.useEffect(() => {
    if (!open) {
      resetForm();
    } else {
      setValues({ date: dayjs(partner?.evaluationExpiresAt).endOf('day') });
    }
  }, [open]);

  return (
    <Dialog
      title={t('partners.table.confirm.extend.title')}
      open={open}
      onClose={onClose}
      handleSubmit={handleSubmit}
      actions={
        <>
          <Button variant="outlined" color="default" onClick={onClose}>
            {t('common.no')}
          </Button>
          <Button type="submit" className="ml-15" disabled={isSaving}>
            {t('common.yes')}
          </Button>
        </>
      }
    >
      <div className="mb-25">
        {
          t('partners.table.confirm.extend.content', {
            name: partner?.name,
            count: defineExpirationDays(values.date),
          }) as string
        }
      </div>
      <div className="half-width">
        <DatePicker
          closeOnSelect={false}
          value={values.date}
          label={t('forms.date') as string}
          error={!!errors.date}
          helperText={errors.date as string}
          minimumDate={dayjs().add(1, 'day').toDate()}
          maximumDate={dayjs().add(30, 'day').toDate()}
          onChange={(newDate) => setFieldValue('date', newDate)}
        />
      </div>
    </Dialog>
  );
};

const ExtendEvaluationAccountDialog = withTranslation()(Renderer);

export default ExtendEvaluationAccountDialog;
