import * as React from 'react';
import { Button, Input, Select, Row, Col } from 'elements';
import { withTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import { useFormik } from 'formik';
import { ALL_TEST_IDS } from 'enums';
import { getCountries } from 'utils';
import {
  customerCompanyValidationSchema,
  ICompanyFormValues,
} from './constants';
import FormStepper from './FormStepper';

type IProps = React.PropsWithChildren<
  WithT & {
    innerContent: any;
    isSubmitting: boolean;
    showStepper: boolean;
    activeStep: number;
    initialValues: ICompanyFormValues;
    onSubmit: (val: ICompanyFormValues) => void;
  }
>;

const Renderer = (props: IProps) => {
  const {
    t,
    showStepper,
    activeStep,
    innerContent,
    initialValues,
    isSubmitting,
    onSubmit,
  } = props;

  const countries = React.useMemo(getCountries, []);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    submitCount,
    setFieldValue,
    isValidating,
    submitForm,
  } = useFormik({
    initialValues,
    validationSchema: customerCompanyValidationSchema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit,
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mb-40">
          <FormStepper
            showStepper={showStepper}
            handleNext={() => submitForm()}
            activeStep={activeStep}
          />
        </div>
        {innerContent}
        <Input
          label={t('forms.companyName')}
          placeholder={t('forms.placeholders.companyName')}
          value={values.name}
          name="name"
          id="name"
          className="mb-25"
          onChange={handleChange}
          helperText={errors.name}
          helperTextOptions={{max: 30}}
          error={!!submitCount && !!errors.name}
          testId={ALL_TEST_IDS.tenantSignUp.inputs}
        />
        <Input
          label={t('forms.signUp.tenant.shortname')}
          placeholder={t('forms.placeholders.shortname')}
          value={values.shortName}
          name="shortName"
          id="shortName"
          className="mb-50"
          onChange={handleChange}
          helperText={errors.shortName}
          helperTextOptions={{max: 16}}
          error={!!submitCount && !!errors.shortName}
          testId={ALL_TEST_IDS.tenantSignUp.inputs}
        />
        <div className="uppercase fs-14 bolder mb-20">
          {t('auth.registration.tenant.address') as string}
        </div>
        <Input
          label={t('forms.address1')}
          placeholder={t('forms.placeholders.address1')}
          value={values.line1}
          name="line1"
          className="mb-25"
          onChange={handleChange}
          helperText={errors.line1}
          helperTextOptions={{max: 60}}
          error={!!submitCount && !!errors.line1}
          testId={ALL_TEST_IDS.tenantSignUp.inputs}
        />
        <Input
          label={t('forms.address2')}
          value={values.line2}
          name="line2"
          placeholder={t('forms.placeholders.address1')}
          onChange={handleChange}
          helperText={errors.line2}
          helperTextOptions={{max: 60}}
          error={!!submitCount && !!errors.line2}
          testId={ALL_TEST_IDS.tenantSignUp.inputs}
          className="mb-25"
        />
        <Row columnSpacing={2} className="mb-25">
          <Col xs={6}>
            <Input
              label={t('forms.zipcode')}
              value={values.zipCode}
              name="zipCode"
              placeholder={t('forms.placeholders.zipcode')}
              onChange={handleChange}
              error={!!submitCount && !!errors.zipCode}
              helperText={errors.zipCode}
              testId={ALL_TEST_IDS.tenantSignUp.inputs}
            />
          </Col>
          <Col xs={6}>
            <Input
              label={t('forms.city')}
              value={values.city}
              name="city"
              placeholder={t('forms.placeholders.city')}
              onChange={handleChange}
              error={!!submitCount && !!errors.city}
              helperText={errors.city}
              testId={ALL_TEST_IDS.tenantSignUp.inputs}
            />
          </Col>
        </Row>
        <Select
          label={t('forms.country') as string}
          value={values.countryCode || undefined}
          name="countryCode"
          placeholder={t('forms.placeholders.country') as string}
          onChange={(val: any) => setFieldValue('countryCode', val)}
          options={countries}
          error={!!submitCount && !!errors.countryCode}
          helperText={errors.countryCode}
          testId={ALL_TEST_IDS.tenantSignUp.inputs}
          className="mb-30"
        />
        <Button
          testId={ALL_TEST_IDS.tenantSignUp.submit}
          size="large"
          fullWidth
          disabled={isSubmitting || isValidating}
          className="mb-20"
          type="submit"
        >
          {t('auth.registration.button.submit')}
        </Button>
      </form>
    </>
  );
};

const CustomerCompanyInfoForm = withTranslation()(Renderer);

export default CustomerCompanyInfoForm;
