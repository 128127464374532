import React from 'react';
import cn from 'classnames';
import { Button, Dialog, DialogProps, Input, Row, Select } from 'elements';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { AnyFunc } from 'global-shapes';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { IMigrationEndpointFormValues } from 'file-shares';
import { EXPIRATION_OPTIONS, migrationEndpointFormSchema } from './constants';
import { validateCallback, validator } from 'utils';

interface IProps extends DialogProps<IMigrationEndpointFormValues> {
  onClose: AnyFunc;
  initialValues?: IMigrationEndpointFormValues;
  isNew?: boolean;
  idDisabledSubmit: boolean;
  testId?: string;
}

const INITIAL_STATE: IMigrationEndpointFormValues = {
  sourceWANIp: '',
  localSourcePath: '',
  expireIn: EXPIRATION_OPTIONS[1],
};

const OBSERVERS = {
  meta: StateHandlers.meta,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    onSave,
    open,
    isNew,
    onClose,
    testId,
    initialValues,
    idDisabledSubmit,
    meta,
    ...rest
  } = props;
  const { t } = useTranslation();

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    handleChange,
    resetForm,
    setValues,
    submitCount,
  } = useFormik({
    initialValues: initialValues || {
      ...INITIAL_STATE,
      sourceWANIp: meta.data.ip || '',
    },
    validationSchema: migrationEndpointFormSchema,
    onSubmit: async (val: IMigrationEndpointFormValues) =>
      onSave &&
      onSave({
        ...val,
        // @ts-ignore
        expireIn: +val.expireIn?.value,
      }),
  });

  React.useEffect(() => {
    if (!open) {
      resetForm();
    }
    if (open && initialValues) {
      setValues({
        ...initialValues,
        localSourcePath: initialValues.localSourcePath.replace('\\\\', ''),
        // @ts-ignore
        expireIn: EXPIRATION_OPTIONS.find(
          (opt) => opt.value === '' + initialValues.expireIn
        ),
      });
    }
  }, [open]);

  return (
    <Dialog
      {...rest}
      open={open}
      handleSubmit={handleSubmit}
      onClose={onClose}
      testId={testId}
      fullWidth
      actions={
        <Row justifyContent="flex-end">
          <Button
            className="mr-15"
            color="default"
            variant="outlined"
            onClick={onClose}
            testId={`${testId}-cancel`}
          >
            {t('common.cancel')}
          </Button>
          <Button
            type="submit"
            disabled={idDisabledSubmit}
            testId={`${testId}`}
          >
            {t(isNew ? 'common.continue' : 'common.save')}
          </Button>
        </Row>
      }
    >
      <div className="mb-20 steel">
        {t('services.core.file-shares.dialog.migration.subtitle')}
      </div>
      <Input
        name="sourceWANIp"
        label="forms.sourceWanIp"
        value={values.sourceWANIp}
        placeholder="forms.placeholders.sourceWanIp"
        error={!!submitCount && !!errors.sourceWANIp}
        helperText={errors.sourceWANIp}
        onChange={handleChange}
        testId="sourceWANIp"
        className="mb-20"
      />
      <Input
        name="localSourcePath"
        label="forms.localSourcePath"
        value={values.localSourcePath}
        placeholder="forms.placeholders.localSourcePath"
        inputClassName="lh-default"
        error={!!submitCount && !!errors.localSourcePath}
        helperText={errors.localSourcePath}
        onChange={validateCallback({ restrict: validator.slash })(handleChange)}
        testId="localSourcePath"
        inputProps={{ className: cn('pt-0 pb-0 pl-0 lh-default') }}
        startAdornment={<span className="pl-10">\\</span>}
        className={cn('mb-20 lh-default')}
      />
      <Select
        name="expireIn"
        label={t('forms.expiration')}
        value={values.expireIn}
        placeholder={t('forms.placeholders.select')}
        options={EXPIRATION_OPTIONS}
        error={!!submitCount && !!errors.expireIn}
        helperText={errors.expireIn}
        onChange={(val) => setFieldValue('expireIn', val)}
        testId="expire"
      />
    </Dialog>
  );
});

const MigrationEndpointDialog = (props: IProps) => (
  <View {...props} {...OBSERVERS} />
);

export default MigrationEndpointDialog;
