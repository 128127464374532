import { BaseMobxInstanceDecorator } from './utilities';
import {
  ITaskManagerMappedListState,
  ITaskManagerOptionsState,
} from 'task-manager-service';
import { taskManagerService } from 'services';
import { buildListParams } from 'utils/global';
import { reformatTaskKey, reformatTaskOptionKey } from 'utils/taskSubscriber';
import { BaseDecorator } from './types';

const mapMainResponse = (tasks: Task[]): ITaskManagerMappedListState => {
  const res: ITaskManagerMappedListState = {};

  tasks.forEach((t) => {
    res[reformatTaskKey(t)] = t;
  });

  return res;
};

const mapOptionsResponse = (tasks: ITaskOption[]): ITaskManagerOptionsState => {
  const res: ITaskManagerOptionsState = {};

  tasks.forEach((t) => {
    res[reformatTaskOptionKey(t)] = t;
  });

  return res;
};

export const taskManagerList = new BaseMobxInstanceDecorator<Task, true>({
  instanceName: 'tasksList',
  requests: {
    get: async () =>
      taskManagerService
        .get({ orderBy: 'createdAt', orderType: 'asc' })
        .then((res) => {
          const reversed = res.data
            .reverse()
            .filter((task: Task) => !task.isBackground);
          res.data = reversed;
          return res;
        }),
  },
  initialState: [],
});

export type ITaskManagerListHandler = BaseDecorator<Task, true>;

export const taskManagerOptions = new BaseMobxInstanceDecorator<
  ITaskManagerOptionsState,
  false
>({
  instanceName: 'taskOptions',
  requests: {
    get: async () => {
      const res = await taskManagerService.getOptions();
      return { data: mapOptionsResponse(res.data) };
    },
  },
  initialState: {},
});

export type ITaskManagerOptionsHandler = BaseDecorator<
  ITaskManagerOptionsState,
  false
>;

export const taskManager = new BaseMobxInstanceDecorator<
  ITaskManagerMappedListState,
  false
>({
  instanceName: 'taskManager',
  requests: {
    get: async () => {
      const res = await taskManagerService.get(
        buildListParams(
          {
            orderBy: 'createdAt',
            orderType: 'desc',
            perPage: 1000,
          },
          ['orderBy', 'orderType']
        )
      );
      taskManagerList.setData(res.data.filter((task) => !task.isBackground));
      return { data: mapMainResponse(res.data), pagination: res.pagination };
    },
  },
  initialState: {},
});

export type ITaskManagerHandler = BaseDecorator<
  ITaskManagerMappedListState,
  false
>;

export const scheduledTasks = new BaseMobxInstanceDecorator<
  IScheduledTasks.Task,
  true
>({
  instanceName: 'scheduledTasks',
  requests: {
    get: async () =>
      taskManagerService.getScheduledTasks(
        buildListParams(
          {
            orderBy: 'createdAt',
            orderType: 'desc',
            perPage: 1000,
          },
          ['orderBy', 'orderType']
        )
      ),
    create: async (payload: IScheduledTasks.CreatePayload) =>
      taskManagerService.createScheduledTasks(payload),
    remove: async (id: number) => taskManagerService.removeScheduledTasks(id),
  },
  initialState: [],
});
