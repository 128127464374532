import * as React from 'react';
import * as Translations from 'i18next';
import { withTranslation, Trans } from 'react-i18next';
import { IBackup } from 'backup';
import { backupNamespaces, generateInitialPlanState } from 'enums';
import { parseBackupToForm } from './helpers';

type IProps = Translations.WithT &
  React.PropsWithChildren<{ backup: IBackup | null }>;

const Renderer = (props: IProps) => {
  const { t, backup } = props;

  const state = React.useMemo(() => {
    return backup ? parseBackupToForm(backup) : generateInitialPlanState();
  }, [JSON.stringify(backup)]);

  const textValues = React.useMemo(() => {
    return {
      every: state.dailyHours?.value,
      from: state.dailyFrom?.value,
      keep: state.dailyRetention?.value,
      weekDay: state.weeklyDay?.value,
      keepWeeks: state.weeklyWeeks?.value,
      keepMonths: state.monthlyMonths?.value,
    };
  }, [
    JSON.stringify([
      state.dailyHours,
      state.dailyFrom,
      state.dailyRetention,
      state.weeklyDay,
      state.weeklyWeeks,
      state.monthlyMonths,
    ]),
  ]);

  if (!backup) return null;

  return (
    <div>
      {backupNamespaces.map((key) => {
        if (!state[key]) return null;
        return (
          <div key={key} className="mb-20 fs-14">
            <span className="bolder pr-5">
              {t(`services.backup.plans.type.${key}`) as string}:
            </span>
            <Trans
              i18nKey={`services.backup.plans.options.${key}`}
              values={textValues}
              components={{
                1: <span className="primary" />,
                2: <span className="primary" />,
                3: <span className="primary" />,
                4: <span className="primary" />,
                5: <span className="primary" />,
                6: <span className="primary" />,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const BackupPlansInfo = withTranslation()(Renderer);

export default BackupPlansInfo;
