import React from 'react';
import * as R from 'ramda';
import {
  AddIcon,
  Button,
  Col,
  Dialog,
  DialogProps,
  IconButton,
  MuiIcons,
  Row,
  Select,
} from 'elements';
import { useStateHandler } from 'hooks';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { countries, remCalc, showSystemMessage } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { IRdsGeoipAccessPayload } from 'rds';
import { parseInitialGeoipValuesToForm } from './helpers';

type Props = DialogProps<IRdsGeoipAccessPayload>;

const GeoipAccessDialog = observer((props: Props) => {
  const { open, onSave, onClose } = props;
  const { t } = useTranslation();
  const rds = useStateHandler(StateHandlers.rds);

  const form = useFormik({
    initialValues: parseInitialGeoipValuesToForm(rds.data?.sourceCountryCodes),
    onSubmit: async (val) => {
      try {
        const sourceCountryCodes = val.countries.map((c) => c.value);
        await rds.executeRequest('setGeoipRestriction')({ sourceCountryCodes });
        await rds.get({});
        onSave && (await onSave({ sourceCountryCodes }));
        onClose();
      } catch (e: any) {
        showSystemMessage(e.message, 'error');
      }
    },
  });

  React.useEffect(() => {
    if (open) {
      form.setValues(
        parseInitialGeoipValuesToForm(rds.data?.sourceCountryCodes)
      );
    } else {
      form.resetForm();
    }
  }, [open, rds.data?.sourceCountryCodes]);

  return (
    <Dialog
      open={open}
      fullWidth
      handleSubmit={form.handleSubmit}
      title={t('services.rds.users.dialog.limitAccess.title')}
      onClose={onClose}
      onSave={() => undefined}
      PaperProps={{
        style: {
          maxWidth: remCalc(480),
        },
      }}
      actions={
        <>
          <Button color="default" variant="outlined" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" disabled={form.isSubmitting}>
            {t('common.save')}
          </Button>
        </>
      }
    >
      <div className="steel fs-14 mb-25">
        {t('services.rds.users.dialog.limitAccess.content')}
      </div>
      <Row className="mb-25" alignItems="flex-end" columnSpacing={2}>
        <Col xs={10}>
          <Select
            label={t('forms.geoip')}
            options={R.difference(countries, form.values.countries)}
            onChange={(val) => form.setFieldValue('country', val)}
          />
        </Col>
        <Col xs>
          <AddIcon
            className="mb-5"
            disabled={!form.values.country || form.values.countries.length >= 5}
            onClick={() =>
              form.setFieldValue('countries', [
                ...form.values.countries,
                form.values.country,
              ])
            }
          />
        </Col>
      </Row>
      <div className="fs-12 steel mb-15">{t('table.head.country')}</div>
      {form.values.countries.length ? (
        form.values.countries.map((country) => {
          return (
            <Row
              justifyContent="space-between"
              alignItems="center"
              columnSpacing={2}
              className="mb-5"
            >
              <Col>
                {country.label}, {country.value}
              </Col>
              <Col>
                <IconButton
                  onClick={() =>
                    form.setFieldValue(
                      'countries',
                      form.values.countries.filter(
                        (c) => c.value !== country.value
                      )
                    )
                  }
                >
                  <MuiIcons.Delete className="fs-18 steel" />
                </IconButton>
              </Col>
            </Row>
          );
        })
      ) : (
        <div className="pt-10 pb-10 steel fs-14">{t('common.noData')}</div>
      )}
    </Dialog>
  );
});

export default GeoipAccessDialog;
