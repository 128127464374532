import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Row,
  Col,
  MuiIcons,
  List,
  ListItemButton,
  Popover,
  IconButton,
  Alert,
} from 'elements';
import { useState, useAsync, usePermissions, usePrice } from 'hooks';
import { CostResourceIds } from 'enums';
import { useFormik } from 'formik';
import { coreInfService, rdsService } from 'services';
import { confirm, showSystemMessage } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import EnableDialog from './EnableDialog';
import ManageBillingDialog from './ManageBillingDialog';
import { buildInitialState, patchingValidationSchema } from './constants';
import { parseNextRebootField, isDirtyForm } from './helpers';
import { patchingMainSelectors } from './selectors';
import Form from './Form';
import { EmptyHolder, MainContentWrapper } from './Styled';

type IProps = {
  serviceType: 'Core' | 'Rds' | 'Vm';
  fetchBaseService: () => Promise<any>;
};

const OBSERVERS = {
  core: StateHandlers.core,
  rds: StateHandlers.rds,
  currentPatching: StateHandlers.currentPatching,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const { serviceType, fetchBaseService, core, rds, currentPatching } = props;
  const { t } = useTranslation();
  const prices = usePrice([CostResourceIds.patchingManagement]);
  const { permissions, isProvider, isPartner } = usePermissions('SERVICES');

  const showManageSystemButton =
    !!prices[CostResourceIds.patchingManagement].daily;

  const [state, handleState] = useState<IPatchingTypes.IState>({
    enableDialogOpen: false,
    billingDialogOpen: false,
  });

  const instance = React.useMemo(() => {
    if (serviceType === 'Core') {
      return core.data;
    }
    if (serviceType === 'Rds') {
      return rds.data;
    }
  }, [serviceType, core.data, rds.data]);

  const {
    hasPatching,
    patchingInitialValues,
    patchingOutManagementInitialValues,
    maintenanceWindow,
    isEditable,
    outsourceManagementEnabled,
  } = patchingMainSelectors({
    instance,
    currentPatching: currentPatching.data,
    isProvider,
    isPartner,
    permissions: permissions,
  });

  const serviceRequest = React.useMemo(() => {
    if (serviceType === 'Core') {
      return coreInfService.setPatching;
    }

    if (serviceType === 'Rds') {
      return rdsService.setPatching;
    }

    return rdsService.setPatching;
  }, [serviceType]);

  const { execute, isPending } = useAsync(serviceRequest as any);

  const handleDialogOpen = React.useCallback(
    (dialog: 'enableDialogOpen' | 'billingDialogOpen', open: boolean) => () => {
      handleState({ [dialog]: open });
    },
    []
  );

  const fetchPatching = React.useCallback(() => {
    if (instance?.servicePatching?.id) {
      return currentPatching.get(instance?.servicePatching?.id);
    }
  }, [instance?.servicePatching?.id]);

  const confirmDelete = React.useCallback(() => {
    confirm({
      title: t('patching.confirm.delete.title'),
      content: t('patching.confirm.delete.content'),
      onSuccess: () => {
        return currentPatching.remove(currentPatching.data?.id).then(() => {
          fetchBaseService();
          currentPatching.reset();
        });
      },
      onCancel: () => undefined,
    });
  }, [JSON.stringify(currentPatching.data)]);

  const onEnablePatching = React.useCallback(
    async (values: IPatchingTypes.Values) => {
      try {
        await execute(values);
        await fetchPatching();
        await fetchBaseService();
      } catch (er: any) {
        showSystemMessage(er.message, 'error');
      }
      handleState({ enableDialogOpen: false, billingDialogOpen: false });
      return values;
    },
    [fetchPatching, fetchBaseService, execute]
  );

  const onEnableOutsourceManagement = React.useCallback(
    async (values: IServicePatchingTypes.IUpdatePayload) => {
      try {
        await currentPatching.update(currentPatching.data?.id, values);
        await fetchBaseService();
        await fetchPatching();
        handleState({ enableDialogOpen: false, billingDialogOpen: false });
        return values;
      } catch (error: any) {
        showSystemMessage(error.message, 'error');
      }
    },
    [currentPatching.data?.id]
  );

  const form = useFormik({
    initialValues: patchingInitialValues,
    validationSchema: patchingValidationSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: onEnablePatching,
  });

  React.useEffect(() => {
    fetchPatching();
  }, [instance?.servicePatching?.id]);

  React.useEffect(() => {
    if (currentPatching.data) {
      form.setValues(buildInitialState(currentPatching.data));
    }
  }, [JSON.stringify(currentPatching.data)]);

  React.useEffect(() => {
    return () => {
      currentPatching.reset();
    };
  }, []);

  const isDirty = React.useMemo(
    () =>
      isDirtyForm(
        buildInitialState(currentPatching.data as IServicePatching),
        form.values
      ),
    [JSON.stringify(currentPatching.data), JSON.stringify(form.values)]
  );

  const isRequesting = currentPatching.isRequesting || isPending;

  if (!hasPatching) {
    return (
      <EmptyHolder
        alignItems="center"
        justifyContent="center"
        className={cn({ disabled: isRequesting })}
      >
        <div className="text-center _content">
          <div className="steel mb-25">{t('patching.empty.content')}</div>
          {permissions.canManage && (
            <Button onClick={handleDialogOpen('enableDialogOpen', true)}>
              {t('patching.buttons.manage')}
            </Button>
          )}
        </div>
        <EnableDialog
          open={state.enableDialogOpen}
          onClose={handleDialogOpen('enableDialogOpen', false)}
          onSave={onEnablePatching}
        />
      </EmptyHolder>
    );
  }

  return (
    <div className={cn({ disabled: isRequesting })}>
      <Row alignItems="center" justifyContent="space-between" className="mb-20">
        <Col className="uppercase bolder">{t('patching.title')}</Col>
        {permissions.canManage && (
          <Col>
            <Row
              columnSpacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              {showManageSystemButton && (
                <Col>
                  <Button onClick={handleDialogOpen('billingDialogOpen', true)}>
                    {t('patching.buttons.billingSettings')}
                  </Button>
                </Col>
              )}
              <Col>
                <PopupState variant="popper">
                  {(popupState) => (
                    <>
                      <IconButton size="small" {...bindTrigger(popupState)}>
                        <MuiIcons.MoreVert className="fs-20 steel pointer" />
                      </IconButton>
                      <Popover
                        {...bindMenu(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <List>
                          <ListItemButton
                            data-test-id={`pathing-item-delete`}
                            disabled={isRequesting}
                            onClick={() => {
                              confirmDelete();
                              popupState.close();
                            }}
                          >
                            {t(`common.remove`)}
                          </ListItemButton>
                        </List>
                      </Popover>
                    </>
                  )}
                </PopupState>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <div className="mb-25 steel">
        {t('patching.description', {
          date: parseNextRebootField(
            maintenanceWindow?.startAt,
            maintenanceWindow?.recurringInterval
          ),
        })}
      </div>
      <MainContentWrapper>
        {outsourceManagementEnabled && (
          <Alert severity="info" contentClassName="fs-12" className="mb-15">
            {t('patching.alerts.hasOutsourceManagement')}
          </Alert>
        )}
        <Form
          formInstance={form}
          isEditable={isEditable}
          showOutsourceManagement={false}
        />
      </MainContentWrapper>
      <Button
        disabled={isRequesting || isDirty || !permissions.canManage}
        onClick={() => form.submitForm()}
      >
        {t('common.save')}
      </Button>
      <ManageBillingDialog
        initialValues={patchingOutManagementInitialValues}
        open={state.billingDialogOpen}
        onClose={handleDialogOpen('billingDialogOpen', false)}
        onSave={onEnableOutsourceManagement}
      />
    </div>
  );
});

const Main = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default Main;
