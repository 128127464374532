import * as React from 'react';
import { Emitter } from 'utils';
import { IActionHandlers, ActionOption } from 'components/ActionButtons/types';
import { useState, useStateHandler } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import * as StateHandlerHelpers from 'states/helpers';
import { CustomEvents } from 'enums';
import { IExtendedActualTask } from 'task-manager-service';
import DedicatedServerDialog from 'pages/vmList/DedicatedServers/DedicatedServerDialog';
import ReinstallOsDialog from 'pages/vmList/DedicatedServers/ReinstallOsDialog';

export type InjectedProps = {
  handleDetailsOpen: (open: boolean) => void;
  isRequesting: boolean;
  isInFolder: boolean;
  fetchVm: any;
  fetchBillingInfo: any;
  onEdit: any;
  onDelete: any;
  task: IExtendedActualTask;
  menuItems?: ActionOption[];
  actionItems?: ActionOption[];
  handlers: IActionHandlers;
};

type IState = {
  initialValues: any;
  detailsDialogOpen: boolean;
  reinstallDialogOpen: boolean;
  isDetailsPage: boolean;
  dedicatedServer: null | IDedicatedServer.DedicatedServer;
  vmId: number;
};

const initial: IState = {
  initialValues: undefined,
  detailsDialogOpen: false,
  reinstallDialogOpen: false,
  dedicatedServer: null,
  vmId: 0,
  isDetailsPage: false,
};

export default observer((props: any) => {
  const vm = useStateHandler(StateHandlers.vmDetails);
  const dedicatedServers = useStateHandler(StateHandlers.dedicatedServers);
  const vms = useStateHandler(StateHandlers.vms);
  const dnd = useStateHandler(StateHandlers.dnd);

  const [state, handleState] = useState<IState>(initial);

  const { vmId, dedicatedServer, isDetailsPage, initialValues } = state;

  const reloadCurrentVm = React.useCallback(
    (id: number) => {
      if (isDetailsPage) {
        return vm.get({
          id,
          include: ['serviceMonitoring', 'serviceSnapshot', 'billingCycle'],
          isDedicatedServer: true,
        });
      }
      return vms.reload(id, {
        include: ['serviceMonitoring', 'serviceSnapshot', 'billingCycle'],
      });
    },
    [isDetailsPage, vmId]
  );

  const refetchServer = React.useCallback((action: string, id: number) => {
    if (action === 'delete') return StateHandlerHelpers.fetchVms();
    return reloadCurrentVm(id);
  }, []);

  const handleReinstallOpen = React.useCallback(
    (open: boolean) => (payload: Partial<IState>) => {
      let newState: Partial<IState> = {
        ...payload,
        reinstallDialogOpen: open,
      };

      if (!open) {
        newState = initial;
      }

      dnd.merge({ isDialogOpen: open });

      handleState(newState);
    },
    []
  );

  const handleDetailsOpen = React.useCallback(
    (open: boolean) => (payload: Partial<IState>) => {
      let newState: Partial<IState> = {
        ...payload,
        detailsDialogOpen: open,
      };

      if (!open) {
        newState = initial;
      }

      dnd.merge({ isDialogOpen: open });

      handleState(newState);
    },
    []
  );

  React.useEffect(() => {
    Emitter.on(CustomEvents.reinstallOs, handleReinstallOpen(true));
    Emitter.on(CustomEvents.editDS, handleDetailsOpen(true));

    return () => {
      Emitter.off(CustomEvents.reinstallOs, handleReinstallOpen(false));
      Emitter.off(CustomEvents.editDS, handleDetailsOpen(false));
    };
  }, []);

  return (
    <>
      <ReinstallOsDialog
        open={state.reinstallDialogOpen}
        onClose={() => {
          dnd.merge({ isDialogOpen: false });
          handleState(initial);
        }}
        vmId={dedicatedServer?.id as number}
        initialValues={initialValues}
        selectedServer={dedicatedServer?.tenantosServer}
        onSave={async (payload) => {
          const res = await dedicatedServers.executeRequest('reinstallOs')(
            dedicatedServer?.id,
            {
              osPassword: payload.osPassword,
              templateId: payload.templateId,
              billingCycleInterval: payload.billingCycleInterval,
            }
          );

          await refetchServer('none', vmId);

          handleReinstallOpen(false)({});

          return res;
        }}
      />

      <DedicatedServerDialog
        open={state.detailsDialogOpen}
        onClose={() => {
          dnd.merge({ isDialogOpen: false });
          handleState({
            detailsDialogOpen: false,
            initialValues: null,
          });
        }}
        initialValues={initialValues}
        selectedServer={dedicatedServer?.tenantosServer}
        onSave={async (newServer) => {
          if (newServer.reinstall) {
            await dedicatedServers.executeRequest('reinstallOs')(
              dedicatedServer?.id,
              {
                osPassword: newServer.osPassword,
                templateId: newServer.templateId,
              }
            );
          }

          const res = await dedicatedServers.update(dedicatedServer?.id, {
            name: newServer.name,
            description: newServer.description,
            billingCycleInterval: newServer.billingCycleInterval,
          });

          await refetchServer('none', vmId);

          dnd.merge({ isDialogOpen: false });
          handleState({
            detailsDialogOpen: false,
            initialValues: null,
          });

          return res;
        }}
      />
    </>
  );
});
