import * as React from 'react';
import * as Translations from 'i18next';
import * as R from 'ramda';
import cn from 'classnames';
import Input from '@mui/material/Input';
import { MuiIcons } from 'elements';
import { withTranslation } from 'react-i18next';
import Drawer from '../Drawer';
import InputAdornment from '@mui/material/InputAdornment';
import {
  drawerContainer,
  FILTER_OPTIONS,
  DEFAULT_SEARCH_ENTITY_QUERY,
} from '../constants';
import { debounce, toggleArrayState } from 'utils';
import { useAsync, useState } from 'hooks';
import * as StateHandlers from 'states';
import { searchEntityService } from 'services';
import SearchResults from './SearchResults';
import EntityFilterChip from './EntityFilterChip';
import ChipListPopover from '../../ChipListPopover';
import { SPrimaryText } from './Styled';
import { flattfyEntries, groupEntities, shouldBeFetched } from '../helpers';

const INITIAL_STATE: ISearchEntity.IState = {
  search: '',
  filterAnchor: null,
  // @ts-ignore
  entities: {},
  filterEntities: [],
};

type IProps = Translations.WithT &
  React.PropsWithChildren<{
    open: boolean;
    isEvaluation: boolean;
    onClose: () => void;
  }>;

const Renderer = (props: IProps) => {
  const { t, open, onClose, isEvaluation } = props;

  const searchInput = React.useRef<HTMLInputElement>(null);

  const accessLevel = StateHandlers.currentUser.data?.accessLevel;

  const { execute, params, isPending } = useAsync(
    (params: ISearchEntity.RequestParams) =>
      searchEntityService.getEntities({ groupByEntity: true, ...params })
  );

  const [state, handleState] = useState<ISearchEntity.IState>(INITIAL_STATE);

  const parsePayload = React.useCallback(
    (res: ISearchEntity.RequestResponse) => {
      handleState({ entities: groupEntities(res.aggregation, accessLevel) });
    },
    [accessLevel]
  );

  const handleSearch = React.useCallback(
    debounce((q?: string, shouldReset?: boolean) => {
      const payload: ISearchEntity.Query & Partial<ISearchEntity.TableParams> =
        {
          q,
          perPage: 20,
          entity: flattfyEntries(state.filterEntities, accessLevel),
        };

      const resetState = shouldBeFetched(payload);

      if (resetState) {
        return handleState({ entities: {} });
      }

      execute(payload)
        .then(parsePayload)
        .then(() =>
          handleState({
            query: shouldReset ? DEFAULT_SEARCH_ENTITY_QUERY : payload,
          })
        );
    }, 500),
    [JSON.stringify([state.filterEntities, params]), accessLevel]
  );

  const handleOpenFilter = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      handleState({ filterAnchor: event.currentTarget });
    },
    []
  );

  const handleClose = React.useCallback(() => {
    handleState({ filterAnchor: null });
  }, []);

  const handleFilterChange = (newEntities?: ISearchEntity.FilterType[]) => {
    const payload: ISearchEntity.Query & Partial<ISearchEntity.TableParams> = {
      perPage: 20,
      q: state.search,
      entity: flattfyEntries(
        newEntities as ISearchEntity.FilterType[],
        accessLevel
      ),
    };

    const resetState = shouldBeFetched(payload);

    if (resetState) {
      return handleState({ entities: {}, filterEntities: newEntities });
    }

    handleState({
      filterEntities: newEntities,
      query: payload,
    });

    execute(payload).then(parsePayload);
  };

  React.useEffect(() => {
    if (!open) {
      handleState(INITIAL_STATE);
    } else {
      searchInput.current?.focus();
    }
  }, [open]);

  return (
    <Drawer
      container={drawerContainer}
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onClose}
      isEvaluation={isEvaluation}
      classes={{
        paper: cn('pl-30 pr-30 pt-40 pb-40'),
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div className="mb-10">
        <Input
          id="search"
          value={state.search}
          fullWidth
          className="search-input"
          inputProps={{ ref: searchInput }}
          onChange={(ev) => {
            handleState({ search: ev.target.value });
            handleSearch(ev.target.value);
          }}
          startAdornment={
            <InputAdornment position="start">
              <MuiIcons.Search className="fs-18 ml-10" />
            </InputAdornment>
          }
          endAdornment={
            state.search && (
              <InputAdornment position="start">
                <MuiIcons.Clear
                  className="pointer fs-18 error"
                  onClick={() => {
                    handleState({ search: '' });
                    handleSearch('');
                  }}
                />
              </InputAdornment>
            )
          }
        />
      </div>
      <div className="mb-30">
        <div className="flex align-center fs-12 ">
          <div className="steel mr-15">
            {t('entitySearch.input.filter.label') as string}:
          </div>
          <SPrimaryText
            className="uppercase flex align-center pointer"
            onClick={handleOpenFilter}
          >
            <div className="mr-5">
              {t('entitySearch.input.filter.placeholder') as string}
            </div>
            {state.filterAnchor ? (
              <MuiIcons.KeyboardArrowUp className="fs-14" />
            ) : (
              <MuiIcons.KeyboardArrowDown className="fs-14" />
            )}
          </SPrimaryText>
          <ChipListPopover
            translationHash="entitySearch.type"
            anchorEl={state.filterAnchor}
            onClose={handleClose}
            selected={state.filterEntities}
            options={R.keys(FILTER_OPTIONS[accessLevel])}
            onSelect={handleFilterChange as any}
          />
        </div>
        <div className="flex align-center">
          {state.filterEntities.map((parameter) => (
            <EntityFilterChip
              key={parameter}
              parameter={parameter}
              onClear={() =>
                handleFilterChange(
                  toggleArrayState(state.filterEntities, parameter)
                )
              }
            />
          ))}
        </div>
      </div>

      <SearchResults
        accessLevel={accessLevel}
        searchStr={state.search}
        entities={state.entities as ISearchEntity.EntityState}
        isLoading={isPending}
      />
    </Drawer>
  );
};

const SearchEntityDrawer = withTranslation()(Renderer);

export default SearchEntityDrawer;
