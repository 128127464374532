import * as React from 'react';
import cn from 'classnames';
import { Checkbox, Tooltip, createColumnHelper } from 'elements';
import { TFunction } from 'react-i18next';
import { dayjs, isArray } from 'utils';
import {
  PATCHING_INTERVAL_OPTIONS,
  PATCHING_INTERVAL_TEXT_MAP,
} from 'components/Patching/constants';

type ITableProps = {
  t: TFunction;
  onCheck: (tenantId: string) => void;
  isAllSelected: boolean;
  onSelectAll: () => void;
  values: IBulkScheduleUpdateDialog.IFormValues;
};

export const SCHEDULE_TIME_FORMAT = 'D MMMM YYYY HH:mm';

const column = createColumnHelper<IPartialTenantWithInstanceMW>();

export function getColumns(props: ITableProps) {
  const { t, onCheck, values } = props;
  return [
    column.accessor('name', {
      header: (
        <Checkbox
          label={t('tenants.table.head.tenantName')}
          checked={props.isAllSelected}
          onCheck={props.onSelectAll}
        />
      ),
      disableSort: true,
      size: 150,
      cell: ({
        row: { original: tenant },
      }: ICell<IPartialTenantWithInstanceMW>) => {
        return (
          <Checkbox
            label={tenant.name}
            checked={values.applied.includes(tenant.uniqId)}
            onCheck={() => onCheck(tenant.uniqId)}
          />
        );
      },
    }),

    column.accessor('shortName', {
      header: t('table.head.shortName'),
      disableSort: true,
      cell: ({
        row: { original: tenant },
      }: ICell<IPartialTenantWithInstanceMW>) => {
        return <>{tenant.shortName}</>;
      },
    }),

    column.accessor('uniqId', {
      header: t('table.head.existingSchedule'),
      disableSort: true,
      size: 130,
      cell: ({
        row: { original: tenant },
      }: ICell<IPartialTenantWithInstanceMW>) => {
        if (!tenant.existingSchedule) return '-';

        const isMultipleSchedule = isArray(tenant.existingSchedule);

        if (
          isMultipleSchedule &&
          !(tenant.existingSchedule as IMaintenanceWindow[]).length
        ) {
          return '-';
        }

        const currentSchedule = (
          isMultipleSchedule
            ? // @ts-ignore
              tenant.existingSchedule[0]
            : tenant.existingSchedule
        ) as IMaintenanceWindow;
        const currentInterval = currentSchedule?.recurringInterval || 'SINGLE';

        const interval = PATCHING_INTERVAL_TEXT_MAP[currentInterval];

        if (isMultipleSchedule) {
          return (
            <Tooltip
              placement="top"
              arrow
              title={(tenant.existingSchedule as IMaintenanceWindow[]).map(
                (mw) => (
                  <div key={mw?.appEntityId} className="pb-5 pt-5">{`${t(
                    PATCHING_INTERVAL_TEXT_MAP[
                      mw?.recurringInterval || 'SINGLE'
                    ]
                  )}: ${dayjs(mw?.startAt).format(SCHEDULE_TIME_FORMAT)}`}</div>
                )
              )}
            >
              <span>
                {t(interval)}:{' '}
                {dayjs(currentSchedule?.startAt).format(SCHEDULE_TIME_FORMAT)}
              </span>
            </Tooltip>
          );
        }

        return (
          <span>
            {t(interval)}:{' '}
            {dayjs(currentSchedule.startAt).format(SCHEDULE_TIME_FORMAT)}
          </span>
        );
      },
    }),

    column.accessor('instanceId', {
      header: t('table.head.result'),
      disableSort: true,
      size: 170,
      cell: ({
        row: { original: tenant },
      }: ICell<IPartialTenantWithInstanceMW>) => {
        const errors = tenant.conflicts?.instanceErrors?.errors;
        const hasError = !!errors?.length;
        return (
          <span className={cn({ error: hasError })}>
            {hasError
              ? tenant.conflicts?.instanceErrors?.errors
                  .map((er) => t(er))
                  .join(', ')
              : t('tenants.dialog.bulkSchedule.content.newScheduleApply')}
          </span>
        );
      },
    }),
  ];
}

export const OPERATION_OPTIONS: ValueType<IBulkScheduleUpdateDialog.IOperation>[] =
  [
    {
      label: 'tenants.dialog.bulkSchedule.options.core',
      value: 'core',
    },
    {
      label: 'tenants.dialog.bulkSchedule.options.rdsPatching',
      value: 'rdsPatching',
    },
    {
      label: 'tenants.dialog.bulkSchedule.options.rdsReboot',
      value: 'rdsReboot',
    },
  ];

export const INITAL_VALUES = {
  applied: [],
  operation: OPERATION_OPTIONS[0],
  recurring: PATCHING_INTERVAL_OPTIONS[0],
  startDate: dayjs().add(1, 'days').set('hours', 4).set('minutes', 0),
  alertEmailRecipient: '',
  informUsers: false,
  replaceRebootRules: false,
  selected: [],
};
