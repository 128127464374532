import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useUserHash } from 'hooks';
import { shouldDisableNextStep } from './helpers';
import { ALL_TEST_IDS, LANG_MAP, LANGUAGES, TestPrefixes } from 'enums';
import { getCountries } from 'utils';
import {
  Button,
  Checkbox,
  Col,
  DialogTab,
  DialogTabs,
  Input,
  Row,
  Select,
  MuiIcons,
  Tooltip,
} from 'elements';
import { companyInfoSchema, userSchema } from './constants';
import { STabPaner, SkipText, SDialog } from './Styled';

type Props = {
  isNew: boolean;
  isRequesting: boolean;
  open: boolean;
  initialValues: IPartnerTypes.PartnerFormValues | null;
  onClose: () => void;
  onSubmit: (role: IPartnerTypes.PartnerFormValues) => any;
};

const initials: IPartnerTypes.PartnerFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  lang: LANG_MAP.de,
  shortName: '',
  companyName: '',
  line1: '',
  line2: '',
  zipCode: '',
  city: '',
  countryCode: { value: 'CH', label: 'Switzerland' },
  isEvaluationAccount: false,
  type: 'PRODUCTION',
};

const PartnerDetailsDialog = (props: Props) => {
  const { isNew, initialValues, onSubmit, isRequesting, ...rest } = props;
  const [tabValue, setTab] = React.useState(0);
  const [hash] = useUserHash();
  const { t } = useTranslation();
  const countries = React.useMemo(getCountries, []);
  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    setFieldValue,
    submitCount,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: initials,
    validationSchema: tabValue === 0 ? companyInfoSchema : userSchema,
    validateOnMount: false,
    onSubmit: (val: IPartnerTypes.PartnerFormValues) => {
      if (tabValue === 1) {
        return Promise.resolve(
          onSubmit({
            ...val,
            shouldUserValidate: true,
          })
        );
      }
      setTab(1);
    },
  });
  const isDisabledNextStep = useMemo(
    () => shouldDisableNextStep(errors),
    [JSON.stringify(errors)]
  );

  React.useEffect(() => {
    if (rest.open) setValues(initialValues || initials);
    if (!rest.open) {
      resetForm();
      setTab(0);
    }
  }, [rest.open]);

  const handleTabChange = useCallback((event: any, newValue: any) => {
    setTab(newValue);
  }, []);

  const testId = useMemo(
    () => (isNew ? TestPrefixes.createPartner : TestPrefixes.editPartner),
    [isNew]
  );

  return (
    <SDialog
      {...rest}
      title={t(`partners.dialog.title.${isNew ? 'create' : 'edit'}`)}
      PaperProps={{ className: '_paper' }}
      fullWidth
      handleSubmit={handleSubmit}
      testId={ALL_TEST_IDS.partners.dialog.paper}
      actions={
        <Row justifyContent="flex-end" columnSpacing={2} rowSpacing={2}>
          <Col>
            <Button
              variant="outlined"
              color="default"
              onClick={props.onClose}
              testId={ALL_TEST_IDS.partners.dialog.cancel}
            >
              {t('common.cancel')}
            </Button>
          </Col>
          {!isDisabledNextStep && tabValue === 1 && (
            <Col>
              <Button
                testId={ALL_TEST_IDS.partners.dialog.skip}
                disabled={isRequesting}
                onClick={() =>
                  onSubmit({
                    ...values,
                    shouldUserValidate: false,
                  })
                }
              >
                {t('partners.dialog.skipButton')}
              </Button>
            </Col>
          )}
          {tabValue === 0 && (
            <Col>
              <Button type="submit" testId={ALL_TEST_IDS.partners.dialog.next}>
                {t('common.next')}
              </Button>
            </Col>
          )}
          {tabValue === 1 && (
            <Col>
              <Button
                testId={ALL_TEST_IDS.partners.dialog.submit}
                type="submit"
                disabled={isRequesting}
              >
                {t('common.save')}
              </Button>
            </Col>
          )}
        </Row>
      }
    >
      <DialogTabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="ant example"
      >
        <DialogTab
          index={0}
          label={t('partners.dialog.tab.partner')}
          testId={testId}
        />
        <DialogTab
          index={1}
          disabled={isDisabledNextStep}
          label={t('partners.dialog.tab.account')}
          testId={testId}
        />
      </DialogTabs>

      <STabPaner index={0} value={tabValue} testId={testId}>
        <Input
          label={t('forms.companyName')}
          value={values.companyName}
          name="companyName"
          placeholder={t('partners.dialog.field.placeholder.company')}
          className="mb-25"
          onChange={handleChange}
          helperText={errors.companyName}
          error={!!submitCount && !!errors.companyName}
          helperTextOptions={{ max: 30 }}
          testId={testId}
        />
        <Input
          value={values.line1}
          name="line1"
          label={t('forms.address1')}
          placeholder={t('partners.dialog.field.placeholder.address1')}
          className="mb-25"
          onChange={handleChange}
          helperTextOptions={{ max: 60 }}
          helperText={errors.line1}
          error={!!submitCount && !!errors.line1}
          testId={testId}
        />
        <Input
          value={values.line2}
          name="line2"
          label={t('forms.address2')}
          placeholder={t('partners.dialog.field.placeholder.address2')}
          className="mb-25"
          onChange={handleChange}
          helperText={errors.line2}
          helperTextOptions={{ max: 60 }}
          error={!!submitCount && !!errors.line2}
          testId={testId}
        />
        <Row columnSpacing={2}>
          <Col xs={5}>
            <Input
              value={values.zipCode}
              name="zipCode"
              label={t('forms.zipcode')}
              placeholder={t('partners.dialog.field.placeholder.zipcode')}
              className="mb-25"
              onChange={handleChange}
              error={!!submitCount && !!errors.zipCode}
              helperText={errors.zipCode}
              helperTextOptions={{ max: 30 }}
              testId={testId}
            />
          </Col>
          <Col xs={7}>
            <Input
              value={values.city}
              name="city"
              label={t('forms.city')}
              placeholder={t('partners.dialog.field.placeholder.city')}
              className="mb-25"
              onChange={handleChange}
              error={!!submitCount && !!errors.city}
              helperText={errors.city}
              helperTextOptions={{ max: 60 }}
              testId={testId}
            />
          </Col>
        </Row>
        <Select
          label={t('forms.country')}
          value={values.countryCode || undefined}
          name="countryCode"
          placeholder={t('partners.dialog.field.placeholder.country')}
          className="mb-25"
          onChange={(val: any) => setFieldValue('countryCode', val)}
          options={countries}
          error={!!submitCount && !!errors.countryCode}
          helperText={errors.countryCode}
          testId={testId}
        />
        <Input
          label={t('forms.shortname', { shortName: hash }).replace(
            '&#x2F;',
            '/'
          )}
          value={values.shortName}
          name="shortName"
          placeholder={t('partners.dialog.field.placeholder.shortname')}
          className="mb-25"
          onChange={handleChange}
          error={!!submitCount && !!errors.shortName}
          helperText={errors.shortName}
          helperTextOptions={{ min: 3, max: 16 }}
          testId={testId}
        />
        <Checkbox
          label={t('partners.dialog.field.evaluations')}
          checked={values.type === 'EVALUATION'}
          testId={testId}
          onCheck={(val: boolean) =>
            setFieldValue('type', val ? 'EVALUATION' : 'PRODUCTION')
          }
        />
      </STabPaner>
      <STabPaner index={1} value={tabValue} testId={testId}>
        <Row columnSpacing={2}>
          <Col xs={6}>
            <Input
              label={t('forms.firstName')}
              value={values.firstName}
              name="firstName"
              placeholder={t('forms.firstName')}
              className="mb-25"
              onChange={handleChange}
              error={!!submitCount && !!errors.firstName}
              helperText={errors.firstName}
              helperTextOptions={{ max: 30 }}
              testId={testId}
              labelIcon={
                <sup>
                  <Tooltip
                    title={t('forms.sups.name')
                      .split(';')
                      .map((el) => (
                        <div key={el}>- {el}</div>
                      ))}
                  >
                    <MuiIcons.InfoOutlined className="fs-12 primary ml-5" />
                  </Tooltip>
                </sup>
              }
            />
          </Col>
          <Col xs={6}>
            <Input
              label={t('forms.lastName')}
              value={values.lastName}
              name="lastName"
              placeholder={t('forms.lastName')}
              className="mb-25"
              onChange={handleChange}
              error={!!submitCount && !!errors.lastName}
              helperText={errors.lastName}
              helperTextOptions={{ max: 30 }}
              testId={testId}
              labelIcon={
                <sup>
                  <Tooltip
                    title={t('forms.sups.name')
                      .split(';')
                      .map((el) => (
                        <div key={el}>- {el}</div>
                      ))}
                  >
                    <MuiIcons.InfoOutlined className="fs-12 primary ml-5" />
                  </Tooltip>
                </sup>
              }
            />
          </Col>
        </Row>
        <Input
          label={t('forms.email')}
          value={values.email}
          name="email"
          placeholder={t('forms.placeholders.email')}
          className="mb-25"
          onChange={handleChange}
          error={!!submitCount && !!errors.email}
          helperText={errors.email}
          testId={testId}
        />
        <Select
          label={t('forms.language')}
          value={values.lang}
          name="lang"
          placeholder={t('forms.language')}
          className="mb-25"
          options={LANGUAGES}
          onChange={(val: any) => setFieldValue('lang', val)}
          error={!!submitCount && !!errors.lang}
          helperText={errors.lang}
          testId={testId}
        />
        <SkipText>{t('partners.dialog.skipMessage')}</SkipText>
      </STabPaner>
    </SDialog>
  );
};

export default PartnerDetailsDialog;
