import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { dayjs } from 'utils';
import { GLOBAL_DATE_FORMAT } from 'enums';
import { LightTooltip, Col, Row, MuiIcons, styled } from 'elements';
import { ISnapshot } from 'snapshots';

export const InfoIcon = styled(MuiIcons.Info)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const ButtonList = (props: { snapshot?: ISnapshot }) => {
  const { snapshot } = props;
  const { t } = useTranslation();

  if (!snapshot) return null;

  return (
    <>
      <Row alignItems="center">
        <Col>
          {
            t('services.vm.label.snapshot.titleWithCreated', {
              day: dayjs(snapshot.createdAt).format(GLOBAL_DATE_FORMAT),
              time: dayjs(snapshot.createdAt).format('HH:mm:ss'),
            }) as string
          }
        </Col>
        {snapshot.description && (
          <LightTooltip title={snapshot.description} placement="right" arrow>
            <InfoIcon className="fs-20 ml-5" />
          </LightTooltip>
        )}
      </Row>
      {snapshot.removalOn && (
        <Row alignItems="center">
          <Col>
            {
              t('services.vm.label.snapshot.autoremovalLabel', {
                day: dayjs(snapshot.removalOn).format(GLOBAL_DATE_FORMAT),
                time: dayjs(snapshot.removalOn).format('HH:mm:ss'),
              }) as string
            }
          </Col>
        </Row>
      )}
    </>
  );
};

export default ButtonList;
