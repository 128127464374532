import * as React from 'react';
import { ICurrentUserVmDisplayType } from 'auth-shapes';
import { useStateHandler, useAccount } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import FolderListItemCombined from './FolderListItemCombined';

const FolderList = observer(() => {
  const [account] = useAccount();
  const folders = useStateHandler(StateHandlers.folders);

  const vmDisplayType = account?.uiServiceConfig?.viewType;

  return (
    <>
      {folders.data.map((f) => (
        <FolderListItemCombined
          key={f.id}
          vmDisplayType={vmDisplayType as ICurrentUserVmDisplayType}
          {...f}
        />
      ))}
    </>
  );
});

export default FolderList;
