import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';
import {
  useQuery,
  useTask,
  useServiceStatus,
  useUserHash,
  usePermissions,
} from 'hooks';
import LeftBarNavigation from 'components/LeftBarNavigation';
import { useTranslation } from 'react-i18next';
import { Row, Col, Loader, Alert, CopyTooltip } from 'elements';
import { VM_DETAILS_NAV_ITEMS_PARAMS } from 'enums';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { DEDICATED_SERVER_DETAILS_NAV_ITEMS } from 'pages/services/constants';
import { StatusDot } from 'pages/services/components/StatusIdentifier';
import { findIndexRoute } from '../helpers';

const OBSERVERS = {
  costBreakdown: StateHandlers.costBreakdown,
  tenantPricing: StateHandlers.tenantPricing,
  vmDetails: StateHandlers.vmDetails,
  forwardedPorts: StateHandlers.forwardedPorts,
  connectivity: StateHandlers.connectivity,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const {
    costBreakdown,
    tenantPricing,
    vmDetails,
    forwardedPorts,
    connectivity,
  } = props;
  const { t } = useTranslation();
  const { permissionsAll } = usePermissions();
  const location = useLocation();
  const [hash, changeUrl] = useUserHash();

  const { query } = useQuery();

  const fetchVM = useCallback(
    (id: string) =>
      vmDetails.get({
        id,
        include: ['billingCycle', 'serviceMonitoring', 'serviceSnapshot'],
        isDedicatedServer: true,
      }),
    []
  );

  const fetchAllData = React.useCallback(async () => {
    if (query.id) {
      await fetchVM(query.id);
    }
    await tenantPricing.get();
    await costBreakdown.get();
    await connectivity.get();
    await forwardedPorts.get({
      perPage: 1000,
      page: 1,
    });
  }, [query.id]);

  const { status } = useServiceStatus(
    vmDetails.data?.dedicatedServer?.displayStatus as string
  );
  const task = useTask(vmDetails.data?.dedicatedServer?.task, {
    onComplete: async () => {
      if (query.id) {
        await costBreakdown.get();
        await tenantPricing.get();
        return fetchVM(query.id);
      }
    },
  });
  const isPublic = vmDetails.data?.networkType === 'PUBLIC';
  const isWindows = vmDetails.data?.os?.osFamilyId === 1;

  const ipAddresOfPublicVm = vmDetails.data.nics?.find((n) => n.isPrimary);

  const NAV_ITEMS = useMemo(
    () =>
      DEDICATED_SERVER_DETAILS_NAV_ITEMS({
        permissions: permissionsAll,
      }),
    [isPublic, isWindows, permissionsAll]
  );

  React.useEffect(() => {
    fetchAllData();
  }, [query.id]);

  React.useEffect(
    () => () => {
      vmDetails.reset();
    },
    []
  );

  const isOnValidRoute = findIndexRoute(hash, location);

  React.useEffect(() => {
    if (!isOnValidRoute) {
      changeUrl('/services/dedicated/info');
    }
  }, [isOnValidRoute]);

  if (!query.id) {
    return (
      <h1 className="text-center p-20">{t('services.vm.noVmSelected')}</h1>
    );
  }

  if (
    !vmDetails.dataReceived ||
    !tenantPricing.dataReceived ||
    !costBreakdown.dataReceived
  ) {
    return <Loader />;
  }

  if (vmDetails.errors.get) {
    return <Alert severity="error">{vmDetails.errors.get}</Alert>;
  }

  return (
    <div className="full-width">
      <Row columnSpacing={2}>
        <Col xs={12} sm={3}>
          <LeftBarNavigation
            header={
              <div className="p-20 pl-25 pr-25">
                <div className="mb-10 fs-17 bolder flex align-start full-width lh-12">
                  <StatusDot status={status} className="mt-5" />
                  {vmDetails.data.dedicatedServer?.name}
                </div>
                {isPublic ? (
                  <div className="steel">
                    <CopyTooltip>{ipAddresOfPublicVm?.ipv4}</CopyTooltip>
                  </div>
                ) : (
                  <>
                    {vmDetails.data.internalIpAddress && (
                      <div className="steel">
                        <CopyTooltip>
                          {vmDetails.data.internalIpAddress}
                        </CopyTooltip>{' '}
                        ({t('services.card.content.internal')})
                      </div>
                    )}
                    {vmDetails.data.externalIpAddress && (
                      <div className="steel">
                        <CopyTooltip>
                          {vmDetails.data.externalIpAddress}
                        </CopyTooltip>{' '}
                        ({t('services.card.content.external')})
                      </div>
                    )}
                  </>
                )}
              </div>
            }
            staticQuery={{ id: query.id }}
            tHash="services.dedicated"
            items={NAV_ITEMS}
            params={VM_DETAILS_NAV_ITEMS_PARAMS}
            baseUrl="services/dedicated"
          />
        </Col>
        <Col xs={12} sm={9}>
          {status === 'initializing' && (
            <Alert severity="info" className="mb-15">
              {t('services.vm.alert.initializing.title')}
            </Alert>
          )}
          <div
            className={cn({
              disabled:
                task.isTaskActive ||
                vmDetails.isRequesting ||
                status === 'initializing',
            })}
          >
            <Outlet />
          </div>
        </Col>
      </Row>
    </div>
  );
});

const DedicatedServerDetailsMain = () => <View {...OBSERVERS} />;

export default DedicatedServerDetailsMain;
