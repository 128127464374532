import * as React from 'react';
import cn from 'classnames';
import { LinearTable, Row, Col } from 'elements';
import { IServiceName, IOsServiceItemGrouped } from 'monitoring';
import { dayjs, showSystemMessage, confirm } from 'utils';
import duration from 'dayjs/plugin/duration';
import { useState } from 'hooks';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { useTranslation } from 'react-i18next';
import { processColumns } from './constants';
import AddServiceNameDialog from './ServiceNameDialog';
import Filters from './Filters';
import * as Types from '../types';

dayjs.extend(duration);

const OBSERVERS = {
  osServices: StateHandlers.monitoringOsServices,
};

const INITIAL_QUERY: Types.IProcessQuery = {
  search: '',
  orderBy: 'name',
  orderType: 'asc',
};

const INITIAL_STATE: Types.IProcessState = {
  serviceNameDialogOpen: false,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: Types.IProcessesProps & IViewProps) => {
  const { osServices, instanceId, monitoringId, isEditable } = props;
  const { t } = useTranslation();
  const [query, handleQuery] = useState(INITIAL_QUERY);
  const [state, handleState] = useState(INITIAL_STATE);

  const handleOpenServiceNameDialog = React.useCallback(
    (open: boolean) => () => {
      handleState({ serviceNameDialogOpen: open });
    },
    []
  );

  const addServiceNameRequest = React.useCallback(
    (payload: IServiceName) =>
      osServices
        .executeRequest('addServiceName')(monitoringId, instanceId, payload)
        .then(() => {
          showSystemMessage(
            'monitoring.sections.processes.notify.serviceName.create.success',
            'success'
          );
          handleState({ serviceNameDialogOpen: false });
          return osServices.get({ instanceId, monitoringId });
        })
        .catch((err) => showSystemMessage(err.message, 'error')),
    [monitoringId, instanceId]
  );

  const removeServiceNameRequest = React.useCallback(
    (payload: IServiceName) =>
      osServices
        .executeRequest('removeServiceName')(monitoringId, instanceId, payload)
        .then(() => {
          showSystemMessage(
            'monitoring.sections.processes.notify.serviceName.remove.success',
            'success'
          );
          return osServices.get({ instanceId, monitoringId });
        })
        .catch((err) => showSystemMessage(err.message, 'error')),
    [monitoringId, instanceId]
  );

  const confirmDeleteServiceName = React.useCallback(
    (service: IOsServiceItemGrouped) => {
      return confirm({
        title: t(
          'monitoring.sections.processes.confirm.serviceName.remove.title',
          { name: service.name, interpolation: { escapeValue: false } }
        ),
        content: t(
          'monitoring.sections.processes.confirm.serviceName.remove.content'
        ),
        onSuccess: () =>
          removeServiceNameRequest({
            serviceName: service.discoveryUnitName || service.name,
          }),
        onCancel: () => undefined,
      });
    },
    [monitoringId, instanceId, removeServiceNameRequest]
  );

  const fetchProcesses = React.useCallback(() => {
    if (instanceId && monitoringId) {
      osServices.get({ instanceId, monitoringId });
    }
  }, [instanceId, monitoringId]);

  const onRefresh = React.useCallback(() => {
    props.onRefresh().then(fetchProcesses);
  }, [props.onRefresh, instanceId, monitoringId]);

  const columns = React.useMemo(
    () =>
      processColumns({
        t,
        isEditable,
        onDelete: confirmDeleteServiceName,
      }),
    [confirmDeleteServiceName]
  );

  React.useEffect(() => {
    fetchProcesses();
  }, [instanceId, monitoringId]);

  const data = React.useMemo(
    () =>
      osServices.data
        ?.slice()
        .sort((p, n) =>
          query.orderType === 'asc'
            ? p.name.localeCompare(n.name)
            : n.name.localeCompare(p.name)
        ),
    [osServices.data, query]
  );

  return (
    <div className="fs-14 pt-15">
      <Row
        className="mb-25"
        alignItems="center"
        columnSpacing={2}
        justifyContent="space-between"
      >
        <Col xs>
          <div className="steel">
            {t('monitoring.sections.processes.content')}
          </div>
        </Col>
        <Col>
          <Filters
            isEditable={isEditable}
            onAddProcess={handleOpenServiceNameDialog(true)}
            onRefresh={onRefresh}
            query={query}
            onChange={handleQuery}
          />
        </Col>
      </Row>

      <LinearTable
        className={cn({ disabled: osServices.isRequesting })}
        isLoading={!osServices.dataReceived}
        data={data}
        columns={columns}
        cellClassName="pt-5 pb-5"
        query={query}
        onSort={(orderBy, orderType) => {
          handleQuery({
            orderBy,
            orderType,
          });
        }}
        hasSorting
      />
      <AddServiceNameDialog
        open={state.serviceNameDialogOpen}
        onClose={handleOpenServiceNameDialog(false)}
        onSave={addServiceNameRequest}
      />
    </div>
  );
});

const OsServices = (props: Types.IProcessesProps) => (
  <View {...props} {...OBSERVERS} />
);

export default OsServices;
