import * as R from 'ramda';

export type IStatusNamespace =
  | 'completed'
  | 'waiting'
  | 'active'
  | 'delayed'
  | 'failed'
  | 'powerOnInProgress'
  | 'powerOffInProgress'
  | 'provisioning'
  | 'patching'
  | 'running'
  | 'suspended'
  | 'waitingForInput'
  | 'deleting'
  | 'error'
  | 'poweredOff'
  | 'initializing'
  | 'none';

export const STATUS_NAMESPACES_MAP: Record<IStatusNamespace, string[]> = {
  provisioning: [
    'RESOLVED',
    'UNRESOLVED',
    'READY_TO_SETUP',
    'EDGE_GATEWAY_SETUP',
    'ORG_NET_SETUP',
    'NAT_SERVICE_SETUP',
    'FIREWALL_SERVICE_SETUP',
    'ATTACH_NETWORK_TO_VAPP',
    'SETUP_VM',
    'AGENT_VERIFICATION',
    'CREATE_VCD_NAMED_DISK',
    'AGENT_SET_LOCAL_ADMIN_PWD',
    'AGENT_IMPORT_CERTIFICATE',
    'AGENT_INIT_DISK',
    'AGENT_INSTALL_NU_GET',
    'AGENT_INSTALL_UPDATES',
    'AGENT_CREATE_AD_DOMAIN',
    'AGENT_CREATE_GPO_STORE',
    'AGENT_INSTALL_GPAE',
    'AGENT_CREATE_GPAE_POLICY',
    'AGENT_CREATE_BG_DOMAIN_ADMIN',
    'AGENT_CHECK_AD_READY_STATE',
    'AGENT_SET_DNS_FORWARDER',
    'AGENT_SET_LOCAL_DNS',
    'AGENT_CREATE_BASE_OUS',
    'INCREASE_VCD_DISK_SIZE',
    'AGENT_INCREASE_DISK_SIZE',
    'CREATE_PROFILE_VCD_NAMED_DISK',
    'CORE_INFRA_AGENT_INIT_PROFILE_DISK',
    'HOST_AGENT_VERIFICATION',
    'HOST_AGENT_INSTALL_NU_GET',
    'ADD_GATEWAY_VM_HTTP_PUBLIC_SERVICE',
    'GATEWAY_AGENT_ENABLE_REMOTE_PS',
    'GATEWAY_AGENT_VERIFICATION',
    'PREPARE_OUs_And_GROUPS',
    'HOST_AGENT_IMPORT_CERTIFICATE',
    'SETUP_HOST_VM',
    'CREATE_VM',
    'SETUP_GATEWAY_VM',
    'JOIN_DOMAIN',
    'GATEWAY_AGENT_IMPORT_CERTIFICATE',
    'GATEWAY_AGENT_CHANGE_LOCAL_ADMIN_PWD',
    'CORE_INFRA_AGENT_SETUP_RDS_FARM',
    'CORE_INFRA_AGENT_INSTALL_RDS_FARM_CERTIFICATES',
    'CORE_INFRA_AGENT_INSTALL_RDS_FARM_CERTS',
    'CORE_INFRA_AGENT_CONFIG_RDS_COLLECTION',
    'HOST_AGENT_CONFIG_HTTPS_REDIRECT',
    'CORE_INFRA_AGENT_CONFIG_LICENSING',
    'ISSUE_CERTIFICATES',
    'SHARE_PROFILE',
    'HOST_AGENT_INSTALL_FS_LOGIX',
    'CORE_INFRA_AGENT_CONFIG_FS_LOGIX',
    'CORE_INFRA_AGENT_CREATE_COMPUTER_LOCKDOWN_POLICY',
    'CORE_INFRA_AGENT_CREATE_USER_LOCKDOWN_POLICY',
    'INCREASE_VCD_PROFILE_DISK_SIZE',
    'AGENT_SET_LOCAL_ADMIN_PWD',
    'HOST_AGENT_APPLY_GPOS',
    'WAITING_HOST_GATEWAY_SETUP_COMPLETED',
    'WAITING_VMS_BASE_SETUP',
    'GATEWAY_AGENT_JOIN_DOMAIN',
    'ISSUE_CERTIFICATES',
    'RECONFIGURE_VM',
    'UPDATE_VM',
    'CORE_INFRA_AGENT_ENABLE_JOIN_ADMIN',
    'CORE_INFRA_AGENT_RESET_JOIN_ADMIN_PWD',
    'SET_CUSTOM_PROPERTIES',
    'UPDATE_PUBLIC_SERVICES_CONFIG',
    'WAITING_HOST_CERTS_INSTALLATION',
    'WAITING_HOST_FS_LOGIX_INSTALLATION',
    'AGENT_CREATE_WINDOWS_DEFENDER_GPO',
    'AGENT_CREATE_FOLDER_REDIRECTION',
    'CORE_INFRA_AGENT_PREPARE_OUS_AND_GROUPS',
    'INSTALLING_OPERATING_SYSTEM',
    'CORE_INFRA_AGENT_DISABLE_JOIN_ADMIN',
    'WAITING_CONFIG_RDS_USER_LOCKDOWN_POLICY',
    'AGENT_INSTALL_FS_LOGIX',
    'WAITING_CONFIG_RDS_SHARE_PROFILE',
    'AGENT_RDS_REMOVE_ONE_SESSION_HOST',
    'AGENT_LOG_OFF_ALL_USERS',
    'AGENT_SEND_USERS_MESSAGE',
    'AGENT_ENABLE_MAINTENANCE_MODE',
    'AGENT_ADD_RDS_ADDITIONAL_SESSION_HOST',
    'AGENT_INSTALL_SESSION_CERT',
    'WAITING_INSTALL_RDS_FARM_CERTS',
    'AGENT_ENABLE_REMOTE_PS',
    'AGENT_JOIN_DOMAIN',
    'AGENT_CHANGE_LOCAL_ADMIN_PWD',
    'WAITING_AGENT_VERIFICATION',
    'AGENT_INIT_SHARE_HOME',
    'PROVISIONING',
  ],
  patching: ['OS_PATCHING'],
  running: ['POWERED_ON', 'SETUP_COMPLETED', 'RUNNING'],
  suspended: ['SUSPENDED'],
  waitingForInput: ['WAITING_FOR_INPUT'],
  deleting: [
    'DELETING',
    'DELETE_VM',
    'DELETE_DB_RECORD',
    'DELETE_GATEWAY_VM',
    'DELETE_PROFILE_DISK',
    'DELETE_HOST_VM',
    'DELETE_RDS_FARM',
    'READY_TO_DELETE_HOST_GATEWAY',
    'WAITING_HOST_GATEWAY_DELETE_COMPLETED',
  ],
  powerOnInProgress: ['POWER_ON_INPROGRESS', 'POWERING_ON', 'POWER_ON_VM'],
  powerOffInProgress: ['POWER_OFF_INPROGRESS', 'POWERING_OFF', 'POWER_OFF_VM'],
  error: ['FAILED_CREATION', 'UNKNOWN', 'UNRECOGNIZED', 'ERROR'],
  poweredOff: ['POWERED_OFF'],
  initializing: ['AGENT_INIT', 'INIT', 'INITIALIZING'],
  none: [],
  waiting: [],
  delayed: [],
  active: [],
  failed: [],
  completed: [],
};

export const defineStatusNamespace = (
  status?: string | null
): IStatusNamespace => {
  let namespace: IStatusNamespace = 'none';

  if (!status) return namespace;

  R.keys(STATUS_NAMESPACES_MAP).forEach((key) => {
    if (STATUS_NAMESPACES_MAP[key].includes(status)) {
      namespace = key;
    }
  });
  return namespace;
};

export const SYSTEM_COLORS_MAP: Record<IStatusNamespace, string> = {
  completed: 'success',
  waiting: 'warning',
  active: 'success',
  delayed: 'primary',
  failed: 'error',
  powerOnInProgress: 'primary',
  powerOffInProgress: 'primary',
  provisioning: 'primary',
  patching: 'primary',
  running: 'success',
  suspended: 'warning',
  waitingForInput: 'warning',
  deleting: 'primary',
  error: 'error',
  poweredOff: 'default',
  initializing: 'primary',
  none: 'default',
};

export const defineSystemColorByStatus = (
  status: IStatusNamespace | string
): string => {
  const _status = defineStatusNamespace(status);

  return SYSTEM_COLORS_MAP[_status];
};
