import { remCalc } from 'utils';
import { PALETTE } from 'enums';
import { styled } from 'elements';

export const PriceDetailsHolder = styled('div')(({ theme }) => ({
  width: remCalc(870),
  maxWidth: '100%',
  padding: remCalc(15),
  [theme.breakpoints.up('md')]: {
    padding: remCalc(30),
  },
  [theme.breakpoints.up('lg')]: {
    padding: remCalc(45, 40),
  },
}));

export const PriceDetailsListItem = styled('div')(({ theme }) => ({
  paddingLeft: remCalc(25),
  position: 'relative',
  '&::before': {
    display: 'block',
    content: "''",
    width: remCalc(8),
    height: remCalc(8),
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    position: 'absolute',
    top: `calc(50% - ${remCalc(4)})`,
    left: 0,
  },
}));

export const ServiceBlockWrapper = styled('div')(({ theme }) => ({
  padding: remCalc(10, 20, 0, 40),
  marginBottom: remCalc(8),
  border: `1px solid ${PALETTE.lightGrey}`,
  borderRadius: 6,
}));
