import * as React from 'react';
import { Patching as PatchingBase } from 'components';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';

const OBSERVERS = {
  core: StateHandlers.core,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  return (
    <PatchingBase
      serviceType="Core"
      fetchBaseService={() => props.core.get()}
    />
  );
});

const Patching = () => <View {...OBSERVERS} />;

export default Patching;
