import * as React from 'react';
import { v4 } from 'uuid';
import { useAuth, useState } from 'hooks';
import { Emitter } from 'utils';
import { CustomEvents } from 'enums';
import GeneralConfirmDialog from './GeneralConfirmDialog';

type IState = Record<string, AnyShape>;

const INITIAL_STATE: IState = {
  confirmDialogs: {},
};

const ConfirmDialogs = () => {
  const [dialogs, handleState] = useState<IState>(INITIAL_STATE);
  const { isOnAuthorizedRoute } = useAuth();

  const handleConfirmDialog = React.useCallback(
    (key: string) => (props: any) => {
      handleState({ [key]: props });
    },
    []
  );

  const handleOpen = React.useCallback(
    (props: any) => handleConfirmDialog(v4())({ ...props, open: true }),
    []
  );

  const handleClearValues = React.useCallback(
    (key: string) => handleConfirmDialog(key)(null),
    []
  );

  const onClose = React.useCallback(
    (key: string) => () => {
      const newState = dialogs[key];
      newState.open = false;
      handleState(newState);
      setTimeout(handleClearValues, 300);
    },
    [dialogs]
  );

  React.useEffect(() => {
    if (isOnAuthorizedRoute) {
      Emitter.on(CustomEvents.confirmDialog, handleOpen);
    }
  }, [isOnAuthorizedRoute]);

  React.useEffect(() => {
    return () => {
      Emitter.off(CustomEvents.confirmDialog, handleOpen);
    };
  }, []);

  return (
    <>
      {Object.keys(dialogs).map((dKey) => {
        const pr = (dialogs[dKey] || {}) as any;
        return pr ? (
          <GeneralConfirmDialog
            {...pr}
            key={dKey}
            open={pr.open}
            onClose={onClose(dKey)}
            onSave={pr.onSuccess}
            formInitials={pr.initialValues}
          />
        ) : null;
      })}
    </>
  );
};

export default ConfirmDialogs;
