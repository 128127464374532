import React, { useEffect, useMemo } from 'react';
import { Role } from 'roles-shapes';
import { useTranslation } from 'react-i18next';
import { DEFAULT_QUERY, permissionNamespaceMap } from './constants';
import RoleItem from './RoleItem';
import { useQuery, useState, usePermissions } from 'hooks';
import { Button, Row, Loader, Col, Alert, TablePagination } from 'elements';
import RolesDetailsDialog from 'pages/roles/RolesDetailsDialog';
import { confirm, noop, defineDisplayPagination } from 'utils';
import { ALL_TEST_IDS } from 'enums';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { AnyShape } from 'global-shapes';

type State = {
  isOpen: boolean;
  values: any;
};

const INITIAL_STATE = {
  isOpen: false,
  values: null,
};

const OBSERVERS = {
  roles: StateHandlers.roles,
  currentUser: StateHandlers.currentUser,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IViewProps) => {
  const { query, queryStr, changeQuery } = useQuery();
  const { t } = useTranslation();
  const { currentUser, roles } = props;
  const { permissions } = usePermissions('ROLES');
  const [state, handleStateChange] = useState<State>(INITIAL_STATE);
  const Q = { ...DEFAULT_QUERY, ...query };
  const isNew = state.values === null;
  const isCurrentUserRole = useMemo(() => {
    if (state.values && currentUser.data.role) {
      return state.values.id === currentUser.data.role.id;
    }
    return false;
  }, [state.values, currentUser.data.role]);

  const showPagination = defineDisplayPagination(roles.meta?.totalCount, query);

  const fetchRoles = React.useCallback(() => {
    return roles.get(Q);
  }, [queryStr]);

  const onSubmit = (role: AnyShape) => {
    const permissions: any[] = [];

    Object.keys(role.permissions).forEach((p: string) => {
      permissions.push({
        resource: p,
        // @ts-ignore
        ...permissionNamespaceMap[role.permissions[p]],
      });
    });
    const payload = { ...role, permissions };

    const onSuccess = () => {
      handleStateChange({ values: null, isOpen: false });
      fetchRoles();
      return;
    };

    isNew
      ? roles.create(payload).then(onSuccess)
      : roles.update(state.values?.id, payload).then(onSuccess);
  };

  useEffect(() => {
    permissions.canView && roles.get(Q);
  }, [permissions.canView, queryStr]); // eslint-disable-line

  if (!roles.dataReceived || roles.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Row justifyContent="space-between" alignItems="center" className="mb-20">
        <Col>
          <h3>{t('roles.title')}</h3>
        </Col>
        <Col>
          {permissions?.canManage && (
            <Button
              onClick={() => {
                handleStateChange({ values: null, isOpen: true });
              }}
              testId={ALL_TEST_IDS.roles.create}
            >
              {t('roles.button.createRole')}
            </Button>
          )}
        </Col>
      </Row>

      <Row columnSpacing={2} rowSpacing={2}>
        {roles.errors.get && <Alert severity="error">{roles.errors.get}</Alert>}
        {roles.data.map((r: Role) => (
          <Col xs={12} md={6} lg={4} key={r.id}>
            <RoleItem
              {...r}
              onDelete={() =>
                confirm({
                  title: 'roles.deleteDialog.title',
                  content: t('roles.deleteDialog.content'),
                  onCancel: noop,
                  onSuccess: () => roles.remove(r.id).then(() => roles.get(Q)),
                })
              }
              onEdit={() => {
                handleStateChange({ values: r, isOpen: true });
              }}
            />
          </Col>
        ))}
      </Row>
      {showPagination && (
        <TablePagination
          shouldUseQuery
          perPage={query.perPage}
          page={query.page}
          totalCount={roles.meta?.totalCount}
          onChange={({ page }) => {
            changeQuery({ page });
          }}
        />
      )}

      <RolesDetailsDialog
        open={state.isOpen}
        isNew={isNew}
        initialValues={state.values}
        onSave={onSubmit}
        isPendingRequest={roles.isRequesting || currentUser.isRequesting}
        isCurrentUserRole={isCurrentUserRole}
        onClose={() => {
          handleStateChange({ values: null, isOpen: false });
        }}
      />
    </>
  );
});

const RolesMain = () => <View {...OBSERVERS} />;

export default RolesMain;
