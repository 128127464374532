import { usageService, quotaService } from 'services';
import { IUsageStatisticsByUser, IUsageRow } from 'usage';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export const usage = new BaseMobxInstanceDecorator<IUsageRow, true>({
  instanceName: 'usage',
  requests: {
    get: (query) => {
      const { accessLevel, ...q } = query;
      return usageService.getUsage(accessLevel, q);
    },
  },
  initialState: [],
});

export type IUsageHandler = BaseDecorator<IUsageRow, true>;

export const usageSelected = new BaseMobxInstanceDecorator<IUsageRow, false>({
  instanceName: 'selected-usage',
  requests: {
    get: usageService.getUsageById,
  },
  initialState: {} as IUsageRow,
});

export type IUsageSelectedHandler = BaseDecorator<IUsageRow, false>;

export const usagePrepaid = new BaseMobxInstanceDecorator<IUsageRow, false>({
  instanceName: 'prepaid-usage',
  requests: {
    get: usageService.getUsageById,
  },
  initialState: {} as IUsageRow,
});

export type IUsagePrepaidHandler = BaseDecorator<IUsageRow, false>;

export const usageStatistics = new BaseMobxInstanceDecorator<
  IUsageStatisticsByUser,
  false
>({
  instanceName: 'usage-statistics',
  requests: {
    get: async (query) => {
      const res = await usageService.getStatistics(query);
      return { data: res };
    },
  },
  initialState: {} as IUsageStatisticsByUser,
});

export type IUsageStatisticsHandler = BaseDecorator<
  IUsageStatisticsByUser,
  false
>;

export const tenantQuota = new BaseMobxInstanceDecorator<
  IQuotaTypes.TenantResource,
  false
>({
  instanceName: 'tenant-quota',
  requests: {
    get: (params) => quotaService.getTenantQuotas(params.id),
    // @ts-ignore
    update: quotaService.updateTenantQuotas,
  },
  initialState: {} as IQuotaTypes.TenantResource,
});

export const partnerQuota = new BaseMobxInstanceDecorator<
  IQuotaTypes.PartnerResource,
  false
>({
  instanceName: 'partner-quota',
  requests: {
    get: (params) => quotaService.getPartnerQuotas(params.id),
    // @ts-ignore
    update: quotaService.updatePartnerQuotas,
  },
  initialState: {} as IQuotaTypes.PartnerResource,
});
