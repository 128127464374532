import { BaseMobxInstanceDecorator } from './utilities';
import { IGuideTooltipsState } from 'guide-tooltips';
import { guideTooltipsService } from 'services';

export const guideTooltips = new BaseMobxInstanceDecorator<
  IGuideTooltipsState,
  false
>({
  instanceName: 'guide-tooltips',
  requests: {
    get: async () => {
      const res = await guideTooltipsService.getTooltips();
      return { data: res };
    },
    update: (_id, payload) => guideTooltipsService.updateTooltips(payload),
  },
  initialState: {} as IGuideTooltipsState,
});
