import { stripeService } from 'services';
// import { showSystemMessage } from 'utils';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

type IStripePalceholder = Record<string, string>;

export const stripe = new BaseMobxInstanceDecorator<IStripePalceholder, false>({
  instanceName: 'stripe',
  requests: {},
  additionalRequests: {
    createAccount: async (
      partnerId: number,
      payload: IStripe.CreateAccountPayload
    ) => {
      const res = await stripeService.createAccount(partnerId, payload);
      // showSystemMessage
      return res;
    },
    refreshActiveAccountSession: async (
      partnerId: number,
      payload: IStripe.CreateAccountPayload
    ) => {
      const res = await stripeService.refreshAccountCreationSession(
        partnerId,
        payload
      );
      // showSystemMessage
      return res;
    },
    payInvoice: async (
      invoiceId: number,
      payload: IStripe.CreatePaymentPayload
    ) => {
      const res = await stripeService.payInvoice(invoiceId, payload);
      // showSystemMessage
      return res;
    },
  },
  initialState: {},
});

export type IStripeHandler = BaseDecorator<IStripePalceholder, false>;
