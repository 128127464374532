import * as React from 'react';
import { Dialog, Input, Button, Select } from 'elements';
import { useFormik } from 'formik';
import { useRemoteSupport } from 'hooks';
import { useTranslation } from 'react-i18next';
import { rdsService } from 'services';
import { showSystemMessage } from 'utils';
import { ValueType } from 'global-shapes';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import {
  EXPIRE_IN_OPTIONS,
  INITIAL_VALUES,
  validationSchema,
} from './constants';
import { IRemoteSupport } from './types';

const loadHostInstanceOptions = async (
  rdsId: number
): Promise<ValueType<number>[]> =>
  await rdsService.getHost(rdsId).then((res) =>
    res.data.hostInstances.map((el) => ({
      label: el.vm.name,
      value: el.vm.id,
    }))
  );

const OBSERVERS = {
  rds: StateHandlers.rds,
  connectivity: StateHandlers.connectivity,
  meta: StateHandlers.meta,
  forwardedPorts: StateHandlers.forwardedPorts,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: IRemoteSupport.Props & IViewProps) => {
  const {
    subtitle,
    open,
    attributes,
    onClose,
    meta,
    rds,
    forwardedPorts,
    onSuccess,
  } = props;
  const [rdsVms, setRdsVms] = React.useState<ValueType<number>[]>([]);
  const { isRequesting, gatewayWanIp, selectedPort } = useRemoteSupport({
    type: props.type,
    vmId: attributes.vmId,
    onSuccess,
  });
  const { t } = useTranslation();
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: { ...INITIAL_VALUES, sourceWANIp: meta.data?.ip || '' },
    validationSchema: validationSchema(props.type),
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (val) => {
      const payload = {
        sourceWANIp: val.sourceWANIp,
        coreInfrastructureId: attributes.coreInfrastructureId,
        vmId: props.type === 'rds' ? val.vmId?.value : attributes.vmId,
        rdsId: attributes.rdsId,
        expireIn: val.expireIn,
        port: attributes.osType === 'linux' ? 22 : 3389,
      };
      try {
        const res = selectedPort?.id
          ? await forwardedPorts.remove(selectedPort.id)
          : await forwardedPorts.create(payload);
        await forwardedPorts.get({
          perPage: 1000,
          page: 1,
        });
        onClose();
        if (onSuccess) onSuccess();
        return res;
      } catch (error: any) {
        showSystemMessage(error.message, 'error');
      }
    },
  });

  const hasRds = !!rds.data;

  React.useEffect(() => {
    if (open) {
      if (hasRds && props.type === 'rds')
        loadHostInstanceOptions(rds.data?.id).then((options) => {
          setRdsVms(options);
          if (selectedPort) {
            setFieldValue(
              'vmId',
              options.find((el) => el.value === selectedPort?.vmId)
            );
          }
        });

      setFieldValue(
        'sourceWANIp',
        selectedPort?.sourceWANIp || meta.data?.ip || ''
      );

      setFieldValue(
        'expireIn',
        EXPIRE_IN_OPTIONS.find((el) => el.value === selectedPort?.expireIn) ||
          EXPIRE_IN_OPTIONS[1]
      );
    } else {
      resetForm();
    }
  }, [open, selectedPort, hasRds, meta.data?.ip]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      keepMounted={false}
      title={t('remoteSupport.dialog.title')}
      handleSubmit={handleSubmit}
      actions={
        <>
          <Button color="default" variant="outlined" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button disabled={isRequesting} type="submit">
            {selectedPort?.id ? t('common.disable') : t('common.enable')}
          </Button>
        </>
      }
    >
      {subtitle && <div className="mb-20 steel">{t(subtitle)}</div>}
      <div className="mb-25">
        <Input
          name="sourceWANIp"
          label={t('forms.sourceIp')}
          aria-autocomplete="none"
          autoComplete="off"
          disabled={!!selectedPort?.id}
          inputProps={{
            autoComplete: 'off',
          }}
          value={values.sourceWANIp}
          onChange={handleChange}
          error={!!errors.sourceWANIp}
          helperText={errors.sourceWANIp}
          placeholder="000.000.000.000"
        />
      </div>
      <div className="mb-25">
        <Select
          name="type"
          label={t('forms.expirationUntil')}
          options={EXPIRE_IN_OPTIONS}
          value={values.expireIn}
          onChange={(expireIn) => setFieldValue('expireIn', expireIn)}
          error={!!errors.expireIn}
          helperText={errors.expireIn}
          isDisabled={!!selectedPort?.id}
        />
      </div>
      <div className="mb-25">
        <Input
          name="targetServer"
          label={t('forms.targetServer')}
          aria-autocomplete="none"
          autoComplete="off"
          disabled
          inputProps={{
            autoComplete: 'off',
          }}
          value={
            props.type ? t(`remoteSupport.options.source.${props.type}`) : ''
          }
        />
      </div>
      {props.type === 'rds' && (
        <div className="mb-25">
          <Select
            name="vmId"
            label={t('forms.rdsServer')}
            error={!!errors.vmId}
            helperText={errors.vmId}
            value={values.vmId}
            options={rdsVms}
            isDisabled={!!selectedPort?.id}
            onChange={(val) => setFieldValue('vmId', val)}
          />
        </div>
      )}

      <div className="mb-25">
        <Input
          name="gatewayWanIp"
          label={t('forms.gatewayWanIp')}
          aria-autocomplete="none"
          autoComplete="off"
          inputProps={{
            autoComplete: 'off',
          }}
          value={gatewayWanIp}
          disabled
        />
      </div>
    </Dialog>
  );
});

const RemoteSupportDialog = (props: IRemoteSupport.Props) => (
  <View {...props} {...OBSERVERS} />
);

export default RemoteSupportDialog;
