import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

export const Emitter = {
  emitter: eventEmitter,
  on: (event: any, fn: (args: any) => void) => eventEmitter.on(event, fn),
  once: (event: any, fn: () => void) => eventEmitter.once(event, fn),
  off: (event: any, fn: (args: any) => void) => eventEmitter.off(event, fn),
  emit: (event: any, payload: any) => eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);
