import { IAssignmentReducerState } from 'badges';

export function getBadgeStateKey(badge: any): string {
  return `entity${badge?.appEntityId}`;
}

export function parseBadgeAssignemntToState(
  badges: IBadgeAssignment[]
): IAssignmentReducerState {
  const result: IAssignmentReducerState = {};
  badges.forEach((badge) => {
    if (!result[getBadgeStateKey(badge)]) result[getBadgeStateKey(badge)] = [];
    result[getBadgeStateKey(badge)].push(badge);
  });
  return result;
}

export function download(url: string, filename: string) {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function consvertAndDownloadStringAsFile(
  filename: string,
  text: string
) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function upgradeByteMeasure(value?: number) {
  if (!value) return 0;
  return value * 1024;
}

export function downgradeByteMeasure(value?: number) {
  if (!value) return 0;
  return value / 1024;
}
