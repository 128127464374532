import * as React from 'react';
import {
  AccordionSummary,
  Row,
  Col,
  Button,
  IconButton,
  LinearTable,
  CopyTooltip,
  MuiIcons,
  PrimaryTextDiv,
} from 'elements';
import { useTranslation } from 'react-i18next';
import { useDidUpdate, useState, useAsync } from 'hooks';
import { dnsService } from 'services';
import { dayjs, confirm, noop } from 'utils';
import { IDNSRecord, IDNSServer, IDNSZone } from 'dns';
import cn from 'classnames';
import { AnyShape } from 'global-shapes';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import RecordDetailsDialog from './dialogs/RecordDetailsDialog';
import { DEFAULT_RECORDS_QUERY, defineRecordsColumns } from './constants';
import { AccordionRoot, ExpandIcon, AccordionContent } from './Styled';

type Props = IDNSZone & {
  className?: string;
  query: AnyShape;
  expanded: boolean;
  isEditable: boolean;
  onOpenDetails: (id: number) => void;
  onDelete: (zone: IDNSZone) => void;
};

const INITIAL_STATE = {
  query: DEFAULT_RECORDS_QUERY,
  dialog: false,
  initialValues: null,
};

const OBSERVERS = {
  zones: StateHandlers.dnsZones,
  records: StateHandlers.dnsZoneRecords,
};

type IViewProps = typeof OBSERVERS;

const View = observer((props: React.PropsWithChildren<Props> & IViewProps) => {
  const {
    id,
    className,
    expanded,
    onOpenDetails,
    domainName,
    createdAt,
    updatedAt,
    onDelete,
    servers,
    isUsedByInternalService,
    isEditable,
    records,
    zones,
  } = props;

  const { t } = useTranslation();
  const {
    value: recordsData,
    params: recordsMeta,
    execute: fetchRecords,
    isPending: isRequesting,
  } = useAsync(dnsService.getRecords);

  const [state, handleState] = useState<any>(INITIAL_STATE);

  React.useEffect(() => {
    if (expanded) {
      fetchRecords(id, state.query);
    }
  }, [JSON.stringify(state.query)]);

  const onExpand = React.useCallback(() => {
    onOpenDetails(id);
    if (!expanded) {
      fetchRecords(id, state.query);
    }
  }, [expanded, id, JSON.stringify(state.query)]);

  const handleDialogClose = React.useCallback(() => {
    handleState({ dialog: false, initialValues: null });
  }, []);

  const openRecordDetailsDialog = React.useCallback(
    (initialValues: any) => (ev: any) => {
      ev.stopPropagation();
      handleState({ dialog: true, initialValues });
    },
    [id]
  );

  const updateRecordsTable = React.useCallback(() => {
    handleDialogClose();
    fetchRecords(id, state.query);
    zones.reload(id);
    return true;
  }, [id, JSON.stringify(state)]);

  const createRecord = React.useCallback(
    (values: any) => {
      records.create({ zoneId: id, values }).then(updateRecordsTable);
    },
    [id, JSON.stringify(state)]
  );

  const editRecord = React.useCallback(
    (values: any) => {
      if (state.initialValues) {
        records
          .update(state.initialValues.id, { zoneId: id, values })
          .then(updateRecordsTable);
      }
    },
    [id, JSON.stringify(state)]
  );

  const removeRecord = React.useCallback(
    (record: IDNSRecord) => {
      confirm({
        title: t('services.dns.confirm.record.delete.title'),
        content: t('services.dns.confirm.record.delete.content', {
          name: record.name,
        }),
        onSuccess: () =>
          records
            .remove({ zoneId: id, id: record.id })
            .then(updateRecordsTable),
        onCancel: noop,
      });
    },
    [id, JSON.stringify(state)]
  );

  const deleteZone = React.useCallback(
    (ev: React.SyntheticEvent) => {
      ev.stopPropagation();
      onDelete(props);
    },
    [props]
  );

  const columns = React.useMemo(() => {
    return defineRecordsColumns({
      t,
      isFetching: false,
      onDelete: removeRecord,
      onEdit: openRecordDetailsDialog,
      isUsedByInternalService,
      isEditable,
    });
  }, [isUsedByInternalService, isEditable]);

  useDidUpdate(() => {
    if (!expanded) {
      records.reset();
    }
  }, [expanded]);

  return (
    <>
      <AccordionRoot
        elevation={0}
        expanded={expanded}
        onChange={onExpand}
        className={cn(className, 'mb-5', {
          disabled: zones.updateIds.includes(id),
        })}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          aria-controls={`${id}-content`}
          id={`${id}-header`}
          className="m-0"
          classes={{ content: 'm-0 full-width' }}
        >
          <Row
            className="pt-20 pb-20 full-width"
            alignItems="center"
            justifyContent="space-between"
          >
            <Col className="flex align-center" xs={4}>
              <ExpandIcon
                expanded={expanded}
                className={cn('pointer fs-20 mr-30')}
              />
              <div>
                <div className="fs-14 uppercase bolder mb-5">
                  {t('services.dns.domains.list.head.name') as string}
                </div>
                <PrimaryTextDiv>
                  <CopyTooltip enableDoubleClick={false}>
                    {domainName}
                  </CopyTooltip>
                </PrimaryTextDiv>
              </div>
            </Col>
            <Col xs={4}>
              <Row justifyContent="space-between" columnSpacing={2}>
                <Col xs={6}>
                  <div className="fs-14 uppercase bolder mb-5">
                    {t('services.dns.domains.list.head.creationDate') as string}
                  </div>
                  <div className="uppercase steel">
                    {dayjs(createdAt).format('DD.MM.YYYY HH:mm')}
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="fs-14 uppercase bolder mb-5">
                    {t('services.dns.domains.list.head.lastUpdated') as string}
                  </div>
                  <div className="uppercase steel">
                    {dayjs(updatedAt).format('DD.MM.YYYY HH:mm')}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs>
              {isEditable && (
                <div className="flex justify-end full-width align-center">
                  <Button
                    className="mr-15"
                    onClick={openRecordDetailsDialog(null)}
                    size="small"
                  >
                    {t('services.dns.domains.addRecord')}
                  </Button>
                  {!isUsedByInternalService && (
                    <IconButton onClick={deleteZone}>
                      <MuiIcons.Delete className="steel fs-24" />
                    </IconButton>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </AccordionSummary>
        <AccordionContent>
          <div className="fs-14 uppercase mb-5 bolder">
            {t('services.dns.domains.servers.title') as string}
          </div>
          <div className="flex mb-30">
            {servers.map((server: IDNSServer) => (
              <div key={server} className="pr-40">
                <CopyTooltip>{server}</CopyTooltip>
              </div>
            ))}
          </div>
          <div className="fs-14 uppercase mb-5 bolder">
            {t('services.dns.domains.records.title') as string}
          </div>
          <div className="mb-10">
            <LinearTable
              data={recordsData || []}
              params={recordsMeta}
              columns={columns}
              query={state.query}
              usePagination
              className={cn({ disabled: records.isRequesting || isRequesting })}
              collapseEdgePaddings
              shouldUseQuery={false}
              noDataClassName={cn(
                { 'auto-height': !(recordsData || []).length },
                'pt-20 pb-15'
              )}
              onPageChange={({ page }) =>
                handleState({
                  query: { ...state.query, page },
                })
              }
            />
          </div>
        </AccordionContent>
      </AccordionRoot>
      <RecordDetailsDialog
        open={state.dialog}
        onClose={handleDialogClose}
        onSave={!state.initialValues ? createRecord : editRecord}
        isNew={!state.initialValues}
        initialValues={state.initialValues}
        isRequesting={records.isRequesting}
        domain={domainName}
      />
    </>
  );
});
const ZoneItem = (props: React.PropsWithChildren<Props>) => (
  <View {...props} {...OBSERVERS} />
);

export default ZoneItem;
