import * as React from 'react';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import { GLOBAL_DATE_FORMAT } from 'enums';
import { WithT } from 'i18next';
import {
  AccordionDetails,
  AccordionSummary,
  Chip,
  Col,
  MuiIcons,
  Row,
  IconButton,
  ExpandIcon,
} from 'elements';
import { numberToCurrency, dayjs } from 'utils';
import { Permission } from 'auth-shapes';
import { IBillableItem } from 'billable-items';
import { setColumnWidth } from './helpers';
import { AccordionRoot } from './Styled';
import {
  BILLING_DATE_FORMAT,
  IBIAccessLevel,
  statusColorMap,
} from './constants';

type IProps = WithT &
  IBillableItem &
  React.PropsWithChildren<{
    permissions: Permission;
    isRequesting: boolean;
    expanded: boolean;
    accessLevel: IBIAccessLevel;
    onOpenDetails: (id: number) => void;
    onEdit: (item: IBillableItem) => void;
    onDelete: (id: IBillableItem) => void;
  }>;

const Renderer = (props: IProps) => {
  const {
    t,
    expanded,
    onOpenDetails,
    onEdit,
    onDelete,
    permissions,
    isRequesting,
    ...itemData
  } = props;

  const {
    id,
    partner,
    tenant,
    ticket,
    user,
    type,
    status,
    date,
    amount,
    description,
    unit,
    qty,
    price,
    sendUpfront,
    discountPercent,
    accessLevel,
    recurringSettings,
    invoice,
    notes,
  } = itemData;

  const sourceUser = React.useMemo(() => {
    return accessLevel === 'provider' ? partner : tenant;
  }, [accessLevel, partner, tenant]);

  const isEditable = React.useMemo(() => {
    if (!permissions.canManage) return false;
    if (status === 'INVOICED') return false;
    return type !== 'APP_SERVICE';
  }, [type, status, permissions]);

  const onEditClick = React.useCallback(
    (ev: React.SyntheticEvent) => {
      ev.stopPropagation();
      onEdit(itemData);
    },
    [itemData, onEdit]
  );

  const onDeleteClick = React.useCallback(
    (ev: React.SyntheticEvent) => {
      ev.stopPropagation();
      onDelete(itemData);
    },
    [itemData, onDelete]
  );

  const isTenant = accessLevel === 'tenant';

  const invoiceTooltip = React.useMemo(() => {
    if (status === 'INVOICED') {
      return t(
        `${
          invoice
            ? `billableItems.invoicedWith`
            : 'billableItems.invoiceDeleted'
        }`,
        { invoice: invoice?.invoiceNumber }
      );
    }

    return undefined;
  }, [invoice, status]);

  return (
    <AccordionRoot
      elevation={0}
      expanded={expanded}
      onChange={() => onOpenDetails(id)}
      className={cn('mb-5')}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        className="m-0 pl-30 pr-15 fs-14"
        classes={{ content: 'm-0' }}
      >
        <Row columnSpacing={1} className="lh-1" justifyContent="space-between">
          <Col xs={4}>
            <Row alignItems="center">
              <Col {...{ xs: isTenant ? false : 6 }}>
                <Row alignItems="center">
                  <Col>
                    <ExpandIcon
                      expanded={expanded}
                      className={cn('pointer fs-20 mr-30')}
                    />
                  </Col>
                  {!isTenant && (
                    <Col xs>
                      <div
                        className={cn('primary break-word-all break-word pr-5')}
                      >
                        {sourceUser?.name}
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col
                xs={6}
                className={cn('break-word flex align-center lh-14', {
                  steel: !isTenant,
                })}
              >
                {!expanded && (description || '')}
              </Col>
            </Row>
          </Col>
          <Col alignItems="center" className="flex">
            <Row columnSpacing={2} alignItems="center">
              <Col {...setColumnWidth(180)} className="steel flex align-center">
                {recurringSettings
                  ? (t(
                      `billableItems.options.${recurringSettings.interval}`
                    ) as string)
                  : '-'}
              </Col>
              <Col {...setColumnWidth(200)} className="steel flex align-center">
                {sendUpfront ? (
                  <MuiIcons.Check className="success fs-16" />
                ) : (
                  '-'
                )}
              </Col>
              <Col
                {...setColumnWidth(150)}
                className="steel flex align-center break-word"
              >
                {isTenant
                  ? ticket
                  : user && `${user.firstName} ${user.lastName}`}
              </Col>
              <Col {...setColumnWidth(100)} className="steel flex align-center">
                {dayjs(date, BILLING_DATE_FORMAT).format('DD.MM.YYYY')}
              </Col>
              <Col
                {...setColumnWidth(130)}
                className="steel flex align-center justify-end"
              >
                {numberToCurrency(+amount, false)}
              </Col>
              <Col {...setColumnWidth(190)}>
                <Row className="full-width" justifyContent="space-between">
                  <Col className="flex align-center">
                    <Chip
                      status={statusColorMap[status]}
                      tooltip={invoiceTooltip}
                    >
                      {t(`billableItems.status.${status}`)}
                    </Chip>
                  </Col>
                  {isEditable && (
                    <Col>
                      <Row
                        alignItems="center"
                        justifyContent="flex-end"
                        className="full-width"
                      >
                        <Col className="flex align-center steel">
                          <IconButton size="small" onClick={onEditClick}>
                            <MuiIcons.Edit
                              className={cn('fs-20', {
                                disabled: isRequesting,
                              })}
                            />
                          </IconButton>
                        </Col>
                        <Col className="flex align-center steel" xs>
                          <IconButton size="small" onClick={onDeleteClick}>
                            <MuiIcons.Delete
                              className={cn('fs-20', {
                                disabled: isRequesting,
                              })}
                            />
                          </IconButton>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </AccordionSummary>
      <AccordionDetails className="pl-30">
        <Row columnSpacing={2} className="mb-20" justifyContent="space-between">
          <Col xs={4}>
            <div className="fs-12 steel mb-5">
              {t('table.head.description') as string}
            </div>
            <div className="break-word-all fs-14 mb-20">{description}</div>
            <div className="full-width">
              <Row
                alignItems="center"
                className="steel fs-12 mb-5"
                columnSpacing={2}
              >
                <Col xs={3}>{t('table.head.type') as string}</Col>
                {recurringSettings && (
                  <>
                    <Col xs={3}>{t('table.head.startDate') as string}</Col>
                    <Col xs={3}>{t('table.head.endDate') as string}</Col>
                  </>
                )}
              </Row>
              <Row alignItems="center" columnSpacing={2} className="fs-14">
                <Col xs={3}>{t(`billableItems.types.${type}`) as string}</Col>
                {recurringSettings && (
                  <>
                    <Col xs={3}>
                      {dayjs(recurringSettings.periodStart).format(
                        GLOBAL_DATE_FORMAT
                      )}
                    </Col>
                    <Col xs={3}>
                      {recurringSettings.periodEnd
                        ? dayjs(recurringSettings.periodEnd).format(
                            GLOBAL_DATE_FORMAT
                          )
                        : '-'}
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </Col>
          <Col className="flex">
            <Row columnSpacing={2} className="fs-14">
              <Col {...setColumnWidth(180)}>
                {isTenant ? (
                  <>
                    <div className="fs-12 steel mb-5">
                      {t('table.head.user') as string}
                    </div>
                    <div className="break-word">
                      {user && `${user.firstName} ${user.lastName}`}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="fs-12 steel mb-5">
                      {t('table.head.ticket') as string}
                    </div>
                    <div className="break-word">{ticket}</div>
                  </>
                )}
              </Col>
              <Col {...setColumnWidth(200)}>
                <div className="fs-12 steel mb-5">
                  {t('table.head.unitPrice') as string}
                </div>
                <div>
                  {numberToCurrency(
                    discountPercent
                      ? +price - +price * (discountPercent / 100)
                      : +price,
                    false
                  )}
                </div>
              </Col>
              <Col {...setColumnWidth(150)}>
                <div className="fs-12 steel mb-5">
                  {t('table.head.qty') as string}
                </div>
                <div>{+qty}</div>
              </Col>
              <Col {...setColumnWidth(100)}>
                <div className="fs-12 steel mb-5">
                  {t('table.head.unit') as string}
                </div>
                <div>{t(`billableItems.unit.${unit}`) as string}</div>
              </Col>
              <Col {...setColumnWidth(130)}></Col>
              <Col {...setColumnWidth(190)}></Col>
            </Row>
          </Col>
        </Row>
        {notes && (
          <div>
            <div className="fs-12 steel mb-5">
              {t('forms.adminNotes') as string}
            </div>
            <div className="break-word-all fs-14 mb-20">{notes}</div>
          </div>
        )}
      </AccordionDetails>
    </AccordionRoot>
  );
};

const BillableItem = withTranslation()(Renderer);

export default BillableItem;
