import * as React from 'react';
import * as Translations from 'i18next';
import { MuiIcons, styled } from 'elements';
import { withTranslation } from 'react-i18next';
import { important, remCalc } from 'utils';
import cn from 'classnames';
import { HEADER_HEIGHT } from './constants';

type IProps = Translations.WithT &
  React.PropsWithChildren<{
    open: boolean;
    className?: string;
    onClick: () => void;
  }>;

const Renderer = styled((props: IProps) => {
  const { onClick, open, className } = props;
  return (
    <div
      className={cn(
        className,
        { _iconActive: open },
        'mr-5 pointer flex align-center justify-center'
      )}
      onClick={onClick}
      id="header-search-icon"
    >
      <MuiIcons.Search />
    </div>
  );
})(({ theme }) => {
  const mobileUp = theme.breakpoints.up(1200);
  return {
    color: theme.palette.appBar.contrastText,
    borderRadius: important(0),
    height: remCalc(HEADER_HEIGHT),
    width: remCalc(62),
    fontSize: remCalc(17),
    [mobileUp]: {
      fontSize: remCalc(21),
    },
    '&._iconActive': {
      backgroundColor: important(theme.palette.appBar.contrastText),
      color: theme.palette.appBar.main,
      '& svg': {
        color: theme.palette.appBar.main,
      },
    },
  };
});

const SearchIcon = withTranslation()(Renderer);

export default SearchIcon;
