import { showSystemMessage, buildListParams } from 'utils';
import { backupService } from 'services';
import {
  IBackup,
  IBackupVersion,
  IBackupNotificationsWithEmails,
} from 'backup';
import { BaseMobxInstanceDecorator } from './utilities';
import { BaseDecorator } from './types';

export const backup = new BaseMobxInstanceDecorator<IBackup, false>({
  instanceName: 'backup',
  requests: {
    get: (query) => {
      const { entityName } = query;
      return backupService.getBackup(entityName)(query.id);
    },
    create: backupService.createBackup,
    update: backupService.updateBackup,
    remove: backupService.removeBackup,
  },
  // @ts-ignore
  initialState: null,
});

export type IBackupHandler = BaseDecorator<IBackup, false>;

export const backupVersions = new BaseMobxInstanceDecorator<
  IBackupVersion,
  true
>({
  instanceName: 'backup-versions',
  requests: {
    get: (query) => {
      const { backupId, ...q } = query;
      return backupService.getBackupVersions(
        backupId,
        buildListParams(q, ['orderBy', 'orderType', 'include'])
      );
    },
  },
  initialState: [],
});

export type IBackupVersionsHandler = BaseDecorator<IBackupVersion, true>;

export const backupNotifications = new BaseMobxInstanceDecorator<
  IBackupNotificationsWithEmails,
  false
>({
  instanceName: 'backup-notification-emails',
  requests: {
    get: (query) => {
      const { backupId, ...q } = query;
      return backupService.getBackupNotifications(backupId, buildListParams(q));
    },
    create: async (payload) => {
      const { backupId, ...rest } = payload;
      const res = await backupService.createBackupNotificationEmails(
        backupId,
        rest
      );
      showSystemMessage(
        'services.backup.notify.email.create.success',
        'success'
      );
      return res;
    },
    update: async (backupId, payload) => {
      const res = await backupService.updateBackupNotifications(
        backupId,
        payload
      );
      showSystemMessage(
        'services.backup.notify.notifications.update.success',
        'success'
      );
      return res;
    },
    remove: async (id) => {
      const res = await backupService.deleteBackupNotificationEmails(
        id.backupId,
        id.emailId
      );
      showSystemMessage(
        'services.backup.notify.email.delete.success',
        'success'
      );
      return res;
    },
  },
  initialState: {} as IBackupNotificationsWithEmails,
});

export type IBackupNotificationsHandler = BaseDecorator<
  IBackupNotificationsWithEmails,
  false
>;
