import { createTheme, ThemeOptions } from '@mui/material/styles';

export * from '@mui/material/styles';

const calc = (base: number, ...values: any) =>
  values.reduce((res: string, value: number | string) => {
    const val = ` ${Number(value) / base}rem`;
    res = (res + val).trim();
    return res;
  }, '');

const important = (val: number | string): string => `${val} !important`;
const remCalc = (...values: string[] | number[]) => calc(16, ...values);

const palette = {
  primary: '#1d62ab',
  primaryLight: '#5091d5',
  info: '#75B5FF',
  error: '#ff3c00',
  errorSecondary: '#ff3921',
  success: '#12af7b',
  warning: '#FFA102',
  dark: '#001844',
  white: '#fff',
  primaryDark: '#171d29',
  lightGrey: '#c8c9cc',
  steel: '#81858b',
  paleGrey: '#ebf4ff',
  pageBackground: '#f9f9f9',
  black: '#222',
  folderBackground: 'rgba(29, 98, 171, 0.04)',
};

export const ThemeConfig: ThemeOptions = {
  palette: {
    common: {
      black: palette.black,
      white: '#fff',
    },
    primary: {
      light: '#1a6fc4',
      main: palette.primary,
      dark: '#14599c',
      contrastText: '#fff',
    },
    default: {
      light: '#fff',
      main: palette.white,
      dark: '#e3e3e3',
      contrastText: palette.black,
    },
    secondary: {
      light: '#fc452f',
      main: palette.error,
      dark: '#e33219',
      contrastText: '#fff',
    },
    appBar: {
      light: '#002860',
      main: palette.dark,
      dark: '#00102c',
      contrastText: '#fff',
    },
    error: {
      light: '#fc452f',
      main: palette.error,
      dark: '#e33219',
      contrastText: '#fff',
    },
    success: {
      light: '#6fdc34',
      main: palette.success,
      dark: '#5cc725',
      contrastText: '#fff',
    },
    warning: {
      light: '#fda413',
      main: palette.warning,
      dark: '#e79202',
      contrastText: '#fff',
    },
    info: {
      light: '#89bcfc',
      main: palette.info,
      dark: '#659bdb',
      contrastText: '#fff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1340,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: remCalc(20),
          fontSize: remCalc(17),
          padding: remCalc(14, 25),
          borderRadius: remCalc(6),
          textTransform: 'none',
          '&.Mui-disabled': {
            color: important('rgba(0, 0, 0, 0.26)'),
            backgroundColor: important('rgba(0, 0, 0, 0.12)'),
            borderColor: important('transparent'),
          },
          '&.MuiButton-outlined': {
            backgroundColor: palette.white,
            lineHeight: remCalc(20),
            fontSize: remCalc(17),
            padding: remCalc(13, 25),
            textTransform: 'none',
          },
          '&.MuiButton-outlinedDefault': {
            color: palette.black,
            borderColor: 'rgba(0, 0, 0, 0.23)',
            '&:hover': {
              backgroundColor: '#ededee',
            },
          },
          '&.MuiButton-sizeLarge': {
            padding: remCalc(18, 25),
            fontSize: remCalc(17),
          },
          '&.MuiButton-sizeSmall': {
            padding: remCalc(8, 12),
            fontSize: remCalc(14),
            lineHeight: 1.14,
          },
          '&.MuiButton-text': {
            padding: remCalc(10, 25),
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: remCalc(12),
          marginBottom: remCalc(8),
          color: palette.dark,
          display: 'flex',
        },
      },
    },
    // @ts-ignore
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: remCalc(6),
        },
        standardError: {
          backgroundColor: 'rgba(255, 57, 33, 0.08)',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: important('1px'),
        },
        root: {
          padding: 0,
          '&.Mui-error': {
            backgroundColor: '#FFF0EE',
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: important(palette.error),
          },
        },
        input: {
          fontSize: remCalc(16),
          lineHeight: remCalc(20),
          borderRadius: remCalc(6),
          padding: remCalc(15),
          borderColor: palette.lightGrey,
          boxSizing: 'border-box',
        },
        multiline: {
          padding: remCalc(13, 15),
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          lineHeight: 1.5,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: important('0 4px 12px 0 rgba(23, 29, 41, 0.08)'),
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: remCalc(10, 20),
          borderBottom: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          backgroundColor: '#1229563b',
        },
        paper: {
          lineHeight: 1.5,
          padding: remCalc(30, 45, 35),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderRadius: remCalc(6),
          overflowX: 'hidden',
          padding: important(remCalc(0, 0, 10, 0)),
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          padding: remCalc(15, 0, 0),
          marginBottom: remCalc(23),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: remCalc(25, 0, 10),
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          top: remCalc(30),
          fontSize: remCalc(12),
          color: palette.steel,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          display: 'none',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: remCalc(0, 15, 0, 0),
        },
      },
    },
  },
};

export const THEME = createTheme(ThemeConfig);

export const PALETTE = palette;
