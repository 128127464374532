import * as React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { observer } from 'mobx-react-lite';
import { useStateHandler } from 'hooks';
import * as StateHandlers from 'states';

import { DragAndDropTypes } from 'enums/DnD';

export interface BoxProps {
  vm: IVmTypes.Vm;
}

export default observer(
  ({ vm, children }: React.PropsWithChildren<BoxProps>) => {
    const dnd = useStateHandler(StateHandlers.dnd);
    const folders = useStateHandler(StateHandlers.folders);
    const dedicatedServer = vm?.dedicatedServer;
    const isDedicatedServer = !!dedicatedServer;

    const elementType = isDedicatedServer
      ? DragAndDropTypes.DS
      : DragAndDropTypes.VM;
    const instance = isDedicatedServer ? dedicatedServer : vm;

    const { attributes, listeners, setNodeRef, transform, isDragging } =
      useDraggable({
        id: isDedicatedServer ? dedicatedServer.id : vm.id,
        data: {
          elementType,
          ...instance,
        },
      });

    const handleDragListener = React.useCallback((listener?: any) => {
      return (ev: any) => {
        if (
          ev.target?.dataset?.draggable === 'ignore' ||
          ev.target?.ariaHidden === 'true'
        ) {
          return;
        }
        return listener && listener(ev);
      };
    }, []);

    const style: React.CSSProperties = transform
      ? {
          zIndex: 2,
          position: 'relative',
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0) scale(1.05)`,
          opacity: isDragging ? 0.8 : 1,
          overflow: 'hidden',
          cursor: 'grabbing',
          transition:
            'background-color 0.25s ease-in-out 0s, opacity 0.25s ease-in-out 0s',
          borderRadius: 6,
        }
      : {};

    if (
      dnd.data.isDialogOpen ||
      (folders.dataReceived && !folders.data.length)
    ) {
      return <>{children}</>;
    }

    return (
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        onPointerDown={handleDragListener(listeners?.onPointerDown)}
        onKeyDown={handleDragListener(listeners?.onKeyDown)}
      >
        {children}
      </div>
    );
  }
);
