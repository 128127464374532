import * as React from 'react';
import { useFormik } from 'formik';
import {
  Button,
  Col,
  Dialog,
  DialogProps,
  Row,
  VmTabs,
  VmTab,
  TabPanel,
  Switch,
  Select,
} from 'elements';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { remCalc, getCountries } from 'utils';
import { useQuery, useStateHandler, usePermissions } from 'hooks';
import { editPublicServiceValidationSchema } from '../constants';
import * as TYPES from '../types';
import FirewallIpAddresses from '../FirewallIpAddresses';
import GeoIp from '../GeoIp';
import { getNicsOptions } from '../helpers';

const INITIAL: TYPES.IPublicServiceFormValues = {
  tab: 0,
  source: '',
  sources: [],
  geoip: undefined,
  countryCodes: [],
  anySource: true,
  nicId: undefined,
};

type IProps = DialogProps<IVmTypes.PortUpdatePayload> & {
  initialValues?: IVmTypes.VMPort;
};

const STYLES = {
  tab: { maxWidth: remCalc(350) },
};

const Renderer = observer((props: IProps) => {
  const { open, onClose, onSave, initialValues } = props;
  const { t } = useTranslation();
  const { isProvider } = usePermissions();
  const { queryStr, query } = useQuery();

  const countries = React.useMemo(getCountries, []);

  const ports = useStateHandler(StateHandlers.vmPorts);

  const updateService = React.useCallback(
    (port: TYPES.IPublicServiceFormValues) => {
      const payload = {
        sourceIpAddresses: port.sources,
        sourceCountryCodes: port.countryCodes.map((el) => el.value),
        nicId: port.nicId?.value,
      };

      if (port.anySource) {
        payload.sourceIpAddresses = [];
        payload.sourceCountryCodes = [];
      }

      return ports
        .update(
          {
            vmId: query.id,
            portId: initialValues?.id,
          },
          payload
        )
        .then((res) => {
          onClose();
          onSave && onSave(payload);
          return res;
        });
    },
    [queryStr, initialValues]
  );

  const form = useFormik({
    initialValues: INITIAL,
    validationSchema: editPublicServiceValidationSchema,
    onSubmit: updateService,
    validateOnMount: true,
  });

  const { values, setFieldValue } = form;

  const handleTabChange = React.useCallback((ev: any, ind: number) => {
    setFieldValue('tab', ind);
  }, []);

  React.useEffect(() => {
    if (initialValues) {
      const sources = initialValues?.sourceIpAddresses || [];
      const countryCodes = countries.filter((c) =>
        initialValues?.sourceCountryCodes.includes(c.value)
      );
      const anySource = !(sources.length + countryCodes.length);

      getNicsOptions(query.id)('').then((opts) => {
        const routedNetNic = opts.find((n) => n.value === initialValues?.nicId);
        console.log(routedNetNic);
        setFieldValue('nicId', routedNetNic || opts[0]);
      });

      form.setValues({
        ...INITIAL,
        sources,
        countryCodes,
        anySource,
      });
    } else {
      form.resetForm();
    }
  }, [initialValues]);

  return (
    <Dialog
      open={open}
      title={t('services.vm.services.dialog.edit.title')}
      onClose={onClose}
      fullWidth
      handleSubmit={form.handleSubmit}
      PaperProps={{ style: { maxWidth: remCalc(750) } }}
      actions={
        <Row justifyContent="flex-end" spacing={2}>
          <Col>
            <Button variant="outlined" color="default" onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button disabled={ports.isRequesting} type="submit">
              {t('common.save')}
            </Button>
          </Col>
        </Row>
      }
    >
      <div className={cn('mb-25 steel lh-12')}>
        {t('services.vm.services.dialog.edit.content') as string}
      </div>

      <Row className="full-width mb-25" columnGap={2} alignItems="flex-start">
        <Col xs={5}>
          <Select
            name="nicId"
            label="forms.network"
            value={form.values.nicId}
            onChange={(nicId) => form.setFieldValue('nicId', nicId)}
            useAsync
            defaultOptions
            onLoad={getNicsOptions(query.id)}
          />
        </Col>
      </Row>

      <div className="mb-25">
        <Switch
          label={t('services.vm.services.dialog.switchers.anySource')}
          checked={values.anySource}
          onCheck={(anySource) => setFieldValue('anySource', anySource)}
        />
      </div>
      {!values.anySource && (
        <>
          <VmTabs onChange={handleTabChange} value={values.tab}>
            <VmTab
              index={0}
              label={t('services.vm.services.dialog.tab.firewall')}
            />
            {isProvider && (
              <VmTab
                index={1}
                label={t('services.vm.services.dialog.tab.geoip')}
              />
            )}
          </VmTabs>
          <TabPanel
            value={0}
            index={values.tab}
            className="pt-25 pb-25"
            style={STYLES.tab}
          >
            <FirewallIpAddresses {...form} />
          </TabPanel>
          {isProvider && (
            <TabPanel
              value={1}
              index={values.tab}
              className="pt-25 pb-25"
              style={STYLES.tab}
            >
              <GeoIp {...form} />
            </TabPanel>
          )}
        </>
      )}
    </Dialog>
  );
});

export default Renderer;
