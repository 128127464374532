import * as React from 'react';
import { Button } from 'elements';
import { withTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import { useAsync, useState } from 'hooks';
import { EXPORT_INVOICE_STATE, IExportPaymentState } from './constants';
import ExportPaymentsDialog from './ExportPaymentsDialog';
import { downloadCsv } from './helpers';

type IProps = React.PropsWithChildren<WithT>;

const Renderer = (props: IProps) => {
  const { t } = props;

  const [state, handleState] =
    useState<IExportPaymentState>(EXPORT_INVOICE_STATE);

  const { execute, isPending } = useAsync((date) =>
    // @ts-ignore
    downloadCsv({
      dateFrom: date.startDate,
      dateTo: date.endDate,
      type: 'PAYMENTS_EXPORT_CSV',
    })
  );

  const handleDialogClose = React.useCallback(
    () => handleState({ openExportDialog: false }),
    []
  );

  const onButtonClick = React.useCallback(
    () => handleState({ openExportDialog: true }),
    []
  );

  return (
    <>
      <Button variant="outlined" color="default" onClick={onButtonClick}>
        {t('invoices.buttons.exportPayments')}
      </Button>
      <ExportPaymentsDialog
        open={state.openExportDialog}
        onClose={handleDialogClose}
        onSave={execute}
        isDisabled={isPending}
      />
    </>
  );
};

const ExportInvoicesButton = withTranslation()(Renderer);

export default ExportInvoicesButton;
