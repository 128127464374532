import * as React from 'react';
import { AddIcon, Col, Input, MuiIcons, Row } from 'elements';
import { styled } from 'elements/utilities';
import { withTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import cn from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PALETTE } from 'enums';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('forms.emailInvalid').required('forms.required'),
});

const INITIALS = {
  email: '',
};

const SRow = styled(Row)(({ theme }) => ({
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${PALETTE.pageBackground}`,
  },
}));

type IProps = React.PropsWithChildren<
  WithT & {
    emails: IPartnerTypes.IBillingEmail[];
    isDisabled?: boolean;
    useFormElement?: boolean;
    billingEmailsEnabled: boolean;
    onEmailsChange: (emails: IPartnerTypes.IBillingEmail[]) => void;
  }
>;

const Form = (props: React.PropsWithChildren) => <form {...props} />;
const Div = (props: React.PropsWithChildren) => <div>{props.children}</div>;

const Renderer = (props: IProps) => {
  const {
    t,
    emails,
    billingEmailsEnabled,
    onEmailsChange,
    useFormElement,
    isDisabled,
  } = props;

  const onDeleteEmail = React.useCallback(
    (email: string) =>
      onEmailsChange(
        emails.filter(
          (item: IPartnerTypes.IBillingEmail) => item.email !== email
        )
      ),
    [JSON.stringify(emails)]
  );

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    submitCount,
    submitForm,
  } = useFormik({
    initialValues: INITIALS,
    validateOnMount: false,
    validationSchema,
    onSubmit: (data, formProps) => {
      if (!hasEmailDuplicates) {
        onEmailsChange([...emails, { email: data.email }]);
        formProps.resetForm();
      }
      return Promise.resolve(data);
    },
  });

  const hasEmailDuplicates = React.useMemo(() => {
    return emails.some(
      (item: IPartnerTypes.IBillingEmail) => item.email === values.email
    );
  }, [values.email]);

  const FormTag = React.useMemo(() => {
    return useFormElement ? Form : Div;
  }, [useFormElement]);

  return billingEmailsEnabled ? (
    <>
      {/* @ts-ignore */}
      <FormTag onSubmit={handleSubmit}>
        <Row
          justifyContent="space-between"
          alignItems="center"
          columnSpacing={2}
          className="mb-25"
        >
          <Col xs>
            <Input
              id="email"
              value={values.email}
              name="email"
              label="forms.email"
              placeholder="forms.placeholders.email"
              className="full-width"
              disabled={isDisabled}
              onChange={handleChange}
              error={!!submitCount && !!errors.email}
              helperText={errors.email}
            />
          </Col>
          <Col>
            <div className={cn('mt-25', { disabled: isDisabled })}>
              <AddIcon
                size="medium"
                disabled={hasEmailDuplicates}
                onClick={submitForm}
              />
            </div>
          </Col>
        </Row>
      </FormTag>
      {!!emails.length && (
        <div className="fs-12 uppercase mb-5">
          {t('tenants.dialog.manageBilling.table.heads.billingEmail') as string}
        </div>
      )}
      <div>
        {emails.map((item, index) => {
          return (
            <SRow
              key={index}
              alignItems="center"
              justifyContent="space-between"
              className={cn('pt-10 pb-10')}
            >
              <Col className="steel fs-12">{item.email}</Col>
              <MuiIcons.Delete
                className="steel fs-20 pointer"
                onClick={() => onDeleteEmail(item.email)}
              />
            </SRow>
          );
        })}
      </div>
    </>
  ) : null;
};

const ManageBillingEmailsContainer = withTranslation()(Renderer);

export default ManageBillingEmailsContainer;
