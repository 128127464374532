import { ILanguage } from 'global-shapes';
import * as StateHandlers from 'states';
import { useStateHandler } from './instanceHook';

type Result = {
  locale: ILanguage;
};

export function useLocale(): Result {
  const currentUser = useStateHandler(StateHandlers.currentUser);

  return { locale: currentUser.data?.lang || 'de' };
}
