import * as R from 'ramda';
import { is } from 'ramda';
import isEmail from 'validator/lib/isEmail';
import isFQDN from 'validator/lib/isFQDN';
import isURL from 'validator/lib/isURL';
import isIP from 'validator/lib/isIP';
import isHexColor from 'validator/lib/isHexColor';
import isIBAN from 'validator/lib/isIBAN';
import { AnyFunc } from 'global-shapes';
import { REGEX } from 'enums';
import { Address4 } from 'ip-address';
import { Dayjs } from 'dayjs';

export interface ValidatorRules {
  max?: number;
  min?: number;
  restrict?: AnyFunc;
}

const DEFAULT_MESSAGE = 'Cannot be empty';

const base = (checker: any) => {
  return (val: string) => {
    if (R.isNil(val)) return DEFAULT_MESSAGE;
    if (R.isEmpty(val)) return DEFAULT_MESSAGE;
    return checker(val);
  };
};

const min = (value: string) => {
  if (value.length < 10) return 'Min 10 character';
};

export function _isIBAN(iban: string): boolean {
  iban = iban.replace(/ /g, '');
  return isIBAN(iban);
}

export function isQRIBAN(iban: string): boolean {
  iban = iban.replace(/ /g, '');
  const QRIID = iban.substr(4, 5);
  return +QRIID >= 30000 && +QRIID <= 31999;
}

export const isCorrectPortValue = (val: string[]) => {
  return val.every((el) => {
    return +el >= 1 && +el <= 65535;
  }); // 1 - 65535 equals to 2 bytes
};

export const validator = {
  isEmail,
  min: base(min),
  empty: base(min),
  max: base(min),
  email: base(min),
  isNumber: (val: any) => {
    const _val = +val;
    if (_val === 0) return true;
    return _val;
  },
  name: (val: string) => {
    if (!val.length) return true;
    const reg = /^[a-zA-Z_. \u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]+$/;
    return reg.test(val);
  },
  username: (val: string) => {
    if (!val.length) return true;
    const reg = /^[a-zA-Z0-9-_.\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]*$/;
    return reg.test(val);
  },
  onlyLetters: (val: string) => {
    if (!val.length) return true;
    const reg = /[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]+$/i;
    return reg.test(val);
  },
  coreUserFirstLastName: (val: string) => {
    if (!val.length) return true;
    const reg = /[A-Za-z0-9' \u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]+$/i;
    return reg.test(val);
  },
  serverName: (val: string) => {
    if (!val.length) return true;
    const reg = /^[a-zA-Z0-9-]+$/i;
    return reg.test(val);
  },
  diskName: (val: string) => {
    if (!val.length) return true;
    const reg = /^[a-zA-Z0-9-]+$/i;
    return reg.test(val);
  },
  port: (val: string) => {
    if (!val.length) return true;
    return !!val.match(/^[0-9-]+$/);
  },
  portName: (val: string) => {
    if (!val.length) return true;
    return !!val.match(/^[0-9a-zA-Z-.,()$]*$/i);
  },
  ipSymbols: (val: string) => {
    if (!val.length) return true;
    const reg = /^[0-9.]+$/;
    return reg.test(val);
  },
  ipAndSubnetSymbols: (val: string) => {
    if (!val.length) return true;
    const reg = /^[0-9., /]+$/;
    return reg.test(val);
  },
  numRange: (val: string) => {
    if (!val.length) return true;
    const reg = /^[0-9-]+$/;
    return reg.test(val);
  },
  onlyLettersAndNum: (val: string) => {
    if (!val.length) return true;
    return !!val.match(REGEX.alphanumerical);
  },
  onlyNumbers: (val: string) => {
    if (!val.length) return true;
    return !!val.match(/^[0-9]+$/);
  },
  numbersWithDefinedDecimals:
    (max: number, isNegative = true) =>
    (val: string) => {
      if (!val.length) return true;
      const regex = isNegative ? /^[-0-9.]+$/ : /^[0-9.]+$/;
      const canEnter = !!val.match(regex);

      if (canEnter) {
        const splited = val.split('.');
        if (splited.length > 2) return false;
        if (splited[1] && splited[1].length > max) return false;
        return true;
      }
      return false;
    },
  onlyNumbersWithDot: (val: string) => {
    if (!val.length) return true;
    return !!val.match(/^[0-9.]+$/);
  },
  numberRangesWithComma: (val: string) => {
    if (!val.length) return true;
    return !!val.match(/^(\d+(-\d+)?)(,(\d+(-\d+)?))*$/);
  },
  onlyNumbersWithComma: (val: string) => {
    if (!val.length) return true;
    return !!val.match(/^[0-9, ]+$/);
  },
  prices: (val: string) => {
    if (!val.length) return true;
    const canEnter = !!val.match(/^[0-9.]+$/);

    if (canEnter) {
      const splited = val.split('.');
      if (splited.length > 2) return false;
      if (splited[0].length > 6) return false;
      if (splited[1] && splited[1].length > 4) return false;
      return true;
    }
    return false;
  },
  pricesWithNegative:
    (integerNumber = 6, decimalNumber = 4) =>
    (val: string) => {
      if (!val.length) return true;
      const canEnter = !!val.match(/^[0-9.-]+$/);
      const cleared = val.replace(/-/g, '');

      if (canEnter) {
        const splited = cleared.split('.');
        if (splited.length > 2) return false;
        if (splited[0].length > integerNumber) return false;
        if (splited[1] && splited[1].length > decimalNumber) return false;
        return true;
      }
      return false;
    },
  fileShareName: (val: string) => {
    if (!val.length) return true;
    return (val.match(REGEX.fileShareName) || []).length === val.length;
  },
  slash: (val: string) => {
    if (!val.length) return true;
    return !val.match(/\//);
  },
  isFQDN: (val: string) => isFQDN(val),
  isIP: (val: string) => isIP(val),
  isIBAN: (val?: string | null) => _isIBAN(val || ''),
  isQRIBAN: (val?: string | null) => _isIBAN(val || '') && isQRIBAN(val || ''),
  isPhone: (val?: string | null) => {
    const phoneValue = val && val.trim() ? `+${val}` : '';
    return !!phoneValue.match(REGEX.phone);
  },
  isVATSimplified: (val?: string | null) => {
    if ((val || '').trim().length <= 100) {
      return true;
    }
    return false;
  },
  isHex: (val: string) => {
    const colorStr = val || '';
    if (colorStr.length > 7) return false;
    return isHexColor(colorStr);
  },
  isURL: (val: string) => {
    if (val && val.trim()) {
      return isURL(val, {
        require_protocol: true,
        protocols: ['http', 'https', 'ftp'],
      });
    }
    return true;
  },

  isLaterThan: (val: Dayjs, limit: Dayjs) => {
    return +val > +limit;
  },

  isEarlierThan: (val: Dayjs, limit: Dayjs) => {
    return +val < +limit;
  },
};

export const validateCallback =
  (rules?: ValidatorRules) =>
  (cb: (event: React.ChangeEvent<HTMLInputElement>, val?: any) => void) => {
    return (ev: any, val: any) => {
      if (!rules) return cb(ev, val);

      const isNativeEvent = !!(is(Object)(ev) && ev && ev.target);

      const isValueInteger = isNativeEvent
        ? ev.target.type === 'number'
        : typeof ev === 'number';
      const eValue = isNativeEvent ? ev.target.value : ev;

      const finalCountValue = isValueInteger ? +eValue : eValue.length;
      if (rules.max !== undefined && finalCountValue > rules.max) {
        return;
      }

      if (rules.min !== undefined && finalCountValue < rules.min) {
        return;
      }

      if (rules.restrict && !rules.restrict(eValue)) {
        return;
      }

      return cb(ev, val);
    };
  };

async function validateIpRange(ipRange: string) {
  const ips = ipRange.split('-');
  const startAddress = new Address4(ips[0] || '');
  const endAddress = new Address4(ips[1] || '');

  if (
    !Address4.isValid(ips[0] || '') ||
    !Address4.isValid(ips[1] || '') ||
    startAddress.subnetMask !== endAddress.subnetMask
  ) {
    return false;
  }

  const difference = endAddress
    .bigInteger()
    .compareTo(startAddress.bigInteger());
  if (difference < 0) {
    return false;
  }

  const subnetMask = Math.ceil(Math.log(difference));
  const networkAddress = new Address4(`${startAddress.address}/${subnetMask}`);

  return !!(
    startAddress.isInSubnet(networkAddress) &&
    endAddress.isInSubnet(networkAddress)
  );
}

export const isValidIpCidrRange = async (ip: string) => {
  ip = ip || '';
  ip = ip.replace(/\s/g, '');
  if (ip.match(/^[\d.]+-[\d.]+$/)) {
    return validateIpRange(ip);
  }

  return Address4.isValid(ip);
};
