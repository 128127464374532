import * as React from 'react';
import cn from 'classnames';
import { includes } from 'ramda';
import { Loader, TablePagination, Alert } from 'elements';
import { useTranslation } from 'react-i18next';
import { RouterState } from 'hooks';
import { Permission } from 'auth-shapes';
import { IBillableItem, IBIParams } from 'billable-items';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import BillableItem from './BillableItem';
import { IBIAccessLevel } from './constants';

const OBSERVERS = {
  items: StateHandlers.billableItems,
};

export type IProps = React.PropsWithChildren<{
  accessLevel: IBIAccessLevel;
  permissions: Permission;
  isRequesting: boolean;
  router: RouterState<IBIParams>;
  onEdit: (item: IBillableItem) => void;
  onDelete: (id: IBillableItem) => void;
}>;

type IViewProps = typeof OBSERVERS;

const View = observer((props: IProps & IViewProps) => {
  const {
    onDelete,
    onEdit,
    router,
    accessLevel,
    permissions,
    isRequesting,
    items,
  } = props;
  const { query, queryStr, changeQuery } = router;
  const { t } = useTranslation();

  const onOpenDetails = React.useCallback(
    (id: number) => {
      const currentSelected = query.selected || [];
      // @ts-ignore
      const hasId = includes(id.toString(), currentSelected);
      // @ts-ignore
      const newValue = hasId
        ? currentSelected.filter((_id) => +_id !== id)
        : [...currentSelected, id];

      changeQuery({ selected: newValue });
    },
    [queryStr]
  );

  if (!items.dataReceived) {
    return (
      <div className="pt-50 pb-50">
        <Loader />
      </div>
    );
  }

  if (items.errors.get) {
    return (
      <Alert severity="error" className="mt-25 mb-25">
        {items.errors.get}
      </Alert>
    );
  }

  if (!items.data.length)
    return (
      <div className="pt-30 pb-30 text-center fs-14 steel">
        {t('common.noData') as string}
      </div>
    );

  return (
    <div className={cn('pt-20 pb-20', { disabled: items.isRequesting })}>
      {items.data.map((bi: IBillableItem) => {
        return (
          <BillableItem
            key={bi.id}
            {...bi}
            permissions={permissions}
            accessLevel={accessLevel}
            // @ts-ignore
            expanded={(query.selected || []).includes(bi.id.toString())}
            onOpenDetails={onOpenDetails}
            onEdit={onEdit}
            onDelete={onDelete}
            isRequesting={isRequesting}
          />
        );
      })}
      {query.perPage < items.meta.totalCount && (
        <TablePagination
          shouldUseQuery
          totalCount={items.meta.totalCount}
          onChange={({ page }) => changeQuery({ page })}
        />
      )}
    </div>
  );
});

const BillableItems = (props: IProps) => <View {...props} {...OBSERVERS} />;

export default BillableItems;
