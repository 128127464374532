import React, { useMemo } from 'react';
import { ALL_TEST_IDS, defaultDnsZonesQuery, CustomEvents } from 'enums';
import { useUserHash, usePermissions, useTask } from 'hooks';
import { AnyFunc } from 'global-shapes';
import { isEmpty } from 'ramda';
import { getBadgeStateKey, Emitter } from 'utils';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { IAddServiceCallback } from 'services-shapes';
import DnsContent from '../contents/Dns';
import CardHolder from '../CardHolder';
import { defineDnsDisplayStatus } from '../../../helpers';

type IProps = {
  onAdd: IAddServiceCallback;
  onDelete: AnyFunc;
  coreSetupCompleted: boolean;
};

type IViewProps = {
  dns: StateHandlers.IDnsHandler;
  assignments: StateHandlers.IBadgesAssignmentHandler;
};

const View = observer((props: React.PropsWithChildren<IProps & IViewProps>) => {
  const { onAdd, dns, assignments } = props;
  const [, changeUrl] = useUserHash();
  const task = useTask(dns.data?.task, { onComplete: () => dns.get() });
  const { permissions } = usePermissions('SERVICES');

  const entityAssignments = assignments.data[getBadgeStateKey(dns.data)] || [];

  const displayStatus = useMemo(defineDnsDisplayStatus(dns.data), [
    JSON.stringify(dns.data),
  ]);

  const onOpenDetails = React.useCallback(
    () => changeUrl(`/services/dns/domains`, defaultDnsZonesQuery),
    []
  );

  return (
    <>
      <CardHolder
        createId="create-dns-button"
        onAdd={() => permissions.canManage && onAdd('dns')}
        placeholder="services.content.placeholder.dns"
        isEmpty={!dns.data || isEmpty(dns.data)}
        status={displayStatus}
        title="services.card.dns"
        testId={ALL_TEST_IDS.services.dns.main}
        dataReceived={dns.dataReceived}
        assignments={entityAssignments}
        isDisabled={dns.isRequesting || task.isTaskActive}
        menuItems={[
          {
            value: 'openDetails',
            label: 'openDetails',
            onClick: onOpenDetails,
          },
          {
            value: 'assignBadges',
            label: 'assignBadges',
            onClick: () => Emitter.emit(CustomEvents.assignDialog, dns.data),
          },
        ]}
        onTitleClick={onOpenDetails}
      >
        <DnsContent {...(dns.data || {})} onSeeAll={onOpenDetails} />
      </CardHolder>
    </>
  );
});

const DnsCard = (props: React.PropsWithChildren<IProps>) => (
  <View
    {...props}
    dns={StateHandlers.dns}
    assignments={StateHandlers.badgesAssignment}
  />
);

export default DnsCard;
