import * as React from 'react';
import { Outlet } from 'react-router-dom';
import TabNav from './components/tabs/TabNav';
import { observer } from 'mobx-react-lite';
import * as StateHandlers from 'states';
import { Loader, Row } from 'elements';

const getAllServices = async () => {
  try {
    await StateHandlers.connectivity.get();
    await StateHandlers.core.get();
    await StateHandlers.vms.get({
      networkType: undefined,
      include: ['billingCycle', 'serviceMonitoring', 'serviceSnapshot'],
    });
    await StateHandlers.rds.get();
    await StateHandlers.dns.get();
    await StateHandlers.snapshots.get({ perPage: 100, page: 1 });
    await StateHandlers.costBreakdown.get();
    await StateHandlers.forwardedPorts.get({
      perPage: 1000,
      page: 1,
    });
    await StateHandlers.tenantPricing.get();
    await StateHandlers.scheduledTasks.get();
  } catch (er) {
    console.log('error on fetching services -> ', er);
  }
};

const resetAllDate = () => {
  StateHandlers.connectivity.reset();
  StateHandlers.core.reset();
  StateHandlers.vms.reset();
  StateHandlers.vmDetails.reset();
  StateHandlers.rds.reset();
  StateHandlers.dns.reset();
  StateHandlers.snapshots.reset();
  StateHandlers.costBreakdown.reset();
  StateHandlers.forwardedPorts.reset();
  StateHandlers.tenantPricing.reset();
  StateHandlers.scheduledTasks.reset();
};

type IViewProps = {
  connectivity: StateHandlers.IConnectivityHandler;
  core: StateHandlers.ICoreHandler;
  vms: StateHandlers.IVmsHandler;
  dns: StateHandlers.IDnsHandler;
  tenantPricing: StateHandlers.ITenantPricingHandler;
  forwardedPorts: StateHandlers.IForwardedPortsHandler;
  costBreakdown: StateHandlers.ICostBreakdownHandler;
  snapshots: StateHandlers.ISnapshotsHandler;
  rds: StateHandlers.IRdsHandler;
  scheduledTasks: typeof StateHandlers.scheduledTasks;
};

const View = observer((props: IViewProps) => {
  const {
    connectivity,
    core,
    vms,
    dns,
    tenantPricing,
    forwardedPorts,
    costBreakdown,
    snapshots,
    rds,
    scheduledTasks,
  } = props;

  const allDataReceived =
    connectivity.dataReceived &&
    core.dataReceived &&
    vms.dataReceived &&
    dns.dataReceived &&
    tenantPricing.dataReceived &&
    forwardedPorts.dataReceived &&
    costBreakdown.dataReceived &&
    snapshots.dataReceived &&
    scheduledTasks.dataReceived &&
    rds.dataReceived;

  React.useEffect(() => {
    getAllServices();
    return () => {
      resetAllDate();
    };
  }, []);

  if (!allDataReceived) {
    return <Loader />;
  }

  return (
    <Row justifyContent="space-between">
      <TabNav />
      <Outlet />
    </Row>
  );
});

const Services = () => (
  <View
    connectivity={StateHandlers.connectivity}
    core={StateHandlers.core}
    vms={StateHandlers.vms}
    dns={StateHandlers.dns}
    tenantPricing={StateHandlers.tenantPricing}
    forwardedPorts={StateHandlers.forwardedPorts}
    costBreakdown={StateHandlers.costBreakdown}
    snapshots={StateHandlers.snapshots}
    rds={StateHandlers.rds}
    scheduledTasks={StateHandlers.scheduledTasks}
  />
);

export default Services;
