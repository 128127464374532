import * as R from 'ramda';
import { IUser } from 'core-infrastructure';
import { IRds, IRdsUserPayload } from 'rds';
import {
  actionsMap,
  DEFAULT_VALUES,
  valueMap,
  valueMapReversed,
} from './constants';
import { IValues } from './types';
import { countries } from 'utils';

export const groupUserByType = (
  values: IValues
): { type: any; faLicense: number } => {
  const mappedTypes = R.reduce((res: any, key: string) => {
    res[key] = values[key]?.type;
    return res;
  }, {})(Object.keys(values));

  const mapped2FA = R.reduce((res: number, key: string) => {
    res = values[key]?.is2faEnabled ? res + 1 : res;
    return res;
  }, 0)(Object.keys(values));

  const arr = R.compose(R.values)(mappedTypes);
  return {
    type: { ...DEFAULT_VALUES, ...R.countBy(Math.floor)(arr) },
    faLicense: mapped2FA,
  };
};

export const getDirtyUsers = (
  clearUsers: IValues,
  currentValues: IValues
): IValues => {
  const dirtyUsers: IValues = {};
  Object.keys(clearUsers).forEach((userId) => {
    if (
      clearUsers[userId]?.type !== currentValues[userId]?.type ||
      clearUsers[userId]?.is2faEnabled !==
        currentValues[userId]?.is2faEnabled ||
      clearUsers[userId]?.twoFaBypass !== currentValues[userId]?.twoFaBypass
    ) {
      dirtyUsers[userId] = currentValues[userId];
    }
  });
  return dirtyUsers;
};

export const parseValuesForApi = (
  values: IValues,
  initialUsersValues: IValues
): IRdsUserPayload[] => {
  const payload: IRdsUserPayload[] = [];
  const dirtyUsers = getDirtyUsers(initialUsersValues, values);
  Object.keys(dirtyUsers).forEach((id) => {
    payload.push({
      coreInfraUserId: +id,
      // @ts-ignore
      type: valueMapReversed[dirtyUsers[id]?.type],
      is2faEnabled: !!dirtyUsers[id]?.is2faEnabled,
      twoFaBypass: !!dirtyUsers[id]?.twoFaBypass,
      // @ts-ignore
      action: actionsMap[dirtyUsers[id]?.type],
      details: dirtyUsers[id]?.details,
    } as IRdsUserPayload);
  });

  return payload;
};

export const parseUsersToForm = (users: IUser[]) => {
  const values: IValues = {};
  users.forEach((u) => {
    if (!u.rdsUser) {
      return (values[u.id] = {
        type: valueMap.DISABLED,
        is2faEnabled: false,
        twoFaBypass: false,
      });
    }

    values[u.id] = {
      // @ts-ignore
      type: valueMap[u.rdsUser.type],
      is2faEnabled: !!u.rdsUser?.is2faEnabled,
      twoFaBypass: !!u.rdsUser?.twoFaBypass,
    };
  });

  return values;
};

export function parseInitialGeoipValuesToForm(
  values: IRds['sourceCountryCodes']
): {
  countries: ValueType<string>[];
  country?: ValueType<string>;
} {
  return {
    countries: countries.filter((c) => values.includes(c.value)),
    country: undefined,
  };
}
