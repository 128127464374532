import React from 'react';
import cn from 'classnames';
import { useTranslation, withTranslation, TFunction } from 'react-i18next';
import { v4 } from 'uuid';
import { Row, Col, Input, AddIcon } from 'elements';
import { getZonesInputsColumns } from './constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isFloat from 'validator/lib/isFloat';
import isFQDN from 'validator/lib/isFQDN';
import { ZonesTypes } from './types';
import { ZonesLinearTable } from '../Styled';

interface ZoneFormProps {
  onAdd: (zone: ZonesTypes.ZoneValue) => void;
  existingZones: string[];
}

const validationSchema = Yup.object({
  domain: Yup.string()
    .trim()
    .required('forms.required')
    .test('domain', 'forms.invalid.domainName', (_val) => {
      _val = _val || '';
      return !isFloat(_val) && isFQDN(_val);
    }),
});

const initialValues = {
  domain: '',
};

const Inputs = withTranslation()((props: ZoneFormProps & { t: TFunction }) => {
  const { t, onAdd, existingZones } = props;

  const {
    values,
    errors,
    handleChange,
    submitCount,
    handleSubmit,
    submitForm,
    isValid,
  } = useFormik({
    initialValues: initialValues,
    validateOnMount: true,
    validationSchema,
    onSubmit: async (values, { resetForm, setErrors }) => {
      if (existingZones.includes(values.domain)) {
        return setErrors({ domain: 'forms.invalid.dns.list' });
      }
      await onAdd({ id: v4(), ...values });
      resetForm();
    },
  });

  const isValidForm = !values.domain.trim() || !isValid;

  return (
    <form onSubmit={handleSubmit}>
      <Row columnSpacing={2} className="full-width mb-25">
        <Col xs>
          <Input
            name="domain"
            value={values.domain}
            label={t('forms.domainName')}
            placeholder={t('forms.placeholders.domainName')}
            onChange={handleChange}
            error={!!submitCount && !!errors.domain}
            helperText={errors.domain}
          />
        </Col>
        <Col>
          <div className="pt-30">
            <AddIcon onClick={submitForm} disabled={isValidForm} size="medium" />
          </div>
        </Col>
      </Row>
    </form>
  );
});

interface ZonesPageProps {
  zones: ZonesTypes.ZoneValue[];
  onDelete: (zones: ZonesTypes.ZoneValue[]) => void;
  onAdd: (zone: ZonesTypes.ZoneValue) => void;
}

export default (props: ZonesPageProps) => {
  const { zones, onAdd, onDelete } = props;
  const { t } = useTranslation();

  const deleteZone = React.useCallback(
    (id: string) => onDelete(zones.filter((d) => d.id !== id)),
    [zones.length]
  );

  return (
    <div>
      <Inputs onAdd={onAdd} existingZones={zones.map((z) => z.domain)} />

      <div className={cn('mb-25')}>
        <ZonesLinearTable
          contentClassName="_table"
          cellClassName="_cell"
          data={zones}
          noDataClassName="_noData"
          columns={getZonesInputsColumns({ t, onDelete: deleteZone })}
        />
      </div>
    </div>
  );
};
