import * as React from 'react';
import * as R from 'ramda';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useLocale } from 'hooks';
import { GLOBAL_BORDER_RADIUS } from 'enums';
import {
  styled,
  Row,
  Col,
  MuiIcons,
  MenuItem,
  Menu,
  IconButton,
} from 'elements';
import { remCalc } from 'utils';
import { parseRepeatOnField, parseNextRebootField } from './helpers';
import { REBOOT_INTERVAL_OPTIONS } from './constants';

type IProps = IMaintenanceWindow & {
  className?: string;
  isEditable: boolean;
  onEdit: (config: IMaintenanceWindow) => void;
  onForceReboot: (config: IMaintenanceWindow) => void;
  onDelete: (config: IMaintenanceWindow) => void;
};

const RebootItem = (props: IProps) => {
  const { onEdit, onForceReboot, onDelete, className, ...rebootProps } = props;
  const {
    taskOperationName,
    recurringInterval,
    startAt,
    notificationEnabled,
    id,
    status,
    isEditable,
  } = rebootProps;
  const { locale } = useLocale();
  const { t } = useTranslation();

  const isRdsRecofiguration = taskOperationName === 'RdsHostReconfigure';

  const interval =
    R.find<ValueType<IMaintenanceRecurringInterval>>(
      R.propEq('value', recurringInterval)
    )(REBOOT_INTERVAL_OPTIONS) || REBOOT_INTERVAL_OPTIONS[0];

  return (
    <div className={cn(className, 'p-20', { disabled: status === 'ENABLED' })}>
      <Row alignItems="center" className="mb-15">
        <Col xs={3} className="steel fs-12">
          {t('table.head.name')}
        </Col>
        <Col xs={2} className="steel fs-12">
          {t('table.head.type')}
        </Col>
        <Col xs={2} className="steel fs-12">
          {t('table.head.repeatOn')}
        </Col>
        <Col xs={2} className="steel fs-12">
          {t('table.head.nextReboot')}
        </Col>
        <Col xs={2} className="steel fs-12">
          {t('table.head.informUsers')}
        </Col>
        <Col xs={1} />
      </Row>
      <Row alignItems="center">
        <Col xs={3} className="fs-14">
          {t(`reboots.operationName.${taskOperationName}`)}
        </Col>
        <Col xs={2} className="fs-14">
          {t(interval.label)}
        </Col>
        <Col xs={2} className="fs-14">
          {parseRepeatOnField(startAt, recurringInterval, locale)}
        </Col>
        <Col xs={2} className="fs-14">
          {parseNextRebootField(startAt, recurringInterval)}
        </Col>
        <Col xs={2} className="fs-14 lh-1">
          {notificationEnabled ? (
            <MuiIcons.Check className="success fs-18 lh-1" />
          ) : (
            <MuiIcons.Close className="error fs-18 lh-1" />
          )}
        </Col>
        <Col xs={1} className="fs-14" justifyContent="flex-end">
          <div className="flex justify-end">
            {isEditable && (
              <PopupState variant="popover">
                {(popupState) => (
                  <>
                    <IconButton size="small" {...bindTrigger(popupState)}>
                      <MuiIcons.MoreVert className="fs-20 steel pointer" />
                    </IconButton>

                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        data-test-id={`${id}-reboot-edit-item`}
                        onClick={() => {
                          onEdit(rebootProps);
                          popupState.close();
                        }}
                      >
                        {t(`common.edit`)}
                      </MenuItem>
                      {isRdsRecofiguration ? (
                        <MenuItem
                          data-test-id={`${id}-force-reboot--item`}
                          onClick={() => {
                            onForceReboot(rebootProps);
                            popupState.close();
                          }}
                        >
                          {t(`services.rds.reboot.actions.forceReboot`)}
                        </MenuItem>
                      ) : (
                        <MenuItem
                          data-test-id={`${id}-reboot-delete-item`}
                          onClick={() => {
                            onDelete(rebootProps);
                            popupState.close();
                          }}
                        >
                          {t(`common.delete`)}
                        </MenuItem>
                      )}
                    </Menu>
                  </>
                )}
              </PopupState>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default styled(RebootItem)(({ theme }) => {
  return {
    boxShadow: theme.shadows[1],
    marginBottom: remCalc(2),
    borderRadius: GLOBAL_BORDER_RADIUS,
  };
});
