import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = IServiceGroups.Group;

const FolderCard = (props: Props) => {
  const { t } = useTranslation();

  const innerVms =
    (props.type === 'DEDICATED_SERVER' ? props.dedicatedServers : props.vms) ||
    [];

  if (!innerVms.length) {
    return (
      <span className="steel">{t('services.card.content.folder.noItems')}</span>
    );
  }

  return (
    <>
      <div className="primary-dark mb-5">
        {t('services.card.content.folder.includes')}:
      </div>
      <div className="steel mb-15">
        {innerVms.map((vm) => {
          return (
            <div key={vm.id} className="steel mb-5">
              {vm.name}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FolderCard;
