import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'hooks';
import { Paper, Button } from 'elements';
import { fetchCsv } from './helpers';

const Main = () => {
  const { permissions: servicesPermissions } = usePermissions('SERVICES');
  const { permissions: appStorePermissions } = usePermissions('APP_STORE');
  const { permissions: cutomerPermissions } = usePermissions('TENANTS');
  const { t } = useTranslation();

  return (
    <Paper className="p-40">
      <h3 className="mb-15">{t('reports.title')}</h3>
      <p className="steel mb-30">{t('reports.subtitle')}</p>
      {servicesPermissions.canManage && (
        <>
          <div className="mb-30">
            <div className="mb-15">{t('badges.list.failedRds.text')}</div>
            <Button onClick={fetchCsv('failed-rds')}>
              {t('reports.buttons.download')}
            </Button>
          </div>
          <div className="mb-30">
            <div className="mb-15">{t('badges.list.failedCI.text')}</div>
            <Button onClick={fetchCsv('failed-core')}>
              {t('reports.buttons.download')}
            </Button>
          </div>
        </>
      )}
      {appStorePermissions.canManage && (
        <div className="mb-30">
          <div className="mb-15">{t('badges.list.officeLicense.text')}</div>
          <Button onClick={fetchCsv('users-app-stats')}>
            {t('reports.buttons.download')}
          </Button>
        </div>
      )}
      {cutomerPermissions.canManage && (
        <>
          <div className="mb-30">
            <div className="mb-15">
              {t('reports.sections.customersWithMSLicense.text')}
            </div>
            <Button onClick={fetchCsv('customers-with-ms-license')}>
              {t('reports.buttons.download')}
            </Button>
          </div>

          <div className="mb-30">
            <div className="mb-15">
              {t('reports.sections.customersAddressInfo.text')}
            </div>
            <Button onClick={fetchCsv('customers-address-info')}>
              {t('reports.buttons.download')}
            </Button>
          </div>
        </>
      )}
    </Paper>
  );
};

export default Main;
