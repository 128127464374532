import React, { useMemo } from 'react';
import { IMaskInput } from 'react-imask';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import cn from 'classnames';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { is } from 'ramda';
import { AnyShape } from 'global-shapes';
import { useTranslation } from 'react-i18next';
import { styled } from './utilities';
import { Row } from './Grid';
import { isString } from 'utils/customFunctions';
import { remCalc } from 'utils/uxHelpers';
import { PALETTE, TestSuffixes } from 'enums';
import { MuiIcons } from './MuiIcons';

type IOnChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  val?: any
) => void;
type IElementSize = 'md' | 'lg' | 'sm';

export interface InputProps extends OutlinedInputProps {
  elementSize?: IElementSize;
  label?: any;
  placeholder?: any;
  touched?: AnyShape | boolean;
  helperText?: any;
  helperTextOptions?: AnyShape;
  hideAdornment?: boolean;
  showClearIcon?: boolean;
  labelIcon?: any;
  onChange?: IOnChange;
  onClear?: (...args: any) => void;
  testId?: string;
  inputClassName?: string;
  labelClassName?: string;
  visibleByDefault?: boolean;
  errorId?: string | number;
}

const StyledInput = styled(
  (props: OutlinedInputProps & { elementSize?: IElementSize }) => (
    <OutlinedInput {...props} />
  )
)(({ theme }) => {
  return {
    '& .MuiOutlinedInput-notchedOutline': {
      transition: theme.transitions.easing.easeInOut,
      transitionDuration: '0.2s',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&.MuiInputBase-adornedStart > svg': {
      color: theme.palette.primary.main,
    },
    '&._md': {
      '& input': {
        height: remCalc(48),
      },
    },

    '&._lg': {
      '& input': {
        height: remCalc(54),
      },
    },

    '&._sm': {
      '& input': {
        height: remCalc(32),
        paddingTop: remCalc(7),
        paddingBottom: remCalc(7),
      },
    },
    '&._textArea': {
      '& textarea': {
        padding: remCalc(3, 0),
      },
    },

    '&._disabled': {
      backgroundColor: PALETTE.pageBackground,

      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent !important',
      },

      '& input': {
        color: PALETTE.steel,
      },

      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent !important',
      },
    },
  };
});

const defaultProps = {
  label: '',
  fullWidth: true,
  margin: 'none',
  className: 'jbbf-input',
  elementSize: 'md',
};

const Input = React.forwardRef((_props: InputProps, ref: any) => {
  const props = { ...defaultProps, ..._props };
  const {
    label,
    error,
    id,
    className,
    elementSize,
    helperText,
    touched,
    name,
    placeholder,
    labelIcon,
    disabled,
    testId,
    inputProps,
    inputClassName,
    labelClassName,
    visibleByDefault,
    errorId: propsErrorId,
    onClear,
    showClearIcon,
    helperTextOptions,
    hideAdornment,
    ...rest
  } = props;
  const { t } = useTranslation();

  const [showPass, handlePassState] = React.useState(visibleByDefault || false);

  const dataTestId = useMemo(() => {
    const ids: string[] = [];
    testId && ids.push(testId);
    name && ids.push(name);
    return ids.join('-');
  }, [name, testId]);

  const hasError = useMemo(
    () =>
      name && touched
        ? // @ts-ignore
          (is(Object)(touched) ? touched[name] : touched) && error
        : error,
    [name, touched, error]
  );

  const endAdornment = (function () {
    if (hideAdornment) {
      return null;
    }

    if (rest.type === 'password') {
      const Icon = showPass ? MuiIcons.VisibilityOff : MuiIcons.Visibility;
      return (
        <Icon
          fontSize="large"
          className={cn({ disabled }, 'pointer fs-20 primary mr-15')}
          onClick={() => handlePassState(!showPass)}
        />
      );
    }

    if (rest.type === 'search') {
      return (
        <>
          {!!ref?.current && !!ref?.current.value && (
            <MuiIcons.Clear
              className="fs-18 mr-15 error pointer"
              onClick={onClear}
            >
              clear
            </MuiIcons.Clear>
          )}
        </>
      );
    }

    return rest.endAdornment;
  })();

  const startAdornment = (function () {
    if (hideAdornment) {
      return null;
    }

    if (rest.type === 'search') {
      return (
        <>
          <MuiIcons.Search className="fs-18 steel ml-20" />
          {rest.startAdornment}
        </>
      );
    }

    return rest.startAdornment;
  })();

  const errorId = `${dataTestId}-${TestSuffixes.inputError}`;

  return (
    <div
      className={className}
      data-test-id={cn({
        [`${dataTestId}-${TestSuffixes.wrapper}`]: !!testId || !!name,
      })}
    >
      {label && (
        <InputLabel
          htmlFor={id || name}
          className={labelClassName}
          data-test-id={cn({
            [`${dataTestId}-${TestSuffixes.inputLabel}`]: !!testId || !!name,
          })}
        >
          {isString(label) ? t(label) : label} {labelIcon}
        </InputLabel>
      )}
      {/*@ts-ignore*/}
      <StyledInput
        {...rest}
        name={name}
        error={hasError}
        className={cn(inputClassName, {
          _disabled: disabled,
          _textArea: rest.multiline,
          [`_${elementSize}`]: elementSize,
        })}
        placeholder={t(placeholder)}
        id={id || name}
        inputProps={{
          'aria-label': id || name,
          'data-test-id': cn({
            [`${dataTestId}-${TestSuffixes.input}`]: !!testId || !!name,
          }),
          ...inputProps,
          ref,
        }}
        disabled={disabled}
        type={showPass ? 'text' : rest.type}
        endAdornment={endAdornment}
        startAdornment={startAdornment}
      />
      {!disabled && hasError && helperText && (
        <Row justifyContent="flex-end">
          <FormHelperText
            error={!!error}
            data-id={propsErrorId || errorId}
            data-test-id={cn({
              [errorId]: !!testId || !!name,
            })}
          >
            {t(helperText, helperTextOptions) as string}
          </FormHelperText>
        </Row>
      )}
    </div>
  );
});

interface IPhoneMaskProps {
  onChange: (value: string) => void;
}

const PhoneMask = React.forwardRef<any, IPhoneMaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+000000000000000"
        definitions={{
          '#': /[1-9]/,
        }}
        unmask
        // @ts-ignore
        inputRef={ref}
        // @ts-ignore
        onAccept={onChange}
        overwrite
      />
    );
  }
);

function InputPhone(props: InputProps & IPhoneMaskProps) {
  return <Input {...props} inputComponent={PhoneMask as any} />;
}

export { Input, InputLabel, InputAdornment, InputPhone };
