import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { reduce, values, pick, mergeDeepRight } from 'ramda';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { PermissionList, Permission, PermissionValueTypes } from 'auth-shapes';
import { Role, RoleFormValues } from 'roles-shapes';
import { AccessLevelLow, AnyShape } from 'global-shapes';
import Dropdown from '../users/Dropdown';
import { ALL_TEST_IDS } from 'enums';
import { showSystemMessage } from 'utils';
import { Dialog, Button, Input, Row, Col, DialogProps } from 'elements';
import { usePermissions } from 'hooks';
import { PERMISSION_OPTIONS_BY_LEVEL } from './constants';
import { SRow, RowRightCol } from './Styled';

type Props = DialogProps<any> & {
  isNew: boolean;
  isPendingRequest: boolean;
  isCurrentUserRole: boolean;
  initialValues: Role | null;
};

interface permissionNamesByLevelShape {
  [key: string]: PermissionList[];
}

const permissionNamesByLevel: permissionNamesByLevelShape = {
  provider: [
    'USERS',
    'ROLES',
    'PRICING',
    'BILLABLE_ITEMS',
    'INVOICES',
    'INVOICES_STATISTICS',
    'CUSTOMER_BILLING',
    'PARTNERS',
    'TENANTS',
    'SERVICES',
    'DOMAIN_ADMINS',
    'MONITORING',
    'APP_STORE',
    'USAGE',
  ],
  partner: [
    'USERS',
    'ROLES',
    'PRICING',
    'BILLABLE_ITEMS',
    'INVOICES',
    'INVOICES_STATISTICS',
    'CUSTOMER_BILLING',
    'TENANTS',
    'SERVICES',
    'DOMAIN_ADMINS',
    'MONITORING',
    'USAGE',
  ],
  tenant: [
    'USERS',
    'ROLES',
    'BILLABLE_ITEMS',
    'INVOICES',
    'SERVICES',
    'DOMAIN_ADMINS',
    'BACKUP',
    'MONITORING',
    'USAGE',
  ],
};

type IPermissionsValuesMap = Record<PermissionList, PermissionValueTypes>;

const getDefaultPermissions = (
  accessLevel: AccessLevelLow
): IPermissionsValuesMap => {
  const permissions = reduce((res, p: PermissionList) => {
    // @ts-ignore
    res[p] = 'none';
    return res;
  }, {})(permissionNamesByLevel[accessLevel]) as IPermissionsValuesMap;
  permissions.USAGE = 'viewOnly';
  permissions.PARTNERS = 'viewOnly';
  permissions.SERVICES = 'modify';
  permissions.DOMAIN_ADMINS = 'modify';
  permissions.MONITORING = 'modify';

  if (accessLevel === 'tenant') {
    permissions.BILLABLE_ITEMS = 'viewOnly';
    permissions.PRICING = 'viewOnly';
    permissions.DOMAIN_ADMINS = 'modify';
    permissions.MONITORING = 'modify';
    permissions.INVOICES = 'viewOnly';
    permissions.BACKUP = 'modify';
  }
  return permissions;
};

// @ts-ignore
const initials = (accessLevel: AccessLevelLow): RoleFormValues => ({
  name: '',
  isDefault: false,
  id: undefined,
  ...getDefaultPermissions(accessLevel),
});

const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required('forms.required')
    .max(16, 'forms.invalid.max'),
});

const validate = (permissions: IPermissionsValuesMap) => {
  const isValid = !values(permissions).every((p) => p === 'none'); // hasSelectedPermission
  return isValid;
};

const reformatPermissionToString = (
  perm?: Permission
): PermissionValueTypes => {
  let newVal: PermissionValueTypes = 'none';
  if (perm) {
    if (perm.canManage && perm.canView) newVal = 'modify';
    if (!perm.canManage && perm.canView) newVal = 'viewOnly';
  }
  return newVal;
};

const RolesDetailsDialog = (props: Props) => {
  const {
    isNew,
    initialValues,
    onSave,
    isCurrentUserRole,
    open,
    isPendingRequest,
    ...rest
  } = props;
  const { currentAccessLevel } = usePermissions();
  const { t } = useTranslation();
  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    setFieldValue,
    submitCount,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: initials(currentAccessLevel),
    validationSchema,
    onSubmit: (formValues) => {
      const { name, isDefault, id } = formValues;
      const permissions = pick(permissionNamesByLevel[currentAccessLevel])(
        formValues
      ) as IPermissionsValuesMap;
      const hasSelectedPermission = validate(permissions);
      if (!hasSelectedPermission) {
        showSystemMessage('roles.dialog.atLeastOnePermission', 'info');
      } else {
        const payload = {
          name,
          isDefault,
          id,
          permissions,
        };
        return Promise.resolve(onSave && onSave(payload));
      }
    },
  });

  const options = useMemo(() => {
    const opts: AnyShape = mergeDeepRight(
      {},
      PERMISSION_OPTIONS_BY_LEVEL[currentAccessLevel]
    );

    if (values.USERS === 'none') {
      opts.ROLES[0].disabled = false;
      opts.ROLES[1].disabled = true;
      opts.ROLES[2].disabled = true;
    }

    if (values.USERS === 'viewOnly') {
      opts.ROLES[0].disabled = true;
      opts.ROLES[1].disabled = false;
      opts.ROLES[2].disabled = true;
    }

    if (values.USERS === 'modify') {
      opts.ROLES[0].disabled = true;
      opts.ROLES[1].disabled = false;
      opts.ROLES[2].disabled = false;
    }

    if (values.SERVICES === 'viewOnly') {
      opts.DOMAIN_ADMINS[0].disabled = false;
      opts.DOMAIN_ADMINS[1].disabled = false;
      opts.DOMAIN_ADMINS[2].disabled = true;

      opts.BACKUP[0].disabled = false;
      opts.BACKUP[1].disabled = false;
      opts.BACKUP[2].disabled = true;

      opts.MONITORING[0].disabled = false;
      opts.MONITORING[1].disabled = false;
      opts.MONITORING[2].disabled = true;
    }

    if (values.SERVICES === 'modify') {
      opts.DOMAIN_ADMINS[0].disabled = false;
      opts.DOMAIN_ADMINS[1].disabled = false;
      opts.DOMAIN_ADMINS[2].disabled = false;

      opts.BACKUP[0].disabled = false;
      opts.BACKUP[1].disabled = false;
      opts.BACKUP[2].disabled = false;

      opts.MONITORING[0].disabled = false;
      opts.MONITORING[1].disabled = false;
      opts.MONITORING[2].disabled = false;
    }

    if (values.SERVICES === 'none') {
      opts.BACKUP[1].disabled = true;
      opts.BACKUP[2].disabled = true;
    }

    if (values.INVOICES === 'none') {
      opts.INVOICES_STATISTICS[0].disabled = false;
      opts.INVOICES_STATISTICS[1].disabled = true;
    }

    if (['viewOnly', 'modify'].includes(values.INVOICES)) {
      opts.INVOICES_STATISTICS[0].disabled = false;
      opts.INVOICES_STATISTICS[1].disabled = false;
    }

    opts.PRICING[0].disabled = values.BILLABLE_ITEMS !== 'none';

    if (currentAccessLevel === 'tenant') {
      opts.INVOICES[2].disabled = true;
    }

    return opts;
  }, [values, currentAccessLevel]);

  const onPermissionChange = useCallback(
    (p: PermissionList) => (val: PermissionValueTypes) => {
      setFieldValue(p, val);
      if (p === 'USERS') {
        if (val === 'none') {
          setFieldValue('ROLES', 'none');
        }
        if (val === 'viewOnly') {
          setFieldValue('ROLES', 'viewOnly');
        }
        if (val === 'modify') {
          const hasValidValue = ['viewOnly', 'modify'].includes(values.ROLES);
          !hasValidValue && setFieldValue('ROLES', 'viewOnly');
        }
      }

      if (p === 'SERVICES') {
        if (val === 'none') {
          setFieldValue('DOMAIN_ADMINS', 'none');
          setFieldValue('MONITORING', 'none');
          setFieldValue('BACKUP', 'none');
        }
        if (val === 'viewOnly') {
          setFieldValue('DOMAIN_ADMINS', 'viewOnly');
          setFieldValue('MONITORING', 'viewOnly');
          setFieldValue('BACKUP', 'viewOnly');
        }
        if (val === 'modify') {
          const hasDomainValidValue = ['viewOnly', 'modify'].includes(
            values.DOMAIN_ADMINS
          );
          const hasMonitoringValidValue = ['viewOnly', 'modify'].includes(
            values.MONITORING
          );
          const hasBacupValidValue = ['viewOnly', 'modify'].includes(
            values.BACKUP
          );
          !hasBacupValidValue && setFieldValue('BACKUP', 'viewOnly');
          !hasDomainValidValue && setFieldValue('DOMAIN_ADMINS', 'viewOnly');
          !hasMonitoringValidValue && setFieldValue('MONITORING', 'viewOnly');
        }
      }

      if (p === 'INVOICES') {
        if (val === 'none') {
          setFieldValue('INVOICES_STATISTICS', 'none');
        }
        if (val === 'viewOnly') {
          setFieldValue('INVOICES_STATISTICS', 'viewOnly');
        }
        if (val === 'modify') {
          const hasValidValue = ['viewOnly', 'modify'].includes(
            values.INVOICES_STATISTICS
          );
          !hasValidValue && setFieldValue('INVOICES_STATISTICS', 'viewOnly');
        }
      }

      if (p === 'BILLABLE_ITEMS') {
        if (val !== 'none' && values.PRICING === 'none') {
          setFieldValue('PRICING', 'viewOnly');
        }
      }
    },
    [values]
  );

  React.useEffect(() => {
    if (open) {
      if (initialValues) {
        setValues(initials(currentAccessLevel));
      }

      if (initialValues) {
        const newValues: AnyShape = {};

        permissionNamesByLevel[currentAccessLevel].forEach(
          (p: PermissionList) => {
            const current = initialValues.permissions.find(
              (perm) => perm.resource === p
            );
            newValues[p] = reformatPermissionToString(current);
          }
        );

        // @ts-ignore
        setValues({
          name: initialValues.name,
          isDefault: initialValues.isDefault || false,
          id: initialValues.id,
          ...newValues,
        });
      }
    }

    if (!open) {
      resetForm();
    }
  }, [open]);

  const testId = isNew
    ? ALL_TEST_IDS.roles.createRole
    : ALL_TEST_IDS.roles.editRole;

  return (
    <Dialog
      open={open}
      {...rest}
      title={t(`roles.dialog.title.${isNew ? 'create' : 'edit'}`)}
      fullWidth
      handleSubmit={handleSubmit}
      testId={testId}
      actions={
        <Row justifyContent="flex-end" columnSpacing={2}>
          <Col>
            <Button
              variant="outlined"
              color="default"
              onClick={props.onClose}
              testId={`${testId}-cancel`}
            >
              {t('common.cancel')}
            </Button>
          </Col>
          <Col>
            <Button type="submit" testId={testId} disabled={isPendingRequest}>
              {t('common.save')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Input
        name="name"
        className="mb-25"
        value={values.name}
        label={t('roles.dialog.input')}
        placeholder={t('roles.dialog.input.placeholder')}
        onChange={handleChange}
        error={!!submitCount && !!errors.name}
        helperText={errors.name}
        helperTextOptions={{ max: 16 }}
        testId={testId}
      />

      <SRow
        className={cn('fs-12 steel')}
        alignItems="center"
        justifyContent="space-between"
      >
        <Col>{t('roles.dialog.head.activity')}</Col>
        <RowRightCol className={cn('pl-10')}>
          {t('roles.dialog.head.permissions')}
        </RowRightCol>
      </SRow>

      <div className="mb-30">
        {permissionNamesByLevel[currentAccessLevel].map((p: PermissionList) => {
          const perm: PermissionValueTypes = values[p];

          return (
            <SRow
              key={p}
              alignItems="center"
              justifyContent="space-between"
              data-test-id={`${ALL_TEST_IDS.roles.permission}-${p}`}
            >
              <Col>{t(`permissions.${p}`)}</Col>
              <RowRightCol>
                <Dropdown
                  options={options[p]}
                  value={perm}
                  onChange={onPermissionChange(p)}
                  testId={`${ALL_TEST_IDS.roles.permission}-${p}-value`}
                  disabled={isCurrentUserRole && p === 'ROLES'}
                />
              </RowRightCol>
            </SRow>
          );
        })}
      </div>
    </Dialog>
  );
};

export default RolesDetailsDialog;
