import * as React from 'react';
import { Input, Slider, SliderValueLabel } from 'elements';
import { withTranslation } from 'react-i18next';
import { WithT } from 'i18next';
import { AnyFunc } from 'global-shapes';
import { remCalc } from 'utils';
import cn from 'classnames';
import { ALL_TEST_IDS } from 'enums';

type IProps = React.PropsWithChildren<
  WithT & {
    name: string;
    className?: string;
    minValue: any;
    maxValue: any;
    error?: boolean;
    isDisabled?: boolean;
    onChange: AnyFunc;
    onSystemInfoChange: AnyFunc;
    sliderProps: any;
    value: any;
    helperText?: any;
    isNew?: boolean;
  }
>;

const Renderer = (props: IProps) => {
  const {
    name,
    error,
    helperText,
    minValue,
    onSystemInfoChange,
    sliderProps,
    value,
    onChange,
    className,
    maxValue,
    t,
    isDisabled,
  } = props;

  return (
    <div className={cn(className, 'relative')}>
      <div className="flex justify-between align-center mb-10">
        <div>{t(`services.dialog.vms.${name}`) as string}</div>
        <div>
          <Input
            type="number"
            elementSize="sm"
            name={name}
            testId={ALL_TEST_IDS.services.vm.details}
            style={{ width: remCalc(100) }}
            value={value}
            onChange={onChange}
            disabled={isDisabled}
          />
        </div>
      </div>
      <Slider
        value={+value}
        {...sliderProps}
        min={minValue}
        valueLabelDisplay="auto"
        components={{
          ValueLabel: SliderValueLabel,
        }}
        disabled={isDisabled}
        onChange={onSystemInfoChange(name)}
      />
      {helperText && (
        <div
          className={cn('fs-12', { error, steel: !error })}
          style={{
            position: 'relative',
            display: 'block',
            maxWidth: '80%',
            margin: '-28px auto 0',
            textAlign: 'center',
          }}
        >
          {
            t(helperText, {
              value: +(value || 0) > maxValue ? maxValue : minValue,
              max: maxValue,
              min: minValue,
            }) as string
          }
        </div>
      )}
    </div>
  );
};

const SliderItem = withTranslation()(Renderer);

export default SliderItem;
